import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import { useCookies } from 'vue3-cookies';
import { IAddressesCoordResponse, IProductCategory, ITopProduct } from '@/types/interfaces';

const { cookies } = useCookies()

class ProductAPIModel {
  async createProduct(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProduct(filters: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.GetItems,
      responseType: 'items_data',
      headers: { Authorization: cookies.get('core')},
      data: { filter: filters, pagination: { per_page: 1, page: 1 }, order: [['updated_at', 'desc']]},
    })
  }
  
  async getAddressesByProduct(filters: any): Promise<string[]> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.getFarmerAddresseesFromProductFilter,
      data: { filter: filters },
    })
  }

  async getFinalCategoryBranch(attributes: any): Promise<IProductCategory> {
    return RequestManager.request({
      model: 'ProductCategoryOptimized',
      method: 'getFinalCategoryBranch',
      data: { attributes },
    })
  }

  async getProductCategoryMetric(): Promise<{ items: ITopProduct[] }> {
    return RequestManager.request({
      model: 'ProductCategoryMetric',
      method: EgalMethods.GetItems,
      data: {},
    })
  }
}

const ProductAPI = new ProductAPIModel()
export { ProductAPI }
