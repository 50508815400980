import { APIModels } from '@/types/enums'
import { IFarmer } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class FarmerAPIModel {
  async getItems(
    filters: any[][],
    withs: any[] = [],
    order: any[][],
    perPage = 10,
    page = 1,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.GetItems,
      data: { filter: filters, withs, pagination: { per_page: perPage, page: page }, order},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFarmersWithCategories(
    withs: string[] = ['farmer_products', 'addresses'],
    id?: string,
    search?: string,
  ): Promise<any> {
    const filter: any[] = []
    filter.push(['is_active', 'eq', true])
    if (id) {
      filter.push('AND')
      filter.push(['id', 'eq', id])
    }
    if (search) {
      filter.push('AND')
      filter.push(['Продукция', 'search', search])
    }

    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.getFarmersWithCategories,
      data: {
        filter,
        withs,
        pagination: { per_page: 1000, page: 1 },
        order: [
          {
            column: 'name',
            direction: 'asc',
          },
        ],
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  //  В запросе переписаны фильтры по нормальному
  async getFarmersWithCategoriesNew(
    filters: any[],
    withs: string[] = [],
  ): Promise<any> {
    const filter = [
      ['is_active', 'eq', true],
      'AND',
      ['farmer_products.is_deleted', 'eq', false],
    ]
    if (filters.length) {
      filter.push('AND', ...filters)
    }

    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.getFarmersWithCategories,
      responseType: 'items_data',
      data: {
        pagination: { per_page: 1000, page: 1 },
        filter,
        withs,
        order: [
          { column: 'name', direction: 'asc' },
        ],
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFarmer(id: number | string, withs: string[] = []): Promise<IFarmer> {
    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.GetItem,
      data: { id: String(id), withs},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getHelpsPrompt(filters: object): Promise<any> {
    return RequestManager.request({
      model: 'PromptHelper',
      method: EgalMethods.getPrompts,
      data: { attributes: filters },
      headers: { Authorization: cookies.get('core') || undefined },
    })
  }

  async create(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateFarmer(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFarmersAndProductsCount(): Promise<{
    farmers_count: number
    products_count: number
  }> {
    return RequestManager.request({
      model: 'PromptHelper',
      method: 'getFarmersAndProductsCount',
      data: {},
    })
  }
}

const FarmerAPI = new FarmerAPIModel()
export { FarmerAPI }
