<script lang="ts" setup>
import {
  Table,
  Divider,
  Button,
  Tooltip,
  Modal,
  notification,
} from 'ant-design-vue'
import {
  EditFilled,
  LeftOutlined,
  CloseOutlined,
  PlusOutlined,
  InfoCircleFilled,
} from '@ant-design/icons-vue'
import {onMounted, ref, h, computed} from 'vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import {roundNumber, priceWithoutVat} from '@/helpers/scripts'
import router from '@/router'
import { useRoute } from 'vue-router'
import DrawerSpecialPrice from '@/components/Drawer/DrawerSpecialPrice.vue'
import { IFarmerProduct, ISpecialPrice } from '@/types/interfaces'
import { SpecialPriceAPI } from '@/api/SpecialPriceAPI'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
const route = useRoute()

const columnSpecialPrices = [
  {
    title: 'Покупатель',
    dataIndex: 'customer',
    key: 'customer',
    width: 240,
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price',
    key: 'price',
    width: 140,
  },
  {
    title: 'Цена без НДС',
    dataIndex: 'price_without_vat',
    key: 'price_without_vat',
    width: 160,
  },
  // {
  //   title: 'Условия',
  //   dataIndex: 'details',
  //   key: 'details',
  //   width: 200,
  // },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    width: 524,
  },
  {
    title: '',
    key: 'expand',
    width: 128,
  },
]

const closeEdit = () => router.go(-1)

const product = ref<IFarmerProduct>({})
const isShowModalUpdatePage = ref<boolean>(false)

const getProduct = async (id: number) => {
  await FarmerProductAPI.getItem(Number(id), ['specialPrices.customer']).then(
    (res) => {
      product.value = res
      product.value.special_prices = res.special_prices.sort((a, b) => {
        const nameA = a.customer.name.toLowerCase()
        const nameB = b.customer.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
  )
  sortSpecialPrices()
}

// Сортировка спеццен по покупателям
const sortSpecialPrices = () => {
  product.value.special_prices?.sort((a, b) => {
    const firstFullname = `${a.customer.legal_form} ${a.customer.name}`
    const secondFullname = `${b.customer.legal_form} ${b.customer.name}`

    if (firstFullname > secondFullname) {
      return 1
    }
    if (firstFullname < secondFullname) {
      return -1
    }
    return 0
  })
}

const productId = route.params.id

onMounted(() => {
  getProduct(Number(productId))
})

const open = ref<boolean>(false)
const editPrice = ref<boolean>(false)
const specialPrice = ref<ISpecialPrice>({})
const index = ref(0)

const openDrawer = (
  gonnaEdit: boolean,
  special?: ISpecialPrice,
  priceIndex?: number,
) => {
  index.value = priceIndex
  specialPrice.value = special
  editPrice.value = gonnaEdit
  open.value = true
}

const closeDrawer = () => {
  open.value = false
}

const getSpecialPrices = (id: number) => {
  getProduct(id)
}

const openModal = ref<boolean>(false)
const productInModal = ref<any>()

const showModal = (product: any) => {
  productInModal.value = product
  openModal.value = true
}
const closeModal = () => {
  openModal.value = false
}

const deleteSpecial = async (product: any) => {
  await SpecialPriceAPI.delete(product?.id).then(async () => {
    notificationDeleted()
    closeModal()
    await getProduct(productId)
  })
}

const notificationDeleted = () => {
  notification.info({
    message: 'Спеццена удалена',
    top: '80px',
    icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
  })
}

const calcPrice = (price: number, product: any) => {
  if (price && product.measurement_unit_id) {
    return `${roundNumber(price)} ₽/${product.measurement_unit_id}`
  } else if (price) return `${roundNumber(price)} ₽`
  else return '-'
}

</script>

<template>
  <DrawerSpecialPrice
    v-model:open="open"
    :index="index"
    :specialPrice="specialPrice"
    :product="product"
    :editPrice="editPrice"
    :isOpen="open"
    @closeDrawer="closeDrawer"
    @getSpecialPrices="getSpecialPrices"
    @showModal="showModal"
    @openUpdatePageModal="(status) => (isShowModalUpdatePage = status)"
  />
  <div class="layout">
    <div class="layout__header">
      <Button @click="closeEdit">
        <template #icon> <LeftOutlined /> </template>
      </Button>
      <div class="layout__header__title_name">
        <h2>Редактирование спеццен</h2>
        <span class="product_name"> {{ product?.product?.fullName }} </span>
      </div>
    </div>
    <Divider />
    <Table
      :columns="columnSpecialPrices"
      :data-source="product?.special_prices"
      :pagination="false"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'customer'">
          {{ record?.customer.legal_form }} {{ record?.customer.name }}
        </template>
        <template v-if="column.key === 'price'">
          {{ calcPrice(record.price, product) }}
        </template>
        <template v-else-if="column.key === 'price_without_vat'">
            {{ calcPrice(+priceWithoutVat(record.price, record.vat_percent), product) }}
          <span class="vat"> НДС {{ record?.vat_percent }}% </span>
        </template>
        <!-- <template v-else-if="column.key === 'details'">
                    <div v-if="record?.expires_after || record?.quantity">
                        <Tag
                            class="date"
                            color="success"
                            v-if="record?.expires_after"
                        >
                            До {{ dayjs(record?.expires_after).format('DD.MM.YYYY') }}
                        </Tag>
                        <br>
                        <Tag
                            class="date"
                            color="success"
                            v-if="record?.quantity"
                        >
                            {{ record?.quantity }} {{ product?.measurement_unit_id }}
                        </Tag>
                        <br>
                        <span
                            class="vat"
                            v-if="record?.expires_after"
                        >
                            Осталось {{ expiresDays(record?.expires_after) }} {{ declensionDays(expiresDays(record?.expires_after)) }}
                        </span>
                        <br>
                    </div>
                    <span v-else> - </span>
                </template> -->
        <template v-else-if="column.key === 'comment'">
          <span v-if="record?.comment"> {{ record?.comment }} </span>
          <span v-else> - </span>
        </template>
        <template v-if="column.key === 'expand'">
          <div class="expand_buttons">
            <Tooltip
              placement="bottom"
              :overlayInnerStyle="{
                width: '121px',
              }"
            >
              <template #title>
                <span> Редактировать </span>
              </template>
              <Button
                type="text"
                @click="
                  openDrawer(true, product?.special_prices?.[index], index)
                "
              >
                <EditFilled class="edit" />
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              :overlayInnerStyle="{
                width: '73px',
              }"
            >
              <template #title>
                <span> Удалить </span>
              </template>
              <Button type="text" danger @click="showModal(record)">
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </template>
      </template>
    </Table>
  </div>
  <div class="footer">
    <div class="footer__button_panel">
      <Button size="large" class="close" @click="closeEdit"> Закрыть </Button>
      <Tooltip
        v-if="product.special_prices?.length >= 20"
        :overlayInnerStyle="{
          width: '302px',
        }"
      >
        <template #title>
          <span>
            Создано максимум спеццен. <br />
            Удалите ненужные, чтобы создать новые
          </span>
        </template>
        <Button size="large" type="primary" disabled>
          <template #icon> <PlusOutlined /> </template>
          Добавить спеццену
        </Button>
      </Tooltip>
      <Button v-else size="large" type="primary" @click="openDrawer(false)">
        <template #icon> <PlusOutlined /> </template>
        Добавить спеццену
      </Button>
    </div>
  </div>
  <Modal
    width="440px"
    centered
    v-model:open="openModal"
    title="Удалить спеццену?"
  >
    <p class="modalText">
      Спеццена будет удалена для {{ productInModal?.customer?.legal_form }}
      {{ productInModal?.customer?.name }}
    </p>
    <template #footer>
      <Button key="back" @click="closeModal">Закрыть</Button>
      <Button key="submit" @click="deleteSpecial(productInModal)" type="primary"
        >Удалить</Button
      >
    </template>
  </Modal>
  <ModalUpdatePage
    description="Спеццена был изменена другим пользователем. Обновите страницу для подгрузки актуальных данных."
    v-model:open="isShowModalUpdatePage"
    @close="isShowModalUpdatePage = false"
  />
</template>

<style lang="scss" scoped>
.layout {
  background: white;
  min-height: 66vh;
  padding: 20px 50px 0 50px;
  width: 100%;

  &__header {
    display: flex;

    Button {
      padding: 0;
      min-height: 34px;
      min-width: 34px;
      width: 34px;
      height: 34px;
    }

    &__title_name {
      padding-left: 12px;

      h2 {
        margin-bottom: 4px;
        font-size: 30px;
      }

      span {
        font-size: 14px;
        color: #000000a6;
      }
    }
  }

  .date {
    margin-bottom: 4px;
  }
}

.footer {
  margin: -40px -50px -48px -50px;
  height: 88px;
  box-shadow: 0px -4px 8px #0000001f;
  background: white;

  &__button_panel {
    margin-top: 60px;
    right: 24px;
    position: absolute;

    .close {
      margin-right: 16px;
    }
  }
}

.modalText {
  height: -webkit-fill-available;
  height: 100%;
  height: -moz-available;
}

.expand_buttons {
  width: 90px;
  margin: auto;

  Button {
    margin-right: 8px;
    padding: 0;
    width: 34px;
    height: 34px;

    .edit {
      color: #1677ff;
    }
  }
}

.vat {
  display: block;
  color: #00000073;
}

:deep(.ant-table-wrapper) {
  margin-bottom: 174px;
  border-radius: 10px;
  border: 1px solid #0000000f;
}
</style>
