<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import { useAuth } from '@/composables/useAuth'
import { emailRegEx, togglePasswordEyeIcon } from '@/types/globalConst'
import { format, maxString, minString, required } from '@/helpers/validators'
import { useCookies } from 'vue3-cookies'
import router from '@/router'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import TabSwitcher from '@/components/Switcher/TabSwitcher.vue'
import { Roles } from '@/types/enums'
import { ISwitcherItem } from '@/types/interfaces'
import { TypographyText as TgText, Flex } from 'ant-design-vue'

const emit = defineEmits(['next'])
const { register } = useAuth()
const { cookies } = useCookies()

const registerForm = reactive({
  email: '',
  password: '',
  password_confirmation: '',
})
const errors = reactive({
  email: '',
  password: '',
  password_confirmation: '',
})

const uniqueEmailError = ref('')

const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const repeatPasswordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const tabItems = [
  {
    name: 'Я поставщик',
    value: Roles.Farmer,
  },
  {
    name: 'Я покупатель',
    value: Roles.Customer,
  },
]

const activeRole = reactive({
  name: 'Поставщик',
  value: Roles.Farmer,
})

const switchForm = (item: ISwitcherItem) => {
  Object.assign(activeRole, item)
}

/**
 * Смена иконки глаза в инпутах с паролем
 * @param ev - клик эвент
 * @param repeatPassword - определяет был ли клик по инпуту "Повторите пароль"
 */
const onClick = (ev: { target: any }, repeatPassword: boolean) => {
  const obj = repeatPassword ? repeatPasswordInput : passwordInput
  togglePasswordEyeIcon(ev.target.tagName, obj)
}
const isAllFieldsFilled = computed(() => {
  return (
    !!registerForm.email &&
    !!registerForm.password &&
    !!registerForm.password_confirmation
  )
})
const isNoValidationErrors = computed(() => {
  return (
    !errors.email &&
    !errors.password &&
    !errors.password_confirmation &&
    registerForm.password === registerForm.password_confirmation
  )
})

const isFieldsValid = computed(() => {
  return isAllFieldsFilled.value && isNoValidationErrors.value
})

const firstRegister = async () => {
  registerForm.email = registerForm.email.toLowerCase()
  const userData = {
    attributes: {
      ...registerForm,
      company_type: activeRole.value,
    },
  }
  cookies.set('registerForm', JSON.stringify(registerForm), '365d', '')
  cookies.set('role', activeRole.value, '365d', '')
  try {
    await register(userData)
    cookies.set(
      'registerForm',
      JSON.stringify({
        ...registerForm,
      }),
        '365d', ''
    )
    cookies.remove('registerByInvite')
    await router.push('/confirm-email')
  } catch (err) {
    if (err?.response?.status == 405) await router.push('/mail-used')
    else await router.push('/error-occured')
    // uniqueEmailError.value = 'Такая почта уже зарегистрирована'
  }
}
</script>

<template>
  <div class="auth-card">
    <div class="auth-card__header">
      Регистрация
      <div class="step">
        <span>этап 1</span>
        <span class="max">/4</span>
      </div>
    </div>
    <div class="auth-card__body">
      <Flex :gap="6" vertical>
        <TabSwitcher
          :active="activeRole"
          :items="tabItems"
          class="auth-card__switcher"
          @select="switchForm"
        />
        <TgText type="secondary"
          >Если ваша компания выступает как поставщиком, так и покупателем,
          пожалуйста, зарегистрируйте 2 аккаунта
        </TgText>
      </Flex>
      <EInput
        v-model.trim="registerForm.email"
        class="ym-record-keys"
        :data="{
          ...inputDataConfig,
          id: 'email',
          label: 'Электронная почта',
          placeholder: '',
          modelValue: registerForm.email,
          error: uniqueEmailError,
          validators: [
            required,
            maxString(50),
            format(emailRegEx, 'Неверный формат'),
          ],
        }"
        :style-config="inputStyleConfig"
        @error="(err) => (errors.email = err)"
        @update:modelValue="() => (uniqueEmailError = '')"
      />
      <Flex :gap="8" vertical>
        <div class="password-row">
          <EInput
            v-model.trim="registerForm.password"
            :data="{
              ...inputDataConfig,
              id: 'password',
              label: 'Пароль',
              placeholder: '',
              modelValue: registerForm.password,
              type: passwordInput.type,
              iconRight: passwordInput.iconRight,
              validators: [
                required,
                maxString(24),
                minString(6),
                format(
                  /[a-zA-Z]/,
                  'Пароль должен содержать минимум одну букву латинского алфавита',
                ),
                format(/\d/, 'Пароль должен содержать минимум одну цифру'),
              ],
            }"
            :style-config="inputStyleConfig"
            class="password ym-record-keys"
            @click="(ev) => onClick(ev, false)"
            @error="(err) => (errors.password = err)"
          />
          <EInput
            v-model.trim="registerForm.password_confirmation"
            :data="{
              ...inputDataConfig,
              id: 'password-repeat',
              label: 'Повторите пароль',
              placeholder: '',
              modelValue: registerForm.password_confirmation,
              type: repeatPasswordInput.type,
              iconRight: repeatPasswordInput.iconRight,
              validators: [required],
              error:
                !registerForm.password_confirmation ||
                registerForm.password === registerForm.password_confirmation
                  ? ''
                  : 'Пароли не совпадают',
            }"
            :style-config="inputStyleConfig"
            class="password ym-record-keys"
            @click="(ev) => onClick(ev, true)"
            @error="(err) => (errors.password_confirmation = err)"
          />
        </div>
        <TpText type="secondary"
          >Пароль должен состоять минимум из 6 символов, содержать цифру и
          латинскую букву</TpText
        >
      </Flex>
    </div>

    <div class="auth-card__footer">
      <EButton
        :data="{ disabled: !isFieldsValid }"
        @click="firstRegister"
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
          padding: '10px 29px',
        }"
      >
        Зарегистрироваться
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 610px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 48px;

  &__header {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
    .password-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }
}
</style>
