<script setup lang="ts">
import {fontSize16, primaryButton, secondaryButton} from "@/assets/EgalStyles/EButton";
import StarLoader from "@/components/Loaders/StarLoader.vue";
import DocumentUploadListItem from "@/components/Document/DocumentUploadListItem.vue";
import DefaultModalWindow from "@/components/Modal/DefaultModalWindow.vue";
import {onMounted} from "vue";

interface IProps {
  show: boolean
  systemShippingDocument: any[],
  isDocumentsLoading: boolean,
  callback?: () => void,
  downloadOthersFilesAndDocsSent: () => void,
  closeDocumentsModal: () => void,
}

const props = defineProps<IProps>()
const emit = defineEmits(['close'])

const downloadOthersFilesAndDocsSent = async () => {
  if (props?.downloadOthersFilesAndDocsSent) {
    props?.downloadOthersFilesAndDocsSent()
  }
}

const closeDocumentsModal = async () => {
  if (props?.closeDocumentsModal) {
    props.closeDocumentsModal()
  }
}
</script>

<template>
  <DefaultModalWindow class="sign-modal" :show="show" :show-close-icon="false" :onlyContent="true">
    <template #content>
      <div class="other-document-modal">
        <div class="other-document-modal-content">
          <div class="document-modal-header">
            Скачайте отгрузочные документы
          </div>
          <p class="document-modal-info">
            Мы сгенерировали все нужные отгрузочные документы для вашего
            заказа, скачайте их при необходимости
          </p>
        </div>
        <StarLoader v-if="isDocumentsLoading" class="loader" />
        <DocumentUploadListItem
            v-for="(document, index) in systemShippingDocument"
            :key="document.id"
            :id="document.id"
            :errors="['']"
            :index="index"
            :is-download="true"
            :name="document.file_name"
            document-model="order"
            :download-btn="true"
        />
        <div class="download-link">
          <EButton
              :style-config="{
                ...secondaryButton,
                ...fontSize16,
                backgroundColor: '#ffffff',
                padding: '14px 14px',
                border: '1px solid #A0AEC0',
                justifyContent: 'center',
                width: '100%',
                hover: {
                  backgroundColor: '#F7FAFC',
                },
                active: {
                  backgroundColor: '#F7FAFC',
                },
              }"
              @click="closeDocumentsModal"
          >Закрыть
          </EButton>
          <EButton
              :style-config="{
                ...primaryButton,
                ...fontSize16,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }"
              @click="downloadOthersFilesAndDocsSent"
          >
            Скачать все документы
          </EButton>
        </div>
      </div>
    </template>
  </DefaultModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.loader {
  margin: 0 auto;
}

.loader-text {
  margin: 0 auto;
}

.download-link {
  text-decoration: none;
  display: flex;
  gap: 12px;
}

.page-card,
.other-document-modal {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 476px;
  height: fit-content;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  gap: 1rem;
  text-align: left;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }
}

.other-document-modal {
  padding: 8px;
  background: none;
  box-shadow: none;
  gap: 12px;

  .other-document-modal-content {
    margin-bottom: 8px;
  }

  .document-modal-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
    margin-bottom: 8px;
  }

  .document-modal-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $gray-700;
  }

  .download-link {
    margin-top: 20px;
  }
}
</style>
