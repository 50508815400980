<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { IPriceRequest, IProductRequest } from '@/types/interfaces'
import { Button, Space, Table, Tag } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import { getUserIdTemporary, priceRecipientStatusTag } from '@/helpers/scripts'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import router from '@/router'
import { RecipientAPI } from '@/api/RecipientAPI'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnershipStatus from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/components/PartnershipStatus/PartnershipStatus.vue'
import { usePartnersStore } from '@/store/usePartnersStore'

interface Props {
  data: IPriceRequest[] | undefined
  isDataFetching: boolean
  page: number
}
const props = defineProps<Props>()
const emit = defineEmits([
  'increaseTablePage',
  'changeSortDate',
  'changeRecipientStatus',
])

const expandedRowKeys = ref<number[]>([])

const columns = [
  {
    title: '',
    key: 'expand',
    width: 50,
  },
  {
    title: 'Товары в запросе',
    dataIndex: 'product_requests',
    key: 'product_requests',
    width: '30%',
  },
  {
    title: 'Покупатель',
    dataIndex: 'customer',
    key: 'customer',
  },
  {
    title: 'Дата запроса',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
  },
  {
    title: '',
    key: 'actions',
    width: 256,
  },
]

const getProductsList = (
  product: IProductRequest[],
  id: number,
  expanded: number[],
) => {
  if (expanded.includes(id)) return product
  return product?.slice(0, 3)
}
const handleExpandKey = (id: number) => {
  if (expandedRowKeys.value.includes(id)) {
    expandedRowKeys.value = expandedRowKeys.value.filter((exId) => exId !== id)
  } else {
    expandedRowKeys.value.push(id)
  }
}

const increasePageEmit = () => {
  emit('increaseTablePage')
}

const getOurRecipient = (priceRequest: IPriceRequest | undefined) => {
  return priceRequest?.recipients?.find(
    (recipient) => +recipient.farmer_id == +getUserIdTemporary(),
  )
}

const handleChangeSort = (
  page: { pageSize: number; current: number },
  filters: any,
  sorter: any,
) => {
  emit('changeSortDate')
}

const handleReply = (request: IPriceRequest) => {
  const recipientId: number | undefined = getOurRecipient(request)?.id

  if (recipientId) {
    localStorage.setItem('recipient', JSON.stringify(request))
    router.push(`/response/${recipientId.toString()}`)
  }
}

const handleReject = (request: IPriceRequest) => {
  const recipientId = getOurRecipient(request)?.id
  if (recipientId) {
    RecipientAPI.update({ id: recipientId, answer_status: 'refused' }).then(
      () => {
        emit('changeRecipientStatus', request.id, recipientId, 'canceled')
      },
    )
  }
}

const getCustomRow = (record: IPriceRequest) => {
  if (getOurRecipient(record)?.answer_status === 'answered') {
    return {
      onClick: () => {
        router.push(`/response/${getOurRecipient(record)?.id}`)
      },
      style: {
        cursor: 'pointer',
      },
    }
  } else {
    return {}
  }
}

const partnersStore = usePartnersStore()
const partners = computed(() => {
  return partnersStore.partners
})

const isPartner = (id: number) =>
  partners?.value?.some((el: { company_id: number }) => el.company_id === id)
</script>

<template>
  <div class="customer-request-list">
    <div class="customer-request-list-table">
      <Table
        :columns="columns"
        :data-source="props.data || []"
        rowKey="id"
        :pagination="false"
        :indentSize="10"
        @change="handleChangeSort"
        :custom-row="getCustomRow"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'expand'">
            <Button
              v-if="record?.product_requests?.length > 3"
              type="text"
              @click.stop="handleExpandKey(record.id)"
              class="rotate-btn"
              :class="{ isRotated: expandedRowKeys.includes(record.id) }"
            >
              <template #icon>
                <DownOutlined />
              </template>
            </Button>
          </template>
          <template v-else-if="column.key === 'customer'">
            <div class="partnership">
              <PartnershipStatus
                :is-partner="isPartner(record.customer_id)"
                v-if="isPartner(record.customer_id)"
              />
              {{ record?.customer?.name }}
            </div>
            <SimpleRate :company_rating="record.customer.company_rating"/>
          </template>
          <template v-else-if="column.key === 'product_requests'">
            <div class="product-list">
              <span
                v-for="product in getProductsList(
                  record.product_requests,
                  record.id,
                  expandedRowKeys,
                ).sort((a, b) => a?.name?.localeCompare(b?.name))"
                :key="product.product_name"
              >
                {{ product.product_name }}
              </span>
            </div>
          </template>
          <template v-else-if="column.key === 'created_at'">
            {{ dayjs(record.created_at).format('DD.MM.YYYY') }}
          </template>
          <template v-else-if="column.key === 'status'">
            <Tag
              v-if="getOurRecipient(record)?.answer_status === 'answered'"
              :color="priceRecipientStatusTag.answered?.color"
            >
              {{ priceRecipientStatusTag.answered?.text }}
            </Tag>
            <Tag
              v-else-if="new Date(record.expiration_date) < new Date()"
              :color="priceRecipientStatusTag.expired?.color"
              >{{ priceRecipientStatusTag.expired.text }}</Tag
            >
            <Space v-else :size="4" direction="vertical">
              <Tag
                :color="
                  priceRecipientStatusTag[
                    getOurRecipient(record)?.answer_status
                  ]?.color
                "
                >{{
                  priceRecipientStatusTag[
                    getOurRecipient(record)?.answer_status
                  ]?.text
                }}</Tag
              >
              <span
                v-if="
                  getOurRecipient(record)?.answer_status === 'waiting' ||
                  getOurRecipient(record)?.answer_status === 'received'
                "
              >
                До
                {{ dayjs(record.expiration_date).format('DD.MM.YYYY, HH:mm') }}
              </span>
            </Space>
          </template>
          <template v-else-if="column.key === 'actions'">
            <Space
              v-if="
                record.status === 'waiting' &&
                new Date(record.expiration_date) > new Date() &&
                (getOurRecipient(record)?.answer_status === 'waiting' ||
                  getOurRecipient(record)?.answer_status === 'received')
              "
              :size="6"
            >
              <Button @click.stop="() => handleReply(record)" type="link"
                >Ответить</Button
              >
              <Button
                @click.stop="() => handleReject(record)"
                type="link"
                danger
                >Отказаться</Button
              >
            </Space>
          </template>
        </template>
      </Table>
      <InfiniteLoading
        :distance="400"
        class="infinite-loading"
        @infinite="increasePageEmit"
      />
      <div v-if="isDataFetching" class="list-loader">
        <FullscreenLoading :type="'secondary'" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.partnership {
  display: flex;
  align-items: center;
  gap: 8px;
}
.customer-request-list {
  display: flex;
  flex-direction: column;
  gap: 24px 24px;
  max-height: 500px;
  overflow: auto;

  &-table {
    margin-bottom: 20px;
  }

  .product-list {
    display: flex;
    flex-direction: column;
  }

  .rotate-btn {
    :deep(.anticon) {
      transform: rotate(0);
      transition: 0.2s ease-in;
    }
    &.isRotated {
      :deep(.anticon) {
        transform: rotate(180deg);
      }
    }
  }
  .list-loader {
    transform: scale(0.6);
    width: 100%;
    height: 70px;
    position: relative;
  }
}
</style>
