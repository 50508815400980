<script setup lang="ts">

import { Empty, Space, TypographyTitle as TgTitle } from 'ant-design-vue'

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
interface IProps {
  title?: string
  subtitle?: string
  imgWidth?: number
  imgHeight?: number
}

const props = defineProps<IProps>()
</script>

<template>
  <Empty
    :image="simpleImage"
    class="empty-view"
    :imageStyle="{
      width: `${props.imgWidth ?? 184}px`,
      height: `${props.imgHeight ?? 117}px`,
      margin: '0 auto 24px auto',
    }"
  >
    <template #description>
      <Space direction="vertical" :size="0">
        <TgTitle :level="4">{{ title }}</TgTitle>
        <span class="empty-description">
          {{ subtitle }}
        </span>
      </Space>
    </template>
  </Empty>
</template>

<style scoped lang="scss">
.empty-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
  :deep(.ant-empty-image svg) {
    width: 184px;
    height: 117px;
  }
}
</style>
