<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { Tag } from 'ant-design-vue'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import { AuditOutlined } from '@ant-design/icons-vue'
import { IFarmer } from '@/types/interfaces'
import { CatalogAPI } from '@/api/CatalogAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'

interface Props {
  farm: IFarmer
}
const props = defineProps<Props>()

const { getCoreCookie } = useJWTDecode()

const isPartner = ref<boolean>(false)
onMounted(async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const company_id = user?.company_id

  await CatalogAPI.getPartner(props.farm.id, company_id).then((res) => {
    if (res.items[0]?.status === 'signed_customer') [(isPartner.value = true)]
  })
})
</script>

<template>
  <div class="tags">
    <Tag color="success" v-if="isPartner">
      <PartnerIcon />
      Договор заключен
    </Tag>
    <Tag v-if="farm.vat">
      <template #icon> <AuditOutlined /> </template>
      Налогообложение по НДС
    </Tag>
    <Tag v-if="farm?.can_deliver">
      <template #icon> <SupplierDelivery /> </template>
      Доставка
    </Tag>
    <Tag>
      <template #icon> <SelfDelivery /> </template>
      Самовывоз
    </Tag>
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-tag) {
  padding-left: 8px;
}

:deep(.anticon) {
  margin-left: 4px;
}
</style>
