<script setup lang="ts">
import CatalogPosition from '@/components/Catalog/CatalogPosition.vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import YaMap from '@/components/YaMap/YaMap.vue'
import { coordinatesRegex } from '@/model/constants'
import { computed, onMounted, Ref, ref } from 'vue'
import { calcCoords, convertPointToMapFormat } from '@/helpers/scripts'
import { sortFarmerProducts } from './helpers'
import { CatalogAPI } from '@/api/CatalogAPI'
import { IFarmer } from '@/types/interfaces'
import { IAddress } from '@/types/IAddress'
import { useStore } from '@/store'
import router from '@/router'
import emitter from "@/emitter";

const mainStore = useStore()

const minMaxProduct = ref({
  min: '',
  max: '',
})
const addressPoint = ref<IAddress | null>(null)
const farmerProducts: Ref<any[]> = ref([])
const catalogPosition = ref({ id: 1 })
const mapPoints: Ref<any[]> = ref([])
const showPreloader = ref(false)
const clickedFarmFromMap = ref()
const foundFarmIndex = ref(-1)
const isFetching = ref(true)
const totalCount = ref(0)

const isFirstFetch = computed(() => farmerProducts.value.length === 0)

const getCatalogPosition = async (id: string) => {
  try {
    const res = JSON.parse(
      JSON.stringify((await CatalogAPI.getPosition(id)).items[0]),
    )
    catalogPosition.value = res
    minMaxProduct.value.min = res.min_price
    minMaxProduct.value.max = res.max_price
  } catch (error) {
    console.error('getCatalogPosition error', error)
    return
  }
  await getFarmerProducts()
  isFetching.value = false
}

const getFarmerProducts = async (per_page = 10, page = 1) => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const company_id = user?.company_id

  let tempFarmerProducts = []
  const products = await CatalogAPI.getProduct(
    catalogPosition.value.id,
    per_page,
    page,
  )

  if (isFirstFetch.value) {
    farmerProducts.value = products?.items
  } else {
    tempFarmerProducts = products?.items
  }
  totalCount.value = products?.total_count

  for (const farmerProduct of farmerProducts.value) {
    const index = farmerProducts.value.indexOf(farmerProduct)
    if (farmerProducts.value[index].partner) continue
    farmerProducts.value[index].partner = (
      await CatalogAPI.getPartner(farmerProduct.farmer.id, company_id)
    ).items
  }

  farmerProducts.value.push(...tempFarmerProducts)
  farmerProducts.value = sortFarmerProducts(
    JSON.parse(JSON.stringify(farmerProducts.value)),
  )
}

const loadNextPage = async (page: number) => {
  showPreloader.value = true
  await getFarmerProducts(undefined, page)
  showPreloader.value = false
}

const resetScrollValue = () => {
  foundFarmIndex.value = -1
}
const getFarmerAddress = (address: IAddress) => {
  addressPoint.value = address
}

const initCustomerCatalog = async () => {
  await getCatalogPosition(String(router.currentRoute.value.params.id))
  const farmerFilter = farmerProducts.value
  mapPoints.value = farmerFilter
}

emitter.on('refresh-token', async (e) => {
  await initCustomerCatalog()
})

onMounted(async () => {
  await initCustomerCatalog()
})

const pointToDraw = computed(() => {
  return convertPointToMapFormat(
    farmerProducts.value?.filter(
      (farm) =>
        farm?.farmer?.addresses[0]?.coordinates &&
        coordinatesRegex.test(farm?.farmer?.addresses[0]?.coordinates),
    ).map((farm) => farm?.farmer),
  )
})
</script>

<template>
  <div class="loader" v-if="isFetching">
    <StarLoader />
  </div>
  <div class="wrapper" v-else>
    <div class="catalog">
      <CatalogPosition
        :scroll-value="foundFarmIndex"
        :position="catalogPosition"
        :farmer-products="farmerProducts"
        :show-preloader="showPreloader"
        :total-count="totalCount"
        :min-max-product="minMaxProduct"
        @load-next-page="loadNextPage"
        :scrollValue="foundFarmIndex"
        @reset-scroll-value="resetScrollValue"
        @get-farmer-address="getFarmerAddress"
      />
      <div class="map-side">
        <YaMap
          :class="!mainStore?.isVerification ? 'blur' : ''"
          class="catalog__map"
          width="71.05vw"
          height="calc(100vh - 68px)"
          :points="pointToDraw"
          :is-interactive="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.blur {
  filter: blur(5px);
  @include no-select();
}

.wrapper {
  width: 100%;
  position: absolute;
  top: 68px;
  left: 0;
}

.catalog {
  display: flex;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
}
</style>
