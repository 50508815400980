<script setup lang="ts">
import { IAddress, IContact, IUser } from '@/types/interfaces'
import { Col, Divider, Flex, Row, Space, Tag, Tooltip } from 'ant-design-vue'
import {
  AuditOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  FileTextOutlined,
} from '@ant-design/icons-vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import { convertPointToMapFormat, formatPhoneNumber } from '@/helpers/scripts'
import YaMap from '../YaMap/YaMap.vue'
import AddressList from '@/views/Admin/UserView/AddressList.vue'
import { coordinatesRegex } from '@/model/constants'
import { computed } from 'vue'
import { useStore } from '@/store'

const iconStyle = {
  fontSize: '16px',
  color: '#00000073',
}

interface IProps {
  user: IUser
  addresses: IAddress[]
  contacts: IContact[]
  isPartner: boolean
}
const props = defineProps<IProps>()

const address_coordinates = computed(() => {
  return convertPointToMapFormat(props.addresses)
})

const isIndividualContract = computed(
  () => props.user.company?.only_individual_contract,
)

const isAnyContract = computed(
  () =>
    !props.user.company?.individual_contract &&
    !props.user.company?.only_individual_contract,
)

const mainStore = useStore()
</script>

<template>
  <Row :gutter="8">
    <Col :span="24" class="data-row">
      <Flex :gap="16">
        <Flex flex="3" vertical :gap="16" justify="start">
          <Space :size="0">
            <Tag color="success" v-if="props?.isPartner">
              <PartnerIcon />
              Договор заключен
            </Tag>
            <template v-if="user?.type === 'farmer'">
              <Tag>
                <AuditOutlined />
                <template v-if="user?.company?.vat">
                  Налогообложение по НДС
                </template>
                <template v-else> Налогообложение без НДС </template>
              </Tag>
              <Tag v-if="user?.company?.can_deliver">
                <template #icon><SupplierDelivery /> </template>
                Доставка
              </Tag>
              <Tag>
                <SelfDelivery />
                Самовывоз
              </Tag>
              <Tooltip
                class="contract-tooltip"
                placement="bottom"
                :overlayInnerStyle="{ width: '232px' }"
              >
                <template #title>
                  <template v-if="isIndividualContract"
                    >Работает только по своему договору поставки</template
                  >
                  <template v-else-if="isAnyContract"
                    >Работа по договору Платформы или партнера</template
                  >
                  <template v-else
                    >Работа по своему договору либо предложенному
                    партнером</template
                  >
                </template>
                <Tag>
                  <template #icon>
                    <FileTextOutlined />
                  </template>
                  <template v-if="isIndividualContract"
                    >Только свой договор
                  </template>
                  <template v-else-if="isAnyContract"
                    >Нет своего договора
                  </template>
                  <template v-else>Свой или любой договор</template>
                </Tag>
              </Tooltip>
            </template>
          </Space>

          <Space :size="8" direction="vertical">
            <Space :size="4">
              <PhoneOutlined :style="iconStyle" />
              {{ formatPhoneNumber(user.company.contact_number) }}
            </Space>
            <Space :size="4">
              <EnvironmentOutlined :style="iconStyle" />
              {{ user.company.legal_address }}
            </Space>
          </Space>

          <Space
            :size="4"
            direction="vertical"
            v-if="user.company?.description"
          >
            <span class="data-title">Описание организации</span>
            <span class="data-subtitle">{{ user.company.description }}</span>
          </Space>
        </Flex>
        <Divider class="height-divider" type="vertical"></Divider>
        <Flex flex="2" align="start">
          <Row :gutter="[32, 16]">
            <Col :span="7">
              <Space :size="4" direction="vertical">
                <span class="data-title">ИНН</span>
                {{ user.company.tin }}
              </Space>
            </Col>
            <Col :span="7">
              <Space :size="4" direction="vertical">
                <span class="data-title">КПП</span>
                {{ user.company.tax_registration_reason }}
              </Space>
            </Col>
            <Col :span="10">
              <Space :size="4" direction="vertical">
                <span class="data-title">Расчётный счёт</span>
                {{ user.company.checking_account }}
              </Space>
            </Col>
            <Col :span="7">
              <Space :size="4" direction="vertical">
                <span class="data-title">ОКПО</span>
                {{ user.company.okpo }}
              </Space>
            </Col>
            <Col :span="7">
              <Space :size="4" direction="vertical">
                <span class="data-title">ОГРН</span>
                {{ user.company.bin }}
              </Space>
            </Col>
          </Row>
        </Flex>
      </Flex>
    </Col>
    <Col :span="24" class="data-row">
      <Flex justify="space-between" :gap="24">
        <AddressList :addresses="addresses" />
        <YaMap
          width="676px"
          style="width: auto"
          height="500px"
          :points="address_coordinates"
          :class="!mainStore?.isVerification ? 'blur' : ''"
        />
      </Flex>
    </Col>
  </Row>
  <div class="main-data"></div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.data-subtitle {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  word-break: break-all;
}

.blur {
  filter: blur(5px);
  @include no-select();
}

.data-row {
  padding: 24px 16px;
  &:first-child {
    border-bottom: 1px solid #0000000f;
  }
  .data-title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
  }
  :deep(.address-name) {
    font-weight: 600;
  }

  .height-divider {
    height: auto;
    display: flex;
    align-self: stretch;
  }

  .contact-title {
    //styleName: LG/Strong;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    color: #000000e0;
  }
}

.contract-tooltip {
  &.ant-tooltip-open {
    font-size: 12px;
  }
}
</style>
