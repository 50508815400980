<script setup lang="ts">


interface Props {
  title: string
  subtitle: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="flex-container">
    <div class="customer-block-title">
      {{ props.title }}
    </div>
    <div class="customer-block-subtitle">
      {{ props.subtitle }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 22px;
  font-size: 14px;
}
.customer-block-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}
.customer-block-subtitle {
}
</style>
