<script setup lang="ts">
import { ref, shallowRef } from 'vue'
import { Button, Col, Flex, Input, Row, Textarea, Select } from 'ant-design-vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { FormItem } from 'ant-design-vue/es/form'
import { watchThrottled } from '@vueuse/core'
import { phoneMask } from '@/types/globalConst'
import { calcCoords, transformPhoneNumber } from '@/helpers/scripts'
import { streetValidator } from './VerificationFormValidators'
import { VerificationFirstStepAddress } from './VerificationFirstStep.vue'
import { useModalStore } from '../ModalSystem/ModalSystemStore'
import { ModalKeys } from '../ModalSystem/ModalKeys'

type EmitName =
  | 'update-street'
  | 'update-name'
  | 'update-number'
  | 'update-comment'
  | 'remove-address'

const props = defineProps<{
  index: number
  address: VerificationFirstStepAddress
}>()

const emit = defineEmits([
  'update-street',
  'update-name',
  'update-number',
  'update-comment',
  'remove-address',
])

const options = ref([])
const input = shallowRef(props.address.street || null)
const modalStore = useModalStore()

const handleUpdateStreet = (e: string) => {
  e.length > 0 ? (input.value = e) : (input.value = null)
  // @ts-ignore
  new window.ymaps.suggest(e).then((res) => {
    options.value = res.map((item) => ({
      value: item.value,
      label: item.value,
    }))
  })
}

const handleUpdateAddress = (e: Event, emitName: EmitName) => {
  const el = e.target as HTMLInputElement
  emit(emitName, el.value, props.index)
}

const removeAddress = () => {
  emit('remove-address', props.index)
}

const onSelect = async (value: string) => {
  // @ts-ignore
  await window.ymaps.geocode(value).then((res) => {
    const obj = res.geoObjects.get(0)
    if (obj?.geometry?._coordinates?.length > 0) {
      input.value = value
      emit('update-street', value, props.index, obj?.geometry?._coordinates)
    }
  })
}

const onChange = (value: string | undefined) => {
  if (!value) {
    options.value = []
    emit('update-street', null, props.index)
    handleUpdateStreet('')
    input.value = null
    return
  }
  input.value = value
  emit('update-street', value, props.index)
}

const openMapModel = () => {
  const coordArray = calcCoords(props.address.coordinates)  
  const coordinates = coordArray ? {
    lat: coordArray[0],
    lon: coordArray[1],
  } : null

  modalStore.openModal(ModalKeys.SelectAddressOnMap, {
    selectedAddress: coordinates
      ? {
          id: props.address.id,
          coordinates: coordinates,
          name: props.address.street,
          address: props.address.street,
          description: props.address.street,
          text: props.address.street,
        }
      : null,
    onSave: (data) => {
      emit('update-street', data.text, props.index, data.coordinates) 
      input.value = data.text     
    },
  })
}

const handleBlur = () => {
  if (!input.value) {
    emit('update-street', null, props.index)
  }
}
</script>

<template>
  <Flex vertical>
    <FormItem
      :name="['addresses', index, 'street']"
      labelAlign="left"
      class="label"
      :rules="[
        {
          required: true,
          validator: streetValidator,
          trigger: 'change',
        },
      ]"
    >
      <template #label>
        <Flex justify="space-between" style="width: 100%">
          <div>Адрес</div>
          <Button
            v-if="index >= 1"
            size="small"
            type="link"
            danger
            @click="removeAddress"
          >
            <DeleteOutlined />
            Удалить адрес
          </Button>
        </Flex>
      </template>
      <Flex gap="12">
        <Select
          v-model:value="input"
          class="address-input"
          show-search
          size="large"
          allowClear
          placeholder="Введите адрес"
          @select="onSelect"
          @change="onChange"
          @search="handleUpdateStreet"
          @blur="handleBlur"
          :show-arrow="false"
          :options="options"
          :not-found-content="
            input
              ? 'Мы не нашли этот адрес, воспользуйтесь картой'
              : 'Введите адрес'
          "
        />
        <Button size="large" class="map-btn" @click="openMapModel" type="link">
          Карта
        </Button>
      </Flex>
    </FormItem>
    <Row :gutter="12">
      <Col :span="12">
        <FormItem
          label="Контактное лицо на адресе"
          :name="['addresses', index, 'name']"
          :rules="[
            {
              max: 255,
              message: 'Максимум 255 символов',
              trigger: 'change',
            },
          ]"
        >
          <Input
            placeholder="Введите контактное лицо"
            size="large"
            :value="props.address.name"
            @change="(e) => handleUpdateAddress(e, 'update-name')"
          />
        </FormItem>
      </Col>
      <Col :span="12">
        <FormItem
          label="Номер телефона"
          :name="['addresses', index, 'contact_number']"
          :rules="[
            {
              min: 16,
              message: 'Минимум 11 символов',
              trigger: 'change',
            },
          ]"
          v-maska="{
            mask: phoneMask,
            preprocessor: (value: string) => transformPhoneNumber(value),
          }"
        >
          <Input
            placeholder="Введите номер телефона"
            size="large"
            :value="props.address.contact_number"
            @change="(e) => handleUpdateAddress(e, 'update-number')"
            :maxlength="16"
          />
        </FormItem>
      </Col>
    </Row>
    <FormItem
      label="Комментарий к адресу"
      help="Максимум 1000 символов"
      :name="['addresses', index, 'comment']"
      :rules="[
        {
          max: 1000,
          message: 'Максимум 1000 символов',
          trigger: 'change',
        },
      ]"
    >
      <Textarea
        placeholder="Например: въезд со двора, красная дверь, график работы с 9 до 17"
        size="large"
        :value="props.address.comment"
        @change="(e) => handleUpdateAddress(e, 'update-comment')"
        :autoSize="{
          minRows: 4,
          maxRows: 4,
        }"
      />
    </FormItem>
  </Flex>
</template>

<style lang="scss" scoped>
.address-input {
  :deep(.ant-select-selection-search-input) {
    font-size: 16px;
  }
}
.label {
  :deep(.ant-form-item-required) {
    width: 100%;
  }
}
.map-btn {
  background: #deebfc;
}
</style>
