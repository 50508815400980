export const farmerLinks = [
  {
    to: `/products`,
    label: 'Мои товары',
  },
  {
    to: '/history',
    label: 'История заказов',
  },
  {
    to: '/requests',
    label: 'Запросы цен',
  },
  {
    to: `/partners`,
    label: 'Партнёры',
  },
  {
    to: '/auctions',
    label: 'Торги продажа',
  },
  {
    to: '/reductions',
    label: 'Торги покупка',
  },
]

export const customerLinks = [
  {
    to: '',
    label: 'Каталог',
  },
  {
    to: '/history',
    label: 'История заказов',
  },
  {
    to: '/requests',
    label: 'Запросы цен',
  },
  {
    to: `/partners`,
    label: 'Партнёры',
  },
  {
    to: '/auctions',
    label: 'Торги продажа',
  },
  {
    to: '/reductions',
    label: 'Торги покупка',
  },
]

export const withoutStylesRoutes = [
  '/create-request',
  '/create-reduction',
  '/create-auction',
  '/partners',
  '/response/',
  '/reductions/',
  '/auctions/',
  '/1c-faq',
  '/1c-table',
]

export const guestLinks = [
  // {
  //   to: '/about-company',
  //   label: 'О компании',
  // },
  // {
  //   to: '#about-block',
  //   label: 'Как стать поставщиком',
  // },
  // {
  //   to: '#about-block',
  //   label: 'Как стать покупателем',
  // },
  // {
  //   to: '/contacts',
  //   label: 'Контакты',
  // },
]
