<script setup lang="ts">
import { Button } from 'ant-design-vue'
import LeftWing from '../../assets/img/left_wing.svg'
import RightWing from '../../assets/img/right_wing.svg'

interface IProps {
  companyName: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['continue'])
</script>

<template>
  <div class="selected-modal">
    <div class="title">
      <img :src="LeftWing" alt="left_wing" loading="lazy" />
      <div class="text">Поставщик выбрал вас</div>
      <img :src="RightWing" alt="right_wing" loading="lazy" />
    </div>
    <div class="description">
      <span class="description-title">
        "{{ companyName }}" завершил торг на продажу
      </span>
      <span> Вы можете оформить заказ на основании своих ставок</span>
    </div>
    <Button class="create-order" @click="emit('continue')">
      Оформить заказ
    </Button>
  </div>
</template>

<style scoped>
.selected-modal {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  z-index: 3;
  bottom: 1px;
  text-align: center;
  align-items: center;
  display: flex;
  height: 72px;
  width: 900px;
  background: #1677ff;
  color: #fff;
  font-size: 14px;
  margin-bottom: 168px;

  .title {
    display: flex;
    margin: 8px 12px 8px 8px;
    line-height: 16px;
    height: 56px;
    width: 160px;
    text-align: center;
    align-items: center;

    .text {
      font-weight: 500;
      height: 30px;
      width: 90px;
    }

    img {
      min-height: 48px;
      max-height: 48px;
      min-width: 36px;
      max-width: 36px;
    }
  }

  .description {
    height: 14px;
    width: 400px;
    display: inline-grid;
    gap: 8px;
    align-items: center;
    text-align: left;
    color: #ffffff;
    margin-bottom: 25px;

    .description-title {
      font-weight: 600;
    }
  }

  .create-order {
    position: absolute;
    right: 16px;
    height: 40px;
    width: 158px;
    font-size: 16px;
  }
}
</style>
