<script lang="ts" setup>
import { Button } from 'ant-design-vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import close from '@/assets/img/close-card-top.svg';
import {truncatedTitle, formatPrice, formatPriceWithoutDecimal} from './helpers';
import {
  ITopOfYearCardProps
} from "@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockTopOfYear/components/TopOfYearCard/interfaces";

const props = defineProps<ITopOfYearCardProps>()
const emit = defineEmits(['topYearFilter'])

const handleClickSearchButton = () => {
  emit('topYearFilter', ['category', 'search', props.card.id])
}
</script>

<template>
  <div class="top-of-year-card" :key="card.id" @click="handleClickSearchButton">
    <div class="top-of-year-card__background" />
    <div
      class="top-of-year-card__inner-wrapper"
      :class="{ active: card.isActive }"
    >
      <div class="inner-wrapper__content">
        <div class="top-of-year-card__total-price">
          {{ formatPriceWithoutDecimal(card.sum) }}
        </div>
        <div class="top-of-year-card__title">
          {{ truncatedTitle(card.name) }}
        </div>
      </div>
      <div
        class="button__wrapper"
        :class="{ button__wrapper_active: card.isActive }"
      >
        <Button
          type="primary"
          class="top-of-year-card__button"
          :class="{ 'top-of-year-card__button_active': card.isActive }"
        >
          <SearchOutlined v-if="!card.isActive" class="button__icon" />
          <img v-if="card.isActive" :src="close" alt="" />
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/style/variables';

.button {
  &__icon {
    height: 20px;
    width: 20px;
  }
  &__wrapper {
    height: 40px;
    width: 40px;
  }
}

.inner-wrapper__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-of-year-card__inner-wrapper.active {
  cursor: pointer;
  background: variables.$top-of-year-card-background;
  z-index: 99999;
  box-shadow: variables.$benefits-tabbar-card-box-shadow;
  min-width: 107%;
  border-left: none;
  transition:
    width 0.35s ease-in-out,
    margin-right 0.35s ease-in-out;
}
.button__wrapper {
  opacity: 0;
  &_active {
    opacity: 1;
  }
}
.top-of-year-card {
  display: flex;
  position: relative;
  width: 100%;
  &__inner-wrapper {
    width: 107%;
    position: relative;
    z-index: 2;
    display: flex;
    gap: 14px;
    border-radius: 12px;
    padding: 17px 20px;
    justify-content: space-between;
    transition:
      width 0.35s ease-in-out,
      margin-right 0.35s ease-in-out;
    background-color: rgba(255, 255, 255, 1);

    &:hover {
      cursor: pointer;
      opacity: 1;

      background: variables.$top-of-year-card-background;
      z-index: 99999;
      box-shadow: variables.$benefits-tabbar-card-box-shadow;
      min-width: 107%;
      transition:
        width 0.35s ease-in-out,
        margin-right 0.35s ease-in-out;
      > .button__wrapper {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }
  }
  &__title {
    font-family:
      Nunito Sans,
      sans-serif !important;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.76px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$top-of-year-card-text-color;
    margin-left: 20px;
    max-width: fit-content;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 192px;
    max-width: 192px;
  }
  &__total-price {
    font-family: Inter, sans-serif;
    font-size: 33px;
    font-weight: 600;
    line-height: 42px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$top-of-year-cart-total-price-color;
    border-right: 1px solid rgba(39, 69, 53, 0.11);
    padding-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    max-width: fit-content;
    min-width: 290px;
  }

  &__button {
    height: 40px;
    width: 40px;
    transition: all 0.3s ease-in-out;
    background: variables.$info-block-bnt-search-color;
    border: 1px solid variables.$info-block-bnt-search-color;
    box-shadow: variables.$info-block-bnt-search-box-shadow;
    border-radius: 10px;
    padding: 0;
    &_active {
      height: 40px;
      width: 40px;
      padding: 2px 0 0 0;
      border-radius: 10px;
      border: 1px solid rgba(15, 158, 121, 1);
      background: rgba(50, 218, 174, 0.15);
      box-shadow: 0 2px 0 0 rgba(15, 158, 121, 0.2);
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        border: 1px solid rgba(15, 158, 121, 1);
        background: rgba(50, 218, 174, 0.15) !important;
        box-shadow: 0 2px 0 0 rgba(15, 158, 121, 0.2);
      }
    }
    &:hover {
      cursor: pointer;
      border: 1px solid variables.$btn-info-search-bg-active;
      background: variables.$btn-info-search-bg-active;
      box-shadow: variables.$btn-info-search-box-shadow;
    }
  }
}

.top-of-year-card__background {
  position: absolute;
  top: -5px;
  left: -10px;
  width: 100%;
  height: 117%;
  z-index: 1;
  background-repeat: no-repeat;
  background-image: url('@/assets/img/TopOfYearCardShape.svg');
}
</style>
