<script lang="ts" setup>
import { reactive, ref, watch } from 'vue'
import {
  Modal,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  FormInstance,
  Select,
  Tooltip,
} from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'

interface IProps {
  open: boolean
  priorityPay: string
  priorityDelivery: string
  priorityAddressId?: number
  addressOffers: any
  canDeliver: boolean
}
interface IForm {
  delivery: string
  payment: string
  addressId: number | undefined
}

const props = defineProps<IProps>()
const emit = defineEmits(['close', 'continue'])
const formRef = ref<FormInstance>()
const options = ref()
const modalForm = reactive<IForm>({
  delivery: '',
  payment: '',
  addressId: undefined,
})

const makeOptions = async () => {
  options.value = []

  if (!props?.addressOffers?.length) return

  props?.addressOffers?.forEach((address) => {
    options?.value.push({
      value: address.id,
      label: address.street,
    })
  })
}

const checkDelivery = async (_rule: Rule, value: string) => {
  if (!value) return Promise.reject('Выберите способ получения')
  else return Promise.resolve()
}

const checkAddress = async (_rule: Rule, value: string) => {
  if (!value) return Promise.reject('Выберите адрес для доставки')
  else return Promise.resolve()
}

const checkPayment = async (_rule: Rule, value: string) => {
  if (!value) return Promise.reject('Выберите способ получения')
  else return Promise.resolve()
}

const rules: Record<string, Rule[]> = {
  delivery: [{ required: true, validator: checkDelivery, trigger: 'change' }],
  addressId: [{ required: true, validator: checkAddress, trigger: 'change' }],
  payment: [{ required: true, validator: checkPayment, trigger: 'change' }],
}

const continueModal = async () => {
  if (!modalForm.payment || !modalForm.delivery) {
    await formRef.value?.validateFields()
  } else {
    emit('continue', modalForm.delivery, modalForm.payment, modalForm.addressId)
  }
}

const prioritizeOptions = () => {
  if (props.priorityDelivery) modalForm.delivery = props.priorityDelivery
  if (props.priorityPay) modalForm.payment = props.priorityPay
  if (props.priorityAddressId) modalForm.addressId = props.priorityAddressId
}

watch(
  () => [props.addressOffers, props.priorityDelivery, props.priorityPay],
  () => {
    makeOptions()
    prioritizeOptions()
  },
)
</script>

<template>
  <Modal
    class="modal"
    closable
    centered
    title="Оформление заказа"
    :open="open"
    okText="Продолжить оформление"
    @ok="continueModal"
    cancelText="Не оформлять"
    @cancel="emit('close')"
  >
    <Form :model="modalForm" :rules="rules" ref="formRef">
      <FormItem name="delivery" class="form-item">
        <p class="title">Способ получения</p>
        <RadioGroup class="checkboxes" v-model:value="modalForm.delivery">
          <Radio value="Самовывоз">Самовывоз</Radio>
          <Tooltip
            :overlayInnerStyle="{ width: '215px' }"
            v-if="!canDeliver"
            placement="bottom"
          >
            <template #title>Поставщик не осуществляет доставку</template>
            <Radio class="radio-disabled" disabled>
              Доставка от поставщика
            </Radio>
          </Tooltip>
          <Radio v-else value="Доставка фермером">Доставка от поставщика</Radio>
        </RadioGroup>
      </FormItem>

      <FormItem
        v-if="modalForm.delivery == 'Доставка фермером' && canDeliver"
        name="addressId"
        class="form-item"
      >
        <p class="title">Адрес доставки</p>
        <Select
          placeholder="Не указано"
          :options="options"
          v-model:value="modalForm.addressId"
        />
      </FormItem>

      <FormItem name="payment" class="form-item">
        <p class="title">Условия оплаты</p>
        <RadioGroup class="checkboxes" v-model:value="modalForm.payment">
          <Radio value="cash">Наличный расчёт</Radio>
          <Radio value="non_cash">Безналичный расчёт</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
  </Modal>
</template>

<style lang="scss" scoped>
.title {
  margin-bottom: 8px;
  font-weight: 800;
}

.form-item {
  margin-bottom: 16px;
}

.checkboxes {
  display: grid;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 8px;
}

.ant-modal-footer {
  margin-top: 42px !important;
}

.radio-disabled {
  width: 237px;
}

.ant-tooltip {
  transform: translateY(-15px);
}
</style>
