<script setup lang="ts">
import { computed, ref, Ref, watch } from 'vue'
import { Button, Divider, Modal, Space } from 'ant-design-vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { PaperClipOutlined } from '@ant-design/icons-vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { downloadPartnerDocOnClick } from '@/helpers/scripts'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import { UploadDocument } from '@/types/types'
import { PartnerDocumentAPI } from '@/api/PartnerDocumentAPI'
import { OrderAPI } from '@/api/OrderAPI'
import DocumentMiniListItem from '@/components/Document/DocumentMiniListItem.vue'

type TPartnershipStep =
  | 'initial'
  | 'signed_earlier'
  | 'sign_out'
  | 'upload_documents'
  | 'generating_contract'
  | 'generating_contract_error'
  | 'contract_send'

interface IProps {
  open: boolean
  partnershipId: number
  orderId: number
  isFarmerSigned: boolean
}

interface IUploadDocument {
  file_name: string
  file_body: string
  errors: string[]
  extension: string
}

interface ISignedDocument {
  file_path: string
  is_contract: boolean
}
const emit = defineEmits(['update:open', 'onSuccess'])
const props = defineProps<IProps>()
const step: Ref<TPartnershipStep> = ref('initial')
const isLoading = ref(false)
const downloadRef = ref<HTMLAnchorElement>()
const maxSingleSize = 5
const uploadedDocuments = ref<IUploadDocument[]>([])

const validDocumentTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'application/pdf',
  // doc and docx now considered invalid
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const modalHeader: Record<TPartnershipStep, string> = {
  initial: 'Вы уже подписали договор с покупателем?',
  signed_earlier: 'Ожидаем подтверждения партнёрства от покупателя',
  sign_out: 'Покупатель должен подтвердить, что договор уже был подписан',
  upload_documents: 'Прикрепите подписанный договор',
  contract_send: 'Договор отправлен покупателю на подписание',
  generating_contract: '',
  generating_contract_error: '',
}

const cancelBtnText: Record<TPartnershipStep, string> = {
  initial: 'Договор подписан вне системы',
  signed_earlier: '',
  sign_out: '',
  upload_documents: '',
  contract_send: '',
  generating_contract: '',
  generating_contract_error: 'Закрыть',
}

const okBtnText: Record<TPartnershipStep, string> = {
  initial: 'Подписать договор',
  signed_earlier: 'Закрыть',
  sign_out: 'Закрыть',
  upload_documents: 'Отправить договор',
  contract_send: 'Закрыть',
  generating_contract: '',
  generating_contract_error: 'Сгенерировать заново',
}

const closeModal = (success = false) => {
  emit('update:open', false)
  step.value = 'initial'
  if (success) emit('onSuccess')
}
const onCancel = () => {
  switch (step.value) {
    case 'initial':
      signPartnershipOut()
      break
    case 'generating_contract_error':
      closeModal()
      break
    default:
      break
  }
}

const onOK = () => {
  switch (step.value) {
    case 'initial':
      updateOrderStatus()
      emit('onSuccess')
      step.value = 'upload_documents'
      break

    case 'signed_earlier':
      closeModal()
      break

    case 'sign_out':
      closeModal(true)
      break

    case 'generating_contract_error':
      step.value = 'generating_contract'
      break

    case 'upload_documents':
      signPartnership()
      break

    case 'contract_send':
      closeModal(true)
      break
    default:
      break
  }
}

const signPartnershipOut = async () => {
  isLoading.value = true
  await updateOrderStatus()
  await PartnersAPI.sign({
    id: props.partnershipId,
    order_id: props.orderId,
    signed_earlier: 'signed_out',
  }).then(() => {
    isLoading.value = false
    step.value = 'sign_out'
  })
}

const updateOrderStatus = async () => {
  await OrderAPI.updateOrder({
    id: props.orderId,
    status: NewGeneralOrderStatus.ContractUnderSigning,
  })
}
const signPartnership = async () => {
  isLoading.value = true
  const documentPaths: ISignedDocument[] = []
  const respones = uploadedDocuments.value.map(async (doc) => {
    await PartnerDocumentAPI.upload(splitBase64(doc)).then((res) =>
      documentPaths.push({
        file_path: res.path,
        is_contract: true,
      }),
    )
  })

  await Promise.all(respones)
  await PartnersAPI.sign({
    id: props.partnershipId,
    order_id: props.orderId,
    signed_earlier: 'not_signed',
    documents: documentPaths,
  }).then(() => {
    isLoading.value = false
    step.value = 'contract_send'
  })
}

const downloadPartnershipBlank = async () => {
  await PartnersAPI.generateContract(props.partnershipId)
    .then((res) => {
      downloadPartnerDocOnClick(
        res.id,
        {
          file_name: 'Шаблонный договор системы.docx',
          file_body: null,
        },
        downloadRef.value,
      )
      step.value = 'upload_documents'
    })
    .catch((err) => {
      step.value = 'generating_contract_error'
    })
}

watch(step, (newStep) => {
  if (newStep === 'generating_contract') {
    downloadPartnershipBlank()
  }
})

watch(
  () => props.open,
  (newValue) => {
    if (newValue) {
      if (props.isFarmerSigned) {
        step.value = 'signed_earlier'
      }
    }
  },
)
const uploadFile = (file: File, base64: string) => {
  const fileError = [
    isValidFileTypes(file),
    isValidFileSize(+file.size / 1048576),
  ]
  uploadedDocuments.value.push({
    file_name: file.name,
    file_body: base64,
    errors: fileError,
    extension: file.type.split('/')[1],
  })
}

const deleteDocument = (index: number) => {
  uploadedDocuments.value.splice(index, 1)
}

const isValidFileTypes = (file: File | UploadDocument) => {
  return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
}

const isValidFileSize = (size: number) => {
  return size > maxSingleSize ? 'Превышен размер файла' : ''
}

const uploadDocumentsNotEmpty = computed(
  () => uploadedDocuments.value.length > 0,
)

const isOkDisabled = computed(
  () =>
    step.value === 'upload_documents' &&
    (!uploadDocumentsNotEmpty.value ||
      uploadedDocuments.value.some((doc) => doc.errors.join('') !== '')),
)

const splitBase64 = (document: IUploadDocument): IUploadDocument => {
  return {
    ...document,
    file_body: document.file_body.split(',')[1],
  }
}
</script>

<template>
  <Modal
    width="500px"
    :open="open"
    centered
    @cancel="emit('update:open', false)"
    closable
  >
    <template #title v-if="modalHeader[step]">
      <div class="title">
        {{ modalHeader[step] }}
      </div>
    </template>

    <template v-if="step === 'initial'">
      Для продолжения работы над заказом необходимо заключить договор с
      покупателем. Если вы уже заключили договор, выберите пункт «Договор
      подписан вне системы»
    </template>

    <template v-if="step === 'signed_earlier'">
      Вы уже отправили заявку на подтверждение партнёрства в рамках другого
      заказа. Мы уведомим вас, когда можно будет продолжить работу по заказу.
    </template>

    <template v-if="step === 'sign_out'">
      Мы уведомим вас, когда покупатель подтвердит подписание договора. Тогда
      можно будет продолжить работу по заказу.
    </template>

    <template v-if="step === 'upload_documents'">
      <Space :size="8" direction="vertical">
        <span>
          Если у вас нет своего договора, вы можете воспользоваться шаблонным
          договором системы, мы внесём в него необходимые данные, вам останется
          только подписать его.
        </span>
        <Space :size="8">
          <PaperClipOutlined :style="{ color: '#00000073' }" />
          <span class="like-link" @click="step = 'generating_contract'">
            Шаблоный договор системы.docx</span
          >
        </Space>
      </Space>
      <Divider class="divider" />
      <Space :size="12" direction="vertical">
        <span>
          Вы можете загрузить до 20 файлов. Размер одного файла не более 5 Мб.
          Допустимые форматы: .pdf, .gif, .jpg, .jpeg, .jpe, .png.
        </span>
        <div>
          <FileUploader
            @load-file="uploadFile"
            v-if="!uploadDocumentsNotEmpty"
          />
          <template v-if="uploadDocumentsNotEmpty">
            <div class="documents-list-block">
              <div class="documents-list">
                <DocumentMiniListItem
                  v-for="(document, index) in uploadedDocuments"
                  :key="document.file_name"
                  :id="index"
                  :name="document.file_name"
                  :body="document.file_body"
                  :errors="document.errors"
                  @deleteDocument="deleteDocument"
                  :isShow="false"
                />
              </div>
              <FileUploader
                :type="'custom'"
                @load-file="uploadFile"
                v-if="uploadedDocuments.length < 20"
              >
                <a href="#" class="page-card__add-document">
                  <span class="plus">+</span>
                  Добавить документы
                </a>
              </FileUploader>
            </div>
          </template>
        </div>
      </Space>
    </template>

    <template v-if="step === 'generating_contract'">
      <div class="center-wrapper">
        <Space :size="4" direction="vertical">
          <div class="loader-wrapper">
            <FullscreenLoading :type="'secondary'"></FullscreenLoading>
          </div>
          <span class="modal-title"> Генерируем договор </span>
          <span class="modal-description"> Пожалуйста, подождите </span>
        </Space>
      </div>
    </template>

    <template v-if="step === 'generating_contract_error'">
      <div class="center-wrapper">
        <Space :size="4" direction="vertical" align="center">
          <img src="@/assets/img/errorOccured.svg" width="64" height="64" loading="lazy" />
          <span class="modal-title">При генерации произошла ошибка</span>
          <span class="modal-description">
            Пожалуйста, попробуйте ещё раз
          </span>
        </Space>
      </div>
    </template>

    <template v-if="step === 'contract_send'">
      Мы уведомим вас, когда покупатель подпишет договор. Тогда можно будет
      продолжить работу по заказу.
    </template>
    <template #footer>
      <Space :size="8">
        <Button
          @click="onCancel"
          v-if="cancelBtnText[step]"
          :loading="isLoading"
          >{{ cancelBtnText[step] }}</Button
        >
        <Button
          type="primary"
          @click="onOK"
          v-if="okBtnText[step]"
          :disabled="isOkDisabled"
          :loading="isLoading"
          >{{ okBtnText[step] }}</Button
        >
      </Space>
    </template>
    <a ref="downloadRef" />
  </Modal>
</template>

<style scoped lang="scss">
.like-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1677ff;
  cursor: pointer;
  &:hover {
    color: #69b1ff;
  }
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #000000e0;
}
.title {
  width: 400px;
}
.modal-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #000000e0;
}

.loader-wrapper {
  position: relative;
  width: 100%;
  height: 45px;
  transform: scale(0.6);
}
.divider {
  margin: 12px 0;
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.documents-list-block {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .documents-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
</style>
