<script setup lang="ts">
import FarmerBlock from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateFarmerRes/FarmerBlock.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import {computed, onMounted, onUnmounted} from 'vue'
import { IProductAnswer, IRecipient } from '@/types/interfaces'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'

const {
  currentCustomerRequest: { recipients },
} = useCustomerRequestStore()

const sortedRecipients = computed(() => {
  const statuses = {
    refused: 0,
    waiting: 1,
    received: 2,
    answered: 3,
  }

  return JSON.parse(JSON.stringify(recipients)).sort(
    (recipient1: IRecipient, recipient2: IRecipient) =>
      statuses[recipient2?.answer_status] - statuses[recipient1?.answer_status],
  )
})

const zoomLevel = computed(() => window.devicePixelRatio * 100);

function setTableWrapperHeight() {
  const tableWrapper = document.querySelector('.farmer-blocks');
  if (tableWrapper && zoomLevel.value >= 150) {
    tableWrapper.style.height = `${100}%`;
  } else {
    tableWrapper.style.height = `${60}%`;
  }
}

onMounted(() => {
  setTableWrapperHeight();
  window.addEventListener('resize', setTableWrapperHeight);
})

onUnmounted(() => {
  window.removeEventListener('resize', setTableWrapperHeight);
})
</script>

<template>
  <div class="farmer-blocks">
    <FarmerBlock
      v-for="recipient in sortedRecipients"
      :key="recipient.id"
      :recipient="recipient"
    />
  </div>
</template>

<style lang="scss">
.farmer-blocks {

}
</style>
