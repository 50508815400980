import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {AxiosReqType, EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies()

class ProductAntTreeAPIModel {
  async getItems(): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductAntTree,
      method: EgalMethods.GetItems,
      data: {},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getPartnerProducts(): Promise<any> {
    return RequestManager.request({
      AxiosReqType: AxiosReqType.Get,
      model: APIModels.Product,
      method: EgalMethods.getPartnerProducts,
      data: {},
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const ProductAntTreeAPI = new ProductAntTreeAPIModel()
export { ProductAntTreeAPI }
