<script lang="ts" setup>
import { onMounted, ref, onUnmounted } from 'vue'
import { catalogStore } from '@/store/catalogStore'
import CatalogCategoryItem from '@/components/Catalog/CatalogCategoryItem.vue'
import CatalogMenuRight from '@/components/Catalog/CatalogMenuRight.vue'
import { returnToScroll } from '@/helpers/scripts'
import { Button } from 'ant-design-vue'

const useCatalogStore = catalogStore()
const sortedCatalogList = ref([])
const isSorting = ref(true)

onMounted(() => {
  useCatalogStore.getCategoriesCatalog().then(() => {
    const catalogList = useCatalogStore.catalogList
    sortedCatalogList.value = sortCatalog(catalogList)
    isSorting.value = false
  })
})

// Сортировка каталога по алфавиту
const sortCatalog = (catalogList: any) => {
  return catalogList.sort((a, b) => {
    if (a?.name === 'ПРОЧЕЕ') return 1
    if (b?.name === 'ПРОЧЕЕ') return -1
    return a?.name.localeCompare(b?.name)
  })
}

const selectCategory = (id: number) => {
  useCatalogStore.getCategory(id)
}

const closeModal = () => {
  useCatalogStore.isOpen = false
  returnToScroll()
}
onUnmounted(() => {
  document.body.style.position = ''
  window.scrollTo(0, 0)
  document.body.style.top = `0`
})
</script>

<template>
    <div class="catalog-overlay">
      <div class="catalog-menu" v-click-outside="closeModal">
        <div class="catalog-menu-left">
          <CatalogCategoryItem
              :isSorting="isSorting"
              @selectCategory="selectCategory"
              :items="sortedCatalogList"
          />
        </div>
        <div class="catalog-menu-right">
          <CatalogMenuRight
              :current="useCatalogStore.current"
              :items="useCatalogStore.currentCategory"
              :isCategoryFetching="useCatalogStore.isCategoryFetching"
              :isSorting="isSorting"
          />
          <Button class="catalog-menu-right-close" @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
          </Button>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.catalog-enter-active,
.catalog-leave-active {
  transition: all 0.5s ease;
}
.catalog-enter-from,
.catalog-leave-to {
  opacity: 0;
}

.catalog {
  transition: 1s ease-in-out;
  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 72px;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &-menu {
    position: absolute;
    background-color: $base-white;
    height: fit-content;
    width: 90%;
    max-height: 864px;
    box-sizing: border-box;
    left: 0;
    z-index: 10;
    display: flex;
    overflow: hidden;
    border-top: 1px solid $gray-300;
    &-left {
      box-sizing: border-box;
      border-right: 1px solid $gray-300;
      padding: 24px 10px 20px 8px;
      overflow-y: scroll;
      flex: 600px;
      transition: 1s ease-in-out;
    }
    &-right {
      padding: 40px;
      width: 100%;
      position: relative;
      transition: 1s ease-in-out;
    }
    &-right-close {
      cursor: pointer;
      position: absolute;
      top: 35px;
      right: 35px;
    }
  }
}
</style>
