<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue'
import { Roles } from '@/types/enums'
import { useJWTDecode } from '@/composables/useJWTDecode'
import VerificationConfirmStep from '@/components/VerificationSteps/VerificationConfirmStep.vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import {useCookies} from "vue3-cookies";

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

</script>

<template>
  <VerificationConfirmStep />
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.verification__container {
  width: 610px;
  padding: 48px;
  border-radius: 24px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow:
    0 0 1px rgba(12, 26, 75, 0.1),
    0 10px 16px rgba(20, 37, 63, 0.06);
}

.title {
  font-weight: 700;
  font-size: 24px;
  color: $gray-800;
  margin-bottom: 48px;
}

.loader-wrapper {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
</style>
