<script lang="ts" setup>
import CartProductItem from '@/components/Cart/CartProductItem.vue'
import { ref } from 'vue'
import FarmOrderCard from '@/views/Customer/FarmOrderCard.vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import { ICartData, IQuantityError } from '@/types/interfaces'
import { useStore } from '@/store'
import router from '@/router'
import { Button, Col, List, ListItem, Modal, Row, Space } from 'ant-design-vue'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons-vue'
import { declensionProduct } from '@/helpers/scripts'
import { width100 } from '@/model/constants'
import CartProductHeader from '@/components/Cart/CartProductHeader.vue'
import { useCartStore } from '@/store/cartStore'

interface Props {
  cardData: ICartData[]
  notAvailableCartData: ICartData[]
  totalAmount?: number
  quantityError: IQuantityError[]
  collapsedCards: string[]
}

const props = defineProps<Props>()
const emit = defineEmits([
  'check',
  'quantity-update',
  'delete',
  'error',
  'deleteAll',
  'delete-unavailable',
  'collapseCard',
])

const { previousRoute } = useStore()
const cartStore = useCartStore()

const backToPage = () => {
  router.push(previousRoute)
}

const deleteAllModal = ref(false)

const toggleDeleteAllModal = () => {
  deleteAllModal.value = !deleteAllModal.value
}

const deleteItemModal = ref(false)
const deletingItemId = ref<string | number>(0)

const toggleDeleteItemModal = (deletingId: number | string) => {
  deletingItemId.value = deletingId
  deleteItemModal.value = !deleteItemModal.value
}

const deleteCartItem = () => {
  emit('delete', deletingItemId.value)
  deleteItemModal.value = false
}
</script>

<template>
  <div class="first-step">
    <div class="first-step__header">
      <Space :size="8" align="end">
        <CreateHeader
          :title="'Корзина'"
          @button-click="backToPage"
          :is-show-create-chat-button="false"
        />
        <span class="products-count">{{
          `${props.totalAmount} товар${declensionProduct(props.totalAmount)}`
        }}</span>
      </Space>
      <Button type="link" @click="toggleDeleteAllModal" class="delete-all-btn">
        <template #icon><DeleteOutlined /> </template>
        Очистить корзину
      </Button>
    </div>
    <div class="first-step__content">
      <div class="not-available-card-list" v-if="notAvailableCartData.length">
        <div class="not-available-card-list-header">
          <Row :style="width100" justify="space-between">
            <Col>
              <Space :size="8">
                <ExclamationCircleFilled
                  :style="{ color: '#FF4D4F', fontSize: '24px' }"
                />
                <h3>Поставщик удалил товар из своего каталога</h3>
              </Space>
            </Col>

            <Col>
              <Button
                danger
                type="link"
                @click="() => emit('delete-unavailable')"
              >
                <template #icon><DeleteOutlined /> </template>
                Удалить недоступные товары
              </Button>
            </Col>
          </Row>
        </div>
        <div
          v-for="farm in notAvailableCartData"
          :key="farm.id"
          class="unavailable-farmer-card"
        >
          <CartProductHeader :farm="farm" withoutIcon isNotAvailable />
          <List rowKey="id" :data-source="farm.cart_items">
            <template #renderItem="{ item }">
              <ListItem class="list-item">
                <CartProductItem
                  :key="item.id"
                  :cartItem="item"
                  isNotAvailable
                  @toggleDeleteItemModal="toggleDeleteItemModal(farm.id)"
                />
              </ListItem>
            </template>
          </List>
        </div>

        <!--        <div-->
        <!--          class="farmer"-->
        <!--          v-for="item in notAvailableCartData"-->
        <!--          :key="item.name"-->
        <!--        >-->
        <!--          <CartProductItem-->
        <!--            v-for="farm in item.cart_items"-->
        <!--            :key="farm.id"-->
        <!--            :cartItem="farm"-->
        <!--            :withoutInput="true"-->
        <!--            @toggleDeleteItemModal="toggleDeleteItemModal(farm.id)"-->
        <!--            @inputError="(err) => emit('inputError', err)"-->
        <!--          />-->
        <!--        </div>-->
      </div>
      <div class="farm-order-card-list">
        <FarmOrderCard
          v-for="farm in cardData"
          :key="farm.name"
          :farm="farm"
          :quantityError="quantityError"
          @quantity-update="
            (farmId: number, amount: number) =>
              emit('quantity-update', farmId, amount)
          "
          @delete="(id) => emit('delete', id)"
          @collapseCard="(id) => emit('collapseCard', id)"
          :collapsedCards="collapsedCards"
          @toggleDeleteItemModal="toggleDeleteItemModal"
        />
      </div>
    </div>
  </div>
  <Modal
    centered
    title="Очистить всю корзину?"
    okText="Очистить"
    @ok="emit('deleteAll')"
    cancelText="Закрыть"
    v-model:open="deleteAllModal"
    width="440px"
  >
    <p>ㅤ</p>
  </Modal>
  <Modal
    centered
    title="Удалить товар из корзины?"
    okText="Удалить"
    @ok="deleteCartItem"
    cancelText="Закрыть"
    v-model:open="deleteItemModal"
    width="440px"
  >
    <p>ㅤ</p>
  </Modal>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.first-step {
  flex-grow: 1;
  max-width: 66%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: flex-end;
    :deep(.products__header) {
      margin-bottom: 0;
    }
    .products-count {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000000e0;
      height: 24px;
      display: flex;
    }
    :deep(.header) {
      margin-bottom: 0;
    }
    .delete-all-btn {
      color: #00000073;
      &:hover {
        color: #ff7875;
      }
      &:active {
        color: #d9363e;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 12px 12px 0 0;
    border: 1px solid #0000000f;
    overflow-y: auto;
    .list-item {
      width: 100%;
      padding: 24px 16px;
      border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
    }
  }

  //недоступно для заказа
  .not-available-card-list {
    background-color: #fff2e8;
    &-header {
      padding: 16px;
      h3 {
        color: #ff4d4f;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
      }
    }
  }
}
</style>
