<script setup lang="ts">
import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  FormItem,
  Input,
  InputNumber,
  Row,
  Select,
  Textarea,
} from 'ant-design-vue'
import { reactive, ref } from 'vue'
import { Rule } from 'ant-design-vue/es/form'
import dayjs, { Dayjs } from 'dayjs'
import { ICustomer } from '@/types/interfaces'

interface IFormState {
  customer: string
  price: string
  vat: string
  commentary: string
}

interface Props {
  drawerIsOpen: boolean
  productName: string
  measurmentUnitId: string
  customer: ICustomer
}
const props = defineProps<Props>()

const emit = defineEmits(['toggleSpecialPrice', 'createSpecialPrice'])

const commentaryCaption = ref(true)
const formRef = ref<FormInstance>()

const formState = reactive<IFormState>({
  customer: props.customer.name,
  price: '',
  vat: '',
  commentary: '',
})

const disabledDate = (current: Dayjs) => {
  return current && current < dayjs().endOf('day')
}

const handleSave = () => {
  formRef.value?.validateFields().then(() => {
    const payload: any = {
      customer_id: props.customer.id,
      quantity: formState.special_price_volume || null,
      price: formState.price,
      vat_percent: formState.vat,
      comment: formState.commentary,
      expires_after: formState.special_price_date
        ? dayjs(formState.special_price_date).format('YYYY-MM-DD')
        : null,
    }

    emit('createSpecialPrice', payload)
  })
}

const validPrice = async (_rule: Rule, value: string | null) => {
  if (value !== null && Number(value) < 0.01) {
    return Promise.reject('Не меньше 0,01')
  } else if (Number(value) > 1000000) {
    return Promise.reject('Не больше 1 000 000')
  } else {
    return Promise.resolve()
  }
}

const validVAT = async (_rule: Rule, value: string) => {
  if (Number(value) < 0) {
    return Promise.reject('Не меньше 0')
  } else if (Number(value) > 100) {
    return Promise.reject('Не больше 100')
  } else {
    return Promise.resolve()
  }
}

const validCommentary = async (_rule: Rule, value: string) => {
  if (value.length > 1000) {
    commentaryCaption.value = false
    return Promise.reject('Максимум 1000 символов')
  } else {
    commentaryCaption.value = true
    return Promise.resolve()
  }
}
</script>

<template>
  <Drawer
    :open="props.drawerIsOpen"
    :width="500"
    title="Спеццена"
    @close="() => emit('toggleSpecialPrice', false)"
    :headerStyle="{ display: 'block' }"
  >
    <template #extra>
      <div class="drawer__extra">{{ props.productName }}</div>
    </template>

    <Form layout="vertical" :model="formState" ref="formRef">
      <div class="drawer__content">
        <Row>
          <Col span="24">
            <FormItem label="Покупатель" name="customer" :required="true">
              <Select v-model:value="formState.customer" :disabled="true" />
            </FormItem>
          </Col>
          <Row
            :style="{
              width: '100%',
              gap: '16px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            }"
          >
            <Col flex="1">
              <FormItem
                label="Цена с НДС"
                :required="true"
                name="price"
                :rules="[
                  {
                    validator: validPrice,
                    trigger: 'change',
                  },
                  { required: true, message: 'Укажите цену' },
                ]"
              >
                <InputNumber
                  v-model:value="formState.price"
                  :step="0.01"
                  decimalSeparator=","
                  :controls="false"
                  :formatter="
                    (value) =>
                      value ? `${value} ₽/${props.measurmentUnitId}` : ''
                  "
                />
              </FormItem>
            </Col>
            <Col flex="1">
              <FormItem
                label="НДС"
                :required="true"
                name="vat"
                :rules="[
                  {
                    validator: validVAT,
                    trigger: 'change',
                  },
                  { required: true, message: 'Укажите НДС' },
                ]"
              >
                <InputNumber
                  v-model:value="formState.vat"
                  :controls="false"
                  :formatter="(value) => (value ? `${value} %` : '')"
                />
              </FormItem>
            </Col>
          </Row>
        </Row>
        <Row
          :style="{
            display: 'block',
          }"
        >
          <!-- <div class="title-block">
            <span class="title-block__title">Условия спеццены</span>
            <span class="title-block__sub-title"
              >Укажите, когда спеццена прекратит действовать</span
            >
          </div> -->
          <Row
            :style="{
              gap: '16px',
            }"
          >
            <!-- <Col flex="1">
              <FormItem
                label="Объем закупки"
                name="special_price_volume"
                :rules="[
                  {
                    validator: validVolume,
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  v-model:value="formState.special_price_volume"
                  :controls="false"
                  :formatter="
                    (value) =>
                      value ? `${value} ${props.measurmentUnitId}` : ''
                  "
                />
              </FormItem>
            </Col> -->
            <!-- <Col flex="1">
              <FormItem label="Действует до" name="special_price_date">
                <DatePicker
                  v-model:value="formState.special_price_date"
                  :disabled-date="disabledDate"
                />
              </FormItem>
            </Col> -->
            <Col span="24">
              <FormItem
                label="Комментарий"
                name="commentary"
                :rules="[{ validator: validCommentary, trigger: 'change' }]"
                class="form-item__textarea"
              >
                <Textarea
                  v-model:value="formState.commentary"
                  placeholder="Необязательно"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                />
              </FormItem>
              <span v-if="commentaryCaption" class="commentary__caption">
                Максимум 1000 символов
              </span>
            </Col>
          </Row>
        </Row>
      </div>
    </Form>

    <template #footer>
      <div class="drawer__footer">
        <Button :onClick="() => emit('toggleSpecialPrice', false)" size="large">
          Закрыть
        </Button>
        <Button type="primary" size="large" :onClick="handleSave">
          Сохранить
        </Button>
      </div>
    </template>
  </Drawer>
</template>

<style scoped lang="scss">
.drawer__extra {
  width: 300px;
  margin-top: 4px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}
.drawer {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 0;
  }
}

.title-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__title {
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  &__sub-title {
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
  }
}

.commentary__caption {
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}

.form-item__textarea {
  margin-bottom: 0;
}

:deep(.ant-input-number) {
  width: 100%;
}
:deep(.ant-picker) {
  width: 100%;
}
</style>
