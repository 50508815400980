<script lang="ts" setup>
import { computed, onMounted, reactive, ref, toRaw } from 'vue'
import { useCookies } from 'vue3-cookies'
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  FormItem,
  Input,
  notification,
  Row,
  Textarea,
  Upload,
  TypographyTitle,
  TypographyText,
  Flex,
} from 'ant-design-vue'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue'
import { FileType } from 'ant-design-vue/es/upload/interface'
import { FarmerAPI } from '@/api/FarmerAPI'
import { DocumentAPI } from '@/api/DocumentAPI'
import { UserAPI } from '@/api/UserAPI'
import { CustomerAPI } from '@/api/CustomerAPI'
import { AddressAPI } from '@/api/AddressAPI'
import { useAuth } from '@/composables/useAuth'
import { useDocument } from '@/composables/useDocument'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { onlyNumbersPhone, transformPhoneNumber } from '@/helpers/scripts'
import { phoneMask } from '@/types/globalConst'
import { Roles, AddressType, APIModels } from '@/types/enums'
import VerificationAddress from './VerificationAddress.vue'
import { rules } from './VerificationFormValidators'

interface VerificationFirstStep {
  id: string
  tin_file: string
  contact_number: string
  checking_account: string
  description: string
  can_deliver?: boolean
  vat: boolean
  addresses: VerificationFirstStepAddress[]
  upload: FileType
}

export interface VerificationFirstStepAddress {
  type: string
  company_id: string
  name: string
  contact_number: string
  comment: string
  street: string
  coordinates: string
  id?: number
  company_type: string
}

interface Props {
  headUserPhoneNumber: string | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['next', 'prev'])

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()
const { loginIntoService } = useAuth()
const { declensionLitres } = useDocument()
const coreCookie = getCoreCookie()

const accountData = ref()
const isLoader = ref(false)
const userType = ref()
const companyId = ref()
const fileRef = ref()

const formRef = ref<FormInstance>()

const DEFAULT_ADDRESS = {
  id: 0,
  name: '',
  contact_number: '',
  comment: '',
  street: '',
  type:
    getCoreCookie()?.company_type === 'farmer'
      ? AddressType.shipping
      : AddressType.delivery,
  company_id: '',
  company_type: getCoreCookie()?.company_type,
  coordinates: '',
}

const dynamicValidateForm = reactive<VerificationFirstStep>({
  id: '',
  tin_file: '',
  contact_number: props.headUserPhoneNumber || '',
  checking_account: '',
  description: '',
  vat: true,
  can_deliver: false,
  addresses: [DEFAULT_ADDRESS],
  upload: null,
})

const userDataString = computed(() => localStorage.getItem('userData'))

const userData = userDataString.value ? JSON.parse(userDataString.value) : ''

const getUser = async () => {
  UserAPI.getUser(coreCookie?.id)
    .then((res) => {
      localStorage.setItem('user', JSON.stringify(res))
      cookies.set('role', res?.type, '365d', '')
      companyId.value = res.company_id
      userType.value = res.type
    })
    .then(async () => {
      const userDataC = await UserAPI.getUserData(
        companyId.value,
        userType.value !== 'farmer'
          ? APIModels.Customer
          : userType.value === 'farmer'
            ? APIModels.Farmer
            : APIModels.Farmer,
        ['users'],
      )
      localStorage.setItem('userData', JSON.stringify(userDataC))
      userData.value = userDataC
    })
}

const updateCustomer = async () => {
  await CustomerAPI.updateCustomer({
    id: userData.id,
    signatory_powers: true,
  })
}

const updateFarmer = async () => {
  await FarmerAPI.updateFarmer({
    id: userData.id,
    signatory_powers: true,
  })
}

const getID = async () => {
  accountData.value = await UserAPI.getUser(getCoreCookie().id)
  dynamicValidateForm.id = accountData.value.company_id || companyId.value
}

const beforeUpload = (file: FileType) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    fileRef.value = reader.result
  })
  reader.readAsDataURL(file)

  return false
}

const onSubmit = () => {
  formRef.value.validate().then(async () => {
    isLoader.value = true
    if (!localStorage.getItem('logout_token')) {
      await loginIntoService(cookies.get('umt'), 'core')
    }

    try {
      await getUser()
      const file = toRaw(dynamicValidateForm).upload[0]
      const fileExt = file.type.split('/').pop()

      const res = await DocumentAPI.uploadCompanyDocument({
        file_name: file.name,
        file_body: fileRef.value,
        extension: fileExt,
        size: declensionLitres(file.size),
      })

      dynamicValidateForm.tin_file = res?.path

      if (userType.value === 'farmer') {
        await FarmerAPI.updateFarmer({
          id: dynamicValidateForm.id,
          tin_file: dynamicValidateForm.tin_file,
          contact_number: onlyNumbersPhone(dynamicValidateForm.contact_number),
          checking_account: dynamicValidateForm.checking_account,
          description: dynamicValidateForm.description,
          can_deliver: dynamicValidateForm.can_deliver,
          vat: dynamicValidateForm.vat,
        })
        await updateFarmer()
      }
      if (userType.value !== 'farmer') {
        await CustomerAPI.updateCustomer({
          id: dynamicValidateForm.id,
          tin_file: dynamicValidateForm.tin_file,
          contact_number: onlyNumbersPhone(dynamicValidateForm.contact_number),
          checking_account: dynamicValidateForm.checking_account,
          description: dynamicValidateForm.description,
        })
        await updateCustomer()
      }

      await AddressAPI.createMany(
        dynamicValidateForm.addresses.map((item) => ({
          ...item,
          contact_number: onlyNumbersPhone(item.contact_number),
        })),
      )
        .then(async () => {
          await getUser()
          emit('next')
        })
        .finally(() => {
          isLoader.value = false
        })
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при загрузке документа',
        top: '80px',
      })
      isLoader.value = false
    }
  })
}

const addAddress = () => {
  dynamicValidateForm.addresses.push({
    ...DEFAULT_ADDRESS,
  })
}
const removeAddress = (id: number) => {
  dynamicValidateForm.addresses = dynamicValidateForm.addresses.filter(
    (_item, index) => {
      return index !== id
    },
  )
}

const updateStreet = (
  street: string,
  index: number,
  coordinates: [number, number],
) => {
  dynamicValidateForm.addresses[index] = {
    ...dynamicValidateForm.addresses[index],
    street,
    company_id: accountData.value.company_id,
    coordinates: coordinates ? JSON.stringify(coordinates) : null,
  }

  formRef.value.validate([['addresses', index, 'street']])
}

const updateName = (name: string, index: number) => {
  dynamicValidateForm.addresses[index] = {
    ...dynamicValidateForm.addresses[index],
    name,
  }
}

const updateNumber = (number: string, index: number) => {
  dynamicValidateForm.addresses[index] = {
    ...dynamicValidateForm.addresses[index],
    contact_number: number,
  }
}

const updateComment = (comment: string, index: number) => {
  dynamicValidateForm.addresses[index] = {
    ...dynamicValidateForm.addresses[index],
    comment,
  }
}

onMounted(async () => {
  await getID()
})
</script>

<template>
  <Form
    layout="vertical"
    ref="formRef"
    :model="dynamicValidateForm"
    :rules="rules"
  >
    <TypographyTitle :level="5">Общие данные</TypographyTitle>
    <Row :gutter="12">
      <Col :span="12">
        <FormItem
          label="Расчётный счёт организации"
          name="checking_account"
          v-maska="'####################'"
        >
          <Input
            placeholder="Введите расчётный счёт"
            v-model:value="dynamicValidateForm.checking_account"
            :maxlength="20"
            size="large"
          />
        </FormItem>
      </Col>
      <Col :span="12">
        <FormItem
          label="Основной номер для связи"
          name="contact_number"
          v-maska="{
            mask: phoneMask,
            preprocessor: (value: string) => transformPhoneNumber(value),
          }"
        >
          <Input
            placeholder="Введите номер"
            v-model:value="dynamicValidateForm.contact_number"
            :maxlength="16"
            size="large"
          />
        </FormItem>
      </Col>
    </Row>
    <TypographyTitle :level="5">Фактический адрес</TypographyTitle>
    <VerificationAddress
      v-for="(address, index) in dynamicValidateForm.addresses"
      :index="index"
      :address="address"
      :key="address.id"
      @update-street="updateStreet"
      @update-name="updateName"
      @update-number="updateNumber"
      @update-comment="updateComment"
      @remove-address="removeAddress"
    />

    <Button
      @click="addAddress"
      type="link"
      class="address-button"
      v-if="getCoreCookie()?.company_type === Roles.Customer"
    >
      <PlusOutlined />
      Добавить адрес
    </Button>

    <TypographyTitle :level="5">
      <span class="required">*</span> ИНН организации
    </TypographyTitle>
    <TypographyText type="secondary">
      Формат файлов .pdf, .gif, .jpg, .png, .jpeg до 10 Мб
    </TypographyText>
    <FormItem name="upload">
      <Upload
        list-type="picture"
        accept="application/pdf,image/gif,image/jpg,image/png,image/jpeg"
        v-model:file-list="dynamicValidateForm.upload"
        :maxCount="1"
        :before-upload="beforeUpload"
        :is-image-url="() => false"
      >
        <Button type="link" size="large" class="button">
          <UploadOutlined />
          Загрузить ИНН
        </Button>
      </Upload>
    </FormItem>

    <Flex v-if="getCoreCookie()?.company_type === Roles.Farmer" vertical>
      <TypographyTitle :level="5">Дополнительно</TypographyTitle>
      <FormItem name="vat" class="form-item">
        <Checkbox v-model:checked="dynamicValidateForm.vat">
          Налогообложение по НДС
        </Checkbox>
      </FormItem>
      <TypographyText type="secondary">
        Если вы работаете по НДС, то после верификации ставка НДС для созданных
        товаров будет назначена автоматически. Если вы работаете без НДС, то
        после верификации ставка НДС для созданных товаров будет равна 0%
      </TypographyText>
      <FormItem name="can_deliver">
        <Checkbox v-model:checked="dynamicValidateForm.can_deliver">
          Я могу самостоятельно доставлять товары
        </Checkbox>
      </FormItem>
    </Flex>

    <FormItem
      label="Описание профиля"
      name="description"
      help="Максимум 1000 символов"
    >
      <Textarea
        v-model:value="dynamicValidateForm.description"
        placeholder="Опишите, чем занимается ваша организация, какие заказы вас интересуют и другую информацию."
        size="large"
        :autoSize="{
          minRows: 4,
          maxRows: 4,
        }"
      />
    </FormItem>

    <FormItem>
      <Button type="primary" html-type="submit" size="large" @click="onSubmit">
        Продолжить
      </Button>
    </FormItem>
  </Form>
</template>

<style scoped lang="scss">
.label {
  :deep(.ant-form-item-required) {
    width: 100%;
  }
}
.button {
  padding-left: 0;
  font-weight: 500;
}
.form-item {
  margin-bottom: 0px;
}
.address-button {
  padding-left: 0;
  font-weight: 500;
  margin-bottom: 24px;
}
.required {
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif !important;
}

:deep(.ant-select) {
  max-width: 520px;
}
</style>
