<script setup lang="ts">
import { ref, reactive } from 'vue'
import { OneCAPI } from '@/api/OneCAPI'
import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { format, maxString, minString, required } from '@/helpers/validators'
import { togglePasswordEyeIcon } from '@/types/globalConst'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import emitter from '@/emitter'

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['close1CModalEnterEmailAndPassword'])
const outerError = ref('')
let loginForm = reactive({
  name: '',
  password: '',
  passwordRepeat: '',
})
const errors = ref({
  name: '',
  password: '',
  passwordRepeat: '',
})
const isFieldsValid = ref(() => {
  return (
    !!loginForm.password &&
    !!loginForm.name &&
    !!loginForm.passwordRepeat &&
    !errors.value.password &&
    !errors.value.name &&
    !errors.value.passwordRepeat
  )
})
const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const onClick = (ev: { target: any }) => {
  togglePasswordEyeIcon(ev.target.tagName, passwordInput)
}

const closeModal = () => {
  emit('close1CModalEnterEmailAndPassword')
}

const create = () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const companyId = user?.company_id
  if (companyId?.length) {
    OneCAPI.companyCreate({
      id: +companyId,
      login: loginForm.name,
      password: loginForm.password,
      password_confirmation: loginForm.passwordRepeat,
    })
      .then((res) => {
        localStorage.setItem('onec_id', res.id)
        localStorage.setItem('onec_login', res.login)
        closeModal()
      })
      .catch((e) => {
        if (e.errors.id.includes('The id has already been taken.')) {
          console.log('error', e.errors.id[0])
        }
      })
  }
}

emitter.on('clear-login-form', () => {
  loginForm.name = ''
  loginForm.password = ''
  loginForm.passwordRepeat = ''
  outerError.value = ''
  errors.value.name = ''
  errors.value.password = ''
  errors.value.passwordRepeat = ''
  isFieldsValid.value = () => true
})

// TODO: Добавить обязательность полей
// TODO: Добавить валидацию пароля
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">Придумайте имя пользователя и пароль</div>
      <div class="modal__subtitle">Эти данные невозможно будет изменить</div>
    </template>
    <template #body>
      <div class="modal__body">
        <EInput
          v-model.trim="loginForm.name"
          :data="{
            ...inputDataConfig,
            id: 'name',
            label: 'Имя пользователя',
            placeholder: '',
            modelValue: loginForm.name,
            error: outerError,
            validators: [required, maxString(50)],
          }"
          @keyup.enter="() => {}"
          @change:modelValue="() => (outerError = '')"
          @update:modelValue="() => (outerError = '')"
          @error="(err) => (errors.name = err)"
          :style-config="inputStyleConfig"
        />
        <EInput
            class="password ym-record-keys"
          v-model.trim="loginForm.password"
          :data="{
            ...inputDataConfig,
            id: 'password',
            label: 'Пароль',
            placeholder: '',
            modelValue: loginForm.password,
            error: outerError,
            type: passwordInput.type,
            iconRight: passwordInput.iconRight,
            validators: [
              required,
              maxString(24),
              minString(6),
              format(
                /[a-zA-Z]/,
                'Пароль должен содержать минимум одну букву латинского алфавита',
              ),
              format(/\d/, 'Пароль должен содержать минимум одну цифру'),
            ],
          }"
          :style-config="inputStyleConfig"
          @click="onClick"
          @keyup.enter="() => {}"
          @change:modelValue="() => (outerError = '')"
          @update:modelValue="() => (outerError = '')"
          @error="(err) => (errors.password = err)"
        />
        <EInput
          class="password"
          v-model.trim="loginForm.passwordRepeat"
          :data="{
            ...inputDataConfig,
            id: 'passwordRepeat',
            label: 'Повторите пароль',
            placeholder: '',
            modelValue: loginForm.passwordRepeat,
            error: outerError,
            type: passwordInput.type,
            iconRight: passwordInput.iconRight,
            validators: [
              required,
              maxString(24),
              minString(6),
              format(
                /[a-zA-Z]/,
                'Пароль должен содержать минимум одну букву латинского алфавита',
              ),
              format(/\d/, 'Пароль должен содержать минимум одну цифру'),
            ],
          }"
          :style-config="inputStyleConfig"
          @click="onClick"
          @keyup.enter="() => {}"
          @change:modelValue="() => (outerError = '')"
          @update:modelValue="() => (outerError = '')"
          @error="(err) => (errors.passwordRepeat = err)"
        />
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal"> Сбросить данные </Button>
        <Button @click="create" type="primary">Создать</Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }
  &__subtitle {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
    padding-top: 5px;
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>
