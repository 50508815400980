<script setup lang="ts">
import { computed, UnwrapRef, defineProps } from 'vue'
import { Button, Tooltip } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'

interface Props {
  currentStep: number
  thirdFormError: UnwrapRef<boolean>
  secondFormError: UnwrapRef<boolean>
  isShowAddMore: UnwrapRef<boolean>
  thirdFormState: UnwrapRef<any>
}
const props = defineProps<Props>()
const emit = defineEmits([
  'nextStep',
  'prevStep',
  'showRejectModal',
  'handleSelectFarmerModal',
])
const backText = ['Вернуться к требованиям', 'Вернуться к списку товаров']
const nextText = ['Продолжить', 'Продолжить', 'Опубликовать торг на продажу']

const showNextButton = computed(() => {
  if (props.currentStep === 2 && props.thirdFormState.length === 0) {
    return false
  }
  return true
})
</script>

<template>
  <div class="create-request-footer">
    <Button
      danger
      @click="emit('showRejectModal')"
      :class="'custom-danger'"
      type="text"
      size="large"
    >
      <template #icon><CloseOutlined /></template>
      Отменить создание
    </Button>
    <div class="main-block">
      <Button
        v-if="props.currentStep > 0"
        type="link"
        @click="emit('prevStep')"
        size="large"
      >
        {{ backText[props.currentStep - 1] }}
      </Button>
      <Button
        v-if="isShowAddMore"
        type="link"
        @click="emit('handleSelectFarmerModal', true)"
        size="large"
      >
        Добавить участников
      </Button>
      <Tooltip
        :color="'#F16063'"
        placement="topRight"
        :open="props.secondFormError && props.currentStep === 1"
      >
        <template #title>
          <span v-if="secondFormError">Добавьте минимум 1 товар</span>
        </template>
        <Button
          type="primary"
          size="large"
          @click="emit('nextStep')"
          v-if="showNextButton"
        >
          {{ nextText[props.currentStep] }}
        </Button>
      </Tooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.create-request-footer {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  background: white;
  justify-content: space-between;
  border-top: 1px solid $gray-300;
  z-index: 2;
  .custom-danger {
    font-weight: 500;
    background-color: transparent;
  }
}
</style>
