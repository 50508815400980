<script lang="ts" setup>
import {
  IFarmer,
  IFarmerProduct,
  IPartnership,
  IProductCategory,
} from '@/types/interfaces'
import SearchResultItem from '@/components/Home/SearchResultItem.vue'
import ClickedFarmCart from '@/components/Home/ClickedFarmCart.vue'
import CustomerFilters from '@/components/Home/CustomerFilters.vue'
import { Option } from 'ant-design-vue/es/vc-util/Children/toArray'
import { computed, h, onMounted, reactive, ref, watch } from 'vue'
import CustomerSearch from '@/components/Home/CustomerSearch.vue'
import { ProductCategoryAPI } from '@/api/ProductCategoryAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { useUserAuthStore } from '@/store/useUserAuthStore'
import { convertPointToMapFormat } from '@/helpers/scripts'
import { notification, SelectProps } from 'ant-design-vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { coordinatesRegex } from '@/model/constants'
import InfiniteLoading from 'v3-infinite-loading'
import { PartnersAPI } from '@/api/PartnersAPI'
import YaMap from '../YaMap/YaMap.vue'
import { FarmerAPI } from '@/api/FarmerAPI'
import { TMapPoint } from '@/types/types'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
import router from '@/router'
import FilterSearch from '../../assets/img/filter-search.png'

const mainStore = useStore()
const { isVerification } = storeToRefs(mainStore)

const route = useRoute()
const cartStore = useStore()
const userStore = useUserAuthStore()
const userAddresses = computed(() => userStore.addresses)

let searchFilter = ref<any>([])
let filters = reactive<any>([])

const allFarms = ref<IFarmer[]>([])
const selectedFarms = ref<IFarmer[] | null>(null)
const isFetching = ref(true)
const currentPage = ref(1)
const partners = ref<IPartnership[]>([])
const flag = ref(false)
const isFarmsLoading = ref(false)
const childCount = ref(0)
const screenWidth = ref<number>(document.documentElement.clientWidth)
const isOpenFilters = ref(false)
const categoryOptions = ref<SelectProps['options']>([])
const clickedFarmFromMap = ref(null)
const toMain = ref(false)
//Работа фильтров
const selectedCategory = ref(null)
const categoryChildren = ref<any[]>([])
const filterParameters = ref<Record<string, any[]>>({
  single: [],
  binary: [],
  multiple: [],
  string: [],
})
const filterRange = ref({
  price: {
    ge: null,
    le: null,
  },
  quantity: {
    ge: null,
    le: null,
  },
})
const userPointsToDraw = ref([])

window.addEventListener('resize', () => {
  screenWidth.value = document.documentElement.clientWidth
})

// Перенаправление на главную после завершения заказа
cartStore.$subscribe((_, state) => {
  if (toMain.value !== state.toMain) {
    clickedFarmFromMap.value = null
  }
  toMain.value = state.toMain
})

const getFarmsWithFilters = async () => {
  isFetching.value = true
  await FarmerAPI.getFarmersWithCategoriesNew(
    [searchFilter.value],
    ['addresses', 'company_rating'],
  ).then((res) => {
    const uniqFarmers = new Set<string>()
    res.forEach((product) => {
      uniqFarmers.add(JSON.stringify(product))
    })
    allFarms.value = Array.from(uniqFarmers).map((farmer) => JSON.parse(farmer)).filter((el) => el?.is_active === true && el.is_docs_sent === true && el.status !== 'confirmed')
    sortFarms()
    isFetching.value = false
  })
}

const getInputSearch = async () => {
  isFarmsLoading.value = true
  await FarmerAPI.getFarmersWithCategoriesNew(
    [searchFilter.value],
    ['addresses', 'company_rating'],
  ).then((res) => {
    isFarmsLoading.value = false
    let uniqFarmers = new Set<string>()
    res.items?.forEach((product: IFarmerProduct) => {
      uniqFarmers.add(JSON.stringify(product))
    }).filter((el) => el?.is_active === true && el.is_docs_sent === true && el.status !== 'confirmed')
    allFarms.value.push(
      ...Array.from(uniqFarmers).map((farmer) => JSON.parse(farmer)),
    )
  })
}

const getFilteredFarmers = async () => {
  isFetching.value = true
  await FarmerProductAPI.getFarmersWithFilter(filters.value).then((res) => {
    allFarms.value = res?.filter((el) => el?.is_active === true && el.is_docs_sent === true && el.status !== 'confirmed')
    sortFarms()
    isFetching.value = false
  })
}

// Поиск
const search = async (filterSearch: any) => {
  currentPage.value = 1
  flag.value = false
  searchFilter.value = Object.values(filterSearch[0])
  clickedFarmFromMap.value = null
  filterParameters.value = {
    single: [],
    binary: [],
    multiple: [],
  }
  filterRange.value = {
    price: {
      ge: null,
      le: null,
    },
    quantity: {
      ge: null,
      le: null,
    },
  }
  categoryChildren.value = []
  selectedCategory.value = null
  await getFarmsWithFilters()
}

const toggleFilters = () => {
  isOpenFilters.value = !isOpenFilters.value
}

const closeFilters = () => {
  isOpenFilters.value = false
}

const setFilters = async (filter: any) => {
  clickedFarmFromMap.value = null
  toggleFilters()
  flag.value = true
  currentPage.value = 1
  filters.value = filter
  isFetching.value = true
  if (filter) {
    await getFilteredFarmers()
  } else {
    await getAllFarms()
  }
}

const openFarmCard = (farmItem: IFarmer) => {
  if (farmItem?.addresses[0]?.coordinates === null) return
  if (coordinatesRegex.test(farmItem?.addresses[0]?.coordinates)) {
    selectedFarms.value = [farmItem]
  }
  clickedFarmFromMap.value = farmItem
}

// Сортировка списка поставщиков по алфавиту
const sortFarms = () => {
  allFarms.value.filter((el) => el?.is_active === true && el.is_docs_sent === true && el.status !== 'confirmed').sort(function (a, b) {
    const firstFullname = `${a.legal_form} ${a.name}`
    const secondFullname = `${b.legal_form} ${b.name}`
    if (firstFullname > secondFullname) {
      return 1
    }
    if (firstFullname < secondFullname) {
      return -1
    }
    return 0
  })
}

const getAllFarms = async () => {
  allFarms.value = await FarmerAPI.getFarmersWithCategoriesNew(
    [],
    ['addresses', 'company_rating'],
  )
  sortFarms()
  flag.value = false
  isFetching.value = false
}

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const showNotification = async () => {
  const userData = localStorage.getItem('userData') || ''
  const { name, legal_form } = await JSON.parse(userData)
  notification.info({
    message: 'Добро пожаловать',
    description: `Вы успешно зарегистрировались в системе от компании ${legal_form} ${name}`,
    top: '80px',
    style: { width: '420px' },
    icon: h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)' },
    }),
  })
}
const sortByAlphabet = (array: any[]) => {
  return array.sort((a, b) => a.label.localeCompare(b.label))
}

const loadMore = () => {
  currentPage.value += 1
  !flag.value && getInputSearch()
  // else fetchMoreFarms()
}

const handlePointClick = (point: TMapPoint) => {
  const findFarm = allFarms.value.find((farm: IFarmer) => +farm.id === point.id)
  findFarm && openFarmCard(findFarm)
}

const handleCategory = (value: string, option: Option) => {
  if (!value) {
    selectedCategory.value = null
    categoryChildren.value = []
    return
  }
  // @ts-ignore
  selectedCategory.value = { ...option.entity }
  categoryChildren.value = [
    {
      selectedEntity: {
        id: null,
      },
      // @ts-ignore
      id: option.entity.id,
      // @ts-ignore
      options: option.entity.all_children_product_categories.map(
        (item: IProductCategory) => ({
          label: item.name,
          value: item.id,
          entity: item,
        }),
      ),
    },
  ]
  const options = categoryChildren.value[0].options
  if (options.length == 1) {
    const childCategory = options[0]
    handleCategoryChildren(childCategory.value, childCategory, 0)
  }
  childCount.value = options.length
}

const handleCategoryChildren = (
  value: string,
  option: Option,
  index: number,
) => {
  if (!value) {
    categoryChildren.value[index].selectedEntity = {
      id: null,
    }
    categoryChildren.value.splice(index + 1)
    return
  }
  // @ts-ignore
  categoryChildren.value[index].selectedEntity = option.entity
  // @ts-ignore
  if (option.entity.all_children_product_categories?.length) {
    categoryChildren.value[index + 1] = {
      selectedEntity: {
        id: null,
      },
      // @ts-ignore
      id: option.entity.id,
      options: sortByAlphabet(
        // @ts-ignore
        option.entity.all_children_product_categories.map(
          (item: IProductCategory) => ({
            label: item.name,
            value: item.id,
            entity: item,
          }),
        ),
      ),
    }
  } else {
    categoryChildren.value.splice(index + 1)
  }
}

const setFilterParameters = (value: Record<string, any[]>) => {
  filterParameters.value = value
}

const dropFilters = () => {
  filterParameters.value = {
    single: [],
    binary: [],
    multiple: [],
  }
  filterRange.value = {
    price: {
      ge: null,
      le: null,
    },
    quantity: {
      ge: null,
      le: null,
    },
  }
  categoryChildren.value = []
  selectedCategory.value = null
  setFilters(null)
}
const handleRangeFilter = (value: string, type: string, direction: string) => {
  filterRange.value[type][direction] = value
}

const makeProductFilter = () => {
  const secondFilterPart: string[] = []
  Object.values(filterParameters.value)
    .flat()
    .forEach((parameter) => {
      if (parameter.disabled) return
      switch (parameter.type) {
        case 'binary':
          parameter.value && secondFilterPart.push(parameter.name)
          break
        case 'single':
          parameter.value && secondFilterPart.push(parameter.value)
          break
        case 'multiple':
          parameter.value?.length &&
            secondFilterPart.push(parameter.value.join('|'))
          break
        default:
          break
      }
    })
  const firstFilterPart = [
    selectedCategory.value.name,
    ...categoryChildren.value.map((item: any) => item.selectedEntity.name),
  ].join(' ')
  return {
    category: firstFilterPart,
    raw_parameters: secondFilterPart.join('|'),
  }
}

const makeRangeFilter = () => {
  const addFilter = (filter: any) => {
    if (filter) {
      return filter
    } else {
      return null
    }
  }

  return {
    count: [
      addFilter(filterRange.value?.price?.ge),
      addFilter(filterRange.value?.price?.le),
    ],
    price: [
      addFilter(filterRange.value?.quantity?.ge),
      addFilter(filterRange.value?.quantity?.le),
    ],
  }
}
const applyFilters = async () => {
  if (!selectedCategory.value) {
    isFetching.value = true
    clickedFarmFromMap.value = null
    toggleFilters()
    flag.value = true
    currentPage.value = 1
    await getAllFarms()
    return
  }
  const productPart = makeProductFilter()
  const rangePart = makeRangeFilter()
  const finalFilter = Object.assign(productPart, rangePart)
  await setFilters(finalFilter)
}

const toggleShowUserAddresses = () => {
  if (userPointsToDraw.value.length) {
    userPointsToDraw.value = []
    return
  }
  if (!userAddresses.value.length) return
  userPointsToDraw.value = convertPointToMapFormat(userAddresses.value).filter(
    (point) => !!point?.coordinates,
  )
}

const pointsToDraw = computed(() => {
  const points = selectedFarms.value || allFarms.value
  return convertPointToMapFormat(
    points?.filter(
      (farm) =>
        farm?.addresses[0]?.coordinates &&
        coordinatesRegex.test(farm?.addresses[0]?.coordinates),
    ),
  )
})

watch(
  () => [allFarms.value.length, route.query.farmerId],
  () => {
    if (allFarms.value.length && route.query.farmerId) {
      const farmerId = route.query.farmerId
      router.push({ path: route.path })

      const neededFarmer = allFarms.value.find(
        (farmer) => +farmer.id === +farmerId,
      )

      openFarmCard(neededFarmer)
    }
  },
  {
    immediate: true,
  },
)

onMounted(async () => {
  const hash = router.currentRoute.value.hash
  await getAllFarms()
  await ProductCategoryAPI.getItems([]).then((res) => {
    categoryOptions.value = sortByAlphabet(
      res.map((item: IProductCategory) => ({
        label: item.name,
        value: item.id,
        entity: item,
      })),
    )
    const extracted = categoryOptions.value.filter(
      (item) => item.label == 'ПРОЧЕЕ',
    )

    categoryOptions.value = categoryOptions.value.filter(
      (el) => !extracted.includes(el),
    )

    categoryOptions.value.push(...extracted)
  })
  if (hash == '#invite') {
    await showNotification()
    await router.replace('/')
  }
  if (isVerification.value) {
    await getMyPartners()
  }
})
</script>

<template>
  <div class="container">
    <div
      v-if="!clickedFarmFromMap"
      :class="{ 'map-list': true, 'map-list_small': screenWidth <= 1024 }"
    >
      <SearchResultItem
        v-for="item in allFarms"
        :partners="partners"
        :key="item.id"
        :item="item as any"
        @click="openFarmCard(item)"
      />
      <div v-if="allFarms.length === 0" class="map-list__no-results">
        <img alt="filter-search" :src="FilterSearch" loading="lazy" />
        <p class="">
          Мы не нашли подходящих поставщиков, попробуйте изменить фильтры
        </p>
      </div>
      <InfiniteLoading
        :distance="300"
        class="infinite-loading"
        @infinite="loadMore"
      />
      <div v-if="isFarmsLoading" class="farms_loader">
        <star-loader />
      </div>
    </div>
    <div
      v-else
      :class="{ 'map-list': true, 'map-list_small': screenWidth <= 1024 }"
    >
      <ClickedFarmCart
        :farm="clickedFarmFromMap"
        :partners="partners"
        @back="
          () => {
            clickedFarmFromMap = null
            selectedFarms = null
          }
        "
      />
    </div>
    <div class="right-side">
      <CustomerSearch
        :screen-width="screenWidth"
        :is-open-filters="isOpenFilters"
        :is-show-user-points-active="userPointsToDraw.length > 0"
        @search="search"
        @open-filters="toggleFilters"
        @show-addresses="toggleShowUserAddresses"
      />
      <CustomerFilters
        :is-open-filters="isOpenFilters"
        @filter="setFilters"
        @close-filters="closeFilters"
        :categoryOptions="categoryOptions"
        :selected-category="selectedCategory"
        @set-category="handleCategory"
        :categoryChildrens="categoryChildren"
        @set-category-children="handleCategoryChildren"
        :child-count="childCount"
        :filterParameters="filterParameters"
        @set-filter-parameters="setFilterParameters"
        @drop-filters="dropFilters"
        :filter-range="filterRange"
        @handle-range-filter="handleRangeFilter"
        @apply-filters="applyFilters"
      />
      <YaMap
        :class="!isVerification ? 'blur' : ''"
        class="map"
        height="100%"
        width="100%"
        :points="pointsToDraw"
        :secondary-points="userPointsToDraw"
        :is-interactive="true"
        :on-point-click="handlePointClick"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.blur {
  filter: blur(5px);
  @include no-select();
}

.wrapper {
  margin: 0 -24px 0 -24px !important;
  width: 100vw !important;
}

.container-wrapper {
  height: 100%;
  width: 100vw;
}

.egal-button {
  padding: 8px;
}

.container {
  display: flex;
  width: 100vw;
  padding-left: 20px;
  height: calc(100vh - 73px);

  &--empty {
    @include empty();
  }
}
.farms_loader {
  display: flex;
  justify-content: center;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map-list {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid $gray-300;
  width: 100%;
  max-width: 448px;
  min-width: 448px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &_small {
    max-width: 360px;
    min-width: 360px;
  }

  &__no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d3748;
    width: 100%;
    align-self: center;
  }
}

.search__icon {
  margin-bottom: 0;
  margin-right: 8px;
}

.v-enter-active,
.v-leave-active {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.v-enter-from,
.v-leave-to {
  transform: scaleY(0.5);
  opacity: 0;

  transform-origin: center top;
}

.button-enter-active,
.button-leave-active {
  transition: opacity 0.1s ease;
}

.button-enter-from,
.button-leave-to {
  opacity: 0;
}

.with-postfix {
  :deep(.input-container) {
    input {
      padding-right: 12px;
    }

    .postfix {
      right: 12px;
      top: 14px;
    }
  }
}

.result-card {
  border-bottom: 1px solid $gray-200;

  &:last-child {
    border-bottom: none;
  }
}

.right-side {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
