<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import {ICartItem, IFarmerProduct} from '@/types/interfaces'
import {
  Row,
  Col,
  TypographyTitle as TgTitle,
  Space,
  Button,
  InputNumber,
  Tag,
  Popover,
  FormItem,
  Form,
  FormInstance,
} from 'ant-design-vue'
import {
  declensionDays,
  formatNumber,
  clearPriceWithVat,
  roundNumber,
  getPriceOptionByQuantity,
  inputParser,
  inputFormatter,
} from '@/helpers/scripts'
import RemoveFromCart from '@/components/Icons/RemoveFromCart.vue'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'
import { InfoCircleTwoTone } from '@ant-design/icons-vue'
import { width100 } from '@/model/constants'
import QuantityExceededTooltip from '@/components/Tooltip/QuantityExceededTooltip.vue'
import { useCartStore } from '@/store/cartStore'

interface Props {
  cartItem: ICartItem
  isNotAvailable?: boolean
  isFinal?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['delete', 'quantity-update', 'toggleDeleteItemModal'])

const formRef = ref<FormInstance>()
const farmerProduct = ref(props.cartItem.farmer_product)
const priceFromPriceOption = ref()

const isMinValueChanged = ref(false)
const isMaxValueChanged = ref(false)

const cartStore = useCartStore()

const formState = reactive<{
  productQuantity: number
  quantityValue: number
  quantityError: string
}>({
  productQuantity: 0,
  quantityValue: 0,
  quantityError: '',
})

const saveQuantity = () => {
  formRef.value
    ?.validateFields()
    .then(() => {
      const value = roundNumber(formState.productQuantity)
      if (value > +farmerProduct?.value?.quantity) {
        isMaxValueChanged.value = true
      }

      cartStore.removeError(props.cartItem.id, ['quantity'])
      emit('quantity-update', +props.cartItem.id, value)
    })
    .catch((error) => {
      if (error.errorFields[0].name.includes('quantity')) {
        cartStore.addItemError(props.cartItem.id, ['quantity'])
      }
    })
}

const specialPrice = computed(() => {
  return farmerProduct?.value?.specialPrice
})

const priceOption = computed(() => {
  return getPriceOptionByQuantity(
    farmerProduct?.value?.priceOptions, +formState.productQuantity)
})

const cartSum = computed(() => {
  if (farmerProduct.value.specialPrice?.price) {
    return formatNumber(
      roundNumber(
        +formState.productQuantity * +farmerProduct.value.specialPrice?.price,
      ),
    )
  } else {
    if (farmerProduct.value?.price || priceFromPriceOption.value?.price) {
      return formatNumber(
        roundNumber(
          +formState.productQuantity *
            Number(
              +priceFromPriceOption.value?.price || farmerProduct.value?.price,
            ),
        ),
      )
    }
    return formatNumber(0)
  }
})

const calVat = computed(() => {
  if (!farmerProduct.value.specialPrice?.price) {
    return formatNumber(
      roundNumber(
        +formState.productQuantity *
          Number(
            +priceFromPriceOption.value?.price || +farmerProduct.value?.price,
          ),
      ),
    )
  } else
    return formatNumber(
      roundNumber(
        +formState.productQuantity *
          clearPriceWithVat(
            Number(farmerProduct.value.specialPrice?.price),
            farmerProduct.value?.vat_percent,
          ),
      ),
    )
})

const productUnit = computed(() =>
  fillMeasurementUnitIdCommon(
    farmerProduct.value?.fullName,
    farmerProduct.value?.measurement_unit_id,
  ),
)

const validateQuantity = async () => {
  if (
    Number(formState.productQuantity) < Number(farmerProduct?.value?.min_value)
  ) {
    return Promise.reject()
  }
  return Promise.resolve()
}

watch(priceOption, () => {
  priceFromPriceOption.value = priceOption?.value
})

watch(
  () => props.cartItem.quantity,
  (newQuantity) => {
    if (!formState.productQuantity) {
      formState.productQuantity =
        newQuantity ||
        +priceFromPriceOption.value?.min_value ||
        +farmerProduct?.value?.min_value
    }
  },
  { immediate: true },
)

watch(
  () => formState.productQuantity,
  () => {
    if (formState.productQuantity < +farmerProduct?.value?.min_value) {
      isMinValueChanged.value = true
    } else {
      isMinValueChanged.value = false
    }
    if (formState.productQuantity > +farmerProduct?.value?.quantity) {
      isMaxValueChanged.value = true
    } else {
      isMaxValueChanged.value = false
    }
  },
  { immediate: true },
)

onUnmounted(() => {
  cartStore.removeItemError(props.cartItem.id)
})
</script>

<template>
  <Row :gutter="[24, 24]" class="cart-product-item" :wrap="false">
    <!--    // TODO: Не удалять для дебага-->
       <!-- {{+priceFromPriceOption?.price}} {{+farmerProduct?.price}} {{farmerProduct?.specialPrice?.price}} -->
    <Col :flex="'120px'">
      <img
        :src="farmerProduct.image_url"
        alt="img"
        v-if="farmerProduct.image_url"
        width="100"
        height="100"
        class="product-image"
        loading="lazy"
      />
      <div class="product-cover-block" v-else />
    </Col>
    <Col :flex="'58%'">
      <TgTitle :level="4">
        {{ cartItem.farmer_product.fullName }}
      </TgTitle>
      <Space :size="12" direction="vertical" class="product-characteristics">
        <div
          class="product-characteristics-item"
          :class="{ red: isMinValueChanged }"
        >
          <span class="characteristic-name">Мин. заказ</span
          ><span>{{ `${farmerProduct.min_value} ${productUnit}` }}</span>
        </div>
        <div
          class="product-characteristics-item"
          :class="{ orange: isMaxValueChanged }"
        >
          <span class="characteristic-name">В наличии</span>
          <span class="characteristic-value">
            <span>
              {{
                `${farmerProduct.quantity} ${
                    fillMeasurementUnitIdCommon(
                        (cartItem.farmer_product as IFarmerProduct)?.fullName ?? '',
                        farmerProduct.measurement_unit_id ?? null,
                    )
}`
              }}
            </span>
            <QuantityExceededTooltip :show="isMaxValueChanged" />
          </span>
        </div>
        <div class="product-characteristics-item">
          <span class="characteristic-name">Время подготовки</span
          ><span>{{
            `${farmerProduct.prepare_days} ${declensionDays(farmerProduct.prepare_days)}`
          }}</span>
        </div>
      </Space>
    </Col>
    <Col :flex="'auto'" class="product-price-block">
      <Row :gutter="isNotAvailable || isFinal ? [0, 8] : [0, 24]">
        <Col :span="24">
          <Row
            :gutter="[11]"
            justify="space-between"
            v-if="!isNotAvailable"
            align="bottom"
          >
            <Col>
              <h5 v-if="!isFinal">Сумма</h5>
            </Col>
            <Col>
              <h4>
                {{ `${cartSum} ₽` }}
              </h4>
            </Col>
          </Row>
          <h5 v-else-if="farmerProduct?.is_deleted">Поставщик удалил товар</h5>
          <h5 v-else-if="!farmerProduct?.quantity">Нет в наличии</h5>
        </Col>
        <Col :span="24">
          <Space :size="4" direction="vertical" v-if="!isFinal">
            <span class="cart-product-price-item" v-if="isNotAvailable">
              {{ `Объём ${formState.productQuantity} ${productUnit}` }}
            </span>
            <Space :size="8" v-else>
              <Form :model="formState" ref="formRef">
                <FormItem
                  name="quantity"
                  :rules="[
                    {
                      validator: validateQuantity,
                      trigger: 'change',
                    },
                  ]"
                >
                  <InputNumber
                    v-model:value="formState.productQuantity"
                    :step="1"
                    decimal-separator=","
                    :formatter="
                      (value, info) =>
                        inputFormatter(
                          value,
                          info,
                          farmerProduct.measurement_unit_id,
                        )
                    "
                    :parser="
                      (value) =>
                        inputParser(value, farmerProduct.measurement_unit_id)
                    "
                    :precision="
                      farmerProduct.measurement_unit_id === 'шт' ? 0 : 2
                    "
                    :controls="false"
                    :style="width100"
                    @change="saveQuantity"
                    max="1000000"
                    min="0"
                  />
                </FormItem>
                <Button @click.stop="emit('toggleDeleteItemModal')">
                  <template #icon>
                    <RemoveFromCart />
                  </template>
                </Button>
              </Form>
            </Space>

            <template v-if="farmerProduct.specialPrice?.price">
              <Tag color="success" v-if="!isNotAvailable">Спеццена</Tag>
              <span class="cart-product-price-item">
                {{
                  `Спеццена с НДС ${farmerProduct.specialPrice?.price} ₽/${farmerProduct.measurement_unit_id}`
                }}
                <Popover
                  title="Комментарий к спеццене"
                  placement="topLeft"
                  v-if="specialPrice?.comment"
                >
                  <template #content> {{ specialPrice?.comment }}</template>
                  <InfoCircleTwoTone />
                </Popover>
              </span>
              <span class="cart-product-price-item">
                {{ `НДС ${specialPrice.vat_percent}%` }}
              </span>
            </template>
            <template v-else>
              <span class="cart-product-price-item" v-if="!isNotAvailable">
                {{
                  `Цена с НДС ${priceFromPriceOption?.price || farmerProduct?.price} ₽/${productUnit}`
                }}
                <Popover
                  title="Комментарий к цене"
                  placement="topLeft"
                  v-if="priceOption?.comment"
                >
                  <template #content> {{ priceOption?.comment }}</template>
                  <InfoCircleTwoTone />
                </Popover>
              </span>
              <span class="cart-product-price-item">
                {{ `НДС ${farmerProduct.vat_percent}%` }}
              </span>
            </template>
          </Space>
          <Space :size="4" direction="vertical" :style="width100" v-else>
            <p class="cart-product-price-item right">
              {{ `${formState.productQuantity} ${productUnit}` }}
            </p>
          </Space>
        </Col>
      </Row>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.cart-product-item {
  flex-grow: 1;
  .product-image {
    border-radius: 8px;
  }
  .product-cover-block {
    background-color: #f7fafc;
    height: 100px;
    width: 100px;
    background-image: url('@/assets/img/product_cover.png');
    background-repeat: no-repeat;
    background-size: 70px;
    background-position: center center;
    border-radius: 8px;
  }

  .product-price-block {
    border-left: 1px solid #0000000f;
    padding-left: 24px !important;
    .cart-product-price-item {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      &.right {
        text-align: right;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #000000e0;
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #000000e0;
    }
  }
  .product-characteristics {
    margin-top: 6px;
    &-item {
      display: flex;
      gap: 12px;
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #000000e0;
        &.characteristic-name {
          font-weight: 400;
          color: #000000a6;
        }
        &.characteristic-value {
          display: flex;
          gap: 6px;
        }
      }
      &.orange {
        span {
          color: #faad14 !important;
          font-weight: 600;
        }
        color: #faad14 !important;
      }
      &.red {
        span {
          color: #ff4d4f !important;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
