<script lang="ts" setup>
import { Modal } from 'ant-design-vue'
import { IProduct } from '@/types/interfaces'
interface IProps {
  open: boolean
  products: IProduct
}

const props = defineProps<IProps>()
const emit = defineEmits(['close', 'continue'])
</script>

<template>
  <Modal
    class="modal"
    closable
    centered
    title="Оформление заказа"
    :open="open"
    okText="Продолжить оформление"
    @ok="emit('continue')"
    cancelText="Не оформлять"
    @cancel="emit('close')"
  >
    <div class="title">Некоторые товары не будут включены в заказ</div>
    <div class="description">Поставщик удалил их из своего каталога</div>
    <div class="list">
      <ol>
        <li v-for="product in products" :key="product?.id">
          {{ product?.fullName || product?.farmer_product_name }}
        </li>
      </ol>
    </div>
  </Modal>
</template>

<style lang="scss">
.modal {
  max-height: 500px;
}

.list {
  margin-top: 8pxт;
  max-height: 354px;
  overflow-y: scroll;

  ol {
    margin-left: 20px;
    margin-bottom: 24px;
    display: grid;
    gap: 12px;
    list-style: decimal;
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

.description {
  transform: translateY(-10px);
  color: #00000073;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}
</style>
