import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies()

class PriceOptionAPIModel {
  async createMany(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceOption,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceOption,
      method: EgalMethods.GetItems,
      data: { pagination: { per_page: perPage, page: page }, withs, order: [['created_at', 'desc']], filter},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async delete(ids: number[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceOption,
      method: EgalMethods.deleteMany,
      data: { ids },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const PriceOptionAPI = new PriceOptionAPIModel()
export { PriceOptionAPI }
