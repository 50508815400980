<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import {
  IAnswerPriceOption,
  IAnswerSpecialPrice,
  IProductRequest,
} from '@/types/interfaces'
import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  FormItem,
  Input,
  InputNumber,
  Popover,
  Table,
  Tag,
  Tooltip,
} from 'ant-design-vue'

import {
  CloseOutlined,
  InfoCircleOutlined,
  StarFilled,
  UndoOutlined,
} from '@ant-design/icons-vue'
import { Rule } from 'ant-design-vue/es/form'
import CellDeletedRow from '@/views/Farmer/productResponse/answerPriceOptionsTable/CellDeletedRow.vue'
import dayjs from 'dayjs'
import { ColumnsType } from 'ant-design-vue/es/table'
import { priceWithoutVat } from '@/helpers/scripts'

interface Props {
  answerPriceOptions: (IAnswerPriceOption | IAnswerSpecialPrice)[]
  measurmentUnitId: string
  productRequest: IProductRequest
  isEditedAnswerOptions: boolean
  productQuantity: string
}
const props = defineProps<Props>()

const emit = defineEmits([
  'editPriceOption',
  'removePriceOption',
  'changeIsEditedAnswerOptions',
])

const formRef = ref<FormInstance>()

defineExpose({ tableFormRef: formRef })

const columns: ColumnsType = [
  {
    title: 'Цена без НДС',
    dataIndex: 'price_without_vat',
    width: '15%',
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price_with_vat',
    width: '20%',
  },
  {
    dataIndex: 'volume',
    width: '15%',
  },
  {
    dataIndex: 'remaining_shelf_life',
    width: '15%',
  },
  {
    title: 'Итого',
    dataIndex: 'total',
    width: '10%',
  },
  {
    title: '',
    dataIndex: 'delete-product',
    width: '5%',
  },
]

if (props.productRequest.is_sample_needed) {
  columns.splice(
    4,
    0,
    {
      dataIndex: 'is_sample_needed',
      colSpan: 2,
      width: 48,
    },
    {
      title: `Пробный образец ${props.productRequest?.needed_samples_number ? '' : 'Любой объем'}`,
      dataIndex: 'samples_number',
      colSpan: 0,
      width: '15%',
    },
  )
}

const getPriceInterval = (
  minimalVolume: string,
  nextMinimalVolume: string | undefined,
  measurmentUnitId: string,
  isSpecialPrice: boolean,
) => {
  return isSpecialPrice
    ? `на ${minimalVolume} ${measurmentUnitId}`
    : (nextMinimalVolume
        ? `${minimalVolume}-${nextMinimalVolume}`
        : `от ${minimalVolume}`) + ` ${measurmentUnitId}`
}

const handleCheckboxChange = (checkedEvent: boolean, index: number) => {
  emit('editPriceOption', 'is_sample_needed', checkedEvent, index)

  const updateObject: any = {
    id: props.answerPriceOptions[index].id,
    is_sample_needed: checkedEvent,
  }

  if (!checkedEvent) {
    updateObject.samples_number = null
  }

  formRef.value?.validateFields([['options', index, 'samples_number']])
}

const handleBlurInput = (propName: keyof IAnswerPriceOption, index: number) => {
  if (formRef.value) {
    formRef.value.validateFields([['options', index, propName]])
  }
}

const handleDeleteClick = (index: number) => {
  emit(
    'editPriceOption',
    'status',
    !props.answerPriceOptions[index].status,
    index,
  )
}

const validAmount = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
) => {
  if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (Number(value) < 0.01) {
      return Promise.reject('Не меньше 0,01')
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (/[.,]/.test(value)) {
      return Promise.reject('Введите целое число')
    } else if (Number(value) < 1) {
      return Promise.reject('Не меньше 1')
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else {
      return Promise.resolve()
    }
  }
}

const validShelfLife = async (_rule: Rule, value: string) => {
  if (value === '' || value === null) {
    return Promise.resolve()
  } else if (Number(value) < 1 || Number(value) > 100) {
    return Promise.reject('Не меньше 1% и не больше 100%')
  } else {
    return Promise.resolve()
  }
}

const validSamplesNumber = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
  isSampleNeeded: boolean,
) => {
  if (!isSampleNeeded) {
    return Promise.resolve()
  }

  if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '' || value === null) {
      return Promise.resolve()
    } else if (Number(value) < 0.01 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 0,01 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '' || value === null) {
      return Promise.resolve()
    } else if (/[.,]/.test(value)) {
      return Promise.reject('Введите целое число')
    } else if (Number(value) < 1 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 1 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  }
}

const getRowClassName = (record: IAnswerPriceOption, index: number) => {
  return checkIncludeIntervalVolume(
    index,
    Number(props.productRequest.customer_needed_volume),
  )
    ? 'editable-row green-row'
    : 'editable-row'
}

const checkIncludeIntervalVolume = (index: number, volume: number) => {
  if (!props.answerPriceOptions[index]?.minimal_volume) {
    return volume === Number(props.answerPriceOptions[index]?.special_volume)
  }
  const firstVolume = Number(props.answerPriceOptions[index]?.minimal_volume)
  if (
    props.answerPriceOptions.length > index + 1 &&
    props.answerPriceOptions[index + 1]?.minimal_volume
  ) {
    const secondVolume = Number(
      props.answerPriceOptions[index + 1]?.minimal_volume,
    )

    return volume >= firstVolume && volume <= secondVolume
  } else {
    return volume >= firstVolume
  }
}

const volumeIndex = ref<undefined | number>(undefined)

const handleEnter = (index: number) => {
  volumeIndex.value = index
}
const resetEnter = () => {
  volumeIndex.value = undefined
}

const isLastAvailablePrice = computed(() => {
  return props.answerPriceOptions.filter((option) => option.status).length === 1
})

onMounted(() => {
  formRef.value?.validate()
})
</script>

<template>
  <div v-if="props.answerPriceOptions.length > 0" class="table__wrapper">
    <Form ref="formRef" :model="{ options: props.answerPriceOptions }">
      <Table
        bordered
        :data-source="props.answerPriceOptions"
        :columns="columns"
        :row-class-name="getRowClassName"
        :pagination="false"
      >
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'volume'">
            <div class="header-cell">
              <span>Объем</span>
              <span class="header-cell__value">{{
                `${props.productRequest.customer_needed_volume} ${measurmentUnitId}`
              }}</span>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'remaining_shelf_life'">
            <div class="header-cell">
              <span>Остаточный срок хранения</span>
              <span
                class="header-cell__value"
                v-if="props.productRequest.remaining_shelf_life"
                >{{ `${props.productRequest.remaining_shelf_life}%` }}</span
              >
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'is_sample_needed'">
            <div class="header-cell">
              <span>Пробный образец</span>
              <span
                v-if="props.productRequest.needed_samples_number"
                class="header-cell__value"
                >{{
                  `${!!props.productRequest?.needed_samples_number ? props.productRequest?.needed_samples_number : 'Любой объем'} ${props.productRequest?.needed_samples_number ? measurmentUnitId : ''}`
                }}</span
              >
            </div>
          </template>
        </template>
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="column.dataIndex === 'price_without_vat'">
            <div
              class="table-body__price-cell"
              :class="{ 'table-body__price-cell_deleted': !record.status }"
            >
              <Popover
                v-if="!!record.comment"
                placement="topLeft"
                class="comment-popover"
              >
                <template #content>
                  <div class="commentary-block">
                    <span class="commentary-block__title">Комментарий</span>
                    <span>{{ record.comment }}</span>
                  </div>
                </template>
                <InfoCircleOutlined
                  :style="{
                    color: 'rgba(22, 119, 255, 1)',
                  }"
                />
              </Popover>
              <span>
                {{
                  `${priceWithoutVat(Number(record.price_per_unit), record.vat_percent)} ₽/${props.measurmentUnitId}`
                }}
              </span>
              <span
                v-if="record.minimal_volume || record.special_volume"
                class="subtitle"
              >
                {{
                  getPriceInterval(
                    record.minimal_volume || record.special_volume,
                    props.answerPriceOptions[index + 1]?.minimal_volume,
                    props.measurmentUnitId,
                    !!record.customer_id,
                  )
                }}
              </span>
              <div v-if="record.customer_id" class="special-price">
                <span v-if="record.expiration_date" class="date">
                  до {{ dayjs(record.expiration_date).format('DD.MM.YYYY') }}
                </span>
                <Tag color="success">
                  <template #icon>
                    <StarFilled />
                  </template>
                  Спеццена
                </Tag>
              </div>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'price_with_vat'">
            <div
              class="table-body__price-cell"
              :class="{ 'table-body__price-cell_deleted': !record.status }"
            >
              <span>
                {{
                  `${Number(record.price_per_unit).toFixed(2)} ₽/${props.measurmentUnitId}`
                }}
              </span>
              <span class="subtitle">
                {{ `НДС ${record.vat_percent}%` }}
              </span>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'volume'">
            <Tooltip
              @mouseenter="handleEnter(index, record.volume)"
              @mouseleave="resetEnter()"
              v-if="record.status"
              color="rgba(250, 173, 20, 1)"
              placement="bottomLeft"
              :overlayStyle="{
                paddingTop: '0',
                minWidth: '252px',
              }"
              :open="
                volumeIndex === index &&
                Number(props.productQuantity) < record.volume
              "
            >
              <template #title> Остаток не соответствует объёму </template>
              <FormItem
                :name="['options', index, 'volume']"
                :style="{
                  height: '100%',
                  border:
                    Number(props.productQuantity) < record.volume
                      ? '1px solid rgba(250, 173, 20, 1)'
                      : 'none',
                  position: 'relative',
                }"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validAmount(_rule, value, props.measurmentUnitId),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.answerPriceOptions[index].volume"
                  :placeholder="props.measurmentUnitId"
                  :controls="false"
                  :step="0.01"
                  decimalSeparator=","
                  @change="emit('editPriceOption', 'volume', $event, index)"
                  @blur="() => handleBlurInput('volume', index)"
                  string-mode
                  :formatter="
                    (value) =>
                      value ? `${value} ${props.measurmentUnitId}` : ''
                  "
                />
              </FormItem>
            </Tooltip>
            <CellDeletedRow v-else />
          </template>
          <template v-else-if="column.dataIndex === 'remaining_shelf_life'">
            <FormItem
              v-if="record.status"
              :name="['options', index, 'remaining_shelf_life']"
              :style="{ height: '100%' }"
              :rules="[
                {
                  validator: validShelfLife,
                  trigger: 'change',
                },
              ]"
            >
              <InputNumber
                :value="props.answerPriceOptions[index].remaining_shelf_life"
                placeholder="%"
                :controls="false"
                @change="
                  emit('editPriceOption', 'remaining_shelf_life', $event, index)
                "
                @blur="() => handleBlurInput('remaining_shelf_life', index)"
                string-mode
                :formatter="(value) => (value ? `${value + '%'}` : '')"
              />
            </FormItem>
            <CellDeletedRow v-else />
          </template>
          <template v-else-if="column.dataIndex === 'is_sample_needed'">
            <div class="text-align-center">
              <FormItem
                v-if="record.status"
                :name="['options', index, 'is_sample_needed']"
                :style="{ height: '100%' }"
              >
                <Checkbox
                  :checked="props.answerPriceOptions[index].is_sample_needed"
                  @change="
                    ($event) =>
                      handleCheckboxChange($event.target.checked, index)
                  "
                />
              </FormItem>
              <CellDeletedRow v-else />
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'samples_number'">
            <FormItem
              v-if="record.status"
              :name="['options', index, 'samples_number']"
              :style="{ height: '100%' }"
              :rules="[
                {
                  validator: (_rule: Rule, value: string) =>
                    validSamplesNumber(
                      _rule,
                      value,
                      props.measurmentUnitId,
                      record.is_sample_needed,
                    ),
                  trigger: 'change',
                },
              ]"
            >
              <InputNumber
                :value="props.answerPriceOptions[index].samples_number || ''"
                :disabled="!props.answerPriceOptions[index].is_sample_needed"
                :placeholder="props.measurmentUnitId"
                :controls="false"
                @change="
                  emit('editPriceOption', 'samples_number', $event, index)
                "
                @blur="() => handleBlurInput('samples_number', index)"
                string-mode
                :formatter="
                  (value) => (value ? `${value} ${props.measurmentUnitId}` : '')
                "
              />
            </FormItem>
            <CellDeletedRow v-else />
          </template>
          <template v-else-if="column.dataIndex === 'total'">
            <span
              :style="{
                fontWeight: 'bold',
              }"
            >
              <span v-if="record.status">
                {{
                  `${(
                    Number(record.price_per_unit) * Number(record.volume)
                  ).toFixed(2)} ₽`
                }}
              </span>
            </span>
          </template>
          <template v-else>
            <div
              class="action-block"
              :class="{ 'action-block__deleted': !record.status }"
            >
              <Tooltip
                placement="bottomRight"
                title="В ответе должна быть хотя бы одна цена"
                v-if="record.status && isLastAvailablePrice"
                :overlayStyle="{ maxWidth: 'fit-content' }"
              >
                <Button
                  type="text"
                  :disabled="record.status && isLastAvailablePrice"
                >
                  <template #icon>
                    <CloseOutlined :style="{ color: 'rgba(255, 77, 79, 1)' }" />
                  </template>
                </Button>
              </Tooltip>
              <Button
                :onclick="() => handleDeleteClick(index)"
                type="text"
                v-else
              >
                <template #icon>
                  <CloseOutlined
                    v-if="record.status"
                    :style="{ color: 'rgba(255, 77, 79, 1)' }"
                  />
                  <UndoOutlined
                    v-else
                    :style="{ color: 'rgba(22, 119, 255, 1)' }"
                  />
                </template>
              </Button>
            </div>
          </template>
        </template>
      </Table>
    </Form>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.table__wrapper {
  flex-grow: 1;
  height: 100%;
  max-height: 570px;
  margin-bottom: 20px;
  overflow: auto;
  width: 100%;
}

:deep(.green-row) {
  background-color: rgba(246, 255, 237, 1);
}

thead tr .ant-table-cell {
  background: rgba(0, 0, 0, 0.04);
  font-weight: 400;
}

:deep(.ant-tooltip-arrow) {
  top: -20px !important;
}

.header-cell {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;

  &__value {
    font-weight: 600;
  }
}

.action-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__deleted {
    background: rgba(0, 0, 0, 0.04);
  }
}

.body-cell {
  &__deleted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

:deep(.ant-table-cell) {
  background: inherit !important;
}

//:deep(.green-row .ant-table-cell:nth-child(1)) {
//  border-left: 4px solid rgba(115, 209, 61, 1);
//}

//:deep(.ant-table-tbody .ant-table-cell:nth-child(1)),
//:deep(.ant-table-tbody .ant-table-cell:nth-child(2)),
//:deep(.ant-table-tbody .ant-table-cell:nth-child(7)) {
//  background: rgba(0, 0, 0, 0.04) !important;
//}
.table-body {
  &__price-cell {
    display: flex;
    flex-direction: column;
    position: relative;

    .subtitle {
      color: rgba(0, 0, 0, 0.45);
    }

    .date {
      color: rgba(0, 0, 0, 0.45);
    }

    .special-price {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .comment-popover {
      position: absolute;
      top: 0;
      right: 0;
    }

    &_deleted {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.45) !important;
    }
  }
}

.text-align-center {
  text-align: center;
}

.commentary-block {
  display: flex;
  flex-direction: column;
  max-width: 356px;
  &__title {
    font-weight: 600;
  }
}

:deep(
    tr.editable-row .ant-table-cell .ant-form-item .ant-input-number-disabled
  ) {
  background-color: inherit !important;
}

//:deep(.ant-btn:disabled ) {
//  span {
//    color: currentColor !important;
//  }
//}
</style>
