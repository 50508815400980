<script setup lang="ts">

import { Button } from 'ant-design-vue'
import router from '@/router'

interface IProps {
  type: 'faq' | 'table'
}
const emit = defineEmits(['openModalEnterEmailAndPassword'])
const props = defineProps<IProps>()

const openModalEnterEmailAndPassword = () => {
  emit('openModalEnterEmailAndPassword')
}

const saveAndExit = () => {
  router.push('products')
}
</script>

<template>
  <div class="footer">
    <div class="footer__buttons" v-if="props.type === 'faq'">
      <Button type="default" @click="openModalEnterEmailAndPassword">
        Данные для обмена
      </Button>
    </div>
    <div class="footer__buttons" v-if="props.type === 'table'">
      <Button @click="saveAndExit" type="primary">Сохранить и выйти</Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 88px;
  box-shadow: 0 6px 16px 0 #00000014;
  border-top: 1px solid #0000000f;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  &__buttons {
    display: flex;
    padding-right: 20px;
    gap: 10px;
  }
}
</style>
