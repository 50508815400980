<script setup lang="ts"></script>

<template>
  <div class="add-product-footer">
    <slot name="buttons" />
  </div>
</template>

<style scoped lang="scss">
.add-product-footer {
  padding: 24px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: #0000000f 1px solid;
  max-width: 1408px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: white;
}
</style>
