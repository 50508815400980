<script setup lang="ts">
import { TypographyText as TgText } from 'ant-design-vue'
import { EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons-vue'
import { formatPhoneNumber } from '@/helpers/scripts'
import { computed } from 'vue'

interface IProps {
  name: string
  legal_form: string | null
  phone: string | null
  address: string | null
  email: string
}
const props = defineProps<IProps>()
const iconStyle = { color: '#00000073', fontSize: '16px' }
const name = computed(() => {
  if (!props.name) return props.email
  return props.legal_form ? `${props.legal_form} ${props.name}` : props.name
})
</script>

<template>
  <div class="company-short-info">
    <span>{{ name }}</span>
    <div class="company-short-info__address" v-if="phone"
      ><PhoneOutlined :style="iconStyle" /><TgText type="secondary">{{
        formatPhoneNumber(phone)
      }}</TgText></div
    >
    <div v-if="address" class="company-short-info__address"
      ><EnvironmentOutlined :style="iconStyle" /><TgText type="secondary">{{
        address
      }}</TgText></div>
  </div>
</template>

<style scoped lang="scss">
.company-short-info {
  margin-top: 12%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 100%;
  height: 100%;
  &__address {
    display: flex;
    gap: 4px;
    margin-bottom: 10px;
  }
}
</style>
