<script setup lang="ts">
import { onMounted, ref } from 'vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { storeToRefs } from 'pinia'
import { reductionStore } from '@/store/reductionStore'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import ReductionHeader from '@/views/Reductions/components/ReductionHeader/ReductionHeader.vue'
import FarmerReductionTable from '@/views/Reductions/components/ReductionTable/FarmerReductionTable.vue'
import { getUserIdTemporary } from '@/helpers/scripts'
import AboutReductionModal from '@/views/Reductions/components/AboutReductionModal.vue'
import emitter from "@/emitter";

const { reductions, isFetching, isMoreFetching } = storeToRefs(reductionStore())
const isOpenAboutReductionModal = ref(false)

const initFarmerReductions = async () => {
  reductionStore().setDefaultFilters([
    ['is_public', 'eq', 1],
    'OR',
    ['reduction_selected_recipients.farmer_id', 'eq', getUserIdTemporary()],
  ])
  await reductionStore().getReductions()
}

emitter.on('refresh-token', async (e) => {
  await initFarmerReductions()
})

onMounted(async () => {
  await initFarmerReductions()
})

const closeAboutReductionModal = () => {
  isOpenAboutReductionModal.value = false
}

const openAboutReductionModal = () => {
  isOpenAboutReductionModal.value = true
}
</script>

<template>
  <div class="farmer-reductions-view">
    <FullscreenLoading
      :type="'secondary'"
      v-if="isFetching"
      class="initial-loader"
    />
    <template v-else>
      <template v-if="reductions.length">
        <ReductionHeader
          title="Торги на покупку"
          button-text="О торгах на покупку"
          @onBtnClick="openAboutReductionModal"
          :is-list="true"
          :button-type="'default'"
        />
        <FarmerReductionTable
          :table-data="reductions"
          :isMoreFetching="isMoreFetching"
        />
      </template>
      <EmptyView
        v-else
        title="Список торгов на покупку пуст"
        button-text="О торгах на покупку"
        description="Здесь вы можете отслеживать и участвовать в торгах на покупку"
        @onBtnClick="openAboutReductionModal"
        :is-show-button="true"
        :button-type="'default'"
      />
      <AboutReductionModal
        :open="isOpenAboutReductionModal"
        @closeAboutReductionModal="closeAboutReductionModal"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.farmer-reductions-view {
  display: flex;
  flex-direction: column;
  position: relative;
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
