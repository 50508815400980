<script lang="ts" setup>
import { computed } from 'vue'
import { Roles } from '@/types/enums'
import { useJWTDecode } from '@/composables/useJWTDecode'
import FarmerReductionView from '@/views/Farmer/reductions/reduction/FarmerReductionView.vue'
import CustomerReductionView from '@/views/Customer/reductions/CustomerReductionView.vue'
import {useCookies} from "vue3-cookies";
import {useRoute} from "vue-router";

const { getCoreCookie } = useJWTDecode()

const { cookies } = useCookies()
const route = useRoute()

const isFarmer = computed(() => getCoreCookie()?.company_type === Roles.Farmer)

</script>

<template>
  <div class="wrapper">
    <FarmerReductionView v-if="isFarmer" />
    <CustomerReductionView v-else />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 72px - 152px);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
