<script setup lang="ts">
import { Breadcrumb, Button, Modal, Table, Tag, Divider } from 'ant-design-vue'
import { CheckOutlined } from '@ant-design/icons-vue'
import {computed, onMounted, ref, watch} from 'vue'
import { role_type } from '@/helpers/dictionary'
import { UserAPI } from '@/api/UserAPI'
import CompanyShortInfo from '@/components/Company/CompanyShortInfo.vue'
import UserStatusTag from '@/components/User/UserStatusTag.vue'
import { show_notification } from '@/helpers/scripts'
import { ICompanyView, IUser } from '@/types/interfaces'
import { CompanyAPI } from '@/api/CompanyAPI'
import InfiniteLoading from 'v3-infinite-loading'
import dayjs from 'dayjs'
import { DocumentAPI } from '@/api/DocumentAPI'
import {DocumentTypes, Roles} from '@/types/enums'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import EmptyListView from '@/views/EmptyListView.vue'
import router from '@/router'
import UserListFilters from '@/views/Admin/UserListComponents/UserListFilters.vue'
import EmptyForFilter from '@/components/EmptyForFilter/EmptyForFilter.vue'
import {useRoute} from "vue-router";
import {useJWTDecode} from "@/composables/useJWTDecode";
import emitter from "@/emitter";

const BreadCrumbItem = Breadcrumb.Item

const columns = [
  {
    title: '',
    key: 'number',
    width: '48px',
  },
  {
    title: 'Организация',
    key: 'company',
    width: '32%',
  },
  {
    title: 'Роль',
    key: 'role',
    width: '120px',
  },
  {
    title: 'Почта',
    key: 'email',
  },
  {
    title: 'Регистрация',
    key: 'registered_at',
    width: '108px',
  },
  {
    title: 'Статус',
    key: 'status',
    width: '180px',
  },
  {
    title: 'Действия',
    key: 'actions',
    width: '190px',
  },
]

const statusMarks = ref({
  need_verify: 0,
  active: 0,
  confirmed: 0,
  undefined: 0,
})

const is_filtered_users = ref(false)
const is_verify_modal = ref(false)
const is_verifying = ref(false)
const is_more_fetching = ref(false)
const is_all_registered_fetched = ref(false)
const selected_company = ref<null | ICompanyView>(null)
const users_page = ref(0)
const users = ref<ICompanyView[]>([])

const select_company = (company: ICompanyView) => {
  selected_company.value = company
  is_verify_modal.value = true
}

const verify_company = async () => {
  is_verifying.value = true
  await confirm_user_documents().then(() => {
    is_verify_modal.value = false
    is_verifying.value = false
    update_users()
    show_notification('info', 'Компания верифицирована')
  })
}
const { getRoles } = useJWTDecode()
const isSuperUser = computed(() => getRoles()?.[0] === Roles.SuperAdmin)
const isAdmin = computed(() => getRoles()?.[0] === Roles.Admin)

const get_unregistered_users = async (page: number, perPage: number) => {
  const filter = [
    [
      ['role.role_id', 'eq', 'user'],
      'OR',
      ['role.role_id', 'eq', 'user.confirmed'],
    ],
  ]
  if (isAdmin.value || isSuperUser.value) {
    return UserAPI.getUserList(['role'], {page, perPage}, filter).then(
        (res) => {
          users.value.push(...transform_to_company(res.items))
          is_more_fetching.value = false
        },
    )
  }
}
const get_registered_users = async (page: number, perPage: number) => {
  if (isAdmin.value || isSuperUser.value) {
    return CompanyAPI.getItems(page, perPage).then((res) => {
      users.value.push(...res.items)
      if (res.total_count === users.value.length) {
        is_all_registered_fetched.value = true
        users_page.value = 1
        get_unregistered_users(users_page.value, 20)
      } else {
        is_more_fetching.value = false
      }
    })
  }
}

const transform_to_company = (users: IUser[]): ICompanyView[] => {
  const companies: ICompanyView[] = []
  users.forEach((user) => {
    companies.push({
      id: user.id,
      legal_form: null,
      company_name: user?.company_name,
      status: user?.role_id?.includes('confirmed')
        ? 'no_company'
        : 'registration_not_confirmed',
      company_type: user?.company_type,
      company_id: 0,
      email: user.email,
      contact_number: null,
      legal_address: null,
      created_at: user.created_at,
    })
  })
  return companies
}

const get_more_users = async () => {
  is_more_fetching.value = true;
  users_page.value += 1;
  if (isAdmin.value || isSuperUser.value) {
    if (!is_all_registered_fetched.value) {
      await get_registered_users(users_page.value, 20);
    } else {
      await get_unregistered_users(users_page.value, 20);
    }
  }
};

const confirm_user_documents = async () => {
  const docs = await DocumentAPI.getCompanyDocuments(
    [
      ['company_id', 'eq', Number(selected_company.value?.company_id)],
      'AND',
      ['company_type', 'eq', selected_company.value?.company_type],
      'AND',
      ['type', 'ne', DocumentTypes.PAS],
    ],
    100,
    [],
  ).then((res) => {
    return res.items.map((doc) => ({
      id: doc.id,
      is_confirmed: true,
    }))
  })

  await DocumentAPI.updateManyDocuments(docs)
}

const update_users = () => {
  const index = users.value.findIndex(
    (company) => company.id === selected_company.value?.id,
  )
  users.value[index].status = 'active'
  const [updated_company] = users.value.splice(index, 1)
  const first_active = users.value.findIndex(
    (company) => company.status === 'active',
  )
  const last_active = users.value?.findLastIndex(
    (company) => company.status === 'active',
  )

  const active_companies = users.value.slice(first_active, last_active + 1)
  active_companies.push(updated_company)
  active_companies.sort((a, b) => {
    const nameA = a.legal_form + ' ' + a.company_name
    const nameB = b.legal_form + ' ' + b.company_name
    return nameA.localeCompare(nameB)
  })
  users.value.splice(first_active, active_companies.length, ...active_companies)
}

const initial_fetch = ref(false)

const get_first_users = async () => {
  initial_fetch.value = true
  await get_more_users()
  initial_fetch.value = false
}

emitter.on('refresh-token', async (e) => {
  await get_first_users()
})

onMounted(async () => {
  await get_first_users()
})

const getCustomRow = (record: ICompanyView) => {
  let path = '/admin/users/' + record.id
  if (!record.company_id) {
    path += '#user'
  }
  return {
    onClick: () => router.push(path),
    style: { cursor: 'pointer' },
  }
}

const searchFilteredUsers = async (dates: [string, string]) => {
  initial_fetch.value = true
  let dateFilters: any[] = []
  const date0 = dates[0]
  const date1 = dates[1]

  if (date0 && date1) {
    dateFilters = [
      'created_at',
      'createdAtBetween',
      {
        from: date0,
        to: date1,
      },
    ]
  }

  await CompanyAPI.getItems(1, 1000, [dateFilters]).then((res) => {
    users.value = res.items

    let dateFiltersForUsers: any[] = []
    if (date0 && date1) {
      dateFiltersForUsers = [
        'users.created_at',
        'createdAtBetween',
        {
          from: date0,
          to: date1,
        },
      ]
    }

    const filter = [
      [
        ['role.role_id', 'eq', 'user'],
        'OR',
        ['role.role_id', 'eq', 'user.confirmed'],
      ],
        'AND',
      dateFiltersForUsers,
    ]

    UserAPI.getUserList([], { page: 1, perPage: 1000 }, filter).then((res) => {
      users.value.push(...transform_to_company(res.items))
    })
  })

  users_page.value = 0
  is_all_registered_fetched.value = true
  is_filtered_users.value = true

  initial_fetch.value = false
}

const resetFilters = () => {
  users.value = []
  is_filtered_users.value = false
  is_all_registered_fetched.value = false
  searchFilteredUsers.value = []
  get_first_users()
}

const getStatusNumberRow = (index: number) => {
  return index + 1
}

watch(
  () => users.value.length,
  () => {
    statusMarks.value = {
      need_verify: 0,
      active: 0,
      confirmed: 0,
      undefined: 0,
    }
    if (users.value.length) {
      users.value.forEach((user, index, array) => {
        if (index > 0) {
          if (user.status !== array[index - 1].status) {
            statusMarks.value[array[index - 1].status] = index
          }
        }
      })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="header">
    <Breadcrumb>
      <BreadCrumbItem>Список компаний</BreadCrumbItem>
    </Breadcrumb>
  </div>
  <div class="wrapper">
    <UserListFilters
      @search="searchFilteredUsers"
      @resetFilters="resetFilters"
    />
    <Divider style="margin: 16px 0" />
    <div class="box">
      <template v-if="!initial_fetch && users.length">
        <Table
          :columns="columns"
          row-key="id"
          :data-source="users"
          :pagination="false"
          :custom-row="getCustomRow"
          class="table"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'number'">
              {{ getStatusNumberRow(index) }}
            </template>
            <template v-if="column.key === 'company'">
              <CompanyShortInfo
                  :address="record.legal_address"
                  :legal_form="record.legal_form"
                  :name="record.company_name"
                  :phone="record.contact_number"
                  :email="record.email"
              />
            </template>
            <template v-if="column.key === 'role'">
              <Tag v-if="record.company_type">
                {{ role_type[record.company_type] }}
              </Tag>
              <span v-else> - </span>
            </template>
            <template v-if="column.key === 'email'">
              {{ record.email }}
            </template>
            <template v-if="column.key === 'registered_at'">
              {{ dayjs(record.created_at).format('DD.MM.YYYY') }}
            </template>
            <template v-if="column.key === 'status'">
              <UserStatusTag :status="record.status" />
            </template>
            <template v-if="column.key === 'actions'">
              <Button
                v-if="record.status === 'need_verify'"
                @click.stop="select_company(record)"
                type="primary"
              >
                <template #icon>
                  <CheckOutlined />
                </template>
                Верифицировать
              </Button>
            </template>
          </template>
        </Table>
        <div v-if="!is_filtered_users" class="infinite-loading__wrapper">
          <InfiniteLoading
            :distance="300"
            class="infinite-loading"
            @infinite="get_more_users"
          />
        </div>
        <div v-if="is_more_fetching" class="list-loader">
          <FullscreenLoading :type="'secondary'" />
        </div>
      </template>
      <template v-if="initial_fetch">
        <FullscreenLoading :type="'secondary'" />
      </template>
      <EmptyForFilter
        v-if="is_filtered_users && !users.length"
        @resetFilters="resetFilters"
      />
      <EmptyListView
        v-else-if="!initial_fetch && !users.length"
        title="В системе нет зарегистрированных компаний"
        absolute
      />
    </div>
  </div>
  <Modal
    width="440px"
    centered
    v-model:open="is_verify_modal"
    :bodyStyle="{ marginBottom: '24px' }"
    okText="Верифицировать"
    cancelText="Закрыть"
    @ok="verify_company"
    :okButtonProps="{
      loading: is_verifying,
    }"
  >
    <template #title>
      Верифицировать компанию
      {{ `${selected_company?.legal_form} ${selected_company?.company_name}` }}?
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.header {
  width: 100%;
  padding: 16px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}
.table-wrapper {
  padding: 24px;
}
.wrapper {
  height: calc(100vh - 55px);
  padding: 24px;
  overflow: scroll;
  .box {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 8px;
  }
}
.list-loader {
  width: 100%;
  position: relative;
  transform: scale(0.6);
  padding: 30px 0;
}

.infinite-loading {
  &__wrapper {
    height: 20px;
    width: 100%;
  }
}
</style>
