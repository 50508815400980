<script setup lang="ts">
import { Button, Col, FormInstance, Row, Tree, Alert } from 'ant-design-vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { computed, ref, UnwrapRef } from 'vue'
import ExcelTable from '@/components/ExcelTable/ExcelTable.vue'
import { IProductTableItem } from '@/views/Customer/CustomerCreateRequestView.vue'

interface Props {
  formState: { domains: IProductTableItem[] }
  checkedKeys: string[]
  expandedKeys: string[]
  treeData: UnwrapRef<any>
}
const props = defineProps<Props>()
const emit = defineEmits([
  'editSecondForm',
  'removeProduct',
  'addProduct',
  'checkKeys',
  'expandKeys',
])
const secondFormRef = ref<FormInstance>()
defineExpose({
  secondFormRef,
})
const collapsed = ref<boolean>(false)
const height100 = { height: '100%' }

function processNode(node: any, disabledNotSelected: boolean) {
  const newKey = `${node.key}:${node.path ? node.path.join('') : 'end'}`
  const newNode = { ...node, key: newKey }
  if (newNode.children?.length > 0 || newNode.products?.length > 0) {
    newNode.checkable = false
  }
  if (newNode.products?.length > 0) {
    newNode.children = newNode.products.map((product: any) => ({
      key: product.key,
      title: makeTreeTitle(newNode, product),
      measurement_unit_id: product.measurement_unit_id,
      disableCheckbox: disabledNotSelected &&
      !props.checkedKeys.includes(`${product.key}:end`),
    }))
  }
  newNode.children = newNode.children?.map((child: any) => processNode(child, disabledNotSelected))
  return newNode
}

const makeTreeTitle = (node: any, product: any) => {
  if (!product.title.length) return node.path.slice(1).join(', ')
  return (
    node.path.slice(1).join(', ') +
    ', ' +
    product.title
      .map((part: any) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(', ')
  )
}

const isMaxProducts = computed(() => {
  return props.checkedKeys.length >= 100
})

const newTreeData = computed(() => {
  // Обрабатываем узлы и разделяем их на две группы
  const processedNodes = props.treeData.map((node: any) => processNode(node, isMaxProducts.value));
  const otherNodes = processedNodes.filter((node: any) => node.title !== "ПРОЧЕЕ");
  const otherNodesWithOtherTitle = processedNodes.filter((node: any) => node.title === "ПРОЧЕЕ");

  // Объединяем обработанные узлы, помещая узлы "ПРОЧЕЕ" в конец
  return [...otherNodes, ...otherNodesWithOtherTitle];
})

const secondFormEditWrapper = (field: string, value: any, index: number) => {
  emit('editSecondForm', field, value, index)
}

const checkTree = (checkedKeys: string[], e: any) => {
  emit('checkKeys', checkedKeys, e)
}
const expandTree = (expandedKeys: string[]) => {
  emit('expandKeys', expandedKeys)
}
const removeProduct = (key: string) => {
  emit('removeProduct', key)
}
</script>

<template>
  <div class="request-products" :style="height100">
    <Row :wrap="false" :style="height100">
      <Col flex="none" class="sidebar">
        <div class="side-menu" :class="{ collapsed: collapsed }">
          <Row :gutter="[20, 20]">
            <Col :span="24">
              <div class="side-menu__header hidden-content" v-if="!collapsed">
                <span class="title"> Каталог товаров </span>
                <Button @click="collapsed = !collapsed" type="link">
                  Свернуть
                  <template #icon>
                    <LeftOutlined />
                  </template>
                </Button>
              </div>
              <Button
                v-else
                @click="collapsed = !collapsed"
                type="link"
                :class="'uncollapse-btn'"
              >
                <template #icon>
                  <LeftOutlined />
                </template>
              </Button>
            </Col>

            <Col :span="24" :class="'hidden-content'">
              <Alert
                message="Добавлено максимум товаров"
                type="warning"
                show-icon
                v-if="isMaxProducts"
              />
              <Tree
                :expandedKeys="props.expandedKeys"
                :checkedKeys="props.checkedKeys"
                checkable
                :tree-data="newTreeData"
                :selectable="false"
                :show-line="true"
                @check="checkTree"
                @expand="expandTree"
                class="tree-block"
              >
                <template #title="{ title }">
                  {{ title }}
                </template>
              </Tree>
            </Col>
          </Row>
        </div>
      </Col>
      <Col flex="auto" :style="{ overflowY: 'scroll' }">
        <ExcelTable
          :form-state="props.formState"
          @editSecondForm="secondFormEditWrapper"
          @removeProduct="removeProduct"
          ref="secondFormRef"
        />
      </Col>
    </Row>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.request-products {
  .side-menu {
    height: 100%;
    width: 400px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    padding: 20px 32px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span.title {
        @include h5();
        color: $gray-800;
      }
    }
    .hidden-content {
      transform: translateX(0);
      transition: all 0.3s ease;
      opacity: 1;
    }
    &.collapsed {
      width: 80px;
      padding: 20px 16px;
      .hidden-content {
        transform: translateX(-100%);
        opacity: 0;
        max-height: 400px;
        overflow: hidden;
      }
    }

    .uncollapse-btn {
      margin: 0 auto;
      transform: rotate(180deg);
      width: 100%;
    }
    :deep(.ant-tree-switcher-noop .ant-tree-switcher-line-icon) {
      display: none !important;
    }
    :deep(.ant-tree-checkbox) {
      margin-block-start: 0 !important;
    }
  }
  .sidebar {
    border-right: 1px solid $gray-300;
    background: white;
    overflow-y: auto;
  }
}
</style>
