<script setup lang="ts">
import { onMounted } from 'vue'
import router from '@/router'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { storeToRefs } from 'pinia'
import { reductionStore } from '@/store/reductionStore'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import ReductionHeader from '@/views/Reductions/components/ReductionHeader/ReductionHeader.vue'
import ReductionTable from '@/views/Reductions/components/ReductionTable/ReductionTable.vue'
import { getUserIdTemporary } from '@/helpers/scripts'
import emitter from "@/emitter";

const { reductions, isFetching, isMoreFetching } = storeToRefs(reductionStore())

const initCustomerReductions = async () => {
  reductionStore().setDefaultFilters([
    ['customer_id', 'eq', getUserIdTemporary()],
  ])
  await reductionStore().getReductions()
}

emitter.on('refresh-token', async (e) => {
  await initCustomerReductions()
})

onMounted(async () => {
  await initCustomerReductions()
})
</script>

<template>
  <div class="customer-reductions-view">
    <FullscreenLoading
      :type="'secondary'"
      v-if="isFetching"
      class="initial-loader"
    />
    <template v-else>
      <template v-if="reductions.length">
        <ReductionHeader
          title="Мои торги на покупку "
          button-text="Создать торг на покупку"
          @onBtnClick="router.push('/create-reduction')"
          :is-list="true"
          :on-back-action="() => {}"
          :buttonType="'primary'"
        />
        <ReductionTable
          :table-data="reductions"
          :isMoreFetching="isMoreFetching"
        />
      </template>
      <EmptyView
        v-else
        title="Список торгов на покупку пуст"
        button-text="Создать торг на покупку"
        description="Здесь вы можете отслеживать свои торги на покупку"
        :is-show-button="true"
        :buttonType="'primary'"
        :button-type="'primary'"
        @onBtnClick="router.push('/create-reduction')"
      />

      <!--    <NotFoundView-->
      <!--        title="По вашему запросу ничего не найдено"-->
      <!--        button-text="Сбросить фильтры"-->
      <!--        description="Попробуйте применить другие фильтры"-->
      <!--        :button-func="() => {}"-->
      <!--    />-->
    </template>
  </div>
</template>

<style scoped lang="scss">
.customer-reductions-view {
  display: flex;
  flex-direction: column;
  position: relative;
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
