export const renameTabs = (tabName: string) => {
    const tabMap = {
        farmer: 'поставщикам',
        customer: 'покупателям',
    }
    return tabMap[tabName]
}

export const highlightText = (text: string, startIndex: number, endIndex?: number) => {
    const words = text.split(' ')
    endIndex = endIndex === undefined ? words.length - 1 : endIndex
    return words
        .map((word, index) => {
            if (index >= startIndex && index <= endIndex) {
                return `${word}`
            }
            return undefined
        })
        .join(' ')
}
