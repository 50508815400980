<script setup lang="ts">
import { IReduction } from '@/types/interfaces'
import { computed } from 'vue'
import {
  Row,
  Col,
  TypographyTitle as TgTitle,
  TypographyText as TgText,
  Space,
  Empty,
} from 'ant-design-vue'
import dayjs from 'dayjs'
import { declensionDays } from '@/helpers/scripts'
interface IProps {
  reductionData: IReduction | null
}

const props = defineProps<IProps>()
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
const informationList = computed(() => {
  const info = []
  if (!props.reductionData) {
    return info
  }
  props.reductionData.should_be_delivered_in_days &&
    info.push([
      'Срок поставки',
      [
        `${props.reductionData.should_be_delivered_in_days} ${declensionDays(props.reductionData.should_be_delivered_in_days)}`,
      ],
    ])

  props.reductionData.should_be_delivered_at &&
    info.push([
      'Срок поставки',
      [dayjs(props.reductionData.should_be_delivered_at).format('DD.MM.YYYY')],
    ])

  if (
    props.reductionData.is_self_delivery ||
    props.reductionData.is_supplier_delivery
  ) {
    const result = []
    props.reductionData.is_self_delivery && result.push('Самовывоз')
    props.reductionData.is_supplier_delivery &&
      result.push('Доставка от поставщика')
    info.push(['Способ получения', result])
  }

  if (
    props.reductionData.is_pay_cash ||
    props.reductionData.is_pay_non_cash ||
    props.reductionData.is_payment_delayed
  ) {
    const result = []
    props.reductionData.is_pay_cash && result.push('Наличные')
    props.reductionData.is_pay_non_cash && result.push('Безналичными')
    props.reductionData.is_payment_delayed &&
      result.push(
        `Отсрочка по оплате ${props?.reductionData?.payment_delay_days ? `${props?.reductionData?.payment_delay_days} ${declensionDays(props.reductionData?.payment_delay_days)}.` : ''}`,
      )
    info.push(['Условия оплаты', result])
  }

  props.reductionData.is_delivery_excluded &&
    info.push(['Оплата доставки', ['Оплачивается отдельно']])
  props.reductionData.is_delivery_included &&
    info.push(['Оплата доставки', ['Включена в стоимость товара']])

  if (props.reductionData.need_unload || props.reductionData.palletizing_type) {
    const result = []
    props.reductionData.palletizing_type &&
      result.push(
        props.reductionData.palletizing_type === 'euro'
          ? 'Европалеты'
          : 'Палетирование',
      )
    props.reductionData.need_unload && result.push('Разгрузка товаров')
    info.push(['Услуги', result])
  }
  props.reductionData.delivery_address &&
    info.push(['Адрес доставки', [props.reductionData.delivery_address]])

  props.reductionData.comment?.length &&
    info.push(['Комментарии', [props.reductionData.comment]])

  return info
})

const isReductionEmpty = computed(() => {
  return (
    !informationList.value.length &&
    !props.reductionData?.contact_name &&
    !props.reductionData?.contact_phone &&
    !props.reductionData?.contact_post
  )
})
</script>

<template>
  <Row :gutter="[24, 14]" :style="{ alignContent: 'flex-start' }">
    <Col :span="24">
      <TgTitle :level="4">Общая информация</TgTitle>
    </Col>
    <Col :span="24">
      <Row v-if="!isReductionEmpty" :gutter="[24, 24]">
        <Col
          :span="12"
          v-if="
            props.reductionData?.contact_name ||
            props.reductionData?.contact_phone ||
            props.reductionData?.contact_post
          "
        >
        </Col>
        <Col :span="12" v-for="infoItem in informationList" :key="infoItem[0]">
          <Space :size="8" direction="vertical">
            <TgText strong>{{ infoItem[0] }}</TgText>
            <TgText v-for="info in infoItem[1]" :key="info">{{ info }}</TgText>
          </Space>
        </Col>
      </Row>
      <div v-else>
        <Empty
          :image="simpleImage"
          class="empty-view"
          :imageStyle="{
            width: '184px',
            height: '117px',
            margin: '0 auto 24px auto',
          }"
        >
          <template #description>
            <Space direction="vertical" :size="0">
              <TgTitle :level="4"
                >Данные о торге на покупку отсутствуют</TgTitle
              >
              <span class="empty-description">
                Вы не указывали дополнительных требований при создании торга на
                покупку
              </span>
            </Space>
          </template>
        </Empty>
      </div>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
.empty-view {
  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
  :deep(.ant-empty-image svg) {
    width: 184px;
    height: 117px;
  }
}
</style>
