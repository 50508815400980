<script lang="ts" setup>
import {
  Button,
  Select,
  SelectProps,
  Flex,
  Tooltip,
  Spin,
  Empty,
} from 'ant-design-vue'
import { SearchOutlined, EnvironmentOutlined } from '@ant-design/icons-vue'
import { onMounted, ref, watch, defineProps, computed } from 'vue'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { IPromptHelperItem } from '@/types/interfaces'
import { AddressAPI } from '@/api/AddressAPI'
import { useDebounceFn } from '@vueuse/core'
import { IAddress } from '@/types/IAddress'
import { FarmerAPI } from '@/api/FarmerAPI'
import { storeToRefs } from 'pinia'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/store'

interface Props {
  isOpenFilters: boolean
  screenWidth: number
  isShowUserPointsActive?: boolean
}

const simpleEmptyImage = Empty.PRESENTED_IMAGE_SIMPLE

const props = defineProps<Props>()
const emit = defineEmits(['search', 'open-filters', 'show-addresses'])

const searchValue = ref()
const searchFilter = ref<any>([])
const allUserAddresses = ref<IAddress[]>()
const options = ref<SelectProps['options']>([])
const promptHelperItems = ref<IPromptHelperItem[]>([])
const isSearchLoading = ref(false)

const { getCoreCookie } = useJWTDecode()

const { cookies } = useCookies()
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => umt && chat)

const search = (value: string) => {
  const promptHelperObject = promptHelperItems.value.find(
    (item) => item.value === value,
  )

  if (!promptHelperObject) return

  searchFilter.value = [
    {
      field: promptHelperObject.type,
      operator: 'search',
      value: promptHelperObject.id,
    },
  ]

  emit('search', searchFilter.value)
}

const clearInput = () => {
  searchValue.value = null
}
const { isVerification } = storeToRefs(useStore())

const changeInput = async (value: string) => {
  if (value.length > 1) {
    const attributes = {
      query: value,
    }

    isSearchLoading.value = true
    try {
      const resp = await FarmerAPI.getHelpsPrompt(attributes)
      promptHelperItems.value = resp
      if (resp.length > 0) {
        options.value = []
        const uniqTypes: string[] = []

        resp.forEach((item) => {
          if (!uniqTypes.includes(item.type)) {
            uniqTypes.push(item.type)
          }
        })

        uniqTypes.forEach((type) => {
          options.value?.push({
            label: type,
            options: resp
              .filter((el) => el.type === type)
              .map(({ value }) => ({ value, label: value })),
          })
        })
      }
    } catch (error) {
      console.error('Error fetching prompt:', error)
    } finally {
      isSearchLoading.value = false
    }
  }
}

const debInput = useDebounceFn(changeInput, 300)

const clearFilter = () => {
  searchFilter.value = [] as []
}

const isFullBtn = computed(() => props.screenWidth > 1280)

const handleUserAddressesButtonClick = () => {
  emit('show-addresses')
}

watch(
  () => props.isOpenFilters,
  (curr) => {
    if (curr) {
      clearFilter()
      clearInput()
    }
  },
)

watch(
  () => searchValue.value,
  () => {
    if (searchValue.value?.length) {
      clearFilter()
    }
  },
)

const zoomLevel = computed(() => window.devicePixelRatio * 100);

onMounted(async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  if (!currentCompanyId) return
  await AddressAPI.getAddresses(
    currentCompanyId,
    getCoreCookie()?.company_type === 'customer' ? 'customer' : 'farmer',
  ).then((res) => {
    allUserAddresses.value = res.items
  })
})
</script>

<template>
  <div
    v-if="!isAuth || isVerification"
    class="search"
    :class="{ 'with-filters': isOpenFilters }"
  >
    <Flex :gap="12">
      <Select
        v-model:value="searchValue"
        @search="debInput"
        :filter-option="options.length > 0"
        @select="search"
        :options="options"
        style="width: 360px"
        size="large"
        :placeholder="
          isAuth ? 'Поиск по поставщикам или продукции' : 'Поиск по продукции'
        "
        :disabled="isAuth && !isVerification"
        show-search
        :class="{ select_auth: !isAuth }"
      >
        <template #suffixIcon>
          <SearchOutlined class="ant-select-suffix" />
        </template>
        <template #notFoundContent>
          <div class="not-found-container">
            <Spin tip="Поиск..." v-if="isSearchLoading" />
            <Empty
              v-else
              :image="simpleEmptyImage"
              description="Ничего не найдено"
            />
          </div>
        </template>
      </Select>
      <Tooltip :placement="zoomLevel >= 125 ? 'bottom' : undefined" :title="isFullBtn ? undefined : 'Фильтр продукции'">
        <Button
          @click="() => emit('open-filters')"
          :class="{
            btn__filters: true,
            'btn__filters_not-full': !isFullBtn,
            btn__filters_auth: !isAuth,
            btn__filters_auth_open: !isAuth && isOpenFilters,
          }"
          :disabled="isAuth && !isVerification"
          size="large"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-sliders"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"
              />
            </svg>
          </template>
          <span>Фильтр продукции</span>
        </Button>
      </Tooltip>
      <Tooltip :placement="zoomLevel >= 125 ? 'bottom' : undefined" title="Адресa доставки" v-if="isAuth">
        <Button
          :disabled="!isVerification"
          class="btn__address"
          size="large"
          :class="{ btn__address_active: !!isShowUserPointsActive }"
          @click="handleUserAddressesButtonClick"
        >
          <template #icon>
            <EnvironmentOutlined />
          </template>
        </Button>
      </Tooltip>
    </Flex>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.search {
  margin: 20px 0 0 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: all 0.2s ease;
}

.ant-select-suffix {
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
}

.btn__address {
  justify-content: center;

  &_active-default {
    color: $customer-search-filter-btn-active-color-default;
    border-color: $customer-search-filter-btn-active-color-default;
  }

  &_active {
    color: $customer-search-filter-btn-active-color-green;
    border-color: $customer-search-filter-btn-active-color-green;
  }
}

.btn__filters {
  &_not-full {
    padding-left: 11px;
    padding-right: 11px;
    & span {
      display: none;
    }
  }
  &_auth {
    &:hover {
      border-color: rgb(0, 89, 74);
      color: rgb(0, 89, 74);
    }
  }
  &_auth_open {
    border-color: #0f9e79;
    color: #0f9e79;

    &:hover {
      border-color: rgb(0, 89, 74);
      color: rgb(0, 89, 74);
    }
  }
}

.select_auth.ant-select-open > :deep(.ant-select-selector) {
  border-color: #0f9e79 !important;
}

.select_auth > :deep(.ant-select-selector) {
  &:hover {
    border-color: #0f9e79 !important;
  }
}

:deep(.ant-select-selection-placeholder) {
  color: rgba(0, 0, 0, 0.88);
}

:deep(.ant-select-open) {
  .ant-select-selector {
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

:deep(.ant-btn) {
  display: flex;
  align-items: center;
  gap: 8px;
}

.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
</style>
