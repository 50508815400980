<script setup lang="ts"></script>

<template>
  <div class="cell_deleted"></div>
</template>

<style scoped lang="scss">
.cell {
  &_deleted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
</style>
