<script setup lang="ts">
import { Rule } from 'ant-design-vue/es/form'
import {
  Button,
  Drawer,
  Input,
  Radio,
  FormItem,
  Form,
  Empty,
} from 'ant-design-vue'
import {
  computed,
  onMounted,
  reactive,
  ref,
} from 'vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import empty from '@/assets/img/empty-drawer-item.png'
import CModalAlreadyLinked from '../1CModalAlreadyLinked/1CModalAlreadyLinked.vue'
import { OneCAPI } from '@/api/OneCAPI'
import { IFarmerProduct } from '@/types/interfaces'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
import CModalSaveChanges from '../1CModalSaveChanges/1CModalSaveChanges.vue'

interface IProps {
  open: boolean
  selectedProduct: {
    c_id: string
    company_id: number
    id: string
    measurement_unit: {
      id: number
      name: string
      c_id: number
      company_id: number
    }
    measurement_unit_id: number
    name: string
    product_id: number
    remnant: number | string
  }
}

const props = defineProps<IProps>()
const emit = defineEmits(['saveChanges', 'closeDrawer'])

const step = ref(1)
const modalLinkedOpen = ref<boolean>(false)
const modalSaveChangesOpen = ref<boolean>(false)
const isShowModalUpdatePage = ref<boolean>(false)
const updating = ref<boolean>(false)

const nextStep = (value: number) => {
  if (props.selectedProduct?.farmer_product && step.value == 1) {
    modalLinkedOpen.value = true
  }
  step.value = value
}

const openSaveChanges = () => {
  modalSaveChangesOpen.value = true
}

const close1CModalLinked = () => {
  modalLinkedOpen.value = false
  step.value = 1
}

const close1CModalSaveChanges = () => {
  isShowModalUpdatePage.value = false
  step.value = 1
}

const closeDrawer = () => {
  step.value = 1
  updating.value = false
  emit('closeDrawer')
}

const setUpdate = () => {
  updating.value = true
  modalLinkedOpen.value = false
}

interface IProducts {
  id: number
  name: string
  isActive: boolean
  image_url: string
  fullname: string
}

interface ICompare {
  ratio: number
  farmer_product: IFarmerProduct
}

const products = ref<IProducts[]>([])

const getFarmerProducts = () => {
  FarmerProductAPI.getProductsWithoutWrapper([], 1, 100, []).then((res) => {
    products.value = res.items?.map((el: any) => {
      return {
        ...el,
        isActive: false,
      }
    })
  })
}

const selectProduct = (product: any) => {
  products.value.forEach((p: any) => {
    p.isActive = false
    if (p === product) {
      p.isActive = !p.isActive
    }
  })
}

const calcTitle = () => {
  if (step.value === 1) return 'Выберите товар из своего каталога'
  else return 'Настройка товара'
}

const activeProduct = () => {
  return products.value.find((p: any) => p.isActive)
}

interface IFormState {
  value1C: number | null
  valueSystem: number | null
}

const formState = reactive<IFormState>({
  value1C: null,
  valueSystem: null,
})

const validateValue1C = (_rule: Rule, value: number) => {
  if (Number(value) < 1) {
    return Promise.reject('Не меньше 1')
  } else if (Number(value) > 1000000) {
    return Promise.reject('Не больше 1 000 000')
  }
  return Promise.resolve()
}

const rules: Record<string, Rule[]> = {
  value1C: [{ validator: validateValue1C, trigger: 'change' }],
  valueSystem: [{ validator: validateValue1C, trigger: 'change' }],
}

const saveChanges = async () => {
  modalSaveChangesOpen.value = false
  nextStep(1)

  if (props.selectedProduct.farmer_product) {
    await OneCAPI.getCFarmerProduct(props.selectedProduct?.id)
    .then((res) => {
      const productNow : ICompare = {
        ratio: res.ratio,
        farmer_product: res.farmer_product
      }
      const productSelected : ICompare = {
        ratio: props.selectedProduct.ratio,
        farmer_product: props.selectedProduct.farmer_product
      }

      if (JSON.stringify(productNow) != JSON.stringify(productSelected)) {
        isShowModalUpdatePage.value = true
      }
    })
  }

  if (!isShowModalUpdatePage.value) {
    emit(
      'saveChanges',
      {
        farmer_product_id: activeProduct()?.id,
        c_product_id: props.selectedProduct?.product_id,
        c_measurement_unit_name: props.selectedProduct?.measurement_unit?.name,
        c_name: props.selectedProduct?.name,
        ratio: calcRemains.value,
        c_remnant: 1,
      },
      props.selectedProduct?.farmer_product,
      props.selectedProduct?.id
    )
  }
}

const calcRemains = computed(() => {
  return Math.round((props.selectedProduct?.remnant * formState.valueSystem)) || 1
})

onMounted(async () => {
  getFarmerProducts()
})
</script>

<template>
  <CModalSaveChanges
    @save="saveChanges"
    @close1CModalSaveChanges="close1CModalSaveChanges"
    :open="modalSaveChangesOpen"
  />
  <ModalUpdatePage
    description="Данные были изменены другим пользователем"
    v-model:open="isShowModalUpdatePage"
  />
  <CModalAlreadyLinked
    :open="modalLinkedOpen"
    @setUpdate="setUpdate"
    @close1CModalLinked="close1CModalLinked"
  />
  <Drawer
    :open="props.open"
    :width="500"
    :footer-style="{ padding: '24px 32px' }"
    @close="closeDrawer"
    detroyOnClose
    :closable="true"
    :headerStyle="{
      display: 'flex',
      gridTemplateColumns: '6fr 1fr',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      padding: '24px 32px 24px 32px',
      alignItems: 'baseline',
      flex: '0 1',
    }"
  >
    <template #extra>
      <h4 class="drawer__title">{{ calcTitle() }}</h4>
      <div>
        Товар из 1С:
        {{ props.selectedProduct.name }}
      </div>
    </template>
    <div v-if="step === 1" class="cards">
      <div
        class="empty"
        v-if="!products.length"
      >
        <Empty
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
          :image-style="{
            height: '117px',
          }"
          :description="null"
        />
        <h4 class="empty__title">
          В вашем каталоге нет товаров
        </h4>
        <span class="empty__description">
          Добавьте товары в свой каталог,<br />
          чтобы связать их с товарами 1С
        </span>
      </div>
      <div
        v-else
        v-for="product in products"
        :key="product?.id"
        class="card"
        :class="{ card_active: product?.isActive }"
        @click="selectProduct(product)"
      >
        <Radio :checked="product?.isActive" />
        <img
          :src="product?.image_url || empty"
          :alt="product?.fullname"
          class="card__img"
          loading="lazy"
        />
        <div class="card__fullname">
          {{ product?.fullname }}
        </div>
      </div>
    </div>
    <div v-if="step === 2" class="body">
      <div class="body__selected-product">Выбранный товар</div>
      <div class="selected-card">
        <img
          :src="activeProduct()?.image_url || empty"
          :alt="activeProduct()?.fullname"
          class="card__img"
          loading="lazy"
        />
        <div class="card__fullname">
          {{ activeProduct()?.fullname }}
        </div>
      </div>
      <div class="conversion">
        <div class="conversion__title">Укажите значение конвертации</div>
        <div class="conversion__desc">
          Если в 1С ваш товар измеряется в тоннах, а в нашей системе в
          килограммах, <br />
          то вы можете заполнить значение 1С равным «1», а значение системы
          равным <br />
          «1000», чтобы перевести тонны в килограммы.
        </div>
        <Form
          ref="formRef"
          :model="formState"
          :rules="rules"
          class="conversion__inputs inputs"
        >
          <FormItem name="value1C" class="input">
            <div class="input__label">Значение 1С</div>
            <Input
              disabled
              type="number"
              placeholder="1"
              :value="1"
            />
            <!-- v-model:value="formState.value1C" -->
          </FormItem>
          <div class="inputs__between-text">к</div>
          <FormItem name="valueSystem" class="input">
            <div class="input__label">Значение системы</div>
            <Input
              type="number"
              placeholder="1"
              v-model:value="formState.valueSystem"
            />
          </FormItem>
        </Form>
        <div class="conversion__info info">
          <div class="info__item item">
            <div class="item__title">Единица измерения в 1С</div>
            <div class="item__value">
              {{ props.selectedProduct?.measurement_unit?.name }}
            </div>
          </div>
          <div class="info__item item">
            <div class="item__title">Остаток в 1С</div>
            <div class="item__value">
              {{ props.selectedProduct?.remnant || 0 }}
            </div>
          </div>
          <div class="info__item item">
            <div class="item__title">Единица измерения в системе</div>
            <div class="item__value">
              {{ activeProduct()?.product?.measurement_unit_id }}
            </div>
          </div>
          <div class="info__item item">
            <div class="item__title">Остаток в системе</div>
            <div class="item__value"> {{ calcRemains }} {{ activeProduct()?.product?.measurement_unit_id }} </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="drawer__footer footer">
        <div class="footer__btns" v-if="step === 1">
          <Button @click="closeDrawer">Закрыть</Button>
          <Button
            :disabled="!products.length"
            type="primary" @click="() => nextStep(2)"
          >
            Далее
          </Button>
        </div>
        <div class="footer__btns" v-if="step === 2">
          <Button @click="() => nextStep(1)">Назад</Button>
          <Button type="primary" @click="openSaveChanges">Сохранить</Button>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<style scoped lang="scss">
.item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.45);
  }
  &__value {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.88);
  }
}

.empty {
  margin: 40% auto;
  height: 365px;
  width: 306px;
  text-align: center;
  line-height: 22px;

  &__title {
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 14px;
    color: #000000A6;
  }

  :deep(.ant-empty .ant-empty-image svg) {
    height: 117px;
    width: 184px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inputs {
  &__between-text {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.input {
  display: flex;
  flex-direction: column;
  &__label {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-bottom: 7px;
  }
}

.conversion {
  margin-top: 20px;
  &__input {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__inputs {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
  }
  &__title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
  }
  &__desc {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
}

.selected-card {
  height: 96px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: 0.25s;
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
}

.body {
  margin-right: 20px;
  &__selected-product {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.cards {
  margin-left: -23px;
  margin-right: -22px;
  margin-top: -25px;
}

.card {
  height: 96px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: 0.25s;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }

  &__img {
    object-fit: cover;
    height: 64px;
    width: 64px;
    margin-left: 10px;
  }
  &__fullname {
    margin-left: 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  &_active {
    background: rgba(0, 0, 0, 0.04);
  }
}

.drawer {
  &__title {
    height: fit-content;
    margin-top: 4px;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    width: 100%;
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.footer {
  &__btns {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
</style>
