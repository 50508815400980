<script setup lang="ts">
import { Button, Flex, Modal } from 'ant-design-vue'

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits([
  'closeRefuseTheEntireOrderModal',
  'cancelOrderRefuseTheEntireOrderModal',
])
</script>

<template>
  <Modal
    :open="props.open"
    @cancel="emit('closeRefuseTheEntireOrderModal')"
    title="Отказаться от всего заказа?"
    :width="440"
    centered
    closable
  >
    <div class="modal__body">Мы уведомим поставщиков об отмене заказа</div>
    <template #footer>
      <Flex :gap="12" justify="center">
        <Button @click="emit('closeRefuseTheEntireOrderModal')" size="large">
          Закрыть
        </Button>
        <Button
          @click="emit('cancelOrderRefuseTheEntireOrderModal')"
          type="primary"
          size="large"
        >
          Хорошо
        </Button>
      </Flex>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
:deep(.ant-btn) {
  width: 190px;
}
</style>
