<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { computed, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { IRoutLink } from '@/types/interfaces'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import { useCookies } from 'vue3-cookies'
import { useOrderStore } from '@/store/orderStore'
import { useJWTDecode } from '@/composables/useJWTDecode'
import NotificationHistory from '@/components/Notification/NotificationHistory.vue'
import { NotificationAPI } from '@/api/NotificationAPI'
import HeaderProfile from '@/components/Header/HeaderProfile.vue'
import { UserAPI } from '@/api/UserAPI'
import { ProfileStatus, Roles } from '@/types/enums'
import { useStore } from '@/store'
import CustomTooltip from '@/components/Tooltip/CustomTooltip.vue'
import CatalogMenu from '@/components/Catalog/CatalogMenu.vue'
import { catalogStore } from '@/store/catalogStore'
import CartIcon from '@/components/Icons/CartIcon.vue'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import { Button } from 'ant-design-vue'
import {
  getUserId,
  getUserIdTemporary,
  rememberScroll,
  returnToScroll,
} from '@/helpers/scripts'
import MainHeaderLogo from '@/components/Header/MainHeader/components/MainHeaderLogo.vue'
import { chatStore } from '@/store/chatStore'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import { useCentrifuge } from '@/composables/useCentrifuge'
import { storeToRefs } from 'pinia'
import verified from '@/assets/img/profile/profile-verified.svg'
import unverified from '@/assets/img/profile/profile-unverified.svg'
import verifying from '@/assets/img/profile/profile-verifying.svg'
import needToVerify from '@/assets/img/profile/profile-needToVerify.svg'
import ChatDotsFill from './../../../assets/img/chat-dots-fill.svg'
import ChatDotsFillDisabled from './../../../assets/img/chat-dots-fill-disable.svg'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import emitter from '@/emitter'
import LoginIcon from '@/components/Icons/LoginIcon.vue'

interface IProps {
  links: IRoutLink[]
  anchors: IRoutLink[]
  companyId: number | any
  isAuth: boolean
  role: Roles | undefined
  userData: any // TODO: написать интерфейс
  user: any // TODO: написать интерфейс
}

const props = defineProps<IProps>()

const route = useRoute()
const router = useRouter()

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()
const id = getCoreCookie()?.id
const useCatalogStore = catalogStore()
const modalStore = useModalStore()

const mainStore = useStore()
const orderStore = useOrderStore()
const { getHasUnreadChannels, getCustomersIds } = chatStore()

const { setUserRole, setUser, setIsVerification } = mainStore
const { isVerification } = storeToRefs(mainStore)

const hasChatMessagesValue = ref(false)
const isTabNotActive = ref(false)
const isCartTooltip = ref(false)
const isHistoryTooltip = ref(false)
const userStatus = ref<ProfileStatus>()
const userNotifications = ref([] as any)
const isOpenNotifications = ref(false)
const showPopper = ref(false)

const headerProfileStatus = ref()
const userNameWithLegal = ref('')

const umt = computed(() => cookies.get('umt'))
const chat = computed(() => cookies.get('chat'))
const isAuth = computed(() => umt.value && chat.value)

const toRegister = async () => {
  window.location.href = '/register'
}

const toLogin = async () => {
  window.location.href = '/sign-in'
}

const toChat = async () => {
  await router.push('/chat')
}

const getUserNameData = async () => {
  if (id) {
    if (props.role === Roles.Customer) {
      await getCustomersIds(props.companyId)
    }
  }
}

watch(
  () => props.userData,
  () => {
    const { user_need_accept, name, legal_form } = props.userData
    if (user_need_accept !== null) {
      userNameWithLegal.value = `${name}`
    } else {
      userNameWithLegal.value = `${legal_form} ${name}`
    }
  },
)

const setUserStatus = async () => {
  await UserAPI.getUser(id, ['company', 'company.contacts']).then((user) => {
    cookies.set('role', user.type, '365d', '')

    const isNotUploadDocuments = localStorage.getItem('isNotUploadDocuments')

    if (props.role === Roles.Farmer && isNotUploadDocuments === 'true') {
      userStatus.value = ProfileStatus.NeedToVerify
      headerProfileStatus.value = getProfileStatus(ProfileStatus.NeedToVerify)
      setIsVerification(false)
    }

    if (user.company.is_active) {
      userStatus.value = ProfileStatus.Verified
      headerProfileStatus.value = getProfileStatus(ProfileStatus.Verified)
      setIsVerification(true)
    } else if (user.company.user_need_accept !== null) {
      userStatus.value = ProfileStatus.Verifying
      headerProfileStatus.value = getProfileStatus(ProfileStatus.Verifying)
      setIsVerification(false)
    } else if (
      !user.company.is_active &&
      user.company.is_docs_sent &&
      !isNotUploadDocuments
    ) {
      setIsVerification(false)
      userStatus.value = ProfileStatus.Unverified
      headerProfileStatus.value = getProfileStatus(ProfileStatus.Unverified)
    } else if (!user.company.is_docs_sent) {
      setIsVerification(false)
      userStatus.value = ProfileStatus.NeedToVerify
      headerProfileStatus.value = getProfileStatus(ProfileStatus.NeedToVerify)
    }
  })
}

const getProfileStatus = (status: ProfileStatus) => {
  const profileStatusMap = {
    [ProfileStatus.Verified]: {
      status: ProfileStatus.Verified,
      class: 'header-profile__icon',
      alt: 'person',
      src: verified,
      tooltipText: '',
    },
    [ProfileStatus.Unverified]: {
      status: ProfileStatus.Unverified,
      alt: 'person',
      class: 'header-profile__icon',
      src: unverified,
      tooltipText: 'Администратор проверит ваши данные в ближайшее время',
    },
    [ProfileStatus.Verifying]: {
      status: ProfileStatus.Verifying,
      alt: 'person',
      class: 'header-profile__icon',
      src: verifying,
      tooltipText:
        'Мы отправим вам уведомление, как только проверим юридические документы',
    },
    [ProfileStatus.NeedToVerify]: {
      status: ProfileStatus.NeedToVerify,
      alt: 'person',
      class: 'header-profile__icon',
      src: needToVerify,
      tooltipText:
        'Перейдите к верификации аккаунта, чтобы получить полный доступ к платформе',
    },
  }
  return profileStatusMap[status]
}

watch(
  () => chatStore().hasChatMessages,
  (newValue) => {
    hasChatMessagesValue.value = newValue
  },
)

mainStore.$subscribe(async (mutation, state) => {
  if (state.verificationStatus !== 'none') {
    mainStore.setVerificationStatus('full')
    await getUserNameData().then(async () => {
      await setUserStatus()
    })
  }
  if (state.verificationStatus === 'refetch') {
    mainStore.setVerificationStatus('none')
  }
})

const getUserData = async () => {
  setUser({
    id: props.userData.id,
    name: props.userData.legal_form + ' ' + props.userData.name,
  })
}

watch(
  () => props.role,
  async () => {
    if (props.role !== Roles.Admin && props.role !== Roles.SuperAdmin) {
      await getUserNameData()
      await setUserStatus()
      await getUserData()
      setUserRole(props.role)
      await getNotifications()
      await requestNotificationPermission()
      await listenTabVisibility()
      await connectNotifications()

      if (props.role === Roles.Customer) {
        await orderStore.getCartAmountStore()
      }
    }
    gsap.registerPlugin(ScrollToPlugin)
  },
)

onUnmounted(() => {
  removeListeners()
})

const listenTabVisibility = async () => {
  document.addEventListener('visibilitychange', toggleVisibilityChange)
}

const isNotificationGranted = ref(false)

const requestNotificationPermission = async () => {
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      isNotificationGranted.value = permission === 'granted'
    })
  }
}

const toggleVisibilityChange = () => {
  isTabNotActive.value = document.hidden
}

const tooltipToggler = (e: MouseEvent, value: string) => {
  if (!isVerification.value) {
    if (value === 'isHistory') {
      isHistoryTooltip.value = e.type === 'mouseover'
    }
    if (value === 'isCart') {
      isCartTooltip.value = e.type === 'mouseover'
    }
  }
}

const toggleNotifications = () => {
  isOpenNotifications.value = !isOpenNotifications.value
  showPopper.value = false
}

const closeNotifications = () => {
  isOpenNotifications.value = false
}

const getNotifications = async () => {
  if (getCoreCookie()) {
    NotificationAPI.contactGetItems().then((response) => {
      userNotifications.value = response.items
      showBrowserNotification()
    })
  }
}

const showBrowserNotification = () => {
  if (isNotificationGranted.value && isTabNotActive.value) {
    const new_notification = userNotifications.value[0]
    const notification_config = {
      icon: new_notification.icon,
    }
    new_notification.text && (notification_config.body = new_notification.text)
    const notification = new Notification(
      new_notification.title,
      notification_config,
    )
    notification.onclick = function (event) {
      router.push(new_notification.link)
      new_notification.checked = true
      event.preventDefault()
      window.focus()
      notification.close()
      showPopper.value = false
      NotificationAPI.updateNotification({
        id: new_notification.id,
        checked: true,
      })
    }
  }
}

const countUnreadMessages = computed(
  () =>
    userNotifications.value.filter(
      (notify: { checked: boolean }) => !notify.checked,
    ).length,
)

const connectNotifications = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  startCentrifuge()

  if (props.role === Roles.Customer) {
    addChanel(`core@Customer.${getUserId()}`, socketAction)
    addChanel(`core@Customer.${getUserIdTemporary()}`, socketAction)
  }

  if (props.role === Roles.Farmer) {
    addChanel(`core@Farmer.${getUserId()}`, socketAction)
    addChanel(`core@Farmer.${getUserIdTemporary()}`, socketAction)
  }

  addChanel(`chat@User.${getUserId()}`, socketAction)
}

const socketAction = async (socketMessage: any) => {
  if (isVerification.value) {
    await getHasUnreadChannels(getUserId())
  }
  if (socketMessage?.data?.name === 'company_verified') {
    await getUserNameData().then(async () => {
      await setUserStatus()
    })
  } else {
    await getNotifications()
    showPopper.value = true
  }
}
const isOpenedCatalog = computed(() => useCatalogStore.isOpen)

const openModal = () => {
  if (isOpenedCatalog.value) {
    useCatalogStore.isOpen = false
    returnToScroll()
  } else {
    setTimeout(() => {
      rememberScroll()
      useCatalogStore.isOpen = true
    }, 100)
  }
}

const calcLinksClasses = (link: { to: string; label: string }) => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const userId = user?.company_id

  if (userId || !isAuth.value) {
    if (
      (link.label === 'Торги продажа' && route.path === '/create-auction') ||
      (link.label === 'Запросы цен' && route.path === '/create-request') ||
      (link.label === 'Торги покупка' && route.path === '/create-reduction') ||
      (link.to.split('/')[1] ===
        router?.currentRoute.value.path.split('/')[1] &&
        !isOpenedCatalog.value) ||
      (isOpenedCatalog.value && link?.label === 'Каталог')
    ) {
      return 'links-list__item_active'
    }
    if (
      !isVerification.value &&
      [
        'Партнёры',
        'Запросы цен',
        'Торги покупка',
        'Торги продажа',
        'История заказов',
      ].includes(link.label)
    ) {
      return 'disabled-link'
    } else return ''
  } else return ''
}

const calcTo = (link: { to: string; label: string }) => {
  if (link) {
    if (
      !isVerification.value &&
      [
        'Запросы цен',
        'Торги покупка',
        'Торги продажа',
        'Партнёры',
        'История заказов',
      ].includes(link.label)
    ) {
      return ''
    } else {
      return link.to
    }
  }
}

const removeListeners = () => {
  document.removeEventListener('visibilitychange', toggleVisibilityChange)
}

const scrollToElement = () => {
  emitter.emit('scroll-to-section', 3)
}

watch(
  () => userStatus.value,
  () => {
    if (userStatus.value) {
      headerProfileStatus.value = getProfileStatus(userStatus.value)
    }
  },
)

const handleOpenFeedbackForm = () => {
  modalStore.openModal(ModalKeys.FeedbackForm, {
    isClosable: true,
    class: 'verification-modal',
    close: confirm,
  })
}
</script>

<template>
  <div :class="['layout-header', { 'layout-header--no-auth': !isAuth }]">
    <div class="header-wrapper">
      <MainHeaderLogo />
      <div class="links-list">
        <div
          class="links-list__item"
          v-for="anchor in anchors"
          :key="anchor?.label"
          :class="[calcLinksClasses(anchor)]"
          @click="scrollToElement"
        >
          <div>{{ anchor?.label }}</div>
        </div>
        <router-link
          class="links-list__item"
          v-for="link in links?.filter((item) => item?.label !== 'Поставщики')"
          :key="link?.label"
          :class="[calcLinksClasses(link)]"
          :to="calcTo(link)"
        >
          <div v-if="link?.label !== 'Каталог'">{{ link?.label }}</div>
          <div
            v-else
            @click="openModal"
            :class="
              isOpenedCatalog
                ? 'links-list__catalog_active'
                : 'links-list__catalog'
            "
          >
            <BootstrapIcon
              :icon="!isOpenedCatalog ? 'list' : 'x-lg'"
              class="catalog__item"
            />
            {{ link?.label }}
          </div>
        </router-link>
      </div>
      <div class="buttons">
        <div class="actions" v-if="isAuth">
          <div
            class="actions__item cart"
            @click="
              () =>
                [ProfileStatus.Verified]?.includes(userStatus) ? toChat() : {}
            "
          >
            <div v-if="[ProfileStatus.Verified]?.includes(userStatus)">
              <img :src="ChatDotsFill" alt="chat_enable" loading="lazy" />
              <div class="cart-amount" v-if="hasChatMessagesValue" />
            </div>
            <img v-else :src="ChatDotsFillDisabled" alt="chat_disable" loading="lazy" />
          </div>
          <CustomTooltip
            v-if="isAuth"
            :message="'У вас новое уведомление'"
            :showPopper="showPopper"
            :hover="false"
            :type="'primary'"
          >
            <div
              class="actions__item cart"
              @click="toggleNotifications"
              v-click-outside="closeNotifications"
            >
              <div class="notification-icon"></div>
              <div class="cart-amount" v-if="countUnreadMessages > 0">
                {{ countUnreadMessages }}
              </div>
            </div>
          </CustomTooltip>

          <NotificationHistory
            v-if="isOpenNotifications"
            :notifications="userNotifications"
            @close-notifications="closeNotifications"
            @update-notifications="getNotifications"
          />
        </div>

        <div class="actions" v-if="role === Roles.Customer && isAuth">
          <router-link :to="isVerification ? '/cart' : ''">
            <div
              class="actions__item cart"
              @click="toCart"
              v-on:mouseover="(e) => tooltipToggler(e, 'isCart')"
              v-on:mouseleave="(e) => tooltipToggler(e, 'isCart')"
              :class="{ 'disabled-btn': !isVerification }"
            >
              <BlurTooltip>
                <InfoIcon
                  v-if="!isVerification"
                  fill="#F16063"
                  height="20px"
                  type="outlined"
                  width="20px"
                />
                <CartIcon :fill="isVerification ? '#2D3748' : '#A0AEC0'" />
                <div class="cart-amount" v-if="orderStore.getOrdersAmount">
                  {{ orderStore.getOrdersAmount }}
                </div>
              </BlurTooltip>
            </div>
          </router-link>
        </div>
        <Button
          v-if="!isAuth"
          type="primary"
          class="button__exit"
          @click="toLogin"
        >
          <LoginIcon class="button__exit-icon" />
        </Button>
        <Button
          v-if="!isAuth"
          type="primary"
          class="button__outlined"
          @click="handleOpenFeedbackForm"
        >
          Заказать звонок
        </Button>
        <Button
          v-if="!isAuth"
          type="primary"
          class="button__primary"
          @click="toRegister"
        >
          Зарегистрироваться
        </Button>
        <HeaderProfile
          v-if="isAuth"
          :user-name="user?.name"
          :name="userNameWithLegal"
          :role="role"
          :status="headerProfileStatus"
        />
      </div>
      <CatalogMenu
        v-if="isOpenedCatalog"
        @closeCatalog="closeHandler"
        :isShow="isShow"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.button {
  &__primary {
    background: $btn-primary-bg;
    box-shadow: $btn-primary-box-shadow;
    color: $btn-primary-text-color;
    transition: 0.35s ease-in-out;
    &:hover {
      cursor: pointer;
      background: $btn-primary-bg-active;
    }
  }
  &__outlined {
    border: 1px solid $btn-header-exit-border-color;
    box-shadow: $btn-primary-box-shadow;
    color: $btn-outlined-text-color;
    background: $btn-outlined-bg;
    &:hover {
      cursor: pointer;
      color: $btn-outlined-text-color;
      background: $btn-outlined-bg;
    }
  }
  &__exit {
    height: 32px;
    width: 32px;
    background: $btn-header-exit-bg;
    border: 1px solid $btn-header-exit-border-color;
    border-radius: 6px;
    box-shadow: none;
    align-self: center;
    padding: 0;
    transition: 0.35s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &-icon {
      height: 16px;
      width: 16px;
      color: $btn-header-exit-icon-color;
    }
    &:hover {
      cursor: pointer;
      color: $btn-header-exit-text-color-active;
      border: 1px solid $btn-header-exit-border-color-active;
      background: $btn-header-exit-bg-active;
    }
  }
}

.catalog__item {
  transition: all 0.2s ease-in-out;
  margin-bottom: 0;
  user-select: none;
}

.layout-header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid $gray-300;
  background: $layout-header-bg;
  border-bottom: 1px solid $gray-300;
  height: 72px;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100vw;

  .header-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .links-list {
    display: flex;
    justify-content: center;
    gap: 28px;
    height: 100%;
    margin-left: 8px;
    user-select: none;

    @media (max-width: 1279px) {
      gap: 12px;
    }

    &__catalog {
      @include p6();
      color: #000000e0;

      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.2s ease-in-out;
      height: 100%;

      &_active {
        &:first-child {
          @include p6();
          color: #1677ff;
          font-weight: 600;
          text-decoration: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          transition: all 0.2s ease-in-out;
        }
      }

      &:not(.disabled-link):hover {
        cursor: pointer;
        color: #1677ff;
      }
    }

    &__item {
      font-size: 14px;
      color: #000000e0;
      text-decoration: none;
      padding-top: 8px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.2s ease-in-out;
      text-align: center;

      &_hidden {
        display: none;
      }

      &:hover {
        > .links-list__catalog {
          color: #1677ff;
        }
      }

      &:first-child {
        padding-right: 5px;
      }

      &:not(.disabled-link):hover {
        cursor: pointer;
        color: #1677ff;
        border-color: #1677ff;
      }

      &_active {
        text-shadow: 1px 0 0 #1677ff;
        color: #1677ff;
        border-color: #1677ff;

        &:hover {
          cursor: pointer;
          color: $gray-500;
          border-color: $gray-500;
        }
      }
    }
  }

  .disabled-link {
    @include disabled-link;
  }
}

.layout-header--no-auth {
  z-index: 10000;
}

.buttons {
  position: absolute;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  flex: 1 1 50%;

  @media (max-width: 1279px) {
    right: 12px;
  }
}

.notification-icon {
  background-image: url('@/assets/img/notifications.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 23px;
}

.actions {
  margin-left: 6px;
  display: flex;
  gap: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;

  a {
    text-decoration: none;
  }

  &__item {
    cursor: pointer;
  }

  svg {
    color: $gray-800;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }

  .cart {
    position: relative;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.0588235294);
    padding: 10px 14px 10px 14px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    min-width: 20px;

    &:hover {
      background: #00000026;
    }

    .cart-icon {
      background-image: url('@/assets/img/shopping_basket.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 22px;
      height: 20px;
      margin-right: 10px;
    }

    .cart-amount {
      content: '';
      position: absolute;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      background: $default-accent;
      top: -6px;
      left: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .disabled-btn {
    background-color: $gray-100;
    color: $gray-400;
    &:hover {
      cursor: default;
    }
  }
}
</style>
