import { initYandexMetrika, Config as IConfig } from 'yandex-metrika-vue3'
import { App as IApplication } from 'vue';

export const calcCounterNumber = (host: string) => {
    if (host === 'development.laurence.host') return 99998386
    else if (host === 'easy-retail.org') return 99998405
    else return 99998470
}

export const initMetrica = (app: IApplication) => {
    const host = window.location.host;
    if (host !== 'localhost') {
        console.debug('initMetrica with №', calcCounterNumber(host) + '...')
        const config = {
            id: String(calcCounterNumber(host)),
            options: {
                clickmap: true,
                defer: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            },
            router: app.config.globalProperties.$router, // Экземпляр маршрутизатора
            env: 'production', // Для продакшн-среды
        };
        app.use(initYandexMetrika, config as IConfig)
    }
}
