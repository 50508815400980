export const useFractionalNumber = () => {
  const roundNumber = (string: string) => Number(string).toFixed(2)
  const floorOnBlur = (inputText: string) => {
    return inputText.replace(/[^\d.]/g, '')
  }
  const NumbersOnly = (evt: any) => {
    evt = evt ? evt : window.event
    const charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault()
    } else {
      return true
    }
  }

  const NumbersOnlyTwoDecimal = (evt: any) => {
    evt = evt ? evt : window.event
    const charCode = evt.which ? evt.which : evt.keyCode
    const input = evt.target as HTMLInputElement
    const value = input.value

    const dotCount = (value.match(/\./g) || []).length
    const decimalCount = value.split('.')[1]?.length || 0
    const maxValue = 1000000
    const minValue = 0.01
    if (charCode === 46) {
      // Проверка наличия уже введенной точки или десятичных знаков
      if (dotCount >= 1 || decimalCount > 2) {
        evt.preventDefault()
        return
      } else if (input.value === '') {
        input.value = minValue?.toString() || '0.01'
        return
      }
    } else if (
      // Разрешить ввод только цифр и точки
      (charCode < 48 || charCode > 57) &&
      charCode !== 8 &&
      charCode !== 46
    ) {
      evt.preventDefault()
      return
    }

    // Проверка максимального и минимального значения
    const floatValue = parseFloat(value.replace(/,/g, ''))
    if (floatValue > maxValue || floatValue < minValue) {
      evt.preventDefault()
      return
    }

    // Ограничение количества знаков после точки до двух
    if (decimalCount >= 2) {
      const decimalValue = value.split('.')[1]
      input.value = `${value.split('.')[0]}.${decimalValue.slice(0, 1)}`
    }
  }

  // todo ниже неплохо работает
  // const NumbersOnlyTwoDecimal = (evt: any) => {
  //   evt = evt ? evt : window.event
  //   const charCode = evt.which ? evt.which : evt.keyCode
  //   const input = evt.target as HTMLInputElement
  //   const value = input.value
  //
  //   const dotCount = (value.match(/\./g) || []).length
  //   const decimalCount = value.split('.')[1]?.length || 0
  //   const maxValue = 1000000
  //   const minValue = 0.01
  //
  //   if (charCode === 46) {
  //     // Проверка наличия уже введенной точки или десятичных знаков
  //     if (dotCount >= 1 || decimalCount > 2) {
  //       evt.preventDefault()
  //       return
  //     } else if (input.value === '') {
  //       input.value = minValue?.toString() || '0.01'
  //       return
  //     }
  //   } else if (
  //     // Разрешить ввод только цифр и точки
  //     (charCode < 48 || charCode > 57) &&
  //     charCode !== 8 &&
  //     charCode !== 46
  //   ) {
  //     evt.preventDefault()
  //     return
  //   }
  //
  //   // Проверка максимального и минимального значения
  //   const floatValue = parseFloat(value.replace(/,/g, ''))
  //   if (floatValue > maxValue || floatValue < minValue) {
  //     evt.preventDefault()
  //     return
  //   }
  //
  //   // Ограничение количества знаков после точки до двух
  //   if (decimalCount > 2) {
  //     const decimalValue = value.split('.')[1]
  //     input.value = `${value.split('.')[0]}.${decimalValue.slice(0, 1)}`
  //   }
  // }

  const NumbersOnlyThreeDigits = (evt: any) => {
    evt = evt ? evt : window.event
    const charCode = evt.which ? evt.which : evt.keyCode
    const input = evt.target as HTMLInputElement

    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 8 &&
      (charCode < 37 || charCode > 40)
    ) {
      evt.preventDefault()
    } else {
      const inputValue = input.value + String.fromCharCode(charCode)
      if (/^\d{0,3}$/.test(inputValue)) {
        return true
      } else {
        evt.preventDefault()
      }
    }
  }

  return {
    roundNumber,
    floorOnBlur,
    NumbersOnly,
    NumbersOnlyTwoDecimal,
    NumbersOnlyThreeDigits,
  }
}
