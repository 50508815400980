<script setup lang="ts">

import { IProduct } from '@/types/interfaces'
import { Flex, TypographyTitle as TpTitle } from 'ant-design-vue'
import AgregateProductTable from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateProductRes/AgregateProductTable.vue'

interface Props {
  product: IProduct
}

const props = defineProps<Props>()
</script>

<template>
  <Flex vertical :gap="16">
    <TpTitle :level="4">{{ props.product.fullName }}</TpTitle>
    <AgregateProductTable :product="props.product" />
  </Flex>
</template>

<style lang="scss"></style>
