import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class CustomerAPIModel {
  async getItems(
    filters: any[][],
    withs: any[] = [],
    order: any[][],
    perPage = 10,
    page = 1,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Customer,
      method: EgalMethods.GetItems,
      data: { filter: filters, withs, pagination: { per_page: perPage, page: page }, order},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItem(id: string | number, withs: string[] = []): Promise<any> {
    return RequestManager.request({
      model: APIModels.Customer,
      method: EgalMethods.GetItem,
      data: { id, withs },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async create(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Customer,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateCustomer(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Customer,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const CustomerAPI = new CustomerAPIModel()
export { CustomerAPI }
