<script setup lang="ts">

import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  open: boolean
  description: string
}

const props = defineProps<IProps>()

const closeModal = () => {
  window.location.reload()
}
</script>

<template>
  <ModalWindow
      :show="props.open"
      padding-value="20px"
      border-radius="8px"
      @close="closeModal"
  >
    <template #header>
      <div class="modal__title">
        Обновите страницу
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        {{ props.description }}
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal" type="primary"> Обновить </Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    display: flex;
    flex-wrap: wrap;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 440px;
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>

