<script setup lang="ts">
import {
  computed,
  ref,
  Ref,
  watch,
    defineProps,
} from 'vue'
import { Button, Divider, Modal, Space } from 'ant-design-vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { getUserIdTemporary } from '@/helpers/scripts'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import { UploadDocument } from '@/types/types'
import { PartnerDocumentAPI } from '@/api/PartnerDocumentAPI'
import DocumentMiniListItem from '@/components/Document/DocumentMiniListItem.vue'
import { IPartnerDocument } from '@/types/interfaces'

type TPartnershipStep =
  | 'sign_out_by_farmer'
  | 'upload_documents'
  | 'partnership_signed'

interface IProps {
  open: boolean
  partnershipId: number
  orderId: number
  partnershipStatus: 'signed_farmer' | 'signed_customer'
  signingStatus: 'not_signed' | 'signed_out'
  farmer_id: number
}

interface IUploadDocument {
  file_name: string
  file_body: string
  errors: string[]
  extension: string
}

interface ISignedDocument {
  file_path: string
  is_contract: boolean
}

const emit = defineEmits(['update:open', 'onSuccess'])
const props = defineProps<IProps>()
const step: Ref<TPartnershipStep> = ref('upload_documents')
const isLoading = ref(false)
const downloadRef = ref<HTMLAnchorElement>()
const maxSingleSize = 5
const uploadedDocuments = ref<IUploadDocument[]>([])
const farmer_documents = ref<IPartnerDocument[]>([])

const validDocumentTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'application/pdf',
  // doc and docx now considered invalid
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
const modalHeader: Record<TPartnershipStep, string> = {
  upload_documents:
    'Для продолжения работы над заказом, нужно подписать договор с поставщиком',
  sign_out_by_farmer: 'Поставщик указал, что договор уже был заключён',
  partnership_signed: 'Заказ согласован обеими сторонами',
}

const cancelBtnText: Record<TPartnershipStep, string> = {
  upload_documents: 'Договор подписан вне системы',
  sign_out_by_farmer: 'Закрыть',
  partnership_signed: '',
}

const okBtnText: Record<TPartnershipStep, string> = {
  upload_documents: 'Отправить договор',
  sign_out_by_farmer: 'Да, договор был заключён',
  partnership_signed: 'Закрыть',
}

const closeModal = (success = false) => {
  emit('update:open', false)
  if (success) emit('onSuccess')
}
const onCancel = () => {
  switch (step.value) {
    case 'upload_documents':
      signPartnershipOut()
      break
    case 'sign_out_by_farmer':
      closeModal()
      break
    default:
      break
  }
}

const onOK = () => {
  switch (step.value) {
    case 'upload_documents':
      signPartnership()
      break

    case 'sign_out_by_farmer':
      signPartnershipOut()
      break

    case 'partnership_signed':
      closeModal(true)
      break

    default:
      break
  }
}

const signPartnershipOut = async () => {
  isLoading.value = true
  await PartnersAPI.sign({
    id: props.partnershipId,
    order_id: props.orderId,
    signed_earlier: 'signed_out',
  }).then(() => {
    isLoading.value = false
    step.value = 'partnership_signed'
  })
}

const signPartnership = async () => {
  isLoading.value = true
  const documentPaths: ISignedDocument[] = []
  const respones = uploadedDocuments.value.map(async (doc) => {
    await PartnerDocumentAPI.upload(splitBase64(doc)).then((res) =>
      documentPaths.push({
        file_path: res.path,
        is_contract: true,
      }),
    )
  })

  await Promise.all(respones)
  await PartnersAPI.sign({
    id: props.partnershipId,
    order_id: props.orderId,
    documents: documentPaths,
  }).then(() => {
    isLoading.value = false
    step.value = 'partnership_signed'
  })
}

const uploadFile = (file: File, base64: string) => {
  const fileError = [
    isValidFileTypes(file),
    isValidFileSize(+file.size / 1048576),
  ]
  uploadedDocuments.value.push({
    file_name: file.name,
    file_body: base64,
    errors: fileError,
    extension: file.type.split('/')[1],
  })
}

const deleteDocument = (index: number) => {
  uploadedDocuments.value.splice(index, 1)
}

const isValidFileTypes = (file: File | UploadDocument) => {
  return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
}

const isValidFileSize = (size: number) => {
  return size > maxSingleSize ? 'Превышен размер файла' : ''
}

const uploadDocumentsNotEmpty = computed(
  () => uploadedDocuments.value.length > 0,
)

const isOkDisabled = computed(
  () =>
    step.value === 'upload_documents' &&
    (!uploadDocumentsNotEmpty.value ||
      uploadedDocuments.value.some((doc) => doc.errors.join('') !== '')),
)

const splitBase64 = (document: IUploadDocument): IUploadDocument => {
  return {
    ...document,
    file_body: document.file_body.split(',')[1],
  }
}

const getPartnershipDocuments = async (farmer_id: number) => {
  await PartnerDocumentAPI.getItems(
    [
      ['partner.farmer_id', 'eq', farmer_id],
      "AND",
      ['partner.customer_id', 'eq', getUserIdTemporary()],
      "AND",
      ['source', 'eq', 'farmer'],
    ],
    20,
    ['partner'],
  ).then((res) => {
    farmer_documents.value = res.items
  })
}

watch(
  () => props.open,
  () => {
    if (props.open) {
      getPartnershipDocuments(props.farmer_id)
      if (props.signingStatus === 'signed_out') {
        step.value = 'sign_out_by_farmer'
      } else {
        step.value = 'upload_documents'
      }
    }
  },
)
</script>

<template>
  <Modal
    :title="modalHeader[step]"
    width="500px"
    :open="open"
    centered
    @cancel="emit('update:open', false)"
    closable
  >
    <template #title v-if="modalHeader[step]">
      {{ modalHeader[step] }}
    </template>

    <template v-if="step === 'upload_documents'">
      <Space :size="8" direction="vertical">
        <span>
          Пожалуйста, скачайте, подпишите и загрузите скан-копию договора о
          сотрудничестве. Если вы уже заключили договор, выберите пункт «Договор
          подписан вне системы».
        </span>
        <div class="documents-list">
          <DocumentMiniListItem
            v-for="document in farmer_documents"
            :key="document.id"
            :id="document.id"
            :name="document.file_name"
            :body="''"
            :errors="[]"
            :isShow="true"
            documentModel="partner"
          />
        </div>
      </Space>
      <Divider class="divider" />
      <Space :size="12" direction="vertical">
        <span>
          Вы можете загрузить до 20 файлов. Размер одного файла не более 5 Мб.
          Допустимые форматы: .pdf, .gif, .jpg, .jpeg, .jpe, .png.
        </span>
        <div>
          <FileUploader
            @load-file="uploadFile"
            v-if="!uploadDocumentsNotEmpty"
          />
          <template v-if="uploadDocumentsNotEmpty">
            <div class="documents-list-block">
              <div class="documents-list">
                <DocumentMiniListItem
                  v-for="(document, index) in uploadedDocuments"
                  :key="document.file_name"
                  :id="index"
                  :name="document.file_name"
                  :body="document.file_body"
                  :errors="document.errors"
                  @deleteDocument="deleteDocument"
                  :isShow="false"
                />
              </div>
              <FileUploader
                :type="'custom'"
                @load-file="uploadFile"
                v-if="uploadedDocuments.length < 20"
              >
                <a href="#" class="page-card__add-document">
                  <span class="plus">+</span>
                  Добавить документы
                </a>
              </FileUploader>
            </div>
          </template>
        </div>
      </Space>
    </template>

    <template v-if="step === 'sign_out_by_farmer'"
      >Если это правда, пожалуйста, подтвердите. В противном случае свяжитесь с
      поставщиком.</template
    >

    <template v-if="step === 'partnership_signed'">
      Поставщик может приступать к его сбору. Мы уведомим вас о готовности
      заказа.
    </template>
    <template #footer>
      <Space :size="8">
        <Button
          @click="onCancel"
          v-if="cancelBtnText[step]"
          :loading="isLoading"
          >{{ cancelBtnText[step] }}</Button
        >
        <Button
          type="primary"
          @click="onOK"
          v-if="okBtnText[step]"
          :disabled="isOkDisabled"
          :loading="isLoading"
          >{{ okBtnText[step] }}</Button
        >
      </Space>
    </template>
    <a ref="downloadRef" />
  </Modal>
</template>

<style scoped lang="scss">
.like-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1677ff;
  cursor: pointer;
  &:hover {
    color: #69b1ff;
  }
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #000000e0;
}
.modal-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #000000e0;
}

.loader-wrapper {
  position: relative;
  width: 100%;
  height: 45px;
  transform: scale(0.6);
}
.divider {
  margin: 12px 0;
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.documents-list-block {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .documents-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
</style>
