<script setup lang="ts">
import { Tag } from 'ant-design-vue'
import { computed, ref } from 'vue'

interface Props {
  status: string
}

const props = defineProps<Props>()

const tagsData = ref([
  { status: 'Новый заказ', color: '#87d068' },
  { status: 'Поставщик подписал договор', color: 'blue' },
  { status: 'Заказ на доставке', color: 'blue' },
  { status: 'Сбор заказа', color: 'blue' },
  { status: 'Договор на подписании', color: 'blue' },
  { status: 'Заказ изменен поставщиком', color: 'blue' },
  { status: 'Заказ выполнен', color: 'green' },
  { status: 'Заказ выполнен частично', color: 'orange' },
  { status: 'Заказ не выполнен', color: 'red' },
  { status: 'Заказ отменен поставщиком', color: 'red' },
  { status: 'Заказ отменен покупателем', color: 'red' },
])

const getTagColor = computed(() => {
  const foundTag = tagsData.value.find((tag) => tag.status === props.status)
  return foundTag ? foundTag.color : ''
})
</script>

<template>
  <Tag :color="getTagColor">
    {{ status }}
  </Tag>
</template>

<style scoped>
.ant-tag {
  height: fit-content;
}
</style>
