<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { APIModels, Roles } from '@/types/enums'
import { useJWTDecode } from '@/composables/useJWTDecode'
import VerificationFirstStep from '@/components/VerificationSteps/VerificationFirstStep.vue'
import { UserAPI } from '@/api/UserAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import router from '@/router'
import { IUser } from '@/types/interfaces'
import { Flex, TypographyText as TpText } from 'ant-design-vue'
import { useStore } from '@/store'
import { useCookies } from 'vue3-cookies'
import {useRoute} from "vue-router";

const { getCoreCookie } = useJWTDecode()
const count = ref(1)
const store = useStore()
const isFetching = ref(false)
const headUserPhoneNumber = ref<string>()

const coreCookie = getCoreCookie()
const { cookies } = useCookies()

const activeRole: { name: string; value: Roles.Farmer | Roles.Customer } =
  reactive({
    name: 'Поставщик',
    value: Roles.Farmer,
  })

const accountId = computed(() => getCoreCookie().id)
const userDataString = localStorage.getItem('userData')

let userData = userDataString ? JSON.parse(userDataString) : ''

const companyId = ref(userData.id)

const goToRegisterDocuments = async () => {
  const userDataC = await UserAPI.getUserData(
    companyId.value,
    getCoreCookie()?.company_type === 'customer'
      ? APIModels.Customer
      : getCoreCookie()?.company_type !== 'customer'
        ? APIModels.Farmer
        : APIModels.Farmer,
    ['users'],
  )
  if (
    getCoreCookie()?.company_type !== 'customer' &&
    !!userDataC?.checking_account
  ) {
    await router.push('/register-documents')
  }
}

const getUser = async () => {
  UserAPI.getUser(coreCookie?.id)
    .then((res) => {
      localStorage.setItem('user', JSON.stringify(res))
      companyId.value = res.company_id
    })
    .then(async () => {
      const userDataC = await UserAPI.getUserData(
        companyId.value,
        getCoreCookie()?.company_type === 'customer'
          ? APIModels.Customer
          : getCoreCookie()?.company_type !== 'customer'
            ? APIModels.Farmer
            : APIModels.Farmer,
        ['users'],
      )
      localStorage.setItem('userData', JSON.stringify(userDataC))
      userData.value = userDataC
    })
}

onMounted(async () => {
  await getVerificationProgress()

  if (getCoreCookie()?.company_type === 'customer') {
    Object.assign(activeRole, { name: 'Покупатель', value: Roles.Customer })
  }

  if (getCoreCookie()?.company_type !== 'customer') {
    Object.assign(activeRole, { name: 'Поставщик', value: Roles.Farmer })
    await goToRegisterDocuments()
  }
})

const goToRoot = async () => {
  store.setVerificationStatus('full')
  if (getCoreCookie()?.company_type === 'customer') await router.push('/')
  await goToRegisterDocuments()
}

const getVerificationProgress = async () => {
  isFetching.value = true
  await getUser()
  const userData = await UserAPI.getUser(accountId.value, [
    'company',
    'company.contacts',
    'company.users',
  ])

  const firstPhone = userData.company.users.find(
    (user: IUser) => user.role === 'head',
  )?.phone

  if (firstPhone) {
    headUserPhoneNumber.value = firstPhone
  }

  if (!userData.company?.checking_account) {
    count.value = 1
  }

  isFetching.value = false
}

onMounted(async () => {
  if (userData.is_docs_sent) await router.replace('/')
})

const route = useRoute()
</script>

<template>
  <div v-if="!isFetching" class="verification__container">
    <div class="verification__wrapper">
      <Flex :gap="24" vertical class="title-block">
        <div class="title-block__text">Верификация аккаунта</div>
        <Flex v-if="count === 1" vertical>
          <TpText
            >После прохождения верификации, Вы сможете совершать и принимать
            заказы, <br />
            а также участвовать в торгах с другими компаниями.
          </TpText>
          <TpText
            >На платформе находятся только проверенные поставщики и покупатели,
            <br />что гарантирует безопасность Вам и Вашим партнерам!
          </TpText>
        </Flex>
      </Flex>
      <VerificationFirstStep
        v-if="count === 1"
        @next="goToRoot"
        :headUserPhoneNumber="headUserPhoneNumber"
      />
    </div>
  </div>

  <div v-else class="loader-wrapper">
    <StarLoader />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
.verification-modal {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 32px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
      margin: 0 auto;
    }
    .modal-body {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-600;
      .verification-buttons {
        margin-top: 32px;
      }
    }
  }
}
.verification__container {
  width: 610px;
  padding: 48px;
  border-radius: 24px;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow:
    0 0 1px rgba(12, 26, 75, 0.1),
    0 10px 16px rgba(20, 37, 63, 0.06);
}
.title-block {
  margin-bottom: 24px;

  &__text {
    font-weight: 700;
    font-size: 24px;
    color: $gray-800;
  }
}

.loader-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
</style>
