<script lang="ts" setup>
import { Button, Flex } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { IProduct } from '@/types/interfaces'
import { useRedirectAddProductStore } from '@/store/useRedirectAddProductStore'
import router from '@/router'

interface IProps {
  show: boolean
  product: IProduct
  isFromReduction: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['close'])
const { setProduct, setIsRedirected } = useRedirectAddProductStore()
const navigateToAddProduct = () => {
  setProduct(props.product)
  setIsRedirected(true)
  emit('close')
  router.push('/add-product')
}
</script>

<template>
  <ModalWindow
    class="redirect-to-add-product"
    :show="props.show"
    @close="emit('close')"
  >
    <template #header>Переход на страницу создания товара</template>
    <template #body>
      <div class="body-text">
        Выйти из раздела
        {{ props.isFromReduction ? 'Торги покупка' : 'Запросы цен' }} и перейти
        в каталог для создания товара
      </div>
      <Flex justify="flex-end" gap="16">
        <Button @click="emit('close')">Закрыть</Button>
        <Button type="primary" @click="navigateToAddProduct">Перейти</Button>
      </Flex>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.redirect-to-add-product {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 26px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
    }
    .modal-body {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-600;
    }
    .body-text {
      margin-bottom: 10px;
    }
  }
}
</style>
