import { APIModels } from '@/types/enums'
import router from '@/router'
import { GetItemsResponse, IAddress, IAddressesCoordResponse } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {calcFiltersInGetAddresses} from "@/helpers/scripts";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class AddressAPIModel {
  async createMany(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateAddress(data: any, redirect = true): Promise<any> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    }).then(() => {
      if (redirect) {
        router.push('/')
      }
    })
  }

  async deleteAddress(id: number | string): Promise<any> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.delete,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getAddresses(
    company_id: string,
    role?: string,
    additionalFilters?: string[] | string[][],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('core')},
      data: { filter: calcFiltersInGetAddresses({ company_id, role, additionalFilters }), pagination: { per_page: 100, page: 1 } },
    })
  }

  async getItems(
    page = 1,
    perPage = 10,
    filter: (string | object)[] = [],
    withs: string[] = [],
    order?: string[][],
  ): Promise<GetItemsResponse<IAddress>> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('core')},
      data: { filter, withs, pagination: { per_page: perPage, page }, order },
    })
  }

  async getFarmerAdresses(filters: any): Promise<IAddressesCoordResponse> {
    return RequestManager.request({
      model: APIModels.Address,
      method: EgalMethods.getFarmerAddresses,
      data: { filter: filters },
    })
  }
}

const AddressAPI = new AddressAPIModel()
export { AddressAPI }
