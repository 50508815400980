export enum ModalKeys {
  VerificationFull = 'VerificationFull',
  FeedbackForm = 'FeedbackForm',
  RedirectToAddProduct = 'RedirectToAddProduct',
  SelectAddress = 'SelectAddress',
  RedirectWarning = 'RedirectWarning',
  HandleError = 'HandleError',
  SelectAddressOnMap = 'SelectAddressOnMap',
  GeneratingDocsModal = 'GeneratingDocsModal',
  DownloadDocsModal = 'DownloadDocsModal',
}
