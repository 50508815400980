<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomerRequestCommonInfoTermsOfPayments',
  props: {
    payments: {
      type: Array,
      default: () => [],
      required: true,
    },
    isPaymentDelayed: {
      type: Boolean,
      default: false,
      required: true,
    },
    paymentDelayDays: {
      type: Number,
      default: 0,
      required: true,
    },
  },
})
</script>

<template>
  <div class="customer-request-common-info-terms-of-payments">
    <div class="customer-request-common-info-terms-of-payments_title">
      Условия оплаты
    </div>
    <div v-for="payment in payments" :key="payment">
      <div class="customer-request-common-info-terms-of-payments_item">
        {{ payment }}
      </div>
    </div>
    <div
      v-if="isPaymentDelayed"
      class="customer-request-common-info-terms-of-payments_item"
    >
      Отсрочка по оплате
      {{ !!paymentDelayDays ? paymentDelayDays + ' дней' : '' }}
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info-terms-of-payments {
  &_title {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 600;
    font-family: Inter, sans-serif;
  }
  &_item {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
}
</style>
