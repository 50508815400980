<script setup lang="ts">
import { Button, Modal } from 'ant-design-vue'

interface IProps {
  open: boolean
}
const props = defineProps<IProps>()
const emit = defineEmits(['ok', 'update:open'])
</script>

<template>
  <Modal
    :open="open"
    width="440px"
    centered
    :bodyStyle="{ marginBottom: '24px' }"
    title="Отказаться от заказа?"
    @ok="emit('ok')"
    cancelText="Закрыть"
    okText="Отказаться"
    @cancel="emit('update:open', false)"
  >
    Вы удаляете последнюю позицию в заказе, это автоматически отменит весь заказ
  </Modal>
</template>

<style scoped lang="scss"></style>
