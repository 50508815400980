<script lang="ts" setup>
import {
  fontSize16,
  outlineGrayButton,
  primaryButton,
} from '@/assets/EgalStyles/EButton'
import router from '@/router'
import { Roles } from '@/types/enums'
import {useCookies} from "vue3-cookies";
import {useJWTDecode} from "@/composables/useJWTDecode";

const { cookies } = useCookies()

const toVerify = async () => {
  localStorage.setItem('toVerify', 'true')
  await router.push('/verification')
}

const { getCoreCookie } = useJWTDecode()

const toWelcome = async () => {
  await router.push('/')
}
</script>

<template>
  <div class="verification-confirm-card">
    <div class="verification-confirm-wrapper">
      <div class="title">Вы успешно зарегистрировались</div>
      <p v-if="getCoreCookie()?.company_type === Roles.Customer">
        Теперь Вы можете познакомиться с системой и каталогами товаров
        поставщиков.
        <br />
        <br />
        Для оформления заказа и получения доступа к полному списку функций
        Платформы Вам необходимо пройти полную верификацию и подтвердить аккаунт
        – это гарантирует безопасность Вам и Вашим контрагентам.
        <br />
        <br />
        Вы можете пройти этап подтверждения сейчас или вернуться к нему позже в
        личном кабинете.
      </p>
      <p v-if="getCoreCookie()?.company_type === Roles.Farmer">
        Теперь Вы можете познакомиться с системой: заполнить каталог своей
        продукции, ознакомиться с каталогами других поставщиков.
        <br />
        <br />
        Чтобы другие пользователи смогли отправить Вам заказ, необходимо пройти
        полную верификацию и подтвердить аккаунт – это гарантирует безопасность
        Вам и Вашим контрагентам.
        <br />
        <br />
        Вы можете пройти этап подтверждения сейчас или вернуться к нему позже в
        личном кабинете.
      </p>
    </div>
    <div class="verification-confirm-buttons-row">
      <EButton
        :style-config="{
          ...primaryButton,
          fontSize16,
        }"
        class="suggest-footer_btn"
        @click="toWelcome"
      >
        Познакомиться с системой
      </EButton>
      <EButton
        :style-config="{
          ...outlineGrayButton,
          fontSize16,
          fontWeight: 500,
          color: '#2d3748',
        }"
        class="suggest-footer_btn"
        @click="toVerify"
      >
        Подтвердить аккаунт
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.verification-confirm {
  &-card {
    width: 610px;
    padding: 48px;
    height: fit-content;
    border-radius: 24px;
    margin: 20px auto 0;
    background-color: #ffffff;
    box-shadow:
      0 0 1px rgba(12, 26, 75, 0.1),
      0 10px 16px rgba(20, 37, 63, 0.06);
  }

  &-wrapper {
    .title {
      font-weight: 700;
      font-size: 24px;
      color: $gray-800;
      margin-bottom: 24px;
    }

    p {
      @include p3();
    }
  }

  &-buttons-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 32px;
    gap: 8px;
  }
}
</style>
