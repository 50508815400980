import { APIModels } from '@/types/enums'
import { IPriceRequest } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class PriceRequestAPIModel {
  async create(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceRequest,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
  ): Promise<IPriceRequest> {
    return RequestManager.request({
      model: APIModels.PriceRequest,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('core')},
      data: { pagination: { per_page: perPage, page: page }, filter, withs, order: [['created_at', 'desc']]},
    })
  }

  async getItem(id: string, withs: string[] = [], filters?: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceRequest,
      method: EgalMethods.GetItem,
      data: { id, filter: filters, withs },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const PriceRequestAPI = new PriceRequestAPIModel()
export { PriceRequestAPI }
