<script setup lang="ts">
import { computed, h, reactive, ref, UnwrapRef, watch } from 'vue'
import { IAddress, IContact } from '@/types/interfaces'
import router from '@/router'
import { useDebounceFn } from '@vueuse/core'
import dayjs from 'dayjs'
import { Modal, notification } from 'ant-design-vue'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { AuctionAPI } from '@/api/AuctionAPI'
import { AuctionProductRequestAPI } from '@/api/AuctionProductRequestAPI'
import { AuctionSelectedRecipientAPI } from '@/api/AuctionSelectedRecipientAPI'
import CreateAuctionBody from '@/components/CreateAuction/CreateAuctionBody/CreateAuctionBody.vue'
import CreateAuctionFooter from '@/components/CreateAuction/CreateAuctionFooter.vue'
import CreateAuctionHeader from '@/components/CreateAuction/CreateAuctionHeader.vue'
import { CustomerAPI } from '@/api/CustomerAPI'
import { useRoute } from 'vue-router'

export interface FirstFormState {
  expiration_date: string
  is_self_delivery: boolean
  is_supplier_delivery: boolean
  is_delivery_included: boolean
  is_delivery_excluded: boolean
  delivery_method: string[]
  delivery_payment_type: string[]
  payment_method: string[]
  is_pay_late: boolean
  payment_delay_days: number | string
  comment: string

  addressIsError: boolean
  selectedAddress: number | null
  savedAddress: IAddress | null
  selectedContactPerson: number
  savedContactPerson: IContact | null
}
export interface IProductTableItem {
  key: string
  farmerProductName: string
  volume: string | number
  vat: string | number
  startPrice: string | number
  remainingShelfLife: string | number
  isSampleRequested: boolean
  requestedSamplesNumber: string | number
  measurement_unit_id: string
  min_value: number
}

const currentStep = ref(0)
const firstFormState: UnwrapRef<FirstFormState> = reactive({
  expiration_date: '',
  is_self_delivery: false,
  is_supplier_delivery: false,
  is_delivery_included: false,
  is_delivery_excluded: false,
  delivery_method: ['is_self_delivery'],
  delivery_payment_type: ['is_delivery_included'],
  payment_method: ['is_pay_non_cash'],
  is_pay_late: false,
  payment_delay_days: '',
  need_unload: false,
  comment: '',

  addressIsError: false,
  selectedAddress: null,
  savedAddress: null,
  selectedContactPerson: 0,
  savedContactPerson: null,
})
const secondFormState = reactive<{ domains: IProductTableItem[] }>({
  domains: [],
})
const checkedKeys = ref<string[]>([])
const expandedKeys = ref<string[]>([])
const thirdFormState = ref<any[]>([])
const thirdFormError = ref<boolean>(false)
const secondFormError = ref<boolean>(false)
const allCustomers = ref<any[]>([])
const searchedCustomers = ref<any[]>([])
const isCustomersFetching = ref<boolean>(false)
const selectedCustomers = ref<string[]>([])
const customersSearchQuery = ref<string>('')
const forms = ref()
const isConfirmModal = ref<boolean>(false)
const isRejectModal = ref<boolean>(false)
const isAuctionSending = ref<boolean>(false)

const editFirstForm = (field: string, value: any) => {
  firstFormState[field] = value
}
const editSecondForm = (field: string, value: any, index: number) => {
  secondFormState.domains[index][field] = value === null ? '' : value
  if (field === 'isSampleRequested' && !value) {
    secondFormState.domains[index].requestedSamplesNumber = ''
  }

  if (
    field === 'volume' ||
    field === 'startPrice' ||
    field === 'requestedSamplesNumber'
  ) {
    if (typeof value === 'string') {
      secondFormState.domains[index][field] =
        Number.parseFloat(value).toFixed(2)
    } else {
      secondFormState.domains[index][field] = ''
    }
  }
}
const addProduct = (item: IProductTableItem) => {
  secondFormError.value = false
  if (item.measurement_unit_id === null) {
    const productText = item.farmerProductName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    secondFormState.domains.push(item)
  } else {
    secondFormState.domains.push(item)
  }
}

const removeProduct = (key: string) => {
  secondFormState.domains = secondFormState.domains.filter(
    (obj) => obj.key !== key,
  )
  checkedKeys.value = checkedKeys.value.filter((k) => k !== key)
}
const validateFirstStep = () => {
  Promise.all([
    forms.value.firstFormRef.firstFormRef.validate(),
    validateAddress(),
  ])
    .then(() => {
      nextStep()
    })
    .catch((err) => {
      if (err === 'delivery-address') {
        firstFormState.addressIsError = true
      }
    })
}

const validateSecondStep = () => {
  if (!secondFormState.domains.length) {
    secondFormError.value = true
    return
  }
  secondFormError.value = false
  forms.value.secondFormRef.secondFormRef.secondFormRef.validate().then(() => {
    nextStep()
  })
}

const validateThirdStep = () => {
  if (thirdFormState.value.length) {
    isPrivateAuction.value = true
  } else {
    thirdFormError.value = true
  }
}
const resetThirdStep = () => {
  thirdFormState.value = []
  selectedCustomers.value = []
}
const checkKeys = (keys: string[], event: any) => {
  checkedKeys.value = keys
  if (event.checked) {
    addProduct(makeTableElement(event))
  } else {
    removeProduct(event.node.key)
  }
}
const expandKeys = (keys: string[]) => {
  expandedKeys.value = keys
}

const validateAddress = () => {
  return new Promise((resolve, reject) => {
    if (
      (firstFormState.savedAddress &&
        firstFormState.delivery_method.includes('is_self_delivery')) ||
      !firstFormState.delivery_method.includes('is_self_delivery')
    ) {
      resolve(true)
    } else {
      reject('delivery-address')
    }
  })
}

const setSelectedCustomers = (value: string[], more: any) => {
  selectedCustomers.value = value
}
const deleteCustomer = (uid: string) => {
  selectedCustomers.value = selectedCustomers.value.filter((id) => id !== uid)
  thirdFormState.value = thirdFormState.value.filter((obj) => obj.id !== uid)
}
const saveCustomers = () => {
  const foundObjects: any[] = []
  selectedCustomers.value.forEach((uid) => {
    const foundObject = allCustomers.value.find((obj) => obj.id === uid)
    if (foundObject) {
      foundObjects.push(foundObject)
    }
  })
  thirdFormState.value = foundObjects
  thirdFormError.value = false
}
const nextStep = () => {
  currentStep.value = currentStep.value + 1
}
const prevStep = () => {
  if (currentStep.value === 2) {
    resetThirdStep()
  }
  currentStep.value = currentStep.value - 1
}
const isPrivateAuction = ref(false)
const isSelectCustomers = ref(false)
const isPublicAuction = ref(false)

const handleSelectCustomerModal = (value: boolean) => {
  isSelectCustomers.value = value
  if (!value) {
    customersSearchQuery.value = ''
  }
}
const handlePublicReduction = () => {
  isPublicAuction.value = !isPublicAuction.value
}
const setSearchQuery = useDebounceFn((value: string) => {
  customersSearchQuery.value = value
}, 500)

const showRejectModal = () => {
  isRejectModal.value = true
}
const closeConfirmModal = () => {
  if (isAuctionSending.value) return
  isConfirmModal.value = false
}
const toggleStep = () => {
  switch (currentStep.value) {
    case 0:
      validateFirstStep()
      break
    case 1:
      validateSecondStep()
      break
    case 2:
      validateThirdStep()
      break
    default:
      break
  }
}

const createAuction = async (isPublic: boolean) => {
  try {
    isAuctionSending.value = true
    await AuctionAPI.create(collectAuctionData(isPublic)).then(async (res) => {
      await AuctionProductRequestAPI.createMany(
        collectAuctionProductRequestData(res.id),
      ).then(async () => {
        if (!isPublic) {
          await AuctionSelectedRecipientAPI.createMany(
            collectRecipientData(res.id),
          )
        }
        await AuctionAPI.update({
          id: res.id,
          status: 'open',
        }).then(() => {
          createdSuccessfully(isPublic)
        })
      })
    })
  } catch (error) {
    isAuctionSending.value = false
    console.error(error)
  }
}
const createdSuccessfully = (isPublic: boolean) => {
  isAuctionSending.value = false
  notification.info({
    message: isPublic
      ? 'Торг на продажу опубликован'
      : 'Приглашение на торг на продажу успешно отправлено',
    top: '80px',
    icon: h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)' },
    }),
  })
  router.push('/auctions')
}
const getCustomers = async () => {
  isCustomersFetching.value = true

  await CustomerAPI.getItems(
    [['is_active', 'eq', 'true']],
    ['company_rating'],
    [['name', 'asc']],
    100,
  )
    .then((res) => {
      if (customersSearchQuery.value) {
        searchedCustomers.value = res.items.filter((obj) => {
          const farmerName = (obj.legal_form + obj.name)
            .toLowerCase()
            .replace(/\s+/, '')
          return farmerName.includes(
            customersSearchQuery.value.toLowerCase().replace(/\s+/, ''),
          )
        })
      } else {
        allCustomers.value = res.items
      }
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      isCustomersFetching.value = false
    })
}
const makeTableElement = (e: any): IProductTableItem => ({
  key: e.node.key,
  farmerProductName: `${e.node.title.split(',').join('')}`,
  volume: '',
  startPrice: '',
  remainingShelfLife: '',
  isSampleRequested: false,
  requestedSamplesNumber: '',
  vat: e.node.vat,
  measurement_unit_id: e.node.measurement_unit_id,
  min_value: e.node.min_value,
})
const collectAuctionData = (isPublic: boolean) => {
  return {
    is_public: isPublic,
    expiration_date: dayjs(firstFormState.expiration_date).format('YYYY-MM-DD'),
    contact_name: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.name
      : '',
    contact_phone: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.phone
      : '',
    contact_post: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.post
      : '',
    is_self_delivery:
      firstFormState.delivery_method.includes('is_self_delivery'),
    is_supplier_delivery: firstFormState.delivery_method.includes(
      'is_supplier_delivery',
    ),
    is_delivery_excluded:
      firstFormState.delivery_method.includes('is_supplier_delivery') &&
      firstFormState.delivery_payment_type.includes('is_delivery_excluded'),
    is_delivery_included:
      firstFormState.delivery_method.includes('is_supplier_delivery') &&
      firstFormState.delivery_payment_type.includes('is_delivery_included'),
    self_delivery_address: firstFormState.delivery_method.includes(
      'is_self_delivery',
    )
      ? firstFormState.savedAddress!.street
      : '',
    is_pay_cash: firstFormState.payment_method.includes('is_pay_cash'),
    is_pay_non_cash: firstFormState.payment_method.includes('is_pay_non_cash'),
    is_payment_delayed: firstFormState.is_pay_late,
    payment_delay_days: firstFormState.payment_delay_days
      ? firstFormState.payment_delay_days
      : null,
    comment: firstFormState.comment,
  }
}

const collectAuctionProductRequestData = (auctionId: number) => {
  return secondFormState.domains.map((product: IProductTableItem) => ({
    auction_id: auctionId,
    farmer_product_id: +product.key.split(':')[0],
    farmer_product_name: product.farmerProductName,
    volume: +product.volume,
    vat: +product.vat,
    start_price: +product.startPrice,
    remaining_shelf_life: +product.remainingShelfLife
      ? +product.remainingShelfLife
      : null,
    is_sample_requested: product.isSampleRequested,
    min_value: product.min_value,
    requested_samples_number:
      product.isSampleRequested && product.requestedSamplesNumber
        ? +product.requestedSamplesNumber
        : null,
  }))
}
const collectRecipientData = (auctionId: number) => {
  return thirdFormState.value.map((obj) => ({
    auction_id: auctionId,
    customer_id: obj.id,
  }))
}

const handleSelectFarmerModal = () => {
  isSelectCustomers.value = !isSelectCustomers.value
}

const setCurrentStep = (value: number) => {
  currentStep.value = value
}

watch([currentStep, customersSearchQuery], async () => {
  if (currentStep.value === 2) {
    await getCustomers()
  }
})

const route = useRoute()
</script>

<template>
  <div class="create-auction-view">
    <CreateAuctionHeader
      :currentStep="currentStep"
      :thirdFormState="thirdFormState"
      :customers="customersSearchQuery ? searchedCustomers : allCustomers"
      :selectedCustomers="selectedCustomers"
      :isSelectCustomers="isSelectCustomers"
      :isCustomersFetching="isCustomersFetching"
      :handleSelectCustomerModal="isCustomersFetching"
      :customersSearchQuery="customersSearchQuery"
      @setSelectedCustomers="setSelectedCustomers"
      @saveCustomers="saveCustomers"
      @handleSelectCustomerModal="handleSelectCustomerModal"
      @setSearchQuery="setSearchQuery"
      @handleSelectFarmerModal="handleSelectFarmerModal"
      @setCurrentStep="setCurrentStep"
    />
    <CreateAuctionBody
      ref="forms"
      :currentStep="currentStep"
      :firstFormState="firstFormState"
      :secondFormState="secondFormState"
      :thirdFormState="thirdFormState"
      :checkedKeys="checkedKeys"
      :expandedKeys="expandedKeys"
      @triggerValidation="toggleStep"
      @editFirstForm="editFirstForm"
      @editSecondForm="editSecondForm"
      @deleteCustomer="deleteCustomer"
      @handleSelectCustomerModal="handleSelectCustomerModal"
      @handlePublicReduction="handlePublicReduction"
      @addProduct="addProduct"
      @removeProduct="removeProduct"
      @checkKeys="checkKeys"
      @expandKeys="expandKeys"
    />
    <CreateAuctionFooter
      :currentStep="currentStep"
      :thirdFormError="thirdFormError"
      :secondFormError="secondFormError"
      :isShowAddMore="thirdFormState.length > 0"
      :thirdFormState="thirdFormState"
      @showRejectModal="showRejectModal"
      @handleSelectFarmerModal="handleSelectCustomerModal"
      @next-step="toggleStep"
      @prev-step="prevStep"
    />
  </div>

  <Modal
    width="440px"
    centered
    v-model:open="isRejectModal"
    :bodyStyle="{ marginBottom: '24px' }"
    title="Отменить создание торга на продажу?"
    okText="Отменить создание"
    cancelText="Вернуться к созданию"
    @ok="router.push('/auctions')"
  >
    Внесённые данные не будут сохранены
  </Modal>
  <Modal
    width="440px"
    centered
    v-model:open="isPublicAuction"
    :bodyStyle="{ marginBottom: '24px' }"
    title="Опубликовать торг на продажу для всех?"
    okText="Опубликовать для всех"
    cancelText="Закрыть"
    @ok="createAuction(true)"
    :confirmLoading="isAuctionSending"
    >К торгу на продажу сможет присоединиться любой желающий покупатель</Modal
  >
  <Modal
    width="440px"
    centered
    v-model:open="isPrivateAuction"
    :bodyStyle="{ marginBottom: '24px' }"
    title="Опубликовать торг на продажу?"
    okText="Опубликовать"
    cancelText="Закрыть"
    @ok="createAuction(false)"
    :confirmLoading="isAuctionSending"
  >
    Только выбранные покупатели смогут принять участие в торге на продажу
  </Modal>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.create-auction-view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.confirm-request-modal,
.reject-request-modal {
  :deep(.wrapper) {
    padding: 24px;
  }
  :deep(.modal-header) {
    span {
      @include h4();
      color: $gray-800;
    }
  }
  :deep(.modal-body) {
    margin-top: 8px;
    margin-bottom: 32px;
    span {
      @include p5();
      color: $gray-700;
    }
  }
  :deep(.modal-container) {
    width: 440px;
    min-width: 440px;
    height: auto;
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-container div) {
    box-sizing: border-box;
    height: 100%;
  }
  :deep(.modal-footer) {
    display: flex;
    gap: 8px;
    .ant-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 8px;
    }
  }
  .button-loading {
    transform: scale(0.5);
  }
}
</style>
