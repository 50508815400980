<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { Drawer, Flex } from 'ant-design-vue'
import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'
import DataListBlock from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/DataListBlock.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'

export default defineComponent({
  name: 'CustomerRequestDataTabDrawerSuppliers',
  components: {
    Drawer,
    CustomerRequestHeaderActionButton,
    DataListBlock,
    Flex,
  },
  setup() {
    const visible = ref<boolean>(false)
    const store = useCustomerRequestStore()

    const recipientsGreen = ref([])
    const recipientsYellow = ref([])
    const recipientsRed = ref([])

    const showDrawer = () => {
      visible.value = true
    }

    const sortRecipients = async () => {
      const recipients = computed(
        () => store.currentCustomerRequest?.recipients,
      ).value
      //@ts-ignore
      recipientsGreen.value = recipients?.filter(
        (recipient) => recipient.answer_status === 'answered',
      )
      //@ts-ignore
      recipientsYellow.value = recipients?.filter((recipient) =>
        ['received', 'waiting'].includes(recipient.answer_status),
      )
      //@ts-ignore
      recipientsRed.value = recipients?.filter(
        (recipient) => recipient.answer_status === 'refused',
      )
    }

    onMounted(() => {
      sortRecipients()
    })

    watch(visible, () => {
      sortRecipients()
    })

    return {
      visible,
      showDrawer,
      recipientsGreen,
      recipientsYellow,
      recipientsRed,
    }
  },
})
</script>

<template>
  <CustomerRequestHeaderActionButton
    type="AllSuppliers"
    @handle-click="showDrawer"
  />
  <Drawer
    title="Все поставщики"
    placement="right"
    :closable="true"
    v-model:open="visible"
    :bodyStyle="{ padding: '24px 0' }"
    :width="500"
  >
    <Flex vertical :gap="24">
      <DataListBlock :items="recipientsGreen" title="Ответили на запрос" />
      <DataListBlock :items="recipientsYellow" title="Не ответили" />
      <DataListBlock :items="recipientsRed" title="Отказались" />
    </Flex>
  </Drawer>
</template>
