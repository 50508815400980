<script lang="ts" setup>
import { AccountTabs } from '@/types/enums'
import person from '@/assets/img/person.svg'
import myCompany from '@/assets/img/my-company.svg'
import location_on from '@/assets/img/location_on.svg'
import employees from '@/assets/img/employees.svg'
import edit_document from '@/assets/img/edit_document.svg'
import info from '@/assets/img/info.svg'

interface Props {
  tabName: AccountTabs
  tabImg: string
  disabled: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['change-tab'])

const changeTab = () => {
  emit('change-tab', props.tabName)
}

const getImg = (img: string) => {
  const imgMap = {
    'person.svg': person,
    'my-company.svg': myCompany,
    'location_on.svg': location_on,
    'employees.svg': employees,
    'edit_document.svg': edit_document,
    'info.svg': info,
  }
  return imgMap[img]
}
</script>

<template>
  <li class="tab" @click="changeTab" :class="{ disabled }">
    <div>
      <img :src="getImg(props.tabImg)" alt="tab" loading="lazy" />
    </div>
    <span>{{ props.tabName }}</span>
  </li>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.tab {
  width: 312px;
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: $gray-300;
  }

  div {
    width: 24px;
    height: 24px;
    fill: $gray-800;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    padding: 2px;
    width: 16px;
    height: 16px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    color: $gray-800;
  }
  &.disabled {
    cursor: not-allowed;
    span {
      color: #00000040;
    }
    div {
      filter: opacity(0.25);
    }
  }
}
</style>
