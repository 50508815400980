import { ContactData } from '@/types/types'
import { APIModels } from '@/types/enums'
import { GetItemsResponse, IContact } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

type TFilters = any[][] | string[] | number[] | any[]

const { cookies } = useCookies()

class ContactAPIModel {
  async createManyContacts(data: ContactData[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.Contact,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getItemsContacts(
    filters: TFilters = [],
    page = 1,
    perPage = 10,
    sort: string[][] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Contact,
      method: EgalMethods.GetItems,
      data: { filter: filters, page, perPage, sort },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async updateContact(data: ContactData): Promise<any> {
    return RequestManager.request({
      model: APIModels.Contact,
      method: EgalMethods.update,
      data: { attributes: { countID: data.countID, id: data.id, name: data.name, phone: data.phone, post: data.post}},
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getItems(
    page = 1,
    perPage = 10,
    filter: (string | object)[] = [],
    withs: string[] = [],
    order?: string[][],
  ): Promise<GetItemsResponse<IContact>> {
    return RequestManager.request({
      model: APIModels.Contact,
      method: EgalMethods.GetItems,
      data: { pagination: { per_page: perPage, page }, filter, withs, order},
      headers: { Authorization: cookies.get('core') }
    })
  }

  async deleteContact(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Contact,
      method: EgalMethods.delete,
      data: { id: data.id },
      headers: { Authorization: cookies.get('core') }
    })
  }
}

const ContactAPI = new ContactAPIModel()
export { ContactAPI }
