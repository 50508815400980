<script lang="ts" setup>
import TastSuccess from '../../../assets/img/task-success.svg'
import { EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons-vue'
import { ICompanyRating } from '@/types/interfaces'
import { Tag } from 'ant-design-vue'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import { AuditOutlined } from '@ant-design/icons-vue'
import { formatPhoneNumber } from '@/helpers/scripts'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import {useCookies} from "vue3-cookies";

interface IProps {
  name: string
  legal_address: string
  contact_number: string
  vat: boolean | null
  can_deliver: boolean | null
  average_mark: number
  mark_count: number
}

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()
const props = defineProps<IProps>()
</script>

<template>
  <div class="partnerData">
    <div class="partnerData__name-block">
      <img :src="TastSuccess" alt="icon" loading="lazy" />
      <span>{{ name }}</span>
    </div>
    <div class="partnerData__info-block">
      <div>
        <EnvironmentOutlined />
        <span>{{ legal_address }}</span>
      </div>
      <div>
        <PhoneOutlined />
        <span>{{ formatPhoneNumber(contact_number) }}</span>
      </div>
    </div>
    <div class="rate-tags">
      <SimpleRate
        :company_rating="
          {
            mark_count: mark_count,
            average_mark: average_mark,
          } as ICompanyRating
        "
      />
      <div class="tags" v-if="getCoreCookie()?.company_type === Roles.Customer">
        <Tag v-if="vat">
          <template #icon> <AuditOutlined /> </template>
          Налогообложение по НДС
        </Tag>
        <Tag v-if="can_deliver">
          <template #icon> <SupplierDelivery /> </template>
          Доставка
        </Tag>
        <Tag>
          <template #icon> <SelfDelivery /> </template>
          Самовывоз
        </Tag>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.partnerData {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__name-block {
    display: flex;
    gap: 4px;
    align-items: flex-start;

    img {
      position: relative;
      top: 2px;
    }
  }

  &__info-block {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    & div {
      display: flex;
      align-items: flex-start;
      gap: 4px;

      & span[role='img'] {
        position: relative;
        top: 4px;
      }
    }
  }

  .rate-tags {
    display: flex;
    align-items: center;

    .tags {
      gap: 0;

      :deep(.anticon + span) {
        margin-inline-start: 4px;
      }
    }
  }
}
</style>
