<script setup lang="ts">
import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['closeAboutAuctionModal'])

const closeModal = () => {
  emit('closeAboutAuctionModal')
}
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">О торгах на продажу</div>
    </template>
    <template #body>
      <div class="modal__body">
        <b>Торг на продажу</b> — это торги с повышением цены, которые <br />
        проводит поставщик для покупателей. Поставщик <br />
        не обязан заключать договор с победителем торга на продажу, <br />
        так же как и покупатель не обязан покупать товары <br />
        по указанным в ставках условиях. Покупатели могут <br />
        делать ставку на один товар, на список товаров или <br />
        не делать ставки вовсе.
        <div class="modal__body-stages-title">
          Проведение торга на продажу включает несколько этапов:
        </div>
        <ol class="modal__body-stages">
          <li>
            1. Поставщик создает публичный или закрытый <br />
            торг на продажу, в котором он указывает интересующие его <br />
            товары и указывает свои требования к заказу.
          </li>
          <li>
            2. Покупатели, с доступом к торгу на продажу, делают ставки <br />
            на имеющиеся товары.
          </li>
          <li>
            3. Рейтинги ставок в торгах на продажу динамически <br />
            меняются, в зависимости от ставок покупателей.
          </li>
          <li>
            4. После закрытия торга на продажу, все рейтинги <br />
            фиксируются.
          </li>
        </ol>
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal" type="default"> Закрыть </Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    display: flex;
    flex-wrap: wrap;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 440px;
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    &-stages {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-title {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(0, 0, 0, 1);
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>
