<script lang="ts" setup>
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { computed, onMounted, reactive, ref } from 'vue'
import { format, maxString, minString, required } from '@/helpers/validators'
import { emailRegEx, togglePasswordEyeIcon } from '@/types/globalConst'
import { primaryButton } from '@/assets/EgalStyles/EButton'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import { UserAPI } from '@/api/UserAPI'
import router from '@/router'
import { useRoute } from 'vue-router'
import { useTimer } from '@/composables/useTimer'
import { TypographyText as TpText, Flex } from 'ant-design-vue'
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

const activeStep = ref(1)
const { timer, startTimer, buttonText } = useTimer()

const cardHeader = computed(() =>
  activeStep.value === 2
    ? 'Ссылка для сброса пароля отправлена на почту'
    : 'Сброс пароля',
)
const cardInfo = computed(() => {
  return activeStep.value === 3
    ? 'Введите и подтвердите новый пароль для входа в систему'
    : activeStep.value === 2
      ? 'Если вы не нашли письмо, то проверьте папку спама'
      : 'Введите почту — мы отправим на неё ссылку для сброса пароля'
})

const outerError = ref('')

const errors = reactive({
  email: '',
})

const isDropPasswordValid = computed(() => {
  return !!dropPasswordForm.email && !errors.email
})

const isConfirmDropPasswordValid = computed(() => {
  return (
    confirmDropPasswordForm.password &&
    confirmDropPasswordForm.password ===
      confirmDropPasswordForm.password_confirmation
  )
})

const dropPasswordForm = reactive({
  email: '',
})
const confirmDropPasswordForm = reactive({
  token: '',
  password: '',
  password_confirmation: '',
})

const dropPasswordUser = () => {
  if (!isDropPasswordValid.value) {
    outerError.value = 'Обязательное поле'
    return
  }
  startTimer()
  UserAPI.dropPassword(dropPasswordForm)
    .then(() => {
      activeStep.value = 2
    })
    .catch(() => {
      outerError.value = 'Почта не найдена'
    })
}

const confirmDropPassword = () => {
  if (!isConfirmDropPasswordValid.value) {
    outerError.value = 'Пароли не совпадают'
    return
  }
  UserAPI.confirmDropPassword(confirmDropPasswordForm)
    .then(() => {
      router.push('/sign-in')
    })
    .catch(() => {
      outerError.value = 'Пароль слишком слабый'
    })
}

const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})
const repeatPasswordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const onClick = (ev: { target: any }, repeatPassword: boolean) => {
  const obj = repeatPassword ? repeatPasswordInput : passwordInput
  togglePasswordEyeIcon(ev.target.tagName, obj)
}

const previousStep = () => {
  if (activeStep.value === 1) router.push('/sign-in')
  if (activeStep.value === 3) activeStep.value = 1
}

onMounted(() => {
  let recoveryToken = useRoute().query?.token
  if (!recoveryToken?.length) return

  function getTokenFromQuery() {
    const route = useRoute()
    return route.query.token
  }

  const tokenQuery = getTokenFromQuery()
  console.debug('tokenQuery', tokenQuery)

  if (tokenQuery?.length) {
    confirmDropPasswordForm.token = tokenQuery as string
    activeStep.value = 3
  }

  // if (recoveryToken?.length > 0) {
  //   confirmDropPasswordForm.id = JSON.parse(
  //     atob(String(recoveryToken).split('.')[1]),
  //   ).auth_information.id
  //   activeStep.value = 3
  // }
})

const route = useRoute()
</script>

<template>
  <div class="auth-card">
    <div class="auth-card__header">{{ cardHeader }}</div>
    <p class="auth-card__info">
      {{ cardInfo }}
    </p>
    <div class="auth-card__body">
      <EInput
        v-if="activeStep === 1"
        v-model.trim="dropPasswordForm.email"
        class="ym-record-keys"
        :data="{
          ...inputDataConfig,
          id: 'email',
          label: 'Почта',
          placeholder: '',
          modelValue: dropPasswordForm.email,
          error: outerError,
          validators: [
            required,
            maxString(50),
            format(emailRegEx, 'Неверный формат'),
          ],
        }"
        @update:modelValue="() => (outerError = '')"
        @error="(err) => (errors.email = err)"
        :style-config="inputStyleConfig"
      />

      <Flex :gap="8" vertical>
        <EInput
          class="password ym-record-keys"
          v-if="activeStep === 3"
          v-model.trim="confirmDropPasswordForm.password"
          :data="{
            ...inputDataConfig,
            id: 'password',
            label: 'Пароль',
            placeholder: '',
            modelValue: confirmDropPasswordForm.password,
            type: passwordInput.type,
            iconRight: passwordInput.iconRight,
            validators: [
              required,
              maxString(24),
              minString(6),
              format(
                /[a-zA-Z]/,
                'Пароль должен содержать минимум одну букву латинского алфавита',
              ),
              format(/\d/, 'Пароль должен содержать минимум одну цифру'),
            ],
          }"
          :style-config="inputStyleConfig"
          @click="(ev) => onClick(ev, false)"
          @error="(err) => (errors.password = err)"
        />
        <TpText v-if="activeStep === 3" type="secondary"
          >Пароль должен состоять минимум из 6 символов, содержать цифру и
          латинскую букву
        </TpText>
      </Flex>
      <EInput
        class="password ym-record-keys"
        v-if="activeStep === 3"
        v-model.trim="confirmDropPasswordForm.password_confirmation"
        :data="{
          ...inputDataConfig,
          id: 'password-repeat',
          label: 'Повторите пароль',
          placeholder: '',
          modelValue: confirmDropPasswordForm.password_confirmation,
          type: repeatPasswordInput.type,
          iconRight: repeatPasswordInput.iconRight,
          validators: [required],
          error:
            !confirmDropPasswordForm.password_confirmation ||
            confirmDropPasswordForm.password ===
              confirmDropPasswordForm.password_confirmation
              ? ''
              : 'Пароли не совпадают',
        }"
        :style-config="inputStyleConfig"
        @click="(ev) => onClick(ev, true)"
        @error="(err) => (errors.password_confirmation = err)"
      />
    </div>
    <div class="auth-card__footer">
      <EButton
        @click="dropPasswordUser"
        :style="{ ...primaryButton, fontWeight: 500 }"
        v-if="activeStep === 1"
        >Продолжить
      </EButton>
      <EButton
        @click="confirmDropPassword"
        :style="{ ...primaryButton, fontWeight: 500 }"
        v-if="activeStep === 3"
        :data="{
          disabled: !isConfirmDropPasswordValid,
        }"
        >Войти
      </EButton>
      <div class="auth-card__links" v-if="activeStep !== 2">
        <div class="auth-card__links" @click="previousStep">
          <router-link to="/recovery">
            <ChevronLeftIcon />
            Назад
          </router-link>
        </div>
      </div>
    </div>

    <EButton
      @click="dropPasswordUser"
      :style="{ ...primaryButton, fontWeight: 500 }"
      v-if="activeStep === 2"
      :data="{ disabled: timer > 0 }"
    >
      {{ buttonText }}
    </EButton>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 356px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 27px;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    a {
      @include router-link();
    }
  }

  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #718096;
    margin-bottom: 40px;
    width: 101%;
  }
}

.egal-button {
  justify-content: center;
}

.password {
  :deep(.input-container .icon--right) {
    cursor: pointer;

    &:hover {
      color: $gray-700;
    }
  }
}

.eye-icon {
  color: $gray-800;
}

.back-icon {
  margin-right: 8px;
}
</style>
