<script setup lang="ts">
import { Button, Modal } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { TProductAnswerStatus } from '@/types/types'
import { computed, ref, watch } from 'vue'
import {
  IAnswerPriceOption,
  IAnswerSpecialPrice,
  IFarmerProduct,
} from '@/types/interfaces'
import { useRoute, useRouter } from 'vue-router'
import { ProductAnswerAPI } from '@/api/ProductAnswerAPI'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { getDifferenceObject } from '@/helpers/scripts'
import { AnswerPriceOptionAPI } from '@/api/AnswerPriceOptionAPI'
import { AnswerSpecialPriceAPI } from '@/api/AnswerSpecialPriceAPI'

interface Props {
  productAnswerStatus: TProductAnswerStatus
  initialAnswerPriceOptions: IAnswerPriceOption[]
  priceOptions: (IAnswerPriceOption | IAnswerSpecialPrice)[]
  farmerProduct: IFarmerProduct
  tableFormRef: any
  specialPrice?: IAnswerSpecialPrice
}
const props = defineProps<Props>()
const isLoading = ref(false)

const emit = defineEmits(['toggleSpecialPrice', 'changeProductStatus'])

const route = useRoute()
const router = useRouter()

const isEditedAnswerOptions = ref(false)
const isProductDeleted = ref(false)

const savePriceOptions = async () => {
  const updatedOptions = props.priceOptions.slice(
    0,
    props.specialPrice ? -1 : props.priceOptions.length,
  )

  const differentOptions = updatedOptions
    .map((updateOption, index) => ({
      ...getDifferenceObject(
        updateOption,
        props.initialAnswerPriceOptions[index],
      ),
      id: updateOption.id,
    }))
    .filter((option) => Object.keys(option).length > 1)

  if (differentOptions.length) {
    try {
      await AnswerPriceOptionAPI.updateMany(differentOptions)
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }
}

const saveSpecialPrice = async () => {
  let updatedSpecialPrice = props.specialPrice
    ? props.priceOptions.at(-1)
    : null

  const differenceSpecialPrice = getDifferenceObject(
    updatedSpecialPrice,
    props.specialPrice,
  )

  if (Object.keys(differenceSpecialPrice).length) {
    try {
      await AnswerSpecialPriceAPI.updateMany([
        { ...differenceSpecialPrice, id: props.specialPrice?.id },
      ])
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }
}

const handleSaveProductAnswer = async () => {
  isLoading.value = true
  try {
    await props.tableFormRef.validate().then(async () => {
      const isChangedPriceOptions = await savePriceOptions()
      const isChangedSpecialPrice = await saveSpecialPrice()

      if (props.productAnswerStatus !== 'answer_received') {
        await ProductAnswerAPI.updateMany([
          {
            id: +String(route.params.productAnswerId),
            status: 'answer_received',
          },
        ]).then(() => {
          isEditedAnswerOptions.value = false
          emit('changeProductStatus', 'answer_received')
        })
      }

      router.back()
    })
    if (await checkIsProductDeleted()) {
      isProductDeleted.value = true
      return
    }
  } catch (err) {
    console.log(err)
    return
  } finally {
    isLoading.value = false
  }
}

const handleCancel = async () => {
  if (await checkIsProductDeleted()) {
    isProductDeleted.value = true
    return
  }
  ProductAnswerAPI.updateMany([
    {
      id: +String(route.params.productAnswerId),
      status: 'no_answer',
    },
  ]).then(() => {
    const index = window.location.pathname.lastIndexOf('/')
    router.push(window.location.pathname.slice(0, index))
  })
}

const saveIsDisabled = computed(() => {
  return (
    props.productAnswerStatus !== 'answer_waiting' &&
    !isEditedAnswerOptions.value
  )
})

const checkIsProductDeleted = async () => {
  return await FarmerProductAPI.getItem(props.farmerProduct.id ?? 0).then(
    (res) => res.is_deleted,
  )
}
watch(props.priceOptions, () => {
  isEditedAnswerOptions.value = true
})
</script>

<template>
  <div class="footer-wrapper">
    <div class="footer">
      <Button :onClick="handleCancel" danger type="link">
        <template #icon>
          <CloseOutlined />
        </template>
        Не поставлю
      </Button>
      <div class="footer__btns-block">
        <Button
          :disabled="!!props.specialPrice"
          :onClick="() => emit('toggleSpecialPrice', true)"
          >Добавить спеццену</Button
        >
        <Button
          :onClick="handleSaveProductAnswer"
          :disabled="saveIsDisabled"
          type="primary"
          :loading="isLoading"
          >Сохранить</Button
        >
      </div>
    </div>
  </div>
  <Modal
    v-model:open="isProductDeleted"
    title="Невозможно сохранить ответ,товар был удалён из вашего каталога"
    okText="Закрыть"
    closable
    width="440px"
    @cancel="router.go(-1)"
    centered
    class="ant-modal-fix-header"
  >
    <div class="modal_body">
      <TpText>{{ props.farmerProduct.fullname }}</TpText>
    </div>
    <template #footer>
      <Button type="primary" @click="router.go(-1)"> Закрыть </Button>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

@import '@/assets/style/mixins.scss';

.footer-wrapper {
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 80px;

  .footer {
    @include wrapper();
    padding: 24px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.059);
    background: white;
    width: 100%;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
    margin: 0;
    &__caption {
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
    }

    &__btns-block {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}
</style>
