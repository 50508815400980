<script lang="ts" setup>


interface IProps {
  bestOffer: {
    price: string | number
    company_name: string | null
  } | null
}

const props = defineProps<IProps>()
</script>

<template>
  <div class="best-offer-cell">
    <template v-if="props.bestOffer?.price">
      <div class="best-offer-cell__price" v-if="props.bestOffer.price">
        {{ props.bestOffer.price }} ₽
      </div>
      <div class="best-offer-cell__company" v-if="props.bestOffer.company_name">
        {{ props.bestOffer.company_name }}
      </div>
    </template>
    <div class="best-offer-cell__no-offers" v-else>Нет ставок</div>
  </div>
</template>

<style lang="scss" scoped>
.best-offer-cell {
  display: flex;
  flex-direction: column;
  &__price {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.88);
  }
  &__company {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.88);
  }
  &__no-offers {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
