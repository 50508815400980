<script setup lang="ts">

import { IFarmer } from '@/types/interfaces'
import { Space, TypographyText as TgText } from 'ant-design-vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import PartnershipTooltip from "@/components/Tooltip/PartnershipTooltip.vue";
import router from '@/router'
import SimpleRate from "@/components/Rating/SimpleRate.vue";

interface IProps {
  farmer: IFarmer | null
  isPartner?: boolean
}
const props = defineProps<IProps>()

const navToFarmer = () => {
  router.push(`/farmer-product/${props.farmer?.id}`)
}
</script>

<template>
  <Space direction="vertical" :size="4">
    <Space :size="8" align="start">
      <PartnershipTooltip
        v-if="isPartner"
      />
      <TgText @click="navToFarmer" class="link">{{
        `${farmer?.legal_form} ${farmer?.name}`
      }}</TgText>
    </Space>
    <Space :size="8" align="start">
      <EnvironmentOutlined :style="{ color: '#00000073' }" />
      <span class="farmer-address">{{ farmer?.legal_address }}</span></Space
    >
    <SimpleRate :company_rating="farmer?.company_rating"/>
  </Space>
</template>

<style scoped lang="scss">
.farmer-address {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000a6;
}

.link {
  cursor: pointer;
  &:hover {
    color: #1677ff;
  }
  &:active {
    color: #0958d9;
  }
}
</style>
