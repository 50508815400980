<script setup lang="ts">
import { ref, onMounted } from 'vue'

interface IProps {
  items: any
}

const emit = defineEmits([])
const props = defineProps<IProps>()

const activeItemId = ref<string>('0')
const offset = 180

const scrollToAnchor = (anchorId: string) => {
  const anchorElement = document.getElementById(anchorId)
  if (anchorElement) {
    const offsetPosition = anchorElement.offsetTop - offset
    window.scroll({
      top: offsetPosition,
      behavior: 'smooth',
    })
    activeItemId.value = String(anchorId)
  }
}

onMounted(() => {
  window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY
    const itemElements = props.items.map((item: any) =>
      document.getElementById(item.id),
    )
    for (const itemElement of itemElements) {
      if (itemElement) {
        const itemOffset = itemElement.offsetTop
        if (scrollPosition >= itemOffset - offset) {
          activeItemId.value = String(itemElement.id)
        }
      }
    }
  })
})
</script>

<template>
  <div class="faq-nav">
    <div
      :class="[
        'faq-nav__item',
        { 'faq-nav__item_active': String(item.id) === String(activeItemId) },
      ]"
      v-for="item in props?.items"
      :key="item.id"
      @click="scrollToAnchor(item.id)"
    >
      {{ item.title }}
    </div>
    <div class="faq-nav__help help">
      <div class="help__text">
        Если у вас возникли проблемы — обратитесь в техподдержку
      </div>
      <a href="mailto:manager@easy-retail.org" class="help__link">
        <img src="../components/assets/mail.svg" alt="mail" loading="lazy" />
        manager@easy-retail.org
      </a>
      <a href="tel:+79138711239" class="help__link">
        <img src="../components/assets/phone.svg" alt="phone" loading="lazy" />
        +7 913 871-12-39
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">

.help {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px;
  gap: 10px;
  &__link {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    display: flex;
    gap: 5px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
  }
  &__text {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
}

.faq-nav {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.88);
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    border-left: 2px solid rgba(0, 0, 0, 0.04);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    min-height: 30px;
    &_active {
      color: rgba(22, 119, 255, 1);
      border-left: 2px solid rgba(22, 119, 255, 1);
    }
    &:hover {
      color: rgba(22, 119, 255, 1);
    }
  }
}
</style>
