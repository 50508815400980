import { remove } from 'lodash'
import { defineStore } from 'pinia'

type TItemErrorName = 'quantity'

interface IItemError {
  itemId: string
  errors: TItemErrorName[]
}

interface ICartStore {
  itemErrors: IItemError[]
}

export const useCartStore = defineStore('userCartStore', {
  state: (): ICartStore => ({
    itemErrors: [],
  }),
  actions: {
    addItemError(itemId: string, errors: TItemErrorName[]) {
      this.itemErrors.push({ itemId, errors })
    },
    removeItemError(itemId: string) {
      this.itemErrors = this.itemErrors.filter(
        (itemError) => itemError.itemId !== itemId,
      )
    },
    removeError(itemId: string, errors: TItemErrorName[]) {
      this.itemErrors.find((itemError) => {
        if (itemError.itemId === itemId) {
          remove(itemError.errors, (error) => errors.includes(error))
        }
      })
    },
  },
})
