<script setup lang="ts">
import { Flex, TypographyText as TgText } from 'ant-design-vue'
import { IAddress, ICompanyView, IContact } from '@/types/interfaces'
import CardData from '@/views/Admin/UserView/CardData.vue'
import { onMounted, ref } from 'vue'
import { ContactAPI } from '@/api/ContactAPI'
import { AddressAPI } from '@/api/AddressAPI'
import AddressList from '@/views/Admin/UserView/AddressList.vue'
import CompanyGeneralData from '@/views/Admin/UserView/CompanyGeneralData.vue'

interface IProps {
  company: ICompanyView
}

const props = defineProps<IProps>()
const contacts = ref<IContact[]>([])
const is_contacts_loading = ref(false)
const addresses = ref<IAddress[]>([])
const is_addresses_loading = ref(false)

const get_data = async () => {
  if (!props.company.company_id) return
  is_contacts_loading.value = true
  is_addresses_loading.value = true

  await ContactAPI.getItemsContacts(
    [
      ['company_id', 'eq', props.company.company_id],
      'AND',
      ['company_type', 'eq', props.company.company_type],
    ],
    1,
    100,
    [['name', 'asc']],
  ).then((res) => {
    contacts.value = res.items
    is_contacts_loading.value = false
  })

  await AddressAPI.getAddresses(
    String(props.company.company_id),
    props.company.company_type,
  ).then((res) => {
    addresses.value = res.items
    is_addresses_loading.value = false
  })
}

onMounted(() => {
  get_data()
})
</script>

<template>
  <Flex vertical :gap="24">
    <CardData>
      <CompanyGeneralData :company="company" />
    </CardData>

    <CardData
      title="Адреса доставки"
      :loading="is_addresses_loading"
      :empty="!addresses.length"
      empty_title="Адреса доставки не указаны"
      empty_subtitle="Компания пока что не создала ни одного адреса доставки"
    >
      <AddressList :addresses="addresses" />
    </CardData>

    <CardData title="Описание организации" v-if="company?.company?.description">
      <TgText>{{ company?.company?.description }}</TgText>
    </CardData>
  </Flex>
</template>

<style scoped lang="scss"></style>
