<script lang="ts" setup>
import { computed, reactive } from 'vue'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import EgalInput from '@/components/Egal/Input/EInput.vue'
import {
  constLength,
  onlyNonZeroNumber,
  onlyNumber,
  requiredWithText,
} from '@/helpers/validators'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const emit = defineEmits(['next'])
const toStep = () => {
  cookies.set('activeBank', stepForm4.bic, '365d', '')
  cookies.set('threeAlt', String(true), '365d', '')
  emit('next')
}

const stepForm4 = reactive<{ bic: string }>({
  bic: '',
})

const bicValidation = {
  validators: [
    onlyNumber,
    requiredWithText('Укажите БИК'),
    onlyNonZeroNumber,
    constLength(9),
  ],
  maxLength: 9,
}
const step4Errors = reactive({
  bic: false,
})

const isError = computed(() => {
  return stepForm4.bic && stepForm4.bic.length === 9
})
</script>

<template>
  <div class="first-step__card">
    <div class="first-step__card-header">
      Укажите данные для последующей проверки
      <div class="step">
        <span>этап 4</span>
        <span class="max">/5</span>
      </div>
    </div>
    <div class="first-step__card-body">
      <p class="info-row">
        Сейчас мы не можем проверить ваши данные, пожалуйста, заполните поля для
        последующей проверки. Как только ваши данные будут проверены — мы
        отправим вам уведомление.
      </p>
      <div class="form-row">
        <EgalInput
          v-model="stepForm4.bic"
          :data="{
            ...inputDataConfig,
            id: 'bic',
            label: 'БИК банка',
            inputMaxLength: bicValidation.maxLength,
            placeholder: '',
            modelValue: stepForm4.bic,
            validators: bicValidation.validators,
            error: step4Errors.bic ? 'Укажите БИК' : '',
            type: 'number',
          }"
          :style-config="inputStyleConfig"
          @update:modelValue="() => (step4Errors.bic = false)"
        />
      </div>
    </div>
    <div class="first-step__card-footer">
      <EButton
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
        }"
        @click="toStep"
        :data="{
          disabled: !isError,
        }"
      >
        Отправить на проверку
      </EButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
.first-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;
  &-header {
    position: relative;
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .info-row {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
    .info-item {
      color: $default-accent;
    }
    .form-row {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 16px;
      margin-top: 48px;
    }
  }
  &-footer {
    margin-top: 40px;
  }
}
</style>
