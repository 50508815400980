import { Rule } from 'ant-design-vue/es/form'
import { FileType } from 'ant-design-vue/es/upload/interface'

const checkingAccountValidator = (_rule: Rule, value: string) => {
  if (!value) {
    return Promise.reject('Обязательное поле')
  }
  if (value.length < 20) {
    return Promise.reject('Минимум 20 символов')
  }
  return Promise.resolve()
}

const contactNumberValidator = (_rule: Rule, value: string) => {
  if (!value) {
    return Promise.reject('Обязательное поле')
  }
  if (value.length < 16) {
    return Promise.reject('Минимум 11 символов')
  }
  return Promise.resolve()
}

const uploadValidator = (_rule: Rule, value: FileType[]) => {
  if (!value || !value[0]) {
    return Promise.reject('Прикрепите файл')
  }
  if (value[0].size / 1024 / 1024 > 10) {
    return Promise.reject('Максимальный размер документа 10 Мб')
  }
  if (value[0].name.length > 200) {
    return Promise.reject('Слишком длинное имя для файла')
  }
  return Promise.resolve()
}

export const streetValidator = (_rule: Rule, value: string) => {
  if (!value) {
    return Promise.reject('Обязательное поле')
  }
  if (value.length > 128) {
    return Promise.reject('Максимум 128 символов')
  }
  return Promise.resolve()
}

export const rules: Record<string, Rule[]> = {
  checking_account: [
    {
      required: true,
      validator: checkingAccountValidator,
      trigger: 'change',
    },
  ],
  contact_number: [
    {
      required: true,
      validator: contactNumberValidator,
      trigger: 'change',
    },
  ],
  description: [
    { max: 1000, message: 'Максимум 1000 символов', trigger: 'change' },
  ],
  upload: [
    {
      required: true,
      validator: uploadValidator,
      trigger: 'change',
    },
  ],
}
