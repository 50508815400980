<script setup lang="ts">
import {
  Divider,
  Flex,
  Popover,
  Table,
  Tag,
  TypographyText as TpText,
  Checkbox,
  RadioGroup,
} from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { computed, watch } from 'vue'
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons-vue'
import {
  priceWithoutVat,
  roundNumber,
  splitNumberByThreeDigitsNumber,
} from '@/helpers/scripts'
import dayjs from 'dayjs'
import {
  IAnswerPriceOption,
  IPriceRequest,
  IProduct,
  IProductAnswer,
  IProductRequest,
} from '@/types/interfaces'
import { requestOrderCompositionStore } from '@/store/requestOrderCompositionStore'
import TpTitle from '@/components/Title/TpTitle.vue'

interface ICustomPriceOption extends IAnswerPriceOption {
  product?: IProduct
  productAnswer: IProductAnswer
  productRequest?: IProductRequest
}

interface Props {
  products: IProduct[]
  productRequest: IProductRequest[]
  productAnswers: IProductAnswer[]
}

const props = defineProps<Props>()

const store = requestOrderCompositionStore()

const getProductCustomCell = (record: ICustomPriceOption, index: number) => {
  const [firstIndex, lastIndex] = getProductInterval(
    Number(record?.product?.id),
  )
  if (firstIndex === index) {
    return { rowSpan: lastIndex - firstIndex + 1 }
  } else {
    return { rowSpan: 0 }
  }
}

const getCustomPriceCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received'
    ? {
        colSpan: 7,
      }
    : {
        colSpan: 1,
      }
}

const sharedOnCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received'
    ? {
        colSpan: 0,
      }
    : {
        colSpan: 1,
      }
}

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '25%',
    customCell: getProductCustomCell,
  },
  {
    title: 'Цена без НДС',
    key: 'price',
    width: '10%',
    customCell: getCustomPriceCell,
  },
  {
    title: 'Цена с НДС',
    key: 'priceWithVAT',
    width: '15%',
    customCell: sharedOnCell,
  },
  {
    title: 'Объём',
    key: 'volume',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Остаточный срок хранения',
    key: 'remaining_shelf_life',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: `Пробный образец`,
    key: 'samples_needed',
    colSpan: 2,
    width: '5%',
    customCell: sharedOnCell,
  },
  {
    title: '',
    colSpan: 0,
    key: 'samples_amount',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Итого с НДС',
    key: 'total',
    width: '10%',
    customCell: sharedOnCell,
  },
]

const getPriceInterval = (
  minimalVolume: string,
  nextMinimalVolume: string | undefined,
  measurmentUnitId: string,
  isSpecialPrice: boolean,
) => {
  return isSpecialPrice
    ? `на ${roundNumber(minimalVolume)} ${measurmentUnitId}`
    : (nextMinimalVolume
        ? `${roundNumber(minimalVolume)}-${roundNumber(nextMinimalVolume)}`
        : `от ${roundNumber(minimalVolume)}`) + ` ${measurmentUnitId}`
}

const getProductInterval = (productId: number) => {
  const firstIndex = tableData.value.findIndex(
    (item) => item.product?.id === productId,
  )
  const lastIndex = tableData.value.findLastIndex(
    (item: ICustomPriceOption) => item?.product?.id === productId,
  )

  return [firstIndex, lastIndex]
}

const getPriceWithVAT = (price: number, vat: number) => {
  return roundNumber(price * (1 + vat / 100))
}

const getUnitId = (record: ICustomPriceOption) => {
  return fillMeasurementUnitId(record?.product)
}

const fillMeasurementUnitId = (item: any) => {
  if (item.product_category?.measurement_unit_id === null) {
    const productText = item.parameters
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    return item.measurement_unit_id
  } else return item.product_category?.measurement_unit_id
}

const getCustomRow = (record: any) => {
  if (store.checkDisabled(record.productRequest.product_id)) {
    return {
      style: { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
    }
  }
  return {}
}

function getProductsCountLabel(count: number): string {
  const lastDigit = count % 10
  const lastTwoDigits = count % 100

  let word: string

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    word = 'товаров'
  } else {
    switch (lastDigit) {
      case 1:
        word = 'товар'
        break
      case 2:
      case 3:
      case 4:
        word = 'товара'
        break
      default:
        word = 'товаров'
        break
    }
  }

  return `${count} ${word}`
}

const tableData = computed(() => {
  const priceOptionsArr: ICustomPriceOption[] = []

  const statuses = {
    answer_received: 3,
    answer_waiting: 2,
    no_answer: 1,
    no_product: 0,
  }

  const sortedProductAnswers = JSON.parse(
    JSON.stringify(props.productAnswers),
  ).sort(
    (product1: IProductAnswer, product2: IProductAnswer) =>
      statuses[product2.status] - statuses[product1.status],
  )

  sortedProductAnswers.forEach((productAnswer: IProductAnswer) => {
    const productRequest = props.productRequest.find(
      (productReq: IProductRequest) =>
        productReq.product_id === productAnswer.product_id,
    )
    const product = props.products.find(
      (product) => product.id === productAnswer.product_id,
    )

    const additionalInfo = {
      productAnswer,
      product,
      productRequest,
    }

    if (productAnswer.status === 'answer_received') {
      productAnswer.price_options?.forEach((priceOption: any) => {
        priceOption.status &&
          priceOptionsArr.push({
            ...priceOption,
            ...additionalInfo,
          })
      })

      if (productAnswer.special_price && productAnswer.special_price.status) {
        priceOptionsArr.push({
          ...productAnswer.special_price,
          ...additionalInfo,
        })
      }
    } else {
      priceOptionsArr.push({
        ...additionalInfo,
      })
    }
  })

  return priceOptionsArr
})

watch(
  () => store.checkboxState.checkedList,
  (val) => {
    store.checkboxState.checkAll =
      val.length ===
      store
        .getAvaliableProducts(props.productAnswers)
        .map((productAnswer) => productAnswer.id).length
  },
)
</script>

<template>
  <Table
    bordered
    :columns="columns"
    :data-source="tableData"
    :pagination="false"
    :custom-row="getCustomRow"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key === 'product'">
        <Checkbox
          v-model:checked="store.checkboxState.checkAll"
          @change="(e) => store.onCheckAllChange(e, productAnswers)"
          >Выбрать все ({{
            getProductsCountLabel(
              store.getAvaliableProducts(props.productAnswers).length,
            )
          }})</Checkbox
        >
      </template>
    </template>
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'product'">
        <Flex justify="space-between">
          <div>
            <Flex vertical>
              <Checkbox
                :disabled="
                  store.checkDisabled(
                    record.productRequest.product_id,
                    record.productRequest.customer_needed_volume,
                  )
                "
                :checked="
                  store.checkboxState.checkedList.some(
                    (value) => value === record.productAnswer.id,
                  )
                "
                @change="(e) => store.onCheckChange(e, productAnswers)"
                :value="record.productAnswer.id"
              >
                <Tag
                  v-if="store.checkDisabled(record.productRequest.product_id)"
                  color="default"
                >
                  <template #icon>
                    <ExclamationCircleOutlined />
                  </template>
                  Товар закончился
                </Tag>
                <TpTitle v-else :level="5"
                  >{{ record.product.fullName }}
                </TpTitle>
              </Checkbox>
              <TpTitle
                v-if="store.checkDisabled(record.productRequest.product_id)"
                :level="5"
                style="color: rgba(0, 0, 0, 0.65)"
                >{{ record.product.fullName }}</TpTitle
              >
              <div>
                <TpText type="secondary"
                  >{{
                    `Объём ${
                      record.productRequest.customer_needed_volume
                    } ${getUnitId(record)}`
                  }}
                </TpText>
                <Divider type="vertical" />
                <TpText type="secondary"
                  >{{
                    record.productRequest.remaining_shelf_life ? `Срок хранения ${record.productRequest.remaining_shelf_life}%` : 'Срок хранения не указан'
                  }}
                </TpText>
                <Divider type="vertical" />
                <TpText type="secondary"
                  >{{
                    `Пробный образец ${
                      record.productRequest?.needed_samples_number
                        ? record.productRequest?.needed_samples_number
                        : 'Любой объём'
                    } ${record.productRequest?.needed_samples_number ? getUnitId(record) : ''}`
                  }}
                </TpText>
              </div>
            </Flex>
          </div>
          <Flex vertical>
            <RadioGroup
              v-if="
                store.radioState.find(
                  (radio) => radio.productAnswerId === record.productAnswer.id,
                )?.options?.length > 1
              "
              :disabled="
                !store.checkboxState.checkedList.some(
                  (value) => value === record.productAnswer.id,
                )
              "
              v-model:value="
                store.radioState.find(
                  (radio) => radio.productAnswerId === record.productAnswer.id,
                ).value
              "
              :options="
                store.radioState.find(
                  (radio) => radio.productAnswerId === record.productAnswer.id,
                )?.options
              "
            />
          </Flex>
        </Flex>
      </template>
      <template v-if="record.productAnswer.status === 'answer_received'">
        <template v-if="column.key === 'price'">
          <Flex vertical class="price-cell">
            <Popover
              v-if="!!record.comment"
              placement="topLeft"
              class="comment-popover"
            >
              <template #content>
                <Flex vertical class="commentary-block">
                  <TpText strong>Комментарий</TpText>
                  <span>{{ record.comment }}</span>
                </Flex>
              </template>
              <InfoCircleOutlined
                :style="{
                  color: 'rgba(22, 119, 255, 1)',
                }"
              />
            </Popover>
            <span>{{
              `${roundNumber(priceWithoutVat(Number(record.price_per_unit), record.vat_percent))} ₽/${getUnitId(record)}`
            }}</span>
            <TpText
              v-if="record.minimal_volume || record.special_volume"
              type="secondary"
            >
              {{
                getPriceInterval(
                  record.minimal_volume || record.special_volume,
                  tableData[index + 1]?.minimal_volume,
                  getUnitId(record),
                  !!record.customer_id,
                )
              }}
            </TpText>
            <Flex v-if="record.customer_id" vertical gap="8">
              <TpText v-if="record.expiration_date" type="secondary"
                >до
                {{ dayjs(record.expiration_date).format('DD.MM.YYYY') }}
              </TpText>

              <span>
                <Tag color="success">
                  <template #icon>
                    <StarFilled />
                  </template>
                  Спеццена
                </Tag>
              </span>
            </Flex>
          </Flex>
        </template>
        <template v-else-if="column.key === 'priceWithVAT'">
          <Flex vertical>
            <TpText strong>
              {{
                `${roundNumber(record.price_per_unit)}₽/${getUnitId(record)}`
              }}
            </TpText>
            <TpText type="secondary">{{ `НДС ${record.vat_percent}%` }}</TpText>
          </Flex>
        </template>
        <template v-else-if="column.key === 'volume'">
          <TpText class="cellCentered onlyX"
            >{{ `${record.volume} ${getUnitId(record)}` }}
          </TpText>
        </template>
        <template v-else-if="column.key === 'remaining_shelf_life'">
          <TpText class="cellCentered onlyX"
            >{{ record.remaining_shelf_life ? `${record.remaining_shelf_life}%` : '-' }}
          </TpText>
        </template>
        <template v-else-if="column.key === 'samples_needed'">
          <div class="cellCentered">
            <CheckOutlined class="icon check" v-if="record.is_sample_needed" />
            <CloseOutlined class="icon close" v-else />
          </div>
        </template>
        <template v-else-if="column.key === 'samples_amount'">
          <TpText v-if="!!record.samples_number" class="cellCentered onlyX">
            {{ `${record.samples_number} ${getUnitId(record)}` }}
          </TpText>
          <TpText v-else type="secondary" class="cellCentered onlyX">–</TpText>
        </template>
        <template v-else-if="column.key === 'total'">
          <TpText strong class="cellCentered onlyX">
            {{
              `${splitNumberByThreeDigitsNumber(
                record.volume * record.price_per_unit,
                2,
              )} ₽`
            }}
          </TpText>
        </template>
      </template>
      <template v-else>
        <template v-if="column.key === 'price'">
          <TpText
            v-if="record.productAnswer.status === 'no_answer'"
            class="cellCentered onlyX"
          >
            Отметил товар как неинтересующий
          </TpText>
          <TpText
            v-else-if="record.productAnswer.status === 'no_product'"
            class="cellCentered onlyX"
          >
            У поставщика нет этого товара
          </TpText>
        </template>
      </template>
    </template>
  </Table>
</template>

<style lang="scss" scoped>
:deep(.ant-table-cell) {
  font-weight: 400 !important;
  text-align: start !important;
}
:deep(.ant-radio-wrapper) {
  width: 40px;
  height: 77px;
  display: flex;
  margin-right: 0;
  align-items: center;
  justify-content: center;

  //& span:not(.ant-radio) {
  //  display: none;
  //}
}
.icon {
  font-size: 16px;

  &.close {
    color: rgba(0, 0, 0, 0.45);
  }
  &.check {
    color: rgba(22, 119, 255, 1);
  }
}
.cellCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.onlyX {
    left: 0;
    transform: translate(16px, -50%);
  }
}

.commentary-block {
  max-width: 356px;
}

.price-cell {
  position: relative;

  .comment-popover {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
