import { APIModels, SuggestionTypes } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class DadataAPIModel {
  async getSuggests(type: SuggestionTypes, query: string): Promise<any> {
    return RequestManager.request({
      model: APIModels.Dadata,
      method: type,
      data: { attributes: { query }},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async ping(): Promise<any> {
    return RequestManager.request({
      model: APIModels.Dadata,
      method: EgalMethods.ping,
      data: {},
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const DadataAPI = new DadataAPIModel()
export { DadataAPI }
