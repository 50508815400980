<script setup lang="ts">
import { computed } from 'vue'
import { Badge, Button, Space } from 'ant-design-vue'
import {
  IReductionParticipant,
  IReducSocketMessage,
} from '@/types/interfaces'
import { declensionBets } from '@/helpers/scripts'
import PeopleIcon from '@/components/Icons/PeopleIcon.vue'

interface IProps {
  isPublic: boolean
  participants: IReductionParticipant[]
  socketData?: IReducSocketMessage | null
}
const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])

const numberOfParticipants = computed(() => {
  if (props.socketData?.total) {
    return props.socketData.total
  }
  return props.participants.length
})

const hasBet = computed(() => {
  if (props.socketData?.with_bet) {
    return props.socketData.with_bet
  }
  return props.participants.filter((item) => item.answer_status === 'has_bet')
    .length
})

const hasNotBet = computed(() => {
  if (
    props.socketData?.without_bet != null &&
    !isNaN(props.socketData?.without_bet)
  ) {
    return props.socketData.without_bet
  }
  return props.participants.length - hasBet.value
})
</script>

<template>
  <template v-if="isPublic">
    <Badge
      status="success"
      :text="`${hasBet} ${declensionBets(hasBet)}, публичный торг на покупку`"
    />
  </template>
  <template v-else>
    <Space :size="24">
      <Badge
        status="success"
        :text="`Сделали ставку ${hasBet}/${numberOfParticipants}`"
      />
      <Badge
        status="warning"
        :text="`Не сделали ставку ${hasNotBet}/${numberOfParticipants}`"
      />
      <Button type="link" @click="emit('onBtnClick')">
        <template #icon>
          <PeopleIcon />
        </template>
        Все поставщики
      </Button>
    </Space>
  </template>
</template>

<style scoped lang="scss"></style>
