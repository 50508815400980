<script lang="ts" setup>
import { ProfileStatus } from '@/types/enums'
import {computed, onMounted, ref} from 'vue'
import router from '@/router'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import { useJWTDecode } from '@/composables/useJWTDecode'
import ShieldIcon from '@/components/Icons/ShieldIcon.vue'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import DotsIcon from '@/components/Icons/DotsIcon.vue'
import { formatPhoneNumber } from '@/helpers/scripts'
import { IAccountUser } from '@/types/interfaces'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import verified from '@/assets/img/profile/profile-verified.svg'
import unverified from '@/assets/img/profile/profile-unverified.svg'
import verifying from '@/assets/img/profile/profile-verifying.svg'
import needToVerify from '@/assets/img/profile/profile-needToVerify.svg'
import { Tooltip } from 'ant-design-vue'

interface Props {
  status: ProfileStatus
  totalUserData: IAccountUser | Record<string, string>
  userData: any
}

const props = defineProps<Props>()
const phoneNumber = ref('')
const isNotUploadDocuments = localStorage.getItem('isNotUploadDocuments')

const { getCoreCookie } = useJWTDecode()

onMounted(() => {
  phoneNumber.value = formatPhoneNumber(props.totalUserData.contact_number)
})

const getProfileStatus = (status: ProfileStatus) => {
  const profileStatusMap = {
    [ProfileStatus.Verified]: {
      status: ProfileStatus.Verified,
      class: 'status-wrapper',
      alt: 'person',
      src: verified,
      tooltipText: '',
      innerClass: 'status-display status-display-verified',
    },
    [ProfileStatus.Unverified]: {
      status: ProfileStatus.Unverified,
      alt: 'person',
      class: 'status-wrapper',
      src: unverified,
      tooltipText: 'Администратор проверит ваши данные в ближайшее время',
      innerClass: 'status-display status-display-unverified',
    },
    [ProfileStatus.Verifying]: {
      status: ProfileStatus.Verifying,
      alt: 'person',
      class: 'status-wrapper',
      src: verifying,
      tooltipText:
        'Мы отправим вам уведомление, как только проверим юридические документы',
      innerClass: 'status-display status-display-verifying',
    },
    [ProfileStatus.NeedToVerify]: {
      status: ProfileStatus.NeedToVerify,
      alt: 'person',
      class: 'status-wrapper',
      src: needToVerify,
      tooltipText:
        'Перейдите к верификации аккаунта, чтобы получить полный доступ к платформе',
      innerClass: 'status-display status-display-needToVerify',
    },
  }
  return profileStatusMap[status]
}

const isOpenTooltip = ref(false)

const isShowButton = computed(() => {
  if (isNotUploadDocuments === 'true') {
    if (props.userData?.status === 'confirmed') {
      return true
    }
    if (props.userData?.status === 'need_verify') {
      return true
    }
  }
  if (props.userData?.status === 'active' && (isNotUploadDocuments === 'true' || !isNotUploadDocuments)) {
    return false
  }
  if (props.userData?.status === 'confirmed') {
    return true
  }
  if (props.userData?.status === 'need_verify') {
    return false
  } else return false
})
</script>

<template>
  <div class="wrapper">
    <div
      v-if="status === getProfileStatus(props?.status)?.status"
      class="status-wrapper"
    >
      <Tooltip
        trigger="hover"
        :visible="!!getProfileStatus(props.status)?.tooltipText?.length && isOpenTooltip"
        @mouseover="isOpenTooltip = true"
        @mouseout="isOpenTooltip = false"
      >
        <template #title>
          <span v-if="getProfileStatus(props.status)?.tooltipText">
            {{ getProfileStatus(props.status)?.tooltipText }}
          </span>
        </template>
        <div :class="getProfileStatus(props.status)?.innerClass">
          <div class="icon-wrapper">
            <ShieldIcon
              v-if="getProfileStatus(props.status)?.status === ProfileStatus.Verified"
              fill="#11A162"
              height="22px"
              width="21px"
            />
            <InfoIcon
              v-if="
                [ProfileStatus.Unverified, ProfileStatus.NeedToVerify].includes(
                  getProfileStatus(props.status)?.status
                )
              "
              :fill="
                getProfileStatus(props.status)?.status ===
                ProfileStatus.Unverified
                  ? '#0066FF'
                  : '#F16063'
              "
              height="20px"
              type="outlined"
              width="20px"
            />
            <DotsIcon
              v-if="status === ProfileStatus.Verifying"
              fill="#EF874D"
              height="20px"
              width="20px"
            />
          </div>
          {{ getProfileStatus(props.status)?.status }}
        </div>
      </Tooltip>
    </div>

    <div class="status-header">
      {{ props.totalUserData?.legal_form }}
      {{ `«${props.totalUserData?.name}»` }}
    </div>
    <SimpleRate :company_rating="props.totalUserData?.company_rating" />
    <p class="status-info">
      {{ getCoreCookie().email }}
    </p>
    <p
      class="status-info"
      v-if="
        status === ProfileStatus.Verified || status === ProfileStatus.Unverified
      "
    >
      {{ formatPhoneNumber(phoneNumber) }}
    </p>

    <div v-if="isShowButton" class="status-footer">
      <EButton
        @click="router.push('/verification')"
        :style-config="{
          ...primaryButton,
          fontSize16,
          width: '100%',
          justifyContent: 'center',
        }"
      >
        <div class="button-content">
          <div class="icon-wrapper">
            <ShieldIcon fill="#ffffff" width="21px" height="22px" />
          </div>
          Перейти к верификации
        </div>
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
.wrapper {
  .status-wrapper {
    :deep(.status-tooltip) {
      display: none;
      width: 2000px;
    }

    :deep(.popper) {
      width: 200px;
    }
  }

  .status-block__loader {
    display: flex;
    justify-content: center;
    transform: scale(0.4);
  }

  .status-display {
    padding: 2px 5px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: fit-content;
    cursor: pointer;
    .icon-wrapper {
      transform: scale(0.8);
      height: 23px;
    }

    &-verified {
      background-color: #e3f8e7;
      color: #11a162;
      cursor: auto;
    }
    &-unverified {
      background-color: #deebfc;
      color: $default-accent;
    }
    &-verifying {
      background-color: #ffefe7;
      color: #ef874d;
    }
    &-needToVerify {
      background-color: #fff0f0;
      color: #f16063;
    }
  }
  .status-header {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: $gray-800;
    margin-top: 5px;
  }
  .status-info {
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    line-height: 17px;
    color: $gray-600;
  }
  .status-footer {
    margin-top: 20px;
    .button-content {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 16px;
      .icon-wrapper {
        transform: scale(0.8);
      }
    }
  }
}
</style>
