import { defineStore } from 'pinia'
import {
  IAddress,
  IFarmerProduct,
  IPriceRequest,
  IPriceRequestOrder,
  IProductAnswer,
  IRecipient,
} from '@/types/interfaces'
import { PriceRequestAPI } from '@/api/PriceRequestAPI'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { AddressAPI } from '@/api/AddressAPI'
import { CartAPI } from '@/api/CartAPI'
import { WithRequiredProperty } from '@/types/types'
import dayjs from 'dayjs'
import { PartnersAPI } from '@/api/PartnersAPI'
import {
  IOrderFarmer,
  IOrderTerms,
  IProductItem,
  IRegistrationOrderData,
  registrationOrderStore,
} from '@/store/registrationOrderStore'
import router from '@/router'
type TPriceOption = number

interface IRadio {
  value: number
  productAnswerId: number
  options: {
    label: undefined
    value: TPriceOption
  }
}

interface IState {
  createdOrderId: number | undefined
  customerPriceRequest:
    | WithRequiredProperty<IPriceRequest, 'recipients'>
    | undefined
  products: IFarmerProduct[]
  checkboxState: {
    checkAll: boolean
    checkedList: number[]
  }
  radioState: IRadio[]
}

export const requestOrderCompositionStore = defineStore(
  'requestOrderCompositionStore',
  {
    state: (): IState => ({
      createdOrderId: undefined,
      customerPriceRequest: undefined,
      checkboxState: {
        checkAll: true,
        checkedList: [],
      },
      radioState: [],
      products: [],
    }),
    persist: true,
    actions: {
      getCustomerPriceRequest(id: string, recipientId: string) {
        PriceRequestAPI.getItem(id, [
          'products.productCategory',
          'recipients.farmer',
          'recipients.farmer.company_rating',
          'productRequests.product.productCategory',
          'recipients.priceAnswer.productAnswers.priceOptions',
          'recipients.priceAnswer.productAnswers.specialPrice',
          'customer.contacts',
        ])
          .then((res) => {
            if (res) {
              const recipient = res.recipients.find(
                (recipient: IRecipient) =>
                  Number(recipient.id) === Number(recipientId),
              )

              recipient.price_answer.product_answers =
                recipient.price_answer.product_answers.filter(
                  (productAnswer: IProductAnswer) =>
                    productAnswer.status === 'answer_received',
                )
              res.recipients = [recipient]

              this.customerPriceRequest = res

              FarmerProductAPI.getFilteredProducts(
                [
                  ['farmer_id', 'eq', recipient.farmer_id],
                  'AND',
                  [
                    'product_id',
                    'in',
                    recipient.price_answer.product_answers.map(
                      (productAnswer: IProductAnswer) =>
                        productAnswer.product_id,
                    ),
                  ],
                ],
                1,
              ).then((res) => {
                this.products = res.items

                this.checkboxState.checkedList = this.getAvaliableProducts(
                  recipient.price_answer.product_answers,
                ).map((productAnswer: IProductAnswer) => productAnswer.id)
              })

              this.radioState = recipient.price_answer.product_answers.map(
                ({ id, price_options, special_price }: IProductAnswer) => {
                  const priceOptions =
                    price_options
                      ?.filter((item) => item.status)
                      ?.map((option) => ({
                        value: option.id,
                        label: undefined,
                      })) ?? []

                  if (special_price && special_price.status) {
                    //Парсим дату как id, потому что special_price.id может быть равен option.id
                    priceOptions.push({
                      value: new Date(special_price.created_at).getTime(),
                      label: undefined,
                    })
                  }

                  return {
                    value: price_options?.[0]?.id,
                    productAnswerId: id,
                    options: priceOptions,
                  }
                },
              )
            }
          })
          .catch((e) => {
            console.log('getCurrentCustomerRequest e', e)
          })
      },
      checkDisabled(productId: number) {
        const customerNeededVolume = +(
          this.customerPriceRequest?.product_requests.find(
            (pRequest) => pRequest.product_id === productId,
          )?.customer_needed_volume || 0
        )

        return (
          (this.products.find((product) => product.product_id === productId)
            ?.quantity || 0) < customerNeededVolume
        )
      },
      getAvaliableProducts(productAnswers: IProductAnswer[]) {
        return productAnswers.filter(
          (productAnswer) => !this.checkDisabled(productAnswer.product_id),
        )
      },
      resetRequest() {
        this.customerPriceRequest = undefined
      },
      onCheckAllChange(e: any, productAnswers: IProductAnswer[]) {
        this.checkboxState.checkedList = e.target.checked
          ? (this.getAvaliableProducts(productAnswers).map(
              (productAnswer: IProductAnswer) => productAnswer.id,
            ) ?? [])
          : []
      },
      onCheckChange(e: any, productAnswers: IProductAnswer[]) {
        if (
          this.checkboxState.checkedList.some((item) => item === e.target.value)
        ) {
          this.checkboxState.checkedList = [
            ...this.checkboxState.checkedList.filter(
              (item) => item !== e.target.value,
            ),
          ]
        } else {
          this.checkboxState.checkedList.push(e.target.value)

          if (
            this.checkboxState.checkedList.length ===
            this.getAvaliableProducts(productAnswers).length
          ) {
            this.checkboxState.checkAll = true
          }
        }
      },
      async createOrder(recipientId: number) {
        const createOrderStore = registrationOrderStore()

        const recipient = this.customerPriceRequest?.recipients?.find(
          ({ id }) => id === recipientId,
        )

        const farmerData = recipient?.farmer

        await AddressAPI.getAddresses(
          String(recipient?.farmer.id),
          'farmer',
        ).then((res) => {
          if (farmerData) {
            farmerData.address = res.items[0]
          }
        })

        const checkedProductAnswers =
          recipient?.price_answer?.product_answers?.filter((productAnswer) =>
            this.checkboxState.checkedList.includes(productAnswer.id),
          ) ?? []

        const productItems: IProductItem[] = checkedProductAnswers.map(
          (productAnswer) => {
            const farmerProduct = this.products.find(
              (farmerProduct) =>
                farmerProduct.product.id === productAnswer.product_id,
            )

            const getOrderPriceOption = () => {
              const priceOptionId = this.radioState.find(
                (radio) => radio.productAnswerId === productAnswer.id,
              )?.value

              const priceOption = productAnswer.price_options?.find(
                (priceOption) => priceOption.id === priceOptionId,
              )

              if (priceOption) {
                return {
                  vat_percent: priceOption?.vat_percent,
                  price: +priceOption?.price_per_unit,
                  isSpecialPrice: false,
                  quantity: priceOption.volume,
                }
              } else {
                return {
                  vat_percent: productAnswer.special_price.vat_percent,
                  price: +productAnswer.special_price.price_per_unit,
                  isSpecialPrice: true,
                  quantity: productAnswer.special_price.volume,
                }
              }
            }

            const { quantity, isSpecialPrice, price, vat_percent } =
              getOrderPriceOption()

            return {
              chosen_price_option: {
                isSpecialPrice,
                price,
                vat_percent,
              },
              farmer_product: farmerProduct as IFarmerProduct,
              quantity: +quantity,
            }
          },
        )

        const customerAddress: IAddress = await AddressAPI.getAddresses(
          String(this.customerPriceRequest?.customer_id),
          'customer',
        ).then((res) => {
          return res.items.find(
            (address: IAddress) =>
              address.street === this.customerPriceRequest?.delivery_address,
          )
        })

        const is_partner = await PartnersAPI.getItems(
          [],
          [
            ['customer_id', 'coi', this.customerPriceRequest?.customer_id],
            'AND',
            ['farmer_id', 'coi', recipient?.farmer_id],
          ],
        ).then((res) => res.items[0]?.status === 'signed_customer')

        const farmers: IOrderFarmer[] = [
          {
            ...farmerData,
            // @ts-ignore
            address: farmerData?.address,
            is_partner,
            product_items: productItems,
          },
        ]

        if (recipient?.price_answer) {
          const {
            is_self_delivery,
            comment: farmerComment,
            should_be_delivered_at,
            should_be_delivered_in_days,
            palletizing_type,
            is_pay_cash,
            is_payment_delayed,
            payment_delay_days,
            is_delivery_included,
            need_unload,
            contact_name,
          } = recipient.price_answer

          const deliveryDate: string = should_be_delivered_at
            ? should_be_delivered_at
            : should_be_delivered_in_days
              ? dayjs(
                  Date.now() +
                    should_be_delivered_in_days * 24 * 60 * 60 * 1000,
                ).format('YYYY-MM-DD HH:mm:ss')
              : dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss')

          const customerComment = this.customerPriceRequest?.comment
          const contacts = this.customerPriceRequest?.customer?.contacts

          const orderTerms: IOrderTerms = {
            delivery_method: is_self_delivery
              ? 'Самовывоз'
              : 'Доставка фермером',
            delivery_date: deliveryDate,
            is_payment_delayed: is_payment_delayed ?? false,
            need_unload: need_unload ?? false,
            payment_type: is_pay_cash ? 'cash' : 'non_cash',
            address: customerAddress ?? undefined,
            delivery_payment: is_delivery_included ? 'included' : 'excluded',
            palletizing_type: palletizing_type ?? undefined,
            payment_delay_days,
            comment: customerComment,
          }

          const handleCreateOrder = async ({
            comment,
            delivery_date,
            delivery_method,
            address,
            palletizing_type,
            payment_type,
            is_payment_delayed,
            payment_delay_days,
            delivery_payment,
            need_unload,
            farmers,
          }: IRegistrationOrderData) => {
            const isSelfDelivery = delivery_method === 'Самовывоз'
            try {
              let createOrderData: IPriceRequestOrder = {
                order_comment: farmerComment,
                comment,
                delivery_date: dayjs(delivery_date).format('YYYY-MM-DD'),
                delivery_method: 'Самовывоз',
                palletizing_type,
                payment_type,
                is_payment_delayed,
                payment_delay_days,
                need_unload,
                farmer_id: Number(farmers[0].id),
                order_positions: farmers[0].product_items.map(
                  ({
                    quantity,
                    //@ts-ignore
                    volume,
                    farmer_product: { id: farmer_product_id },
                    chosen_price_option: { price: unit_price, vat_percent },
                  }) => ({
                    quantity,
                    // quantity: volume,
                    unit_price,
                    vat_percent,
                    farmer_product_id,
                  }),
                ),
              }

              if (!isSelfDelivery) {
                createOrderData = {
                  ...createOrderData,
                  delivery_payment,
                  address: address?.id,
                  delivery_method: 'Доставка фермером',
                }
              }
              const res = await CartAPI.createPriceRequestOrder(createOrderData)

              return {
                orderId: res.id,
                error: null,
              }
            } catch (e: any) {
              return {
                orderId: null,
                error: e.message,
              }
            }
          }

          createOrderStore.fillOrderData(
            handleCreateOrder,
            farmers,
            deliveryDate,
            orderTerms,
          )
        }
        await router.push('/create-order')
      },
    },
  },
)
