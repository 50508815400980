<script lang="ts" setup>
import { useRouter } from 'vue-router'
import Logo from '../../../../assets/img/logo.svg'
import {computed} from "vue";
import {useCookies} from "vue3-cookies";
import emitter from "@/emitter";

const router = useRouter()
const { cookies } = useCookies()
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => umt && chat)

const scrollToFirstSection = () => {
  emitter.emit('scroll-to-section-0', 0)
}

const navigateToHome = () => {
  if (isAuth.value) router.push('/')
  else scrollToFirstSection()
}
</script>

<template>
  <div :class="'logo'" @click="navigateToHome">
    <img :src="Logo" alt="logo" loading="lazy" />
  </div>
</template>

<style scoped lang="scss">
@mixin logoCommon {
  padding: 10px 24px 2px 24px;
  gap: 4px;
  cursor: pointer;
  transition: all 0.25s;
}

.logo {
  @include logoCommon;
  border-bottom: 2px solid transparent;
  user-select: none;

  @media (max-width: 1279px) {
    padding: 22px 12px 10px;
  }
  & img {
    @media (max-width: 1279px) {
      width: 120px;
    }
  }

  &:hover {
    background: #00000005;
  }
}
</style>
