<script lang="ts" setup>
import { ISuggestBank, ISuggestOrg } from '@/types/interfaces'
import { onMounted, ref, computed } from 'vue'
import { useCookies } from 'vue3-cookies'
import { FarmerAPI } from '@/api/FarmerAPI'
import { CustomerAPI } from '@/api/CustomerAPI'
import { UserAPI } from '@/api/UserAPI'
import { useAuth } from '@/composables/useAuth'
import { useJWTDecode } from '@/composables/useJWTDecode'
import router from '@/router'
import StepOne from '@/views/Register/Steps/StepOne.vue'
import StepThree from '@/views/Register/Steps/StepThree.vue'
import StepThreeAlt from '@/views/Register/Steps/StepThreeAlt.vue'
import StepFour from '@/views/Register/Steps/StepFour.vue'
import StepFourAlt from '@/views/Register/Steps/StepFourAlt.vue'
import StepFive from '@/views/Register/Steps/StepFive.vue'
import { DadataAPI } from '@/api/Dadata'

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()
const { loginIntoService } = useAuth()

const currentStep = ref('1')
const registerForm = ref<string>('')
const emailConfirmed = ref<string>('')
const name = ref<string>('')
const role = ref<string>('')
const activeOrganisation = ref<ISuggestOrg>()
const activeBank = ref<ISuggestBank>()
const user = ref<string>()

const isTinNotExist = ref(false)
const step1 = computed(() => !registerForm.value)
const step3 = computed(
  () =>
    !threeAlt.value &&
    registerForm.value &&
    emailConfirmed.value &&
    !activeOrganisation.value,
)
const step3Alt = computed(
  () =>
    threeAlt.value &&
    registerForm.value &&
    emailConfirmed.value &&
    !activeOrganisation.value,
)
const step4 = computed(
  () =>
    !FourAlt.value &&
    registerForm.value &&
    emailConfirmed.value &&
    activeOrganisation.value &&
    !activeBank.value,
)
const step4Alt = computed(
  () =>
    FourAlt.value &&
    registerForm.value &&
    emailConfirmed.value &&
    activeOrganisation.value &&
    !activeBank.value,
)
const step5 = computed(
  () =>
    registerForm.value &&
    emailConfirmed.value &&
    activeOrganisation.value &&
    activeBank.value,
)

const checkStep = () => {
  registerForm.value = cookies.get('registerForm') || cookies.get('loginForm')
  emailConfirmed.value = cookies.get('emailConfirmed') || cookies.get('umt')
  name.value = cookies.get('name')
  role.value = getCoreCookie()?.company_type
  activeOrganisation.value = cookies.get('activeOrganisation')
  activeBank.value = cookies.get('activeBank')
  user.value = cookies.get('user')
  isTinNotExist.value = false

  if (!isTinNotExist.value && step1.value) {
    currentStep.value = '1'
  } else if (!isTinNotExist.value && step3.value) {
    currentStep.value = '3'
  } else if (!isTinNotExist.value && step3Alt.value) {
    currentStep.value = '3.1'
  } else if (!isTinNotExist.value && step4.value) {
    currentStep.value = '4'
  } else if (!isTinNotExist.value && step4Alt.value) {
    currentStep.value = '4.1'
  } else if (!isTinNotExist.value && step5.value) {
    currentStep.value = '5'
  }
  if (emailConfirmed.value && currentStep.value == '1') {
    currentStep.value = '2'
  }
}

const threeAlt = ref<boolean>(false)
const openThreeAlt = () => {
  threeAlt.value = true
  checkStep()
}
const FourAlt = ref<boolean>(false)
const openFourAlt = () => {
  FourAlt.value = true
  checkStep()
}

const sendData = async () => {
  await getAccess().then(async () => {
    await createRole()
  })
}

const getAccess = async () => {
  const { loginIntoService } = useAuth()
  await loginIntoService(cookies.get('umt'), 'core')
    .then((res) => {
      console.log('getAccess success')
      let ust = res.data.action_result.data
      cookies.set('core', ust, '365d', '')
    })
    .catch(() => {
      console.log('error getAccess')
    })
}

const pingDaData = async () => {
  DadataAPI.ping()
    .catch(() => {
      currentStep.value = '3.1'
    })
    .then(() => {
      currentStep.value = '3'
    })
}

// Создаём роль по введённым данным ранее
const createRole = async () => {
  console.log('createRole')
  let data = {
    tin: activeOrganisation.value,
    bic: activeBank.value,
  }

  if (getCoreCookie()?.company_type === 'farmer') {
    await FarmerAPI.create(data)
        .then(async () => {
          isTinNotExist.value = false
          await updateUserInfo()
          window.location.href = '/verification-confirm'
        })
        .catch(async (e) => {
          if (
              ['tin not exists.'].includes(e?.response.data?.action_error?.message)
          ) {
            isTinNotExist.value = true
            await pingDaData()
          }
          if (
              ['bic not exists.'].includes(e?.response?.data?.action_error?.message)
          ) {
            currentStep.value = '4.1'
          }
          if (
              e?.response?.data?.action_error?.message ===
              'No access to action call!'
          ) {
            window.location.href = '/verification-confirm'
          }
        })
  }
  else {
    await CustomerAPI.create(data)
        .then(async () => {
          isTinNotExist.value = false
          await updateUserInfo()
        })
        .catch(async (e) => {
          if (
              ['tin not exists.'].includes(e.response.data.action_error.message)
          ) {
            isTinNotExist.value = true
            await pingDaData()
          }
          if (
              ['bic not exists.'].includes(e.response.data.action_error.message)
          ) {
            currentStep.value = '4.1'
          }
          if (
              e.response.data.action_error.message === 'No access to action call!'
          ) {
            await router.push('/verification-confirm')
          }
        })
  }
}

const updateUserInfo = async () => {
  user.value = cookies.get('user')
  const data = {
    attributes: {
      id: getCoreCookie()?.id,
      name: user.value?.name,
      phone: user.value?.phone,
      position: user.value?.position,
      personal_data_consent: true,
    },
  }
  await loginIntoService(cookies.get('umt'), 'core').then(async () => {
    await UserAPI.updateUserDataRegister(data)
      console.log('UserAPI.updateUserDataRegister')
    console.log('updateUserData umt 1', cookies.get('umt'))
      if (threeAlt.value || FourAlt.value) {
        await loginIntoService(cookies.get('umt'), 'core')
        console.log('updateUserData umt 2.1', cookies.get('umt'))
        await router.push('/account#thankyou')
      } else {
        console.log('go to', '/verification-confirm')
        await loginIntoService(cookies.get('umt'), 'core')
        console.log('updateUserData umt 2.2', cookies.get('umt'))
        await router.push('/verification-confirm')
      }
  })
}

onMounted(() => {
  checkStep()
})
</script>

<template>
  <StepOne v-if="currentStep === '1'" @next="checkStep" />
  <StepThree
    v-if="currentStep === '3'"
    @next="checkStep"
    @threeAlt="openThreeAlt"
  />
  <StepThreeAlt v-if="currentStep === '3.1'" @next="checkStep" />
  <StepFour
    v-if="currentStep === '4'"
    @next="checkStep"
    @fourAlt="openFourAlt"
  />
  <StepFourAlt @next="checkStep" v-if="currentStep === '4.1'" />
  <StepFive v-if="currentStep === '5'" @next="() => sendData()" />
</template>
