<script lang="ts" setup>
import { reactive } from 'vue'
import { primaryButton } from '@/assets/EgalStyles/EButton'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { selectStyleConfig } from '@/assets/EgalStyles/ESelect'
import EgalSelect from '@/components/Egal/ESelect/EgalSelect.vue'
import { OrderStatus } from '@/types/enums'

const emit = defineEmits(['select-status'])

const statusOptions = [
  { name: OrderStatus.Done, value: OrderStatus.Done },
  { name: OrderStatus.DonePartly, value: OrderStatus.DonePartly },
  { name: OrderStatus.NotDone, value: OrderStatus.NotDone },
]

const selectedStatus = reactive({
  name: '',
  value: '',
})

const onSelectCategory = (option: { name: string; value: string }) => {
  Object.assign(selectedStatus, option[0])
}

const selectStatus = () => {
  emit('select-status', selectedStatus.value)
}
</script>

<template>
  <div class="page-card">
    <div class="page-card__header">Установите новый статус заказа</div>
    <p class="page-card__info"></p>
    <ul class="page-card__info-list">
      <li class="page-card__info-list-item">
        <p>
          <span class="page-card__info-status">Заказ выполнен:</span>
          <span class="page-card__info-status-description">
            все позиции доставлены в соответсвии с заказом
          </span>
        </p>
      </li>
      <li class="page-card__info-list-item">
        <p>
          <span class="page-card__info-status">Заказ выполнен частично:</span>
          <span class="page-card__info-status-description">
            некоторые позиции в заказе не были доставлены или доставлены в
            другом количестве
          </span>
        </p>
      </li>
      <li class="page-card__info-list-item">
        <p>
          <span class="page-card__info-status">Заказ не выполнен:</span>
          <span class="page-card__info-status-description">
            вы отказались от привезённых товаров, либо поставщик привёз не те
            товары
          </span>
        </p>
      </li>
    </ul>

    <div class="page-card__status">
      <EgalSelect
        class="create__field"
        :data="{
          ...inputDataConfig,
          id: 'status',
          options: statusOptions,
          modelValue: [{ name: selectedStatus.name }],
          placeholder: 'Статус заказа',
        }"
        :style-config="selectStyleConfig"
        @update:modelValue="onSelectCategory"
      />
    </div>

    <div class="page-card__footer">
      <EButton
        @click="selectStatus"
        :data="{ disabled: selectedStatus.name.length === 0 }"
        :style-config="{ ...primaryButton, fontWeight: 500, width: '100%' }"
      >
        Продолжить
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.page-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 540px;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px 3.5rem;
  gap: 1rem;
  text-align: left;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 27px;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &-link {
      @include router-link();
      cursor: pointer;
    }
  }

  &__info {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    &-list {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      gap: 1rem;
      list-style-type: disc;
    }

    &-list-item {
    }

    &-status {
      color: #2d3748;
    }
    &-status-description {
      color: #718096;
    }
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f16063;
    padding-top: 0.75rem;
  }
}

.egal-button {
  justify-content: center;
}
</style>
