<script lang="ts" setup>
import { Alert, Button, Col, Row, Space } from 'ant-design-vue'
import { TMapCoordinates } from '@/types/types'
import YaMap from '../YaMap/YaMap.vue'
import {
  calcCoords,
  convertPointToMapFormat,
  formatPrice,
} from '@/helpers/scripts'
import { width100 } from '@/model/constants'
import { Tooltip } from 'ant-design-vue'
import { Flex } from 'ant-design-vue'
import { useStore } from '@/store'
import { computed } from 'vue'

export interface IFarmerPriceItem {
  farmerName: string
  id?: number
  coordinates?: [number, number]
  address?: string
  totalVat: number
  totalPrice: number
}

interface Props {
  priceFarmerList: IFarmerPriceItem[]
  btnText: string
  isDisabledBtn: boolean
  handleBtnClick: () => void
  alerts: string[]
  isLoading?: boolean
  btnTooltip?: {
    title: string
    description: string
  } | null
}

const props = defineProps<Props>()

const totalPriceForAllFarmers = computed(() =>
  props.priceFarmerList.reduce(
    (accum, { totalPrice }) => accum + totalPrice,
    0,
  ),
)

const totalVatPriceForAllFarmers = computed(() =>
  props.priceFarmerList.reduce(
    (accumulator, { totalVat }) => accumulator + totalVat,
    0,
  ),
)
const mainStore = useStore()

const pointsToShow = computed(() => {
  if (!props.priceFarmerList.length) return []
  
  return props.priceFarmerList
    .filter((farmer) => farmer.coordinates)
    .map((farmer) => ({
      coordinates: {
        lat: farmer.coordinates[0],
        lon: farmer.coordinates[1],
      },
      address: farmer.address,
      id: farmer.id,
      name: farmer.farmerName,
    }))
})
</script>

<template>
  <Flex class="sidebar__wrapper" :gap="8" vertical>
    <div class="sidebar">
      <YaMap
        height="238px"
        width="100%"
        :points="pointsToShow"
        :class="!mainStore?.isVerification ? 'blur' : ''"
      />
      <div class="sidebar__info">
        <div class="sidebar__info_farmers" :data-source="priceFarmerList">
          <div
            class="sidebar__info_farmer"
            v-for="(
              { farmerName, totalPrice, totalVat }, index
            ) in priceFarmerList"
            :key="index"
          >
            <span class="sidebar__info_farmer-name">{{ farmerName }}</span>
            <Space
              direction="vertical"
              :size="0"
              class="sidebar__info_price-block"
            >
              <span class="sidebar__info_price">
                {{ formatPrice(totalPrice) }}
              </span>
              <span class="sidebar__info_vat">
                НДС
                {{ formatPrice(totalVat) }}
              </span>
            </Space>
          </div>
        </div>
        <div class="total-price">
          <Row :gutter="11" justify="space-between" :style="width100">
            <Col>
              <span class="total-price-sum"> Итого </span>
            </Col>
            <Col>
              <Space :direction="'vertical'" :size="0">
                <span class="total-price-sum">
                  {{ formatPrice(totalPriceForAllFarmers) }}
                </span>
                <span class="sidebar__info_vat">
                  НДС {{ formatPrice(totalVatPriceForAllFarmers) }}
                </span>
              </Space>
            </Col>
          </Row>
        </div>

        <Tooltip
          placement="bottom"
          v-if="isDisabledBtn && btnTooltip"
          color="#FFFFFF"
          :overlayStyle="{ minWidth: '400px' }"
          class="tooltip"
        >
          <template #title>
            <div class="tooltip-content tooltip-title">
              {{ props.btnTooltip?.title }}
            </div>
            <div class="tooltip-content">
              {{ props.btnTooltip?.description }}
            </div>
          </template>

          <Button
            :disabled="isDisabledBtn"
            @click="handleBtnClick"
            size="large"
            :type="'primary'"
            :loading="isLoading"
          >
            <span>
              {{ btnText }}
            </span>
          </Button>
        </Tooltip>

        <Button
          :disabled="isDisabledBtn"
          @click="handleBtnClick"
          size="large"
          :type="'primary'"
          :loading="isLoading"
          v-if="!btnTooltip"
        >
          <span>
            {{ btnText }}
          </span>
        </Button>
      </div>
    </div>
    <Alert show-icon type="warning" v-if="alerts.length">
      <template #message>
        <p v-for="alert in alerts" :key="alert">
          {{ alert }}
        </p>
      </template>
    </Alert>
  </Flex>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.blur {
  filter: blur(5px);
  @include no-select();
}

.sidebar {
  max-width: 448px;
  flex: 1;
  border: 1px solid $gray-300;
  border-radius: 20px;
  height: fit-content;
  overflow: hidden;

  &__wrapper {
    height: min-content;
    flex: 1;
  }

  :deep(.popper) {
    width: 100%;
    box-sizing: border-box;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #00000005;
    &_farmers {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &_farmer {
      border-bottom: 1px solid #ececec;
      display: flex;
      grid-template-columns: 66% 1fr;
      column-gap: 8px;
      -moz-column-gap: 8px;
      justify-content: space-between;
    }
    &_farmer-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000000e0;
    }
    &_price-block {
      text-align: right;
    }
    &_price {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #000000e0;
    }
    &_vat {
      color: #000000a6;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .farmer-price {
      display: flex;
      justify-content: space-between;
      color: $gray-700;
      font-size: 14px;
      border-bottom: 1px solid $gray-300;
      padding: 12px 0;

      .name {
        font-weight: 400;
        line-height: 19px;
      }

      .price {
        font-weight: 700;
        line-height: 17px;
        width: 100px;
        text-align: right;
      }
    }

    .total-price {
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
      color: $gray-800;
      padding: 24px 0;
      display: flex;
      :deep(.ant-space) {
        text-align: right;
      }
      &-sum {
        font-size: 24px;
        text-align: right;
        font-weight: 700;
        color: #000000e0;
      }
    }
  }
}

.tooltip {
  display: block;
  & button {
    width: 100%;
  }
}
.tooltip-content {
  color: #000000e0;
  &.tooltip-title {
    font-weight: 600;
    padding-bottom: 8px;
  }
}
</style>
