<script setup lang="ts">
import {ref, onMounted, h, computed} from 'vue'
import { Table, Button, notification } from 'ant-design-vue'
import {useRoute, useRouter} from 'vue-router'
import OneCHeader from '@/views/1C/components/1CHeader/1CHeader.vue'
import OneCFooter from '@/views/1C/components/1CFooter/1CFooter.vue'
import OneCDrawer from '@/views/1C/components/1CDrawer/1CDrawer.vue'
import { OneCAPI, IChanges } from '@/api/OneCAPI'
import { InfoCircleFilled } from '@ant-design/icons-vue'

const router = useRouter()
const isOpen1CDrawer = ref(false)
const currentPage = ref<number>(1)
const pageSize = ref<number>(100)
const totalPages = ref<number>(1)
const dataSource = ref<any>([])
const offers = ref<any>([])
const cFarmerProductsIds = ref<string[]>([])

type TStatus = 'integrated' | 'started' | 'finished'
const status = ref<TStatus>('integrated')

const columns = [
  {
    title: 'Название товара в 1С',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Каталожная позиция в системе',
    key: 'catalog_position',
    width: '20%',
  },
  {
    title: 'Остаток в 1С',
    key: 'remnant',
    width: '10%',
  },
  {
    title: 'Ед. изм. в 1С',
    key: 'measurement_unit',
    width: '10%',
  },
  {
    title: 'Ед. изм. в системе',
    key: 'measurement_unit_system',
    width: '10%',
  },
  {
    title: 'Конвертация',
    key: '',
    width: '20%',
    children: [
      {
        title: '1C',
        key: '1c',
        width: 100,
      },
      {
        title: 'Система',
        key: 'system',
        width: 100,
      },
    ],
  },
  {
    title: 'Остаток в системе',
    key: 'remains',
    width: '20%',
  },
]

const currentItem = ref()
const cFarmerProducts = ref<any>([])

const goBack = () => {
  router.push(status.value === 'finished' ? '/products' : '/1c-faq')
}

const openDrawer = (record: any) => {
  currentItem.value = record
  isOpen1CDrawer.value = true
}

const closeDrawer = () => {
  isOpen1CDrawer.value = false
}

const saveChanges = (data: IChanges, updating: boolean, id: number) => {
  closeDrawer()
  if (updating) {
    OneCAPI.updateCFarmerProduct(data, id)
    .then(() => {
      showNotification()
    })
  }
  else {
    OneCAPI.createCFarmerProduct(data)
    .then(() => {
      showNotification()
    })
  }
}

const showNotification = () => {
  notification.info({
    top: '80px',
    icon: h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)' },
    }),
    message: 'Изменения сохранены',
    description: 'Остатки товара синхронизированы с 1С',
  })
  getTableData()
}

const getTableData = async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const companyId = user?.company_id
  if (companyId?.length) {
    await OneCAPI.checkStatus(companyId).then((res) => {
      status.value = res
    })
    await OneCAPI.getCFarmerProducts().then((res) => {
      cFarmerProductsIds.value = res?.items?.map((el: any) => el.id)
      cFarmerProducts.value = res?.items
    })
    await OneCAPI.getOffers({
      company_id: Number(companyId),
      page: currentPage.value,
      per_page: pageSize.value,
    }).then((res) => {
      totalPages.value = res.total
      offers.value = res.data

      dataSource.value = offers.value.map((offer: any) => {
        let cFarmerProduct = cFarmerProducts.value.filter(
          (product: any) => product?.c_product_id === offer?.product_id,
        )
        return {
          ...offer,
          ...cFarmerProduct[0],
        }
      })
    })
  }
}

onMounted(() => {
  getTableData()
})

const route = useRoute()
</script>

<template>
  <div class="table">
    <OneCHeader
      type="Настройка соответствия товаров"
      description="Данные 1С синхронизированы"
      @goBack="goBack"
    />
    <div class="table__data">
      <Table :columns="columns" :data-source="dataSource" :pagination="false" bordered>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            {{ record?.name }}
          </template>
          <template v-if="column.key === 'catalog_position'">
            <div class="cell__catalog-position catalog-position">
              <div
                class="catalog-position"
                v-if="record?.farmer_product?.product?.fullName"
              >
                {{ record?.farmer_product?.product?.fullName }}
              </div>
              <div
                class="catalog-position__name"
                v-else
              >
                Не указано
              </div>
              <Button class="btn_blue" @click="() => openDrawer(record)">
                {{ record?.farmer_product ? 'Изменить' : 'Выбрать' }}
              </Button
              >
            </div>
          </template>
          <template v-if="column.key === 'remnant'">
            {{ record?.remnant }}
          </template>
          <template v-if="column.key === '1c'">
           1
          </template>
          <template v-if="column.key === 'system'">
            {{ record?.ratio || '-'}}
          </template>
          <template v-if="column.key === 'measurement_unit'">
            {{ record?.measurement_unit?.name }}
          </template>
          <template v-if="column.key === 'measurement_unit_system'">
            {{ record?.farmer_product?.product?.measurement_unit_id || '-'}}
          </template>
          <template v-if="column.key === 'remains'">
            {{
              record.ratio || '-'
            }}
          </template>
        </template>
      </Table>
    </div>
    <OneCFooter type="table" />
    <OneCDrawer
      :open="isOpen1CDrawer"
      @close="closeDrawer"
      :selected-product="currentItem"
      @saveChanges="saveChanges"
      @closeDrawer="closeDrawer"
    />
  </div>
</template>

<style scoped lang="scss">
.btn_blue {
  background: var(--Colors-Base-Blue-1, rgba(230, 244, 255, 1));
  color: rgba(22, 119, 255, 1);
  border: 1px solid transparent;
}

.catalog-position__name {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 0.25);
}

.cell {
  &__catalog-position {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }
}
.table {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__data {
    height: 100%;
    min-height: 76vh;
  }
}
</style>
