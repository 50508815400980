<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { useStore } from '@/store'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import SearchErrorIcon from '@/components/Icons/SearchErrorIcon.vue'
import { Select, Tag, Button } from 'ant-design-vue'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import { AuditOutlined } from '@ant-design/icons-vue'
import PriceInfo from './PriceInfo.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { Flex } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import {useCookies} from "vue3-cookies";
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

interface Props {
  farm: any
  partners: any[]
}

const props = defineProps<Props>()
const emit = defineEmits(['back'])
const { getCoreCookie } = useJWTDecode()

const isFetching = ref(false)
const products = ref<any[]>([])
const { isVerification } = storeToRefs(useStore())
const page = ref(1)
const isAllFetched = ref(false)
const activeTabCategory = ref('')

onMounted(() => {
  activeTabCategory.value = 'all'
})

const isPartner = computed(() => {
  return (
    !!props.partners?.filter((el) => el.company_id == props.farm?.id)?.length &&
    getCoreCookie()?.company_type === Roles.Customer
  )
})

const categoriesOptions = computed(() => {
  return [
    { value: 'all', label: 'Все товары' },
    ...props.farm.categories.map((category: string) => {
      return {
        value: category?.second_category_name,
        label: category?.second_category_name,
      }
    }),
  ]
})

const increasePage = () => {
  page.value = page.value + 1
  !isAllFetched.value && fetchMore()
}

const categoriesFilter = computed(() => {
  return activeTabCategory.value === 'all'
    ? []
    : [['', 'product_category', activeTabCategory.value]]
})
const fetchMore = async () => {
  isFetching.value = true
  await FarmerProductAPI.getProduct(
    [
      ['is_deleted', 'eq', false],
      'AND',
      ['farmer_id', 'eq', props.farm.id],
      'AND',
      ...categoriesFilter.value,
    ],
    page.value,
    ['specialPrices', 'priceOptions'],
  ).then((res) => {
    isAllFetched.value = res.length < 15
    products.value.push(...res)
    isFetching.value = false
  })
}

const handleGoToPartner = () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  localStorage.removeItem('partnersTab')
  if (
    props.farm.id === Number(user?.company_id) &&
    getCoreCookie()?.company_type === Roles.Farmer
  ) {
    router.push('/products')
  } else {
    localStorage.setItem('partnersTab', 'catalog')
    localStorage.setItem('currentPartnerId', props.farm.id)
    router.push(`/partners/${props.farm.id}?farmerId=${props.farm.id}&role=farmer`)
  }
}

watch(activeTabCategory, async () => {
  isFetching.value = true
  page.value = 1
  products.value = []
  isAllFetched.value = false
  await FarmerProductAPI.getProduct(
    [
      ['is_deleted', 'eq', false],
      'AND',
      ['farmer_id', 'eq', props.farm.id],
      'AND',
      ...categoriesFilter.value,
    ],
    1,
    ['specialPrices.customer', 'priceOptions'],
  ).then((res) => {
    products.value = res
    isFetching.value = false
    isAllFetched.value = res.length < 15
  })
})
</script>

<template>
  <div class="card">
    <div>
      <Button @click="() => emit('back')" class="back" type="link">
        <ChevronLeftIcon />
        <p>Вернуться к списку поставщиков</p>
      </Button>

      <div class="header">
        <BlurTooltip :withoutFlex="true">
          <div class="header__title" :class="!isVerification ? 'blur' : ''">
            <PartnershipTooltip v-if="isPartner" />
            <span>{{ `${farm.legal_form} «${farm.name}»` }}</span>
          </div>
        </BlurTooltip>
        <div
          class="header__subtitle"
          :class="!isVerification ? 'blur' : ''"
          v-if="!!farm?.addresses?.length"
        >
          <BootstrapIcon icon="geo-alt" />
          <p>
            {{ !!farm?.addresses?.length ? farm?.addresses[0]?.street : '' }}
          </p>
        </div>
        <SimpleRate :company_rating="farm?.company_rating" />
        <div :class="!isVerification ? 'blur' : ''">
          <div class="header__tags">
            <Tag v-if="farm.vat">
              <template #icon>
                <AuditOutlined />
              </template>
              Налогообложение по НДС
            </Tag>
            <Tag v-if="farm?.can_deliver">
              <template #icon>
                <SupplierDelivery />
              </template>
              Доставка
            </Tag>
            <Tag>
              <template #icon>
                <SelfDelivery />
              </template>
              Самовывоз
            </Tag>
          </div>
        </div>
      </div>

      <div>
        <div v-if="farm?.categories?.length > 0" class="body">
          <div class="tabs-container">
            <Select
              size="middle"
              :options="categoriesOptions"
              v-model:value="activeTabCategory"
              :style="{
                width: '100%',
              }"
            />
          </div>

          <div class="product-list">
            <div
              v-for="product in products"
              :key="product.id"
              class="product-list__item"
            >
              <PriceInfo :product="product" />
            </div>
          </div>
          <div v-if="isFetching" class="infinity-list-loader">
            <FullscreenLoading :type="'secondary'" />
          </div>
          <InfiniteLoading
            v-if="!isAllFetched && !isFetching"
            :distance="100"
            class="infinite-loading"
            @infinite="increasePage"
          />
        </div>
        <div
          v-if="
            !isFetching && (!farm?.categories || farm?.categories?.length == 0)
          "
          class="body-empty"
        >
          <div class="body-empty-text">
            <SearchErrorIcon :fill="'#718096'" />
            <span> У поставщика нет товаров </span>
          </div>
        </div>
      </div>
    </div>

    <Flex>
      <div :class="!isVerification ? 'blur' : ''" style="width: 100%">
        <div class="action">
          <Button
            type="primary"
            size="large"
            block
            @click="handleGoToPartner"
            :disabled="!isVerification"
          >
            Открыть каталог поставщика
          </Button>
        </div>
      </div>
    </Flex>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.card {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.back {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 24px 24px 0 24px;

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    margin-bottom: 0;
  }
}

.blur {
  @include blur();
  @include no-select();
}

.header {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 4px;

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $gray-800;
    display: flex;
    gap: 10px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-600;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      margin-bottom: 0;
      width: 18px;
      height: 18px;
    }
  }

  &__tags {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px 0;
  }

  &__badge {
    background: $default-secondary;
    border-radius: 4px;
    padding: 4px 8px;
    color: $default-accent;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: fit-content;
    margin-bottom: 16px;
  }
}

.body {
  border-top: 1px solid $gray-300;
  position: relative;
  &-empty {
    margin: auto;
    border-top: 1px solid $gray-300;
    color: $gray-600;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: grid;
    align-items: center;
    column-gap: 8px;
    padding: 120px 24px 16px;

    &-text {
      vertical-align: middle;
      margin: auto;
      display: flex;
    }
  }
}

.action {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid $gray-300;
  padding: 24px;
}

.product-list {
  padding: 0 24px 63px;
  max-height: 47vh;
  overflow-y: scroll;

  &__badge {
    background: $gray-200;
    border-radius: 4px;
    width: fit-content;
    padding: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-600;
  }
}

.tabs-container {
  padding: 24px 24px 0;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;

  :deep(.ant-select-selector) {
    height: 42px;
    padding: 6px 11px;
  }
}

.loader {
  transform: scale(0.5);
  display: flex;
  justify-content: center;
}

.infinity-list-loader {
  position: relative;
  height: 60px;
  transform: scale(0.5);
}

:deep(.anticon) {
  span {
    margin-inline-start: 4px;
  }
}
</style>
