<script lang="ts" setup>
import { Flex } from 'ant-design-vue'
import OrderCompositionHeader from '@/views/Customer/RequestOrderComposition/components/OrderCompositionHeader.vue'
import { computed, onMounted, watch } from 'vue'
import { requestOrderCompositionStore } from '@/store/requestOrderCompositionStore'
import { useRoute } from 'vue-router'
import OrderCompositionTable from '@/views/Customer/RequestOrderComposition/components/OrderCompositionTable.vue'
import OrderCompositionFooter from '@/views/Customer/RequestOrderComposition/components/OrderCompositionFooter.vue'
import { IProductAnswer } from '@/types/interfaces'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import emitter from '@/emitter'

const store = requestOrderCompositionStore()
const route = useRoute()

emitter.on('refresh-token', async (e) => {
  store.getCustomerPriceRequest(
    route.params.requestId as string,
    route.params.recipientId as string,
  )
})

onMounted(() => {
  store.getCustomerPriceRequest(
    route.params.requestId as string,
    route.params.recipientId as string,
  )
})

const getRecipient = () =>
  store.customerPriceRequest?.recipients?.find(
    (recipient) => recipient.id === Number(route.params.recipientId),
  )

const productAnswers = computed(() => {
  if (store.customerPriceRequest) {
    return getRecipient()?.price_answer?.product_answers
  } else return []
})

const isShowWarning = computed(() => {
  return store
    .getAvaliableProducts(getRecipient()?.price_answer?.product_answers || [])
    .some((productAnswer: IProductAnswer) => {
      const activePriceOptions = productAnswer.price_options.reduce(
        (prev, curr) => prev + Number(curr.status),
        0,
      )

      return (
        Number(activePriceOptions) +
          Number(
            !!productAnswer.special_price && productAnswer.special_price.status,
          ) >
        1
      )
    })
})
</script>

<template>
  <div class="page__wrapper">
    <Flex v-if="store.customerPriceRequest && productAnswers" gap="24" vertical>
      <OrderCompositionHeader :isShowWarning="isShowWarning" />
      <OrderCompositionTable
        :products="store.customerPriceRequest.products"
        :productRequest="store.customerPriceRequest.product_requests"
        :product-answers="productAnswers"
      />
      <OrderCompositionFooter />
    </Flex>
    <div class="loader-wrapper" v-else>
      <FullscreenLoading :type="'secondary'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.page__wrapper {
  padding: 32px 24px;
  margin-bottom: 120px;
  width: 100%;
}
.loader-wrapper {
  height: 70vh;
}
</style>
