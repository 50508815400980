<script setup lang="ts">
import {
  CheckboxGroup,
  DatePicker,
  Form,
  FormItem,
  InputNumber,
  Textarea,
  RadioGroup,
  Tooltip,
} from 'ant-design-vue'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import TitleToolbarBlock from '@/views/Farmer/response/toolbarWidget/TitleToolbarBlock.vue'
import { computed, reactive, ref, watch } from 'vue'
import { IPriceAnswer } from '@/types/interfaces'
import { PaletizingType } from '@/types/types'
import { PriceAnswerAPI } from '@/api/PriceAnswerAPI'
import { declensionDays } from '@/helpers/scripts'
import dayjs, { Dayjs } from 'dayjs'
import { Rule } from 'ant-design-vue/es/form'

interface IState {
  delivery: string[]
  paymentDelivery: string
  paymentMethod: string[]
  services: string[]
  paymentDelay: string[]
}

interface Props {
  priceAnswer: IPriceAnswer | null
  showButton: boolean
}

const props = defineProps<Props>()

const state = reactive<IState>({
  delivery: [],
  paymentDelivery: '', // radioButtons
  paymentMethod: [],
  services: [],
  paymentDelay: [],
})

const formRef = ref()

const shouldDeliveredInDays = ref<number>(1)
const shouldDeliveredAt = ref<Dayjs>(dayjs(new Date()))
const paymentDelayDays = ref<number>(0)
const commentaryProvider = ref<string>('')
const isTooltipOpened = ref(false)

const deliveryOptions = computed(() => {
  const initDeliveryOptions = [
    {
      value: 'is_self_delivery',
      label: 'Самовывоз',
      disabled:
        !props.priceAnswer?.recipient?.farmer?.can_deliver &&
        isTooltipOpened.value,
    },
    {
      value: 'is_supplier_delivery',
      label: 'Доставка от поставщика',
      disabled: !props.priceAnswer?.recipient?.farmer?.can_deliver,
    },
  ]

  return initDeliveryOptions
})

const paymentDeliveryOptions = [
  { value: 'is_delivery_included', label: 'Включена в стоимость товара' },
  { value: 'is_delivery_excluded', label: 'Оплачивается отдельно' },
]

const paymentMethodOptions = [
  { value: 'is_pay_cash', label: 'Наличный расчёт' },
  { value: 'is_pay_non_cash', label: 'Безналичный расчёт' },
]

const paymentDelayOptions = [
  {
    value: 'is_payment_delayed',
    label: 'Отсрочка по оплате',
  },
]

const servicesOptions = computed(() => {
  const initServicesOptions = [
    { value: 'standard', label: 'Паллетирование' },
    { value: 'euro', label: 'Европаллеты' },
    { value: 'need_unload', label: 'Разгрузка товара' },
  ]

  const pallet: string[] = []

  if (props.priceAnswer?.palletizing_type_initial === 'standard') {
    pallet.push('standard')
  } else if (props.priceAnswer?.palletizing_type_initial === 'euro') {
    pallet.push('standard', 'euro')
  }

  if (props.priceAnswer?.need_unload !== null) {
    pallet.push('need_unload')
  }

  return initServicesOptions.filter((option) =>
    pallet.find((item) => item === option.value),
  )
})

const customFormat = (value: any) =>
  `к ${value.locale('ru').format('DD MMM, YYYY')}`

const handleOpenTooltip = () => {
  isTooltipOpened.value = true
}

const getPalletArr = (palletizingType: PaletizingType) => {
  if (palletizingType) {
    if (palletizingType === 'euro') {
      return ['standard', 'euro']
    } else {
      return ['standard']
    }
  } else {
    return []
  }
}

const updatePriceAnswer = (objWithUpdateField: any) => {
  PriceAnswerAPI.update({
    id: props.priceAnswer?.id,
    ...objWithUpdateField,
  }).catch(({ items }: { items: IPriceAnswer[] }) => {
    console.log(items)
  })
}

const handleBlurDeliveredDays = () => {
  formRef.value.validateFields(['deliveryTime']).then(() => {
    if (
      props.priceAnswer?.should_be_delivered_in_days !==
      shouldDeliveredInDays.value
    ) {
      updatePriceAnswer({
        should_be_delivered_in_days: shouldDeliveredInDays.value ?? null,
      })
    }
  })
}

const handleDateChange = (current: Dayjs) => {
  updatePriceAnswer({ should_be_delivered_at: current.format('YYYY-MM-DD') })
}

const disabledDate = (current: Dayjs) => {
  const currentDate = dayjs()
  const maxDate = currentDate.add(181, 'day')

  return (current && current < dayjs().endOf('day')) || current > maxDate
}

const handleBlurPaymentDelay = () => {
  formRef.value.validateFields(['paymentDelay']).then(() => {
    updatePriceAnswer({ payment_delay_days: paymentDelayDays.value ?? null })
  })
}

const handlePriceDelivery = (e: any) => {
  const { checked, value } = e.target
  updatePriceAnswer({
    [value]: true,
    [String(
      paymentDeliveryOptions.find((option) => option.value !== value)?.value,
    )]: false,
  })
}

const handleBlurCommentary = () => {
  formRef.value.validateFields(['comment']).then(() => {
    if (props.priceAnswer?.comment !== commentaryProvider.value) {
      updatePriceAnswer({ comment: commentaryProvider.value })
    }
  })
}

const validateDeliveryDays = async () => {
  if (props.priceAnswer?.should_be_delivered_at) {
    return Promise.resolve()
  }
  if (+shouldDeliveredInDays.value < 1 || +shouldDeliveredInDays.value > 180) {
    shouldDeliveredInDays.value = 1
    return Promise.reject(
      'Срок поставки не может быть менее 1 или более 180 дней.',
    )
  } else if (String(shouldDeliveredInDays.value).includes('.')) {
    shouldDeliveredInDays.value = 1
    return Promise.reject('Введите целое число')
  } else {
    return Promise.resolve()
  }
}
const validateDelay = async () => {
  if (+paymentDelayDays.value > 90 || +paymentDelayDays.value < 1) {
    return Promise.reject('Не меньше 1 и не больше 90 дней')
  } else if (String(paymentDelayDays.value).includes('.')) {
    return Promise.reject('Введите целое число')
  } else {
    return Promise.resolve()
  }
}

const validateCommentary = async () => {
  if (+commentaryProvider.value.length > 1000) {
    return Promise.reject('Должно быть не более 1000 символов')
  } else {
    return Promise.resolve()
  }
}

const changeDeliveryMethod = (e: any) => {
  const checked = state.delivery.length < e.length
  const value = checked
    ? e.find((option) => !state.delivery.includes(option))
    : state.delivery.find((option) => !e.includes(option))
  const obj = {
    [value]: checked,
  }
  if (state.delivery.length === 1 && !checked) {
    obj[
      String(
        deliveryOptions.value.find((option) => option.value !== value)?.value,
      )
    ] = true

    state.delivery = [
      String(
        deliveryOptions.value.find((option) => option.value !== value)?.value,
      ),
    ]
  } else {
    state.delivery = e
  }

  updatePriceAnswer(obj)
}

const changePaymentMethod = (e: any) => {
  const checked = state.paymentMethod.length < e.length
  const value = checked
    ? e.find((option) => !state.paymentMethod.includes(option))
    : state.paymentMethod.find((option) => !e.includes(option))
  const obj = {
    [value]: checked,
  }
  if (state.paymentMethod.length === 1 && !checked) {
    obj[
      String(
        paymentMethodOptions.find((option) => option.value !== value)?.value,
      )
    ] = true

    state.paymentMethod = [
      String(
        paymentMethodOptions.find((option) => option.value !== value)?.value,
      ),
    ]
  } else {
    state.paymentMethod = e
  }

  updatePriceAnswer(obj)
}

const rules: Record<string, Rule[]> = {
  deliveryTime: [{ validator: validateDeliveryDays, trigger: 'change' }],

  paymentDelay: [
    {
      validator: validateDelay,
      trigger: 'change',
    },
  ],

  comment: [
    {
      validator: validateCommentary,
      trigger: 'change',
    },
  ],
}

watch(
  () => [state.services, state.paymentDelay],
  (next, prev) => {
    const differentIndexArray = next.findIndex(
      (nextArr, index) => nextArr.length !== prev[index].length,
    )
    let differentField: string | undefined = ''

    if (
      next[differentIndexArray]?.length === prev[differentIndexArray]?.length
    ) {
      return
    }

    if (next[differentIndexArray]?.length > prev[differentIndexArray]?.length) {
      differentField = next[differentIndexArray]?.find(
        (option) =>
          !prev[differentIndexArray].find((option2) => option === option2),
      )
    } else {
      differentField = prev[differentIndexArray].find(
        (option) =>
          !next[differentIndexArray].find((option2) => option === option2),
      )
    }

    const contractObj: object = {}

    const isAddField =
      next[differentIndexArray].length > prev[differentIndexArray].length

    if (differentField === 'euro') {
      if (isAddField) {
        if (!state.services.includes('standard')) {
          state.services.push('standard')
        }
        contractObj['palletizing_type'] = 'euro'
      } else {
        if (!next[differentIndexArray].includes('standard')) {
          return // если нет поля standard и мы убрали euro
        }
        contractObj['palletizing_type'] = 'standard'
      }
    } else if (differentField === 'standard') {
      if (isAddField) {
        contractObj['palletizing_type'] = 'standard'
      } else {
        state.services = state.services.filter((item) => item !== 'euro')
        contractObj['palletizing_type'] = null
      }
    }
    // если next[differentIndexArray].length > значит checkbox нажали, иначе отжали
    else if (differentField) {
      contractObj[differentField] = isAddField
    }

    if (differentField === 'is_payment_delayed' && !isAddField) {
      contractObj['payment_delay_days'] = null
      paymentDelayDays.value = 0
    }

    updatePriceAnswer(contractObj)
  },
)

const emit = defineEmits(['button-click'])

const customerShouldBeDelivered = computed(() =>
  props.priceAnswer?.should_be_delivered_at
    ? `к ${dayjs(
        props.priceAnswer?.recipient?.price_request?.should_be_delivered_at,
      ).format('DD.MM.YYYY')}`
    : `${
        props.priceAnswer?.recipient?.price_request?.should_be_delivered_in_days
      } ${declensionDays(
        String(
          props.priceAnswer?.recipient?.price_request
            ?.should_be_delivered_in_days,
        ),
      )}`,
)

watch(
  () => props.priceAnswer,
  () => {
    if (!props.priceAnswer) {
      return
    }

    shouldDeliveredInDays.value = props.priceAnswer?.should_be_delivered_in_days

    if (props.priceAnswer?.should_be_delivered_at) {
      shouldDeliveredAt.value = dayjs(props.priceAnswer?.should_be_delivered_at)
    }

    if (props.priceAnswer?.payment_delay_days) {
      paymentDelayDays.value = props.priceAnswer?.is_payment_delayed
        ? props.priceAnswer?.payment_delay_days
        : 0
    }

    if (props.priceAnswer?.comment) {
      commentaryProvider.value = props.priceAnswer?.comment
    }

    deliveryOptions.value.map((option) => {
      if (props.priceAnswer && props.priceAnswer[option.value]) {
        state.delivery.push(option.value)
      }
    })

    paymentDelayOptions.map((option) => {
      if (props.priceAnswer && props.priceAnswer[option.value]) {
        state.paymentDelay.push(option.value)
      }
    })

    paymentDeliveryOptions.map((option) => {
      if (props.priceAnswer && props.priceAnswer[option.value]) {
        state.paymentDelivery = option.value
      }
    })

    paymentMethodOptions.map((option) => {
      if (props.priceAnswer && props.priceAnswer[option.value]) {
        state.paymentMethod.push(option.value)
      }
    })

    servicesOptions.value.map((option) => {
      if (props.priceAnswer && props.priceAnswer[option.value]) {
        state.services.push(option.value)
      }
    })

    if (props.priceAnswer) {
      state.services.push(...getPalletArr(props.priceAnswer?.palletizing_type))
    }
  },
  {
    immediate: true,
  },
)

// watch(
//   () => props.priceAnswer?.recipient?.farmer?.can_deliver,
//   (newValue) => {
//     if (!newValue && props.priceAnswer?.is_supplier_delivery) {
//       updatePriceAnswer({ is_supplier_delivery: null, is_self_delivery: true })
//     }
//   },
//   {
//     immediate: true,
//   },
// )
</script>

<template>
  <Form
    :disabled="props.priceAnswer?.recipient.answer_status === 'answered'"
    ref="formRef"
    :model="state"
    layout="vertical"
    :rules="rules"
  >
    <div class="terms-delivery">
      <div class="terms-delivery__title">
        <TitleToolbarBlock
          title="Условия к поставке"
          subtitle="Укажите условия, на которые вы согласны"
        />
      </div>
      <FormItem
        v-if="deliveryOptions.length"
        name="delivery"
        label="Способ получения"
        required
      >
        <Tooltip
          placement="bottomLeft"
          :overlayInnerStyle="{
            width: '232px',
          }"
          :onOpenChange="handleOpenTooltip"
        >
          <template v-if="!priceAnswer?.recipient.farmer.can_deliver" #title>
            В Вашем профиле не указано, что вы можете осуществлять доставку
          </template>
          <CheckboxGroup
            :value="state.delivery"
            :options="deliveryOptions"
            @change="changeDeliveryMethod"
          />
        </Tooltip>
      </FormItem>
      <FormItem
        v-if="state.delivery.includes('is_supplier_delivery')"
        name="paymentDelivery"
        label="Как оплачивается доставка?"
        required
      >
        <RadioGroup
          v-model:value="state.paymentDelivery"
          :options="paymentDeliveryOptions"
          :onChange="handlePriceDelivery"
        />
      </FormItem>
      <FormItem
        v-if="
          props.priceAnswer?.should_be_delivered_at || shouldDeliveredInDays
        "
        name="deliveryTime"
        label="Срок поставки"
      >
        <DatePicker
          v-if="props.priceAnswer?.should_be_delivered_at"
          v-model:value="shouldDeliveredAt"
          :onChange="handleDateChange"
          :style="{ width: '100%' }"
          :format="customFormat"
          :locale="locale"
          :disabledDate="disabledDate"
        />
        <InputNumber
          v-else
          :controls="false"
          v-model:value="shouldDeliveredInDays"
          @blur="handleBlurDeliveredDays"
          :formatter="(value) => `${value} ${declensionDays(value)}`"
          class="inputNumber"
        />
        <div :style="{ color: 'rgba(0, 0, 0, 0.45)', marginTop: '4px' }">
          Покупатель указал:
          {{ customerShouldBeDelivered }}
        </div>
      </FormItem>
      <FormItem required name="paymentMethod" label="Способ оплаты">
        <CheckboxGroup
          :value="state.paymentMethod"
          :options="paymentMethodOptions"
          @change="changePaymentMethod"
        />
      </FormItem>
      <FormItem
        v-if="priceAnswer?.is_payment_delayed"
        name="paymentMethod"
        label="Отсрочка по оплате"
        class="payment-delay"
      >
        <CheckboxGroup
          v-model:value="state.paymentDelay"
          :options="paymentDelayOptions"
        />
      </FormItem>
      <FormItem v-if="priceAnswer?.is_payment_delayed" name="paymentDelay">
        <InputNumber
          v-model:value="paymentDelayDays"
          :disabled="!state.paymentDelay.length"
          :onBlur="handleBlurPaymentDelay"
          :controls="false"
          :formatter="(value) => `${value} ${declensionDays(value)}`"
          placeholder="Укажите количество дней отсрочки"
          class="inputNumber"
          :precision="0"
        />
        <div :style="{ color: 'rgba(0, 0, 0, 0.45)', marginTop: '4px' }">
          Покупатель указал:
          {{
            `${
              props.priceAnswer.recipient.price_request.payment_delay_days
            } ${declensionDays(
              props.priceAnswer.recipient.price_request.payment_delay_days,
            )}`
          }}
        </div>
      </FormItem>
      <FormItem v-if="servicesOptions.length" name="services" label="Услуги">
        <CheckboxGroup
          v-model:value="state.services"
          :options="servicesOptions"
        />
      </FormItem>
    </div>
    <div class="comment-block">
      <FormItem class="withoutMargin" name="comment">
        <Textarea
          v-model:value="commentaryProvider"
          :onBlur="handleBlurCommentary"
          rows="4"
          placeholder="Комментарий к ответу"
        />
      </FormItem>
      <span class="comment-caption">Максимум 1000 символов</span>
    </div>
  </Form>
</template>

<style scoped lang="scss">
.terms-delivery {
  padding: 20px 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}
:deep(.ant-form-item-label) {
  font-weight: 600;
}
:deep(.ant-checkbox-group) {
  flex-direction: column;
  gap: 8px;
}

.inputNumber {
  width: 100%;
}
.withoutMargin {
  margin-bottom: 0 !important;
}
.comment-caption {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.comment-block {
  font-size: 14px;
  padding: 20px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.payment-delay {
  margin-bottom: 8px;
}

:deep(.ant-radio-group) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
