<script setup lang="ts">

import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['close1CModalNotFound'])

const closeModal = () => {
  emit('close1CModalNotFound')
}
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">По указанными данным ничего не найдено</div>
    </template>
    <template #body>
      <div class="modal__body">
        Проверьте правильность введённых данных в 1С: <br />
        адрес сайта, имя пользователя и пароль
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal"> Закрыть </Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>
