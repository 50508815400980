<script setup lang="ts">
import { Alert } from 'ant-design-vue'
import { IOrder } from '@/types/interfaces'
import { computed } from 'vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import { IAddress } from '@/types/IAddress'

interface IProps {
  order: IOrder
  is_complex_order: boolean
  delivery_address: IAddress | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['edit_general_order'])
const is_show = computed(() => {
  return (
    props.order.status === NewGeneralOrderStatus.SupplierModifiedOrder &&
    !props.delivery_address &&
    props.order.delivery_method === 'Доставка фермером'
  )
})
</script>

<template>
  <Alert show-icon type="warning" v-if="is_show">
    <template #message v-if="is_complex_order"
      >Поставщик изменил способ получения с самовывоза на доставку. Отмените
      заказ или измените способ получения.</template
    >
    <template #message v-else
      >Поставщик изменил способ получения с самовывоза на доставку. Пожалуйста,
      <span class="link" @click="emit('edit_general_order')"
        >укажите адрес</span
      >
      доставки или измените способ получения заказа.
    </template>
  </Alert>
</template>

<style scoped lang="scss">
.link {
  //styleName: Base/Underline;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1677ff;
  cursor: pointer;
}
</style>
