<script setup lang="ts">
import { onMounted, ref, UnwrapRef } from 'vue'
import { AddressAPI } from '@/api/AddressAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { IAddress, IContact } from '@/types/interfaces'
import { ContactAPI } from '@/api/ContactAPI'
import {
  FirstFormState,
  IProductTableItem,
} from '@/views/Customer/CustomerCreateRequestView.vue'
import { ProductAntTreeAPI } from '@/api/ProductAntTreeAPI'
import ReductionSupplyRequirments from '@/components/CreateReduction/CreateReductionBody/ReductionSupplyRequirments.vue'
import ReductionProducts from '@/components/CreateReduction/CreateReductionBody/ReductionProducts.vue'
import ReductionFarmers from '@/components/CreateReduction/CreateReductionBody/ReductionFarmers.vue'
import { getUserIdTemporary } from '@/helpers/scripts'

interface Props {
  currentStep: number
  firstFormState: UnwrapRef<FirstFormState>
  secondFormState: { domains: IProductTableItem[] }
  thirdFormState: UnwrapRef<any[]>
  checkedKeys: UnwrapRef<string[]>
  expandedKeys: UnwrapRef<string[]>
}

const props = defineProps<Props>()
const emit = defineEmits([
  'editFirstForm',
  'editSecondForm',
  'editSecondForm',
  'deleteFarmer',
  'handleSelectFarmerModal',
  'addProduct',
  'removeProduct',
  'checkKeys',
  'expandKeys',
  'handlePublicReduction',
])
const { getCoreCookie } = useJWTDecode()
const addressList = ref<IAddress[]>([])
const contactPersonList = ref<IContact[]>([])
const treeData = ref([])

const firstFormRef = ref()
const secondFormRef = ref()

defineExpose({
  firstFormRef,
  secondFormRef,
})

onMounted(async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  const addressGet = AddressAPI.getAddresses(
      currentCompanyId,
    'customer',
  ).then((res) => {
    addressList.value = res.items
  })
  const contactsGet = ContactAPI.getItemsContacts(
    [
      ['company_id', 'eq', currentCompanyId],
      'AND',
      ['company_type', 'eq', 'customer'],
    ],
    1,
    100,
  ).then((res) => {
    contactPersonList.value = res.items
  })
  const treeGet = ProductAntTreeAPI.getItems().then((res) => {
    treeData.value = res
  })
  await Promise.all([addressGet, contactsGet, treeGet])
})

const firstFormEditWrapper = (field: string, value: any) => {
  emit('editFirstForm', field, value)
}

const secondFormEditWrapper = (field: string, value: any, index: number) => {
  emit('editSecondForm', field, value, index)
}
const thirdFormEditWrapper = (uid: string) => {
  emit('deleteFarmer', uid)
}
const handleSelectFarmerModalWrapper = (value: boolean) => {
  emit('handleSelectFarmerModal', value)
}
const addProductWrapper = (value: IProductTableItem) => {
  emit('addProduct', value)
}

const removeProductWrapper = (key: string) => {
  emit('removeProduct', key)
}
const checkKeysWrapper = (keys: string[], e: any) => {
  emit('checkKeys', keys, e)
}
const expandKeysWrapper = (keys: string[]) => {
  emit('expandKeys', keys)
}
</script>

<template>
  <div class="create-request-body">
    <ReductionSupplyRequirments
      ref="firstFormRef"
      v-if="props.currentStep === 0"
      :addressList="addressList"
      :contactPersonList="contactPersonList"
      :form-state="props.firstFormState"
      @editFirstForm="firstFormEditWrapper"
    />
    <ReductionProducts
      v-if="props.currentStep === 1"
      ref="secondFormRef"
      :form-state="props.secondFormState"
      :checkedKeys="checkedKeys"
      :expandedKeys="expandedKeys"
      :treeData="treeData"
      @editSecondForm="secondFormEditWrapper"
      @addProduct="addProductWrapper"
      @removeProduct="removeProductWrapper"
      @checkKeys="checkKeysWrapper"
      @expandKeys="expandKeysWrapper"
    />
    <ReductionFarmers
      v-if="props.currentStep === 2"
      :form-state="props.thirdFormState"
      @deleteFarmer="thirdFormEditWrapper"
      @handleSelectFarmerModal="handleSelectFarmerModalWrapper"
      @handlePublicReduction="emit('handlePublicReduction')"
    />
  </div>
</template>

<style scoped lang="scss">
.create-request-body {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
