<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import type { TreeProps } from 'ant-design-vue'
import { Button, Drawer, RangePicker, Select, Tree } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { ProductAntTreeAPI } from '@/api/ProductAntTreeAPI'
import { PartnersAPI } from '@/api/PartnersAPI'

interface IOption {
  value: string
  label: string
}

const emit = defineEmits(['sendFilter'])

const format = 'DD.MM.YYYY'
const optionsPartners = ref<IOption[]>([])
const selectedPartners = ref()
const searchValue = ref<string>('')
const autoExpandParent = ref<boolean>(true)
const expandedKeys = ref()
const checkedKeys = ref<string[]>([])
const dataList: TreeProps['treeData'] = []
const productTree = ref()
const selectedProducts = ref([])
const selectedProductsShow = ref([])
const date = ref<[Dayjs, Dayjs] | null>()
const selectedDate = ref()

const openDrawer = ref(false)

const isMaxProducts = computed(() => {
  return checkedKeys.value.length >= 100
})

const disableReset = computed(() => {
  return !(
    selectedPartners.value?.length ||
    selectedProducts.value?.length ||
    date.value
  )
})

const onRangeChange = (date: [Dayjs, Dayjs], dateString: [string, string]) => {
  selectedDate.value = dateString
  sendFilter()
}

const sendFilter = (type?: string, title?: string) => {
  let partners = []
  let products = []

  const getFilters = () => {
    const filtersData = localStorage.getItem('sendingFilters')
    return filtersData ? JSON.parse(filtersData) : {}
  }

  const uniqDataList = [...new Set(dataList)]

  const deletedProduct = uniqDataList.find((el: any) => el.title === title)
  const deletedProductKey = deletedProduct?.key

  if (type === 'partners') {
    selectedPartners.value = selectedPartners.value.filter(
      (partner: string) => Number(partner) !== Number(title),
    )
    partners = partners.filter(
      (partner: string) => Number(partner) !== Number(title),
    )
  }

  if (type === 'products') {
    selectedProducts.value = selectedProducts.value.filter(
      (product: string) => Number(product) !== deletedProductKey,
    )
    getFilters().products = selectedProducts.value
    partners = partners.filter(
      (product: string) => Number(product) !== deletedProductKey,
    )
  }

  if (selectedDate.value) {
    localStorage.setItem(
      'sendingFilters',
      JSON.stringify({
        ...getFilters(),
        from: selectedDate.value[0],
        to: selectedDate.value[1],
      }),
    )
  }
  if (selectedPartners.value) {
    selectedPartners.value?.forEach((partner: number) => {
      partners.push(partner)
    })
    localStorage.setItem(
      'sendingFilters',
      JSON.stringify({
        ...getFilters(),
        partners: partners,
      }),
    )
  }
  if (selectedProducts.value) {
    selectedProducts.value?.forEach((product: number) => {
      products.push(product)
    })
    localStorage.setItem(
      'sendingFilters',
      JSON.stringify({
        ...getFilters(),
        products: products,
      }),
    )
  }
  emit('sendFilter', getFilters())
}

const submitSelectedProducts = () => {
  let products = checkedKeys?.value.map((product) => {
    return product?.replace(':end', '')
  })

  selectedProductsShow.value = []

  products?.forEach((product: any) => {
    const item = dataList?.find((item) => item?.key == product)

    selectedProductsShow.value?.push({
      id: item?.key,
      key: item?.title,
      title: item?.title,
    })
  })

  selectedProducts.value = products
  openDrawer.value = false
  sendFilter()
}

const resetFilters = () => {
  selectedPartners.value = []
  selectedProducts.value = []
  selectedProductsShow.value = []
  checkedKeys.value = []
  date.value = null
  if (selectedDate.value) {
    selectedDate.value[0] = null
    selectedDate.value[1] = null
  }

  sendFilter()
}

const getParentKey = (
  key: string | number,
  tree: TreeProps['treeData'],
): string | number | undefined => {
  let parentKey
  if (tree?.length) {
    for (let i = 0; i < tree?.length; i++) {
      const node = tree[i]
      if (node?.children) {
        if (node?.children?.some((item) => item?.key === key)) {
          parentKey = node?.key
        } else if (getParentKey(key, node?.children)) {
          parentKey = getParentKey(key, node?.children)
        }
      }
    }
  }
  return parentKey
}

function processNode(node: any, disabledNotSelected: boolean) {
  const newKey = `${node.key}:${node.path ? node.path.join('') : 'end'}`
  const newNode = { ...node, key: newKey, title: node.title }
  dataList?.push({ key: node.key, title: node.title })
  if (newNode.children?.length > 0 || newNode.products?.length > 0) {
    newNode.checkable = false
  }
  if (newNode.products?.length > 0) {
    newNode.children = newNode.products.map((product: any) => {
      return {
        key: product?.key,
        title: product?.title,
        disableCheckbox:
          disabledNotSelected &&
          checkedKeys.value.includes(`${product?.key}:end`),
        measurement_unit_id: product?.measurement_unit_id,
      }
    })
  }
  newNode.children = newNode?.children?.map((child: any) =>
    processNode(child, disabledNotSelected),
  )
  return newNode
}

const getProductTree = async () => {
  await ProductAntTreeAPI.getPartnerProducts().then((res) => {
    productTree.value = res
  })
}

const newTreeData = computed(() => {
  return productTree.value.map((node: any) =>
    processNode(node, isMaxProducts.value),
  )
})

const makeOptions = async () => {
  optionsPartners.value = []
  await PartnersAPI.myPartners([]).then((res) => {
    res?.items.forEach((partner: any) => {
      optionsPartners.value?.push({
        value: partner?.company_id,
        label: partner?.name,
      })
    })
  })
}

const deselect_product = (product_name: string, option: any) => {
  const item = dataList?.find((item) => item?.title == product_name)
  checkedKeys.value = checkedKeys.value.filter(
    (key: string) => key !== `${item?.key}:end`,
  )
  sendFilter('products', product_name)
}

const close_drawer = () => {
  openDrawer.value = false
  checkedKeys.value = selectedProducts.value.map((product: string) => {
    return `${product}:end`
  })
}

watch(searchValue, (value) => {
  const expanded = productTree.value
    .map((item: TreeProps['treeData'][number]) => {
      if (item.title.indexOf(value) > -1) {
        return getParentKey(item.key, newTreeData.value)
      }
      return null
    })
    .filter(
      (item: any, i: number, self: any) => item && self.indexOf(item) === i,
    )
  expandedKeys.value = expanded
  searchValue.value = value
  autoExpandParent.value = true
})

onMounted(async () => {
  await makeOptions()
  await getProductTree()
})

onUnmounted(() => {
  localStorage.removeItem('sendingFilters')
})
</script>

<template>
  <Drawer
    v-model:open="openDrawer"
    width="500"
    destroyOnClose
    @close="close_drawer"
    class="drawer"
    :headerStyle="{
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      padding: '24px 64px 24px 32px',
    }"
    :footerStyle="{
      height: '88px',
      padding: '0px',
    }"
  >
    <template #extra>
      <h4 class="drawer-title">Фильтр по товарам</h4>
      <!--      <Input class="search" placeholder="Товары" v-model:value="searchValue">-->
      <!--        <template #suffix>-->
      <!--          <SearchOutlined :fill="'#bfbfbf'" />-->
      <!--        </template>-->
      <!--      </Input>-->
    </template>
    <Tree
      v-model:expandedKeys="expandedKeys"
      v-model:checkedKeys="checkedKeys"
      v-model:selectedKeys="selectedProducts"
      :checkable="true"
      :tree-data="newTreeData"
      :selectable="false"
      :show-line="true"
      class="tree-block"
    >
      <template #title="{ title }">
        {{ title }}
      </template>
    </Tree>
    <template #footer>
      <div class="buttons">
        <Button size="large" @click="close_drawer">Закрыть</Button>
        <Button size="large" type="primary" @click="submitSelectedProducts">
          Применить
        </Button>
      </div>
    </template>
  </Drawer>

  <div class="filters">
    <Select
      class="select"
      mode="multiple"
      :showSearch="false"
      placeholder="Партнёр"
      v-model:options="optionsPartners"
      v-model:value="selectedPartners"
      @select="sendFilter"
      max-tag-count="responsive"
      @deselect="(e: string) => sendFilter('partners', e)"
    >
      <template #notFoundContent>
        <span class="not-found">
          Не удалось обнаружить контрагента по запросу.<br />
          Попробуйте другой запрос.
        </span>
      </template>
    </Select>

    <Select
      class="select"
      mode="tags"
      notFoundContent=""
      placeholder="Товары"
      v-model:value="selectedProductsShow"
      key="title"
      :open="false"
      max-tag-count="responsive"
      @click="openDrawer = true"
      @deselect="deselect_product"
    />

    <RangePicker
      class="datepicker"
      v-model:value="date"
      :locale="locale"
      :placeholder="['Даты оформления заказов', '']"
      :format="format"
      :inputReadOnly="true"
      @change="onRangeChange"
    />

    <Button
      :disabled="disableReset"
      class="reset-filters"
      type="text"
      @click="resetFilters"
    >
      <template #icon> <CloseOutlined /> </template>
      Сбросить фильтры
    </Button>
  </div>
</template>

<style scoped lang="scss">
.drawer-title {
  width: 404px;
  margin-bottom: 24px;
}

.search {
  width: 404px;
}

.buttons {
  position: absolute;
  display: flex;
  bottom: 24px;
  right: 32px;
  gap: 12px;
}

.filters {
  position: relative;
  display: flex;
  gap: 16px;

  .select {
    height: 32px;
    width: 360px;

    :deep(.ant-select-selection-overflow) {
      height: 32px;
      overflow-y: auto;
    }
  }

  .datepicker {
    padding-top: 0;
    padding-bottom: 0;
    height: 36px;
    width: 450px;
  }

  .reset-filters {
    position: absolute;
    right: 0;
  }
}

.not-found {
  font-size: 12px;
  line-height: 20px;
  color: #000000e0;
}

:deep(.ant-select-selection-overflow) {
  height: 32px;
  overflow-y: scroll;
}

:deep(.ant-drawer-close) {
  position: absolute !important;
  right: 0 !important;
}

:deep(.ant-tree-switcher-noop .ant-tree-switcher-line-icon) {
  display: none !important;
}
</style>
