<script lang="ts">
import { defineComponent } from 'vue'
import { Button } from 'ant-design-vue'
import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  UndoOutlined,
} from '@ant-design/icons-vue'

type TActionButtonTypes =
  | 'Close'
  | 'Repeat'
  | 'Cancel'
  | 'Back'
  | 'AllSuppliers'
  | 'Delivery'

export default defineComponent({
  props: {
    type: {
      type: String as () => TActionButtonTypes,
      required: true,
      default: 'Close',
    },
  },
  emits: ['handleClick'],
  components: {
    Button,
    CheckOutlined,
    UndoOutlined,
    CloseOutlined,
    LeftOutlined,
  },
})
</script>

<template>
  <Button
    v-if="type === 'Close'"
    class="button__close"
    @click="$emit('handleClick')"
  >
    <CheckOutlined /> Закрыть</Button
  >
  <Button
    v-if="type === 'Repeat'"
    class="button__repeat"
    @click="$emit('handleClick')"
    ><UndoOutlined /> Повторить</Button
  >
  <Button
    v-if="type === 'Cancel'"
    class="button__cancel"
    @click="$emit('handleClick')"
  >
    <CloseOutlined /> Отменить
  </Button>
  <Button
    v-if="type === 'Back'"
    class="button__back"
    @click="$emit('handleClick')"
  >
    <LeftOutlined />
  </Button>
  <Button
    v-if="type === 'AllSuppliers'"
    class="button__all-suppliers"
    @click="$emit('handleClick')"
  >
    <img src="./icons/People.svg" alt="icon" loading="lazy" />
    <div>Все поставщики</div>
  </Button>
  <Button
    v-if="type === 'Delivery'"
    class="button__delivery"
    @click="$emit('handleClick')"
  >
    <img src="./icons/Delivery.svg" alt="icon" loading="lazy" />
    <div>Доставка и оплата</div>
  </Button>
</template>

<style lang="scss">
.button {
  &__delivery {
    color: #1677ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    align-items: center;
    display: flex;
    gap: 5px;
    padding-left: 0;
    padding-right: 0;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
  }
  &__all-suppliers {
    color: #1677ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    align-items: center;
    display: flex;
    gap: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  &__close {
    color: #1677ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    &:hover {
      cursor: pointer;
    }
  }
  &__repeat {
    color: #1677ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    &:hover {
      cursor: pointer;
    }
  }
  &__cancel {
    color: #ff4d4f;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    &:hover {
      cursor: pointer;
    }
  }
  &__back {
    width: 32px;
    height: 32px;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
