<script setup lang="ts">
import { Breadcrumb, Divider } from 'ant-design-vue'
import OrdersFilters from '@/views/Admin/OrderView/OrdersFilters.vue'
import OrdersTable from '@/views/Admin/OrderView/OrdersTable.vue'
import OrdersNotFound from '@/views/Admin/OrderView/OrdersNotFound.vue'
import NoOrders from '@/views/Admin/OrderView/NoOrders.vue'
import {onMounted, ref} from 'vue'
import { OrderAPI } from '@/api/OrderAPI'
import { useCentrifuge } from '@/composables/useCentrifuge'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {useRoute} from "vue-router";
import emitter from "@/emitter";
import FullscreenLoading from "@/components/Loaders/FullscreenLoading.vue";

const { getCoreCookie } = useJWTDecode()

interface IPageData {
  page: number
  per_page: number
}

const BreadCrumbItem = Breadcrumb.Item
const isFetching = ref<boolean>(true)
const orders = ref({})
const page = ref(1)
const per_page = ref(10)
const lastFilter = ref([])
const lastKeyword = ref<string>('')
const flagReset = ref<boolean>(false)
const route = useRoute()

const getOrders = async (filter?: any, keyword?: string) => {
  orders.value = []
  isFetching.value = true
  if (keyword || lastKeyword.value.length) {
    await OrderAPI.getOrdersByKeyword(
      1,
      per_page.value,
      keyword || lastKeyword.value,
    ).then((res) => {
      orders.value = res
      lastKeyword.value = keyword || lastKeyword.value
      lastFilter.value = {}
      isFetching.value = false
    })
  } else {
    await OrderAPI.getOrdersAdmin(
      1,
      per_page.value,
      filter || lastFilter.value,
    ).then((res) => {
      orders.value = res
      lastFilter.value = filter || lastFilter.value
      lastKeyword.value = ''
      isFetching.value = false
    })
  }
}

const paginationChange = async (pageData: IPageData) => {
  page.value = pageData.page
  per_page.value = pageData.per_page
  orders.value = []
  isFetching.value = true
  if (lastKeyword.value.length) {
    await OrderAPI.getOrdersByKeyword(
      page.value,
      per_page.value,
      lastKeyword.value,
    ).then((res) => {
      orders.value = res
      isFetching.value = false
    })
  } else {
    await OrderAPI.getOrdersAdmin(
      page.value,
      per_page.value,
      lastFilter.value,
    ).then((res) => {
      orders.value = res
      isFetching.value = false
    })
  }
}

const resetFilters = () => {
  lastKeyword.value = ''
  lastFilter.value = []
  page.value = 1
  per_page.value = 10
  getOrders()
}

const connectOrders = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  startCentrifuge()
  const id = getCoreCookie().id ?? ''
  addChanel('core@Admin.' + id, silent_refetch)
}

const silent_refetch = async () => {
  if (lastKeyword.value.length) {
    await OrderAPI.getOrdersByKeyword(
      page.value,
      per_page.value,
      lastKeyword.value,
    ).then((res) => {
      orders.value = res
    })
  } else {
    await OrderAPI.getOrdersAdmin(
      page.value,
      per_page.value,
      lastFilter.value,
    ).then((res) => {
      orders.value = res
    })
  }
}

const initPage = async () => {
  const companyType = route.query.type
  const companyId = route.query.company_id
  if (!companyType && !companyId) {
    await getOrders()
    await connectOrders()
  }
}

emitter.on('refresh-token', async (e) => {
  await initPage()
})

onMounted(async () => {
  await initPage()
})
</script>

<template>
  <div class="order-list">
    <div class="header">
      <Breadcrumb>
        <BreadCrumbItem>Список заказов</BreadCrumbItem>
      </Breadcrumb>
    </div>
    <div class="orders">
      <OrdersFilters
        :flag-reset="flagReset"
        @send-filters="getOrders"
        @reset-filters="resetFilters"
      />
      <Divider class="divider" />
      <OrdersTable
        :orders="orders"
        v-show="orders.data?.items?.length"
        @pagination-change="paginationChange"
      />
      <OrdersNotFound
        @reset-filters="flagReset = !flagReset"
        v-show="!isFetching && !orders.data?.items?.length"
      />
    </div>
    <NoOrders
      v-show="
        !orders.data?.items?.length &&
        !isFetching &&
        !lastKeyword &&
        !lastFilter
      "
    />
    <div class="loader-wrapper" v-if="isFetching">
      <FullscreenLoading type="secondary" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.order-list {
  height: 100vh;
}

.header {
  width: 100%;
  padding: 16px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;

  .ant-breadcrumb {
    color: #000000e0;
  }
}

.orders {
  margin: 24px;

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.loader-wrapper {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
</style>
