import axiosInstance from "@/axios";
import {useCookies} from "vue3-cookies";
import { AxiosReqType } from '@/types/RequestManagerEnum';
import {IRequestProps, IRequestType, IResponse} from "@/types/RequestManagerInterfaces";
import {Microservices} from "@/types/enums";
import axios from "axios";

const { cookies } = useCookies()
const defaultBaseUrl = import.meta.env.VITE_BASE_URL

class RequestManagerClass {
    /**
     * Make an HTTP request based on the provided parameters.
     *
     * @param {IRequestType} params - The request parameters, including base URL, service, model, method, axios request type, data, headers, and debug flag.
     * @returns {Promise<any>} A promise with the response data.
     */
    async #requestType({ baseUrl, service, model, method, axiosReqType, data, headers, isDebug, isAuthRequest }: IRequestType): Promise<any> {
        const _fullUrl = `${baseUrl}/${service}/${model}/${method}`
        const _baseHeaders = { Authorization: cookies.get('core')}
        const _mergeHeaders = isAuthRequest ? { headers: { ..._baseHeaders, ...headers } } : undefined

        const calcAxiosInstance = (isAuthRequest: boolean) => {
            const axiosInstancesMap = {
                isAuth: axiosInstance,
                inNotAuth: axios,
            }
            return axiosInstancesMap[isAuthRequest ? 'isAuth' : 'inNotAuth']
        }

        if (isDebug) console.debug('_mergeHeaders:', _mergeHeaders, '_fullUrl:', _fullUrl, 'axiosReqType:', axiosReqType)
        if (axiosReqType === AxiosReqType.Get) return await calcAxiosInstance(isAuthRequest).get(_fullUrl, _mergeHeaders)
        if (axiosReqType === AxiosReqType.Post) return await calcAxiosInstance(isAuthRequest).post(_fullUrl, data, _mergeHeaders)
        if (axiosReqType === AxiosReqType.Put) return await calcAxiosInstance(isAuthRequest).put(_fullUrl, data, _mergeHeaders)
        if (axiosReqType === AxiosReqType.Delete) return await calcAxiosInstance(isAuthRequest).delete(_fullUrl, _mergeHeaders)
    }

    async #responseFormat(response: any, type: string): Promise<any> {
        const typesMap = {
            res_data: response.data,
            default: response?.data?.action_result?.data,
            action_result: response?.data?.action_result,
            items_data: response?.data?.action_result?.data?.items,
            items: response?.items
        }
        return typesMap[type]
    }

    /**
     * Make a request with default values for base URL and service.
     *
     * @param {IRequestProps} params - The request parameters, including base URL, service, model, method, axios request type, data, and debug flag.
     * @returns {Promise<any>} A promise with the response data.
     */
    async request({ baseUrl = defaultBaseUrl, service = Microservices.Core, model, method, axiosReqType = AxiosReqType.Post, data, isDebug, isAuthRequest = true, responseType = 'default', headers }:IRequestProps): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.#requestType({
                    baseUrl,
                    service,
                    model,
                    method,
                    axiosReqType,
                    data,
                    isDebug,
                    isAuthRequest,
                    headers
                });
                return resolve(this.#responseFormat(response, responseType));
            } catch (error) {
                return reject(error);
            }
        })
    }
}

const RequestManager = new RequestManagerClass()
export { RequestManager }
