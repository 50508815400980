<script lang="ts" setup>
import { Button, Flex } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import InviteModal from '@/components/AccountForms/EmployeeTab/InviteModal.vue'
import { ref } from 'vue'

const isOpenInviteModal = ref(false)

const invertOpenInviteModal = () => {
  isOpenInviteModal.value = !isOpenInviteModal.value
}
</script>

<template>
  <Flex justify="space-between" align="center">
    <h3>Список сотрудников</h3>
    <Button :onClick="invertOpenInviteModal" type="link">
      <template #icon>
        <PlusOutlined />
      </template>
      Пригласить сотрудника
    </Button>
  </Flex>

  <InviteModal
    :is-open="isOpenInviteModal"
    @close-modal="invertOpenInviteModal"
  />
</template>

<style scoped lang="scss">
h3 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(45, 55, 72, 1);
}
</style>
