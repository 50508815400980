<script setup lang="ts">
import { h } from 'vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { Spin } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue'

interface IProps {
  open: boolean
}

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
  },
  spin: true,
})

const props = defineProps<IProps>()
const emit = defineEmits(['close1CModalLoading'])

const closeModal = () => {
  emit('close1CModalLoading')
}
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px 20px 10px 20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">
        <Spin class="farm-loader" :indicator="indicator" width="20px" height="20px" />
      </div>
    </template>
    <template #body>
      <div class="modal__body">Идёт загрузка товаров из 1С</div>
    </template>
    <template #footer>
      <div class="modal__footer">
        Пожалуйста, дождитесь полной синхронизации
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    display: flex;
    height: 20px;
    margin-left: 40px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  &__body {
    font-family: Inter, sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  &__footer {
    text-align: center;
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
  }
}
</style>
