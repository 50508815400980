<script setup lang="ts">


const props = defineProps({
  type: {
    type: String,
    default: 'primary',
  },
})
</script>

<template>
  <div class="fullscreen-loading">
    <div class="spinner-rolling-52rr2tsmbvl">
      <div class="loading-ss9u9gtopam">
        <div
          :class="{
            primary: props.type === 'primary',
            secondary: props.type === 'secondary',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes loading-ss9u9gtopam {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.fullscreen-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-ss9u9gtopam div {
    position: absolute;
    width: 50px;
    height: 50px;
    &.primary {
      border: 6px solid #fff;
      border-top-color: transparent;
    }
    &.secondary {
      border: 6px solid #0066ff;
      border-top-color: transparent;
    }
    border-radius: 50%;
  }
  .loading-ss9u9gtopam div {
    animation: loading-ss9u9gtopam 1s linear infinite;
    top: 45px;
    left: 45px;
  }
  .spinner-rolling-52rr2tsmbvl {
    width: 90px;
    height: 90px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .loading-ss9u9gtopam {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .loading-ss9u9gtopam div {
    box-sizing: content-box;
  }
}
</style>
