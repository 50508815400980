import { APIModels } from '@/types/enums'
import { useCookies } from 'vue3-cookies'
import axiosInstance from '@/axios'
import { IFilterOrders } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import { EgalMethods } from '@/types/RequestManagerEnum';

const { cookies } = useCookies()

class OrderAPIModel {
  async createOrder(data: any): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.create,
          data: { attributes: data },
          headers: { Authorization: cookies.get('core')},
      })
  }

  async getOrders(
    page: number,
    filters: any = [],
    withs: string[] = [
      'farmer',
      'farmer.farmer_products',
      'customer',
      'order_positions',
      'general_order',
    ],
    perPage = 100,
    order = [['created_at', 'desc']],
  ): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.GetItems,
          data: { filter: filters, withs, order, pagination: { per_page: perPage, page } },
          headers: { Authorization: cookies.get('core')},
      })
  }

  async getOrdersAdmin(
    page: number,
    per_page: number,
    filter?: IFilterOrders,
  ): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.getItemsWithTotal,
          responseType: 'action_result',
          headers: { Authorization: cookies.get('core')},
          data: { pagination: { per_page, page },
              filter: filter || [],
              withs: ['farmer', 'farmer.users', 'customer', 'customer.users', 'general_order'],
              order: [
                  { column: 'status', direction: 'asc' },
                  { column: 'created_at', direction: 'desc' },
              ],},
      })
  }

  async getOrder(
    filters: any = [],
    withs: string[] = [
      'farmer',
      'farmer.farmer_products',
      'customer',
      'order_positions',
      'farmer.contacts',
      'general_order',
      'documents',
    ],
    perPage = 100,
  ): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.GetItems,
          data: { filter: filters, order: [['created_at', 'desc']], withs, pagination: { per_page: perPage, page: 1 } },
          responseType: 'items_data',
          headers: { Authorization: cookies.get('core')},
      })
  }

  async getOrdersByKeyword(
    page: number,
    per_page: number,
    keyword: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // TODO: Доработать RequestManager
      return axiosInstance
        .post(
          `${import.meta.env.VITE_BASE_URL}/core/PromptHelper/getOrdersByKeyword?keyword=${keyword}`,
          {
            pagination: {
              per_page: per_page,
              page: page,
            },
            withs: ['farmer.users', 'customer.users', 'general_order'],
            order: [
              { column: 'status', direction: 'asc' },
              { column: 'created_at', direction: 'desc' },
            ],
          },
            {
                headers: { Authorization: cookies.get('core')},
            }
        )
        .then((response) => {
          resolve(response.data.action_result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getItemsWithTotal(filter: any): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.getItemsWithTotal,
          data: { filter, withs: ['farmer', 'customer']},
          headers: { Authorization: cookies.get('core')},
      })
  }

  async updateOrderStatus(id: number, status: string): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.update,
          data: { attributes: { id, status }},
          headers: { Authorization: cookies.get('core')},
      })
  }

  async updateManyOrdersStatus(objects: any): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.updateMany,
          data: { objects },
          headers: { Authorization: cookies.get('core')},
      })
  }

  async getOneOrder(
    filters: any = [],
    withs: string[] = [],
    id: number,
  ): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.GetItem,
          data: { id, filter: filters, withs },
          headers: { Authorization: cookies.get('core')},
      })
  }

  async updateOrder(data: any): Promise<any> {
      return RequestManager.request({
          model: APIModels.Order,
          method: EgalMethods.update,
          data: { attributes: data },
          headers: { Authorization: cookies.get('core')},
      })
  }
}

const OrderAPI = new OrderAPIModel()
export { OrderAPI }
