<script lang="ts" setup>
import ReductionHeader from '@/views/Reductions/components/ReductionHeader/ReductionHeader.vue'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import { ReductionAPI } from '@/api/ReductionAPI'
import { IReduction } from '@/types/interfaces'
import dayjs from 'dayjs'
import { Popover, TabPane, Tabs } from 'ant-design-vue'
import FarmerReductionInfo from '@/views/Farmer/reductions/reduction/farmerReductionInfoTab/FarmerReductionInfo.vue'
import MakeBetDrawer from '@/views/Farmer/reductions/reduction/makeBetDrawer/MakeBetDrawer.vue'
import BetForCompanyTab from '@/views/Farmer/reductions/reduction/BetForCompanyTab.vue'
import BetForProductTab from '@/views/Farmer/reductions/reduction/BetForProductTab.vue'
import { getUserIdTemporary } from '@/helpers/scripts'
import router from '@/router'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import emitter from "@/emitter";

const route = useRoute()

const reduction = ref<IReduction | null>(null)
const isLoadingReduction = ref(false)
const activeKey = ref('1')
const drawerIsOpen = ref(false)
const selectedRequestId = ref<null | number>(null)

const farmerId = +getUserIdTemporary()

const changeSelectedRequestId = (value: number | null) => {
  selectedRequestId.value = value
  toggleDrawerOpen()
}

const goToReductionsList = () => {
  router.push('/reductions')
}

const toggleDrawerOpen = () => {
  drawerIsOpen.value = !drawerIsOpen.value
}

const getReduction = async () => {
  isLoadingReduction.value = true
  ReductionAPI.getItemsWithBetter(
    1,
    [['id', 'eq', String(route.params.id)]],
    [
      'customer',
      'mergedRecipients',
      'customer.company_rating',
      'reduction_participants',
      'reductionProductRequests.product',
      'reductionProductRequests.reductionProductAnswers',
      'reductionParticipants.farmer',
      'reductionProductRequests.reductionProductAnswers.reductionParticipant.farmer.addresses',
    ],
  )
    .then((res) => {
      reduction.value = res[0]
    })
    .finally(() => {
      isLoadingReduction.value = false
    })
}

emitter.on('refresh-token', async (e) => {
  await getReduction()
})

onMounted(async () => {
  await getReduction()
})

const headerTitle = computed(
  () =>
    `Торг на покупку от ${reduction.value?.customer?.name}, ${dayjs(reduction.value?.created_at).format('DD.MM.YYYY')}`,
)

const myParticipantId = computed(
  () =>
    (reduction.value?.merged_recipients &&
      reduction.value?.merged_recipients.find(
        (participant) => participant.farmer_id === farmerId,
      )?.id) ??
    0,
)

const farmerHasBet = computed(
  () =>
    reduction.value?.merged_recipients?.find(
      (participant) => participant.id === myParticipantId.value,
    )?.answer_status === 'has_bet',
)

watch(
  () => drawerIsOpen.value,
  () => {
    if (!drawerIsOpen.value) {
      setTimeout(() => {
        selectedRequestId.value = null
      }, 500)
    }
  },
)
</script>

<template>
  <div class="page-wrapper" v-if="reduction">
    <ReductionHeader
      :is-open="reduction.status === 'open'"
      :title="headerTitle"
      :is-list="false"
      :created-at="dayjs(reduction?.created_at).format('DD.MM.YYYY')"
      :expiration-date="dayjs(reduction?.expiration_date).format('DD.MM.YYYY')"
      @onBtnClick="goToReductionsList"
    />
    <Tabs v-if="!isLoadingReduction" v-model:activeKey="activeKey">
      <TabPane key="1" tab="Информация о торге на покупку">
        <FarmerReductionInfo
          :myParticipantId="myParticipantId"
          :reduction="reduction"
          @setRequestId="changeSelectedRequestId"
        />
      </TabPane>
      <TabPane :disabled="!farmerHasBet" key="2">
        <template #tab>
          <Popover
            v-if="!farmerHasBet"
            :overlayInnerStyle="{ width: '320px' }"
            trigger="hover"
            placement="bottomLeft"
          >
            <template #content>
              Сделайте первую ставку, чтобы получить доступ к ставкам других
              поставщиков
            </template>
            Ставки по компаниям
          </Popover>
          <span v-else>Ставки по компаниям</span>
        </template>
        <BetForCompanyTab
          :myParticipantId="myParticipantId"
          :reduction="reduction"
          @setRequestId="changeSelectedRequestId"
        />
      </TabPane>
      <TabPane :disabled="!farmerHasBet" key="3">
        <template #tab>
          <Popover
            v-if="!farmerHasBet"
            :overlayInnerStyle="{ width: '320px' }"
            trigger="hover"
            placement="bottomLeft"
          >
            <template #content>
              Сделайте первую ставку, чтобы получить доступ к ставкам других
              поставщиков
            </template>
            Ставки по товарам
          </Popover>
          <span v-else>Ставки по товарам</span>
        </template>
        <BetForProductTab
          :myParticipantId="myParticipantId"
          :reduction="reduction"
          @setRequestId="changeSelectedRequestId"
        />
      </TabPane>
    </Tabs>
    <FullscreenLoading v-else :type="'secondary'" />
    <MakeBetDrawer
      v-if="selectedRequestId"
      :farmerHasBet="farmerHasBet"
      :customerId="reduction.customer_id"
      :open="drawerIsOpen"
      :onClose="toggleDrawerOpen"
      :product-request="
        reduction?.reduction_product_requests?.find(
          (request) => request.id === selectedRequestId,
        )
      "
      :participant-id="myParticipantId"
      :reduction-id="reduction.id"
      @refetchReduction="getReduction"
    />
  </div>
</template>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - 72px - 152px);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
:deep(.ant-tabs-nav-wrap) {
  padding: 0 24px;
}
:deep(.ant-tabs-nav) {
  margin-bottom: 0;
}
</style>
