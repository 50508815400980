<script setup lang="ts">
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import ProductBlock from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateProductRes/ProductBlock.vue'

const {
  currentCustomerRequest: { products },
} = useCustomerRequestStore()
</script>

<template>
  <div>
    <ProductBlock
      v-for="product in products"
      :key="product.id"
      :product="product"
    />
  </div>
</template>

<style lang="scss"></style>
