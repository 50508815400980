<script setup lang="ts">

import { ICustomer } from '@/types/interfaces'
import { Space } from 'ant-design-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { computed } from 'vue'
import { usePartnersStore } from '@/store/usePartnersStore'

interface Props {
  customer: ICustomer
}

const props = defineProps<Props>()
const partnersStore = usePartnersStore()

const partners = computed(() => {
  return partnersStore.partners
})

const isPartner = computed(() => {
  return partners.value?.some((el: { company_id: number }) => {
    return Number(props.customer?.id) === el?.company_id
  })
})
</script>

<template>
  <div class="small-customer-card">
    <div class="card-body">
      <Space :size="8" :align="'start'">
        <img
          v-if="isPartner"
          class="signed-contract"
          src="@/assets/img/signed-contract.svg"
          alt="signed_contract"
          loading="lazy"
        />
        <span class="customer-name">
          {{
            `${props.customer.legal_form ? props.customer.legal_form : ''} ${props.customer.name}`
          }}
        </span>
      </Space>
    </div>
    <span class="customer-address">{{ props.customer.legal_address }}</span>
    <SimpleRate :company_rating="props.customer.company_rating"/>
    <slot name="footer" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.small-customer-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .customer-name {
    @include p4();
    white-space: normal;
    word-break: normal;
  }
  .customer-address {
    @include p5();
    color: $gray-600;
  }
}
</style>
