<script lang="ts" setup>
import CatalogList from '@/components/Catalog/CatalogList.vue'
import CatalogFilters from '@/components/Catalog/CatalogFilters.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { fontSize16, outlineBlackButton } from '@/assets/EgalStyles/EButton'
import { catalogStore } from '@/store/catalogStore'
import {useRoute} from "vue-router";
import emitter from "@/emitter";

const useCatalogStore = catalogStore()

let isShow = ref(true)

const clearFilters = async () => {
  useCatalogStore.page = 1
  useCatalogStore.filters.forEach((filterItem) => {
    filterItem.values = filterItem.type !== 'binary' ? [] : ['нет']
  })
  useCatalogStore.filterPart = []
  await useCatalogStore.getProducts(useCatalogStore.idSubCategory || 0)
}

emitter.on('refresh-token', async (e) => {
  if (useCatalogStore.idSubCategory && useCatalogStore.catalogList.length) {
    await useCatalogStore.getProducts(useCatalogStore.idSubCategory)
  }
})

onBeforeMount(async () => {
  if (useCatalogStore.idSubCategory && useCatalogStore.catalogList.length) {
    await useCatalogStore.getProducts(useCatalogStore.idSubCategory)
  }
})

const isFiltersNotEmpty = computed(() => {
  return useCatalogStore.filters.some((item) =>
    item.type !== 'binary'
      ? item.values.length > 0
      : item.values.includes('да'),
  )
})

const route = useRoute()
</script>

<template>
  <div class="products">
    <h2 class="products-title">{{ useCatalogStore.titleCategory }}</h2>
    <div
      class="products-catalog"
      v-if="
        useCatalogStore.filters.length || useCatalogStore.catalogList.length
      "
    >
      <div class="products-filters">
        <div class="products-filters-list">
          <CatalogFilters
            v-for="item in useCatalogStore.filters"
            :allItems="useCatalogStore.filters"
            :item="item"
            :key="item.name"
            :isShow="isShow"
          />
        </div>
        <div class="products-filters-clear" v-if="isFiltersNotEmpty">
          <EButton
            :style-config="{
              ...outlineBlackButton,
              padding: '12px auto',
              ...fontSize16,
              width: '100%',
              justifyContent: 'center',
            }"
            class="products-filters-clear_btn"
            @click="clearFilters"
          >
            Очистить
          </EButton>
        </div>
      </div>
      <CatalogList @clear-filters="clearFilters" />
    </div>
    <div class="products-catalog" v-else>
      <p>К сожалению нет данных! Обратитесь к администратору сервиса!</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  padding-top: 20px;
  &-title {
    align-self: flex-start;
    margin-left: 10%;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
  }
  &-catalog {
    background-color: $base-white;
    border-radius: 16px;
    border: 1px solid $gray-300;
    display: flex;
    margin-top: 24px;
    width: 80%;
  }
  &-filters {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-300;
    max-height: 80vh;
    width: 570px;
    padding-top: 12px;
    &-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;
    }
    &-clear {
      display: flex;
      justify-content: center;
      padding: 24px 32px 40px 24px;
      border-top: 1px solid $gray-300;
      margin-top: auto;
    }
  }
}
</style>
