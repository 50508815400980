import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class NotificationAPIModel {
  async contactGetItems(): Promise<any> {
    return RequestManager.request({
      model: APIModels.Notification,
      method: EgalMethods.GetItems,
      data: {
        order: [['created_at', 'desc']],
        pagination: { per_page: 1000, page: 1 },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateNotification(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Notification,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const NotificationAPI = new NotificationAPIModel()
export { NotificationAPI }
