<script lang="ts" setup>
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import PickupIcon from '@/components/Icons/PickupIcon.vue'
import {
  formatPrice,
  getStatusType,
  ISODateTransformerShort,
} from '@/helpers/scripts'
import FarmerDeliveryIcon from '@/components/Icons/FarmerDeliveryIcon.vue'
import router from '@/router'
import {
  IGeneralOrderHistory,
  IHistoryOrder,
  IOrder,
  IPartnership,
} from '@/types/interfaces'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import { computed } from 'vue'

interface ICustomerOrderRow {
  delivery_date: string
  order_date: string
  generalId: number
  delivery_address: { street: string } | null
  order: {
    id: number
    status: string
    price: number
    vat_price: number
    delivery_method: 'Самовывоз' | 'Доставка фермером'
    is_partner: boolean
    from_address: string
    farmer: {
      name: string
      legal_form: string
    }
    shipping_address: {
      street: string
    }
    partnership: IPartnership
    history: IHistoryOrder[]
  }
  history: IGeneralOrderHistory[]
}

const props = defineProps<ICustomerOrderRow>()

const openOrderPage = () => {
  router.push(`/history/${props.generalId}`)
}

const get_price = (order: IOrder) => {
  if (
    (order.status === NewGeneralOrderStatus.New ||
      order.status === NewGeneralOrderStatus.OrderPicking) &&
    order.history[0]?.initiator_type === 'farmer'
  ) {
    // Sort history by date from new to old and take first price
    return order.history?.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )[0]?.old_entity.price
  } else {
    return order.price
  }
}

const orderWasEditedByFarmer = computed(() => {
  if (!props.order?.history?.length) return false
  const order_was_edited =
    props.order.history[props.order?.history?.length - 1]?.initiator_type ===
      'farmer' &&
    props.order.delivery_method !==
      props.order.history[props.order?.history?.length - 1]?.old_entity
        .delivery_method

  return order_was_edited && props.order.status === NewGeneralOrderStatus.New
})

const dateWasEditedByFarmer = computed(() => {
  if (!props.history) return false
  const order_was_edited =
    props.order.history[props.order?.history?.length - 1]?.initiator_type ===
      'farmer' &&
    props.delivery_date !==
      props?.history[props?.history?.length - 1]?.old_entity?.delivery_date

  return order_was_edited && props.order.status === NewGeneralOrderStatus.New
})

const showDeliveryAddress = computed(() => {
  if (
    !orderWasEditedByFarmer.value &&
    props.order.delivery_method === 'Доставка фермером'
  ) {
    return props.delivery_address?.street
  }

  if (
    !orderWasEditedByFarmer.value &&
    props.order.delivery_method === 'Самовывоз'
  ) {
    return props.order.shipping_address.street
  }

  if (
    orderWasEditedByFarmer.value &&
    props?.order?.history[props.order?.history?.length - 1]?.old_entity
      ?.delivery_method === 'Доставка фермером'
  ) {
    return props?.history[props.order?.history?.length - 1]?.old_entity
      ?.delivery_address?.street
  }

  if (
    orderWasEditedByFarmer.value &&
    props?.order?.history[props.order?.history?.length - 1]?.old_entity
      ?.delivery_method === 'Самовывоз'
  ) {
    return props.order.shipping_address.street
  }
})
const deliveryMethod = computed(() => {
  return !orderWasEditedByFarmer.value
    ? props.order.delivery_method.split(' ')[0]
    : props.order?.history[
        props.order?.history.length - 1
      ]?.old_entity.delivery_method.split(' ')[0]
})
</script>

<template>
  <div v-if="order.status" class="customer-order-row" @click="openOrderPage">
    <div class="customer-order-cell">
      <div
        class="partnership-tooltip"
        v-if="order.partnership?.status === 'signed_customer'"
      >
        <PartnershipTooltip />
      </div>
      {{ order.farmer.legal_form + ' ' + order.farmer.name }}
      <SimpleRate :company_rating="order.farmer.company_rating" />
    </div>
    <div class="customer-order-cell">
      <CustomTag
        :shadow="false"
        :text="order.status"
        :type="getStatusType(order.status)"
        :weight="'normal'"
      />
    </div>
    <div class="customer-order-cell">
      <div class="delivery-method-info">
        <PickupIcon
          v-if="
            order.delivery_method === 'Самовывоз' && !orderWasEditedByFarmer
          "
          :fill="'#2D3748'"
        />
        <FarmerDeliveryIcon
          v-if="
            order.delivery_method === 'Доставка фермером' &&
            !orderWasEditedByFarmer
          "
          :fill="'#2D3748'"
        />
        <PickupIcon
          v-if="
            order?.history[order.history.length - 1]?.old_entity
              .delivery_method === 'Самовывоз' && orderWasEditedByFarmer
          "
          :fill="'#2D3748'"
        />
        <FarmerDeliveryIcon
          v-if="
            order?.history[order?.history.length - 1]?.old_entity
              .delivery_method === 'Доставка фермером' && orderWasEditedByFarmer
          "
          :fill="'#2D3748'"
        />
        {{ deliveryMethod }}
      </div>
      <span class="cell-additional-info">
        {{ showDeliveryAddress }}
      </span>
    </div>
    <div class="customer-order-cell">
      {{ ISODateTransformerShort(order_date) }}
    </div>
    <div class="customer-order-cell">
      {{
        ISODateTransformerShort(
          !dateWasEditedByFarmer
            ? delivery_date
            : history[props?.history?.length - 1]?.old_entity?.delivery_date,
        )
      }}
    </div>
    <div class="customer-order-cell">
      {{ formatPrice(get_price(order)) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.customer-order-row {
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;
  padding: 20px 12px;
  display: grid;
  grid-template-columns: 2fr 270px 2fr 120px 120px 1fr;
  border-top: 1px solid $gray-300;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  .customer-order-cell {
    padding: 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    position: relative;
    .partnership-tooltip {
      position: absolute;
      top: 0;
      left: 0;
    }
    &:last-child {
      font-weight: 700;
      white-space: nowrap;
    }

    .delivery-method-info {
      display: flex;
      column-gap: 8px;
      margin-bottom: 4px;

      svg {
        transform: scale(1.2);
      }
    }

    .cell-additional-info {
      font-weight: 400;
      word-break: break-word;
      font-size: 14px;
      line-height: 20px;
      color: $gray-600;
    }
  }
}
</style>
