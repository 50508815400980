import {BatchSpanProcessor, WebTracerProvider} from "@opentelemetry/sdk-trace-web";
import {Resource} from "@opentelemetry/resources";
import {OTLPTraceExporter} from "@opentelemetry/exporter-trace-otlp-http";
import {ZoneContextManager} from "@opentelemetry/context-zone";
import {W3CTraceContextPropagator} from "@opentelemetry/core";
import {registerInstrumentations} from "@opentelemetry/instrumentation";
import {UserInteractionInstrumentation} from "@opentelemetry/instrumentation-user-interaction";
import {getWebAutoInstrumentations} from "@opentelemetry/auto-instrumentations-web";
import {useCookies} from "vue3-cookies";

export const initOtelCollector = () => {
    if (window.location.hostname !== 'localhost') {
        const COLLECTOR_URL = 'https://otel.laurence.host/v1/traces'
        const {cookies} = useCookies();

        console.log('initOtelCollector...')
        const provider = new WebTracerProvider({
            resource: Resource.default().merge(new Resource({
                'service.name': 'EASY_RETAIL_MAIN_APP',
                'company_id': localStorage.getItem('company_id'),
                'umrt': localStorage.getItem('umrt'),
                'umt': localStorage.getItem('umt'),
                'user': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
                'userData': localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
                'cookie-auth': cookies.get('auth'),
                'cookie-chat': cookies.get('chat'),
                'cookie-core': cookies.get('core'),
                'cookie-onec': cookies.get('onec')
            })),
        });

        const exporter = new OTLPTraceExporter({
            url: COLLECTOR_URL,
            headers: {
                'Content-Type': 'application/x-protobuf',
            },
            keepAlive: true,
        });

        const batchSpanProcessor = new BatchSpanProcessor(exporter, {
            scheduledDelayMillis: 1000,
            maxQueueSize: 1000,
            maxExportBatchSize: 10,
            exportTimeoutMillis: 30000,
        });

        provider.addSpanProcessor(batchSpanProcessor);

        provider.register({
            contextManager: new ZoneContextManager(),
            propagator: new W3CTraceContextPropagator(),
        });

        registerInstrumentations({
            instrumentations: [
                new UserInteractionInstrumentation(),
                getWebAutoInstrumentations({
                    '@opentelemetry/instrumentation-xml-http-request': {
                        clearTimingResources: true,
                        enabled: true,
                        ignoreUrls: [COLLECTOR_URL],
                        attributes: {
                            'http.status_code': true,
                        },
                    },
                    '@opentelemetry/instrumentation-fetch': {
                        enabled: true,
                        ignoreUrls: [COLLECTOR_URL],
                        propagateTraceHeaderCorsUrls: /.*/,
                        clearTimingResources: true,
                    },
                    '@opentelemetry/instrumentation-user-interaction': {
                        enabled: true,
                        ignoreUrls: [COLLECTOR_URL]
                    },
                    '@opentelemetry/instrumentation-document-load': {
                        enabled: true,
                        ignoreUrls: [COLLECTOR_URL]
                    },
                }),
            ],
        });
    }
}
