<script setup lang="ts">
import {
  IAuction,
  IAuctionProductAnswer,
  IAuctionProductRequest,
} from '@/types/interfaces'
import { ref } from 'vue'
import {
  Button,
  Divider,
  Flex,
  Space,
  Table,
  TypographyText as TgText,
} from 'ant-design-vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import {priceWithoutVat, getUserIdTemporary, priceWithVat, roundNumber} from '@/helpers/scripts'
import MyCompanyTag from '@/views/Farmer/reductions/reduction/MyCompanyTag.vue'
import StartPriceCell from '@/views/Auctions/components/AuctionTable/StartPriceCell.vue'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'

interface IProps {
  auction: IAuction
  myParticipantId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId', 'setSelectedProduct'])

const expandedRowKeys = ref<string[]>([])
const customerId = +getUserIdTemporary()

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '45%',
  },
  {
    title: 'Стартовая цена',
    key: 'start_price',
    width: '15%',
  },
  {
    title: 'Ваше предложение',
    key: 'your_offer',
    width: '15%',
  },
  {
    title: 'Лучшее предложение',
    key: 'best_offer',
    width: '25%',
  },
]

const nestedColumns = [
  {
    title: 'Рейтинг',
    key: 'rating',
    width: '7%',
  },
  {
    title: 'Покупатель',
    key: 'customer',
    width: '39%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const getProductParameters = (product: IAuctionProductRequest) => {
  const result: string[] = []
  result.push(
    `Объем ${product.volume} ${product.product?.measurement_unit_id ?? 'кг'}`,
  )

  result.push(`Срок хранения ${product.remaining_shelf_life}%`)
  product.is_sample_requested && product.requested_samples_number &&
    result.push(
      `Пробный образец ${product.requested_samples_number ? `${product.requested_samples_number} кг` : ''}`,
    )
  return result
}

const calculateBetSum = (auctionProductRequest: IAuctionProductRequest) => {
  const price = auctionProductRequest.best_bet?.price_per_unit ?? 0
  return +(Number(price) * Number(auctionProductRequest.volume)).toFixed(2)
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const getYourOffer = (requestProductId: number) => {
  let productRequest: IAuctionProductRequest | undefined = undefined
  let myAnswer: IAuctionProductAnswer | undefined

  productRequest = props.auction.auction_product_requests?.find(
    (request) => request.id === requestProductId,
  )

  productRequest?.auction_product_answers.forEach((answer) => {
    if (answer.auction_participant_id === props.myParticipantId) {
      myAnswer = answer
    }
  })

  if (productRequest && myAnswer) {
    return `${+productRequest.volume * Number(myAnswer.price_per_unit)} ₽`
  } else {
    return '-'
  }
}

const filterByBet = (
  reductionProductRequests: IAuctionProductRequest[] | undefined,
) => {
  if (!reductionProductRequests) {
    return []
  }
  return reductionProductRequests.filter(
    (currentRequest) => currentRequest.best_bet && !currentRequest.is_deleted,
  )
}
// TODO: refactor with fillMeasurementUnitIdCommon
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :data-source="filterByBet(auction?.auction_product_requests)"
      :pagination="false"
      :expandedRowKeys="expandedRowKeys"
      :row-key="(record, index) => String(index)"
      :showExpandColumn="false"
      class="edged-border-table"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'product'">
          <Space direction="vertical" :size="4">
            <ProductWithProperties
              :volume="record?.volume"
              :remaining_shelf_life="record.remaining_shelf_life"
              :measurement_unit_id="record.product?.measurement_unit_id ?? null"
              :name="record.farmer_product_name"
              :is_sample_needed="record.is_sample_requested"
              :needed_samples_number="record.requested_samples_number"
              :isProductNameLink="true"
              @onClickName="emit('setSelectedProduct', record.farmer_product)"
            />
          </Space>
        </template>
        <template v-if="column.key === 'start_price'">
          <StartPriceCell
            :price="roundNumber(record.start_price)"
            :vat="record.vat"
            :measurementUnit="record.farmer_product.measurement_unit_id"
          />
        </template>
        <template v-if="column.key === 'your_offer'">
          <span>{{ getYourOffer(record.id) }}</span>
        </template>
        <template v-if="column.key === 'best_offer'">
          <template v-if="record.best_bet">
            <Flex justify="space-between" align="center">
              <Space direction="vertical" :size="0">
                <TgText strong>{{ `${calculateBetSum(record)} ₽` }}</TgText>
                <span
                  v-if="
                    record.best_bet.auction_participant.customer_id !==
                    customerId
                  "
                  class="customer-name"
                  >{{
                    `Покупатель ${record.best_bet.auction_participant.auction_name.toUpperCase()}`
                  }}</span
                >
                <MyCompanyTag v-else />
              </Space>
              <Button type="link" :onClick="() => changeExpandedRow(index)">
                <template #icon>
                  <DownOutlined
                    v-if="!expandedRowKeys.includes(String(index))"
                  />
                  <UpOutlined v-else />
                </template>
                Ставки
              </Button>
            </Flex>
          </template>
          <template v-else>
            <TgText type="secondary">Нет ставок</TgText>
          </template>
        </template>
      </template>

      <template #expandedRowRender="{ record }">
        <Table
          :columns="nestedColumns"
          :data-source="record.auction_product_answers"
          :pagination="false"
          bordered
          class="nested-table"
        >
          <template #bodyCell="{ column, record: answer, index }">
            <template v-if="column.key === 'rating'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'customer'">
              <span
                v-if="answer.auction_participant.customer_id !== customerId"
                >{{
                  `Покупатель ${answer.auction_participant.auction_name.toUpperCase()}`
                }}</span
              >
              <Flex v-else :gap="8">
                <span>{{ answer.auction_participant.customer.name }}</span>
                <MyCompanyTag />
              </Flex>
            </template>
            <template v-if="column.key === 'price_without_vat'">
              <CellDifData
                :new-data="
                  roundNumber(priceWithoutVat(answer.price_per_unit, record.vat))
                "
                :prev-data="
                  roundNumber(
                    priceWithoutVat(answer.price_per_unit_first, record.vat),
                  )
                "
                :additional-info="record.farmer_product.measurement_unit_id ? `₽/${record.farmer_product.measurement_unit_id}` : '₽'"
              />
            </template>
            <template v-if="column.key === 'price_with_vat'">
              <CellDifData
                :new-data="answer.price_per_unit"
                :prev-data="answer.price_per_unit_first"
                :additional-info="record.farmer_product.measurement_unit_id ? `₽/${record.farmer_product.measurement_unit_id}` : '₽'"
              />
            </template>
            <template v-if="column.key === 'amount'">
              <Flex justify="space-between">
                <CellDifData
                  :new-data="
                    answer.price_per_unit * Number(record.volume)
                  "
                  :prev-data="
                    answer.price_per_unit_first * Number(record.volume)
                  "
                  additional-info="₽"
                />
                <Button
                  v-if="
                    answer.auction_participant.customer_id === customerId &&
                    auction.status === 'open'
                  "
                  type="primary"
                  @click="
                    emit('setRequestId', answer.auction_product_request_id)
                  "
                  >Сделать ставку</Button
                >
              </Flex>
            </template>
          </template>
        </Table>
      </template>
    </Table>
  </div>
</template>

<style scoped lang="scss">
.table-wrapper {
  padding: 24px;
}
.product-parameters-row {
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}
.customer-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000a6;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}
.nested-table {
  :deep(.ant-table) {
    border-radius: 0 !important;
  }
  :deep(.ant-table-row .ant-table-cell:first-child) {
    text-align: center;
  }
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}
</style>
