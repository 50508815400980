<script lang="ts" setup>
// TODO: Дописать
import { useRoute } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import { IAuction, IFarmerProduct } from '@/types/interfaces'
import dayjs from 'dayjs'
import { Popover, TabPane, Tabs } from 'ant-design-vue'
import AuctionModal from '@/components/Modal/AuctionModal.vue'
import { getUserIdTemporary } from '@/helpers/scripts'
import router from '@/router'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import CustomerAuctionInfo from '@/views/Customer/auctions/customerAuctionInfoTab/CustomerAuctionInfo.vue'
import AuctionHeader from '@/views/Auctions/components/AuctionHeader/AuctionHeader.vue'
import { AuctionAPI } from '@/api/AuctionAPI'
import BetForCompanyTab from '@/views/Customer/auctions/BetForCompanyTab.vue'
import BetForProductTab from '@/views/Customer/auctions/BetForProductTab.vue'
import MakeBetDrawer from '@/views/Customer/auctions/makeBetDrawer/MakeBetDrawer.vue'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import ConditionsModal from '@/components/Modal/conditionsModal.vue'
import ConfirmModal from '@/components/Modal/confirmModal.vue'
import NotIncludedProductsModal from '@/components/Modal/NotIncludedProductsModal.vue'
import { CartAPI } from '@/api/CartAPI'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { AddressAPI } from '@/api/AddressAPI'
import NotAvailableProductsModal from '@/components/Modal/NotAvailableProductsModal.vue'
import { IAddress } from '@/types/IAddress'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import emitter from "@/emitter";

const route = useRoute()

const auction = ref<IAuction | null>(null)
const isLoadingAuction = ref(false)
const activeKey = ref('1')
const drawerIsOpen = ref(false)
const selectedRequestId = ref<null | number>(null)
const givenAddress = ref<IAddress>()

const openNotAvailable = ref(false)
const openNotIncluded = ref(false)
const openConfirmModal = ref(false)
const openConditionsModal = ref(false)
const selectedFarmer = ref(0)
const answerProducts = ref()
const priorityPay = ref('')
const priorityDelivery = ref('')
const notIncludedProducts = ref()
const addressOffers = ref({})

const customerId = +getUserIdTemporary()
const modalStore = useModalStore()

const changeSelectedRequestId = (value: number | null) => {
  selectedRequestId.value = value
  toggleDrawerOpen()
}

const goToAuctionsList = () => {
  router.push('/auctions')
}

const toggleDrawerOpen = () => {
  drawerIsOpen.value = !drawerIsOpen.value
}

const getReduction = async () => {
  isLoadingAuction.value = true
  AuctionAPI.getItemsWithBetter(
    1,
    [['id', 'eq', String(route.params.id)]],
    [
      'farmer',
      'farmer.company_rating',
      'auction_participants',
      'mergedRecipients.customer',
      'auctionProductRequests.farmerProduct.specialPrices',
      'auctionProductRequests.farmerProduct.priceOptions',
      'auctionProductRequests.auctionProductAnswers',
      'auctionParticipants.customer',
      'auctionParticipants.customer.company_rating',
      'auctionProductRequests.auctionProductAnswers.auctionParticipant.customer.addresses',
      'auctionProductRequests.auctionProductAnswers.auctionParticipant.customer.company_rating',
    ],
  )
    .then((res) => {
      auction.value = res?.items?.[0]
      findAddress()
      prioritizeCheck()
    })
    .finally(() => {
      isLoadingAuction.value = false
    })
}

emitter.on('refresh-token', async (e) => {
  await getReduction()
})

onMounted(async () => {
  await getReduction()
})

const headerTitle = computed(
  () =>
    `Торг на продажу от ${auction.value?.farmer?.name}, ${dayjs(auction.value?.created_at).format('DD.MM.YYYY')}`,
)

const myParticipantId = computed(
  () =>
    auction.value?.merged_recipients &&
    auction.value?.merged_recipients.find(
      (participant) => participant.customer_id === customerId,
    )?.id,
)

const customerHasBet = computed(
  () =>
    auction.value?.merged_recipients?.find(
      (participant) => participant.id === myParticipantId.value,
    )?.answer_status === 'has_bet',
)

const isAllProductsDeleted = computed(() => {
  return auction.value?.auction_product_requests?.every(
    (request) => request.is_deleted,
  )
})

watch(
  () => drawerIsOpen.value,
  () => {
    if (!drawerIsOpen.value) {
      setTimeout(() => {
        selectedRequestId.value = null
      }, 500)
    }
  },
)

const selectedProduct = ref<IFarmerProduct | undefined>(undefined)
const isOpenProductDrawer = ref<boolean>(false)
const setSelectedProduct = (product: IFarmerProduct) => {
  isOpenProductDrawer.value = true
  selectedProduct.value = product
}

const closeProductDrawer = () => {
  isOpenProductDrawer.value = false
}

const filterAnswers = () => {
  const newArray = []
  answerProducts.value.map((answer) => {
    let flag = true
    notIncludedProducts.value.map((product) => {
      if (product.id == answer.farmer_product_id) {
        flag = false
      }
    })
    if (flag) newArray.push(answer)
  })
  answerProducts.value = newArray
}

const getParticipantAnswers = () => {
  let answers = []
  answerProducts.value.map((product) => {
    product.auction_product_answers?.map((answer) => {
      if (
        answer.auction_participant.customer.id == customerId &&
        !product.is_deleted
      ) {
        // const a = answerProducts.value.find(
        //   (p) => p.farmer_product_id == product.id,
        // )
        // const calcQuantity = () => {
        //   const all = Number(product.farmer_product.quantity)
        //   const inAuc = Number(a.volume)
        //   console.debug('all', all)
        //   console.debug('inAuc', inAuc)
        //   if (all > inAuc) {
        //     return inAuc
        //   } else return all
        // }
        // const currQuantity = calcQuantity()
        //
        // console.debug('currQuantity', currQuantity)

        const productAuctionVolume = Number(product.volume) // Кол-во товара в торге
        answers.push({
          quantity: productAuctionVolume,
          unit_price: answer.price_per_unit,
          vat_percent: product.vat,
          farmer_product_id: product.farmer_product_id,
        })
      }
    })
  })
  return answers
}

// TODO: refactor
const showModal = async () => {
  answerProducts.value = auction.value?.auction_product_requests
  notIncludedProducts.value = []

  let product_ids = answerProducts.value.map((product) => {
    return product.farmer_product.id
  })

  answerProducts.value = getParticipantAnswers()

  await FarmerProductAPI.getProductsCustom([
    ['farmer_id', 'eq', auction?.value.farmer_id],
    'AND',
    ['is_deleted', 'eq', false],
    'AND',
    ['farmer_products.id', 'in', product_ids],
  ]).then((res) => {
    if (res.length > 0) {
      res.forEach((product) => {
        answerProducts.value.forEach((productAnswer) => {
          if (productAnswer.farmer_product_id == product.product.p_id) {
            productAnswer.farmer_product_id = product.id
          }
        })
      })

      notIncludedProducts.value = auction.value.auction_product_requests.filter(
        (product) => !res.find((p) => p.id == product.farmer_product_id),
      )

      answerProducts.value = answerProducts.value.filter(
        (product) =>
          !notIncludedProducts.value.find(
            (p) => p.farmer_product_id == product.farmer_product_id,
          ),
      )

      if (notIncludedProducts.value.length > 0) {
        openNotIncluded.value = true
      } else {
        openConfirmModal.value = true
      }
    } else {
      openNotAvailable.value = true
    }
  })
}

const findAddress = async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  await AddressAPI.getAddresses(currentCompanyId, 'customer').then((res) => {
    addressOffers.value = res?.items

    const addresses = res?.items

    if (!addresses?.length) {
      return
    }

    addresses?.forEach((item) => {
      if (item.street == auction?.value?.self_delivery_address) {
        givenAddress.value = item
      }
    })
  })
}

const createOrder = async (
  delivery: string,
  payment: string,
  addressId: number,
) => {
  const orderDay = dayjs(new Date()).add(3, 'day').format('YYYY-MM-DD')
  const deliveryPay = auction.value?.is_delivery_included
    ? 'included'
    : 'excluded'
  await CartAPI.createOtherOrder({
    farmer_id: auction.value?.farmer_id,
    delivery_date: orderDay,
    delivery_method: delivery,
    delivery_payment: delivery == 'Самовывоз' ? undefined : deliveryPay, // Если самовывоз - delivery_payment не должен быть
    payment_type: payment == 1 ? 'cash' : 'non_cash',
    address: delivery == 'Самовывоз' ? undefined : addressId,
    need_unload: false, //auction.value?.need_unload,
    is_payment_delayed: auction.value?.is_payment_delayed,
    order_positions: answerProducts.value,
  }).then(async (res) => {
    localStorage.setItem('lastCreatedOrderId', res.id)
    await router.push('/order-created')
  }).catch(async (e) => {
    if (e.status === 422) {
        const errorData = e.response.data.action_error?.data
        const deletedItems = Object.entries(errorData).reduce(
          (acc, [id, errors]: [string, string[]]) => {
            if (errors.includes('deleted')) {
              acc.push(id)
            }
            return acc
          },
          [] as string[],
        )
        const troubledItems =
          auction.value.auction_product_requests?.filter((item) =>
            deletedItems.includes(String(item.farmer_product_id)),
          )

        if (deletedItems.length) {
          openConditionsModal.value = false
          const productNames = troubledItems.map((item) => {
            return item.farmer_product_name
          }).join(', ')
          modalStore.openModal(ModalKeys.HandleError, {
            title: 'Не удалось создать заказ',
            message:
              `Товар(ы)
              <b>${productNames}</b>,
              отсутствует(ют) в каталоге поставщика. Пожалуйста свяжитесь с поставщиком, чтобы он добавил данный товар в каталог.`,
          })
        }
      }

  })
}

const confirmModalContinue = () => {
  openConfirmModal.value = false
  openConditionsModal.value = true
}

const prioritizeCheck = () => {
  const aucData = auction?.value
  if (aucData?.is_pay_cash && !aucData?.is_pay_non_cash)
    priorityPay.value = 'cash'
  else if (!aucData?.is_pay_cash && aucData?.is_pay_non_cash)
    priorityPay.value = 'non_cash'
  if (aucData.is_self_delivery && !aucData.is_supplier_delivery)
    priorityDelivery.value = 'Самовывоз'
  else if (!aucData.is_self_delivery && aucData.is_supplier_delivery)
    priorityDelivery.value = 'Доставка фермером'
}

const fullCompanyName = computed(() => {
  return `${auction.value?.farmer?.legal_form} ${auction.value?.farmer?.name}`
})

const checkBeenSelected = computed(() => {
  const answers = auction.value?.auction_product_requests
  let show = false
  answers?.map((product) => {
    product.auction_product_answers?.map((answer) => {
      if (
        answer.auction_participant.customer.id == customerId &&
        answer.auction_participant?.can_order
      ) {
        show = true
      }
    })
  })
  return show

  // return auction.value?.status == 'closed' && auction.value.best_customer_id == customerId;
})

const continueNotIncludedModal = () => {
  openNotIncluded.value = false
  openConfirmModal.value = true
}

const continueConfirmModal = () => {
  openConfirmModal.value = false
  openConditionsModal.value = true
}
</script>

<template>
  <NotAvailableProductsModal
    :open="openNotAvailable"
    @close="openNotAvailable = false"
  />
  <AuctionModal
    v-if="checkBeenSelected"
    :companyName="fullCompanyName"
    @continue="showModal"
  />
  <NotIncludedProductsModal
    :open="openNotIncluded"
    :products="notIncludedProducts"
    @continue="continueNotIncludedModal"
    @close="openNotIncluded = false"
  />
  <ConfirmModal
    :open="openConfirmModal"
    @continue="continueConfirmModal"
    @close="openConfirmModal = false"
    location="auction"
  />
  <ConditionsModal
    :can-deliver="auction?.farmer?.can_deliver"
    :priority-address-id="givenAddress?.id"
    :priority-pay="priorityPay"
    :priority-delivery="priorityDelivery"
    :addressOffers="addressOffers"
    :open="openConditionsModal"
    @close="openConditionsModal = false"
    @continue="createOrder"
  />
  <div class="page-wrapper" v-if="auction">
    <AuctionHeader
      :is-open="auction.status === 'open'"
      :title="headerTitle"
      :is-list="false"
      :created-at="dayjs(auction?.created_at).format('DD.MM.YYYY')"
      :expiration-date="dayjs(auction?.expiration_date).format('DD.MM.YYYY')"
      @onBtnClick="goToAuctionsList"
    />
    <Tabs v-if="!isLoadingAuction" v-model:activeKey="activeKey">
      <TabPane key="1" tab="Информация о торге на продажу">
        <CustomerAuctionInfo
          :auctionData="auction"
          :my-participant-id="myParticipantId"
          :isAllProductsDeleted="isAllProductsDeleted"
          @setRequestId="changeSelectedRequestId"
        />
      </TabPane>
      <TabPane :disabled="!customerHasBet || isAllProductsDeleted" key="2">
        <template #tab>
          <Popover
            v-if="!customerHasBet || isAllProductsDeleted"
            :overlayInnerStyle="{ width: '320px' }"
            trigger="hover"
            placement="bottomLeft"
          >
            <template #title v-if="isAllProductsDeleted"
              >Просмотр ставок недоступен</template
            >
            <template #content v-if="!customerHasBet && !isAllProductsDeleted">
              Сделайте первую ставку, чтобы получить доступ к ставкам других
              покупателей
            </template>
            <template #content v-else>
              Все товары были удалены из торга на продажу
            </template>
            Ставки по компаниям
          </Popover>
          <span v-else>Ставки по компаниям</span>
        </template>
        <BetForCompanyTab
          :myParticipantId="myParticipantId"
          :auction="auction"
          @setRequestId="changeSelectedRequestId"
          @setSelectedProduct="setSelectedProduct"
        />
      </TabPane>
      <TabPane :disabled="!customerHasBet || isAllProductsDeleted" key="3">
        <template #tab>
          <Popover
            v-if="!customerHasBet || isAllProductsDeleted"
            :overlayInnerStyle="{ width: '320px' }"
            trigger="hover"
            placement="bottomLeft"
          >
            <template #title v-if="isAllProductsDeleted"
              >Просмотр ставок недоступен</template
            >
            <template #content v-if="!customerHasBet && !isAllProductsDeleted">
              Сделайте первую ставку, чтобы получить доступ к ставкам других
              покупателей
            </template>
            <template #content v-else>
              Все товары были удалены из торга на продажу
            </template>
            Ставки по товарам
          </Popover>
          <span v-else>Ставки по товарам</span>
        </template>
        <BetForProductTab
          :myParticipantId="myParticipantId"
          :auction="auction"
          @setRequestId="changeSelectedRequestId"
          @setSelectedProduct="setSelectedProduct"
        />
      </TabPane>
    </Tabs>
    <FullscreenLoading v-else :type="'secondary'" />
    <MakeBetDrawer
      v-if="selectedRequestId"
      :customerHasBet="customerHasBet"
      :farmerId="auction.farmer_id"
      :open="drawerIsOpen"
      :onClose="toggleDrawerOpen"
      :product-request="
        auction?.auction_product_requests?.find(
          (request) => request.id === selectedRequestId,
        )
      "
      :participant-id="myParticipantId"
      :auction-id="auction.id"
      @refetchReduction="getReduction"
    />
    <DrawerForm
      :product="selectedProduct"
      @editProduct="
        router.push({ path: '/edit-product/' + selectedProduct?.id })
      "
      @editSpecialPrices="
        router.push({ path: '/edit-special-prices/' + selectedProduct?.id })
      "
      @editPriceOptions="
        router.push({ path: '/edit-price-options/' + selectedProduct?.id })
      "
      @closeDrawer="closeProductDrawer"
      :isFarmer="false"
      :selfDeleting="true"
      :open="isOpenProductDrawer"
    />
  </div>
</template>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - 72px - 152px);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
:deep(.ant-tabs-nav-wrap) {
  padding: 0 24px;
}
:deep(.ant-tabs-nav) {
  margin-bottom: 0;
}
</style>
