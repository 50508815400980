<script setup lang="ts">
import {
  InputSearch,
  Checkbox,
  ListItem,
  Space,
  Steps,
  Empty,
  List,
  Col,
  Row,
} from 'ant-design-vue'
import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'
import SmallCustomerCard from '@/components/Customer/SmallCustomerCard.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { fontSize14, primaryButton } from '@/assets/EgalStyles/EButton'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { computed, UnwrapRef } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

interface Props {
  currentStep: number
  thirdFormState: UnwrapRef<any[]>
  customers: UnwrapRef<any[]>
  selectedCustomers: UnwrapRef<string[]>
  isSelectCustomers: UnwrapRef<boolean>
  isCustomersFetching: UnwrapRef<boolean>
  customersSearchQuery: UnwrapRef<string>
}

const props = defineProps<Props>()
const emit = defineEmits([
  'set-filter',
  'handleSelectCustomerModal',
  'saveCustomers',
  'setSelectedCustomers',
  'setSearchQuery',
  'setCurrentStep',
])
const width100 = { width: '100%' }
const simpleEmptyImage = Empty.PRESENTED_IMAGE_SIMPLE
const stepsItems = [
  {
    title: 'Требования к торгу на продажу',
    key: 'Требования к торгу на продажу',
  },
  {
    title: 'Список товаров',
    key: 'Список товаров',
  },
  {
    title: 'Участники',
    key: 'Участники',
  },
]
const handleSelectAll = (event: any) => {
  if (event.target.checked) {
    selectAllCustomers()
  } else unselectAllCustomers()
}
const selectAllCustomers = () => {
  const newSelectedFarmers = new Set([
    ...props.selectedCustomers,
    ...props.customers.map((item) => item.id),
  ])
  emit('setSelectedCustomers', Array.from(newSelectedFarmers))
}

const unselectAllCustomers = () => {
  const newSelectedFarmers = [...props.selectedCustomers]
  props.customers.forEach((farmer) => {
    newSelectedFarmers.splice(newSelectedFarmers.indexOf(farmer.id), 1)
  })
  emit('setSelectedCustomers', newSelectedFarmers)
}

const isCheckedAll = computed(() => {
  if (props.customers.length === 0 || props.selectedCustomers.length === 0) {
    return false
  }
  for (const farmer of props.customers) {
    if (!props.selectedCustomers.includes(farmer.id)) {
      return false
    }
  }
  return !(
    props.selectedCustomers.length !== props.customers.length &&
    !props.customersSearchQuery.length
  )
})

const saveCustomers = () => {
  emit('handleSelectCustomerModal', false)
  emit('saveCustomers')
}

const handleGoBack = () => {
  if (props.currentStep === 0) {
    router.push('/auctions')
  } else {
    emit('setCurrentStep', props.currentStep - 1)
  }
}

const checkFarmer = (event: Event, id: string) => {
  if ((event.target as HTMLInputElement).checked) {
    emit('setSelectedCustomers', [...props.selectedCustomers, id])
  }
  if (!(event.target as HTMLInputElement).checked) {
    emit(
      'setSelectedCustomers',
      props.selectedCustomers.filter((uid) => uid !== id),
    )
  }
}
</script>

<template>
  <div class="create-reduction-header">
    <Row justify="space-between" :gutter="80">
      <Col
        :style="{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '10px',
        }"
      >
        <CustomerRequestHeaderActionButton
          type="Back"
          @handleClick="handleGoBack"
        />
        <h3>Новый торг на продажу</h3>
      </Col>
      <Col flex="auto">
        <Steps :items="stepsItems" :current="currentStep" />
      </Col>
      <Col>
        <div />
      </Col>
    </Row>
  </div>

  <Teleport to="body">
    <ModalWindow
      :show="props.isSelectCustomers"
      :show-close-icon="true"
      class="select-modal select-farmers-modal"
      @close="emit('handleSelectCustomerModal', false)"
    >
      <template #header>
        <Space :size="25" :direction="'vertical'" :style="width100">
          <h3>Выберите участников торга на продажу</h3>
          <span class="modal-description"
            >Торг на продажу будет доступен только выбранным покупателям,
            остальные не смогут принять участие</span
          >
          <Row :align="'middle'">
            <Col :flex="'auto'">
              <InputSearch
                :placeholder="'Поиск по поставщикам'"
                :size="'large'"
                @change="emit('setSearchQuery', $event.target.value)"
                allowClear
                class="search-input"
              />
            </Col>
            <Col :flex="'none'" class="to-right">
              <Checkbox
                :style="{ flexDirection: 'row-reverse' }"
                @change="handleSelectAll"
                :checked="isCheckedAll"
                :disabled="props.isCustomersFetching"
                class="select-all-checkbox"
                >Выбрать всех</Checkbox
              >
            </Col>
          </Row>
        </Space>
      </template>
      <template #body>
        <List
          :data-source="props.customers"
          :style="{ width: '790px' }"
          v-if="props.customers.length"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Checkbox
                :checked="props.selectedCustomers.includes(item.id) || false"
                :key="item.id"
                :style="{ flexDirection: 'row-reverse', ...width100 }"
                @change="checkFarmer($event, item.id)"
              >
                <SmallCustomerCard :customer="item" />
              </Checkbox>
            </ListItem>
          </template>
        </List>
        <div class="no-data" v-else>
          <Empty :image="simpleEmptyImage" description="Нет данных" />
        </div>
        <div class="loading-indicator" v-if="props.isCustomersFetching">
          <FullscreenLoading :type="'secondary'" />
        </div>
      </template>
      <template #footer>
        <EButton
          :style-config="{
            ...primaryButton,
            fontSize14,
            padding: '14px 18px',
            marginRight: 'auto',
          }"
          :data="{
            disabled:
              !props.selectedCustomers.length || props.isCustomersFetching,
          }"
          class="cancel__button"
          @click="saveCustomers"
        >
          Подтвердить выбор
        </EButton>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.chevron-left {
  color: $gray-800;
  stroke: none;
}

.create-reduction-header {
  padding: 24px 32px;
  border-bottom: 1px solid $gray-300;
  background: white;
  h3 {
    @include h3();
  }
}
.select-modal {
  :deep(.modal-container) {
    width: 800px;
    height: 600px;
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-container > div) {
    box-sizing: border-box;
    height: 100%;
  }
  :deep(.wrapper) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  :deep(.modal-header) {
    padding: 32px 32px 0;
    box-sizing: border-box;
    .to-right {
      transform: translateX(40px);
    }
    h3 {
      @include h3();
      color: $gray-800;
    }
    span.modal-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000000e0;
    }
    span.search-input,
    .select-all-checkbox span {
      @include p3();
    }
  }
  :deep(.modal-footer) {
    box-sizing: border-box;
    padding: 24px 32px;
    display: flex;
    justify-items: flex-start;
    border-top: 1px solid $gray-300;
  }
  :deep(.modal-body) {
    box-sizing: border-box;
    flex-grow: 1;
    overflow-y: scroll;
    position: relative;
    border-top: 1px solid $gray-300;
    margin-top: 24px;
  }
  :deep(.no-data) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :deep(.ant-list-item) {
    padding: 24px 26px !important;
    &:hover {
      background-color: #0000000a;
    }
  }

  :deep(.ant-checkbox + span) {
    width: 100%;
  }
  :deep(.ant-checkbox) {
    transform: scale(1.25);
  }
  :deep(.checkbox-group .ant-checkbox) {
    align-self: flex-start;
  }
  .loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }
}
</style>
