<script setup lang="ts">
import { computed, h, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { IFarmerProduct } from '@/types/interfaces'
import {
  Button,
  Col,
  Form,
  FormInstance,
  FormItem,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Textarea,
} from 'ant-design-vue'
import EditProductFooter from '@/components/EditProduct/EditProductFooter.vue'
import router from '@/router'
import OverflowLoader from '@/components/Loaders/OverflowLoader.vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import { width100 } from '@/model/constants'
import UploadImage from '@/components/AddProduct/UploadImage.vue'
import { FarmerAPI } from '@/api/FarmerAPI'
import { CustomerAPI } from '@/api/CustomerAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
import { useCookies } from 'vue3-cookies'

interface IProductForm extends Partial<IFarmerProduct> {
  farmer_id: string
  image_body: string
  image_name: string
  image_url: string
  id: number
  title: string
}
const minQuantityDictionary = {
  шт: 1,
  кг: 0.01,
}
const route = useRoute()
const farmerProduct = ref<IProductForm>({
  id: 0,
  quantity: '',
  min_value: '',
  title: '',
  prepare_days: 0,
  vat_percent: 0,
  farmer_id: '',
  measurement_unit_id: '',
  comment: '',
  image_body: '',
  image_name: '',
  image_url: '',
})
const productId = route.params.id as number
const originalProduct = ref<any>()
const isShowModalUpdatePage = ref<boolean>(false)

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const company_id = user?.company_id

const isVerification = ref<boolean>(false)
const { getCoreCookie } = useJWTDecode()
const formRef = ref<FormInstance>()
const isFetching = ref(false)
const isSaving = ref(false)
const withVat = ref(false)
const formItemWidth = computed(() => {
  if (withVat.value && isVerification) {
    return '25%'
  }
  return '33.3333%'
})
const getProduct = async (id: number | string) => {
  await FarmerProductAPI.getItem(id, ['product']).then((res) => {
    const value: IProductForm = {
      id: res.id,
      title:
        res.secondCategory + ' ' + res.product.parameters.split('|').join(' '),
      quantity: +res.quantity,
      min_value: +res.min_value,
      prepare_days: +res.prepare_days,
      vat_percent: 0,
      farmer_id: company_id,
      measurement_unit_id: res.measurement_unit_id,
      comment: res.comment,
      image_body: res.body,
      image_name: res.image_name,
      image_url: res.image_url,
    }
    if (withVat.value || !isVerification.value) {
      value.vat_percent = res.vat_percent ?? 0
    }
    farmerProduct.value = value
    originalProduct.value = value
  })
}

const backToTable = () => router.go(-1)

const checkProductChanges = async () => {
  let currentData: any
  let newData: any
  let quantity: number
  await formRef.value
    ?.validate()
    .then((res) => {
      quantity = res.quantity
      currentData = {
        id: farmerProduct.value.id,
        ...res,
        image_body: farmerProduct.value.image_body,
        image_name: farmerProduct.value.image_name,
        image_url: farmerProduct.value.image_url,
      }
    })
    .then(async () => {
      await FarmerProductAPI.getItem(productId, ['product']).then(
        async (res) => {
          newData = {
            id: Number(res.id),
            title:
              res.secondCategory +
              ' ' +
              res.product.parameters.split('|').join(' '),
            quantity: +res.quantity,
            min_value: +res.min_value,
            prepare_days: +res.prepare_days,
            vat_percent: 0,
            farmer_id: company_id,
            measurement_unit_id: res.measurement_unit_id,
            comment: res.comment,
            image_body: res.body,
            image_name: res.image_name,
            image_url: res.image_url,
          }
          if (withVat.value || !isVerification.value) {
            newData.vat_percent = res.vat_percent ?? 0
          }

          if (
            originalProduct.value?.quantity &&
            originalProduct.value?.measurement_unit_id &&
            originalProduct.value.id &&
            originalProduct.value.farmer_id &&
            originalProduct.value.title
          ) {
            delete originalProduct.value.quantity
            delete originalProduct.value.measurement_unit_id
            delete originalProduct.value.id
            delete originalProduct.value.farmer_id
            delete originalProduct.value.title
          }

          delete currentData.id
          delete currentData.quantity

          if (originalProduct.value.vat_percent == 0) {
            delete originalProduct.value.vat_percent
          }

          const copyOriginalProduct = JSON.parse(
            JSON.stringify(originalProduct.value),
          )

          if (
            JSON.stringify(copyOriginalProduct) === JSON.stringify(currentData)
          ) {
            console.debug('no diff data')
            await FarmerProductAPI.update({
              ...currentData,
              id: newData.id,
              farmer_id: Number(company_id),
              quantity: quantity,
            })
              .then(() => {
                isSaving.value = false
                isFetching.value = false
                notification.info({
                  message: 'Изменения сохранены',
                  description: 'Пользователи увидят товар с новыми параметрами',
                  top: '80px',
                  icon: h(InfoCircleFilled, {
                    style: { color: 'rgba(22, 119, 255, 1)' },
                  }),
                })
                isShowModalUpdatePage.value = false
                router.push('/products')
              })
              .finally(() => {
                isSaving.value = false
                isFetching.value = false
              })
          } else if (
            JSON.stringify(copyOriginalProduct) !== JSON.stringify(currentData)
          ) {
            console.error('diff data')
            isSaving.value = false
            isFetching.value = false
            isShowModalUpdatePage.value = true
          }
        },
      )
    })
}

const saveProduct = async () => {
  await formRef.value?.validate().then(async (res) => {
    isSaving.value = true
    await checkProductChanges()
  })
}

const deleteProduct = async () => {
  isSaving.value = true
  const data = {
    id: farmerProduct.value.id,
    is_deleted: true,
  }
  await FarmerProductAPI.update(data).then(() => {
    isSaving.value = false
    notification.info({
      message: 'Товар удалён',
      top: '80px',
      icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
    })
    router.push('/products')
  })
}

const { cookies } = useCookies()

const getVatInfo = async () => {
  if (getCoreCookie()?.company_type === 'farmer') {
    await FarmerAPI.getFarmer(company_id).then((res) => {
      isVerification.value = res.is_active
      withVat.value = res.vat
    })
  } else if (getCoreCookie()?.company_type === 'customer') {
    await CustomerAPI.getItem(company_id).then((res) => {
      isVerification.value = res.is_active
      withVat.value = res.vat
    })
  }
}

const saveImage = (imageData: any) => {
  if (imageData.image_url !== 'NULL') {
    farmerProduct.value.image_url = imageData.image_body.split(',')[1]
    farmerProduct.value.image_body = imageData.image_body.split(',')[1]
    farmerProduct.value.image_name = imageData.image_name
  } else {
    farmerProduct.value.image_url = imageData.image_body
    farmerProduct.value.image_body = imageData.image_body
    farmerProduct.value.image_name = imageData.image_name
  }
}

const onQuantityBlur = () => {
  formRef.value?.validate(['min_value'])
}

const isQuitWithoutSaving = ref(false)

const quitWithoutSavingConfig = {
  title: 'Выйти без сохранения?',
  body: 'Внесённые изменения не будут сохранены',
  okText: 'Выйти',
  cancelText: 'Закрыть',
  width: 440,
  ok: () => router.go(-1),
}

const isDeleteProduct = ref(false)

const deleteProductConfig = computed(() => ({
  title: 'Удалить товар?',
  body: farmerProduct.value.title,
  okText: 'Удалить',
  cancelText: 'Закрыть',
  width: 440,
  ok: () => deleteProduct(),
}))

onMounted(async () => {
  isFetching.value = true
  await getVatInfo()
  await getProduct(productId as string | number)
  isFetching.value = false
})
</script>

<template>
  <div class="wrapper">
    <div class="wrapper-additional">
      <CreateHeader
        :is-show-create-chat-button="false"
        title="Редактирование данных товара"
        :is-product="true"
        :subtitle="farmerProduct.title"
        @button-click="backToTable"
      />
      <div class="farmer-product-card" v-if="!isFetching">
        <div class="card-body">
          <Form ref="formRef" :model="farmerProduct" layout="vertical">
            <Row :gutter="24" :wrap="false">
              <Col :flex="'260px'">
                <UploadImage
                  @input="saveImage"
                  :image="{
                    image_body: '',
                    image_name: farmerProduct.image_name,
                    image_url: farmerProduct.image_url,
                  }"
                />
              </Col>
              <Col :flex="'auto'">
                <Row :gutter="[0, 16]">
                  <Col :span="24">
                    <Row :gutter="[24, 0]" :wrap="false" :style="width100">
                      <Col :flex="formItemWidth">
                        <FormItem
                          label="В наличии"
                          name="quantity"
                          :rules="[
                            {
                              required: true,
                              message: 'Обязательное поле',
                              trigger: 'change',
                            },
                            {
                              max: 1000000,
                              message: `Максимум 1 000 000 ${farmerProduct.measurement_unit_id}`,
                              trigger: 'change',
                              type: 'number',
                            },
                            {
                              min: minQuantityDictionary[
                                farmerProduct.measurement_unit_id
                              ],
                              message: `Минимум ${minQuantityDictionary[farmerProduct.measurement_unit_id]} ${farmerProduct.measurement_unit_id}`,
                              trigger: 'change',
                              type: 'number',
                            },
                          ]"
                        >
                          <InputNumber
                            :controls="false"
                            v-model:value="farmerProduct.quantity"
                            :style="width100"
                            @blur="onQuantityBlur"
                          >
                            <template #addonAfter>
                              {{ farmerProduct.measurement_unit_id }}
                            </template>
                          </InputNumber>
                        </FormItem>
                      </Col>
                      <Col :flex="formItemWidth">
                        <FormItem
                          label="Минимальный заказ"
                          name="min_value"
                          :rules="[
                            {
                              required: true,
                              message: 'Обязательное поле',
                            },
                            {
                              max: farmerProduct.quantity,
                              message: `Не может быть больше, чем количество в наличии`,
                              trigger: 'change',
                              type: 'number',
                            },
                            {
                              min: minQuantityDictionary[
                                farmerProduct.measurement_unit_id
                              ],
                              message: `Минимум ${minQuantityDictionary[farmerProduct.measurement_unit_id]} ${farmerProduct.measurement_unit_id}`,
                              trigger: 'change',
                              type: 'number',
                            },
                          ]"
                        >
                          <InputNumber
                            :controls="false"
                            v-model:value="farmerProduct.min_value"
                            :style="width100"
                          >
                            <template #addonAfter>
                              {{ farmerProduct.measurement_unit_id }}
                            </template>
                          </InputNumber>
                        </FormItem>
                      </Col>
                      <Col :flex="formItemWidth">
                        <FormItem
                          label="Срок подготовки"
                          name="prepare_days"
                          :rules="[
                            {
                              max: 180,
                              message: 'Максимум 180 дней',
                              trigger: 'change',
                              type: 'number',
                            },
                          ]"
                        >
                          <InputNumber
                            :controls="false"
                            v-model:value="farmerProduct.prepare_days"
                            :style="width100"
                          >
                            <template #addonAfter> дн. </template>
                          </InputNumber>
                        </FormItem>
                      </Col>
                      <Col :flex="formItemWidth" v-if="withVat">
                        <FormItem
                          label="НДС"
                          name="vat_percent"
                          :rules="[
                            {
                              max: 100,
                              message: 'Максимум 100%',
                              trigger: 'change',
                              type: 'number',
                            },
                            {
                              min: 1,
                              message: 'Минимум 1%',
                              trigger: 'change',
                              type: 'number',
                            },
                          ]"
                        >
                          <InputNumber
                            :controls="false"
                            v-model:value="farmerProduct.vat_percent"
                            :style="width100"
                          >
                            <template #addonAfter> % </template>
                          </InputNumber>
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col :span="24">
                    <FormItem
                      label="Описание товара"
                      name="comment"
                      help="Максимум 1000 символов"
                      :rules="[
                        {
                          max: 1000,
                          message: 'Максимум 1000 символов',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <Textarea v-model:value="farmerProduct.comment" />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <OverflowLoader v-show="isFetching || isSaving" />
      <EditProductFooter>
        <template #buttons>
          <Button
            :loading="isFetching || isSaving"
            :size="'large'"
            @click="isDeleteProduct = true"
            danger
          >
            Удалить товар
          </Button>
          <Space :size="16">
            <Button
              :loading="isFetching || isSaving"
              :size="'large'"
              @click="isQuitWithoutSaving = true"
              >Выйти без сохранения</Button
            >
            <Button
              :loading="isFetching || isSaving"
              :size="'large'"
              type="primary"
              @click="saveProduct"
            >
              Сохранить изменения</Button
            >
          </Space>
        </template>
      </EditProductFooter>
    </div>
  </div>
  <ModalUpdatePage
    description="Товар был изменен другим пользователем. Обновите страницу для подгрузки актуальных данных."
    v-model:open="isShowModalUpdatePage"
  />
  <Modal
    v-model:open="isQuitWithoutSaving"
    :title="quitWithoutSavingConfig.title"
    @ok="quitWithoutSavingConfig.ok"
    :okText="quitWithoutSavingConfig.okText"
    :cancelText="quitWithoutSavingConfig.cancelText"
    centered
    :width="quitWithoutSavingConfig.width"
  >
    <p>{{ quitWithoutSavingConfig.body }}</p>
  </Modal>
  <Modal
    v-model:open="isDeleteProduct"
    :title="deleteProductConfig.title"
    @ok="deleteProductConfig.ok"
    :okText="deleteProductConfig.okText"
    :cancelText="deleteProductConfig.cancelText"
    :confirm-loading="isSaving"
    centered
    :width="deleteProductConfig.width"
  >
    <p>{{ deleteProductConfig.body }}</p>
  </Modal>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.wrapper {
  background: white;
  width: 100%;
  min-height: calc(100vh - 69px - 40px - 80px);
  padding: 40px 50px 100px 50px;

  &-additional {
    @include wrapper();
    position: relative;
  }

  .farmer-product-card {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    .card-header {
      padding: 24px;
      border-bottom: 1px solid #f0f0f0;
    }
    .card-body {
      padding: 24px;
    }
  }
}
</style>
