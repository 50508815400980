<script setup lang="ts">
import { Button, Modal } from 'ant-design-vue'

// Модалка для отказа от всего заказа

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['update:open', 'onSuccess'])

const closeModal = () => {
  emit('onSuccess')
  emit('update:open', false)
}
</script>

<template>
  <Modal width="440px" :open="open" centered @cancel="closeModal" closable>
    <template #title> Запрос отправлен поставщику </template>
    Изменения сохранены, ожидайте согласования поставщиком
    <template #footer>
      <div class="modal_footer">
        <Button @click="closeModal" type="primary"> Хорошо </Button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.modal_footer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
</style>
