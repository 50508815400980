<script setup lang="ts">
import { IContact, IGeneralOrder } from '@/types/interfaces'
import { computed, ref, watch } from 'vue'
import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Form,
  FormItem,
  Select,
  Textarea,
} from 'ant-design-vue'
import dayjs, { Dayjs } from 'dayjs'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { ContactAPI } from '@/api/ContactAPI'
import { getUserIdTemporary } from '@/helpers/scripts'
import { AddressAPI } from '@/api/AddressAPI'

const { Option } = Select

interface IProps {
  general_order: IGeneralOrder
  open: boolean
}

interface IForm {
  delivery_date: null | Dayjs
  delivery_address_id: null | number | string
  comment: null | string
  contact_id: null | number | string
}
const props = defineProps<IProps>()
const emit = defineEmits(['update:open', 'save'])

const form_state = ref<IForm>({
  delivery_date: null,
  delivery_address_id: null,
  comment: null,
  contact_id: null,
})

const rules = {
  comment: [
    {
      max: 500,
      message: 'Максимум 500 символов',
    },
  ],
}

const disabled_time = (current: Dayjs) => {
  const max_prepared_days =
    Math.max(
      ...order.value.order_positions.map(
        (position) => position.farmer_product.prepare_days,
      ),
    ) + 1
  const disabled_date = dayjs(props.general_order?.created_at).add(
    max_prepared_days,
    'day',
  )

  return current && current.isBefore(disabled_date, 'day')
}

const form = ref()
const set_form_state = () => {
  form_state.value = {
    delivery_date: dayjs(props.general_order?.delivery_date),
    delivery_address_id:
      props.general_order?.delivery_address?.street || 'Не указано',
    contact_id: props.general_order?.contact?.name || 'Не указано',
    comment: props.general_order?.comment,
  }
}

const close_and_reset = () => {
  emit('update:open', false)
  set_form_state()
}

const order = computed(() => {
  return props.general_order?.orders![0]
})

const parameteres_the_same = computed(
  () =>
    form_state.value.delivery_date ===
      dayjs(props.general_order?.delivery_date) &&
    form_state.value.delivery_address_id ===
      props.general_order?.delivery_address?.street &&
    form_state.value.contact_id === props.general_order?.contact?.name &&
    form_state.value.comment === props.general_order?.comment,
)

const is_fetching = ref(false)
const contacts = ref<IContact[]>([])
const addresses = ref<IContact[]>([])
const get_select_items = async () => {
  is_fetching.value = true
  await Promise.all([get_contacts(), get_addresses()]).then(() => {
    set_form_state()
    is_fetching.value = false
  })
}

const get_contacts = async () => {
  contacts.value = await ContactAPI.getItemsContacts(
    [
      ['company_id', 'eq', getUserIdTemporary()],
      'and',
      ['company_type', 'eq', 'customer'],
    ],
    1,
    100,
  ).then((res) =>
    push_empty_option(res.items, !!props.general_order?.contact?.name),
  )
}

const get_addresses = async () => {
  addresses.value = await AddressAPI.getAddresses(
    getUserIdTemporary(),
    'customer',
  ).then((res) =>
    push_empty_option(
      res.items,
      !!props.general_order?.delivery_address?.street,
    ),
  )
}

const push_empty_option = (items: IContact[], is_have_data: boolean) => {
  if (!is_have_data) {
    items.unshift({ id: 0, name: 'Не указано', street: 'Не указано' })
  }
  return items
}

const save_changes = () => {
  form.value.validate().then(() => {
    emit('save', {
      ...form_state.value,
      delivery_address_id:
        addresses.value.find(
          (address) => address.street === form_state.value.delivery_address_id,
        )?.id || null,
      id: props.general_order?.id,
      delivery_date: dayjs(form_state.value.delivery_date).format('YYYY-MM-DD'),
      contact_id:
        contacts.value.find(
          (contact) => contact.name === form_state.value.contact_id,
        )?.id || null,
      comment: form_state.value.comment || null,
    })
  })
}

const is_delivery_by_farmer = computed(() => {
  return order.value.delivery_method === 'Доставка фермером'
})

watch(
  () => props.open,
  async (new_value) => {
    if (new_value) {
      await get_select_items()
    }
  },
)
</script>

<template>
  <Drawer
    :open="open"
    :bodyStyle="{ padding: '24px 32px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :footer-style="{ padding: '24px 32px' }"
    :closable="true"
    :width="500"
    @close="close_and_reset"
    :title="`Заказ от ${order?.farmer.legal_form} ${order?.farmer.name}`"
    class="drawer-reverse-header"
  >
    <Form :model="form_state" ref="form" :rules="rules">
      <Flex :gap="32" vertical>
        <Flex :gap="8" vertical>
          <span class="form-title">Дата получения</span>
          <DatePicker
            size="large"
            v-model:value="form_state.delivery_date"
            :show-today="false"
            :disabled-date="disabled_time"
            :locale="locale"
            placeholder="Дата и время"
            format="DD.MM.YYYY"
            :inputReadOnly="true"
          />
        </Flex>

        <Flex :gap="8" vertical v-if="is_delivery_by_farmer">
          <span class="form-title">Адрес доставки</span>
          <Select
            v-model:value="form_state.delivery_address_id"
            :loading="is_fetching"
          >
            <Option
              v-for="address in addresses"
              :key="address.id"
              :value="address.street"
              >{{ address.street }}</Option
            >
          </Select>
        </Flex>

        <Flex :gap="8" vertical>
          <span class="form-title">Комментарий к заказу</span>
          <FormItem name="comment">
            <Textarea
              v-model:value="form_state.comment"
              :rows="4"
              placeholder="Комментарий к заказу, необязательно"
            />
          </FormItem>
        </Flex>
      </Flex>
    </Form>

    <template #footer>
      <Flex :gap="12" justify="flex-end">
        <Button size="large" :onclick="close_and_reset">Закрыть</Button>
        <Button
          :disabled="parameteres_the_same"
          type="primary"
          size="large"
          :onClick="save_changes"
          >Сохранить изменения
        </Button>
      </Flex>
    </template>
  </Drawer>
</template>

<style scoped lang="scss">
.form-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
</style>
