<script setup lang="ts">
import {
  fontSize16,
  secondaryButton,
  dangerButton,
} from '@/assets/EgalStyles/EButton'

const emit = defineEmits(['close', 'continue'])
</script>

<template>
  <div class="signed-modal-content">
    <div class="header">
      <div class="title">Отказаться от заказа?</div>
    </div>

    <div class="content">
      Вы удаляете последнюю позицию в заказе, это автоматически отменит весь
      заказ
    </div>
    <div class="btns">
      <EButton
        :style-config="{
          ...secondaryButton,
          ...fontSize16,
          width: '100%',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          border: '1px solid #A0AEC0',
          hover: {
            backgroundColor: '#F7FAFC',
          },
          active: {
            backgroundColor: '#F7FAFC',
          },
        }"
        @click="() => emit('close')"
        >Нет, закрыть
      </EButton>
      <EButton
        :style-config="{
          ...dangerButton,
          ...fontSize16,
          width: '100%',
          justifyContent: 'center',
        }"
        @click="() => emit('continue')"
        >Отказаться</EButton
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.signed-modal-content {
  display: flex;
  flex-direction: column;
  width: 392px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
  }
}

.content {
  text-align: start;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $gray-700;
  margin-bottom: 35px;
}

.btns {
  display: flex;
  gap: 12px;
  justify-content: center;
}
</style>
