<script lang="ts" setup>
// import NotFoundView from "@/components/NotFoundView/NotFoundView.vue";
import { computed, onUnmounted } from 'vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import CustomerAuctions from '@/views/Customer/auctions/CustomerAuctions.vue'
import FarmerAuctions from '@/views/Farmer/auctions/FarmerAuctions.vue'
import { auctionStore } from '@/store/auctionStore'
import {useCookies} from "vue3-cookies";
import {useRoute} from "vue-router";

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const store = auctionStore()
const route = useRoute()

onUnmounted(() => {
  store.$reset()
})

</script>

<template>
  <div class="auctions-view">
    <CustomerAuctions v-if="getCoreCookie()?.company_type === Roles.Customer" />
    <FarmerAuctions v-else />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.auctions-view {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  @include wrapper();
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
