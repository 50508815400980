<script setup lang="ts">
import { IOrder } from '@/types/interfaces'
import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Form,
  FormItem,
  InputNumber,
  Radio,
  RadioGroup,
} from 'ant-design-vue'
import { computed, ref, watch } from 'vue'
import { width100 } from '@/model/constants'
import FarmerChangeDeliveryMethodAlert from '@/components/Customer/FarmerChangeDeliveryMethodAlert.vue'
import { IAddress } from '@/types/IAddress'
import { Rule } from 'ant-design-vue/es/form'

interface IProps {
  order: IOrder
  open: boolean
  is_complex_order: boolean
  delivery_address: IAddress | null
}

interface IForm {
  delivery_method: null | 'Самовывоз' | 'Доставка'
  palletizing_type: null | 'standard' | 'euro'
  payment_type: null | 'cash' | 'non_cash'
  is_payment_delayed: boolean
  payment_delay_days: null | number
  delivery_payment: null | 'excluded' | 'included'
  need_unload: boolean
}
const props = defineProps<IProps>()

const emit = defineEmits(['update:open', 'save_changes'])

const radioStyle = {
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
}

const form = ref()
const form_state = ref<IForm>({
  delivery_method: null,
  delivery_payment: null,
  is_payment_delayed: false,
  need_unload: false,
  palletizing_type: null,
  payment_delay_days: null,
  payment_type: null,
})

const validateDelayDays = async (_rule: Rule, value: string) => {
  if (!form_state.value.is_payment_delayed) {
    return Promise.resolve()
  }
  if (Number(value) < 1 || Number(value) > 90) {
    return Promise.reject('Не меньше 1 и не больше 90 дней')
  } else {
    return Promise.resolve()
  }
}

const rules = computed(() => {
  return {
    payment_delay_days: [
      {
        required: form_state?.value?.is_payment_delayed,
        validator: validateDelayDays,
        message: 'Не меньше 1 и не больше 90 дней',
        type: 'number',
      },
    ],
  }
})

const close_and_reset = () => {
  emit('update:open', false)
  set_form_state()
}

const set_form_state = () => {
  form_state.value = {
    delivery_method: props.order.delivery_method,
    delivery_payment: props.order.delivery_payment,
    is_payment_delayed: props.order.is_payment_delayed,
    need_unload: props.order.need_unload,
    palletizing_type: props.order.palletizing_type,
    payment_delay_days: props.order.payment_delay_days,
    payment_type: props.order.payment_type,
  }
}

const handle_delivery_type = (event: any) => {
  if (event.target.value === 'Самовывоз') {
    form_state.value.delivery_payment = null
  } else {
    form_state.value.delivery_payment = 'included'
  }
}

const handle_is_payment_delayed = (e: any) => {
  if (!e.target.checked) {
    form_state.value.payment_delay_days = null
    form.value.validate()
  }
}

const handle_standart_paletizing = (e: any) => {
  form_state.value.palletizing_type = e.target.checked ? 'standard' : null
}
const handle_euro_paletizing = (e: any) => {
  form_state.value.palletizing_type = e.target.checked ? 'euro' : 'standard'
}

const save_changes = () => {
  form.value.validate().then(() => {
    emit('save_changes', {
      ...form_state.value,
      id: props.order.id,
      payment_delay_days: form_state.value.payment_delay_days || null,
    })
  })
}

const parameteres_the_same = computed(
  () =>
    form_state.value.delivery_method === props.order.delivery_method &&
    form_state.value.delivery_payment === props.order.delivery_payment &&
    form_state.value.is_payment_delayed === props.order.is_payment_delayed &&
    form_state.value.need_unload === props.order.need_unload &&
    form_state.value.palletizing_type === props.order.palletizing_type &&
    form_state.value.payment_delay_days === props.order.payment_delay_days &&
    form_state.value.payment_type === props.order.payment_type,
)

watch(
  () => props.open,
  (new_value) => {
    if (new_value) {
      set_form_state()
    }
  },
)
</script>

<template>
  <Drawer
    :open="open"
    :bodyStyle="{ padding: '24px 32px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :footer-style="{ padding: '24px 32px' }"
    :closable="true"
    :width="500"
    @close="close_and_reset"
    :title="`Заказ от ${order.farmer.legal_form} ${order.farmer.name}`"
    class="drawer-reverse-header"
  >
    <Flex :gap="32" vertical>
      <Alert
        v-if="
          order.delivery_method === 'Самовывоз' &&
          form_state.delivery_method === 'Доставка фермером' &&
          !delivery_address
        "
        type="warning"
        show-icon
        message="Выберите адрес доставки"
      />
      <FarmerChangeDeliveryMethodAlert
        :is_complex_order="is_complex_order"
        :order="order"
        :delivery_address="delivery_address"
        @edit_general_order="emit('edit_general_order')"
      />

      <Form :model="form_state" ref="form" :rules="rules">
        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title"> Способ доставки </span>
            <RadioGroup
              v-model:value="form_state.delivery_method"
              @change="handle_delivery_type"
            >
              <Radio :style="radioStyle" value="Самовывоз">Самовывоз</Radio>
              <Radio
                :style="radioStyle"
                :disabled="!order?.farmer.can_deliver"
                value="Доставка фермером"
                >Доставка от поставщика</Radio
              >
            </RadioGroup>
            <RadioGroup
              v-model:value="form_state.delivery_payment"
              class="second-level"
              :disabled="form_state.delivery_method !== 'Доставка фермером'"
            >
              <Radio :style="radioStyle" value="included"
                >Включена в стоимость товара</Radio
              >
              <Radio :style="radioStyle" value="excluded"
                >Оплачивается отдельно</Radio
              >
            </RadioGroup>
          </Flex>
        </FormItem>

        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title">Условия оплаты</span>
            <RadioGroup v-model:value="form_state.payment_type">
              <Radio :style="radioStyle" value="non_cash"
                >Безналичный расчёт</Radio
              >
              <Radio :style="radioStyle" value="cash">Наличный расчёт</Radio>
            </RadioGroup>
            <Checkbox
              v-model:checked="form_state.is_payment_delayed"
              @change="handle_is_payment_delayed"
            >
              Нужна отсрочка по оплате
            </Checkbox>
            <FormItem name="payment_delay_days">
              <InputNumber
                v-model:value="form_state.payment_delay_days"
                :controls="false"
                placeholder="Укажите кол-во дней отсрочки"
                :disabled="!form_state.is_payment_delayed"
                class="payment-delay-days-input"
                :style="width100"
                :precision="0"
              />
            </FormItem>
          </Flex>
        </FormItem>

        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title">Дополнительные услуги</span>
            <Checkbox
              :checked="
                form_state.palletizing_type === 'standard' ||
                form_state.palletizing_type === 'euro'
              "
              @change="handle_standart_paletizing"
            >
              Паллетирование
            </Checkbox>
            <Checkbox
              :checked="form_state.palletizing_type === 'euro'"
              @change="handle_euro_paletizing"
              :disabled="!form_state.palletizing_type"
              class="second-level"
            >
              Европалеты
            </Checkbox>
            <Checkbox v-model:checked="form_state.need_unload">
              Разгрузка товара поставщиком
            </Checkbox>
          </Flex>
        </FormItem>
      </Form>
    </Flex>

    <template #footer>
      <Flex :gap="12">
        <Button size="large" :onclick="close_and_reset">Закрыть</Button>
        <Button
          :disabled="parameteres_the_same"
          type="primary"
          size="large"
          :onClick="save_changes"
          >Сохранить изменения
        </Button>
      </Flex>
    </template>
  </Drawer>
</template>

<style scoped lang="scss">
.form-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.second-level {
  margin-left: 24px;
}
</style>
