<script lang="ts" setup>

import { ICartData } from '@/types/interfaces'
import { declensionProduct } from '@/helpers/scripts'
import DocumentSignedIcon from '@/components/Icons/DocumentSignedIcon.vue'
import { DownOutlined } from '@ant-design/icons-vue'
import { Button, Space } from 'ant-design-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'

interface Props {
  farm: ICartData
  withoutIcon: boolean
  isNotAvailable?: boolean
  collapsedCards: string[]
}

const props = defineProps<Props>()
const emit = defineEmits(['collapseCard'])
</script>

<template>
  <div class="cart-product-header">
    <div class="header" :class="{ 'is-not-available': isNotAvailable }">
      <div class="header-wrapper">
        <span class="title">
          <DocumentSignedIcon v-if="props?.farm.is_partner" fill="#11A162" />
          <router-link
            href=""
            class="to-farmer-link"
            :to="`farmer-product/${farm.id}`"
          >
            {{ props?.farm.legal_form + ' ' + farm.name }}
          </router-link>
        </span>
        <span class="subtitle">
          <SimpleRate :company_rating="props?.farm.company_rating"/>
          {{ props?.farm.addresses.street }}
        </span>
      </div>
      <Space :size="8" v-if="!isNotAvailable">
        {{
          `${props?.farm.cart_items.length} товар${declensionProduct(props?.farm.cart_items.length)}`
        }}
        <Button
          type="text"
          :size="'small'"
          @click="emit('collapseCard', props?.farm.id)"
          :class="{ collapsed: collapsedCards.includes(farm.id) }"
        >
          <template #icon>
            <DownOutlined />
          </template>
        </Button>
      </Space>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.cart-product-header {
  .header {
    background: #00000005;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: flex-start;
    border-bottom: 1px solid #0000000f;
     span.anticon {
      transition: 0.2s ease-in-out;
      transform: rotate(0deg);
    }
    .collapsed span.anticon {
      transform: rotate(180deg);
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    .amount {
      white-space: nowrap;
    }
    &.is-not-available {
      background-color: transparent;
      border-bottom: none;
    }
  }

  .title {
    .to-farmer-link {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #000000e0;
      text-decoration: none;
      &:hover {
        color: #1677ff;
      }
      &:active {
        color: #125ac0;
      }
    }
    svg {
      transform: scale(1.25);
    }
  }

  .subtitle {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}
</style>
