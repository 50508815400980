import { APIModels } from '@/types/enums'
import { Document, DocumentCreate } from '@/types/types'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class DocumentAPIModel {
  async createManyDocuments(data: Document[] | DocumentCreate[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async createDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return RequestManager.request({
      model: APIModels.Document,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async uploadDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return RequestManager.request({
      model: APIModels.Document,
      method: EgalMethods.upload,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async uploadCompanyDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.upload,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getCustomDocuments2(
    filters: any = [],
    perPage = 10,
    withs: string[] = ['users', 'orders', 'order_documents', 'document_users'],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.GetItems,
      data: { filter: filters },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Document,
      method: EgalMethods.GetItems,
      data: { filter: filters, withs, pagination: { per_page: perPage, page: 1 }},
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getCompanyDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.GetItems,
      data: { filter: filters, withs, pagination: { per_page: perPage, page: 1 }},
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getDocument(id: string | number): Promise<any> {
    return RequestManager.request({
      model: APIModels.Document,
      method: EgalMethods.GetItem,
      data: { id },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getCompanyDocument(id: string | number): Promise<any> {
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.GetItem,
      data: { id },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async updateDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return RequestManager.request({
      model: APIModels.Document,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async updateManyDocuments(data: Partial<Document>[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.CompanyDocument,
      method: EgalMethods.updateMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core') }
    })
  }
}

const DocumentAPI = new DocumentAPIModel()
export { DocumentAPI }
