import {
  ISpecialPriceForCustomerResponse,
  ISpecialPriceForCustomerFilters,
} from '@/types/interfaces'
import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class SpecialPriceAPIModel {
  async getForCustomer(data: {
    filters: ISpecialPriceForCustomerFilters
  }): Promise<ISpecialPriceForCustomerResponse> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.getForCustomer,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getForFarmer(data: {
    filters: ISpecialPriceForCustomerFilters
  }): Promise<ISpecialPriceForCustomerResponse> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.getForFarmer,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async create(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('core')},
      data: { filter, withs, pagination: { per_page: perPage, page: page }, order: [['created_at', 'desc']],},
    })
  }

  async update(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async delete(id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.SpecialPrice,
      method: EgalMethods.delete,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const SpecialPriceAPI = new SpecialPriceAPIModel()
export { SpecialPriceAPI }
