<script setup lang="ts">
import CustomerSearch from '@/components/Home/CustomerSearch.vue'
import YaMap from '@/components/YaMap/YaMap.vue'
import CustomerFilters from '@/components/Home/CustomerFilters.vue'
import { ref } from 'vue'
import { useStore } from '@/store'
import { TOptionCategory } from '@/types/types'
import {IProps} from './interfaces'

const props = defineProps<IProps>()

const emit = defineEmits([
  'toggleFilters',
  'showAddress',
  'closeFilters',
  'setCategory',
  'setCategoryChildren',
  'setFilterParameters',
  'dropFilters',
  'handleRangeFilter',
  'handleApplyFilters',
  'applyFilters',
  'setFilters',
])

const screenWidth = ref<number>(document.documentElement.clientWidth)
const mainStore = useStore()

const handleSearch = (filterSearch: any) => {
  const filter = filterSearch.map((el: any) => {
    const currField = el.field === 'Категории' ? 'category' : 'product'
    const currId = el.value
    return [currField, 'search', currId]
  })
  emit('setFilters', filter, 'search')
}

const handleToggleFilters = () => {
  emit('toggleFilters')
}

const handleShowAddress = (address: any) => {
  emit('showAddress', address)
}

const handleCloseFilters = () => {
  emit('closeFilters')
}

const handleSetCategory = (value: string, option: TOptionCategory) => {
  if (props.selectedCategory?.value && props.selectedCategory?.value?.id !== value) {
    emit('setCategory', value, option, true)
  }
  emit('setCategory', value, option, false)
}

const handleCategoryChildren = (
  value: string,
  option: TOptionCategory,
  index: number,
) => {
  emit('setCategoryChildren', { value, option, index })
}

const handleSetFilterParameters = (filterParameters: Record<string, any[]>) => {
  emit('setFilterParameters', filterParameters)
}

const handleDropFilters = () => {
  emit('dropFilters')
}

const handleRangeFilter = (value: string, type: string, direction: string) => {
  emit('handleRangeFilter', { value, type, direction })
}

const handleApplyFilters = () => {
  emit('applyFilters', 'filters')
}

const setFilters = (filter: any) => {
  emit('setFilters', filter, 'filters')
}
</script>

<template>
  <div
    class="map-block"
    :style="{ position: props.position, top: marginTop ? marginTop : '0' }"
  >
    <CustomerSearch
      colorMode="green"
      :screen-width="screenWidth"
      :is-open-filters="isOpenFilters"
      @search="handleSearch"
      @open-filters="handleToggleFilters"
      @show-address="handleShowAddress"
    />
    <CustomerFilters
      :is-open-filters="isOpenFilters"
      :categoryOptions="categoryOptions"
      :selected-category="selectedCategory"
      :categoryChildrens="categoryChildrens"
      :childCount="childCount"
      :filterParameters="filterParameters"
      :filter-range="filterRange"
      @filter="setFilters"
      @closeFilters="handleCloseFilters"
      @setCategory="handleSetCategory"
      @setCategoryChildren="handleCategoryChildren"
      @setFilterParameters="handleSetFilterParameters"
      @drop-filters="handleDropFilters"
      @handle-range-filter="handleRangeFilter"
      @apply-filters="handleApplyFilters"
    />
    <YaMap
      :class="!mainStore?.isVerification ? 'blur' : ''"
      class="map"
      height="100%"
      width="100%"
      :points="farmsPoints"
      :is-interactive="false"
      :marker-colors="{
        default: 'islands#darkGreenCircleIcon',
        active: 'islands#darkGreenCircleIcon',
      }"
      :cluster-color="'islands#darkGreenClusterIcons'"
      :maxZoom="7"
    />
  </div>
</template>

<style scoped lang="scss">
.map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.map-block {
  position: relative;
  width: 100%;
  height: calc(100vh - 73px);
}
</style>
