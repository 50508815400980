<script lang="ts" setup>
import {
  Button,
  Col,
  Divider,
  Drawer,
  Flex,
  Form,
  FormItem,
  InputNumber,
  Modal,
  notification,
  Row,
  TypographyText as TpText,
  TypographyTitle as TpTitle,
  Space, Input,
} from 'ant-design-vue'
import {IAuctionProductRequest, IFarmerProduct} from '@/types/interfaces'
import {computed, h, onMounted, ref, toRefs, watch} from 'vue'
import ProductProperty from '@/views/Farmer/reductions/reduction/makeBetDrawer/ProductProperty.vue'
import { getUserIdTemporary, roundNumber } from '@/helpers/scripts'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { RUBLE } from '@/model/constants'
import { AuctionProductAnswerAPI } from '@/api/AuctionProductAnswerAPI'
import { Rule } from 'ant-design-vue/es/form'
import { AuctionAPI } from '@/api/AuctionAPI'
import { SelectOutlined } from '@ant-design/icons-vue'
import router from '@/router'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import {SpecialPriceAPI} from "@/api/SpecialPriceAPI";
import {useCookies} from "vue3-cookies";

interface IFormState {
  price_per_unit: string | number
  vat: string | number
  price_with_vat: string | number
}

interface IProps {
  productRequest: IAuctionProductRequest
  open: boolean
  onClose: () => void
  auctionId: number
  participantId: number
  customerHasBet: boolean
  farmerId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['refetchReduction'])

const formRef = ref()
const creatingBet = ref(false)
const isProductDeleted = ref(false)
const isAuctionClosed = ref(false)
const isOpenModal = ref(false)
const isOpenProductDrawer = ref(false)

const customerId = +getUserIdTemporary()
const priceWithVat = computed(() => customerAnswer.value?.price_per_unit)

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const isCustomer = computed(() => getCoreCookie()?.company_type === Roles.Customer)

const customerAnswer = computed(() =>
  props.productRequest?.auction_product_answers.find(
    (answer) => answer.auction_participant_id === props.participantId,
  ),
)

const formState = ref<IFormState>({
  price_per_unit: customerAnswer.value
    ? roundNumber(
        Number(customerAnswer.value.price_per_unit) / (1 + Number(props.productRequest.vat) / 100),
      )
    : '',
  vat: props.productRequest.vat,
  price_with_vat: customerAnswer.value
    ? roundNumber(customerAnswer.value.price_per_unit)
    : '',
})

const getFullPrice = ({
  price_per_unit,
  measurement_unit_id,
}: {
  price_per_unit: string | number
  measurement_unit_id: string
}) => {
  return `${price_per_unit} ₽/${measurement_unit_id}`
}

const showNotification = () => {
  if (!props.customerHasBet) {
    notification.success({
      message: 'Первая ставка сделана',
      style: {
        "background-color": 'rgba(220, 243, 209, 1)',
      },
      description: 'Теперь вам доступен просмотр ставок от других компаний',
    })
  } else {
    notification.success({
      message: 'Ставка сделана',
      style: {
        "background-color": 'rgba(220, 243, 209, 1)',
      },
    })
  }
}

const createBet = async (price_with_vat: number) => {
  creatingBet.value = true
  if (await checkIsAuctionClosed()) {
    isAuctionClosed.value = true
    creatingBet.value = false
    return
  }
  if (await checkIsProductDeleted()) {
    isProductDeleted.value = true
    creatingBet.value = false
    return
  }
  const goAfterAnswer = () => {
    emit('refetchReduction')
    showNotification()
    props.onClose()
  }
  if (customerAnswer.value) {
    AuctionProductAnswerAPI.update({
      id: customerAnswer.value.id,
      price_per_unit: roundNumber(price_with_vat),
    })
      .then(() => {
        goAfterAnswer()
      })
      .finally(() => {
        creatingBet.value = false
      })
  } else {
    AuctionProductAnswerAPI.create({
      auction_product_request_id: props.productRequest.id,
      price_per_unit: roundNumber(price_with_vat),
    })
      .then(() => {
        goAfterAnswer()
      })
      .finally(() => {
        creatingBet.value = false
      })
  }
}

const handleCreateBet = () => {
  formRef.value
    .validate()
    .then(({ price_per_unit, vat, price_with_vat }: IFormState) => {
      const best_bet_price =
        props.productRequest?.auction_product_answers?.reduce(
          (accum: number | null, currentAnswer) => {
            const full_price = Number(currentAnswer.price_per_unit)
            if (
              currentAnswer.auction_participant.customer_id !== customerId &&
              (!accum || accum > full_price)
            ) {
              return full_price
            }
            return accum
          },
          null,
        )
      if (best_bet_price && best_bet_price >= Number(price_with_vat)) {
        isOpenModal.value = true
      } else {
        createBet(Number(price_with_vat))
      }
    })
}

const checkIsProductDeleted = async () => {
  return !(await FarmerProductAPI.getFilteredProducts(
    [
      [
        'product_id',
        'eq',
        String(props.productRequest.farmer_product?.product_id ?? 0),
      ],
      'AND',
      ['farmer_id', 'eq', String(props.farmerId)],
    ],
    1,
    1,
    [],
  ).then(({ items }) => items.length))
}

const checkIsAuctionClosed = async () => {
  return await AuctionAPI.getItem(props.auctionId.toString()).then(
    (res) => res.status === 'closed',
  )
}

const bestBet = computed(() =>
    getFullPrice({
    price_per_unit: roundNumber(props.productRequest.best_bet?.price_per_unit) || '',
    measurement_unit_id:
      props.productRequest.farmer_product?.measurement_unit_id || '',
  }),
)

const isDisabledCreateBet = computed(
  () =>
    customerAnswer.value?.price_per_unit === formState.value.price_per_unit ||
    isBlockBtn.value,
)

const setProductInitPrice = () => {
  SpecialPriceAPI.getForCustomer({
    filters: {
      farmer_product_id: props.productRequest.farmer_product_id,
      product_request_id: props.productRequest.id,
      customer_id: customerId,
      amount: +props.productRequest.volume,
    },
  }).then((data) => {
    formState.value.price_with_vat = data.price
  })
}

watch(
  () => [formState.value.price_with_vat],
  () => {
    if (formState.value.price_with_vat) {
      formState.value.price_per_unit = roundNumber(
        Number(formState.value.price_with_vat) /
          (1 + Number(formState.value.vat) / 100),
      )
    } else {
      formState.value.price_per_unit = ''
    }
  },
)

const farmerProductId = ref<null | number>(null)
const specialPrice = ref()

watch(
  () => props.open,
  (newValue) => {
    const farmerId = props.productRequest?.farmer_product?.farmer_id
    if (newValue) {
      FarmerProductAPI.getFilteredProducts(
          [
            ['product_id', 'eq', String(props.productRequest.farmer_product_id)],
            'AND',
            ['farmer_id', 'eq', farmerId],
          ],
          1,
          1,
          ['special_prices'],
      ).then(({ items }) => {
        const neededFarmerProduct: IFarmerProduct = items?.[0]

        farmerProductId.value = neededFarmerProduct?.id
        const customerSpecialPrice = neededFarmerProduct?.special_prices?.find(
            (specialPrice) => +specialPrice.customer_id === customerId,
        )
        if (customerSpecialPrice) {
          specialPrice.value = customerSpecialPrice
        }
        if (!customerAnswer.value || !neededFarmerProduct) {
          setProductInitPrice()
        }
      })
    } else {
      formState.value = {
        price_per_unit: customerAnswer.value
          ? roundNumber(
              Number(customerAnswer.value.price_per_unit) /
                (1 + Number(props.productRequest.vat) / 100),
            )
          : '',
        vat: props.productRequest.vat,
        price_with_vat: customerAnswer.value
          ? roundNumber(customerAnswer.value.price_per_unit)
          : formState.value.price_with_vat,
      }
    }
  },
  {
    immediate: true,
  },
)

const closeAndRefetch = () => {
  isProductDeleted.value = false
  isAuctionClosed.value = false
  emit('refetchReduction')
  props.onClose()
}

const isBlockBtn = ref(false)

const moreThanStartPrice = (_rule: Rule, value: string) => {
  // if (Number(value) <= Number(props.productRequest.start_price)) {
  //   isBlockBtn.value = true
  //   return Promise.reject('Ставка должна быть больше, чем стартовая цена')
  // } else if (Number(value) <= Number(priceWithVat.value) && isCustomer?.value) {
  //   isBlockBtn.value = true
  //   return Promise.reject('Ставка должна быть больше, чем предыдущая ставка')
  // }
  if (Number(value) >= 999999999) {
      isBlockBtn.value = true
      return Promise.reject('Слишком большая ставка')
  } else {
    isBlockBtn.value = false
    return Promise.resolve()
  }
}

const calcStartPrice = () => {
  if (
    props.productRequest.start_price &&
    props.productRequest.farmer_product?.measurement_unit_id
  ) {
    return `${roundNumber(props.productRequest.start_price)} ${RUBLE}/${props.productRequest.farmer_product?.measurement_unit_id}`
  } else if (props.productRequest.start_price)
    return `${roundNumber(props.productRequest.start_price)} ${RUBLE}`
  else return '-'
}
</script>

<template>
  <Drawer
    :open="open"
    :width="500"
    :footer-style="{ padding: '24px 32px' }"
    @close="props.onClose"
    detroyOnClose
    class="drawer-reverse-header"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
  >
    <template #title>
      <Space :size="0" direction="vertical">
        <TpTitle :level="5">
          {{ props.productRequest.farmer_product_name }}
        </TpTitle>
        <Button
          type="link"
          @click="isOpenProductDrawer = true"
          class="remove-paddings"
        >
          <template #icon>
            <SelectOutlined />
          </template>
          Открыть страницу товара
        </Button>
      </Space>
    </template>
    <Flex class="body-container" :gap="40" vertical>
      <Row>
        <Col :span="24">
          <ProductProperty
            propertyName="Объём"
            :propertyValue="`${productRequest.volume} ${productRequest.farmer_product?.measurement_unit_id}`"
          />
          <ProductProperty
            v-if="productRequest.remaining_shelf_life"
            propertyName="Остаточный срок хранения"
            :propertyValue="`${productRequest.remaining_shelf_life}%`"
            :is-last="!productRequest.is_sample_requested"
          />
          <ProductProperty
            v-if="productRequest.is_sample_requested"
            propertyName="Пробный образец"
            :propertyValue="`${productRequest.requested_samples_number ? `${productRequest.requested_samples_number} ${productRequest.farmer_product?.measurement_unit_id}` : 'Любой объем'}`"
            :is-last="true"
          />
        </Col>
      </Row>
      <div class="best-bet__wrapper">
        <Flex justify="space-between" v-if="props.productRequest?.best_bet">
          <TpText>Лучшая ставка</TpText>
          <Flex align="flex-end" vertical>
            <TpText>{{ bestBet }}</TpText>
            <TpText type="secondary">{{
              `НДС ${props.productRequest.vat}%`
            }}</TpText>
          </Flex>
        </Flex>
        <Divider v-if="props.productRequest?.best_bet" />
        <Flex justify="space-between">
          <TpText>Стартовая цена</TpText>
          <Flex align="flex-end" vertical>
            <TpText>{{ calcStartPrice() }}</TpText>
            <TpText type="secondary">{{
              `НДС ${props.productRequest.vat}%`
            }}</TpText>
          </Flex>
        </Flex>
      </div>
      <Flex :gap="24" vertical>
        <TpTitle :level="5" :style="{ marginBottom: '4px' }"
          >Ваша ставка</TpTitle
        >

        <Form
          layout="vertical"
          ref="formRef"
          :model="formState"
          :preserve="false"
        >
          <Row :gutter="20">
            <Col :span="14">
              <FormItem label="Цена без НДС" name="price_per_unit">
                <InputNumber
                  size="large"
                  v-model:value="formState.price_per_unit"
                  :step="0.01"
                  decimalSeparator=","
                  :controls="false"
                  disabled
                >
                  <template #addonAfter>
                    {{
                      props.productRequest?.farmer_product?.measurement_unit_id
                        ? `₽/${props.productRequest?.farmer_product?.measurement_unit_id}`
                        : '₽'
                    }}
                  </template>
                </InputNumber>
              </FormItem>
            </Col>
            <Col :span="10">
              <FormItem label="НДС" name="vat">
                <InputNumber
                  v-model:value="formState.vat"
                  size="large"
                  :controls="false"
                  disabled
                  addon-after="%"
                />
              </FormItem>
            </Col>
            <Col :span="24">
              <FormItem
                label="Цена с НДС"
                name="price_with_vat"
                :rules="[
                  {
                    required: true,
                    message: 'Обязательное поле',
                  },
                  {
                    validator: moreThanStartPrice,
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  v-model:value="formState.price_with_vat"
                  size="large"
                  :controls="false"
                  :style="{ width: '100%' }"
                  :step="0.01"
                  decimalSeparator=","
                  @blur="
                    formState.price_with_vat = roundNumber(
                      formState.price_with_vat,
                    )
                  "
                >
                  <template #addonAfter>
                    {{
                      productRequest?.farmer_product?.measurement_unit_id
                        ? `₽/${productRequest?.farmer_product?.measurement_unit_id}`
                        : '₽'
                    }}
                  </template>
                </InputNumber>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Flex>
    </Flex>
    <template #footer>
      <Flex :gap="4" justify="flex-end">
        <Button size="large" style="margin-right: 8px" @click="onClose"
          >Закрыть</Button
        >
        <Button
          :disabled="isDisabledCreateBet"
          type="primary"
          size="large"
          @click="handleCreateBet"
          :loading="creatingBet"
          >Сделать ставку</Button
        >
      </Flex>
    </template>

    <DrawerForm
      :product="productRequest.farmer_product"
      @editProduct="
        router.push({
          path: '/edit-product/' + productRequest.farmer_product?.id,
        })
      "
      @editSpecialPrices="
        router.push({
          path: '/edit-special-prices/' + productRequest.farmer_product?.id,
        })
      "
      @editPriceOptions="
        router.push({
          path: '/edit-price-options/' + productRequest.farmer_product?.id,
        })
      "
      @closeDrawer="isOpenProductDrawer = false"
      :isFarmer="false"
      :selfDeleting="true"
      :open="isOpenProductDrawer"
    />
  </Drawer>

  <Modal
    v-model:open="isOpenModal"
    title="Ваша ставка не будет лучшей"
    okText="Продолжить"
    cancelText="Изменить ставку"
    :onOk="() => createBet(roundNumber(Number(formState.price_with_vat)))"
    :okButtonProps="{
      loading: creatingBet,
    }"
    centered
  >
    <div class="modal_body">
      <TpText>Вы можете продолжить или изменить ставку</TpText>
    </div>
  </Modal>

  <Modal
    v-model:open="isProductDeleted"
    title="Невозможно сделать ставку, товар был удален"
    okText="Закрыть"
    closable
    width="460px"
    :cancelButtonProps="{
      hidden: true,
    }"
    @cancel="closeAndRefetch"
    :okButtonProps="{
      loading: creatingBet,
    }"
    centered
  >
    <div class="modal_body">
      <TpText>{{ props.productRequest.farmer_product_name }}</TpText>
    </div>
    <template #footer>
      <Button type="primary" @click="closeAndRefetch"> Закрыть </Button>
    </template>
  </Modal>

  <Modal
    v-model:open="isAuctionClosed"
    title="Торг на продажу был закрыт"
    okText="Закрыть"
    closable
    width="460px"
    :cancelButtonProps="{
      hidden: true,
    }"
    @cancel="closeAndRefetch"
    :okButtonProps="{
      loading: creatingBet,
    }"
    centered
  >
    <div class="modal_body">
      <TpText>К сожалению, время торга на продажу истекло</TpText>
    </div>
    <template #footer>
      <Button type="primary" @click="closeAndRefetch"> Закрыть </Button>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.body-container {
  padding: 0 8px;

  .best-bet__wrapper {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 16px 20px;
  }
}

:deep(.ant-input-number, ) {
  width: 100%;
}
:deep(.ant-form-item) {
  margin-bottom: 20px;
}

:deep(.ant-divider-horizontal) {
  margin: 8px 0;
}
.remove-paddings {
  padding-right: 0;
  padding-left: 0;
}
</style>
