<script setup lang="ts">
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import { Flex, Space, TypographyText as TgText } from 'ant-design-vue'
import { palletizing_type } from '@/helpers/dictionary'
import { InboxOutlined } from '@ant-design/icons-vue'
import { IOrder } from '@/types/interfaces'
import { computed } from 'vue'
import { is_orders_has_difference } from '@/helpers/scripts'
import { NewGeneralOrderStatus } from '@/types/enums'

interface IProps {
  order: IOrder
  history: IOrder | null
}

const props = defineProps<IProps>()

const isShowServices = computed(() => {
  if (
    orderWasEditedByFarmer.value &&
    (props.order?.palletizing_type !== null || props.order?.need_unload)
  ) {
    return (
      props.order.history[props.order.history.length - 1]?.old_entity
        .palletizing_type !== null ||
      props.order.history[props.order.history.length - 1]?.old_entity
        .need_unload
    )
  } else if (!props.history) {
    return props.order?.palletizing_type !== null || props.order?.need_unload
  } else {
    return (
      props.order.palletizing_type !== null ||
      props.history?.palletizing_type !== null ||
      (props.history?.need_unload && props.order?.need_unload)
    )
  }
})

const isServicesChanged = computed(() => {
  return (
    props.history &&
    (props.order.palletizing_type !== props.history?.palletizing_type ||
      props.order.need_unload !== props.history?.need_unload)
  )
})

const oldText = computed(() => {
  const result = []
  isServicesChanged &&
    result.push(
      palletizing_type[props.history?.palletizing_type] ?? 'Без паллетирования',
    )

  isServicesChanged && props.history?.need_unload
    ? result.push('Разгрузка товара поставщиком')
    : result.push('Без разгрузки товара поставщиком')

  return result
})

const newText = computed(() => {
  const result = []
  props.order?.palletizing_type === 'euro' &&
    result.push(palletizing_type.standard)

  palletizing_type[props.order?.palletizing_type] &&
    result.push(palletizing_type[props.order?.palletizing_type])

  props.order?.need_unload && result.push('Разгрузка товара поставщиком')

  !result.length && result.push('Без доп. услуг')
  return result
})

const orderWasEditedByFarmer = computed(() => {
  const orderWasEdited =
    props.order.history[props.order.history.length - 1]?.initiator_type ===
      'farmer' && is_orders_has_difference(props.order, props.history)

  return orderWasEdited && props.order.status === NewGeneralOrderStatus.New
})
</script>

<template>
  <Space :size="6" align="start" v-if="isShowServices">
    <InboxOutlined :style="iconStyle" />
    <Flex :gap="4" vertical>
      <TextWithOldPopover
        v-if="isServicesChanged && !orderWasEditedByFarmer"
        :text="newText"
        :old_text="oldText"
      />

      <template v-else-if="orderWasEditedByFarmer">
        <TgText
          v-if="
            props.order.history[props.order.history.length - 1]?.old_entity
              .palletizing_type === 'standard'
          "
        >
          {{ palletizing_type.standard }}
        </TgText>
        <template
          v-if="
            props.order.history[props.order.history.length - 1]?.old_entity
              .palletizing_type === 'euro'
          "
        >
          <TgText> {{ palletizing_type.standard }}</TgText>
          <TgText> {{ palletizing_type.euro }}</TgText>
        </template>
        <TgText
          v-if="
            props.order.history[props.order.history.length - 1]?.old_entity
              .need_unload
          "
        >
          Разгрузка товара поставщиком
        </TgText>
      </template>

      <template v-else>
        <TgText v-if="order.palletizing_type === 'standard'">
          {{ palletizing_type.standard }}
        </TgText>
        <template v-if="order.palletizing_type === 'euro'">
          <TgText> {{ palletizing_type.standard }}</TgText>
          <TgText> {{ palletizing_type.euro }}</TgText>
        </template>
        <TgText v-if="order.need_unload"> Разгрузка товара поставщиком </TgText>
      </template>
    </Flex>
  </Space>
</template>

<style scoped lang="scss"></style>
