<script lang="ts">
import { defineComponent } from 'vue'

export type TStatusTypes = 'Success' | 'Error' | 'Warning'

export default defineComponent({
  name: 'CustomerRequestSubheaderAnswers',
  props: {
    status: {
      type: String as () => TStatusTypes,
      default: 'Success',
    },
    total: {
      type: Number,
      default: 0,
    },
    answered: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    getStatusName(status: string) {
      const statusNames = {
        Success: 'Ответили',
        Error: 'Отказали',
        Warning: 'Не ответили',
      }
      return statusNames[status] || 'unknown'
    },
  },
})
</script>

<template>
  <div class="customer-request-subheader_answers">
    <img v-if="status === 'Success'" src="./icons/success.svg" alt="icon" loading="lazy" />
    <img v-if="status === 'Warning'" src="./icons/warning.svg" alt="icon" loading="lazy" />
    <img v-if="status === 'Error'" src="./icons/error.svg" alt="icon" loading="lazy" />
    <div>{{ getStatusName(status) }} {{ answered }}/{{ total }}</div>
  </div>
</template>

<style lang="scss">
.customer-request-subheader_answers {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 5px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000e0;
}
</style>
