import {ICategoryOptions} from "@/components/Home/NotAuthHome/interfaces";
import {notification} from "ant-design-vue";
import {h} from "vue";
import {InfoCircleFilled} from "@ant-design/icons-vue";

export const sortByAlphabet = (array: ICategoryOptions[]) => {
    return array.sort((a, b) => a.label.localeCompare(b.label))
}

export const showNotification = async () => {
    const userData = localStorage.getItem('userData') || ''
    const { name, legal_form } = await JSON.parse(userData)
    notification.info({
        message: 'Добро пожаловать',
        description: `Вы успешно зарегистрировались в системе от компании ${legal_form} ${name}`,
        top: '80px',
        style: { width: '420px' },
        icon: h(InfoCircleFilled, {
            style: { color: 'rgba(22, 119, 255, 1)' },
        }),
    })
}
