<script lang="ts" setup>
import Popper from 'vue3-popper'

const props = defineProps({
  title: { type: String, default: '' },
  message: { type: String, default: '' },
  show: { type: Boolean, default: true },
  showPopper: { type: Boolean, default: true },
  type: { type: String, default: '' },
  hover: { type: Boolean, default: true },
  width: { type: String, default: '' },
  placement: { type: String, default: 'bottom' },
  withoutFlex: { type: Boolean, default: false },
})

const emit = defineEmits([''])
// Не работает! Не использовать!

</script>

<template>
  <Popper
    v-if="hover"
    :class="props?.type"
    :hover="hover"
    :placement="placement"
    arrow
  >
    <div class="tooltip__content">
      <slot name="default"></slot>
    </div>
    <template #content v-if="showPopper">
      <p v-if="title" class="tooltip__title">{{ title }}</p>

      <p class="tooltip__message" :style="{ width: props.width }">
        <slot name="icon" />
        {{ message }}
      </p>
    </template>
  </Popper>
  <Popper
    v-else
    :class="props?.type"
    :placement="placement"
    :show="showPopper"
    arrow
  >
    <div
      class="tooltip__content"
      :style="withoutFlex ? { display: 'block' } : {}"
    >
      <slot name="default"></slot>
    </div>
    <template #content v-if="showPopper">
      <p v-if="title" class="tooltip__title">{{ title }}</p>

      <p class="tooltip__message" :style="{ width: props.width }">
        <slot name="icon" />
        {{ message }}
      </p>
    </template>
  </Popper>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.primary {
  :deep(.popper) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    background: #0066ff;
    box-shadow:
      0 10px 16px rgba(20, 37, 63, 0.06),
      0 0 4px rgba(12, 26, 75, 0.1);
    border-radius: 8px;
  }

  :deep(.popper #arrow::before) {
    background: #0066ff !important;
  }

  :deep(.popper:hover),
  :deep(.popper:hover #arrow::before) {
    background: #0066ff !important;
  }
}

.grey {
  :deep(.popper) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;
    background: #2d3748;
    box-shadow:
      0 0 1px rgba(12, 26, 75, 0.1),
      0 10px 16px rgba(20, 37, 63, 0.06);
    border-radius: 8px;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    max-height: 41px;
    font-style: normal;
  }

  :deep(.popper #arrow::before) {
    background: #2d3748;
  }

  :deep(.popper:hover),
  :deep(.popper:hover .arrow::before) {
    background: #2d3748;
  }
}

.tooltip__content {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-bottom: 0;
    width: 23px;
    height: 23px;
    margin-right: 5px;
  }
}

.tooltip {
  font-size: 14px;
  line-height: 17px;
  color: white;

  &__title {
    font-weight: 700;
    margin-bottom: 4px;
  }
  &__message {
    font-weight: 400;
  }
}
</style>
