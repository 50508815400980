<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import CartFirstStep from '@/components/Cart/CartFirstStep.vue'
import {
  clearPriceWithVat,
  getNextValueInPriceOptions,
  getPriceOptionByQuantity,
  getUserIdTemporary,
  priceWithoutVat,
  roundNumber,
} from '@/helpers/scripts'
import { useOrderStore } from '@/store/orderStore'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { storeToRefs } from 'pinia'
import {
  ICartData,
  ICartItem,
  IFarmer,
  IFarmerProduct,
} from '@/types/interfaces'
import { useRouter } from 'vue-router'
import CartEmpty from '@/components/Cart/CartEmpty.vue'
import { CartAPI } from '@/api/CartAPI'
import { CreateOrderType } from '@/types/types'
import { Space } from 'ant-design-vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import OrderTotalSidebar from '@/components/OrderTotalSidebar/OrderTotalSidebar.vue'
import {
  IOrderFarmer,
  IOrderPriceOption,
  IProductItem,
  IRegistrationOrderData,
  registrationOrderStore,
} from '@/store/registrationOrderStore'
import dayjs from 'dayjs'
import { PartnersAPI } from '@/api/PartnersAPI'
import { useCartStore } from '@/store/cartStore'
import emitter from "@/emitter";

const router = useRouter()

const cartData = ref<ICartData[]>([])
const notAvailableCartData = ref<ICartData[]>([])
const createOrderTooltip = ref<{ title: string; description: string } | null>(
  null,
)

const orderStore = useOrderStore()
const cartStore = useCartStore()
const { fillOrderData } = registrationOrderStore()

const itemErrors = computed(() => cartStore.itemErrors)

const isCreateOrderBtnDisabled = computed(() => {
  return itemErrors.value.some((error) => error.errors?.includes('quantity'))
})

const buttonTooltipText = computed(() => {
  if (isCreateOrderBtnDisabled.value) {
    return {
      title:
        'Объём некоторых товаров меньше минимального объёма заказа, установленного поставщиком.',
      description:
        'Пожалуйста, увеличьте объём этих товаров в заказе до минимально необходимого или удалите товары из заказа.',
    }
  }
  return null
})

const quantityError = ref('')

const updateItemQuantity = (() => {
  // eslint-disable-next-line no-undef
  let timer: any = null

  return async (farmId: number, amount: number): Promise<void> => {
    quantityError.value = ''

    clearTimeout(timer)

    timer = setTimeout(async () => {
      try {
        await CartAPI.updateCartItem(farmId, amount)
      } catch (e: any) {
        quantityError.value = e.message
      }
      updateCartItemQuantity(farmId, amount)
    }, 1000)
  }
})()

const updateCartItemQuantity = (id: number, quantity: number) => {
  cartData.value.forEach((cart) => {
    cart.cart_items.forEach((item) => {
      if (+item.id === id) {
        item.quantity = quantity.toString()
      }
    })
  })
}

const { getOrdersWithValueErrorAndChecked: valueError } =
  storeToRefs(orderStore)

const deleteItem = async (item: number) => {
  await orderStore.deleteCartItemStore(item)
  cartData.value = cartData.value
    .map((data: ICartData) => {
      return {
        ...data,
        cart_items: data.cart_items.filter(
          (cartItem: ICartItem) => +cartItem.farmer_product_id !== item,
        ),
      }
    })
    .filter((data) => data.cart_items.length)
}
const deleteAll = async () => {
  let ids: string[] = []
  cartData.value.forEach((farmer) => {
    farmer.cart_items.forEach((item) => {
      ids.push(item.id)
    })
  })

  notAvailableCartData.value.forEach((farmer) => {
    farmer.cart_items.forEach((item) => {
      ids.push(item.id)
    })
  })

  await orderStore.deleteManyCartItemsStore(ids)
  cartData.value = []
  notAvailableCartData.value = []
}

const isFetching = ref(false)
const isQuantityChanged = ref(false)

const createOrder = async ({
  address,
  comment,
  delivery_date,
  palletizing_type,
  payment_type,
  is_payment_delayed,
  payment_delay_days,
  delivery_payment,
  delivery_method,
  need_unload,
  contact,
  farmers,
}: IRegistrationOrderData) => {
  const isSelfDelivery = delivery_method === 'Самовывоз'
  const farmersData = farmers.map(({ id, can_deliver }: IFarmer) => ({
    id,
    delivery_method: isSelfDelivery
      ? delivery_method
      : can_deliver
        ? 'Доставка фермером'
        : 'Самовывоз',
  }))

  const cartOrderData: CreateOrderType = {
    comment: comment ?? '',
    address: isSelfDelivery ? undefined : address?.id,
    delivery_date: dayjs(delivery_date).format('YYYY-MM-DD'),
    palletizing_type,
    payment_type,
    is_payment_delayed,
    payment_delay_days,
    delivery_payment: isSelfDelivery ? undefined : delivery_payment,
    need_unload,
    contact_id: contact?.id,
    farmers: farmersData,
  }

  try {
    const res = await CartAPI.createOrderFromCart(cartOrderData)
    await orderStore.getCartAmountStore()

    return {
      error: null,
      orderId: res.id,
    }
  } catch (err: any) {
    return {
      error: err.message,
      orderId: null,
    }
  }
}
const validateFirstStep = async () => {
  return !(await CartAPI.checkItems()).length
}

const toRegistrationOrder = async () => {
  if (await validateFirstStep()) {
    let maxPrepareTime = 0

    const partners = await PartnersAPI.getItems(
      [],
      [
        ['customer_id', 'coi', getUserIdTemporary()],
        'AND',
        ['farmer_id', 'in', cartData.value.map(({ id }) => id)],
      ],
    ).then((res) =>
      res.items.map((item) => ({
        farmer_id: item.farmer_id,
        is_partner: item.status === 'signed_customer',
      })),
    )

    const farmers: IOrderFarmer = cartData.value.map(
      ({ cart_items, addresses: address, ...farmer }) => {
        const productItems: IProductItem[] = cart_items.map(
          ({ farmer_product, quantity, id: cartItemId }) => {
            if (farmer_product.prepare_days > maxPrepareTime) {
              maxPrepareTime = farmer_product.prepare_days
            }
            let neededPriceOption: IOrderPriceOption
            if (farmer_product.specialPrice) {
              const specialPrice = farmer_product.specialPrice
              neededPriceOption = {
                price: +specialPrice.price,
                vat_percent: specialPrice.vat_percent,
                isSpecialPrice: true,
              }
            } else {
              const priceOption = getPriceOptionByQuantity(farmer_product.priceOptions, +quantity)
              if (priceOption) {
                neededPriceOption = {
                  price: +priceOption.price,
                  vat_percent: farmer_product.vat_percent,
                  isSpecialPrice: false,
                }
              }
            }

            return {
              quantity: +quantity,
              farmer_product: farmer_product as IFarmerProduct,
              chosen_price_option: neededPriceOption as IOrderPriceOption,
              cartItemId,
            }
          },
        )

        return {
          ...farmer,
          is_partner: !!partners.find(
            (partner) => partner.farmer_id === farmer.id,
          )?.is_partner, // нужно интегрировать запрос
          address: address,
          product_items: productItems,
        }
      },
    )
    fillOrderData(
      createOrder,
      farmers,
      dayjs()
        .add(maxPrepareTime + 1, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
    )
    router.push('create-order')
  }
}

const farmsAddresses = ref<any>([])

const farmersId = ref<string[][]>([])

const totalProductsAmount = computed(() => {
  return (
    cartData.value.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.cart_items.length,
      0,
    ) +
    notAvailableCartData.value?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.cart_items.length,
      0,
    )
  )
})

const invalidIds = ref<string[]>([])

const isAllDelivery = ref(true)
const isAllCanNotDelivery = ref(true)

const getProducts = async () => {
  isFetching.value = true
  cartData.value = []
  notAvailableCartData.value = []
  isQuantityChanged.value = false
  const fetchedValidCartData = await CartAPI.getGroupedItems(false)
  const fetchedInvalidCartData = await CartAPI.getGroupedItems(true, 'OR')

  const formatData = (
    fetchedValue: any,
    targetArray: ICartData[],
    invalid?: boolean,
  ) => {
    fetchedValue.items.forEach((item: ICartData) => {
      const cartDataItem: ICartData = {
        id: item.id,
        name: item.name,
        legal_form: item.legal_form,
        can_deliver: item.can_deliver,
        addresses: {
          id: item.addresses.id,
          contact_number: item.addresses.contact_number,
          street: item.addresses.street,
          name: item.addresses.name,
          comment: item.addresses.comment,
          // coordinates: JSON.parse(item.addresses.coordinates),
          coordinates: item.addresses.coordinates,
          type: item.addresses.type,
          is_deleted: item.addresses.is_deleted,
        },
        cart_items: [],
        company_rating: item.company_rating,
      }
      if (!invalid && !item.can_deliver) isAllDelivery.value = false
      else if (!invalid) isAllCanNotDelivery.value = false
      farmsAddresses.value.push(cartDataItem.addresses)

      item.cart_items.forEach((cartItem: ICartItem) => {
        if (
          cartItem.quantity > cartItem.farmer_product.quantity ||
          cartItem.quantity < cartItem.farmer_product.min_value
        ) {
          isQuantityChanged.value = true
        }

        invalid && invalidIds.value.push(cartItem.id)
        const cartItemData: ICartItem = {
          id: cartItem.id,
          farmer_product_id: cartItem.farmer_product_id,
          quantity: cartItem.quantity,
          farmer_product: {
            farmer_id: cartItem.farmer_product.farmer_id,
            id: cartItem.farmer_product.id,
            price: cartItem.farmer_product.price,
            quantity: cartItem.farmer_product.quantity,
            min_value: cartItem.farmer_product.min_value,
            measurement_unit_id: cartItem.farmer_product.measurement_unit_id,
            prepare_days: cartItem.farmer_product.prepare_days,
            image_url: cartItem.farmer_product.image_url,
            fullName: cartItem.farmer_product.product.fullname,
            is_deleted: cartItem.farmer_product.is_deleted,
            vat_percent: cartItem.farmer_product.vat_percent,
            priceOptions: getNextValueInPriceOptions(
              cartItem.farmer_product.price_options ?? [],
              cartItem.farmer_product.measurement_unit_id,
            ),
            specialPrice: cartItem.farmer_product.special_price,
          },
        }

        cartDataItem.cart_items.push(cartItemData)

        farmersId.value.push([
          'farmer_id',
          'eq',
          cartItem.farmer_product.farmer_id,
        ])
      })
      targetArray.push(cartDataItem)
    })
  }
  // todo когда оставп даст данные
  formatData(fetchedValidCartData, cartData.value)
  formatData(fetchedInvalidCartData, notAvailableCartData.value, true)
  // await getPartners()
  isFetching.value = false
}
const collapsedCards = ref<string[]>([])

emitter.on('refresh-token', async (e) => {
  await getProducts()
})

onMounted(async () => {
  await getProducts()
})

const isNoValidCartData = computed(() => {
  return isOrderUnavailalbe.value
})

const mapUserPoints = computed(() => {
  return farmsAddresses.value
})

const deleteUnavailable = async () => {
  const arr = notAvailableCartData.value.flatMap((item) => {
    return item.cart_items.map((cartItem) => {
      return cartItem.id
    })
  })

  await orderStore.deleteManyCartItemsStore(arr)
  notAvailableCartData.value = []
}

const collapseCard = (id: number) => {
  if (collapsedCards.value.includes(id)) {
    collapsedCards.value = collapsedCards.value.filter((item) => item !== id)
    return
  }
  collapsedCards.value.push(id)
}

const isOrderUnavailalbe = computed(() => {
  return notAvailableCartData.value.length
})

const sideAlerts = computed(() => {
  const messages: string[] = []
  if (notAvailableCartData.value.length && !cartData.value.length) {
    messages.push('Ни один из этих товаров нельзя заказать')
  } else {
    // Ворнинги, разкомментить, если нужно
    // notAvailableCartData.value.length &&
    //   messages.push('Удалите недоступные товары, чтобы оформить заказ')
    // isQuantityChanged.value &&
    //   messages.push(
    //     'Количество товаров автоматически изменится на доступное для заказа',
    //   )
  }
  return messages
})

const priceFarmerList = computed(() => {
  return cartData.value.map((item) => {
    let totalVat = 0
    let totalPrice = 0
    item.cart_items.forEach((cartItem) => {
      let price: string
      let vat_percent: number
      const specialPrice = cartItem.farmer_product.specialPrice
      if (specialPrice) {
        price = specialPrice.price
        vat_percent = specialPrice.vat_percent
      } else {
        price =
          getPriceOptionByQuantity(
            cartItem.farmer_product.priceOptions,
            +cartItem.quantity,
          )?.price || cartItem.farmer_product.price

        vat_percent = cartItem.farmer_product.vat_percent
      }
      totalVat += roundNumber(
        +cartItem.quantity * +priceWithoutVat(+price, vat_percent),
      )
      totalPrice += roundNumber(+cartItem.quantity * +price)
    })

    return {
      farmerName: `${item.legal_form} ${item.name}`,
      id: item.id,
      coordinates: item.addresses.coordinates,
      address: item.addresses.street,
      totalVat: totalPrice - totalVat,
      totalPrice,
    }
  })
})

const isCartEmpty = computed(() => {
  return !cartData.value.length && !notAvailableCartData.value.length
})

</script>

<template>
  <div class="wrapper cart">
    <div v-if="isFetching" class="cart__loader">
      <FullscreenLoading :type="'secondary'" />
    </div>
    <template v-else>
      <div class="cart__content" v-if="!isCartEmpty">
        <CartFirstStep
          :card-data="cartData"
          :notAvailableCartData="notAvailableCartData"
          :totalAmount="totalProductsAmount"
          @delete-unavailable="deleteUnavailable"
          @delete="deleteItem"
          @quantity-update="updateItemQuantity"
          @deleteAll="deleteAll"
          @collapseCard="collapseCard"
          :collapsedCards="collapsedCards"
        />
        <Space :size="8" direction="vertical" class="cart-side-map">
          <OrderTotalSidebar
            btn-text="Перейти к оформлению"
            :is-disabled-btn="!!isNoValidCartData || isCreateOrderBtnDisabled"
            :handle-btn-click="toRegistrationOrder"
            :price-farmer-list="priceFarmerList"
            :alerts="sideAlerts"
            :btn-tooltip="buttonTooltipText"
          />
        </Space>
      </div>

      <div v-else class="cart__empty">
        <CartEmpty
          v-if="!isFetching"
          title="Ваша корзина пуста"
          description="Добавьте в неё товары, чтобы оформить заказ"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  @include wrapper();
  padding-bottom: 40px;
  padding-top: 20px;
}

.side-info {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #00000005;
  &-farmers {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &-farmer {
    padding: 13px 0;
    border-bottom: 1px solid #ececec;
    display: grid;
    grid-template-columns: 66% 1fr;
    column-gap: 8px;
    justify-content: space-between;
  }
  &-farmer-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000e0;
  }
  &-price-block {
    text-align: right;
  }
  &-price {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000000e0;
  }
  &-vat {
    color: #000000a6;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .farmer-price {
    display: flex;
    justify-content: space-between;
    color: $gray-700;
    font-size: 14px;
    border-bottom: 1px solid $gray-300;
    padding: 12px 0;

    .name {
      font-weight: 400;
      line-height: 19px;
    }

    .price {
      font-weight: 700;
      line-height: 17px;
      width: 100px;
      text-align: right;
    }
  }

  .total-price {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    color: $gray-800;
    padding: 24px 0;
    display: flex;
    :deep(.ant-space) {
      text-align: right;
    }
    &-sum {
      font-size: 24px;
      text-align: right;
      font-weight: 700;
      color: #000000e0;
    }
  }
}

.cart {
  &__content {
    display: flex;
    justify-content: center;
    gap: 32px;
    width: 100%;

    :deep(.popper) {
      background-color: $warning;
      color: #ffffff;
      border-radius: 8px;
      padding: 12px;
    }

    //:deep(.popper .arrow::before) {
    //  background-color: $warning;
    //}

    :deep(.tooltip__message) {
      display: flex;
      column-gap: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__empty {
    padding-top: 32px;

    .cart__loader {
      @include empty();
    }
  }
  &-side-map {
    max-width: 448px;
    min-width: 448px;
    position: sticky;
    top: 100px;
  }
  &__order {
    border: 1px solid $gray-300;
    border-radius: 20px;
    margin-top: 64px;
    height: fit-content;
    display: grid;
    overflow: hidden;

    :deep(.popper) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__farms-list {
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-bottom: 1px solid $gray-300;
  }

  &__farm-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
  }

  &__item-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
    color: $gray-600;

    .amount {
      font-weight: 400;
    }

    .price {
      font-weight: 700;
    }

    .price-cross {
      text-decoration: line-through;
    }
  }

  &__item-can-delivery {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 0 5px;
    width: fit-content;
    height: 25px;
    background: #ef874d;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;

    .summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      line-height: 29px;
      color: $gray-800;

      &__text {
        font-weight: 500;
      }

      &__price {
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1200px) {
    .summary {
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>
