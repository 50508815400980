<script lang="ts" setup>
import { outlineGrayButton } from '@/assets/EgalStyles/EButton'
import {Button} from 'ant-design-vue'
import { onMounted, ref } from 'vue'
import { useStore } from '@/store'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import { chatStore } from '@/store/chatStore'
import { RouteParamValue } from 'vue-router'
import router from '@/router'
import { FarmerAPI } from '@/api/FarmerAPI'
import { storeToRefs } from 'pinia'
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

interface CreateHeaderProps {
  title: string
  company?: any
  subtitle?: string
  isProduct?: boolean
  isShowCreateChatButton: boolean
  farmer_id?: number
}

const { isVerification } = storeToRefs(useStore())
const { createChat, setFarmerUIDs } = chatStore()

const props = defineProps<CreateHeaderProps>()
const emit = defineEmits(['button-click'])

const getUserName = () => {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const { user_need_accept, name, legal_form } = JSON.parse(userData)
    if (user_need_accept !== null) {
      return `${name}`
    } else {
      return `${legal_form} ${name}`
    }
  }
}

const handleClickChat = () => {
  const company1 = `${props.company.legal_form} ${props.company.name}`
  const company2 = getUserName()

  const calcNameChat = () => {
    if (company1 && company2) {
      return company2 + ' - ' + company1
    } else if (company1) {
      return company1
    } else if (company2) {
      return company2
    } else {
      return ''
    }
  }

  const name = calcNameChat()
  createChat(name)
}

const farmUsers = ref({})

const getFarmCard = async (id: string | RouteParamValue[]) => {
  await FarmerAPI.getFarmer(Number(id), ['users']).then((res) => {
    farmUsers.value = res?.users
  })
}

onMounted(async () => {
  if (props?.company?.id || props.farmer_id || router.currentRoute.value?.params?.id) {
    await getFarmCard(props?.company?.id || props?.farmer_id || router.currentRoute.value?.params?.id)
    setFarmerUIDs(
      props?.company?.users?.length
        ? props?.company?.users?.map((user: any) => user.id)
        : farmUsers?.value?.map((user: any) => user.id),
    )
  }
})
</script>

<template>
  <div class="products__header">
    <Button :style="outlineGrayButton" class="back" @click="emit('button-click')">
      <ChevronLeftIcon />
    </Button>
    <BlurTooltip v-if="!isVerification && !isProduct">
      <div
        class="products__title"
        :class="!isVerification && !isProduct ? 'blur blur-height' : ''"
      >
        {{ title }}
      </div>
    </BlurTooltip>
    <div
      v-else
      class="products__title"
      :class="!isVerification && !isProduct ? 'blur blur-height' : ''"
    >
      {{ title }}
      <p v-if="subtitle" class="products__subtitle">
        {{ subtitle }}
      </p>
    </div>
    <EButton
      v-if="props.isShowCreateChatButton"
      class="products__header-button"
      @click="handleClickChat"
    >
      Открыть чат
    </EButton>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.back {
  padding-left: 10px;
  padding-right: 10px;
}

.products {
  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;

    .egal-button--md {
      padding: 12px;
      .bi {
        stroke: #000000e0 !important;
      }
    }
    .chevron-left {
      color: $gray-800;
      stroke: none;
    }
    .blur {
      @include blur();
      @include no-select();
    }
    .blur-height {
      filter: blur(7px);
      @include no-select();
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: $gray-800;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}
</style>
