<script setup lang="ts">
import { Button, Modal, notification } from 'ant-design-vue'
import router from '@/router'
import { IProductAnswer } from '@/types/interfaces'
import { computed, h, ref } from 'vue'
import { RecipientAPI } from '@/api/RecipientAPI'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { RecipientStatus } from '@/types/types'

interface Props {
  productAnswers: IProductAnswer[]
  recipientId: number
  recipientStatus: RecipientStatus
}

const emit = defineEmits(['sendAnswer'])
const props = defineProps<Props>()

const isOpenRefuseModal = ref<boolean>(false)
const isOpenSendAnswerModal = ref<boolean>(false)

const isDisabledSendAnswer = computed(() => {
  return (
    props.productAnswers.every(
      (product) => product.status !== 'answer_received',
    ) ||
    !props.productAnswers.every(
      (product) => product.status !== 'answer_waiting',
    )
  )
})

const handleSendAnswer = () => {
  RecipientAPI.update({
    id: props.recipientId,
    answer_status: 'answered',
  }).then(() => {
    router.push('/requests')
    emit('sendAnswer')
    notification.info({
      message: undefined,
      description: 'Ответ на запрос отправлен',
      top: '80px',
      icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
    })
  })
}

const handleRefused = () => {
  RecipientAPI.update({
    id: props.recipientId,
    answer_status: 'refused',
  }).then(() => {
    emit('sendAnswer')
    router.push('/requests')
    notification.info({
      message: undefined,
      description: 'Вы отказались от запроса',
      top: '80px',
      icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
    })
  })
}

const openRefusingModal = () => {
  isOpenRefuseModal.value = true
}

const openSendAnswerModal = () => {
  isOpenSendAnswerModal.value = true
}

const handleReturn = () => {
  router.push('/requests')
}
</script>

<template>
  <div class="footer-wrapper">
    <div class="footer">
      <template v-if="props.recipientStatus !== 'answered'"
        ><span class="footer__caption"
          >Укажите цены и условия поставки, чтобы отправить запрос</span
        >
        <div class="footer__btns-block">
          <Button @click="openRefusingModal" danger>Отказаться</Button>
          <Button
            @click="openSendAnswerModal"
            :disabled="isDisabledSendAnswer"
            type="primary"
            >Отправить ответ
          </Button>
        </div>
      </template>
      <div class="footer__btn-return" v-else>
        <Button type="default" :onclick="handleReturn"
          >Вернуться к списку запросов
        </Button>
      </div>

      <Modal
        width="440px"
        centered
        title="Отказаться от запроса?"
        v-model:visible="isOpenRefuseModal"
        cancelText="Закрыть"
        okText="Отказаться"
        :onCansel="() => (isOpenRefuseModal = false)"
        :onOk="handleRefused"
      >
        <div class="modal__content">Внесённые данные будут удалены</div>
      </Modal>

      <Modal
        width="440px"
        centered
        title="Отправить ответ или внести изменения?"
        v-model:visible="isOpenSendAnswerModal"
        cancelText="Внести изменения"
        okText="Отправить"
        :onCansel="() => (isOpenSendAnswerModal = false)"
        :onOk="handleSendAnswer"
      >
        <div class="modal__content">
          Пожалуйста, проверьте состав запроса и условия поставки
        </div>
      </Modal>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.footer-wrapper {
  position: fixed;
  align-items: center;
  width: 100%;
  display: flex;
  height: 80px;
  bottom: 0;
}
.footer {
  @include wrapper();
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.059);
  background: white;
  box-sizing: border-box;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  margin: 0;
  align-items: center;
  &__caption {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
  }

  &__btns-block {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__btn-return {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.modal__content {
  width: 280px;
  margin-bottom: 24px !important;
}
</style>
