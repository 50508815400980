<script setup lang="ts">
import { onMounted, ref } from 'vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { storeToRefs } from 'pinia'
import { auctionStore } from '@/store/auctionStore'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import AuctionHeader from '@/views/Auctions/components/AuctionHeader/AuctionHeader.vue'
import AuctionTable from '@/views/Auctions/components/AuctionTable/AuctionTable.vue'
import { getUserIdTemporary } from '@/helpers/scripts'
import AboutAuctionModal from '@/views/Auctions/components/AboutAuctionModal.vue'
import emitter from "@/emitter";

const { auctions, isFetching, isMoreFetching } = storeToRefs(auctionStore())

const initAuctions = async () => {
  auctionStore().setDefaultFilters([])
  await auctionStore().getAuctions()
}

emitter.on('refresh-token', async (e) => {
  await initAuctions()
})

onMounted(async () => {
  await initAuctions()
})

const isOpenAboutAuctionModal = ref(false)

const closeAboutAuctionModal = () => {
  isOpenAboutAuctionModal.value = false
}

const openAboutAuctionModal = () => {
  isOpenAboutAuctionModal.value = true
}
</script>

<template>
  <div class="customer-auctions-view">
    <FullscreenLoading
      :type="'secondary'"
      v-if="isFetching"
      class="initial-loader"
    />
    <template v-else>
      <AuctionHeader
        v-if="!!auctions.length"
        title="Торги на продажу"
        button-text="О торгах на продажу"
        @onBtnClick="openAboutAuctionModal"
        :is-list="true"
        :on-back-action="() => {}"
        buttonType="default"
      />
      <AuctionTable
        v-if="auctions.length"
        :table-data="auctions"
        :isMoreFetching="isMoreFetching"
      />
      <EmptyView
        v-if="!auctions.length"
        title="Список торгов на продажу пуст"
        button-text="О торгах на продажу"
        description="Здесь вы можете отслеживать и участвовать в торгах на продажу"
        :is-show-button="true"
        @onBtnClick="openAboutAuctionModal"
        :button-type="'default'"
      />
      <AboutAuctionModal
        :open="isOpenAboutAuctionModal"
        @closeAboutAuctionModal="closeAboutAuctionModal"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.customer-auctions-view {
  display: flex;
  flex-direction: column;
  position: relative;
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
