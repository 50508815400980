<script lang="ts" setup>
import { computed } from 'vue'
import { useCookies } from 'vue3-cookies'
import NotAuthHome from './NotAuthHome/index.vue'
import CustomerHome from './CustomerHome.vue'

const { cookies } = useCookies()
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => umt && chat)
</script>

<template>
  <div class="wrapper">
    <div class="container-wrapper">
      <CustomerHome v-if="isAuth" />
      <NotAuthHome v-if="!isAuth" />
    </div>
  </div>
</template>

<style>
html {
  scrollbar-width: none;
}
</style>

<style lang="scss" scoped>
@use '@/assets/style/variables';
@import '@/assets/style/mixins.scss';

.wrapper {
  margin: 0 -24px 0 -24px !important;
  width: 100vw !important;
  background-color: #ffffff;
}

.container-wrapper {
  height: 100%;
  width: 100vw;
}

/* .container {
  display: flex;
  min-height: 100%;
  width: 100vw;
  padding-left: 20px;
  height: calc(100vh - 73px);
  flex-direction: row;
  &--empty {
    @include empty();
  }
}

.container-not-auth {
  display: flex;
  min-height: 100%;
  width: 100vw;
  padding-left: 20px;
  &--empty {
    @include empty();
  }
} */
</style>
