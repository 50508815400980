<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import FarmCard from '@/components/Customer/FarmCard.vue'
import router from '@/router'
import {RouteParamValue, useRoute} from 'vue-router'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { FarmerAPI } from '@/api/FarmerAPI'
import { IFarmer } from '@/types/interfaces'
import emitter from "@/emitter";

const farmCard = ref<null | IFarmer>(null)
const isFetching = ref(true)

const getFarmCard = async (id: string | RouteParamValue[]) => {
  const resData = await FarmerAPI.getFarmersWithCategories(['users', 'company_rating'])
  farmCard.value = resData.items.find((el) => el.id === Number(id))
}

const closeFarmCard = () => router.go(-1)

const initFarmCardView = async () => {
  isFetching.value = true
  await getFarmCard(router.currentRoute.value.params.id)
  isFetching.value = false
}

emitter.on('refresh-token', async (e) => {
  await initFarmCardView()
})

onMounted(async () => {
  await initFarmCardView()
})

const route = useRoute()
</script>

<template>
  <FarmCard
    :farm="farmCard"
    :farmerId="String(router.currentRoute.value.params.id)"
    @back="closeFarmCard"
    v-if="!isFetching"
  />
  <div class="loader-wrapper" v-else>
    <FullscreenLoading :type="'secondary'" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.loader-wrapper {
  position: relative;
  min-height: 70vh;
}
</style>
