<script setup lang="ts">
import CloseModalButton from '../../assets/img/close-modal-button.svg'

const props = defineProps({
  show: Boolean,
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close', 'close-icon'])

const close = () => {
  document.body.style.overflowY = 'auto'
  emit('close')
}

const closeWithButton = () => {
  close()
  emit('close-icon')
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click="close">
      <div class="modal-wrapper">
        <div class="modal-container" ref="modal" id="modal">
          <div class="wrapper" @click.stop>
            <div class="modal-body">
              <div class="modal-body__close" v-if="showCloseIcon">
                <img
                  class="modal-body__close-image"
                  :src="CloseModalButton"
                  alt="close"
                  @click="closeWithButton"
                  loading="lazy"
                />
              </div>
              <slot name="body" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  position: relative;
  &__close {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    cursor: pointer;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  //  -webkit-transform: scale(1.1);
  //  transform: scale(1.1);
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

@media (max-width: 500px) {
  .modal-container {
    width: 100%;
    padding: 30px 20px;
    height: 100vh;
    overflow-y: auto;
    border-radius: 0;
  }

  .modal-header {
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
