<script setup lang="ts">
import UserStatusTag from '@/components/User/UserStatusTag.vue'
import { Button, Flex, Space } from 'ant-design-vue'
import {
  CheckOutlined,
  FileOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue'
import { ICompanyView } from '@/types/interfaces'
import router from '@/router'

interface IProps {
  company_name: string
  company: ICompanyView | null
}

const props = defineProps<IProps>()

const emit = defineEmits(['verify', 'show_docs'])

const to_company_orders = () => {
  router.push({
    path: '/admin/orders',
    query: {
      type: String(props.company?.company_type),
      company_id: String(props.company?.company_id),
    },
    replace: true,
  })
}
</script>

<template>
  <Flex justify="space-between" :style="{ marginBottom: '12px' }">
    <Space :size="8"
      ><h4>{{ company_name }}</h4>
      <UserStatusTag
        v-if="company?.status"
        :status="company?.status || 'not_confirmed'"
    /></Space>
    <Space :size="12">
      <Button v-if="company?.status === 'active'" @click="to_company_orders">
        <template #icon><UnorderedListOutlined /></template>
        Список заказов
      </Button>
      <Button
        v-if="company?.status === 'active' || company?.status === 'need_verify'"
        @click="emit('show_docs')"
      >
        <template #icon><FileOutlined /></template>
        Документы
      </Button>
      <Button
        type="primary"
        v-if="company?.status === 'need_verify'"
        @click="emit('verify')"
      >
        <template #icon><CheckOutlined /></template>
        Верифицировать
      </Button>
    </Space>
  </Flex>
</template>

<style scoped lang="scss"></style>
