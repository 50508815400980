<script setup lang="ts">
import { ref } from 'vue'
import CustomerInfoBlock from '@/views/Farmer/response/toolbarWidget/CustomerInfoBlock.vue'
import { Button } from 'ant-design-vue'
import { RightOutlined } from '@ant-design/icons-vue'
import { IPriceAnswer } from '@/types/interfaces'
import TermsDelivery from '@/views/Farmer/response/toolbarWidget/TermsDelivery.vue'
import FromCustomer from '@/views/Farmer/response/toolbarWidget/FromCustomer.vue'

interface Props {
  priceAnswer: IPriceAnswer
}

const props = defineProps<Props>()

const isOpen = ref(true)

defineExpose({ isOpen })

const toggleCollapse = () => {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <div class="toolbar-wrapper" :style="{ minWidth: isOpen ? '360px' : '24px' }">
    <div v-show="isOpen" class="toolbar-uncollapsed">
      <CustomerInfoBlock
        type="request"
        @button-click="toggleCollapse"
        :contact_name="props.priceAnswer?.contact_name || ''"
        :contact_phone="props.priceAnswer?.contact_phone || ''"
        :contact_post="props.priceAnswer?.contact_post || ''"
        :auction="props.priceAnswer"
        :priceAnswer="props.priceAnswer"
        :address="
          props.priceAnswer?.recipient.price_request?.delivery_address || ''
        "
      />
      <TermsDelivery
        v-if="priceAnswer"
        @button-click="toggleCollapse"
        :priceAnswer="priceAnswer"
        :showButton="
          !(
            props.priceAnswer?.contact_name ||
            props.priceAnswer?.contact_phone ||
            props.priceAnswer?.contact_post ||
            props.priceAnswer?.recipient.price_request?.delivery_address
          )
        "
      />
      <FromCustomer
        v-if="props.priceAnswer.recipient.price_request?.comment"
        :customer-comment="
          props.priceAnswer.recipient.price_request?.comment || '-'
        "
      />
    </div>
    <div v-if="!isOpen" class="toolbap-collapsed">
      <Button type="link" @click="toggleCollapse">
        <template #icon>
          <RightOutlined />
        </template>
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar-wrapper {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 24px 0;
  width: min-content;
  overflow: auto;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 56px;

  & .toolbap-collapsed {
    padding: 0 24px;
  }

  & .toolbar-uncollapsed {
    height: 100%;
  }
}
:deep(.toolbar-block-wrapper) {
  margin-bottom: 24px;
}
</style>
