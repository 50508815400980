export const tabsForBenefitsTabbar = [
    {
        type: 'farmer',
        cards: [
            {
                title: 'Экономия времени',
                description:
                    'Принимайте заказ, выстраивайте маршрут или изменяйте условия поставки всего в несколько кликов.',
            },
            {
                title: 'Комплексные закупки',
                description:
                    'Сотрудничайте с региональными ритейлерами с любым объемом ваших поставок.',
            },
            {
                title: 'Прозрачное взаимодействие',
                description:
                    'Получите открытый доступ к каталогу товаров всех поставщиков.',
            },
            {
                title: 'Легкая интеграция',
                description:
                    'Зарегистрируйтесь быстро и просто. Удобное мобильное приложение, интеграция каталогов с 1С.',
            },
            {
                title: 'Документальное сопровождение',
                description: 'Автоматически выгружайте договор поставки, отгрузочные и сопроводительные документы.'
            },
            {
                title: 'Максимальная эффективность',
                description: 'Проводите открытые или закрытые торги на покупку и продажу.'
            }
        ],
    },
    {
        type: 'customer',
        cards: [
            {
                title: 'Проверенные контрагенты',
                description:
                    'Получите доступ к базе проверенных контрагентов по Единому государственному реестру.',
            },
            {
                title: 'Гибкое формирование заказа',
                description:
                    'Работайте по заказу с целевым поставщиком, либо собирайте необходимый объем товара от разных поставщиков в одном заказе.',
            },
            {
                title: 'Ищите лучшие цены',
                description:
                    'Сравнивайте актуальные предложения от постоянных и новых поставщиков, либо, используя «сервис запроса цен», получайте лучшее предложение на рынке. ',
            },
            {
                title: 'Удобная логистика',
                description: 'Создавайте комплексные заказы и выстраивайте логистику на карте в несколько кликов. Храним адреса складов всех поставщиков. ',
            },
            {
                title: 'Документальное сопровождение',
                description: 'Автоматически генерируйте договоры поставки, отгрузочные и сопроводительные документы.',
            },
            {
                title: 'Автоматизация бизнес-процессов',
                description: 'Платформа содержит модули торгов для покупателей и поставщиков. Получайте актуальные предложения оперативно.'
            }
        ],
    },
]
