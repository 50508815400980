<script lang="ts" setup>
import { reactive, onMounted, ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import {
  Input,
  Form,
  FormItem,
  FormInstance,
  Button,
  Checkbox,
  TypographyText as TpText,
} from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { phoneMask } from '@/types/globalConst'
import { onlyNumbersPhone, transformPhoneNumber } from '@/helpers/scripts'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import {useJWTDecode} from "@/composables/useJWTDecode";

interface FormState {
  name: string
  position: string
  phone: string
  agreement: boolean
}

const formState = reactive<FormState>({
  name: '',
  position: '',
  phone: '',
  agreement: false,
})

const errors = ref({
  name: '',
  position: '',
  phone: '',
  agreement: '',
})

const formRef = ref<FormInstance>()
const isLoading = ref(true)

const validateName = async (_rule: Rule, value?: string) => {
  if (!value?.trim()) {
    return Promise.reject('Введите ФИО')
  }

  if (value.length > 255) {
    return Promise.reject('Максимум 255 символов')
  }
}

const validatePosition = async (_rule: Rule, value?: string) => {
  if (value && value.length > 255) {
    return Promise.reject('Максимум 255 символов')
  }
}

const validatePhone = async (_rule: Rule, value: string) => {
  if (onlyNumbersPhone(value).length != 11) {
    if (!value) {
      await Promise.resolve()
    } else return Promise.reject('Укажите номер из 11 цифр')
  } else return Promise.resolve()
}

const validateAgreement = async (_rule: Rule, value?: boolean) => {
  if (!value) {
    return Promise.reject('Подтвердите согласие')
  }
}

const rules: Record<string, Rule[]> = {
  name: [{ required: true, validator: validateName, trigger: 'change' }],
  position: [
    { required: false, validator: validatePosition, trigger: 'change' },
  ],
  phone: [{ required: false, validator: validatePhone, trigger: 'change' }],
  agreement: [
    { required: true, validator: validateAgreement, trigger: 'change' },
  ],
}

const onFinish = async () => {
  sendData()
}

const threeAlt = ref<boolean>(false) // 3-ий шаг, daData не прошёл ping
const { cookies } = useCookies()
const emit = defineEmits(['next'])

const sendData = () => {
  cookies.set('user', JSON.stringify(formState), '365d', '')
  localStorage.setItem('isSkipUserDataFill', String(true))
  emit('next')
}

const handleChangePhone = (e: any) => {
  formState.phone = transformPhoneNumber(e?.target?.value)
}

onMounted(() => {
  threeAlt.value = Boolean(cookies.get('threeAlt'))
  if (localStorage.getItem('isSkipUserDataFill')) {
    emit('next')
    isLoading.value = false
  } else {
    isLoading.value = false
  }
})

const { getCoreCookie } = useJWTDecode()
</script>

<template>
  <div v-if="isLoading" class="loader">
    <StarLoader />
  </div>
  <div v-else class="auth-card">
    <div class="auth-card__header">
      <span>Регистрация</span>
      <div class="step">
        <span>этап {{ threeAlt ? '3' : '4' }}</span>
        <span class="max">/{{ threeAlt ? '3' : '4' }}</span>
      </div>
      <TpText class="header__description">
        <template v-if="getCoreCookie()?.company_type === 'customer'"
          >Пожалуйста, укажите свои данные. При регистрации компании вы станете
          её первым сотрудником, получите права администратора, сможете
          пригласить других сотрудников и заключить договор с
          платформой.</template
        >
        <template v-else>
          Пожалуйста, укажите свои данные. При регистрации компании вы станете
          её первым сотрудником и получите права администратора, т.е. сможете
          пригласить других сотрудников, заполнить каталог товаров и заключить
          договор с платформой для проведения заказов.
        </template>
      </TpText>
    </div>
    <div class="auth-card__body">
      <Form ref="formRef" :rules="rules" :model="formState" @finish="onFinish">
        <FormItem label="ФИО" name="name" class="ym-record-keys">
          <Input v-model:value="formState.name" placeholder="Введите ФИО" />
        </FormItem>
        <div class="position-phone">
          <FormItem label="Должность" name="position">
            <Input
              v-model:value="formState.position"
              placeholder="Необязательно"
            />
          </FormItem>
          <FormItem label="Номер телефона" name="phone">
            <Input
              v-maska="{
                mask: phoneMask,
                preprocessor: (value: string) => transformPhoneNumber(value),
              }"
              :value="formState.phone"
              @change="handleChangePhone"
              placeholder="Необязательно"
            />
          </FormItem>
        </div>
        <FormItem class="auth-card__checkbox" name="agreement">
          <Checkbox v-model:checked="formState.agreement">
            Я согласен c
            <a
              class="terms"
              href="https://storage.yandexcloud.net/farm-platform-production-images/privacy.pdf"
              target="_blank"
            >
              обработкой персональных данных
            </a>
          </Checkbox>
        </FormItem>
        <Button
          :disabled="!(formState.agreement && formState.name.trim())"
          size="large"
          type="primary"
          html-type="submit"
          class="continue-button"
        >
          Продолжить
        </Button>
      </Form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  &__description {
    font-size: 16px;
    font-weight: 400;
  }
}

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 604px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 48px;

  &__header {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .position-phone {
      display: flex;
      gap: 12px;
    }

    :deep(.ant-input) {
      height: 40px;
    }

    :deep(.ant-form-item) {
      width: 100%;
      margin-bottom: 32px;
    }

    :deep(.ant-row) {
      display: block;
    }

    .password-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 0;
    width: 100%;
    margin-bottom: 41px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    span {
      color: #2d3748;
      margin-right: 5px;
    }

    a.terms {
      transform: translateY(1px);
      margin-left: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 1px;
      @include router-link();
      border-bottom: 1px solid $default-accent;
    }
  }

  .continue-button {
    margin-top: 40px;
  }
}

:deep(.ant-form-item-explain-error) {
  font-size: 12px;
}
</style>
