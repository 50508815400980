<script lang="ts" setup>
import { catalogStore } from '@/store/catalogStore'
import router from '@/router'


interface IProps {
  items: []
}

const props = defineProps<IProps>()
const useCatalogStore = catalogStore()
const columns = window.innerWidth >= 1920 ? 4 : 3

const getItems = async (id: number) => {
  useCatalogStore.idSubCategory = id
  useCatalogStore.selectedFilters = []
  await useCatalogStore.getProducts(id)
  useCatalogStore.isOpen = false
  await router.push({ path: '/products-catalog' })
}

const getColumnItems = (i: number) => {
  let items: any[] = []
  while (props.items[i]) {
    items.push(props.items[i])
    i += columns
  }
  return items
}
</script>

<template>
  <div class="catalog-menu-right-category-items">
    <div
      class="catalog-menu-right-category-column"
      v-for="i in columns"
      :key="i"
    >
      <div
        class="catalog-menu-right-category-item"
        v-for="item in getColumnItems(i - 1)"
        :key="item.name"
      >
        <div
          class="catalog-menu-right-category-item-header"
          v-if="item.all_children_product_categories?.length > 0"
        >
          <h3 class="catalog-menu-right-category-title">{{ item.name }}</h3>
        </div>
        <div
          class="catalog-menu-right-category-item-header clickable"
          @click="getItems(item.id)"
          v-else
        >
          <h3
            class="catalog-menu-right-category-title clickable"
            v-if="item.childrenCount && item.childrenCount > 0"
            @click="getItems(item.id)"
          >
            {{ item.name }}
          </h3>
          <h3 class="catalog-menu-right-category-title clickable" v-else>
            {{ item.name }}
          </h3>
          <div
            class="catalog-menu-right-subcotegory-item-counter-title"
            v-if="item.childrenCount && item.childrenCount > 0"
          >
            <span>{{ item.childrenCount }}</span>
          </div>
          <div class="catalog-menu-right-subcotegory-item-counter-title" v-else>
            <span>0</span>
          </div>
        </div>
        <div class="catalog-menu-right-subcategories">
          <div
            class="catalog-menu-right-subcotegory"
            v-for="type in item.all_children_product_categories.filter(
              (catalogItem) => catalogItem.is_active,
            )"
            :key="type.name"
          >
            <div class="" v-if="item.name === 'Говядина'">
              <span class="catalog-menu-right-subcotegory-gray">{{
                type.name
              }}</span>
              <ul class="catalog-menu-right-subcotegory-list">
                <li
                  @click="
                    subtype.childrenCount && subtype.childrenCount > 0
                      ? getItems(subtype.id)
                      : ''
                  "
                  class="catalog-menu-right-subcotegory-item"
                  :key="subtype.name"
                  v-for="subtype in type.all_children_product_categories"
                >
                  {{ subtype.name }}
                  <div
                    class="catalog-menu-right-subcotegory-item-counter"
                    v-if="subtype.childrenCount && subtype.childrenCount > 0"
                  >
                    <span>{{ subtype.childrenCount }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else class="catalog-menu-right-subcotegory">
              <ul>
                <li
                  @click="getItems(type.id)"
                  class="catalog-menu-right-subcotegory-category"
                >
                  {{ type.name }}
                  <div class="catalog-menu-right-subcotegory-item-counter">
                    <span>{{ type.childrenCount }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.clickable {
  cursor: pointer;
}

.catalog-menu {
  position: relative;
  &-right {
    padding: 24px 40px;
    width: 100%;
    overflow-x: auto;
    &-title {
      font-size: 24px;
    }
  }
  &-right-category {
    display: flex;
    justify-content: flex-start;
    &-item-header {
      display: flex;
      position: relative;
      width: fit-content;
      &.clickable {
        &:hover {
          color: #4096ff;
        }
        &:active {
          color: #0958d9;
        }
      }
    }
    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 48px;
      margin-top: 40px;
    }
    &-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 48px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      img {
        width: 25px;
        height: 25px;
      }
    }
    &-title {
      font-size: 18px;
    }
  }
  &-right-subcategories {
    display: flex;
    flex-direction: column;
    gap: 12px;

    margin-top: 16px;
  }
  &-right-subcotegory {
    display: flex;
    flex-direction: column;
    &-gray {
      color: $gray-600;
      font-size: 16px;
    }
    &-category {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 16px;
      cursor: pointer;
      width: fit-content;
      position: relative;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      color: #000000e0;
      &:hover {
        color: #4096ff;
      }
      &:active {
        color: #0958d9;
      }
    }
    &-list {
    }
    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 12px;
      gap: 12px;
      cursor: pointer;
      color: $gray-800;
      text-decoration: none;
      position: relative;
      width: fit-content;
      &:hover {
        color: #4096ff;
      }
      &:active {
        color: #0958d9;
      }
      &-counter {
        background-color: $gray-400;
        font-size: 12px;
        border-radius: 12px;
        display: flex;
        width: 19px;
        height: 19px;
        align-items: center;
        justify-content: center;
        &-title {
          display: flex;
          align-items: center;
          background-color: $gray-400;
          font-size: 12px;
          margin-left: 8px;
          border-radius: 12px;
          width: 19px;
          color: inherit;
          height: 19px;
          position: absolute;
          top: 3px;
          right: -24px;
          justify-content: center;
        }
      }
    }
  }
}
</style>
