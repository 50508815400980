<script lang="ts" setup>
import { computed } from 'vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import {
  Button,
  Divider,
  Flex,
  Tag,
  Tooltip,
  TypographyText as TpText,
} from 'ant-design-vue'
import {fillMeasurementUnitIdCommon, formatPrice} from '@/helpers/scripts'
import {
  IFarmerProduct,
  IOrder,
  IOrderPositionHistory,
  ISpecialPrice,
} from '@/types/interfaces'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { DeleteFilled, EditFilled, UndoOutlined } from '@ant-design/icons-vue'
import QuantityExceededTooltip from '@/components/Tooltip/QuantityExceededTooltip.vue'
import ProductCover from '@/assets/img/product_cover.png'

interface Props {
  is_changed: boolean
  is_canceled: boolean
  is_can_edit: boolean
  quantity: string
  price: string
  unit_price: string
  vat_percent: number
  is_special_price: boolean
  order: IOrder
  farmer_product: {
    prepare_days: number
    vat_percent: number
    image_url: string
    measurement_unit_id: string
    quantity: string
    product: {
      fullNameWithMainCategory: string
    }
    special_prices: ISpecialPrice[]
  }
  history: IOrderPositionHistory[]
}

const props = defineProps<Props>()
const emit = defineEmits(['selectViewProduct', 'restore', 'delete', 'edit'])

const productCover = computed(() => {
  return props.farmer_product.image_url
    ? props.farmer_product.image_url
    : ProductCover
})
const { getCoreCookie } = useJWTDecode()

const neededSpecialPrice = computed(() => {
  return props.farmer_product.special_prices?.find(
    (specialPrice) => specialPrice.customer_id === getCoreCookie()?.id,
  )
})

const selectViewProduct = () => {
  emit('selectViewProduct', {
    product: props.farmer_product,
    orderQuantity: props.quantity,
  })
}

const not_show_changes = computed(() => {
  return (
    (props.order.status === NewGeneralOrderStatus.New ||
      props.order.status === NewGeneralOrderStatus.OrderPicking) &&
    props.history[0]?.initiator_type === 'farmer'
  )
})

const actual_order_position_data = computed(() => {
  const previous_order_position = props.history[0]?.old_entity
  const pre_previous_order_position = props.history[1]?.old_entity
  if (not_show_changes.value) {
    // TODO: Проверить корректность
    return {
      unit_price: +previous_order_position.unit_price,
      price: +previous_order_position.price,
      vat_percent: previous_order_position.vat_percent,
      quantity: previous_order_position.quantity,
      is_canceled: previous_order_position.is_canceled,
      initial_order_position: pre_previous_order_position
        ? {
            ...pre_previous_order_position,
            // TODO: Проверить корректность
            unit_price: Number(pre_previous_order_position.unit_price),
            price: Number(pre_previous_order_position.price),
          }
        : null,
    }
  } else {
    return {
      // TODO: Проверить корректность
      unit_price: +props.unit_price,
      price: +props.price,
      vat_percent: props.vat_percent,
      quantity: props.quantity,
      is_canceled: props.is_canceled,
      initial_order_position: previous_order_position
        ? {
            // TODO: Проверить корректность
            ...previous_order_position,
            unit_price: Number(previous_order_position.unit_price),
            price: Number(previous_order_position.price),
          }
        : null,
    }
  }
  // if (is_show_changes.value) {
  //   return {
  //     unit_price: priceWithVat(+props.unit_price, props.vat_percent),
  //     price: priceWithVat(+props.price, props.vat_percent),
  //     vat_percent: props.vat_percent,
  //     quantity: props.quantity,
  //     initial_order_position: previousOrderPosition.value
  //       ? {
  //           ...previousOrderPosition.value.old_entity,
  //           unit_price: priceWithVat(
  //             Number(previousOrderPosition.value.old_entity.unit_price),
  //             previousOrderPosition.value.old_entity.vat_percent,
  //           ),
  //           price: priceWithVat(
  //             Number(previousOrderPosition.value.old_entity.price),
  //             previousOrderPosition.value.old_entity.vat_percent,
  //           ),
  //         }
  //       : null,
  //   }
  // } else {
  //   const vat_percent = previousOrderPosition.value
  //     ? previousOrderPosition.value.old_entity.vat_percent
  //     : props.vat_percent
  //   const quantity = previousOrderPosition.value
  //     ? previousOrderPosition.value.old_entity.quantity
  //     : props.quantity
  //   let unit_price = previousOrderPosition.value
  //     ? previousOrderPosition.value.old_entity.unit_price
  //     : props.unit_price
  //   unit_price = priceWithVat(+unit_price, vat_percent)
  //   const price = roundNumber(+unit_price * +quantity)
  //   return {
  //     vat_percent,
  //     quantity,
  //     unit_price,
  //     price,
  //   }
  // }
})

const is_order_position_params_changed = computed(() => {
  if (!actual_order_position_data.value.initial_order_position) return false
  const isQuantityChanged =
    actual_order_position_data.value.initial_order_position.quantity !==
    actual_order_position_data.value.quantity
  const isUnitPriceChanged =
    actual_order_position_data.value.initial_order_position.unit_price !==
    actual_order_position_data.value.unit_price
  const isVatChanged =
    actual_order_position_data.value.initial_order_position.vat_percent !==
    actual_order_position_data.value.vat_percent

  return isQuantityChanged || isUnitPriceChanged || isVatChanged
})

const is_show_changes = computed(() => {
  return !!props.order.history.length
})
const is_customer_delete_product = computed(() => {
  if (!actual_order_position_data.value.is_canceled) return false
  return (
    props.history.find((history) => !history.old_entity.is_canceled)
      ?.initiator_type === 'customer'
  )
})

const is_farmer_delete_product = computed(() => {
  if (!actual_order_position_data.value.is_canceled) return false
  return (
    props.history.find((history) => !history.old_entity.is_canceled)
      ?.initiator_type === 'farmer'
  )
})

const is_last_edited_by_farmer = computed(() => {
  if (not_show_changes.value) {
    return false
  } else {
    return props.history[0].initiator_type === 'farmer'
  }
})

const uniqWordsInString = (str: string) => {
  const words = str.split(' ')
  const uniqueWords = [...new Set(words)]
  return uniqueWords.join(' ')
}
const isQuantityExceeded = computed(() => {
  return (
    Number(actual_order_position_data.value.quantity) >
    Number(props.farmer_product.quantity)
  )
})
</script>

<template>
  <slot name="divider" />
  <div
    class="order-position-item"
    :class="{
      canceled: actual_order_position_data.is_canceled,
      'quantity-exceeded-container': isQuantityExceeded,
    }"
  >
    <img :src="productCover" alt="product_cover" height="104" loading="lazy" />
    <div class="wrapper">
      <div class="order-position-item-exclamations">
        <Tag v-if="actual_order_position_data.is_canceled">
          <template v-if="is_farmer_delete_product"
            >Поставщик удалил товар</template
          >
          <template v-if="is_customer_delete_product"
            >Вы удалили товар</template
          >
        </Tag>
        <Tag
          v-if="is_order_position_params_changed"
          :color="is_last_edited_by_farmer ? 'orange' : 'default'"
        >
          <template v-if="is_last_edited_by_farmer"
            >Поставщик внёс изменения</template
          >
          <template v-else>Вы внесли изменения</template>
        </Tag>
      </div>
      <div class="info-wrapper">
        <div class="order-position-item-info">
          <p @click="selectViewProduct" class="product-name-link">
            {{
              uniqWordsInString(farmer_product.product.fullNameWithMainCategory)
            }}
          </p>
          <div class="product-info">
            <TpText>
              <span class="actual">
                {{ actual_order_position_data.unit_price }}
                ₽/{{ farmer_product.measurement_unit_id }}
              </span>
              <div>
                <span
                  v-if="
                    actual_order_position_data.initial_order_position &&
                    actual_order_position_data.initial_order_position
                      .unit_price !== actual_order_position_data.unit_price &&
                    is_show_changes
                  "
                  class="product-info-item-initial"
                >
                  {{
                    actual_order_position_data.initial_order_position.unit_price
                  }}
                  ₽/{{ farmer_product.measurement_unit_id }}
                </span>
              </div>
            </TpText>
            <Divider type="vertical" />
            <TpText>
              <span class="actual">
                {{ `НДС ${actual_order_position_data.vat_percent}%` }}
              </span>
              <span
                v-if="
                  actual_order_position_data.initial_order_position &&
                  actual_order_position_data.initial_order_position
                    .vat_percent !== actual_order_position_data.vat_percent &&
                  is_show_changes
                "
                class="product-info-item-initial"
              >
                {{
                  `${actual_order_position_data.initial_order_position.vat_percent}%`
                }}
              </span>
            </TpText>
            <Divider type="vertical" />
            <TpText>
              <span
                class="actual"
                :class="{
                  'quantity-exceeded': isQuantityExceeded,
                }"
              >
                {{
                  actual_order_position_data.quantity +
                  ' ' +
                  fillMeasurementUnitIdCommon(
                      (farmer_product as IFarmerProduct)?.product?.fullNameWithMainCategory ?? '',
                      farmer_product.measurement_unit_id ?? null,
                  )
                }}
                <QuantityExceededTooltip :show="isQuantityExceeded" />
              </span>
              <span
                v-if="
                  actual_order_position_data.initial_order_position &&
                  actual_order_position_data.initial_order_position.quantity !==
                    actual_order_position_data.quantity &&
                  is_show_changes
                "
                class="product-info-item-initial"
              >
                {{
                  actual_order_position_data.initial_order_position.quantity +
                  ' ' +
                  farmer_product.measurement_unit_id
                }}
              </span>
            </TpText>
          </div>
          <Flex v-if="is_special_price">
            <Tag color="green">Спеццена</Tag>
          </Flex>
        </div>
        <div class="order-position-item-price">
          {{ formatPrice(actual_order_position_data.price) }}
          <p
            v-if="
              actual_order_position_data.initial_order_position &&
              actual_order_position_data.initial_order_position.price &&
              actual_order_position_data.initial_order_position.price !==
                actual_order_position_data.price &&
              is_show_changes
            "
            style="font-size: 14px"
          >
            {{
              formatPrice(
                actual_order_position_data.initial_order_position.price,
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="edit-product" v-if="is_can_edit">
      <template v-if="actual_order_position_data.is_canceled">
        <Tooltip
          placement="bottom"
          :overlayInnerStyle="{
            width: '118px',
          }"
        >
          <template #title>
            <span>Вернуть товар</span>
          </template>
          <Button type="link" @click="emit('restore')">
            <template #icon>
              <UndoOutlined />
            </template>
          </Button>
        </Tooltip>
      </template>

      <template v-else>
        <Tooltip
          placement="bottom"
          :overlayInnerStyle="{
            width: '164px',
          }"
        >
          <template #title>
            <span> Редактировать товар </span>
          </template>
          <Button type="link" @click="emit('edit')">
            <template #icon>
              <EditFilled class="edit" />
            </template>
          </Button>
        </Tooltip>

        <Tooltip
          placement="bottom"
          :overlayInnerStyle="{
            width: '118px',
          }"
        >
          <template #title>
            <span> Удалить товар </span>
          </template>
          <Button danger type="link" @click="emit('delete')">
            <template #icon>
              <DeleteFilled />
            </template>
          </Button>
        </Tooltip>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.order-position-item {
  position: relative;
  display: grid;
  grid-template-columns: 140px 1fr;
  column-gap: 16px;
  align-items: flex-start;
  padding: 24px 16px;
  &.canceled {
    background-color: #0000000a;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .info-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 24px;
  }

  img {
    width: 100%;
    border-radius: 12px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 135%;
      color: $gray-800;
    }

    .product-info {
      display: flex;
      flex-direction: row;

      .actual {
        font-size: 16px;
      }

      &-item {
        color: $gray-700;

        &-initial {
          display: flex;
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: $gray-500;
          text-decoration-line: line-through;
        }
      }
    }
  }

  .edit-product {
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 20px;
    gap: 8px;

    .ant-btn-link {
      padding: 8px;
    }
  }

  &-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    color: $gray-800;
    text-align: right;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 135%;
      color: $gray-500;
      text-decoration-line: line-through;
    }
  }

  &-product {
    margin-top: 8px;
  }

  &-exclamations {
  }

  :deep(.ant-divider) {
    height: 100%;
  }
}
.product-name-link {
  cursor: pointer;
  &:hover,
  &:active {
    color: #1677ff;
  }
}

.quantity-exceeded-container {
  background-color: #fef7f3;
  border-radius: 8px;
}

.quantity-exceeded {
  font-weight: 600;
  color: #faad14;
}
</style>
