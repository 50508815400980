<script setup lang="ts">
import { Flex } from 'ant-design-vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import AntEmpty from '@/components/Empty/AntEmpty.vue'
import {computed} from "vue";
interface IProps {
  title?: string
  loading?: boolean
  empty?: boolean
  empty_title?: string
  empty_subtitle?: string
}
const props = defineProps<IProps>()
const isLoading = computed(() => props.loading)
</script>

<template>
  <div
    class="data-block"
    :class="{ loading: props.loading, empty: props.empty }"
  >
    <Flex vertical :gap="16" v-if="!loading && !empty">
      <h3 v-if="title">{{ title }}</h3>
      <slot></slot>
    </Flex>
    <AntEmpty
      v-if="empty && !loading"
      :title="empty_title"
      :subtitle="empty_subtitle"
    />
    <FullscreenLoading :type="'secondary'" v-if="isLoading" />
  </div>
</template>

<style scoped lang="scss">
.data-block {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #000000e0;
  }
  &.loading,
  &.empty {
    min-height: 391px;
    position: relative;
  }
}
</style>
