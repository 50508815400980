<script lang="ts">
import { defineComponent } from 'vue'
import dayjs from "dayjs";

export default defineComponent({
  name: 'CustomerRequestCommonInfoDeliveryTime',
  computed: {
    dayjs() {
      return dayjs
    },
  },
  props: {
    deliveryDate: String,
    deliveryTime: {
      type: String,
      default: '0',
      required: true,
    },
  },
})

// TODO: Дописать
</script>

<template>
  <div class="customer-request-common-info-delivery-time">
    <div class="customer-request-common-info-delivery-time_title">
      Срок поставки
    </div>
    <div class="customer-request-common-info-delivery-time_delivery-time" v-if="deliveryTime">
      {{ deliveryTime }} дней
    </div>
    <div class="customer-request-common-info-delivery-time_delivery-time" v-else>
      {{ dayjs(deliveryDate).format('DD.MM.YYYY') }}
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info-delivery-time {
  &_title {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 600;
    font-family: Inter, sans-serif;
  }
  &_delivery-time {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
}
</style>
