<script setup lang="ts">

import AgregateFarmerTable from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateFarmerRes/agregateFarmerTable/AgregateFarmerTable.vue'
import RecipientInfo from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/RecipientInfo/RecipientInfo.vue'
import { Flex } from 'ant-design-vue'
import { IRecipient } from '@/types/interfaces'

interface Props {
  recipient: IRecipient
}

const props = defineProps<Props>()
</script>

<template>
  <Flex vertical :gap="16" class="farmer-block">
    <div
      :class="{ 'with-border': props.recipient.answer_status !== 'answered' }"
    >
      <RecipientInfo :recipient="props.recipient" is-big-name need-status-tag />
    </div>
    <div v-if="props.recipient.answer_status === 'answered'">
      <AgregateFarmerTable
        v-if="props.recipient.price_answer?.product_answers"
        :productAnswerws="props.recipient.price_answer.product_answers"
      />
    </div>
  </Flex>
</template>

<style lang="scss">
.farmer-block {
  padding-bottom: 40px;
  height: 100%;
}

.with-border {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.delivery-btn-wrapper {
  margin-bottom: 16px;
  padding-left: 16px;
}
</style>
