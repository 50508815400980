<script lang="ts" setup>
import { computed, ref } from 'vue'
import PickupIcon from '@/components/Icons/PickupIcon.vue'
import {
  declensionProvider,
  ISODateTransformerShort,
  splitNumberByThreeDigits,
} from '@/helpers/scripts'
import FarmerDeliveryIcon from '@/components/Icons/FarmerDeliveryIcon.vue'
import { fontSize14, withoutBGButton } from '@/assets/EgalStyles/EButton'
import ExpandArrowIcon from '@/components/Icons/ExpandArrowIcon.vue'
import CustomerOrderRow from '@/components/Orders/CustomerOrderRow.vue'
import router from '@/router'

interface ICustomerComplexOrderRow {
  genOrder: {
    delivery_date: string
    order_date: string
    price: string
    id: number
    delivery_address: {
      street: string
    }
    orders: {
      id: number
      status: string
      price: number
      delivery_method: 'Самовывоз' | 'Доставка фермером'
      is_partner: boolean
      from_address: string
      farmer: {
        name: string
        legal_form: string
      }
    }[]
  }
}

const props = defineProps<ICustomerComplexOrderRow>()
const isOpen = ref(false)
const toggleComplexOrder = () => {
  isOpen.value = !isOpen.value
}
const isPickup = computed(() => {
  return props.genOrder.orders?.some(
    (order) => order.delivery_method === 'Самовывоз',
  )
})
const isDelivery = computed(() => {
  return props.genOrder.orders?.some(
    (order) => order.delivery_method === 'Доставка фермером',
  )
})

const openOrderPage = () => {
  router.push(`/history/${props.genOrder.id}`)
}
</script>

<template>
  <div class="customer-complex-order-row" @click="openOrderPage">
    <div class="customer-complex-order-cell">
      Комплексный заказ
      <EButton
        :class="isOpen ? 'opened' : ''"
        :style-config="{
          ...fontSize14,
          ...withoutBGButton,
          padding: 0,
        }"
        class="show-order-button"
        @click.stop="toggleComplexOrder"
      >
        <span class="show-order-button-text"> Подробнее</span>
        <ExpandArrowIcon :fill="'#0066FF'" />
      </EButton>
    </div>
    <div class="customer-complex-order-cell">
      {{ genOrder.orders?.length }}

      {{ 'поставщик' + declensionProvider(genOrder.orders?.length) }}
    </div>
    <div class="customer-complex-order-cell">
      <div v-if="isPickup && !isDelivery" class="delivery-method-info">
        <PickupIcon :fill="'#2D3748'" />
        Самовывоз
      </div>
      <div v-if="isDelivery" class="delivery-method-info">
        <FarmerDeliveryIcon :fill="'#2D3748'" />
        {{ isPickup && isDelivery ? 'Доставка и самовывоз' : 'Доставка' }}
      </div>
      <span v-if="isPickup && isDelivery" class="cell-additional-info">
        {{ genOrder?.delivery_address?.street }}
      </span>
    </div>
    <div class="customer-complex-order-cell">
      {{ ISODateTransformerShort(genOrder?.order_date) }}
    </div>
    <div class="customer-complex-order-cell">
      {{ ISODateTransformerShort(genOrder?.delivery_date) }}
    </div>
    <div class="customer-complex-order-cell">
      {{ splitNumberByThreeDigits(genOrder?.price) }} ₽
    </div>
  </div>
  <div v-if="isOpen" class="customer-complex-orders">
    <CustomerOrderRow
      v-for="order in genOrder.orders"
      :key="order.id"
      :general-id="genOrder.id"
      :delivery_date="genOrder.delivery_date"
      :order="order"
      :delivery_address="genOrder.delivery_address"
      :order_date="genOrder.order_date"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.customer-complex-order-row {
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;
  padding: 20px 12px;
  display: grid;
  grid-template-columns: 2fr 264px 2fr 120px 120px 1fr;
  border-top: 1px solid $gray-300;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  .customer-complex-order-cell {
    padding: 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;

    .show-order-button {
      display: flex;
      gap: 8px;
      margin-top: 4px;

      &-text {
        border-bottom: 1px dashed $primary-accent;
        font-weight: 400;
        line-height: 19px;
      }

      svg {
        transition: all 0.1s ease-in;
      }

      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &:last-child {
      font-weight: 700;
    }

    .delivery-method-info {
      display: flex;
      column-gap: 8px;
      margin-bottom: 4px;

      svg {
        transform: scale(1.2);
      }
    }

    .cell-additional-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: $gray-600;
    }
  }
}

.customer-complex-orders {
  box-shadow: 0 0 16px 5px rgba(20, 37, 63, 0.06) inset;

  :deep(.customer-order-row:first-of-type) {
    padding-top: 40px;
  }

  :deep(.customer-order-row:last-of-type) {
    padding-bottom: 40px;
  }
}
</style>
