<script lang="ts" setup>
import FarmerHistory from '@/views/Farmer/FarmerHistory.vue'
import CustomerHistory from '@/views/Customer/CustomerHistory.vue'
import { Roles } from '@/types/enums'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useUserAuthStore } from '@/store/useUserAuthStore'

const emit = defineEmits([''])

const route = useRoute()
const userStore = computed(() => useUserAuthStore())
</script>

<template>
  <FarmerHistory
    v-if="userStore.user?.type === Roles.Farmer"
    :openOrderHistory="Number(route?.params?.id)"
  />

  <CustomerHistory
    v-else-if="userStore.user?.type === Roles.Customer"
    :openOrderHistory="Number(route?.params?.id)"
  />
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.wrapper {
  @include wrapper();
}

.content {
  width: 100%;
}
</style>
