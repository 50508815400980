import { APIModels } from '@/types/enums'
import { IProductAnswer } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class ProductAnswerAPIModel {
  async update(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductAnswer,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductAnswer,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('core')},
      data: { pagination: { per_page: perPage, page: page }, filter, withs, order: [['created_at', 'desc']]},
    })
  }

  async updateMany(data: Partial<IProductAnswer>[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductAnswer,
      method: EgalMethods.updateMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const ProductAnswerAPI = new ProductAnswerAPIModel()
export { ProductAnswerAPI }
