export const MAP_SRC =
  'https://api-maps.yandex.ru/2.1/?apikey=1f84d01f-111d-4c23-9a2a-d15847199ad0&lang=ru_RU&suggest_apikey=58e4bc60-65d6-49d8-94a2-81a9f4306d0d'

export const CLUSTER_CONTENT_WITH_BUTTON =
  '<div style="margin: 5px 5px 10px 5px; font-size: 14px; font-family: Inter">' +
  '<b>{{properties.address}}</b><br />' +
  '<br />' +
  '<button id="my-button" data-farm_id="{{properties.farm_id}}">Просмотреть информацию о поставщике</button>' +
  '</div>'

export const CLUSTER_CONTENT_NO_BUTTON =
  '<div style="margin: 5px 5px 10px 5px; font-size: 14px; font-family: Inter">' +
  '<b>{{properties.address}}</b><br />' +
  '<br />' +
  '<button id="my-button" hidden data-farm_id="{{properties.farm_id}}"/>' +
  '</div>'
