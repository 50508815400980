<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomerCommonInfoComment',
  props: {
    title: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
      required: true,
    },
  },
})

// TODO: Дописать
</script>

<template>
  <div class="customer-common-info-comment">
    <div class="customer-common-info-comment_title">
      {{ title ? `${title}` : 'Комментарии' }}
    </div>
    <div class="customer-common-info-comment_comment">{{ comment }}</div>
  </div>
</template>

<style lang="scss">
.customer-common-info-comment {
  &_title {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
  }
  &_comment {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}
</style>
