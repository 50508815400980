<script lang="ts" setup>
import { TMapCoordinates, TMapPoint } from '@/types/types'
import {
  CLUSTER_CONTENT_NO_BUTTON,
  CLUSTER_CONTENT_WITH_BUTTON,
  MAP_SRC,
} from './constants'
import { onMounted, ref, watch } from 'vue'
import ymaps from 'ymaps'

type TMarkerColors = {
  default: string | null
  active: string | null
}

interface IProps {
  points?: TMapPoint[]
  secondaryPoints?: TMapPoint[]
  isInteractive?: boolean // Если false, то на карте не будет возможности кликать, пропадут контролы и попапы
  width: string
  height: string
  markerColors?: TMarkerColors
  clusterColor?: string
  onMapClick?: ({ lat, lon }: TMapCoordinates) => void
  onPointClick?: (point: TMapPoint) => void
  maxZoom?: number
}

const props = withDefaults(defineProps<IProps>(), {
  markerColors: () => ({
    default: 'islands#blueCircleIcon',
    active: 'islands#greenCircleDotIcon',
  }),
  clusterColor: 'islands#blueClusterIcons',
})

const mapContainer = ref()
let mapInstance = null
let geoObjects = []
let secondaryGeoObjects = []
let clusterer = null
let ymapsInstance = null

/**
 * Initializes the Yandex map and sets up event listeners and cluster management.
 */
const initMap = async () => {
  // Load Yandex Maps API
  ymapsInstance = await ymaps.load(MAP_SRC)

  // Create a new map instance
  mapInstance = new ymapsInstance.Map(mapContainer.value, {
    center: [56.5118491, 84.96442809], // Default center is Tomsk
    zoom: 10,
    controls: props.isInteractive ? ['zoomControl', 'fullscreenControl'] : [],
  }, {
    maxZoom: props.maxZoom || 19,
    minZoom: 2,
    restrictMapArea: [
    [75, -179.999], // Southwest corner (latitude, longitude)
    [-75, 179.999]  // Northeast corner (latitude, longitude)
]
  })

  // Add click event listener for map clicks
  if (props.onMapClick) {
    mapInstance.events.add('click', (e) => {
      const coords = e.get('coords')
      props.onMapClick?.({
        lat: coords[0],
        lon: coords[1],
      })
    })
    mapInstance.cursors.push('pointer')
  }

  // Define the layout for the cluster balloon content
  const BalloonContentLayout = ymapsInstance.templateLayoutFactory.createClass(
    props.onPointClick
      ? CLUSTER_CONTENT_WITH_BUTTON
      : CLUSTER_CONTENT_NO_BUTTON,
    {
      /**
       * Build method for the balloon content layout.
       */
      build: function () {
        BalloonContentLayout.superclass.build.call(this)
        document
          .getElementById('my-button')
          .addEventListener('click', this.myButtonClick)
      },

      /**
       * Clear method for the balloon content layout.
       */
      clear: function () {
        document
          .getElementById('my-button')
          .removeEventListener('click', this.myButtonClick)
        BalloonContentLayout.superclass.clear.call(this)
      },

      /**
       * Click handler for the custom button in the balloon content.
       * @param {Event} e - The event object from the click.
       */
      myButtonClick: function (e) {
        const farmId = Number(e.target.dataset.farm_id)
        const farmFromClusterer = props.points.find(
          (point) => Number(point.id) === farmId,
        )
        if (farmFromClusterer) {
          const clickedPoint = props.points.find(
            (point) => Number(point.id) === farmId,
          )
          props.onPointClick?.(clickedPoint)
        }
      },
    },
  )

  // Initialize the clusterer with specified options
  clusterer = new ymapsInstance.Clusterer({
    groupByCoordinates: false,
    clusterDisableClickZoom: true,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    clusterBalloonContentLayout: 'cluster#balloonAccordion',
    clusterBalloonItemContentLayout: BalloonContentLayout,
    clusterOpenBalloonOnClick: props.isInteractive,
    clusterBalloonPanelMaxMapArea: 0,
    clusterBalloonContentLayoutWidth: 300,
    clusterBalloonContentLayoutHeight: 220,
    preset: props.clusterColor,
  })

  // Add the clusterer to the map
  mapInstance.geoObjects.add(clusterer)

  // Update map points
  updatePoints()
}

/**
 * Updates the points on the map with the new data.
 * @function
 */
const addPoints = (
  points: TMapPoint[],
  markerColors: TMarkerColors,
  isInteractive: boolean,
  shouldCluster = true,
) => {
  // Add the new points to the map and the clusterer
  points.forEach((point) => {
    const placemark = new ymapsInstance.Placemark(
      [point.coordinates.lat, point.coordinates.lon],
      {
        hintContent: isInteractive ? point.name : '',
        clusterCaption: point.name,
        address: point.address,
        farm_id: point.id,
      },
      {
        preset: markerColors.default,
        hasBalloon: false,
        cursor: isInteractive ? 'pointer' : 'default',
        clusterize: false,
      },
    )

    if (isInteractive) {
      placemark.events.add('mouseenter', () => {
        placemark.options.set('preset', markerColors.active)
      })

      placemark.events.add('mouseleave', () => {
        placemark.options.set('preset', markerColors.default)
      })

      placemark.events.add('click', () => {
        props.onPointClick?.(point)
      })
    }

    // If the point should not be clustered, add it to the secondaryGeoObjects array
    if (!shouldCluster) {
      secondaryGeoObjects.push(placemark)
      mapInstance.geoObjects.add(placemark)
    } else {
      geoObjects.push(placemark)
    }

  })

  clusterer.add(geoObjects)

  // If there are points on the map, set the bounds of the map to the bounds of the points
  if (geoObjects.length > 0) {
    const bounds = mapInstance.geoObjects.getBounds()
    if (bounds) {
      mapInstance.setBounds(bounds, { checkZoomRange: true, zoomMargin: 20 })
    }
  }
}

const updatePoints = () => {
  if (!mapInstance || !clusterer) return

  // Remove the old points from the map
  clusterer.removeAll()
  geoObjects.forEach((obj) => mapInstance.geoObjects.remove(obj))
  secondaryGeoObjects.forEach((obj) => {
    mapInstance.geoObjects.remove(obj)
  })
  geoObjects = []
  secondaryGeoObjects = []

  // Add primary points to the map
  props.points &&
    addPoints(props.points, props.markerColors, props.isInteractive)

  // Add secondary points to the map
  props.secondaryPoints &&
    addPoints(
      props.secondaryPoints,
      {
        default: 'islands#orangeCircleIcon',
        active: 'islands#yellowCircleDotIcon',
      },
      false,
      false,
    )

}

watch(
  () => [props.points, props.secondaryPoints],
  () => {
    updatePoints()
  },
  { deep: true },
)

onMounted(() => {
  initMap()
})
</script>

<template>
  <div class="cart-map" :style="{ minHeight: height }">
    <div
      id="map"
      ref="mapContainer"
      :style="{ width: width, height: height }"
    ></div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.cart-map {
  object-fit: cover;
  position: relative;
  height: 256px;
  width: 100%;
}

.catalog__map {
  top: 0;
  left: 0;
}
</style>
