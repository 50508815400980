<script lang="ts" setup>
import { ref } from 'vue'
import { CompanyAPI } from '@/api/CompanyAPI'
import { IEmployee } from '@/types/interfaces'
import { Flex } from 'ant-design-vue'
import InfiniteLoading from 'v3-infinite-loading'
import EmployeeItem from '@/components/AccountForms/EmployeeTab/EmployeeItem.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'

const employees = ref<IEmployee[]>([])
const isLoading = ref(true)
const page = ref(1)
const allFetched = ref(false)

const getEmployees = async () => {
  isLoading.value = true
  const { items, total_count } = await CompanyAPI.getEmployees(page.value)
  employees.value.push(...(items as IEmployee[]))
  page.value += 1
  isLoading.value = false

  if (employees.value.length === total_count) {
    allFetched.value = true
  }
}
</script>

<template>
  <Flex :gap="24" vertical>
    <EmployeeItem
      v-for="{ status, name, email, phone, position } in employees"
      :key="email"
      :status="status"
      :name="name"
      :email="email"
      :phone="phone"
      :position="position"
    />
    <InfiniteLoading
      v-if="!allFetched"
      class="infinite-loading"
      @infinite="getEmployees"
      :distance="100"
    />
    <div v-if="isLoading" class="loader-wrapper">
      <FullscreenLoading :type="'secondary'" />
    </div>
  </Flex>
</template>

<style scoped lang="scss">
.loader-wrapper {
  position: relative;
  transform: scale(0.5);
}
</style>
