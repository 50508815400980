<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import OrgStep from '@/components/ConfirmDataSteps/OrgStep.vue'
import { IAccountUser } from '@/types/interfaces'
import BankStep from '@/components/ConfirmDataSteps/BankStep.vue'
import { APIModels, Roles } from '@/types/enums'
import { UserAPI } from '@/api/UserAPI'
import { useStore } from '@/store'

interface Props {
  item?: any
  userInfo: IAccountUser | Record<string, string>
  role: Roles.Customer | Roles.Farmer
}

const props = defineProps<Props>()
const emit = defineEmits(['refetchUser'])
const containCron = computed(() => {
  return props.userInfo.user_need_accept.includes('cron')
})
const containAnd = computed(() => {
  return props.userInfo.user_need_accept.includes('And')
})
const containAll = computed(() => {
  return props.userInfo.user_need_accept.includes('All')
})
const containTin = computed(() => {
  return props.userInfo.user_need_accept.includes('Tin')
})
const containBic = computed(() => {
  return props.userInfo.user_need_accept.includes('Bic')
})

const userNeedAccept = computed(() => {
  if (containCron.value) {
    return 'cron'
  }
  if (props.userInfo.user_need_accept.toLowerCase().includes('verify')) {
    return null
  }
  return undefined
})

const isOrg = computed(() => {
  return (containTin.value || containAll.value) && activeStep.value === 1
})
const isBank = computed(() => {
  return (containBic.value || containAll.value) && activeStep.value === 2
})

const activeStep = ref(1)
const requestData = ref<IUserUpdate>()

interface IUserUpdate {
  tin?: string
  bic?: string
  id?: string
  user_need_accept?: string | null
}

const totalSteps = computed(() => {
  return (containAnd.value || containAll.value) && !containCron.value ? 2 : 1
})

const setRequestData = (data: IUserUpdate) => {
  requestData.value = { ...requestData.value, ...data }
  requestData.value.id = props.userInfo.id

  if (totalSteps.value === 2 && activeStep.value !== 2) {
    activeStep.value += 1
  } else {
    if (userNeedAccept.value !== undefined) {
      requestData.value.user_need_accept = userNeedAccept.value
    }
    sendRequestData()
  }
}
const { setVerificationStatus } = useStore()
const role = computed(() => {
  if (props.role === Roles.Farmer) {
    return APIModels.Farmer
  } else if (props.role === Roles.Customer) {
    return APIModels.Customer
  }
  return ''
})
const sendRequestData = async () => {
  await UserAPI.updateRoleUserData(requestData.value, role.value).then(() => {
    if (!containCron.value) {
      setVerificationStatus('refetch')
    }
    emit('refetchUser')
  })
}
onMounted(() => {
  if (containTin.value && !containBic.value) {
    activeStep.value = 1
  }
  if (!containTin.value && containBic.value) {
    activeStep.value = 2
  }
})
</script>

<template>
  <div class="confirm-view">
    <OrgStep v-if="isOrg" :userInfo="userInfo" @setData="setRequestData" />
    <BankStep v-if="isBank" :userInfo="userInfo" @setData="setRequestData" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
</style>
