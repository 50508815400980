import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class AuctionAPIModel {
  async create(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.Auction,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.Auction,
      method: EgalMethods.update,
      data: { attributes: data },
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
    order: any = [['created_at', 'desc']],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Auction,
      method: EgalMethods.GetItems,
      data: { filter, withs, pagination: { per_page: perPage, page: page }, order },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItemsWithBetter(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
    order: any = [['created_at', 'desc']],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.AuctionWithBetter,
      method: EgalMethods.GetItems,
      data: { filter, withs, pagination: { per_page: perPage, page: page }, order },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItem(
    id: string,
    withs: string[] = [],
    filter: any[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.AuctionWithBetter,
      method: EgalMethods.GetItem,
      data: { id, withs, filter },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const AuctionAPI = new AuctionAPIModel()
export { AuctionAPI }
