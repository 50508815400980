import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class AuctionProductAnswerModel {
  async create(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.AuctionProductAnswer,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.AuctionProductAnswer,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const AuctionProductAnswerAPI = new AuctionProductAnswerModel()
export { AuctionProductAnswerAPI }
