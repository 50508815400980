<script setup lang="ts">
import { useRouter } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
import { PriceRequestAPI } from '@/api/PriceRequestAPI'
import { IPriceRequest } from '@/types/interfaces'
import { Badge, Button, Space, Table, Tag } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import { priceStatusTag } from '@/helpers/scripts'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import emitter from "@/emitter";

const page = ref(1)
const data = ref<IPriceRequest[]>()
const isDataFetching = ref(false)
const expandedRowKeys = ref<number[]>([])
const router = useRouter()
const columns = [
  {
    title: '',
    key: 'expand',
    width: 50,
  },
  {
    title: 'Товары в запросе',
    dataIndex: 'products',
    key: 'products',
    width: '35%',
  },
  {
    title: 'Дата отправки',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Статус запроса',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Поставщики',
    key: 'recipients',
    dataIndex: 'recipients',
  },
  {
    title: '',
    key: 'actions',
  },
]
const getPriceRequests = async () => {
  isDataFetching.value = true
  const userDataString = localStorage.getItem('userData')
  if (!userDataString) return
  const userData = JSON.parse(userDataString)
  await PriceRequestAPI.getItems(
    page.value,
    [['customer.id', 'eq', userData.id]],
    ['recipients', 'product_requests', 'recipient_stats_in_price_request'],
  )
    .then(({ items }: { items: IPriceRequest[] }) => {
      if (page.value === 1) {
        data.value = items
      } else {
        data.value = data.value ? [...data.value, ...items] : items
      }
    })
    .finally(() => {
      isDataFetching.value = false
    })
}

const getProductsList = (product: string[], id: number, expanded: number[]) => {
  if (expanded.includes(id)) return product
  return product.slice(0, 3)
}
const handleExpandKey = (id: number) => {
  if (expandedRowKeys.value.includes(id)) {
    expandedRowKeys.value = expandedRowKeys.value.filter((exId) => exId !== id)
  } else {
    expandedRowKeys.value.push(id)
  }
}

const navigateToCurrentRequest = (id: number) => {
  router.push(`requests/${id}`)
}

const getCustomRow = (record: IPriceRequest) => {
  return {
    onClick: () => navigateToCurrentRequest(record.id),
    style: { cursor: 'pointer' },
  }
}

const increasePage = () => {
  page.value = page.value + 1
}

emitter.on('refresh-token', async (e) => {
  await getPriceRequests()
})

onMounted(async () => {
  await getPriceRequests()
})

watch(page, async () => {
  await getPriceRequests()
})
</script>

<template>
  <div class="customer-request-list">
    <div class="customer-request-list-filter"></div>
    <div class="customer-request-list-table">
      <Table
        :columns="columns"
        :data-source="data"
        rowKey="id"
        :pagination="false"
        :indentSize="10"
        v-if="data"
        :custom-row="getCustomRow"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'expand'">
            <Button v-if="record?.product_requests?.length > 3"
              type="text"
              @click.stop="handleExpandKey(record.id)"
              class="rotate-btn"
              :class="{ isRotated: expandedRowKeys.includes(record.id) }"
            >
              <template #icon>
                <DownOutlined />
              </template>
            </Button>
          </template>
          <template v-else-if="column.key === 'products'">
            <div class="product-list">
              <span
                v-for="product in getProductsList(
                  record.product_requests,
                  record.id,
                  expandedRowKeys,
                )"
                :key="product.product_name"
              >
                {{ product.product_name }}
              </span>
            </div>
          </template>
          <template v-else-if="column.key === 'created_at'">
            <div>
              {{ dayjs(record.created_at).format('DD.MM.YYYY') }}
            </div>
          </template>
          <template v-else-if="column.key === 'status'">
            <Space :size="4" direction="vertical">
              <Tag :color="priceStatusTag[record.status]?.color">{{
                priceStatusTag[record.status].text
              }}</Tag>
              До
              {{ dayjs(record.expiration_date).format('DD.MM.YYYY, HH:mm') }}
            </Space>
          </template>
          <template v-else-if="column.key === 'recipients'">
            <Space :size="4" direction="vertical">
              <Badge
                status="success"
                :text="`${!!record?.recipient_stats_in_price_request?.answered ? record?.recipient_stats_in_price_request?.answered : 0}/${!!record?.recipient_stats_in_price_request?.total ? record?.recipient_stats_in_price_request?.total : 0}`"
              />
              <Badge
                status="warning"
                :text="`${!!record?.recipient_stats_in_price_request?.pending ? record?.recipient_stats_in_price_request?.pending : 0}/${!!record?.recipient_stats_in_price_request?.total ? record?.recipient_stats_in_price_request?.total : 0}`"
              />
              <Badge
                status="error"
                :text="`${!!record?.recipient_stats_in_price_request?.refused ? record?.recipient_stats_in_price_request?.refused : 0}/${!!record?.recipient_stats_in_price_request?.total ? record?.recipient_stats_in_price_request?.total : 0}`"
              />
            </Space>
          </template>
          <template v-else-if="column.key === 'actions'">
            <Space :size="8">
              <!--              // TODO: Убрано из релиза-->
              <!--              <Button type="link">Повторить</Button>-->
              <!--              <Button type="link" danger>Отмена</Button>-->
            </Space>
          </template>
        </template>
      </Table>
      <InfiniteLoading
        :distance="100"
        class="infinite-loading"
        @infinite="increasePage"
      />
      <div v-if="isDataFetching" class="list-loader">
        <FullscreenLoading :type="'secondary'" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.customer-request-list {
  display: flex;
  flex-direction: column;
  gap: 24px 24px;
  max-height: 500px;
  margin-bottom: 20px;
  overflow: auto;

  :deep {
    .ant-table-content table {
      border: 1px solid #f0f0f0;
    }
  }

  &-table {
    height: 100%;
    min-height: 500px;
    margin-bottom: 20px;
  }

  .product-list {
    display: flex;
    flex-direction: column;
  }

  .rotate-btn {
    :deep(.anticon) {
      transform: rotate(0);
      transition: 0.2s ease-in;
    }
    &.isRotated {
      :deep(.anticon) {
        transform: rotate(180deg);
      }
    }
  }
  .list-loader {
    transform: scale(0.6);
    width: 100%;
    height: 70px;
    position: relative;
  }
}
</style>
