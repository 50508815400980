<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue'
import { required } from '@/helpers/validators'
import { requiredWithText } from '@/helpers/validators'
import { useCookies } from 'vue3-cookies'
import {
  checkboxStyleConfig,
  LabelCheckboxStyle,
} from '@/assets/EgalStyles/ECheckbox'
import { Input, Form, FormItem, Button } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { UserAPI } from '@/api/UserAPI'
import router from '@/router'
import { useAuth } from '@/composables/useAuth'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { phoneMask } from '@/types/globalConst'
import { onlyNumbersPhone, transformPhoneNumber } from '@/helpers/scripts'
import { useCaptcha } from '@/composables/useCaptcha'

const { loginIntoService } = useAuth()

interface FormState {
  id: string
  name: string
  position: string
  phone: string
  personal_data_consent: boolean
}

const formState = reactive<FormState>({
  id: '',
  name: '',
  position: '',
  phone: '',
  personal_data_consent: false,
})

const loginForm = reactive({
  email: '',
  password: '',
  captcha: null,
})

const { login, relogin } = useAuth()
const { decipherJWT } = useJWTDecode()
const { cookies } = useCookies()
const userData = ref()
const { recaptcha } = useCaptcha()

const validatePhone = async (_rule: Rule, value: string) => {
  if (onlyNumbersPhone(value).length != 11) {
    if (!value) {
      await Promise.resolve()
    } else return Promise.reject('Укажите номер из 11 цифр')
  } else return Promise.resolve()
}

const validateName = async (_rule: Rule, value?: string) => {
  if (!value) {
    return Promise.reject('Введите ФИО')
  }

  if (value.length > 255) {
    return Promise.reject('Максимум 255 символов')
  }
}

const validatePosition = async (_rule: Rule, value?: string) => {
  if (value && value.length > 255) {
    return Promise.reject('Максимум 255 символов')
  }
}

const rules: Record<string, Rule[]> = {
  name: [{ required: true, validator: validateName, trigger: 'change' }],
  position: [
    { required: false, validator: validatePosition, trigger: 'change' },
  ],
  phone: [{ required: false, validator: validatePhone, trigger: 'change' }],
}

const getId = async () => {
  let id = decipherJWT('auth').auth_information.id
  formState.id = id
}

const onFinish = async () => {
  UserAPI.updateUserData(formState).then(async () => {
    await relogin().then(async () => {
      cookies.remove('inviteStepOne')
      await router.push('/#invite')
    })
  })
}

const setLoginData = async () => {
  userData.value = cookies.get('registerForm')
  loginForm.email = userData.value.email
  loginForm.password = userData.value.password
  loginForm.captcha = await recaptcha()
}

const handleChangePhone = (e: any) => {
  formState.phone = transformPhoneNumber(e?.target?.value)
}

onMounted(async () => {
  await setLoginData()
  await login(loginForm, false).then(async (res) => {
    const umt = res.data.action_result.data.user_master_token
    localStorage.setItem('umt', umt)
    cookies.set('umt', umt, '365d', '')
    loginIntoService(umt, 'auth').then((res) => {
      let ust = res.data.action_result.data
      cookies.set('auth', ust, '365d', '')
      getId()
    })
  })
})
</script>

<template>
  <div class="auth-card">
    <div class="auth-card__header">
      Регистрация по приглашению
      <div class="step">
        <span>этап 2</span>
        <span class="max">/2</span>
      </div>
    </div>
    <div class="auth-card__mail-text">
      Почта была успешно подтверждена. Для завершения регистрации, укажите ваши
      персональные данные.
    </div>
    <div class="auth-card__body">
      <Form :rules="rules" :model="formState" @finish="onFinish">
        <FormItem label="ФИО" name="name" class="ym-record-keys">
          <Input v-model:value="formState.name" placeholder="Введите ФИО" />
        </FormItem>
        <div class="position-phone">
          <FormItem label="Должность" name="position">
            <Input
              v-model:value="formState.position"
              placeholder="Необязательно"
            />
          </FormItem>
          <FormItem label="Номер телефона" name="phone">
            <Input
              :value="formState.phone"
              @change="handleChangePhone"
              v-maska="{
                mask: phoneMask,
                preprocessor: (value: string) => transformPhoneNumber(value),
              }"
              placeholder="Необязательно"
            />
          </FormItem>
        </div>
        <div class="auth-card__checkbox">
          <ECheckbox
            :data="{
              checked: formState.personal_data_consent,
              required,
              validators: [requiredWithText('Подтвердите согласие')],
            }"
            :style-config="{ ...LabelCheckboxStyle, ...checkboxStyleConfig }"
            @change="
              () =>
                (formState.personal_data_consent =
                  !formState.personal_data_consent)
            "
            @error="(err: any) => (err.personal_data_consent = err)"
          >
            Я согласен с
            <a
              class="terms"
              href="https://storage.yandexcloud.net/farm-platform-production-images/privacy.pdf"
              target="_blank"
            >
              обработкой персональных данных
            </a>
          </ECheckbox>
        </div>
        <Button
          :disabled="!(formState.personal_data_consent && formState.name)"
          size="large"
          type="primary"
          html-type="submit"
        >
          Продолжить
        </Button>
      </Form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 604px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 48px;

  &__header {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__mail-text {
    margin-bottom: 32px;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .position-phone {
      display: flex;
      gap: 12px;
    }

    :deep(.ant-input) {
      height: 40px;
    }

    :deep(.ant-form label) {
      color: #718096;
    }

    :deep(.ant-form-item) {
      width: 100%;
      margin-bottom: 32px;
    }

    :deep(.ant-row) {
      display: block;
    }

    .password-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 41px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    span {
      color: $gray-800;
      margin-right: 5px;
    }

    a.terms {
      transform: translateY(1px);
      margin-left: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 1px;
      @include router-link();
      border-bottom: 1px solid $default-accent;
    }
  }
}
</style>
