<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref} from "vue";

const zoomLevel = computed(() => window.devicePixelRatio * 100);
const unitingBlockTitleClass = ref(zoomLevel.value >= 125 ? 'uniting-block__title_zoom-150' : 'uniting-block__title')

const calcClasses = () => {
  unitingBlockTitleClass.value = zoomLevel.value >= 125 ? 'uniting-block__title_zoom-150' : 'uniting-block__title'
}

onMounted(() => {
  window.addEventListener('resize', calcClasses);
})

onUnmounted(() => {
  window.removeEventListener('resize', calcClasses);
})
</script>

<template>
  <div :class="unitingBlockTitleClass">
    <div>
      <span>Объединяем</span>
      <span class="uniting-block__title_green">ритейлеров</span>
      <span>и</span>
    </div>
    <div>
      <span class="uniting-block__title_green">поставщиков продуктов питания</span>
    </div>
    <div>на одной платформе</div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/style/variables';

.uniting-block {
  width: 100%;
  &__title {
    // Nunito Sans
    font-family: 'Nunito Sans', serif !important;
    font-weight: 500;
    font-size: 37px; // 42px
    color: variables.$title-color;
    display: flex;
    flex-direction: column;
    gap: 20px; // 25px
    width: 100%;
    &_m {
      display: flex;
      padding-top: 20px;

    }
    &_green {
      font-family: 'Nunito Sans', serif !important;
      background-color: variables.$green-text-background;
      padding: 0 5px;
      margin: 0 5px;
      border-radius: 3px;
      transform: rotate(5deg);
    }
    div {
      display: block;
      font-family: 'Nunito Sans', serif !important;
      &:last-child {
        display: flex;
        margin-top: -10px;
      }

      & > span {
        font-family: 'Nunito Sans', serif !important;
      }
    }
    &_zoom-150 {
      // Nunito Sans
      font-family: 'Nunito Sans', serif !important;
      font-weight: 500;
      font-size: 32px; // 42px
      color: variables.$title-color;
      display: flex;
      flex-direction: column;
      gap: 20px; // 25px
      width: 100%;
      &_m {
        display: flex;
        padding-top: 20px;

      }
      &_green {
        font-family: 'Nunito Sans', serif !important;
        background-color: variables.$green-text-background;
        padding: 0 5px;
        margin: 0 5px;
        border-radius: 3px;
        transform: rotate(5deg);
      }
      div {
        display: block;
        font-family: 'Nunito Sans', serif !important;
        &:last-child {
          display: flex;
          margin-top: -10px;
        }

        & > span {
          font-family: 'Nunito Sans', serif !important;
        }
      }
    }
  }
}
</style>
