<script setup lang="ts">
import { Collapse, CollapsePanel } from 'ant-design-vue'


interface IProps {
  item: any
}

const emit = defineEmits([])
const props = defineProps<IProps>()
</script>

<template>
  <div class="faq-item" :key="props?.item?.id" :id="props?.item?.id">
    <div class="faq-item__title">
      {{ props?.item?.title }}
    </div>
    <div class="faq-item__content content">
      <div
        class="content__text"
        v-for="content in props?.item?.content"
        :key="content.id"
      >
        <Collapse
          v-if="content.type === 'collapse'"
        >
          <CollapsePanel
            :header="content.title"
          >
            <div
              v-for="item in content.content"
              :key="item"
            >
              <div v-if="item.type === 'text-bold'" class="content__text-bold">
                {{ item.value }}
              </div>
              <div v-if="item.type === 'text'" class="content__text">
                {{ item.value }}
              </div>
              <div
                v-if="item.type === 'divider'"
                :style="{ height: item.height }"
              />
              <img
                v-if="item.type === 'image'"
                :src="item.value"
                :alt="item.alt"
                class="content__image"
                loading="lazy"
              />
            </div>
          </CollapsePanel>
        </Collapse>
        <div v-if="content.type === 'text-bold'" class="content__text-bold">
          {{ content.value }}
        </div>
        <div v-if="content.type === 'text'" class="content__text">
          {{ content.value }}
        </div>
        <div
          v-if="content.type === 'divider'"
          :style="{ height: content.height }"
        />
        <div v-if="content.type === 'text-with-end-inner-link'">
          <div>
            {{ content.value }}
            <a :href="content.link" class="link">
              {{ content.linkText }}
            </a>
          </div>
        </div>
        <div v-if="content.type === 'text-with-end-action'">
          <div>
            {{ content.value }}
            <span @click="content?.action()" class="link">
              {{ content.linkText }}
            </span>
          </div>
        </div>
        <img
          v-if="content.type === 'image'"
          :src="content.value"
          :alt="content.alt"
          class="content__image"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.link {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(22, 119, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.faq-item {
  &:first-child {
    margin-top: 20px;
  }
  &__title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
}

.content {
  &__text {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    &-bold {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }
  }
  &__image {
    object-fit: cover;
    width: 100%;
  }
}
</style>
