<script lang="ts" setup>
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import PickupIcon from '@/components/Icons/PickupIcon.vue'
import {
  formatPrice,
  getStatusType,
  ISODateTransformerShort,
} from '@/helpers/scripts'
import FarmerDeliveryIcon from '@/components/Icons/FarmerDeliveryIcon.vue'
import router from '@/router'
import { IHistoryOrder, IOrder, IPartnership } from '@/types/interfaces'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import { computed } from 'vue'

interface IFarmerOrderRow {
  order: {
    id: number
    customer: {
      legal_form: string
      name: string
    }
    status: string
    price: number
    vat_price: number
    delivery_method: 'Самовывоз' | 'Доставка поставщиком'
    is_partner: boolean
    from_address: string
    general_order: {
      delivery_date: string
      order_date: string
      delivery_address: {
        street: string
      } | null
    }
    shipping_address: {
      street: string
    }
    partnership: IPartnership
    history: IHistoryOrder[]
  }
}
const props = defineProps<IFarmerOrderRow>()

const openOrderPage = () => {
  router.push(`/history/${props.order.id}`)
}

const get_price = (order: IOrder) => {
  if (
    order.status === NewGeneralOrderStatus.SupplierModifiedOrder &&
    order.history[0].initiator_type === 'customer'
  ) {
    return order.history[0].old_entity.price
  } else {
    return order.price
  }
}

const orderWasEditedByCustomer = computed(() => {
  if (!props.order?.history?.length) return false
  const order_was_edited =
    props.order.history[props.order.history.length - 1]?.initiator_type ===
      'customer' &&
    props.order.delivery_method !==
      props.order.history[props.order.history.length - 1]?.old_entity
        .delivery_method

  return (
    order_was_edited &&
    props.order.status === NewGeneralOrderStatus.SupplierModifiedOrder
  )
})

const dateWasEditedByCustomer = computed(() => {
  const order_was_edited =
    props.order.general_order?.history[
      props.order.general_order?.history.length - 1
    ]?.initiator_type === 'customer' &&
    props?.order?.general_order?.delivery_date !==
      props?.order?.general_order?.history[
        props.order.general_order.history.length - 1
      ]?.old_entity?.delivery_date

  return (
    order_was_edited &&
    props.order.status === NewGeneralOrderStatus.SupplierModifiedOrder
  )
})

const showDeliveryAddress = computed(() => {
  if (
    !orderWasEditedByCustomer.value &&
    props.order.delivery_method === 'Доставка фермером'
  ) {
    return props.order.delivery_address?.street
  }

  if (
    !orderWasEditedByCustomer.value &&
    props.order.delivery_method === 'Самовывоз'
  ) {
    return props.order.shipping_address.street
  }

  if (
    orderWasEditedByCustomer.value &&
    props?.order?.history[props.order.history.length - 1]?.old_entity
      ?.delivery_method === 'Доставка фермером'
  ) {
    return props?.order.history[props.order.history.length - 1]?.old_entity
      ?.delivery_address?.street
  }

  if (
    orderWasEditedByCustomer.value &&
    props?.order?.history[props.order.history.length - 1]?.old_entity
      ?.delivery_method === 'Самовывоз'
  ) {
    return props.order.shipping_address.street
  }
})

const deliveryMethod = computed(() => {
  return !orderWasEditedByCustomer.value
    ? props.order.delivery_method.split(' ')[0]
    : props.order?.history[
        props.order?.history.length - 1
      ]?.old_entity.delivery_method.split(' ')[0]
})
</script>

<template>
  <div class="farmer-order-row" v-if="order.status" @click="openOrderPage">
    <div class="farmer-order-cell">
      <div
        class="partnership-tooltip"
        v-if="order.partnership?.status === 'signed_customer'"
      >
        <PartnershipTooltip />
      </div>
      {{ order.customer.legal_form + ' ' + order.customer.name }}
      <SimpleRate :company_rating="order.customer.company_rating" />
    </div>
    <div class="farmer-order-cell">
      <CustomTag
        :shadow="false"
        :weight="'normal'"
        :text="order.status"
        :type="getStatusType(props.order.status)"
      />
    </div>
    <div class="farmer-order-cell">
      <div class="delivery-method-info">
        <PickupIcon
          v-if="
            order.delivery_method === 'Самовывоз' && !orderWasEditedByCustomer
          "
          :fill="'#2D3748'"
        />
        <FarmerDeliveryIcon
          v-if="
            order.delivery_method === 'Доставка фермером' &&
            !orderWasEditedByCustomer
          "
          :fill="'#2D3748'"
        />
        <PickupIcon
          v-if="
            order?.history[order.history.length - 1]?.old_entity
              .delivery_method === 'Самовывоз' && orderWasEditedByCustomer
          "
          :fill="'#2D3748'"
        />
        <FarmerDeliveryIcon
          v-if="
            order?.history[order.history.length - 1]?.old_entity
              .delivery_method === 'Доставка фермером' &&
            orderWasEditedByCustomer
          "
          :fill="'#2D3748'"
        />
        {{ deliveryMethod }}
      </div>
      <span class="cell-additional-info">
        {{ showDeliveryAddress }}
      </span>
    </div>
    <div class="farmer-order-cell">
      {{ ISODateTransformerShort(order.general_order.order_date) }}
    </div>
    <div class="farmer-order-cell">
      {{
        ISODateTransformerShort(
          dateWasEditedByCustomer
            ? props?.order.general_order?.history[
                props.order.general_order.history.length - 1
              ]?.old_entity.delivery_date
            : order.general_order.delivery_date,
        )
      }}
    </div>
    <div class="farmer-order-cell">
      {{ formatPrice(get_price(order as IOrder)) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.farmer-order-row {
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;
  padding: 20px 12px;
  display: grid;
  grid-template-columns: 2fr 270px 2fr 120px 120px 1fr;
  border-top: 1px solid $gray-300;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  .farmer-order-cell {
    padding: 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    position: relative;
    .partnership-tooltip {
      position: absolute;
      top: 0;
      left: 0;
    }
    &:last-child {
      font-weight: 700;
    }

    .delivery-method-info {
      display: flex;
      column-gap: 8px;
      margin-bottom: 4px;
      svg {
        transform: scale(1.2);
      }
    }
    .cell-additional-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: $gray-600;
      word-break: break-word;
    }
  }
}
</style>
