<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnershipStatus',
  props: {
    isPartner: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
</script>

<template>
  <div class="partnership-status">
    <img src="./icons/task.svg" v-if="isPartner" alt="icon" loading="lazy" />
  </div>
</template>

<style lang="scss">
//TODO: Дописать стили
.partnership-status {
}
</style>
