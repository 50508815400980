import {createPinia} from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { App as IApplication } from 'vue';

export const initPinia = (app: IApplication) => {
    const pinia = createPinia()
    console.debug('initPinia...')
    pinia.use(piniaPluginPersistedstate)
    app.use(pinia)
}
