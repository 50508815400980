import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class RankedFarmerAPIModel {
  async search(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.RankedFarmer,
      method: EgalMethods.search,
      data: { attributes: data, withs: ['farmer.company_rating'] },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const RankedFarmerAPI = new RankedFarmerAPIModel()
export { RankedFarmerAPI }
