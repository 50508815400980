import { IMark } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {APIModels} from "@/types/enums";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class OrderMarkAPIModel {
  async createMark(data: IMark): Promise<any> {
    return RequestManager.request({
      method: EgalMethods.Create,
      model: APIModels.OrderMark,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const orderMarkAPI = new OrderMarkAPIModel()
export { orderMarkAPI }
