<script setup lang="ts">
import { IOrder, IOrderPosition } from '@/types/interfaces'
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Flex,
  Form,
  FormItem,
  InputNumber,
  Radio,
  RadioGroup,
  Textarea,
} from 'ant-design-vue'
import { computed, onMounted, ref, watch } from 'vue'
import { width100 } from '@/model/constants'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import dayjs, { Dayjs } from 'dayjs'
import { Rule } from 'ant-design-vue/es/form'

interface IProps {
  order: IOrder | null
  open: boolean
  order_positions: IOrderPosition[]
}

interface IForm {
  delivery_method: null | 'Самовывоз' | 'Доставка'
  palletizing_type: null | 'standard' | 'euro'
  payment_type: null | 'cash' | 'non_cash'
  is_payment_delayed: boolean
  payment_delay_days: null | number
  delivery_payment: null | 'excluded' | 'included'
  need_unload: boolean
  delivery_date: null | Dayjs
  comment: string | null
}
const props = defineProps<IProps>()

const emit = defineEmits(['update:open', 'save_changes'])

const radioStyle = {
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
}

const form = ref()
const form_state = ref<IForm>({
  delivery_date: null,
  delivery_method: null,
  delivery_payment: null,
  is_payment_delayed: false,
  need_unload: false,
  palletizing_type: null,
  payment_delay_days: null,
  payment_type: null,
  comment: null,
})

const validateDelayDays = async (_rule: Rule, value: string) => {
  if (!form_state.value.is_payment_delayed) {
    return Promise.resolve()
  }
  if (Number(value) < 1 || Number(value) > 90) {
    return Promise.reject('Не меньше 1 и не больше 90 дней')
  } else {
    return Promise.resolve()
  }
}

const rules = computed(() => {
  return {
    payment_delay_days: [
      {
        required: form_state.value.is_payment_delayed,
        validator: validateDelayDays,
        message: 'Не меньше 1 и не больше 90 дней',
        type: 'number',
      },
    ],
    comment: [
      {
        max: 500,
        message: 'Максимум 500 символов',
      },
    ],
  }
})

const close_and_reset = () => {
  emit('update:open', false)
  set_form_state()
}

const set_form_state = () => {
  form_state.value = {
    delivery_date: dayjs(props.order?.general_order?.delivery_date),
    delivery_method: props.order?.delivery_method,
    delivery_payment: props.order?.delivery_payment,
    is_payment_delayed: props.order?.is_payment_delayed,
    need_unload: props.order?.need_unload,
    palletizing_type: props.order?.palletizing_type,
    payment_delay_days: props.order?.payment_delay_days,
    payment_type: props.order?.payment_type,
    comment: props.order?.comment,
  }
}

const handle_delivery_type = (event: any) => {
  if (event.target.value === 'Самовывоз') {
    form_state.value.delivery_payment = null
  } else {
    form_state.value.delivery_payment = 'included'
  }
}

const handle_is_payment_delayed = (e: any) => {
  if (!e.target.checked) {
    form_state.value.payment_delay_days = null
    form.value.validate()
  }
}

const handle_standart_paletizing = (e: any) => {
  form_state.value.palletizing_type = e.target.checked ? 'standard' : null
}
const handle_euro_paletizing = (e: any) => {
  form_state.value.palletizing_type = e.target.checked ? 'euro' : 'standard'
}

const save_changes = () => {
  form.value.validate().then(() => {
    emit('save_changes', {
      ...form_state.value,
      id: props.order?.id,
      delivery_date: dayjs(form_state.value.delivery_date).format('YYYY-MM-DD'),
      comment: form_state.value.comment || null,
      payment_delay_days: form_state.value.payment_delay_days || null,
    })
  })
}

const parameteres_the_same = computed(
  () =>
    dayjs(form_state.value.delivery_date).format('YYYY-MM-DD') ===
      props.order?.general_order?.delivery_date &&
    form_state.value.delivery_method === props.order?.delivery_method &&
    form_state.value.delivery_payment === props.order?.delivery_payment &&
    form_state.value.is_payment_delayed === props.order?.is_payment_delayed &&
    form_state.value.need_unload === props.order?.need_unload &&
    form_state.value.palletizing_type === props.order?.palletizing_type &&
    form_state.value.payment_delay_days === props.order?.payment_delay_days &&
    form_state.value.payment_type === props.order?.payment_type &&
    form_state.value.comment === props.order?.comment,
)

const disabled_time = (current: Dayjs) => {
  const max_prepared_days =
    Math.max(
      ...props.order_positions.map(
        (position) => position.farmer_product.prepare_days,
      ),
    ) + 1
  const disabled_date = dayjs(props.order?.general_order?.created_at).add(
    max_prepared_days,
    'day',
  )

  return current && current.isBefore(disabled_date, 'day')
}

const is_complex_order = computed(() => {
  return props.order?.general_order.orders.length > 1
})

const address_warning_message = computed(() => {
  if (props.order?.general_order.delivery_address) {
    return `Адрес доставки: ${props.order?.general_order.delivery_address.street}`
  } else {
    return 'Покупатель укажет адрес доставки после того, как вы отправите заказ на согласование'
  }
})

watch(
  () => props.open,
  (new_value) => {
    if (new_value) {
      set_form_state()
    }
  },
)

watch(
  () => form_state.value.is_payment_delayed,
  () => {
    if (!form_state.value.is_payment_delayed) {
      form.value.validate()
    }
  },
)
</script>

<template>
  <Drawer
    :open="open"
    :bodyStyle="{ padding: '24px 32px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :footer-style="{ padding: '24px 32px' }"
    :closable="true"
    :width="500"
    @close="close_and_reset"
    :title="`Заказ от ${order?.customer.legal_form} ${order?.customer.name}`"
    class="drawer-reverse-header"
  >
    <Flex :gap="32" vertical>
      <Form :model="form_state" ref="form" :rules="rules">
        <FormItem name="delivery_date">
          <Flex :gap="8" vertical>
            <span class="form-title">Дата получения</span>
            <DatePicker
              size="large"
              v-model:value="form_state.delivery_date"
              :show-today="false"
              :disabled-date="disabled_time"
              :locale="locale"
              placeholder="Дата и время"
              format="DD.MM.YYYY"
              :inputReadOnly="true"
              :disabled="is_complex_order"
            />
            <span v-if="is_complex_order" class="help-text">
              Невозможно изменить дату получения, потому что в заказе
              присутствуют несколько поставщиков
            </span>
          </Flex>
        </FormItem>

        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title"> Способ получения </span>
            <RadioGroup
              v-model:value="form_state.delivery_method"
              @change="handle_delivery_type"
            >
              <Radio :style="radioStyle" value="Самовывоз">Самовывоз</Radio>
              <Radio
                :style="radioStyle"
                value="Доставка фермером"
                :disabled="!order?.farmer.can_deliver"
                >Доставка от поставщика</Radio
              >
            </RadioGroup>
            <RadioGroup
              v-model:value="form_state.delivery_payment"
              class="second-level"
              :disabled="form_state.delivery_method !== 'Доставка фермером'"
            >
              <Radio :style="radioStyle" value="included"
                >Включена в стоимость товара</Radio
              >
              <Radio :style="radioStyle" value="excluded"
                >Оплачивается отдельно</Radio
              >
            </RadioGroup>

            <Alert
              v-if="form_state.delivery_method === 'Доставка фермером'"
              type="info"
              show-icon
              :message="address_warning_message"
            />
          </Flex>
        </FormItem>

        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title">Условия оплаты</span>
            <RadioGroup v-model:value="form_state.payment_type">
              <Radio :style="radioStyle" value="non_cash"
                >Безналичный расчёт</Radio
              >
              <Radio :style="radioStyle" value="cash">Наличный расчёт</Radio>
            </RadioGroup>
            <Checkbox
              v-model:checked="form_state.is_payment_delayed"
              @change="handle_is_payment_delayed"
            >
              Нужна отсрочка по оплате
            </Checkbox>
            <FormItem name="payment_delay_days">
              <InputNumber
                v-model:value="form_state.payment_delay_days"
                :controls="false"
                placeholder="Укажите кол-во дней отсрочки"
                :disabled="!form_state.is_payment_delayed"
                class="payment-delay-days-input"
                :style="width100"
                :precision="0"
              />
            </FormItem>
          </Flex>
        </FormItem>

        <FormItem name="delivery_method">
          <Flex :gap="8" vertical>
            <span class="form-title">Дополнительные услуги</span>
            <Checkbox
              :checked="
                form_state.palletizing_type === 'standard' ||
                form_state.palletizing_type === 'euro'
              "
              @change="handle_standart_paletizing"
            >
              Паллетирование
            </Checkbox>
            <Checkbox
              :checked="form_state.palletizing_type === 'euro'"
              @change="handle_euro_paletizing"
              :disabled="!form_state.palletizing_type"
              class="second-level"
            >
              Европалеты
            </Checkbox>
            <Checkbox v-model:checked="form_state.need_unload">
              Разгрузка товара поставщиком
            </Checkbox>
          </Flex>
        </FormItem>

        <FormItem name="comment">
          <Textarea
            v-model:value="form_state.comment"
            :rows="4"
            placeholder="Комментарий к заказу, необязательно"
          />
        </FormItem>
      </Form>
    </Flex>

    <template #footer>
      <Flex :gap="12" justify="flex-end">
        <Button size="large" :onclick="close_and_reset">Закрыть</Button>
        <Button
          :disabled="parameteres_the_same"
          type="primary"
          size="large"
          :onClick="save_changes"
          >Сохранить изменения
        </Button>
      </Flex>
    </template>
  </Drawer>
</template>

<style scoped lang="scss">
.form-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.help-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #00000073;
}

.second-level {
  margin-left: 24px;
}
</style>
