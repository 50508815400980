<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { catalogStore } from '@/store/catalogStore'
import InfiniteLoading from 'v3-infinite-loading'
import router from '@/router'
import { getEnding } from '@/helpers/scripts'
import { IProduct } from '@/types/interfaces'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import EmptyForFilter from '@/components/EmptyForFilter/EmptyForFilter.vue'
import { roundNumber } from '@/helpers/scripts'

interface Props {
  onClearFilters: () => void
}

const props = defineProps<Props>()

const useCatalogStore = catalogStore()

let scroll = ref(null as HTMLElement | null)

const load = async () => {
  useCatalogStore.page++
  await useCatalogStore.getProductsWithFilter(
    useCatalogStore.idSubCategory || 0,
    useCatalogStore.getPage,
    [useCatalogStore.filterPart],
  )
}
const scrollBlock = ref<HTMLElement | null>(null)

onBeforeUnmount(() => {
  scrollBlock.value?.removeEventListener('scroll', saveYScroll)
  useCatalogStore.page = 1
})

const scrollY = ref(0)
const scrollTo = (scrollValue: number) => {
  setTimeout(() => {
    scrollBlock.value?.scrollTo({
      top: scrollValue,
      behavior: 'smooth',
    })
  }, 250)
}

const saveYScroll = () => {
  scrollY.value = scrollBlock.value?.scrollTop || 0
}

onMounted(() => {
  if (useCatalogStore.needToScroll) {
    scrollTo(useCatalogStore.scrollPosition)
    useCatalogStore.toggleScroll()
  }
  scrollBlock.value?.addEventListener('scroll', saveYScroll)
})

const clickHandler = (id: number) => {
  if (id) {
    useCatalogStore.setScrollPosition(scrollY.value)
    router.push(`/catalog/${id}`)
  }
}

const getFullName = (item: IProduct) => {
  if (!item.parameters_bin) return item.categories
  return `${item.categories.split('|').join(', ')}, ${handleWriteCase(item.parameters_bin.split('|')).join(', ')}`
}

const handleWriteCase = (array: string[]) =>
  array.map((item) => {
    if (
      item.charAt(0) === item.charAt(0).toUpperCase() &&
      item.charAt(1) === item.charAt(1).toUpperCase()
    ) {
      return item
    }
    return item.toLowerCase()
  })
</script>

<template>
  <div ref="scrollBlock" class="products">
    <ul
      class="products-list"
      ref="scroll"
      v-if="useCatalogStore.getProductsItems.length"
    >
      <li
        @click="clickHandler(item.id)"
        v-for="item in useCatalogStore.getProductsItems"
        class="products-item"
        :class="item.count < 1 ? 'not-active' : ''"
        :key="item.id"
      >
        <div class="products-item-title">
          {{ getFullName(item) }}
        </div>
        <div
          class="products-item-info"
          v-if="item.max_price === item.min_price"
        >
          <span class="products-item-info-count"
            >{{ item.count }} предложен{{ getEnding(Number(item.count)) }}</span
          >
          <span class="products-item-info-price"
            >{{ roundNumber(item.min_price) }} &#8381;</span
          >
        </div>
        <div class="products-item-info" v-else>
          <span class="products-item-info-count"
            >{{ item.count }} предложен{{ getEnding(Number(item.count)) }}</span
          >
          <span class="products-item-info-price"
            >от {{ roundNumber(item.min_price) }} до {{ roundNumber(item.max_price) }} &#8381;</span
          >
        </div>
      </li>
    </ul>
    <EmptyForFilter v-else @resetFilters="props.onClearFilters" />
    <InfiniteLoading
      v-if="
        !useCatalogStore.isRefetching && useCatalogStore.getProductsItems.length
      "
      class="infinite-loading"
      @infinite="load"
      :distance="100"
    />
    <div class="list-loader" v-if="useCatalogStore.isMoreFetching">
      <FullscreenLoading :type="'secondary'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.products {
  width: 100%;
  padding: 0 20px;
  height: 80vh;
  &-list {
    width: 100%;
    //overflow-y: auto;
  }
  &-item {
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid $gray-300;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: #fafafa;
    }
    &:active {
      background-color: #e8e8e8;
    }

    &:last-child {
      border: none;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      text-align: left;
    }

    &.not-active {
      pointer-events: none;
      cursor: default;
    }
  }
  &-item-info-count {
    font-size: 1rem;
    color: $gray-700;
    margin-right: 30px;
  }
  &-item-info-price {
    background-color: $gray-300;
    color: $gray-700;
    padding: 3px 6px;
    border-radius: 8px;
    white-space: nowrap;
  }
  &-item-empty {
    display: flex;
    align-items: center;
    &-img {
      width: 20px;
      margin-right: 10px;
    }
    &-text {
      color: $gray-700;
      font-size: 1rem;
    }
  }
}
.list-loader {
  width: 100%;
  position: relative;
  transform: scale(0.6);
  padding: 30px 0;
}
</style>
