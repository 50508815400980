import gsap from "gsap";

const SECTION_ANIMATION_IN_OUT = { opacity: 1, x: 0, duration: 2, ease: 'power2.inOut' }
const SECTION_ANIMATION_OUT = { opacity: 1, x: 0, duration: 2, ease: 'power2.out' }

export const gsapScrollAnimation = (index: number) => {
    if (gsap) {
        gsap.set(`.section-${index}`, { opacity: 0, x: '-100%' })
        gsap.to(`.section-${index}`, SECTION_ANIMATION_IN_OUT)
    }
}

export const setScrollOnFirstSection = async () => {
    if (gsap) {
        gsap.set('.map-block', { opacity: 0, x: '100%' })
        gsap.set('.section-0', { opacity: 0, x: '-100%' })
        gsap.to('.section-0', SECTION_ANIMATION_OUT)
        gsap.to('.map-block', SECTION_ANIMATION_IN_OUT)
    }
}

export const scrollTriggerOnEnter = (index: number, section: any, sectionElements: any[], scrollToSection: Function) => {
    // Когда секция входит в видимость
    if (sectionElements) {
        sectionElements.forEach((s) => s.classList.remove('active'))
    }
    addSectionClassActive(section)
    scrollToSection(index + 1)
    gsapScrollAnimation(index + 1)
}

export const scrollTriggerOnLeave = (index: number, section: any) => {
    // Когда секция уходит из видимости
    removeSectionClassActive(section)
    gsapScrollAnimation(index)
}

export const scrollTriggerOnEnterBack = (index: number, section: any, sectionElements: any[], scrollToSection: Function) => {
    // Когда секция возвращается в видимость при прокрутке вверх
    if (sectionElements) {
        sectionElements.forEach((s) => s.classList.remove('active'))
    }
    section.classList.add('active')
    scrollToSection(index)
    gsapScrollAnimation(index)
}

export const scrollTriggerOnLeaveBack = (section: any) => {
    // Когда секция уходит из видимости при прокрутке вверх
    section.classList.remove('active')
}

export const addSectionClassActive = (section: any) => section.classList.add('active')

export const removeSectionClassActive = (section: any) => section.classList.remove('active')
