<script setup lang="ts">
import { Flex, TabPane, Tabs } from 'ant-design-vue'
import { IAddress, IContact, IUser } from '@/types/interfaces'
import {computed, onMounted, ref} from 'vue'
import PartnerViewHeader from '@/components/Partner/PartnerViewHeader.vue'
import { UserAPI } from '@/api/UserAPI'
import { ContactAPI } from '@/api/ContactAPI'
import { AddressAPI } from '@/api/AddressAPI'
import { useRoute } from 'vue-router'
import { Roles } from '@/types/enums'
import { useJWTDecode } from '@/composables/useJWTDecode'
import OrganizationDataPane from '@/components/Partner/OrganizationDataPane.vue'
import CatalogPane from '@/components/Partner/CatalogPane.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { usePartnersStore } from '@/store/usePartnersStore'
import emitter from "@/emitter";

type TTabKey = 'organization' | 'catalog'

const user = ref<IUser | null>(null)
const addresses = ref<IAddress[] | null>(null)
const contacts = ref<IContact[] | null>(null)
const route = useRoute()
const is_fetching = ref(false)

const partnersStore = usePartnersStore()

const partners = computed(() => {
  return partnersStore.partners
})

const isPartner = computed(() => {
  return partners.value?.some((el: { company_id: number }) => {
    return Number(route?.params?.id) === el?.company_id
  })
})

const activeKey = ref( isPartner.value ? 'organization' : localStorage.getItem('partnersTab') as TTabKey)

const { getCoreCookie } = useJWTDecode()

emitter.on('refresh-token', async (e) => {
  await initPage()
})

const initPage = async () => {
  const is_customer = getCoreCookie()?.company_type === Roles.Customer
  const opposite_role = route.query?.role ?? is_customer ? Roles.Farmer : Roles.Customer
  const company_id = route.params.id || Number(localStorage.getItem('currentPartnerId'))

  await UserAPI.getItemsCore(1, 1, [
    ['company_id', 'eq', company_id],
    'AND',
    ['type', 'eq', opposite_role],
  ], [
    'company',
    'company.users',
  ]).then((res) => {
    user.value = res.items[0]
  })

  await AddressAPI.getItems(1, 100, [
    ['company_id', 'eq', company_id],
    'AND',
    ['company_type', 'eq', opposite_role],
    'AND',
    ['is_deleted', 'eq', false],
  ]).then(
      (res) => {
        addresses.value = res.items
      },
  )

  await ContactAPI.getItems(1, 100, [
    ['company_id', 'eq', company_id],
    'AND',
    ['company_type', 'eq', opposite_role],
  ]).then(
      (res) => {
        contacts.value = res.items
      },
  )

  activeKey.value = isPartner.value ? 'organization' : localStorage.getItem('partnersTab') as TTabKey
}

onMounted(async () => {
  await initPage()
  is_fetching.value = false
})

</script>

<template>
  <div class="wrapper">
    <Flex
      :gap="24"
      vertical
      v-if="!is_fetching && user && addresses"
    >
      <PartnerViewHeader :key="user?.id"
        :company="user?.company!"
        :role="user?.type"
        :isPartner="isPartner"
      />
      <Tabs v-model:activeKey="activeKey">
        <TabPane key="organization" tab="Данные организации">
          <OrganizationDataPane :key="user?.id"
            :user="user"
            :addresses="addresses ? addresses : []"
            :contacts="contacts"
            :isPartner="isPartner"
          />
        </TabPane>
        <TabPane
          key="catalog"
          v-if="user?.type === 'farmer'"
          tab="Каталог поставщика"
        >
          <CatalogPane :key="user?.id" />
        </TabPane>
      </Tabs>
    </Flex>
    <FullscreenLoading :type="'secondary'" v-else />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.wrapper {
  @include wrapper();
  padding: 32px 0;
}
:deep(.ant-tabs-nav) {
  margin-bottom: 0;
}
</style>
