<script lang="ts" setup>
import { computed } from 'vue'
import {
  Row,
  Col,
  TypographyTitle as TgTitle,
  Space,
  Flex,
} from 'ant-design-vue'
import {
  declensionDays,
  formatNumber,
  clearPriceWithVat,
  roundNumber, fillMeasurementUnitIdCommon,
} from '@/helpers/scripts'
import QuantityExceededTooltip from '@/components/Tooltip/QuantityExceededTooltip.vue'
import { IProductItem } from '@/store/registrationOrderStore'
import { width100 } from '@/model/constants'
import {IFarmerProduct} from "@/types/interfaces";

interface Props {
  productItem: IProductItem
}

const props = defineProps<Props>()

const cartSum = computed(() => {
  return formatNumber(
    roundNumber(
      +props.productItem.quantity *
        +props.productItem.chosen_price_option.price,
    ),
  )
})

const isItemOverStock = computed(() => {
  return +props.productItem.quantity > +props.productItem.farmer_product.quantity
})

</script>

<template>
  <Row :gutter="[24, 24]" class="cart-product-item" :wrap="false">
    <Col :flex="'120px'">
      <img
        :src="props.productItem.farmer_product.image_url"
        alt="img"
        v-if="props.productItem.farmer_product.image_url"
        width="100"
        height="100"
        class="product-image"
        loading="lazy"
      />
      <div class="product-cover-block" v-else />
    </Col>
    <Col :flex="'58%'">
      <TgTitle :level="4"> {{ productItem.farmer_product.fullName }}</TgTitle>
      <Space :size="12" direction="vertical" class="product-characteristics">
        <div class="product-characteristics-item">
          <span class="characteristic-name">Мин. заказ</span
          ><span>{{
            `${productItem.farmer_product.min_value} ${fillMeasurementUnitIdCommon(
                (productItem.farmer_product as IFarmerProduct)?.fullName ?? '',
                productItem.farmer_product.measurement_unit_id ?? null,
            )}`
          }}</span>
        </div>
        <div class="product-characteristics-item":class="isItemOverStock && 'over-stock'">
          <span class="characteristic-name">В наличии</span>
          <span>
            {{
              `${productItem.farmer_product.quantity} ${fillMeasurementUnitIdCommon(
                  (productItem.farmer_product as IFarmerProduct)?.fullName ?? '',
                  productItem.farmer_product.measurement_unit_id ?? null,
              )}`
            }}
            <QuantityExceededTooltip :show="isItemOverStock" />
          </span>

        </div>
        <div class="product-characteristics-item">
          <span class="characteristic-name">Время подготовки</span
          ><span>{{
            `${productItem.farmer_product.prepare_days} ${declensionDays(productItem.farmer_product.prepare_days)}`
          }}</span>
        </div>
      </Space>
    </Col>
    <Col :flex="'auto'" class="product-price-block">
      <Row :gutter="[0, 8]">
        <Col :span="24">
          <Flex justify="end">
            <h4>
              {{ `${cartSum} ₽` }}
            </h4>
          </Flex>
        </Col>
        <Col :span="24">
          <Space :size="4" direction="vertical" :style="width100">
            <p class="cart-product-price-item right">
              {{
                `${productItem.quantity} ${fillMeasurementUnitIdCommon(
                    (productItem.farmer_product as IFarmerProduct)?.fullName ?? '',
                    productItem.farmer_product.measurement_unit_id ?? null,
                )}`
              }}
            </p>
          </Space>
        </Col>
      </Row>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.cart-product-item {
  flex-grow: 1;
  .product-image {
    border-radius: 8px;
  }
  .product-cover-block {
    background-color: #f7fafc;
    height: 100px;
    width: 100px;
    background-image: url('@/assets/img/product_cover.png');
    background-repeat: no-repeat;
    background-size: 70px;
    background-position: center center;
    border-radius: 8px;
  }

  .product-price-block {
    border-left: 1px solid #0000000f;
    padding-left: 24px !important;
    .cart-product-price-item {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      &.right {
        text-align: right;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #000000e0;
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #000000e0;
    }
  }
  .product-characteristics {
    margin-top: 6px;
    &-item {
      display: flex;
      gap: 12px;
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #000000e0;
        &.characteristic-name {
          font-weight: 400;
          color: #000000a6;
        }
      }
    }
  }
  .over-stock {
    span.characteristic-name{
      color: #FAAD14;
    }
    span {
      color: #FAAD14;
    }
    color: #FAAD14;
  }
}
</style>
