<script setup lang="ts">
import { computed } from 'vue'
import { IAuctionProductRequest, IFarmerProduct } from '@/types/interfaces'
import {
  Col,
  Divider,
  Empty,
  List,
  ListItem,
  Row,
  Space,
  Tag,
  TypographyText as TgText,
  TypographyTitle as TgTitle,
} from 'ant-design-vue'
import {
  fillMeasurementUnitIdCommon,
  roundNumber,
} from '@/helpers/scripts'
import { RUBLE } from '@/model/constants'

interface IProps {
  products: IAuctionProductRequest[]
}
const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE
const props = defineProps<IProps>()
const emits = defineEmits(['setSelectedProduct'])
const getProductParameters = (product: IAuctionProductRequest) => {
  const result: string[] = []
  const measurementUnit = fillMeasurementUnitIdCommon(
    product.farmer_product_name,
    product.farmer_product?.measurement_unit_id ?? null,
  )
  result.push(`Объем ${product.volume} ${measurementUnit}`)

  product.remaining_shelf_life &&
    result.push(`Срок хранения ${product.remaining_shelf_life}%`)
  product.is_sample_requested && product.requested_samples_number &&
    result.push(
      `Пробный образец ${product.requested_samples_number ? `${product.requested_samples_number} ${measurementUnit}` : ''}`,
    )
  return result
}

const notDeletedProducts = computed(() =>
  props.products.filter((product) => !product.is_deleted),
)
</script>

<template>
  <Row :gutter="[24, 2]" :style="{ alignContent: 'flex-start' }">
    <Col :span="24">
      <TgTitle :level="4">Товары в торге на продажу</TgTitle>
    </Col>
    <Col :span="24">
      <List :data-source="notDeletedProducts" v-if="notDeletedProducts.length">
        <template #renderItem="{ item }">
          <ListItem>
            <Space direction="vertical" :size="4">
              <TgText
                class="like-link"
                @click="emits('setSelectedProduct', item.farmer_product)"
                >{{ item.farmer_product_name }}</TgText
              >
              <div class="product-parameters-row">
                <span
                  v-for="(productParameter, index) in getProductParameters(
                    item,
                  )"
                  :key="productParameter"
                >
                  {{ productParameter }}
                  <Divider
                    type="vertical"
                    v-if="index < getProductParameters(item).length - 1"
                  />
                </span>
              </div>
              <div>
                <Tag
                  >Стартовая цена {{ roundNumber(item.start_price) }} {{ RUBLE }}/{{
                    item.farmer_product.measurement_unit_id
                  }}</Tag
                >
                <Tag>НДС {{ item.vat }}%</Tag>
              </div>
            </Space>
          </ListItem>
        </template>
      </List>
      <div v-else>
        <Empty
          :image="emptyImage"
          class="empty-view"
          :imageStyle="{
            width: '184px',
            height: '117px',
            margin: '0 auto 24px auto',
          }"
        >
          <template #description>
            <Space direction="vertical" :size="0">
              <TgTitle :level="4">Товары отсутствуют</TgTitle>
              <span class="empty-description">
                Вы удалили товары, которые были в этом торге на продажу
              </span>
            </Space>
          </template>
        </Empty>
      </div>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
:deep(.ant-list-item) {
  padding-left: 0;
}
.product-parameters-row {
  display: flex;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}

.empty-view {
  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
  :deep(.ant-empty-image svg) {
    width: 184px;
    height: 117px;
  }
}
</style>
