<script lang="ts" setup>
import { Divider, Flex, TypographyText as TpText } from 'ant-design-vue'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'

interface IProps {
  name: string
  volume: number
  measurement_unit_id: string
  remaining_shelf_life: number
  is_sample_needed: boolean
  needed_samples_number?: number
  isProductNameLink?: boolean
}

defineProps<IProps>()
const emit = defineEmits(['onClickName'])

</script>

<template>
  <Flex :gap="4" vertical>
    <TpText
      @click="emit('onClickName')"
      :class="{ 'like-link': isProductNameLink }"
      strong
      >{{ name }}</TpText
    >
    <Flex class="product__properties" align="center">
      <TpText>
        {{
          `Объём ${volume} ${fillMeasurementUnitIdCommon(name, measurement_unit_id)}` 
        }}
      </TpText>
      <Divider type="vertical" v-if="remaining_shelf_life" />
      <TpText v-if="remaining_shelf_life">
        {{ `Срок хранения ${remaining_shelf_life}%` }}
      </TpText>
      <Divider v-if="is_sample_needed" type="vertical" />
      <TpText v-if="is_sample_needed && needed_samples_number">
        {{
          `Пробный образец ${needed_samples_number ? `${needed_samples_number} ${fillMeasurementUnitIdCommon(name, measurement_unit_id)}` : ''} `
        }}
      </TpText>
    </Flex>
  </Flex>
</template>

<style lang="scss" scoped>
.product {
  &__properties {
    span {
      font-size: 12px;
    }
  }
}
</style>
