import { defineStore } from 'pinia'
import { AuctionAPI } from '@/api/AuctionAPI'
import { IAuction } from '@/types/interfaces'

interface IAuctionStore {
  auctions: IAuction[]
  isFetching: boolean
  isMoreFetching: boolean
  page: number
  defaultFilters: (string | number)[][]
}

export const auctionStore = defineStore('auctionStore', {
  state: (): IAuctionStore => ({
    auctions: [],
    isFetching: false,
    isMoreFetching: false,
    page: 1,
    defaultFilters: [],
  }),
  actions: {
    switchAuction(id: number) {
      this.auctions = this.auctions.map((auction) => {
        if (auction.id === id) {
          return {
            ...auction,
            switch: !!0,
          }
        }
        return auction
      })
    },
    async getAuctions() {
      const isMoreFetching = this.page > 1
      if (isMoreFetching) {
        this.isMoreFetching = true
      } else {
        this.isFetching = true
      }
      try {
        const res = await AuctionAPI.getItemsWithBetter(
          this.page,
          [...this.defaultFilters],
          [
            'best_customer',
            'farmer',
            'farmer.company_rating',
            'auction_product_requests',
            'auction_participants',
              'mergedRecipients.customer',
          ],
        )
        if (isMoreFetching) {
          this.auctions = [...this.auctions, ...res?.items]
        } else {
          this.auctions = res?.items
        }
        this.isFetching = false
        this.isMoreFetching = false
      } catch (error) {
        this.isFetching = false
        this.isMoreFetching = false
        console.error(error)
      }
    },
    increasePage() {
      this.page += 1
      this.getAuctions()
    },
    setDefaultFilters(filters: (string | number)[][]) {
      this.defaultFilters = [...filters]
    },
    $reset() {
      this.auctions = []
      this.isFetching = false
      this.isMoreFetching = false
      this.page = 1
      this.defaultFilters = []
    },
  },
})
