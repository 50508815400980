import { TUserStatus } from '@/types/types'

export const paymentType = {
  cash: 'Наличный расчёт',
  non_cash: 'Безналичный расчёт',
}

export const deliveryPayment = {
  included: 'Доставка включена в стоимость товаров',
  excluded: 'Доставка оплачивается отдельно',
}

export const palletizing_type = {
  standard: 'Паллетирование',
  euro: 'Европаллеты',
}

export const role_type = {
  customer: 'Покупатель',
  farmer: 'Поставщик',
}

export const vat_type = {
  true: 'Налогообложение по НДС',
  false: 'Налогообложение без НДС',
}

export const user_status_type: Record<TUserStatus, string> = {
  need_verify: 'Ожидает проверки администратора',
  active: 'Верифицирован',
  confirmed: 'Заявка на верификацию не отправлена',
  need_confirm: 'Данные организации не подтверждены',
  not_confirmed: 'Проверяем данные организации',
  incorrect_data: 'Некорректные данные организации',
  registration_not_confirmed: 'Неподтверждённый аккаунт',
  no_company: 'Нет данных о компании',
  banned: 'Заблокирован',
}
