<script setup lang="ts">
import { computed } from 'vue'
import { IFarmerProduct, IReductionProductRequest } from '@/types/interfaces'
import {
  Row,
  Col,
  TypographyTitle as TgTitle,
  TypographyText as TgText,
  List,
  ListItem,
  Space,
  Divider,
} from 'ant-design-vue'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'
interface IProps {
  products: IReductionProductRequest[]
}

const props = defineProps<IProps>()
const getProductParameters = (product: IReductionProductRequest) => {
  const result: string[] = []
  const measurementUnit = fillMeasurementUnitIdCommon(
    (product.product as IFarmerProduct)?.fullName ?? '',
    product.product?.measurement_unit_id ?? null,
  )
  result.push(`Объем ${product.volume} ${measurementUnit}`)

  product.remaining_shelf_life &&
    result.push(`Срок хранения ${product.remaining_shelf_life}%`)

  product.is_sample_needed &&
    product.needed_samples_number &&
    result.push(
      `Пробный образец ${product.needed_samples_number ? `${product.needed_samples_number} ${measurementUnit}` : ''}`,
    )
  return result
}

const sortedProducts = computed(() => {
  return [...props.products].sort((a, b) =>
    a.product_name.localeCompare(b.product_name),
  )
})
</script>

<template>
  <Row :gutter="[24, 2]" :style="{ alignContent: 'flex-start' }">
    <Col :span="24">
      <TgTitle :level="4">Товары в торге на покупку</TgTitle>
    </Col>
    <Col :span="24">
      <List :data-source="sortedProducts">
        <template #renderItem="{ item }">
          <ListItem>
            <Space direction="vertical" :size="4">
              <TgText>{{ item.product_name }}</TgText>
              <div class="product-parameters-row">
                <span
                  v-for="(productParameter, index) in getProductParameters(
                    item,
                  )"
                  :key="productParameter"
                >
                  {{ productParameter }}
                  <Divider
                    type="vertical"
                    v-if="index < getProductParameters(item).length - 1"
                  />
                </span>
              </div>
            </Space>
          </ListItem>
        </template>
      </List>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
:deep(.ant-list-item) {
  padding-left: 0;
}
.product-parameters-row {
  display: flex;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}
</style>
