<script setup lang="ts">
import router from '@/router'
import { useRoute } from 'vue-router'
import { Flex, Space, Tag, Button } from 'ant-design-vue'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import { ICustomer, IFarmer } from '@/types/interfaces'
import { computed, onMounted, ref } from 'vue'
import { TUsersRole } from '@/types/types'
import { LeftOutlined } from '@ant-design/icons-vue'
import { chatStore } from '@/store/chatStore'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {useCookies} from "vue3-cookies";

interface IProps {
  company: IFarmer | ICustomer
  role: TUsersRole
  isPartner: boolean
}

const route = useRoute()
const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()
const backPath = ref<string>('partners')
const { createChat, setFarmerUIDs } = chatStore()

const props = defineProps<IProps>()
const name = computed(() => {
  if (!props.company) return 'Загрузка'
  return `${props.company.legal_form} «${props.company.name}»`
})

const handleGoBack = () => {
  router.push(`/${backPath.value}`)
}

const getUserName = () => {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const { user_need_accept, name, legal_form } = JSON.parse(userData)
    if (user_need_accept !== null) {
      return `${name}`
    } else {
      return `${legal_form} ${name}`
    }
  }
}

const handleClickChat = () => {
  const company1 = `${props.company.legal_form} ${props.company.name}`
  const company2 = getUserName()

  const calcNameChat = () => {
    if (company1 && company2) {
      return company2 + ' - ' + company1
    } else if (company1) {
      return company1
    } else if (company2) {
      return company2
    } else {
      return ''
    }
  }

  const name = calcNameChat()
  createChat(name)
}

onMounted(() => {
  if (route.query.redirectPath) {
    backPath.value = route.query.redirectPath as string
    router.push({ path: route.path })
  }
  setFarmerUIDs(props?.company?.users?.map((user: any) => user.id))
})
</script>

<template>
  <Flex justify="space-between">
    <Space :size="16">
      <Button @click="handleGoBack"
        ><template #icon> <LeftOutlined /> </template
      ></Button>
      <Space :size="8">
        <PartnerIcon
          :style="{ fontSize: '24px', color: '#52C41A' }"
          v-if="props?.isPartner"
        />
        <span class="name-title">{{ name }}</span>
        <Tag v-if="!company?.tin_status" color="red">Ликвидированы</Tag>
      </Space>
    </Space>
    <EButton
      v-if="getCoreCookie()?.company_type === 'customer'"
      class="products__header-button"
      @click="handleClickChat"
    >
      Открыть чат
    </EButton>
    <Button v-if="false">
      <template v-if="role === 'farmer'"> Заказы у поставщика </template>
      <template v-else> Заказы у поставщика </template></Button
    >
  </Flex>
</template>

<style scoped lang="scss">
.name-title {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  color: #000000e0;
}
</style>
