<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import DocumentUploadListItem from '@/components/Document/DocumentUploadListItem.vue'
import { DocumentCreate, UploadDocument } from '@/types/types'
import { useDocument } from '@/composables/useDocument'
import { DocumentAPI } from '@/api/DocumentAPI'
import { Document } from '@/types/types'
import router from '@/router'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { DocumentTypes, Roles } from '@/types/enums'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { useStore } from '@/store'
import { downloadCompanyOnClick } from '@/helpers/scripts'
import {TypographyText as TpText, Flex, notification} from 'ant-design-vue'
import {useCookies} from "vue3-cookies";
import {useRoute} from "vue-router";
const { setVerificationStatus } = useStore()

const company_id = Number(JSON.parse(localStorage.getItem('userData')).id)
const user = ref('')
const userOrganization = ref('')
const isSending = ref(false)

const maxSize = 100
const maxSingleSize = 20
// check

const validDocumentTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'application/pdf',
]


const checkIsFarmer = () => {
  const { cookies } = useCookies()
  const { getCoreCookie } = useJWTDecode()

  if (getCoreCookie()?.company_type !== Roles.Farmer) router.push('/')
}
const isValidFileTypes = (file: File | UploadDocument) => {
  return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
}

const isValidFileSize = (size: number) => {
  return size > maxSingleSize ? 'Превышен размер файла. Максимум 20Мб' : ''
}

const uploadDocumentsNotEmpty = computed(() => uploadDocuments.value.length > 0)
const isSizeError = computed(
  () =>
    uploadDocuments.value.length > 1 &&
    uploadDocuments.value.reduce(
      (accumulator, document) => accumulator + document.sizeMegabytes,
      0,
    ) > maxSize,
)
const isLengthError = computed(() => uploadDocuments.value.length < 1)

const isFileTypesError = computed(() =>
  uploadDocuments.value.find(
    (document) => isValidFileTypes(document).length > 0,
  ),
)

const isFileSizeError = computed(() =>
  uploadDocuments.value.find(
    (document) => isValidFileSize(document.sizeMegabytes).length > 0,
  ),
)
const isDocumentsError = computed(
  () =>
    isSizeError.value ||
    isLengthError.value ||
    isFileTypesError.value ||
    isFileSizeError.value,
)

const uploadDocuments = ref<UploadDocument[]>([])
const { declensionLitres, bytesToMegabytes } = useDocument()
const isUploadedDocs = ref(false)

const uploadFile = (file: File, base64: string) => {
  const fileError = [
    isValidFileTypes(file),
    isValidFileSize(+file.size / 1048576),
  ]
  uploadDocuments.value.push({
    name: file.name,
    size: declensionLitres(file.size),
    sizeMegabytes: bytesToMegabytes(file.size),
    base64: base64,
    errors: fileError,
    documentType: DocumentTypes.PA,
    type: file.type,
  })
}

const deleteDocument = (index: number) => {
  uploadDocuments.value.splice(index, 1)
}

const sentDocuments = async () => {
  const sentDocuments: Document[] = []
  const sentDocumentsPaths: DocumentCreate[] = []
  localStorage.removeItem('isNotUploadDocuments')
  isUploadedDocs.value = true

  uploadDocuments.value.forEach((file, index) => {
    const fileExt = file.name.split('.').pop()

    sentDocuments.push({
      file_name: file.name,
      file_body: file.base64,
      document_type: DocumentTypes.PA,
      extension: String(fileExt),
    })
  })

  for (const document of sentDocuments) {
    isSending.value = true
    await DocumentAPI.uploadCompanyDocument(document).then((resp) => {
      sentDocumentsPaths.push({
        type: DocumentTypes.PA,
        file_path: resp.path,
      })
    })
  }

  // Загружать только по 10 документов
  const countOfRequest = sentDocumentsPaths.length / 10
  for (let i = 0; i < countOfRequest; i++) {
    await DocumentAPI.createManyDocuments(
      sentDocumentsPaths.slice(i * 10, 10 + i * 10),
    )
  }
  setVerificationStatus('full')
  window.location.replace(`/`)
}

const getUserOrganization = async () => {
  user.value = JSON.parse(localStorage.getItem('userData'))
  userOrganization.value = user.value.legal_form
}

const userPADocument = ref<Document>({
  file_name: '',
  file_body: '',
})

const getSystemPADocument = async () => {
  const docs = await DocumentAPI.getCompanyDocuments([
    ['type', 'eq', DocumentTypes.PAS],
    'AND',
    ['company_id', 'eq', company_id],
  ])

  userPADocument.value = docs.items[0]
}

const downloadRef = ref<HTMLAnchorElement>()
const downloadRefSpare = ref<HTMLAnchorElement>()

const downloadContractDocument = async () => {
  const maxRetries = 10
  const retryInterval = 2000 // 2 seconds

  const fetchDocument = async (retries = 0) => {
    try {
      await getSystemPADocument()
      if (userPADocument.value.file_name && userPADocument.value.file_url) {
        // If we have a file, stop the recursion
        await downloadCompanyOnClick(
            userPADocument.value?.id,
            userPADocument.value,
            downloadRef.value,
        )
      } else if (retries < maxRetries) {
        // If we don't have a file, retry after the interval
        setTimeout(() => fetchDocument(retries + 1), retryInterval)
      } else {
        // If we've reached the max retries, stop the recursion
        console.error(
            'Failed to retrieve document after',
            maxRetries,
            'retries',
        )
        notification.error({
          message: 'Ошибка',
          description: `Не удалось получить документ`,
        })
      }
    } catch (error) {
      console.error('Error fetching document:', error)
    }
  }

  await fetchDocument()
}

router.afterEach((to, from) => {
  if (to.path != '/') {
    if (!isUploadedDocs?.value) {
      localStorage.setItem('isNotUploadDocuments', 'true')
    } else {
      localStorage.removeItem('isNotUploadDocuments')
    }
  }
})

onMounted(async () => {
  checkIsFarmer()
  await getUserOrganization()
  await getSystemPADocument()
})

const route = useRoute()
</script>

<template>
  <div class="page-card" :class="type === 'small' ? 'page-card--small' : ''">
    <div class="page-card__header">Подпишите договор с платформой</div>
    <div class="contract-description">
      <ul>
        <li>
          По данному Договору Вы можете использовать весь функционал Платформы
          (без него только функцию просмотра).
        </li>
        <li>У нас НЕТ ежемесячной абонентской платы.</li>
        <li>Вы платите процент ТОЛЬКО от совершенных сделок.</li>
        <li>
          Счет на услуги Платформы мы выставляем 1 раз в месяц. На основании
          Ваших сделок.
        </li>
        <li>
          Базовая ставка - 1% от сделки. Цену на Ваш товар Вы устанавливаете
          сами.
        </li>
        <li>
          Если Вы считаете, что процент от сделки слишком высок, свяжитесь с
          нашим отделом поддержки, мы подберем Вам выгодный тариф.
        </li>
      </ul>
    </div>
    <a class="page-card__document-link" target="_blank" ref="downloadRef">
      <EButton
        @click="downloadContractDocument"
        :style="{
          background: 'rgba(222, 235, 252, 1)',
          color: 'rgba(0, 102, 255, 1)',
          fontWeight: 500,
          width: '100%',
        }"
      >
        Скачать договор с платформой
      </EButton>
    </a>

    <div class="page-card__uploader">
      <Flex :gap="16" vertical>
        <TpText style="font-size: 16px"
          >Загрузите скан подписанного договора</TpText
        >
        <FileUploader @load-file="uploadFile" v-if="!uploadDocumentsNotEmpty" />
        <TpText type="secondary">
          Формат документов: .pdf .gif, .jpg, .png .jpeg
        </TpText>
      </Flex>
      <div v-if="!uploadDocumentsNotEmpty" class="page-card__links">
        <a ref="downloadRefSpare" />
      </div>
      <div class="page-card__uploader-manage-files">
        <div class="page-card__uploader-documents">
          <DocumentUploadListItem
            v-for="(document, index) in uploadDocuments"
            :key="index"
            :index="index"
            :name="document.name"
            :size="document.size"
            :errors="document.errors"
            document-model="profile"
            @delete-document="deleteDocument"
          />
          <FileUploader
            :type="'custom'"
            @load-file="uploadFile"
            v-if="uploadDocuments.length < 5"
          >
            <a
              v-if="uploadDocumentsNotEmpty"
              href="#"
              class="page-card__add-document"
            >
              <span class="plus">+</span>
              Добавить документы
            </a>
          </FileUploader>
        </div>
        <div class="page-card__loader" v-if="isSending">
          <StarLoader />
        </div>
        <EButton
          @click="sentDocuments"
          :data="{ disabled: isDocumentsError || isSending }"
          :style-config="{
            ...primaryButton,
            disabled: {
              backgroundColor: '#E2E8F0',
              borderColor: 'transparent',
              color: '#A0AEC0',
            },
            fontSize16,
            width: 'fit-content',
          }"
        >
          Отправить документы
        </EButton>

        <div class="page-card__error" v-if="isSizeError">
          Превышен размер загружаемых файлов, максимум 100 Мб
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.page-card {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: 608px;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 48px;
  gap: 24px;

  &--small {
    width: 360px;
    height: 100%;
    margin-bottom: 7px;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: none;
    border-radius: 1rem;
    padding: 36px 24px;

    .page-card__footer {
      width: 100%;
    }

    .page-card__links {
    }
  }

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }

  &__footer {
    margin-top: 24px;
    width: fit-content;
  }

  &__document-link {
    text-decoration: none;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 27px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &-link {
      @include router-link();
      cursor: pointer;
      margin-top: 34px;
    }
  }

  &__info,
  .contract-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(45, 55, 72, 1);
  }
  .contract-description {
    span {
      font-weight: 500;
      color: $gray-800;
      display: block;
      margin-bottom: 8px;
    }
    ul {
      list-style: inside;

      li {
        margin-bottom: 5px;
      }
    }
  }

  &__uploader {
    width: 100%;
    margin-top: 24px;
    .page-card__link {
      margin-top: 1.25rem;
    }
  }

  &__uploader-manage-files {
    height: fit-content;
    gap: 48px;
    display: flex;
    flex-direction: column;
  }

  &__uploader-documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
    width: 100%;
    height: fit-content;
    .icon-wrapper {
      display: none;
    }
  }

  &__add-document {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #0066ff;
    text-decoration: none;
    margin: 15px 0 0 15px;
    .plus {
      font-size: 20px;
      margin-right: 4px;
    }
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f16063;
    padding-top: 0.75rem;
  }

  &__loader {
    display: flex;
    justify-content: center;
  }
}

.egal-button {
  justify-content: center;
}

.document-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d3748;
  display: flex;
  flex-direction: column;
  flex: none;
  order: 1;
  flex-grow: 0;
  gap: 0.5rem;
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}
</style>
