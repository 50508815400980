export const selectStyleConfig = {
  // fontFamily: 'Open Sans',
  // valueColor: '#a0aec0',
  // valueFontWeight: 500,
  placeholderColor: '#718096',
  valueFontSize: '14px',
  // placeholderFontSize: '12px',
  // backgroundColor: '#ffffff',
  // labelColor: '#a0aec0',
  // labelFontWeight: 500,
  // labelFontSize: '12px',
  // helperTextColor: '#a0aec0',
  // helperTextFontWeight: 400,
  // borderColor: '#edf2f7',
  // borderRadius: '6px',
  // focusBorderColor: '#0066ff',
  // filledBackgroundColor: '#e5f0ff',
  // filledFontColor: '#3385ff',
  // errorColor: '#f16063',
  // arrowColor: '#cbd5e0',
  // crossColor: '#a0aec0',
}
