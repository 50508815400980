<script lang="ts" setup>

import AuctionHeaderItem from '@/views/Auctions/components/AuctionHeader/Components/Item/AuctionHeaderItem.vue'
import AuctionHeaderList from '@/views/Auctions/components/AuctionHeader/Components/List/AuctionHeaderList.vue'

interface IProps {
  title: string
  buttonText?: string
  isList: boolean
  createdAt?: string
  expirationDate?: string
  isOpen: boolean
  buttonType: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])
</script>

<template>
  <div class="auction-header__wrapper">
    <AuctionHeaderList
      v-if="!props.isList"
      :title="props.title"
      :button-text="props.buttonText ?? ''"
      @onBtnClick="emit('onBtnClick')"
      :created-at="props.createdAt ?? ''"
      :expiration-date="props.expirationDate ?? ''"
      :isOpen="isOpen"
    />
    <AuctionHeaderItem
      v-if="props.isList"
      :title="props.title"
      :button-text="buttonText ?? ''"
      :created-at="props.createdAt"
      @onBtnClick="emit('onBtnClick')"
      :expiration-date="props.expirationDate"
      :buttonType="buttonType"
    />
  </div>
</template>
