import {
    App as IApplication,
    DirectiveBinding as IDirectiveBinding,
    VNode as IVNode
} from 'vue';

// Расширяем интерфейс HTMLElement для добавления свойства clickOutsideEvent
interface IHTMLElementWithClickOutside extends HTMLElement {
    clickOutsideEvent?: (event: MouseEvent) => void;
}

export const initVClickOutsideDir = (app: IApplication) => {
    console.debug('initVClickOutsideDir...')
    app.directive('click-outside', {
        mounted(el: IHTMLElementWithClickOutside, binding: IDirectiveBinding, vnode: IVNode) {
            // Определяем функцию обработки события 'click'
            el.clickOutsideEvent = function (event: MouseEvent) {
                // Проверяем, что клик был вне элемента
                if (!(el === event.target || el.contains(event.target as Node))) {
                    binding.value(event, el);  // Выполняем функцию, переданную в директиву
                }
            }

            // Добавляем обработчик события на body
            document.body.addEventListener('click', el.clickOutsideEvent);
        },

        unmounted(el: IHTMLElementWithClickOutside) {
            // Убираем обработчик события при размонтировании компонента
            document.body.removeEventListener('click', el.clickOutsideEvent);
        },
    });
}
