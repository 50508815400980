// использование токенов. Декодирование

import { useCookies } from 'vue3-cookies'
import { ref } from 'vue'

export function useJWTDecode() {
  const { cookies } = useCookies()
  const roles = ref<string[]>([])

  /**
   * Расшифровывает токен из кукис
   * @param cookie
   */
  const decipherJWT = (cookie: string) => {
    if (!cookies.isKey(cookie)) return null
    const token = cookies.get(cookie)
    return JSON?.parse(atob(token?.split('.')[1]))
  }

  const parseJWT = (jwt: string) => {
    return JSON?.parse(atob(jwt?.split('.')[1]))
  }

  /**
   * Возвращает информацию о залогиненном пользователе из куки core
   */
  const getCoreCookie = () => {
    return decipherJWT('core')?.auth_information
  }

  /**
   * Возвращает роли пользователя
   */
  const getRoles = (): string[] => {
    roles.value = getCoreCookie()?.roles
    return roles.value
  }

  return {
    decipherJWT,
    getCoreCookie,
    getRoles,
    parseJWT,
  }
}
