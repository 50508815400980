<script lang="ts" setup>
import { computed, ref } from 'vue'
import router from '@/router'
import { NotificationAPI } from '@/api/NotificationAPI'
import ChevronRight from '../../assets/img/chevron_right.png'

const props = defineProps({
  id: Number,
  order: String,
  status: String,
  text: String,
  time: String,
  checked: Boolean,
  link: String,
  img: String,
  type: String,
})

const checkedClass = computed(() =>
  !props.checked && !isChecked.value
    ? 'notification-history__notification--unread'
    : '',
)

let isChecked = ref(false)

const emit = defineEmits(['close-notifications', 'update-notifications'])

const toHistoryOrder = async () => {
  emit('close-notifications');
  await router.replace(String(props.link));
}

const readNotification = async () => {
  if (!props.checked) {
    await NotificationAPI.updateNotification({
      id: props.id,
      checked: true,
    }).then(() => {
      isChecked.value = true
      emit('update-notifications')
    })
  }
}

const get_notification_text = (type: string) => {
  switch (type) {
    case 'price_request':
      return 'Перейти к запросу'
    case 'reduction':
      return 'Перейти к торгу на покупку'
    case 'auction':
      return 'Перейти к торгу на продажу'
    case 'order':
      return 'Перейти к заказу'
    case 'account':
    default:
      return null
  }
}
</script>

<template>
  <div
    class="notification-history__notification"
    :class="checkedClass"
    @mouseover="readNotification"
    @click="toHistoryOrder"
  >
    <div class="img-wrapper">
      <img class="img" :src="props.img" alt="notification" loading="lazy" />
    </div>
    <div class="notification-history__notification-header">
      <div class="header-content">
        <p>
          {{ order }}
        </p>
        <span class="notification-history__notification-status">
          {{ status }}
        </span>
      </div>
    </div>
    <p class="notification-history__notification-text">
      {{ text }}
    </p>
    <div class="notification-history__notification-time">
      <!--      {{ formatDate(new Date(props.time)) }}-->
      <div
        class="notification-history__notification-link"
        v-if="get_notification_text(type)"
      >
        <span class="notification-history__notification-link-text">
          <p>{{ get_notification_text(type) }}</p>
          <img :src="ChevronRight" alt="" loading="lazy" />
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.img-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #1d1d23;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
}

.notification-history {
  &__notification {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 8px;
    border-bottom: 1px solid #e2e8f0;
    transition: 0.3s;
    background: #ffffff;
    cursor: pointer;
    position: relative;
    word-break: break-word;

    &:hover {
      background: #edf2f7;
      border-bottom: 1px solid #cbd5e0;
    }

    &--unread {
      .img-wrapper {
        background-color: #0066ff;
      }
      background: #deebfc;
      border-bottom: 1px solid #76acfb;
    }

    &--unread:hover {
      background: #a6c8fa;
      cursor: pointer;
    }

    &-header {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      gap: 0.3rem;
      flex-wrap: wrap;
      padding-left: 44px;
      width: 336px;
    }

    &-title {
      line-height: 17px;
      color: #2d3748;
      cursor: pointer;
    }

    &-status {
      line-height: 17px;
      color: #2d3748;
    }

    &-time {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #a0aec0;
      align-self: flex-end;
    }
    &-text {
      color: #2d3748;
      padding-left: 44px;
    }
    &-link {
      color: #0066ff;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      &-text {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        &-icon {
        }
      }
    }
  }
}
</style>
