<script setup lang="ts">
import { onMounted, ref, onUnmounted, computed } from 'vue'
import TitleToolbarBlock from '@/views/Farmer/response/toolbarWidget/TitleToolbarBlock.vue'
import { Button } from 'ant-design-vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import CustomerSubBlock from '@/views/Farmer/response/toolbarWidget/CustomerSubBlock.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { IAuction } from '@/types/interfaces'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { usePartnersStore } from '@/store/usePartnersStore'

interface Props {
  contact_name: string
  contact_phone: string
  contact_post: string
  address: string
  auction: IAuction
  type?: string
  titleStyle?: string
  priceAnswer?: any
}

const props = defineProps<Props>()
const recipientStr = ref('')
const customerStr = ref('')

const recipient = computed(() => recipientStr.value ? JSON?.parse(recipientStr.value) : {})
const customer = computed(() => customerStr.value ? JSON.parse(customerStr.value) : {})
const auctionRecipient = computed(() => props.auction?.recipient)
const auctionCustomer = computed(() => props.auction?.customer)
const auctionFarmer = computed(() => props.auction?.farmer)

const legalForm = computed(() => auctionRecipient.value?.price_request?.customer?.legal_form ||
    auctionRecipient.value?.price_request?.farmer?.legal_form ||
    auctionCustomer.value?.legal_form ||
    auctionFarmer.value?.legal_form ||
    recipient.value?.customer?.legal_form ||
    customer?.value?.legal_form)

const companyName = computed(() => auctionRecipient.value?.price_request?.customer.name ||
    auctionRecipient.value?.price_request?.farmer.name ||
    auctionCustomer.value?.name ||
    auctionFarmer.value?.name ||
    recipient?.value?.customer?.name ||
    customer?.value?.name)

const companyRating = computed(() => auctionRecipient.value?.price_request?.customer?.company_rating ||
    auctionRecipient.value?.price_request?.farmer?.company_rating ||
    auctionCustomer.value?.company_rating ||
    auctionFarmer.value?.company_rating ||
    recipient?.value.customer?.company_rating?.average_mark ||
    customer?.value.company_rating?.average_mark)

const emit = defineEmits(['button-click'])
const partnersStore = usePartnersStore()
const partners = computed(() => {
  return partnersStore.partners
})

const isPartner = computed(() => {
  return partners.value?.some((el: { company_id: number }) => {
    return (
      auctionRecipient?.value?.price_request?.customer?.id === el?.company_id ||
      recipient?.value.customer?.id === el?.company_id ||
      customer?.value.id === el?.company_id ||
      Number(auctionFarmer?.value?.id) === el?.company_id ||
      auctionCustomer?.value?.id === el?.company_id
    )
  })
})

onMounted(async () => {
  recipientStr.value = localStorage.getItem('recipient') || ''
  customerStr.value = localStorage.getItem('customer') || ''
})

onUnmounted(() => {
  localStorage.removeItem('recipient')
  localStorage.removeItem('customer')
})
</script>

<template>
  <div class="customer-block">
    <div
      class="customer-block__title"
      :class="{ 'without-padding': titleStyle }"
    >
      <TitleToolbarBlock
        :title="
          ['reduction', 'request'].includes(props?.type)
            ? 'О покупателе'
            : 'О поставщике'
        "
      />
      <Button
        :style="{ marginLeft: 'auto' }"
        @click="emit('button-click')"
        type="link"
      >
        Свернуть
        <template #icon>
          <LeftOutlined />
        </template>
      </Button>
    </div>
    <div class="customer-block__body">
      <div class="supplier">
        <div>
          <PartnershipTooltip :is-partner="isPartner" v-if="isPartner" />
        </div>
        <div class="supplier__name">
          {{ legalForm }}
          "{{ companyName }}"
        </div>
      </div>
      <SimpleRate class="rating" :company_rating="companyRating" />
      <CustomerSubBlock
        v-if="address"
        title="Адрес отгрузки"
        :subtitle="props.address"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.rating {
  // padding-left: 20px;
  margin-bottom: -10px;
  margin-top: -20px;
}

.supplier {
  // padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  &__title {
    // padding-left: 25px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.88);
    margin-bottom: -10px;
  }
  &__name {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.88);
  }
}

.without-padding {
  padding: 0 !important;
}

.customer-block {
  line-height: 22px;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-left: 20px;
  }
  &__body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__first-part {
    display: flex;
    flex-direction: column;
  }
  &__caption {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(0, 0, 0, 0.45);

    & .divider {
      background-color: rgba(0, 0, 0, 0.45);
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }
}
</style>
