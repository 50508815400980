<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { primaryButton } from '@/assets/EgalStyles/EButton'
import { useTimer } from '@/composables/useTimer'
import { UserAPI } from '@/api/UserAPI'
import router from '@/router'
import { ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import { useRoute } from 'vue-router'

const { timer, startTimer, buttonText, isDisableTime } = useTimer()
const { cookies } = useCookies()
const route = useRoute()

const userEmail = computed(() => {
  // @ts-ignore
  let email = cookies.get('registerForm')?.email
  if (!email) {
    // @ts-ignore
    email = cookies.get('loginForm')?.email
  }
  return email
})

const sentEmailMessage = async () => {
  const registerForm = cookies.get('registerForm')
  const registerByInvite = cookies.get('registerByInvite')
  const registerToken = cookies.get('registerToken')
  const emailFromRegisterForm = registerForm?.email
  let email =
    registerToken?.data?.email ||
    emailFromRegisterForm ||
    JSON.parse(cookies.get('registerForm'))?.email

  if (registerByInvite) {
    email = emailFromRegisterForm
  }

  await UserAPI.resendConfirmEmail({
    email: email,
  }).then(() => {
    startTimer()
  })
}

const invalidToken = ref(false)
const confirmRegistration = async (userToken: string) => {
  if (userToken.includes('}')) {
    userToken = userToken.replace('}', '')
  }

  await UserAPI.confirmRegistration({ token: userToken })
    .then(() => {
      cookies.set('emailConfirmed', JSON.stringify(userToken), '365d', '')
      if (cookies.get('registerByInvite')) {
        cookies.remove('registerByInvite')
        cookies.set('inviteStepOne', 'true', '365d', '')
        router.push('/invite-register')
      } else {
        router.push('/register')
      }
    })
    .catch((err) => {
      if (err.response.status == 500) {
        router.push('/mail-used')
      }
      if (err?.response?.status == 405) {
        invalidToken.value = true
      } else {
        console.log('err', err)
        router.push('/error-occured')
      }
      return
    })
}

const checkQueryParams = async () => {
  const registerToken = route.query?.token
  const invite = route.query?.invite

  // @ts-ignore
  if (!cookies.get('registerForm')?.email && !isDisableTime) {
    await sentEmailMessage()
  }

  // Проверяем, регистрация проходит по инвайту?
  if (invite) {
    cookies.set('registerByInvite', 'true', '365d', '')
    await router.replace('/confirm-email')
  }

  if (registerToken) {
    cookies.set('registerToken', registerToken, '365d', '')
    await confirmRegistration(registerToken)
  }
}

onMounted(async () => {
  startTimer()
  setTimeout(() => {
    checkQueryParams()
  }, 1000)
  localStorage.removeItem('umt')
  localStorage.removeItem('umrt')
  cookies.remove('core')
  cookies.remove('auth')
  if (!isDisableTime) {
    await sentEmailMessage()
  }
  cookies.remove('chat')
})

const sendAgain = () => {
  router.push({ path: '/confirm-email', replace: true }).then(() => {
    window.location.reload()
  })
}
</script>

<template>
  <div class="auth-card" v-if="!invalidToken">
    <div class="auth-card__header">Подтвердите регистрацию</div>
    <div class="auth-card__info">
      <p class="info-row">
        Мы отправили письмо на <span class="info-item">{{ userEmail }}</span>
      </p>

      <p class="info-row">
        Перейдите по ссылке в письме, чтобы подтвердить регистрацию.
      </p>
      <p class="info-row">Если вы не нашли письмо — проверьте папку спама.</p>

      <p class="info-row">
        Если письмо не приходит или вы указали неправильную почту — обратитесь в
        тех. поддержку:
        <span class="info-item">manager@easy-retail.org</span> или
        <span class="info-item">+7 913 871-12-39</span>
      </p>
    </div>
    <div class="auth-card__body">
      <EButton
        @click="sentEmailMessage"
        :style="primaryButton"
        :data="{ disabled: timer > 0 }"
      >
        {{ buttonText }}
      </EButton>
    </div>
  </div>
  <div class="invalid-card" v-else>
    <div class="invalid-card__header">Приглашение недействительно</div>
    <div class="invalid-card__info">
      <p class="info-row">
        Срок действия приглашения истёк, вы можете отправить приглашение
        повторно
      </p>
    </div>
    <div class="invalid-card__body">
      <EButton @click="sendAgain" :style="primaryButton">
        Отправить повторно
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.auth-card,
.invalid-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    column-gap: 12px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 27px;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    a {
      @include router-link();
    }
  }

  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $gray-800;
    margin-bottom: 40px;
    width: 101%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .info-item {
      color: $default-accent;
    }
  }
}

.egal-button {
  justify-content: center;
}
</style>
