<script setup lang="ts">
import {
  IReduction,
  IReductionProductAnswer,
  IReductionProductRequest,
} from '@/types/interfaces'
import { ref } from 'vue'
import {
  Button,
  Flex,
  Space,
  Table,
  TypographyText as TgText,
} from 'ant-design-vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import {
  getUserIdTemporary,
  priceWithoutVat,
  roundNumber,
} from '@/helpers/scripts'
import MyCompanyTag from '@/views/Farmer/reductions/reduction/MyCompanyTag.vue'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'

interface IProps {
  reduction: IReduction
  myParticipantId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])

const expandedRowKeys = ref<string[]>([])
const farmerId = +getUserIdTemporary()

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '60%',
  },
  {
    title: 'Ваше предложение',
    key: 'your_offer',
    width: '15%',
  },
  {
    title: 'Лучшее предложение',
    key: 'best_offer',
    width: '25%',
  },
]

const nestedColumns = [
  {
    title: 'Рейтинг',
    key: 'rating',
    width: '7%',
  },
  {
    title: 'Поставщик',
    key: 'farmer',
    width: '39%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'НДС',
    key: 'vat',
    width: '8%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const getProductParameters = (product: IReductionProductRequest) => {
  const result: string[] = []
  result.push(
    `Объем ${product.volume} ${product.product?.measurement_unit_id ?? 'кг'}`,
  )

  result.push(`Срок хранения ${product.remaining_shelf_life}%`)
  product.is_sample_needed &&
    product.needed_samples_number &&
    result.push(
      `Пробный образец ${product.needed_samples_number ? `${product.needed_samples_number} кг` : ''}`,
    )
  return result
}

const calculateBetSum = (reductionProductRequest: IReductionProductRequest) => {
  const vat = reductionProductRequest.best_bet?.vat ?? 0
  const price = reductionProductRequest.best_bet?.price_per_unit ?? 0
  return +(Number(price) * Number(reductionProductRequest.volume)).toFixed(2)
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const getYourOffer = (requestProductId: number) => {
  let productRequest: IReductionProductRequest | undefined = undefined
  let myAnswer: IReductionProductAnswer | undefined

  productRequest = props.reduction.reduction_product_requests?.find(
    (request) => request.id === requestProductId,
  )

  productRequest?.reduction_product_answers.forEach((answer) => {
    if (answer.reduction_participant_id === props.myParticipantId) {
      myAnswer = answer
    }
  })

  if (productRequest && myAnswer) {
    return `${roundNumber(+productRequest.volume * Number(myAnswer.price_per_unit))} ₽`
  } else {
    return '-'
  }
}

const filterByBet = (
  reductionProductRequests: IReductionProductRequest[] | undefined,
) => {
  if (!reductionProductRequests) {
    return []
  }
  return reductionProductRequests
    .filter((currentRequest) => currentRequest.best_bet)
    .sort((productA, productB) =>
      productA.product_name.localeCompare(productB.product_name),
    )
}
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :data-source="filterByBet(reduction?.reduction_product_requests)"
      :pagination="false"
      :expandedRowKeys="expandedRowKeys"
      :row-key="(record, index) => String(index)"
      :showExpandColumn="false"
      class="edged-border-table"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'product'">
          <Space direction="vertical" :size="4">
            <ProductWithProperties
              :volume="record.volume"
              :remaining_shelf_life="record.remaining_shelf_life"
              :measurement_unit_id="record.product?.measurement_unit_id ?? null"
              :name="record.product_name"
              :is_sample_needed="record.is_sample_needed"
              :needed_samples_number="record.needed_samples_number"
            />
          </Space>
        </template>
        <template v-if="column.key === 'your_offer'">
          <span>{{ getYourOffer(record.id) }}</span>
        </template>
        <template v-if="column.key === 'best_offer'">
          <template v-if="record.best_bet">
            <Flex justify="space-between" align="center">
              <Space direction="vertical" :size="0">
                <TgText strong>{{
                  `${roundNumber(calculateBetSum(record))} ₽`
                }}</TgText>
                <span
                  v-if="
                    record.best_bet.reduction_participant.farmer_id !== farmerId
                  "
                  class="farmer-name"
                  >{{
                    `Поставщик ${record.best_bet.reduction_participant.reduction_name.toUpperCase()}`
                  }}</span
                >
                <MyCompanyTag v-else />
              </Space>
              <Button type="link" :onClick="() => changeExpandedRow(index)">
                <template #icon>
                  <DownOutlined
                    v-if="!expandedRowKeys.includes(String(index))"
                  />
                  <UpOutlined v-else />
                </template>
                Ставки
              </Button>
            </Flex>
          </template>
          <template v-else>
            <TgText type="secondary">Нет ставок</TgText>
          </template>
        </template>
      </template>

      <template #expandedRowRender="{ record }">
        <Table
          :columns="nestedColumns"
          :data-source="record.reduction_product_answers"
          :pagination="false"
          bordered
          class="nested-table"
        >
          <template #bodyCell="{ column, record: answer, index }">
            <template v-if="column.key === 'rating'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'farmer'">
              <span
                v-if="answer.reduction_participant.farmer_id !== farmerId"
                >{{
                  `Поставщик ${answer.reduction_participant.reduction_name.toUpperCase()}`
                }}</span
              >
              <Flex v-else :gap="8">
                <span>{{ answer.reduction_participant.farmer.name }}</span>
                <MyCompanyTag />
              </Flex>
            </template>
            <template v-if="column.key === 'price_without_vat'">
              <CellDifData
                :new-data="
                  roundNumber(
                    priceWithoutVat(answer.price_per_unit, answer.vat),
                  )
                "
                :prev-data="
                  roundNumber(
                    priceWithoutVat(
                      answer.price_per_unit_first,
                      answer.vat_first,
                    ),
                  )
                "
                :additional-info="
                  record.product.measurement_unit_id
                    ? `₽/${record.product.measurement_unit_id}`
                    : '₽'
                "
              />
            </template>
            <template v-if="column.key === 'vat'">
              <CellDifData
                :new-data="answer.vat"
                :prev-data="answer.vat_first"
                additional-info="%"
              />
            </template>
            <template v-if="column.key === 'price_with_vat'">
              <CellDifData
                :new-data="roundNumber(answer.price_per_unit)"
                :prev-data="roundNumber(answer.price_per_unit_first)"
                :additional-info="
                  record.product.measurement_unit_id
                    ? `₽/${record.product.measurement_unit_id}`
                    : '₽'
                "
              />
            </template>
            <template v-if="column.key === 'amount'">
              <Flex justify="space-between">
                <CellDifData
                  :new-data="
                    roundNumber(answer.price_per_unit * Number(record.volume))
                  "
                  :prev-data="
                    roundNumber(
                      answer.price_per_unit_first * Number(record.volume),
                    )
                  "
                  additional-info="₽"
                />
                <Button
                  v-if="
                    answer.reduction_participant.farmer_id === farmerId &&
                    reduction.status !== 'closed'
                  "
                  type="primary"
                  @click="
                    emit('setRequestId', answer.reduction_product_request_id)
                  "
                  >Сделать ставку</Button
                >
              </Flex>
            </template>
          </template>
        </Table>
      </template>
    </Table>
  </div>
</template>

<style scoped lang="scss">
.table-wrapper {
  padding: 24px;
}
.product-parameters-row {
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000a6;
  }
}
.farmer-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000a6;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}
.nested-table {
  :deep(.ant-table) {
    border-radius: 0 !important;
  }
  :deep(.ant-table-row .ant-table-cell:first-child) {
    text-align: center;
  }
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}
</style>
