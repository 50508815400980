<script lang="ts" setup>
import PartnersHeader from '@/components/Partners/PartnersHeader.vue'
import { computed, ref, watch } from 'vue'
import { IFilter, IPartner, ISortPartners } from '@/types/interfaces'
import PartnersFilter from '@/components/Partners/PartnersFilter.vue'
import PartnersTable from '@/components/Partners/PartnersTable/PartnersTable.vue'
import PartnersEmpty from '@/components/Partners/PartnersEmpty.vue'
import PartnersFooter from '@/components/Partners/PartnersFooter.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import {useRoute} from "vue-router";

const partners = ref<IPartner[]>([])
const sortPartners = ref<ISortPartners>({})
const isLoading = ref<boolean>(false)
const filtersForDownload = ref<IFilter>()

const setParams = (filter?: IFilter) => {
  filtersForDownload.value = filter
  getPartners()
}

const getPartners = () => {
  isLoading.value = true
  PartnersAPI.myPartners(filtersForDownload.value, sortPartners.value)
    .then((res) => {
      partners.value = res?.items
    })
    .finally(() => {
      isLoading.value = false
    })
}

const dealsSum = computed(() => {
  return partners.value
    .reduce((accumulator, item) => accumulator + (item.sum ? +item.sum : 0), 0)
    .toFixed(2)
})

const changeSort = (field: string, order: 'ascend' | 'descend' | undefined) => {
  // const sortElementIndex = sortPartners.value.findIndex((el) => el[0] === field)

  if (order) {
    sortPartners.value = {
      column: field,
      direction: order === 'ascend' ? 'asc' : 'desc',
    }
  } else {
    sortPartners.value = {}
  }
}

watch(
  () => sortPartners.value,
  () => {
    getPartners()
  },
  {
    immediate: true,
  },
)

const route = useRoute()
</script>

<template>
  <div class="wrapper">
    <template v-if="!!partners.length">
      <PartnersHeader
        :sort="sortPartners"
        :filters="filtersForDownload"
        :isPartners="!!partners.length && !isLoading"
      />
      <PartnersFilter @send-filter="setParams" :partners="partners" />
      <PartnersTable
        :table-data="partners"
        @changeSort="changeSort"
        :isLoading="isLoading"
      />
      <PartnersFooter :all-sum="dealsSum" />
    </template>
    <PartnersEmpty v-else />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  @include wrapper();
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 32px;
}
</style>
