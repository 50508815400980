<script lang="ts" setup>
import { computed } from 'vue'
import { Roles } from '@/types/enums'
import CustomerAuctionView from '@/views/Customer/auctions/CustomerAuctionView.vue'
import FarmerAuctionView from '@/views/Farmer/auctions/FarmerAuctionView.vue'
import {useCookies} from "vue3-cookies";
import {useJWTDecode} from "@/composables/useJWTDecode";
import {useRoute} from "vue-router";

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()
const route = useRoute()
</script>

<template>
  <div class="wrapper">
    <FarmerAuctionView v-if="getCoreCookie()?.company_type === Roles.Farmer" />
    <CustomerAuctionView v-else />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 72px - 152px);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
