<script setup lang="ts">
import { IAddress } from '@/types/IAddress'
import { formatPhoneNumber } from '@/helpers/scripts'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import {
  EnvironmentOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import { Flex, Popover, Space, TypographyText as TgText } from 'ant-design-vue'
import { IOrder } from '@/types/interfaces'
import { deliveryPayment } from '@/helpers/dictionary'
import { computed } from 'vue'

interface IProps {
  order?: IOrder
  history?: IOrder | null
  address?: IAddress
  old_address?: IAddress
}

const props = defineProps<IProps>()
const address = computed(() => {
  if (props.address) {
    return props.address
  }
  if (isDelivery.value) {
    return props.order?.general_order?.delivery_address
  }
  return props.order?.shipping_address
})

const previous_address = computed(() => {
  if (props.order && !isDelivery.value) return null
  if (props.old_address && props.old_address.street !== address.value?.street) {
    return props.old_address
  }
  return null
})

const isDelivery = computed(() => {
  if (!props.order) return true
  return props.order?.delivery_method === 'Доставка фермером'
})
</script>

<template>
  <Flex :gap="8" vertical>
    <Popover placement="bottomLeft" v-if="previous_address">
      <Space :size="6">
        <ExclamationCircleFilled
          :style="{
            color: '#FAAD14',
            fontSize: '20px',
          }"
        />
        <TgText strong v-if="isDelivery"> Адрес доставки </TgText>
        <TgText strong v-else> Адрес отгрузки </TgText>
      </Space>
      <template #content>
        <Flex :gap="8" vertical>
          <TgText strong>Адрес доставки изменён, предыдущий адрес:</TgText>
          <Space :size="6" direction="vertical">
            <TgText>
              {{ previous_address.street }}
            </TgText>
            <Space
              :size="8"
              v-if="previous_address.name || previous_address.contact_number"
            >
              <TgText type="secondary" v-if="previous_address.name">
                {{ previous_address.name }}
              </TgText>
              <img
                v-if="previous_address.name && previous_address.contact_number"
                alt="dot-icon"
                src="@/assets/img/dot.svg"
                loading="lazy"
              />
              <TgText type="secondary" v-if="previous_address.contact_number">
                {{ formatPhoneNumber(previous_address.contact_number) }}
              </TgText>
            </Space>
            <TgText type="secondary" v-if="previous_address.comment">
              {{ previous_address.comment }}
            </TgText>
          </Space>
        </Flex>
      </template>
    </Popover>
    <Space :size="6" v-else>
      <EnvironmentOutlined :style="iconStyle" />
      <TgText strong v-if="isDelivery"> Адрес доставки </TgText>
      <TgText strong v-else> Адрес отгрузки </TgText>
    </Space>

    <Flex :gap="4" vertical>
      <TgText>
        {{ address.street }}
      </TgText>

      <template v-if="isDelivery">
        <TgText v-if="order?.delivery_payment">
          {{ deliveryPayment[order.delivery_payment] }}
        </TgText>
        <TgText
          type="secondary"
          v-if="
            history?.delivery_payment &&
            order.delivery_payment !== history.delivery_payment
          "
          class="line-through"
        >
          {{ deliveryPayment[history.delivery_payment] }}
        </TgText>
      </template>

      <Space :size="8" v-if="address.name || address.contact_number">
        <TgText type="secondary" v-if="address.name">
          {{ address.name }}
        </TgText>
        <img
          v-if="address.name && address.contact_number"
          alt="dot-icon"
          src="@/assets/img/dot.svg"
          loading="lazy"
        />
        <TgText type="secondary" v-if="address.contact_number">
          {{ formatPhoneNumber(address.contact_number) }}
        </TgText>
      </Space>
      <TgText type="secondary" v-if="address.comment">
        {{ address.comment }}
      </TgText>
    </Flex>
  </Flex>
</template>

<style scoped lang="scss"></style>
