<script lang="ts" setup>
import { PropType, ref, watchEffect } from 'vue'
import { ISwitcherItem } from '@/types/interfaces'
import deliveryFalse from '@/assets/img/catalog/delivery-false.svg'
import deliveryTrue from '@/assets/img/catalog/delivery-true.svg'

const props = defineProps({
  items: {
    type: Array as PropType<ISwitcherItem[]>,
  },
  active: {
    // текущий выбранный элемент
    type: Object,
  },
  title: { type: String },
  isDisabled: { type: Boolean, default: false },
})

const emit = defineEmits(['select'])

const activeTab = ref()

watchEffect(() => {
  activeTab.value = props.active
})

const selectTab = (item: ISwitcherItem) => {
  if (!props.isDisabled || !item.image) {
    emit('select', item)
  }
}
const getImageSrc = (imageSrc: string) => {
  const imgMap = {
    'img/catalog/delivery-false.svg': deliveryFalse,
    'img/catalog/delivery-true.svg': deliveryTrue
  }
  return imgMap[imageSrc]
}
</script>

<template>
  <div :class="isDisabled ? 'disabled' : ''" class="switch-image">
    <div v-if="title" class="switch-image-title">
      {{ title }}
    </div>
    <div class="switcher-image">
      <div
        v-for="item in items"
        :key="item.name"
        :class="{
          active: activeTab && activeTab === item.name,
          disabled: !item.image,
        }"
        class="switcher-image-tab"
        @click="selectTab(item)"
      >
        <img
          v-if="item.image"
          :alt="item.name"
          :src="getImageSrc(item.image)"
          loading="lazy"
        />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.switch-image-title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $gray-600;
}

.switcher-image {
  display: flex;
  width: 100%;
  border-radius: 12px;
  background-color: #f5f7f8;
  padding: 8px;
  box-sizing: border-box;

  &-tab {
    background-color: transparent;
    padding: 10px;
    border-radius: 8px;
    width: 50%;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    cursor: pointer;
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $gray-600;
      cursor: pointer;

      img {
        filter: invert(52%) sepia(21%) saturate(410%) hue-rotate(176deg)
          brightness(91%) contrast(85%);
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    &.active {
      background: #ffffff;
      border: 1px solid $gray-300;
      box-shadow:
        0 0 1px rgba(12, 26, 75, 0.1),
        0 4px 20px -2px rgba(50, 50, 71, 0.08);
      color: $gray-800;
      cursor: default;

      img {
        filter: none;
      }
    }

    &.disabled {
      color: $gray-500;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      cursor: default;
      pointer-events: none;
    }
  }
}

.switch.disabled {
  .switch-image-title {
    color: $gray-400;
  }

  .switcher-image-tab {
    cursor: default;
    color: $gray-500;
  }
}
</style>
