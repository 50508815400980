<script setup lang="ts">


interface Props {
  name: string
  value: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="product-property-line">
    <span class="property__name">{{ props.name }}</span>
    <span class="property__value">{{ props.value ?? '-' }}</span>
  </div>
</template>

<style scoped lang="scss">
.product-property-line {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.property {
  &__name {
    color: rgba(0, 0, 0, 0.45);
  }
  &__value {
    color: rgba(0, 0, 0, 0.88);
  }
}
</style>
