export const inputStyleConfig = {
  fontFamily: 'Inter',
  iconColor: '#718096',
  placeholderColor: '#718096',
  backgroundColor: '#FFFFFF',
  searchBackgroundColor: '#FFFFFF',
  borderRadius: '8px',
  labelColor: '#718096',
  labelFontWeight: 400,
}

export const inputStyleConfigWhiteOutline = {
  fontFamily: 'Inter',
  valueColor: '#FFFFFF',
  valueFontWeight: 400,
  placeholderColor: '#FFFFFF',
  placeholderDisabledColor: '#FFFFFF',
  labelColor: '#FFFFFF',
  labelFontWeight: 700,
  helperTextColor: '#a0aec0',
  helperTextFontWeight: 400,
  helperTextFontSize: '12px',
  borderColor: '#76ACFB',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  backgroundDisabledColor: '#edf2f7',
  focusBorderColor: '#E2E8F0',
  filledBackgroundColor: '#e5f0ff',
  filledFontColor: '#3385ff',
  searchBackgroundColor: '#f7fafc',
  iconColor: '#cbd5e0',
  errorColor: '#f16063',
  successColor: '#66cb9f',
}
