<script setup lang="ts">
import { Button, Empty } from 'ant-design-vue'
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
const emit = defineEmits(['resetFilters'])
</script>

<template>
  <div class="wrapper">
    <div class="empty-container">
      <Empty
        class="empty-view"
        :image="simpleImage"
        :description="null"
        :imageStyle="{
          width: '184px',
          height: '117px',
          margin: '0 auto 24px auto',
        }"
      />
      <div class="text">
        <div class="title">По вашему запросу ничего не найдено</div>
        <div class="description">Попробуйте применить другие фильтры</div>
      </div>
      <Button @click="emit('resetFilters')"> Сбросить фильтры </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 756px;
  width: 100%;
  background: #FFFFFF;
  display: table;
}

.empty-container {
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.text {
  margin-bottom: 24px;
  text-align: center;
  display: grid;
  gap: 8px;

  .title {
    font-size: 20px;
    font-weight: 600;
  }
}

.empty-view {
  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
  :deep(.ant-empty-image svg) {
    width: 184px;
    height: 117px;
  }
}
</style>
