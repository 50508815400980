const originalFetch = fetch;

if (window.location.host !== 'localhost') {
    window.fetch = function (url, options = {}) {
        const controller = new AbortController();
        options.signal = controller.signal;
        if (!!url && !url?.host?.includes('mc.yandex.com')) {
            return originalFetch(url, options);
        }
    };
}
