<script lang="ts" setup>
import { ref } from 'vue'
import CustomTooltip from '@/components/Tooltip/CustomTooltip.vue'
import { useStore } from '@/store'
import { storeToRefs } from 'pinia'

interface Props {
  withoutFlex?: boolean
}

const isTooltip = ref(false)

const props = defineProps<Props>()

const { isVerification } = storeToRefs(useStore())

const tooltipToggler = (e: MouseEvent) => {
  if (!isVerification.value) {
    isTooltip.value = e.type === 'mouseover'
  }
}
</script>

<template>
  <div v-if="!isVerification">
    <div v-on:mouseover="tooltipToggler" v-on:mouseleave="tooltipToggler">
      <CustomTooltip
        :message="'Просмотр данных недоступен, необходима верификация аккаунта'"
        :show-popper="isTooltip"
        :hover="true"
        :width="'279px'"
        :cursor="'default'"
        :without-flex="props.withoutFlex"
      >
        <slot></slot>
      </CustomTooltip>
    </div>
  </div>
  <slot v-else></slot>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
</style>
