<script setup lang="ts">
import { ICompanyView } from '@/types/interfaces'
import {
  Divider,
  Flex,
  Space,
  Tag,
  TypographyText as TgText,
} from 'ant-design-vue'
import DataItem from '@/views/Admin/UserView/DataItem.vue'
import { role_type, vat_type } from '@/helpers/dictionary'
import { computed } from 'vue'
import { AuditOutlined } from '@ant-design/icons-vue'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import { formatPhoneNumber } from '@/helpers/scripts'
import dayjs from 'dayjs'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { BankStatus, OrganisationStatus } from '@/types/enums'

interface IProps {
  company: ICompanyView
}

const auto_height = { height: 'auto' }

const props = defineProps<IProps>()

const is_farmer = computed(() => {
  if (!props.company.company_type) return false
  return role_type[props.company.company_type] === 'Поставщик'
})

const is_verificating = computed(() => {
  if (!props.company) return false
  return (
    props.company.status === 'active' || props.company.status === 'need_verify'
  )
})
</script>

<template>
  <Flex :gap="20">
    <Flex vertical :gap="16" flex="1">
      <h3>Общие данные</h3>
      <DataItem title="Роль">
        {{ role_type[company.company_type] || '-' }}
      </DataItem>

      <DataItem title="Рейтинг">
        <SimpleRate :company_rating="company.company?.company_rating" />
      </DataItem>

      <DataItem title="Тип налогообложения" v-if="is_farmer">
        <template v-if="!is_verificating">-</template>
        <div v-else>
          <Tag>
            <template #icon> <AuditOutlined /> </template>
            {{ vat_type[company.company.vat] }}
          </Tag>
        </div>
      </DataItem>

      <DataItem title="Способ доставки" v-if="is_farmer">
        <template v-if="!is_verificating">-</template>
        <template v-else>
          <Space :size="0">
            <Tag v-if="company.company?.can_deliver">
              <template #icon>
                <SupplierDelivery />
              </template>
              Доставка
            </Tag>
            <Tag>
              <template #icon><SelfDelivery /></template>
              Самовывоз
            </Tag>
          </Space>
        </template>
      </DataItem>

      <DataItem title="ФИО подписанта" v-if="is_verificating">
        <Space direction="vertical" :size="6">
          {{ company.company?.signatory_full_name }}
          <Tag v-if="company.company?.signatory_powers">
            Руководитель организации</Tag
          >
        </Space>
      </DataItem>

      <DataItem title="ИНН руководителя" v-if="is_verificating">
        {{ company.company?.personal_tin || '-' }}
      </DataItem>

      <DataItem title="Общий номер телефона">
        {{ formatPhoneNumber(company.company?.contact_number) || '-' }}
      </DataItem>

      <DataItem title="Договор поставки">
        <template v-if="!is_verificating">-</template>
        <template v-else>
          <span v-if="company.company?.only_individual_contract"
            >Работает только по своему договору</span
          >
          <span v-else-if="company.company?.individual_contract"
            >Есть свой договор поставки, но готов работать по любому
            договору</span
          >
          <span v-else>
            Нет своего договора поставки, готов работать по любому договору
          </span>
        </template>
      </DataItem>

      <DataItem title="Расчётный счёт">
        {{ company.company?.checking_account || '-' }}
      </DataItem>

      <DataItem title="Дата регистрации">
        {{ dayjs(company.created_at).format('DD.MM.YYYY') }}
      </DataItem>
    </Flex>
    <Divider type="vertical" :style="auto_height" />
    <Flex vertical :gap="16" flex="1">
      <h3>Данные организации</h3>

      <DataItem title="Наименование организации">
        {{
          company.company_name
            ? `${company.legal_form} ${company.company_name}`
            : '-'
        }}
      </DataItem>

      <DataItem title="Юридический адрес">
        {{ company.legal_address || '-' }}
      </DataItem>

      <DataItem title="ИНН">
        {{ company.company?.tin || '-' }}
      </DataItem>
      <DataItem title="КПП">
        {{ company.company?.tax_registration_reason || '-' }}
      </DataItem>
      <DataItem title="ОКПО">
        {{ company.company?.okpo || '-' }}
      </DataItem>
      <DataItem title="ОГРН">
        {{ company.company?.bin || '-' }}
      </DataItem>
      <DataItem
        title="Статус организации"
        v-if="!company.company?.tin_status && is_verificating"
      >
        <TgText type="danger">
          {{ OrganisationStatus.LIQUIDATING }}
        </TgText>
      </DataItem>
    </Flex>
    <Divider type="vertical" :style="auto_height" />
    <Flex vertical :gap="16" flex="1"
      ><h3>Данные банка</h3>
      <DataItem title="Наименование банка">
        {{ company.company?.bank || '-' }}
      </DataItem>

      <DataItem title="Кор. счёт">
        {{ company.company?.correspondent_account || '-' }}
      </DataItem>

      <DataItem title="ИНН">
        {{ company.company?.bank_tin || '-' }}
      </DataItem>
      <DataItem title="БИК">
        {{ company.company?.bic || '-' }}
      </DataItem>
      <DataItem
        title="Статус банка"
        v-if="company.company?.bic_status !== 'ACTIVE'"
      >
        <TgText type="danger">
          {{ BankStatus[company.company?.bic_status] }}
        </TgText>
      </DataItem>
    </Flex>
  </Flex>
</template>

<style scoped lang="scss">
h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
}
</style>
