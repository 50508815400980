<script setup lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { Dayjs } from 'dayjs'
import type { SelectProps } from 'ant-design-vue'
import { Button, InputSearch, RangePicker, Select, Spin } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { CustomerAPI } from '@/api/CustomerAPI'
import { FarmerAPI } from '@/api/FarmerAPI'
import { OptionStatuses } from '@/types/enums'
import { useRoute } from 'vue-router'
import router from '@/router'
import { ICustomer, IFarmer } from '@/types/interfaces'
import {
  customInfinityScroll,
  ICustomInfinityScroll,
} from '@/helpers/customInfinityScroll'

interface IProps {
  flagReset: boolean
}

interface IOption {
  value: string
  label: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['sendFilters', 'resetFilters'])
const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes
  },
})

const wordSearch = ref<string>('')
const customerSearch = ref<number[]>([])
const farmerSearch = ref<number[]>([])
const format = 'YYYY-MM-DD'
const dateSearch = ref<[Dayjs | null, Dayjs | null] | null>()
const selectedDate = ref<string[]>()
const statusSearch = ref<string[]>([])

const disableReset = ref<boolean>(true)
const disableWordSearch = ref<boolean>(false)
const disableOtherSearch = ref<boolean>(false)

const optionsCustomers = ref<IOption[]>([])
const optionsFarmers = ref<IOption[]>([])
const optionsStatuses = ref<SelectProps['options']>(OptionStatuses)

const farmerPage = ref(1)
const isAllCustomerFetched = ref(false)
const customerPage = ref(1)
const isAllFarmerFetched = ref(false)
const isLoading = ref(false)
const route = useRoute()

const resetFilters = () => {
  console.log('reset')
  wordSearch.value = ''
  customerSearch.value = []
  farmerSearch.value = []
  if (dateSearch.value) {
    dateSearch.value[0] = null
    dateSearch.value[1] = null
    selectedDate.value = []
  }
  statusSearch.value = []
  disableWordSearch.value = false
  disableOtherSearch.value = false
  disableReset.value = true
  sendFilter()
}

const getCustomerOptions = async () => {
  try {
    isLoading.value = true
    const response = await CustomerAPI.getItems(
      [['status', 'eq', 'active']],
      [],
      [['name', 'desc']],
      20,
      customerPage.value,
    )

    optionsCustomers.value = createCompanyOptions(
      response.items,
      optionsCustomers.value,
    )

    if (response.total_count === optionsCustomers.value.length) {
      isAllCustomerFetched.value = true
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

const getMoreCustomers = (event: ICustomInfinityScroll) => {
  if (!isAllCustomerFetched.value && customInfinityScroll(event)) {
    customerPage.value++
    getCustomerOptions()
  }
}

const getFarmerOptions = async () => {
  try {
    isLoading.value = true
    const response = await FarmerAPI.getItems(
      [['status', 'eq', 'active']],
      [],
      [['name', 'desc']],
      20,
      farmerPage.value,
    )

    optionsFarmers.value = createCompanyOptions(
      response.items,
      optionsFarmers.value,
    )

    if (response.total_count === optionsFarmers.value.length) {
      isAllFarmerFetched.value = true
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

const getMoreFarmers = (event: ICustomInfinityScroll) => {
  if (!isAllFarmerFetched.value && customInfinityScroll(event)) {
    farmerPage.value++
    getFarmerOptions()
  }
}

const createCompanyOptions = (
  data: IFarmer[] | ICustomer[],
  values: IOption[],
) => {
  let options: IOption[] = values
  data.forEach((company: IFarmer | ICustomer) => {
    options.push({
      value: company?.id,
      label: company?.name,
    })
  })
  return options
}

const checkDisable = () => {
  disableOtherSearch.value = !!wordSearch.value.length
  disableWordSearch.value = !!(
    customerSearch.value.length ||
    farmerSearch.value.length ||
    selectedDate.value?.length ||
    statusSearch.value.length
  )
  disableReset.value = !(disableOtherSearch.value || disableWordSearch.value)
}

const onRangeChange = (date: [Dayjs, Dayjs], dateString: [string, string]) => {
  if (dateString[0] == '' || dateString[1] == '') {
    selectedDate.value = []
  } else {
    selectedDate.value = dateString
  }
  sendFilter()
}

const createFilter = () => {
  let filter = []
  if (customerSearch.value.length) {
    filter.push([
      'customer_id',
      'in',
      customerSearch.value?.map((customer) => customer),
    ])
  }
  if (farmerSearch.value.length) {
    if (filter.length) filter.push('OR')
    filter.push([
      'farmer_id',
      'in',
      farmerSearch.value?.map((farmer) => farmer),
    ])
  }
  if (statusSearch.value.length) {
    if (filter.length) filter.push('OR')
    filter.push(['status', 'in', statusSearch.value?.map((status) => status)])
  }
  if (selectedDate.value?.length) {
    let dateFrom = new Date(selectedDate.value[0])
    let dateTo = new Date(selectedDate.value[1])

    if (filter.length) filter.push('AND')
    filter.push(['general_order.order_date', 'ge', dateFrom])
    filter.push('AND')
    filter.push(['general_order.order_date', 'le', dateTo])
  }
  return filter
}

const sendFilter = () => {
  checkDisable()
  if (disableWordSearch.value) {
    emit('sendFilters', createFilter())
  }
  if (disableOtherSearch.value) {
    emit('sendFilters', [], wordSearch.value)
  }
  if (disableReset.value) {
    emit('resetFilters')
  }
}

const set_filters_from_route = () => {
  if (route.query) {
    const type: 'farmer' | 'customer' = route.query.type as
      | 'farmer'
      | 'customer'
    const id = Number(route.query.company_id)
    if (type === 'farmer') farmerSearch.value.push(id)
    else if (type === 'customer') customerSearch.value.push(id)
    sendFilter()
  }
}

onMounted(() => {
  Promise.allSettled([getCustomerOptions(), getFarmerOptions()]).then(() => {
    set_filters_from_route()
  })
})

watch(
  () => props.flagReset,
  () => {
    resetFilters()
  },
)
</script>

<template>
  <div class="filters">
    <div class="word-date-status">
      <InputSearch
        class="word-search"
        enter-button
        mode="tags"
        :disabled="disableWordSearch"
        v-model:value="wordSearch"
        @search="sendFilter"
        placeholder="Поиск по ключевому слову"
      />

      <RangePicker
        class="datepicker"
        :disabled="!!disableOtherSearch"
        v-model:value="dateSearch"
        :locale="locale"
        :placeholder="['От', 'До']"
        :format="format"
        :inputReadOnly="true"
        @change="onRangeChange"
      />

      <Select
        :max-tag-count="1"
        mode="multiple"
        class="select-status"
        :disabled="!!disableOtherSearch"
        :options="optionsStatuses"
        v-model:value="statusSearch"
        @select="sendFilter"
        @change="sendFilter"
        placeholder="Статус заказа"
      />
    </div>

    <div class="customer-farmer-reset">
      <Select
        :max-tag-count="1"
        mode="multiple"
        class="select"
        :disabled="!!disableOtherSearch"
        :options="optionsCustomers"
        v-model:value="customerSearch"
        @select="sendFilter"
        @change="sendFilter"
        @popupScroll="getMoreCustomers"
        placeholder="Покупатель"
      >
        <template #dropdownRender="{ menuNode: menu }">
          <Spin :spinning="isLoading" size="medium">
            <v-nodes :vnodes="menu" />
          </Spin>
        </template>
      </Select>

      <Select
        :max-tag-count="1"
        mode="multiple"
        class="select"
        :disabled="!!disableOtherSearch"
        :options="optionsFarmers"
        v-model:value="farmerSearch"
        @select="sendFilter"
        @change="sendFilter"
        @popupScroll="getMoreFarmers"
        placeholder="Поставщик"
      >
        <template #dropdownRender="{ menuNode: menu }">
          <Spin :spinning="isLoading" size="medium">
            <v-nodes :vnodes="menu" />
          </Spin>
        </template>
      </Select>

      <Button
        type="text"
        class="reset-filters"
        :disabled="!!disableReset"
        @click="resetFilters"
      >
        <template #icon> <CloseOutlined /> </template>
        Сбросить фильтры
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filters {
  .word-date-status {
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    gap: 12px;

    .datepicker,
    .select-status {
      min-width: 30%;
    }
  }

  .customer-farmer-reset {
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    gap: 12px;

    .select {
      width: 100%;
    }
  }

  .tags-select {
    overflow-y: hidden;
    min-width: 300px;
    max-height: 32px;
  }

  .datepicker {
    min-width: 240px;
  }
}
</style>
