import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies()

class ProductCategoryModel {
  async getItems(
    filter: any[],
    withs: string[] = [
      'all_children_product_categories',
      'parameters',
      'product_category',
    ],
    custom: string | undefined = undefined,
  ): Promise<any> {
    return RequestManager.request({
      model: custom ? APIModels.Category : APIModels.ProductCategoryOptimized,
      method: EgalMethods.GetItems,
      responseType: 'items_data',
      data: { filter, withs },
    })
  }
}

const ProductCategoryAPI = new ProductCategoryModel()
export { ProductCategoryAPI }
