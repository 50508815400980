<script setup lang="ts">
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Divider,
  Form,
  FormItem, Input,
  InputNumber,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Row,
  Segmented,
  Space,
  Textarea,
} from 'ant-design-vue'
import { computed, ComputedRef, ref, UnwrapRef } from 'vue'
import { fontSize14, primaryButton } from '@/assets/EgalStyles/EButton'
import { Rule } from 'ant-design-vue/es/form'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { IAddress, IContact } from '@/types/interfaces'
import { FirstFormState } from '@/views/Customer/CustomerCreateRequestView.vue'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'

interface Props {
  addressList: IAddress[]
  contactPersonList: IContact[]
  formState: UnwrapRef<FirstFormState>
}

const props = defineProps<Props>()
const emit = defineEmits(['triggerValidation', 'editFirstForm'])
const modalStore = useModalStore()
const width100 = { width: '100%' }
const height100 = { height: '100%' }
const radioStyle = {
  display: 'flex',
  height: '30px',
  paddingLeft: '0',
}
const firstFormRef = ref()
defineExpose({
  firstFormRef,
})
const format = 'DD MMM, YYYY'
const deliveryFormat = 'DD MMM, YYYY'
const isSelectAddress = ref(false)
const disabledDeliveryDate = (current: Dayjs) => {
  const currentDate = dayjs()
  const endDate = currentDate.add(180, 'days')
  return (
    current &&
    (current < currentDate.endOf('day') || current > endDate.endOf('day'))
  )
}

const rules: ComputedRef<Record<string, Rule[]>> = computed(() => {
  return {
    expiration_date: [{ required: true, message: 'Укажите дату и время' }],
    delivery_method: [
      {
        required: true,
        message: 'Выберите способ получения',
        type: 'array',
      },
    ],
    should_be_delivered_in_days: [
      { min: 1, message: 'Не меньше 1 дня', type: 'number' },
      { max: 180, message: 'Не больше 180 дней', type: 'number' },
    ],
    payment_method: [
      {
        required: true,
        message: 'Выберите условия оплаты',
        type: 'array',
      },
    ],
    payment_delay_days: [
      { min: 1, message: 'Не меньше 1 дня', type: 'number' },
      { max: 90, message: 'Не больше 90 дней', type: 'number' },
      {
        required: props?.formState?.is_pay_late,
        message: 'Не меньше 1 и не больше 90 дней',
        type: 'number',
      },
    ],
    comment: [{ max: 1000, message: 'Максимум 1000 символов', type: 'string' }],
  }
})

const disabledDate = (current: Dayjs) => {
  return current && current < dayjs().endOf('day')
}

const saveAddress = (id: number) => {
  emit('editFirstForm', 'addressIsError', false)
  emit(
    'editFirstForm',
    'savedAddress',
    props.addressList.find((address: IAddress) => address.id === id) || null,
  )
}

const changeDeliveryPayment = (e: any) => {
  emit('editFirstForm', 'delivery_payment_type', [e.target.value])
}

const deliveryDateTypes = [
  {
    value: 'date',
    label: 'К дате',
  },
  {
    value: 'days',
    label: 'В днях',
  },
]
const handleOpenSelectAddress = () => {
  modalStore.openModal(ModalKeys.SelectAddress, {
    selectedAddress: props.formState.savedAddress?.id || null,
    addressList: props.addressList,
    onSave: saveAddress,
    redirectWarningMessage:
      'Сбросить текущие настройки торга на покупку и перейти в профиль компании для внесения нового адреса.',
  })
}
</script>

<template>
  <div class="request-supply-requirements">
    <Form :model="props.formState" ref="firstFormRef" :rules="rules">
      <Row :gutter="48" justify="center">
        <Col span="7">
          <Space :size="40" direction="vertical" class="requirements-space">
            <div class="date-block">
              <Space :size="8" direction="vertical">
                <span class="form-label form-label-required"
                  >До какого числа действует торг на покупку?</span
                >
                <Space :size="8" direction="vertical">
                  <FormItem name="expiration_date">
                    <DatePicker
                      size="large"
                      :value="props.formState.expiration_date"
                      :show-today="false"
                      :disabled-date="disabledDate"
                      :locale="locale"
                      placeholder="Дата и время"
                      :format="format"
                      :inputReadOnly="true"
                      @change="emit('editFirstForm', 'expiration_date', $event)"
                    />
                  </FormItem>
                  <span class="help-text">
                    Поставщики не смогут ответить после окончания этого времени
                  </span>
                </Space>
              </Space>
            </div>
            <div class="delivery-method">
              <Space :size="8" direction="vertical">
                <span class="form-label form-label-required"
                  >Способ получения</span
                >
                <FormItem name="delivery_method">
                  <CheckboxGroup
                    :value="props.formState.delivery_method"
                    @change="emit('editFirstForm', 'delivery_method', $event)"
                  >
                    <Row :gutter="[0, 12]">
                      <Col :span="24">
                        <Checkbox
                          value="is_self_delivery"
                          name="delivery_method"
                          >Самовывоз</Checkbox
                        >
                      </Col>
                      <Col :span="24">
                        <Checkbox
                          value="is_supplier_delivery"
                          name="delivery_method"
                        >
                          Доставка от поставщика
                        </Checkbox>
                      </Col>
                    </Row>
                  </CheckboxGroup>
                </FormItem>

                <div
                  class="child-form-item"
                  v-if="
                    formState.delivery_method.includes('is_supplier_delivery')
                  "
                >
                  <FormItem name="delivery_payment_type">
                    <RadioGroup
                      :value="formState.delivery_payment_type[0]"
                      :options="[
                        {
                          label: 'Включена в стоимость',
                          value: 'is_delivery_included',
                        },
                        {
                          label: 'Оплачивается отдельно',
                          value: 'is_delivery_excluded',
                        },
                      ]"
                      @change="changeDeliveryPayment"
                    />
                  </FormItem>
                </div>
              </Space>
            </div>

            <div
              class="delivery-address"
              v-if="
                props.formState.delivery_method.includes('is_supplier_delivery')
              "
            >
              <Space :size="8" direction="vertical" class="requirements-space">
                <Space :size="8" class="requirements-space">
                  <span class="form-label form-label-required"
                    >Aдрес доставки</span
                  >
                  <Button @click="handleOpenSelectAddress">
                    {{
                      props.formState.savedAddress?.street
                        ? 'Изменить'
                        : 'Выбрать'
                    }}
                  </Button>
                </Space>
                <span v-if="props.formState.addressIsError" class="error"
                  >Выберите адрес доставки</span
                >
                <span
                  v-else
                  :class="
                    (!props.formState.savedAddress && 'not-selected') +
                    ' delivery-address-span'
                  "
                >
                  {{
                    props.formState.savedAddress?.street
                      ? `${props.formState.savedAddress?.street}`
                      : 'Не выбран'
                  }}
                </span>
              </Space>
            </div>
          </Space>
        </Col>
        <Col span="1"><Divider type="vertical" :style="height100" /></Col>
        <Col span="7">
          <Space :size="40" direction="vertical">
            <div class="delivery-date">
              <Space :size="8" direction="vertical">
                <Row
                  justify="space-between"
                  :gutter="[16, 0]"
                  :align="'middle'"
                >
                  <Col><span class="form-label">Срок поставки</span></Col>
                  <Col
                    ><Segmented
                      :value="formState.delivery_date_type"
                      @change="
                        emit('editFirstForm', 'delivery_date_type', $event)
                      "
                      :options="deliveryDateTypes"
                  /></Col>
                </Row>
                <FormItem
                  name="should_be_delivered_at"
                  v-if="props.formState.delivery_date_type === 'date'"
                >
                  <DatePicker
                    size="large"
                    :value="props.formState.should_be_delivered_at"
                    :disabled-date="disabledDeliveryDate"
                    :locale="locale"
                    :show-time="false"
                    :show-today="false"
                    placeholder="Выберите дату"
                    :showNow="false"
                    :format="deliveryFormat"
                    :inputReadOnly="true"
                    @change="
                      emit('editFirstForm', 'should_be_delivered_at', $event)
                    "
                  />
                </FormItem>
                <FormItem name="should_be_delivered_in_days" v-else>
                  <InputNumber
                    :value="props.formState.should_be_delivered_in_days"
                    @change="
                      emit(
                        'editFirstForm',
                        'should_be_delivered_in_days',
                        $event,
                      )
                    "
                    :controls="false"
                    size="large"
                    placeholder="Укажите количество дней"
                    :style="width100"
                  />
                </FormItem>
              </Space>
            </div>
            <div class="payment-conditions">
              <Space :size="8" direction="vertical">
                <span class="form-label form-label-required"
                  >Условия оплаты</span
                >
                <div>
                  <FormItem name="payment_method">
                    <CheckboxGroup
                      :value="props.formState.payment_method"
                      @change="emit('editFirstForm', 'payment_method', $event)"
                    >
                      <Row :gutter="[0, 12]">
                        <Col :span="24">
                          <Checkbox value="is_pay_cash"
                            >Наличный расчёт</Checkbox
                          >
                        </Col>
                        <Col :span="24">
                          <Checkbox value="is_pay_non_cash"
                            >Безналичный расчёт</Checkbox
                          >
                        </Col>
                      </Row>
                    </CheckboxGroup>
                  </FormItem>
                </div>
              </Space>
            </div>

            <div>
              <Space :size="8" direction="vertical">
                <span class="form-label">Отсрочка по оплате</span>
                <FormItem name="is_pay_late">
                  <Checkbox
                    :checked="props.formState.is_pay_late"
                    @change="
                      emit(
                        'editFirstForm',
                        'is_pay_late',
                        $event.target.checked,
                      )
                    "
                  >
                    Нужна отсрочка по оплате
                  </Checkbox>
                </FormItem>
              </Space>
              <div v-if="props.formState.is_pay_late">
                <FormItem name="payment_delay_days">
                  <Space direction="vertical" :style="width100">
                    <InputNumber
                      :value="props.formState.payment_delay_days"
                      @change="
                        emit('editFirstForm', 'payment_delay_days', $event)
                      "
                      :controls="false"
                      size="large"
                      placeholder="Укажите кол-во дней отсрочки"
                      :style="width100"
                      :precision="0"
                    />
                  </Space>
                </FormItem>
              </div>
            </div>
            <div class="services">
              <Space :size="8" direction="vertical">
                <span class="form-label">Услуги</span>
                <div>
                  <FormItem name="standart">
                    <Checkbox
                      :checked="props.formState.standart"
                      @change="
                        emit('editFirstForm', 'standart', $event.target.checked)
                      "
                    >
                      Паллетирование</Checkbox
                    >
                  </FormItem>
                  <div class="child-form-item" v-if="props.formState.standart">
                    <FormItem name="euro">
                      <Checkbox
                        :checked="props.formState.euro"
                        @change="
                          emit('editFirstForm', 'euro', $event.target.checked)
                        "
                      >
                        Нужны европаллеты</Checkbox
                      >
                    </FormItem>
                  </div>
                  <FormItem
                    name="need_unload"
                    v-if="
                      props.formState.delivery_method.includes(
                        'is_supplier_delivery',
                      )
                    "
                  >
                    <Checkbox
                      :checked="props.formState.need_unload"
                      @change="
                        emit(
                          'editFirstForm',
                          'need_unload',
                          $event.target.checked,
                        )
                      "
                    >
                      Разгрузка товара</Checkbox
                    >
                  </FormItem>
                </div>
              </Space>
            </div>
          </Space>
        </Col>
        <Col span="1"><Divider type="vertical" :style="height100" /></Col>
        <Col span="7">
          <Space :size="20" direction="vertical" :style="width100">
            <Space :size="8" direction="vertical" :style="width100">
              <span class="form-label">Дополнительно</span>
              <FormItem
                help="Максимум 1000 символов"
                :style="width100"
                name="comment"
              >
                <Textarea
                  placeholder="Комментарий к торгу на покупку"
                  :auto-size="{ minRows: 4, maxRows: 8 }"
                  :value="props.formState.comment"
                  :style="{
                    padding: '16px',
                  }"
                  @change="
                    emit('editFirstForm', 'comment', $event.target.value)
                  "
                />
              </FormItem>
            </Space>
          </Space>
        </Col>
      </Row>
    </Form>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.request-supply-requirements {
  padding: 32px;
  .requirements-space {
    width: 100%;
  }
  span.form-label {
    @include h5();
    &-required {
      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    }
  }
  .child-form-item {
    margin-left: 24px;
  }
  :deep(.ant-form-item) {
    margin-bottom: 8px !important;
  }
  span.help-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray-600;
  }
  :deep(.ant-form-item-explain-error) {
    margin-top: 6px !important;
  }
  span.not-selected {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: $gray-600;
  }
  span.delivery-address-span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
  }
  span.error {
    color: $default-danger;
  }
}

:deep(.ant-radio-group) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
