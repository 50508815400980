<script setup lang="ts">

import { Flex, TypographyTitle as TpTitle } from 'ant-design-vue'

interface Props {
  title: string
}

const props = defineProps<Props>()
</script>

<template>
  <Flex vertical>
    <TpTitle :level="5">{{ props.title }}</TpTitle>
    <Flex vertical :gap="4">
      <slot />
    </Flex>
  </Flex>
</template>

<style lang="scss" scoped></style>
