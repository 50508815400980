<script lang="ts" setup>
import { computed, reactive, ref, watch } from 'vue'
import {
  Alert,
  Button,
  Drawer,
  Flex,
  Form,
  FormInstance,
  FormItem,
  InputNumber,
  Tag,
  TypographyText as TpText,
  TypographyTitle as TpTitle,
  List,
  ListItem,
} from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form'
import { SelectOutlined } from '@ant-design/icons-vue'
import { IFarmerProduct, ISpecialPrice } from '@/types/interfaces'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import {
  priceWithoutVat,
  roundNumber,
  splitNumberByThreeDigits,
} from '@/helpers/scripts'
import QuantityExceededTooltip from '@/components/Tooltip/QuantityExceededTooltip.vue'

interface Props {
  customerId: string
  orderPosition: any
  isOpen: boolean
  isFarmer: boolean
}

interface IFormState {
  volume: string
  priceWithVAT: string
  vat_percent: string
  priceWithoutVAT: string
}

const props = defineProps<Props>()

const emit = defineEmits(['close', 'update', 'openProductDrawer'])

const formRef = ref<FormInstance>()
const formState = reactive<IFormState>({
  volume: String(props.orderPosition?.quantity),
  priceWithVAT: String(props.orderPosition?.unit_price),
  vat_percent: props.orderPosition?.vat_percent,
  priceWithoutVAT: String(
    priceWithoutVat(
      props.orderPosition?.unit_price,
      props.orderPosition?.vat_percent,
    ),
  ),
})

const isOpenProductDrawer = ref(false)
const selectedViewProduct = ref<IFarmerProduct>()

const closeProductDrawer = () => {
  selectedViewProduct.value = undefined
  isOpenProductDrawer.value = false
}

const handleUpdate = () => {
  formRef.value?.validateFields().then(() => {
    const data = {
      id: props.orderPosition.id,
      quantity: formState.volume,
      is_special_price_used: false,
    }
    if (props.isFarmer) {
      data.unit_price = formState.priceWithVAT
      data.vat_percent = formState.vat_percent
    }
    emit('update', data)
  })
}

const handleOpenProductDrawer = () => {
  isOpenProductDrawer.value = true
  selectedViewProduct.value = props.orderPosition.farmer_product
  // emit('openProductDrawer', props.orderPosition.farmer_product)
}

const getCustomerSpecialPrice = (specialPrices: ISpecialPrice[]) => {
  return specialPrices?.find((item) => item.customer_id === props.customerId)
}

const validateVolume = async (_rule: Rule, value: string) => {
  const isKg = measurementUnitId.value === 'кг'

  if (value === '') {
    return Promise.resolve()
  } else if (isKg ? Number(value) < 0.01 : Number(value) < 1) {
    return isKg
      ? Promise.reject('Минимум 0,01 кг')
      : Promise.reject('Минимум 1 шт')
  } else if (
    Number(value) < Number(props.orderPosition.farmer_product.min_value)
  ) {
    return Promise.reject(
      `Минимальный заказ ${props.orderPosition.farmer_product.min_value} ${measurementUnitId.value}`,
    )
  } else if (
    Number(value) > Number(props.orderPosition.farmer_product.quantity)
  ) {
    return Promise.reject(
      `В наличии только ${props.orderPosition.farmer_product.quantity} ${measurementUnitId.value}`,
    )
  } else if (Number(value) > 1000000) {
    return Promise.reject('Максимум 1 000 000 кг')
  } else {
    return Promise.resolve()
  }
}

const validatePriceWithVAT = (_rule: Rule, value: string) => {
  if (Number(value) < 0.01) {
    return Promise.reject(`Минимум 1₽/${measurementUnitId.value}`)
  } else if (Number(value) > 1000000) {
    return Promise.reject(`Максимум 1 000 000 ₽/${measurementUnitId.value}`)
  } else {
    return Promise.resolve()
  }
}

const validateVAT = (_rule: Rule, value: string) => {
  if (Number(value) < 0) {
    return Promise.reject('Минимум 0%')
  } else if (Number(value) > 100) {
    return Promise.reject('Максимум 100%')
  } else {
    return Promise.resolve()
  }
}

const calcSum = computed(() =>
  roundNumber(+formState.volume * +formState.priceWithVAT),
)

const isParametersTheSame = computed(() => {
  return !(
    formState.volume == props.orderPosition?.quantity &&
    formState.priceWithVAT == props.orderPosition?.unit_price &&
    formState.vat_percent == props.orderPosition?.vat_percent
  )
})

// const neededSpecialPrice = computed(() =>
//   getCustomerSpecialPrice(props.orderPosition?.farmer_product?.special_prices),
// )

const measurementUnitId = computed(
  () => props.orderPosition.farmer_product.measurement_unit_id,
)

watch(
  () => [formState.priceWithVAT, formState.vat_percent],
  () => {
    formState.priceWithoutVAT = String(
      priceWithoutVat(+formState.priceWithVAT, +formState.vat_percent),
    )
  },
)

watch(
  () => props.orderPosition,
  () => {
    formState.volume = String(props.orderPosition?.quantity)
    formState.priceWithVAT = String(props.orderPosition?.unit_price)
    formState.vat_percent = props.orderPosition?.vat_percent
    formState.priceWithoutVAT = String(
      priceWithoutVat(
        props.orderPosition?.unit_price,
        props.orderPosition?.vat_percent,
      ),
    )
  },
)

const calcPriceWithoutVAT = (orderPosition: any) => {
  if (
    formState.priceWithVAT &&
    orderPosition?.farmer_product?.product?.measurement_unit_id
  ) {
    return `${formState.priceWithVAT}₽/${orderPosition?.farmer_product?.product?.measurement_unit_id}`
  } else if (formState.priceWithVAT) {
    return `${formState.priceWithVAT}₽`
  } else return '-'
}

const isQuantityExceeded = computed(() => {
  return (
    Number(props.orderPosition?.farmer_product.quantity) <
    Number(formState?.volume)
  )
})
</script>
<template>
  <Drawer
    :open="props.isOpen"
    @close="() => emit('close')"
    destroyOnClose
    :width="500"
    :headerStyle="{
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      padding: '24px 32px',
    }"
    :bodyStyle="{ padding: '24px 32px' }"
    :footerStyle="{
      padding: '24px 32px',
      display: 'flex',
      justifyContent: 'flex-end',
    }"
  >
    <template #extra>
      <Flex
        style="min-width: 408px"
        :gap="8"
        vertical
        class="drawer-header_subtitle"
        align="flex-start"
      >
        <TpTitle :level="4">
          {{ orderPosition?.farmer_product.product?.fullName }}
        </TpTitle>
        <Flex vertical align="flex-start" :gap="4">
          <Flex v-if="orderPosition?.is_special_price_used" :gap="8">
            <Tag color="green">Спеццена </Tag>
          </Flex>
          <Button
            style="padding: 0"
            type="link"
            :onClick="handleOpenProductDrawer"
          >
            <template #icon>
              <SelectOutlined />
            </template>
            Открыть страницу товара
          </Button>
        </Flex>
      </Flex>
    </template>

    <Flex vertical :gap="32" class="drawer-body">
      <template v-if="isFarmer">
        <Alert
          type="info"
          show-icon
          v-if="!orderPosition?.is_special_price_used"
        >
          <template #message>
            Изменения не влияют на товар в вашем каталоге. Они будут применены
            только для этого заказа, но их надо будет согласовать с покупателем.
          </template>
        </Alert>
        <Alert type="info" show-icon v-else>
          <template #message>
            Товар продается по спеццене. При изменении параметров, товар будет
            продаваться по обычной цене. Изменения нужно будет согласовать с
            покупателем.
          </template>
        </Alert>
      </template>
      <template v-else>
        <Alert type="info" show-icon>
          <template #message>
            <template v-if="orderPosition?.is_special_price_used">
              Товар продается по спеццене. При изменении параметров, товар будет
              продаваться по обычной цене.
            </template>
            Изменения товара нужно будет согласовать с поставщиком
          </template>
        </Alert>
      </template>

      <List v-if="!isFarmer" class="product-parameters-list">
        <ListItem>
          <TpText type="secondary">Цена c НДС</TpText>
          <TpText>{{ calcPriceWithoutVAT(orderPosition) }}</TpText>
        </ListItem>
        <ListItem>
          <TpText type="secondary">НДС</TpText>
          <TpText>{{ `${formState.vat_percent}%` }}</TpText>
        </ListItem>
      </List>

      <Form ref="formRef" :model="formState" layout="vertical">
        <FormItem
          name="volume"
          label="Объём товара"
          required
          class="volume-input"
          :rules="[
            {
              required: true,
              message: 'Укажите количество',
            },
          ]"
        >
          <InputNumber
            v-model:value="formState.volume"
            :controls="false"
            decimalSeparator=","
            size="large"
            :precision="
              orderPosition?.farmer_product.measurement_unit_id === 'кг' ? 2 : 0
            "
          >
            <template #addonAfter>
              {{
                orderPosition?.farmer_product?.measurement_unit_id
                  ? orderPosition?.farmer_product?.measurement_unit_id
                  : ''
              }}
            </template>
          </InputNumber>
        </FormItem>
        <List>
          <ListItem>
            <TpText
              :ellipsis="false"
              :class="{
                'quantity-exceeded': isQuantityExceeded,
              }"
            >
              В наличии
            </TpText>
            <TpText
              type="secondary"
              :class="{
                'quantity-exceeded': isQuantityExceeded,
              }"
            >
              {{
                props.orderPosition?.farmer_product.quantity +
                ' ' +
                props.orderPosition?.farmer_product.measurement_unit_id
              }}
              <QuantityExceededTooltip
                :show="isQuantityExceeded"
                :placement="'bottomLeft'"
                :text="'Недостаточно для минимального заказа'"
              />
            </TpText>
          </ListItem>
        </List>

        <FormItem
          name="priceWithVAT"
          label="Цена с НДС"
          required
          v-if="isFarmer"
          :rules="[
            {
              validator: validatePriceWithVAT,
              trigger: 'change',
            },
            {
              required: true,
              message: 'Укажите цену',
            },
          ]"
        >
          <InputNumber
            v-model:value="formState.priceWithVAT"
            :step="0.01"
            decimalSeparator=","
            :controls="false"
            size="large"
            :precision="2"
          >
            <template #addonAfter>
              {{
                props.orderPosition?.farmer_product?.measurement_unit_id
                  ? `₽/${props.orderPosition?.farmer_product?.measurement_unit_id}`
                  : '₽'
              }}
            </template>
          </InputNumber>
        </FormItem>

        <FormItem
          name="vat_percent"
          label="НДС"
          v-if="isFarmer"
          required
          :rules="[
            {
              validator: validateVAT,
              trigger: 'change',
            },
            {
              required: true,
              message: 'Укажите значение',
            },
          ]"
        >
          <InputNumber
            v-model:value="formState.vat_percent"
            :controls="false"
            size="large"
          >
            <template #addonAfter> % </template>
          </InputNumber>
        </FormItem>

        <FormItem
          name="priceWithoutVAT"
          label="Цена без НДС"
          required
          v-if="isFarmer"
        >
          <InputNumber
            v-model:value="formState.priceWithoutVAT"
            disabled
            :step="0.01"
            decimalSeparator=","
            :controls="false"
            size="large"
          >
            <template #addonAfter>
              {{
                props.orderPosition?.farmer_product?.measurement_unit_id
                  ? `₽/${props.orderPosition?.farmer_product?.measurement_unit_id}`
                  : '₽'
              }}
            </template>
          </InputNumber>
        </FormItem>
      </Form>
      <Flex justify="space-between" align="flex-end" class="total-price">
        <TpText style="font-size: 16px">Сумма</TpText>
        <TpTitle :level="3">
          {{ `${splitNumberByThreeDigits(roundNumber(calcSum))} ₽` }}
        </TpTitle>
      </Flex>
    </Flex>

    <template #footer>
      <Flex :gap="12">
        <Button size="large" :onclick="() => emit('close')">Закрыть</Button>
        <Button
          :disabled="!isParametersTheSame"
          type="primary"
          size="large"
          :onClick="handleUpdate"
          >{{ `Сохранить изменения ${isFarmer ? 'для заказа' : ''}` }}</Button
        >
      </Flex>
    </template>

    <DrawerForm
      :hide-control-elements="true"
      :is-farmer="props.isFarmer"
      :product="selectedViewProduct"
      @closeDrawer="closeProductDrawer"
      :open="isOpenProductDrawer"
      :showCreateSpecialPrice="false"
    />
  </Drawer>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.drawer-header {
  &_subtitle {
    width: min-content;
  }
}

:deep(.ant-alert) {
  align-items: flex-start;

  & .anticon {
    margin-top: 4px;
  }
}

:deep(.ant-input-number-group-wrapper) {
  width: 100%;

  & .ant-input-number {
    width: 100%;
  }
}

:deep(.ant-typography) {
  margin: 0 !important;
}

.drawer-body {
  :deep(.ant-list-item) {
    padding-right: 0;
    padding-left: 0;
  }
}

.total-price {
  margin-top: auto;
}

.drawer-body {
  height: 100%;
}

.quantity-exceeded {
  font-size: 16px;
  font-weight: 600;
  color: #faad14;
}

.volume-input {
  margin-bottom: 0px;
}
</style>
