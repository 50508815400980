<script setup lang="ts">
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import RecipientStatus from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/components/RecipientStatus/RecipientStatus.vue'
import PartnershipStatus from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/components/PartnershipStatus/PartnershipStatus.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { onMounted, ref } from 'vue'
import { PartnersAPI } from '@/api/PartnersAPI'

interface IProps {
  isBigTitle: {
    type: boolean
    default: false
  }
  isShowBottomBorder: {
    type: boolean
    default: true
  }
  isShowAddressIcon: {
    type: boolean
    default: true
  }
  isShowRecipientStatus: {
    type: boolean
    default: true
  }
  item: {
    default: () => { status: 'Success' }
    type: object
  }
}

onMounted(async () => {
  await getMyPartners()
})

const props = defineProps<IProps>()
const partners = ref([])
const farmerIndex = ref()

const isPartner = () => !!partners.value[farmerIndex.value]

const getMyPartners = async () => {
  await PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
    farmerIndex.value = res?.items.findIndex(
      (item) => item.company_id === props.item?.farmer_id,
    )
  })
}
</script>

<template>
  <div
    :class="[
      'data-list-block-item',
      isShowBottomBorder ? '' : 'data-list-block-item__without-border',
    ]"
  >
    <div class="data-list-block-item_company">
      <RecipientStatus
        v-if="isShowRecipientStatus"
        :status="item?.answer_status"
      />
      <PartnershipStatus :is-partner="isPartner()" v-if="isPartner()" />
      <div
        :class="[
          'data-list-block-item_company-name',
          isBigTitle ? 'data-list-block-item_company-name__big-text' : '',
        ]"
      >
        {{ item?.farmer?.legal_form }} {{ item?.farmer?.name }}
      </div>
    </div>
    <div class="data-list-block-item_address">
      <EnvironmentOutlined v-if="isShowAddressIcon" />
      <div
        class="data-list-block-item_address-text"
        :class="{ 'with-padding': !isShowAddressIcon }"
      >
        {{ item?.farmer?.legal_address }}
      </div>
    </div>
    <SimpleRate :company_rating="item.farmer.company_rating" />
  </div>
</template>

<style lang="scss">
.data-list-block-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0000000f;
  padding: 16px 32px;
  &_company {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    &-name {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      &__big-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  &_address {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    &-text {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);

      &.with-padding {
        padding-left: 16px;
      }
    }
  }
  &__without-border {
    border: none;
  }
}
</style>
