<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import {
  dateToLocale,
  getUserIdTemporary,
  roundNumber,
  splitNumberByThreeDigits,
} from '@/helpers/scripts'
import { GeneralOrderAPI } from '@/api/GeneralOrderAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import CustomerGeneralOrderView from '@/views/Customer/CustomerGeneralOrderView.vue'
import InfiniteLoading from 'v3-infinite-loading'
import router from '@/router'
import { useStatus } from '@/composables/useStatus'
import { useCentrifuge } from '@/composables/useCentrifuge'
import { Sockets } from '@/types/enums'
import EmptyHistory from '@/components/Customer/EmptyHistory.vue'
import CustomerOrderRow from '@/components/Orders/CustomerOrderRow.vue'
import CustomerComplexOrderRow from '@/components/Orders/CustomerComplexOrderRow.vue'
import FarmLoader from '@/components/Loaders/FarmLoader.vue'
import { useRoute } from 'vue-router'
import emitter from "@/emitter";

const route = useRoute()

const props = defineProps({
  openOrderHistory: Number,
})
// примерный вид данных с бэка
const farmerOrders = ref<any>([])

// карточка просмотра заказа поставщика
const isCardOpen = ref(false)
const openedOrder = ref()
const isFirstFetched = ref(true)

const openCard = (order: any) => {
  openedOrder.value = order
  isCardOpen.value = true
  isFirstFetched.value = true
}

const closeCard = () => {
  openedOrder.value = null
  isCardOpen.value = false
  router.push('/history')
}

const currentPage = ref(1)

const cancelOrder = (order: any) => {
  //  cancel request
  // farmerOrders.value.map((i) => {
  //   if (i.id === order.id) {
  //     i.status = OrderStatus.Cancelled
  //   }
  // })
}

const sendToApproveOrder = (order: any) => {
  //  send To Approve Order request
}

const editOrder = (newOrder: any) => {
  return
}

const signContract = (order: any) => {
  // signContract request
}

const tHeaders = [
  { name: 'Поставщик', value: 'farmers' },
  { name: 'Статус', value: 'status' },
  { name: 'Способ получения', value: 'delivery_method' },
  { name: 'Оформлен', value: 'order_date' },
  { name: 'Получение', value: 'delivery_date' },
  { name: 'Сумма заказа', value: 'price' },
]

const getItemData = (order: any, header: string) => {
  if (header === 'price') {
    return roundNumber(splitNumberByThreeDigits(Number(order[header]))) + ' ₽'
  }

  if (header === 'delivery_method' && order.orders.length > 1) {
    const hasDelivery = order.orders.some(
      (order) => order.delivery_method === 'Доставка фермером',
    )
    const hasSelfPickup = order.orders.some(
      (order) => order.delivery_method === 'Самовывоз',
    )

    if (hasDelivery && !hasSelfPickup) {
      return {
        text: 'Доставка',
        to_address: order.to_address || '',
      }
    } else if (!hasDelivery && hasSelfPickup) {
      return {
        text: 'Самовывоз',
        to_address: '',
      }
    } else if (hasDelivery && hasSelfPickup) {
      return {
        text: 'Доставка и самовывоз',
        to_address: order.to_address || '',
      }
    }
  }

  if (header === 'order_date' || header === 'delivery_date') {
    return dateToLocale(order[header])
  }

  if (header === 'farmers') {
    if (!order.orders?.length) {
      return ''
    }

    if (order.orders.length > 1) {
      return 'Комплексный заказ'
    }

    return `${order.orders[0].farmer.legal_form} ${order.orders[0].farmer.name}`
  }

  return order[header]
}

/**
 * Определения стиля и текста у бейджа со статусом заказа
 */
const getStatusClass = (order: any) => {
  const { setStatus, getStatusClass: getClass } = useStatus()
  setStatus(order.status)
  const { cssClass } = getClass.value
  return cssClass
}

const isFetching = ref(true)

emitter.on('refresh-token', async (e) => {
  await getAllCustomerOrders()
  await checkOpenOrderHistory()
  await connectGeneralOrdersSockets()
})

onMounted(async () => {
  await getAllCustomerOrders()
  await checkOpenOrderHistory()
  await connectGeneralOrdersSockets()
})

const getAllCustomerOrders = async () => {
  const fetchedData = await GeneralOrderAPI.getGeneralOrders(
    currentPage.value,
    [['customer_id', 'eq', getUserIdTemporary()]],
    [
      'orders',
      'orders.partnership',
      'orders.farmer',
      'orders.history',
      'orders.order_marks',
      'orders.farmer.company_rating',
      'delivery_address',
      'orders.shipping_address',
      'history',
    ],
  )
  if (fetchedData?.items?.length < 10) isAllOrdersFetched.value = true
  if (fetchedData?.items) {
    farmerOrders.value.push(...fetchedData?.items)
  }
  isFetching.value = false
  isNewPageFetching.value = false
}

const refetchOrders = async () => {
  isFetching.value = true
  currentPage.value = 1
  isAllOrdersFetched.value = false
  farmerOrders.value = await GeneralOrderAPI.getGeneralOrders(
    currentPage.value,
    [['customer_id', 'eq', getUserIdTemporary()]],
    [
      'orders',
      'orders.partnership',
      'orders.farmer',
      'orders.history',
      'orders.order_marks',
      'orders.farmer.company_rating',
      'delivery_address',
      'orders.shipping_address',
      'history',
    ],
  ).then((res) => res?.items)
  isFetching.value = false
}

// const getAllCustomerOrdersUpdate = async (filters: string[][] = []) => {
//   currentPage.value = 1
//   const fetchedData = await GeneralOrderAPI.getGeneralOrders(
//     currentPage.value,
//     filters,
//     ['orders', 'orders.farmer', 'orders.farmer.company_rating'],
//   )
//   if (fetchedData.length < 10) isAllOrdersFetched.value = true
//   farmerOrders.value = fetchedData
//   isFetching.value = false
//   isNewPageFetching.value = false
//   // sortOrders()
// }

const isNewPageFetching = ref(false)
const isAllOrdersFetched = ref(false)

const connectGeneralOrdersSockets = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  startCentrifuge()

  addChanel(
    Sockets.GeneralOrder + '.' + String(getUserIdTemporary()),
    updateGeneralsOrders,
  )
}

const updateGeneralsOrders = async (ctx?: any) => {
  newOrderIsFetching.value = true
  if (ctx) {
    const index = farmerOrders.value.findIndex(
      (item: any) => item.id === ctx.data.model_id,
    )
    if (index !== -1) {
      await GeneralOrderAPI.getGeneralOrder([
        ['id', 'eq', ctx.data.model_id],
      ]).then((res) => {
        const updatedItem = { ...farmerOrders.value[index], ...res[0] }
        const updatedOrders = [...farmerOrders.value]
        updatedOrders.splice(index, 1, updatedItem)
        farmerOrders.value = updatedOrders
      })
    } else {
      await GeneralOrderAPI.getGeneralOrder([
        ['id', 'eq', ctx.data.model_id],
      ]).then((res) => {
        farmerOrders.value = [res[0], ...farmerOrders.value]
      })
    }
    newOrderIsFetching.value = false
  }
}

const loadNextPage = () => {
  isNewPageFetching.value = true
  currentPage.value += 1
  getAllCustomerOrders()
}

const newOrderIsFetching = ref(false)
const newOrderIsFetchingDelay = ref(false)

const updateOrders = async (order_id: number) => {
  if (!order_id || isFirstFetched.value) {
    isFirstFetched.value = false
    return
  }
  newOrderIsFetchingDelay.value = true

  currentPage.value = 1
  setTimeout(async () => {
    const res = await GeneralOrderAPI.getGeneralOrders(
      currentPage.value,
      [['id', 'eq', order_id]],
      ['orders', 'orders.farmer', 'orders.farmer.company_rating'],
    )
    openedOrder.value = res?.items?.[0]
    newOrderIsFetchingDelay.value = false
  }, 3000)
}

const checkOpenOrderHistory = async () => {
  if (props.openOrderHistory) isCardOpen.value = true
}

watch(props, async () => {
  await checkOpenOrderHistory()
})

watch(route, (newRouter) => {
  if (newRouter.path === '/history') {
    isCardOpen.value = false
  }
})

</script>

<template>
  <div v-if="!isFetching" class="wrapper-home home">
    <EmptyHistory v-if="farmerOrders?.length === 0" />
    <div v-else>
      <div v-if="!isCardOpen" class="home__content">
        <div class="home__header">
          <div class="home__title">История заказов</div>
        </div>
        <!--      <OrderHistoryFilters @updateOrders="getAllCustomerOrdersUpdate" />-->
        <div
          v-if="isFetching"
          class="home__orders loader"
          style="overflow-y: hidden"
        >
          <StarLoader />
        </div>

        <div
          v-else
          :class="{ empty: farmerOrders?.length === 0 }"
          class="home__orders"
        >
          <div v-if="farmerOrders?.length !== 0" class="home__list">
            <div class="table-header row">
              <div
                v-for="header in tHeaders"
                :key="header.name"
                class="home__tcell"
              >
                {{ header.name }}
              </div>
            </div>
            <div
              v-for="genOrder in farmerOrders"
              :key="genOrder.id"
              class="order-item-wrapper"
            >
              <CustomerOrderRow
                v-if="genOrder.orders?.length === 1"
                :key="genOrder.orders[0].id"
                :generalId="genOrder.id"
                :delivery_date="genOrder.delivery_date"
                :order="genOrder.orders[0]"
                :order_date="genOrder.order_date"
                :delivery_address="genOrder.delivery_address"
                :history="genOrder.history"
              />
              <CustomerComplexOrderRow
                v-else
                :key="genOrder.id"
                :genOrder="genOrder"
              />

              <!--              <div v-else>-->
              <!--                {{ getItemData(forder, header.value) }}-->
              <!--                <div-->
              <!--                    class="company-rating"-->
              <!--                    v-if="-->
              <!--                    header.value == 'farmers' &&-->
              <!--                    forder.orders.length == 1-->
              <!--                  "-->
              <!--                >-->
              <!--                  <SimpleRate-->
              <!--                      :company_rating="forder.orders[0].farmer.company_rating"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <InfiniteLoading
              :distance="100"
              class="infinite-loading"
              @infinite="loadNextPage"
            />
          </div>
        </div>
        <div
          v-if="isNewPageFetching && !isAllOrdersFetched"
          class="home__orders new-page-loader"
          style="overflow-y: hidden"
        >
          <StarLoader />
        </div>
      </div>
      <!--:item="openedOrder"-->
      <CustomerGeneralOrderView
        v-if="isCardOpen"
        :newOrderIsFetching="newOrderIsFetching"
        :newOrderIsFetchingDelay="newOrderIsFetchingDelay"
        @approve="sendToApproveOrder(openedOrder)"
        @back="closeCard"
        @cancel="cancelOrder(openedOrder)"
        @edit="editOrder"
        @sign-contract="signContract(openedOrder)"
        @update-orders="updateOrders"
        @refetchOrders="refetchOrders"
      />
    </div>
  </div>
  <div v-else class="customer-history-loader">
    <FarmLoader :fullscreen="true" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.customer-history-loader {
  width: 100%;
}

.company-rating {
  margin-top: 4px;
}

:deep(.sidebar) {
  min-height: unset;
}

.wrapper-home {
  gap: 32px;
  @include wrapper();
  padding-top: 20px;
}

.more-complex {
  display: flex;
  gap: 13px;
  color: $primary-accent;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  margin-top: 4.5px;

  .more-text {
    border-bottom: 1px dashed $primary-accent;
  }
}

.home {
  &__sidebar {
    max-width: 208px;
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: $gray-800;
  }

  &__tcell {
    word-break: break-word;
    padding: 0 20px;
  }

  &__orders {
    &.empty {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $gray-600;
    }

    &.loader {
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.new-page-loader {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.5);
    }
  }

  &__list {
    display: grid;
    grid-auto-flow: row;
    background: #ffffff;
    border: 1px solid $gray-300;
    overflow: hidden;
    border-radius: 16px;

    .row {
      font-size: 14px;
      line-height: 17px;
      color: $gray-800;
      padding: 20px 12px;
      display: grid;
      grid-template-columns: 2fr 264px 2fr 120px 120px 1fr;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__theader {
    font-weight: 500;
    text-transform: uppercase;
    background-color: $gray-300;
    color: $gray-600;
  }

  &__trow {
    font-weight: 400;
    cursor: pointer;
  }
}

.home__list {
  height: 100%;
  max-height: 670px;
  overflow: auto;
  margin-bottom: 20px;
  .table-header {
    background-color: #f5f7f8;
    text-transform: uppercase;
    color: $gray-600;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 12px;
    border-radius: 16px 16px 0 0;
  }
}
</style>
