<script lang="ts" setup>
import { Button, Table } from 'ant-design-vue'

import { computed } from 'vue'
import { IPartner } from '@/types/interfaces'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {roundNumber, splitNumberByThreeDigits} from '@/helpers/scripts'
import router from '@/router'
import { Roles } from '@/types/enums'
import PartnerDataCell from '@/components/Partners/PartnersTable/PartnerDataCell.vue'
import {useCookies} from "vue3-cookies";

interface Props {
  tableData: IPartner[]
  isLoading: boolean
}
const props = defineProps<Props>()

const emit = defineEmits(['changeSort'])

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

const columns = [
  {
    title: 'Данные партнера',
    key: 'name',
    width: '47%',
    sorter: (a: string, b: string) => a.length - b.length,
  },
  {
    title: 'Дата последнего заказа',
    key: 'last_date_order',
    width: '185px',
    sorter: (a: string, b: string) => a.length - b.length,
  },
  {
    title: 'Количество завершенных заказов',
    key: 'count',
    width: '172px',
    sorter: (a: string, b: string) => a.length - b.length,
  },
  {
    title: 'Сумма сделок',
    key: 'sum',
    width: '200px',
    sorter: (a: string, b: string) => a.length - b.length,
  },
  {
    title: 'Ссылки',
    key: 'links',
    width: '206px',
  },
]

const getNameSorterField = () => {
  return getCoreCookie()?.company_type === Roles.Customer
    ? 'farmer_name'
    : 'customer_name'
}

const handleChangeSort = (
  page: { pageSize: number; current: number },
  filters: any,
  sorter: any,
) => {
  emit('changeSort', sorter?.columnKey, sorter.order)
}

const to_user_page = (record: any) => {
  router.push({
    path: `/partners/${record?.company_id}`,
    query: { redirectPath: 'partners' },
    replace: true
  })
}

const to_catalog = (company_id: number) => {
  router.push(`/farmer-product/${company_id}`)
}
// const goToOrdersHistory = (record: IPartner) => {
//   // функция отправляет пользователя на страницу истории заказов и подставляет соответствующий фильтр контрагента
//   return {
//     onClick: () => {
//       if (getCoreCookie().roles.includes('farmer')) {
//         router.push(`/history?partner=${record.customer_id}`)
//       } else {
//         router.push(`/history?partner=${record.farmer_id}`)
//       }
//     },
//   }
// }
</script>

<template>
  <div class="table__wrapper">
    <Table
      :customRow="
        (record) => {
          return {
            onClick: () => {
              to_user_page(record)
            },
          }
        }
      "
      :showSorterTooltip="false"
      bordered
      :data-source="props.tableData"
      :columns="getCoreCookie()?.company_type === Roles.Customer ? columns : columns.slice(0, -1)"
      :pagination="false"
      @change="handleChangeSort"
      :loading="isLoading"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <PartnerDataCell
            :name="record.name"
            :legal_address="record.legal_address"
            :vat="record.vat"
            :contact_number="record.contact_number"
            :can_deliver="record.can_deliver"
            :average_mark="record.average_mark"
            :mark_count="record.mark_count"
          />
        </template>
        <template v-else-if="column.key === 'last_date_order'">
          <template v-if="record.last_date_order">
            {{ record.last_date_order }}
          </template>
          <template v-else> - </template>
        </template>
        <template v-else-if="column.key === 'count'">
          {{ splitNumberByThreeDigits(record?.count) }}
        </template>
        <template v-else-if="column.key === 'sum'">
          {{
            splitNumberByThreeDigits(roundNumber(record.sum ? record.sum : 0))
          }}
          ₽
        </template>
        <template v-else-if="column.key === 'links'">
          <Button @click.stop="to_catalog(record.company_id)" type="link">
            Каталог
          </Button>
        </template>
      </template>
    </Table>
  </div>
</template>

<style lang="scss" scoped>
.table {
  &__wrapper {
    flex-grow: 1;
    padding-bottom: 89px;
  }
}

:deep(.ant-table-cell):nth-child(5):not(th) {
  padding: 0 !important;
}

:deep(.ant-table-row) {
  cursor: pointer;
}
</style>
