<script lang="ts" setup>
import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  FormItem,
  InputNumber,
  notification,
  Row,
  TypographyText as TpText,
  TypographyTitle as TpTitle,
  Modal,
} from 'ant-design-vue'
import {
  IFarmerProduct,
  IReductionProductRequest,
  ISpecialPrice,
} from '@/types/interfaces'
import ProductProperty from '@/views/Farmer/reductions/reduction/makeBetDrawer/ProductProperty.vue'
import { ReductionProductAnswerAPI } from '@/api/ReductionProductAnswerAPI'
import {
  getUserIdTemporary,
  priceWithoutVat,
  roundNumber,
} from '@/helpers/scripts'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { SpecialPriceAPI } from '@/api/SpecialPriceAPI'
import { computed, onMounted, ref, watch } from 'vue'

interface IFormState {
  price_per_unit: string | number
  vat: string | number
  price_without_vat: string | number
}

interface IProps {
  productRequest: IReductionProductRequest
  open: boolean
  onClose: () => void
  reductionId: number
  participantId: number
  farmerHasBet: boolean
  customerId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['refetchReduction'])
const { getCoreCookie } = useJWTDecode()

const isBlockBtn = ref(false)
const formRef = ref()
const creatingBet = ref(false)
const farmerProductId = ref<null | number>(null)
const isOpenModal = ref(false)
const isProductDeleted = ref(false)
const priceWithVatValue = ref()

const farmerAnswer = computed(() =>
  props.productRequest?.reduction_product_answers.find(
    (answer) => answer.reduction_participant_id === props.participantId,
  ),
)

const isFarmer = computed(() => getCoreCookie()?.company_type === 'farmer')

const farmerId = +getUserIdTemporary()

const formState = ref<IFormState>({
  price_without_vat: farmerAnswer.value
    ? roundNumber(
        priceWithoutVat(
          Number(farmerAnswer.value.price_per_unit),
          farmerAnswer.value.vat,
        ),
      )
    : '',
  vat: farmerAnswer.value ? farmerAnswer.value.vat : '',
  price_per_unit: farmerAnswer.value
    ? roundNumber(Number(farmerAnswer.value.price_per_unit))
    : '',
})

const specialPrice = ref<ISpecialPrice | null>(null)

const getFullPrice = ({
  price_per_unit,
  vat,
  measurement_unit_id,
}: {
  price_per_unit: string
  vat: number
  measurement_unit_id: string
}) => {
  return `${roundNumber(Number(price_per_unit))} ₽/${measurement_unit_id}`
}

const showNotification = () => {
  if (!props.farmerHasBet) {
    notification.success({
      top: '80px',
      message: 'Первая ставка сделана',
      style: {
        'background-color': 'rgba(220, 243, 209, 1)',
      },
      description: 'Теперь вам доступен просмотр ставок от других компаний',
    })
  } else {
    notification.success({
      top: '80px',
      message: 'Ставка сделана',
      style: {
        'background-color': 'rgba(220, 243, 209, 1)',
      },
    })
  }
}

const createBet = async (price_per_unit: number, vat: number) => {
  if (await checkIsProductDeleted()) {
    isProductDeleted.value = true
    return
  }
  const goAfterAnswer = () => {
    updateSpecialPrice({
      price: String(formState.value.price_per_unit),
      vat_percent: Number(formState.value.vat),
    })

    emit('refetchReduction')

    showNotification()

    props.onClose()
  }

  creatingBet.value = true
  if (farmerAnswer.value) {
    ReductionProductAnswerAPI.update({
      id: farmerAnswer.value.id,
      price_per_unit: price_per_unit,
      vat: vat,
    })
      .then(() => {
        goAfterAnswer()
      })
      .finally(() => {
        creatingBet.value = false
      })
  } else {
    ReductionProductAnswerAPI.create({
      reduction_product_request_id: props.productRequest.id,
      price_per_unit: price_per_unit,
      vat: vat,
    })
      .then(() => {
        goAfterAnswer()
      })
      .finally(() => {
        creatingBet.value = false
      })
  }
}

const checkIsProductDeleted = async () => {
  return !(await FarmerProductAPI.getFilteredProducts(
    [
      ['product_id', 'eq', String(props.productRequest.product_id)],
      'AND',
      ['farmer_id', 'eq', String(farmerId)],
    ],
    1,
    1,
    [],
  ).then(({ items }) => items.length))
}

const handleCreateBet = async () => {
  if (formState.value.price_per_unit && formState.value.vat !== '') {
    const price_per_unit = Number(formState.value.price_per_unit)
    const vat = Number(formState.value.vat)
    formState.value.price_per_unit = price_per_unit
    formState.value.vat = vat
    const result = await formRef.value.validate()

    if (result) {
      const { price_per_unit, vat, price_with_vat } = result
      const best_bet_price =
        props.productRequest?.reduction_product_answers?.reduce(
          (accum: number | null, currentAnswer) => {
            const full_price = Number(currentAnswer.price_per_unit)
            if (
              currentAnswer.reduction_participant.farmer_id !== farmerId &&
              (!accum || accum > full_price)
            ) {
              return full_price
            }
            return accum
          },
          null,
        )

      if (
        best_bet_price &&
        best_bet_price < Number(formState.value.price_per_unit)
      ) {
        isOpenModal.value = true
      } else {
        await createBet(Number(price_per_unit), Number(vat))
      }
    }
  }
}

const updateSpecialPrice = (data: { price: string; vat_percent: number }) => {
  if (specialPrice.value) {
    SpecialPriceAPI.update({
      id: specialPrice.value.id,
      ...data,
    })
  } else {
    SpecialPriceAPI.create({
      farmer_product_id: farmerProductId.value,
      customer_id: props.customerId,
      expires_after: null,
      quantity: null,
      comment: null,
      ...data,
    })
  }
}

const setProductInitPrice = () => {
  SpecialPriceAPI.getForFarmer({
    filters: {
      farmer_id: farmerId,
      product_id: props.productRequest.product_id,
      product_request_id: props.productRequest.id,
      customer_id: props.customerId,
      amount: +props.productRequest.volume,
    },
  }).then((data) => {
    formState.value.price_per_unit = String(data.price)
    formState.value.vat = String(data.vat) // Тут строки, чтобы значение 0 не было falsy
  })
}

const bestBet = computed(() =>
  getFullPrice({
    vat: props.productRequest.best_bet?.vat ?? 0,
    price_per_unit:
      String(roundNumber(props.productRequest.best_bet?.price_per_unit || 0)) ||
      '',
    measurement_unit_id:
      props.productRequest.product?.measurement_unit_id || '',
  }),
)

const isDisabledCreateBet = computed(
  () =>
    Number(farmerAnswer.value?.price_per_unit) ===
      formState.value.price_per_unit &&
    farmerAnswer.value?.vat === formState.value.vat,
)

watch(
  () => [formState.value.price_per_unit, formState.value.vat],
  () => {
    if (formState.value.price_per_unit && formState.value.vat !== '') {
      formState.value.price_without_vat = roundNumber(
        priceWithoutVat(
          Number(formState.value.price_per_unit),
          Number(formState.value.vat),
        ),
      )
    } else {
      formState.value.price_per_unit = ''
    }
  },
)

watch(
  () => props.open,
  (newValue) => {
    if (newValue) {
      FarmerProductAPI.getFilteredProducts(
        [
          ['product_id', 'eq', String(props.productRequest.product_id)],
          'AND',
          ['farmer_id', 'eq', String(farmerId)],
        ],
        1,
        1,
        ['special_prices'],
      ).then(({ items }) => {
        const neededFarmerProduct: IFarmerProduct = items[0]

        farmerProductId.value = neededFarmerProduct.id
        const customerSpecialPrice = neededFarmerProduct?.special_prices?.find(
          (specialPrice) => +specialPrice.customer_id === props.customerId,
        )
        if (customerSpecialPrice) {
          specialPrice.value = customerSpecialPrice
        }
        if (!farmerAnswer.value) {
          setProductInitPrice()
        }
      })
    } else {
      formState.value = {
        price_without_vat: farmerAnswer.value
          ? roundNumber(
              priceWithoutVat(
                Number(farmerAnswer.value.price_per_unit),
                Number(farmerAnswer.value.vat),
              ),
            )
          : '',
        vat: farmerAnswer.value ? farmerAnswer.value.vat : '',
        price_per_unit: farmerAnswer.value
          ? roundNumber(Number(farmerAnswer.value.price_per_unit))
          : '',
      }
    }
  },
  {
    immediate: true,
  },
)

const closeAndRefetch = () => {
  isProductDeleted.value = false
  emit('refetchReduction')
  props.onClose()
}

// const moreThanStartPrice = (_rule: Rule, value: string) => {
//   if (
//     priceWithVatValue.value !== 0 &&
//     Number(value) >= Number(priceWithVatValue.value) &&
//     isFarmer?.value
//   ) {
//     isBlockBtn.value = true
//     return Promise.reject('Ставка должна быть меньше, чем предыдущая ставка')
//   } else {
//     isBlockBtn.value = false
//     return Promise.resolve()
//   }
// }

watch(
  () => formState.value.price_per_unit,
  () => {
    if (isFarmer.value) {
      const isValid = formRef.value?.validate('price_per_unit')
      isBlockBtn.value = !isValid
    }
  },
)

onMounted(() => {
  priceWithVatValue.value = roundNumber(
    Number(formState.value.price_per_unit) *
      (1 + Number(formState.value.vat) / 100),
  )
})
</script>

<template>
  <Drawer
    :open="open"
    :title="props.productRequest.product_name"
    :width="500"
    :footer-style="{ padding: '24px 32px' }"
    @close="props.onClose"
    detroyOnClose
  >
    <Flex class="body-container" :gap="40" vertical>
      <Row>
        <Col :span="24">
          <ProductProperty
            propertyName="Объём"
            :propertyValue="`${productRequest.volume} ${productRequest.product?.measurement_unit_id}`"
            :is-last="
              !productRequest.remaining_shelf_life &&
              !productRequest.is_sample_needed
            "
          />
          <ProductProperty
            v-if="productRequest.remaining_shelf_life"
            propertyName="Остаточный срок хранения"
            :propertyValue="`${productRequest.remaining_shelf_life}%`"
            :is-last="!productRequest.is_sample_needed"
          />
          <ProductProperty
            v-if="productRequest.is_sample_needed"
            propertyName="Пробный образец"
            :propertyValue="`
              ${
                productRequest.needed_samples_number
                  ? `${productRequest.needed_samples_number} ${productRequest.product?.measurement_unit_id}`
                  : 'Объём не указан'
              }`"
            :is-last="true"
          />
        </Col>
      </Row>
      <div v-if="props.productRequest?.best_bet" class="best-bet__wrapper">
        <Flex justify="space-between">
          <TpText>Лучшая ставка</TpText>
          <Flex align="flex-end" vertical>
            <TpText>{{ bestBet }}</TpText>
            <TpText type="secondary">
              {{ `НДС ${props.productRequest?.best_bet.vat}%` }}
            </TpText>
          </Flex>
        </Flex>
      </div>
      <Flex :gap="24" vertical>
        <Flex vertical>
          <TpTitle :level="5" :style="{ marginBottom: '4px' }">
            Новая ставка
          </TpTitle>
          <TpText type="secondary">
            Ставка сохранится в качестве спеццены для этого покупателя
          </TpText>
        </Flex>

        <Form
          layout="vertical"
          ref="formRef"
          :model="formState"
          :preserve="false"
        >
          <Row :gutter="20">
            <Col :span="14">
              <FormItem
                label="Цена без НДС"
                name="price_without_vat"
                :rules="[
                  {
                    required: true,
                    message: 'Обязательное поле',
                  },
                  {
                    max: 1000000,
                    message: productRequest?.product?.measurement_unit_id
                      ? `Максимум 1 000 000 ₽/${productRequest?.product?.measurement_unit_id}`
                      : 'Максимум 1 000 000 ₽',
                    trigger: 'change',
                    type: 'number',
                  },
                  {
                    min: 0.01,
                    message: productRequest?.product?.measurement_unit_id
                      ? `Минимум 0,01 ₽/${productRequest?.product?.measurement_unit_id}`
                      : 'Минимум 0,01 ₽',
                    trigger: 'change',
                    type: 'number',
                  },
                ]"
              >
                <InputNumber
                  size="large"
                  name="price_without_vat"
                  v-model:value="formState.price_without_vat"
                  :step="0.01"
                  decimalSeparator=","
                  :controls="false"
                  disabled
                >
                  <template #addonAfter>
                    {{
                      productRequest?.product?.measurement_unit_id
                        ? `₽/${productRequest?.product?.measurement_unit_id}`
                        : '₽'
                    }}
                  </template>
                </InputNumber>
              </FormItem>
            </Col>
            <Col :span="10">
              <FormItem
                label="НДС"
                name="vat"
                :rules="[
                  {
                    required: true,
                    message: 'Обязательное поле',
                  },
                  {
                    max: 100,
                    message: `Максимум 100%`,
                    trigger: 'change',
                    type: 'number',
                  },
                  {
                    min: 0,
                    message: `Минимум 0%`,
                    trigger: 'change',
                    type: 'number',
                  },
                ]"
              >
                <InputNumber
                  v-model:value="formState.vat"
                  name="vat"
                  size="large"
                  :controls="false"
                  addon-after="%"
                />
              </FormItem>
            </Col>
            <Col :span="24">
              <FormItem
                label="Цена с НДС"
                name="price_per_unit"
                :rules="[
                  {
                    required: true,
                    message: 'Обязательное поле',
                  },
                  // {
                  //   validator: moreThanStartPrice,
                  // },
                ]"
              >
                <InputNumber
                  v-model:value="formState.price_per_unit"
                  size="large"
                  :controls="false"
                  :step="0.01"
                  decimalSeparator=","
                  :style="{ width: '100%' }"
                >
                  <template #addonAfter>
                    {{
                      productRequest?.product?.measurement_unit_id
                        ? `₽/${productRequest?.product?.measurement_unit_id}`
                        : '₽'
                    }}
                  </template>
                </InputNumber>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Flex>
    </Flex>
    <template #footer>
      <Flex :gap="4" justify="flex-end">
        <Button size="large" style="margin-right: 8px" @click="onClose">
          Закрыть
        </Button>
        <Button
          :disabled="isDisabledCreateBet"
          type="primary"
          size="large"
          @click="handleCreateBet"
          :loading="creatingBet"
        >
          Сделать ставку
        </Button>
      </Flex>
    </template>
  </Drawer>
  <Modal
    v-model:open="isOpenModal"
    title="Ваша ставка не будет лучшей"
    okText="Продолжить"
    cancelText="Изменить ставку"
    :onOk="
      () => createBet(Number(formState.price_per_unit), Number(formState.vat))
    "
    :okButtonProps="{
      loading: creatingBet,
    }"
    centered
  >
    <div class="modal_body">
      <TpText>Вы можете продолжить или изменить ставку</TpText>
    </div>
  </Modal>

  <Modal
    v-model:open="isProductDeleted"
    title="Невозможно сделать ставку, товар был удален"
    okText="Закрыть"
    closable
    width="460px"
    :cancelButtonProps="{
      hidden: true,
    }"
    @cancel="closeAndRefetch"
    :okButtonProps="{
      loading: creatingBet,
    }"
    centered
  >
    <div class="modal_body">
      <TpText>{{ props.productRequest.product_name }}</TpText>
    </div>
    <template #footer>
      <Button type="primary" @click="closeAndRefetch"> Закрыть </Button>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.body-container {
  padding: 0 8px;

  .best-bet__wrapper {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 16px 20px;
  }
}

.modal_body {
  margin-bottom: 24px;
}

:deep(.ant-input-number) {
  width: 100%;
}
:deep(.ant-form-item) {
  margin-bottom: 20px;
}
</style>
