<script setup lang="ts">
import { UnwrapRef } from 'vue'
import {
  fontSize14,
  primaryButton,
  withoutBGButton,
} from '@/assets/EgalStyles/EButton'
import { Tooltip, Button } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'

interface Props {
  currentStep: number
  thirdFormError: UnwrapRef<boolean>
}
const props = defineProps<Props>()
const emit = defineEmits(['nextStep', 'prevStep', 'showRejectModal'])
const backText = ['Вернуться к требованиям', 'Вернуться к товарам', 'Назад']
</script>

<template>
  <div class="create-request-footer">
    <Button
      danger
      @click="emit('showRejectModal')"
      :class="'custom-danger'"
      type="text"
    >
      <template #icon><CloseOutlined /></template>
      Отменить запрос
    </Button>
    <div class="main-block">
      <EButton
        v-if="props.currentStep > 0"
        :style-config="{
          ...withoutBGButton,
          fontSize14,
          padding: '11px 16px',
        }"
        @click="emit('prevStep')"
      >
        {{ backText[props.currentStep - 1] }}
      </EButton>
      <Tooltip
        :color="'#F16063'"
        placement="topRight"
        :open="props.thirdFormError && props.currentStep === 2"
      >
        <template #title>
          <span>Добавьте минимум 1 поставщика</span>
        </template>
        <EButton
          :style-config="{
            ...primaryButton,
            fontSize14,
            padding: '11px 16px',
            marginLeft: '16px',
          }"
          @click="emit('nextStep')"
        >
          {{ props.currentStep === 2 ? 'Отправить запрос' : 'Создать запрос' }}
        </EButton>
      </Tooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.create-request-footer {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  background: white;
  justify-content: space-between;
  border-top: 1px solid $gray-300;
  z-index: 2;
  .custom-danger {
    font-weight: 500;
    background-color: transparent;
  }
}
</style>
