<script lang="ts">
import { defineComponent } from 'vue'

// TODO: Дописать

export default defineComponent({
  name: 'CustomerRequestCommonInfoDeliveryAddress',
  props: {
    address: {
      type: String,
      default: '',
      required: true,
    },
  },
})
</script>

<template>
  <div class="customer-request-common-info-delivery-address">
    <div class="customer-request-common-info-delivery-address_title">
      Адрес доставки
    </div>
    <div class="customer-request-common-info-delivery-address_address">
      {{ address }}
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info-delivery-address {
  &_title {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 600;
    font-family: Inter, sans-serif;
  }
  &_address {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
}
</style>
