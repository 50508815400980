<script lang="ts" setup>
import {
  IReduction,
  IReductionParticipant,
  IReductionProductAnswer,
  IReductionProductRequest,
} from '@/types/interfaces'
import { computed, ref } from 'vue'
import { Button, Flex, Table, TypographyText as TpText } from 'ant-design-vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import {
  getUserIdTemporary,
  priceWithoutVat,
  roundNumber,
} from '@/helpers/scripts'
import MyCompanyTag from '@/views/Farmer/reductions/reduction/MyCompanyTag.vue'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'

interface IAdvancedParticipant extends IReductionParticipant {
  bets_count: number
  bets_amount: number
}

interface IAdvancedProductAnswer extends IReductionProductAnswer {
  measurement_unit_id: string
  is_sample_needed: boolean
  needed_samples_number: string | number | null
  product_name: string
  volume: string
  remaining_shelf_life: number
}

interface IProps {
  reduction: IReduction
  myParticipantId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])

const expandedRowKeys = ref<string[]>([])

const farmerId = +getUserIdTemporary()

const getAdditionalFields = (participantId: number) => {
  return props.reduction?.reduction_product_requests?.reduce(
    (accum, currentRequest) => {
      currentRequest?.reduction_product_answers.forEach((currentAnswer) => {
        if (currentAnswer.reduction_participant_id === participantId) {
          accum.bets_count += 1
          accum.bets_amount += +(
            Number(currentAnswer.price_per_unit) * Number(currentRequest.volume)
          ).toFixed(2)
        }
      })

      return accum
    },
    { bets_count: 0, bets_amount: 0 },
  )
}

const participantHasBets = (participantId: number) => {
  return props.reduction?.reduction_product_requests?.some((currentRequest) => {
    return currentRequest.reduction_product_answers.some(
      (currentAnswer) =>
        currentAnswer.reduction_participant_id === participantId,
    )
  })
}

const getParticipantAnswers = (
  participantId: number,
): IAdvancedProductAnswer[] => {
  const answers: IAdvancedProductAnswer[] = []

  const getAdvancedFields = ({
    product_name,
    volume,
    remaining_shelf_life,
    is_sample_needed,
    needed_samples_number,
  }: IReductionProductRequest) => ({
    product_name,
    volume,
    remaining_shelf_life,
    is_sample_needed,
    needed_samples_number,
  })
  props.reduction?.reduction_product_requests?.forEach((currentRequest) => {
    currentRequest?.reduction_product_answers.forEach((currentAnswer) => {
      if (currentAnswer.reduction_participant_id === participantId) {
        answers.push({
          ...currentAnswer,
          ...getAdvancedFields(currentRequest),
          measurement_unit_id:
            currentRequest?.product?.measurement_unit_id ?? '',
        })
      }
    })
  })

  return answers
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const advancedParticipants: IAdvancedParticipant[] = computed(() => {
  return (
    props.reduction?.reduction_participants
      ?.map((participant) => ({
        ...participant,
        ...getAdditionalFields(participant.id),
      }))
      ?.filter((participant) => participantHasBets(participant.id)) ?? []
  )
})

const columns = [
  {
    title: 'Рейтинг',
    key: 'rate',
    width: 100,
  },
  {
    title: 'Поставщик',
    key: 'farmer',
  },
  {
    title: 'Количество ставок на позиции',
    key: 'bets_count',
    width: 200,
  },
  {
    title: 'Сумма закупки',
    key: 'bets_amount',
    width: 348,
  },
]

const nestedColumns = [
  {
    title: 'Товары',
    key: 'products',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: 132,
  },
  {
    title: 'НДС',
    key: 'vat',
    width: 80,
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: 200,
  },
  {
    title: 'Сумма',
    key: 'amount',
    width: 348,
  },
]
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :expandedRowKeys="expandedRowKeys"
      :data-source="advancedParticipants"
      :row-key="(record, index) => String(index)"
      :pagination="false"
      :showExpandColumn="false"
      bordered
    >
      <template #expandedRowRender="{ record }">
        <Table
          :columns="nestedColumns"
          :data-source="getParticipantAnswers(record.id)"
          :pagination="false"
          bordered
          class="nested-table"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'products'">
              <Flex :gap="8">
                <ProductWithProperties
                  :volume="record.volume"
                  :remaining_shelf_life="record.remaining_shelf_life"
                  :measurement_unit_id="record.measurement_unit_id"
                  :name="record.product_name"
                  :is_sample_needed="record.is_sample_needed"
                  :needed_samples_number="record.needed_samples_number"
                />
              </Flex>
            </template>
            <template v-if="column.key === 'price_without_vat'">
              <CellDifData
                :new-data="
                  roundNumber(
                    priceWithoutVat(record.price_per_unit, record.vat),
                  )
                "
                :prev-data="
                  roundNumber(
                    priceWithoutVat(
                      record.price_per_unit_first,
                      record.vat_first,
                    ),
                  )
                "
                :additional-info="
                  record.measurement_unit_id
                    ? `₽/${record.measurement_unit_id}`
                    : '₽'
                "
              />
            </template>
            <template v-if="column.key === 'vat'">
              <CellDifData
                :new-data="record.vat"
                :prev-data="record.vat_first"
                additional-info="%"
              />
            </template>
            <template v-if="column.key === 'price_with_vat'">
              <CellDifData
                :new-data="roundNumber(record.price_per_unit)"
                :prev-data="roundNumber(record.price_per_unit_first)"
                :additional-info="
                  record.measurement_unit_id
                    ? `₽/${record.measurement_unit_id}`
                    : '₽'
                "
              />
            </template>
            <template v-if="column.key === 'amount'">
              <Flex justify="space-between">
                <CellDifData
                  :new-data="roundNumber(record.price_per_unit * record.volume)"
                  :prev-data="
                    roundNumber(record.price_per_unit_first * record.volume)
                  "
                  additional-info="₽"
                />
                <Button
                  v-if="
                    record.reduction_participant_id === myParticipantId &&
                    reduction.status !== 'closed'
                  "
                  type="primary"
                  @click="
                    emit('setRequestId', record.reduction_product_request_id)
                  "
                  >Сделать ставку</Button
                >
              </Flex>
            </template>
          </template>
        </Table>
      </template>
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'rate'">
          <Flex justify="center" align="center">{{ index + 1 }}</Flex>
        </template>
        <template v-if="column.key === 'farmer'">
          <span v-if="record.farmer_id !== farmerId">{{
            `Поставщик ${record.reduction_name?.toUpperCase()}`
          }}</span>
          <Flex v-else :gap="8">
            <span>{{ record.farmer.name }}</span>
            <MyCompanyTag />
          </Flex>
        </template>
        <template v-if="column.key === 'bets_count'">
          {{ record.bets_count }}
        </template>
        <template v-if="column.key === 'bets_amount'">
          <Flex justify="space-between" align="center">
            <TpText strong>{{ `${roundNumber(record.bets_amount)} ₽` }}</TpText>
            <Button type="link" :onClick="() => changeExpandedRow(index)">
              <template #icon>
                <DownOutlined v-if="!expandedRowKeys.includes(String(index))" />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </Flex>
        </template>
      </template>
    </Table>
  </div>
</template>

<style lang="scss" scoped>
.table-wrapper {
  padding: 24px;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}

.nested-table {
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}
</style>
