<script setup lang="ts">
import { IFarmer } from '@/types/interfaces'
import { Space } from 'ant-design-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import SignedContractImg from '../../assets/img/signed-contract.svg'

interface Props {
  farmer: IFarmer
}

const props = defineProps<Props>()
</script>

<template>
  <div class="small-farmer-card">
    <div class="card-body">
      <Space :size="8" :align="'start'">
        <img
          v-if="props.farmer.is_partner"
          class="signed-contract"
          :src="SignedContractImg"
          alt="signed_contract"
          loading="lazy"
        />
        <span class="farmer-name">
          {{
            `${props.farmer.legal_form ? props.farmer.legal_form : ''} ${props.farmer.name}`
          }}
        </span>
      </Space>
    </div>
    <span class="farmer-address">{{ props.farmer.legal_address }}</span>
    <SimpleRate :company_rating="props.farmer.company_rating" />
    <slot name="footer" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.small-farmer-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .farmer-name {
    @include p4();
    white-space: normal;
    word-break: normal;
  }
  .farmer-address {
    @include p5();
    color: $gray-600;
  }
}
</style>
