<script setup lang="ts">
import { IAuction } from '@/types/interfaces'
import { computed } from 'vue'
import {
  Col,
  Empty,
  Row,
  Space,
  TypographyText as TgText,
  TypographyTitle as TgTitle,
} from 'ant-design-vue'
import { declensionDays } from '@/helpers/scripts'

interface IProps {
  auctionData: IAuction | null
}

const props = defineProps<IProps>()
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
const informationList = computed(() => {
  const info: any[][] = []
  if (!props.auctionData) {
    return info
  }

  if (
    props.auctionData.is_self_delivery ||
    props.auctionData.is_supplier_delivery
  ) {
    const result = []
    props.auctionData.is_self_delivery && result.push('Самовывоз')
    props.auctionData.is_supplier_delivery &&
      result.push('Доставка от поставщика')
    info.push(['Способ получения', result])
  }

  if (
    props.auctionData.is_pay_cash ||
    props.auctionData.is_pay_non_cash ||
    props.auctionData.is_payment_delayed
  ) {
    const result = []
    props.auctionData.is_pay_cash && result.push('Наличные')
    props.auctionData.is_pay_non_cash && result.push('Безналичными')
    props.auctionData.is_payment_delayed &&
      result.push(
        `Отсрочка по оплате ${props?.auctionData?.payment_delay_days ? `${props.auctionData?.payment_delay_days} ${declensionDays(props?.auctionData?.payment_delay_days)}` : ''}`,
      )
    info.push(['Условия оплаты', result])
  }

  props.auctionData.is_delivery_excluded &&
    info.push(['Оплата доставки', ['Оплачивается отдельно']])
  props.auctionData.is_delivery_included &&
    info.push(['Оплата доставки', ['Включена в стоимость товара']])

  props.auctionData.self_delivery_address &&
    info.push(['Адрес отгрузки', [props.auctionData.self_delivery_address]])

  props.auctionData.comment?.length &&
    info.push(['Комментарии', [props.auctionData.comment]])

  return info
})

const isAuctionEmpty = computed(() => {
  return (
    !informationList.value.length &&
    !props.auctionData?.contact_name &&
    !props.auctionData?.contact_phone &&
    !props.auctionData?.contact_post
  )
})
</script>

<template>
  <Row :gutter="[24, 14]" :style="{ alignContent: 'flex-start' }">
    <template v-if="!isAuctionEmpty">
      <Col :span="24">
        <TgTitle :level="4">Общая информация</TgTitle>
      </Col>
      <Col :span="24">
        <Row :gutter="[24, 24]">
          <Col
            :span="12"
            v-if="
              props.auctionData?.contact_name ||
              props.auctionData?.contact_phone ||
              props.auctionData?.contact_post
            "
          >
          </Col>
          <Col
            :span="12"
            v-for="infoItem in informationList"
            :key="infoItem[0]"
          >
            <Space :size="8" direction="vertical">
              <TgText strong>{{ infoItem[0] }}</TgText>
              <TgText v-for="info in infoItem[1]" :key="info">{{
                info
              }}</TgText>
            </Space>
          </Col>
        </Row>
      </Col>
    </template>
    <Col :span="24" v-else>
      <div>
        <Empty
          :image="simpleImage"
          class="empty-view"
          :imageStyle="{
            width: '184px',
            height: '117px',
            margin: '0 auto 24px auto',
          }"
        >
          <template #description>
            <Space direction="vertical" :size="0">
              <TgTitle :level="4"
                >Данные о торге на продажу отсутствуют</TgTitle
              >
              <span class="empty-description">
                Вы не указывали дополнительных требований при создании торга на
                продажу
              </span>
            </Space>
          </template>
        </Empty>
      </div>
    </Col>
  </Row>
</template>

<style scoped lang="scss">
.empty-view {
  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
  :deep(.ant-empty-image svg) {
    width: 184px;
    height: 117px;
  }
}
</style>
