<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import SuggestionTip from '@/components/RegisterSteps/SuggestionTip/SuggestionTip.vue'
import { BankStatus, SuggestionTypes } from '@/types/enums'
import { IAccountUser, ISuggestBank } from '@/types/interfaces'
import { DadataAPI } from '@/api/Dadata'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import { isNull } from '@/helpers/scripts'

interface IProps {
  userInfo: { type: IAccountUser, default: null, required: false, bic_status: string }
}

const props = defineProps<IProps>()

const emit = defineEmits(['setData'])
const isVerifying = ref(!!props.userInfo.bank)
const activeBank = ref<ISuggestBank>(
  isVerifying.value
    ? { ...props.userInfo, status: props.userInfo.bic_status }
    : null,
)
const searchValue = ref(isVerifying.value ? `${activeBank.value.bank}` : '')
const isShow = ref(false)
const changeInput = async (el: any) => {
  searchValue.value = el.target.value
  if (el.target.value !== '') {
    await DadataAPI.getSuggests(SuggestionTypes.Bank, el.target.value).then(
      (resp) => {
        tipItems.value = resp
      },
    )
    isShow.value = true
  } else {
    isShow.value = false
  }
}

const debInput = useDebounceFn(changeInput, 1000)
const tipItems = ref<ISuggestBank[]>([])
const activeStep = ref(isVerifying.value ? 1 : 0)

const selectTip = (tip: ISuggestBank) => {
  activeBank.value = tip
  searchValue.value = `${tip.bank}`
  isShow.value = false
}
const openTips = () => {
  if (tipItems.value.length > 0)
    setTimeout(() => {
      isShow.value = true
    }, 0)
}
const closeTip = () => {
  isShow.value = false
}

const sendData = () => {
  emit('setData', { bic: activeBank.value.bic })
}

watch(tipItems.value, () => {
  if (tipItems.value.length > 0) {
    isShow.value = true
  }
})
</script>

<template>
  <div v-if="activeStep === 1" class="info-step__card">
    <div v-if="isVerifying" class="first-step__card-header">
      Подтвердите свой банк
    </div>
    <div v-else class="first-step__card-header">Найдите свой банк</div>
    <div class="first-step__card-body">
      <div class="suggest-input">
        <input
          v-model="searchValue"
          class="input"
          placeholder="Введите название банка, БИК или ИНН"
          type="text"
          @input="debInput"
          @click="openTips"
        />
        <SuggestionTip
          :isShow="isShow"
          @closeTip="closeTip"
          @selectTip="selectTip"
          :items="tipItems"
          :type="SuggestionTypes.Bank"
        />
      </div>
      <div v-if="activeBank" class="suggest-body">
        <div class="suggest-body__tooltip">
          <InfoIcon fill="#3385FF" type="outlined" />
          Проверьте и подтвердите данные банка, если это не ваш банк — выберите
          другой
        </div>
        <div class="suggest-body__info">
          <div class="info-column">
            <span class="info-column__title">Наименование банка</span>
            {{ isNull(activeBank.bank) }}
          </div>
          <div class="info-row">
            <div class="info-column">
              <span class="info-column__title">ИНН</span>
              {{ isNull(activeBank.bank_tin) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">Кор. счёт</span>
              {{ isNull(activeBank.correspondent_account) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">БИК</span>
              {{ isNull(activeBank.bic) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">КПП</span>
              {{ isNull(activeBank.bank_kpp) }}
            </div>
            <div
              v-if="BankStatus[activeBank.status] !== BankStatus.ACTIVE"
              class="info-column org-status"
            >
              <span class="info-column__title"> Статус банка </span>
              <p>
                <DangerIcon />
                {{ BankStatus[activeBank.status] }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="suggest-footer">
        <EButton
          :style-config="{
            ...primaryButton,
            disabled: {
              backgroundColor: '#E2E8F0',
              borderColor: 'transparent',
              color: '#A0AEC0',
            },
            fontSize16,
          }"
          class="suggest-footer_btn"
          :data="{
            disabled: !activeBank,
          }"
          @click="sendData"
        >
          Подтвердить данные
        </EButton>
      </div>
    </div>
  </div>

  <div v-else-if="activeStep === 0" class="info-step__card">
    <div class="info-step__card-header">
      По указанному БИК {{ userInfo.bic }} не найден банк
    </div>

    <div class="info-step__card-body">
      <p class="info-step-row">
        Пожалуйста, укажите другие данные или обратитесь в тех. поддержку:
        <span class="info-step-item">manager@easy-retail.org</span> или
        <span class="info-step-item">+7 913 871-12-39</span>
      </p>
    </div>

    <div class="info-step__card-footer">
      <EButton
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
        }"
        @click="activeStep++"
      >
        Найти банк
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.first-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;

  &-header {
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .suggest {
      &-input {
        position: relative;

        .input {
          box-sizing: border-box;
          padding: 14px 20px;
          display: block;
          width: 100%;
          align-items: center;
          border: 1px solid $gray-300;
          border-radius: 8px;
          flex-grow: 1;
          position: relative;
        }
      }

      &-body {
        &__tooltip {
          padding: 10px;
          border-radius: 8px;
          background-color: $accent-opacity-1;
          color: $hover-accent;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          column-gap: 5px;
          margin-bottom: 40px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
        }
      }

      &-footer {
        margin-top: 40px;
      }
    }
  }
}

.info {
  &-row {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    column-gap: 32px;
    row-gap: 20px;
  }

  &-column {
    &.org-status {
      color: $default-danger;

      p {
        display: flex;
        align-items: center;
        column-gap: 9px;
      }
    }

    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    row-gap: 3px;
    color: $gray-800;

    &__title {
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
    }
  }
}

.info-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;

  &-header {
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .info-step-row {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }

    .info-step-item {
      color: $default-accent;
    }
  }

  &-footer {
    margin-top: 40px;
  }
}
</style>
