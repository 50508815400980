<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { Button, Modal, Rate, FormItem, Textarea, Form } from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form'
import { NewGeneralOrderStatus, Roles } from '@/types/enums'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import DocumentMiniListItem from '@/components/Document/DocumentMiniListItem.vue'
import { UploadDocument } from '@/types/types'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {useCookies} from "vue3-cookies";

interface Props {
  status: string
  orderCompleted: boolean
  orderId: number
  open: boolean
  isOrderStatusChanging: boolean
}

interface IFormState {
  rate: number
  comment: string
}

interface IUploadDocument {
  file_name: string
  file_body: string
  errors: string[]
  extension: string
}

const validDocumentTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
]

const validateComment = async (_rule: Rule, value?: string) => {
  if (!value) return Promise.resolve()
  if (value.length > 1000) {
    return Promise.reject('Максимум 1000 символов')
  }
}

const validateRate = async (_rule: Rule, value?: string) => {
  if (rate.value == 0) {
    return Promise.reject('Укажите оценку')
  } else return Promise.resolve()
}

const isValidFileTypes = (file: File | UploadDocument) => {
  return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
}

const isValidFileSize = (size: number) => {
  return size > maxSingleSize ? 'Превышен размер файла' : ''
}

const uploadDocumentsNotEmpty = computed(
  () => uploadedDocuments.value.length > 0,
)

const deleteDocument = (index: number) => {
  uploadedDocuments.value.splice(index, 1)
}

const uploadFile = (file: File, base64: string) => {
  const fileError = [
    isValidFileTypes(file),
    isValidFileSize(+file.size / 1048576),
  ]
  uploadedDocuments.value.push({
    file_name: file.name,
    file_body: base64,
    errors: fileError,
    extension: file.type.split('/')[1],
  })
}

const { cookies } = useCookies()
const emit = defineEmits(['close', 'sendData', 'changeOrderStatus'])
const props = defineProps<Props>()
const openModal = ref()
const title = ref()
const description = ref(
  'При необходимости, вы можете оставить комментарий и прикрепить отгрузочные документы',
)
const sendText = ref('Отправить оценку')
const rate = ref<number>(1)
const showDoc = ref(false)
const maxSingleSize = 10
const uploadedDocuments = ref<IUploadDocument[]>([])
const showComment = ref(false)
const formState = ref<IFormState>({})
const rules: Record<string, Rule[]> = {
  rate: [{ required: true, validator: validateRate, trigger: 'change' }],
  comment: [{ required: false, validator: validateComment, trigger: 'change' }],
}

const close = () => {
  emit('close')
}
const sendData = () => {
  let comment = formState.value.comment
  if (comment?.length < 1000 || !comment) {
    if (comment == '') {
      // Будет отсылать 403 при пустой строке
      comment = null
    }
    emit(
      'sendData',
      {
        orderId: props.orderId,
        status: props.status,
        completed: props.orderCompleted,
        mark: rate.value,
        file_list: uploadedDocuments.value,
        comment: formState.value?.comment,
      },
      closeAndClear,
    )
  }
}
const clear = () => {
  formState.value.comment = ''
  uploadedDocuments.value = []
}

const closeAndClear = () => {
  close()
  clear()
}

const checkStatus = () => {
  switch (props.status) {
    case NewGeneralOrderStatus.OrderCompleted:
      title.value = 'Заказ полностью выполнен'
      description.value = ''
      rate.value = 5
      showDoc.value = false
      showComment.value = false
      break
    case NewGeneralOrderStatus.PartialOrderCompleted:
      title.value = 'Заказ выполнен частично'
      description.value =
      `
        При необходимости, вы можете ${cookies.get('role') === Roles.Customer ? 'оставить комментарий и' : ''} прикрепить
        ${cookies.get('role') === Roles.Customer ? 'отгрузочные документы' : 'скан-копии исправленных отгрузочных документов'}
      `
      rate.value = 4
      showDoc.value = true
      showComment.value = cookies.get('role') === Roles.Customer
      break
    case NewGeneralOrderStatus.OrderPicking:
    case NewGeneralOrderStatus.New:
    case NewGeneralOrderStatus.SupplierCancelledOrder:
    case NewGeneralOrderStatus.BuyerCancelledOrder:
      title.value = `${props.orderCompleted ? 'Оцените заказ' : 'Отказаться от заказа?'}`
      description.value = `Мы уведомим ${cookies.get('role') === Roles.Customer ? 'поставщика' : 'покупателя'} об отмене заказа`
      if (props.orderCompleted)
        description.value = ''
      rate.value = 2
      showDoc.value = false
      showComment.value = false
      sendText.value = `${props.orderCompleted ? 'Отправить оценку' : 'Отказаться'}`
      break
    case NewGeneralOrderStatus.OrderNotCompleted:
      title.value = 'Заказ не выполнен'
      description.value = `${cookies.get('role') === Roles.Customer ? 'При необходимости, вы можете оставить комментарий' : ''}`
      rate.value = 1
      showDoc.value = false
      showComment.value = cookies.get('role') === Roles.Customer
      break
  }
}

const checkOpen = () => {
  openModal.value = props.open
}

watch(props, () => {
  checkStatus()
  checkOpen()
})
</script>

<template>
  <Modal
    width="440px"
    centered
    closable
    v-model:open="openModal"
    :afterClose="close"
    :title="title"
    :headerStyle="{
      padding: '24px 64px 24px 32px',
    }"
  >
    <p class="grey">
      {{ description }}
    </p>
    <Form :rules="rules" :model="formState" name="formState">
      <FormItem label="Ваша оценка" name="rate">
        <Rate
          class="rate"
          :count="5"
          v-model:value="rate"
          @handleChange="handleChange"
        />
        <span class="grey">
          Оценка была предустановлена, вы можете изменить её
        </span>
      </FormItem>

      <FormItem
        v-if="showDoc"
        label="Отгрузочные документы"
        help="Формат файлов: .pdf, .png, jpg, .jpeg до 10 мб"
      >
        <div>
          <FileUploader
            @load-file="uploadFile"
            v-if="!uploadDocumentsNotEmpty"
          />
          <template v-if="uploadDocumentsNotEmpty">
            <div class="documents-list-block">
              <div class="documents-list">
                <DocumentMiniListItem
                  v-for="(document, index) in uploadedDocuments"
                  :key="document.file_name"
                  :id="index"
                  :name="document.file_name"
                  :body="document.file_body"
                  :errors="document.errors"
                  @deleteDocument="deleteDocument"
                  :isShow="false"
                  documentModel="order"
                />
              </div>
              <FileUploader
                :type="'custom'"
                @load-file="uploadFile"
                v-if="uploadedDocuments.length < 20"
              >
                <a href="#" class="page-card__add-document">
                  <span class="plus">+</span>
                  Добавить документы
                </a>
              </FileUploader>
            </div>
          </template>
        </div>
      </FormItem>

      <FormItem
        v-if="showComment"
        label="Комментарий"
        name="comment"
        help="Максимум 1000 символов"
      >
        <Textarea
          class="comment"
          placeholder="Например: поставщик привёз не те товары"
          v-model:value="formState.comment"
        />
      </FormItem>
    </Form>
    <template #footer>
      <Button @click="close" :loading="isOrderStatusChanging"> Закрыть </Button>
      <Button
        @click="sendData"
        type="primary"
        :disabled="uploadedDocuments.some((doc) => doc.errors.join('') !== '') || !rate"
        :loading="isOrderStatusChanging"
      >
        {{ sendText }}
      </Button>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
:deep(.ant-upload-list-item-name) {
  color: #1677ff;
}

:deep(.ant-upload-text-icon) {
  color: #00000073;
}

:deep(.ant-row) {
  display: block;
}

:deep(.ant-rate-star) {
  padding: 16px 19px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.documents-list-block {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .documents-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.rate {
  :deep(.anticon svg) {
    height: 32px;
    width: 32px;
  }
}

.grey {
  color: #00000073;
}

.comment {
  height: 86px;
}
</style>
