<script setup lang="ts">
import {
  Table,
  ConfigProvider,
  InputNumber,
  Checkbox,
  Button,
  Form,
  FormInstance,
  FormItem,
} from 'ant-design-vue'
import { ref } from 'vue'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { CloseOutlined } from '@ant-design/icons-vue'
import { Rule } from 'ant-design-vue/es/form'
import EmptyView from '@/views/EmptyView.vue'
import { IProductTableItem } from '@/views/Customer/CustomerCreateRequestView.vue'

interface Props {
  formState: { domains: IProductTableItem[] }
}
const props = defineProps<Props>()

const emit = defineEmits(['editSecondForm', 'removeProduct'])
const columns = [
  {
    title: 'Товар',
    dataIndex: 'product',
  },
  {
    title: 'Объём',
    dataIndex: 'amount',
    width: '25%',
    isRequired: true,
  },
  {
    title: 'Остаточный срок хранения',
    dataIndex: 'shelfLife',
    width: '15%',
  },
  {
    title: 'Пробный образец',
    dataIndex: 'need_test_sample',
    colSpan: 2,
    width: 48,
  },
  {
    title: 'amount',
    colSpan: 0,
    dataIndex: 'test_sample_amount',
    width: '16%',
  },
  {
    title: '',
    dataIndex: 'delete-product',
    width: 64,
  },
]
const height100 = { height: '100%' }
const secondFormRef = ref<FormInstance>()
defineExpose({
  secondFormRef,
})

const onDelete = (key: string) => {
  emit('removeProduct', key)
}

const validAmount = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
  isRequired: boolean
) => {
  if (isRequired && value === '') {
    return Promise.reject('Укажите значение')
  } else if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (Number(value) < 0) {
      return Promise.reject('Значение не может быть отрицательным')
    } else if (Number(value) < 0.01) {
      return Promise.reject('Не меньше 0,01')
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (Number(value) < 0) {
      return Promise.reject('Значение не может быть отрицательным')
    } else if (Number(value) < 1) {
      return Promise.reject('Не меньше 1')
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else {
      return Promise.resolve()
    }
  }
}

const validShelfLife = async (_rule: Rule, value: string) => {
  if (value === '') {
    return Promise.resolve()
  } else if (Number(value) < 1 || Number(value) > 100) {
    return Promise.reject('Не меньше 1% и не больше 100%')
  } else {
    return Promise.resolve()
  }
}

const validTestSampleAmount = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
) => {
  if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '') {
      return Promise.resolve()
    } else if (Number(value) < 0.01 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 0,01 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '') {
      return Promise.resolve()
    } else if (Number(value) < 1 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 1 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  }
}
</script>

<template>
  <ConfigProvider :locale="locale">
    <div class="excel-table" v-if="props.formState.domains.length > 0">
      <Form ref="secondFormRef" :model="props.formState">
        <Table
          bordered
          :data-source="props.formState.domains"
          :columns="columns"
          :row-class-name="'editable-row'"
          :pagination="false"
        >
          <template #headerCell="{ column }">
            <template v-if="column.title">
              <span>
                {{ column.title }}
                <span v-if="column.isRequired" style="color: red">*</span>
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex === 'product'">
              {{ text }}
            </template>
            <template v-else-if="column.dataIndex === 'amount'">
              <FormItem
                :name="['domains', index, 'amount']"
                :style="height100"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validAmount(_rule, value, record.measurement_unit_id, column.isRequired),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].amount"
                  :placeholder="record.measurement_unit_id"
                  :controls="false"
                  @change="emit('editSecondForm', 'amount', $event, index)"
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'shelfLife'">
              <FormItem
                :name="['domains', index, 'shelfLife']"
                :style="height100"
                :rules="[
                  {
                    validator: validShelfLife,
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].shelfLife"
                  placeholder="%"
                  :controls="false"
                  @change="emit('editSecondForm', 'shelfLife', $event, index)"
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'need_test_sample'">
              <div class="text-align-center">
                <FormItem
                  :name="['domains', index, 'need_test_sample']"
                  :style="height100"
                  :rules="[
                    {
                      required: column.isRequired,
                      message: 'Укажите значение',
                      trigger: 'change',
                    },
                  ]"
                >
                  <Checkbox
                    :checked="props.formState.domains[index].need_test_sample"
                    @change="
                      emit(
                        'editSecondForm',
                        'need_test_sample',
                        $event.target.checked,
                        index,
                      )
                    "
                  />
                </FormItem>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'test_sample_amount'">
              <FormItem
                :name="['domains', index, 'test_sample_amount']"
                :style="height100"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validTestSampleAmount(
                        _rule,
                        value,
                        record.measurement_unit_id,
                      ),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].test_sample_amount"
                  :placeholder="record.measurement_unit_id"
                  :controls="false"
                  :disabled="!props.formState.domains[index].need_test_sample"
                  @change="
                    emit('editSecondForm', 'test_sample_amount', $event, index)
                  "
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'delete-product'">
              <Button type="text" class="delete-product-btn">
                <template #icon>
                  <CloseOutlined
                    :style="{ color: 'red' }"
                    @click="onDelete(record.key)"
                  />
                </template>
              </Button>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </Table>
      </Form>
    </div>
    <EmptyView
      v-else
      title="Список товаров пуст"
      subtitle="Добавьте товары из каталога"
    />
  </ConfigProvider>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.text-align-center {
  text-align: center;
}
</style>
