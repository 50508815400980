<script lang="ts" setup>
import {
  Form,
  FormItem,
  Row,
  Col,
  Input,
  Flex,
  Textarea,
  Checkbox,
  Button,
  FormInstance,
  notification,
} from 'ant-design-vue'
import { h, onMounted, ref, watch } from 'vue'
import { phoneMask } from '@/types/globalConst'
import {
  formatPhoneNumber,
  getDifferenceObject,
  getUserIdTemporary,
  onlyNumbersPhone,
  transformPhoneNumber,
} from '@/helpers/scripts'
import { FarmerAPI } from '@/api/FarmerAPI'
import { ICustomer, IFarmer } from '@/types/interfaces'
import { Rule } from 'ant-design-vue/es/form'
import { Roles } from '@/types/enums'
import { CustomerAPI } from '@/api/CustomerAPI'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import DocumentItem from '@/components/Document/DocumentItem.vue'
import { Document } from '@/types/types'

interface FormState {
  name: string
  contact_number: string
  checking_account: string
  description: string | null
  vat: boolean
  can_deliver: boolean
}

interface Props {
  role: string
  documents: Document[]
}

const props = defineProps<Props>()

const validatePhone = async (_rule: Rule, value: string) => {
  if (onlyNumbersPhone(value).length != 11) {
    if (!value) {
      return Promise.reject('Введите телефон')
    } else return Promise.reject('Минимум 11 цифр')
  } else return Promise.resolve()
}

const validateCheckingAccount = async (_rule: Rule, value: string) => {
  if (value.length != 20) {
    return Promise.reject('В расчётном счёте должно быть 20 цифр')
  } else return Promise.resolve()
}

const validateDescription = async (_rule: Rule, value: string) => {
  if (!value || value?.length < 1000) {
    return Promise.resolve()
  } else return Promise.reject('')
}

const rules: Record<string, Rule[]> = {
  contact_number: [
    { required: true, validator: validatePhone, trigger: 'change' },
  ],
  checking_account: [
    { required: true, validator: validateCheckingAccount, trigger: 'change' },
  ],
  description: [{ validator: validateDescription, trigger: 'change' }],
}

const formRef = ref<FormInstance>()
const company = ref<IFarmer | ICustomer>()
const isDisabledBtn = ref(true)
const isLoading = ref(false)

const formState = ref<FormState>({
  name: '',
  contact_number: '',
  checking_account: '',
  description: '',
  vat: false,
  can_deliver: false,
})

const getCompany = async () => {
  const getFunc =
    props.role === Roles.Customer ? CustomerAPI.getItem : FarmerAPI.getFarmer
  company.value = (await getFunc(String(getUserIdTemporary()))) as
    | IFarmer
    | ICustomer // может придти и customer, интерфейсы одинаковые

  const {
    legal_form,
    name,
    contact_number,
    checking_account,
    description,
    vat,
    can_deliver,
  } = company.value

  formState.value = {
    name: `${legal_form} ${name}`,
    contact_number: String(formatPhoneNumber(contact_number)),
    checking_account,
    description,
    vat,
    can_deliver,
  }
}

const getNeededUpdateFunc = () => {
  if (props.role === Roles.Customer) {
    return CustomerAPI.updateCustomer
  } else {
    return FarmerAPI.updateFarmer
  }
}

const showEnablingVatNotif = () => {
  notification.info({
    message: 'Актуализируйте НДС для товаров',
    description:
      'Ставка НДС для всех ваших товаров назначена автоматически, пожалуйста,  проверьте актуальность присвоенных значений и измените их по необходимости',
    top: '80px',
    icon: h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)', width: '420px' },
    }),
  })
}

const showDisablingVatNotif = () => {
  notification.info({
    message: 'Налогообложение по НДС отключено',
    description:
      'Теперь цена на ваши товары будет указана без добавочной стоимости.',
    top: '80px',
    icon: h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)', width: '420px' },
    }),
  })
}

const saveChanges = () => {
  formRef.value
    ?.validateFields()
    .then(async ({ name: _, contact_number, ...updateValues }) => {
      isLoading.value = true

      let formObject: any = {
        contact_number: onlyNumbersPhone(contact_number),
        ...updateValues,
      }

      if (props.role === Roles.Farmer) {
        formObject.vat = formState.value.vat
        formObject.can_deliver = formState.value.can_deliver
      }

      await getNeededUpdateFunc()({
        id: company.value?.id,
        ...getDifferenceObject(formObject, company.value as object),
      })
        .then(() => {
          notification.info({
            message: 'Изменения сохранены.',
            top: '80px',
            icon: h(InfoCircleFilled, {
              style: { color: 'rgba(22, 119, 255, 1)' },
            }),
          })

          if (
            props.role === Roles.Farmer &&
            company.value?.vat !== formState.value.vat
          ) {
            if (formState.value.vat) {
              showEnablingVatNotif()
            } else {
              showDisablingVatNotif()
            }
          }

          company.value = {
            ...company.value,
            ...formState.value,
            contact_number: onlyNumbersPhone(formState.value.contact_number),
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    })
}

const handleChangePhone = (e: any) => {
  formState.value.contact_number = transformPhoneNumber(e?.target?.value)
}

onMounted(async () => {
  await getCompany()
})

watch(
  () => [formState.value, company.value],
  () => {
    if (!company.value) return

    let isChangedValue = false
    for (const key in formState.value) {
      if (key === 'name') continue
      if (
        (key !== 'contact_number' &&
          formState.value[key] !== company.value?.[key]) ||
        (key === 'contact_number' &&
          formState.value[key] !==
            formatPhoneNumber(String(company.value?.[key])))
      ) {
        isChangedValue = true
      }
    }

    isDisabledBtn.value = !isChangedValue
  },
  {
    deep: true,
  },
)
</script>

<template>
  <div class="form">
    <h3 class="title">Моя компания</h3>
    <Form
      ref="formRef"
      :model="formState"
      :disabled="!company"
      :rules="rules"
      layout="vertical"
    >
      <Flex :gap="16" vertical>
        <Row :gutter="12">
          <Col :span="16">
            <FormItem
              class="form-item__company-name"
              name="name"
              label="Название компании"
            >
              <Input v-model:value="formState.name" disabled />
            </FormItem>
          </Col>
          <Col :span="8">
            <FormItem
              name="contact_number"
              label="Общий номер телефона"
              required
            >
              <Input
                v-maska="{
                mask: phoneMask,
                preprocessor: (value: string) => transformPhoneNumber(value),
              }"
                :value="formState.contact_number"
                @change="handleChangePhone"
                placeholder="Введите номер"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col :span="24">
            <FormItem name="checking_account" label="Расчётный счёт" required>
              <Input v-model:value="formState.checking_account" type="number" />
            </FormItem>
          </Col>
        </Row>
        <Flex :gap="24" :style="{ marginBottom: '24px' }" vertical>
          <div v-for="document in props.documents" :key="document.id">
            <DocumentItem :document-item="document" :title="document.type" />
          </div>
        </Flex>
        <Row>
          <Col :span="24">
            <FormItem
              label="Описание профиля"
              help="Максимум 1000 символов"
              name="description"
            >
              <Textarea
                :autoSize="{ minRows: 2, maxRows: 6 }"
                class="textarea"
                v-model:value="formState.description"
                placeholder="Опишите, чем занимается ваша организация, какие заказы вас интересуют и другую информацию"
              />
            </FormItem>
          </Col>
        </Row>
        <Flex
          v-if="role === Roles.Farmer"
          :gap="12"
          :style="{ marginBottom: '24px' }"
          vertical
        >
          <Checkbox v-model:checked="formState.vat">
            Налогообложение по НДС
          </Checkbox>
          <Checkbox v-model:checked="formState.can_deliver">
            Я могу самостоятельно доставлять товары
          </Checkbox>
        </Flex>
        <Flex justify="start">
          <Button
            :loading="isLoading"
            :onClick="saveChanges"
            :disabled="isDisabledBtn"
            type="primary"
          >
            Сохранить изменения</Button
          >
        </Flex>
      </Flex>
    </Form>
  </div>
</template>

<style scoped lang="scss">
.form {
  padding: 48px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  min-width: 608px;

  .title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 24px;
    color: rgba(45, 55, 72, 1);
  }

  :deep(.ant-input) {
    height: 48px;
    border-color: rgba(203, 213, 224, 1);
  }
  :deep(.ant-btn) {
    height: 48px;
  }
}

:deep(.textarea) {
  font-size: 16px;
}
</style>
