<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.1711 4.16562L3.01172 4.16562C3.07735 4.16562 3.13203 4.11094 3.13203 4.04531L3.13203 3.07969L12.9711 3.07969L12.9711 12.9203L3.13047 12.9203L3.13047 11.9547C3.13047 11.8891 3.07578 11.8344 3.01016 11.8344L2.16953 11.8344C2.10391 11.8344 2.04922 11.8875 2.04922 11.9547L2.04922 13.5203C2.04922 13.7859 2.26328 14 2.52891 14L13.5711 14C13.8367 14 14.0508 13.7859 14.0508 13.5203L14.0508 2.47969C14.0508 2.21406 13.8367 2 13.5711 2L2.53047 2C2.26485 2 2.05078 2.21406 2.05078 2.47969L2.05078 4.04531C2.05078 4.1125 2.10547 4.16562 2.1711 4.16562ZM1.95703 8.5625L6.86328 8.5625L6.86328 9.75C6.86328 9.85469 6.98516 9.91406 7.06641 9.84844L9.28359 8.09844C9.29853 8.08674 9.31062 8.07181 9.31893 8.05475C9.32724 8.03769 9.33156 8.01897 9.33156 8C9.33156 7.98103 9.32724 7.96231 9.31893 7.94525C9.31062 7.9282 9.29853 7.91326 9.28359 7.90156L7.06641 6.15156C6.98359 6.08594 6.86328 6.14531 6.86328 6.25L6.86328 7.4375L1.95703 7.4375C1.88828 7.4375 1.83203 7.49375 1.83203 7.5625L1.83203 8.4375C1.83203 8.50625 1.88828 8.5625 1.95703 8.5625Z"
      fill="#0F9E79"
    />
  </svg>
</template>
