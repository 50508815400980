<script setup lang="ts">


interface Props {
  title: string
  subtitle: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="empty-view">
    <div class="empty-view-content">
      <img src="../assets/img/empty-cart.svg" alt="empty-cart" loading="lazy" />
      <div class="text-content">
        <span class="title">{{ props.title }}</span>
        <span class="subtitle">{{ props.subtitle }}</span>
      </div>
      <slot name="action" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.empty-view {
  width: 100%;
  height: 100%;
  position: relative;
  &-content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .image-content {
      background-color: $gray-100;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-content {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      text-align: center;
      .title {
        @include h3();
        color: $gray-800;
      }
      .subtitle {
        @include p5();
        color: $gray-800;
      }
    }
  }
}
</style>
