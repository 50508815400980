<script setup lang="ts">
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import { OnClickOutside } from '@vueuse/components'

interface Props {
  show: boolean
  showCloseIcon: boolean
  onlyContent: boolean
}

defineProps<Props>()

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" ref="modal" id="modal">
          <div class="modal-body" v-if="!showCloseIcon">
            <slot name="content" v-if="onlyContent" />
            <div v-else class="container">
              <div class="modal-header" :class="{ flex: !showCloseIcon }">
                <h3><slot name="header"></slot></h3>

                <BootstrapIcon
                  v-if="showCloseIcon"
                  class="close"
                  icon="x-lg"
                  @click="close"
                />
              </div>

              <div class="modal-body">
                <slot name="body" />
              </div>

              <div class="modal-footer">
                <slot name="footer" />
              </div>
            </div>
          </div>
          <OnClickOutside v-else @trigger="close">
            <div class="wrapper">
              <div class="onlyContent" v-if="onlyContent">
                <BootstrapIcon
                  v-if="showCloseIcon"
                  class="close"
                  icon="x-lg"
                  @click="close"
                />
                <slot name="content" />
              </div>
              <div v-else class="container">
                <div class="modal-header" :class="{ flex: !showCloseIcon }">
                  <h3><slot name="header"></slot></h3>

                  <BootstrapIcon
                    v-if="showCloseIcon"
                    class="close"
                    icon="x-lg"
                    @click="close"
                  />
                </div>

                <div class="modal-body">
                  <slot name="body" />
                </div>

                <div class="modal-footer">
                  <slot name="footer" />
                </div>
              </div>
            </div>
          </OnClickOutside>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  position: relative;
  width: fit-content;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.container {
  width: 440px;
}

.modal-container {
  width: fit-content;
  margin: 0 auto;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  background: #fff;
  border-radius: 16px;
}

.onlyContent {
  width: fit-content;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  stroke: $gray-800;
  align-self: baseline;
}

.modal-header {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-column-gap: 25px;
  align-items: start;

  h3 {
    margin-top: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $gray-800;
  }
}
.close-wrapper {
  display: flex;
  justify-content: flex-end;
  .close {
    margin-right: 30px;
    cursor: pointer;
  }
}

.modal-header.flex {
  text-align: start;
  display: inline-block;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.modal-body {
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  //  -webkit-transform: scale(1.1);
  //  transform: scale(1.1);
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

@media (max-width: 500px) {
  .modal-container {
    width: 100%;
    padding: 30px 20px;
    height: 100vh;
    overflow-y: auto;
    border-radius: 0;
  }

  .modal-header {
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
