<template>
  <div v-if="isLoading" class="list-loader">
    <FullscreenLoading :style="{ zIndex: 500 }" :type="'secondary'" />
  </div>
  <div :class="{'chat-blur': isLoading}" class="chat">
    <div class="chat-error" v-if="isError" :class="{ 'visible': isError }">
      <img
          class="chat-error__img"
          src="../assets/img/chat/chat-error.svg"
          alt="chat-error"
      />
      <div class="chat-error__body">
        <div class="chat-error__body-title">Произошла ошибка</div>
        <div class="chat-error__body-desc">
          К сожалению, страница недоступна
        </div>
        <Button class="chat-error__body-btn" type="default" @click="navToMain">
          Вернуться на главную
        </Button>
      </div>
    </div>
    <iframe
        v-show="!isError && !isLoading"
        id="chat-iframe"
        :src="currentUrl"
        frameborder="0"
        class="chat"
        @load="onIframeLoad"
        @error="onIframeLoad"
    />
  </div>
</template>

<script setup lang="ts">
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Button } from 'ant-design-vue'

const router = useRouter()
const isError = ref<boolean>(false)
const isLoading = ref<boolean>(true)

const currentUrl = computed(() =>
    window.location.href.replace('/chat', '/chatify'),
)

const onIframeLoad = () => {
  const iframe = document.getElementById('chat-iframe') as HTMLIFrameElement;
  const contentDocument = iframe.contentDocument || iframe.contentWindow?.document;
  window.addEventListener('message', handleIframeMessage);

  if (contentDocument) {
      const contentGray = contentDocument.querySelector('.messenger');
      if (contentGray) {
        if (!!contentGray.children?.length) {
          setTimeout(() => {
            // Проверяем наличие дочерних элементов
            isLoading.value = true
            const shadowHost = document.createElement('div') as HTMLElement;
            const shadow = shadowHost.attachShadow({mode: 'open'});
            const container = document.createElement('div');
            container.appendChild(contentGray.cloneNode(true)); // Клонируем элемент и добавляем в Shadow DOM
            shadow.appendChild(container);
            setTimeout(() => {
              isLoading.value = false
            }, 1000)
          }, 1000)
        } else {
          console.log('iframe children not found')
          setError()
        }
      } else {
        // Если элемент не найден, устанавливаем ошибку
        console.log('element not found')
        setError()
      }
  } else {
    // Если не удалось получить доступ к документу, устанавливаем ошибку
    console.log('не удалось получить доступ к документу')
    setError()
  }
}

const handleIframeMessage = (event: any) => {
  const iframe = document.getElementById('chat-iframe') as HTMLIFrameElement;
  const contentDocument = iframe.contentDocument || iframe.contentWindow?.document;
  if (!contentDocument) return
  const contentGray = contentDocument.querySelector('.messenger');
  if (!contentGray) return
  if (contentGray.children?.length) {
    console.log('message from iframe:', event)
    isLoading.value = false
    isError.value = false
  }
}

const setError = () => {
  isLoading.value = false
  isError.value = true;
}

const navToMain = () => {
  router.push('/')
}
</script>

<style scoped lang="scss">
.chat {
  height: 72vh;
  width: 100%;

  &-blur {
    filter: blur(500px); // Эффект размытия
    transition: filter 0.3s ease; // Плавный переход
  }

  &-error {
    &__img {
      margin-bottom: 20px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    opacity: 0; // Начальное состояние
    transition: opacity 0.5s ease-in-out; // Переход для плавного появления

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &-title {
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.88);
        font-family: Inter, sans-serif;
        font-weight: 600;
      }

      &-desc {
        font-size: 14px;
        line-height: 22px;
        font-family: Inter, sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  // Класс для видимости с анимацией
  &-error.visible {
    opacity: 1; // Конечное состояние
  }
}
</style>
