<script setup lang="ts">
import { UnwrapRef } from 'vue'
import SmallFarmerCardWithList from '@/components/Farmer/SmallFarmerCardWithList.vue'
import {
  Button,
  Col,
  List,
  ListItem,
  Row,
  TypographyText as TgText,
} from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'

interface Props {
  formState: UnwrapRef<any[]>
}
const props = defineProps<Props>()
const emit = defineEmits([
  'deleteFarmer',
  'handleSelectFarmerModal',
  'handlePublicReduction',
])
const height100 = { height: '100%' }
const width100 = { width: '100%' }
const deleteFarmer = (uid: string) => {
  emit('deleteFarmer', uid)
}
</script>

<template>
  <div class="reduction-farmers" :style="height100">
    <List :data-source="props.formState" v-if="props.formState.length">
      <template #renderItem="{ item }">
        <ListItem>
          <Row :style="width100">
            <Col :flex="'auto'">
              <SmallFarmerCardWithList :farmer="item" />
            </Col>
            <Col>
              <Button
                type="text"
                @click="deleteFarmer(item.id)"
                class="delete-product-btn"
              >
                <template #icon>
                  <CloseOutlined :style="{ color: 'red' }" />
                </template>
              </Button>
            </Col>
          </Row>
        </ListItem>
      </template>
    </List>
    <div class="card-wrapper" v-else>
      <div class="option-card">
        <img
          src="@/assets/img/reduction/reduction_lock.svg"
          alt="card-icon"
          width="150"
          height="150"
        />
        <h4>Ограничить круг участников</h4>
        <TgText
          >Выберите поставщиков, которые смогут присоединиться к торгу на
          покупку, остальные поставщики не смогут участвовать в нём
        </TgText>
        
        <Button
          type="primary"
          @click="emit('handleSelectFarmerModal', true)"
          class="option-card-btn"
        >
          Ограничить круг участников
        </Button>
      </div>
      <div class="option-card">
        <img
          src="@/assets/img/reduction/reduction_speaker.svg"
          alt="card-icon"
          width="150"
          height="150"
        />
        <h4>Опубликовать торг на покупку для всех</h4>
        <TgText
          >Торг на покупку будет открыт для всех, участвовать сможет любой
          поставщик, у которого есть хотя бы 1 товар из вашего списка
        </TgText>
        <Button
          type="primary"
          @click="emit('handlePublicReduction', true)"
          class="option-card-btn"
        >
          Опубликовать для всех
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reduction-farmers {
  overflow-y: scroll;
  :deep(.ant-list-item) {
    padding: 24px 32px !important;
    &:hover {
      background-color: #0000000a;
    }
  }
  .delete-product-btn {
    &:hover {
      background-color: #fff0f0;
    }
    &:active {
      background-color: #ffd5d5;
    }
  }
  .card-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 51%;
    column-gap: 40px;
    .option-card {
      text-align: center;
      flex: 1;
      padding: 56px 32px;
      border: 1px solid #0000000f;
      border-radius: 8px;
      background-color: #00000005;
      h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #000000e0;
        margin-bottom: 4px;
        margin-top: 24px;
        white-space: nowrap;
      }
      &-btn {
        display: block;
        margin: 32px auto 0 auto;
      }
    }
  }
}
</style>
