<script lang="ts" setup>

import InfoToolbar from '@/views/Customer/auctions/customerAuctionInfoTab/infoToolbar/InfoToolbar.vue'
import { IAuction } from '@/types/interfaces'
import ProductsTable from '@/views/Customer/auctions/customerAuctionInfoTab/CustomerProductsTable.vue'

interface IProps {
  auctionData: IAuction
  myParticipantId: number
  isAllProductsDeleted: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])
</script>

<template>
  <div class="wrapper">
    <InfoToolbar :auction="props.auctionData" />
    <ProductsTable
      :products="auctionData?.auction_product_requests ?? []"
      :isClosedReduction="auctionData.status === 'closed'"
      :myParticipantId="myParticipantId"
      :isAllProductsDeleted="isAllProductsDeleted"
      @set-request-id="emit('setRequestId', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}
</style>
