<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Col, Drawer, Row, Space } from 'ant-design-vue'
import { ICompanyDocument } from '@/types/interfaces'
import { width100 } from '@/model/constants'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import AntEmpty from '@/components/Empty/AntEmpty.vue'
import { CompanyDocumentAPI } from '@/api/CompanyDocumentAPI'
import { TCompanyDocumentsType } from '@/types/types'
import DocumentMiniListItem from '@/components/Document/DocumentMiniListItem.vue'

interface IProps {
  open: boolean
  company_id?: number
}

interface IDocumentsGroup {
  title: string
  documents: ICompanyDocument[]
}

const emit = defineEmits(['update:open'])
const props = defineProps<IProps>()

const isFetching = ref<boolean>(false)
const company_documents = ref<IDocumentsGroup[]>([])

const closeDrawer = () => {
  emit('update:open', false)
}

const get_company_documents = async () => {
  isFetching.value = true
  const filters = [
    ['company_id', 'eq', props.company_id],
      'AND',
    ['type', 'ne', 'PAS'],
  ]

  CompanyDocumentAPI.getItems(filters, 100).then((response) => {
    company_documents.value = group_documents(response.items)
    isFetching.value = false
  })
}

const group_documents = (documents: ICompanyDocument[]) => {
  const documents_group: Record<TCompanyDocumentsType, IDocumentsGroup> = {
    passport: {
      title: 'Паспорт подписанта',
      documents: [],
    },
    proxy: {
      title: 'Доверенность подписанта',
      documents: [],
    },
    PA: {
      title: 'Договор с платформой',
      documents: [],
    },
    tin: {
      title: 'Документы, подтверждающие постановку на учёт в налоговом органе',
      documents: [],
    },
    PAS: {
      title: 'Системный договор',
      documents: [],
    },
  }
  documents.forEach((item) => {
    switch (item.type) {
      case 'passport':
        documents_group.passport.documents.push(item)
        break
      case 'proxy':
        documents_group.proxy.documents.push(item)
        break
      case 'PA':
        documents_group.PA.documents.push(item)
        break
      case 'tin':
        documents_group.tin.documents.push(item)
        break
      case 'PAS':
        documents_group.PAS.documents.push(item)
        break
      default:
        break
    }
  })

  return Object.values(documents_group).filter((item) => item.documents.length)
}

const emptyDocuments = computed(() => {
  return company_documents.value.length === 0 && !isFetching.value
})
watch(
  () => props.company_id,
  (new_value) => {
    if (new_value) {
      get_company_documents()
    }
  },
)
</script>

<template>
  <Drawer
    width="540"
    :bodyStyle="{ padding: '24px 40px 72px 40px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    title="Документы компании"
    class="drawer-reverse-header"
    :open="open"
    @close="closeDrawer"
  >
    <div class="loader-wrapper" v-if="isFetching">
      <FullscreenLoading type="secondary" />
    </div>

    <div class="empty-wrapper" v-if="emptyDocuments">
      <AntEmpty
        title="Документы отсутствуют"
        subtitle="Документы не загружены"
      />
    </div>
    <Row v-else :gutter="[0, 24]">
      <Col
        :span="24"
        v-for="documents_group in company_documents"
        :key="documents_group.title"
      >
        <h5 class="document-block-title">{{ documents_group.title }}</h5>
        <Space :size="12" direction="vertical" :style="width100">
          <DocumentMiniListItem
            v-for="document in documents_group.documents"
            :key="document.id"
            :id="document.id"
            :name="document.file_name"
            :body="''"
            :errors="[]"
            :isShow="true"
            documentModel="company"
          />
        </Space>
      </Col>
    </Row>
  </Drawer>
</template>

<style scoped lang="scss">
.document-block-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
  margin-bottom: 8px;
}
.loader-wrapper,
.empty-wrapper {
  position: relative;
  height: calc(100vh - 100px);
}
</style>
