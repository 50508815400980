<script lang="ts" setup>
import { Tag } from 'ant-design-vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { useStore } from '@/store'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { IPartnership } from '@/types/interfaces'
import { storeToRefs } from 'pinia'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'

interface Props {
  partners: IPartnership[]
  item: {
    id: number
    name: string
    legal_address: string
    farmer_products: any[]
    legal_form: string
    categories: Record<number, string>
    company_rating: any[]
  }
}

const props = defineProps<Props>()
const { getCoreCookie } = useJWTDecode()

const isPartner = () => {
  return (
    !!props.partners.filter((el) => el.company_id == props.item?.id)?.length &&
    getCoreCookie()?.company_type === Roles.Customer
  )
}

const { isVerification } = storeToRefs(useStore())

const quotesReplacer = (text: string) =>
  text
    ?.replaceAll('""', '')
    ?.replaceAll('<<', '')
    ?.replaceAll('>>', '')
    ?.replaceAll('«', '')
    ?.replaceAll('»', '')
</script>

<template>
  <div class="result-card">
    <div class="result-card__title">
      <BlurTooltip :withoutFlex="true">
        <PartnershipTooltip v-if="isPartner()" />
        <span :class="!isVerification ? 'blur' : ''"
          >{{ item.legal_form }} &#171;{{
            quotesReplacer(item.name)
          }}&#187;</span
        >
      </BlurTooltip>
    </div>
    <div
      v-if="item?.addresses?.length"
      class="result-card__subtitle"
      :class="!isVerification ? 'blur' : ''"
    >
      <BootstrapIcon icon="geo-alt" />
      <!-- TODO: Проверить адреса -->
      {{
        item?.addresses[0]?.street
          ? item?.addresses[0]?.street
          : item?.farmer_products.addresses[0].street
      }}
    </div>
    <div :class="!isVerification ? 'blur' : ''">
      <SimpleRate :company_rating="item.company_rating" />
    </div>
    <div :class="!isVerification ? 'blur' : ''">
      <div v-if="item?.categories" class="result-card__tags">
        <div
          v-for="tag in item?.categories?.slice(0, 7)"
          :key="tag"
          class="result-card__tag"
        >
          <Tag>
            {{ tag.second_category_name }}
          </Tag>
        </div>
        <Tag v-if="item?.categories?.length > 7">
          +{{ item?.categories?.length - 7 }}
        </Tag>
      </div>
    </div>

    <div :class="!isVerification ? 'blur' : ''">
      <div v-if="!item?.categories" class="body-empty">
        <SearchOutlined
          :style="{ width: '16px', height: '16px' }"
          class="search"
          :fill="'#718096'"
        />
        <span> У поставщика нет товаров </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.result-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background: $gray-100;
  }
  .blur {
    @include blur();
    @include no-select();
  }
  &__title {
    display: flex;
    gap: 4px;
    width: 408px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $gray-800;
    margin-bottom: 4px;

    &:hover {
      color: #1677ff;
    }
  }

  &__subtitle {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-600;

    svg {
      margin-right: 8px;
      margin-bottom: 0;
      min-width: 18px;
      min-height: 18px;
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 20px;

    :deep(.ant-tag) {
      margin-right: 0;
    }
  }
  .body {
    &-empty {
      margin-top: 20px;
      color: $gray-600;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;

      .search {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
