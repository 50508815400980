<script lang="ts">
import { defineComponent, computed } from 'vue'
import CustomerRequestCommonInfo from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/CustomerRequestCommonInfo.vue'
import CustomerRequestProductsList from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestProductsList/CustomerRequestProductsList.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'

export default defineComponent({
  name: 'CustomerRequestDataTab',
  components: {
    CustomerRequestCommonInfo,
    CustomerRequestProductsList,
  },
  setup() {
    const store = useCustomerRequestStore()

    // Товары
    const productRequests = computed(
      () => store.currentCustomerRequest?.product_requests,
    )

    return {
      productRequests,
    }
  },
})
</script>

<template>
  <div class="customer-request-data-tab">
    <CustomerRequestCommonInfo />
    <CustomerRequestProductsList :products="productRequests" />
  </div>
</template>

<style lang="scss">
.customer-request-data-tab {
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}
</style>
