<script lang="ts">
import { defineComponent } from 'vue'
import DataListBlockItem from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/DataListBlockItem.vue'

export default defineComponent({
  name: 'DataListBlock',
  components: { DataListBlockItem },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
})
</script>

<template>
  <div class="data-list-block" v-if="!!items.length">
    <div class="data-list-block_title">{{ title }}</div>
    <DataListBlockItem v-for="item of items" :key="item?.id" :item="item" />
  </div>
</template>

<style lang="scss">
.data-list-block {
  display: flex;
  flex-direction: column;

  &_title {
    padding: 0 32px;
    margin-top: 10px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000e0;
  }
}
</style>
