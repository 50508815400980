<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import SuggestionTip from '@/components/RegisterSteps/SuggestionTip/SuggestionTip.vue'
import { BankStatus, SuggestionTypes } from '@/types/enums'
import { ISuggestBank } from '@/types/interfaces'
import { DadataAPI } from '@/api/Dadata'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import { isNull } from '@/helpers/scripts'
import { useCookies } from 'vue3-cookies'
import { useDebounceFn } from '@vueuse/core'

const { cookies } = useCookies()

const emit = defineEmits(['next', 'setCreateData', 'FourAlt'])
const searchValue = ref('')
const isShow = ref(false)
const changeInput = async (el: any) => {
  searchValue.value = el.target.value
  if (el.target.value !== '') {
    await DadataAPI.getSuggests(SuggestionTypes.Bank, el.target.value)
      .then((resp) => {
        tipItems.value = resp
      })
      .catch((err) => {
        if (err.code === 418) {
          emit('next', { step: 2, status: '' })
        }
      })
    isShow.value = true
  } else {
    isShow.value = false
  }
}
const debounce_input = useDebounceFn(changeInput, 500)

const activeBank = ref<ISuggestBank>()
const tipItems = ref<ISuggestBank[]>([])

const selectTip = (tip: ISuggestBank) => {
  activeBank.value = tip
  searchValue.value = tip.bank
  emit('setCreateData', ['bic', tip.bic])
  isShow.value = false
}
const openTips = () => {
  if (tipItems.value.length > 0)
    setTimeout(() => {
      isShow.value = true
    }, 0)
}
const closeTip = () => {
  isShow.value = false
}

const sendData = () => {
  cookies.set('activeBank', activeBank.value?.bic as string, '365d', '')
  emit('next')
}

onMounted(() => {
  const FourAlt = cookies.get('FourAlt')
  if (!FourAlt) {
    DadataAPI.ping().catch(() => {
      localStorage.setItem('FourAlt', String(true))
      emit('FourAlt')
    })
  }
})
</script>

<template>
  <div class="first-step__card">
    <div class="first-step__card-header">
      Найдите свой банк
      <div class="step">
        <span>этап 3</span>
        <span class="max">/4</span>
      </div>
    </div>
    <div class="first-step__card-body">
      <div class="suggest-input">
        <input
          v-model="searchValue"
          class="input"
          placeholder="Введите название банка, БИК или ИНН"
          type="text"
          @click="openTips"
          @input="debounce_input"
        />
        <SuggestionTip
          :isShow="isShow"
          :items="tipItems"
          :type="SuggestionTypes.Bank"
          @closeTip="closeTip"
          @selectTip="selectTip"
        />
      </div>
      <div v-if="activeBank" class="suggest-body">
        <div class="suggest-body__tooltip">
          <InfoIcon fill="#3385FF" type="outlined" />
          Проверьте и подтвердите данные банка, если это не ваш банк — выберите
          другой
        </div>
        <div class="suggest-body__info">
          <div class="info-column">
            <span class="info-column__title">Наименование банка</span>
            {{ isNull(activeBank.bank) }}
          </div>
          <div class="info-row">
            <div class="info-column">
              <span class="info-column__title">ИНН</span>
              {{ isNull(activeBank.bank_tin) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">Кор. счёт</span>
              {{ isNull(activeBank.correspondent_account) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">БИК</span>
              {{ isNull(activeBank.bic) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">КПП</span>
              {{ isNull(activeBank.bank_kpp) }}
            </div>
            <div
              v-if="BankStatus[activeBank.status] !== BankStatus.ACTIVE"
              class="info-column org-status"
            >
              <span class="info-column__title"> Статус банка </span>
              <p>
                <DangerIcon />
                {{ BankStatus[activeBank.status] }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="suggest-footer">
        <EButton
          :data="{
            disabled: !activeBank,
          }"
          :style-config="{
            ...primaryButton,
            disabled: {
              backgroundColor: '#E2E8F0',
              borderColor: 'transparent',
              color: '#A0AEC0',
            },
            fontSize16,
          }"
          class="suggest-footer_btn"
          @click="sendData"
        >
          Подтвердить данные
        </EButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.first-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;

  &-header {
    position: relative;
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .suggest {
      &-input {
        position: relative;

        .input {
          box-sizing: border-box;
          padding: 14px 20px;
          display: block;
          width: 100%;
          align-items: center;
          border: 1px solid $gray-300;
          border-radius: 8px;
          flex-grow: 1;
          position: relative;
        }
      }

      &-body {
        &__tooltip {
          padding: 10px;
          border-radius: 8px;
          background-color: $accent-opacity-1;
          color: $hover-accent;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          column-gap: 5px;
          margin-bottom: 40px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
        }
      }

      &-footer {
        margin-top: 40px;
      }
    }
  }
}

.info {
  &-row {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    column-gap: 32px;
    row-gap: 20px;
  }

  &-column {
    &.org-status {
      color: $default-danger;

      p {
        display: flex;
        align-items: center;
        column-gap: 9px;
      }
    }

    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    row-gap: 3px;
    color: $gray-800;

    &__title {
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
    }
  }
}
</style>
