<script lang="ts" setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import {computed, onMounted, onUnmounted, ref} from 'vue'
import {tabsForBenefitsTabbar} from "@/views/HomeUnauthorized/components/BenefitsTabbar/constants";
import {renameTabs, highlightText} from '@/views/HomeUnauthorized/components/BenefitsTabbar/helpers'

const currentCards = ref(tabsForBenefitsTabbar[0]?.cards)
const currentTab = ref(tabsForBenefitsTabbar[0]?.type)

const handleTabClick = (tab: any) => {
  currentTab.value = tab.type
  currentCards.value = tab.cards
  tab.cards.forEach((card: any) => {
    card.classList?.add('tab-change')
    setTimeout(() => {
      card.classList?.remove('tab-change')
      card.classList?.add('animate')
    }, 500)
  })
}

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate')
        }
      })
    },
    { threshold: 1 },
  )

  const tabs = document.querySelectorAll('.benefits-tabbar__tab')
  const cards = document.querySelectorAll('.benefits-tabbar__cards > *')

  cards.forEach((card) => {
    observer.observe(card)
  })

  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      cards.forEach((card, index) => {
        gsap.to(card, {
          duration: 0.25,
          opacity: 0.5,
          x: index % 2 === 0 ? 5 : -5,
          ease: 'power2.out',
          onComplete: () => {
            card.classList?.remove('animate')
            card.classList?.add('tab-change')
            gsap.to(card, {
              duration: 0.25,
              opacity: 1,
              x: 0,
              ease: 'power3.in',
              onComplete: () => {
                card.classList.remove('tab-change')
                card.classList.add('animate')
              },
            })
          },
        })
      })
    })
  })

  // Add ScrollTrigger
  gsap.registerPlugin(ScrollTrigger)
  const benefitsTabbar = document.querySelector('.benefits-tabbar')
  const benefitsTabbarCards = document.querySelectorAll(
    '.benefits-tabbar__cards > *',
  )

  benefitsTabbarCards.forEach((card, index) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: benefitsTabbar,
        // markers: true,
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none reset',
      },
      x: index % 2 === 0 ? -100 : 100,
      opacity: 0,
      duration: 0.5,
    })
  })
})

const zoomLevel = computed(() => window.devicePixelRatio * 100);
const cardTitleClass = ref(zoomLevel.value >= 125 ? 'card__title_zoom-150' : 'card__title')
const cardDescClass = ref(zoomLevel.value >= 125 ? 'card__description_zoom-150' : 'card__description')
const benefitsTabbarTitleClass = ref(zoomLevel.value >= 125 ? 'benefits-tabbar__title_zoom-150' : 'benefits-tabbar__title')
const benefitsTabbarTab = ref(zoomLevel.value >= 125 ? 'benefits-tabbar__tab_zoom-150' : 'benefits-tabbar__tab')
const benefitsTabbarCardsWrapper = ref(zoomLevel.value >= 125 ? 'benefits-tabbar__cards-wrapper_zoom-150' : 'benefits-tabbar__cards-wrapper')

const calcClasses = () => {
  cardTitleClass.value = zoomLevel.value >= 125 ? 'card__title_zoom-150' : 'card__title'
  cardDescClass.value = zoomLevel.value >= 125 ? 'card__description_zoom-150' : 'card__description'
  benefitsTabbarTitleClass.value = zoomLevel.value >= 125 ? 'benefits-tabbar__title_zoom-150' : 'benefits-tabbar__title'
  benefitsTabbarTab.value = zoomLevel.value >= 125 ? 'benefits-tabbar__tab_zoom-150' : 'benefits-tabbar__tab'
  benefitsTabbarCardsWrapper.value = zoomLevel.value >= 125 ? 'benefits-tabbar__cards-wrapper_zoom-150' : 'benefits-tabbar__cards-wrapper'
}

onMounted(() => {
  window.addEventListener('resize', calcClasses);
})

onUnmounted(() => {
  window.removeEventListener('resize', calcClasses);
})
</script>

<template>
  <div class="benefits-tabbar">
    <div :class="benefitsTabbarTitleClass">Почему с нами нужно сотрудничать</div>
    <div class="benefits-tabbar__tabs">
      <div
        :class="[
          benefitsTabbarTab,
          currentTab === tab?.type ? `${benefitsTabbarTab}_active` : '',
        ]"
        v-for="(tab, index) in tabsForBenefitsTabbar"
        :key="index"
        @click="handleTabClick(tab)"
      >
        {{ renameTabs(tab.type) }}
      </div>
    </div>
    <div :class="benefitsTabbarCardsWrapper">
      <div class="benefits-tabbar__cards">
        <div class="card" v-for="(card, index) in currentCards" :key="index">
          <div :class="cardTitleClass">
            {{ card.title.split(' ')[0] }}
            <div class="card__title_highlight">
              {{ highlightText(card.title, 1) }}
            </div>
          </div>
          <div :class="cardDescClass">{{ card.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/style/variables';

.card {
  padding: 20px 26px 15px 26px; // 51px 46px 51px 46px
  border-radius: 12px;
  background-color: variables.$benefits-tabbar-card-background;
  box-shadow: variables.$benefits-tabbar-card-box-shadow;
  &__title {
    font-family:
      Nurito sans,
      sans-serif !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$benefits-tabbar-card-title-color;
    margin-bottom: 16px;
    &_zoom-150 {
      font-family:
          Nurito sans,
          sans-serif !important;
      font-size: 16px; // 24px
      font-weight: 600;
      line-height: 31.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$benefits-tabbar-card-title-color;
      margin-bottom: 10px; // 16px
    }
    &_highlight {
      display: inline-block;
      position: relative;
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 2px;
      font-family:
        Nurito sans,
        sans-serif !important;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 4px;
        background: rgb(208, 245, 235);
        left: 0;
      }
    }
  }
  &__description {
    //styleName: LG/Normal;
    font-family:
        Nurito sans,
        sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$benefits-tabbar-card-desc-color;
    &_zoom-150 {
      //styleName: LG/Normal;
      font-family:
          Nurito sans,
          sans-serif !important;
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$benefits-tabbar-card-desc-color;
    }
  }
}

.benefits-tabbar__cards-wrapper_zoom-150 {
  margin-top: 20px;
  overflow-y: auto;
  height: 400px;
}

.benefits-tabbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__title {
    font-family:
      Nunito Sans,
      sans-serif !important;
    font-size: 32px;
    font-weight: 500;
    line-height: 43.84px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$benefits-tabbar-title-color;
    background: url('@/assets/img/gradient-bg-b-tabbar.svg') no-repeat;
    &_zoom-150 {
      font-family:
          Nunito Sans,
          sans-serif !important;
      font-size: 24px;
      font-weight: 500;
      line-height: 23.84px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$benefits-tabbar-title-color;
      background: url('@/assets/img/gradient-bg-b-tabbar.svg') no-repeat;
    }
  }
  &__tabs {
    display: flex;
    justify-items: center;
    justify-content: center;
    margin-top: 5px; // 10px
  }
  &__tab {
    font-family:
      Nunito Sans,
      sans-serif !important;
    font-size: 36px;
    font-weight: 200;
    line-height: 49.32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$benefits-tabbar-tab-text-color;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color;
    &:hover {
      border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color-active;
      color: variables.$benefits-tabbar-tab-text-color-active;
    }
    &_active {
      border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color-active;
      color: variables.$benefits-tabbar-tab-text-color-active;
    }
    &:first-child {
      padding-right: 10px;
      padding-left: 10px;
    }
    &:last-child {
      padding-left: 10px;
      padding-right: 10px;
    }
    &_zoom-150 {
      font-family:
          Nunito Sans,
          sans-serif !important;
      font-size: 20px;
      font-weight: 200;
      line-height: 24.32px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$benefits-tabbar-tab-text-color;
      cursor: pointer;
      transition: 0.25s ease-in-out;
      border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color;
      &:hover {
        border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color-active;
        color: variables.$benefits-tabbar-tab-text-color-active;
      }
      &_active {
        border-bottom: 1px solid variables.$benefits-tabbar-tab-text-color-active;
        color: variables.$benefits-tabbar-tab-text-color-active;
      }
      &:first-child {
        padding-right: 10px;
        padding-left: 10px;
      }
      &:last-child {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  &__cards {
    display: flex;
    justify-items: center;
    margin: 20px auto 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 418px; // 450px
    padding-bottom: 15px;
  }
}

.benefits-tabbar__cards > *:nth-child(4n + 1) {
  width: calc(40% - 20px);

  .card__title_highlight::before {
    transform: rotate(-1.72deg);
  }
}

.benefits-tabbar__cards > *:nth-child(4n + 2) {
  width: calc(30% - 20px);

  .card__title_highlight::before {
    transform: rotate(1.55deg);
  }
}

.benefits-tabbar__cards > *:nth-child(4n + 3) {
  width: calc(30% - 20px);

  .card__title_highlight::before {
    transform: rotate(0.76deg);
  }
}

.benefits-tabbar__cards > *:nth-child(4n) {
  width: calc(40% - 20px);

  .card__title_highlight::before {
    transform: rotate(1.55deg);
  }
}

.benefits-tabbar__cards > * {
  margin: 10px;
}
</style>
