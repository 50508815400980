<script setup lang="ts">
import { ICompanyView, IUser } from '@/types/interfaces'
import { Table, Tag, Pagination, Flex, Button } from 'ant-design-vue'
import {computed, onMounted, reactive, ref} from 'vue'
import { UserAPI } from '@/api/UserAPI'
import { formatPhoneNumber, isNull } from '@/helpers/scripts'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import AntEmpty from '@/components/Empty/AntEmpty.vue'
import {useJWTDecode} from "@/composables/useJWTDecode";
import {Roles} from "@/types/enums";
import {useAuth} from "@/composables/useAuth";

interface IProps {
  company: ICompanyView
}

const { getRoles } = useJWTDecode()
const props = defineProps<IProps>()
const employees: IUser[] = reactive([])
const page = ref(1)
const per_page = ref(10)
const total_count = ref(0)
const initial_fetch = ref(false)
const is_loading = ref(false)
const isSuperUser = computed(() => getRoles()?.[0] === Roles.SuperAdmin)
const auth = useAuth()

const columns = [
  {
    title: 'ФИО',
    dataIndex: 'name',
    width: '26%',
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    width: '22%',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    width: '16%',
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    width: '21%',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: '140px',
  },
]

const get_employees = async () => {
  if (!props.company.company_type) {
    initial_fetch.value = false
    return
  }
  const filters: (string | number)[][] = [
    ['company_id', 'eq', props.company.company_id],
      'AND',
    ['type', 'eq', props.company.company_type],
  ]
  await UserAPI.getItemsCore(page.value, per_page.value, filters).then(
    (res) => {
      total_count.value = res.total_count
      employees.splice(0, employees.length, ...res.items)
      initial_fetch.value = false
      is_loading.value = false
    },
  )
}

const change_page = (new_page: number, pageSize: number) => {
  page.value = new_page
  per_page.value = pageSize
  is_loading.value = true
  get_employees()
}

onMounted(() => {
  initial_fetch.value = true
  get_employees()
  if (isSuperUser.value) {
    columns.push({
      title: 'Действия',
      dataIndex: 'action',
      width: '20%',
    })
  }
})
</script>

<template>
  <div>
    <Flex vertical gap="24" v-if="!initial_fetch && total_count">
      <Table
        row-key="id"
        :columns="columns"
        :data-source="employees"
        :loading="is_loading"
        :pagination="false"
        @change="change_page"
        class="table"
        :sticky="true"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'name'">
            {{ isNull(record.name) }}
          </template>
          <template v-if="column.dataIndex === 'phone'">
            {{ isNull(formatPhoneNumber(record.phone)) }}
          </template>
          <template v-if="column.dataIndex === 'position'">
            {{ isNull(record.position) }}
          </template>
          <template v-if="column.dataIndex === 'status'">
            <Tag color="green">Активен</Tag></template
          >
          <template v-if="column.dataIndex === 'action'">
            <Button @click="auth.putUserMask(record.email)">Войти под пользователем</Button>
          </template>
        </template>
      </Table>
      <Flex gap="8" align="center" v-if="total_count > 10">
        <Pagination
          v-model:current="page"
          v-model:page-size="per_page"
          :total="total_count"
          show-size-changer
          @change="change_page"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}</span>
          </template>
        </Pagination>
        элементов
      </Flex>
    </Flex>
    <template v-if="initial_fetch">
      <FullscreenLoading :type="'secondary'" />
    </template>
    <template v-if="!initial_fetch && !total_count">
      <div class="empty-wrapper">
        <AntEmpty
          title="Список сотрудников пуст"
          subtitle="Компания пока что не имеет ни одного сотрудника"
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.table {
  height: calc(100vh - 145px - 24px - 48px - 32px);
  overflow: auto;
}
:deep(.ant-pagination) {
  width: 100%;
}
:deep(.ant-pagination-options) {
  float: right;
  &::before {
    content: 'Показывать на странице';
    display: inline-block;
    margin-right: 8px;
  }
}
.empty-wrapper {
  height: calc(100vh - 145px - 48px);
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
</style>
