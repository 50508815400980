<script lang="ts" setup>
import { Tooltip } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

const props = defineProps({
  show: Boolean,
  placement: { type: String, default: 'bottom' },
  text: {
    type: String,
    default:
      'У поставщика недостаточное количество товара для выполнения вашего заказа. Пожалуйста, укажите это в комментариях к заказу.',
  },
})
</script>

<template>
  <Tooltip v-if="props.show" :placement="props.placement" color="#FFFFFF">
    <template #title>
      <div class="tooltip-content">
        {{ props.text }}
      </div>
    </template>
    <InfoCircleOutlined />
  </Tooltip>
</template>

<style lang="scss" scoped>
.tooltip-content {
  color: #000000e0;
}
</style>
