<script setup lang="ts">
import { List, ListItem, Radio, RadioGroup } from 'ant-design-vue'
import { primaryButton, withoutBGButton } from '@/assets/EgalStyles/EButton'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { defineProps, ref } from 'vue'
import { IAddress } from '@/types/interfaces'
import { PlusOutlined } from '@ant-design/icons-vue'
import { useModalStore } from '../ModalSystemStore'
import { ModalKeys } from '../ModalKeys'
import { AccountActions, AccountTabs } from '@/types/enums'
import { formatPhoneNumber } from '@/helpers/scripts'
import DotImg from '../../../assets/img/dot.svg'

interface IProps {
  show: boolean
  selectedAddress: number | null
  addressList: IAddress[]
  onSave: (id: number) => void
  redirectWarningMessage?: string
  title?: string
}

const width100 = { width: '100%' }

const props = defineProps<IProps>()
const selectedValue = ref(props.selectedAddress)
const emit = defineEmits(['close'])
const modalStore = useModalStore()

const handleChangeSelect = (value: number) => {
  selectedValue.value = value
}

const handleSave = () => {
  selectedValue.value && props.onSave(selectedValue.value)
  emit('close')
}

const handleClose = async () => {
  emit('close')
}

const handleOpenRedirectModal = () => {
  emit('close')
  modalStore.openModal(ModalKeys.RedirectWarning, {
    path: 'account',
    message: props.redirectWarningMessage,
    onCancel: () => {
      modalStore.openModal(ModalKeys.SelectAddress, props)
    },
    beforeRedirect: () => {
      localStorage.setItem('MyAccountTab', AccountTabs.AddressList)
      localStorage.setItem('MyAccountAction', AccountActions.AddAddress)
    },
  })
}
</script>

<template>
  <ModalWindow
    :show="props.show"
    :show-close-icon="true"
    class="select-modal select-address-modal"
    @close="handleClose"
  >
    <template #header>
      <div class="select-address-modal__block">
        {{ title ? title : 'Выберите адрес доставки' }}
      </div>
    </template>
    <template #body>
      <RadioGroup
        :value="selectedValue"
        @change="handleChangeSelect($event.target.value)"
        :style="width100"
      >
        <List :data-source="props.addressList" rowKey="id">
          <template #renderItem="{ item }">
            <ListItem>
              <Radio :value="item.id" class="radio-item">
                <div class="info">
                  <div class="address">{{ item.street }}</div>
                  <div class="contact">
                    <span class="address-name">{{ item.name }}</span>
                    <img
                      v-if="item.name && item.contact_number"
                      alt="dot"
                      :src="DotImg"
                    />

                    <span class="address-phone">{{
                      formatPhoneNumber(item.contact_number)
                    }}</span>
                  </div>
                </div>
              </Radio>
            </ListItem>
          </template>
        </List>
      </RadioGroup>
    </template>
    <template #footer>
      <EButton
        :style-config="{
          ...withoutBGButton,
        }"
        @click="handleOpenRedirectModal"
        v-if="props.redirectWarningMessage"
      >
        <PlusOutlined />&nbsp;Добавить новый адрес
      </EButton>
      <EButton
        :style-config="{
          ...primaryButton,
        }"
        :data="{
          disabled: !selectedValue,
        }"
        class="cancel__button"
        @click="handleSave"
      >
        Подтвердить
      </EButton>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.select-modal {
  :deep(.modal-container) {
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-header) {
    padding: 32px;
    padding-bottom: 25px;
    box-sizing: border-box;
  }
  :deep(.modal-footer) {
    box-sizing: border-box;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray-300;
    font-size: 14px;
    button {
      font-weight: 500;
    }
  }
  :deep(.modal-body) {
    box-sizing: border-box;
    padding: 0 32px;
    width: 600px;
    height: 60vh;
    margin-bottom: auto;
    overflow-y: auto;
  }
  :deep(.ant-list-item) {
    padding-left: 0;
    overflow: hidden;
  }
  :deep(.ant-radio-wrapper) {
    height: auto !important;
  }
  :deep(.radio-item) {
    .info {
      padding-left: 5px;
    }

    .ant-radio {
      align-self: start;
      padding-top: 5px;
    }

    .address {
      //max-width: 505px;
      font-weight: 400;
      font-size: 16px;
      color: $gray-800;
      margin-bottom: 5px;
      //white-space: nowrap;
    }

    .contact {
      font-weight: 400;
      font-size: 14px;
      color: $gray-600;
      display: flex;

      .address-name {
        max-width: 360px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
  .cancel__button {
    margin-left: auto;
  }
}

.wrapper {
  @include wrapper();
}
</style>
