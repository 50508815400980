<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'
import CustomersRequestSubheaderAnswersPanel from '@/views/Customer/CustomerRequestView/components/CustomerRequestSubheader/components/AnswersPanel/CustomersRequestSubheaderAnswersPanel.vue'
import CustomerRequestSubheaderTabSwitcher from '@/views/Customer/CustomerRequestView/components/CustomerRequestSubheader/components/TabSwitcher/CustomerRequestSubheaderTabSwitcher.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import CustomerRequestDataTabDrawerSuppliers from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/CustomerRequestDataTabDrawerSuppliers.vue'

export default defineComponent({
  name: 'CustomerRequestSubheader',
  components: {
    CustomerRequestDataTabDrawerSuppliers,
    CustomerRequestSubheaderTabSwitcher,
    CustomersRequestSubheaderAnswersPanel,
  },
  setup() {
    const { currentTab, setCurrentTab, setIsOpenDrawerSuppliers } =
      useCustomerRequestStore()
    const store = useCustomerRequestStore()
    const tabs = [
      { name: 'Данные запроса', value: 1 },
      { name: 'Ответы по компаниям', value: 2 },
      { name: 'Ответы по товарам', value: 3 },
    ]
    const activeTab = reactive(currentTab)

    const handleAllSuppliers = () => {
      setIsOpenDrawerSuppliers(true)
    }

    // Вычисляемые свойства
    const refused = computed(
      () =>
        store.currentCustomerRequest?.recipient_stats_in_price_request?.refused,
    )
    const answered = computed(
      () =>
        store.currentCustomerRequest?.recipient_stats_in_price_request
          ?.answered,
    )
    const total = computed(
      () =>
        store.currentCustomerRequest?.recipient_stats_in_price_request?.total,
    )
    const pending = computed(
      () =>
        store.currentCustomerRequest?.recipient_stats_in_price_request?.pending,
    )

    return {
      tabs,
      activeTab,
      setCurrentTab,
      setIsOpenDrawerSuppliers,
      handleAllSuppliers,
      refused,
      answered,
      total,
      pending,
    }
  },
})
</script>

<template>
  <div class="customer-request-subheader">
    <div class="customer-request-subheader_left">
      <CustomerRequestSubheaderTabSwitcher
        :items="tabs"
        :active="activeTab"
        @select="
          (item) => {
            Object.assign(activeTab, item)
          }
        "
      />
    </div>
    <div class="customer-request-subheader_right">
      <div class="customer-request-subheader_right__answers">
        <CustomersRequestSubheaderAnswersPanel
          :total-success="total"
          :success-answered="answered"
          :total-warning="total"
          :warning-answered="pending"
          :total-error="total"
          :error-answered="refused"
        />
        <CustomerRequestDataTabDrawerSuppliers />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.customer-request-subheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: #f0f0f0 1px solid;
  height: 56px;
  padding: 0 20px;

  &_left {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }
  &_right {
    &__answers {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
    }
  }
}
</style>
