<script setup lang="ts">
import { Collapse, CollapsePanel, Flex } from 'ant-design-vue'
import { IAddress, IOrder } from '@/types/interfaces'
import AddressItem from '@/components/Customer/AboutOrderItems/AddressItem.vue'
import { computed, onMounted, ref } from 'vue'
import PhoneItem from '@/components/Customer/AboutOrderItems/PhoneItem.vue'
import DeliveryItem from '@/components/Customer/AboutOrderItems/DeliveryItem.vue'
import { getSortedArrayByCreatedAt } from '@/helpers/scripts'
import PaymentItem from '@/components/Customer/AboutOrderItems/PaymentItem.vue'
import ServicesItem from '@/components/Customer/AboutOrderItems/ServicesItem.vue'
import CommentItem from '@/components/Customer/AboutOrderItems/CommentItem.vue'
import { NewGeneralOrderStatus } from '@/types/enums'

interface IProps {
  order: IOrder
  deliveryAddress: IAddress
}
const props = defineProps<IProps>()
const active_key = ref<string[]>([])
const isDelivery = computed(() => {
  if (!props.order) return true
  return props.order?.delivery_method === 'Доставка фермером'
})

const previousOrder = computed(() => {
  return getSortedArrayByCreatedAt(props.order.history)[0]?.old_entity
})

const collapse_panel = () => {
  if (
    !previousOrder.value ||
    props.order.status !== NewGeneralOrderStatus.SupplierModifiedOrder
  )
    return
  if (is_order_was_edited(props.order, previousOrder.value)) {
    active_key.value = ['1']
  }
}

const is_order_was_edited = (order1: IOrder | null, order2: IOrder | null) => {
  return (
    order1?.delivery_payment !== order2?.delivery_payment ||
    order1?.payment_type !== order2?.payment_type ||
    order1?.payment_delay_days !== order2?.payment_delay_days ||
    order1?.is_payment_delayed !== order2?.is_payment_delayed ||
    order1?.need_unload !== order2?.need_unload ||
    order1?.palletizing_type !== order2?.palletizing_type ||
    order1?.comment !== order2?.comment
  )
}

onMounted(() => {
  collapse_panel()
})
</script>

<template>
  <Collapse v-model:activeKey="active_key">
    <CollapsePanel class="collapse" key="1" header="Подробнее">
      <Flex :gap="16" vertical>
        <AddressItem
          :address="isDelivery ? deliveryAddress : order?.shipping_address"
        />
        <PhoneItem :phone="order.farmer?.contact_number" />
        <DeliveryItem :order="order" :history="previousOrder" />
        <PaymentItem :order="order" :history="previousOrder" />
        <ServicesItem :order="order" :history="previousOrder" />
        <CommentItem :order="order" :history="previousOrder" />
      </Flex>
    </CollapsePanel>
  </Collapse>
</template>

<style scoped lang="scss"></style>
