<script lang="ts" setup>
import Empty from '@/assets/img/Empty.svg'

import {
  Button,
  Flex,
  TypographyTitle as TpTitle,
  TypographyText as TpText,
} from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

interface IProps {
  title: string
  description: string
  isShowButton: boolean
  buttonText: string
  buttonType: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])
</script>

<template>
  <Flex class="empty-view" :gap="24" align="center" vertical>
    <img class="empty-view__img" :src="Empty" alt="Empty" loading="lazy" />
    <Flex align="center" vertical>
      <TpTitle :level="4">{{ props.title }}</TpTitle>
      <TpText class="empty-view__desc" type="secondary"
        >{{ props.description }}
      </TpText>
    </Flex>
    <Button
      v-if="props.isShowButton"
      size="large"
      @click="emit('onBtnClick')"
      :type="buttonType"
    >
      <template #icon v-if="buttonType == 'default'">
        <InfoCircleOutlined />
      </template>
      {{ props.buttonText }}
    </Button>
  </Flex>
</template>

<style lang="scss" scoped>
.empty-view {
  &__desc {
    max-width: 224px;
    text-align: center;
  }
}
</style>
