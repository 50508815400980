import {
  GeneralOrderStatus,
  NewGeneralOrderStatus,
  OrderStatus,
} from '@/types/enums'
import { computed, ref } from 'vue'

export const useStatus = () => {
  const status = ref('')
  const setStatus = (newStatus: string) => (status.value = newStatus)
  const getStatusClass = computed(() => {
    let cssClass = ''
    let text = status.value

    switch (status.value) {
      // Новые статусы
      case NewGeneralOrderStatus.New:
        cssClass = 'new'
        break
      case NewGeneralOrderStatus.SupplierContractSigned:
      case NewGeneralOrderStatus.OrderInDelivery:
      case NewGeneralOrderStatus.OrderPicking:
      case NewGeneralOrderStatus.ContractUnderSigning:
      case NewGeneralOrderStatus.SupplierModifiedOrder:
        cssClass = 'blue'
        break
      case NewGeneralOrderStatus.OrderCompleted:
        cssClass = 'green'
        break
      case NewGeneralOrderStatus.PartialOrderCompleted:
        cssClass = 'orange'
        break
      case NewGeneralOrderStatus.OrderNotCompleted:
      case NewGeneralOrderStatus.SupplierCancelledOrder:
      case NewGeneralOrderStatus.BuyerCancelledOrder:
        cssClass = 'red'
        break

      //
      case GeneralOrderStatus.OnClearance:
        cssClass = 'default'
        break
      case GeneralOrderStatus.Changed:
        cssClass = 'gray'
        break
      case GeneralOrderStatus.OnDelivery:
        cssClass = 'blue'
        break
      case GeneralOrderStatus.Signed:
        text = 'Договор подписан поставщиком'
        cssClass = 'black'
        break
      case OrderStatus.Signed:
        cssClass = 'black'
        text = 'Договор подписан поставщиком'
        break
      case GeneralOrderStatus.Done:
        cssClass = 'green'
        break
      case GeneralOrderStatus.DonePartly:
        cssClass = 'yellow'
        break
      case GeneralOrderStatus.NotDone:
      case GeneralOrderStatus.Cancelled:
        cssClass = 'red'
        break
    }

    return { cssClass, text }
  })

  return { status, setStatus, getStatusClass }
}
