<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref} from "vue";

const fakeCheckboxes = [
  'Оформляем и принимаем заказы',
  'Предоставляем маршруты поставок и логистику',
  'У нас - проверенные контрагенты',
  'Полное документальное сопровождение',
    'Встроенная система торгов',
    'Интеграция с 1С поставщика',
    // 'Удобное мобильное приложение'
]

const zoomLevel = computed(() => window.devicePixelRatio * 100);
const blockCheckboxesClass = ref(zoomLevel.value >= 125 ? 'uniting-block__checkboxes_zoom-150' : 'uniting-block__checkboxes')

const calcClasses = () => {
  console.log('class',  zoomLevel.value >= 125 ? 'uniting-block__checkboxes_zoom-150' : 'uniting-block__checkboxes')
  blockCheckboxesClass.value = zoomLevel.value >= 125 ? 'uniting-block__checkboxes_zoom-150' : 'uniting-block__checkboxes'
}

onMounted(() => {
  window.addEventListener('resize', calcClasses);
})

onUnmounted(() => {
  window.removeEventListener('resize', calcClasses);
})
</script>

<template>
  <div
    :class="blockCheckboxesClass"
    v-for="(checkboxText, index) in fakeCheckboxes"
    :key="index"
  >
    <div class="checkboxes__checkbox">
      <img :alt="`checkbox-${index}`" src="../assets/fakeCheckbox.svg" />
      <div class="checkbox__text">{{ checkboxText }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/style/variables';

.uniting-block__checkboxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; // 20px
  &:last-child {
    margin-bottom: 0;
  }

  &_zoom-150 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; // 20px
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkboxes__checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.checkbox {
  &__text {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: variables.$fake-checkbox-text-color;
  }
}
</style>
