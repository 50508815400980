import { IFarmer, IFarmerProduct } from '@/types/interfaces'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { IAddress } from '@/types/interfaces'

export enum EDeliveryMethod {
  'Самовывоз' = 'Самовывоз',
  'Доставка фермером' = 'Доставка',
}

type TDeliveryPayment = 'excluded' | 'included'
type TPaymentType = 'cash' | 'non_cash'
type TFarmer = Omit<IFarmer, 'address'> & Required<Pick<IFarmer, 'address'>>
type TCreatedOrder = {
  orderId: number | null
  error: string | null
}
type TCreateOrderFunc = (
  orderData: IRegistrationOrderData,
) => Promise<TCreatedOrder>

export interface IOrderPriceOption {
  vat_percent: number
  price: number
  isSpecialPrice: boolean
}
export interface IProductItem {
  quantity: number
  farmer_product: IFarmerProduct
  chosen_price_option: IOrderPriceOption
  cartItemId?: number // заполнено если был переход с корзины
}

export interface IOrderFarmer extends TFarmer {
  product_items: IProductItem[]
  is_partner: boolean
}

export interface IOrderTerms {
  delivery_method: keyof typeof EDeliveryMethod
  delivery_date: string
  is_payment_delayed: boolean
  need_unload: boolean
  payment_type: TPaymentType
  address?: IAddress
  delivery_payment: TDeliveryPayment
  palletizing_type?: 'standard' | 'euro'
  payment_delay_days?: number
  comment?: string
}
export interface IRegistrationOrderData extends IOrderTerms {
  farmers: IOrderFarmer[]
}
interface IRegistrationOrderStore {
  isCreatingOrder: boolean
  orderData: IRegistrationOrderData
  isBlockedDeliveryDateBefore: string
  createOrderFunc: TCreateOrderFunc
  createdOrder: TCreatedOrder
}

const emptyOrderTerms: Omit<IOrderTerms, 'delivery_date'> = {
  delivery_method: 'Самовывоз',
  is_payment_delayed: false,
  need_unload: false,
  payment_type: 'cash',
  delivery_payment: 'included',
  // delivery_date: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  // delivery_date рассчитывается отдельно и приходит в метод fillOrderData
}

export const registrationOrderStore = defineStore('registrationOrder', {
  state: (): IRegistrationOrderStore => {
    return {
      isCreatingOrder: false,
      orderData: {} as IRegistrationOrderData,
      isBlockedDeliveryDateBefore: dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
      async createOrderFunc() {
        return {
          orderId: null,
          error: null,
        }
      },
      createdOrder: {
        error: null,
        orderId: null,
      },
    }
  },
  persist: true,

  actions: {
    fillOrderData(
      createOrderFunc: TCreateOrderFunc,
      farmers: IOrderFarmer[],
      delivery_date: string,
      orderTerms?: Omit<IOrderTerms, 'delivery_date'>,
    ) {
      const terms = orderTerms || emptyOrderTerms
      this.orderData = {
        farmers: farmers,
        delivery_date,
        ...terms,
      }
      this.isBlockedDeliveryDateBefore = delivery_date
      this.createOrderFunc = createOrderFunc

      this.createdOrder = {
        error: null,
        orderId: null,
      }
    },
    async createOrder() {
      this.isCreatingOrder = true
      const { error, orderId } = await this.createOrderFunc(this.orderData)
      this.createdOrder = { error, orderId }
      this.isCreatingOrder = false
    },
  },
})
