<script setup lang="ts">


interface Props {
  items: string[]
  title?: string
  active: string
}

const props = defineProps<Props>()

const emit = defineEmits(['close', 'set-active'])
</script>

<template>
  <div class="badges" v-if="props.items.length">
    <div class="badges__title" v-if="props.title">{{ props.title }}</div>
    <div class="badges__container">
      <div
        class="badges__item"
        v-for="item in [props.items[0]]"
        :key="item"
        :class="{ active: active?.toLowerCase() === item?.toLowerCase() }"
        @click="() => emit('set-active', item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.badges {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  width: 500px;

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $gray-600;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__item {
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
    cursor: pointer;

    &.active {
      background: $default-accent;
      color: white;
      border-color: $default-accent;
    }
  }
}
</style>
