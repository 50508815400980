const checkQuantity = (a: { quantity: number }, b: { quantity: number }) => {
  if (a?.quantity > 0 && b?.quantity <= 0) {
    return -1
  }
  if (a?.quantity <= 0 && b?.quantity > 0) {
    return 1
  }
  return 0
}

const checkPartner = (a: { partner: any[] }, b: { partner: any[] }) => {
  if (a?.partner?.length > b?.partner?.length) {
    return -1
  }
  if (a?.partner?.length < b?.partner?.length) {
    return 1
  }
  return 0
}

const checkCanDeliver = (
  a: { farmer: { can_deliver: boolean } },
  b: { farmer: { can_deliver: boolean } },
) => {
  if (a?.farmer?.can_deliver && !b?.farmer?.can_deliver) {
    return -1
  }
  if (!a?.farmer?.can_deliver && b?.farmer?.can_deliver) {
    return 1
  }
  return 0
}

export const sortFarmerProducts = (farmerProducts: any[]) => {
  const newFarmerProducts = farmerProducts
    .sort((el, el2) => checkCanDeliver(el, el2))
    .sort((el, el2) => checkQuantity(el, el2))
    .sort((el, el2) => checkPartner(el, el2))
  return newFarmerProducts
}