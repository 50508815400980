import { useCookies } from 'vue3-cookies'
import axiosInstance from '@/axios'
import {RequestManager} from "@/helpers/RequestManager";
import {APIModels} from "@/types/enums";
import {EgalMethods} from "@/types/RequestManagerEnum";

export interface IChanges {
  farmer_product_id: number
  c_product_id: number
  c_measurement_unit_name: string
  c_name: string
  ratio: number
  c_remnant: number
}

export interface ICompanyCreateData {
  id: number
  login: string
  password: string
  password_confirmation: string
}

interface IGetOffersData {
  company_id: number
  page: number
  per_page: number
  with?: any[]
}

const { cookies } = useCookies()

class OneCAPIModel {
  async companyCreate(data: ICompanyCreateData): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${import.meta.env.VITE_1C_API}/Company/create`, data, {
          headers: {
            Authorization: cookies.get('onec'),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async checkStatus(company_id: string): Promise<any> {
    // TODO: Доработать RequestManager
    try {
      const response = await axiosInstance.get(
        `${import.meta.env.VITE_1C_API}/Status/getItem/${company_id}`,
        {
          headers: {
            Authorization: cookies.get('onec'),
          },
        },
      )
      return response?.data?.data?.status || null
    } catch (error) {
      return error
    }
  }

  async getOffers(data: IGetOffersData): Promise<any> {
    // TODO: Доработать RequestManager
    // Добавить фильтр
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${import.meta.env.VITE_1C_API}/Offer/getItems`, data, {
          headers: {
            Authorization: cookies.get('onec'),
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCFarmerProducts(): Promise<any> {
    const userStr = localStorage.getItem('user')
    const user = userStr ? JSON.parse(userStr) : null
    const companyId = user?.company_id
    return RequestManager.request({
      model: APIModels.CFarmerProduct,
      method: EgalMethods.GetItems,
      data: {
        filter: [['farmer_id', 'eq', companyId]],
        withs: ['farmer_product'],
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getCFarmerProduct(id: number): Promise<any> {
    // TODO: Доработать RequestManager
    const userStr = localStorage.getItem('user')
    const user = userStr ? JSON.parse(userStr) : null
    const companyId = user?.company_id
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${import.meta.env.VITE_BASE_URL}/core/CFarmerProduct/getItem/${id}`,
          {
            filters: [['farmer_id', 'eq', companyId]],
            withs: ['farmer_product'],
          },
          {
            headers: {
              Authorization: cookies.get('core'),
            },
          },
        )
        .then((response) => {
          resolve(response?.data?.action_result?.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async createCFarmerProduct(data: IChanges): Promise<any> {
    return RequestManager.request({
      model: APIModels.CFarmerProduct,
      method: EgalMethods.create,
      data: { attributes: data},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateCFarmerProduct(data: IChanges, id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.CFarmerProduct,
      method: EgalMethods.update,
      data: { attributes: {...data, id }},
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const OneCAPI = new OneCAPIModel()
export { OneCAPI }
