<script setup lang="ts">
import { UnwrapRef } from 'vue'
import SmallFarmerCardWithList from '@/components/Farmer/SmallFarmerCardWithList.vue'
import { List, ListItem, Row, Col, Button } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import EmptyView from '@/views/EmptyView.vue'
import { fontSize14, secondaryBlueButton } from '@/assets/EgalStyles/EButton'
interface Props {
  formState: UnwrapRef<any[]>
}
const props = defineProps<Props>()
const emit = defineEmits(['deleteFarmer', 'handleSelectFarmerModal'])
const height100 = { height: '100%' }
const width100 = { width: '100%' }
const deleteFarmer = (uid: string) => {
  emit('deleteFarmer', uid)
}
</script>

<template>
  <div class="request-farmers" :style="height100">
    <List :data-source="props.formState" v-if="props.formState.length">
      <template #renderItem="{ item }">
        <ListItem>
          <Row :style="width100">
            <Col :flex="'auto'">
              <SmallFarmerCardWithList :farmer="item" />
            </Col>
            <Col>
              <Button
                type="text"
                @click="deleteFarmer(item.id)"
                class="delete-product-btn"
              >
                <template #icon>
                  <CloseOutlined :style="{ color: 'red' }" />
                </template>
              </Button>
            </Col>
          </Row>
        </ListItem>
      </template>
    </List>
    <EmptyView
      v-else
      title="Список поставщиков пуст"
      subtitle="Добавьте поставщиков, чтобы создать запрос"
    >
      <template #action>
        <EButton
          :style-config="{
            ...secondaryBlueButton,
            fontSize14,
            padding: '11px 16px',
          }"
          @click="emit('handleSelectFarmerModal', true)"
        >
          Добавить поставщиков
        </EButton>
      </template>
    </EmptyView>
  </div>
</template>

<style scoped lang="scss">
.request-farmers {
  overflow-y: scroll;
  :deep(.ant-list-item) {
    padding: 24px 32px !important;
    &:hover {
      background-color: #0000000a;
    }
  }
  .delete-product-btn {
    &:hover {
      background-color: #fff0f0;
    }
    &:active {
      background-color: #ffd5d5;
    }
  }
}
</style>
