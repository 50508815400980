<script setup lang="ts">
import { IRecipient } from '@/types/interfaces'
import { computed, onMounted, ref } from 'vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import {
  Flex,
  Button,
  Tooltip,
  TypographyText as TpText,
  TypographyTitle as TpTitle,
} from 'ant-design-vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import RecipientStatus from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/components/RecipientStatus/RecipientStatus.vue'
import DeliveryButton from '@/components/DeliveryButton.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { useRouter } from 'vue-router'
import { usePartnersStore } from '@/store/usePartnersStore'

interface Props {
  recipient: IRecipient
  isBigName?: boolean
  needSecondIcon?: boolean
  needStatusTag?: boolean
}

const props = defineProps<Props>()

const router = useRouter()

const partnersStore = usePartnersStore()
const partners = computed(() => {
  return partnersStore.partners
})

const { setIsOpenDrawerOneSupplier } = useCustomerRequestStore()
const {
  currentCustomerRequest: { status, product_requests },
  farmerProducts,
} = useCustomerRequestStore()

const { currentTab } = useCustomerRequestStore()

const handleClick = () => {
  setIsOpenDrawerOneSupplier(true, props.recipient.id)
}

const fullName = computed(
  () => `${props.recipient.farmer.legal_form} ${props.recipient.farmer.name}`,
)

const goCompositionOrder = () => {
  router.push(router.currentRoute.value.path + `/${String(props.recipient.id)}`)
}

const isAllProductsOutStock = computed(() => {
  if (!farmerProducts) return false
  const recipientFarmerProducts = farmerProducts.filter(
    (farmerProduct) => farmerProduct.farmer_id === props.recipient.farmer_id,
  )

  const isNotEnoughProducts = product_requests?.every((productRequest) => {
    const farmerProduct = recipientFarmerProducts.find(
      (farmerProduct) => farmerProduct.product_id === productRequest.product_id,
    )
    if (farmerProduct) {
      return +farmerProduct?.quantity < +productRequest.customer_needed_volume
    }
    return true
  })

  const isCloseStatus = status === 'closed'
  return isCloseStatus && isNotEnoughProducts
})

const isPartner = computed(() => {
  return partners.value?.some((el: { company_id: number }) => {
    return Number(props.recipient?.farmer_id) === el?.company_id
  })
})
</script>

<template>
  <Flex vertical :gap="4">
    <Flex justify="space-between" align="center">
      <Flex gap="4" align="center">
        <RecipientStatus :status="props.recipient.answer_status" />
        <PartnershipTooltip v-if="isPartner" />
        <TpTitle
          v-if="props.isBigName"
          :level="4"
          :style="{ marginBottom: '0' }"
          >{{ fullName }}
        </TpTitle>
        <TpText v-else>{{ fullName }}</TpText>
      </Flex>
      <Flex justify="flex-end">
        <Flex gap="24">
          <DeliveryButton
            v-if="currentTab.value === 2"
            :handle-click="handleClick"
            name="Доставка и оплата"
            :answer_status="props.recipient.answer_status"
            :needStatusTag="props.needStatusTag || false"
          />
          <Tooltip
            :overlayInnerStyle="{
              width: '285px',
              position: 'relative',
              right: '30px'
            }"
            placement="bottomRight"
          >
            <template #title v-if="isAllProductsOutStock">
              <span>У поставщика закончились все товары</span>
            </template>
            <Button
              v-if="currentTab.value === 2 && props.recipient.answer_status !== 'refused'"
              :type="'primary'"
              :disabled="status !== 'closed' || isAllProductsOutStock"
              :onClick="goCompositionOrder"
              >Оформить заказ
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
    <Flex :gap="8">
      <EnvironmentOutlined
        v-if="props.needSecondIcon"
        :style="{
          color: 'rgba(0, 0, 0, 0.45)',
          position: 'relative',
          top: '3px',
        }"
      />
      <TpText type="secondary">
        {{ props.recipient.farmer.legal_address }}
      </TpText>
    </Flex>
    <SimpleRate :company_rating="props.recipient.farmer.company_rating" />
    <Flex
      class="delivery-btn-wrapper"
      justify="flex-start"
      v-if="currentTab.value === 3"
    >
      <DeliveryButton
        :handle-click="handleClick"
        name="Доставка и оплата"
        :answer_status="props.recipient.answer_status"
        :needStatusTag="props.needStatusTag || false"
      />
    </Flex>
  </Flex>
</template>

<style lang="scss" scoped>
:deep(.ant-btn .ant-btn-link) {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-left: 0 !important;
}

.padding-top {
  padding-top: 6px;
}

.delivery-btn-wrapper {
  margin-top: 10px;
  padding-left: 0;
}
</style>
