<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import EgalCalendar from '@/components/Egal/Calendar/Calendar.vue'
import TabImageSwitcher from '@/components/Switcher/TabImageSwitcher.vue'
import { secondaryBlueButton } from '@/assets/EgalStyles/EButton'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { ISODateTransformer } from '@/helpers/scripts'
import { IAddress, IGeneralOrder } from '@/types/interfaces'
import { AddressAPI } from '@/api/AddressAPI'
import { OrderAPI } from '@/api/OrderAPI'
import CustomInfoTooltip from '@/components/CustomInfoTooltip/CustomInfoTooltip.vue'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import BtnLoaderIcon from '@/components/Icons/BtnLoaderIcon.vue'
import {
  Modal,
  RadioGroup,
  Radio,
  Space,
  Checkbox,
  InputNumber,
  Button,
  Form,
  FormItem,
  Textarea,
  Flex,
} from 'ant-design-vue'
import {
  CalendarOutlined,
  EnvironmentOutlined,
  WalletOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
import {
  IOrderFarmer,
  registrationOrderStore,
} from '@/store/registrationOrderStore'
import dayjs from 'dayjs'
import FarmerExpandableCard from '@/views/Customer/CreateOrder/components/FarmerExpandableCard/FarmerExpandableCard.vue'
import TpTitle from '@/components/Title/TpTitle.vue'
import { useRouter } from 'vue-router'
import { useOrderStore } from '@/store/orderStore'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'

const iconStyle = { color: '#00000073', fontSize: '19px' }
const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
})

const deliveryPaymentDictionary = {
  excluded: 'Доставка оплачивается отдельно',
  included: 'Оплата доставки включена в стоимость',
}

const router = useRouter()
const modalStore = useModalStore()
const cartStore = useOrderStore()
const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const currentCompanyId = Number(user?.company_id)

const formRef = ref()
defineExpose({
  formRef,
})

const setPickupDeliveryMethod = () => {
  orderData.delivery_method = 'Самовывоз'
}

const rules = computed(() => {
  return {
    payment_delay_days: [
      {
        min: 1,
        message: 'Не меньше 1 и не больше 90 дней',
        type: 'number',
        max: 90,
      },
      {
        required: orderData.is_payment_delayed,
        message: 'Не меньше 1 и не больше 90 дней',
        type: 'number',
      },
    ],
    comment: [
      {
        max: 500,
        message: 'Максимум 500 символов',
      },
    ],
  }
})

const formattedDate = computed(() => {
  return ISODateTransformer(orderData.delivery_date ?? '')
})

/**
 * Задание даты когда нужно получить заказ
 * @param newDate - дата в формате 2022-10-11T00:00:00.000Z
 */
const setDate = (newDate: string) => {
  calendarModal.value = false
  orderData.delivery_date = newDate.split('T')[0]
}

const isOrderConditionsCollapsed = ref(false)
const orderStore = registrationOrderStore()
const orderData = orderStore.orderData

const getDeliveryAddress = async () => {
  if (orderData.address) return
  const lastOrder: IGeneralOrder = await OrderAPI.getOrders(
    1,
    [
      ['customer_id', 'eq', currentCompanyId],
      'AND',
      ['delivery_method', 'coi', 'Доставка фермером'],
    ],
    ['generalOrder.delivery_address'],
    1,
    [['created_at', 'desc']],
  ).then((res) => {
    if (res[0]) {
      return res[0].general_order
    }
    return null
  })
  if (lastOrder && lastOrder?.delivery_address) {
    // TODO: переписать на поиск по списку адресов юзера из useUserAuthStore 
    orderData.address = await AddressAPI.getAddresses(
      String(currentCompanyId),
      'customer',
      [['street', 'coi', lastOrder.delivery_address.street]],
    ).then((res) => {
      return res.items[0]
    })
    if (!orderData.address) {
      orderData.address = userAddresses.value[0]
    }
  } else {
    orderData.address = userAddresses.value[0]
  }
}
// TODO: переписать на получение адресов из useUserAuthStore
const getAllAddresses = async () => {
  await AddressAPI.getAddresses(String(currentCompanyId), 'customer').then(
    (res) => {
      userAddresses.value = res.items
    },
  )
}
const calendarModal = ref(false)
const isOpenDeleteFarmerModal = ref(false)
const userAddresses = ref<IAddress[]>([])
const selectedDeliveryPayment = ref<'included' | 'excluded'>(
  orderData.delivery_payment,
)
const deliveryPaymentModal = ref(false)

const deletingFarmer = ref<IOrderFarmer>()
const setDeletingFarmer = (farmer: IOrderFarmer) => {
  deletingFarmer.value = { ...farmer }
  isOpenDeleteFarmerModal.value = true
}

const deleteFarmer = async () => {
  if (deletingFarmer.value?.product_items[0].cartItemId) {
    await cartStore.deleteManyCartItemsStore(
      deletingFarmer.value.product_items.map(({ cartItemId }) =>
        String(cartItemId),
      ),
    )
  }

  orderData.farmers = orderData.farmers.filter(
    (farmer) => farmer.id !== deletingFarmer.value?.id,
  )

  isOpenDeleteFarmerModal.value = false
}
const toggleDeliveryPaymentModal = () => {
  deliveryPaymentModal.value = !deliveryPaymentModal.value
}

const saveDeliveryPayment = () => {
  orderData.delivery_payment = selectedDeliveryPayment.value
  toggleDeliveryPaymentModal()
}

const selectedAddress = ref<null | IAddress>(orderData.address || null)
const selectAddress = (id: number) => {
  const item = userAddresses.value.find((address) => address.id === id)
  selectedAddress.value = item || null
  orderData.address = item
}

const handleStandartPalletizing = (e: any) => {
  orderData.palletizing_type = e.target.checked ? 'standard' : undefined
}
const handleEuroPalletizing = (e: any) => {
  orderData.palletizing_type = e.target.checked ? 'euro' : 'standard'
}

const handleIsPaymentDelayed = (e: any) => {
  if (!e.target.checked) {
    orderData.payment_delay_days = undefined
    formRef.value.validate()
    formRef.value.resetFields(['payment_delay_days'])
  }
}

const handleDeliveryMethod = (value: { name: 'Самовывоз' | 'Доставка' }) => {
  orderData.delivery_method =
    value.name === 'Самовывоз' ? 'Самовывоз' : 'Доставка фермером'
}

const calcPrepareDays = () => {
  return (
    (new Date(orderStore.isBlockedDeliveryDateBefore).getTime() - Date.now()) /
    (1000 * 60 * 60 * 24)
  )
}

const deliveryItems = computed(() => {
  if (orderData.farmers.every((farmer) => !farmer.can_deliver)) {
    setPickupDeliveryMethod()
    return [
      {
        name: 'Самовывоз',
        image: 'img/catalog/delivery-false.svg',
      },
      {
        name: 'У поставщиков в заказе нет доставки',
      },
    ]
  }
  return [
    {
      name: 'Самовывоз',
      image: 'img/catalog/delivery-false.svg',
    },
    {
      name: 'Доставка',
      image: 'img/catalog/delivery-true.svg',
    },
  ]
})

const isAllFarmersHaveDelivery = computed(() =>
  orderData.farmers.every((farmer) => farmer.can_deliver),
)

const goBack = () => {
  router.go(-1)
}

watch(deliveryPaymentModal, (newValue) => {
  if (!newValue) {
    selectedDeliveryPayment.value = orderData.delivery_payment || 'included'
  }
})

watch(
  () => orderData.address,
  (newAddress) => {
    if (newAddress) {
      selectedAddress.value = newAddress
    }
  },
)

onMounted(async () => {
  await getAllAddresses()
  await getDeliveryAddress()
})

const handleOpenSelectAddress = () => {
  modalStore.openModal(ModalKeys.SelectAddress, {
    selectedAddress: selectedAddress?.value?.id || null,
    addressList: userAddresses.value,
    onSave: selectAddress,
    redirectWarningMessage:
      'Сбросить текущие настройки заказа и перейти в профиль компании для внесения нового адреса.',
  })
}
</script>

<template>
  <div class="second-step">
    <Flex v-if="true" :gap="24" vertical>
      <Flex :gap="16" align="center">
        <Button :onClick="goBack">
          <template #icon>
            <LeftOutlined />
          </template>
        </Button>
        <TpTitle :level="2"> Оформление заказа</TpTitle>
      </Flex>
      <div class="step-card">
        <div class="step-card-header">
          <span> Условия доставки </span>
          <Button
            @click="isOrderConditionsCollapsed = !isOrderConditionsCollapsed"
          >
            <template #icon>
              <DownOutlined
                v-if="isOrderConditionsCollapsed"
                :style="{ fontSize: '16px' }"
              />
              <UpOutlined v-else :style="{ fontSize: '16px' }" />
            </template>
          </Button>
        </div>
        <div class="step-card-body" v-show="!isOrderConditionsCollapsed">
          <div
            class="step-card-footer"
            v-if="
              !isAllFarmersHaveDelivery &&
              orderData.delivery_method === 'Доставка фермером'
            "
          >
            <CustomInfoTooltip
              description="Вы можете осуществить самовывоз от этих поставщиков или исключить их товары из заказа"
              title="Не у всех поставщиков есть доставка"
              type="ORANGE"
            >
              <template #icon>
                <DangerIcon fill="#EF874D" height="32" width="32" />
              </template>
            </CustomInfoTooltip>
          </div>

          <TabImageSwitcher
            :items="deliveryItems"
            :active="
              orderData.delivery_method === 'Самовывоз'
                ? 'Самовывоз'
                : 'Доставка'
            "
            @select="handleDeliveryMethod"
          />
          <div class="info-block">
            <div
              v-if="
                orderData.delivery_method === 'Доставка фермером' &&
                orderData.address
              "
              class="row"
            >
              <div class="row-title">
                <EnvironmentOutlined :style="iconStyle" />
                {{ orderData.address?.street }}
              </div>
              <EButton
                :style-config="{
                  ...secondaryBlueButton,
                  fontSize: '12px',
                  lineHeight: '15px',
                  fontWeight: '500',
                  padding: '4px 12px',
                  borderRadius: '4px',
                  hover: {
                    backgroundColor: '#A6C8FA',
                  },
                  active: {
                    backgroundColor: '#A6C8FA',
                  },
                }"
                @click="handleOpenSelectAddress"
                ><span class="btn-text">Изменить</span>
              </EButton>
            </div>

            <div class="row calendar-row">
              <div class="row-title">
                <CalendarOutlined :style="iconStyle" />
                {{ formattedDate }}
              </div>
              <EButton
                :style-config="{
                  ...secondaryBlueButton,
                  fontSize: '12px',
                  lineHeight: '15px',
                  fontWeight: '500',
                  padding: '5px 12px',
                  borderRadius: '4px',
                  hover: {
                    backgroundColor: '#A6C8FA',
                  },
                  active: {
                    backgroundColor: '#A6C8FA',
                  },
                }"
                @click="calendarModal = true"
                ><span class="btn-text">Изменить</span>
              </EButton>
            </div>

            <div
              class="row"
              v-if="orderData.delivery_method === 'Доставка фермером'"
            >
              <div class="row-title">
                <WalletOutlined :style="iconStyle" />
                <span>{{
                  orderData.delivery_payment
                    ? deliveryPaymentDictionary[orderData.delivery_payment]
                    : 'Выберите способ оплаты'
                }}</span>
              </div>
              <EButton
                :style-config="{
                  ...secondaryBlueButton,
                  fontSize: '12px',
                  lineHeight: '15px',
                  fontWeight: '500',
                  padding: '4px 12px',
                  borderRadius: '4px',
                  hover: {
                    backgroundColor: '#A6C8FA',
                  },
                  active: {
                    backgroundColor: '#A6C8FA',
                  },
                }"
                @click="toggleDeliveryPaymentModal"
                ><span class="btn-text">Изменить</span>
              </EButton>
            </div>
            <Form ref="formRef" :model="orderData" :rules="rules">
              <div class="additional-order-data">
                <Space :size="8" direction="vertical">
                  <h5>Условия оплаты</h5>
                  <RadioGroup v-model:value="orderData.payment_type">
                    <Radio :style="radioStyle" value="non_cash"
                      >Безналичный расчёт
                    </Radio>
                    <Radio :style="radioStyle" value="cash"
                      >Наличный расчёт
                    </Radio>
                  </RadioGroup>
                  <Checkbox
                    v-model:checked="orderData.is_payment_delayed"
                    @change="handleIsPaymentDelayed"
                  >
                    Нужна отсрочка по оплате
                  </Checkbox>
                  <FormItem name="payment_delay_days">
                    <InputNumber
                      v-model:value="orderData.payment_delay_days"
                      :controls="false"
                      placeholder="Укажите кол-во дней отсрочки"
                      :disabled="!orderData.is_payment_delayed"
                      class="payment-delay-days-input"
                      :precision="0"
                    />
                  </FormItem>
                </Space>
                <Space :size="8" direction="vertical">
                  <h5>Дополнительные услуги</h5>
                  <Checkbox
                    :checked="
                      orderData.palletizing_type === 'standard' ||
                      orderData.palletizing_type === 'euro'
                    "
                    @change="handleStandartPalletizing"
                  >
                    Паллетирование
                  </Checkbox>
                  <Checkbox
                    :checked="orderData.palletizing_type === 'euro'"
                    @change="handleEuroPalletizing"
                    :disabled="!orderData.palletizing_type"
                    class="children-checkbox"
                  >
                    Европалеты
                  </Checkbox>
                  <Checkbox v-model:checked="orderData.need_unload">
                    Разгрузка товара поставщиком
                  </Checkbox>
                </Space>
              </div>
              <FormItem name="comment">
                <Textarea
                  v-model:value="orderData.comment"
                  class="comment"
                  placeholder="Комментарий к заказу, необязательно"
                  :rows="4"
                />
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div class="step-body">
        <div class="step-body-list">
          <FarmerExpandableCard
            v-for="(farmer, index) in orderStore.orderData.farmers"
            :isShowDeleteBtn="orderData.farmers.length > 1"
            :orderFarmer="farmer"
            @setDeletingFarmer="setDeletingFarmer"
            v-bind:key="index"
            :deliveryMethod="orderData.delivery_method"
          />
        </div>
      </div>
    </Flex>
    <div v-else>
      <div class="loader-wrapper">
        <BtnLoaderIcon :stroke="'#0066FF'" />
      </div>
    </div>
  </div>

  <Teleport to="body">
    <ModalWindow
      :show="calendarModal"
      :show-close-icon="false"
      class="calendar-modal"
      @close="calendarModal = false"
    >
      <template #header>
        <div class="calendar-modal-header">Выберите дату получения</div>
        <p class="calendar-modal-description">
          Получить заказ возможно нe раньше
          {{ dayjs(orderStore.isBlockedDeliveryDateBefore).format('DD.MM') }}
        </p>
      </template>
      <template #body>
        <EgalCalendar
          :data="{
            locale: 'ru-RU',
            needToClose: false,
            date: {
              date_from: orderData.delivery_date,
            },
            inputData: {
              size: 'md',
              showFilled: false,
              clearable: false,
              placeholder: 'Когда нужно получить заказ?',
            },
            showInput: false,
          }"
          :max-prepare-time="calcPrepareDays()"
          @update:dateValue="(value) => setDate(value[0])"
        />
      </template>
    </ModalWindow>
  </Teleport>
  <Modal
    centered
    title="Исключить поставщика из заказа?"
    okText="Исключить"
    @ok="deleteFarmer"
    cancelText="Закрыть"
    v-model:open="isOpenDeleteFarmerModal"
    width="440px"
  >
    <p v-if="deletingFarmer">
      {{ deletingFarmer.legal_form }} {{ deletingFarmer.name }}
    </p>
  </Modal>

  <Modal
    centered
    title="Оплата доставки"
    okText="Сохранить"
    @ok="saveDeliveryPayment"
    cancelText="Закрыть"
    v-model:open="deliveryPaymentModal"
    width="520px"
  >
    <RadioGroup v-model:value="selectedDeliveryPayment">
      <Radio :style="radioStyle" value="included">Включена в стоимость</Radio>
      <Radio :style="radioStyle" value="excluded">Оплачивается отдельно</Radio>
    </RadioGroup>
  </Modal>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.second-step {
  flex-grow: 1;
  max-width: 66%;
  height: 100%;
  margin-bottom: 20px;
  overflow: auto;
  .comment {
    max-height: 160px;

    textarea {
      width: 100%;
      height: 64px;
      box-sizing: border-box;
      border: none;
    }
  }

  .step {
    &-card {
      box-shadow:
        0 0 1px rgba(12, 26, 75, 0.1),
        0 10px 16px rgba(20, 37, 63, 0.06);
      background: #ffffff;
      border-radius: 20px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: $gray-800;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .row {
          border-bottom: 1px solid $gray-300;
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: start;
          padding: 16px 0;
          column-gap: 10px;
          text-overflow: ellipsis;

          &-title {
            display: grid;
            grid-template-columns: 24px auto;
            column-gap: 4px;
            align-items: start;
            line-height: 24px;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .additional-order-data {
          margin-top: 24px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 32px;

          h5 {
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #000;
          }

          .children-checkbox {
            margin-left: 24px;
          }

          .payment-delay-days-input {
            width: min(100%, 312px);
          }
        }
      }

      &-footer {
      }
    }
    &-body {
      &-list {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
    &__can-delivery-message {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 20px;
      background: #f7fafc;
      border-radius: 8px;
      height: 88px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #4a5568;
      }
    }
  }
}

:deep(.textarea--md) {
  textarea {
    resize: none;
    width: calc(100% - 32px);
  }
}

:deep(.switcher) {
  .switcher__tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    &.active {
      background: $default-secondary;
      color: $default-accent;
    }
  }
}

// :deep(#calendar-input--date) {
//   color: $gray-800;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;

//   &::placeholder {
//     color: $gray-600;
//     font-weight: 400;
//   }
// }

.calendar-modal {
  :deep(.modal-container) {
    width: 440px;
    padding: 0;
    border-radius: 16px;
  }
  :deep(.wrapper) {
    padding: 24px;
  }
  :deep(.calendar) {
    position: relative;
    margin-top: 24px;
    width: 280px;
  }

  :deep(.modal-header) {
    justify-content: center;
  }

  :deep(.calendar-wrapper) {
    display: flex;
    justify-content: center;
  }

  &-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
  }

  &-description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $gray-700;
  }
}

@media (max-width: 1200px) {
  .second-step {
    &__body {
      .row {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }
}

.loader-wrapper {
  transform: scale(1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
</style>
