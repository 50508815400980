<script lang="ts" setup>
import { Button } from 'ant-design-vue'
// import { useRouter } from 'vue-router'
import { registerSteps } from "@/views/HomeUnauthorized/components/RegisterNow/constants";
import {computed, onMounted, onUnmounted, ref} from "vue";

// const router = useRouter()

const goToRegisterPage = async () => {
  window.location.href = '/register'
  // await router.push('/register')
}

// TODO: Добавить инструкции
// const goToCustomerInstruction = async () => {
//   console.log('goToCustomerInstruction')
//   // await router.push('/customer-instruction')
// }
//
// const goToFarmerInstruction = async () => {
//   console.log('goToFarmerInstruction')
//   // await router.push('/farmer-instruction')
// }

const zoomLevel = computed(() => window.devicePixelRatio * 100);
const titleClass = ref(zoomLevel.value >= 125 ? 'register-now__title_zoom-150' : 'register-now__title')
const buttonsClass = ref(zoomLevel.value >= 125 ? 'register-now__buttons_zoom-150' : 'register-now__buttons')
const stepClass = ref(zoomLevel.value >= 125 ? 'register-now__step_zoom-150' : 'register-now__step')
const stepTitleClass = ref(zoomLevel.value >= 125 ? 'step__title_zoom-150' : 'step__title')
const stepDescClass = ref(zoomLevel.value >= 125 ? 'step__description_zoom-150' : 'step__description')
const registerNowBlockClass = ref(zoomLevel.value >= 125 ? 'register-now_zoom-150' : 'register-now')

const calcClasses = () => {
  titleClass.value = zoomLevel.value >= 125 ? 'register-now__title_zoom-150' : 'register-now__title'
  buttonsClass.value = zoomLevel.value >= 125 ? 'register-now__buttons_zoom-150' : 'register-now__buttons'
  stepClass.value = zoomLevel.value >= 125 ? 'register-now__step_zoom-150' : 'register-now__step'
  stepTitleClass.value = zoomLevel.value >= 125 ? 'step__title_zoom-150' : 'step__title'
  stepDescClass.value = zoomLevel.value >= 125 ? 'step__description_zoom-150' : 'step__description'
  registerNowBlockClass.value = zoomLevel.value >= 125 ? 'register-now_zoom-150' : 'register-now'
}

onMounted(() => {
  window.addEventListener('resize', calcClasses);
})

onUnmounted(() => {
  window.removeEventListener('resize', calcClasses);
})
</script>

<template>
  <div :class="registerNowBlockClass" id="#about-block">
    <div :class="titleClass">
      {{ 'Зарегистрируйтесь\nи начните работать прямо сейчас' }}
    </div>
    <div class="register-now__steps">
      <div
        :class="stepClass"
        v-for="(item, index) of registerSteps"
        :key="index"
      >
        <div :class="stepTitleClass">{{ item?.title }}</div>
        <div :class="stepDescClass">{{ item?.description }}</div>
      </div>
    </div>
    <div :class="buttonsClass">
      <Button
        type="primary"
        class="button__try"
        @click="goToRegisterPage"
        size="large"
      >
        Попробовать прямо сейчас
      </Button>
      <div class="buttons__right">
        <!--        TODO: Добавить инструкции-->
        <!--        <Button-->
        <!--          type="primary"-->
        <!--          class="button__instruction"-->
        <!--          @click="goToCustomerInstruction"-->
        <!--        >-->
        <!--          Инструкция покупателя-->
        <!--        </Button>-->
        <!--        <Button-->
        <!--          type="primary"-->
        <!--          class="button__instruction"-->
        <!--          @click="goToFarmerInstruction"-->
        <!--        >-->
        <!--          Инструкция поставщика-->
        <!--        </Button>-->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/style/variables';

.buttons__right {
  display: flex;
  gap: 10px;
}

.button {
  &__try {
    background-color: variables.$btn-try-bg;
    box-shadow: variables.$btn-try-box-shadow;
    color: variables.$btn-try-text-color;
    transition: 0.35s ease-in-out;
    &:hover {
      cursor: pointer;
      color: white;
      background: variables.$btn-header-exit-bg-active;
      border: 1px solid variables.$btn-header-exit-bg-active;
      box-shadow: 0 2px 0 0 rgba(15, 158, 121, 0.2);
    }
  }
  &__instruction {
    background: transparent;
    box-shadow: variables.$btn-try-box-shadow;
    color: variables.$btn-instruction-text-color;
    border: 1px solid variables.$btn-instruction-border-color;
  }
}
.background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
}

.register-now {
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('@/assets/img/register-now-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  &_zoom-150 {
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url('@/assets/img/register-now-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    background-position-y: 43px;
    background-position-x: -5px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 7%;
    margin-left: 7%;
    margin-right: 14%;
    &_zoom-150 {
      display: flex;
      justify-content: space-between;
      margin-top: 1%;
      margin-left: 6%;
      margin-right: 14%;
    }
  }
  &__title {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$register-now-title-color;
    white-space: pre-wrap;
    margin-top: 7%;
    margin-left: 6.5%;
    margin-bottom: 8.5%;

    &_zoom-150 {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 27px;
      font-weight: 500;
      line-height: 35px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgb(255, 255, 255);
      white-space: pre-wrap;
      margin-top: 10%;
      margin-left: 6%;
      margin-bottom: 9%;
    }
  }
  &__steps {
    display: flex;
    justify-content: space-between;
  }
  &__step {
    &:nth-child(1) {
      margin-left: 7vw;
    }
    &:nth-child(2) {
      margin-left: 1vw;
    }
    &:nth-child(3) {
      margin-left: 1vw;
    }
    &_zoom-150 {
      &:nth-child(1) {
        margin-left: 6vw;
      }
      &:nth-child(3) {
        margin-right: 2vw;
        -moz-margin-end: 11.3vw;
      }
    }
  }
}

.step {
  &__title {
    font-family:
      Nurito sans,
      sans-serif !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$register-now-card-title;
    white-space: pre-wrap;
    margin-bottom: 10px;
    &_zoom-150 {
      font-family:
          Nurito sans,
          sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$register-now-card-title;
      white-space: pre-wrap;
      margin-bottom: 10px;
    }
  }
  &__description {
    font-family:
      Nurito sans,
      sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: variables.$register-now-card-desc;
    white-space: pre-wrap;
    &_zoom-150 {
      font-family:
          Nurito sans,
          sans-serif !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: variables.$register-now-card-desc;
      white-space: pre-wrap;
    }
  }
}
</style>
