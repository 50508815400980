<script lang="ts" setup>
import {
  IAuction,
  IAuctionParticipant,
  IAuctionProductAnswer,
  IAuctionProductRequest,
  IFarmerProduct,
} from '@/types/interfaces'
import { computed, ref, onMounted, h } from 'vue'
import {
  Button,
  Divider,
  Flex,
  Modal,
  notification,
  Space,
  Table,
  TypographyText as TpText,
} from 'ant-design-vue'
import {
  DownOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  UpOutlined,
} from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import CustomerNameWithAddress from '@/components/Customer/CustomerNameWithAddress.vue'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import StartPriceCell from '@/views/Auctions/components/AuctionTable/StartPriceCell.vue'
import { priceWithoutVat, priceWithVat, roundNumber } from '@/helpers/scripts'
import { PartnersAPI } from '@/api/PartnersAPI'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { AuctionParticipantAPI } from '@/api/AuctionParticipant'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'

interface IAdvancedParticipant extends IAuctionParticipant {
  bets_count: number
  bets_amount: number
}

interface IAdvancedProductAnswer extends IAuctionProductAnswer {
  measurement_unit_id: string
  volume: string
  remaining_shelf_life: number
  farmer_product_name: string
  is_sample_requested: boolean
  requested_samples_number: string | number | null
  farmer_product: IFarmerProduct | undefined
}

interface IProps {
  auctionData: IAuction | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['showCustomerDetails', 'setSelectedProduct'])
const partners = ref([])
const openAuctionModal = ref(false)
const participantId = ref()
const expandedRowKeys = ref<string[]>([])

const modalStore = useModalStore()

const getAdditionalFields = (participantId: number) => {
  return props.auctionData?.auction_product_requests?.reduce(
    (accum, currentRequest) => {
      currentRequest?.auction_product_answers.forEach((currentAnswer) => {
        if (currentAnswer.auction_participant_id === participantId) {
          accum.bets_count += 1
          accum.bets_amount += +(
            currentAnswer.price_per_unit * Number(currentRequest.volume)
          )
        }
      })

      return accum
    },
    { bets_count: 0, bets_amount: 0 },
  )
}

const getRowKey = (_: IAdvancedParticipant, index: number) => String(index)

const getParticipantAnswers = (
  participantId: number,
): IAdvancedProductAnswer[] => {
  const answers: IAdvancedProductAnswer[] = []

  const getAdvancedFields = ({
    farmer_product_name,
    volume,
    remaining_shelf_life,
    is_sample_requested,
    requested_samples_number,
    start_price,
    vat,
  }: IAuctionProductRequest) => ({
    farmer_product_name,
    volume,
    remaining_shelf_life,
    is_sample_requested,
    requested_samples_number,
    start_price,
    vat,
  })
  props.auctionData?.auction_product_requests?.forEach((currentRequest) => {
    currentRequest?.auction_product_answers.forEach((currentAnswer) => {
      if (currentAnswer.auction_participant_id === participantId) {
        answers.push({
          ...currentAnswer,
          ...getAdvancedFields(currentRequest),
          farmer_product: currentRequest?.farmer_product,
          measurement_unit_id:
            currentRequest?.farmer_product?.measurement_unit_id ?? '',
        })
      }
    })
  })

  return answers
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const advancedParticipants: IAdvancedParticipant[] = computed(() => {
  return (
    props.auctionData?.merged_recipients
      ?.map((participant) => ({
        ...participant,
        ...getAdditionalFields(participant.id),
      }))
      .filter((item) => item.bets_count) ?? []
  )
})

const columns = [
  {
    title: 'Рейтинг',
    key: 'rate',
    width: '7%',
  },
  {
    title: 'Покупатель',
    key: 'customer',
    width: '48%',
  },
  {
    title: 'Количество ставок на позиции',
    key: 'bets_count',
    width: '14%',
  },
  {
    title: 'Сумма закупки',
    key: 'bets_amount',
  },
]

const nestedColumns = [
  {
    title: 'Товары',
    key: 'products',
    width: '46%',
  },
  {
    title: 'Стартовая цена',
    key: 'start_price',
    width: '5%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },

  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const showAuctionModal = (id: number) => {
  openAuctionModal.value = true
  props.auctionData?.merged_recipients?.forEach((participant) => {
    if (participant.customer_id === id) {
      participantId.value = participant.id
    }
  })
}

const sendAuctionInvite = () => {
  AuctionParticipantAPI.createAuctionOffer(participantId.value)
    .then(() => {
      notification.info({
        message: `Приглашение отправлено`,
        top: '80px',
        icon: h(InfoCircleFilled, {
          style: { color: 'rgba(22, 119, 255, 1)' },
        }),
      })
      openAuctionModal.value = false
    })
    .catch((e) => {
      if (e.status === 422) {
        const errorData = e.response.data.action_error?.data
        const deletedItems = Object.entries(errorData).reduce(
          (acc, [id, errors]: [string, string[]]) => {
            if (errors.includes('deleted')) {
              acc.push(id)
            }
            return acc
          },
          [] as string[],
        )
        const troubledItems =
          props.auctionData?.auction_product_requests?.filter((item) =>
            deletedItems.includes(String(item.farmer_product_id)),
          )

        if (deletedItems.length) {
          openAuctionModal.value = false
          const productNames = troubledItems.map((item) => {
            return item.farmer_product_name
          }).join(', ')
          modalStore.openModal(ModalKeys.HandleError, {
            title: 'Не удалось отправить предложение',
            message:
              `В закупке присутствуют/ет товар(ы)
              <b>${productNames}</b>,
              которые вы удалили. Покупатель не сможет оформить такой заказ.`,
          })
        }
      }
    })
}

onMounted(async () => {
  await getMyPartners()
})
// TODO: refactor with fillMeasurementUnitIdCommon
</script>

<template>
  <Modal
    title="Отправить приглашение оформить заказ?"
    class="modal"
    closable
    centered
    :open="openAuctionModal"
    okText="Отправить"
    @ok="sendAuctionInvite"
    cancelText="Закрыть"
    @cancel="openAuctionModal = false"
  >
    <div class="description">
      Мы уведомим покупателя о том, что он может <br />
      оформить заказ на условиях торга на продажу
    </div>
  </Modal>
  <Table
    :columns="columns"
    :expandedRowKeys="expandedRowKeys"
    :data-source="advancedParticipants"
    :row-key="(record) => String(record.id)"
    :pagination="false"
    :showExpandColumn="false"
    class="edged-border-table"
    v-if="advancedParticipants.length"
  >
    <template #expandedRowRender="{ record }">
      <Table
        :columns="nestedColumns"
        :data-source="getParticipantAnswers(record.id)"
        :pagination="false"
        bordered
        class="nested-table"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'products'">
            <Flex :gap="8">
              <ProductWithProperties
                :volume="record?.volume"
                :remaining_shelf_life="record.remaining_shelf_life"
                :measurement_unit_id="record.measurement_unit_id"
                :name="record.farmer_product_name"
                :is_sample_needed="record.is_sample_requested"
                :needed_samples_number="record.requested_samples_number"
                :isProductNameLink="true"
                @onClickName="emit('setSelectedProduct', record.farmer_product)"
              />
            </Flex>
          </template>
          <template v-if="column.key === 'start_price'">
            <StartPriceCell
              :price="roundNumber(record.start_price)"
              :vat="record.vat"
              :measurementUnit="
                record.measurement_unit_id ? record.measurement_unit_id : ''
              "
            />
          </template>
          <template v-if="column.key === 'price_with_vat'">
            <CellDifData
              :new-data="record.price_per_unit"
              :prev-data="record.price_per_unit_first"
              :additional-info="
                record.measurement_unit_id
                  ? `₽/${record.measurement_unit_id}`
                  : '₽'
              "
            />
          </template>
          <template v-if="column.key === 'price_without_vat'">
            <CellDifData
              :new-data="
                roundNumber(priceWithoutVat(record.price_per_unit, record.vat))
              "
              :prev-data="
                roundNumber(
                  priceWithoutVat(record.price_per_unit_first, record.vat),
                )
              "
              :additional-info="
                record.measurement_unit_id
                  ? `₽/${record.measurement_unit_id}`
                  : '₽'
              "
            />
          </template>

          <template v-if="column.key === 'amount'">
            <Flex justify="space-between">
              <CellDifData
                :new-data="record.price_per_unit * record.volume"
                :prev-data="record.price_per_unit_first * record.volume"
                additional-info="₽"
              />
            </Flex>
          </template>
        </template>
      </Table>
    </template>
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'rate'">
        <Flex justify="center" align="center">{{ index + 1 }}</Flex>
      </template>
      <template v-if="column.key === 'customer'">
        <Space direction="vertical" :size="8">
          <CustomerNameWithAddress
            :customer="record.customer"
            :is-partner="
              partners.find((el: any) => el.company_id === record.customer?.id)
            "
          />

          <Button
            type="link"
            class="additional-btn"
            v-if="record.comment"
            @click="emit('showCustomerDetails', record)"
          >
            <template #icon><InfoCircleOutlined /></template>
            Дополнительно
          </Button>
        </Space>
      </template>
      <template v-if="column.key === 'bets_count'">
        {{ record.bets_count }}
      </template>
      <template v-if="column.key === 'bets_amount'">
        <Flex justify="space-between" align="center">
          <TpText strong>{{ `${roundNumber(record.bets_amount)} ₽` }}</TpText>
          <div>
            <Button
              v-if="auctionData?.status == 'closed'"
              type="primary"
              @click="showAuctionModal(record.customer_id)"
            >
              Закажите у нас
            </Button>
            <Button type="link" @click="changeExpandedRow(record.id)">
              <template #icon>
                <DownOutlined
                  v-if="!expandedRowKeys.includes(String(record.id))"
                />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </div>
        </Flex>
      </template>
    </template>
  </Table>
  <div class="empty-view-wrapper" v-else>
    <EmptyView
      :is-show-button="false"
      title="Ставок пока что нет"
      description="Они будут отображаться здесь"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}

.nested-table {
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

.empty-view-wrapper {
  padding: 72px 0;
}

.description {
  margin-top: 16px;
  margin-bottom: 8px;
}
</style>
