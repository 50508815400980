<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Divider,
  Input,
  Row,
  Select,
  SelectProps,
  Space,
  TypographyText as TgText,
} from 'ant-design-vue'
import { Option } from 'ant-design-vue/es/vc-util/Children/toArray'
import { IParameter } from '@/types/interfaces'
import {
  capitilizeFirstLetter,
  splitProductParameters,
} from '@/helpers/scripts'
import { useCookies } from 'vue3-cookies'

interface Props {
  isOpenFilters: boolean
  categoryOptions: SelectProps['options']
  selectedCategory: Record<string, any> | undefined
  categoryChildrens: any[]
  filterParameters: Record<string, any>
  filterRange: Record<string, Record<string, string>>
  childCount: number
}

const { cookies } = useCookies()
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => umt && chat)

const width100 = { width: '100%' }

const binaryToBool = {
  true: 'да',
  false: 'нет',
}

const props = defineProps<Props>()

const emit = defineEmits([
  'filter',
  'closeFilters',
  'setCategory',
  'setCategoryChildren',
  'setFilterParameters',
  'dropFilters',
  'handleRangeFilter',
  'applyFilters',
])

const filtersBlock = ref()

// Объем
const volume = reactive({
  left: '',
  right: '',
  filterField: 'quantity',
})

// Цена за единицу товара
const price = reactive({
  left: '',
  right: '',
  filterField: 'price',
})

const closeFilters = () => {
  emit('closeFilters')
}

const handleCategory = (value: string, option: Option) => {
  emit('setCategory', value, option)
}

const handleCategoryChildren = (value: string, option: any, index: number) => {
  emit('setCategoryChildren', value, option, index)
}

const isShowParameters = computed(() => {
  return (
    props.categoryChildrens.at(-1)?.selectedEntity
      ?.all_children_product_categories?.length === 0
  )
})

const isProductWithoutParameters = computed(() => {
  const lastCategory = props.categoryChildrens.at(-1)?.selectedEntity
  if (!lastCategory?.id) {
    return false
  }
  return (
    !lastCategory.all_children_product_categories.length &&
    !lastCategory.parameters.length
  )
})

watch(
  () => props.selectedCategory,
  () => {
    const newFilterParameters = {
      single: [],
      binary: [],
      multiple: [],
      string: [],
    }

    if (isShowParameters.value) {
      props.categoryChildrens
        .at(-1)
        ?.selectedEntity.parameters.forEach((item: IParameter) => {
          newFilterParameters[item.type].push({
            ...item,
            options: splitProductParameters(item).map((param) => ({
              label: capitilizeFirstLetter(param),
              value: param,
            })),
            label: capitilizeFirstLetter(item.name),
            value: null,
            disabled: false,
          })
        })
    }
    console.log('newFilterParameters', newFilterParameters)
    emit('setFilterParameters', newFilterParameters)
  },
  {
    deep: true,
  },
)

const handleFilterParameters = (
  type: string,
  index: number | string,
  value: string,
  item: IParameter,
) => {
  const filterParametersCopy = { ...props.filterParameters }
  filterParametersCopy[type][index].value = value
  handleRelatedParameters(filterParametersCopy, item, value)
  emit('setFilterParameters', filterParametersCopy)
}

const handleRelatedParameters = (
  filterParameters: Record<any, IParameter[]>,
  goalParameter: IParameter,
  value: boolean | string | string[],
) => {
  Object.values(filterParameters)
    .flat()
    .forEach((parameter: IParameter) => {
      if (parameter.parameter_id === goalParameter.id) {
        switch (goalParameter.type) {
          case 'binary':
            if (
              binaryToBool[value as string] !==
              parameter.related_rule?.replace(/^\/\^|\$\/[gm]/g, '')
            ) {
              parameter.disabled = true
            } else {
              parameter.disabled = false
            }
            break
          case 'single':
            if (
              !parameter.related_rule
                ?.replace(/^\/\^|\$\/[gm]/g, '')
                .split('|')
                .includes(value as string)
            ) {
              parameter.disabled = true
            } else {
              parameter.disabled = false
            }
            break
          case 'multiple':
            break
          default:
            break
        }
      }
    })
}

const filterMeasurementUnit = computed(() => {
  const lastCategory = props.categoryChildrens.at(-1)?.selectedEntity
  const parentUnit = lastCategory?.measurement_unit_id ?? ''
  let childUnit = ''
  Object.values(props.filterParameters)
    .flat()
    .forEach((parameter: IParameter) => {
      if (parameter.parameter_id && !parameter.disabled) {
        childUnit = parameter.measurement_unit_id
      } else if (parameter.parameter_id && parameter.disabled) {
        childUnit = Object.values(props.filterParameters)
          .flat()
          .find(
            (newParam) => newParam.id === parameter.parameter_id,
          )?.measurement_unit_id
      }
    })

  return childUnit || parentUnit
})

const handleRangeFilter = (value: string, type, direction) => {
  emit('handleRangeFilter', value, type, direction)
}
const dropFilters = () => {
  emit('closeFilters')
  emit('dropFilters')
}

const applyFilters = () => {
  emit('closeFilters')
  emit('applyFilters')
}

function clickOutside(event: MouseEvent) {
  const target = event.target as HTMLElement;
  if (
      target?.className &&
    !filtersBlock.value?.contains(event.target) &&
    !target?.className?.includes?.('btn__filters') &&
    !target?.className?.includes?.('ant-select-item') &&
    !target?.className?.includes?.('uniting-block-info-search__button') &&
    !target?.nodeName?.includes?.('svg')
  ) {
    emit('closeFilters')
  }
}

onMounted(() => {
  document.addEventListener('click', clickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', clickOutside)
})
</script>

<template>
  <div ref="filtersBlock" class="filters" v-if="isOpenFilters">
    <div class="filters-box">
      <div class="filters-box__header">
        <Row :gutter="[20, 20]">
          <Col :span="24">
            <span class="filters-box__header__title"> Раздел каталога </span>
          </Col>
          <Col :span="12">
            <Select
              :placeholder="isAuth ? 'Выберите категорию' : 'Выберите'"
              :options="categoryOptions"
              @change="handleCategory"
              :style="width100"
              :value="selectedCategory?.id"
              size="large"
              :dropdownMenuStyle="{
                fontSize: '16px',
                backgroundColor: 'black',
              }"
              allowClear
            />
          </Col>
          <Col
            v-for="(categoryChildren, index) in categoryChildrens"
            :key="categoryChildren.id"
            :span="12"
          >
            <Select
              v-show="childCount > 1"
              placeholder="Выберите"
              :options="categoryChildren.options"
              size="large"
              :value="categoryChildrens[index].selectedEntity.id"
              :style="width100"
              @change="
                (value, option) => handleCategoryChildren(value, option, index)
              "
              allowClear
            >
            </Select>
          </Col>
        </Row>
      </div>
      <div
        class="filters-box__body"
        v-if="isShowParameters || isProductWithoutParameters"
      >
        <Row :gutter="[20, 20]">
          <Col :span="24">
            <span class="filters-box__body__title"> Параметры товара </span>
          </Col>
          <Col :span="24" v-if="props.filterParameters.binary.length">
            <Space direction="vertical" :size="8">
              <Checkbox
                v-for="(item, index) in props.filterParameters.binary"
                :key="item.name"
                :checked="item.value"
                @change="
                  (value) =>
                    handleFilterParameters(
                      item.type,
                      index,
                      value.target.checked,
                      item,
                    )
                "
                :disabled="item.disabled"
              >
                {{ item.label }}
              </Checkbox>
            </Space>
          </Col>
          <Col :span="24" v-if="props.filterParameters.single.length">
            <Row :gutter="[20, 20]">
              <Col
                :span="12"
                v-for="(item, index) in props.filterParameters.single"
                :key="item.name"
              >
                <Space direction="vertical" :size="8" :style="width100">
                  <TgText>
                    {{ item.label }}
                  </TgText>
                  <Select
                    :value="item.value"
                    size="large"
                    :style="width100"
                    placeholder="Выберите"
                    :options="item.options"
                    @change="
                      (value) =>
                        handleFilterParameters(item.type, index, value, item)
                    "
                    :disabled="item.disabled"
                    allowClear
                  />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col :span="24" v-if="props.filterParameters.multiple.length">
            <Row :gutter="[20, 20]">
              <Col
                :span="24"
                v-for="(item, index) in props.filterParameters.multiple"
                :key="item.name"
              >
                <Space :size="8" direction="vertical">
                  <TgText>{{ item.label }}</TgText>
                  <CheckboxGroup
                    :value="item.value"
                    :style="width100"
                    :disabled="item.disabled"
                    @change="
                      (value) =>
                        handleFilterParameters(item.type, index, value, item)
                    "
                  >
                    <Space :size="8" direction="vertical">
                      <Checkbox
                        v-for="option in item.options"
                        :key="option.value"
                        :value="option.value"
                        >{{ option.label }}</Checkbox
                      >
                    </Space>
                  </CheckboxGroup>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col :span="12">
            <Space :size="4" :style="width100" direction="vertical">
              <TgText> Количество в наличии </TgText>
              <Row :wrap="false" align="middle" :gutter="4">
                <Col :flex="1">
                  <Input
                    prefix="от"
                    placeholder="0"
                    :suffix="filterMeasurementUnit"
                    :value="filterRange.quantity.ge"
                    @change="
                      (value) =>
                        handleRangeFilter(value.target.value, 'quantity', 'ge')
                    "
                  />
                </Col>
                <Col :flex="'12px'"><Divider /></Col>
                <Col :flex="1">
                  <Input
                    prefix="до"
                    placeholder="1 000 000"
                    :suffix="filterMeasurementUnit"
                    :value="filterRange.quantity.le"
                    @change="
                      (value) =>
                        handleRangeFilter(value.target.value, 'quantity', 'le')
                    "
                  />
                </Col>
              </Row>
            </Space>
          </Col>
          <Col :span="12">
            <Space :size="4" :style="width100" direction="vertical">
              <TgText>Стоимость за единицу</TgText>
              <Row :wrap="false" align="middle" :gutter="4">
                <Col :flex="1">
                  <Input
                    prefix="от"
                    placeholder="0"
                    :suffix="`₽/${filterMeasurementUnit}`"
                    :value="filterRange.price.ge"
                    @change="
                      (value) =>
                        handleRangeFilter(value.target.value, 'price', 'ge')
                    "
                  />
                </Col>
                <Col :flex="'12px'"><Divider /></Col>
                <Col :flex="1">
                  <Input
                    prefix="до"
                    placeholder="1 000 000"
                    :suffix="`₽/${filterMeasurementUnit}`"
                    :value="filterRange.price.le"
                    @change="
                      (value) =>
                        handleRangeFilter(value.target.value, 'price', 'le')
                    "
                  />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
      <div class="filters-box__footer">
        <Row :gutter="20" justify="end">
          <Col>
            <Button danger type="text" @click="dropFilters">
              Сбросить фильтры
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              @click="applyFilters"
              :disabled="!selectedCategory?.id"
              :class="{ btn__filters: !isAuth }"
            >
              Применить фильтры
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.ant-select-selection-search {
  color: black !important;
}

.filters {
  margin-top: 0.5rem;
  position: absolute;
  left: 5%;
  z-index: 3;
  width: 600px;
  overflow-y: auto;
  box-sizing: border-box;
  height: auto;
  max-height: 500px;
  border-radius: 12px;
  box-shadow:
    0 9px 28px 8px #0000000d,
    0 3px 6px -4px #0000001f,
    0 6px 16px 0 #00000014;
  background-color: #ffffff;
  &-box {
    position: relative;
    &__header {
      padding: 24px;
      &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #000000e0;
      }
    }

    &__body {
      border-top: 1px solid #0000000f;
      padding: 24px;
      &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #000000e0;
      }
    }
    &__footer {
      position: sticky;
      padding: 24px;
      box-sizing: border-box;
      bottom: 0;
      width: 100%;
      background-color: #ffffff;
      border-top: 1px solid #0000000f;
      border-radius: 0 0 12px 12px;
    }
  }
}

.btn__filters {
  background-color: #0f9e79;
  &:hover {
    background-color: rgb(0, 89, 74);
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
</style>
