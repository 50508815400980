<script setup lang="ts">
import { ICompanyView, IUser } from '@/types/interfaces'
import { Table, Tag, Pagination, Flex } from 'ant-design-vue'
import { onMounted, reactive, ref, h } from 'vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { TokenAPI } from '@/api/TokenAPI'
import AntEmpty from '@/components/Empty/AntEmpty.vue'

interface IProps {
  company: ICompanyView
}

const props = defineProps<IProps>()
const invites: IUser[] = reactive([])
const page = ref(1)
const per_page = ref(10)
const total_count = ref(0)
const initial_fetch = ref(false)
const is_loading = ref(false)

const columns = [
  {
    title: 'Почта',
    dataIndex: 'email',
    width: '40%',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: '60%',
  },
]

const get_invites = async () => {
  if (!props.company.company_type) {
    initial_fetch.value = false
    return
  }
  const filters: (string | number)[][] = [
    ['invite_token.company_id', 'eq', props.company.company_id],
      'AND',
    ['invite_token.type', 'eq', props.company.company_type],
      'AND',
    ['invite_token.status', 'eq', 'not_used'],
  ]
  await TokenAPI.getItems(page.value, per_page.value, filters).then((res) => {
    total_count.value = res.total_count
    invites.splice(0, invites.length, ...res.items)
  }).finally(() => {
    initial_fetch.value = false
    is_loading.value = false
  })
}

const change_page = async (new_page: number, pageSize: number) => {
  page.value = new_page
  per_page.value = pageSize
  is_loading.value = true
  await get_invites()
}

const invite_status_component = (alive_until: string) => {
  const serverDate = new Date(alive_until + ' UTC')
  const currentDate = new Date()
  const offset = currentDate.getTimezoneOffset() * 60000 * -1
  const adjustedLocalTime = new Date(currentDate.getTime() - offset)
  if (adjustedLocalTime < serverDate) {
    return h(Tag, { color: 'orange' }, { default: () => 'Ожидает' })
  } else {
    return h(Tag, { color: 'red' }, { default: () => 'Просрочено' })
  }
}

onMounted(async () => {
  initial_fetch.value = true
  await get_invites()
})
</script>

<template>
  <div>
    <Flex vertical gap="24" v-if="!initial_fetch && total_count">
      <Table
        row-key="id"
        :columns="columns"
        :data-source="invites"
        :loading="is_loading"
        :pagination="false"
        @change="change_page"
        class="table"
        :sticky="true"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'status'">
            <component :is="invite_status_component(record.alive_until)" />
          </template>
        </template>
      </Table>
      <Flex gap="8" align="center" v-if="total_count > 10">
        <Pagination
          v-model:current="page"
          v-model:page-size="per_page"
          :total="total_count"
          show-size-changer
          @change="change_page"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}</span>
          </template>
        </Pagination>
        элементов
      </Flex>
    </Flex>
    <template v-if="initial_fetch">
      <FullscreenLoading :type="'secondary'" />
    </template>
    <template v-if="!initial_fetch && !total_count">
      <div class="empty-wrapper">
        <AntEmpty
          title="Список приглашений пуст"
          subtitle="Компания пока что не пригласила ни одного сотрудника"
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.table {
  height: calc(100vh - 145px - 24px - 48px - 32px);
  overflow: auto;
}
:deep(.ant-pagination) {
  width: 100%;
}
:deep(.ant-pagination-options) {
  float: right;
  &::before {
    content: 'Показывать на странице';
    display: inline-block;
    margin-right: 8px;
  }
}
.empty-wrapper {
  height: calc(100vh - 145px - 48px);
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
</style>
