<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import SuggestionTip from '@/components/RegisterSteps/SuggestionTip/SuggestionTip.vue'
import { OrganisationStatus, SuggestionTypes } from '@/types/enums'
import { IAccountUser, ISuggestOrg } from '@/types/interfaces'
import { DadataAPI } from '@/api/Dadata'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import { isNull } from '@/helpers/scripts'

interface IProps {
  userInfo: { type: IAccountUser, default: null, required: false, legal_address: string, tin_status: string },
}

const props = defineProps<IProps>()

const emit = defineEmits(['setData'])
const isVerifying = ref(!!props.userInfo.legal_address)
//TODO: Как tin_status будет string переделать костыль
const activeOrganisation = ref<ISuggestOrg>(
  isVerifying.value
    ? {
        ...props.userInfo,
        status: !props.userInfo.tin_status ? 'LIQUIDATING' : 'ACTIVE',
      }
    : null,
)
const searchValue = ref(
  isVerifying.value
    ? `${activeOrganisation.value.legal_form} ${activeOrganisation.value.name}`
    : '',
)
const isShow = ref(false)
const changeInput = async (el: any) => {
  searchValue.value = el.target.value
  if (el.target.value !== '') {
    await DadataAPI.getSuggests(
      SuggestionTypes.Organisation,
      el.target.value,
    ).then((resp) => {
      tipItems.value = resp
    })
    isShow.value = true
  } else {
    isShow.value = false
  }
}

const debInput = useDebounceFn(changeInput, 1000)
const tipItems = ref<ISuggestOrg[]>([])
const activeStep = ref(isVerifying.value ? 1 : 0)

const selectTip = (tip: ISuggestOrg) => {
  activeOrganisation.value = tip
  searchValue.value = `${tip.legal_form} ${tip.name}`
  isShow.value = false
}
const openTips = () => {
  if (tipItems.value.length > 0)
    setTimeout(() => {
      isShow.value = true
    }, 0)
}
const closeTip = () => {
  isShow.value = false
}

const toStep = () => {
  if (
    OrganisationStatus[activeOrganisation.value.status] ===
      OrganisationStatus.ACTIVE &&
    !activeOrganisation.value?.exists
  ) {
    emit('setData', { tin: activeOrganisation.value.tin })
  }
  if (activeOrganisation.value?.exists) {
    activeStep.value += 0.2
  } else {
    activeStep.value += 0.1
  }
}

watch(tipItems.value, () => {
  if (tipItems.value.length > 0) {
    isShow.value = true
  }
})
</script>

<template>
  <div class="info-step__card" v-if="activeStep === 1">
    <div class="first-step__card-header" v-if="isVerifying">
      Подтвердите свою организацию
    </div>
    <div class="first-step__card-header" v-else>Найдите свою организацию</div>
    <div class="first-step__card-body">
      <div class="suggest-input">
        <input
          type="text"
          class="input"
          placeholder="Введите название организации, адрес, ИНН или ОГРН"
          v-model="searchValue"
          @input="debInput"
          @click="openTips"
        />
        <SuggestionTip
          :isShow="isShow"
          @selectTip="selectTip"
          @closeTip="closeTip"
          :items="tipItems"
          :type="SuggestionTypes.Organisation"
        />
      </div>
      <div class="suggest-body" v-if="activeOrganisation">
        <div class="suggest-body__tooltip">
          <InfoIcon fill="#3385FF" type="outlined" />
          Проверьте и подтвердите данные организации, если это не ваша
          организация — выберите другую
        </div>
        <div class="suggest-body__info">
          <div class="info-column">
            <span class="info-column__title">Наименование организации</span>

            {{
              `${isNull(activeOrganisation.legal_form)} ${isNull(
                activeOrganisation.name,
              )}`
            }}
          </div>
          <div class="info-column">
            <span class="info-column__title">Юридический адрес</span>
            {{ isNull(activeOrganisation.legal_address) }}
          </div>
          <div class="info-row">
            <div class="info-column">
              <span class="info-column__title">ИНН</span>
              {{ isNull(activeOrganisation.tin) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">КПП</span>
              {{ isNull(activeOrganisation.tax_registration_reason) }}
            </div>
            <div
              class="info-column org-status"
              v-if="
                OrganisationStatus[activeOrganisation.status] !==
                OrganisationStatus.ACTIVE
              "
            >
              <span class="info-column__title"> Статус организации </span>
              <p>
                <DangerIcon />
                {{ OrganisationStatus[activeOrganisation.status] }}
              </p>
            </div>
            <div class="info-column" v-else />
            <div class="info-column">
              <span class="info-column__title">ОКПО</span>
              {{ isNull(activeOrganisation.okpo) }}
            </div>
            <div class="info-column">
              <span class="info-column__title">ОГРН</span>
              {{ isNull(activeOrganisation.bin) }}
            </div>
          </div>
        </div>
      </div>
      <div class="suggest-footer">
        <EButton
          :style-config="{
            ...primaryButton,
            disabled: {
              backgroundColor: '#E2E8F0',
              borderColor: 'transparent',
              color: '#A0AEC0',
            },
            fontSize16,
          }"
          class="suggest-footer_btn"
          @click="toStep"
          :data="{
            disabled: !activeOrganisation,
          }"
        >
          Подтвердить данные
        </EButton>
      </div>
    </div>
  </div>

  <div class="info-step__card" v-else-if="activeStep === 0">
    <div class="info-step__card-header">
      По указанному ИНН {{ userInfo.tin }} не найдена организация
    </div>

    <div class="info-step__card-body">
      <p class="info-step-row">
        Пожалуйста, укажите другие данные или обратитесь в тех. поддержку:
        <span class="info-step-item">manager@easy-retail.org</span> или
        <span class="info-step-item">+7 913 871-12-39</span>
      </p>
    </div>

    <div class="info-step__card-footer">
      <EButton
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
        }"
        @click="activeStep++"
      >
        Найти организацию
      </EButton>
    </div>
  </div>

  <div class="info-step__card" v-else-if="activeStep === 1.1">
    <div class="info-step__card-header">Укажите другую организацию</div>

    <div class="info-step__card-body">
      <p class="info-step-row">
        На платформе нельзя работать от лица организации в статусе «{{
          OrganisationStatus[activeOrganisation.status]
        }}».
      </p>
      <p class="info-step-row">
        Пожалуйста, измените организацию на другую или обратитесь в тех.
        поддержку:
        <span class="info-step-item">manager@easy-retail.org</span> или
        <span class="info-step-item">+7 913 871-12-39</span>
      </p>
    </div>

    <div class="info-step__card-footer">
      <EButton
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
        }"
        @click="activeStep = 1"
      >
        Заменить организацию
      </EButton>
    </div>
  </div>

  <div class="info-step__card" v-else-if="activeStep === 1.2">
    <div class="info-step__card-header">Эта компания уже зарегистрирована</div>

    <div class="info-step__card-body">
      <p class="info-step-row">
        Пожалуйста, измените организацию на другую или обратитесь в тех.
        поддержку:
        <span class="info-step-item">manager@easy-retail.org</span> или
        <span class="info-step-item">+7 913 871-12-39</span>
      </p>
    </div>

    <div class="info-step__card-footer">
      <EButton
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
        }"
        @click="activeStep = 1"
      >
        Заменить организацию
      </EButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
.first-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;
  &-header {
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .suggest {
      &-input {
        position: relative;
        .input {
          box-sizing: border-box;
          padding: 14px 20px;
          display: block;
          width: 100%;
          align-items: center;
          border: 1px solid $gray-300;
          border-radius: 8px;
          flex-grow: 1;
          position: relative;
        }
      }
      &-body {
        margin-top: 10px;
        &__tooltip {
          padding: 10px;
          border-radius: 8px;
          background-color: $accent-opacity-1;
          color: $hover-accent;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          column-gap: 5px;
          margin-bottom: 40px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
        }
      }
      &-footer {
        margin-top: 40px;
      }
    }
  }
}

.info {
  &-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 32px;
    row-gap: 20px;
  }
  &-column {
    &.org-status {
      color: $default-danger;
      p {
        display: flex;
        align-items: center;
        column-gap: 9px;
      }
    }
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    row-gap: 3px;
    color: $gray-800;
    &__title {
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
    }
  }
}

.info-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  background-color: white;
  &-header {
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .info-step-row {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
    .info-step-item {
      color: $default-accent;
    }
  }
  &-footer {
    margin-top: 40px;
  }
}
</style>
