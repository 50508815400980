<script setup lang="ts">
import { ICustomer } from '@/types/interfaces'
import { formatPhoneNumber } from '@/helpers/scripts'
import { Button, Flex } from 'ant-design-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import DocumentSignedIcon from '@/components/Icons/DocumentSignedIcon.vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { TypographyText as TgText, Space } from 'ant-design-vue'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import router from '@/router'
import { useRoute } from 'vue-router'

interface IProps {
  customer: ICustomer
  isPartnershipSigned: boolean
}
const route = useRoute()
const props = defineProps<IProps>()
const emit = defineEmits(['onMoreClick'])

const handleGoToPartner = () => {
  router.push({
    path: `/partners/${props.customer.id}`,
    query: { redirectPath: `history/${route.params.id}` },
  })
}
</script>

<template>
  <Flex :gap="8" vertical>
    <Space :size="8">
      <HomeOutlined :style="iconStyle" />
      <TgText strong> Покупатель </TgText>
    </Space>

    <Flex :gap="4" vertical>
      <Space :size="8">
        <DocumentSignedIcon v-if="isPartnershipSigned" :fill="'#11A262'" />
        <TgText>
          {{ customer.legal_form + ' ' + customer.name }}
        </TgText>
      </Space>
      <TgText>
        {{ formatPhoneNumber(customer.contact_number) }}
      </TgText>
      <SimpleRate :company_rating="customer.company_rating" />
      <Button type="link" @click="handleGoToPartner">
        Подробнее о покупателе
      </Button>
    </Flex>
  </Flex>
</template>

<style scoped lang="scss">
:deep(.ant-btn-link) {
  padding-right: 0;
  padding-left: 0;
  text-align: left;
  width: fit-content;
}
</style>
