<script lang="ts" setup>
import delivery from '@/assets/img/delivery.svg'
import { Button, Tag } from 'ant-design-vue'
import {
  RecipientStatus as TRecipientStatus,
  RecipientStatus,
} from '@/types/types'

interface IProps {
  name: string
  handleClick: () => void
  answer_status: RecipientStatus
  needStatusTag: boolean
}

const props = defineProps<IProps>()

const getStatusText = (status: TRecipientStatus) => {
  switch (status) {
    case 'waiting':
      return 'Ещё не дал ответ'
    case 'closed':
      return 'Не дал ответ'
    case 'canceled':
      return 'Не дал ответ'
  }
}
</script>
<template>
  <Button
    v-if="props.answer_status === 'answered'"
    class="delivery-button"
    @click="props.handleClick"
  >
    <img class="delivery-button__img" :src="delivery" alt="icon" loading="lazy" />
    <div class="delivery-button__name">
      {{ name }}
    </div>
  </Button>
  <Tag
    v-else-if="props.answer_status === 'refused' && props.needStatusTag"
    color="error"
    >
    <div class="tag__text">
      Отметил запрос как неинтересующий
    </div>
  </Tag>
  <Tag v-else-if="props.needStatusTag" color="warning">
    <div class="tag__text">
      {{
        getStatusText(props.answer_status)
      }}
    </div>
  </Tag>
</template>

<style lang="scss">

.tag__text {
  display: flex;
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.delivery-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(230, 244, 255, 1);
  border: none;
  transition: all 0.3s ease;
  &__img {
    width: 20px;
    height: 20px;
  }
  &__name {
    color: rgba(22, 119, 255, 1) !important;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  &:hover {
    background: rgba(186, 224, 255, 1);
  }
}
</style>
