<script lang="ts" setup>
import { computed } from 'vue'
import {
  IFarmerProduct,
  IOrderPosition,
  IOrderPositionHistory,
  IProductParameters,
  ISpecialPrice,
} from '@/types/interfaces'
import {
  Button,
  Flex,
  Popover,
  Table,
  Tag,
  TypographyText as TpText,
} from 'ant-design-vue'
import {
  DeleteFilled,
  EditFilled,
  InfoCircleTwoTone,
  UndoOutlined,
} from '@ant-design/icons-vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {
  capitalizeFirstLetter,
  dayEnding,
  getSortedArrayByCreatedAt,
  priceWithVat,
  roundNumber,
} from '@/helpers/scripts'
import { NewGeneralOrderStatus, Roles } from '@/types/enums'
import { useCookies } from 'vue3-cookies'
import QuantityExceededTooltip from '@/components/Tooltip/QuantityExceededTooltip.vue'

interface Props {
  items: IOrderPosition[]
  category: string
  orderStatus: string
  customerId: string
  status: string
  isCanEditOrder: boolean
}
interface IHandledProductParamters extends IProductParameters {
  name: string
}
const props = defineProps<Props>()
const emit = defineEmits([
  'edit-row',
  'delete-restore',
  'check-item',
  'openProductCard',
])

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

const handledItems = computed(() => {
  return props.items.map((item) => {
    const result = {
      ...item,
      handled_product_parameters: getUniqueProductParameters(item),
    }

    if (
      props.status === NewGeneralOrderStatus.SupplierModifiedOrder &&
      item.history![0]?.initiator_type === 'customer'
    ) {
      Object.assign(result, {
        ...get_last_version(item),
        initial_order_position: add_vat_info(item.history![1]?.old_entity),
      })
    } else {
      Object.assign(result, {
        initial_order_position: add_vat_info(get_last_version(item)),
      })
    }
    return {
      ...add_vat_info(result as IOrderPosition),
    }
  })
})

const get_last_version = (order_position: IOrderPosition) => {
  const history = getSortedArrayByCreatedAt(order_position.history)
  const result = history[0]?.old_entity
  if (!result) return null
  return add_vat_info(result)
}

const add_vat_info = (order_position: IOrderPosition | null) => {
  if (!order_position) return null
  return {
    ...order_position,
    unit_price_vat: priceWithVat(
      Number(order_position.unit_price),
      order_position.vat_percent,
    ),
    price_vat: priceWithVat(
      Number(order_position.price),
      order_position.vat_percent,
    ),
  }
}
const getUniqueProductParameters = (item: IOrderPosition) => {
  const productParameters: IHandledProductParamters[] = []
  item.product_parameters.forEach((parameter) => {
    const existingParameter = productParameters.find(
      (param) => param.parameter.name === parameter.parameter.name,
    )
    if (!existingParameter) {
      productParameters.push({
        ...parameter,
        value: capitalizeFirstLetter(parameter.value),
        name: capitalizeFirstLetter(parameter.parameter.name),
      })
    } else {
      existingParameter.value += `, ${capitalizeFirstLetter(parameter.value)}`
    }
  })

  return productParameters
}
const createColumns = () => {
  const columns = [
    {
      title: 'Товар',
      key: 'productName',
    },
  ]

  handledItems.value[0]?.handled_product_parameters.forEach((param) => {
    columns.push({
      title: param.name,
      key: param.parameter.name,
    })
  })

  columns.push(
    {
      title: 'Срок подготовки',
      key: 'preparePeriod',
      width: 116,
    },
    {
      title: 'Объём',
      key: 'volume',
      width: 120,
    },
    {
      title: 'Цена за единицу',
      key: 'price',
      width: 100,
    },
    {
      title: 'Сумма',
      key: 'sum',
      width: 112,
    },
  )
  if (props.isCanEditOrder) {
    columns.push({
      title: '',
      key: 'actions',
      width: 88,
    })
  }
  return columns
}

const columns = createColumns()

const getEditItem = (item: any) => {
  emit('edit-row', item)
}

const onDeleteClick = (productId: number) => {
  emit('delete-restore', productId, true)
}

const onRestoreClick = (productId: number) => {
  emit('delete-restore', productId, false)
}

const handleOpenProductCard = (product: IFarmerProduct, quantity: string) => {
  emit('openProductCard', product, quantity)
}

const handleCustomRow = (record: any) => {
  return {
    class: record.is_canceled ? 'row__deleted' : '',
  }
}

const getCustomerSpecialPrice = (specialPrices: ISpecialPrice[]) => {
  return specialPrices.find((item) => item.customer_id === props.customerId)
}

const isAdmin = computed(() => {
  return getCoreCookie()?.company_type === Roles.Admin || getCoreCookie()?.company_type === Roles.SuperAdmin
})
</script>

<template>
  <Table
    :columns="columns"
    :data-source="handledItems"
    :pagination="false"
    :custom-row="handleCustomRow"
    bordered
    :row-class-name="
      (record) =>
        Number(record.quantity) > Number(record.farmer_product.quantity)
          ? 'quantity-exceeded-container'
          : null
    "
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'productName'">
        <p v-if="isAdmin">
          {{
            record.farmer_product.product
              .fullNameWithoutParametersAndWithoutMainCategory
          }}
        </p>
        <Button
          v-else
          :onClick="
            () => handleOpenProductCard(record.farmer_product, record.quantity)
          "
          type="link"
          >{{
            record.farmer_product.product
              .fullNameWithoutParametersAndWithoutMainCategory
          }}</Button
        >
      </template>
      <template
        v-for="parameter in record.handled_product_parameters"
        :key="parameter.parameter.name"
        ><template v-if="column.key === parameter.parameter.name">{{
          parameter.value.charAt(0).toUpperCase() + parameter.value.slice(1)
        }}</template>
      </template>
      <template v-if="column.key === 'preparePeriod'">
        {{ record.prepare_days + ' ' + dayEnding(record.prepare_days) }}
      </template>
      <template v-else-if="column.key === 'volume'">
        <Flex vertical>
          <span
            :class="{
              'quantity-exceeded':
                Number(record.quantity) >
                Number(record.farmer_product.quantity),
            }"
            >{{
              `${record.quantity}
            ${record.farmer_product.measurement_unit_id}`
            }}
            <QuantityExceededTooltip
              :show="
                Number(record.quantity) > Number(record.farmer_product.quantity)
              "
              :text="`В наличии ${record.farmer_product.quantity} ${record.farmer_product.measurement_unit_id}`"
          /></span>
          <span
            class="initial-order-position"
            v-if="
              record.initial_order_position &&
              !record.is_canceled &&
              record.initial_order_position.quantity !== record.quantity
            "
            >{{
              `${record.initial_order_position.quantity} ${record.farmer_product.measurement_unit_id}`
            }}</span
          >
        </Flex>
      </template>
      <template v-else-if="column.key === 'price'">
        <Flex :gap="4" vertical :style="{ position: 'relative' }">
          <span>
            {{
              `${roundNumber(record.unit_price)} ₽/${record.farmer_product.measurement_unit_id}`
            }}</span
          >
          <Tag color="green" v-if="record.is_special_price_used">Спеццена </Tag>
          <TpText type="secondary">{{ `НДС ${record.vat_percent}%` }}</TpText>
          <Popover title="Комментарий" v-if="record?.price_comment">
            <template #content> {{ record?.price_comment }}</template>
            <InfoCircleTwoTone class="price-comment-icon" />
          </Popover>

          <!--          <template-->
          <!--            v-if="-->
          <!--              getCustomerSpecialPrice(record.farmer_product.special_prices)-->
          <!--                ?.price === record.unit_price-->
          <!--            "-->
          <!--          >-->
          <!--            <Popover title="Условия спеццены" placement="topLeft">-->
          <!--              <template #content>-->
          <!--                <Flex vertical :gap="8">-->
          <!--                  <span>{{-->
          <!--                    `Действует до ${dayjs(-->
          <!--                      getCustomerSpecialPrice(-->
          <!--                        record.farmer_product.special_prices,-->
          <!--                      )?.expires_after,-->
          <!--                    ).format('DD.MM.YY')}, ${-->
          <!--                      getCustomerSpecialPrice(-->
          <!--                        record.farmer_product.special_prices,-->
          <!--                      )?.quantity-->
          <!--                    } ${record.farmer_product.measurement_unit_id}`-->
          <!--                  }}</span>-->
          <!--                  <TpText strong>Комментарий</TpText>-->
          <!--                  <span>{{-->
          <!--                    getCustomerSpecialPrice(-->
          <!--                      record.farmer_product.special_prices,-->
          <!--                    )?.comment-->
          <!--                  }}</span>-->
          <!--                </Flex>-->
          <!--              </template>-->
          <!--              <InfoCircleTwoTone-->
          <!--                :style="{ position: 'absolute', top: '8px', right: '8px' }"-->
          <!--              />-->
          <!--            </Popover>-->
          <!--          </template>-->

          <span
            class="initial-order-position"
            v-if="
              record.initial_order_position &&
              !record.is_canceled &&
              record.initial_order_position.unit_price !==
                record.unit_price
            "
            >{{
              `${roundNumber(record.initial_order_position.unit_price)} ₽/${record.farmer_product.measurement_unit_id}`
            }}</span
          >
          <span
            class="initial-order-position"
            v-if="
              record.initial_order_position &&
              !record.is_canceled &&
              record.initial_order_position.vat_percent !== record.vat_percent
            "
            >{{ `НДС ${record.initial_order_position.vat_percent}%` }}</span
          >
        </Flex>
      </template>
      <template v-else-if="column.key === 'sum'">
        <Flex vertical>
          <span>{{ roundNumber(record.price) }} ₽</span>
          <span
            class="initial-order-position"
            v-if="
              record.initial_order_position &&
              !record.is_canceled &&
              record.initial_order_position.price_vat !== record.price_vat
            "
            >{{ roundNumber(record.initial_order_position.price_vat) }} ₽</span
          >
        </Flex>
      </template>

      <template v-else-if="column.key === 'actions'">
        <Flex :gap="8" v-if="isCanEditOrder">
          <template v-if="!record.is_canceled">
            <Button @click.stop="getEditItem(record)" type="link">
              <template #icon>
                <EditFilled />
              </template>
            </Button>
            <Button @click.stop="onDeleteClick(record.id)" type="link">
              <template #icon>
                <DeleteFilled style="color: rgba(255, 77, 79, 1)" />
              </template>
            </Button>
          </template>
          <Button v-else @click.stop="onRestoreClick(record.id)" type="link">
            <template #icon>
              <UndoOutlined />
            </template>
          </Button>
        </Flex>
      </template>
    </template>
  </Table>
</template>

<style lang="scss" scoped>
.initial-order-position {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.45);
}

:deep(.row__deleted) {
  background: rgba(243, 243, 243, 1);
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.45);
}
.price-comment-icon {
  position: absolute;
  top: -8px;
  right: -8px;
}
:deep(.quantity-exceeded-container) {
  background-color: #fef7f3;
  &:hover {
    background-color: #fef7f3;
  }
}

.quantity-exceeded {
  font-weight: 600;
  color: #faad14;
}
</style>
