export const registerSteps = [
    {
        title: 'Зарегистрируйтесь',
        description:
            'Создайте аккаунт и ознакомьтесь с базовым функционалом платформы.\nПосмотрите каталог поставщиков и их продукции.',
    },
    {
        title: 'Пройдите верификацию',
        description: 'Получите доступ ко всем возможностям платформы.\nГарантируем работу только с проверенными контрагентами. ',
    },
    {
        title: 'Автоматизируйте продажи и закупки',
        description:
            'Оформляйте и принимайте заказы, участвуйте в торгах.\nОбщайтесь с поставщиками и ритейлерами напрямую.',
    },
]
