<script lang="ts" setup>
import {
  IAuction,
  IAuctionProductAnswer,
  IAuctionProductRequest,
  IFarmerProduct,
  IReductionParticipant,
} from '@/types/interfaces'
import { computed, ref } from 'vue'
import {
  Button,
  Divider,
  Flex,
  Space,
  Table,
  Tag,
  TypographyText as TpText,
} from 'ant-design-vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import {priceWithoutVat, getUserIdTemporary, priceWithVat, roundNumber} from '@/helpers/scripts'
import MyCompanyTag from '@/views/Farmer/reductions/reduction/MyCompanyTag.vue'
import { RUBLE } from '@/model/constants'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'

interface IAdvancedParticipant extends IReductionParticipant {
  bets_count: number
  bets_amount: number
  answers: IAdvancedProductAnswer[]
}

interface IAdvancedProductAnswer extends IAuctionProductAnswer {
  measurement_unit_id: string
  is_sample_requested: boolean
  requested_samples_number: string | number | null
  farmer_product_name: string
  volume: string
  farmer_product: IFarmerProduct | null
  remaining_shelf_life: number
}

interface IProps {
  auction: IAuction
  myParticipantId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId', 'setSelectedProduct'])

const expandedRowKeys = ref<string[]>([])

const customerId = +getUserIdTemporary()

const getAdditionalFields = (participantId: number) => {
  return props.auction?.auction_product_requests?.reduce(
    (accum, currentRequest) => {
      currentRequest?.auction_product_answers.forEach((currentAnswer) => {
        if (currentAnswer.auction_participant_id === participantId) {
          accum.bets_count += 1
          accum.bets_amount += +(
            Number(currentAnswer.price_per_unit) * Number(currentRequest.volume)
          ).toFixed(2)
        }
      })

      return accum
    },
    { bets_count: 0, bets_amount: 0 },
  )
}

const participantHasBets = (participantId: number) => {
  return props.auction?.auction_product_requests?.some((currentRequest) => {
    return currentRequest.auction_product_answers.some(
      (currentAnswer) => currentAnswer.auction_participant_id === participantId,
    )
  })
}

const getParticipantAnswers = (
  participantId: number,
): IAdvancedProductAnswer[] => {
  const answers: IAdvancedProductAnswer[] = []

  const getAdvancedFields = ({
    farmer_product_name,
    volume,
    remaining_shelf_life,
    is_sample_requested,
    requested_samples_number,
    vat,
    start_price,
  }: IAuctionProductRequest) => ({
    farmer_product_name,
    volume,
    remaining_shelf_life,
    is_sample_requested,
    requested_samples_number,
    vat,
    start_price,
  })
  props.auction?.auction_product_requests?.forEach((currentRequest) => {
    currentRequest?.auction_product_answers.forEach((currentAnswer) => {
      if (
        currentAnswer.auction_participant_id === participantId &&
        !currentRequest.is_deleted
      ) {
        answers.push({
          ...currentAnswer,
          ...getAdvancedFields(currentRequest),
          farmer_product: currentRequest.farmer_product ?? null,
          measurement_unit_id:
            currentRequest?.farmer_product?.measurement_unit_id ?? '',
        })
      }
    })
  })

  return answers
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const formattedParticipants = computed(() => {
  return (
    props.auction?.auction_participants
      ?.map((participant) => ({
        ...participant,
        ...getAdditionalFields(participant.id),
        answers: getParticipantAnswers(participant.id),
      }))
      ?.filter((participant) => participantHasBets(participant.id))
      ?.filter((participant) => participant.answers.length > 0) ?? []
  )
})
const columns = [
  {
    title: 'Рейтинг',
    key: 'rate',
    width: 100,
  },
  {
    title: 'Покупатель',
    key: 'customer',
    width: '53%',
  },
  {
    title: 'Количество ставок на позиции',
    key: 'bets_count',
    width: '13%',
  },
  {
    title: 'Сумма закупки',
    key: 'bets_amount',
  },
]

const nestedColumns = [
  {
    title: 'Товары',
    key: 'products',
    width: '40%',
  },
  {
    title: 'Стартовая цена',
    key: 'start_price',
    width: '11%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '13%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const calcStartPrice = (record: any) => {
  if (record?.start_price && record?.measurement_unit_id) {
    return `${roundNumber(record.start_price)} ${RUBLE}/${record.measurement_unit_id}`
  } else if (record.start_price) {
    return `${roundNumber(record.start_price)} ${RUBLE}`
  } else return '-'
}
// TODO: refactor with fillMeasurementUnitIdCommon
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :expandedRowKeys="expandedRowKeys"
      :data-source="formattedParticipants"
      :row-key="(record, index) => String(index)"
      :pagination="false"
      :showExpandColumn="false"
      bordered
    >
      <template #expandedRowRender="{ record }">
        <Table
          :columns="nestedColumns"
          :data-source="record.answers"
          :pagination="false"
          bordered
          class="nested-table"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'products'">
              <Flex :gap="8">
                <ProductWithProperties
                  :volume="record?.volume"
                  :remaining_shelf_life="record.remaining_shelf_life"
                  :measurement_unit_id="record.measurement_unit_id"
                  :name="record.farmer_product_name"
                  :is_sample_needed="record.is_sample_requested"
                  :needed_samples_number="record.requested_samples_number"
                  :isProductNameLink="true"
                  @onClickName="
                    emit('setSelectedProduct', record.farmer_product)
                  "
                />
              </Flex>
            </template>
            <template v-if="column.key === 'start_price'">
              <Space :size="4" direction="vertical">
                <Tag>{{ calcStartPrice(record) }}</Tag>
                <TpText v-if="record.is_sample_requested" type="secondary">
                  {{ `НДС ${record.vat}%` }}
                </TpText>
              </Space>
            </template>
            <template v-if="column.key === 'price_without_vat'">
              <CellDifData
                :new-data="
                  roundNumber(priceWithoutVat(record.price_per_unit, record.vat))
                "
                :prev-data="
                  roundNumber(priceWithoutVat(
                    record.price_per_unit_first, record.vat),
                  )
                "
                :additional-info="record.measurement_unit_id ? `₽/${record.measurement_unit_id}` : '₽'"
              />
            </template>
            <template v-if="column.key === 'price_with_vat'">
              <CellDifData
                :new-data="record.price_per_unit"
                :prev-data="record.price_per_unit_first"
                :additional-info="record.measurement_unit_id ? `₽/${record.measurement_unit_id}` : '₽'"
              />
            </template>
            <template v-if="column.key === 'amount'">
              <Flex justify="space-between">
                <CellDifData
                  :new-data="record.price_per_unit * record.volume"
                  :prev-data="
                    record.price_per_unit_first * record.volume
                  "
                  additional-info="₽"
                />
                <Button
                  v-if="
                    record.auction_participant_id === myParticipantId &&
                    auction.status === 'open'
                  "
                  type="primary"
                  @click="
                    emit('setRequestId', record.auction_product_request_id)
                  "
                  >Сделать ставку</Button
                >
              </Flex>
            </template>
          </template>
        </Table>
      </template>
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'rate'">
          <Flex justify="center" align="center">{{ index + 1 }}</Flex>
        </template>
        <template v-if="column.key === 'customer'">
          <span v-if="record.customer_id !== customerId">{{
            `Покупатель ${record.auction_name?.toUpperCase()}`
          }}</span>
          <Flex v-else :gap="8">
            <span>{{ record.customer.name }}</span>
            <MyCompanyTag />
          </Flex>
        </template>
        <template v-if="column.key === 'bets_count'">
          {{ record.bets_count }}
        </template>
        <template v-if="column.key === 'bets_amount'">
          <Flex justify="space-between" align="center">
<!--            -->
            <TpText strong>{{`${record.bets_amount} ₽` }}</TpText>
            <Button type="link" :onClick="() => changeExpandedRow(index)">
              <template #icon>
                <DownOutlined v-if="!expandedRowKeys.includes(String(index))" />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </Flex>
        </template>
      </template>
    </Table>
  </div>
</template>

<style lang="scss" scoped>
.table-wrapper {
  padding: 24px;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}

.nested-table {
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}
</style>
