<script setup lang="ts">
import CloseIcon from '@/components/Icons/CloseIcon.vue'
import { Button, DatePicker, Flex } from 'ant-design-vue'
import { Dayjs } from 'dayjs'
import { ref } from 'vue'

const { RangePicker } = DatePicker

const datesFilter = ref<[Dayjs, Dayjs] | []>([])

const emit = defineEmits(['search', 'resetFilters'])

const handleChangeDates = (dates: [Dayjs, Dayjs]) => {
  emit(
    'search',
    dates.map((date) => date.format('YYYY-MM-DD')),
  )
}

const handleResetFilters = () => {
  emit('resetFilters')
  datesFilter.value = []
}
</script>

<template>
  <Flex justify="space-between">
    <RangePicker
      v-model:value="datesFilter"
      @change="handleChangeDates"
      :placeholder="['От', 'До']"
      :allowClear="false"
    />
    <Button
      :disabled="datesFilter.length < 2"
      style="display: flex; align-items: center"
      type="link"
      @click="handleResetFilters"
    >
      <template #icon>
        <CloseIcon :style="{ color: '#FF4D4F', fontSize: '24px' }" />
      </template>
      Сбросить фильтры
    </Button>
  </Flex>
</template>

<style scoped lang="scss">
:deep(.ant-btn) {
  color: rgba(0, 0, 0, 0.25);

  &:hover:not(:disabled) {
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
