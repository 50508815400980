<script lang="ts" setup>
import MainHeader from '@/components/Header/MainHeader/MainHeader.vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { computed, onMounted, ref, watch } from 'vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/store'
import { storeToRefs } from 'pinia'
import MainFooter from '@/components/Footer/MainFooter.vue'
import { useRoute } from 'vue-router'
import {APIModels, Roles} from '@/types/enums'
import { UserAPI } from '@/api/UserAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import router from '@/router'
import ModalSystem from '@/components/ModalSystem/ModalSystem.vue'
import {
  customerLinks,
  farmerLinks,
  guestLinks,
  withoutStylesRoutes,
} from '@/helpers/constants'
import { useUserAuthStore } from '@/store/useUserAuthStore'
import { PartnersAPI } from '@/api/PartnersAPI'
import { usePartnersStore } from '@/store/usePartnersStore'
import MainFooterNotAuth from '@/components/Footer/MainFooterNotAuth.vue'
import { AddressAPI } from '@/api/AddressAPI'
import emitter from "@/emitter";
import {initSessionTimer} from "@/axios";

const { getRoles, getCoreCookie } = useJWTDecode()
const coreCookie = getCoreCookie()
const { cookies } = useCookies()
const route = useRoute()
const userStore = useUserAuthStore()
const partnersStore = usePartnersStore()
const isActiveUser = ref(false)
const companyId = ref()
const isLoading = ref(false)
const role = ref()
const umt = computed(() => cookies.get('umt'))
const chat = computed(() => cookies.get('chat'))
const isAuth = computed(() => !!umt.value && !!chat.value)

const mainStore = useStore()

const { setIsVerification } = mainStore
const { isVerification } = storeToRefs(mainStore)


const userDataStr = computed(() => localStorage.getItem('userData'))
const userSrt = computed(() => localStorage.getItem('user'))
const isNotAdmin = computed(() => getRoles()?.[0] !== Roles.SuperAdmin && getRoles()?.[0] !== Roles.Admin)

let userData = ref(userDataStr.value ? JSON.parse(userDataStr.value) : '')
let user = ref(userSrt.value ? JSON.parse(userSrt.value) : '')

const getUser  = async () => {
  let _role = cookies.get('role');
  isLoading.value = true

  if (isNotAdmin.value) {
    let resUser;
    let addresses;
    let userDataC;
    const companyIdFromLocalStorage = localStorage.getItem('company_id');

    try {
      resUser  = await UserAPI.getUser(coreCookie?.id, ['company', 'company.contacts']);
      if (!resUser ) return;

      userStore.setUser (resUser );
      userStore.setIsAuth(true);
      companyId.value = resUser.company_id;
      _role = resUser.type;
      cookies.set('role', resUser.type);
      role.value = resUser.type;
      user.value = resUser;
      localStorage.setItem('user', JSON.stringify(resUser));
    } catch (e) {
      companyId.value = companyIdFromLocalStorage;
      console.error('User API.getUser  error', e);
    }

    try {
      addresses = await AddressAPI.getAddresses(resUser?.company_id, resUser?.type);
      if (addresses) userStore.setAddresses(addresses.items);
    } catch (e) {
      console.error('AddressAPI.getAddresses error', e);
    }

    try {
      userDataC = await UserAPI.getUserData(
          resUser ?.company_id || companyIdFromLocalStorage,
          _role === 'customer' ? APIModels.Customer : APIModels.Farmer,
          ['users']
      );
      localStorage.setItem('userData', JSON.stringify(userDataC));
      setIsVerification(userDataC.is_active);
      isActiveUser.value = userDataC?.is_active || false;
      userData.value = userDataC;
    } catch (e) {
      console.error('User API.getUser Data error', e);
    }

    isLoading.value = false;
  }
};

const isSuperUser = computed(() => getRoles()?.[0] === Roles.SuperAdmin)
const isAdmin = computed(() => getRoles()?.[0] === Roles.Admin)

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partnersStore.setPartners(res?.items)
  })
}

watch(() => coreCookie?.id, async () => {
  if (coreCookie?.id) {
    if (getRoles()?.[0] !== Roles.Admin) {
      await getUser()
    }
    if (getRoles()?.[0].includes('user.confirmed')) {
      await router.push('/register')
    }
    if (isVerification.value && !isSuperUser.value && !isAdmin.value) {
      await getMyPartners()
    }
  } else {
    userStore.setUser(null)
    userStore.setIsAuth(false)
    partnersStore.setPartners(null)
  }
})

const { isOpenCart } = storeToRefs(useStore())

const takeRouterName = ref(false)
const withoutStyles = ref(false)
const layoutBackground = ref<'gray' | 'white'>('gray')
const routesRegex =
  /^(\/cart)?(\/history)?(\/history\/\d+)?(\/partners\/\d+)$/gm

watch(
  () => route.path,
  () => {
    if (route.path.match(routesRegex)) {
      layoutBackground.value = 'white'
    } else {
      layoutBackground.value = 'gray'
    }
    takeRouterName.value =
      route.path === '/' ||
      route.path === '/history' ||
      route.path.includes('catalog') ||
      route.path.includes('create-request') ||
      route.path.includes('add-product') ||
      route.path.includes('edit-product') ||
      route.path.includes('edit-special-prices') ||
      route.path.includes('partners') ||
      route.path.includes('response') ||
      route.path.includes('/1c-faq') ||
      route.path.includes('/1c-table') ||
      route.path.includes('/create-reduction') ||
      route.path.includes('/create-auction') ||
      route.path.includes('/order-created') ||
      !!route.path.match(/^\/requests\/\d+\/\d+$/)
    route.path.includes('edit-price-options')

    withoutStyles.value = withoutStylesRoutes?.some((routeItem) =>
      route.path?.includes(routeItem),
    )
  },
  {
    immediate: true,
  },
)

const layoutInit = async () => {
  if (coreCookie?.id) {
    isLoading.value = true

    if (getRoles()?.[0] !== Roles.Admin || getRoles()?.[0] !== Roles.SuperAdmin) {
      await getUser()
      if (isVerification.value) {
        await getMyPartners()
      }
    }
    if (role.value === Roles.Admin || role.value === Roles.SuperAdmin) {
      await router.push('/admin/users')
    }
    isLoading.value = false
  }
}

emitter.on('refresh-token', async (e) => {
  console.log('default layout refresh-token')
  await layoutInit()
})

onMounted(async () => {
  await layoutInit()
  if (isAuth?.value) {
    initSessionTimer()
  }
})

const links = computed(() => role.value === 'customer' ? customerLinks : role.value === 'farmer' ? farmerLinks : [])
</script>

<template>
  <div class="layout-container">
    <MainHeader
      :links="links"
      :anchors="!isAuth ? guestLinks : []"
      :role="role"
      :userData="userData"
      :user="user"
      :companyId="companyId"
      :isAuth="isAuth"
    />
    <div
      class="content"
      :class="[layoutBackground, { 'raw-content': withoutStyles }]"
    >
      <slot
        name="content"
        v-if="!isLoading"
        :style="{ height: isOpenCart ? 0 : '' }"
      />
    </div>
    <MainFooter :links="links" v-if="!takeRouterName && isAuth" />
    <MainFooterNotAuth :links="links" v-if="!isAuth" />
  </div>
  <div v-if="isLoading" class="loader">
    <StarLoader />
  </div>
  <ModalSystem />
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

.page-enter-active,
.page-leave-active {
  transition: all 0.3s ease;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-container {
  background-color: #f7fafc;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  height: 100vh;
  &--cart {
    min-height: 0;
    .content {
      min-height: 0 !important;
    }
  }

  .header {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
  }

  .content {
    display: flex;
    flex-grow: 1;
    width: 100vw;
    margin-top: 72px;
    // TODO: Добавить минимальную высоту для контента, чтобы спрятать футер
    // min-height: calc(100vh - 76px);

    &.gray {
      background-color: $gray-100;
    }

    &.white {
      background-color: #ffffff;
    }
  }
  .raw-content {
    background-color: $base-white;
    padding: 0;
  }
}

.layout-cart {
  z-index: 10;
  min-height: calc(100vh - 69px - 40px - 80px);
  background: #f7fafc;
  &__modal {
    height: 100%;
  }
}
</style>
