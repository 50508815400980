<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  Col,
  Drawer,
  Row,
  Space,
  TypographyTitle as TgTitle,
  Divider,
} from 'ant-design-vue'
import { IOrderDocument, IPartnerDocument } from '@/types/interfaces'
import { OrderDocumentAPI } from '@/api/OrderDocumentAPI'
import { PartnerDocumentAPI } from '@/api/PartnerDocumentAPI'
import { width100 } from '@/model/constants'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import AntEmpty from '@/components/Empty/AntEmpty.vue'
import DocumentMiniListItem from '@/components/Document/DocumentMiniListItem.vue'

interface IProps {
  open: boolean
  orderId?: number
  partnershipId?: number
  farmerId?: number
  customerId?: number
  subtitle?: string
}

const systemDocsName = {
  BOL: 'Товарная накладная',
  CBOL: 'Товарно-транспортная накладная',
  PI: 'Счет на оплату',
  invoice: 'Счёт-фактура',
}

const emit = defineEmits(['update:open'])
const props = defineProps<IProps>()

const isFetching = ref<boolean>(false)
const orderSystemDocuments = ref<IOrderDocument[]>([])
const orderFarmerDocuments = ref<IOrderDocument[]>([])
const orderCustomerDocuments = ref<IOrderDocument[]>([])
const partnerCustomerDocuments = ref<IPartnerDocument[]>([])
const partnerFarmerDocuments = ref<IPartnerDocument[]>([])
const closeDrawer = () => {
  emit('update:open', false)
}

const getOrderSystemDocuments = () => {
  const filters = [['order_id', 'eq', props.orderId]]
  return OrderDocumentAPI.getItems(filters, 100).then((response) => {
    response.items.forEach((item: IOrderDocument) => {
      if (item.source === 'system') {
        orderSystemDocuments.value.push({
          ...item,
          file_name: systemDocsName[item.type],
        })
      } else if (item.source === 'farmer') {
        orderFarmerDocuments.value.push(item)
      } else if (item.source === 'customer') {
        orderCustomerDocuments.value.push(item)
      }
    })
  })
}

const getPartnerDocuments = () => {
  // #TODO: узнать у  саши показывать ли сгенерированный договор
  return PartnerDocumentAPI.getItems(
    [
      ['partner.farmer_id', 'eq', props.farmerId],
      'AND',
      ['partner.customer_id', 'eq', props.customerId],
    ],
    100,
  ).then((response) => {
    response.items.forEach((item: IPartnerDocument) => {
      if (item.source === 'farmer') {
        partnerFarmerDocuments.value.push(item)
      } else if (item.source === 'customer') {
        partnerCustomerDocuments.value.push(item)
      }
    })
  })
}

const emptyDocuments = computed(() => {
  return (
    orderSystemDocuments.value.length === 0 &&
    orderFarmerDocuments.value.length === 0 &&
    orderCustomerDocuments.value.length === 0 &&
    partnerCustomerDocuments.value.length === 0 &&
    partnerFarmerDocuments.value.length === 0 &&
    !isFetching.value
  )
})
watch(
  () => props.open,
  async () => {
    if (props.open) {
      isFetching.value = true
      const responses = []
      if (
        props.orderId &&
        props.partnershipId &&
        props.farmerId &&
        props.customerId
      ) {
        responses.push(getOrderSystemDocuments(), getPartnerDocuments())
      }
      await Promise.all(responses).finally(() => {
        isFetching.value = false
      })
    } else {
      orderSystemDocuments.value = []
      orderFarmerDocuments.value = []
      orderCustomerDocuments.value = []
      partnerCustomerDocuments.value = []
      partnerFarmerDocuments.value = []
    }
  },
)
</script>

<template>
  <Drawer
    width="540"
    :bodyStyle="{ padding: '24px 40px 72px 40px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    :title="subtitle ? null : 'Документы заказа'"
    class="drawer-reverse-header"
    :open="open"
    @close="closeDrawer"
  >
    <template #title v-if="subtitle">
      <Space :size="0" direction="vertical">
        <TgTitle :level="5">Документы заказа</TgTitle>
        <span class="document-drawer-subtitle">{{ subtitle }}</span>
      </Space>
    </template>
    <div class="loader-wrapper" v-if="isFetching">
      <FullscreenLoading type="secondary" />
    </div>

    <div class="empty-wrapper" v-if="emptyDocuments">
      <AntEmpty
        title="Документы отсутствуют"
        subtitle="Документы не загружены"
      />
    </div>
    <Row v-else :gutter="[0, 16]">
      <Col
        :span="24"
        v-if="partnerCustomerDocuments.length || partnerFarmerDocuments.length"
      >
        <span class="document-block-title">Договор</span>
        <Space :size="16" :style="width100" direction="vertical">
          <div v-if="partnerFarmerDocuments.length">
            <span class="document-sub-block-title">Поставщик</span>
            <Space :size="12" direction="vertical" :style="width100">
              <DocumentMiniListItem
                v-for="document in partnerFarmerDocuments"
                :key="document.id"
                :id="document.id"
                :name="document.file_name"
                :body="''"
                :errors="[]"
                :isShow="true"
                documentModel="partner"
              />
            </Space>
          </div>
          <div v-if="partnerCustomerDocuments.length">
            <span class="document-sub-block-title">Покупатель</span>
            <Space :size="12" direction="vertical" :style="width100">
              <DocumentMiniListItem
                v-for="document in partnerCustomerDocuments"
                :key="document.id"
                :id="document.id"
                :name="document.file_name"
                :body="''"
                :errors="[]"
                :isShow="true"
                documentModel="partner"
              />
            </Space>
          </div>
        </Space>
      </Col>

      <Divider class="divider" />

      <Col :span="24" v-if="orderSystemDocuments.length">
        <span class="document-block-title"> Отгрузочные документы </span>
        <Space :size="12" direction="vertical" :style="width100">
          <DocumentMiniListItem
            v-for="document in orderSystemDocuments"
            :key="document.id"
            :id="document.id"
            :name="document.file_name"
            :body="''"
            :errors="[]"
            :isShow="true"
            documentModel="order"
          />
        </Space>
      </Col>

      <Divider class="divider" />

      <Col
        :span="24"
        v-if="orderCustomerDocuments.length || orderFarmerDocuments.length"
      >
        <span class="document-block-title">Исправленные документы</span>
        <Space :size="16" :style="width100" direction="vertical">
          <div v-if="orderFarmerDocuments.length">
            <span class="document-sub-block-title">Поставщик</span>
            <Space :size="12" direction="vertical" :style="width100">
              <DocumentMiniListItem
                v-for="document in orderFarmerDocuments"
                :key="document.id"
                :id="document.id"
                :name="document.file_name"
                :body="''"
                :errors="[]"
                :isShow="true"
                documentModel="order"
              />
            </Space>
          </div>
          <div v-if="orderCustomerDocuments.length">
            <span class="document-sub-block-title">Покупатель</span>
            <Space :size="12" direction="vertical" :style="width100">
              <DocumentMiniListItem
                v-for="document in orderCustomerDocuments"
                :key="document.id"
                :id="document.id"
                :name="document.file_name"
                :body="''"
                :errors="[]"
                :isShow="true"
                documentModel="order"
              />
            </Space>
          </div>
        </Space>
      </Col>
    </Row>
  </Drawer>
</template>

<style scoped lang="scss">
.document-drawer-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #4a5568;
}
.document-block-title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  color: #2d3748;
  margin-bottom: 20px;
}
.document-sub-block-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #2d3748;
  margin-bottom: 8px;
}
.loader-wrapper,
.empty-wrapper {
  position: relative;
  height: calc(100vh - 100px);
}
.divider {
  margin: 0;
}
</style>
