<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {
  GeneralOrderStatus,
  NewGeneralOrderStatus,
  Sockets,
} from '@/types/enums'
import FarmerOrderView from '@/components/Orders/FarmerOrderView.vue'
import { OrderAPI } from '@/api/OrderAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import InfiniteLoading from 'v3-infinite-loading'
import router from '@/router'
import { useCentrifuge } from '@/composables/useCentrifuge'
import EmptyHistory from '@/components/Customer/EmptyHistory.vue'
import FarmerOrderRow from '@/components/Orders/FarmerOrderRow.vue'
import { SigningAnAgreement } from '@/types/types'
import { PartnersAPI } from '@/api/PartnersAPI'
import FarmLoader from '@/components/Loaders/FarmLoader.vue'
import {
  getSortedArrayByCreatedAt,
  getUserIdTemporary,
} from '@/helpers/scripts'
import {useRoute} from "vue-router";
import emitter from "@/emitter";

const props = defineProps({
  openOrderHistory: Number,
})

// примерный вид данных с бэка
const farmerOrders = ref<any>([])

// карточка просмотра заказа поставщика
const isCardOpen = ref(false)
const openedOrder = ref()

const closeCard = () => {
  openedOrder.value = null
  isCardOpen.value = false
  router.push('/history')
}

const updateOrdersAfterAction = async (filters: string[][] = []) => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const companyId = user?.company_id
  isOrderStatusChanging.value = true
  currentPage.value = 1
  if (isOpenGet.value) {
    await OrderAPI.getOrder(
      [
        ['farmer_id', 'eq', companyId],
        ['general_order.order_number', 'eq', props.openOrderHistory],
      ],
      [],
    ).then((res: any) => {
      isOpenGet.value = true
      farmerOrders.value = res
      isFetching.value = false
      isOrderStatusChanging.value = false
    })
    return
  }
  await OrderAPI.getOrders(
    currentPage.value,
    [['farmer_id', 'eq', companyId], ...filters],
    [
      'customer',
      'customer.company_rating',
      'general_order',
      'partnership',
      'orderPositions',
    ],
  ).then((res: any) => {
    farmerOrders.value = res
    isFetching.value = false
    isOrderStatusChanging.value = false
  })
  // sortOrders()
}

// // Сортировка заказов по дате создания
// const sortOrders = () => {
//   farmerOrders.value?.sort(function (a, b) {
//     return new Date(a.created_at) - new Date(b.created_at);
//   });
// }

const isOrderStatusChanging = ref(false)
const cancelOrder = async (order: any) => {
  isOrderStatusChanging.value = true
  await OrderAPI.updateOrderStatus(order.id, GeneralOrderStatus.Cancelled)
  await updateOrdersAfterAction()
  isOrderStatusChanging.value = false
  closeCard()
}

const sendToApproveOrder = async (
  itemId: number,
  status: NewGeneralOrderStatus,
) => {
  await OrderAPI.updateOrderStatus(itemId, status)
}
const changeOrderStatus = async (
  orderInfo: { id: number; status: string },
  cb: () => void,
) => {
  await OrderAPI.updateOrder(orderInfo).then(async () => {
    cb && (await cb())
    await refetchOrderList()
  })
}
const changeAgreementSigned = async (
  data: SigningAnAgreement,
  cb: () => void,
) => {
  await PartnersAPI.sign(data)
  cb && (await cb())
  await refetchOrderList()
}
const refetchOrderList = async () => {
  currentPage.value = 1
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const userId = user?.company_id
  await OrderAPI.getOrders(
    currentPage.value,
    [['farmer_id', 'eq', userId]],
    [
      'customer',
      'customer.company_rating',
      'generalOrder.delivery_address',
      'shipping_address',
      'partnership',
      'orderPositions',
    ],
  ).then((res) => {
    farmerOrders.value = res
  })
}

const signContract = async (order: any) => {
  await OrderAPI.updateOrder({
    id: order.id,
    status: GeneralOrderStatus.Signed,
  })

  await updateOrdersAfterAction()
  closeCard()
}

const tHeaders = [
  { name: 'Покупатель', value: 'customer' },
  { name: 'Статус', value: 'status' },
  { name: 'Способ получения', value: 'delivery_method' },
  { name: 'Оформлен', value: 'date_ordering' },
  { name: 'Получение', value: 'date_receiving' },
  { name: 'Сумма', value: 'sum' },
]

const currentPage = ref(1)
const isFetching = ref(true)

const getAllFarmerOrders = async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const userId = user?.company_id
  await OrderAPI.getOrders(
    currentPage.value,
    [['farmer_id', 'eq', userId]],
    [
      'customer',
      'customer.company_rating',
      'generalOrder.delivery_address',
      'generalOrder.history',
      'history',
      'shipping_address',
      'partnership',
      'orderPositions',
    ],
    10,
  ).then((res: any) => {
    if (res.length < 10) isAllOrdersFetched.value = true
    res.history = getSortedArrayByCreatedAt(res?.history)
    farmerOrders.value.push(...res?.items)
    isFetching.value = false
    isNewPageFetching.value = false
  })
}

const connectGeneralOrdersSockets = () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  startCentrifuge()
  addChanel(Sockets.Order + '.' + String(getUserIdTemporary()), (item) =>
    setTimeout(() => updateOrders(item), 10000),
  )
}

const updateOrders = async (ctx?: any) => {
  if (ctx) {
    const index = farmerOrders.value.findIndex(
      (item: any) => item.id === ctx.data.model_id,
    )
    if (index !== -1) {
      await OrderAPI.getOrder(
        [['id', 'eq', ctx.data.model_id]],
        ['customer', 'general_order'],
      ).then((res) => {
        const updatedItem = { ...farmerOrders.value[index], ...res[0] }
        const updatedOrders = [...farmerOrders.value]
        updatedOrders.splice(index, 1, updatedItem)
        farmerOrders.value = updatedOrders
      })
    } else {
      await OrderAPI.getOrder(
        [['id', 'eq', ctx.data.model_id]],
        ['customer', 'general_order'],
      ).then((res) => {
        farmerOrders.value = [res[0], ...farmerOrders.value]
      })
    }
  }
  // sortOrders()
}

emitter.on('refresh-token', async (e) => {
  await getAllFarmerOrders()
  await checkOpenOrderHistory()
  connectGeneralOrdersSockets()
  isNewPageFetching.value = false
})

onMounted(async () => {
  await getAllFarmerOrders()
  await checkOpenOrderHistory()
  connectGeneralOrdersSockets()
  isNewPageFetching.value = false
})

const isNewPageFetching = ref(false)
const isAllOrdersFetched = ref(false)

const loadNextPage = async () => {
  isNewPageFetching.value = true
  currentPage.value += 1
  if (!props.openOrderHistory) {
    await getAllFarmerOrders()
  }
  await checkOpenOrderHistory()
}
const isOpenGet = ref(false)

const checkOpenOrderHistory = () => {
  if (props.openOrderHistory) {
    isCardOpen.value = true
  }
}

watch(props, () => {
  checkOpenOrderHistory()
})

const route = useRoute()
</script>

<template>
  <template v-if="!isFetching">
    <EmptyHistory v-if="!isCardOpen && farmerOrders.length === 0" />
    <div v-else class="wrapper-home home">
      <div v-if="!isCardOpen" class="home__content">
        <div class="home__header">
          <div class="home__title">История заказов</div>
        </div>
        <!--      <OrderHistoryFilters @updateOrders="updateOrdersAfterAction" />-->

        <div :class="{ empty: farmerOrders.length === 0 }" class="home__orders">
          <div class="home__list">
            <div class="table-header row">
              <div
                v-for="header in tHeaders"
                :key="header.name"
                class="home__tcell"
              >
                {{ header.name }}
              </div>
            </div>
            <FarmerOrderRow
              v-for="order in farmerOrders"
              :key="order.id"
              :order="order"
            />
            <InfiniteLoading
              :distance="100"
              class="infinite-loading"
              @infinite="loadNextPage"
            />
          </div>
        </div>
        <div
          v-if="isNewPageFetching"
          class="home__orders new-page-loader"
          style="overflow-y: hidden"
        >
          <StarLoader />
        </div>
      </div>

      <FarmerOrderView
        v-if="isCardOpen"
        :isStatusChanging="isOrderStatusChanging"
        @approve="sendToApproveOrder"
        @back="closeCard"
        @cancel="cancelOrder(openedOrder)"
        @sign-contract="signContract(openedOrder)"
        @signing-an-agreement="changeAgreementSigned"
        @changeOrderStatus="changeOrderStatus"
        @refetchOrders="refetchOrderList"
      />
    </div>
  </template>
  <div v-else class="farmer-history-loader">
    <FarmLoader :fullscreen="true" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.farmer-history-loader {
  width: 100%;
}

:deep(.sidebar) {
  min-height: unset;
}

.wrapper-home {
  display: flex;
  gap: 32px;
  @include wrapper();
  height: 86vh;
  padding-top: 20px;
}

.home {
  &__sidebar {
    max-width: 208px;
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: $gray-800;
  }

  &__orders {
    &.empty {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $gray-600;
    }

    &.loader {
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.new-page-loader {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.5);
    }
  }

  &__list {
    display: grid;
    grid-auto-flow: row;
    background: #ffffff;
    border: 1px solid $gray-300;
    overflow: hidden;
    border-radius: 16px;

    .row {
      font-size: 14px;
      line-height: 17px;
      color: $gray-800;
      padding: 20px 32px;
      display: grid;
      grid-template-columns: 2fr 270px 2fr 120px 120px 1fr;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__trow {
    font-weight: 400;
    cursor: pointer;
    gap: 8px;
  }

  &__tcell {
    word-break: break-word;
    padding: 0 20px;
  }
}
.home__list {
  height: 100%;
  max-height: 670px;
  overflow: auto;
  margin-bottom: 20px;
  .table-header {
    background-color: #f5f7f8;
    text-transform: uppercase;
    color: $gray-600;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 12px;
    border-radius: 16px 16px 0 0;
  }
}
</style>
