import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class CatalogAPIModel {
  async getPosition(id: string): Promise<any> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.GetItems,
      data: { filter: [['id', 'eq', id]] },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProduct(id: number, per_page = 10, page = 0): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      data: { withs: [
          'farmer.company_rating',
          'farmer',
          'farmer.addresses',
          'specialPrices.customer',
          'priceOptions',
        ], filter: [
          ['farmer.is_active', 'eq', true],
            'AND',
          ['is_deleted', 'eq', false],
            'AND',
          ['product_id', 'eq', id],
        ], order: [['fullname', 'asc']], pagination: { per_page, page }},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getPartner(farmer_id: number | null, customer_id: number | string | null, filters?: any[]): Promise<any> {
    const filtersData = filters ? [...filters] : []
    if (farmer_id) {
      filtersData.push('AND')
      filtersData.push(['farmer_id', 'eq', farmer_id])
    }
    if (customer_id) {
      filtersData.push('AND')
      filtersData.push(['customer_id', 'eq', customer_id])
    }
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.GetItems,
      data: { filter: filtersData },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getCategories(): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductCategoryOptimized,
      method: EgalMethods.GetItems,
      data: { order: [['name', 'desc']] },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getCategory(id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductCategoryOptimized,
      method: EgalMethods.GetItems,
      data: { filter: [[id.toString()]] },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFilters(id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.Category,
      method: EgalMethods.GetItems,
      data: {
        filter: [['id', 'eq', id]],
        withs: ['parameters'],
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProductsByCategory(
    id: number,
    page = 1,
    filters: any[][] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.GetItems,
      data: {
        filter: [
          ['product_category_id', 'eq', id],
          'AND',
          ['count', 'ge', 1],
          'AND',
          [...filters],
        ],
        withs: ['product_parameters'],
        pagination: { per_page: 25, page },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProductsWithFilterCategory(
    id: number,
    page = 1,
    filters: any,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Product,
      method: EgalMethods.GetItems,
      data: {
        filter: [['farmer_products.is_deleted', 'eq', false], 'AND', ...filters],
        withs: ['product_parameters'],
        pagination: { per_page: 25, page },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const CatalogAPI = new CatalogAPIModel()
export { CatalogAPI }
