<script setup lang="ts">
import { Breadcrumb } from 'ant-design-vue'
import {useRoute, useRouter} from "vue-router";
const BreadCrumbItem = Breadcrumb.Item

interface IProps {
  company_name: string
}

const props = defineProps<IProps>()
const router = useRouter()
const route = useRoute()
</script>

<template>
  <Breadcrumb>
    <BreadCrumbItem
      ><a href="" @click.prevent="router.push('/admin/users')"
        >Список компаний</a
      >
    </BreadCrumbItem>
    <BreadCrumbItem>{{ company_name }}</BreadCrumbItem>
  </Breadcrumb>
</template>
