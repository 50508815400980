<script lang="ts">
import { defineComponent } from 'vue'

// TODO: Дописать

export default defineComponent({
  name: 'CustomerRequestCommonInfoMethodOfObtaining',
  props: {
    methods: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
})
</script>

<template>
  <div class="customer-request-common-info-method-of-obtaining">
    <div class="customer-request-common-info-method-of-obtaining_title">
      Способ получения
    </div>
    <div v-for="method in methods" :key="method">
      <div class="customer-request-common-info-method-of-obtaining_item">
        {{ method }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info-method-of-obtaining {
  &_title {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 600;
    font-family: Inter, sans-serif;
  }
  &_item {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
}
</style>
