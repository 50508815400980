<script lang="ts" setup>

import { Tag, Space } from 'ant-design-vue'
import dayjs from 'dayjs'

interface IProps {
  status: string
  expirationDate: string
}

const props = defineProps<IProps>()
</script>

<template>
  <div class="status-cell">
    <Space :size="4" direction="vertical" v-if="status === 'open'">
      <Tag color="blue">Открыт</Tag>
      <div class="status-cell__date">
        До {{ dayjs(props.expirationDate).format('DD.MM.YYYY HH:mm') }}
      </div>
    </Space>
    <Tag v-else> Закрыт </Tag>
  </div>
</template>

<style lang="scss" scoped>
.status-cell {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
  &__tag {
    width: fit-content;
  }
  &__date {
    margin-top: 5px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.88);
  }
}
</style>
