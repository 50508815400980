<script lang="ts" setup>
import { useCookies } from 'vue3-cookies'
import { computed, onMounted, ref, watch } from 'vue'
import { OrganisationStatus, SuggestionTypes } from '@/types/enums'
import { ISuggestOrg } from '@/types/interfaces'
import { DadataAPI } from '@/api/Dadata'
import InfoIcon from '@/components/Icons/InfoIcon.vue'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import { isNull } from '@/helpers/scripts'
import { removeQuotes } from '@/assets/calendar/helpers'
import { useAuth } from '@/composables/useAuth'
import { Select, Button, Spin } from 'ant-design-vue'
import { reactive } from 'vue'
import { useCaptcha } from '@/composables/useCaptcha'
import { useDebounceFn } from '@vueuse/core'

const { cookies } = useCookies()
const { login } = useAuth()
const umt = ref<string>('')
const ust = ref<string>('')
const invalidCompany = ref<boolean>(false)

// Options
interface Option {
  org: ISuggestOrg
  value: string
  label: string
}

const options = ref<Option[]>([])
const { recaptcha } = useCaptcha()

const makeOptions = (data: any[]) => {
  options.value = data.map((item) => ({
    org: item,
    value: item.tin,
    label: `${item.legal_form} ${item.name}`,
  }))
}

const loginForm = {
  email: cookies.get('registerForm')?.email,
  password: cookies.get('registerForm')?.password,
  captcha: null,
}

const emit = defineEmits(['next', 'setCreateData', 'threeAlt'])
const searchValue = ref(null)
const is_fetching = ref(false)
const is_input_empty = ref(true)
const changeInput = (value: string) => {
  is_fetching.value = true
  options.value = []
  is_input_empty.value = value === ''
  debounce_fetch(value)
}
const fetch_suggestions = (value: string) => {
  if (value !== '') {
    DadataAPI.getSuggests(SuggestionTypes.Organisation, value)
      .then((resp) => {
        makeOptions(resp)
        is_fetching.value = false
      })
      .catch((err) => {
        emit('threeAlt')
      })
  } else {
    is_fetching.value = false
  }
}

const debounce_fetch = useDebounceFn(fetch_suggestions, 500)

const activeOrganisation = ref<ISuggestOrg | null>(null)

const selectTip = (_: string, option: Option) => {
  activeOrganisation.value = option.org
  emit('setCreateData', ['tin', option.org.tin])
}

const resetOrg = () => {
  activeOrganisation.value = null
  invalidCompany.value = false
}

const orgExist = computed(() => {
  return !!activeOrganisation.value?.exists
})

// const orgLiquidated = computed(() => {
//   return activeOrganisation.value?.status == OrganisationStatus.LIQUIDATED
// })
//
// const orgReorganizing = computed(() => {
//   return activeOrganisation.value?.status == OrganisationStatus.REORGANIZING
// })
//
// const orgBankrupt = computed(() => {
//   return activeOrganisation.value?.status == OrganisationStatus.BANKRUPT
// })

const toStep = () => {
  if (activeOrganisation.value?.status === 'ACTIVE') {
    cookies.set('activeOrganisation', activeOrganisation.value.tin, '365d', '')
    emit('next')
  } else {
    invalidCompany.value = true
  }
}

const getAccess = async () => {
  const threeAlt = localStorage.getItem('threeAlt')
  if (!threeAlt) {
    DadataAPI.ping().catch((err) => {
      localStorage.setItem('threeAlt', String(true))
      emit('threeAlt')
    })
  }

  const { loginIntoService } = useAuth()

  umt.value = cookies.get('umt')
  loginForm.captcha = await recaptcha()

  login(loginForm, false).then((res) => {
    const umtData = res.data.action_result?.data.user_master_token
    umt.value = umtData
    ust.value = cookies.get('ust')
    cookies.set('umt', umtData, '365d', '')
    if (!ust.value) {
      const cleanUmt = removeQuotes(umtData)

      if (!cookies.get('ust')) {
        loginIntoService(cleanUmt, 'core')
            .then((res) => {
              console.log('res.data.action_result.data', res.data.action_result.data)
              let resUST = res.data.action_result.data
              cookies.set('ust', resUST, '365d', '')
              ust.value = resUST
            })
            .catch((err) => {
              emit('threeAlt')
            })
      }
    }
  })
}

onMounted(async () => {
  await getAccess()
})
</script>

<template>
  <div class="first-step__card">
    <div class="first-step__card-header">
      <span v-if="orgExist"> Эта компания уже зарегистрирована </span>
      <span v-if="invalidCompany"> Укажите другую организацию </span>
      <span v-if="!orgExist && !invalidCompany">
        Найдите свою организацию
      </span>
      <div class="step" v-if="!orgExist && !invalidCompany">
        <span>этап 2</span>
        <span class="max">/4</span>
      </div>
    </div>

    <div class="first-step__card-body">
      <p class="info-step-row" v-if="invalidCompany || orgExist">
        <span v-if="invalidCompany">
          На платформе нельзя работать от лица организации в статусе «{{
            OrganisationStatus[activeOrganisation?.status]
          }}».
        </span>

        Пожалуйста,
        <span v-if="orgExist"> укажите другие данные </span>
        <span v-if="invalidCompany"> зарегистрируйте другую организацию </span>
        или обратитесь в тех. поддержку:
        <span class="info-step-item">manager@easy-retail.org</span> или
        <span class="info-step-item">+7 913 871-12-39</span>
      </p>

      <div v-else>
        <Select
          placeholder="Введите название организации, адрес, ИНН или ОГРН"
          v-model:value="searchValue"
          @search="changeInput"
          :options="options"
          show-search
          :filter-option="false"
          @select="selectTip"
          :not-found-content="is_input_empty && !is_fetching ? null : undefined"
          label-in-value
        >
          <template #option="{ value, label, org }">
            <div>
              {{ label }}
            </div>
            <div class="subInfo">ИНН: {{ value }}, {{ org.legal_address }}</div>
          </template>

          <template v-if="is_fetching || !is_input_empty" #notFoundContent>
            <Spin size="small" v-if="is_fetching" />
            <span v-if="!is_input_empty && !is_fetching"
              >По вашему запросу ничего не найдено</span
            >
          </template>
          <!--          <SelectOption-->
          <!--            v-for="option in options"-->
          <!--            :key="option.tin"-->
          <!--            :value="option.value"-->
          <!--            :label="option.value"-->
          <!--            @click="selectTip(option.org)"-->
          <!--          >-->
          <!---->
          <!--          </SelectOption>-->
        </Select>
        <div class="suggest-body" v-if="activeOrganisation">
          <div class="suggest-body__tooltip">
            <InfoIcon fill="#3385FF" type="outlined" />
            Проверьте и подтвердите данные организации, если это не ваша
            организация — выберите другую
          </div>
          <div class="suggest-body__info">
            <div class="info-column">
              <span class="info-column__title">Наименование организации</span>

              {{
                `${isNull(activeOrganisation.legal_form)} ${isNull(
                  activeOrganisation.name,
                )}`
              }}
            </div>
            <div class="info-column">
              <span class="info-column__title">Юридический адрес</span>
              {{ isNull(activeOrganisation.legal_address) }}
            </div>
            <div class="info-row">
              <div class="info-column">
                <span class="info-column__title">ИНН</span>
                {{ isNull(activeOrganisation.tin) }}
              </div>
              <div class="info-column">
                <span class="info-column__title">КПП</span>
                {{ isNull(activeOrganisation.tax_registration_reason) }}
              </div>
              <div
                class="info-column org-status"
                v-if="
                  OrganisationStatus[activeOrganisation.status] !==
                  OrganisationStatus.ACTIVE
                "
              >
                <span class="info-column__title"> Статус организации </span>
                <p>
                  <DangerIcon />
                  {{ OrganisationStatus[activeOrganisation.status] }}
                </p>
              </div>
              <div class="info-column" v-else />
              <div class="info-column">
                <span class="info-column__title">ОКПО</span>
                {{ isNull(activeOrganisation.okpo) }}
              </div>
              <div class="info-column">
                <span class="info-column__title">ОГРН</span>
                {{ isNull(activeOrganisation.bin) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="suggest-footer">
        <Button
          v-if="invalidCompany || orgExist"
          @click="resetOrg"
          size="large"
          type="primary"
        >
          <span v-if="invalidCompany">
            Зарегистрировать другую организацию
          </span>
          <span v-if="orgExist"> Изменить данные </span>
        </Button>

        <Button
          v-else
          @click="toStep"
          size="large"
          type="primary"
          :disabled="!activeOrganisation"
        >
          Подтвердить данные
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
.first-step__card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 48px;
  background-color: white;
  &-header {
    position: relative;
    font-weight: 700;

    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    font-size: 24px;
    line-height: 29px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .info-step-row {
      width: 650px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }

    .info-step-item {
      color: $default-accent;
    }

    .suggest {
      &-input {
        position: relative;
      }
      &-body {
        margin-top: 10px;
        &__tooltip {
          padding: 10px;
          border-radius: 8px;
          background-color: $accent-opacity-1;
          color: $hover-accent;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          column-gap: 5px;
          margin-bottom: 40px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
        }
      }
      &-footer {
        margin-top: 40px;
      }
    }
  }
}

.subInfo {
  font-size: 14px;
  color: #718096;
}

.info {
  &-row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 32px;
    row-gap: 20px;
  }
  &-column {
    &.org-status {
      color: $default-danger;
      p {
        display: flex;
        align-items: center;
        column-gap: 9px;
      }
    }
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    row-gap: 3px;
    color: $gray-800;
    &__title {
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
    }
  }
}

:deep(.ant-select-single) {
  width: 100%;
}
</style>
