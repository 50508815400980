<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue'
import { RightOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { useAuth } from '@/composables/useAuth'
import {notification, Tooltip} from 'ant-design-vue'
import {ModalKeys} from "@/components/ModalSystem/ModalKeys";
import {useModalStore} from "@/components/ModalSystem/ModalSystemStore";
import PhoneBlack from "@/assets/img/phone-black.svg";
import Rustore from "@/assets/img/RustoreHeader.svg";
import {RUSTORE_LINK} from "@/types/globalConst";
import {useRouter} from "vue-router";
import ArrowDown from '../../assets/img/arrow_down.svg'

interface Props {
  name: string,
  userName: string,
  role: any
  status: any
}

defineProps<Props>()
const { logout } = useAuth()
const modalStore = useModalStore()
const isShowProfile = ref(false)
const toggleShowProfile = () => (isShowProfile.value = !isShowProfile.value)
const closeShowProfile = () => (isShowProfile.value = false)
const auth = useAuth()
const isSuperUser = computed(() => !!localStorage.getItem('logout_token'))

const handleOpenFeedbackForm = () => {
  modalStore.openModal(ModalKeys.FeedbackForm, {
    isClosable: true,
    class: 'verification-modal',
    close: confirm,
  })
}

const handleGoToRustore = () => {
  window.location.href = RUSTORE_LINK
}

const handleGoToAdmin = async () => {
  await auth.unmaskUser(localStorage.getItem('logout_token'))
}

const removeLogoutToken = () => {
  localStorage.removeItem('logout_token')
}

const ADMIN_SESSION_MINUTES = 60
const adminTime = ref();
const isFinishAdminTime = ref(false);
const hourTimer = 60 * 1000 * ADMIN_SESSION_MINUTES;
const router = useRouter()

const intervalFunction = (startTime: any) => {
  const intervalId = setInterval(() => {
    const now = new Date().getTime()
    const diff = (startTime + hourTimer - now) / 1000

    if (diff <= 0) {
      isFinishAdminTime.value = true
      clearInterval(intervalId)
      if (localStorage.getItem('logout_token')) {
        notification.info({
          message: 'Время сессии истекло...',
        })
        logout()
      }
    } else {
      const minutes = Math.floor((diff % 3600) / 60)
      const seconds = Math.floor(diff % 60)

      adminTime.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }
  }, 1000)
}

const initSuperUserTimer = () => {
    const storedTime = localStorage.getItem('adminTime')
    if (storedTime === null) {
      const date = new Date()
      const startTime = date.getTime()
      localStorage.setItem('adminTime', startTime.toString())
      intervalFunction(startTime)
    } else if (storedTime === '0') {
      isFinishAdminTime.value = true
    } else {
      const startTime = parseInt(storedTime)
      intervalFunction(startTime)
    }
}

onMounted(() => {
  window.addEventListener('storage', (e) => {
    if (e.key === 'logout_token') {
      initSuperUserTimer();
    }
  });
  initSuperUserTimer();
})

watch(() => isFinishAdminTime.value, () => removeLogoutToken())
</script>

<template>
  <Tooltip
    trigger="hover"
    :visible="!!status?.tooltipText?.length && isOpenTooltip"
  >
    <template #title>
      <span v-if="status?.tooltipText">
        {{ status.tooltipText }}
      </span>
    </template>
    <div
      class="header-profile"
      @click="toggleShowProfile"
      :class="isShowProfile ? 'header-profile--selected' : ''"
      v-click-outside="closeShowProfile"
    >
      <div class="header-profile__icon">
        <TransitionGroup name="profile">
          <img
              v-if="status?.status"
              :alt="status?.alt"
              :class="status?.class"
              :src="status?.src"
              loading="lazy"
          />
        </TransitionGroup>
      </div>
      <div class="header-profile__content">
        <img
          class="header-profile__select-icon"
          :class="isShowProfile ? 'header-profile__select-icon-revert' : ''"
          :src="ArrowDown"
          alt="arrow_down"
          loading="lazy"
        />
      </div>
    </div>
  </Tooltip>
  <div class="header-profile__select" v-if="isShowProfile">
    <div class="header-profile__select-name">
      {{ userName }}
      <div class="header-profile__select-name-role">
        {{ role === 'farmer' ? 'Поставщик' : 'Покупатель' }} • «{{ name }}»
      </div>
    </div>

    <div class="header-profile__select-line"/>
    <router-link class="header-profile__option option__space-between" to="/account">
      <div class="option__title">Управление аккаунтом</div>
      <div><RightOutlined /></div>
    </router-link>
    <div class="header-profile__select-line"/>
    <Button class="header-profile__option option__gap-10" @click="handleOpenFeedbackForm">
      <img :src="PhoneBlack" alt="PhoneBlack" loading="lazy" /> Обратный звонок
    </Button>
    <div class="header-profile__select-line"/>
    <Button class="header-profile__option" @click="handleGoToRustore">
      <div class="option__mobile">
        <div class="option__mobile-title">Мобильное приложение</div>
        <img class="option__mobile-icon" :src="Rustore" alt="Rustore" loading="lazy" />
      </div>
    </Button>
    <div class="header-profile__select-line"/>
    <Button danger type="primary"
            class="header-profile__option header-profile__option_red"
            v-if="isSuperUser"
            :key="adminTime"
            @click="handleGoToAdmin"
    >
      <LogoutOutlined />
      <div class="countdown_return-button">
        <div>Вернуться в админку</div>
        <div>{{adminTime}}</div>
      </div>
    </Button>
    <Button danger type="primary"
      class="header-profile__option header-profile__option_red"
      @click="logout"
    >
      <LogoutOutlined />
      <div>Выйти из аккаунта</div>
    </Button>
  </div>
</template>

<style scoped lang="scss">
.countdown {
  &_return-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}

.option {
  &__mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    &-icon {
      width: 100%;
    }
    &-title {
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  &__gap-10 {
    display: flex;
    gap: 10px;
  }
  &__space-between {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.profile-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.profile-enter-from,
.list-leave-to {
  opacity: 0;
}

.header-profile__icon {
  display: flex;
  height: 32px;
  width: 32px;
  transition: 0.3s all ease-in-out;
}

.modal-text {
  margin-top: 16px;
  margin-bottom: 16px;
}

:deep(.ant-row) {
  display: block;
  margin-bottom: 42px;
}

:deep(.ant-form-item-feedback-icon-success) {
  display: none;
}

.inputMail {
  font-size: 16px;
  height: 40px;
}

.copy-mail {
  height: 40px;
  border: 1px solid #1677ff;
  color: #1677ff;

  :deep(.ant-input) {
    color: #1677ff;
  }
}

.copy-button {
  border: none;

  .copy-icon {
    color: #1677ff;
  }
}

.header-profile {
  display: flex;
  align-items: center;
  padding: 0 4px;
  gap: 8px;
  height: 40px;
  border-radius: 8px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  z-index: 10;
  width: 64px;
  background: #0000000f;

  &:hover {
    cursor: pointer;
    background: #00000026;
  }

  &__content {
    width: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-name {
      padding: 5px 12px 5px 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #2d3748;
    }
    &-name-helper {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #718096;
    }
  }
  &__select {
    position: absolute;
    width: 280px;
    top: 125%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    gap: 4px;
    background: #ffffff;
    box-shadow:
      0 0 1px rgba(12, 26, 75, 0.1),
      0 10px 16px rgba(20, 37, 63, 0.06);
    border-radius: 8px;
    z-index: 9000;

    &-icon {
      cursor: pointer;
      &-revert {
        transform: rotate(180deg);
      }
    }
    &-name {
      padding: 8px 12px 8px 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.88);

      &-role {
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, sans-serif;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    &-line {
      border-bottom: 1px solid #e2e8f0;
      width: 100%;
      height: 1px;
    }
  }
  &__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px 8px 12px;
    width: -webkit-fill-available;
    width: -moz-available;
    min-height: 24px;
    background: #ffffff;
    border-radius: 4px;
    transition: 0.3s;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2d3748;
    text-decoration: none;
    cursor: pointer;
    &_red {
      color: rgba(255, 77, 79, 1);
      border: 1px solid rgba(255, 77, 79, 1);
      justify-content: center;
      display: flex;
      gap: 10px;
      margin: 10px 10px;
      &:hover {
        color: rgba(255, 77, 79, 1) !important;
        background: transparent !important;
      }
    }

    &:hover {
      background: #e6f4ff;
      color: #1677ff;
    }
  }
}
</style>
