<script lang="ts" setup>
import {useRoute} from "vue-router";
import {computed} from "vue";

const route = useRoute()
</script>

<template>
  <div class="error-container">
    <img src="@/assets/img/noCompany.svg" loading="lazy" />

    <div class="title">Компания не существует или была удалена</div>

    <div class="info">
      <div class="text">
        Свяжитесь с тех. поддержкой и мы поможем решить ваш вопрос
      </div>
      <div class="contacts">
        manager@easy-retail.org <br />
        +7 913 871-12-39
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-container {
  margin: 155px auto auto;
  width: 461px;
  height: 346px;
  text-align: center;

  img {
    width: 220px;
    height: 220px;
    margin-bottom: 24px;
  }

  .title {
    text-wrap: nowrap;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .info {
    margin-top: 8px;
    text-wrap: nowrap;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #000000a6;

    .contacts {
      color: #1677ff;
    }
  }
}
</style>
