<script lang="ts" setup>

import { Button } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

interface IProps {
  title: string
  buttonText: string
  buttonType: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])
</script>

<template>
  <div class="auction-header">
    <div class="auction-header__title">{{ props.title }}</div>
    <Button :type="buttonType" @click="emit('onBtnClick')" size="large">
      <template #icon v-if="buttonType === 'default'">
        <InfoCircleOutlined />
      </template>
      {{ props.buttonText }}</Button
    >
  </div>
</template>

<style lang="scss" scoped>
.auction-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #000000e0;
  }
}
</style>
