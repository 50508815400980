import { IProduct } from '@/types/interfaces'
import { defineStore } from 'pinia'

interface IUseRedirectAddProductStore {
  product: IProduct
  isRedirected: boolean
}

export const useRedirectAddProductStore = defineStore(
  'useRedirectAddProductStore',
  {
    state: (): IUseRedirectAddProductStore => ({
      product: {} as IProduct,
      isRedirected: false,
    }),
    actions: {
      setProduct(product: IProduct) {
        this.product = product
      },
      setIsRedirected(isRedirected: boolean) {
        this.isRedirected = isRedirected
      },
    },
  },
)
