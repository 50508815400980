<script setup lang="ts">


interface Props {
  title: string
  subtitles: string[]
}

const props = defineProps<Props>()
</script>

<template>
  <div class="block">
    <span class="title">{{ props?.title }}</span>
    <span v-for="subtitle in props?.subtitles" :key="subtitle">{{
      subtitle
    }}</span>
  </div>
</template>

<style scoped lang="scss">
.block {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    font-weight: 600;
  }
}
</style>
