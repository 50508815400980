<script lang="ts" setup>
import {
  IReductionProductAnswer,
  IReductionProductRequest,
} from '@/types/interfaces'
import { computed } from 'vue'
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { Button, Flex, Table, TypographyText as TpText } from 'ant-design-vue'
import { priceWithVat, roundNumber } from '@/helpers/scripts'
import BetsResultFooter from '@/components/BestResultFooter/BetsResultFooter.vue'

interface IProps {
  products: IReductionProductRequest[]
  myParticipantId: number
  isClosedReduction: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])
const { openModal } = useModalStore()
const columns = [
  {
    title: 'Товар',
    dataIndex: 'product_name',
    key: 'product_name',
    // width: '50%',
  },
  {
    title: 'Лучшая ставка',
    dataIndex: 'best_bet',
    key: 'best_bet',
    width: '15%',
  },
  {
    title: 'Ваша ставка',
    dataIndex: 'your_bet',
    key: 'your_bet',
    width: '27%',
  },
]

const getMyProductAnswer = (productAnswers: IReductionProductAnswer[]) => {
  return productAnswers.find(
    (productAnswer) =>
      productAnswer.reduction_participant_id === props.myParticipantId,
  )
}

const getAnswerStatus = (requestProduct: IReductionProductRequest) => {
  if (!requestProduct.has_in_catalogue) {
    return 'no_product'
  }
  return requestProduct?.reduction_product_answers.some(
    (answer) => answer.reduction_participant_id === props.myParticipantId,
  )
    ? 'answered'
    : 'waiting'
}

const getRoundingNumber = (price: number) => {
  return price % 1 !== 0 ? price.toFixed(2) : price
}

const getRowClass = (record: IReductionProductRequest) => {
  return record.has_in_catalogue ? '' : 'row__grey'
}

const sortedProducts = computed(() => {
  const products_has_catalogue = props.products
    .filter((product) => product.has_in_catalogue)
    .sort((productA, productB) =>
      productA.product_name.localeCompare(productB.product_name),
    )
  const products_not_has_catalogue = props.products
    .filter((product) => !product.has_in_catalogue)
    .sort((productA, productB) =>
      productA.product_name.localeCompare(productB.product_name),
    )
  return [...products_has_catalogue, ...products_not_has_catalogue]
})

const fillMeasurementUnitId = (item: any) => {
  if (item.measurement_unit_id === null) {
    const productText = item.fullName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    } else return '-'
    return item.measurement_unit_id
  } else return item.measurement_unit_id
}

const bestBetsSum = props?.products.reduce((acc, record) => {
  if (record.best_bet) {
    const pricePerUnit = Number(record.best_bet.price_per_unit)
    const volume = Number(record.volume)
    const vat = Number(record.best_bet.vat)
    return acc + pricePerUnit * volume
  }
  return acc
}, 0)

const yourBetsSum = props?.products.reduce((acc, record) => {
  const myProductAnswer = getMyProductAnswer(record.reduction_product_answers)
  if (myProductAnswer) {
    const pricePerUnit = Number(myProductAnswer.price_per_unit)
    const volume = Number(record.volume)
    const vat = Number(myProductAnswer.vat)
    return acc + pricePerUnit * volume
  }
  return acc
}, 0)

const handleOpenAddProduct = (reduction: IReductionProductRequest) => {
  openModal(ModalKeys.RedirectToAddProduct, {
    product: reduction.product,
    isFromReduction: true,
  })
}
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :data-source="sortedProducts"
      :pagination="false"
      bordered
      :rowClassName="getRowClass"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'product_name'">
          <Flex :gap="8">
            <div>
              <ClockCircleFilled
                v-if="getAnswerStatus(record) === 'waiting'"
                :style="{ color: 'rgba(250, 173, 20, 1)' }"
              />
              <CheckCircleFilled
                v-else-if="getAnswerStatus(record) === 'answered'"
                :style="{ color: 'rgba(82, 196, 26, 1)' }"
              />
              <ExclamationCircleFilled
                v-else-if="getAnswerStatus(record) === 'no_product'"
                :style="{ color: 'rgba(0, 0, 0, 0.45)' }"
              />
            </div>
            <ProductWithProperties
              :volume="record.volume"
              :remaining_shelf_life="record.remaining_shelf_life"
              :measurement_unit_id="fillMeasurementUnitId(record.product)"
              :name="record.product_name"
              :is_sample_needed="record.is_sample_needed"
              :needed_samples_number="record.needed_samples_number"
            />
          </Flex>
        </template>

        <template v-if="column.key === 'best_bet'">
          <Flex v-if="!!record.best_bet" :gap="4" vertical>
            <TpText strong>
              {{
                `${roundNumber(record?.best_bet.price_per_unit)}
              ₽/${fillMeasurementUnitId(record.product)}`
              }}
            </TpText>
            <TpText type="secondary">
              {{ `НДС ${record.best_bet.vat}%` }}
            </TpText>
          </Flex>
          <TpText v-else type="secondary">-</TpText>
        </template>

        <template v-if="column.key === 'your_bet'">
          <Flex justify="space-between">
            <Flex
              v-if="getMyProductAnswer(record.reduction_product_answers)"
              :gap="4"
              vertical
            >
              <TpText strong>
                {{
                  `${roundNumber(
                    Number(
                      getRoundingNumber(
                        Number(
                          getMyProductAnswer(record.reduction_product_answers)
                            ?.price_per_unit,
                        ),
                      ),
                    ),
                  )}
                ₽/${fillMeasurementUnitId(record.product)}`
                }}
              </TpText>
              <TpText type="secondary">{{
                `НДС ${getMyProductAnswer(record.reduction_product_answers)?.vat}%`
              }}</TpText>
            </Flex>
            <TpText v-else type="secondary">-</TpText>
            <Button
              v-if="record.has_in_catalogue && !isClosedReduction"
              @click="emit('setRequestId', record.id)"
              type="primary"
              >Сделать ставку</Button
            >
            <Flex v-else-if="!record.has_in_catalogue" :vertical="true">
              <TpText type="secondary">Нет в вашем каталоге</TpText>
              <Button @click="handleOpenAddProduct(record)" type="primary">
                Добавить товар
              </Button>
            </Flex>
          </Flex>
        </template>
      </template>
      <template #footer>
        <BetsResultFooter
          :best-bets-sum="bestBetsSum"
          :your-bets-sum="yourBetsSum"
        />
      </template>
    </Table>
  </div>
</template>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}

:deep(.row__grey) {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
