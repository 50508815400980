import { useReCaptcha } from 'vue-recaptcha-v3'
import { UserAPI } from '@/api/UserAPI'
import { onMounted } from 'vue'

export function useCaptcha() {
  const { executeRecaptcha, recaptchaLoaded, instance }: any = useReCaptcha()

  const recaptcha = async () => {
    await recaptchaLoaded()
    return await executeRecaptcha('login')
  }

  const getCaptchaSiteKey = async () => {
    if (!instance.value) return
    instance.value.siteKey = (
      await UserAPI.getCaptcha()
    ).data?.action_result?.data
  }

  onMounted(async () => {
    await getCaptchaSiteKey()
  })

  return { recaptcha }
}
