import { useCookies } from 'vue3-cookies'
import axios from 'axios'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import { removeQuotes } from '@/assets/calendar/helpers'
import { useRouter } from 'vue-router'

export function useAuth() {
  const { cookies } = useCookies()
  const { getCoreCookie } = useJWTDecode()
  const router = useRouter()

  const register = (userData: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${import.meta.env.VITE_BASE_URL}/auth/User/register`, userData)
        .then((res: any) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const logout = async () => {
    localStorage.clear()
    cookies.remove('auth')
    cookies.remove('core')
    cookies.remove('onec')
    cookies.remove('chat')
    cookies.remove('umt')

    window.location.href = '/'
  }

  const relogin = async () => {
    await axios({
      url: `${import.meta.env.VITE_BASE_URL}/auth/User/loginToService`,
      method: 'POST',
      data: {
        service_name: 'core',
        token: localStorage.getItem('umt'),
      },
    })
      .then((response) => {
        console.log('remove core cookie')
        cookies.remove('core')
        cookies.set('core', response.data.action_result.data)
        console.log('set core cookie:', response.data.action_result.data)
      })
      .catch((error) => {
        return error
      })
  }

  const loginInAllServices = async (data: any, redirectAfter = false) => {
    await loginToService(data, false, 'auth')
    await loginToService(data, false, 'chat')
    await loginToService(data, false, 'onec')
    await loginToService(data, redirectAfter, 'core')

    const actionResData = data?.data?.action_result?.data;

    if (actionResData.logout_token) localStorage.setItem('logout_token', actionResData.logout_token)
    localStorage.setItem('umt', actionResData.user_master_token)
    localStorage.setItem('umrt', actionResData.user_master_refresh_token)
  }

  const putUserMask = async (email: string) => {
    // Флоу СуперАдмин получение токенов пользователя для авторизации под ним
    return new Promise((resolve, reject) => {
      return axios.post(`${import.meta.env.VITE_BASE_URL}/auth/User/putUserMask`, {
        attributes: { email }
      }, {
        headers: {
          Authorization: cookies.get('auth')
        }
      }).then(async (data: any) => {
        await loginInAllServices(data, true)
        resolve(data)
      }).catch((e) => {
        console.error('putUserMask error occurred:', e)
        reject(e)
      })
    })
  }

  const unmaskUser = async (logout_token: string) => {
    // Флоу СуперАдмин получение токенов суперАдмина
    return new Promise((resolve, reject) => {
      return axios.post(`${import.meta.env.VITE_BASE_URL}/auth/User/unmask`, {
        attributes: { logout_token }
      },{
        headers: {
          Authorization: cookies.get('auth')
        }
      }).then(async (data: any) => {
        await loginInAllServices(data, true)
        resolve(data)
      }).catch(async (e) => {
        console.error('unmaskUser error occurred:', e)
        reject(e)
        await logout()
      }).finally(() => {
        localStorage.removeItem('logout_token')
        localStorage.removeItem('adminTime')
        localStorage.removeItem('isTimerStarted')
      })
    })
  }

  const login = (
    userData: { password: string; captcha: null; email: string },
    redirectAfter = false,
  ) => {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${import.meta.env.VITE_BASE_URL}/auth/User/login`, userData)
        .then(async (data) => {
          await loginInAllServices(data, redirectAfter)
          resolve(data)
        })
        .catch(async (err) => {
          const error = {
            email: 'Неверная почта или пароль',
            password: 'Неверная почта или пароль',
            blockLogin: false,
            confirmEmail: false,
            deleteToken: false,
          }

          if (
            err?.response?.data?.action_error?.message ===
            'User email is not confirmed'
          ) {
            console.error('User email is not confirmed')
            await router.push('/confirm-email')
          }

          if (err[0]?.data?.action_error?.code === 422) {
            error.blockLogin = true
          }

          if (err[0]?.data?.action_error?.code === 405) {
            error.confirmEmail = true
          }

          if (
            err[0]?.data?.action_error?.message === 'Unable to decode token!'
          ) {
            await logout()
          }

          reject(error)
        })
    })
  }

  const loginToService = (
    data: any,
    redirectAfter = false,
    service_name: string,
  ): Promise<any> => {
    let umt = data?.data?.action_result?.data?.user_master_token
    umt = removeQuotes(umt)

    const loginData = {
      service_name: service_name,
      token: umt,
    }
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${import.meta.env.VITE_BASE_URL}/auth/User/loginToService`,
          loginData
        )
        .then(async (data) => {
          // @ts-ignore
          const actionRes = data.data.action_result.data
          cookies.set(service_name, actionRes)
          if (redirectAfter) {
            // Для админа после авторизации происходит редирект на страницу пользователей
            if (getCoreCookie()?.company_type === Roles.Admin || getCoreCookie()?.company_type === Roles.SuperAdmin) {
             await router.push('/admin/users')
            } else {
              await router.push('/')
            }
          }
          resolve(data)
        })
        .catch((error) => {
          reject(error)
          throw new Error(error.message)
        })
    })
  }

  const loginIntoService = (
    master_token: any,
    service_name: string,
  ): Promise<any> => {
    const cleanUmt = removeQuotes(master_token)

    const loginData = {
      service_name: service_name,
      token: cleanUmt,
    }
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${import.meta.env.VITE_BASE_URL}/auth/User/loginToService`,
          loginData
        )
        .then((data) => {
          const token = data?.data?.action_result?.data
          document.cookie = `${service_name}=${token};max-age=604800;`
          resolve(data)
        })
        .catch((error) => {
          reject(error)
          throw new Error(error.message)
        })
    })
  }

  const isLoggedIn = () => {
    return cookies.get('core')
  }

  return {
    register,
    login,
    loginToService,
    logout,
    isLoggedIn,
    relogin,
    loginIntoService,
    putUserMask,
    unmaskUser
  }
}
