<script lang="ts" setup>
import {computed} from 'vue'
import {InfoCircleOutlined} from '@ant-design/icons-vue'
import {getUserIdTemporary} from '@/helpers/scripts'
import {useJWTDecode} from '@/composables/useJWTDecode'
import {IFarmerProduct} from '@/types/interfaces'
import {Popover, Tag} from 'ant-design-vue'
import dayjs from 'dayjs'

const userId = getUserIdTemporary()

interface Props {
  product: IFarmerProduct
}
const props = defineProps<Props>()

// Находим спеццену для пользователя
// Показываем в разметке спеццены коммент и теги по кол-ву и до какого числа
const customerSpecialPrice = computed(() => {
  return props.product.special_prices?.find(
    (special) => special.customer_id === userId,
  )
})

// Проверяем список цен товара и выбираем наименьшую цену.
const checkPriceOptions = computed(() => {
  if (!props.product.price_options || props.product.price_options.length === 0) return 0;
  return props.product.price_options.reduce((prev, curr) => {
    // Если prev не определен, возвращаем curr
    if (!prev) return curr;
    // Сравниваем цены и возвращаем меньший
    return (Number(prev.price) < Number(curr.price)) ? prev : curr;
  });
});
</script>

<template>
  <div v-if="!product.is_deleted" class="product-list__card">
    <div class="product-list__text">
      <p>{{ product.product.fullName }}</p>
      <div class="info">
        <p class="price">
          {{ product.price_options?.length > 1 ? 'От' : '' }}
          <span v-if="customerSpecialPrice">
            {{ customerSpecialPrice?.price }}
            ₽/{{ product.measurement_unit_id }}
          </span>
          <span v-else>
            {{ checkPriceOptions.price }}
            ₽/{{ product.measurement_unit_id }}
          </span>
          <Popover
            title="Комментарий"
            v-if="checkPriceOptions.comment || customerSpecialPrice?.comment"
            placement="top"
          >
            <!-- Показываем коммент спеццены или просто коммент  -->
            <template #content>
              {{
                customerSpecialPrice?.comment
                  ? customerSpecialPrice?.comment
                  : checkPriceOptions.comment
              }}
            </template>
            <InfoCircleOutlined :style="{ color: '#1677ff' }" />
          </Popover>
        </p>
        <div class="tags" v-if="customerSpecialPrice">
          <Tag v-if="customerSpecialPrice?.expires_after" color="success">
            Спеццена до
            {{
              dayjs(customerSpecialPrice?.expires_after).format('DD.MM.YYYY')
            }}
          </Tag>
          <Tag v-if="customerSpecialPrice?.quantity" color="success">
            {{ customerSpecialPrice?.quantity }}
            {{ product.measurement_unit_id }}
          </Tag>
          <Tag v-else color="success"> Спеццена </Tag>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.product-list {
  &__card {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 14px;
    padding-top: 14px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
    row-gap: 8px;

    .info {
      display: flex;

      .price {
        font-weight: 700;
        color: $gray-800;

        :deep(.anticon) {
          padding-left: 6px;
          padding-right: 0;
        }
      }

      .tags {
        margin: auto;

        :deep(.ant-tag-success) {
          padding-left: 8px;
          margin-left: 8px;
          margin-right: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
