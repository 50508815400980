<script lang="ts" setup>

import { splitNumberByThreeDigits } from '@/helpers/scripts'

interface Props {
  allSum: string
}
const props = defineProps<Props>()
</script>

<template>
  <div class="footer">
    <span>Итого: {{ splitNumberByThreeDigits(props.allSum) }} ₽</span>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  position: fixed;
  box-sizing: border-box;
  padding: 30px 32px;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: white;

  display: flex;
  justify-content: flex-end;

  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);

  & span {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}
</style>
