<script setup lang="ts">
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { IAccountUser } from '@/types/interfaces'
import { computed, defineProps, h, reactive, ref } from 'vue'
import {
  Form,
  FormInstance,
  FormItem,
  Input,
  Textarea,
  Button,
  notification,
} from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { onlyNumbersPhone, transformPhoneNumber } from '@/helpers/scripts'
import axios from 'axios'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { emailRegEx, phoneMask } from '@/types/globalConst'
import { format } from '@/helpers/validators'

interface IProps {
  show: boolean
  showCloseIcon: IAccountUser | Record<string, string>
  class: string
}

interface IFormState {
  email: string
  phone: string
  fio: string
  companyName: string
  comment: string
  honey: string
}

const mailInvalid = ref(false)
const formRef = ref<FormInstance>()
const props = defineProps<IProps>()
const emit = defineEmits(['close'])
// TODO: Доработать, брать из useUserAuthStore
const userStr = localStorage.getItem('user')
const userDataStr = localStorage.getItem('userData')
const user = userStr ? JSON.parse(userStr) : null
const userData = userDataStr ? JSON.parse(userDataStr) : null

const formState = reactive<IFormState>({
  email: user?.email || '',
  phone: user?.phone || '',
  fio: user?.name || '',
  companyName:
    userData?.legal_form && userData?.name
      ? `${userData?.legal_form} ${userData?.name}`
      : '',
  comment: '',
  honey: '',
})

const errors = reactive({
  email: false,
  phone: false,
  fio: false,
  companyName: false,
  comment: false,
})

const validateMail = async (_rule: Rule, value: string) => {
  if (value) {
    const emailRegex =
      /^[A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+[A-Za-z]$/
    if (!emailRegex.test(value)) {
      errors.email = true
      return Promise.reject('Введите корректную электронную почту')
    } else if (mailInvalid.value) {
      errors.email = true
      return Promise.reject(
        'Почта не подходит или уже используется другим пользователем',
      )
    } else {
      errors.email = false
      return Promise.resolve()
    }
  }
}

const validatePhone = async (_rule: Rule, value: string) => {
  if (onlyNumbersPhone(value ?? '').length != 11) {
    if (!value) {
      errors.phone = false
      Promise.resolve().then()
    } else {
      errors.phone = true
      return Promise.reject('Минимум 11 цифр')
    }
  } else {
    errors.phone = false
    return Promise.resolve()
  }
}

const fioValidator = async (_rule: Rule, value: string) => {
  if (!value) {
    errors.fio = true
    return Promise.reject()
  }
  if (value.trim().length < 1) {
    errors.fio = true
    return Promise.reject('Минимум 1 символ')
  }
  if (value.length > 255) {
    errors.fio = true
    return Promise.reject('Максимум 255 символов')
  }
  errors.fio = false
  return Promise.resolve()
}

const companyNameValidator = async (_rule: Rule, value: string) => {
  if (!value) {
    errors.companyName = true
    return Promise.reject()
  }
  if (value.trim() === '') {
    errors.companyName = true
    return Promise.reject('Введите название компании')
  }
  if (value.length > 255) {
    errors.companyName = true
    return Promise.reject('Максимум 255 символов')
  }
  if (value.length < 1) {
    errors.companyName = true
    return Promise.reject('Минимум 1 символ')
  }
  errors.companyName = false
  return Promise.resolve()
}

const commentValidator = async (_rule: Rule, value: string) => {
  if (value && value.length > 5000) {
    errors.comment = true
    return Promise.reject('Максимум 5000 символов')
  }
  errors.comment = false
  return Promise.resolve()
}

const rules: Record<string, Rule[]> = {
  email: [
    { validator: validateMail, trigger: 'change' },
    { required: true, message: 'Введите электронную почту' },
  ],
  phone: [
    { validator: validatePhone, trigger: 'change' },
    { required: true, message: 'Введите номер телефона' },
  ],
  fio: [
    { validator: fioValidator, trigger: 'change' },
    { required: true, message: 'Введите ФИО' },
  ],
  companyName: [
    { validator: companyNameValidator, trigger: 'change' },
    { required: true, message: 'Введите название компании' },
  ],
  comment: [{ validator: commentValidator, trigger: 'change' }],
}

const handleClose = async () => {
  emit('close')
}

const handleSubmit = async () => {
  formRef.value
    ?.validateFields(['email', 'phone', 'fio', 'companyName'])
    .then(async () => {
      if (formState.honey) {
        return
      }
      await axios
        .post(`${import.meta.env.VITE_BASE_URL}/core/Feedback/create`, {
          attributes: {
            email: formState.email,
            phone: formState.phone,
            contact_name: formState.fio,
            company_name: formState.companyName,
            comment: formState.comment,
          },
        })
        .then(() => {
          emit('close')
          notification.info({
            message: `Заявка успешно отправлена`,
            top: '80px',
            icon: h(InfoCircleFilled, {
              style: { color: 'rgba(22, 119, 255, 1)' },
            }),
          })
        })
        .catch(() => {
          notification.error({
            message: 'Произошла ошибка при отправке заявки',
            top: '80px',
          })
        })
    })
}

const handleChangePhone = (e: any) => {
  formState.phone = transformPhoneNumber(e?.target?.value).trim()
}
const handleChangeCompanyName = (e: any) => {
  formState.companyName = e?.target?.value
}
const handleChangeMail = (e: any) => {
  formState.email = e?.target?.value.trim()
}
const handleChangeFio = (e: any) => {
  formState.fio = e?.target?.value
}
const handleChangeComment = (e: any) => {
  formState.comment = e?.target?.value
}
</script>

<template>
  <ModalWindow
    class="feedback-form"
    :show="props.show"
    :show-close-icon="props.showCloseIcon"
    @close="handleClose"
  >
    <template #header> Поможем решить любой вопрос </template>
    <template #body>
      <div class="form__subtitle">
        Пожалуйста, оставьте свои контакты и мы свяжемся с вами в удобное вам
        время.
      </div>
      <Form ref="formRef" :model="formState" :rules="rules" layout="vertical">
        <div class="form__body">
          <div class="form__body-left">
            <FormItem label="Название компании" name="companyName" required>
              <Input
                placeholder="Введите название компании"
                class="mail"
                :value="formState.companyName"
                @change="handleChangeCompanyName"
              />
            </FormItem>
            <FormItem label="ФИО" name="fio" required class="ym-record-keys">
              <Input
                placeholder="Введите ФИО"
                class="mail"
                :value="formState.fio"
                @change="handleChangeFio"
              />
            </FormItem>
          </div>
          <div class="form__body-right">
            <FormItem label="Электронная почта" name="email" required class="ym-record-keys">
              <Input
                placeholder="Введите электронную почту"
                class="mail"
                :value="formState.email"
                @change="handleChangeMail"
              />
            </FormItem>
            <FormItem label="Телефон" name="phone" required>
              <Input
                placeholder="Введите номер телефона"
                class="mail"
                :value="formState.phone"
                @change="handleChangePhone"
                v-maska="{
                  mask: phoneMask,
                  preprocessor: (value: string) => transformPhoneNumber(value),
                }"
              />
            </FormItem>
          </div>
        </div>
        <FormItem label="Комментарий" name="comment">
          <Textarea
            placeholder="Когда вы готовы принять звонок? Что Вы хотели бы обсудить?"
            class="mail"
            :value="formState.comment"
            @change="handleChangeComment"
          />
        </FormItem>
        <FormItem name="honey" :hidden="true">
          <Textarea
            placeholder="Когда вы готовы принять звонок? Что Вы хотели бы обсудить?"
            class="mail"
            v-model:value="formState.honey"
          />
        </FormItem>
        <div class="form__footer">
          <Button
            :disabled="
              errors.email ||
              errors.phone ||
              errors.fio ||
              errors.companyName ||
              errors.comment
            "
            type="primary"
            @click="handleSubmit"
            >Заказать звонок</Button
          >
        </div>
      </Form>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.form {
  &__subtitle {
    color: $gray-800;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  &__body {
    display: flex;
    gap: 10px;

    &-left {
      width: 50%;
    }

    &-right {
      width: 50%;
    }
  }
  &__footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.feedback-form {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 32px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $gray-800;
    }
    .modal-body {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: $gray-800;
    }
  }
}

.wrapper {
  @include wrapper();
}
</style>
