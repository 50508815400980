<script lang="ts" setup>
import { computed } from 'vue'

interface IProps {
  product: {
    type: object
    required: true
    product_name: string
    customer_needed_volume: number
    remaining_shelf_life: number
    is_sample_needed: boolean
    needed_samples_number: number
  }
}

const props = defineProps<IProps>()

const isShowVolume = computed(
  () =>
    !!props?.product?.customer_needed_volume &&
    !!props.product?.product?.measurement_unit_id,
)

const isShowRemainingShelfLife = computed(
  () => !!props.product?.remaining_shelf_life,
)

const isSampleNeeded = computed(
  () =>
    !!props.product?.is_sample_needed &&
    !!props.product?.product?.measurement_unit_id,
)

const calcVolume = () =>
  `Объем ${props?.product?.customer_needed_volume} ${props.product?.product?.measurement_unit_id}`

const calcRemainingShelfLife = () =>
  `Срок хранения ${props.product?.remaining_shelf_life} %`

const calcIsSampleNeeded = () => {
  if (isSampleNeeded.value) {
    if (
      props?.product?.needed_samples_number &&
      props.product?.product?.measurement_unit_id
    ) {
      return `Пробный образец ${props?.product?.needed_samples_number} ${props?.product?.product?.measurement_unit_id}`
    } else if (!props?.product?.needed_samples_number) {
      return `Пробный образец`
    }
  } else return ''
}
</script>

<template>
  <div class="product-list-item">
    <div class="product-list-item_title">
      {{ props.product?.product_name }}
    </div>
    <div class="product-list-item_body">
      <div class="product-list-item_body__volume" v-if="isShowVolume">
        {{ calcVolume() }}
      </div>
      <div
        class="product-list-item_body__storage-period"
        v-if="isShowRemainingShelfLife"
      >
        {{ calcRemainingShelfLife() }}
      </div>
      <div class="product-list-item_body__is-sample">
        {{ calcIsSampleNeeded() }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.product-list-item {
  border-bottom: 1px solid #0000000f;
  margin-left: 20px;
  margin-top: 20px;

  &_title {
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
  &_body {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;

    &__volume {
      font-size: 14px;
      line-height: 22px;
      color: #000000a6;
      font-weight: 400;
      font-family: Inter, sans-serif;
      border-right: 1px solid #0000000f;
      padding-right: 10px;
    }
    &__storage-period {
      font-size: 14px;
      line-height: 22px;
      color: #000000a6;
      font-weight: 400;
      font-family: Inter, sans-serif;
      border-right: 1px solid #0000000f;
      padding-right: 10px;
    }
    &__is-sample {
      font-size: 14px;
      line-height: 22px;
      color: #000000a6;
      font-weight: 400;
      font-family: Inter, sans-serif;
    }
  }
}
</style>
