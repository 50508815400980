<script setup lang="ts">
import { Flex, Space, TypographyText as TgText } from 'ant-design-vue'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import { MessageOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'
import { IGeneralOrder } from '@/types/interfaces'
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'

interface IProps {
  comment: string
  isFromFarmer?: boolean
  isFromCustomer?: boolean
  history: IGeneralOrder
}
const props = defineProps<IProps>()

const old_comment = computed(() => {
  if (props.history && props.history.comment !== props.comment) {
    return props.history.comment
  }
  return null
})
</script>

<template>
  <Flex :gap="8" vertical>
    <Space :size="6">
      <MessageOutlined :style="iconStyle" />
      <TgText strong v-if="isFromCustomer">
        Комментарий к заказу от покупателя</TgText
      >
      <TgText strong v-if="isFromFarmer"
        >Комментарий к заказу от поставщика</TgText
      >
    </Space>

    <TextWithOldPopover
      v-if="props.history?.comment !== props.comment && props.history"
      :text="comment"
      :old_text="old_comment || 'Без комментария'"
    />

    <Flex :gap="4" vertical v-else>
      <TgText> {{ comment }} </TgText>
    </Flex>
  </Flex>
</template>

<style scoped lang="scss"></style>
