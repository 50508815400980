<script setup lang="ts">
import { Flex, Pagination, Table } from 'ant-design-vue'
import { ArrowRightOutlined } from '@ant-design/icons-vue'
import OrderStatusTag from '@/components/Tags/OrderStatusTag.vue'
import { ref, watch } from 'vue'
import {dateToLocale, roundNumber} from '@/helpers/scripts'
import router from '@/router'
import dayjs from 'dayjs'

interface Props {
  orders: any // TODO: Заменить any
}

const emit = defineEmits(['paginationChange'])

const props = defineProps<Props>()
const ordersData = ref([])
const page = ref(1)
const per_page = ref(10)
const total_count = ref(0)

const columns = [
  {
    title: 'Покупатель',
    key: 'customer',
    width: '280px',
  },
  {
    title: 'Поставщик',
    key: 'farmer',
    width: '280px',
  },
  {
    title: 'Статус заказа',
    key: 'status',
    width: '212px',
  },
  {
    title: 'Сумма заказа',
    key: 'price',
    width: '140px',
  },
  {
    key: 'dates',
    width: '228px',
  },
]

const change_page = () => {
  emit('paginationChange', { page: page.value, per_page: per_page.value })
}

const goToOrder = (id: number) => {
  router.push(`/admin/orders/${id}`)
}

const goToUser = (id: string) => {
  router.push(`/admin/users/${id}`)
}

watch(props, () => {
  const data = props.orders?.data

  ordersData.value = props.orders
  page.value = data?.current_page
  per_page.value = data?.per_page
  total_count.value = data?.total_count
})
</script>

<template>
  <div>
    <div class="table-wrapper">
      <Table
        :customRow="
          (record) => {
            return {
              onClick: () => {
                goToOrder(record.id)
              },
            }
          }
        "
        :pagination="false"
        :columns="columns"
        :data-source="ordersData.data?.items"
        row-key="id"
        sticky
        class="table"
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'dates'">
            Дата создания <ArrowRightOutlined class="arrow" /> получения
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'customer'">
            <p
              class="link-to-user"
              @click.stop="goToUser(record.customer?.users[0].id)"
            >
              {{ record.customer?.legal_form }} «{{ record.customer?.name }}»
            </p>
          </template>
          <template v-if="column.key === 'farmer'">
            <p
              class="link-to-user"
              @click.stop="goToUser(record.farmer?.users[0].id)"
            >
              {{ record.farmer?.legal_form }} «{{ record.farmer?.name }}»
            </p>
          </template>
          <template v-if="column.key === 'status'">
            <div class="tag-data">
              <OrderStatusTag :status="record.status" />
              {{ dayjs(record.createdAt).format('DD.MM.YYYY') }}
            </div>
          </template>
          <template v-if="column.key === 'price'">
            {{ roundNumber(record.price) }} ₽
          </template>
          <template v-if="column.key === 'dates'">
            {{ dateToLocale(record.created_at) }}
            <ArrowRightOutlined class="arrow" />
            {{ dateToLocale(record.general_order.delivery_date) }}
          </template>
        </template>
      </Table>
    </div>
    <Flex gap="8" align="center" v-if="total_count > 10">
      <Pagination
        v-model:current="page"
        v-model:page-size="per_page"
        :total="total_count"
        show-size-changer
        @change="change_page"
      >
        <template #buildOptionText="props">
          <span>{{ props.value }}</span>
        </template>
      </Pagination>
      элементов
    </Flex>
  </div>
</template>

<style scoped lang="scss">
.table-wrapper {
  height: calc(100vh - 55px - 76px - 125px);
  overflow-y: auto;
  margin-bottom: 16px;
}
.link-to-user {
  width: 100%;
  word-break: break-word;
  line-height: 1.5;
  color: #1677ff;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.link-to-user:hover {
  color: #69b1ff;
}

.table {
  margin-bottom: 24px;
}

:deep(.ant-table-row) {
  cursor: pointer;
}

.arrow {
  margin-right: 2px;
  margin-left: 2px;
  max-height: 10px;
}

.ant-btn-link {
  padding-left: 0;
}

.tag-data {
  display: grid;
  gap: 4px;

  .ant-tag {
    width: fit-content;
  }
}

:deep(.ant-pagination) {
  width: 100%;
}
:deep(.ant-pagination-options) {
  float: right;
  &::before {
    content: 'Показывать на странице';
    display: inline-block;
    margin-right: 8px;
  }
}
</style>
