import { defineStore } from 'pinia'
import { ReductionAPI } from '@/api/ReductionAPI'
import { IReduction } from '@/types/interfaces'

interface IReductionStore {
  reductions: IReduction[]
  isFetching: boolean
  isMoreFetching: boolean
  page: number
  defaultFilters: (string | number)[][]
}

export const reductionStore = defineStore('reductionStore', {
  state: (): IReductionStore => ({
    reductions: [],
    isFetching: false,
    isMoreFetching: false,
    page: 1,
    defaultFilters: [],
  }),
  actions: {
    switchReduction(id: number) {
      this.reductions = this.reductions.map((reduction) => {
        if (reduction.id === id) {
          return {
            ...reduction,
            switch: !!0,
          }
        }
        return reduction
      })
    },
    async getReductions() {
      const isMoreFetching = this.page > 1
      if (isMoreFetching) {
        this.isMoreFetching = true
      } else {
        this.isFetching = true
      }
      try {
        const res = await ReductionAPI.getItemsWithBetter(
          this.page,
          [...this.defaultFilters],
          [
            'best_farmer',
            'customer',
            'customer.company_rating',
            'reduction_product_requests',
            'reduction_participants',
            'mergedRecipients.farmer',
          ],
        )
        if (isMoreFetching) {
          this.reductions = [...this.reductions, ...res]
        } else {
          this.reductions = res
        }
        this.isFetching = false
        this.isMoreFetching = false
      } catch (error) {
        this.isFetching = false
        this.isMoreFetching = false
        console.error(error)
      }
    },
    increasePage() {
      this.page += 1
      this.getReductions()
    },
    setDefaultFilters(filters: (string | number)[][]) {
      this.defaultFilters = [...filters]
    },
    $reset() {
      this.reductions = []
      this.isFetching = false
      this.isMoreFetching = false
      this.page = 1
      this.defaultFilters = []
    },
  },
})
