<script setup lang="ts">
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import { computed, onMounted, ref, watch } from 'vue'
import router from '@/router'
import {
  IAnswerPriceOption,
  IAnswerSpecialPrice,
  IFarmerProduct,
  IProductAnswer,
} from '@/types/interfaces'
import { useRoute } from 'vue-router'
import { ProductAnswerAPI } from '@/api/ProductAnswerAPI'
import ProductResponseHeader from '@/views/Farmer/productResponse/ProductResponseHeader.vue'
import ProductResponseFooter from '@/views/Farmer/productResponse/ProductResponseFooter.vue'
import ProductToolbarWidget from '@/views/Farmer/productResponse/ProductToolbar/ProductToolbarWidget.vue'
import AnswerPriceOptionsTable from '@/views/Farmer/productResponse/answerPriceOptionsTable/AnswerPriceOptionsTable.vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import SpecialPriceDrawer from '@/views/Farmer/productResponse/SpecialPriceDrawer.vue'
import { SpecialPriceAPI } from '@/api/SpecialPriceAPI'
import { TProductAnswerStatus } from '@/types/types'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import {useCookies} from "vue3-cookies";
import emitter from "@/emitter";

const route = useRoute()

const productAnswer = ref<IProductAnswer>()
const priceOptions = ref<(IAnswerPriceOption | IAnswerSpecialPrice)[]>([])
const farmerProduct = ref<IFarmerProduct>()
const drawerIsOpen = ref(false)
const isEditedAnswerOptions = ref(false)
const isLoading = ref(false)
const answerPriceOptionsTable = ref<{ tableFormRef: any } | null>(null)
const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const isCustomer = computed(() => {
  return getCoreCookie()?.company_type === Roles.Customer
})

const handleToggleSpecPrice = (value: boolean) => {
  drawerIsOpen.value = value
}

const getProductAnswer = async (isRefresh?: boolean) => {
  isLoading.value = true
  await ProductAnswerAPI.getItems(
    1,
    [['id', 'eq', String(route.params.productAnswerId)]],
    [
      'product',
      'priceOptions',
      'productRequest.priceRequest.customer',
      'special_price',
    ],
  ).then(({ items: productAnswerItems }: { items: IProductAnswer[] }) => {
    const product = productAnswerItems[0]
    if (product) {
      productAnswer.value = product

      if (product.price_options && product.price_options.length > 0) {
        priceOptions.value = JSON.parse(
          JSON.stringify(
            product?.price_options.sort(
              (prev, next) =>
                Number(prev.minimal_volume) - Number(next.minimal_volume),
            ),
          ),
        )
        if (product.special_price) {
          priceOptions.value.push(
            JSON.parse(JSON.stringify(product.special_price)),
          )
        }
      }

      if (isRefresh) {
        isLoading.value = false
        return
      }

      FarmerProductAPI.getProducts([
        ['product_id', 'eq', productAnswerItems[0]?.product?.id],
      ]).then((items: IFarmerProduct[]) => {
        if (items && items[0]) {
          farmerProduct.value = items[0]
          isLoading.value = false
        }
      })
    }
  })
}

const handleEditPriceOption = (
  propertyName: string,
  value: number | boolean,
  priceOptionIndex: number,
) => {
  if (propertyName === 'is_sample_needed') {
    priceOptions.value[priceOptionIndex]['samples_number'] = null
  }
  priceOptions.value[priceOptionIndex][propertyName] = value
}

const handleCreateSpecPrice = (payload: any) => {
  function setNewAnswerSpecialPrice() {
    ProductAnswerAPI.getItems(
      1,
      [['id', 'eq', String(route.params.productAnswerId)]],
      ['special_price'],
    ).then(({ items }) => {
      if (items[0]?.special_price) {
        priceOptions.value.push(
          JSON.parse(JSON.stringify(items[0].special_price)),
        )

        if (productAnswer.value) {
          productAnswer.value.special_price = items[0].special_price
        }
      }
    })
  }
  SpecialPriceAPI.create({
    farmer_product_id: farmerProduct.value?.id,
    ...payload,
  })
    .then(() => {
      setNewAnswerSpecialPrice()
    })
    .catch(() => {
      getProductAnswer(true)
    })
    .finally(() => {
      drawerIsOpen.value = false
    })
}

const handleChangeIsEdited = (status: boolean) => {
  isEditedAnswerOptions.value = status
}

const changeProductStatus = (status: TProductAnswerStatus) => {
  if (productAnswer.value) {
    productAnswer.value.status = status
  }
}

watch(
  () => [priceOptions.value],
  () => {
    isEditedAnswerOptions.value = true
  },
  {
    deep: true,
    immediate: false,
  },
)

const initPage = async () => {
  if (isCustomer.value) {
    await router.push('/')
  } else {
    await getProductAnswer()
  }
}

emitter.on('refresh-token', async (e) => {
  await initPage()
})

onMounted(async () => {
  await initPage()
})

</script>

<template>
  <FullscreenLoading v-if="isLoading" :type="'secondary'" />
  <div v-else-if="productAnswer && farmerProduct" class="wrapper">
    <ProductResponseHeader :productName="productAnswer.product?.fullName" />
    <div class="content">
      <ProductToolbarWidget :farmerProduct="farmerProduct" />
      <AnswerPriceOptionsTable
        ref="answerPriceOptionsTable"
        :productRequest="productAnswer.product_request"
        :answerPriceOptions="priceOptions"
        @changeIsEditedAnswerOptions="handleChangeIsEdited"
        :measurmentUnitId="farmerProduct.measurement_unit_id"
        @editPriceOption="handleEditPriceOption"
        :isEditedAnswerOptions="isEditedAnswerOptions"
        :productQuantity="farmerProduct.quantity"
      />
    </div>
    <ProductResponseFooter
      :tableFormRef="answerPriceOptionsTable?.tableFormRef"
      :specialPrice="productAnswer.special_price"
      :productAnswerStatus="productAnswer.status"
      :initialAnswerPriceOptions="productAnswer?.price_options"
      :priceOptions="priceOptions"
      :farmerProduct="farmerProduct"
      @toggleSpecialPrice="handleToggleSpecPrice"
      :isEditedAnswerOptions="isEditedAnswerOptions"
      @changeProductStatus="changeProductStatus"
    />

    <SpecialPriceDrawer
      @toggleSpecialPrice="handleToggleSpecPrice"
      @createSpecialPrice="handleCreateSpecPrice"
      :drawerIsOpen="drawerIsOpen"
      :productName="productAnswer.product?.fullName"
      :measurmentUnitId="farmerProduct.measurement_unit_id"
      :customer="productAnswer.product_request?.price_request?.customer"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.wrapper {
  @include wrapper();
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
}
</style>
