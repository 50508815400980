<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Button, Table } from 'ant-design-vue'
import ProductCell from '@/views/Reductions/components/ReductionTable/ProductCell.vue'
import CreatedAtCell from '@/views/Reductions/components/ReductionTable/CreatedAtCell.vue'
import StatusCell from '@/views/Reductions/components/ReductionTable/StatusCell.vue'
import UsersCell from '@/views/Reductions/components/ReductionTable/UsersCell.vue'
import BestOfferCell from '@/views/Reductions/components/ReductionTable/BestOfferCell.vue'
import { reductionStore } from '@/store/reductionStore'
import { IReduction, IReductionProductRequest } from '@/types/interfaces'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { DownOutlined } from '@ant-design/icons-vue'
import {roundNumber} from "@/helpers/scripts";

interface IProps {
  tableData: IReduction[]
  isMoreFetching: boolean
}

const props = defineProps<IProps>()
const router = useRouter()
const expandedRowKeys = ref<number[]>([])

const columns = [
  {
    title: '',
    key: 'switch',
    width: '2%',
  },
  {
    title: 'Товары',
    key: 'products',
    width: '47%',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
    width: '10%',
  },
  {
    title: 'Статус',
    key: 'status',
    width: '10%',
  },
  {
    title: 'Участники',
    key: 'users',
    width: '10%',
  },
  {
    title: 'Лучшее предложение',
    key: 'bestOffer',
    width: '10%',
  },
]

const getCustomRow = (record: IReduction) => {
  return {
    onClick: () => router.push(`/reductions/${record.id}`),
    style: { cursor: 'pointer' },
  }
}

const increasePage = () => {
  reductionStore().increasePage()
}

const handleExpandKey = (id: number) => {
  if (expandedRowKeys.value.includes(id)) {
    expandedRowKeys.value = expandedRowKeys.value.filter((exId) => exId !== id)
  } else {
    expandedRowKeys.value.push(id)
  }
}

const getProductsList = (
  products: IReductionProductRequest[],
  id: number,
  expanded: number[],
) => {
  if (expanded.includes(id)) return products
  return products.slice(0, 3)
}
</script>

<template>
  <div class="reduction-table">
    <Table
      :columns="columns"
      :data-source="props.tableData"
      :custom-row="getCustomRow"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'switch'">
          <Button
            type="text"
            @click.stop="handleExpandKey(record.id)"
            class="rotate-btn"
            :class="{ isRotated: expandedRowKeys.includes(record.id) }"
            v-if="record.reduction_product_requests.length > 3"
          >
            <template #icon>
              <DownOutlined />
            </template>
          </Button>
        </template>
        <template v-if="column.key === 'products'">
          <ProductCell
            :products="
              getProductsList(
                record.reduction_product_requests,
                record.id,
                expandedRowKeys,
              )
            "
          />
        </template>
        <template v-if="column.key === 'createdAt'">
          <CreatedAtCell :created-at="record.created_at" />
        </template>
        <template v-if="column.key === 'status'">
          <StatusCell
            :expiration-date="record.expiration_date"
            :status="record.status"
          />
        </template>
        <template v-if="column.key === 'users'">
          <UsersCell
            :participants="record.merged_recipients ?? []"
            :is-public="record.is_public"
          />
        </template>
        <template v-if="column.key === 'bestOffer'">
          <BestOfferCell
            :best-offer="
              record.best_farmer_id
                ? {
                    price: roundNumber(record.best_sum),
                    company_name: `${record.best_farmer.legal_form}  ${record.best_farmer.name}`,
                  }
                : null
            "
          />
        </template>
      </template>
    </Table>
    <InfiniteLoading
      :distance="500"
      class="infinite-loading"
      @infinite="increasePage"
    />
    <div v-if="isMoreFetching" class="list-loader">
      <FullscreenLoading :type="'secondary'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reduction-table {
  flex-grow: 1;
  padding-bottom: 89px;
  margin-top: 24px;
  .rotate-btn {
    :deep(.anticon) {
      transform: rotate(0);
      transition: 0.2s ease-in;
    }
    &.isRotated {
      :deep(.anticon) {
        transform: rotate(180deg);
      }
    }
  }
}

.list-loader {
  transform: scale(0.6);
  width: 100%;
  height: 70px;
  position: relative;
}
</style>
