<script lang="ts" setup>
import { Rate } from 'ant-design-vue'
import { ref } from 'vue'
import { IMark } from '@/types/interfaces'

interface Props {
  mark: IMark
}

const props = defineProps<Props>()
const rate = ref<number>(3)
</script>

<template>
  <div class="rate-container">
    <div class="grey">
      Ваша оценка заказа
      <Rate
          :count="5"
          :value="mark.mark"
      />
      {{ mark.mark }},0
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rate-container {
  display: flex;

  .grey {
    margin-left: 4px;
    color: #000000a6;
  }
}
</style>
