export const truncatedTitle = (title: string) => {
    return title.length > 40
        ? (title.substring(0, 40) + '...').replace('|', ', ')
        : title.replace('|', ', ')
}

export const formatPrice = (sum: string) => {
    const formattedSum = sum.toString().includes('.')
        ? Number(sum).toFixed(2).replace('.', ',')
        : sum.replace('.', ',')
    return `${formattedSum.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`
}

export const formatPriceWithoutDecimal = (sum: string) => {
    const formattedSum = sum.toString().includes('.')
        ? Math.floor(Number(sum)).toString().replace('.', ',')
        : sum.replace('.', ',')
    return `${formattedSum.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`
}
