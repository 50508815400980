<script setup lang="ts">
import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['closeAboutReductionModal'])

const closeModal = () => {
  emit('closeAboutReductionModal')
}
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">О торгах на покупку</div>
    </template>
    <template #body>
      <div class="modal__body">
        <b>Торг на покупку</b> — это торги со снижением цены, которые <br />
        проводит покупатель для поставщиков. Покупатель <br />
        не обязан заключать договор с победителем <br />
        торга на покупку, так же как и поставщик не обязан <br />
        продавать товары по указанным в ставках условиях. Поставщики могут
        делать ставку на один товар, <br />
        на список товаров или не делать ставки вовсе.
        <div class="modal__body-stages-title">
          Проведение торга на покупку включает несколько этапов:
        </div>
        <ol class="modal__body-stages">
          <li>
            1. Покупатель создает публичный или закрытый <br />
            торг на покупку, в котором он указывает интересующие <br />
            его товары и указывает свои требования к заказу.
          </li>
          <li>
            2. Поставщики, с доступом к торгу на покупку, делают <br />
            ставки на имеющиеся товары.
          </li>
          <li>
            3. Рейтинги ставок в торгах на покупку динамически <br />
            меняются, в зависимости от ставок поставщиков.
          </li>
          <li>
            4. После закрытия торга на покупку, все рейтинги <br />
            фиксируются.
          </li>
        </ol>
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal" type="default"> Закрыть </Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    display: flex;
    flex-wrap: wrap;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 440px;
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    &-stages {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-title {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(0, 0, 0, 1);
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>
