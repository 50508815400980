<script setup lang="ts">
import { computed, onMounted, PropType, ref } from 'vue'
import { Button } from 'ant-design-vue'
import { IRoutLink } from '@/types/interfaces'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/store'
import { catalogStore } from '@/store/catalogStore'
import PhoneIcon from '@/components/Icons/PhoneIcon.vue'
import MailIcon from '@/components/Icons/MailIcon.vue'
import TelegramIcon from '@/components/Icons/TelegramIcon.vue'
import {
  MANAGER_EMAIL,
  MANAGER_NAME, MANAGER_PHONE, MANAGER_POSITION,
  OWNER_EMAIL, OWNER_NAME, OWNER_PHONE, OWNER_POSITION,
  PRIVACY_POLICY_LINK,
  TELEGRAM_LINK,
} from '@/model/constants'
import Rustore from '@/assets/img/Rustore.svg'
import { storeToRefs } from 'pinia'
import {RUSTORE_LINK} from "@/types/globalConst";
import {ModalKeys} from "@/components/ModalSystem/ModalKeys";
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'

const props = defineProps({
  links: { type: Array as PropType<IRoutLink[]>, default: () => [] } || null,
})

const { cookies } = useCookies()
const useCatalogStore = catalogStore()
const modalStore = useModalStore()

const { isVerification } = storeToRefs(useStore())

const calcNavToLink = (label: string, to: string) => {
  if (
    [
      'История заказов',
      'Запросы цен',
      'Партнёры',
      'Торги продажа',
      'Торги покупка',
      'Корзина',
    ].includes(label) &&
    !isVerification.value
  ) {
    return ''
  } else return to
}

const calcNavDisabled = (label: string) => {
  if (
    [
      'История заказов',
      'Запросы цен',
      'Партнёры',
      'Торги продажа',
      'Торги покупка',
      'Корзина',
    ].includes(label) &&
    !isVerification.value
  ) {
    return 'disabled-link'
  } else return ''
}

const toggleCatalog = (label: string) => {
  if (label === 'Корзина') {
    const { closeOpenCart } = useStore()
    closeOpenCart()
  }
  if (label === 'Каталог') {
    if (useCatalogStore.isOpen) useCatalogStore.isOpen = false
    else {
      setTimeout(() => {
        useCatalogStore.isOpen = true
      }, 100)
    }
  }
}

const handleOpenFeedbackForm = () => {
  modalStore.openModal(ModalKeys.FeedbackForm, {
    isClosable: true,
    class: 'verification-modal',
    close: confirm,
  })
}
</script>

<template>
    <div class="footer">
      <div class="footer__first-block first-block">
        <h3 class="first-block__title">Свяжитесь с нами, мы поможем всё настроить и начать работу</h3>
        <div class="first-block__mobile">
          <a :href="RUSTORE_LINK">
            <img class="mobile__icon" :src="Rustore" alt="Rustore" loading="lazy" />
          </a>
        </div>
      </div>
      <div class="container">
        <div class="contacts">
          <div class="contacts-data">
            <div class="contact">
              <div class="stuff">
                <div class="stuff__name">{{OWNER_NAME}}</div>
                <div class="stuff__position">{{OWNER_POSITION}}</div>
              </div>
              <div class="phone">
                <PhoneIcon :fill="'rgba(0, 0, 0, 0.88)'" />
                <p class="myContact">{{OWNER_PHONE}}</p>
              </div>
              <div class="mail">
                <MailIcon :fill="'rgba(0, 0, 0, 0.88)'" />
                <p class="myContact">{{ OWNER_EMAIL }}</p>
              </div>
            </div>
            <div class="contact">
              <div class="stuff">
                <div class="stuff__name">{{MANAGER_NAME}}</div>
                <div class="stuff__position">{{MANAGER_POSITION}}</div>
              </div>
              <div class="phone">
                <PhoneIcon :fill="'rgba(0, 0, 0, 0.88)'" />
                <p class="myContact">{{MANAGER_PHONE}}</p>
              </div>
              <div class="mail">
                <MailIcon :fill="'rgba(0, 0, 0, 0.88)'" />
                <p class="myContact">{{ MANAGER_EMAIL }}</p>
              </div>
            </div>
            <div class="container__feedback feedback">
              <div class="feedback__block-title">Мы на связи</div>
              <Button type="primary" class="feedback__button" @click="handleOpenFeedbackForm">
                Обратный звонок
              </Button>
              <div class="company-telegram">
                <a :href="TELEGRAM_LINK" class="telegram-link" target="_blank">
                  <TelegramIcon :fill="'#0066FF'" />
                  <p class="myContact">{{ TELEGRAM_LINK.split('//')[1] }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="links-list" v-if="props?.links">
          <router-link class="links-list__item" to="/"> Главная </router-link>
          <router-link
              class="links-list__item link"
              :to="calcNavToLink(link.label, link.to)"
              v-for="link in props.links as IRoutLink[]"
              :key="link.label"
              :class="calcNavDisabled(link.label)"
          >
            <div
                v-if="link.label === 'Каталог' || link.label === 'Корзина'"
                :style="{ cursor: 'pointer', height: '100%' }"
                class="link__label"
                @click="toggleCatalog(link.label)"
            >
              {{ link.label }}
            </div>

            <span v-else>{{ link.label }}</span>
          </router-link>
          <router-link class="links-list__item" to="/account">
            Профиль
          </router-link>
        </div>
      </div>
      <div class="footer__third-block third-block">
        <div class="third-block__info">
          <span class="info__text">Звонки принимаем с 9:00 до 21:00</span>
          <span class="info__text">Средний ответ на email в течение часа</span>
        </div>
        <div class="company-rights">
          <a class="company-rights__policy" :href="PRIVACY_POLICY_LINK" target="_blank">
            Политика конфиденциальности
          </a>
          <span class="company-rights__company-name">
            © 2022-{{ new Date().getFullYear() }} ООО «Изи Ритейл»
          </span>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.company-rights {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__policy {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(22, 119, 255, 1);
    text-decoration: none;
  }
  &__company-name {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.65);
  }
}

.info {
  &__text {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.65);
  }
}

.feedback {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;

  &__block-title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.88);
    padding-bottom: 10px;
  }
}

.third-block {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-right: 20px;
  padding-bottom: 20px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-start;
  }
}

.stuff {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &__name {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.88);
  }
  &__position {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.65);
  }
}

.mobile__icon {
  width: 100%;
  height: 100%;
  max-width: 113px;
  max-height: 38px;
  object-fit: cover;
}

.first-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  &__title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.88);
  }
  &__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer-enter-active,
.footer-leave-active {
  transition: all 0.5s ease;
}
.footer-enter-from,
.footer-leave-to {
  opacity: 0;
}

.link__label {
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: 1;
  border-top: 1px solid #e2e8f0;
  background: #fff;
  bottom: 0;

  .container {
    display: flex;
    padding: 10px 0 10px 0;
    width: 100%;
    justify-content: space-between;
  }

  .contacts {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: center;

    .contacts-data {
      display: flex;
      margin-top: 27px;
      width: 100%;
      gap: 40px;

      .contact {
        display: flex;
        margin: 0;
        text-align: left;
        flex-direction: column;
        gap: 5px;

        .phone,
        .mail {
          display: flex;
          align-items: center;
          height: 16px;
          color: rgba(0, 0, 0, 0.88);
          gap: 8px;
          margin-bottom: 5px;

          .myContact {
            font-weight: 500;
            font-size: 14px;
            width: max-content;
          }
        }

        .comment {
          color: #718096;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .links-list {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(3, 17px);
    grid-column-gap: 56px;
    grid-row-gap: 18px;
    grid-auto-rows: 17px;
    align-items: start;
    grid-auto-flow: column;
    padding-right: 20px;
    align-content: center;

    &__catalog {
      height: 100%;
    }

    &__item {
      @include p6();
      color: #000000a6;
      text-decoration: none;
      cursor: pointer;
      height: 100%;
      width: max-content;

      &:hover {
        color: #0066ff;
      }

      &.disabled-link {
        @include disabled-link;
      }
    }
  }
}
.company {
  position: absolute;
  right: 24px;
  width: 274px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &-telegram {
    .telegram-link {
      color: $primary-accent;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      display: flex;
      column-gap: 8px;
    }
  }

  &-rights {
    display: flex;
    flex-direction: column;
    text-align: right;
    row-gap: 8px;

    .company-name {
      font-size: 12px;
      font-weight: 400;
      color: #718096;
      text-decoration: none;
    }
  }
}

.actions {
  display: flex;
  gap: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;

  &__item {
    cursor: pointer;
  }

  svg {
    color: $gray-800;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 6px;
  }
}
</style>
