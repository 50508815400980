import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies()

class AnswerSpecialPriceAPIModel {
  async createMany(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.AnswerSpecialPrice,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateMany(data: Partial<any>[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.AnswerSpecialPrice,
      method: EgalMethods.updateMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const AnswerSpecialPriceAPI = new AnswerSpecialPriceAPIModel()
export { AnswerSpecialPriceAPI }
