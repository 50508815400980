<script lang="ts" setup>
import { useOrderStore } from '@/store/orderStore'
import { Button } from 'ant-design-vue'
import { computed, onMounted, ref, withDefaults } from 'vue'
import { useStore } from '@/store'
import AddToCart from '@/components/Icons/AddToCart.vue'
import RemoveFromCart from '@/components/Icons/RemoveFromCart.vue'
import { IFarmerProduct } from '@/types/interfaces'
import router from '@/router'
import { IFarmer } from '@/types/interfaces'
import { storeToRefs } from 'pinia'

const { isVerification } = storeToRefs(useStore())

const emit = defineEmits(['closeDrawer'])

interface Props {
  product?: IFarmerProduct
  buttonSize?: string
  farmer?: IFarmer
}
const props = withDefaults(defineProps<Props>(), {
  buttonSize: 'large',
})

const { addToCartStore, orders, deleteCartItemStore } = useOrderStore()
const orderStore = useOrderStore()
const isAddedToCart = computed(() => {
  return !!ordersCopy.value.find((i) => i.id === props.product?.id)
})

const addToCart = (product: IFarmerProduct) => {
  if (!isAddedToCart.value) {
    addToCartStore(product.id, +product.min_value)
  }
}
const removeFromCart = (product: IFarmerProduct) => {
  deleteCartItemStore(product.id)
}

const toCart = () => {
  if (isVerification.value) {
    emit('closeDrawer')
    router.push('/cart')
  } else return
}

const ordersCopy = ref<any[]>([])
orderStore.$subscribe((mutation, state) => {
  ordersCopy.value = [...state.orders]
})
onMounted(() => {
  ordersCopy.value = [...orders]
})
</script>

<template>
  <div>
    <Button
      :disabled="!isVerification"
      v-if="!isAddedToCart"
      class="add_to_cart"
      type="primary"
      :size="buttonSize"
      @click.stop="addToCart(product)"
    >
      <template #icon>
        <AddToCart />
      </template>
      Добавить в корзину
    </Button>
    <div v-else class="to_cart">
      <Button :size="buttonSize" class="cart_button" @click.stop="toCart">
        Перейти в корзину
      </Button>
      <Button
        :size="buttonSize"
        class="basket_button"
        @click.stop="removeFromCart(product)"
      >
        <template #icon>
          <RemoveFromCart />
        </template>
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.add_to_cart {
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
  img {
    margin-right: 8px;
  }
}

.basket,
.basket_delete {
  transform: translateY(2px);
  margin-right: 4px;
  height: 16px;
  width: 16px;
  filter: brightness(0%) invert(100%);
  pointer-events: none;
}

.to_cart {
  display: flex;

  .basket_delete {
    filter: none;
  }

  .cart_button {
    width: -webkit-fill-available;
    width: -moz-available;
    width: 100%;
    margin-right: 6px;
  }

  .basket_button {
    padding: 5px 10px 5px 10px;
  }
}
</style>
