<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomerRequestCommonInfoServices',
  props: {
    title: {
      type: String,
      default: '',
    },
    services: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
})

// TODO: Дописать
</script>

<template>
  <div class="customer-request-common-info-services">
    <div class="customer-request-common-info-services_title">
      {{ title ? title : 'Услуги' }}
    </div>
    <div v-for="service in services" :key="service">
      <div class="customer-request-common-info-services_item">
        {{ service }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info-services {
  &_title {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
  }
  &_item {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000e0;
  }
}
</style>
