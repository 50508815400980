<script setup lang="ts">
import { useOrderStore } from '@/store/orderStore'
import CatalogPositionProducts from '@/components/Catalog/CatalogPositionProducts.vue'
import { ref, onMounted, watch } from 'vue'
import { IFarmer } from '@/types/interfaces'
import CatalogPositionProductsTable from '@/components/Catalog/CatalogPositionProductsTable.vue'
import InfiniteLoading from 'v3-infinite-loading'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import { getEnding } from '@/helpers/scripts'
import router from '@/router'
import { catalogStore } from '@/store/catalogStore'
import {
  Col,
  Row,
  TypographyText as TgText,
  TypographyLink as TgLink,
} from 'ant-design-vue'
import { roundNumber } from '@/helpers/scripts'

const { orders } = useOrderStore()

interface Props {
  position: {
    created_at: string
    fullName: string
    fullNameWithMainCategory: string
    id: number
    product_category: {
      id: number
      name: string
      product_category_id: number
      measurement_unit_id: string
      ultimate: boolean
    }
    product_category_id: number
    product_parameters: any[]
    updated_at: string
  }
  farmerProducts: { price: string; farmer: IFarmer }[]
  showPreloader: boolean
  totalCount: string
  minMaxProduct: { min: string; max: string }
  scrollValue?: number
}

const props = defineProps<Props>()
const emit = defineEmits([
  'loadNextPage',
  'reset-scroll-value',
  'get-farmer-address',
])

const useCatalogStore = catalogStore()

const backToCatalog = async () => {
  await router.push(`/products-catalog`)
  useCatalogStore.toggleScroll()
}

const isProductsTable = ref(true)

const toggleProductsTable = () =>
  (isProductsTable.value = !isProductsTable.value)

const currentPage = ref(1)

const loadNextPage = () => {
  currentPage.value++
  emit('loadNextPage', currentPage.value)
}

const onProductItemClick = (item: { price: string; farmer: IFarmer }) => {
  emit('get-farmer-address', item.farmer.address)
}

const targetRef = ref<HTMLElement | null>(null)

const scrollToView = () => {
  if (
    props.scrollValue !== -1 &&
    props.scrollValue !== undefined &&
    targetRef.value
  ) {
    targetRef.value[props.scrollValue].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}

watch(() => props.scrollValue, scrollToView)

onMounted(scrollToView)

const resetScrollValue = () => {
  emit('reset-scroll-value')
}
</script>

<template>
  <div
    class="catalog__content"
    :class="!isProductsTable ? 'catalog__content--table' : ''"
  >
    <div class="catalog__position">
      <div class="catalog__position-header">
        <div @click="backToCatalog">
          <CreateHeader :title="''" :is-show-create-chat-button="false" :farmer_id="farmerProducts?.[0].farmer_id" />
        </div>
        <div class="catalog__position-header-info">
          <h2 class="catalog__position-header-title">
            {{ position.fullName }}
          </h2>
          <Row :gutter="0" :wrap="false">
            <Col :flex="1">
              <TgText type="secondary">
                {{ totalCount }}
                предложен{{ getEnding(Number(totalCount)) }}
                <span
                  v-if="props.minMaxProduct.min !== props.minMaxProduct.max"
                >
                  , от {{ roundNumber(props.minMaxProduct.min) }} до
                  {{ roundNumber(props.minMaxProduct.max) }} ₽
                </span>
                <span v-else
                  >, {{ roundNumber(props.minMaxProduct.min) }} ₽
                </span>
              </TgText>
            </Col>
            <Col>
              <div class="catalog__position-actions">
                <TgLink @click="toggleProductsTable">
                  {{
                    isProductsTable ? 'Развернуть таблицу' : 'Свернуть таблицу'
                  }}
                </TgLink>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div
      class="catalog__products"
      v-if="farmerProducts.length > 0 && isProductsTable"
    >
      <CatalogPositionProducts
        @click="onProductItemClick(farmerProduct)"
        :isProductsTable="isProductsTable"
        v-for="farmerProduct in farmerProducts"
        :product="farmerProduct"
        :key="farmerProduct.id"
        :scrollValue="props.scrollValue"
        :index="index"
        @is-not-active="resetScrollValue"
      />
    </div>
    <div
      class="catalog__products-table"
      v-if="farmerProducts.length > 0 && !isProductsTable"
    >
      <CatalogPositionProductsTable :farmerProducts="farmerProducts" />
    </div>

    <InfiniteLoading
      class="infinite-loading"
      @infinite="loadNextPage"
      :distance="1"
    />
    <div
      v-if="showPreloader"
      class="catalog__position"
      style="align-items: center; justify-content: center"
    >
      <star-loader />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/components/Catalog/catalogStyles.scss';

.products__header {
  margin-bottom: 0;
  gap: 0;
}
</style>
