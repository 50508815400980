<script lang="ts" setup>
import {
  Flex,
  TypographyText as TpText,
  TypographyParagraph as TpParagraph,
  Tag,
  Row,
  Col,
} from 'ant-design-vue'
import { IEmployee, TEmployeeStatus } from '@/types/interfaces'
import { computed } from 'vue'
import { formatPhoneNumber } from '@/helpers/scripts'

const tags: Record<TEmployeeStatus, { text: string; color: string }> = {
  active: { text: 'Активен', color: 'green' },
  waiting: { text: 'Ожидает', color: 'orange' },
  expired: { text: 'Просрочено', color: 'red' },
}

interface Props {
  status: IEmployee['status']
  phone: IEmployee['phone']
  email: IEmployee['email']
  position: IEmployee['position']
  name: IEmployee['name']
}

const props = defineProps<Props>()

const title = computed(() =>
  props.status === 'active' ? props.name : props.email,
)

const additionalInfo = computed(() =>
  [props.position, formatPhoneNumber(props.phone || ''), props.email].filter(
    (item) => !!item,
  ),
)
</script>

<template>
  <div class="employee-block">
    <Row>
      <Col :span="22">
        <TpParagraph class="title" :content="title" />
      </Col>
      <Col :span="1">
        <Tag :color="tags[status].color">{{ tags[status].text }}</Tag>
      </Col>
    </Row>
    <Flex v-if="status === 'active'" :gap="8" align="center">
      <template v-for="(info, index) in additionalInfo" :key="info">
        <TpParagraph class="secondary" type="secondary" :content="info" />
        <TpParagraph
          v-if="index !== additionalInfo.length - 1"
          class="dot"
          type="secondary"
          content="·"
        />
      </template>
    </Flex>
  </div>
</template>

<style scoped lang="scss">
.employee-block {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}

.dot {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.secondary {
  max-width: 50%;
  margin-bottom: 0;
}
</style>
