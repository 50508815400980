<script setup lang="ts">
import { Button } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'

// Модалка Вы не можете отменить заказ

interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['closeCantRefuseTheEntireOrderModal'])

const closeModal = () => {
  emit('closeCantRefuseTheEntireOrderModal')
}
</script>

<template>
  <Modal
    :open="open"
    width="440px"
    title="Извините, вы не можете отменить заказ"
    @cancel="closeModal"
    centered
  >
    Вы можете отказаться только от тех заказов, которые еще не переходили на
    доставку
    <template #footer>
      <Button @click="closeModal" type="primary"> Закрыть </Button>
    </template>
  </Modal>
</template>

<style scoped lang="scss"></style>
