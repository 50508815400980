<script lang="ts">
import { defineComponent } from 'vue'
import ProductListItem from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestProductsList/components/ProductListItem.vue'

export default defineComponent({
  name: 'CustomerRequestProductsList',
  components: {
    ProductListItem,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
})

// TODO: Дописать
</script>

<template>
  <div class="customer-request-products-list">
    <div class="customer-request-products-list_title">Товары в запросе</div>
    <div class="customer-request-products-list_items">
      <ProductListItem
        v-for="product in products"
        :key="product"
        :product="product"
      />
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-products-list {
  width: 100%;

  &_title {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000e0;
    margin-bottom: 30px;
  }

  &_items {
    overflow-y: auto;
    max-height: 600px;
    height: 100%;
  }
}
</style>
