<script setup lang="ts">
import { CatalogTagsTypes } from '@/types/enums'
import CatalogTags from '@/components/Catalog/Tags/CatalogTags.vue'
import { useStore } from '@/store'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import { Space, Table, TypographyText as TgText } from 'ant-design-vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import { declensionDays } from '@/helpers/scripts'
import ProductPriceBlock from '@/components/CardTable/ProductPriceBlock.vue'
import AddToCart from '@/components/AddToCart/AddToCart.vue'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { storeToRefs } from 'pinia'
interface Props {
  farmerProducts: any[]
}

const props = defineProps<Props>()
const { isVerification } = storeToRefs(useStore())

const columns = [
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    width: '34%',
  },
  {
    title: 'Срок подготовки товара',
    dataIndex: 'prepare_days',
    key: 'prepare_days',
    width: '11%',
  },
  {
    title: 'Мин. заказ',
    dataIndex: 'min_value',
    key: 'min_value',
    width: '11%',
  },
  {
    title: 'В наличии',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '11%',
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Заказ',
    dataIndex: 'order',
    width: 224,
    key: 'order',
  },
]

const getProductTags = (farmerProduct: any) => {
  let tags = [{ type: CatalogTagsTypes.SelfDelivery, text: 'Самовывоз' }]
  farmerProduct.farmer?.can_deliver &&
    tags.push({
      type: CatalogTagsTypes.Delivery,
      text: 'Доставка',
    })
  farmerProduct.farmer.vat &&
    tags.push({
      type: CatalogTagsTypes.WithVat,
      text: 'Налогообложение по НДС',
    })
  return tags
}
</script>

<template>
  <Table
    :data-source="props.farmerProducts"
    :columns="columns"
    :pagination="false"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'supplier'">
        <Space :size="4" direction="vertical">
          <router-link
            class="router-link"
            :to="!isVerification ? '' : String(`/farmer-product/${record.farmer_id}`)"
          >
            <BlurTooltip>
              <Space :size="4">
                <PartnerIcon
                  v-if="record.partner.length"
                  :style="{ color: '#52C41A' }"
                />
                <TgText strong :class="!isVerification ? 'blur' : ''">
                  {{ record.farmer.legal_form }}
                  {{ record.farmer.name }}
                </TgText>
              </Space>
            </BlurTooltip>
          </router-link>
          <Space
            :size="4"
            :style="{ marginBottom: '4px' }"
            v-for="tag in record?.farmer.addresses"
            :key="tag.id"
          >
            <EnvironmentOutlined :style="{ color: '#00000073' }" />
            <TgText
              :class="!isVerification ? 'blur' : ''"
              :style="{ color: '#000000A6' }"
            >
              {{ tag?.street }}
            </TgText>
          </Space>
          <div class="rate-tags">
            <SimpleRate :company_rating="record.farmer.company_rating" />
            <CatalogTags :tags="getProductTags(record)" />
          </div>
        </Space>
      </template>
      <template v-if="column.key === 'prepare_days'">
        {{ `${record.prepare_days} ${declensionDays(record.prepare_days)}` }}
      </template>
      <template v-if="column.key === 'min_value'">
        {{ `${record.min_value} ${record.measurement_unit_id}` }}
      </template>
      <template v-if="column.key === 'quantity'">
        {{ `${record.quantity} ${record.measurement_unit_id}` }}
      </template>
      <template v-if="column.key === 'price'">
        <ProductPriceBlock :farmer-product="record" size="small" />
      </template>
      <template v-if="column.key === 'order'">
        <AddToCart :product="record" buttonSize="default" />
      </template>
    </template>
  </Table>
  <!--  <div class="catalog__product catalog__product&#45;&#45;table">-->
  <!--    <div class="catalog__tag">-->
  <!--      <img-->
  <!--        v-show="props.product?.partner?.length > 0"-->
  <!--        class="catalog__tag-icon"-->
  <!--        :src="require(`@/assets/img/catalog/task.svg`)"-->
  <!--        alt=""-->
  <!--      />-->
  <!--    </div>-->
  <!--    <div class="catalog__product-user">-->
  <!--      <div class="catalog__product-user-name">-->
  <!--        <router-link-->
  <!--          class="catalog__product-title"-->
  <!--          :to="String(`/farmer-product/${product.id}`)"-->
  <!--        >-->
  <!--          <BlurTooltip>-->
  <!--            <p-->
  <!--              class="catalog__product-title"-->
  <!--              :class="!getIsVerification() ? 'blur' : ''"-->
  <!--            >-->
  <!--              {{ product.farmer.legal_form }}-->
  <!--              {{ product.farmer.name }}-->
  <!--            </p>-->
  <!--          </BlurTooltip>-->
  <!--        </router-link>-->
  <!--        <p-->
  <!--          class="catalog__product-location"-->
  <!--          :class="!getIsVerification() ? 'blur' : ''"-->
  <!--        >-->
  <!--          <LocationIcon fill="#A0AEC0" />-->
  <!--          {{ product?.farmer?.address?.street }}-->
  <!--        </p>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <CatalogTags :tags="productTags" />-->
  <!--    <p class="catalog__product-info">-->
  <!--      от {{ product.min_value }}-->
  <!--      {{ product.measurement_unit_id }}-->
  <!--    </p>-->
  <!--    <p class="catalog__product-info">-->
  <!--      {{ product.quantity }}-->
  <!--      {{ product.measurement_unit_id }}-->
  <!--    </p>-->
  <!--    <p class="catalog__product-info">{{ product.prepare_days }} д.</p>-->

  <!--    <p class="catalog__product-info">-->
  <!--      {{ product.price }} ₽/{{ product.measurement_unit_id }}-->
  <!--    </p>-->

  <!--    <CatalogPositionProductsActions-->
  <!--      :product="product"-->
  <!--      :isProductsTable="isProductsTable"-->
  <!--    />-->
  <!--  </div>-->
</template>

<style scoped lang="scss">
@import '@/components/Catalog/catalogStyles.scss';

.rate-tags {
  display: flex;
}

.blur {
  @include blur();
  @include no-select();
}

.catalog__product-title {
  color: #2d3748;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline !important;
  }
}
</style>
