<script lang="ts" setup>
import { Rate } from 'ant-design-vue'
import { computed } from 'vue'
import { ICompanyRating } from '@/types/interfaces'

interface Props {
  company_rating?: ICompanyRating
}
const props = defineProps<Props>()

const textRate = computed(() => {
  let mark_count = props.company_rating?.mark_count
  let rateEnd = mark_count % 10
  if (rateEnd == 1 && mark_count < 10)
    return 'оценка' // Без '11 оценка'
  else if (
    rateEnd > 1 && rateEnd < 5 &&
    (mark_count < 10 || mark_count > 20)
  ) return 'оценки'
  else return 'оценок'
})
</script>

<template>
  <div class="rate-container">
    <Rate
      :count="1"
      :value="props.company_rating?.mark_count >= 1 ? 1 : 0"
      disabled
    />
    <div v-if="company_rating?.mark_count >= 1" class="rate-text">
      {{ company_rating?.average_mark }}
      <div class="grey">
        {{ company_rating?.mark_count }}
        {{ textRate }}
      </div>
    </div>
    <div class="rate-text" v-else>
      <div class="grey">Нет оценок</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rate-container {
  align-items: center;
  margin-right: 8px;
  display: flex;

  .rate-text {
    text-wrap: nowrap;
    display: flex;
    font-size: 14px;
    margin-left: 4px;

    .grey {
      text-wrap: nowrap;
      margin-left: 4px;
      color: #000000a6;
    }
  }
}
</style>
