<script lang="ts" setup>
import dayjs from 'dayjs'


interface IProps {
  createdAt: string
}

const props = defineProps<IProps>()
</script>

<template>
  <div class="created-at-cell">
    {{ dayjs(props.createdAt).format('DD.MM.YYYY') }}
  </div>
</template>

<style lang="scss" scoped>
.created-at-cell {
  display: flex;
  flex-direction: row;
  height: max-content;
  width: max-content;
}
</style>
