<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import CardTable from '@/components/CardTable/CardTable.vue'
import TagFilters from '@/components/CardTable/TagFilters.vue'
import { OptionType } from '@/types/types'
import { useOrderStore } from '@/store/orderStore'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import EmptyFarmCard from '@/components/Customer/EmptyFarmCard.vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import FarmTags from './FarmTags.vue'
import { Divider } from 'ant-design-vue'
import { IFarmerProduct } from '@/types/interfaces'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { Roles } from '@/types/enums'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import {useCookies} from "vue3-cookies";
import emitter from "@/emitter";

interface Props {
  farm: any
  farmerId: string
}
type IFilterItem = {
  value: number | string
  name: string
}

const props = defineProps<Props>()
const emit = defineEmits(['back', 'open'])
const userData = JSON.parse(String(localStorage.getItem('userData')))

const farmProducts = ref<IFarmerProduct[]>([])
const isFarmer = ref(false)
const { getCoreCookie } = useJWTDecode()

const filterItems = ref<IFilterItem[]>([{ value: 'all', name: 'Все товары' }])
const page = ref(1)
const isFetching = ref(true)
const isMoreFetching = ref(false)
const isFarmerEmpty = ref(false)

const getAllProducts = async () => {
  await FarmerProductAPI.getProductsByCategory(
    activeFilter.value === 'all'
      ? []
      : [['', 'product_category', activeFilter.name]],
    page.value,
    props.farmerId || props.farm.id,
    10,
    ['product', 'specialPrices.customer', 'priceOptions'],
  ).then((res: { items: IFarmerProduct[] }) => {
    if (!res?.items?.length && page.value === 1) isFarmerEmpty.value = true
    if (page.value === 1) {
      farmProducts.value = [...res?.items]
    } else {
      farmProducts.value.push(...res?.items)
    }
    isFetching.value = false
    isMoreFetching.value = false
  })
}

const setTabs = () => {
  props?.farm?.categories?.forEach((category: string) => {
    filterItems.value.push({
      name: category.second_category_name,
      value: category.second_category_id,
    })
  })
  filterItems.value?.sort((a, b) => (a.name === 'ПРОЧЕЕ' ? 1 : -1))
}

const isVisibleChat = computed(() => {
  if (userData.is_active == !isFarmer.value) return true
  else return false
})

emitter.on('refresh-token', async (e) => {
  await getAllProducts()
  setTabs()
  isFarmer.value = getCoreCookie()?.company_type === Roles.Farmer
  sortFarmProducts()
})

onMounted(async () => {
  await getAllProducts()
  setTabs()
  isFarmer.value = getCoreCookie()?.company_type === Roles.Farmer
  sortFarmProducts()
})

// Сортировка товаров поставщика по алфавиту
const sortFarmProducts = () => {
  farmProducts.value.sort(function (a, b) {
    if (a.fullName > b.fullName) {
      return 1
    }
    if (a.fullName < b.fullName) {
      return -1
    }
    return 0
  })
}

const { addItemToCart, orders } = useOrderStore()
const addToCart = (item: any) => {
  addItemToCart({
    ...item,
    new_quantity: item.min_value,
  })
}

const activeFilter = reactive({ name: 'Все товары', value: 'all' })

const setFilter = (filter: OptionType) => {
  isFetching.value = true
  page.value = 1
  Object.assign(activeFilter, filter)
  getAllProducts()
}
const loadMore = () => {
  isMoreFetching.value = true
  page.value++
  getAllProducts()
}

// TODO: Проверить openProductCard
</script>

<template>
  <div class="layout">
    <div class="wrapper">
      <CreateHeader
        :title="farm?.name"
        @button-click="emit('back')"
        :is-show-create-chat-button="isVisibleChat"
        :company="farm"
        :farmer_id="farm?.farmer_id || farm?.id"
      />
      <div class="rate-tags">
        <SimpleRate :company_rating="farm?.company_rating" />
        <FarmTags :farm="farm" />
      </div>
      <Divider></Divider>
      <div v-if="!isFarmerEmpty">
        <TagFilters
          :active-filter="activeFilter"
          :filter-items="filterItems"
          @set-filter="setFilter"
        />
        <div v-if="!isFetching">
          <CardTable
            :farmer="farm"
            :is-farmer="isFarmer"
            :read-only="isFarmer"
            :items="farmProducts"
            @open="openProductCard"
            @add-cart="(obj) => addToCart(obj)"
          />
          <InfiniteLoading
            :distance="300"
            class="infinite-loading"
            @infinite="loadMore"
          />
        </div>
        <div v-else class="fullscreen-loader">
          <FullscreenLoading :type="'secondary'" />
        </div>
      </div>
      <EmptyFarmCard
        v-else
        description="Попробуйте зайти чуть позже"
        title="Сейчас у поставщика нет товаров"
      />
      <div v-if="isMoreFetching" class="infinity-list-loader">
        <FullscreenLoading :type="'secondary'" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.layout {
  background: white;
  min-height: 66vh;
  padding: 20px 50px 0 50px;
  width: 100%;
}

.wrapper {
  @include wrapper();

  .loader {
    margin: 0 auto;
    display: flex;
    min-height: calc(100vh - 69px - 40px - 80px - 170px);
    align-items: center;
  }
}

.tables-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px;
  background: white;
  border-radius: 16px;
}

.rate-tags {
  display: flex;
}

.table-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $default-accent;
  margin-bottom: 16px;
  padding: 9px 12px;
  background: $default-secondary;
  border-radius: 8px;
  width: fit-content;
}

.tabs {
  display: flex;
  gap: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 32px;

  &__item {
    color: $gray-500;
    padding: 8px;
    border-bottom: 1px solid transparent;
    transition: 0.2s ease all;
    cursor: pointer;

    &.active {
      color: $default-accent;
      border-color: $default-accent;
    }
  }
}

.infinity-list-loader {
  position: relative;
  height: 100px;
  transform: scale(0.7);
}
.fullscreen-loader {
  position: relative;
  height: 50vh;
}
</style>
