<script setup lang="ts">
import { Button, Tooltip, Flex } from 'ant-design-vue'
import { CloseOutlined, EditFilled, UndoOutlined } from '@ant-design/icons-vue'
import { IProductAnswer } from '@/types/interfaces'
import { ProductAnswerAPI } from '@/api/ProductAnswerAPI'
import router from '@/router'
import { useRoute } from 'vue-router'
import { RecipientStatus } from '@/types/types'
import StatusIcons from '@/components/StatusIcons.vue'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import BalanceImg from '../../../assets/img/farmerPriceAnswer/balance.svg'
import PaceImg from '../../../assets/img/farmerPriceAnswer/pace.svg'
import Package2 from '../../../assets/img/farmerPriceAnswer/package_2.svg'

interface Props {
  answerProduct: IProductAnswer | null
  recipientStatus: RecipientStatus
  isOnlyOneProduct: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['changeProductStatus'])

const route = useRoute()
const { openModal } = useModalStore()

const cancelHandler = () => {
  ProductAnswerAPI.updateMany([
    {
      id: props.answerProduct?.id,
      status: 'no_answer',
    },
  ]).then(() => {
    emit('changeProductStatus', props.answerProduct?.id, 'no_answer')
  })
}

const handleInputPrices = async () => {
  if (props?.answerProduct?.id) {
    await router.push(`${window.location.pathname}/${props?.answerProduct?.id}`)
  }
}

const returnRequest = async () => {
  await ProductAnswerAPI.updateMany([
    {
      id: props.answerProduct?.id,
      status: 'answer_waiting',
    },
  ]).then(() => {
    emit('changeProductStatus', props.answerProduct?.id, 'answer_waiting')
  })
}

const navigateToProductAnswer = async () => {
  await router.push(
    `/response/` + route.params.id + '/' + props.answerProduct?.id,
  )
}

const fillMeasurementUnitId = (
  productName: string,
  measurement_unit_id: string,
) => {
  if (measurement_unit_id === null) {
    const productText = productName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      measurement_unit_id = lastChar
    } else {
      measurement_unit_id = '-'
    }
    return measurement_unit_id
  } else return measurement_unit_id
}

const measurementUnitId = fillMeasurementUnitId(
  props?.answerProduct?.product_request.product_name,
  props?.answerProduct?.product.product_category.measurement_unit_id,
)

const handleOpenAddProduct = () => {
  openModal(ModalKeys.RedirectToAddProduct, {
    product: props?.answerProduct?.product,
  })
}
</script>

<template>
  <div
    class="product-card"
    :class="{ disabledCard: answerProduct?.status === 'no_product' }"
  >
    <div class="product-card__firstPart">
      <span class="product-card__status">
        <StatusIcons :status="answerProduct?.status" />
      </span>
      <div class="product-card__content content">
        <span class="content__productName">{{
          answerProduct?.product_request.product_name
        }}</span>
        <div class="content__iconItems">
          <div
            class="content__item"
            v-if="answerProduct?.product_request?.customer_needed_volume"
          >
            <img :src="BalanceImg" alt="balance" loading="lazy" />
            <span>{{
              `${answerProduct?.product_request?.customer_needed_volume}
              ${measurementUnitId ? measurementUnitId : ''}`
            }}</span>
          </div>
          <div
            class="content__item"
            v-if="answerProduct?.product_request?.remaining_shelf_life"
          >
            <img :src="PaceImg" alt="pace" loading="lazy" />
            <span>{{
              `${answerProduct?.product_request?.remaining_shelf_life}%`
            }}</span>
          </div>
          <div class="content__item">
            <img
              v-if="answerProduct?.product_request?.is_sample_needed"
              :src="Package2"
              alt="package_2"
              loading="lazy"
            />

            <span
              v-if="answerProduct?.product_request?.needed_samples_number"
              >{{
                `${answerProduct?.product_request?.needed_samples_number}
              ${measurementUnitId ? measurementUnitId : ''}`
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <Button
      v-if="answerProduct?.status !== 'no_product'"
      type="link"
      :onClick="navigateToProductAnswer"
      >Подробнее</Button
    >
    <div
      class="product-card__btns"
      v-if="
        recipientStatus !== 'answered' && answerProduct?.status !== 'no_product'
      "
    >
      <Button
        v-if="answerProduct?.status === 'no_answer'"
        type="link"
        :onClick="returnRequest"
      >
        <template #icon>
          <UndoOutlined />
        </template>
        Вернуть запрос
      </Button>
      <template v-else>
        <Button :onClick="handleInputPrices" type="link">
          <template #icon>
            <EditFilled />
          </template>
          Указать цены
        </Button>
        <Button
          :disabled="isOnlyOneProduct"
          :onClick="cancelHandler"
          danger
          type="link"
        >
          <template #icon>
            <CloseOutlined />
          </template>
          <Tooltip
            placement="bottomRight"
            :overlayInnerStyle="{ width: '330px' }"
          >
            <template v-if="isOnlyOneProduct" #title>
              <span>В запросе должен быть хотя бы один товар</span>
            </template>
            Не поставлю
          </Tooltip>
        </Button>
      </template>
    </div>
    <Flex
      v-else-if="answerProduct?.status === 'no_product'"
      vertical="true"
      :align="'center'"
      gap="8"
    >
      <div class="no-product__caption">Нет в вашем каталоге</div>
      <Button @click="handleOpenAddProduct" type="primary">
        Добавить товар
      </Button>
    </Flex>
  </div>
</template>

<style scoped lang="scss">
.product-card {
  padding: 24px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: 4fr 1fr;

  &.disabledCard {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &__status {
    margin: 9px;
    border-radius: 50%;
    min-width: 6px;
    height: 6px;
  }

  &__firstPart {
    display: flex;
    gap: 8px;
    align-items: flex-start;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__btns {
    display: flex;
    gap: 8px;
  }
}

.content {
  &__productName {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }

  &__productProperties {
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    gap: 20px;
  }

  &__iconItems {
    display: flex;
    color: rgba(0, 0, 0, 0.45);

    gap: 20px;
  }
  &__item {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.no-product {
  &__caption {
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>
