<script setup lang="ts">
import { onMounted, ref, UnwrapRef } from 'vue'
import { AddressAPI } from '@/api/AddressAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { IAddress, IContact } from '@/types/interfaces'
import { ContactAPI } from '@/api/ContactAPI'
import { getUserIdTemporary } from '@/helpers/scripts'
import AuctionSupplyRequirments from '@/components/CreateAuction/CreateAuctionBody/AuctionSupplyRequirments.vue'
import AuctionProducts from '@/components/CreateAuction/CreateAuctionBody/AuctionProducts.vue'
import AuctionCustomers from '@/components/CreateAuction/CreateAuctionBody/AuctionCustomers.vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import {
  FirstFormState,
  IProductTableItem,
} from '@/views/Farmer/FarmerCreateАuctionView.vue'
import AuctionModalOtherAccountSettings from '@/components/AuctionModalOtherAccountSettings/AuctionModalOtherAccountSettings.vue'

interface Props {
  currentStep: number
  firstFormState: UnwrapRef<FirstFormState>
  secondFormState: { domains: IProductTableItem[] }
  thirdFormState: UnwrapRef<any[]>
  checkedKeys: UnwrapRef<string[]>
  expandedKeys: UnwrapRef<string[]>
}

const props = defineProps<Props>()
const emit = defineEmits([
  'editFirstForm',
  'editSecondForm',
  'editSecondForm',
  'deleteCustomer',
  'handleSelectCustomerModal',
  'addProduct',
  'removeProduct',
  'checkKeys',
  'expandKeys',
  'handlePublicReduction',
])
const { getCoreCookie } = useJWTDecode()
const addressList = ref<IAddress[]>([])
const contactPersonList = ref<IContact[]>([])
const treeData = ref([])

const firstFormRef = ref()
const secondFormRef = ref()

defineExpose({
  firstFormRef,
  secondFormRef,
})

onMounted(async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  const addressGet = AddressAPI.getAddresses(
      currentCompanyId,
    'farmer',
  ).then((res) => {
    addressList.value = res.items
  })
  const contactsGet = ContactAPI.getItemsContacts(
    [
      ['company_id', 'eq', currentCompanyId],
      'AND',
      ['company_type', 'eq', 'farmer'],
    ],
    1,
    100,
    [['name', 'asc']],
  ).then((res) => {
    contactPersonList.value = res.items
  })
  const treeGet = FarmerProductAPI.getAuctionProducts().then((res) => {
    treeData.value = res
  })
  await Promise.all([addressGet, contactsGet, treeGet])
})

const firstFormEditWrapper = (field: string, value: any) => {
  emit('editFirstForm', field, value)
}

const secondFormEditWrapper = (field: string, value: any, index: number) => {
  emit('editSecondForm', field, value, index)
}
const thirdFormEditWrapper = (uid: string) => {
  emit('deleteCustomer', uid)
}
const handleSelectCustomerModalWrapper = (value: boolean) => {
  emit('handleSelectCustomerModal', value)
}
const addProductWrapper = (value: IProductTableItem) => {
  emit('addProduct', value)
}

const removeProductWrapper = (key: string) => {
  emit('removeProduct', key)
}
const checkKeysWrapper = (keys: string[], e: any) => {
  emit('checkKeys', keys, e)
}
const expandKeysWrapper = (keys: string[]) => {
  emit('expandKeys', keys)
}

const isOpenModalOtherAccountSettings = ref(false)

const toggleAuctionModalOtherAccountSettings = () => {
  isOpenModalOtherAccountSettings.value = !isOpenModalOtherAccountSettings.value
}
</script>

<template>
  <div class="create-request-body">
    <AuctionSupplyRequirments
      ref="firstFormRef"
      v-if="props.currentStep === 0"
      :addressList="addressList"
      :contactPersonList="contactPersonList"
      :form-state="props.firstFormState"
      @editFirstForm="firstFormEditWrapper"
      @toggleAuctionModalOtherAccountSettings="
        toggleAuctionModalOtherAccountSettings
      "
    />
    <AuctionProducts
      v-if="props.currentStep === 1"
      ref="secondFormRef"
      :form-state="props.secondFormState"
      :checkedKeys="checkedKeys"
      :expandedKeys="expandedKeys"
      :treeData="treeData"
      @editSecondForm="secondFormEditWrapper"
      @addProduct="addProductWrapper"
      @removeProduct="removeProductWrapper"
      @checkKeys="checkKeysWrapper"
      @expandKeys="expandKeysWrapper"
    />
    <AuctionCustomers
      v-if="props.currentStep === 2"
      :form-state="props.thirdFormState"
      @deleteCustomer="thirdFormEditWrapper"
      @handleSelectCustomerModal="handleSelectCustomerModalWrapper"
      @handlePublicReduction="emit('handlePublicReduction')"
    />
  </div>
  <AuctionModalOtherAccountSettings
    :open="isOpenModalOtherAccountSettings"
    @closeAuctionModalOtherAccountSettings="
      toggleAuctionModalOtherAccountSettings
    "
  />
</template>

<style scoped lang="scss">
.create-request-body {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
