<script setup lang="ts">
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import CustomerRequestsView from '@/views/Customer/CustomerRequestsView.vue'
import FarmerRequestsView from '@/views/Farmer/FarmerRequestsView.vue'
import RequestsHeader from '@/views/RequestsHeader.vue'
import { computed } from 'vue'

const { getCoreCookie } = useJWTDecode()

const isCustomer = computed(() => getCoreCookie()?.company_type === Roles.Customer)
</script>

<template>
  <div class="wrapper">
    <RequestsHeader :is-customer="isCustomer" />
    <CustomerRequestsView v-if="isCustomer" />
    <FarmerRequestsView v-else />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.wrapper {
  padding-top: 20px;
  @include wrapper();
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
