import { createApp } from 'vue';
// import {initSW} from "@/helpers/SWHelpers";
import './utils/fetchInterceptor.js';
import App from './App.vue';
import '@egalteam/widget-library/dist/style.css';
import '@/assets/style/global.scss';
import '@/assets/style/excel-styles.scss';
import 'vue-toastification/dist/index.css';
import {initMetrica} from './helpers/metricaHelpers';
import {initToasts} from "@/helpers/toastHelpers";
import {initPinia} from "@/helpers/piniaHelpers";
import {initRouter} from "@/helpers/routerHelpers";
import {initRecatcha} from "@/helpers/recapchaHelpers";
import {initEgalWidgets} from "@/helpers/egalHelpers";
import {initVClickOutsideDir} from "@/helpers/directiveHelpers";
import {initMaska} from "@/helpers/maskaHelpers";

const app = createApp(App);
initRouter(app);
initMaska(app);
initToasts(app);
initPinia(app);
initEgalWidgets(app);
initVClickOutsideDir(app);
initRecatcha(app);
initMetrica(app);
// initSW();
app.mount('#app');
