<script lang="ts" setup>
import { checkboxStyleConfig } from '@/assets/EgalStyles/ECheckbox'
import { ICatalogFilters } from '@/store/catalogStore'


interface FProps {
  allItems: any
  isShow: boolean
  filterItem: ICatalogFilters
}

const props = defineProps<FProps>()
const emit = defineEmits(['handleSingleFilter'])

const LabelCheckboxStyle = {
  labelStyle: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '400',
    color: '#2D3748',
  },
}
</script>

<template>
  <div class="filters" v-if="isShow">
    <div v-for="item in props.filterItem.rules" :key="item">
      <div class="filters-list">
        <ECheckbox
          class="main-checkbox filters-item"
          :data="{
            checked: props.filterItem.values.includes(item),
            disabled: props.filterItem.disabled,
          }"
          :class="{
            disabled: props.filterItem.disabled,
          }"
          @change="emit('handleSingleFilter', item)"
          :style-config="{
            ...LabelCheckboxStyle,
            ...checkboxStyleConfig,
            borderColor: '#A0AEC0',
          }"
        >
          <span class="filters-item-text">{{
            item.charAt(0).toUpperCase() + item.slice(1)
          }}</span>
        </ECheckbox>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 32px 12px 24px;

  &-list {
    display: flex;
    flex-direction: column;
  }

  .main-checkbox {
    &.disabled {
      span {
        color: #00000040;
      }
    }
    &:not(.disabled) {
      &:hover {
        span {
          color: #4096ff;
        }
      }

      &:active {
        span {
          color: #0958d9;
        }
      }
    }
  }

  &-item {
    margin: 8px 0;
    :deep(.e-checkbox__text::before) {
      width: 16px !important;
      height: 16px !important;
    }
    &-text {
      display: inline-block;
    }
  }
}
</style>
