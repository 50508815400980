<script lang="ts" setup>
import { Layout } from 'ant-design-vue'
import SideMenu from '@/components/Admin/Layouts/SideMenu.vue'
import {computed, CSSProperties, onMounted, watch} from 'vue'
import {useAuth} from "@/composables/useAuth";
import {Roles} from "@/types/enums";
import router from '@/router'
import {useJWTDecode} from "@/composables/useJWTDecode";
import {useRoute} from "vue-router";
import {initSessionTimer} from "@/axios";
import {useCookies} from "vue3-cookies";

const Sider = Layout.Sider
const Content = Layout.Content

const sider_style: CSSProperties = {
  backgroundColor: '#FFFFFF',
}

const auth = useAuth()
const { getRoles } = useJWTDecode()
const { cookies } = useCookies()
const route = useRoute()
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => !!umt && !!chat)
const isUser = computed(() => getRoles()[0] === Roles.Farmer || getRoles()[0] === Roles.Customer)
const isAdmin = computed(() => getRoles()[0] === Roles.Admin || getRoles()[0] === Roles.SuperAdmin)
const logoutToken = computed(() => localStorage.getItem('logout_token'))

const superUserMiddleware = async () => {
  if (logoutToken?.value && isUser.value) await auth.unmaskUser(logoutToken?.value)
  else if (isUser.value) await router.push('/')
  else if (isAdmin.value) {
    localStorage.removeItem('adminTime')
    localStorage.removeItem('company_id')
    localStorage.removeItem('main')
    localStorage.removeItem('orderStore')
    localStorage.removeItem('user')
    localStorage.removeItem('userData')
  }
}

onMounted(async () => {
  await superUserMiddleware()
  if (isAuth?.value) {
    initSessionTimer()
  }
})

watch(() => route.fullPath, async () => await superUserMiddleware())
</script>

<template>
  <Layout>
    <Sider :style="sider_style" width="236">
      <SideMenu />
    </Sider>
    <Content>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </Content>
  </Layout>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

.layout-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  .header {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
  }

  .sidebar {
    &__footer {
      cursor: pointer;
      padding-left: 24px;
      margin-bottom: 40px;
      transition: color 0.1s;

      &:hover {
        color: $default-accent;
      }
    }
    &__footer-icon {
      margin-right: 10px;
    }
    &__footer-content {
      font-size: 14px;
    }
  }
  :deep(.sidebar) {
    .navbar__logo-group {
      visibility: hidden;
    }
  }

  .content {
    min-height: calc(100vh - 69px - 40px - 80px); // высота хэдера;
    background-color: $gray-100;
    padding: 40px 50px 80px 290px; // left: сайдбар(250px) + паддинг контента(40px)
  }
}

.logo {
  cursor: pointer;
}
.layout-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-bottom: 1px solid $gray-300;
  height: 68px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1408px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
}
</style>
