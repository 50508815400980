<script lang="ts" setup>
import { computed } from 'vue'
import { IReductionParticipant } from '@/types/interfaces'
import { Badge, Space } from 'ant-design-vue'

interface IProps {
  participants: IReductionParticipant[]
  isPublic: boolean
}

const props = defineProps<IProps>()
const hasBet = computed(() => {
  return props.participants.filter((item) => item.answer_status === 'has_bet')
    .length
})
</script>

<template>
  <div class="user-cell">
    <Space :size="4" direction="vertical">
      <template v-if="isPublic">
        <Badge status="success" :text="hasBet" />
      </template>
      <template v-else>
        <Badge
          status="warning"
          :text="`${participants.length - hasBet}/${participants.length}`"
        />
        <Badge status="success" :text="`${hasBet}/${participants.length}`" />
      </template>
    </Space>
  </div>
</template>

<style lang="scss" scoped>
.user-cell {
}
</style>
