<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue'
import { useCookies } from 'vue3-cookies'
import StepOne from '@/views/InviteRegister/Steps/StepOne.vue'
import StepTwo from '@/views/InviteRegister/Steps/StepTwo.vue'
import {useRoute} from "vue-router";

const { cookies } = useCookies()
const registerForm = ref()
const stepOneDone = ref()

onMounted(() => {
  checkStep()
})

const checkStep = () => {
  registerForm.value = cookies.get('registerForm') // Прислали данные в stepOne
  stepOneDone.value = cookies.get('inviteStepOne') // Перешли по токену в почте
}

const route = useRoute()
</script>

<template>
  <StepOne v-if="!stepOneDone" />
  <StepTwo v-if="registerForm && stepOneDone" />
</template>
