export interface ICustomInfinityScroll {
  target: {
    scrollTop: number
    offsetHeight: number
    scrollHeight: number
  }
}

export const customInfinityScroll = (event: ICustomInfinityScroll) => {
  const target = event.target
  return target.scrollTop + target.offsetHeight >= target.scrollHeight - 5
}
