<script lang="ts" setup>
import { computed, ref } from 'vue'
import { declensionProduct } from '@/helpers/scripts'
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import { TagType } from '@/types/enums'
import PickupIcon from '@/components/Icons/PickupIcon.vue'
import FarmerDeliveryIcon from '@/components/Icons/FarmerDeliveryIcon.vue'
import { Button, List, ListItem } from 'ant-design-vue'
import { DeleteFilled } from '@ant-design/icons-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { EDeliveryMethod, IOrderFarmer } from '@/store/registrationOrderStore'
import OrderProductItem from '@/views/Customer/CreateOrder/components/FarmerExpandableCard/OrderProductItem.vue'
import ArrowDown from '../../../../../assets/img/arrow_down.svg'

interface Props {
  orderFarmer: IOrderFarmer
  deliveryMethod: keyof typeof EDeliveryMethod
  isShowDeleteBtn: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['setDeletingFarmer'])
const isOpen = ref(false)
const toggleCard = () => {
  isOpen.value = !isOpen.value
}
const deleteFarmer = () => {
  emit('setDeletingFarmer', props.orderFarmer)
}

const isSomeItemsOverStock = computed(() => {
  return props.orderFarmer.product_items.some(
    (item) => +item.quantity > +item.farmer_product.quantity
  )
})

</script>

<template>
  <div class="expandable-card" :class="isSomeItemsOverStock && 'over-stock'">
    <div
      class="expandable-card-header"
      @click="toggleCard"
      :class="!isOpen ? 'short' : ''"
    >
      <div class="card-row">
        <div class="cart-tags">
          <CustomTag
            :shadow="true"
            v-if="orderFarmer.can_deliver && deliveryMethod !== 'Самовывоз'"
            :type="TagType.WHITE"
            weight="thin"
            :text="'Доставка'"
          >
            <template #icon>
              <FarmerDeliveryIcon fill="#2D3748" />
            </template>
          </CustomTag>
          <CustomTag
            :shadow="true"
            v-else
            :text="'Самовывоз'"
            weight="thin"
            :type="
              deliveryMethod !== 'Самовывоз' ? TagType.ORANGE : TagType.WHITE
            "
          >
            <template #icon>
              <PickupIcon
                :fill="deliveryMethod !== 'Самовывоз' ? '#EF874D' : '#2D3748'"
              />
            </template>
          </CustomTag>
          <CustomTag
            v-if="orderFarmer.is_partner"
            :shadow="true"
            :text="'Договор подписан'"
            :type="TagType.LIGHTGREEN"
            weight="thin"
          >
            <template #icon>
              <DocumentSignedIcon fill="#11A162" />
            </template>
          </CustomTag>
          <SimpleRate :company_rating="orderFarmer.company_rating" />
        </div>
        <span class="card-items-count">
          {{ orderFarmer.product_items.length }} товар{{
            declensionProduct(orderFarmer.product_items.length)
          }}
          <img
            :src="ArrowDown"
            class="expand-arrow"
            :class="isOpen ? 'revert' : ''"
            alt=""
            loading="lazy"
          />
        </span>
      </div>
      <h4>
        {{ orderFarmer.legal_form }}
        {{ orderFarmer.name }}
      </h4>
      <span>
        {{ orderFarmer.address.street }}
      </span>
      <Button
        v-if="isShowDeleteBtn"
        v-show="isOpen"
        @click.stop="deleteFarmer"
        class="delete-btn"
      >
        <template #icon><DeleteFilled /> </template>
        Исключить поставщика из заказа
      </Button>
    </div>

    <div v-if="isOpen" class="expandable-card-body">
      <List rowKey="id" :data-source="orderFarmer.product_items">
        <template #renderItem="{ item }">
          <ListItem class="list-item">
            <OrderProductItem :key="item.id" :product-item="item" />
          </ListItem>
        </template>
      </List>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.expandable-card {
  border: 1px solid #0000000F;
  border-radius: 12px;
  &-header {
    padding: 16px;
    background-color: #00000005;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border-bottom: 1px solid #0000000f;
    border-radius: 12px 12px 0 0;
    .delete-btn {
      margin-top: 12px;
      width: fit-content;
    }
    &:hover {
      background-color: #00000009;
    }

    &.short {
      border-radius: 12px;
      span,
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .card-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      .cart-tags {
        display: flex;
        flex-direction: row;
        column-gap: 6px;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #000000e0;
    }

    span:not(.anticon) {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000000a6;
    }
  }

  &-body {
    .list-item {
      width: 100%;
      padding: 24px 16px;
      border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
      border-radius: 0 0 12px 12px;
    }
  }
}

.over-stock {
  border: 1px solid #FAAD14;
  span.card-items-count{
      color: #FAAD14;
      font-weight: 600px;
  }
}

.revert {
  transform: rotate(180deg);
}
</style>
