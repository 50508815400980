<script lang="ts" setup>

import { Button } from 'ant-design-vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { IFilter, ISortPartners } from '@/types/interfaces'

interface Props {
  isPartners: boolean
  filters: IFilter
  sort: ISortPartners
}
const props = defineProps<Props>()

const downloadReport = async () => {
  const report = await PartnersAPI.downloadReportPartners(props.filters, props.sort)
  const fileBase64 = report.original.file
  const splitFileBase64 = fileBase64.split(';')
  const type = splitFileBase64[0].split(':')[1] || 'application/vnd.ms-excel'
  const file = splitFileBase64[1].split(',')[1]

  function isValidBase64(str: string) {
    try {
      return btoa(atob(str)) === str
    } catch (e) {
      return false
    }
  }

  if (isValidBase64(file)) {
    const byteCharacters = atob(file)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: type })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const date = new Date()

    link.setAttribute('download', `report_partners-${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    console.error('Invalid base64 string')
  }
}
</script>

<template>
  <div class="partners-list-header">
    <h2>Мои партнеры</h2>
    <Button
      v-if="props.isPartners"
      type="primary"
      size="large"
      @click="downloadReport"
    >
      Скачать отчёт
    </Button>
  </div>
</template>

<style lang="scss" scoped>
.partners-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    font-size: 30px;
  }
}
</style>
