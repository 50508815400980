<script lang="ts" setup>
import {
  Button,
  Divider,
  Modal,
  notification,
  Table,
  Tooltip,
} from 'ant-design-vue'
import {
  CloseOutlined,
  EditFilled,
  InfoCircleFilled,
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue'
import {computed, h, onMounted, ref, watch} from 'vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import {priceWithVat, roundNumber} from '@/helpers/scripts'
import router from '@/router'
import { useRoute } from 'vue-router'
import { IFarmerProduct, IPriceOption } from '@/types/interfaces'
import { PriceOptionAPI } from '@/api/PriceOptionAPI'
import DrawerPriceOptions from '@/components/Drawer/DrawerPriceOptions.vue'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
import { priceWithoutVat } from '@/helpers/scripts'

const route = useRoute()

const columnPriceOptions = [
  {
    title: 'Объём',
    dataIndex: 'min_value',
    key: 'min_value',
    width: 188,
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price',
    key: 'price',
    width: 140,
  },
  {
    title: 'Цена без НДС',
    dataIndex: 'price_without_vat',
    key: 'price_without_vat',
    width: 160,
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    width: 524,
  },
  {
    title: '',
    key: 'expand',
    width: 128,
  },
]

const closeEdit = () => router.go(-1)
const isShowModalUpdatePage = ref<boolean>(false)
  const isErrorDelete = ref<boolean>(false)
const selectedIndex = ref(0)

const product = ref<IFarmerProduct>({})
const products = ref<IFarmerProduct[]>([])

const getProduct = async (id: number) => {
  await FarmerProductAPI.getItem(id, ['priceOptions']).then((res) => {
    product.value = res
    priceOptionsAscending()
  })
}

const productId = route.params.id

onMounted(() => {
  getProduct(productId)
})

const open = ref<boolean>(false)
const editPrice = ref<boolean>(false)
const priceOption = ref<IPriceOption>({})

const openDrawer = (
  gonnaEdit: boolean,
  option?: IPriceOption,
  index: number,
) => {
  priceOption.value = option
  editPrice.value = gonnaEdit
  open.value = true
  selectedIndex.value = index
}

const closeDrawer = () => {
  open.value = false
}

const getPriceOptions = (id: number) => {
  getProduct(id)
}

const openModal = ref<boolean>(false)

const showModal = (option: object) => {
  optionData.value = option
  openModal.value = true
}
const closeModal = () => {
  openModal.value = false
}

const optionData = ref<object>({})

const getOptionIndex = (optionData: object) => {
  return product.value.price_options?.findIndex(
    (option) => option.id === optionData.id,
  )
}

const deleteOption = async (id: number) => {
  await PriceOptionAPI.delete([id])
    .then(async () => {
      notificationDeleted()
      closeModal()
      await getProduct(Number(productId))
    })
    .catch((e) => {
      console.error('deleteOption e', e.code)
      isErrorDelete.value = true
      closeModal()
      closeDrawer()
      isShowModalUpdatePage.value = true
    })
}

const notificationDeleted = () => {
  notification.info({
    message: 'Цена удалена',
    top: '80px',
    icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
  })
}

const getNewPrice = (index?: number) => {
  if (index === 0) return product.value?.price_options[1]?.price
  else return product.value?.price_options[index - 1]?.price
}

const getMaxValue = (index: number) => {
  if (index + 1 == product.value?.price_options?.length) {
    return 1000000
  } else {
    let subtractMin = product.value?.measurement_unit_id == 'кг' ? 0.01 : 1
    return product.value?.price_options[index + 1]?.min_value - subtractMin
  }
}

const priceOptionsAscending = () => {
  product.value.price_options = product.value.price_options?.sort((a, b) =>
    Number(a.min_value) > Number(b.min_value) ? 1 : -1,
  )
}

onMounted(() => {
  FarmerProductAPI.getPriceOptions(
    [['farmer_product_id', 'eq', String(productId)]],
    [['min_value', 'asc']],
  )
})

const calcPrice = (price: number, product: any) => {
  if (price && product.measurement_unit_id) {
    return `${roundNumber(price)} ₽/${product.measurement_unit_id}`
  } else if (price) return `${roundNumber(price)} ₽`
  else return '-'
}

const calcPriceWithVat = (record: any, product: any) => {
  if (record?.price && record?.vat_percent && product?.measurement_unit_id) {
    return `${roundNumber(priceWithVat(record?.price, record?.vat_percent))}₽/${product?.measurement_unit_id}`
  } else if (record?.price && record?.vat_percent)
    return `${roundNumber(priceWithVat(record?.price, record?.vat_percent))}₽`
  else return '-'
}

const uniqWordsInString = (str: string) => {
  if (!str) return ''
  const words = str?.split(' ')
  const uniqueWords = [...new Set(words)]
  return uniqueWords?.join(' ')
}

watch(isShowModalUpdatePage, () => {
  isErrorDelete.value = false
})

</script>

<template>
  <DrawerPriceOptions
    :selectedIndex="selectedIndex"
    v-model:open="open"
    :priceOption="priceOption"
    :product="product"
    :products="products"
    :editPrice="editPrice"
    :isOpen="open"
    @closeDrawer="closeDrawer"
    @getPriceOptions="getPriceOptions"
    @showModal="showModal"
    @openUpdatePageModal="(status) => (isShowModalUpdatePage = status)"
  />
  <div class="layout">
    <div class="layout__header">
      <Button @click="closeEdit">
        <template #icon> <LeftOutlined /> </template>
      </Button>
      <div class="layout__header__title_name">
        <h2>Редактирование списка цен</h2>
        <span class="product_name">
          {{ uniqWordsInString(product?.product?.fullNameWithMainCategory) }}
        </span>
      </div>
    </div>
    <Divider />
    <Table
      :columns="columnPriceOptions"
      :data-source="product?.price_options"
      :pagination="false"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'min_value'">
          <span>
            {{ record.min_value }}-{{ getMaxValue(index) }}
            {{ product.measurement_unit_id }}
          </span>
        </template>
        <template v-if="column.key === 'price'">
          {{ calcPrice(record.price, product) }}
        </template>
        <template v-else-if="column.key === 'price_without_vat'">
            {{ calcPrice(+priceWithoutVat(record.price, product.vat_percent), product) }}
            <span class="vat"> НДС {{ product?.vat_percent }}% </span>
        </template>
        <template v-else-if="column.key === 'comment'">
          <span v-if="record?.comment"> {{ record?.comment }} </span>
          <span v-else> - </span>
        </template>
        <template v-if="column.key === 'expand'">
          <div class="expand_buttons">
            <Tooltip
              placement="bottom"
              :overlayInnerStyle="{
                width: '121px',
              }"
            >
              <template #title>
                <span> Редактировать </span>
              </template>
              <Button type="text" @click="openDrawer(true, record, index)">
                <EditFilled class="edit" />
              </Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              :overlayInnerStyle="{
                width: '73px',
              }"
            >
              <template #title>
                <span> Удалить </span>
              </template>
              <Button
                type="text"
                danger
                @click="showModal(record)"
                :disabled="product?.price_options?.length === 1 || index === 0"
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </template>
      </template>
    </Table>
  </div>
  <div class="footer">
    <div class="footer__button_panel">
      <Button size="large" class="close" @click="closeEdit"> Закрыть </Button>
      <Tooltip
        v-if="product.price_options?.length >= 20"
        :overlayInnerStyle="{
          width: '302px',
        }"
      >
        <template #title>
          <span>
            Создано максимум цен. <br />
            Удалите ненужные, чтобы создать новые
          </span>
        </template>
        <Button size="large" type="primary" disabled>
          <template #icon> <PlusOutlined /> </template>
          Добавить цену
        </Button>
      </Tooltip>
      <Button v-else size="large" type="primary" @click="openDrawer(false)">
        <template #icon> <PlusOutlined /> </template>
        Добавить цену
      </Button>
    </div>
  </div>
  <Modal width="440px" centered v-model:open="openModal" title="Удалить цену?">
    <p class="modalText">
      Диапазону от {{ optionData?.min_value }} до
      {{ getMaxValue(getOptionIndex(optionData)) }}
      будет назначена цена
      {{ getNewPrice(getOptionIndex(optionData)) }}
    </p>
    <template #footer>
      <Button key="back" @click="closeModal">Закрыть</Button>
      <Button key="submit" @click="deleteOption(optionData.id)" type="primary"
        >Удалить</Button
      >
    </template>
  </Modal>
  <ModalUpdatePage
    :description="
      isErrorDelete
        ? 'Цена была удалена другим пользователем. Обновите страницу для подгрузки актуальных данных.'
        : 'Цена была изменена другим пользователем. Обновите страницу для подгрузки актуальных данных.'
    "
    v-model:open="isShowModalUpdatePage"
  />
</template>

<style lang="scss" scoped>
.layout {
  background: white;
  min-height: 66vh;
  padding: 20px 50px 0 50px;
  width: 100%;

  &__header {
    display: flex;

    Button {
      padding: 0;
      min-height: 34px;
      min-width: 34px;
      width: 34px;
      height: 34px;
    }

    &__title_name {
      padding-left: 12px;

      h2 {
        margin-bottom: 4px;
        font-size: 30px;
      }

      span {
        font-size: 14px;
        color: #000000a6;
      }
    }
  }
}

.footer {
  margin: -40px -50px -48px -50px;
  height: 88px;
  box-shadow: 0px -4px 8px #0000001f;
  background: white;

  &__button_panel {
    margin-top: 60px;
    right: 24px;
    position: absolute;

    .close {
      margin-right: 16px;
    }
  }
}

.modalText {
  height: 100%;
}

.expand_buttons {
  width: 90px;
  margin: auto;

  Button {
    margin-right: 8px;
    padding: 0;
    width: 34px;
    height: 34px;

    .edit {
      color: #1677ff;
    }
  }
}

.vat {
  display: block;
  color: #00000073;
}

:deep(.ant-table-wrapper) {
  margin-bottom: 174px;
  border-radius: 10px;
  border: 1px solid #0000000f;
}
</style>
