<script setup lang="ts">
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import { computed, onMounted, ref, watch } from 'vue'
import router from '@/router'
import ToolbarWidget from '@/views/Farmer/response/toolbarWidget/ToolbarWidget.vue'
import ResponseHeader from '@/views/Farmer/response/ResponseHeader.vue'
import { PriceAnswerAPI } from '@/api/PriceAnswerAPI'
import { IPriceAnswer, IProductAnswer } from '@/types/interfaces'
import { useRoute } from 'vue-router'
import ResponseFooter from '@/views/Farmer/response/ResponseFooter.vue'
import { RecipientAPI } from '@/api/RecipientAPI'
import ResponseProductCard from '@/views/Farmer/response/ResponseProductCard.vue'
import dayjs from 'dayjs'
import { TProductAnswerStatus } from '@/types/types'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import {useCookies} from "vue3-cookies";
import emitter from "@/emitter";

const route = useRoute()

const priceAnswer = ref<IPriceAnswer | null>(null)

const productsAnswers = ref<IProductAnswer[]>([])
const isLoading = ref<boolean>(false)

const toolbarRef = ref(null)

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const isCustomer = computed(() => getCoreCookie()?.company_type === Roles.Customer)

const getPriceAnswer = async (recipientId: string) => {
  await PriceAnswerAPI.getItems(
    1,
    [['recipient_id', 'eq', recipientId]],
    [
      'productAnswers.product',
      'recipient.priceRequest',
      'recipient.farmer',
      'productAnswers.productRequest',
      'productAnswers.product.productCategory',
      'recipient.priceRequest.customer',
      'recipient.priceRequest.customer.company_rating',
    ],
  ).then(async ({ items }: { items: IPriceAnswer[] }) => {
    if (items[0]) {
      let currentPriceAnswer = items[0]
      if (
        currentPriceAnswer.recipient?.farmer.can_deliver === false &&
        currentPriceAnswer.is_supplier_delivery === true &&
        ['received'].includes(currentPriceAnswer.recipient.answer_status)
      ) {
        const updatedDeliveryPriceAnswer =
          await updateDeliveryMethodPriceAnswer(Number(currentPriceAnswer?.id))

        currentPriceAnswer = {
          ...currentPriceAnswer,
          ...updatedDeliveryPriceAnswer,
        }
      }
      priceAnswer.value = currentPriceAnswer
      if (items[0].product_answers) {
        productsAnswers.value = items[0].product_answers
      }
    }

    isLoading.value = false
  })
}

const updateDeliveryMethodPriceAnswer = async (priceAnswerId: number) => {
  return await PriceAnswerAPI.update({
    id: priceAnswerId,
    is_supplier_delivery: null,
    is_self_delivery: true,
  }).then((res) => {
    return res
  })
}

const changeAnswerProductStatus = (
  id: number,
  status: TProductAnswerStatus,
) => {
  const changedElemStatusIndex = productsAnswers.value.findIndex((product) => {
    return product.id === id
  })

  if (changedElemStatusIndex !== -1) {
    productsAnswers.value[changedElemStatusIndex].status = status
  }
}

const initFarmRes = async () => {
  if (isCustomer.value) {
    await router.push('/')
  } else {
    const recipientId = String(route.params.id)
    await RecipientAPI.getItem(recipientId).then((item) => {
      isLoading.value = true
      if (item.answer_status !== 'waiting') {
        getPriceAnswer(recipientId)
      } else {
        RecipientAPI.update({
          id: recipientId,
          answer_status: 'received',
        }).then(() => {
          getPriceAnswer(recipientId)
        })
      }
    })
  }
}

emitter.on('refresh-token', async (e) => {
  await initFarmRes()
})

onMounted(async () => {
  await initFarmRes()
})

const customer = computed(() => {
  return priceAnswer.value?.recipient?.price_request?.customer
})

const priceRequest = computed(() => {
  return priceAnswer.value?.recipient.price_request
})

const isOnlyOneProduct = computed(() => {
  return (
    productsAnswers.value.filter(
      (product) =>
        product.status === 'answer_received' ||
        product.status === 'answer_waiting',
    ).length === 1
  )
})
// если true - остался только один валидный продукт из списка (в ожидании или принятыц)

const originalIndexMap = new Map(
  productsAnswers.value.map((item, index) => [item.product?.fullName, index]),
)

function isEqual(a, b) {
  // Check if the types of a and b are different
  if (typeof a !== typeof b) {
    return false
  }

  // Check if a and b are both null or undefined
  if (a === null || b === null || a === undefined || b === undefined) {
    return a === b
  }

  // Check if a and b are not objects
  if (typeof a !== 'object' || typeof b !== 'object') {
    return a === b
  }

  // Check if a and b have different number of keys
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false
  }

  // Recursively compare each key-value pair
  for (let key in a) {
    if (!isEqual(a[key], b[key])) {
      return false
    }
  }

  return true
}

const sortProductsAnswersByStatus = () => {
  const sortedProductsAnswers = productsAnswers.value.slice().sort((a, b) => {
    if (a.status === 'no_product' && b.status !== 'no_product') {
      return 1
    }
    if (a.status !== 'no_product' && b.status === 'no_product') {
      return -1
    }
    const aFullName = a.product?.fullName?.toLowerCase() || ''
    const bFullName = b.product?.fullName?.toLowerCase() || ''
    return aFullName.localeCompare(bFullName)
  })

  // Save the sorted indices
  const sortedIndices = sortedProductsAnswers.map((product) =>
    originalIndexMap.get(product.id),
  )
  localStorage.setItem('sortedIndices', JSON.stringify(sortedIndices))

  // Update the productsAnswers array with the sorted elements
  productsAnswers.value.splice(
    0,
    productsAnswers.value.length,
    ...sortedProductsAnswers,
  )
}

onMounted(() => {
  if (localStorage.getItem('sortedIndices')?.length) {
    const sortedIndices = JSON.parse(
      localStorage.getItem('sortedIndices') as string,
    )
    if (sortedIndices) {
      const currentIndices = productsAnswers.value.map((product) =>
        originalIndexMap.get(product.id),
      )
      if (!isEqual(sortedIndices, currentIndices)) {
        productsAnswers.value.sort((a, b) => {
          const aIndex = sortedIndices.indexOf(originalIndexMap.get(a.id))
          const bIndex = sortedIndices.indexOf(originalIndexMap.get(b.id))
          return aIndex - bIndex
        })
      }
    }
  }
})

onMounted(() => {
  //@ts-ignore
  const sortedIndices = JSON.parse(localStorage.getItem('sortedIndices'))
  if (sortedIndices) {
    productsAnswers.value.sort((a, b) => {
      const aIndex = sortedIndices.indexOf(originalIndexMap.get(a.id))
      const bIndex = sortedIndices.indexOf(originalIndexMap.get(b.id))
      return aIndex - bIndex
    })
  }
})

window.addEventListener('beforeunload', () => {
  sortProductsAnswersByStatus()
})

watch(productsAnswers, () => {
  sortProductsAnswersByStatus()
})

</script>

<template>
  <FullscreenLoading v-if="isLoading" :type="'secondary'" />
  <div
    v-else-if="priceAnswer && customer && priceRequest"
    class="wrapper"
    :style="{
      height: toolbarRef?.isOpen ? '100%' : 'calc(100vh - 69px)',
    }"
  >
    <ResponseHeader
      :recipientStatus="priceAnswer?.recipient.answer_status"
      :organizationName="customer.name"
      :requestDate="dayjs(priceRequest.created_at).format('DD.MM.YYYY')"
      :requestExpired="dayjs(priceRequest.expiration_date).format('DD.MM.YYYY')"
    />
    <div class="content">
      <div class="content__top">
        <ToolbarWidget :priceAnswer="priceAnswer" ref="toolbarRef" />
        <div v-if="productsAnswers.length" style="width: 100%">
          <ResponseProductCard
            v-for="product in productsAnswers"
            :key="product.id"
            :isOnlyOneProduct="isOnlyOneProduct"
            :answerProduct="product"
            :recipientStatus="priceAnswer.recipient.answer_status"
            @changeProductStatus="changeAnswerProductStatus"
          />
        </div>
      </div>
    </div>
    <template v-if="priceAnswer && productsAnswers">
      <ResponseFooter
        @send-answer="
          () => {
            sortProductsAnswersByStatus()
          }
        "
        :recipient-status="priceAnswer.recipient.answer_status"
        :recipient-id="priceAnswer.recipient.id"
        :productAnswers="productsAnswers"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.wrapper {
  @include wrapper();
  //height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__top {
    height: 100%;
    display: flex;
    overflow: hidden;
  }
}
</style>
