<script setup lang="ts">
import { ref } from 'vue'
import { IFarmer } from '@/types/interfaces'
import SmallFarmerCard from '@/components/Farmer/SmallFarmerCard.vue'
import { Button, Space } from 'ant-design-vue'
import { declensionProducts } from '@/helpers/scripts'
import { DownOutlined } from '@ant-design/icons-vue'
interface Props {
  farmer: IFarmer
}

const props = defineProps<Props>()
const isExpanded = ref(false)
</script>

<template>
  <div class="small-farmer-card-with-list">
    <SmallFarmerCard :farmer="props.farmer">
      <template #footer>
        <Space :size="12" :direction="'vertical'">
          <Button
            type="link"
            class="expand-list-btn"
            @click="isExpanded = !isExpanded"
            :class="isExpanded ? 'active' : ''"
            v-if="props.farmer.missing_products.length"
          >
            {{
              `Отсутствует ${
                props.farmer.missing_products.length
              } ${declensionProducts(props.farmer.missing_products.length)}`
            }}
            <template #icon>
              <DownOutlined />
            </template>
          </Button>
          <ol class="missing-products-list" v-if="isExpanded">
            <li v-for="product in props.farmer.missing_products" :key="product">
              {{ product }}
            </li>
          </ol>
        </Space>
      </template>
    </SmallFarmerCard>
  </div>
</template>

<style scoped lang="scss">
.small-farmer-card-with-list {
  .ant-btn {
    padding: 4px 0;
    .anticon {
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
    }
    &.active {
      .anticon {
        transform: rotate(180deg);
      }
    }
    &.expand-list-btn {
      color: #fa8c16;
      &:hover {
        color: #ffa940;
      }
      &:active {
        color: #d46b08;
      }
    }
  }
  .missing-products-list {
    list-style: inside;
  }
}
</style>
