<script lang="ts" setup>
import {
  TypographyText as TpText,
  Modal,
  Flex,
  Form,
  FormItem,
  Input,
  Button,
  FormInstance,
  Tooltip,
} from 'ant-design-vue'
import { reactive, ref } from 'vue'
import { Rule } from 'ant-design-vue/es/form'
import { Roles } from '@/types/enums'
import { TokenAPI } from '@/api/TokenAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { CopyOutlined } from '@ant-design/icons-vue'
import { useCookies } from 'vue3-cookies'

interface Props {
  isOpen: boolean
}

interface EmailForm {
  email: string
}

const checkMail = async (_rule: Rule, value: string) => {
  let mailCheck = /\S+@\S+\.\S+/
  if (!value) {
    return Promise.reject('Введите почту')
  }
  if (!mailCheck.test(value)) {
    return Promise.reject('Почта введена неверно')
  } else {
    if (mailExist.value) {
      return Promise.reject('Пользователь c таким email уже существует.')
    } else return Promise.resolve()
  }
}

const rules: Record<string, Rule[]> = {
  email: [{ required: true, validator: checkMail, trigger: 'change' }],
}

defineProps<Props>()

const emit = defineEmits(['closeModal'])

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

const formRef = ref<FormInstance>()

const emailForm = reactive<EmailForm>({
  email: '',
})
const inviteCreated = ref(false)
const inviteLink = ref()
const mailExist = ref(false)

const createInvite = () => {
  formRef.value?.validateFields().then(() => {
    // TODO: Проверить
    const companyID = JSON.parse(localStorage.getItem('user') || '')?.company_id
    const role =
      getCoreCookie()?.company_type === 'farmer' ? Roles.Farmer : Roles.Customer

    TokenAPI.createInvite(emailForm.email, companyID, role)
      .then((res) => {
        inviteLink.value = res.link
        inviteCreated.value = true
        mailExist.value = false
      })
      .catch((err) => {
        if (err?.response?.status == 405) {
          mailExist.value = true
          formRef.value?.validateFields()
        }
      })
  })
}
const copyLink = () => {
  navigator.clipboard.writeText(inviteLink.value)
}

const resetForm = () => {
  formRef.value?.resetFields()
}

const handleCloseModal = () => {
  emit('closeModal')
  inviteCreated.value = false
  inviteLink.value = ''
  emailForm.email = ''
  mailExist.value = false
  resetForm()
}
</script>

<template>
  <Modal
    centered
    width="557px"
    :open="isOpen"
    @cancel="handleCloseModal"
    title="Создание приглашения для сотрудника"
    class="modal"
  >
    <Flex :gap="16" vertical>
      <TpText>
        Приглашенный пользователь сможет зарегистрироваться как сотрудник только
        компании-отправителя приглашения и только по указанному <br />
        в приглашении email. Приглашение активно 24 часа.
      </TpText>

      <Form
        v-if="!inviteCreated"
        :model="emailForm"
        :rules="rules"
        ref="formRef"
        layout="vertical"
      >
        <FormItem label="Электронная почта" name="email" class="ym-record-keys">
          <Input
            class="inputMail"
            v-model:value="emailForm.email"
            placeholder="Введите email"
            size="large"
          />
        </FormItem>
      </Form>

      <Tooltip placement="bottom" title="Ссылка скопирована" trigger="click">
        <Input
          v-if="inviteCreated"
          :value="inviteLink"
          class="copy-mail"
          @click="copyLink"
        >
          <template #suffix>
            <Button class="copy-button" @click="copyLink">
              <template #icon>
                <CopyOutlined class="copy-icon" />
              </template>
            </Button>
          </template>
        </Input>
      </Tooltip>
    </Flex>

    <template #footer>
      <Flex class="footer" justify="flex-end">
        <Button v-if="!inviteCreated" @click="emit('closeModal')">
          Отмена
        </Button>

        <Button v-if="!inviteCreated" type="primary" @click="createInvite">
          Создать приглашение
        </Button>

        <Button v-else type="primary" @click="handleCloseModal">
          Завершить
        </Button>
      </Flex>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.copy-mail {
  height: 40px;
  border: 1px solid #1677ff;
  color: #1677ff;
  margin-bottom: 40px;

  :deep(.ant-input) {
    color: #1677ff;
  }
}

.copy-button {
  border: none;
  .copy-icon {
    color: #1677ff;
  }
}

.footer {
  margin-top: -12px;
}
</style>
