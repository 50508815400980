import Toast, {PluginOptions} from "vue-toastification";
import { App as IApplication } from 'vue';

export const initToasts = (app: IApplication) => {
    const options: PluginOptions = {
        timeout: 10000,
        // @ts-ignore
        position: 'top-right',
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        icon: false,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
    }
    console.debug('initToasts...')
    app.use(Toast, options as PluginOptions)
}
