<script lang="ts" setup>

import { Divider, Flex, TypographyText as TpText } from 'ant-design-vue'

interface IProps {
  propertyName: string
  propertyValue: string
  isLast?: boolean
}

const props = defineProps<IProps>()
</script>

<template>
  <Flex justify="space-between">
    <TpText type="secondary">{{ props.propertyName }}</TpText>
    <TpText>{{ props.propertyValue }}</TpText>
  </Flex>
  <Divider class="divider" v-if="!isLast" />
</template>

<style lang="scss" scoped>
.divider {
  margin: 8px 0;
}
</style>
