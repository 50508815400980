<script setup lang="ts">
import { getUserIdTemporary, roundNumber } from '@/helpers/scripts'
import { InfoCircleTwoTone } from '@ant-design/icons-vue'
import { Popover, Space, Tag } from 'ant-design-vue'
import { IFarmerProduct } from '@/types/interfaces'
import { computed, withDefaults } from 'vue'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'

interface Props {
  farmerProduct: IFarmerProduct
  show_vat?: boolean
  size?: 'small' | 'default' | 'large'
}

interface IPriceObject {
  hasSpecialPrice: boolean
  price: number
  min_value: number
  comment: string
  next_value: number
  vat_percent: number
}

const subtractValue = {
  кг: 0.01,
  г: 1,
  л: 0.01,
  шт: 1,
}

const userId = getUserIdTemporary()

const props = withDefaults(defineProps<Props>(), {
  show_vat: true,
  size: 'large',
})

const priceObject = computed(() => {
  if (
    !props.farmerProduct?.price_options ||
    !props.farmerProduct?.special_prices
  )
    return
  const priceOptions = [...props.farmerProduct.price_options]
  const priceObject: IPriceObject = {
    hasSpecialPrice: props.farmerProduct.special_prices.length > 0,
    min_value: 0,
    next_value: 0,
    vat_percent: props.farmerProduct.vat_percent,
    price: 0,
    comment: '',
  }

  const lastPrice = priceOptions?.sort((a, b) => +a.price - +b.price)[0]
  const sortedByMinValue = priceOptions?.sort(
    (a, b) => +a.min_value - +b.min_value,
  )
  const nextPriceAfterMin = sortedByMinValue?.findIndex(
    (a) => +a.price === +lastPrice?.price,
  )
  priceObject.price = +lastPrice?.price

  priceObject.min_value = +lastPrice?.min_value

  priceObject.next_value = +sortedByMinValue?.[nextPriceAfterMin + 1]?.min_value
    ? +sortedByMinValue?.[nextPriceAfterMin + 1]?.min_value - subtractValue[props.farmerProduct.measurement_unit_id as 'кг' | 'шт' | 'л']
    : 1000000
  priceObject.comment = lastPrice?.comment

  if (customerSpecialPrice.value) {
    priceObject.price = +customerSpecialPrice.value?.price

    priceObject.comment = customerSpecialPrice.value?.comment || ''
    priceObject.vat_percent = customerSpecialPrice.value?.vat_percent
  }
  return priceObject
})

const customerSpecialPrice = computed(() => {
  return props.farmerProduct?.special_prices?.find(
    (special) => special.customer_id === userId,
  )
})

// const findSpecial = computed(() => {
//   return props.farmerProduct.special_prices?.find(
//     (i) => i.customer.id === userId,
//   )
// })

const calcPrice = () => {
  if (
    !!props.farmerProduct?.measurement_unit_id &&
    !!props?.farmerProduct?.price_options?.length
  ) {
    return `${props?.farmerProduct?.price_options?.length > 1 && !customerSpecialPrice.value ? 'от' : ''} ${priceObject.value?.price} ₽/${props.farmerProduct?.measurement_unit_id}`
  } else if (props?.farmerProduct?.price_options?.length) {
    return `${props?.farmerProduct?.price_options?.length > 1 && !customerSpecialPrice.value ? 'от' : ''} ${priceObject.value?.price} ₽`
  }
}
</script>

<template>
  <Space :size="4" direction="vertical">
    <Space :size="8" class="product-price-block">
      <span
        class="price"
        :class="{ small: size === 'small', default: size === 'default' }"
        >{{ calcPrice() }}</span
      >
      <Popover
        title="Комментарий к цене"
        placement="topLeft"
        v-if="priceObject?.comment"
      >
        <template #content> {{ priceObject?.comment }}</template>
        <InfoCircleTwoTone />
      </Popover>
    </Space>
    <Space
      v-if="customerSpecialPrice"
      :size="size === 'small' ? 8 : 0"
      :direction="size === 'small' ? 'vertical' : 'horizontal'"
    >
      <Tag color="success" v-if="customerSpecialPrice"> Спеццена </Tag>
      <!-- <Tag color="success" v-if="customerSpecialPrice.expires_after">
        Спеццена до {{ dayjs(customerSpecialPrice.expires_after).format('DD.MM.YYYY') }}
      </Tag> -->
      <!-- <Tag
        class="special-price"
        color="success"
        v-if="customerSpecialPrice.quantity"
      >
        {{ customerSpecialPrice.quantity }}
        {{ farmerProduct?.measurement_unit_id }}
      </Tag> -->
    </Space>
    <span class="price-subinfo" v-else-if="priceObject?.min_value">
      {{
      `от ${roundNumber(Number(priceObject?.min_value))} до ${roundNumber(Number(priceObject?.next_value))} ${fillMeasurementUnitIdCommon(farmerProduct.fullName, farmerProduct.measurement_unit_id)}`
    }}</span>
    <span v-if="show_vat" class="price-subinfo">{{
      `НДС ${priceObject?.vat_percent} %`
    }}</span>
  </Space>
</template>

<style scoped lang="scss">
.product-price-block {
  span.price {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-align: right;
    color: #000000e0;
    &.small {
      font-size: 14px;
    }
    &.default {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

span.price-subinfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #000000a6;
}
</style>
