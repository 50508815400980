<script setup lang="ts">
import { Space, Flex, Button } from 'ant-design-vue'
import { onMounted, ref } from 'vue'
import BrowserNotificationInstructionModal from '@/components/Account/BrowserNotificationInstructionModal.vue'

const is_notification_off = ref(false)
const get_notification_status = async () => {
  await Notification.requestPermission().then((permission) => {
    is_notification_off.value = permission !== 'granted'
  })
}

onMounted(() => {
  get_notification_status()
})
const is_open = ref(false)
</script>

<template>
  <template v-if="is_notification_off">
    <div class="notification-reminder">
      <Flex align="center" justify="space-between">
        <Space :size="20">
          <img src="@/assets/img/ringing_bell.png" alt="ringing_bell" loading="lazy" />
          <Space :size="0" direction="vertical">
            <span>Вы отключили уведомления в браузере</span>
            <p>Включите их, чтобы не упустить ничего важного</p>
          </Space>
        </Space>
        <Button @click="is_open = true">Как включить?</Button>
      </Flex>
    </div>
    <BrowserNotificationInstructionModal v-model:open="is_open" />
  </template>
</template>

<style scoped lang="scss">
.notification-reminder {
  background-color: #1677ff;
  border-radius: 12px;
  padding: 11px 16px;
  margin-bottom: 20px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #ffffff;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #ffffff;
  }
}
</style>
