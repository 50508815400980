<script lang="ts" setup>
import { ref } from 'vue'
import { DocumentTypesTitle } from '@/types/enums'
import PassportIcon from '@/components/Icons/PassportIcon.vue'
import DocumentIcon from '@/components/Icons/DocumentIcon.vue'
import { Document } from '@/types/types'
import { getDocumentName, profileDownloadOnClick } from '@/helpers/scripts'
import { outlineGrayButton } from '@/assets/EgalStyles/EButton'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'

interface IProps {
  documentItem: {
    type: Document | undefined,
    file_body: string,
    id: number,
    document_type: string,
    file_name: string
  },
  title: {
    type: String,
  },
}

const props = defineProps<IProps>()

const downloadRef = ref<HTMLAnchorElement>()

const downloadBody = ref({
  file_name: '',
  file_body: props.documentItem.file_body ? props.documentItem.file_body : '',
})
const isDocumentFetching = ref(false)

const fetchFileBody = async () => {
  isDocumentFetching.value = true
  await profileDownloadOnClick(
    props.documentItem?.id,
    downloadBody.value,
    downloadRef.value,
  ).then(() => {
    isDocumentFetching.value = false
  })
}
</script>

<template>
  <div class="document-item">
    <div class="document-cover">
      <PassportIcon v-if="documentItem.document_type === 'passport'" />
      <DocumentIcon v-else width="24px" height="28px" />
    </div>
    <div class="document-info">
      <span>{{ DocumentTypesTitle[title] }}</span>
      <span class="document-sub-info">
        {{ getDocumentName(documentItem?.file_name) }}
      </span>
    </div>
    <div class="document-download" v-if="!isDocumentFetching">
      <EButton
        :style="{
          ...outlineGrayButton,
          fontWeight: 500,
          width: '100%',
          fontSize: '14px',
          lineHeight: '17px',
          color: '#2D3748',
        }"
        class="download-button"
        @click="fetchFileBody"
      >
        <div class="icon-wrapper">
          <DownloadIcon fill="#2D3748" />
        </div>
        Скачать
      </EButton>
    </div>
    <div class="download-loader" v-else>
      <StarLoader />
    </div>
    <a ref="downloadRef" class="document-link" target="_blank" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.document-item {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 50px 1fr auto;
  column-gap: 12px;
  align-items: center;
  .document-cover {
    background-color: $gray-200;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
  }
  .document-info {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    display: flex;
    gap: 4px;
    flex-direction: column;
    .document-sub-info {
      color: $gray-600;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .document-download {
    .icon-wrapper {
      margin-right: 10px;
      margin-bottom: 5px;
      width: 8px;
      height: 8px;
      transform: scale(1.3);
    }
  }
}
.download-loader {
  display: flex;
  :deep(.lds-spinner) {
    width: 25px;
    height: 25px;
    transform: scale(0.3) translateX(-80px) translateY(-20px);
  }
}
</style>
