<script lang="ts">
import { defineComponent } from 'vue'
import CustomerRequestSubheaderAnswers from '@/views/Customer/CustomerRequestView/components/CustomerRequestSubheader/components/Answers/CustomerRequestSubheaderAnswers.vue'

export default defineComponent({
  name: 'CustomersRequestSubheaderAnswersPanel',
  components: { CustomerRequestSubheaderAnswers },
  props: {
    successAnswered: {
      type: Number,
      required: true,
      default: 0,
    },
    totalSuccess: {
      type: Number,
      required: true,
      default: 0,
    },
    totalWarning: {
      type: Number,
      required: true,
      default: 0,
    },
    warningAnswered: {
      type: Number,
      required: true,
      default: 0,
    },
    totalError: {
      type: Number,
      required: true,
      default: 0,
    },
    errorAnswered: { type: Number, required: true, default: 0 },
  },
})
</script>

<template>
  <CustomerRequestSubheaderAnswers
    status="Success"
    :total="totalSuccess"
    :answered="successAnswered"
  />
  <CustomerRequestSubheaderAnswers
    status="Warning"
    :total="totalWarning"
    :answered="warningAnswered"
  />
  <CustomerRequestSubheaderAnswers
    status="Error"
    :total="totalError"
    :answered="errorAnswered"
  />
</template>

<style lang="scss"></style>
