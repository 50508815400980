<script setup lang="ts">
import { CatalogTagsTypes } from '@/types/enums'
import { computed, watch } from 'vue'
import { IFarmer, IPartnership } from '@/types/interfaces'
import CatalogTags from '@/components/Catalog/Tags/CatalogTags.vue'
import { useStore } from '@/store'
import BlurTooltip from '@/components/Tooltip/BlurTooltip.vue'
import { Col, Row, Space, TypographyText as TgText } from 'ant-design-vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import ProductPriceBlock from '@/components/CardTable/ProductPriceBlock.vue'
import AddToCart from '@/components/AddToCart/AddToCart.vue'
import { width100 } from '@/model/constants'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import { storeToRefs } from 'pinia'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import router from '@/router'

interface Props {
  isProductsTable: boolean
  product: {
    id: number
    price: string
    quantity: string
    min_value: string
    product_id: number
    farmer_id: number
    farmer: IFarmer
    partner: IPartnership[]
  }
  targetRef?: HTMLElement
  scrollValue?: number
  index: number
}

const props = defineProps<Props>()
const emit = defineEmits(['is-not-active'])

const { isVerification } = storeToRefs(useStore())

const handleGoToPartner = () => {
  if (isVerification.value) {
    router.push({
      path: `/partners/${props.product.farmer.id}`,
      query: {
        redirectPath: `catalog/${props.product.product_id}`,
        tab: 'catalog',
      },
    })
  }
}

const productTags = computed(() => {
  let tags = [{ type: CatalogTagsTypes.SelfDelivery, text: 'Самовывоз' }]
  props.product.farmer?.can_deliver &&
    tags.push({
      type: CatalogTagsTypes.Delivery,
      text: 'Доставка',
    })
  props.product.farmer.vat &&
    tags.push({
      type: CatalogTagsTypes.WithVat,
      text: 'Налогообложение по НДС',
    })
  return tags
})

const quotesReplacer = (text: string) =>
  text
    ?.replaceAll('""', '')
    ?.replaceAll('<<', '')
    ?.replaceAll('>>', '')
    ?.replaceAll('«', '')
    ?.replaceAll('»', '')

const farmerIndex = props.product.partner?.findIndex(
  (el) => +el.farmer_id === props.product.farmer_id,
)

const isPartner = computed(() => {
  if (
    props.product.partner &&
    props.product.partner[farmerIndex] &&
    props.product.partner[farmerIndex]?.status === 'signed_customer'
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div @click="handleGoToPartner" class="catalog__product">
    <Space :size="8" direction="vertical">
      <BlurTooltip>
        <Space :size="4">
          <PartnerIcon v-if="isPartner" :style="{ color: '#52C41A' }" />
          <TgText strong :class="!isVerification ? 'blur' : ''">
            {{ product.farmer.legal_form }}
            {{ quotesReplacer(product.farmer.name) }}
          </TgText>
        </Space>
      </BlurTooltip>
      <Space :size="4" v-if="product?.farmer?.addresses">
        <EnvironmentOutlined :style="{ color: '#00000073' }" />
        <TgText
          :class="!isVerification ? 'blur' : ''"
          :style="{ color: '#000000A6' }"
        >
          {{ product?.farmer?.addresses[0]?.street }}
        </TgText>
      </Space>
      <div class="rate-tags">
        <SimpleRate :company_rating="product.farmer.company_rating" />
        <CatalogTags :tags="productTags" />
      </div>
    </Space>
    <Row align="bottom" :gutter="[8, 8]" :style="width100">
      <Col :flex="1">
        <ProductPriceBlock
          :farmerProduct="product"
          :show_vat="false"
          :size="'default'"
        />
      </Col>
      <Col>
        <AddToCart :product="product" buttonSize="default" />
      </Col>
    </Row>
  </div>
</template>

<style scoped lang="scss">
@import '@/components/Catalog/catalogStyles.scss';

.rate-tags {
  display: flex;
}

.catalog__product-title {
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    :deep(.ant-typography) {
      color: #1677ff !important;
    }
  }
}

.blur {
  @include blur();
  @include no-select();
}
</style>
