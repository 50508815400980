<script setup lang="ts">
import { Button } from 'ant-design-vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import TitleToolbarBlock from '@/views/Farmer/response/toolbarWidget/TitleToolbarBlock.vue'
import { computed } from 'vue'
import { IFarmerProduct } from '@/types/interfaces'
import { declensionDays } from '@/helpers/scripts'
import ProductProperty from '@/views/Farmer/productResponse/ProductToolbar/ProductProperty.vue'
import NoImgProduct from '../../../../assets/img/no-image-product.png'

interface Props {
  farmerProduct: IFarmerProduct
}
const props = defineProps<Props>()

const emit = defineEmits(['button-click'])

const productProperties = computed(() => {
  if (props.farmerProduct) {
    return [
      {
        name: 'Остаток',
        value: `${props.farmerProduct.quantity} ${props.farmerProduct.measurement_unit_id}`,
      },
      {
        name: 'Мин. заказ',
        value: `${props.farmerProduct.min_value} ${props.farmerProduct.measurement_unit_id}`,
      },
      {
        name: 'Срок подготовки',
        value: `${props.farmerProduct.prepare_days} ${declensionDays(
          props.farmerProduct.prepare_days,
        )}`,
      },
    ]
  }
  return undefined
})
</script>

<template v-if="farmerProduct">
  <div class="toolbar-body">
    <div class="toolbar-body__title">
      <TitleToolbarBlock title="Ваш товар" />
      <Button @click="emit('button-click')" type="link">
        Свернуть
        <template #icon>
          <LeftOutlined />
        </template>
      </Button>
    </div>
    <div class="toolbar-body__image-block">
      <img v-if="farmerProduct.image_url" :src="farmerProduct.image_url ? farmerProduct?.image_url : NoImgProduct" alt="" loading="lazy" />
    </div>
    <div class="toolbar-body__properties">
      <ProductProperty
        v-for="{ name, value } in productProperties"
        :name="name"
        :value="value"
        :key="name"
      />
    </div>
    <div class="toolbar-body__description description">
      <span class="description__title"> Описание </span>
      <span class="description__comment">{{
        props.farmerProduct?.comment || '-'
      }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar-body {
  line-height: 22px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image-block {
    width: 312px;
    aspect-ratio: 4/3;
    border-radius: 10px;
    overflow: hidden;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__properties {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .description {
    &__title {
      color: rgba(0, 0, 0, 0.45);
    }
    &__comment {
      color: rgba(0, 0, 0, 0.88);
    }
  }
}
</style>
