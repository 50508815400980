<script>
import VerificationFullModal from '@/components/ModalSystem/Modals/VerificationFullModal.vue'
import FeedbackForm from '@/components/ModalSystem/Modals/FeedbackForm.vue'
import RedirectToAddProduct from '@/components/ModalSystem/Modals/RedirectToAddProduct.vue'
import SelectAddressModal from '@/components/ModalSystem/Modals/SelectAddressModal.vue'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import RedirectWarningModal from './Modals/RedirectWarningModal.vue'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import HandleErrorModal from './Modals/HandleErrorModal.vue'
import { ref, computed, watch } from 'vue'
import SelectAddressOnMapModal from './Modals/SelectAddressOnMapModal.vue'
import GeneratingDocsModal from "@/components/ModalSystem/Modals/GeneratingDocsModal.vue";
import DownloadDocsModal from "@/components/ModalSystem/Modals/DownloadDocsModal.vue";

export default {
  computed: {
    ModalKeys() {
      return ModalKeys
    },
  },
  setup() {
    const modalComponents = {
      [ModalKeys.VerificationFull]: VerificationFullModal,
      [ModalKeys.FeedbackForm]: FeedbackForm,
      [ModalKeys.SelectAddress]: SelectAddressModal,
      [ModalKeys.RedirectWarning]: RedirectWarningModal,
      [ModalKeys.HandleError]: HandleErrorModal,
      [ModalKeys.RedirectToAddProduct]: RedirectToAddProduct,
      [ModalKeys.SelectAddressOnMap]: SelectAddressOnMapModal,
      [ModalKeys.GeneratingDocsModal]: GeneratingDocsModal,
      [ModalKeys.DownloadDocsModal]: DownloadDocsModal
    }
    const modalStore = useModalStore()

    const currentModalKey = ref(null)

    const modals = computed(() => modalStore.modals)

    const isModalOpen = computed(() => {
      const openModal = Object.values(modalStore.getModals()).find(
        (modal) => modal.isOpen,
      )
      return openModal !== undefined
    })

    const closeModal = () => {
      if (
        modals[currentModalKey]?.options &&
        modals[currentModalKey]?.options?.close
      ) {
        modals[currentModalKey]?.options?.close()
      }
      modalStore.closeModal(currentModalKey.value)
    }
    watch(
      () => modalStore.getModals(),
      (newModals) => {
        const openModal = Object.values(newModals).find((modal) => modal.isOpen)
        if (openModal) {
          currentModalKey.value = openModal.key
        } else {
          currentModalKey.value = null
        }
      },
      { deep: true, immediate: true },
    )

    return {
      currentModalKey,
      modals,
      modalComponents,
      modalStore,
      isModalOpen,
      closeModal,
    }
  },
}
</script>

<template>
  <teleport to="body">
    <component
      :is="modalComponents[currentModalKey]"
      :show="isModalOpen"
      v-bind="modals[currentModalKey]?.options"
      v-if="currentModalKey"
      @close="closeModal"
    />
  </teleport>
</template>
