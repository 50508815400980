<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Button, Table } from 'ant-design-vue'
import ProductCell from '@/views/Reductions/components/ReductionTable/ProductCell.vue'
import CreatedAtCell from '@/views/Reductions/components/ReductionTable/CreatedAtCell.vue'
import StatusCell from '@/views/Reductions/components/ReductionTable/StatusCell.vue'
import UsersCell from '@/views/Reductions/components/ReductionTable/UsersCell.vue'
import BestOfferCell from '@/views/Reductions/components/ReductionTable/BestOfferCell.vue'
import { reductionStore } from '@/store/reductionStore'
import { IReduction, IReductionProductRequest } from '@/types/interfaces'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import YourOfferCell from '@/views/Reductions/components/ReductionTable/YourOfferCell.vue'
import { DownOutlined } from '@ant-design/icons-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import {roundNumber} from "@/helpers/scripts";

interface IProps {
  tableData: IReduction[]
  isMoreFetching: boolean
}

const props = defineProps<IProps>()
const router = useRouter()
const expandedRowKeys = ref<number[]>([])

const columns = [
  {
    title: '',
    key: 'switch',
    width: '2%',
  },
  {
    title: 'Товары',
    key: 'products',
    width: '30%',
  },
  {
    title: 'Покупатель',
    key: 'customer',
    width: '16%',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
    width: '9%',
  },
  {
    title: 'Статус',
    key: 'status',
    width: '9%',
  },
  {
    title: 'Лучшее предложение',
    key: 'bestOffer',
    width: '10%',
  },
  {
    title: 'Ваше предложение',
    key: 'yourOffer',
    width: '10%',
  },
  {
    title: '',
    key: 'action',
    width: '12%',
  },
]

const getCustomRow = (record: IReduction) => {
  return {
    onClick: () => {
      localStorage.setItem('customer', JSON.stringify(record?.customer))
      router.push(`/reductions/${record.id}`)
    },
    style: { cursor: 'pointer' },
  }
}
const increasePage = () => {
  reductionStore().increasePage()
}

const handleExpandKey = (id: number) => {
  if (expandedRowKeys.value.includes(id)) {
    expandedRowKeys.value = expandedRowKeys.value.filter((exId) => exId !== id)
  } else {
    expandedRowKeys.value.push(id)
  }
}

const getProductsList = (
  products: IReductionProductRequest[],
  id: number,
  expanded: number[],
) => {
  if (expanded.includes(id)) return products
  return products.slice(0, 3)
}

const partners = ref([])

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

onMounted(async () => {
  await getMyPartners()
})
</script>

<template>
  <div class="reduction-table">
    <Table
      :columns="columns"
      :data-source="props.tableData"
      :pagination="false"
      :custom-row="getCustomRow"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'switch'">
          <Button
            v-if="record.reduction_product_requests.length > 3"
            type="text"
            @click.stop="handleExpandKey(record.id)"
            class="rotate-btn"
            :class="{ isRotated: expandedRowKeys.includes(record.id) }"
          >
            <template #icon>
              <DownOutlined />
            </template>
          </Button>
        </template>
        <template v-if="column.key === 'products'">
          <ProductCell
            :products="
              getProductsList(
                record.reduction_product_requests,
                record.id,
                expandedRowKeys,
              )
            "
          />
        </template>
        <template v-if="column.key === 'customer'">
          <div class="customer__name">
            <PartnershipTooltip
                v-if="!!partners.find((el: any) => el.company_id === record?.customer?.id)"
              :is-partner="
                !!partners.find((el: any) => el.company_id === record?.customer?.id)
              "
            />
            {{ `${record.customer.legal_form} ${record.customer.name}` }}
          </div>
          <SimpleRate :company_rating="record.customer.company_rating" />
        </template>
        <template v-if="column.key === 'createdAt'">
          <CreatedAtCell :created-at="record.created_at" />
        </template>
        <template v-if="column.key === 'status'">
          <StatusCell
            :expiration-date="record.expiration_date"
            :status="record.status"
          />
        </template>
        <template v-if="column.key === 'users'">
          <UsersCell :participants="record.merged_recipients ?? []" />
        </template>
        <template v-if="column.key === 'bestOffer'">
          <BestOfferCell
            :best-offer="
              record.best_farmer_id
                ? {
                    price: roundNumber(record.best_sum),
                    company_name: null,
                  }
                : null
            "
          />
        </template>
        <template v-if="column.key === 'yourOffer'">
          <YourOfferCell :offer="roundNumber(record.your_sum)" />
        </template>
        <template v-if="column.key === 'action'">
          <template v-if="record.status === 'open'">
            <span
              class="no-products-span"
              v-if="
                record.reduction_product_requests.every(
                  (product) => !product.has_in_catalogue,
                )
              "
              >Нет товаров в вашем каталоге</span
            >
            <template v-else>
              <Button type="primary" v-if="record.status === 'open'">
                Сделать ставку
              </Button>
            </template>
          </template>
        </template>
        <template v-else />
      </template>
    </Table>
    <InfiniteLoading
      :distance="500"
      class="infinite-loading"
      @infinite="increasePage"
    />
    <div v-if="isMoreFetching" class="list-loader">
      <FullscreenLoading :type="'secondary'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.customer {
  &__name {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
  }
}

.reduction-table {
  flex-grow: 1;
  padding-bottom: 89px;
  margin-top: 24px;
  .rotate-btn {
    :deep(.anticon) {
      transform: rotate(0);
      transition: 0.2s ease-in;
    }
    &.isRotated {
      :deep(.anticon) {
        transform: rotate(180deg);
      }
    }
  }
  .no-products-span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
  }
}

.list-loader {
  transform: scale(0.6);
  width: 100%;
  height: 70px;
  position: relative;
}
</style>
