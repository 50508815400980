<script setup lang="ts">
import { Modal, Button, Flex } from 'ant-design-vue'
import { computed } from 'vue'
import chrome from '@/assets/img/browser-instructions/chrome.png'
import firefox from '@/assets/img/browser-instructions/firefox.png'
import edge from '@/assets/img/browser-instructions/edge.png'
import opera from '@/assets/img/browser-instructions/opera.png'
import yandex from '@/assets/img/browser-instructions/yandex.png'
interface IProps {
  open: boolean
}
const props = defineProps<IProps>()
const emit = defineEmits(['update:open'])

const browser = computed(() => {
  const userAgent = navigator.userAgent
  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  } else if (userAgent.includes('Edg')) {
    return 'Edge'
  } else if (userAgent.includes('OPR')) {
    return 'Opera'
  } else if (userAgent.includes('YaBrowser')) {
    return 'Yandex'
  } else if (userAgent.includes('Chrome')) {
    return 'Chrome'
  } else {
    return 'other'
  }
})

const instruction = computed(() => {
  switch (browser.value) {
    case 'Chrome':
      return {
        title: 'В Google Chrome',
        description:
          'В левом верхнем углу браузера, слева от адреса сайта, нажмите на кнопку настроек (1) и разрешите получать уведомления (2)',
        img_src: chrome,
      }
    case 'Firefox':
      return {
        title: 'В Firefox',
        description:
          'В левом верхнем углу браузера, слева от адреса сайта, нажмите на кнопку настроек (1) и разрешите получать уведомления (2)',
        img_src: firefox,
      }
    case 'Edge':
      return {
        title: 'В Edge',
        description:
          'В правом верхнем углу браузера, справа от адреса сайта, нажмите на кнопку настроек (1) и разрешите получать уведомления (2)',
        img_src: edge,
      }
    case 'Opera':
      return {
        title: 'В Opera',
        description:
          'В левом верхнем углу браузера, слева от адреса сайта, нажмите на кнопку настроек (1) и разрешите получать уведомления (2)',
        img_src: opera,
      }
    case 'Yandex':
      return {
        title: 'В Яндекс браузере',
        description:
          'В левом верхнем углу браузера, слева от адреса сайта, нажмите на кнопку настроек (1) и разрешите получать уведомления (2)',
        img_src: yandex,
      }
    case 'other':
    default:
      return {
        title: 'У нас нет готовой инструкции для вашего браузера',
        description:
          'В поисковике введите «Как включить уведомления на сайте в „Название вашего браузера“» и следуйте инструкции',
        img_src: null,
      }
  }
})
</script>

<template>
  <Modal
    width="550px"
    :open="open"
    @cancel="emit('update:open', false)"
    centered
    closable
  >
    <template #title>
      <h4 class="title">Инструкция по включению уведомлений на сайте</h4>
      <span class="secondary">{{ instruction.title }}</span>
    </template>
    <Flex :gap="12" vertical>
      {{ instruction.description }}
      <div v-if="instruction.img_src">
        <img :src="instruction.img_src" alt="browser-instruction" loading="lazy" />
      </div>
    </Flex>
    <template #footer>
      <Button @click="emit('update:open', false)" type="primary"
        >Понятно</Button
      >
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.title {
  //styleName: LG/Strong;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin: 0;
  color: #000000e0;
}
.secondary {
  color: #000000a6;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
</style>
