import { APIModels } from '@/types/enums'
import axiosInstance from '@/axios'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";

class ChatAPIModel {
  createChannel(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .post(
          `${import.meta.env.VITE_BASE_URL.replace('/api', '')}/chatify/api/Channel/create`,
          data,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCustomer(id: number, withs: string[] = []): Promise<any> {
    return RequestManager.request({
      method: EgalMethods.GetItem,
      model: APIModels.Customer,
      data: { id, withs },
    })
  }

  async getHasUnreadChannels(userUid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .get(
          `${import.meta.env.VITE_BASE_URL.replace('/api', '')}/chatify/api/User/getHasUnreadChannels?user_uuid=${userUid}`,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const ChatAPI = new ChatAPIModel()
export { ChatAPI }
