<script setup lang="ts">
import {Button} from "ant-design-vue";
import { outlineGrayButton } from '@/assets/EgalStyles/EButton'
import router from '@/router'
import { useRoute } from 'vue-router'
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

const props = defineProps({
  productName: String,
})

const route = useRoute()

const handleGoBack = () => {
  const recipientId = route.params.id
  router.push(`/response/${recipientId}`)
}
</script>

<template>
  <div class="toolbar-header">
    <Button @click="handleGoBack" :style="outlineGrayButton">
      <ChevronLeftIcon />
    </Button>
    <div class="title">
      {{ productName }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.toolbar-header {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}

.title {
  font-size: 30px;
  font-weight: 600;
}
.chevron-left {
  color: $gray-800;
  stroke: none !important;
}
</style>
