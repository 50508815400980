import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class GeneralOrderAPIModel {
  async getGeneralOrders(
    page = 1,
    filters: any = [],
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.GeneralOrder,
      method: EgalMethods.GetItems,
      data: {
        pagination: { per_page: 10, page },
        filter: filters,
        order: [['created_at', 'desc']],
        withs
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getGeneralOrder(
    filters: any = [],
    withs: string[] = [
      'customer',
      'orders',
      'orders.farmer',
      'orders.farmer.contacts',
      'orders.farmer.farmer_products',
      'orders.order_positions',
      'orders.order_positions.farmer_products',
    ],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.GeneralOrder,
      method: EgalMethods.GetItems,
      responseType: 'items_data',
      data: { filter: filters, order: [['created_at', 'desc']], withs },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getOneGeneralOrder(
    filters: any = [],
    withs: string[] = [],
    id: number,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.GeneralOrder,
      method: EgalMethods.GetItem,
      data: { id, withs, order: [['created_at', 'desc']] },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.GeneralOrder,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const GeneralOrderAPI = new GeneralOrderAPIModel()
export { GeneralOrderAPI }
