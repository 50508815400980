<script lang="ts" setup>
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import gsap from 'gsap'
import router from '@/router'
import emitter from '@/emitter'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { convertPointToMapFormat } from '@/helpers/scripts'
import { coordinatesRegex } from '@/model/constants'
import { ProductCategoryAPI } from '@/api/ProductCategoryAPI'
import { AddressAPI } from '@/api/AddressAPI'
import { ProductAPI } from '@/api/ProductAPI'
import { FarmerAPI } from '@/api/FarmerAPI'
import {
  IAddressesCoord,
  IProductCategory,
  ITopProduct,
} from '@/types/interfaces'
import {
  TFilterParameters,
  THandleRangeFilter,
  TMapPoint,
  TOptionCategory,
} from '@/types/types'
import MapBlock from '@/components/Home/MapBlock/MapBlock.vue'
import BenefitsTabbar from '@/views/HomeUnauthorized/components/BenefitsTabbar/BenefitsTabbar.vue'
import RegisterNow from '@/views/HomeUnauthorized/components/RegisterNow/RegisterNow.vue'
import { useStorage } from '@vueuse/core'
import UnitingBlockInfoSearch from '@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockInfoSearch/UnitingBlockInfoSearch.vue'
import UnitingBlockCheckboxes from '@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockCheckboxes.vue'
import UnitingBlockTopOfYear from '@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockTopOfYear/UnitingBlockTopOfYear.vue'
import UnitingBlockTitle from '@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockTitle.vue'
import {
  gsapScrollAnimation,
  scrollTriggerOnEnter,
  scrollTriggerOnEnterBack,
  scrollTriggerOnLeave,
  scrollTriggerOnLeaveBack,
  setScrollOnFirstSection,
} from '@/components/Home/customerHome.scrypts'
import {TTopProductFilter, TTypeFilter} from './types'
import {ICategoryOptions, ICategoryChildren, IHandleCategoryChildren} from './interfaces'
import {sortByAlphabet, showNotification} from './helpers'

const filters = reactive<any>([])
const isScrolling = ref(false)

const allFarms = ref<IAddressesCoord[]>([])
const isFetching = ref(true)
const currentPage = ref(1)
const flag = ref(false)
const childCount = ref(0)

const farmersCount = ref(0)
const productsCount = ref(0)
const topProducts = ref<ITopProduct[]>([])

const isOpenFilters = ref(false)
let categoryOptions = reactive<ICategoryOptions[]>([])

//Работа фильтров
const selectedCategory = ref<IProductCategory>()
const categoryChildren = ref<ICategoryChildren[]>([])
const filterParameters = ref<TFilterParameters>({
  single: [],
  binary: [],
  multiple: [],
})
const filterRange = ref({
  price: {
    ge: null,
    le: null,
  },
  quantity: {
    ge: null,
    le: null,
  },
})

const currentSection = ref('section-0')

const toggleFilters = () => {
  isOpenFilters.value = !isOpenFilters.value
}

const openFilters = () => {
  isOpenFilters.value = true
}

const closeFilters = () => {
  isOpenFilters.value = false
}

const setFilters = async (filter: any, from?: TTypeFilter) => {
  if (from === 'filters' || from === 'search') {
    const topProductsCopy = [...topProducts.value]
    topProducts.value = topProductsCopy
  }

  flag.value = true
  currentPage.value = 1
  filters.value = filter
  isFetching.value = true
  await getFarmerAddresses(from)
}

const getTopProducts = async () => {
  // Топ продуктов
  try {
    // TODO: Пока хардкод
    // const response = await ProductAPI.getProductCategoryMetric()

    topProducts.value = [
      {
        id: 78,
        name: 'Крупа гречневая',
        sum: '27342564,00',
        isActive: false,
      },
      {
        id: 117,
        name: 'Свинина полутуша',
        sum: '11878000,00',
        isActive: false,
      },
      {
        id: 94,
        name: "Яйцо куриное 1с",
        sum: '4989050,00',
        isActive: false,
      },
      {
        id: 65,
        name: 'Крупа рис пропаренный',
        sum: '2556000,00',
        isActive: false,
      },
      {
        id: 275,
        name: 'Прочее',
        sum: '1278271,02',
        isActive: false,
      }
    ]

    // topProducts.value = response.items.map((el: ITopProduct) => {
    //   return {
    //     ...el,
    //     isActive: 'isActive' in el ? el.isActive : false,
    //   }
    // })
  } catch (e) {
    console.log('getTopProducts e', e)
  }
}

const getFarmersAndProductsCount = async () => {
  // Счетник фермеров и каталожных позиций
  try {
    const response = await FarmerAPI.getFarmersAndProductsCount()

    if (response) {
      farmersCount.value = response.farmers_count
      productsCount.value = response.products_count
    }
  } catch (e) {
    console.log('getFarmersAndProductsCount e', e)
  }
}

const getFarmerAddresses = async (from?: TTypeFilter) => {
  // Аналог getAllFarms для неавторизованных пользователей
  try {
    if (from === 'filters') {
      const response = await ProductAPI.getAddressesByProduct(filters.value)

      allFarms.value = response?.map((address) => ({
        addresses: [
          {
            coordinates: address,
          },
        ],
      }))
    } else {
      const response = await AddressAPI.getFarmerAdresses(
        from === 'search' ? filters.value : topYearsFiltersData.value,
      )

      allFarms.value = response?.addresses?.map((address) => ({
        addresses: [
          {
            coordinates: address,
          },
        ],
      }))
    }
  } catch (e) {
    console.log('getFarmerAddresses e', e)
  }
}

const scrollToThirdSection = async (index: number) => {
  isScrolling.value = true
  scrollToSection(index)
  gsapScrollAnimation(index)
  setTimeout(() => {
    isScrolling.value = false
  }, 500)
}

const createScrollTrigger = async ({ sectionsElements, deadZone }) => {
  sectionsElements.forEach((section, index) => {
    ScrollTrigger.create({
      trigger: section,
      start: `top+=${deadZone} top`,
      end: `bottom-=${deadZone}`,
      onEnter: () =>
        scrollTriggerOnEnter(index + 1, section, sectionsElements, () => {
          if (!isScrolling.value) {
            scrollToSection(index + 1)
          }
        }),
      onLeave: () => scrollTriggerOnLeave(index, section),
      onEnterBack: () =>
        scrollTriggerOnEnterBack(index, section, sectionsElements, () =>
          scrollToSection(index),
        ),
      onLeaveBack: () => scrollTriggerOnLeaveBack(section),
    })
  })
}

const calcUnitingBlockOffsetHeight = async () => {
  const unitingBlock = document.querySelector('.uniting-block')
  if (unitingBlock?.getBoundingClientRect()) {
    useStorage(
      'unitingBlockOffsetHeight',
      unitingBlock?.getBoundingClientRect().height + 'px',
    )
  }
}

const checkHash = async () => {
  const hash = router.currentRoute.value.hash
  if (hash == '#invite') {
    await showNotification()
    await router.replace('/')
  }
}

const initGsapScroll = async () => {
  console.debug('Init GsapScroll...')
  // Регистрация ScrollTrigger
  gsap.registerPlugin(ScrollTrigger)

  // Высота мертвой зоны в пикселях
  const deadZone = 100

  // Настройка ScrollTrigger для каждой секции
  const sectionsElements = document.querySelectorAll('.section')

  await createScrollTrigger({ sectionsElements, deadZone })
  await calcUnitingBlockOffsetHeight()

  await setScrollOnFirstSection()

  // Подписка на событие скролла
  emitter.on('scroll-to-section', (index: number) => {
    scrollToThirdSection(index)
  })

  emitter.on('scroll-to-section-0', async (index: number) => {
    ScrollTrigger.killAll()
    console.debug('Kill Gsap Scroll...')
    currentSection.value = 'section-0'
    console.debug('currentSection is section-0...')
    window.scrollTo({ top: 0, behavior: 'smooth' })
    console.debug('Scroll to top 0...')
    setTimeout(async () => {
      await initGsapScroll()
    }, 1000)
  })
}

const initUnauthorizedMode = async () => {
  await getTopProducts()
  await getFarmersAndProductsCount()
  await getFarmerAddresses('topOfYear')

  await ProductCategoryAPI.getItems([]).then((res) => {
    categoryOptions = sortByAlphabet(
      res.map((item: IProductCategory) => ({
        label: item.name,
        value: item.id,
        entity: item,
      })),
    )
    const extracted = categoryOptions.filter((item) => item.label == 'ПРОЧЕЕ')

    categoryOptions = categoryOptions.filter((el) => !extracted.includes(el))

    categoryOptions.push(...extracted)
  })

  await initGsapScroll()
}

const handleCategory = (value: string | number, option: ICategoryOptions, resetTopProduct?: boolean) => {
  if (!value) {
    selectedCategory.value = {}
    categoryChildren.value = []
    return
  }

  if (resetTopProduct && topProducts.value) {
    topProducts.value?.forEach((el) => {
      el.isActive = false
    })
  }

  selectedCategory.value = { ...option.entity }

  categoryChildren.value = [
    {
      selectedEntity: {
        id: null,
      },
      id: option.entity.id,
      options: option.entity.all_children_product_categories.map((item) => ({
        label: item.name,
        value: item.id,
        entity: item,
      })),
    },
  ]

  const options = categoryChildren.value[0].options
  if (options.length == 1) {
    const childCategory = options[0]
    handleCategoryChildren({
      value: childCategory.value,
      option: childCategory,
      index: 0,
    })
  }
  childCount.value = options.length
}

const handleCategoryChildren = ({
  value,
  option,
  index,
}: IHandleCategoryChildren) => {
  if (!value) {
    categoryChildren.value[index].selectedEntity = {
      id: null,
    }
    categoryChildren.value.splice(index + 1)
    return
  }
  categoryChildren.value[index].selectedEntity = option.entity

  if (option.entity.all_children_product_categories?.length) {
    categoryChildren.value[index + 1] = {
      selectedEntity: {
        id: null,
      },
      id: option.entity.id,
      options: sortByAlphabet(
        option.entity.all_children_product_categories.map(
          (item: IProductCategory) => ({
            label: item.name,
            value: item.id,
            entity: item,
          }),
        ),
      ),
    }
  } else {
    categoryChildren.value.splice(index + 1)
  }
}

const setFilterParameters = (value: TFilterParameters) => {
  filterParameters.value = value
}

const dropFilters = async () => {
  filterParameters.value = {
    single: [],
    binary: [],
    multiple: [],
  }
  filterRange.value = {
    price: {
      ge: null,
      le: null,
    },
    quantity: {
      ge: null,
      le: null,
    },
  }
  categoryChildren.value = []
  selectedCategory.value = {}
  const topProductsCopy = [...topProducts.value]
  topProductsCopy.forEach((el) => {
    el.isActive = false
  })
  topProducts.value = topProductsCopy
  topYearsFiltersData.value = []
  await setFilters(null)
}

const handleRangeFilter = ({ value, type, direction }: THandleRangeFilter) => {
  filterRange.value[type][direction] = value
}

const makeProductFilter = () => {
  const secondFilterPart: string[] = []
  Object.values(filterParameters.value)
    .flat()
    .forEach((parameter) => {
      if (parameter.disabled) return
      switch (parameter.type) {
        case 'binary':
          parameter.value && secondFilterPart.push(parameter.name)
          break
        case 'single':
          parameter.value && secondFilterPart.push(parameter.value)
          break
        case 'multiple':
          parameter.value?.length &&
            secondFilterPart.push(parameter.value.join('|'))
          break
        default:
          break
      }
    })

  const firstFilterPart = [
    selectedCategory.value?.name,
    ...categoryChildren.value.map((item) => item.selectedEntity.name),
  ].join(' ')

  return {
    category: firstFilterPart,
    raw_parameters: secondFilterPart.join('|'),
  }
}

const makeRangeFilter = () => {
  const addFilter = (filter: any) => {
    if (filter) return filter;
    else return null;
  }

  return {
    count: [
      addFilter(filterRange.value?.quantity?.ge),
      addFilter(filterRange.value?.quantity?.le),
    ],
    price: [
      addFilter(filterRange.value?.price?.ge),
      addFilter(filterRange.value?.price?.le),
    ],
  }
}

const applyFilters = async (from?: TTypeFilter) => {
  if (!selectedCategory.value) {
    isFetching.value = true
    toggleFilters()
    flag.value = true
    currentPage.value = 1
    await getFarmerAddresses(from)
    return
  }
  const productPart = makeProductFilter()
  const rangePart = makeRangeFilter()
  const finalFilter = Object.assign(productPart, rangePart)
  await setFilters(finalFilter, from)
}

const topYearsFiltersData = ref<TTopProductFilter[]>([])

const topYearFilters = async (filter: TTopProductFilter) => {
  let filtersCopy = topYearsFiltersData.value
  const filterId = filter[filter.length - 1]

  // Деактивируем все фильтры
  topProducts.value.forEach((el: any) => {
    el.isActive = false
  })

  // Если продукт найден, активируем/деактивируем его
  const productId = topProducts.value.find((el) => el.id === filterId)?.id

  if (productId) {
    const product = topProducts.value.find((el) => el.id === productId)
    product.isActive = true
  }

  // Если фильтр уже существует, удаляем его, иначе добавляем
  const existingFilterIndex = filtersCopy?.findIndex(
    (el: any) => el[2] === filterId,
  )

  if (existingFilterIndex !== -1) {
    filtersCopy?.splice(existingFilterIndex, 1)
    const product = topProducts.value.find((el) => el.id === filterId)
    product.isActive = false
  } else {
    filtersCopy = [filter]
  }

  topYearsFiltersData.value = filtersCopy

  try {
    const response = await ProductAPI.getFinalCategoryBranch({ id: productId })
    if (response) await presetFilters(response)
  } catch (e) {
    console.error(e)
  }

  if (existingFilterIndex !== -1) {
    closeFilters()
    await dropFilters()
  } else openFilters()

  await setFilters(filtersCopy, 'topOfYear')
}

const presetFilters = async (filter: IProductCategory, depth = 0) => {
  let childrenCategory
  if (depth >= 1) {
    childrenCategory = filter.all_children_product_categories.find(
      (item) => item.id === filter.all_children_product_categories[0].id,
    )
  } else {
    const category = categoryOptions.find(
      (item) => item.entity.id === filter.id,
    )

    childrenCategory = category.entity.all_children_product_categories.find(
      (item) => item.id === filter.all_children_product_categories[0].id,
    )

    handleCategory(category.value, category)
  }

  if (filter.all_children_product_categories.length) {
    handleCategoryChildren({
      value: childrenCategory.id,
      option: { entity: childrenCategory },
      index: depth,
    })

    await presetFilters(filter.all_children_product_categories[0], depth + 1)
  }
}

const pointsToDraw = computed<TMapPoint[]>(() => {
  return convertPointToMapFormat(
    allFarms.value?.filter(
      (farm) =>
        farm?.addresses[0]?.coordinates &&
        coordinatesRegex.test(farm?.addresses[0]?.coordinates),
    ),
  )
})

const scrollToSection = (index: number) => {
  const scrollContainer = window.document.getElementById('scrollContainer')
  if (scrollContainer) {
    const sections = scrollContainer.querySelectorAll('.section')
    // Проверяем, что индекс находится в пределах допустимого диапазона
    if (index >= 0 && index < sections.length) {
      const targetSection = sections[index] as HTMLElement

      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
        console.error('Target section not found for index:', index)
      }
    } else {
      console.warn('Index out of bounds:', index)
    }
  } else {
    console.error('Scroll container is not defined')
  }
}

onMounted(async () => {
  await checkHash()
  await initUnauthorizedMode()
})

onUnmounted(() => {
  ScrollTrigger.killAll()
})
</script>

<template>
  <div class="container">
    <div class="home-unauthorized-view" ref="scrollContainer" id="scrollContainer">
      <div class="uniting-block">
        <div class="sections-wrapper">
          <section
            class="section section-0"
            :class="{ active: currentSection === 'section-0' }"
          >
            <UnitingBlockTitle />
            <div class="uniting-block__checkboxes-wrapper">
              <UnitingBlockCheckboxes />
            </div>
            <div class="uniting-block__info-search-wrapper">
              <UnitingBlockInfoSearch
                :farmersCount="farmersCount"
                :productsCount="productsCount"
                @toggleFilters="toggleFilters"
              />
            </div>
          </section>
          <section
            class="section section-1"
            :class="{ active: currentSection === 'section-1' }"
          >
            <div class="uniting-block__top-of-year">
              <UnitingBlockTopOfYear
                :topProducts="topProducts"
                @topYearFilter="topYearFilters"
              />
            </div>
          </section>
        </div>
        <MapBlock
          id="mapBlock"
          marginTop="73px"
          borderRadius="12px"
          :isInteractive="false"
          :isOpenFilters="isOpenFilters"
          :categoryOptions="categoryOptions"
          :selected-category="selectedCategory"
          :categoryChildrens="categoryChildren"
          :childCount="childCount"
          :filterParameters="filterParameters"
          :filterRange="filterRange"
          :farms-points="pointsToDraw"
          @toggleFilters="toggleFilters"
          @closeFilters="closeFilters"
          @set-category="handleCategory"
          @setCategoryChildren="handleCategoryChildren"
          @setFilterParameters="setFilterParameters"
          @dropFilters="dropFilters"
          @handleRangeFilter="handleRangeFilter"
          @applyFilters="applyFilters"
          @setFilters="setFilters"
        />
      </div>
      <div class="gradient__wrapper">
        <section
          class="section section-2"
          :class="{ active: currentSection === 'section-2' }"
        >
          <BenefitsTabbar />
        </section>
      </div>
      <section
        id="#about-block"
        class="section section-3"
        :class="{ active: currentSection === 'section-3' }"
      >
        <RegisterNow />
      </section>
    </div>
  </div>
</template>

<style>
html {
  scrollbar-width: none;
}
</style>

<style lang="scss" scoped>
@use '@/assets/style/variables';
@import '@/assets/style/mixins';

.sections-wrapper {
  padding-right: 40px;
}

.gradient__wrapper {
  background: url('@/assets/img/gradient-bg-b-tabbar.svg') no-repeat fixed
    bottom;
}

.uniting-block__info-search-wrapper {
  width: 100%;
}

.section {
  height: 100vh; /* Каждая секция занимает всю высоту экрана */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out; /* Плавный переход для фона */
  position: relative;
  z-index: 999;
  width: 97%;
}

.section.active {
  opacity: 1;
}

.map-block {
  right: -300px;
  position: sticky; /* Позиционируем карту как "прилипшую" */
  top: 0; /* Позиция сверху */
  z-index: 10; /* Убедитесь, что z-index выше, чем у секций */
}

.section-0 {
  display: flex;
  flex-direction: column;
  gap: 6%;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}

.section-1 {
  height: 100vh;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 100px;
}

.section-2 {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.section-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 20px;
  height: 100%;
  min-height: 100%;
}

.uniting-block {
  display: flex;
  position: relative;
  max-height: fit-content;
}

.home-unauthorized-view {
  height: 100%; /* Заполняем весь layout */
  &__uniting-block {
    display: flex;
    max-width: 100%;
    width: 100%;
    padding-left: 24px;
  }
  &__map-wrapper {
    display: flex;
    min-height: 1525px;
    width: 100%;
  }
  &__benefits-tabbar-wrapper {
    display: flex;
  }
}

.blur {
  filter: blur(5px);
  @include no-select();
}

.wrapper {
  margin: 0 -24px 0 -24px !important;
  width: 100vw !important;
  background-color: #ffffff;
}

.container-wrapper {
  height: 100%;
  width: 100vw;
}

.egal-button {
  padding: 8px;
}

.container-not-auth {
  display: flex;
  min-height: 100%;
  width: 100vw;
  padding-left: 20px;
  height: calc(100vh - 73px);
  flex-direction: row;
  &--empty {
    @include empty();
  }
}

.container {
  display: flex;
  min-height: 100%;
  width: 100vw;
  padding-left: 20px;
  &--empty {
    @include empty();
  }
}
.farms_loader {
  display: flex;
  justify-content: center;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map-list {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid $gray-300;
  width: 100%;
  max-width: 448px;
  min-width: 448px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &_small {
    max-width: 360px;
    min-width: 360px;
  }

  &__no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d3748;
    width: 100%;
    align-self: center;
  }
}

.search__icon {
  margin-bottom: 0;
  margin-right: 8px;
}

.v-enter-active,
.v-leave-active {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.v-enter-from,
.v-leave-to {
  transform: scaleY(0.5);
  opacity: 0;

  transform-origin: center top;
}

.button-enter-active,
.button-leave-active {
  transition: opacity 0.1s ease;
}

.button-enter-from,
.button-leave-to {
  opacity: 0;
}

.with-postfix {
  :deep(.input-container) {
    input {
      padding-right: 12px;
    }

    .postfix {
      right: 12px;
      top: 14px;
    }
  }
}

.result-card {
  border-bottom: 1px solid variables.$gray-200;

  &:last-child {
    border-bottom: none;
  }
}

.right-side {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
