import { APIModels, Microservices } from '@/types/enums'
import { useCookies } from 'vue3-cookies'
import { GetItemsResponse, IUser, IUserAuth } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";

const { cookies } = useCookies()

class UserAPIModel {
  // Регистрация пользователя по инвайту
  async inviteRegister(data: any): Promise<any> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.inviteRegister,
      headers: { Authorization: cookies.get('auth')},
      data,
    })
  }

  async dropPassword(data: any): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.dropPassword,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: data },
    })
  }

  async confirmDropPassword(data: any): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.confirmDropPassword,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: data },
    })
  }

  async confirmRegistration(data: any): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.confirmRegistration,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: data },
    })
  }

  async resendEmail(data: { email: string }): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.resendEmail,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: data },
    })
  }

  async resendConfirmEmail(data: any): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.resendEmail,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: data },
    })
  }

  async getUser(id: string, withs: string[] = []): Promise<any> {
    return RequestManager.request({
      model: APIModels.User,
      method: EgalMethods.GetItem,
      data: { id, withs },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItemsCore(
    page = 1,
    perPage = 10,
    filters: (string | object)[] = [],
    withs: string[] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return RequestManager.request({
      model: APIModels.User,
      method: EgalMethods.GetItems,
      data: { withs, pagination: { per_page: perPage, page }, filter: filters, order: [['created_at', 'desc']]},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItemAuth(id: string, withs: string[] = []): Promise<IUserAuth> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.GetItem,
      data: { id, withs },
      headers: { Authorization: cookies.get('auth')},
    })
  }

  async getUserList(
    withs: string[] = [],
    pagination = { perPage: 10, page: 1 },
    filters: (string | string[])[][] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.GetItems,
      data: { withs, pagination: { per_page: pagination.perPage, page: pagination.page }, filter: filters, order: [['created_at', 'desc']],},
      headers: { Authorization: cookies.get('auth')},
    })
  }

  async getUserData(
    id: string | number,
    apiModal: string,
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: apiModal,
      method: EgalMethods.GetItem,
      data: { withs, id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateUserDataRegister(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.User,
      method: EgalMethods.update,
      data,
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateUserData(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.User,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateRoleUserData(data: any, apiModal: string): Promise<any> {
    return RequestManager.request({
      model: apiModal,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateEmail(data: any): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      model: APIModels.User,
      method: EgalMethods.changeEmail,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updatePassword(data: any): Promise<any> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.changePassword,
      data: { attributes: data },
      headers: { Authorization: cookies.get('auth')},
    })
  }

  async getCaptcha(): Promise<any> {
    return RequestManager.request({
      isAuthRequest: false,
      service: Microservices.Auth,
      model: APIModels.User,
      method: EgalMethods.getCaptcha,
      data: {},
      headers: { Authorization: cookies.get('auth')},
    })
  }
}

const UserAPI = new UserAPIModel()
export { UserAPI }
