<script lang="ts" setup>
import router from '@/router'
import { h, onMounted, reactive, ref, watch, computed } from 'vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import InfiniteLoading from 'v3-infinite-loading'
import CardTable from '@/components/CardTable/CardTable.vue'
import { OptionType } from '@/types/types'
import { sortProductsByType } from '@/helpers/scripts'
import TagFilters from '@/components/CardTable/TagFilters.vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { Button, Modal, notification } from 'ant-design-vue'
import EmptyListView from '@/views/EmptyListView.vue'
import { IFarmerProduct } from '@/types/interfaces'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import OneCModalIntegration from '@/views/1C/components/1CModalIntegration/1CModalIntegration.vue'
import { OneCAPI } from '@/api/OneCAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {useCookies} from "vue3-cookies";
import { useUserAuthStore } from '@/store/useUserAuthStore'
import {useRoute} from "vue-router";
import {Roles} from "@/types/enums";
import emitter from "@/emitter";

const { getRoles } = useJWTDecode()

type IfilterItem = { name: string; value: string }
type TStatus = 'started' | 'finished' | 'integrated' | null

const addProduct = async () => {
  await router.push('/add-product')
}

const { getCoreCookie } = useJWTDecode()
const isFarmer = computed(() => getCoreCookie()?.company_type === Roles.Farmer)
const tableItems = ref<IFarmerProduct[]>([])
const isFetching = ref(true)
const currentPage = ref(1)
const initialProducts = ref<IFarmerProduct[]>([])
const status = ref<TStatus>(null)
const filterItems = ref<IfilterItem[]>([{ name: 'Все товары', value: 'all' }])
const activeFilter = reactive({ name: 'Все товары', value: 'all' })
let filterObject = reactive<string[][]>([])
const isOpenModal1CIntegration = ref(false)
const isDeleteProduct = ref(false)
const isDeleting = ref(false)

const getAllProducts = async (
  filters: string[][] = [],
  isFirstRender = false,
) => {
  await FarmerProductAPI.getProductsWithoutWrapper(
    filters,
    currentPage.value,
    10,
    ['product', 'specialPrices.customer', 'priceOptions'],
  ).then((res) => {
    isFirstRender && initialProducts.value.push(...res.items)
    if (currentPage.value === 1) {
      tableItems.value = res.items
    } else {
      tableItems.value.push(...res.items)
    }
    isFirstRender && setTabs(sortProductsByType(res.items))
    isFetching.value = false
  })
}

const setTabs = (products: any[]) => {
  filterItems.value = []
  products.forEach((product: any) => {
    product.items.length &&
      filterItems.value.push({
        name: product.category,
        value: product.category,
      })
  })
  filterItems.value?.sort((a, b) => (a.name === 'ПРОЧЕЕ' ? 1 : -1))
  filterItems.value?.unshift({ name: 'Все товары', value: 'all' })
}

const loadNextPage = async () => {
  currentPage.value += 1
  await getAllProducts(filterObject)
}

emitter.on('refresh-token', async (e) => {
  await initFarmerHome()
})

onMounted(async () => {
  await initFarmerHome()
})

const showErrorNotification = () => {
  notification.error({
    message: 'Извините, сервис 1С не отвечает. Пожалуйста, попробуйте позже.',
  })
}

const initFarmerHome = async () => {
  if (getRoles()?.[0] !== Roles.Farmer) await router.push('/')
  else {
    currentPage.value = 1
    await getAllProducts([], true)
    const userStr = localStorage.getItem('user')
    const user = userStr ? JSON.parse(userStr) : null
    const companyId = user?.company_id
    if (companyId) {
      OneCAPI.checkStatus(companyId)
        .then((res) => {
          status.value = res
        })
        .catch((e) => {
          status.value = null
        })
    }
  }
}

const setFilter = async (filter: OptionType) => {
  isFetching.value = true
  currentPage.value = 1
  Object.assign(activeFilter, filter)
  filterObject = []
  if (filter.value !== 'all') {
    filterObject.push(['', 'product_category', filter.name])
  }
  await getAllProducts(filterObject)
}

const deleteProductConfig = {
  title: 'Удалить товар?',
  okText: 'Удалить',
  cancelText: 'Закрыть',
  width: 440,
  body: '',
}

const deleteProduct = (selectedProduct: IFarmerProduct, cb?: any) => {
  isDeleteProduct.value = true
  deleteProductConfig.body = selectedProduct.product.fullName
  deleteProductConfig.ok = async () => {
    isDeleting.value = true
    await FarmerProductAPI.delete(selectedProduct.id).then(() => {
      cb && cb()
      isDeleting.value = false
      notification.info({
        message: 'Товар удалён',
        top: '80px',
        icon: h(InfoCircleFilled, {
          style: { color: 'rgba(22, 119, 255, 1)' },
        }),
      })
      const index = initialProducts.value.findIndex(
        (item: IFarmerProduct) => item.id === selectedProduct.id,
      )
      initialProducts.value.splice(index, 1)

      const deletedProductIndex = tableItems.value.findIndex(
        (item: IFarmerProduct) => item.id === selectedProduct.id,
      )
      tableItems.value.splice(deletedProductIndex, 1)
      setTabs(sortProductsByType(initialProducts.value))
      isDeleteProduct.value = false
    })
  }
}

const isEmptyList = ref(true)

watch(
  tableItems.value,
  async (newValue) => {
    isFetching.value = true
    if (!newValue.length) {
      setTimeout(() => {
        Object.assign(activeFilter, { name: 'Все товары', value: 'all' })
        tableItems.value = initialProducts.value
      }, 500)
      isFetching.value = false
    } else {
      isEmptyList.value = true
    }
  },
  { deep: true },
)

const setIsOpenModal1CIntegration = async () => {
  if (status?.value === null) showErrorNotification()
  if (['finished'].includes(status?.value)) {
    await router.push('/1c-table')
  } else if (status?.value !== null && !['finished'].includes(status?.value)) {
    isOpenModal1CIntegration.value = true
  }
}

const setISCloseModal1CIntegration = () => {
  isOpenModal1CIntegration.value = false
}

const route = useRoute()
</script>

<template>
  <div class="layout">
    <div class="wrapper products">
      <div class="products__header header">
        <div class="products__title">Мои товары</div>
        <div class="header__buttons">
          <Button @click="addProduct" :size="'large'" type="primary">
            Добавить товар
          </Button>
          <Button
            @click="setIsOpenModal1CIntegration"
            :size="'large'"
            type="default"
          >
            Интеграция с 1С
          </Button>
        </div>
      </div>
      <TagFilters
        v-if="!!tableItems.length"
        :active-filter="activeFilter"
        :filter-items="filterItems"
        @set-filter="setFilter"
      />
      <CardTable
        v-if="!!tableItems.length"
        :is-farmer="true"
        :items="tableItems"
        @deleteProduct="deleteProduct"
      />
      <InfiniteLoading
        v-if="!!tableItems.length"
        :distance="500"
        class="infinite-loading"
        @infinite="loadNextPage"
      />
    </div>
    <div v-if="isFetching" class="loader">
      <StarLoader class="loader" />
    </div>

    <EmptyListView
      v-if="!isFetching && !tableItems.length && isEmptyList"
      :title="'Список ваших товаров пуст'"
      :subtitle="'Добавьте товары, чтобы покупатели смогли оформить заказ'"
    >
      <template #action>
        <Button @click="addProduct" :size="'large'" type="primary">
          Добавить товар
        </Button>
      </template>
    </EmptyListView>
  </div>
  <Modal
    :title="deleteProductConfig.title"
    v-model:open="isDeleteProduct"
    @ok="deleteProductConfig.ok"
    :okText="deleteProductConfig.okText"
    :cancelText="deleteProductConfig.cancelText"
    :confirm-loading="isDeleting"
    centered
    :width="deleteProductConfig.width"
  >
    <p>{{ deleteProductConfig.body }}</p>
  </Modal>
  <OneCModalIntegration
    title="Интеграция с 1С"
    v-model:open="isOpenModal1CIntegration"
    @close1CModal="setISCloseModal1CIntegration"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.header {
  &__buttons {
    display: flex;
    gap: 10px;
  }
}

.layout {
  background: white;
  min-height: 66vh;
  padding: 20px 50px 0 50px;
  width: 100%;
}

.edit-wrapper {
  @include wrapper();
  padding-bottom: 140px;

  &--additional {
    @include wrapper();
  }

  &__header {
    justify-content: flex-start !important;
  }
}

.products {
  background: white;
  @include wrapper();
  position: relative;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .egal-button--md {
      font-weight: 500;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    color: #000000e0;
  }
}

.create {
  width: 464px;

  &__row {
    display: grid;
    grid-template-columns: 160px 280px;
    gap: 24px;
    margin-bottom: 32px;
  }

  &__label {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__field {
    width: 280px;
  }
}

.empty {
  @include empty();
}

.loader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
