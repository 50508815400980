<script setup lang="ts">
import { onMounted } from 'vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { storeToRefs } from 'pinia'
import { auctionStore } from '@/store/auctionStore'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import AuctionHeader from '@/views/Auctions/components/AuctionHeader/AuctionHeader.vue'
import FarmerAuctionTable from '@/views/Auctions/components/AuctionTable/FarmerAuctionTable.vue'
import router from '@/router'
import { getUserIdTemporary } from '@/helpers/scripts'
import emitter from "@/emitter";

const { auctions, isFetching, isMoreFetching } = storeToRefs(auctionStore())

const initAuctions = async () => {
  auctionStore().setDefaultFilters([['farmer_id', 'eq', getUserIdTemporary()]])
  await auctionStore().getAuctions()
}

emitter.on('refresh-token', async (e) => {
  await initAuctions()
})

onMounted(async () => {
  await initAuctions()
})

</script>

<template>
  <div class="farmer-auctions-view">
    <FullscreenLoading
      :type="'secondary'"
      v-if="isFetching"
      class="initial-loader"
    />
    <template v-else>
      <AuctionHeader
        v-if="!!auctions.length"
        title="Мои торги на продажу"
        button-text="Создать торг на продажу"
        @onBtnClick="router.push('/create-auction')"
        :is-list="true"
        :buttonType="'primary'"
      />
      <!--    <AuctionFilter-->
      <!--      class="auctions-view__filter"-->
      <!--      :is-disable-reset-filters="true"-->
      <!--      :clear-filters="() => {}"-->
      <!--    />-->
      <FarmerAuctionTable
        :table-data="auctions"
        :isMoreFetching="isMoreFetching"
        v-if="auctions.length"
      />
      <EmptyView
        v-if="!auctions.length"
        title="Список торгов на продажу пуст"
        button-text="Создать торг на продажу"
        description="Здесь вы можете отслеживать свои торги на продажу"
        :is-show-button="true"
        :button-type="'primary'"
        @onBtnClick="router.push('/create-auction')"
      />

      <!--    <NotFoundView-->
      <!--        title="По вашему запросу ничего не найдено"-->
      <!--        button-text="Сбросить фильтры"-->
      <!--        description="Попробуйте применить другие фильтры"-->
      <!--        :button-func="() => {}"-->
      <!--    />-->
    </template>
  </div>
</template>

<style scoped lang="scss">
.farmer-auctions-view {
  display: flex;
  flex-direction: column;
  position: relative;
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
