<script lang="ts" setup>
import { Button } from 'ant-design-vue'
import router from '@/router'
import { Roles } from '@/types/enums'
import {useCookies} from "vue3-cookies";
import {useJWTDecode} from "@/composables/useJWTDecode";
import EmptyFarmCard from '../../assets/img/empty-farm-card.svg'

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const goToFarmersPage = () => {
  router.push('/')
}
</script>

<template>
  <div class="empty-block__wrapper">
    <img :src="EmptyFarmCard" alt="" loading="lazy" />
    <div class="empty-block__text">
      <span class="empty-block__title">У вас ещё нет партнёров</span>
      <span class="empty-block__subtitle"
        >Здесь будут отображаться пользователи, с которыми был заключён договор
        в рамках нашей платформы.</span
      >
    </div>
    <div class="empty-block__btn" v-if="getCoreCookie()?.company_type === Roles.Customer">
      <Button @click="goToFarmersPage" type="primary" size="large">
        К поставщикам</Button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-block {
  &__wrapper {
    height: 100%;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    max-width: 475px;
    gap: 24px;
    margin: 0 auto;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.88);
  }

  &__subtitle {
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}
</style>
