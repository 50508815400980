<script setup lang="ts">
import FarmerRequestsTable from '@/components/Farmer/RequestList/FarmerRequestsTable.vue'
import { onMounted, ref, watch } from 'vue'
import { IPriceRequest } from '@/types/interfaces'
import { PriceRequestAPI } from '@/api/PriceRequestAPI'
import { RecipientStatus } from '@/types/types'
import emitter from "@/emitter";

const filters = ref<any>({})
const sort = ref(false)

const dataForTable = ref<IPriceRequest[]>([])
const page = ref(1)
const isDataFetching = ref(false)
const totalCount = ref(0)
const expandedRowKeys = ref<number[]>([])

const increasePage = () => {
  // if (page.value * 10 < totalCount.value) {
  page.value += 1
  // }
}

const getFilters = () => {
  const filtersArray: any[] = []
  for (const filter in filters.value) {
    if (
      Object.prototype.hasOwnProperty.call(filters.value, filter) &&
      filters.value[filter].length !== 0
    ) {
      const value = filters.value[filter]
      filtersArray.push(value)
    }
  }

  if (filters.value?.status && filters.value?.status?.length !== 0) {
    if (filters.value?.status[0] !== 'status') {
      filtersArray.push(['status', 'ne', 'canceled'])
    }
    if (filters.value.status[0] !== 'expiration_date') {
      filtersArray.push(['expiration_date', 'ge', new Date()])
    }
  }

  return filtersArray
}

const setFilter = ({
  filterName,
  filter,
}: {
  filterName: string
  filter: any[]
}) => {
  page.value = 1
  filters.value[filterName] = filter
}

const resetFilters = () => {
  for (const filter in filters.value) {
    if (
      Object.prototype.hasOwnProperty.call(filters.value, filter) &&
      filters.value[filter].length !== 0
    ) {
      filters.value[filter] = []
    }
  }
}

const getPriceRequests = async () => {
  isDataFetching.value = true
  const userDataString = localStorage.getItem('userData')
  if (!userDataString) return
  const userData = JSON.parse(userDataString)
  await PriceRequestAPI.getItems(
    page.value,
    [['recipients.farmer_id', 'eq', userData.id], ...getFilters()],
    ['recipients', 'product_requests', 'customer', 'customer.company_rating'],
    10,
    sort.value ? [['created_at', 'asc']] : [['created_at', 'desc']],
  )
    .then(
      ({
        items,
        total_count,
      }: {
        items: IPriceRequest[]
        total_count: number
      }) => {
        totalCount.value = total_count
        if (page.value === 1) {
          dataForTable.value = items
        } else {
          dataForTable.value = dataForTable.value
            ? [...dataForTable.value, ...items]
            : items
        }
      },
    )
    .finally(() => {
      sortData()
      isDataFetching.value = false
    })
}

// Сортирока запросов по дате создания
const sortData = () => {
  dataForTable.value?.sort(function (a, b) {
    return new Date(b.created_at) - new Date(a.created_at)
  })
}

const changeSort = () => {
  page.value = 1
  sort.value = !sort.value
}

const changeRecipientStatus = (
  requestId: number,
  recipientId: number,
  status: string,
) => {
  dataForTable.value = dataForTable.value.map((item) => {
    if (item.id === requestId) {
      const newItem = item
      newItem.recipients = newItem.recipients?.map((recipient) => {
        if (recipient.id === recipientId) {
          return { ...recipient, answer_status: status as RecipientStatus }
        }
        return recipient
      })
      return newItem
    } else {
      return item
    }
  })
}

watch([page, filters.value, sort], async () => {
  await getPriceRequests()
})

emitter.on('refresh-token', async (e) => {
  await getPriceRequests()
})

onMounted(async () => {
  await getPriceRequests()
})
</script>

<template>
  <!--  <FarmerRequestsFilters-->
  <!--    @set-filter="setFilter"-->
  <!--    @reset-filters="resetFilters"-->
  <!--  />-->
  <FarmerRequestsTable
    :data="dataForTable"
    :isDataFetching="isDataFetching"
    :page="page"
    @increaseTablePage="increasePage"
    @change-sort-date="changeSort"
    @changeRecipientStatus="changeRecipientStatus"
  />
</template>
