<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {AccountTabs, APIModels, ProfileStatus, Roles} from '@/types/enums'
import {useJWTDecode} from '@/composables/useJWTDecode'
import AccountDetails from '@/components/AccountForms/AccountDetails.vue'
// import SignerData from '@/components/AccountForms/SignerData.vue'
import {UserAPI} from '@/api/UserAPI'
import {fontSize16} from '@/assets/EgalStyles/EButton'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import {ContactAPI} from '@/api/ContactAPI'
import {ContactData, Document, UserContactType} from '@/types/types'
import {DocumentAPI} from '@/api/DocumentAPI'
import {IAccountUser} from '@/types/interfaces'
import {DEFAULT_USER} from '@/model/constants'
import TabListItem from '@/components/Account/TabListItem.vue'
import JurForm from '@/components/AccountForms/JurForm.vue'
import AgentContract from '@/components/AccountForms/AgentContract.vue'
import StatusBlock from '@/components/Account/StatusBlock.vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import {useStore} from '@/store'
import AddressContactForm from '@/components/AccountForms/AddressContactForm.vue'
import {AddressAPI} from '@/api/AddressAPI'
import {IClientAddress} from '@/types/IAddress'
import {Button} from 'ant-design-vue'
import ConfirmDataView from '@/views/ConfirmDataView.vue'
import router from '@/router'
import {getUserIdTemporary} from '@/helpers/scripts'
import MyCompanyForm from '@/components/AccountForms/MyCompanyForm.vue'
import NotificationReminder from '@/components/Account/NotificationReminder.vue'
import EmployeeList from '@/components/AccountForms/EmployeeTab/EmployeeBlock.vue'
import {useCookies} from 'vue3-cookies'
import {storeToRefs} from 'pinia'
import {useRoute} from "vue-router";
import { useUserAuthStore } from '@/store/useUserAuthStore'
import emitter from "@/emitter";

interface IAddress {
  type: string
  user_id: string
  name: string
  contact_number: string
  comment: string
  street: string
  coordinates: string
  id?: number
}

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

const userStatus = ref<ProfileStatus>()
const userRoles = ref<string[]>([getCoreCookie()?.company_type])
const userData = ref()
const totalUserData = ref()
const userAuthInfo = ref()
const userContacts = ref<UserContactType[]>([])
const userDocuments = ref<Document[]>([])
const isFetching = ref(true)
const userStore = useUserAuthStore()

const verificationStatus = ref<'none' | 'full' | 'part'>('none')
const mainStore = useStore()

const isLoader = ref(false)
const modalOpen = ref(false)

const activeDocuments = ref<Document[]>([])

const checkIsDisableTab = (tabName: string) => {
  if (
    !!userStatus.value &&
    [
      ProfileStatus.Verifying,
      ProfileStatus.Unverified,
      ProfileStatus.NeedToVerify,
    ].includes(userStatus.value)
  ) {
    return accountTabsData.value.find((tab) => tab.tabName === tabName)
      ?.isDisabled
  } else if (userStatus.value === ProfileStatus.Verified) {
    return false
  }
}

const accountTabsData = ref([
  {
    img: 'person.svg',
    tabName: AccountTabs.AccountDetails,
    isDisabled: false,
  },
  {
    img: 'my-company.svg',
    tabName: AccountTabs.MyCompany,
    isDisabled: true,
  },
  {
    img: 'location_on.svg',
    tabName: AccountTabs.AddressList,
    isDisabled: true,
  },
  {
    img: 'employees.svg',
    tabName: AccountTabs.EmployeeList,
    isDisabled: true,
  },
  // {
  //   img: 'contacts.svg',
  //   tabName: AccountTabs.ContactPersons,
  //   isDisabled: true,
  // },
  {
    img: 'edit_document.svg',
    tabName: AccountTabs.AgreementForContractors,
    isDisabled: false,
  },
  // {
  //   img: 'location_away.svg',
  //   tabName: AccountTabs.SignerData,
  //   isDisabled: true,
  // },
  {
    img: 'info.svg',
    tabName: AccountTabs.LegalInformation,
    isDisabled: false,
  },
])

const getDocuments = async () => {
  const docs = await DocumentAPI.getCustomDocuments2([['company_id', 'eq', getUserIdTemporary()]], 10, [])
  userDocuments.value = docs.items;
  activeDocuments.value = docs.items?.filter((document) => (document.type === 'tin' || (getCoreCookie()?.company_type === Roles.Farmer && document.type === 'PA')))
}

const userAddresses = ref()

const sendAddress = async (addresses: IAddress[] | IClientAddress[]) => {
  isLoader.value = true
  if (addresses[0].id !== -1) {
    await AddressAPI.updateAddress(addresses[0], false)
      .then(() => fetchAddresses())
      .then(() => (isLoader.value = false))
  } else {
    await AddressAPI.createMany(addresses)
      .then(() => fetchAddresses())
      .then(() => (isLoader.value = false))
  }
}
const deleteAddress = async (address: IAddress) => {
  isLoader.value = true

  await AddressAPI.deleteAddress(address.id || -1)
    .then(() => fetchAddresses())
    .then(() => (isLoader.value = false))
}

const sendContact = async (contacts: ContactData[]) => {
  isLoader.value = true

  const getUpdateContact: ContactData[] = []
  const getCreateContact: ContactData[] = []
  contacts.forEach((contact) =>
    contact.id
      ? getUpdateContact.push(contact)
      : getCreateContact.push(contact),
  )

  if (getUpdateContact.length) {
    await ContactAPI.updateContact({ ...getUpdateContact[0] })
      .then(() => fetchContacts())
      .then(() => (isLoader.value = false))
  } else {
    await ContactAPI.createManyContacts(getCreateContact)
      .then(() => fetchContacts())
      .then(() => (isLoader.value = false))
  }
}
const deleteContact = async (contact: ContactData) => {
  await ContactAPI.deleteContact(contact)
    .then(() => fetchContacts())
    .then(() => (isLoader.value = false))
}

const fetchAddresses = async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  await AddressAPI.getAddresses(
      currentCompanyId,
      getCoreCookie()?.company_type,
  ).then((res) => {
    userAddresses.value = res.items
    userStore.setAddresses(res.items)
  })
}

const fetchContacts = async () => {
  userAuthInfo.value = await UserAPI.getUser(getCoreCookie().id)
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id
  const type = user?.type

  userContacts.value = (
    await ContactAPI.getItemsContacts([
      ['company_id', 'eq', currentCompanyId],
      'and',
      ['company_type', 'eq', type],
    ])
  ).items
}

const { isVerification } = storeToRefs(mainStore)

const fetchUserInfo = async () => {
  isLoader.value = true
  userAuthInfo.value = await UserAPI.getUser(getCoreCookie().id)

  totalUserData.value = await UserAPI.getUserData(
    userAuthInfo.value.company_id,
    getCoreCookie()?.company_type === 'customer'
      ? APIModels.Customer
      : APIModels.Farmer,
    ['users', 'company_rating'],
  )
  isLoader.value = false

  await setUserStatus()

  if (isVerification.value) {
    await getDocuments()
  }

  isFetching.value = false
}

const initMyAccount = async () => {
  const hash = router.currentRoute.value.hash
  if (hash == '#showVatNotification') {
    await router.replace('/account')
  }
  if (hash == '#thankyou') {
    modalOpen.value = true
    await router.replace('/account')
  }

  isLoader.value = true

  await fetchUserInfo().finally(async () => {
    isLoader.value = false
    await fetchAddresses()
    await fetchContacts()
  })
  if (mainStore.verificationStatus === 'full') {
    verificationStatus.value = 'full'
    activeTab.value = AccountTabs.AgreementForContractors
  } else if (mainStore.verificationStatus === 'part') {
    verificationStatus.value = 'part'
    activeTab.value = AccountTabs.AgreementForContractors
  } else activeTab.value = AccountTabs.AccountDetails
}

emitter.on('refresh-token', async (e) => {
  await initMyAccount()
})

onMounted(async () => {
  await initMyAccount()
})

const activeTab = ref<AccountTabs>(AccountTabs.AccountDetails)

const changeTab = (tab: AccountTabs) => {
  activeTab.value = tab
}

const commonFormErrors = ref({
  email: '',
})

/*
 * Поменять статус верификации в хранилище
 */
const clearVerificationStatus = () => {
  verificationStatus.value = 'none'
  mainStore.setVerificationStatus('none')
}

watch(activeTab, () => {
  switch (activeTab.value) {
    case AccountTabs.MyCompany: {
      activeDocuments.value = userDocuments.value?.filter((document) => {
        return (
          document.type === 'tin' ||
          (getCoreCookie()?.company_type === Roles.Farmer &&
            document.type === 'PA')
        )
      })
      break
    }
  }
})

const setUserStatus = async () => {
  userData.value = await UserAPI.getUser(getCoreCookie().id, [
    'company',
    'company.contacts',
  ])

  const isNotUploadDocuments = localStorage.getItem('isNotUploadDocuments')

  if (
    getCoreCookie()?.company_type === Roles.Farmer &&
    isNotUploadDocuments === 'true'
  ) {
    userStatus.value = ProfileStatus.NeedToVerify
  }

  if (userData.value.company.is_active) {
    userStatus.value = ProfileStatus.Verified
  } else if (userData.value.company.user_need_accept !== null) {
    userStatus.value = ProfileStatus.Verifying
  } else if (
    !userData.value.company.is_active &&
    userData.value.company.is_docs_sent &&
    !isNotUploadDocuments
  ) {
    userStatus.value = ProfileStatus.Unverified
  } else if (!userData.value.company.is_docs_sent) {
    userStatus.value = ProfileStatus.NeedToVerify
  }
}

const checkMyAccountTabInLocalStorage = () => {
  const myAccountTab = localStorage.getItem('MyAccountTab')
  if (myAccountTab === AccountTabs.AddressList) {
    activeTab.value = AccountTabs.AddressList
    localStorage.removeItem('MyAccountTab')
  }
}

onMounted(() => {
  checkMyAccountTabInLocalStorage()
})

watch(userStatus, () => {
  //@ts-ignore
  accountTabsData.value = accountTabsData.value.map((tab) => {
    return {
      ...tab,
      isDisabled: checkIsDisableTab(tab.tabName),
    }
  })
})

const closeModal = () => {
  modalOpen.value = false
}

mainStore.$subscribe(async (mutation, state) => {
  if (state.verificationStatus !== 'none') {
    mainStore.setVerificationStatus('full')
    await fetchUserInfo().then(async () => {
      await setUserStatus()
    })
  }
  if (state.verificationStatus === 'refetch') {
    mainStore.setVerificationStatus('none')
  }
})

const route = useRoute()
</script>

<template>
  <ModalWindow
    class="thanks-modal"
    :show="modalOpen"
    :show-close-icon="false"
    v-model:open="modalOpen"
  >
    <template #header> Спасибо, что вы с нами! </template>

    <template #body>
      Пока происходит проверка данных организации, вы можете <br />
      указать удобную форму заключения договора поставки <br />
      c потенциальными контрагентами.

      <div class="close-button">
        <Button class="modal-close" @click="closeModal" type="primary">
          Хорошо
        </Button>
      </div>
    </template>
  </ModalWindow>
  <div
    class="wrapper-home home"
    v-if="
      totalUserData?.user_need_accept === null ||
      totalUserData?.user_need_accept === 'cron'
    "
  >
    <div class="account">
      <div class="account__header">
        <div class="account__title">Профиль</div>
      </div>
      <NotificationReminder />
      <div class="account__content">
        <div class="left-side">
          <div class="status-block">
            <div class="status-block__wrapper" v-if="totalUserData?.id">
              <StatusBlock
                :status="userStatus"
                :totalUserData="totalUserData"
                :userData="totalUserData"
              />
            </div>
            <div class="status-block__loader" v-else>
              <StarLoader />
            </div>
          </div>
          <div class="tabs">
            <ul class="tabList">
              <TabListItem
                v-for="tabData in accountTabsData"
                :key="tabData.tabName"
                :tabName="tabData.tabName"
                :tabImg="tabData.img"
                :disabled="tabData.isDisabled"
                @change-tab="
                  tabData.isDisabled ? () => {} : changeTab(tabData.tabName)
                "
                :class="{
                  active: activeTab === tabData.tabName,
                  disabled: tabData.isDisabled,
                }"
              />
            </ul>
          </div>
        </div>
        <div class="content-loader" v-if="isLoader">
          <StarLoader />
        </div>
        <div class="account__card" v-else>
          <div
            v-if="userRoles?.length > 0 && totalUserData"
            class="account__tab-content"
          >
            <AccountDetails
              v-if="activeTab === AccountTabs.AccountDetails"
              :key="totalUserData?.user_id"
              :form-errors="commonFormErrors"
              :role="getCoreCookie()?.company_type"
              :status="userStatus"
              :user-data="<IAccountUser>totalUserData"
              @update="fetchUserInfo"
            />
            <MyCompanyForm
              v-if="activeTab === AccountTabs.MyCompany"
              :role="getCoreCookie()?.company_type"
              :documents="activeDocuments"
            />
            <AddressContactForm
              v-if="activeTab === AccountTabs.AddressList"
              :userAddress="userAddresses"
              :form-errors="commonFormErrors"
              title-page="Список адресов"
              :role="getCoreCookie()?.company_type"
              @send="sendAddress"
              @delete="deleteAddress"
              :status="userStatus"
            />

            <EmployeeList v-if="activeTab === AccountTabs.EmployeeList" />

            <AddressContactForm
              v-if="activeTab === AccountTabs.ContactPersons"
              :userContacts="userContacts"
              :form-errors="commonFormErrors"
              title-page="Контактные лица"
              :role="getCoreCookie()?.company_type"
              @send="sendContact"
              @delete="deleteContact"
              :status="userStatus"
            />
            <AgentContract
              v-if="activeTab === AccountTabs.AgreementForContractors"
              :role="getCoreCookie()?.company_type"
              :user-data="totalUserData"
              @update="fetchUserInfo"
            />

            <!--            <SignerData-->
            <!--              v-if="activeTab === AccountTabs.SignerData"-->
            <!--              :key="totalUserData.user_id"-->
            <!--              :userData="totalUserData"-->
            <!--              :form-errors="commonFormErrors"-->
            <!--              :role="-->
            <!--                userRoles.includes(Roles.Customer)-->
            <!--                  ? Roles.Customer-->
            <!--                  : Roles.Farmer-->
            <!--              "-->
            <!--              :status="userStatus"-->
            <!--              :user-data="totalUserData"-->
            <!--              :documents="activeDocuments"-->
            <!--            />-->

            <JurForm
              v-if="activeTab === AccountTabs.LegalInformation"
              :user-data="totalUserData"
              :status="userStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDataView
    v-else-if="totalUserData?.id"
    :role="getCoreCookie()?.company_type"
    :userInfo="totalUserData"
    @refetchUser="fetchUserInfo"
  />
  <!--  Модалка о частичном завершении верификации -->
  <Teleport to="body">
    <ModalWindow
      :show="verificationStatus === 'part'"
      :show-close-icon="false"
      class="verification-modal"
      @close="clearVerificationStatus"
    >
      <template #header> Спасибо, что вы с нами! </template>
      <template #body>
        Пока происходит проверка данных организации, вы можете указать удобную
        форму заключения договора с потенциальными контрагентами.
        <div class="verification-buttons">
          <EButton
            @click="clearVerificationStatus"
            class="larger-btn"
            :style-config="{ ...fontSize16 }"
            >Хорошо</EButton
          >
        </div>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
@import '@/components/AccountForms/accountStyles.scss';

.thanks-modal {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 32px;
    }
    .modal-header {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      text-align: left;
    }
    .modal-body {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #718096;

      .close-button {
        margin-top: 32px;
      }
    }
  }
}

.modal-close {
  height: 48px;
  width: 100%;
}

.wrapper-home {
  display: flex;
  gap: 32px;
  padding-top: 20px;
  @include wrapper();
}

.content-loader {
  width: 706px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account {
  margin-bottom: 20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: $gray-800;
  }

  &__content {
    display: flex;
    gap: 2rem;
  }

  &__card {
    width: 100%;
    height: fit-content;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &--sidebar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 36px 24px;
      gap: 24px;
      height: 458px;
      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 24px;
    }

    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #2d3748;
    }

    &-loader {
      width: 352px;
      min-height: 13rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .status-block__loader {
    display: flex;
    justify-content: center;
    transform: scale(0.4);
  }

  &__documents {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .document-list-item:last-child {
      &:after {
        border: none !important;
      }
    }
  }
}
.active {
  background-color: $gray-300;
}
.left-side {
  width: 344px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .status-block {
    padding: 20px;
    border-radius: 24px;
    box-shadow:
      0 0 1px rgba(12, 26, 75, 0.1),
      0 10px 16px rgba(20, 37, 63, 0.06);
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    .status-block__loader {
      display: flex;
      justify-content: center;
      transform: scale(0.4);
    }
  }
}

.password-modal {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__form {
    display: flex;
    gap: 32px;
    flex-direction: column;
    margin-bottom: 32px;
  }
}
.verification-modal {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 32px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
      margin: 0 auto;
    }
    .modal-body {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-600;
      .verification-buttons {
        margin-top: 32px;
      }
    }
  }
}
</style>
