<script setup lang="ts">
import { Flex } from 'ant-design-vue'

interface IProps {
  title: string
}

const props = defineProps<IProps>()
</script>

<template>
  <Flex :gap="4" vertical class="data-item">
    <span>
      {{ props.title }}
    </span>
    <slot></slot>
  </Flex>
</template>

<style scoped lang="scss">
span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #00000073;
  
}
.data-item {
  line-height: 22px;
}
</style>
