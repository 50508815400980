<script setup lang="ts">

import { Button } from 'ant-design-vue'
import { RightOutlined } from '@ant-design/icons-vue'
import UncollapsedToolbarBody from '@/views/Farmer/productResponse/ProductToolbar/UncollapsedToolbarBody.vue'
import { IFarmerProduct } from '@/types/interfaces'
import { ref } from 'vue'

interface Props {
  farmerProduct: IFarmerProduct
}

const props = defineProps<Props>()

const isOpen = ref(true)

const toggleCollapse = () => {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <div class="toolbar-wrapper" :style="{ minWidth: isOpen ? '360px' : '24px' }">
    <div v-show="isOpen" class="toolbar-uncollapsed">
      <UncollapsedToolbarBody
        v-if="props.farmerProduct"
        :farmerProduct="props.farmerProduct"
        @button-click="toggleCollapse"
      />
    </div>
    <div v-if="!isOpen" class="toolbar-collapsed">
      <Button type="link" @click="toggleCollapse">
        <template #icon>
          <RightOutlined />
        </template>
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar-wrapper {
  box-sizing: border-box;
  padding: 24px 0;
  width: min-content;
  overflow: auto;
  height: 100%;
  transition: all 0.3s;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 56px;

  & .toolbar-collapsed {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    height: 100%;
  }
}
:deep(.toolbar-block-wrapper) {
  margin-bottom: 24px;
}
</style>
