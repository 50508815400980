<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { primaryButton } from '@/assets/EgalStyles/EButton'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import DocumentUploadListItem from '@/components/Document/DocumentUploadListItem.vue'
import { Document, UploadDocument } from '@/types/types'
import { useDocument } from '@/composables/useDocument'
import { DocumentAPI } from '@/api/DocumentAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {DocumentTypes, Roles} from '@/types/enums'
import { downloadOnClick } from '@/helpers/scripts'

const props = defineProps({
  requiredDocuments: {
    type: Array,
  },
  userOrganization: {
    type: String,
  },
  title: { type: String },
  description: { type: String },
  downloadText: { type: String },
  hideRequiredDocuments: { type: Boolean, required: false },
  orderDocument: { type: Object, required: false },
  infiniteLoad: { type: Boolean, required: false, default: false },
  documentFilters: { type: Array, required: false, default: () => [] },
  closeButton: { type: Boolean, required: false, default: () => false },
  orderCustomerId: { type: String, required: false, default: '' },
  orderFarmerId: { type: String, required: false, default: '' },

  withoutTotalSize: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['sent-documents', 'toggle-documents', 'close'])

const {
  uploadDocuments,
  uploadFile,
  uploadDocumentsNotEmpty,
  isDocumentsError,
  isSingleSizeError,
  isLengthError,
  isSizeError,
  setRequiredDocuments,
  deleteDocument,
  isFileTypesError,
} = useDocument()
const isButtonDisabled = ref(false)
const sentDocuments = () => {
  isButtonDisabled.value = true
  emit('sent-documents', uploadDocuments)
}
const userPADocument = ref<Document>({
  file_name: '',
  file_body: '',
})
const userACDocument = ref<Document>({
  file_name: '',
  file_body: '',
})

const { getCoreCookie } = useJWTDecode()

const getSystemPADocument = async () => {
  if (
      getCoreCookie()?.company_type === Roles.Farmer &&
    props.orderFarmerId &&
    props.orderCustomerId
  ) {
    const docs = await DocumentAPI.getDocuments([
      ...props.documentFilters
    ])
    if (docs.items.length === 0) {
      return
    }

    userPADocument.value = docs.items.find(
      (document: { is_system: boolean; document_type: string }) =>
        document.is_system && document.document_type === DocumentTypes.PA,
    )
    userACDocument.value = docs.items.find(
      (document: { is_system: boolean; document_type: string }) =>
        document.is_system && document.document_type === DocumentTypes.AC,
    )

    if (!userPADocument.value) {
      userPADocument.value = userACDocument.value
    }
    await downloadPADocument()
  }
  if (
    !props.orderDocument ||
    props.downloadText?.length === 0 ||
    !props.orderDocument[0]
  )
    return
  else {
    userPADocument.value = JSON.parse(JSON.stringify(
      props.orderDocument[0].find(
        (doc: { document_type: string; is_system: any }) =>
          doc.document_type === 'AC' && !doc.is_system,
      ),
    ))
    await downloadPADocument()
  }
}

const downloadRef = ref<HTMLAnchorElement>()
const downloadPADocument = async () => {
  await downloadOnClick(
    userPADocument.value.id,
    userPADocument.value,
    downloadRef.value,
  )
}

const continueValidator = computed(() => {
  if (props.withoutTotalSize) {
    return (
      !!isSingleSizeError.value?.errors ||
      isFileTypesError.value ||
      isButtonDisabled.value
    )
  }
  if (!props.withoutTotalSize) {
    return !!isDocumentsError.value || isButtonDisabled.value
  } else return false
})

watch(isSingleSizeError, () => {
  return continueValidator
})

watch(uploadDocumentsNotEmpty, () => {
  isButtonDisabled.value = !uploadDocumentsNotEmpty
})

onMounted(() => {
  getSystemPADocument()
  setRequiredDocuments(props.requiredDocuments as UploadDocument[])
})
</script>

<template>
  <div class="page-card">
    <div class="page-card__header">{{ title }}</div>
    <p class="page-card__info">{{ description }}</p>
    <p class="page-card__info">
      Формат документов: .pdf .gif, .jpg, .png .jpeg
    </p>
    <div class="page-card__body">
      <ul
        class="document-list"
        v-if="requiredDocuments.length > 0 && !hideRequiredDocuments"
      >
        <li
          class="document-list__item"
          v-for="requiredDocument in requiredDocuments"
          :key="requiredDocument"
        >
          {{ requiredDocument.name }}
        </li>
      </ul>
    </div>
    <div class="page-card__uploader">
      <FileUploader @load-file="uploadFile" v-if="!uploadDocumentsNotEmpty" />
      <div v-if="!uploadDocumentsNotEmpty" class="page-card__links">
        <a
          class="page-card__links-link"
          @click="downloadPADocument"
          target="_blank"
        >
          {{ downloadText }}
        </a>
        <a ref="downloadRef" />
        <EButton
          v-if="closeButton"
          @click="emit('close')"
          :style-config="{ ...primaryButton, fontWeight: 500, width: '100%' }"
        >
          Продолжить без загрузки документов
        </EButton>
      </div>
      <div
        class="page-card__uploader-manage-files"
        v-if="uploadDocumentsNotEmpty"
      >
        <div class="page-card__uploader-documents">
          <DocumentUploadListItem
            v-for="(document, index) in uploadDocuments"
            :key="index"
            :index="index"
            :name="document.name"
            :size="document.size"
            :errors="document.errors"
            document-model="profile"
            @delete-document="deleteDocument"
          />
          <FileUploader
            :type="'custom'"
            @load-file="uploadFile"
            v-if="isLengthError || infiniteLoad"
          >
            <a href="#" class="page-card__add-document">
              + Добавить документы
            </a>
          </FileUploader>
        </div>
        <EButton
          @click="sentDocuments"
          :data="{ disabled: continueValidator }"
          :style-config="{ ...primaryButton, fontWeight: 500, width: '100%' }"
        >
          Продолжить
        </EButton>

        <div
          class="page-card__error"
          v-if="!props.withoutTotalSize && isSizeError"
        >
          Превышен размер загружаемых файлов, максимум 30 Мб
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.page-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 578px;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px 3.5rem;
  gap: 1rem;
  text-align: left;

  &--small {
    width: 360px;
    height: 100%;
    margin-bottom: 7px;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: none;
    border-radius: 1rem;
    padding: 36px 24px;

    .page-card__footer {
      width: 100%;
    }

    .page-card__links {
      margin-top: 13px;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__document-link {
    text-decoration: none;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 27px;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &-link {
      @include router-link();
      cursor: pointer;
    }
  }

  &__info {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #718096;
  }

  &__uploader {
    width: 100%;

    .page-card__link {
      margin-top: 1.25rem;
    }
  }

  &__uploader-manage-files {
    height: fit-content;
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
  }

  &__uploader-documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
    width: 100%;
    height: fit-content;
  }

  &__add-document {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #0066ff;
    text-decoration: none;
    margin: 15px 0 2.5rem;
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f16063;
    padding-top: 0.75rem;
  }
}

.egal-button {
  justify-content: center;
}

.document-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d3748;
  display: flex;
  flex-direction: column;
  flex: none;
  order: 1;
  flex-grow: 0;
  gap: 0.5rem;
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}
</style>
