<script lang="ts">
import CustomerRequestCommonInfoMethodOfObtaining from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/MethodOfObtaining/CustomerRequestCommonInfoMethodOfObtaining.vue'
import CustomerRequestCommonInfoDeliveryAddress from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/DeliveryAddress/CustomerRequestCommonInfoDeliveryAddress.vue'
import CustomerRequestCommonInfoDeliveryTime from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/DeliveryTime/CustomerRequestCommonInfoDeliveryTime.vue'
import CustomerRequestCommonInfoTermsOfPayments from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/TermsOfPayments/CustomerRequestCommonInfoTermsOfPayments.vue'
import CustomerRequestCommonInfoServices from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/Services/CustomerRequestCommonInfoServices.vue'
import CustomerCommonInfoComment from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/components/CustomerRequestCommonInfo/components/Comment/CustomerCommonInfoComment.vue'
import { computed, defineComponent } from 'vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import { Empty, TypographyTitle as TgTitle } from 'ant-design-vue'

export default defineComponent({
  name: 'CustomerRequestCommonInfo',
  components: {
    TgTitle,
    Empty,
    CustomerRequestCommonInfoMethodOfObtaining,
    CustomerRequestCommonInfoDeliveryAddress,
    CustomerRequestCommonInfoDeliveryTime,
    CustomerRequestCommonInfoTermsOfPayments,
    CustomerRequestCommonInfoServices,
    CustomerCommonInfoComment,
  },
  setup() {
    const store = useCustomerRequestStore()

    // Получение данных из хранилища
    const currentCustomerRequest = computed(() => store.currentCustomerRequest)

    // Вычисляемые свойства
    const comment = computed(() => currentCustomerRequest.value?.comment ?? '')
    const contactName = computed(
      () => currentCustomerRequest.value?.contact_name ?? '',
    )
    const contactPhone = computed(
      () => currentCustomerRequest.value?.contact_phone ?? '',
    )
    const deliveryAddress = computed(
      () => currentCustomerRequest.value?.delivery_address ?? '',
    )
    const contactPost = computed(
      () => currentCustomerRequest.value?.contact_post ?? '',
    )

    const isPaymentDelayed = () => currentCustomerRequest.value?.is_payment_delayed

    const paymentDelayDays = () => currentCustomerRequest.value?.payment_delay_days


    // Доставка
    const isDeliveryExcluded = computed(
      () => currentCustomerRequest.value?.is_delivery_excluded ?? false,
    )
    const isSelfDelivery = computed(
      () => currentCustomerRequest.value?.is_self_delivery ?? false,
    )
    const isSupplierDelivery = computed(
      () => currentCustomerRequest.value?.is_supplier_delivery ?? false,
    )
    const isDeliveryIncluded = computed(
      () => currentCustomerRequest.value?.is_delivery_included ?? false,
    )
    const shouldBeDeliveredInDays = computed(
      () => currentCustomerRequest.value?.should_be_delivered_in_days ?? 0,
    )

    const shouldBeDeliveredAt = computed(
      // @ts-ignore
      () => currentCustomerRequest.value?.should_be_delivered_at ?? 0,
    )

    // Оплата
    const isPayNonCash = computed(
      () => currentCustomerRequest.value?.is_pay_non_cash ?? false,
    )
    const isPayCash = computed(
      () => currentCustomerRequest.value?.is_pay_cash ?? false,
    )

    // Услуги
    const palletizingType = computed(
      () => currentCustomerRequest.value?.palletizing_type,
    )
    const needUnload = computed(
      () => currentCustomerRequest.value?.need_unload ?? false,
    )

    const generateMethodsOfObtaining = () => {
      return [
        isDeliveryIncluded.value && 'Доставка включена в стоимость товара',
        isSupplierDelivery.value && 'Доставка от поставщика',
        isSelfDelivery.value && 'Самовывоз',
        isDeliveryExcluded.value && 'Оплачивается отдельно',
      ].filter(Boolean)
    }

    const generateMethodsOfPayment = () => {
      return [
        isPayCash.value && 'Наличными',
        isPayNonCash.value && 'Безналичными',
      ].filter(Boolean)
    }

    const generateServices = () => {
      const arr = []
      if (palletizingType.value === 'standard') arr.push('Паллетирование')
      if (palletizingType.value === 'euro') {
        arr.push('Паллетирование')
        arr.push('Eвропаллеты')
      }
      if (needUnload.value) arr.push('Разгрузка товаров')
      return arr
    }

    const methodsOfObtaining = computed(() => generateMethodsOfObtaining())
    const methodsOfPayment = computed(() => generateMethodsOfPayment())
    const services = computed(() => generateServices())

    const calcCols = () => {
      let count = 0
      if (contactPhone?.value?.length) {
        count += 1
      }
      if (methodsOfObtaining?.value?.length) {
        count += 1
      }
      if (deliveryAddress?.value?.length) {
        count += 1
      }
      if (shouldBeDeliveredInDays.value || shouldBeDeliveredAt.value) {
        count += 1
      }
      if (methodsOfPayment?.value?.length) {
        count += 1
      }
      if (services?.value?.length) {
        count += 1
      }
      if (comment?.value?.length) {
        count += 1
      }

      if (count === 1) {
        return 'customer-request-common-info__body'
      } else {
        return 'customer-request-common-info__body customer-request-common-info__body-two-cols'
      }
    }

    return {
      TgTitle,
      Empty,
      comment,
      contactName,
      contactPhone,
      deliveryAddress,
      contactPost,
      methodsOfObtaining,
      methodsOfPayment,
      shouldBeDeliveredInDays,
      shouldBeDeliveredAt,
      services,
      calcCols,
      isPaymentDelayed,
      paymentDelayDays
    }
  },
})
</script>

<template>
  <div class="customer-request-common-info">
    <div class="customer-request-common-info__title">Общая информация</div>
    <div :class="calcCols()">
      <CustomerRequestCommonInfoMethodOfObtaining
        :methods="methodsOfObtaining"
        v-if="methodsOfObtaining.length"
      />
      <CustomerRequestCommonInfoDeliveryAddress
        :address="deliveryAddress"
        v-if="deliveryAddress"
      />
      <!--        <CustomerRequestCommonInfoFiles />-->
      <CustomerRequestCommonInfoDeliveryTime
        v-if="!!shouldBeDeliveredInDays || !!shouldBeDeliveredAt"
        :delivery-date="shouldBeDeliveredAt"
        :delivery-time="shouldBeDeliveredInDays"
      />
      <CustomerRequestCommonInfoTermsOfPayments
        v-if="!!methodsOfPayment?.length"
        :payments="methodsOfPayment"
        :isPaymentDelayed="isPaymentDelayed()"
        :paymentDelayDays="paymentDelayDays()"
      />
      <CustomerRequestCommonInfoServices
        v-if="!!services?.length"
        :services="services"
      />

      <CustomerCommonInfoComment v-if="!!comment?.length" :comment="comment" />

      <!-- <StarLoader
        v-else-if="
          !shouldBeDeliveredInDays &&
          !methodsOfPayment?.length &&
          !services?.length &&
          !comment?.length
        "
      /> -->

      <div
        class="empty"
        v-else-if="
          !shouldBeDeliveredInDays &&
          !methodsOfPayment?.length &&
          !services?.length &&
          !comment?.length
        "
      >
        <Empty
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
          class="empty-view"
          :image-style="{
            height: '117px',
          }"
        >
          <template #description>
            <Space direction="vertical" :size="0">
              <TgTitle :level="4">Данные о запросе цен отсутствуют</TgTitle>
              <span class="empty-description">
                Вы не указали дополнительных требований при создании запроса цен
              </span>
            </Space>
          </template>
        </Empty>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.customer-request-common-info {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: 20px;

  &__title {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000e0;
    margin-bottom: 30px;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    height: fit-content;

    &-two-cols {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-right: 1px solid #0000000f;
      padding-right: 15px;
      z-index: 1;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  height: 525px;
  width: 900px;

  .empty-view {
    margin: auto;
  }

  .ant-empty .ant-empty-image svg {
    height: 117px;
    width: 184px;
  }

  .empty-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #000000a6;
  }
}
</style>
