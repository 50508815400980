<script setup lang="ts">
import { useDocument } from '@/composables/useDocument'
import { onMounted, reactive, watchEffect } from 'vue'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import { UploadDocument } from '@/types/types'
import DeleteBucket from '../../assets/img/delete-backet.svg'

interface IProps {
  image?: {
    image_body: string
    image_name: string
    image_url: string
  }
}

const {
  uploadDocuments,
  uploadFile,
  uploadDocumentsNotEmpty,
  isDocumentsError,
  isFileTypesError,
  isSizeError,
  isSingleSizeError,
  setRequiredDocuments,
  deleteDocument,
  setMaxSize,
  addToUploadedDocuments,
  setValidDocumentTypes,
  isSizeOrFileError,
} = useDocument()

const requiredImageDocuments = [{ name: 'фото', type: 'фото' }]

const emit = defineEmits(['input', 'on-unmount', 'error', 'image-error'])

const props = defineProps<IProps>()

const imageData = reactive({
  image_name: '',
  image_url: '',
  image_body: '',
})

const loadFile = (file: File, base64: string) => {
  if (isSizeError || isFileTypesError || isSingleSizeError) {
    imageData.image_body = ''
    imageData.image_url = ''
    imageData.image_name = ''
  }

  if (uploadDocuments.value.length === 0) {
    uploadFile(file, base64)
  }

  if (isFileTypesError.value) {
    deleteDocument(0)
    uploadFile(file, base64)
  }

  if (isSizeOrFileError.value) {
    deleteDocument(0)
    uploadFile(file, base64)
  }
  if (uploadDocuments.value[0]) {
    imageData.image_name = uploadDocuments.value[0].name
    imageData.image_body = uploadDocuments.value[0].base64
  }
  emit('input', imageData)
}

const deleteDoc = (index: number) => {
  deleteDocument(index)
  if (props.image?.image_url !== 'NULL') {
    imageData.image_body = 'NULL'
    imageData.image_url = 'NULL'
    imageData.image_name = 'NULL.png'
  } else {
    imageData.image_body = ''
    imageData.image_url = ''
    imageData.image_name = ''
  }

  emit('input', imageData)
}
watchEffect(() => {
  if (uploadDocuments.value.length === 0) {
    emit('image-error', false)
    return
  }

  emit(
    'image-error',
    isSizeError.value || isFileTypesError.value || isSingleSizeError.value,
  )
})

onMounted(() => {
  setMaxSize(1)
  setRequiredDocuments(requiredImageDocuments as UploadDocument[])
  setValidDocumentTypes(['image/jpeg', 'image/png', 'image/jpg'])
  if (props.image?.image_url) {
    addToUploadedDocuments({
      name: props.image.image_name,
      base64: props.image.image_url,
      size: '0',
      sizeMegabytes: 0,
      errors: [],
      documentType: 'image/png',
      type: 'image/png',
    })
  }
})
</script>

<template>
  <div class="product-card__load-file">
    <p class="product-card__load-file-title">Фотография товара</p>
    <FileUploader
      @load-file="loadFile"
      v-if="!uploadDocumentsNotEmpty || isSizeOrFileError"
    />
    <span
      class="product-card__load-file-rule"
      v-if="!uploadDocumentsNotEmpty || isSizeOrFileError"
      >.jpg, .jpeg, .png, .gif до 10 Мб</span
    >
    <p
      class="product-card__load-file-error"
      v-if="isSizeError || isSingleSizeError"
    >
      Превышен размер фотографии
    </p>
    <p class="product-card__load-file-error" v-if="isFileTypesError">
      Неправильный формат фотографии
    </p>

    <div
      class="product-card__image-block"
      v-if="uploadDocumentsNotEmpty && !isDocumentsError"
    >
      <img
        v-if="uploadDocuments[0].base64.length > 0"
        :src="uploadDocuments[0].base64"
        alt="product-image"
        class="product-card__image"
        loading="lazy"
      />
      <p class="product-card__image-block-delete" @click="deleteDoc">
        <img :src="DeleteBucket" alt="" loading="lazy" />Удалить
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-card {
  &__image-block {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-delete {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 14px;
      line-height: 17px;
      color: #f16063;
      cursor: pointer;
    }
  }

  &__image {
    width: 260px;
    height: 260px;
    min-height: 200px;
    border-radius: 8px;
    object-fit: cover;
  }

  &__load-file {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #2d3748;
    }

    &-error {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #f16063;
      padding: 0;
    }

    &-rule {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #718096;
    }
  }
}
</style>
