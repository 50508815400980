<script setup lang="ts"></script>

<template>
  <div class="overflow-loader" />
</template>

<style scoped lang="scss">
.overflow-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  top: 0;
  left: 0;
}
</style>
