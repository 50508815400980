<script setup lang="ts">

import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'

interface IProps {
  type: string
  description?: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['goBack'])

const handleGoBack = () => {
  emit('goBack')
}
</script>

<template>
  <div class="header">
    <div class="header__body body">
      <CustomerRequestHeaderActionButton
        type="Back"
        @handleClick="handleGoBack"
      />
      <div class="body__txt text">
        <div class="text__title">
          {{ props.type }}
        </div>
        <div class="text__description">
          {{ props.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.chevron-left {
  color: $gray-800;
  stroke: none !important;
}

.header {
  height: 86px;
  width: 100%;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  gap: 24px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 73px;
}

.text {
  display: flex;
  flex-direction: column;
  &__title {
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.88);
  }
  &__description {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000a6;
  }
}

.body {
  display: flex;
  gap: 10px;
  margin-left: 40px;
}
</style>
