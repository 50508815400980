import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class PriceAnswerAPIModel {
  async create(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceAnswer,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItems(
    page = 1,
    filter: any = [],
    withs: string[] = [],
    perPage = 10,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceAnswer,
      method: EgalMethods.GetItems,
      data: { filter, withs, pagination: { per_page: perPage, page: page }, order: [['created_at', 'desc']],},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceAnswer,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const PriceAnswerAPI = new PriceAnswerAPIModel()
export { PriceAnswerAPI }
