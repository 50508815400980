<script lang="ts">
import { computed, defineComponent } from 'vue'
// import CustomerRequestHeaderActionPanel from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionPanel.vue'
import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'
import { useRouter } from 'vue-router'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import { Tag } from 'ant-design-vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'CustomerRequestHeader',
  components: {
    CustomerRequestHeaderActionButton,
    Tag,
  },
  setup() {
    const router = useRouter()
    const store = useCustomerRequestStore()

    const onBackAction = () => {
      router.push('/requests')
    }

    const handleCancel = () => {
      console.debug('handleCancel in CustomerRequestHeader')
    }

    const handleClose = () => {
      console.debug('handleClose in CustomerRequestHeader')
    }

    const handleRepeat = () => {
      console.debug('handleRepeat in CustomerRequestHeader')
    }

    // Вычисляемые свойства
    const createdAt = computed(() =>
      dayjs(store.currentCustomerRequest.created_at).format('DD.MM.YYYY'),
    )
    const expirationDate = computed(() =>
      dayjs(store.currentCustomerRequest?.expiration_date).format(
        'DD.MM.YYYY, HH:mm',
      ),
    )

    return {
      onBackAction,
      handleCancel,
      handleClose,
      handleRepeat,
      createdAt,
      expirationDate,
    }
  },
})
</script>

<template>
  <div class="customer-request-header">
    <div class="customer-request-header_left">
      <CustomerRequestHeaderActionButton
        type="Back"
        @handleClick="onBackAction"
      />
      <div class="customer-request-header_left-title">
        Запрос от {{ createdAt }}
      </div>
      <Tag color="blue">Открыт до {{ expirationDate }}</Tag>
    </div>
    <div class="customer-request-header_right">
      <!--      // TODO: Вернуть и доделать в след. релизе-->
      <!--      <CustomerRequestHeaderActionPanel-->
      <!--        :handle-cancel="handleCancel"-->
      <!--        :handle-close="handleClose"-->
      <!--        :handle-repeat="handleRepeat"-->
      <!--      />-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.customer-request-header {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: #f0f0f0 1px solid;
  height: 86px;
  &_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
    &-title {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }
  }
  &_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
  }
}
</style>
