<script setup lang="ts">
interface Props {
  title: string
  subtitle?: string
  absolute?: boolean
}

const props = defineProps<Props>()
</script>

<template>
  <div class="empty-list-view">
    <div class="empty-list-view-content" :class="{ absolute: props.absolute }">
      <img src="../assets/img/empty-farm-card.svg" alt="empty-farm-cart" loading="lazy" />
      <div class="text-content" :class="{ nowrap: !subtitle }">
        <span class="title">{{ title }}</span>
        <span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
      </div>
      <slot name="action" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.empty-list-view {
  width: 100%;
  height: 100%;
  position: relative;
  &-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    &.absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .image-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-content {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 300px;
      text-align: center;
      .title {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0;
        text-align: center;
      }
      &.nowrap {
        max-width: unset;
        .title {
          white-space: nowrap;
        }
      }
      .subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: center;
        color: #000000a6;
      }
    }
  }
}
</style>
