<script setup lang="ts">
import { TUserStatus } from '@/types/types'
import { computed } from 'vue'
import { user_status_type } from '@/helpers/dictionary'
import { Tag } from 'ant-design-vue'

interface IProps {
  status: TUserStatus
}
const props = defineProps<IProps>()
const color = computed(() => {
  switch (props.status) {
    case 'need_verify':
      return 'blue'
    case 'active':
      return 'green'
    case 'need_confirm':
    case 'confirmed':
      return 'orange'
    case 'not_confirmed':
    case 'banned':
    case 'incorrect_data':
      return 'red'
    default:
      return 'default'
  }
})
</script>

<template>
  <Tag :color="color" class="tag">
    {{ user_status_type[props.status] }}
  </Tag>
</template>

<style scoped lang="scss">
.tag {
  white-space: normal !important;
}
</style>
