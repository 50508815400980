import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class RecipientAPIModel {
  async createMany(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.Recipient,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItem(id: string | number): Promise<any> {
    return RequestManager.request({
      model: APIModels.Recipient,
      method: EgalMethods.GetItem,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Recipient,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const RecipientAPI = new RecipientAPIModel()
export { RecipientAPI }
