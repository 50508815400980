<script setup lang="ts">
import { Modal, TabPane, Tabs } from 'ant-design-vue'
import { computed, onMounted, ref } from 'vue'
import { UserAPI } from '@/api/UserAPI'
import { show_notification } from '@/helpers/scripts'
import { CompanyAPI } from '@/api/CompanyAPI'
import { DocumentAPI } from '@/api/DocumentAPI'
import {DocumentTypes, Roles} from '@/types/enums'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { useRoute } from 'vue-router'
import UserBreadcrumbs from '@/views/Admin/UserView/UserBreadcrumbs.vue'

import { ICompanyView, IUserAuth } from '@/types/interfaces'
import UserHeader from '@/views/Admin/UserView/UserHeader.vue'
import CompanyData from '@/views/Admin/UserView/CompanyData.vue'
import CompanyDocumentDrawer from '@/views/Admin/UserView/CompanyDocumentDrawer.vue'
import EmployeeList from '@/views/Admin/UserView/EmployeeList.vue'
import InviteList from '@/views/Admin/UserView/InviteList.vue'
import {useJWTDecode} from "@/composables/useJWTDecode";
import emitter from "@/emitter";

const is_verify_modal = ref(false)
const is_documents_drawer = ref(false)
const is_verifying = ref(false)
const company = ref<ICompanyView | null>(null)
const active_key = ref('company_data')
const { getRoles } = useJWTDecode()

const verify_company = async () => {
  is_verifying.value = true
  await confirm_user_documents().then(() => {
    is_verify_modal.value = false
    is_verifying.value = false
    update_user()
    show_notification('info', 'Компания верифицирована')
  })
}

const get_unregistered_user = async (id: string) => {
  return UserAPI.getItemAuth(id, ['role']).then((res) => {
    company.value = transform_to_company(res)
  })
}
const get_registered_user = async (id: string) => {
  if (getRoles()[0] === Roles.SuperAdmin || getRoles()[0] === Roles.Admin) {
    return CompanyAPI.getItem(id, ['user', 'company.company_rating']).then(
        (res) => {
          company.value = res
        },
    )
  }
}

const transform_to_company = (user: IUserAuth): ICompanyView => {
  return {
    id: user.id,
    legal_form: null,
    company_name: user.company_name,
    status: user.role?.role_id.includes('confirmed')
      ? 'no_company'
      : 'registration_not_confirmed',
    company_type: user.company_type,
    company_id: 0,
    email: user.email,
    contact_number: null,
    legal_address: null,
    created_at: user.created_at,
  }
}

const confirm_user_documents = async () => {
  const docs = await DocumentAPI.getCompanyDocuments(
    [
      ['company_id', 'eq', Number(company.value?.company_id)],
      'AND',
      ['company_type', 'eq', company.value?.company_type],
      'AND',
      ['type', 'ne', DocumentTypes.PAS],
    ],
    100,
    [],
  ).then((res) => {
    return res.items.map((doc) => ({
      id: doc.id,
      is_confirmed: true,
    }))
  })

  await DocumentAPI.updateManyDocuments(docs)
}

const update_user = () => {
  if (company.value) {
    company.value.status = 'active'
  }
}

const route = useRoute()

const initial_fetch = ref(false)

const get_user = async () => {
  initial_fetch.value = true
  const id = route.params.id as string
  if (route.hash === '#user') {
    await get_unregistered_user(id)
  } else {
    await get_registered_user(id)
  }
  initial_fetch.value = false
}

emitter.on('refresh-token', async (e) => {
  await get_user()
})

onMounted(async () => {
  await get_user()
})

const company_name = computed(() => {
  if (!company.value) return 'Загрузка'
  if (!company.value.company_name) return company.value.email
  return company.value?.legal_form
    ? `${company.value.legal_form} «${company.value?.company_name}»`
    : company.value.company_name
})

const calcRoute = computed(() => route)
</script>

<template>
  <div class="header">
    <UserBreadcrumbs :company_name="company_name" class="breadcrumbs" />
    <UserHeader
      :company_name="company_name"
      :company="company"
      @verify="is_verify_modal = true"
      @show_docs="is_documents_drawer = true"
    />
    <Tabs v-model:activeKey="active_key">
      <TabPane key="company_data" tab="Данные компании" />
      <TabPane key="employee_list" tab="Список сотрудников" />
      <TabPane key="invites" tab="Приглашения" />
    </Tabs>
  </div>
  <div class="wrapper">
    <template v-if="!initial_fetch && company">
      <CompanyData :company="company" v-show="active_key === 'company_data'" />
      <EmployeeList
        :company="company"
        v-show="active_key === 'employee_list'"
      />
      <InviteList :company="company" v-show="active_key === 'invites'" />
    </template>
    <template v-if="initial_fetch">
      <FullscreenLoading :type="'secondary'" />
    </template>
  </div>

  <CompanyDocumentDrawer
    v-model:open="is_documents_drawer"
    :company_id="company?.company_id"
  />

  <Modal
    width="440px"
    centered
    v-model:open="is_verify_modal"
    :bodyStyle="{ marginBottom: '24px' }"
    okText="Верифицировать"
    cancelText="Закрыть"
    @ok="verify_company"
    :okButtonProps="{
      loading: is_verifying,
    }"
  >
    <template #title>
      Верифицировать компанию
      {{ `${company?.legal_form} ${company?.company_name}` }}?
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.header {
  .breadcrumbs {
    padding: 16px 0 16px 0;
  }
  padding: 0 24px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  :deep(.ant-tabs-nav) {
    margin-bottom: 0 !important;
  }
}
.table-wrapper {
  padding: 24px;
}
.wrapper {
  height: calc(100vh - 145px);
  padding: 24px;
  overflow: auto;
  .box {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 8px;
  }
}
.list-loader {
  width: 100%;
  position: relative;
  transform: scale(0.6);
  padding: 30px 0;
}
</style>
