<script lang="ts" setup>
import { computed, reactive, onMounted } from 'vue'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import { togglePasswordEyeIcon } from '@/types/globalConst'
import { useCookies } from 'vue3-cookies'
import router from '@/router'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import { UserAPI } from '@/api/UserAPI'
import { FormItem, Input } from 'ant-design-vue'
import { format, maxString, minString, required } from '@/helpers/validators'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { TokenAPI } from '@/api/TokenAPI'
import { useRoute } from 'vue-router'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { useAuth } from '@/composables/useAuth'
import { clearCookies } from '@/helpers/scripts'

const { logout } = useAuth()
const { cookies } = useCookies()
const { decipherJWT } = useJWTDecode()

const registerForm = reactive({
  company_id: 0,
  email: '',
  password: '',
  password_confirmation: '',
  initiator_id: 0,
  role: '',
  company_type: '',
  token: '',
})
const errors = reactive({
  email: '',
  password: '',
  password_confirmation: '',
})

const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const repeatPasswordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

/**
 * Смена иконки глаза в инпутах с паролем
 * @param ev - клик эвент
 * @param repeatPassword - определяет был ли клик по инпуту "Повторите пароль"
 */
const onClick = (ev: { target: any }, repeatPassword: boolean) => {
  const obj = repeatPassword ? repeatPasswordInput : passwordInput
  togglePasswordEyeIcon(ev.target.tagName, obj)
}
const isAllFieldsFilled = computed(() => {
  return (
    !!registerForm.email &&
    !!registerForm.password &&
    !!registerForm.password_confirmation
  )
})
const isNoValidationErrors = computed(() => {
  return (
    !errors.email &&
    !errors.password &&
    !errors.password_confirmation &&
    registerForm.password === registerForm.password_confirmation
  )
})

const isFieldsValid = computed(() => {
  return isAllFieldsFilled.value && isNoValidationErrors.value
})

const firstRegister = async () => {
  registerForm.email = registerForm.email.toLowerCase()
  const userData = {
    attributes: {
      ...registerForm,
    },
  }
  try {
    await UserAPI.inviteRegister(userData).then(() => {
      cookies.set('registerForm', JSON.stringify(registerForm), '365d', '')
      router.push({ path: '/confirm-email', query: { invite: 'true' } })
    })
  } catch (err: any) {
    if (err?.response?.status == 405) await router.push('/mail-used')
    else await router.push('/error-occured')
  }
}

const logoutToInvite = async () => {
  await clearCookies()

  localStorage.removeItem('umt')
  localStorage.removeItem('ust')
  localStorage.removeItem('umrt')

  window.location.reload()
}

onMounted(async () => {
  if (!cookies.get('inviteStepOne')) {
    await checkToken()
  }
})

// Проверка на наличие токена в ссылке или куках.
const checkToken = async () => {
  let token = useRoute().query.token
  if (!token) token = cookies.get('inviteToken')
  await checkInvite(token)
}

// Проверка через API токена.
const checkInvite = async (token: string) => {
  TokenAPI.checkInvite(token)
    .then(() => {
      saveToken(token)
    })
    .catch(async (err) => {
      const status = err.response.status
      const message = err.response.data.action_error.message
      if (status == 405 && message == 'Expired token!') {
        await router.push('/invite-expired')
      }
      if (status == 405 && message == 'Invite already used') {
        await router.push('/mail-used')
      }
      if (status == 403) {
        await logoutToInvite()
      } else if (status != 403 && status != 405) {
        await router.push('/error-occured')
      }
    })
}

// Сохраняем токен в куках, заполняем форму, очищаем токен в ссылке
const saveToken = (token: string) => {
  cookies.set('inviteToken', token, '365d', '')
  token = decipherJWT('inviteToken').data
  Object.assign(token, { token: cookies.get('inviteToken') })

  setForm(token)

  router.push('/invite-register')
}

// Заполняем форму регистрации по приглашению
const setForm = (token: object) => {
  registerForm.company_id = token?.company_id
  registerForm.email = token?.email
  registerForm.initiator_id = token?.initiator_id
  registerForm.role = token?.role
  registerForm.company_type = token?.type
  registerForm.token = token?.token
}
</script>

<template>
  <div class="auth-card">
    <div class="auth-card__header">
      Регистрация по приглашению
      <div class="step">
        <span>этап 1</span>
        <span class="max">/2</span>
      </div>
    </div>
    <div class="auth-card__body">
      <FormItem label="Электронная почта" class="ym-record-keys">
        <Input disabled :value="registerForm.email" />
      </FormItem>
      <div class="password-row">
        <EInput
          v-model.trim="registerForm.password"
          :data="{
            ...inputDataConfig,
            id: 'password',
            label: 'Пароль',
            placeholder: '',
            modelValue: registerForm.password,
            type: passwordInput.type,
            iconRight: passwordInput.iconRight,
            validators: [
              required,
              maxString(24),
              minString(6),
              format(
                /[a-zA-Z]/,
                'Пароль должен содержать минимум одну букву латинского алфавита',
              ),
              format(/\d/, 'Пароль должен содержать минимум одну цифру'),
            ],
          }"
          :style-config="inputStyleConfig"
          class="password ym-record-keys"
          @click="(ev: any) => onClick(ev, false)"
          @error="(err: any) => (errors.password = err)"
        />
        <EInput
          v-model.trim="registerForm.password_confirmation"
          :data="{
            ...inputDataConfig,
            id: 'password-repeat',
            label: 'Повторите пароль',
            placeholder: '',
            modelValue: registerForm.password_confirmation,
            type: repeatPasswordInput.type,
            iconRight: repeatPasswordInput.iconRight,
            validators: [required],
            error:
              !registerForm.password_confirmation ||
              registerForm.password === registerForm.password_confirmation
                ? ''
                : 'Пароли не совпадают',
          }"
          :style-config="inputStyleConfig"
          class="password ym-record-keys"
          @click="(ev: any) => onClick(ev, true)"
          @error="(err: any) => (errors.password_confirmation = err)"
        />
      </div>
    </div>
    <div class="auth-card__footer">
      <EButton
        :data="{ disabled: !isFieldsValid }"
        @click="firstRegister"
        :style-config="{
          ...primaryButton,
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: 'transparent',
            color: '#A0AEC0',
          },
          fontSize16,
          padding: '10px 29px',
        }"
      >
        Зарегистрироваться
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 700px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;

  &__header {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;

    .step {
      right: 0;
      position: absolute;
      font-size: 20px;

      .max {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
    .password-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }
}

:deep(.ant-row) {
  display: block;
}

:deep(.ant-input) {
  height: 40px;
}
</style>
