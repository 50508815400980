<script setup lang="ts">
import {
  Button,
  TypographyText as TpText,
  TypographyTitle as TpTitle,
  Flex,
  Empty,
} from 'ant-design-vue'

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE

const emit = defineEmits(['resetFilters'])
</script>

<template>
  <div class="wrapper">
    <Flex align="center" :gap="24" vertical>
      <Empty
        class="empty"
        :image="simpleImage"
        description=""
        :imageStyle="{ width: '184px', height: '120px' }"
      />
      <Flex align="center" vertical>
        <TpTitle :level="5">По вашему запросу ничего не найдено</TpTitle>
        <TpText type="secondary">Попробуйте применить другие фильтры</TpText>
      </Flex>
      <Button @click="emit('resetFilters')">Сбросить фильтры</Button>
    </Flex>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.ant-empty) {
  margin-block: 0;
  & svg {
    width: 100%;
  }
}
</style>
