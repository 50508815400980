<script lang="ts" setup>
import {
  IAuctionProductAnswer,
  IAuctionProductRequest,
} from '@/types/interfaces'
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import {
  Button,
  Empty,
  Flex,
  Space,
  Table,
  Tag,
  TypographyText as TpText,
} from 'ant-design-vue'
import { RUBLE } from '@/model/constants'
import AntEmpty from '@/components/Empty/AntEmpty.vue'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { roundNumber } from '@/helpers/scripts'
import BetsResultFooter from '@/components/BestResultFooter/BetsResultFooter.vue'
interface IProps {
  products: IAuctionProductRequest[]
  myParticipantId: number
  isClosedReduction: boolean
  isAllProductsDeleted: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])

const columns = [
  {
    title: 'Товар',
    dataIndex: 'product_name',
    key: 'product_name',
    // width: '50%',
  },
  {
    title: 'Стартовая цена',
    dataIndex: 'start_price',
    key: 'start_price',
    width: '15%',
  },
  {
    title: 'Лучшая ставка',
    dataIndex: 'best_bet',
    key: 'best_bet',
    width: '15%',
  },
  {
    title: 'Ваша ставка',
    dataIndex: 'your_bet',
    key: 'your_bet',
    width: '27%',
  },
]

const getMyProductAnswer = (productAnswers: IAuctionProductAnswer[]) => {
  return productAnswers.find(
    (productAnswer) =>
      productAnswer.auction_participant_id === props.myParticipantId,
  )
}

const getAnswerStatus = (requestProduct: IAuctionProductRequest) => {
  if (requestProduct.is_deleted) {
    return 'no_product'
  }
  return requestProduct?.auction_product_answers.some(
    (answer) => answer.auction_participant_id === props.myParticipantId,
  )
    ? 'answered'
    : 'waiting'
}

const getRowClass = (record: IAuctionProductRequest) => {
  return record.has_in_catalogue ? '' : 'row__grey'
}

const bestBetsSum = props?.products?.reduce((acc, record) => {
  if (record.best_bet) {
    const pricePerUnit = Number(record.best_bet.price_per_unit)
    const volume = Number(record.volume)
    return acc + pricePerUnit * volume
  }
  return acc
}, 0)

const yourBetsSum = props?.products?.reduce((acc, record) => {
  const myProductAnswer = getMyProductAnswer(record.auction_product_answers)
  if (myProductAnswer) {
    const pricePerUnit = Number(myProductAnswer.price_per_unit)
    const volume = Number(record.volume)
    return acc + pricePerUnit * volume
  }
  return acc
}, 0)
</script>

<template>
  <div class="table-wrapper">
    <Table
      :columns="columns"
      :data-source="products"
      :pagination="false"
      bordered
      :rowClassName="getRowClass"
      v-if="!isAllProductsDeleted"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'product_name'">
          <Flex :gap="8">
            <div>
              <ClockCircleFilled
                v-if="getAnswerStatus(record) === 'waiting'"
                :style="{ color: 'rgba(250, 173, 20, 1)' }"
              />
              <CheckCircleFilled
                v-else-if="getAnswerStatus(record) === 'answered'"
                :style="{ color: 'rgba(82, 196, 26, 1)' }"
              />
              <ExclamationCircleFilled
                v-else-if="getAnswerStatus(record) === 'no_product'"
                :style="{ color: 'rgba(0, 0, 0, 0.45)' }"
              />
            </div>
            <ProductWithProperties
              :volume="record?.volume"
              :remaining_shelf_life="record.remaining_shelf_life"
              :measurement_unit_id="record?.farmer_product?.measurement_unit_id"
              :name="record.farmer_product_name"
              :is_sample_needed="record.is_sample_requested"
              :needed_samples_number="record?.requested_samples_number"
            />
          </Flex>
        </template>

        <template v-if="column.key === 'start_price'">
          <Space :size="4" direction="vertical">
            <Tag
              >{{
                `${roundNumber(record.start_price)} ${RUBLE}/${record?.farmer_product?.measurement_unit_id}`
              }}
            </Tag>
            <TpText type="secondary">{{ `НДС ${record.vat}%` }}</TpText>
          </Space>
        </template>

        <template v-if="column.key === 'best_bet'">
          <Flex v-if="!!record.best_bet" :gap="4" vertical>
            <TpText strong>{{
              `${roundNumber(record.best_bet.price_per_unit)} ₽/${record?.farmer_product?.measurement_unit_id}`
            }}</TpText>
            <TpText type="secondary">{{ `НДС ${record.vat}%` }}</TpText>
          </Flex>
          <TpText v-else type="secondary">-</TpText>
        </template>

        <template v-if="column.key === 'your_bet'">
          <Flex justify="space-between">
            <Flex
              v-if="getMyProductAnswer(record.auction_product_answers)"
              :gap="4"
              vertical
            >
              <TpText strong>{{
                `${roundNumber(getMyProductAnswer(record.auction_product_answers)?.price_per_unit || 0)}
                ₽/${record?.farmer_product?.measurement_unit_id}`
              }}</TpText>
              <TpText type="secondary">{{ `НДС ${record.vat}%` }}</TpText>
            </Flex>
            <TpText v-else type="secondary">-</TpText>
            <Button
              v-if="!isClosedReduction && !record.is_deleted"
              @click="emit('setRequestId', record.id)"
              type="primary"
              >Сделать ставку</Button
            >
          </Flex>
        </template>
      </template>
      <template #footer>
        <BetsResultFooter
          :best-bets-sum="bestBetsSum"
          :your-bets-sum="yourBetsSum"
        />
      </template>
    </Table>
    <div class="empty-wrapper" v-else>
      <AntEmpty
        title="Товары отсутствуют"
        subtitle="Поставщик удалил товары, которые были в этом торге на продажу"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}

:deep(.row__grey) {
  background-color: rgba(0, 0, 0, 0.04);
}
.empty-wrapper {
  min-height: calc(100vh - 300px);
  position: relative;
}
</style>
