import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class OrderPositionAPIModel {
  async getPositions(
    filter: any[][] = [],
    withs: string[] = ['order', 'initial_order_position', 'farmer_product'],
    order: string[][],
  ): Promise<any> {
      return RequestManager.request({
        model: APIModels.OrderPosition,
        method: EgalMethods.GetItems,
        data: { filter, withs, order, pagination: { per_page: 100, page: 1 }},
        headers: { Authorization: cookies.get('core')},
      })
  }

  async updatePosition(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.OrderPosition,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const OrderPositionAPI = new OrderPositionAPIModel()
export { OrderPositionAPI }
