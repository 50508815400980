<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { fontSize14 } from '@/assets/EgalStyles/EButton'
import {
  equal,
  format,
  maxString,
  minString,
  required,
} from '@/helpers/validators'
import { ProfileStatus } from '@/types/enums'
import { phoneMask, togglePasswordEyeIcon } from '@/types/globalConst'
import { IAccountUser, IUser } from '@/types/interfaces'
import NotificationComponent from '@/components/Notification/NotificationComponent.vue'
import { useToast } from 'vue-toastification'
import InfoTooltip from '@/components/Tooltip/InfoTooltip.vue'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import {
  formatPhoneNumber,
  getDifferenceObject,
  onlyNumbersPhone,
  transformPhoneNumber,
} from '@/helpers/scripts'
import { UserAPI } from '@/api/UserAPI'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import {
  Input,
  Button,
  Form,
  FormItem,
  Flex,
  Row,
  Col,
  FormInstance,
} from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { useJWTDecode } from '@/composables/useJWTDecode'

interface Props {
  role: string
  userData: IAccountUser
  formErrors: any
  status?: ProfileStatus
}

interface FormState {
  newMail: string
  name: string
  position: string
  phone: string
}

const formState = reactive<FormState>({
  newMail: '',
  name: '',
  position: '',
  phone: '',
})
const formRef = ref<FormInstance>()
const mailInvalid = ref(false)
const props = defineProps<Props>()
const emit = defineEmits(['update'])
const { getCoreCookie } = useJWTDecode()
const accountData = ref<IUser>()

const validateMail = async (_rule: Rule, value: string) => {
  if (!value) {
    return Promise.reject('Введите почту')
  } else {
    if (mailInvalid.value) {
      return Promise.reject(
        'Почта не подходит или уже используется другим пользователем',
      )
    } else return Promise.resolve()
  }
}

const validatePhone = async (_rule: Rule, value: string) => {
  if (onlyNumbersPhone(value ?? '').length != 11) {
    if (!value) {
      Promise.resolve().then()
    } else return Promise.reject('Минимум 11 цифр')
  } else return Promise.resolve()
}

const rules: Record<string, Rule[]> = {
  newMail: [{ required: true, validator: validateMail, trigger: 'change' }],
  phone: [{ validator: validatePhone, trigger: 'change' }],
  name: [{ required: true, message: ' Введите ФИО' }],
  position: [{ max: 255, message: ' Максимум 255 символов' }],
}

const passwordsForm = reactive({
  oldPassword: '',
  newPassword: '',
  newConfirm: '',
})
const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const errors = reactive({
  oldPassword: '',
  newPassword: '',
  newConfirm: '',
})

const outerError = ref('')

const isEditPasswordOpen = ref(false)
const isLoading = ref(false)

const onClick = (ev: { target: any }) => {
  togglePasswordEyeIcon(ev.target.tagName, passwordInput)
}

const toast = useToast()

const changePassword = async () => {
  if (
    (!passwordsForm.oldPassword && passwordsForm.newPassword) ||
    (!passwordsForm.newPassword && passwordsForm.oldPassword)
  ) {
    outerError.value = 'Обязательное поле'
    return
  }
  await UserAPI.updatePassword({
    id: props.userData.user_id,
    old_password: passwordsForm.oldPassword,
    new_password: passwordsForm.newPassword,
  })
    .then(() => {
      isEditPasswordOpen.value = false
      toast({
        component: NotificationComponent,
        props: {
          title: 'Пароль успешно изменён',
        },
      })
      passwordsForm.oldPassword = ''
      passwordsForm.newPassword = ''
      passwordsForm.newConfirm = ''
    })
    .catch((err) => {
      if (err.message.includes('Old password')) {
        errors.oldPassword = 'Введенный пароль не совпадает с текущим паролем'
      } else {
        outerError.value = 'Что-то пошло не так. Повторите попытку снова'
      }
    })
}

const resetInputs = () => {
  passwordsForm.oldPassword = ''
  passwordsForm.newPassword = ''
  passwordsForm.newConfirm = ''
  errors.oldPassword = ''
  errors.newPassword = ''
  errors.newConfirm = ''
}

const isValidPassword = computed(() => {
  const isOldPasswordValid = !!passwordsForm.oldPassword && !errors.oldPassword
  const isNewPasswordValid = !!passwordsForm.newPassword && !errors.newPassword
  const isNewConfirmValid = !!passwordsForm.newConfirm && !errors.newConfirm
  return isOldPasswordValid && isNewPasswordValid && isNewConfirmValid
})

const inputsError = ref({
  email: '',
  contact_number: '',
  checking_account: '',
  description: '',
})

const isNotChangeData = computed(
  () =>
    accountData.value &&
    accountData.value?.name == formState.name &&
    accountData.value?.position == formState.position &&
    onlyNumbersPhone(accountData.value?.phone ?? '') ==
      onlyNumbersPhone(formState.phone ?? ''),
)

const isValidateInputs = computed(() => {
  return !Object.values(inputsError.value).some(Boolean)
})

const handleUpdateUserData = async () => {
  mailInvalid.value = false

  formRef.value?.validateFields().then(async () => {
    isLoading.value = true
    if (accountData.value?.email != formState.newMail) {
      await updateUserEmail()
    }
    if (!isNotChangeData.value && !mailInvalid.value) {
      await updateUserData()
    }
    isLoading.value = false

    if (!mailInvalid.value) {
      emit('update')
    }
  })
}

const updateUserEmail = async () => {
  await UserAPI.updateEmail({
    id: accountData.value.id,
    email: formState.newMail,
  }).catch((err) => {
    if (err?.response?.status == 405) {
      mailInvalid.value = true
      formRef.value?.validateFields()
    }
  })
}

const updateUserData = async () => {
  const updateObject = {
    name: formState.name,
    position: formState.position,
    phone: onlyNumbersPhone(formState.phone ?? ''),
  }
  if (!mailInvalid.value) {
    await UserAPI.updateUserData({
      id: accountData.value.id,
      ...getDifferenceObject(updateObject, accountData.value as object),
    })
  }
}

const getAccountData = async () => {
  accountData.value = await UserAPI.getUser(getCoreCookie().id)
  setProfileData()
}

const setProfileData = () => {
  formState.newMail = accountData.value.email
  formState.name = accountData.value.name
  formState.position = accountData.value.position
  formState.phone = formatPhoneNumber(accountData.value?.phone ?? '') || ''
}

onMounted(() => {
  getAccountData()
})

const validator = () => {
  return [
    required,
    maxString(24),
    minString(6),
    format(
      /[a-zA-Z]/,
      'Пароль должен содержать минимум одну букву латинского алфавита',
    ),
    format(/\d/, 'Пароль должен содержать минимум одну цифру'),
  ]
}

const handleChangePhone = (e: any) => {
  formState.phone = transformPhoneNumber(e?.target?.value)
}

watch(passwordsForm, () => {
  if (passwordsForm.newConfirm === passwordsForm.newPassword) {
    errors.newConfirm = ''
  } else {
    errors.newConfirm = 'Пароли не совпадают'
  }
})

const isEditable = computed(() => {
  return (
    props.status === ProfileStatus.NeedToVerify ||
    props.status === ProfileStatus.Verifying
  )
})
</script>

<template>
  <div class="form">
    <h3 class="title">Данные аккаунта</h3>

    <InfoTooltip
      v-if="status === ProfileStatus.Verifying"
      message="Дождитесь проверки вашей организации, чтобы заполнить данные аккаунта"
      style="margin-bottom: 24px"
    />
    <InfoTooltip
      v-if="status === ProfileStatus.NeedToVerify"
      message="Пройдите верификацию, чтобы заполнить данные аккаунта"
      style="margin-bottom: 24px"
    />

    <Form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :disabled="!accountData"
    >
      <Flex :gap="8" vertical>
        <Row>
          <Col :span="24">
            <FormItem label="Почта" name="newMail" required class="ym-record-keys">
              <Input class="mail" v-model:value="formState.newMail" />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col :span="24">
            <FormItem label="ФИО" name="name" class="ym-record-keys">
              <Input v-model:value="formState.name" />
            </FormItem>
          </Col>
        </Row>

        <Row :gutter="12" :style="{ display: 'flex' }">
          <Col :span="12">
            <FormItem label="Номер телефона" name="phone">
              <Input
                v-maska="{
                mask: phoneMask,
                preprocessor: (value: string) => transformPhoneNumber(value),
              }"
                class="phone"
                :value="formState.phone"
                @change="handleChangePhone"
                placeholder="Введите номер"
              />
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem label="Должность" name="position">
              <Input v-model:value="formState.position" />
            </FormItem>
          </Col>
        </Row>

        <Flex :gap="12" justify="flex-start" :style="{ marginTop: '8px' }">
          <Button
            :loading="isLoading"
            type="primary"
            @click="handleUpdateUserData"
            :disabled="
              !isValidateInputs ||
              (isNotChangeData && accountData?.email == formState.newMail)
            "
          >
            Сохранить изменения
          </Button>
          <Button
            type="link"
            class="edit-password"
            @click="isEditPasswordOpen = true"
          >
            Изменить пароль
          </Button>
        </Flex>
      </Flex>
    </Form>
  </div>
  <Teleport to="body">
    <ModalWindow
      :show="isEditPasswordOpen"
      :show-close-icon="true"
      class="password-modal"
      @close="
        () => {
          resetInputs()
          isEditPasswordOpen = false
        }
      "
    >
      <template #header>
        <div class="password-modal__title">Изменение пароля</div>
      </template>

      <template #body>
        <div class="password-modal__form">
          <EInput
            v-model.trim="passwordsForm.oldPassword"
            :data="{
              ...inputDataConfig,
              id: 'password',
              label: 'Старый пароль',
              placeholder: '',
              modelValue: passwordsForm.oldPassword,
              error: outerError || errors.oldPassword,
              type: passwordInput.type,
              iconRight: passwordInput.iconRight,
              validators: validator(),
            }"
            :style-config="inputStyleConfig"
            class="password ym-record-keys"
            @click="onClick"
            @error="(err) => (errors.oldPassword = err)"
            @update:modelValue="() => (outerError = '')"
          />
          <EInput
            v-model.trim="passwordsForm.newPassword"
            :data="{
              ...inputDataConfig,
              id: 'password-new',
              label: 'Новый пароль',
              placeholder: '',
              modelValue: passwordsForm.newPassword,
              error: outerError || errors.newPassword,
              type: passwordInput.type,
              iconRight: passwordInput.iconRight,
              validators: validator(),
            }"
            :style-config="inputStyleConfig"
            class="password-new ym-record-keys"
            @click="onClick"
            @error="(err) => (errors.newPassword = err)"
            @update:modelValue="() => (outerError = '')"
          />
          <EInput
            v-model.trim="passwordsForm.newConfirm"
            :data="{
              ...inputDataConfig,
              id: 'password-confirm',
              label: 'Повторите новый пароль',
              placeholder: '',
              modelValue: passwordsForm.newConfirm,
              error: outerError || errors.newConfirm,
              type: passwordInput.type,
              iconRight: passwordInput.iconRight,
              validators: [...validator(), equal(passwordsForm.newPassword)],
              showError: passwordsForm.newPassword !== passwordsForm.newConfirm,
            }"
            :style-config="inputStyleConfig"
            class="password-new"
            @click="onClick"
            @error="(err) => (errors.newConfirm = err)"
            @update:modelValue="() => (outerError = '')"
          />
        </div>
      </template>
      <template #footer>
        <EButton
          :style="{ width: '100%' }"
          :style-config="fontSize14"
          class="confirm__button"
          :data="{ disabled: !isValidPassword }"
          @click="changePassword"
          ><span class="btn-text">Изменить пароль</span>
        </EButton>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style scoped lang="scss">
//@import './accountStyles.scss';
@import '@/assets/style/variables.scss';

.form {
  padding: 48px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  min-width: 608px;
  .title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 24px;
  }

  :deep(.ant-input) {
    font-size: 16px;
    height: 48px;
  }

  .documentBlock {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .edit-password {
    background: #deebfc;
  }
}

.password-modal {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__form {
    display: flex;
    gap: 32px;
    flex-direction: column;
    margin-bottom: 32px;
  }
}

.btn-text {
  margin: 0 auto;
}

.form__actions {
  display: flex;
  gap: 12px;
}
:deep(.ant-row) {
  display: block;
}
:deep(.ant-btn) {
  height: 48px;
}
</style>
