<script lang="ts" setup>
import { fontSize14, withoutBGButton } from '@/assets/EgalStyles/EButton'
import { computed, h, onMounted, ref, Ref, watch } from 'vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import CustomerOrderItem from '@/components/Customer/CustomerOrderItem.vue'
import {
  DocumentTypes,
  IPartnershipStatus,
  NewGeneralOrderStatus,
  OrderStatus, Roles,
} from '@/types/enums'
import ProductCard from '@/components/Customer/ProductCard.vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import {
  compare_price_if_equal,
  dateToLocale,
  declensionProvider,
  formatPhoneNumber,
  formatPrice,
  getSortedArrayByCreatedAt,
  getStatusType,
  getUserIdTemporary,
  is_orders_has_difference,
  show_notification,
} from '@/helpers/scripts'
import { OrderAPI } from '@/api/OrderAPI'
import { GeneralOrderAPI } from '@/api/GeneralOrderAPI'
import { Document, SigningAnAgreement, UploadDocument } from '@/types/types'
import { useDocument } from '@/composables/useDocument'
import ModalCloseWindow from '@/components/Modal/ModalCloseWindow.vue'
import DocumentOrder from '@/components/Document/DocumentOrder.vue'
import { DocumentAPI } from '@/api/DocumentAPI'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import SetNewStatusForm from '@/components/Orders/SetNewStatusForm.vue'
import DocumentUploadListItem from '@/components/Document/DocumentUploadListItem.vue'
import { useRoute } from 'vue-router'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import FarmLoader from '@/components/Loaders/FarmLoader.vue'
import CustomModal from '@/components/Modal/CustomModal.vue'
import { OrderDocumentAPI } from '@/api/OrderDocumentAPI'
import { IFarmer, IGeneralOrder, IOrderPosition } from '@/types/interfaces'
import { useJWTDecode } from '@/composables/useJWTDecode'
import RatingSetModal from '@/components/Rating/RatingSetModal.vue'
import { orderMarkAPI } from '@/api/OrderMarkAPI'
import { Alert, Button, notification, Tooltip } from 'ant-design-vue'
import { InfoCircleFilled, UpOutlined } from '@ant-design/icons-vue'
import OrderDocumentDrawer from '@/components/Orders/OrderDocumentDrawer.vue'
import CustomerPartnershipModal from '@/components/Orders/CustomerPartnershipModal/CustomerPartnershipModal.vue'
import RefuseTheEntireOrderModal from '@/views/Customer/RefuseTheEntireOrderModal.vue'
import CantRefuseTheEntireOrderModal from '@/views/Customer/CantRefuseTheEntireOrderModal.vue'
import dayjs from 'dayjs'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
import OrderCreatorItem from '@/components/Orders/OrderHeaderItems/OrderCreatorItem.vue'
import CartIcon from '@/components/Icons/CartIcon.vue'
import AddressItem from '@/components/Orders/OrderHeaderItems/AddressItem.vue'
import CommentItem from '@/components/Orders/OrderHeaderItems/CommentItem.vue'
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import { OrderPositionAPI } from '@/api/OrderPositionAPI'
import ApproveOrderByFarmer from '@/views/Customer/ApproveOrderByFarmer.vue'
import EditGeneralOrderDrawer from '@/views/Customer/EditGeneralOrderDrawer.vue'
import { useCookies } from 'vue3-cookies'
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'

interface Props {
  newOrderIsFetching: boolean
  newOrderIsFetchingDelay: boolean
}

interface IModalOrder {
  orderId: number
  status: string
  completed: boolean
  mark: number
  file_list?: any[]
  comment: string
}

interface IShippingDocuments {
  order_id: number
  file_path: string
}

interface IUploadDocument {
  file_name: string
  file_body: string
  errors: string[]
  extension: string
}

const props = defineProps<Props>()
const emit = defineEmits([
  'back',
  'cancel',
  'approve',
  'edit',
  'sign-contract',
  'update-orders',
  'refetchOrders',
])
const isFetching = ref(true)
const openOrderID = ref()
const orders = ref()

const { sentDocumentsWithoutWrap } = useDocument()
const openProductCard = ref<any>()
const openedFarm = ref<any>()
const ratingModalOpen = ref(false)
const isShowModalUpdatePage = ref(false)
const modalOrderCompleted = ref(false)
const modalOrderStatus = ref()
const openProduct = (position: any, farm: any) => {
  openedFarm.value = farm.farmer
  openProductCard.value = position
}
const closeFarmCard = () => {
  openProductCard.value = null
}

const otherGroupProducts = []

const selectedOrderPartnershipData = ref<
  Pick<SigningAnAgreement, 'id' | 'order_id'> & {
    farmer_id: number
    partnershipStatus: 'signed_farmer' | 'signed_customer' | null
    signingStatus: 'not_signed' | 'signed_out' | null
    farmer_name: string
  }
>({
  id: -1,
  order_id: -1,
  farmer_id: -1,
  partnershipStatus: null,
  signingStatus: null,
  farmer_name: '',
})

const isOpenSignModal = ref(false)
const isOpenDocumentDrawer = ref(false)
const openSignModal = (
  id: number,
  order_id: number,
  farmer_id: number,
  partnershipStatus: 'signed_farmer' | 'signed_customer',
  signingStatus: 'not_signed' | 'signed_out',
) => {
  isOpenSignModal.value = true
  selectedOrderPartnershipData.value = {
    id,
    order_id,
    partnershipStatus: partnershipStatus,
    signingStatus: signingStatus,
    farmer_id: farmer_id,
    farmer_name: '',
  }
}

// Модалка отмены заказа
const isCancelModal = ref(false)
const isCancelAllOrder = ref(false)
const orderToEditStatus = ref<any>()

const isAcceptModal = ref(false)
const toggleAcceptModal = () => (isAcceptModal.value = !isAcceptModal.value)

const isOtherDocumentsModal = ref(false)
const toggleOtherDocumentsModal = () =>
  (isOtherDocumentsModal.value = !isOtherDocumentsModal.value)

const closeOtherDocuments = async () => {
  await orderToStatus(openOrderID.value, OrderStatus.DonePartly, false)
  emit('update-orders')
  toggleOtherDocumentsModal()
}

const isSetStatusOrderModal = ref(false)
const toggleSetStatusOrderModal = () =>
  (isSetStatusOrderModal.value = !isSetStatusOrderModal.value)

const isDocumentsModal = ref(false)
const toggleDocumentsModal = () =>
  (isDocumentsModal.value = !isDocumentsModal.value)

const openCancelModal = (isAll = false, order?: any) => {
  isCancelModal.value = true
  isCancelAllOrder.value = isAll

  orderToEditStatus.value = order
}
const closeCancelModal = () => {
  isCancelModal.value = false
}

const createOtherDocuments = async () => {
  if (!otherDocuments.value) return
  await sentDocumentsWithoutWrap(otherDocuments.value)
}

const orderToStatus = async (
  id: number,
  order_id?: number,
  signed_earlier?: 'not_signed' | 'signed_out',
) => {
  isFetching.value = true
  await PartnersAPI.sign({
    id,
    order_id: order_id || -1,
    signed_earlier: signed_earlier || 'not_signed',
  }).then(async () => {
    await getGeneralOrder()
    emit('refetchOrders')
  })
}
const signContract = async (id: string) => {
  openFarmerID.value = id
  orderDocument.value = (
    await DocumentAPI.getDocuments(
      [
        ['document_type', 'eq', DocumentTypes.AC],
        [
          ['document_users.company_id', 'eq', generalOrder.value?.customer_id],
          'and',
          ['document_users.company_type', 'eq', 'customer'],
        ],
        [
          ['document_users.company_id', 'eq', openFarmerID.value],
          'and',
          ['document_users.company_type', 'eq', 'farmer'],
        ],
      ],
      100,
    )
  ).items //[0]
  toggleAcceptModal()
}

const uploadDocuments = (document: Document) => {
  orderDocument.value = document
  toggleOtherDocumentsModal()
}

const customerRequiredDocuments = [
  { name: 'Договор заказа', type: DocumentTypes.AC },
]
const customerOtherDocuments = [
  { name: 'Отгрузочные документы', type: DocumentTypes.Others },
]

const sentDocuments = async (documents: Ref<UploadDocument[]>) => {
  //@ts-ignore
  if (!orderDocument.value) return
  allUserDocuments.value = (
    await DocumentAPI.getDocuments(
      [
        ['is_system', 'eq', false],
        ['document_type', 'eq', DocumentTypes.AC],
        [
          ['document_users.company_id', 'eq', generalOrder.value?.customer_id],
          'and',
          ['document_users.company_type', 'eq', 'customer'],
        ],
        [
          ['document_users.company_id', 'eq', openFarmerID.value],
          'and',
          ['document_users.company_type', 'eq', 'farmer'],
        ],
      ],
      100,
    )
  ).items
  const document = documents.value
  document[0].user_id = generalOrder.value?.orders[0]?.farmer.id
  const { orderDocumentsFormat } = useDocument()
  for (const orderDocumentItem of orderDocumentsFormat(
    document,
    //@ts-ignore
    props.item.orders.find((order) => order.farmer_id == openFarmerID.value).id,
    orderDocument.value?.id,
  )) {
    await DocumentAPI.uploadDocument(orderDocumentItem).then(
      (response: any) => {
        DocumentAPI.updateDocument({
          ...orderDocumentItem,
          file_path: response.path,
        }).then(async () => {
          await getOrders()
        })
      },
    )
  }
  toggleAcceptModal()
  emit('update-orders', generalOrder.value?.id)
}

const otherDocuments = ref<Document[]>()
const sentOtherDocuments = async (documents: Ref<UploadDocument[]>) => {
  if (!openOrderID.value) return
  const { uploadDocumentsFormat } = useDocument()
  const documentsWithOrderID: UploadDocument[] = []
  documents.value.forEach((document) =>
    documentsWithOrderID.push({ order_id: openOrderID.value, ...document }),
  )
  otherDocuments.value = uploadDocumentsFormat(documentsWithOrderID)
  await orderToStatus(openOrderID.value, OrderStatus.DonePartly, false)
  await createOtherDocuments()
  toggleOtherDocumentsModal()
  emit('update-orders')
}

const getOrders = async () => {
  orders.value = await OrderAPI.getOrders(
    1,
    [['general_order_id', 'eq', generalOrder.value?.id]],
    [
      'orders.order_marks',
      'farmer.farmer_products',
      'customer',
      'order_positions',
      'partnership',
      'farmer.company_rating.',
    ],
    100,
  )
}

const userDocument = computed(() => {
  if (!allUserDocuments.value) return

  return allUserDocuments.value.find((document: Document) => {
    if (!document.users) return
    return document.users.find(
      (user: { id: string }) => user.id === openFarmerID.value,
    )
  })
})
const allUserDocuments = ref<Document[]>()
const orderDocument = ref<Document>()
const orderDocuments = ref<Document[]>()
const openFarmerID = computed(() => {
  return generalOrder.value?.orders[0]?.farmer.id
})
const openFarmer = computed(() => {
  return generalOrder.value?.orders[0]
})

const isDocumentLoader = ref(false)
const openOrderDocuments = (
  id: number,
  order_id: number,
  farmer_id: number,
  partnershipStatus: 'signed_farmer' | 'signed_customer',
  signingStatus: 'not_signed' | 'signed_out',
  farmer_name: string,
) => {
  selectedOrderPartnershipData.value = {
    id,
    order_id,
    partnershipStatus: partnershipStatus,
    signingStatus: signingStatus,
    farmer_id: farmer_id,
    farmer_name,
  }
  isOpenDocumentDrawer.value = true
}

const openOtherDocuments = async (id: number) => {
  openOrderID.value = id
  toggleOtherDocumentsModal()
}

const getDocumentsList = computed(() => {
  let documentList: Document[] = []
  if (userDocument.value) {
    documentList = [...documentList, userDocument.value]
  }
  if (orderDocuments.value && orderDocuments.value?.length > 0) {
    documentList = [...documentList, ...orderDocuments.value]
  }
  return documentList
})

const generalOrder = ref<null | IGeneralOrder>(null)
const route = useRoute()
const getGeneralOrder = async () => {
  generalOrder.value = await GeneralOrderAPI.getOneGeneralOrder(
    [],
    [
      'orders.farmer',
      'orders.history',
      'orders.order_marks',
      'orders.farmer.company_rating',
      'delivery_address',
      'orders.shipping_address',
      'orders.partnership',
      'orders.orderPositions.farmerProduct.priceOptions',
      'orders.orderPositions.history',
      'orders.orderPositions.farmerProduct.specialPrices',
      'orders.orderPositions.farmerProduct.farmer',
      'customer.users',
      'contact',
      'history',
    ],
    Number(route.params.id),
  ).then((res) => {
    isFetching.value = false
    checkIsInitiator(res?.initiator_id)
    res.history = getSortedArrayByCreatedAt(res.history)
    res.orders = res.orders.map((order) => {
      order.history = getSortedArrayByCreatedAt(order.history)
      order.order_positions = order.order_positions.map((orderPosition) => {
        return {
          ...orderPosition,
          history: getSortedArrayByCreatedAt(orderPosition.history),
        }
      })
      return order
    })
    return res
  })
}

onMounted(async () => {
  await getGeneralOrder()
})

const orderDate = computed(() => {
  return dateToLocale(generalOrder.value?.order_date ?? '')
})
const orderHeader = computed(() => {
  if (generalOrder.value?.orders.length > 1) {
    return `Комплекcный заказ от ${orderDate?.value}`
  }
  const farmerName = `${generalOrder.value?.orders[0]?.farmer.legal_form} ${generalOrder.value?.orders[0]?.farmer.name}`
  return `Заказ от ${orderDate?.value}, ${farmerName}`
})
const isDescriptionOpened = ref(false)
const toggleOrderDescription = () => {
  isDescriptionOpened.value = !isDescriptionOpened.value
}

const isOrderStatusChanging = ref(false)
const update_order = async (
  orderInfo: { id: number; status: string; execution_comment?: string },
  cb?: () => void,
) => {
  isFetching.value = true
  uploadedOrderDocuments.value = []
  await OrderAPI.updateOrder(orderInfo).then(async () => {
    cb && (await cb())
    await getGeneralOrder()
  })
}
const isChangesConfirmWithPartner = ref(false)
const closeChangesConfirmWithPartner = () => {
  isChangesConfirmWithPartner.value = false
}

const isChangesConfirmWithNoPartner = ref(false)
const closeChangesConfirmWithNoPartner = () => {
  isChangesConfirmWithNoPartner.value = false
}
const showChangesConfirmModal = (orderInfo: {
  order_id: number
  customer_id: string
  partner_status: IPartnershipStatus
}) => {
  if (orderInfo.partner_status === IPartnershipStatus.signed_customer) {
    update_order({
      id: orderInfo.order_id,
      status: NewGeneralOrderStatus.OrderPicking,
    })
    isChangesConfirmWithPartner.value = true
  } else if (orderInfo.partner_status === IPartnershipStatus.signed_farmer) {
    update_order({
      id: orderInfo.order_id,
      status: NewGeneralOrderStatus.SupplierContractSigned,
    })
    isChangesConfirmWithNoPartner.value = true
  } else {
    update_order({
      id: orderInfo.order_id,
      status: NewGeneralOrderStatus.ContractUnderSigning,
    })
    isChangesConfirmWithNoPartner.value = true
  }
}
const toggleDoneOrder = (order_id = 0) => {
  orderActionId.value = order_id
  ratingModalOpen.value = true
  modalOrderStatus.value = OrderStatus.Done
}

const isPartlyDoingOrder = ref(false)
const orderActionId = ref(0)
const togglePartlyDoingOrder = (order_id = 0) => {
  orderActionId.value = order_id
  ratingModalOpen.value = true
  modalOrderStatus.value = OrderStatus.DonePartly
  isPartlyDoingOrder.value = !isPartlyDoingOrder.value
}

const isNotDoneOrder = ref(false)
const toggleNotDoneOrder = (order_id = 0) => {
  orderActionId.value = order_id
  ratingModalOpen.value = true
  modalOrderStatus.value = OrderStatus.NotDone
  isNotDoneOrder.value = !isNotDoneOrder.value
}

const isRejectOrder = ref(false)
const toggleRejectOrder = (order_id = 0) => {
  orderActionId.value = order_id
  ratingModalOpen.value = true
  modalOrderStatus.value = NewGeneralOrderStatus.SupplierCancelledOrder
  modalOrderStatus.value = NewGeneralOrderStatus.BuyerCancelledOrder
  isRejectOrder.value = !isRejectOrder.value
}

const rateCompletedOrder = (data: { id: number; status: string }) => {
  orderActionId.value = data?.id
  modalOrderStatus.value = data?.status
  modalOrderCompleted.value = true
  ratingModalOpen.value = true
}
const uploadedOrderDocuments = ref<UploadDocument[]>([])

const farmerInfoSidebar = ref(false)
const openedFarmerInfo = ref<IFarmer | null>(null)

const { getCoreCookie } = useJWTDecode()
const isFarmer = computed(() => getCoreCookie()?.company_type === Roles.Farmer)
const isAdmin = computed(() => getCoreCookie()?.company_type === Roles.Admin || getCoreCookie()?.company_type === Roles.SuperAdmin)
const isInitiator = ref<boolean>(false)

const checkIsInitiator = (initiatorId: string) => {
  if (!isFarmer.value && !isAdmin.value) {
    isInitiator.value = getCoreCookie().id === initiatorId
  } else isInitiator.value = true
}

const splitBase64 = (document: IUploadDocument): IUploadDocument => {
  return {
    ...document,
    file_body: document.file_body.split(',')[1],
  }
}
const closeRatingModal = () => {
  ratingModalOpen.value = false
}
const changeStatusAndRate = async (data: IModalOrder, cb: () => void) => {
  isOrderStatusChanging.value = true
  await getGeneralOrder()
  let order = generalOrder.value?.orders?.find(
    (order: any) => order.id === data.orderId,
  )
  if (!data.completed) {
    const updatedOrder = generalOrder.value?.orders?.find(
      (ord) => ord.id === order.id,
    )
    if (order?.status != updatedOrder?.status) {
      cb()
      isShowModalUpdatePage.value = true
      isOrderStatusChanging.value = false
    } else {
      await update_order({
        id: data.orderId,
        status: data.status,
        execution_comment: data.comment,
      })
      const documentPaths: IShippingDocuments[] = []
      const responses = data.file_list?.map(async (doc) => {
        await OrderDocumentAPI.upload(splitBase64(doc)).then((res) =>
          documentPaths.push({
            order_id: data.orderId,
            file_path: res.path,
          }),
        )
      })
      await Promise.all(responses)
      await OrderDocumentAPI.createMany(documentPaths)
    }
  }
  if (!isShowModalUpdatePage.value) {
    await rateOrder(order, data)
    cb()
    await refetchData()
    notification.info({
      message: 'Заказ завершён',
      description: 'Ваша оценка сохранена',
      top: '80px',
      icon: h(InfoCircleFilled, {
        style: { color: 'rgba(22, 119, 255, 1)' },
      }),
    })
  }
}

const rateOrder = async (order: any, data: IModalOrder) => {
  const updatedOrder = generalOrder.value?.orders?.find(
    (ord) => ord.id === order.id,
  )
  if (
    updatedOrder?.order_marks?.find(
      (mark) => mark.initiator_type === 'customer',
    )
  ) {
    isShowModalUpdatePage.value = true
  } else {
    orderMarkAPI
      .createMark({
        initiator_id: order.customer_id,
        recipient_id: order.farmer_id,
        initiator_type: 'customer',
        recipient_type: 'farmer',
        order_id: order.id,
        mark: data.mark,
      })
      .then(() => {
        isOrderStatusChanging.value = false
      })
  }
}

const goBack = () => {
  emit('back')
}

const isOpenRefuseTheEntireOrderModal = ref(false)

const closeRefuseTheEntireOrderModal = () => {
  isOpenRefuseTheEntireOrderModal.value = false
}

const openRefuseTheEntireOrderModal = () => {
  isOpenRefuseTheEntireOrderModal.value = true
}

const cancelOrderRefuseTheEntireOrderModal = async () => {
  const objects = generalOrder.value?.orders?.map((order: any) => ({
    id: order?.id,
    status: 'Заказ отменен покупателем',
  }))

  OrderAPI.updateManyOrdersStatus(objects)
    .then(async () => {
      notification.success({
        top: '80px',
        message: `Заказ успешно отменен`,
        description: '',
      })
      await refetchData()
    })
    .catch(() => {
      notification.error({
        top: '80px',
        message: `Ошибка отмены заказа`,
        description: '',
      })
    })
}

const isOpenCantRefuseTheEntireOrderModal = ref(false)

const closeCantRefuseTheEntireOrderModal = () => {
  isOpenCantRefuseTheEntireOrderModal.value = false
}

const openCantRefuseTheEntireOrderModal = () => {
  isOpenCantRefuseTheEntireOrderModal.value = true
}

const cancelAllOrdersOpenModal = () => {
  const deliveryOrders = generalOrder.value?.orders?.filter((order: any) =>
    order.status.includes(NewGeneralOrderStatus.OrderInDelivery),
  )
  const isHaveDeliveryOrders = deliveryOrders.length > 0

  if (isHaveDeliveryOrders) {
    openCantRefuseTheEntireOrderModal()
  } else {
    openRefuseTheEntireOrderModal()
  }
}

const refetchData = async () => {
  await getGeneralOrder()
  emit('refetchOrders')
}

const isShowCancelAllOrdersBtn = computed(() => {
  const completedStatuses = [
    NewGeneralOrderStatus.OrderCompleted,
    NewGeneralOrderStatus.PartialOrderCompleted,
    NewGeneralOrderStatus.OrderNotCompleted,
    NewGeneralOrderStatus.OrderInDelivery,
    NewGeneralOrderStatus.BuyerCancelledOrder,
    NewGeneralOrderStatus.SupplierCancelledOrder,
  ]
  return (
    isInitiator.value &&
    generalOrder.value?.orders?.every(
      (order: any) => !completedStatuses.includes(order.status),
    )
  )
})

const orderInitiator = computed(() => {
  const id = generalOrder.value?.initiator_id ?? ''
  return generalOrder.value?.customer?.users?.find((user) => user.id == id)
})

const editOrderPosition = async (data: any) => {
  await OrderPositionAPI.updatePosition(data).then(() => {
    refetchData()
  })
}

const deleteOrderPosition = async (orderPosition: IOrderPosition) => {
  await OrderPositionAPI.updatePosition({
    id: orderPosition.id,
    is_canceled: true,
  }).then(() => {
    refetchData()
  })
}

const restoreOrderPosition = async (orderPosition: IOrderPosition) => {
  await OrderPositionAPI.updatePosition({
    id: orderPosition.id,
    is_canceled: false,
  }).then(() => {
    refetchData()
  })
}

const is_complex_order = computed(() => {
  return generalOrder.value?.orders?.length > 1
})
const approve_order_by_farmer_modal = ref(false)
const approve_order = async (order_id: number) => {
  await update_order({
    id: order_id,
    status: NewGeneralOrderStatus.New,
  }).then(() => {
    approve_order_by_farmer_modal.value = true
  })
}

const save_order_changes = async (data: any) => {
  await update_order(data).then(() => {
    show_notification('info', 'Изменения сохранены')
  })
}

const is_edit_general_order = ref(false)
const update_general_order = async (data: any) => {
  await GeneralOrderAPI.update(data).then(() => {
    is_edit_general_order.value = false
    show_notification('info', 'Изменения сохранены')
    refetchData()
  })
}

const general_order_price = computed(() => {
  let price = 0
  let previous_price = 0

  generalOrder.value?.orders?.forEach((order) => {
    if (
      (order.status === NewGeneralOrderStatus.New ||
        order.status === NewGeneralOrderStatus.OrderPicking) &&
      order.history[0]?.initiator_type === 'farmer'
    ) {
      price += Number(order.history[0]?.old_entity.price)
      previous_price += Number(order.history[1]?.old_entity.price || 0)
    } else {
      price += Number(order.price)
      previous_price += Number(order.history[0]?.old_entity.price)
    }
  })

  return {
    price,
    previous_price: compare_price_if_equal(previous_price, price),
  }
})



const orderWasEditedByFarmer = computed(() => {
  const order_was_edited =
    generalOrder.value.history[0]?.initiator_type === 'farmer' &&
    generalOrder.value.delivery_date !==
      generalOrder.value.history[0]?.old_entity.delivery_date

  return (
    order_was_edited &&
    generalOrder.value.orders[0].status === NewGeneralOrderStatus.New
  )
})

const conditionForTextPopover = computed(() => {
  return (
    generalOrder?.value.history![0]?.old_entity.delivery_date &&
    generalOrder?.value.history![0]?.old_entity.delivery_date !==
      generalOrder.value.delivery_date &&
    !orderWasEditedByFarmer.value
  )
})
</script>

<template>
  <ModalUpdatePage
    description="Заказ был изменён другим пользователем. Обновите страницу для подгрузки актуальных данных."
    :open="isShowModalUpdatePage"
  />
  <RatingSetModal
    :status="modalOrderStatus"
    :order-completed="modalOrderCompleted"
    :orderId="orderActionId"
    :open="ratingModalOpen"
    @sendData="changeStatusAndRate"
    @close="closeRatingModal"
    @changeOrderStatus="update_order"
    :isOrderStatusChanging="isOrderStatusChanging"
  />
  <div v-if="!isFetching && !openProductCard && !!generalOrder" class="wrapper">
    <CreateHeader
      :title="orderHeader"
      :company="generalOrder?.orders?.[0]?.farmer"
      :farmer_id="generalOrder?.orders?.[0]?.farmer.id"
      @button-click="goBack"
      :is-show-create-chat-button="false"
    />
    <Alert
      message="Работа с заказом доступна только сотруднику, который оформил этот заказ"
      type="info"
      v-if="!isInitiator"
      show-icon
    />
    <div class="order-header-block">
      <div class="order-header-block-header">
        <div class="info-row">
          <div v-if="generalOrder?.orders?.length > 1" class="info-row-item">
            {{ generalOrder?.orders?.length }}
            поставщик{{ declensionProvider(generalOrder.orders.length) }}
          </div>
          <div v-else>
            <CustomTag
              :shadow="false"
              :text="generalOrder?.orders ? generalOrder.orders[0].status : ''"
              :type="
                getStatusType(
                  generalOrder?.orders ? generalOrder.orders[0].status : '',
                )
              "
              :weight="'normal'"
            />
          </div>
          <div class="info-row-item">
            <CalendarIcon fill="#A0AEC0" />
            <TextWithOldPopover
              v-if="conditionForTextPopover"
              :text="
                'к ' + dayjs(generalOrder.delivery_date).format('DD.MM.YYYY')
              "
              :old_text="
                'к ' +
                dayjs(
                  generalOrder?.history![0]?.old_entity.delivery_date,
                ).format('DD.MM.YYYY')
              "
            />

            <template v-else-if="orderWasEditedByFarmer">
              к
              {{
                dayjs(generalOrder.history[0].old_entity.delivery_date).format(
                  'DD.MM.YYYY',
                )
              }}
            </template>

            <template v-else>
              к {{ dayjs(generalOrder.delivery_date).format('DD.MM.YYYY') }}
            </template>
          </div>
          <div class="info-row-item">
            <CartIcon :fill="'#00000073'" />
            <TextWithOldPopover
              v-if="general_order_price.previous_price"
              :text="formatPrice(general_order_price.price)"
              :old_text="formatPrice(general_order_price.previous_price)"
            />
            <template v-else>
              {{ formatPrice(general_order_price.price) }}
            </template>
          </div>
        </div>
        <div class="action-row">
          <Tooltip
            v-if="
              generalOrder?.orders?.some(
                (order) =>
                  order.status === NewGeneralOrderStatus.SupplierModifiedOrder,
              )
            "
          >
            <template #title v-if="is_complex_order">
              Невозможно изменить общие условия в комплексном заказе</template
            >
            <Button
              :disabled="is_complex_order"
              @click="is_edit_general_order = true"
            >
              Редактировать условия
            </Button>
          </Tooltip>

          <Button
            v-if="isShowCancelAllOrdersBtn"
            @click="cancelAllOrdersOpenModal"
          >
            Отменить весь заказ
          </Button>
          <Button class="description-button" @click="toggleOrderDescription">
            <UpOutlined :class="isDescriptionOpened ? '' : 'revert'" />
          </Button>
        </div>
      </div>
      <div v-show="isDescriptionOpened" class="order-header-block-body">
        <div class="column-item">
          <OrderCreatorItem :initiator="orderInitiator" />
        </div>
        <div
          v-if="
            generalOrder?.delivery_address &&
            generalOrder?.orders.some(
              (order) => order.delivery_method === 'Доставка фермером',
            )
          "
          class="column-item"
        >
          <AddressItem
            :address="generalOrder?.delivery_address"
            :old_address="
              generalOrder?.history![0]?.old_entity.delivery_address
            "
          />
        </div>
        <div class="row-item" v-if="generalOrder.comment">
          <CommentItem
            :comment="generalOrder.comment"
            :history="generalOrder?.history![0]?.old_entity"
            :isFromCustomer="true"
          />
        </div>
      </div>
    </div>
    <div class="customer-orders-list">
      <CustomerOrderItem
        v-for="order in generalOrder.orders"
        :isInitiator="isInitiator"
        :key="order.id"
        :deliveryAddress="
          order.delivery_method === 'Самовывоз'
            ? order.shipping_address
            : generalOrder.delivery_address
        "
        :order="order"
        :order-document="userDocument"
        :general_order="generalOrder"
        :is_complex_order="is_complex_order"
        class="order-item"
        @supplier-contract-signed="openSignModal"
        @cancel="openCancelModal(false, order)"
        @open-product="openProduct"
        @upload-documents="uploadDocuments"
        @sign-contract="signContract"
        @open-documents="openOrderDocuments"
        @other-documents="openOtherDocuments"
        @sent-accept-status="orderToStatus"
        @changeOrderStatus="update_order"
        @isChangesConfirm="showChangesConfirmModal"
        @DoneOrder="toggleDoneOrder"
        @partlyDoingOrder="togglePartlyDoingOrder"
        @notDoneOrder="toggleNotDoneOrder"
        @rejectOrder="toggleRejectOrder"
        @rate-canceled="rateCompletedOrder"
        @approveOrder="approve_order"
        @editOrderPosition="editOrderPosition"
        @restoreOrderPosition="restoreOrderPosition"
        @deleteOrderPosition="deleteOrderPosition"
        @save_order_changes="save_order_changes"
        @edit_general_order="is_edit_general_order = true"
      />
    </div>
  </div>

  <Teleport
    v-else-if="!isFetching && openProductCard && generalOrder"
    to="body"
  >
    <ProductCard
      :farm="openedFarm"
      :other-products="otherGroupProducts"
      :product="openProductCard"
      class="product"
      @close="closeFarmCard"
    />
  </Teleport>
  <div v-else class="customer-general-order-loader">
    <FarmLoader :fullscreen="true" />
  </div>

  <!--  &lt;!&ndash; Сайдбар с информацией о поставщике&ndash;&gt;-->
  <!--  <BigSidebar-->
  <!--    :close-icon="true"-->
  <!--    :show="farmerInfoSidebar"-->
  <!--    @close="farmerInfoSidebar = false"-->
  <!--  >-->
  <!--    <template #content>-->
  <!--      <div class="farmer-info-sidebar">-->
  <!--        <h3>Подробнее о поставщике</h3>-->
  <!--        <div v-if="openedFarmerInfo" class="farmer-info-sidebar-body">-->
  <!--          <div class="farmer-main-info-block">-->
  <!--            <h4>Данные организации</h4>-->
  <!--            <div class="farmer-main-info-item">-->
  <!--              <span>Название</span>-->
  <!--              <Space :size="4">-->
  <!--                <PartnerIcon-->
  <!--                  :style="{ color: '#52C41A' }"-->
  <!--                  v-if="openedFarmerInfo.isPartners"-->
  <!--                />-->
  <!--                <p>-->
  <!--                  {{-->
  <!--                    openedFarmerInfo.legal_form + ' ' + openedFarmerInfo.name-->
  <!--                  }}-->
  <!--                </p>-->
  <!--              </Space>-->
  <!--            </div>-->
  <!--            <div class="farmer-main-info-item">-->
  <!--              <span>Рейтинг поставщика</span>-->
  <!--              <SimpleRate :company_rating="openedFarmerInfo.company_rating" />-->
  <!--            </div>-->
  <!--            &lt;!&ndash;            // TODO: Обработать несколько адресов&ndash;&gt;-->
  <!--            <div class="farmer-main-info-item">-->
  <!--              <span>Адрес отгрузки</span>-->
  <!--              <p>{{ openedFarmerInfo?.addresses[0].street }}</p>-->
  <!--            </div>-->
  <!--            <div-->
  <!--              v-if="openedFarmerInfo?.addresses[0].comment"-->
  <!--              class="farmer-main-info-item"-->
  <!--            >-->
  <!--              <span>Комментарий к адресу</span>-->
  <!--              <p>{{ openedFarmerInfo?.addresses[0].comment }}</p>-->
  <!--            </div>-->
  <!--            <div class="farmer-main-info-item">-->
  <!--              <span>Юридический адрес</span>-->
  <!--              <p>{{ openedFarmerInfo.legal_address }}</p>-->
  <!--            </div>-->
  <!--            <div class="farmer-main-info-item">-->
  <!--              <span>Общий телефон</span>-->
  <!--              <p>{{ formatPhoneNumber(openedFarmerInfo.contact_number) }}</p>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="farmer-contact-info-block">-->
  <!--            <h4>Контактные лица</h4>-->
  <!--            <div class="farmer-contact-info-list">-->
  <!--              <template-->
  <!--                v-for="(contact, idx) in openedFarmerInfo.contacts"-->
  <!--                :key="contact.id"-->
  <!--              >-->
  <!--                <div class="farmer-contact-info-item">-->
  <!--                  <p>-->
  <!--                    {{ contact.name }}-->
  <!--                  </p>-->
  <!--                  <div class="additional-info">-->
  <!--                    <span v-if="contact.post">{{ contact.post }}</span>-->
  <!--                    <img-->
  <!--                      v-if="contact.post && contact.phone"-->
  <!--                      alt="dot-icon"-->
  <!--                      src="../../assets/img/dot.svg"-->
  <!--                    />-->
  <!--                    <span v-if="contact.phone">{{-->
  <!--                      formatPhoneNumber(contact.phone)-->
  <!--                    }}</span>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div-->
  <!--                  v-if="idx !== openedFarmerInfo.contacts.length - 1"-->
  <!--                  class="divider"-->
  <!--                />-->
  <!--              </template>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div v-else class="farmer-info-sidebar-loader">-->
  <!--          <FarmLoader />-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </BigSidebar>-->

  <!-- Согласие с изменениями и согласовано с обоих сторон -->
  <CustomModal
    :button-direction="'column'"
    :close-icon="false"
    :only-main-action="true"
    :show="isChangesConfirmWithPartner"
    :type="'primary'"
    :width="'440px'"
    @toggle-modal="closeChangesConfirmWithPartner"
    @main-action="closeChangesConfirmWithPartner"
    :main-text="'Хорошо'"
  >
    <template #header>Заказ согласован с обеих сторон</template>
    <template #description
      >Поставщик может приступать к его сбору. Мы уведомим вас о готовности
      заказа</template
    >
  </CustomModal>

  <!-- Согласие с изменениями и не согласовано с обоих сторон -->
  <CustomModal
    :button-direction="'column'"
    :close-icon="false"
    :only-main-action="true"
    :show="isChangesConfirmWithNoPartner"
    :type="'primary'"
    :width="'440px'"
    @toggle-modal="closeChangesConfirmWithNoPartner"
    @main-action="closeChangesConfirmWithNoPartner"
    :main-text="'Хорошо'"
  >
    <template #header
      >Заказ согласован с обеих сторон, теперь поставщику нужно подписать
      договор</template
    >
    <template #description
      >Для продолжения работы над заказом поставщику необходимо подписать с вами
      договор или подтвердить, что он уже заключен. Мы уже направили ему
      запрос, пожалуйста, дождитесь ответа.</template
    >
  </CustomModal>
  <!-- Заказ выполнен частично  -->
  <!--  <CustomModal-->
  <!--    :button-direction="'column'"-->
  <!--    :close-icon="true"-->
  <!--    :only-main-action="true"-->
  <!--    :show="isPartlyDoingOrder"-->
  <!--    :type="'primary'"-->
  <!--    :width="'480px'"-->
  <!--    @toggle-modal="togglePartlyDoingOrder"-->
  <!--    @main-action="sendPartlyDoingOrder"-->
  <!--    :main-text="'Завершить заказ'"-->
  <!--    :main-btn-data="{-->
  <!--      disabled: isDisablePartlyDoingOrder,-->
  <!--    }"-->
  <!--  >-->
  <!--    <template #header>Заказ выполнен частично</template>-->
  <!--    <template #description-->
  <!--      >При необходимости, вы можете оставить комментарий и прикрепить отгрузочные документы-->
  <!--    </template>-->
  <!--    <template #body>-->
  <!--      <div class="partly-modal-body">-->
  <!--        <div class="partly-modal-body-item">-->
  <!--          <rating-set-modal/>-->
  <!--          <template v-if="!isPartlyOrderDocsUploading">-->
  <!--            <p class="partly-modal-body-title">Отгрузочные документы</p>-->
  <!--            <DocumentUploadListItem-->
  <!--              v-for="(document, index) in uploadedOrderDocuments"-->
  <!--              :key="index"-->
  <!--              :errors="document.errors"-->
  <!--              :index="index"-->
  <!--              :name="document.name"-->
  <!--              :size="document.size"-->
  <!--              document-model="order"-->
  <!--              @delete-document="deleteOrderDocument"-->
  <!--            />-->
  <!--            <FileUploader-->
  <!--              :document-info="'Формат файлов: .pdf, .rtf, .gif, .jpg, .jpeg, .jpe, .png, до 10 мб'"-->
  <!--              :type="uploadedOrderDocuments.length ? 'custom' : ''"-->
  <!--              v-if="uploadedOrderDocuments.length < 10"-->
  <!--              @load-file="uploadOrderDocument"-->
  <!--            >-->
  <!--              <a class="add-document-title" href="#">-->
  <!--                <span class="plus">+</span>-->
  <!--                Добавить документы-->
  <!--              </a>-->
  <!--            </FileUploader>-->
  <!--          </template>-->
  <!--          <FarmLoader v-else />-->
  <!--        </div>-->
  <!--        <div class="partly-modal-body-item">-->
  <!--          <p class="partly-modal-body-title">Комментарий</p>-->
  <!--          <ETextArea-->
  <!--            class="partly-modal-body-comment"-->
  <!--            :style-config="{-->
  <!--              height: '115px',-->
  <!--              fontSize: '14px',-->
  <!--              fontWeight: 400,-->
  <!--              lineHeight: '21px',-->
  <!--            }"-->
  <!--            :data="{-->
  <!--              id: 'partly-modal-body-comment',-->
  <!--              placeholder:-->
  <!--                'Например: поставщик привёз другое количество товаров',-->
  <!--              validators: [maxString(1000)],-->
  <!--            }"-->
  <!--          />-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </CustomModal>-->

  <!-- Отказаться от заказа  -->

  <!--  <CustomModal-->
  <!--    :button-direction="'row'"-->
  <!--    :close-icon="false"-->
  <!--    :only-main-action="false"-->
  <!--    :show="isRejectOrder"-->
  <!--    :type="'danger'"-->
  <!--    :width="'440px'"-->
  <!--    @toggle-modal="toggleRejectOrder"-->
  <!--    @main-action="sendRejectOrder"-->
  <!--    @additional-action="toggleRejectOrder"-->
  <!--    :main-text="'Отказаться'"-->
  <!--    :additional-text="'Закрыть'"-->
  <!--  >-->
  <!--    <template #header>Отказаться от заказа?</template>-->
  <!--    <template #description>Мы уведомим поставщика об отмене заказа</template>-->
  <!--  </CustomModal>-->

  <!-- Заказ не выполнен  -->
  <!--  <CustomModal-->
  <!--    :button-direction="'column'"-->
  <!--    :close-icon="true"-->
  <!--    :only-main-action="true"-->
  <!--    :show="isNotDoneOrder"-->
  <!--    :type="'primary'"-->
  <!--    :width="'480px'"-->
  <!--    @toggle-modal="toggleNotDoneOrder"-->
  <!--    @main-action="sendNotDoneOrder"-->
  <!--    :main-text="'Завершить заказ'"-->
  <!--  >-->
  <!--    <template #header>Заказ не был выполнен</template>-->
  <!--    <template #description-->
  <!--      >При необходимости оставьте комментарий, почему заказ не был-->
  <!--      выполнен</template-->
  <!--    >-->
  <!--    <template #body>-->
  <!--      <div class="not-done-modal-body">-->
  <!--        <div class="not-done-body-item">-->
  <!--          <ETextArea-->
  <!--            class="not-done-body-comment"-->
  <!--            :style-config="{-->
  <!--              height: '115px',-->
  <!--              fontSize: '14px',-->
  <!--              fontWeight: 400,-->
  <!--              lineHeight: '21px',-->
  <!--            }"-->
  <!--            :data="{-->
  <!--              id: 'not-done-body-comment',-->
  <!--              placeholder: 'Например: поставщик привёз не те товары',-->
  <!--              validators: [maxString(1000)],-->
  <!--            }"-->
  <!--          />-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </CustomModal>-->

  <!--  Подписание договора -->
  <Teleport to="body">
    <ModalCloseWindow
      :show="isAcceptModal"
      :show-close-icon="false"
      class="sign-modal"
      @close="toggleAcceptModal"
    >
      <template #body>
        <DocumentOrder
          :description="'Чтобы подтвердить заказ, вам нужно подписать и загрузить договор с поставщиком. Если договор не скачался автоматически, то скачайте его самостоятельно.'"
          :document-filters="[
            ['is_system', 'eq', false],
            ['document_type', 'eq', DocumentTypes.AC],
          ]"
          :download-text="'Скачать и подписать договор'"
          :hide-required-documents="true"
          :order-document="[orderDocument]"
          :required-documents="customerRequiredDocuments"
          :title="'Подпишите и загрузите договор'"
          @sent-documents="sentDocuments"
        />
      </template>
    </ModalCloseWindow>
  </Teleport>

  <!--  Отгрузочные документы -->
  <Teleport to="body">
    <ModalCloseWindow
      :show="isOtherDocumentsModal"
      :show-close-icon="true"
      class="sign-modal"
      @close-icon="toggleOtherDocumentsModal"
    >
      <template #body>
        <DocumentOrder
          :close-button="true"
          :description="'При необходимости загрузите документы, которые поставщик передал вам вместе с товарами. Загрузить документы можно только 1 раз.'"
          :download-text="''"
          :hide-required-documents="false"
          :infinite-load="true"
          :order-document="[orderDocument]"
          :required-documents="customerOtherDocuments"
          :title="'Отгрузочные документы'"
          :without-total-size="true"
          @close="closeOtherDocuments(true)"
          @sent-documents="sentOtherDocuments"
        />
      </template>
    </ModalCloseWindow>
  </Teleport>

  <!--  Модалка отмены заказа -->
  <Teleport to="body">
    <ModalWindow
      :show="isCancelModal"
      :show-close-icon="false"
      class="cancel cancel-modal"
      @close="closeCancelModal"
    >
      <template #header>
        <div class="cancel__block">
          <div class="cancel__title">
            Вы уверены, что хотите отказаться от заказа?
          </div>
          <div class="cancel__title-helper">
            Заказ будет удалён из общего заказа
          </div>
        </div>
      </template>

      <template #footer>
        <!--        // TODO: Глянуть-->
        <EButton
          :style-config="fontSize14"
          class="cancel__button"
          @click="cancelOrder"
          >Отказаться
        </EButton>
        <EButton
          :style-config="{ ...fontSize14, ...withoutBGButton }"
          class="cancel__button"
          @click="closeCancelModal"
          >Отмена
        </EButton>
      </template>
    </ModalWindow>
  </Teleport>

  <Teleport to="body">
    <ModalCloseWindow
      :show="isSetStatusOrderModal"
      :show-close-icon="false"
      @close="toggleSetStatusOrderModal"
    >
      <template #body>
        <SetNewStatusForm @select-status="orderToStatus" />
      </template>
    </ModalCloseWindow>
  </Teleport>
  <Teleport to="body">
    <div v-if="isDocumentLoader" class="generalOrder_loader">
      <StarLoader />
    </div>
    <ModalCloseWindow
      v-else
      :show="isDocumentsModal"
      :show-close-icon="false"
      class="sign-modal"
      @close="toggleDocumentsModal"
    >
      <template #body>
        <div class="page-card">
          <div class="page-card__header">
            {{ openFarmer.legal_form }}
            {{
              openFarmer.name.length > 30
                ? openFarmer.name.slice(0, 30) + '...'
                : openFarmer.name
            }}
          </div>

          <p class="page-card__info">Документы</p>
          <div v-if="!getDocumentsList.length" class="document_loader">
            <star-loader />
          </div>
          <DocumentUploadListItem
            v-for="(document, index) in getDocumentsList"
            :key="document.id"
            :id="document.id"
            :errors="['']"
            :index="index"
            :is-download="true"
            :name="document.file_name"
            document-model="order"
          ></DocumentUploadListItem>
        </div>
      </template>
    </ModalCloseWindow>
  </Teleport>

  <RefuseTheEntireOrderModal
    :open="isOpenRefuseTheEntireOrderModal"
    @closeRefuseTheEntireOrderModal="closeRefuseTheEntireOrderModal"
    @cancelOrderRefuseTheEntireOrderModal="cancelOrderRefuseTheEntireOrderModal"
  />
  <CantRefuseTheEntireOrderModal
    :open="isOpenCantRefuseTheEntireOrderModal"
    @closeCantRefuseTheEntireOrderModal="closeCantRefuseTheEntireOrderModal"
  />

  <CustomerPartnershipModal
    :partnership-id="selectedOrderPartnershipData.id"
    :order-id="selectedOrderPartnershipData.order_id"
    v-model:open="isOpenSignModal"
    :partnershipStatus="selectedOrderPartnershipData.partnershipStatus"
    :signingStatus="selectedOrderPartnershipData.signingStatus"
    :farmer_id="selectedOrderPartnershipData.farmer_id"
    @onSuccess="refetchData"
  />

  <OrderDocumentDrawer
    v-model:open="isOpenDocumentDrawer"
    :orderId="selectedOrderPartnershipData?.order_id"
    :partnershipId="selectedOrderPartnershipData?.id"
    :farmerId="selectedOrderPartnershipData?.farmer_id"
    :customerId="getUserIdTemporary()"
    :subtitle="selectedOrderPartnershipData?.farmer_name"
  />

  <ApproveOrderByFarmer v-model:open="approve_order_by_farmer_modal" />

  <EditGeneralOrderDrawer
    v-model:open="is_edit_general_order"
    @save="update_general_order"
    :general_order="generalOrder"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  width: 100%;

  :deep(.header) {
    margin-bottom: 16px;
  }

  :deep(.header .wrapper .title) {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  :deep(.header .wrapper .title-wrapper) {
    align-items: center;
  }
}

.order-item {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid $gray-400;

  &__delimiter {
    border: 1px solid $gray-400;
    width: 100%;

    &--indent {
      margin: 1.5rem 0;
    }
  }
}

.order-header-block {
  background: #ffffff;
  padding: 16px;
  border: 1px solid $gray-300;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: $shadow-lg;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &-header {
    position: relative;
    height: 32px;
    display: flex;
    justify-content: space-between;

    .info-row {
      display: flex;
      column-gap: 32px;
      align-items: center;
      flex-direction: row;

      &-item {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $gray-800;
        display: flex;
        column-gap: 8px;
        align-items: center;

        svg {
          transform: scale(0.9);
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $gray-600;
        }
      }
    }

    .action-row {
      position: absolute;
      right: 0;
      display: flex;
      column-gap: 12px;

      //noinspection ALL,CssNoGenericFontName
      .description-button {
        padding: 0 10px;

        :deep(.anticon-up) {
          transition: all 0.3s ease;
          &.revert {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &-body {
    margin-top: 16px;
    border-top: 1px solid $gray-300;
    padding-top: 16px;
    gap: 20px 40px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    .column-item {
    }
    .row-item {
      grid-column: span 3;
    }
  }

  &__footer {
    border-top: 1px solid $gray-300;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    line-height: 150%;
    margin-top: 16px;

    .comment-title {
      font-weight: 400;
      color: $gray-600;
    }

    .comment {
      font-weight: 500;
      color: $gray-800;
      word-break: break-word;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    font-size: 16px;
    line-height: 19px;
  }

  &__row-header {
    width: 160px;
    font-weight: 400;
    color: $gray-600;
  }

  &__row-text {
    font-weight: 500;
    color: $gray-800;
    max-width: 300px;
    word-break: break-word;
  }
}

.customer-orders-list {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  height: 100%;
  max-height: 520px;
  margin-bottom: 20px;
  overflow: auto;
}

.customer-general-order-loader {
  width: 100%;
}

.document_loader {
  display: flex;
  justify-content: center;
}

.red-btn {
  color: $danger;
  cursor: pointer;

  &:hover {
    color: $hover-danger;
  }
}

.product {
  margin: 0;
  border-radius: 16px;
  width: 100vw;
  position: absolute;
  top: 69px;
  padding-left: 0;
  padding-right: 0;
}

.confirm,
.cancel {
  :deep(.modal-container) {
    padding: 32px;
    border-radius: 16px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;

    &-helper {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #718096;
      margin: 16px 0;
      text-align: center;
    }
  }
}

.confirm {
  :deep(.modal-container) {
    width: fit-content;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $gray-600;
    margin-bottom: 24px;
    width: 440px;
  }

  :deep(.modal-footer) {
    .egal-button {
      width: 100%;
      justify-content: center;
    }
  }
}

.cancel {
  :deep(.modal-container) {
    width: fit-content;
  }

  &__title {
  }

  :deep(.modal-footer) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .egal-button {
      width: 120px;
      justify-content: center;
    }
  }
}

.helper {
  margin-top: -9px;
  width: 200px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $gray-600;
}

.approve {
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.document_loader {
  display: flex;
  width: 85%;
  justify-content: center;
}

.page-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 540px;
  height: fit-content;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  gap: 1rem;
  text-align: left;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }

  &__info {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #718096;
  }
}

.partly-modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  :deep(.file-uploader) {
    height: 124px;
  }

  :deep(.file-uploader__center-info) {
    gap: 0;
  }

  :deep(.file-uploader__text) {
    margin-top: 8px;
  }

  //:deep(.uploader-document:not(:first-child)) {
  //  margin-top: 8px;
  //}

  &-title {
    @include p5;
    color: $gray-600;
    margin-bottom: 8px;
  }

  &-comment {
    min-height: 115px;

    textarea {
      width: 100%;
      height: 115px;
      box-sizing: border-box;
      resize: none;
    }
  }

  :deep(.file-uploader-custom) {
    margin-top: 20px;
  }

  .add-document-title {
    display: flex;
    text-decoration: none;
    column-gap: 8px;
    align-items: center;
    @include p6;
    color: $primary-accent;

    .plus {
      font-size: 20px;
    }
  }
}

.not-done-body {
  &-comment {
    min-height: 140px;

    textarea {
      width: 100%;
      height: 140px;
      box-sizing: border-box;
      resize: none;
    }

    //noinspection ALL,CssNoGenericFontName
    textarea::placeholder {
      font-weight: 400;
      color: $gray-500;
      line-height: 150%;
      font-size: 14px;
    }
  }
}

.farmer-info-sidebar {
  h3 {
    @include h3;
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    h4 {
      @include h4;
      font-weight: 700;
      color: $gray-800;
    }

    .farmer-main-info-block {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .farmer-main-info-item {
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        span {
          color: $gray-600;
        }

        p {
          color: $gray-800;
        }
      }
    }

    .farmer-contact-info-block {
      .farmer-contact-info-list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .farmer-contact-info-item {
          display: flex;
          flex-direction: column;

          .p {
            @include p5;
            color: $gray-800;
          }

          .additional-info {
            margin-top: 4px;
            display: grid;
            grid-template-columns: auto 4px 130px;
            column-gap: 8px;
            justify-content: start;
            align-items: center;

            span {
              @include p5;
              color: $gray-600;
              word-break: break-word;
            }
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background-color: $gray-300;
          margin: 15px 0;
        }
      }
    }
  }

  &-loader {
    display: flex;
    align-items: center;
    height: 80vh;
  }
}

.price-crossed {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $gray-500;
  text-decoration: line-through;
  position: absolute;
  right: 0;
  top: 100%;
}

.comments-container {
  display: grid;
  gap: 24px;
  .comment {
    &-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      span {
        margin-left: 8px;
      }
    }
    .content {
      margin-top: 8px;
      font-size: 14px;
      line-height: 22px;
      color: #000000e0;
    }
  }
}
</style>
