import { useCookies } from 'vue3-cookies'
import { GetItemsResponse, IUser } from '@/types/interfaces'
import { APIModels, Microservices } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";

const { cookies } = useCookies()

class TokenAPIModel {
  async createInvite(
    email: string,
    companyID: number,
    role: string,
  ): Promise<any> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.Token,
      method: EgalMethods.createInvite,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: { email: email, type: role, company_id: companyID }},
    })
  }

  async checkInvite(token: string): Promise<any> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.Token,
      method: EgalMethods.checkInvite,
      headers: { Authorization: cookies.get('auth')},
      data: { attributes: { token }},
    })
  }

  async getItems(
    page = 1,
    perPage = 10,
    filters: (string | number)[][] = [],
    withs: string[] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return RequestManager.request({
      service: Microservices.Auth,
      model: APIModels.Token,
      method: EgalMethods.GetItems,
      headers: { Authorization: cookies.get('auth')},
      data: { withs, pagination: { per_page: perPage, page: page }, filter: filters},
    })
  }
}

const TokenAPI = new TokenAPIModel()
export { TokenAPI }
