interface IText {
  type:
    | 'text'
    | 'text-bold'
    | 'text-with-end-inner-link'
    | 'text-with-end-action'
  value: string
  link?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  action?: Function
  linkText?: string
}

interface IImage {
  alt: string
  value: string
}

interface IDivider {
  height: string
}

interface ICollapse {
  title: string,
  content: object
}

enum FaqThemes {
  'Настройка обмена с сайтом',
  'Основные настройки',
  'Выгрузка товаров',
  'Настройки каталога',
  'Настройки отбора',
}

const dividerHeights = {
  '50': { height: '50px' },
  '20': { height: '20px' },
  '10': { height: '10px' },
  '5': { height: '5px' },
}

class FaqRenderAPI {
  renderText({ type, value, link, linkText, action }: IText) {
    return {
      type,
      value,
      link,
      linkText,
      action,
    }
  }
  renderDivider({ height }: IDivider) {
    return {
      type: 'divider',
      height,
    }
  }
  renderImg({ alt, value }: IImage) {
    return {
      type: 'image',
      alt,
      value,
    }
  }
  renderCollapse({ title, content }: ICollapse) {
    return {
      type: 'collapse',
      title,
      content
    }
  }
}

const FaqRender = new FaqRenderAPI()

export { FaqRender, dividerHeights, FaqThemes }
