<script setup lang="ts">
import {
  Badge,
  Col,
  Drawer,
  Flex,
  List,
  ListItem,
  Popover,
  Row,
  Space,
  TabPane,
  Tabs,
  TypographyText as TgText,
} from 'ant-design-vue'
import AuctionHeader from '@/views/Auctions/components/AuctionHeader/AuctionHeader.vue'
import { computed, onMounted, ref } from 'vue'
import {
  IAuction,
  IAuctionParticipant,
  IFarmerProduct,
  IAucSocketMessage,
} from '@/types/interfaces'
import { AuctionAPI } from '@/api/AuctionAPI'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import router from '@/router'
import ParticipantStatuses from '@/components/Farmer/Auction/ParticipantStatuses.vue'
import FarmerAuctionInfo from '@/components/Farmer/Auction/FarmerAuctionInfo.vue'
import CustomerNameWithAddress from '@/components/Customer/CustomerNameWithAddress.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import FarmerAuctionBetsByCompanies from '@/components/Farmer/Auction/FarmerAuctionBetsByCompanies.vue'
import FarmerAuctionBetsByProducts from '@/components/Farmer/Auction/FarmerAuctionBetsByProducts.vue'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { useCentrifuge } from '@/composables/useCentrifuge'
import emitter from "@/emitter";

const route = useRoute()

const activeKey = ref('1')
const auctionData = ref<IAuction | null>(null)
const isFetching = ref(false)
const isShowCustomers = ref(false)
const partners = ref([])
const socketData = ref<IAucSocketMessage | null>(null)
const disconnectSocket = ref<(() => void) | null>(null)

const navToList = () => {
  router.go(-1)
}

onMounted(async () => {
  isFetching.value = true
  auctionData.value = await AuctionAPI.getItem(String(route.params.id), [
    'auctionProductRequests.farmerProduct.specialPrices',
    'auctionProductRequests.farmerProduct.priceOptions',
    'auctionParticipants.customer',
    'auctionParticipants.customer.company_rating',
    'auctionProductRequests.auctionProductAnswers.auctionParticipant.customer',
    'auctionProductRequests.auctionProductAnswers.auctionParticipant.customer.company_rating',
    'bestCustomer',
    'mergedRecipients.customer',
  ])
  isFetching.value = false
})

const isShowCustomerDetails = ref(false)
const selectedParticipant = ref<IAuctionParticipant | null>(null)
const showCustomerDetails = (value: IAuctionParticipant) => {
  selectedParticipant.value = value
  isShowCustomerDetails.value = true
}

const customersByStatuses = computed(() => {
  const result: IAuctionParticipant[][] = [[], []]
  const participants =
    socketData.value?.auction_participants ||
    auctionData.value?.merged_recipients
  participants?.forEach((participant) => {
    if (participant.answer_status === 'has_bet') {
      result[0].push(participant)
    } else {
      result[1].push(participant)
    }
  })
  return result
})

const isAllProductsDeleted = computed(() => {
  return auctionData.value?.auction_product_requests?.every(
    (request) => request.is_deleted,
  )
})

const selectedProduct = ref<IFarmerProduct | undefined>(undefined)
const isOpenProductDrawer = ref<boolean>(false)
const setSelectedProduct = (product: IFarmerProduct) => {
  isOpenProductDrawer.value = true
  selectedProduct.value = product
}

const closeProductDrawer = () => {
  isOpenProductDrawer.value = false
}

const refetchAuction = async () => {
  isFetching.value = true
  auctionData.value = await AuctionAPI.getItem(String(route.params.id), [
    'auctionProductRequests.farmerProduct.specialPrices',
    'auctionProductRequests.farmerProduct.priceOptions',
    'auctionParticipants.customer',
    'auctionProductRequests.auctionProductAnswers.auctionParticipant.customer',
    'bestCustomer',
    'mergedRecipients.customer',
  ])
  isFetching.value = false
}

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const connectAuctionSocket = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  const disconnect = startCentrifuge()
  const id = route.params.id
  if (id) {
    addChanel(`core@Auction.${id}`, socketAction)
  }
  disconnectSocket.value = disconnect
}

const socketAction = async (socketMessage: IAucSocketMessage) => {
  socketData.value = socketMessage
}

emitter.on('refresh-token', async (e) => {
  await connectAuctionSocket()
  await getMyPartners()
})

onMounted(async () => {
  await connectAuctionSocket()
  await getMyPartners()
})
</script>

<template>
  <div class="customer-auction-view" v-if="!isFetching">
    <Row :gutter="[0, 0]">
      <Col span="24">
        <AuctionHeader
          :title="`Торг на продажу от ${dayjs(auctionData?.created_at).format('DD.MM.YYYY')}`"
          @onBtnClick="navToList"
          :is-list="false"
          :created-at="dayjs(auctionData?.created_at).format('DD.MM.YYYY')"
          :expiration-date="
            dayjs(auctionData?.expiration_date).format('DD.MM.YYYY, HH:mm')
          "
          :isOpen="auctionData?.status === 'open'"
        />
      </Col>
      <Col span="24" :style="{ marginLeft: '25px', marginRight: '35px' }">
        <Tabs v-model:activeKey="activeKey">
          <TabPane key="1" tab="Информация о торге на продажу">
            <FarmerAuctionInfo
              :auctionData="auctionData"
              @setSelectedProduct="setSelectedProduct"
            />
          </TabPane>
          <TabPane key="2" :disabled="isAllProductsDeleted">
            <template #tab>
              <Popover
                v-if="isAllProductsDeleted"
                :overlayInnerStyle="{ width: '320px' }"
                trigger="hover"
                placement="bottomLeft"
              >
                <template #title>Просмотр ставок недоступен</template>
                <template #content>
                  Все товары были удалены из торга на продажу
                </template>
                Ставки по компаниям
              </Popover>
              <span v-else> Ставки по компаниям </span>
            </template>
            <FarmerAuctionBetsByCompanies
              :auctionData="auctionData"
              @showCustomerDetails="showCustomerDetails"
              @setSelectedProduct="setSelectedProduct"
          /></TabPane>
          <TabPane key="3" :disabled="isAllProductsDeleted">
            <template #tab>
              <Popover
                v-if="isAllProductsDeleted"
                :overlayInnerStyle="{ width: '320px' }"
                trigger="hover"
                placement="bottomLeft"
              >
                <template #title>Просмотр ставок недоступен</template>
                <template #content>
                  Все товары были удалены из торга на продажу
                </template>
                Ставки по товарам
              </Popover>
              <span v-else> Ставки по товарам </span>
            </template>
            <FarmerAuctionBetsByProducts
              :auctionData="auctionData"
              @showCustomerDetails="showCustomerDetails"
              @setSelectedProduct="setSelectedProduct"
            />
          </TabPane>
          <template #rightExtra>
            <ParticipantStatuses
              :isPublic="auctionData?.is_public ?? false"
              :participants="auctionData?.merged_recipients ?? []"
              @onBtnClick="isShowCustomers = true"
              :socketData="socketData"
            />
          </template>
        </Tabs>
      </Col>
    </Row>
  </div>
  <div class="loader-wrapper" v-else>
    <FullscreenLoading :type="'secondary'" />
  </div>
  <Drawer
    v-model:open="isShowCustomerDetails"
    :width="500"
    :bodyStyle="{ padding: '24px 32px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    title="Комментарий / документы / данные компании"
    class="drawer-reverse-header"
  >
    <Row :gutter="[0, 24]">
      <Col :span="24">
        <Space direction="vertical" :size="8">
          <span class="drawer-item-title">Покупатель</span>
          <CustomerNameWithAddress
            :customer="selectedParticipant?.customer ?? null"
          />
        </Space>
      </Col>
      <Col :span="24">
        <Space direction="vertical" :size="8">
          <span class="drawer-item-title">Комментарий</span>
          <TgText>
            {{ selectedParticipant?.comment }}
          </TgText>
        </Space>
      </Col>
    </Row>
  </Drawer>
  <Drawer
    v-model:open="isShowCustomers"
    :width="500"
    :bodyStyle="{ padding: '24px 0' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    title="Все покупатели"
    class="drawer-reverse-header"
  >
    <Row :gutter="[0, 24]">
      <Col span="24" v-if="customersByStatuses[0].length">
        <span class="section-title">Сделали ставку</span>
        <List
          :data-source="customersByStatuses[0]"
          rowKey="id"
          size="large"
          class="farmers-list"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Flex :gap="4">
                <Badge status="success" />
                <CustomerNameWithAddress
                  :customer="item.customer"
                  :is-partner="
                    partners.find(
                      (el: any) => el.company_id === item.customer?.id,
                    )
                  "
                />
              </Flex>
            </ListItem>
          </template>
        </List>
      </Col>
      <Col span="24" v-if="customersByStatuses[1].length">
        <span class="section-title">Не сделали ставку</span>
        <List
          :data-source="customersByStatuses[1]"
          rowKey="id"
          size="large"
          class="farmers-list"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Flex :gap="4">
                <Badge status="warning" />
                <CustomerNameWithAddress :customer="item.customer" />
              </Flex>
            </ListItem>
          </template>
        </List>
      </Col>
    </Row>
  </Drawer>
  <DrawerForm
    :product="selectedProduct"
    @editProduct="router.push({ path: '/edit-product/' + selectedProduct?.id })"
    @editSpecialPrices="
      router.push({ path: '/edit-special-prices/' + selectedProduct?.id })
    "
    @editPriceOptions="
      router.push({ path: '/edit-price-options/' + selectedProduct?.id })
    "
    @closeDrawer="closeProductDrawer"
    :isFarmer="true"
    @onSuccess="refetchAuction"
    :selfDeleting="true"
    :open="isOpenProductDrawer"
  />
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.customer-auction-view {
  @include wrapper();
  padding: 24px 0;
}

.drawer-item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
}

span.section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
  margin-left: 32px;
}
.farmers-list {
  :deep(.ant-list .ant-list-item) {
    padding: 16px 32px;
  }
}

.loader-wrapper {
  position: relative;
  height: 70vh;
}
</style>
