<script lang="ts" setup>
import { Button, Flex } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  show: boolean
  title?: string
  message?: string
  callback?: () => void
}

const props = defineProps<IProps>()
const emit = defineEmits(['close'])

const handleCloseModal = () => {
  props.callback && props.callback()
  emit('close')
}
</script>

<template>
  <ModalWindow class="error-modal" :show="show" @close="handleCloseModal">
    <template v-if="title" #header>{{ title }}</template>
    <template #body>
      <div class="modal-body" v-if="message">
        <span v-if="message" v-html="message" />
      </div>
      <Flex justify="flex-end" gap="16" class="buttons">
        <Button type="primary" @click="handleCloseModal">Понятно</Button>
      </Flex>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';

.error-modal {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 20px 24px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $gray-800;
    }
    .modal-body {
      margin-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: $gray-600;
    }
    .buttons {
      margin-top: 20px;
    }
  }
}
</style>
