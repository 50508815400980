<script lang="ts" setup>
import { computed, h, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import router from '@/router'
import { ProductCategoryAPI } from '@/api/ProductCategoryAPI'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import {
  IFarmerProduct,
  IParameter,
  IProduct,
  IProductCategory,
} from '@/types/interfaces'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Form,
  FormInstance,
  FormItem,
  InputNumber,
  Input,
  notification,
  Popover,
  Row,
  Select,
  SelectProps,
  Space,
  Textarea,
  TypographyText as TgText,
  TypographyTitle as TgTitle,
  Modal,
} from 'ant-design-vue'
import {
  capitilizeFirstLetter,
  splitProductParameters,
  priceWithoutVat,
} from '@/helpers/scripts'
import { ProductAPI } from '@/api/ProductAPI'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import AddProductFooter from '@/components/AddProduct/AddProductFooter.vue'
import {
  CloseOutlined,
  InfoCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons-vue'
import { width100 } from '@/model/constants'
import UploadImage from '@/components/AddProduct/UploadImage.vue'
import { FarmerAPI } from '@/api/FarmerAPI'
import { CustomerAPI } from '@/api/CustomerAPI'
import OverflowLoader from '@/components/Loaders/OverflowLoader.vue'
import {
  TParameterValue,
  TSetMeasurementUnitId,
  TSetFishParameters,
  TSetNotRequiredFields,
} from '@/views/Farmer/helpers/AddProductViewHelpers'
import { Roles } from '@/types/enums'
import { useCookies } from 'vue3-cookies'
import { useRedirectAddProductStore } from '@/store/useRedirectAddProductStore'
import { useRoute } from 'vue-router'

interface IFarmerProductForm {
  farmer_id: number
  farmer_product?: IFarmerProduct
  image_body: string
  image_name: string
  image_url: string
  measurement_unit_id: string
  min_value: string
  prepare_days: string
  price: string
  product_id: number
  quantity: string
  title: string
  vat_percent: number
  comment: string
}

const binaryToBool = {
  true: 'да',
  false: 'нет',
}
const minQuantityDictionary = {
  шт: 1,
  кг: 0.01,
}

const { getCoreCookie } = useJWTDecode()

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const company_id = user?.company_id

const alreadyCreated = ref([])
const openModal = ref(false)
const childCount = ref(0)
const isVerification = ref(false)
const userData = ref()
const isFetching = ref(false)
const step = ref(1)
const categoryOptions = ref<SelectProps['options']>([])
const selectedCategory = ref()
const categoryChildrens = ref<any[]>([])
const farmerProductsFormRef = ref<FormInstance>()
const formRef = ref<FormInstance>()
const categoryVat = ref<null | number>(null)
const withVat = ref(false)
const measurementUnitIdForStuff = ref<null | string>(null)
const farmerProductForm = reactive<{ products: IFarmerProductForm[] }>({
  products: [],
})
const { setIsRedirected, product, isRedirected } = useRedirectAddProductStore()

const { cookies } = useCookies()

const isFarmer = computed(() => getCoreCookie()?.company_type === 'farmer')

const isCustomer = computed(
  () => getCoreCookie()?.company_type === Roles.Customer,
)

const formParameters = reactive<{ parameters: any[] }>({
  parameters: [],
})

const productParameters = ref<Record<string, any[]>>({
  single: [],
  binary: [],
  multiple: [],
  string: [],
})

const resetProductParameters = () => {
  productParameters.value = {
    single: [],
    binary: [],
    multiple: [],
    string: [],
  }
}

const formItemWidth = computed(() => {
  if (withVat.value && isVerification.value) {
    return '20%'
  }
  return '25%'
})

const handleCategory = (value: string, option: any) => {
  selectedCategory.value = value
  categoryVat.value = option.entity.vat
  categoryChildrens.value = [
    {
      selectedEntity: {
        id: '',
      },
      id: option.entity.id,
      options: option.entity.all_children_product_categories.map(
        (item: IProductCategory) => ({
          label: item.name,
          value: item.id,
          entity: item,
        }),
      ),
    },
  ]
  const options = categoryChildrens.value[0].options
  if (options.length == 1) {
    const childCategory = options[0]
    handleCategoryChildren(childCategory.value, childCategory, 0)
  }
  childCount.value = options.length
}

const setNotRequiredFields = ({ parameter }: TSetNotRequiredFields) => {
  if (parameter.label === 'Фасовка') {
    parameter.isRequired = false
  } else {
    parameter.isRequired = true
  }
}

const sortByAlphabet = (array: any[]) => {
  return array.sort((a, b) => {
    if (a.label === 'ПРОЧЕЕ') return 1
    if (b.label === 'ПРОЧЕЕ') return -1
    return a.label.localeCompare(b.label)
  })
}

const renameCategoryLabel = (itemName: string) => {
  if (itemName === 'Разное') return 'Другая категория'
  else return itemName
}

const handleCategoryChildren = (value: string, option: any, index: number) => {
  categoryChildrens.value[index].selectedEntity = option.entity
  if (option.entity.all_children_product_categories.length) {
    categoryChildrens.value[index + 1] = {
      selectedEntity: {
        id: '',
      },
      id: option.entity.id,
      options: sortByAlphabet(
        option.entity.all_children_product_categories.map(
          (item: IProductCategory) => ({
            label: item.name,
            value: item.id,
            entity: item,
          }),
        ),
      ),
    }
  } else {
    categoryChildrens.value.splice(index + 1)
  }
}

onMounted(async () => {
  if (!isFarmer.value) {
    await router.push('/')
  }
  const userDataStr = localStorage.getItem('userData')
  userData.value = userDataStr ? JSON.parse(userDataStr) : ''
  await ProductCategoryAPI.getItems([]).then((res) => {
    categoryOptions.value = sortByAlphabet(
      res.map((item: IProductCategory) => ({
        label: renameCategoryLabel(item.name),
        value: item.id,
        entity: item,
      })),
    )
  })
  await getVatInfo()
})

const getVatInfo = async () => {
  if (company_id) {
    if (isFarmer.value) {
      await FarmerAPI.getFarmer(company_id).then((res) => {
        isVerification.value = !!res.is_active
        withVat.value = res.vat
      })
    } else if (isCustomer.value) {
      await CustomerAPI.getItem(company_id).then((res) => {
        isVerification.value = !!res.is_active
        withVat.value = res.vat
      })
    }
  }
}

const isShowParameters = computed(() => {
  return (
    categoryChildrens.value.at(-1)?.selectedEntity
      .all_children_product_categories?.length === 0
  )
})

const isProductWithoutParameters = computed(() => {
  const lastCategory = categoryChildrens.value.at(-1)?.selectedEntity
  if (!lastCategory?.id) {
    return false
  }
  return (
    !lastCategory.all_children_product_categories.length &&
    !lastCategory.parameters.length
  )
})

const updateParams = () => {
  categoryChildrens.value
    .at(-1)
    ?.selectedEntity?.parameters?.forEach((item: IParameter) => {
      if (item.type !== 'string') {
        productParameters.value[item.type].push({
          ...item,
          options: splitProductParameters(item).map((param) => ({
            label: capitilizeFirstLetter(param),
            value: param,
          })),
        })
      } else {
        productParameters.value['string'].push({
          ...item,
          label: item.name,
          value: '',
        })
      }
    })
  formParameters.parameters = [getBlankParameter()]
}

const handleRelatedParameters = (
  value: string | string[] | boolean | number,
  goalParameter: IParameter,
  formIndex: number,
  binary?: any,
) => {
  Object.values(productParameters.value)
    .flat()
    .forEach((parameter: IParameter) => {
      const fishCategory = categoryOptions?.value?.find(
        (el) => el.value === selectedCategory.value,
      )
      if (parameter.parameter_id === goalParameter.id) {
        switch (goalParameter.type) {
          case 'string':
            break
          case 'binary':
            if (
              binaryToBool[value as string] !==
              parameter.related_rule?.replace(/^\/\^|\$\/[gm]/g, '')
            ) {
              formParameters.parameters[formIndex].disabledField.push(
                parameter.name,
              )
              if (parameter.name === 'фасовка') {
                formParameters.parameters[formIndex]['фасовка'] = ''
                formRef.value?.clearValidate('фасовка')
              }
            } else {
              formParameters.parameters[formIndex].disabledField =
                formParameters.parameters[formIndex].disabledField.filter(
                  (item: string) => item !== parameter.name,
                )
              if (parameter.name === 'фасовка') {
                formParameters.parameters[formIndex]['фасовка'] = ''
                formRef.value?.clearValidate('фасовка')
              }
            }
            break
          case 'single':
            setFishParameters({
              goalParameter,
              fishCategory,
              formIndex,
              binary,
              formParameters,
            })
            if (
              !parameter.related_rule
                ?.replace(/^\/\^|\$\/[gm]/g, '')
                .split('|')
                .includes(value as string)
            ) {
              formParameters.parameters[formIndex].disabledField.push(
                parameter.name,
              )
            } else {
              formParameters.parameters[formIndex].disabledField =
                formParameters.parameters[formIndex].disabledField.filter(
                  (item: string) => item !== parameter.name,
                )
            }
            break
          case 'multiple':
            break
          default:
            break
        }
      }
    })
  resetParameterError(formIndex)
}

const validate1Step = async () => {
  isFetching.value = true
  if (isProductWithoutParameters.value) {
    removeDisabledFields([])
    const result = await checkLastProduct([])
    isFetching.value = false
    return result
  }
  return await formRef.value
    ?.validate()
    .then(async (res) => {
      removeDisabledFields(res.parameters ?? [])
      const result = await checkLastProduct(res.parameters ?? [])
      isFetching.value = false
      return result
    })
    .catch((_) => {
      isFetching.value = false
      return false
    })
}

const validate2Step = async () => {
  isFetching.value = true
  return await farmerProductsFormRef.value
    ?.validate()
    .then(async () => {
      return true
    })
    .catch((_) => {
      isFetching.value = false
      return false
    })
}

const checkLastProduct = async (parameters: any) => {
  const lastParameter = parameters.at(-1)
  const formParameter = formParameters.parameters.at(-1)
  if (!checkIsNotCreated(parameters, lastParameter)) {
    formParameter.isAlreadyCreatingError = true
    return false
  }
  if (
    !(await checkIsNotInCatalog(parameters, lastParameter, formParameter).then(
      (res) => res,
    ))
  ) {
    formParameter.isInCatalogError = true
    return false
  }
  return true
}

const checkIsNotCreated = (parameters: any[], inputParameterItem: any) => {
  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return false
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    return sortedArr1.every((value, index) => value === sortedArr2[index])
  }

  function areObjectsEqual(
    obj1: Record<string, any>,
    obj2: Record<string, any>,
  ) {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          if (!areArraysEqual(obj1[key], obj2[key])) return false
        } else {
          return false
        }
      }
    }
    return true
  }
  let isFailed = true
  for (let i = 0; i < parameters.length - 1; i++) {
    if (areObjectsEqual(inputParameterItem, parameters[i])) {
      isFailed = false
    }
  }
  return isFailed
}

const checkIsNotInCatalog = async (
  parameters: any[],
  lastParameter: any | undefined,
  formParameter: Record<string, any> | undefined,
) => {
  const categoryEntity = categoryOptions.value?.find(
    (item) => item.value === selectedCategory.value,
  )
  const parametersArray = []
  if (lastParameter) {
    for (const [parameterName, parameterValue] of Object.entries(
      lastParameter,
    )) {
      if (typeof parameterValue === 'boolean' && parameterValue) {
        parametersArray.push(parameterName)
      } else if (Array.isArray(parameterValue)) {
        parameterValue.forEach((item) => parametersArray.push(item))
      } else if (typeof parameterValue == 'string') {
        parametersArray.push(parameterValue)
      }
    }
  }
  const firstPart =
    categoryEntity?.entity.name +
    ' ' +
    categoryChildrens.value.map((item) => item.selectedEntity.name).join(' ')
  const secondPart = parametersArray.join('|')

  return await checkProduct(
    [firstPart, 'product', secondPart],
    lastParameter,
    formParameter,
  ).then((res) => res)
}

const checkProduct = async (
  filterPart: string[],
  parameterItem: Record<string, any>,
  formParameter: Record<string, any>,
) => {
  await ProductAPI.getProduct([filterPart]).then(async (res) => {
    if (res.length) {
      formParameter.product = res[0]
    } else {
      await createProduct(parameterItem, formParameter)
    }
  })
  return await FarmerProductAPI.getProduct(
    [
      ['farmer_id', 'eq', Number(userData.value.id)],
      'AND',
      ['product_id', 'eq', formParameter.product.id],
    ],
    1,
    [],
  ).then((res) => {
    if (!res.length) return true
    if (res[0].is_deleted) {
      formParameter.farmer_product = res[0]
      return true
    }
    return false
  })
}

const createProduct = async (
  parameterItem: Record<string, any>,
  formParameter: Record<string, any>,
) => {
  const product_category_id = categoryChildrens.value.at(-1).selectedEntity.id
  let parametersInObject: Record<string, any> = {}
  if (parameterItem) {
    for (const [parameterName, parameterValue] of Object.entries(
      parameterItem,
    )) {
      if (typeof parameterValue === 'boolean') {
        parametersInObject[parameterName] = binaryToBool[parameterValue]
      } else if (Array.isArray(parameterValue)) {
        parametersInObject[parameterName] = [...parameterValue]
      } else if (typeof parameterValue == 'string') {
        parametersInObject[parameterName] = parameterValue
      }
    }
  }
  const params = JSON.stringify(parametersInObject)
  await ProductAPI.createProduct({
    params,
    product_category_id,
  }).then((res) => {
    formParameter.product = res
  })
}

const resetParameterError = (index: number) => {
  if (
    !formParameters.parameters[index].isAlreadyCreatingError &&
    !formParameters.parameters[index].isInCatalogError
  ) {
    return
  }
  formParameters.parameters[index].isAlreadyCreatingError = false
  formParameters.parameters[index].isInCatalogError = false
}

const lastParametersId = computed(() => {
  return formParameters.parameters.at(-1)?.id
})

const getRowParameters = (parameterItem: Record<string, any>) => {
  const parametersString: string[] = []
  Object.values(productParameters.value)
    .flat()
    .forEach((parameter) => {
      setNotRequiredFields({ parameter })
      switch (parameter.type) {
        case 'string':
          !parameterItem.disabledField.includes(parameter.name) &&
            parametersString.push(
              capitilizeFirstLetter(parameterItem[parameter.name]),
            )
          break
        case 'binary':
          if (
            parameterItem[parameter.name] &&
            !parameterItem.disabledField.includes(parameter.name)
          ) {
            parametersString.push(capitilizeFirstLetter(parameter.name))
          }
          break
        case 'single':
          !parameterItem.disabledField.includes(parameter.name) &&
            parametersString.push(
              capitilizeFirstLetter(parameterItem[parameter.name]),
            )
          setMeasurementUnitId({
            parameter,
            parameterItem,
            measurementUnitIdForStuff,
          })
          break
        case 'multiple':
          !parameterItem.disabledField.includes(parameter.name) &&
            parametersString.push(
              ...parameterItem[parameter.name].map((item: string) =>
                capitilizeFirstLetter(item),
              ),
            )
          break
        default:
          break
      }
    })
  const firstParam = parametersString.shift() as string
  parametersString.push(firstParam)
  return parametersString.join(' ')
}

const increaseStep = async () => {
  if (step.value === 1 && (await validate1Step())) {
    step.value = step.value + 1
  } else if (step.value === 2 && (await validate2Step())) {
    await checkCreated()
    if (alreadyCreated.value.length == 0) {
      await notificationPush()
    }
  }
}

const notificationPush = async () => {
  await createFarmerProducts()
    .then(() => {
      isFetching.value = false
      notification.info({
        message: `${
          farmerProductForm.products.length > 1
            ? 'Товары добавлены в каталог'
            : 'Товар добавлен в каталог'
        }`,
        description: `${
          farmerProductForm.products.length > 1
            ? 'Вы можете указать для них дополнительные цены'
            : 'Вы можете указать для него дополнительную цену'
        }`,
        top: '80px',
        icon: h(InfoCircleFilled, {
          style: { color: 'rgba(22, 119, 255, 1)' },
        }),
      })
      router.push('/products')
    })
    .catch(() => {
      isFetching.value = false
    })
}

const checkCreated = async () => {
  alreadyCreated.value = []
  let farmerProducts
  await FarmerProductAPI.getProductsWithoutWrapper([], 1, 100).then((res) => {
    farmerProducts = res.items
  })

  // Проверяем, есть ли существующие товары
  let ids = []
  formParameters.parameters.forEach((item) => {
    let IDCreating = item.product.id
    ids.push(IDCreating)
  })

  await FarmerProductAPI.getProductsWithoutWrapper(
    [['product_id', 'in', ids]],
    1,
    100,
  ).then((res) => {
    alreadyCreated.value = res.items
  })

  let newSend = []
  if (alreadyCreated.value.length != 0) {
    openModal.value = true
    // делаем чистый массив с новыми товарами
    farmerProductForm.products.forEach((item) => {
      let IDItem = item.product_id
      let addingFlag = true
      alreadyCreated.value.forEach((product) => {
        let IDCreated = product.product.id
        if (IDCreated == IDItem) {
          addingFlag = false
        }
      })
      if (addingFlag) {
        newSend.push(item)
      }
    })
    farmerProductForm.products = newSend
  }
}

const modalTitle = computed(() => {
  if (farmerProductForm.products.length == 0) return 'Обновите страницу'
  else if (alreadyCreated.value.length > 1)
    return 'Некоторые товары были созданы другим пользователем'
  else return 'Товар уже был создан другим пользователем'
})

const modalDescription = computed(() => {
  if (farmerProductForm.products.length == 0)
    return 'Товары были созданы другим пользователем. Обновите страницу для подгрузки актуальных данных.'
  else if (alreadyCreated.value.length > 1)
    return 'Они не будут созданы повторно'
  else return 'Этот товар не будет создан повторно'
})

const newSend = () => {
  if (farmerProductForm?.products?.length == 0) {
    router.push('/products')
  } else {
    notificationPush()
  }
}

const decreaseStep = async () => {
  if (step.value === 1 && (await validate1Step())) {
    formParameters.parameters.push(getBlankParameter())
  } else if (step?.value === 2) {
    step.value = step?.value - 1
  }
}

const categoryFullName = computed(() => {
  return categoryChildrens.value
    .map((item) => item.selectedEntity.name)
    .join(' ')
})

onMounted(() => {
  if (isRedirected) {
    step.value = 2

    categoryVat.value = product?.product_category?.vat
    formParameters.parameters = [
      {
        product: {
          id: product.id,
        },
      },
    ]
  }
})

onUnmounted(() => {
  setIsRedirected(false)
})

watch(step, (value) => {
  if (value === 2) {
    farmerProductForm.products = formParameters.parameters.map((item) => {
      const filledProduct = farmerProductForm.products.find(
        (i) => i.product_id === item.product.id,
      )
      if (isRedirected) {
        const value = {
          title: product.fullName,
          quantity: '',
          min_value: '',
          prepare_days: '',
          price: '',
          vat_percent: categoryVat.value ?? 0,
          farmer_id: company_id,
          product_id: product.id,
          measurement_unit_id: product?.measurement_unit_id,
          comment: '',
          image_body: '',
          image_name: '',
          image_url: '',
        }

        return value
      }
      if (filledProduct) {
        return filledProduct
      }
      const value = {
        title: categoryFullName.value + ' ' + getRowParameters(item),
        quantity: '',
        min_value: '',
        prepare_days: '',
        price: '',
        vat_percent: 0,
        farmer_id: company_id,
        farmer_product: item.farmer_product,
        product_id: item.product.id,
        measurement_unit_id:
          item.product.measurement_unit_id || measurementUnitIdForStuff.value,
        comment: '',
        image_body: '',
        image_name: '',
        image_url: '',
      }
      if (withVat.value || !isVerification.value) {
        value.vat_percent = categoryVat.value ?? 0
      }
      return value
    })
  }
})

const createFarmerProducts = async () => {
  const updatingPromises = farmerProductForm.products
    .slice()
    .filter((product) => product.farmer_product)
    .map(
      (product) => () =>
        FarmerProductAPI.update({
          is_deleted: false,
          id: product.farmer_product.id,
          price: product.price,
          min_value: product.min_value,
          quantity: product.quantity,
          prepare_days: product.prepare_days || 0,
          vat_percent:
            withVat.value || !isVerification.value ? product.vat_percent : 0,
          comment: product.comment,
          image_body: product.image_body ? product.image_body : 'NULL',
          image_name: product.image_name ? product.image_name : 'NULL.png',
          image_url: product.image_url ? product.image_url : 'NULL',
        }),
    )
  const creatingPromises = farmerProductForm.products
    .slice()
    .filter((product) => !product.farmer_product)
    .map(
      (product) => () =>
        FarmerProductAPI.createProduct({
          product_id: product.product_id,
          price: product.price,
          min_value: product.min_value,
          quantity: product.quantity,
          prepare_days: product.prepare_days || 0,
          vat_percent:
            withVat.value || !isVerification.value ? product.vat_percent : 0,
          comment: product.comment,
          image_body: product.image_body,
          image_name: product.image_name,
          image_url: product.image_url,
          farmer_id: Number(userData.value.id),
          measurement_unit_id:
              String(product.measurement_unit_id)?.toLowerCase() || String(measurementUnitIdForStuff.value)?.toLocaleLowerCase(),
        }),
    )

  await Promise.all(
    updatingPromises.concat(creatingPromises).map((promise) => promise()),
  )
}

const backBtnText = {
  1: 'Добавить еще',
  2: 'Назад',
}
const nextBtnText = {
  1: 'Продолжить',
  2: 'Сохранить и добавить в каталог',
}

const onQuantityBlur = (index: number) => {
  farmerProductsFormRef.value?.validate([['products', index, 'min_value']])
}

const onVatPercentBlur = (index: number, value: number) => {
  if (!value) {
    farmerProductForm.products[index].vat_percent = 0
  }
}

const onPriceBlur = (index: number, value: number) => {
  if (!value) {
    farmerProductForm.products[index].price = 0
  }
  farmerProductsFormRef.value?.validate([['products', index, 'price']])
}

const saveImage = (imageData: any, index: number) => {
  farmerProductForm.products[index].image_url =
    imageData.image_body.split(',')[1]
  farmerProductForm.products[index].image_body =
    imageData.image_body.split(',')[1]
  farmerProductForm.products[index].image_name = imageData.image_name
}

const getBlankParameter = () => {
  const parametersObject: Record<string, TParameterValue> = {}
  Object.values(productParameters.value)
    .flat()
    .forEach((parameter) => {
      switch (parameter.type) {
        case 'single':
          parametersObject[parameter.name] = ''
          break
        case 'string':
          break
        case 'binary':
          parametersObject[parameter.name] = false
          break
        case 'multiple':
          parametersObject[parameter.name] = []
          break
        default:
          break
      }
    })
  parametersObject.id = formParameters.parameters.length + 1
  parametersObject.disabledField = []
  parametersObject.isInCatalogError = false
  parametersObject.isAlreadyCreatingError = false
  parametersObject.farmerProduct = null
  return parametersObject
}

const removeDisabledFields = (parameters: Record<string, any>[]) => {
  Object.values(formParameters.parameters)
    .flat()
    .forEach((parameter: IParameter, index) => {
      if (parameter.disabledField.length) {
        parameter.disabledField.forEach((item: string) => {
          delete parameters[index][item]
        })
      }
    })
}

const deleteFormParameter = (index: number) => {
  formParameters?.parameters?.forEach((param, i) => {
    if (i >= index) {
      param.id -= 1
    }
  })
  formParameters?.parameters?.splice(index, 1)
}

const backToTable = (step: any) => {
  if (step?.value === 2) {
    step.value = step?.value - 1
  } else {
    router.push('/products').then()
  }
}

const setMeasurementUnitId = ({
  parameter,
  parameterItem,
  measurementUnitIdForStuff,
}: TSetMeasurementUnitId) => {
  if (parameter.name === 'единица измерения') {
    measurementUnitIdForStuff.value = parameterItem[parameter.name]
  }
}

const setFishParameters = ({
  goalParameter,
  fishCategory,
  formIndex,
  binary,
  formParameters,
}: TSetFishParameters) => {
  if (goalParameter.name === 'охлаждение' && fishCategory) {
    // Блокировка полей для рыбы
    formParameters.parameters[formIndex].disabledField =
      formParameters.parameters[formIndex].disabledField.filter(
        (el: string) => el !== 'потрошёная' && el !== 'без головы',
      )
    if (formParameters?.parameters[formIndex]['охлаждение'] === 'живая') {
      const guttedFish = binary.find((item: any) => item.name === 'потрошёная')
      const fishWithoutHead = binary.find(
        (item: any) => item.name === 'без головы',
      )
      formParameters.parameters[formIndex].disabledField.push(guttedFish?.name)
      formParameters.parameters[formIndex].disabledField.push(
        fishWithoutHead?.name,
      )
    }
  }
}

const calcStrMaxValid = async (name: string, parameter: any) => {
  if (parameter.disabledField.includes(name)) return undefined
  if (name === 'фасовка') return 5
  else return 255
}

const calcStrMinValid = async (name: string, parameter: any) => {
  if (parameter.disabledField.includes(name)) return undefined
  if (name === 'фасовка') return 1
  else return 1
}

const calcStrMinValidMessage = (name: string) => {
  if (name === 'фасовка') return 'Минимум 1 символ'
  return 'Минимум 1 символ'
}

const calcStrMaxValidMessage = (name: string) => {
  if (name === 'фасовка') return 'Максимум 5 символов'
  return 'Максимум 255 символов'
}

const strValidator = async (name: string, parameter: any, value: string) => {
  const maxLength = await calcStrMaxValid(name, parameter)
  const minLength = await calcStrMinValid(name, parameter)

  if (!parameter.disabledField.includes(name)) {
    if (Number(value) < 0) return Promise.reject(`Поле не может быть меньше 0`)

    if (!value || Number(value) === 0 || value === '0') {
      return Promise.reject(`Поле не может быть пустым`)
    }

    if (name === 'фасовка' && ['.', ','].includes(value)) {
      const decimalPart = value.split('.')[1] || value.split(',')[1]
      if (decimalPart.length > 3) {
        return Promise.reject(`Максимум 3 символа после запятой`)
      }
    }

    if (minLength && value?.length < minLength) {
      return Promise.reject(`${calcStrMinValidMessage(name)}`)
    }

    if (maxLength && value?.length > maxLength) {
      return Promise.reject(`${calcStrMaxValidMessage(name)}`)
    }
  } else if (parameter.disabledField.includes(name)) return Promise.resolve()
  else return Promise.resolve()
}

const route = useRoute()
const calcRoute = computed(() => route)

watch(
  isShowParameters,
  (newValue) => {
    resetProductParameters()
    formParameters.parameters = []
    if (newValue) {
      updateParams()
    }
  },
  {
    deep: true,
  },
)

watch(
  () => selectedCategory,
  (newValue) => {
    resetProductParameters()
    formParameters.parameters = []
    if (newValue) {
      updateParams()
    }
  },
  {
    deep: true,
  },
)

watch(
  () => categoryChildrens,
  (newValue) => {
    resetProductParameters()
    formParameters.parameters = []
    if (newValue) {
      updateParams()
    }
  },
  {
    deep: true,
  },
)

// const params = computed(() => getRowParameters(formParameters?.parameters?.[0])?.length)
</script>

<template>
  <Modal
    centered
    width="440px"
    :title="modalTitle"
    :afterClose="newSend"
    v-model:open="openModal"
  >
    <span class="modal-description">
      {{ modalDescription }}
    </span>
    <ul v-if="farmerProductForm.products.length >= 1">
      <li class="created-list" v-for="product in alreadyCreated" :key="product">
        {{ product.product.fullName }}
      </li>
    </ul>
    <template #footer>
      <Button class="send-button" @click="newSend" key="submit" type="primary">
        {{ farmerProductForm.products.length >= 1 ? 'Продолжить' : 'Обновить' }}
      </Button>
    </template>
  </Modal>
  <div class="wrapper products">
    <div class="wrapper--additional">
      <CreateHeader
        title="Добавление товаров"
        :is-product="true"
        @button-click="backToTable"
      />
      <div class="create" v-show="step === 1">
        <div class="category-header">
          <div class="category-header-options">
            <TgTitle :level="4">Раздел категории</TgTitle>
            <Space :size="24" direction="vertical">
              <Select
                placeholder="Выберите категорию"
                :options="categoryOptions"
                @change="handleCategory"
                style="width: 300px"
                v-model:value="selectedCategory"
                size="large"
              />
              <div v-if="childCount > 1" class="category-header-child-options">
                <Select
                  v-for="(categoryChildren, index) in categoryChildrens"
                  :key="categoryChildren.id"
                  placeholder="Выберите товар"
                  :options="categoryChildren.options"
                  style="width: 300px"
                  size="large"
                  :value="categoryChildrens[index].selectedEntity.id"
                  @change="
                    (value, option) =>
                      handleCategoryChildren(value, option, index)
                  "
                />
              </div>
            </Space>
          </div>
          <div class="parameter-form">
            <Form ref="formRef" :model="formParameters" layout="vertical">
              <Row :gutter="16">
                <Col span="24">
                  <div
                    class="product-parameter-block"
                    v-for="(parameter, index) in formParameters.parameters"
                    :key="selectedCategory + parameter.id"
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <h2>Товар {{ index + 1 }}</h2></Col
                      >
                      <Col>
                        <Button
                          v-show="formParameters.parameters.length > 1"
                          type="text"
                          danger
                          @click="deleteFormParameter(index)"
                        >
                          <template #icon>
                            <CloseOutlined></CloseOutlined>
                          </template> </Button
                      ></Col>
                    </Row>

                    <Space :size="8" direction="vertical">
                      <span class="product-parameters-list">{{
                        getRowParameters(parameter)
                      }}</span>
                      <TgText
                        type="danger"
                        v-if="parameter.isAlreadyCreatingError"
                      >
                        Вы уже добавили товар с такими параметрами
                      </TgText>
                      <TgText type="danger" v-if="parameter.isInCatalogError">
                        Товар уже есть в вашем каталоге
                      </TgText>
                    </Space>
                    <Row :gutter="24" class="parameters-control">
                      <Col span="24" v-if="productParameters.string.length">
                        <Space :size="8">
                          <FormItem
                            v-for="item in productParameters.string"
                            :key="item.name"
                            :name="['parameters', index, item.name]"
                            :label="capitilizeFirstLetter(item.name)"
                            :rules="[
                              {
                                required: !parameter.disabledField.includes(
                                  item.name,
                                ),
                                validator: async (rule, value) =>
                                  await strValidator(
                                    item.name,
                                    parameter,
                                    value,
                                  ),
                                trigger: 'change',
                              },
                            ]"
                          >
                            <InputNumber
                              style="width: 100%"
                              v-if="item.name === 'фасовка'"
                              :controls="false"
                              :step="1"
                              :stringMode="true"
                              :placeholder="`Введите значение ${item.name}`"
                              v-model:value="
                                formParameters.parameters[index][item.name]
                              "
                              @change="
                                (event: any) => {
                                  if (event && event.target) {
                                    handleRelatedParameters(
                                      event.target.value,
                                      item,
                                      index,
                                    )
                                  }
                                }
                              "
                              :disabled="
                                parameter.disabledField.includes(item.name) ||
                                lastParametersId !== parameter.id
                              "
                              decimal-separator=","
                            />
                            <Input
                              v-if="item.name !== 'фасовка'"
                              :placeholder="`Введите значение ${item.name}`"
                              v-model:value="
                                formParameters.parameters[index][item.name]
                              "
                              @change="
                                (event: any) =>
                                  handleRelatedParameters(
                                    event.target.value,
                                    item,
                                    index,
                                  )
                              "
                              :disabled="
                                parameter.disabledField.includes(item.name) ||
                                lastParametersId !== parameter.id
                              "
                            />
                          </FormItem>
                        </Space>
                      </Col>
                      <Col span="24" v-if="productParameters.binary.length">
                        <Space :size="8">
                          <FormItem
                            v-for="item in productParameters.binary"
                            :key="item.name"
                            :name="['parameters', index, item.name]"
                          >
                            <Checkbox
                              v-model:checked="
                                formParameters.parameters[index][item.name]
                              "
                              @change="
                                (event) =>
                                  handleRelatedParameters(
                                    event.target.checked,
                                    item,
                                    index,
                                  )
                              "
                              :disabled="
                                parameter.disabledField.includes(item.name) ||
                                lastParametersId !== parameter.id
                              "
                            >
                              {{ capitilizeFirstLetter(item.name) }}
                            </Checkbox>
                          </FormItem>
                        </Space>
                      </Col>
                      <Col span="24">
                        <Row :gutter="24">
                          <Col
                            span="6"
                            v-for="item in productParameters.single"
                            :key="item.name"
                          >
                            <FormItem
                              :label="capitilizeFirstLetter(item.name)"
                              :name="['parameters', index, item.name]"
                              :rules="[
                                {
                                  required: !parameter.disabledField.includes(
                                    item.name,
                                  ),
                                  message: 'Выберите параметр',
                                },
                              ]"
                            >
                              <Select
                                v-model:value="
                                  formParameters.parameters[index][item.name]
                                "
                                :disabled="
                                  parameter.disabledField.includes(item.name) ||
                                  lastParametersId !== parameter.id
                                "
                                size="large"
                                placeholder="Выберите значение"
                                :options="item.options"
                                @select="
                                  (value) =>
                                    handleRelatedParameters(
                                      value,
                                      item,
                                      index,
                                      productParameters.binary,
                                    )
                                "
                              />
                            </FormItem>
                          </Col>
                          <Col
                            span="8"
                            v-for="item in productParameters.multiple"
                            :key="item.name"
                          >
                            <FormItem
                              :label="capitilizeFirstLetter(item.name)"
                              :name="['parameters', index, item.name]"
                            >
                              <CheckboxGroup
                                v-model:value="
                                  formParameters.parameters[index][item.name]
                                "
                                style="width: 100%"
                              >
                                <Space :size="8" direction="vertical">
                                  <Checkbox
                                    v-for="option in item.options"
                                    :key="option.value"
                                    :value="option.value"
                                    @change="
                                      (event) =>
                                        handleRelatedParameters(
                                          event.target.checked,
                                          item,
                                          index,
                                        )
                                    "
                                    :disabled="
                                      parameter.disabledField.includes(
                                        item.name,
                                      ) || lastParametersId !== parameter.id
                                    "
                                    >{{ option.label }}</Checkbox
                                  >
                                </Space>
                              </CheckboxGroup>
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div class="create-farmer-product" v-show="step === 2">
        <Form
          ref="farmerProductsFormRef"
          :model="farmerProductForm"
          layout="vertical"
        >
          <Row :gutter="[0, 16]">
            <Col
              :span="24"
              v-for="(product, index) in farmerProductForm.products"
              :key="product.product_id"
            >
              <div class="farmer-product-card">
                <div class="card-header">
                  <Row :gutter="16">
                    <Col :flex="'auto'">
                      <TgTitle :level="4">{{ product.title }}</TgTitle>
                    </Col>
                    <!--                    <Col>-->
                    <!--                      <Button-->
                    <!--                        type="text"-->
                    <!--                        danger-->
                    <!--                        @click="deleteFormParameter(index)"-->
                    <!--                      >-->
                    <!--                        <template #icon>-->
                    <!--                          <CloseOutlined></CloseOutlined>-->
                    <!--                        </template>-->
                    <!--                      </Button>-->
                    <!--                    </Col>-->
                  </Row>
                </div>
                <div class="card-body">
                  <Row :gutter="24" :wrap="false">
                    <Col :flex="'284px'">
                      <UploadImage @input="(data) => saveImage(data, index)" />
                    </Col>
                    <Col :flex="'auto'">
                      <Row :gutter="[0, 16]">
                        <Col :span="24">
                          <Row
                            :gutter="[24, 0]"
                            :style="width100"
                          >
                            <Col :flex="formItemWidth">
                              <FormItem
                                label="В наличии"
                                :name="['products', index, 'quantity']"
                                :rules="[
                                  {
                                    required: true,
                                    message: 'Укажите количество',
                                    trigger: 'change',
                                  },
                                  {
                                    max: 1000000,
                                    message: `Максимум 1 000 000 ${product.measurement_unit_id || measurementUnitIdForStuff}`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                  {
                                    min: minQuantityDictionary[
                                      product.measurement_unit_id
                                    ],
                                    message: `Минимум ${minQuantityDictionary[product.measurement_unit_id]} ${product.measurement_unit_id || measurementUnitIdForStuff}`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                ]"
                              >
                                <InputNumber
                                  :controls="false"
                                  v-model:value="product.quantity"
                                  :style="width100"
                                  @blur="onQuantityBlur(index)"
                                  :formatter="
                                    (value) =>
                                      product.measurement_unit_id === 'шт'
                                        ? `${Number(value).toFixed(0)}`
                                        : `${value}`
                                  "
                                  :parser="
                                    (value) =>
                                      product.measurement_unit_id === 'шт'
                                        ? `${Number(value).toFixed(0)}`
                                        : `${value}`
                                  "
                                >
                                  <template #addonAfter>
                                    {{ product.measurement_unit_id }}
                                  </template>
                                </InputNumber>
                              </FormItem>
                            </Col>
                            <Col :flex="formItemWidth">
                              <FormItem
                                label="Минимальный заказ"
                                :name="['products', index, 'min_value']"
                                :rules="[
                                  {
                                    required: true,
                                    message: 'Укажите количество',
                                  },
                                  {
                                    max: product.quantity,
                                    message: `Не может быть больше, чем количество в наличии`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                  {
                                    min: minQuantityDictionary[
                                      product.measurement_unit_id
                                    ],
                                    message: `Минимум ${minQuantityDictionary[product.measurement_unit_id]} ${product.measurement_unit_id || measurementUnitIdForStuff}`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                ]"
                              >
                                <InputNumber
                                  :controls="false"
                                  v-model:value="product.min_value"
                                  :style="width100"
                                  :formatter="
                                    (value) =>
                                      product.measurement_unit_id === 'шт'
                                        ? `${Number(value).toFixed(0)}`
                                        : `${value}`
                                  "
                                  :parser="
                                    (value) =>
                                      product.measurement_unit_id === 'шт'
                                        ? `${Number(value).toFixed(0)}`
                                        : `${value}`
                                  "
                                >
                                  <template #addonAfter>
                                    {{
                                      product.measurement_unit_id ||
                                      measurementUnitIdForStuff
                                    }}
                                  </template>
                                </InputNumber>
                              </FormItem>
                            </Col>
                            <Col :flex="formItemWidth">
                              <FormItem
                                label="Срок подготовки"
                                :name="['products', index, 'prepare_days']"
                                :rules="[
                                  {
                                    max: 180,
                                    message: 'Максимум 180 дней',
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                ]"
                              >
                                <InputNumber
                                  :controls="false"
                                  v-model:value="product.prepare_days"
                                  :style="width100"
                                  :formatter="
                                    (value) => `${Number(value).toFixed(0)}`
                                  "
                                  decimal-separator=","
                                >
                                  <template #addonAfter> дн. </template>
                                </InputNumber>
                              </FormItem>
                            </Col>
                            <Col :flex="formItemWidth">
                              <FormItem
                                :name="['products', index, 'price']"
                                :rules="[
                                  {
                                    required: true,
                                    message: 'Укажите цену',
                                  },
                                  {
                                    max: 1000000,
                                    message: `Максимум 1 000 000 ₽/${product.measurement_unit_id || measurementUnitIdForStuff}`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                  {
                                    min: 1,
                                    message: `Минимум 1 ₽/${product.measurement_unit_id || measurementUnitIdForStuff}`,
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                ]"
                              >
                                <template #label>
                                  <Space :size="5">
                                    Цена с НДС
                                    <Popover>
                                      <template #content>
                                        Полный диапазон цен можно указать <br />
                                        после добавления товара в каталог
                                      </template>
                                      <QuestionCircleOutlined
                                        :style="{ color: '#00000073' }"
                                    /></Popover>
                                  </Space>
                                </template>
                                <template
                                  #extra
                                  v-if="withVat && product.price"
                                >
                                  Без НДС
                                  {{
                                    priceWithoutVat(
                                      product.price,
                                      product.vat_percent,
                                    )
                                  }}
                                  ₽/{{ product.measurement_unit_id }}
                                </template>
                                <InputNumber
                                  :controls="false"
                                  v-model:value="product.price"
                                  :style="width100"
                                  :step="0.01"
                                  decimalSeparator=","
                                  @blur="onPriceBlur(index, product.price)"
                                >
                                  <template #addonAfter>
                                    ₽/{{ product.measurement_unit_id }}
                                  </template>
                                </InputNumber>
                              </FormItem>
                            </Col>
                            <Col :flex="formItemWidth" v-if="withVat">
                              <FormItem
                                label="НДС"
                                :name="['products', index, 'vat_percent']"
                                :rules="[
                                  {
                                    max: 100,
                                    message: 'Максимум 100%',
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                  {
                                    min: 0,
                                    message: 'Минимум 0%',
                                    trigger: 'change',
                                    type: 'number',
                                  },
                                ]"
                              >
                                <InputNumber
                                  :controls="false"
                                  v-model:value="product.vat_percent"
                                  :style="width100"
                                  @blur="
                                    onVatPercentBlur(index, product.vat_percent)
                                  "
                                >
                                  <template #addonAfter> % </template>
                                </InputNumber>
                              </FormItem>
                            </Col>
                          </Row>
                        </Col>
                        <Col :span="24">
                          <FormItem
                            label="Описание товара"
                            :name="['products', index, 'comment']"
                            help="Максимум 1000 символов"
                            :rules="[
                              {
                                max: 1000,
                                message: 'Максимум 1000 символов',
                                trigger: 'change',
                              },
                            ]"
                          >
                            <Textarea v-model:value="product.comment" />
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <OverflowLoader v-show="isFetching" />
      <AddProductFooter>
        <template #buttons>
          <Space :size="8">
            <Button
              :loading="isFetching"
              :size="'large'"
              @click="decreaseStep"
              :disabled="isProductWithoutParameters || !isShowParameters"
              >{{ backBtnText[step] }}</Button
            >
            <Button
              :loading="isFetching"
              :size="'large'"
              type="primary"
              @click="increaseStep"
            >
              {{ nextBtnText[step] }}</Button
            >
          </Space>
        </template>
      </AddProductFooter>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  background: white;
  width: 100%;
  min-height: calc(100vh - 69px - 40px - 80px);
  padding: 40px 50px 100px 50px;

  &--additional {
    @include wrapper();
    position: relative;
  }
}

.modal-description {
  margin-bottom: 30px;
}

.created-list {
  margin-left: 24px;
  list-style: disc;
}

.send-button {
  margin-top: 12px;
}

.category {
  margin-bottom: 72px;
  &-header {
    &-options {
      border-radius: 8px;
      padding: 32px;
      border: 1px solid $gray-300;
    }
    &-child-options {
      display: flex;
      gap: 24px;
    }
  }
}

.buttons-wrapper {
  display: flex;
  column-gap: 16px;
}

.loader {
  display: flex;
  justify-content: center;
}

.create__row__container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 40px 48px;
}

.create__row__wrapper {
  padding: 37px 32px 32px 32px;
  width: 300px;
  border: 1px solid $gray-300;
  border-radius: 12px;
}

.errorAdd {
  font-size: 14px;
  font-weight: 400;
  color: #f16063;
  position: absolute;
  top: 30px;
  margin-top: 8px;
}

.create {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  &__row {
    display: grid;
    grid-template-columns: 160px 280px;
    gap: 24px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    //align-items: flex-start;
    position: relative;
    align-items: center;
    margin-bottom: 50px;

    &:hover {
    }
  }

  &__label {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $gray-800;
  }

  &__delete-btn {
    margin: 0;
    color: $default-danger;
    cursor: pointer;
    font-size: 14px;

    img {
      width: 11px;
      height: auto;
    }
  }

  &__field {
    width: 280px;
  }

  &__footer {
    margin-top: 36px;
    gap: 8px;

    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;

    .egal-button--md {
      justify-content: center;

      min-width: 200px;
    }

    .warning_btn {
      width: 355px;
      height: 256px;
      color: $gray-600;
    }
  }
}

.parameter-form {
  margin-top: 32px;
  .product-parameter-block {
    padding: 32px;
    border: 1px solid $gray-300;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .product-parameters-list {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
  }
  .parameters-control {
    margin-top: 24px;
  }
}

.create-farmer-product {
  .farmer-product-card {
    border: 1px solid #f0f0f0;
    .card-header {
      padding: 24px;
      border-bottom: 1px solid #f0f0f0;
    }
    .card-body {
      padding: 24px;
    }
  }
}
</style>
