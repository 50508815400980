<script lang="ts" setup>
import {
  Button,
  Divider,
  Drawer,
  Empty,
  List,
  ListItem,
  Modal,
  Table,
  TabPane,
  Tabs,
  Tag,
} from 'ant-design-vue'
import {
  CloseOutlined,
  EditFilled,
  StarOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue'
import {
  declensionDays,
  priceWithoutVat,
  priceWithVat,
  roundNumber,
} from '@/helpers/scripts'
import ProductPriceBlock from '@/components/CardTable/ProductPriceBlock.vue'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'
import AddToCart from '@/components/AddToCart/AddToCart.vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { IFarmerProduct } from '@/types/interfaces'
import { computed, ref, watch } from 'vue'
import router from '@/router'
import QuantityExceededTooltip from '../Tooltip/QuantityExceededTooltip.vue'

interface Props {
  product: IFarmerProduct
  isFarmer?: boolean
  open?: boolean
  hideControlElements: boolean
  selfDeleting?: boolean
  showCreateSpecialPrice?: boolean
  orderQuantity: string
}

const props = defineProps<Props>()
const emit = defineEmits([
  'closeDrawer',
  'editProduct',
  'editSpecialPrices',
  'editPriceOptions',
  'deleteProduct',
  'selfDeleting',
  'onSuccess',
])
const itemUnavailableText = 'Товара нет в продаже'

const userId = JSON.parse(localStorage.getItem('userData') || '').id
const isDeleting = ref(false)
const isDeleteModal = ref(false)
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE

const columnsPriceOptions = [
  {
    title: 'Объём',
    dataIndex: 'min_value',
    key: 'min_value',
    width: 192,
  },
  {
    title: 'Цена без НДС',
    dataIndex: 'price_without_vat',
    key: 'price_without_vat',
    width: 140,
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price',
    key: 'price',
    width: 180,
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
]

const columnSpecialPrices = [
  {
    title: 'Покупатель',
    dataIndex: 'customer',
    key: 'customer',
    width: 220,
  },
  {
    title: 'Цена без НДС',
    dataIndex: 'price_without_vat',
    key: 'price_without_vat',
    width: 140,
  },
  {
    title: 'Цена с НДС',
    dataIndex: 'price',
    key: 'price',
    width: 156,
  },
  // {
  //   title: 'Условия',
  //   dataIndex: 'details',
  //   key: 'details',
  //   width: 200,
  // },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
]

const activeKey = ref('1')
watch(props, () => {
  if (props.product?.comment) activeKey.value = '1'
  else activeKey.value = '2'
})

const getMaxValue = (index: number) => {
  if (index + 1 == props.product?.price_options?.length) {
    return roundNumber(1000000)
  } else {
    const unit = fillMeasurementUnitIdCommon(
      props.product?.fullName ?? '',
      props.product?.measurement_unit_id ?? null,
    )
    if (['Кг', 'КГ', 'кг'].includes(unit))
      return roundNumber(
        Number(props.product?.price_options?.[index + 1]?.min_value) - 0.01,
      )
    else
      return roundNumber(
        Number(props.product?.price_options?.[index + 1]?.min_value) - 1,
      )
  }
}

const pushToSpecialPrices = () => {
  router.push({ path: '/edit-special-prices/' + props.product?.id })
}

const findCustomerSpecial = computed(() => {
  return props.product?.special_prices?.find((el) => el.customer_id === userId)
})

const equalId = computed(() => {
  return props.product?.farmer_id == userId
})

const closeDrawerWrapper = () => {
  emit('closeDrawer')
}

const modalIsOpen = ref<boolean>(false)
const openModal = () => {
  modalIsOpen.value = true
}
const closeModal = () => {
  modalIsOpen.value = false
}

const priceWithoutVatString = computed(() => {
  if (!props.product?.price_options?.length || !props.product?.special_prices)
    return itemUnavailableText
  if (!findCustomerSpecial.value) {
    const value = []
    const priceOptions = [...props.product.price_options]
    const lastPrice = priceOptions?.sort((a, b) => +a.price - +b.price)[0]

    priceOptions.length > 1 && value.push('от')
    value.push(priceWithoutVat(+lastPrice.price, props.product.vat_percent))
    value.push(
      props.product?.measurement_unit_id
        ? `₽/${fillMeasurementUnitIdCommon(props.product?.fullName, props.product?.measurement_unit_id)}`
        : '₽',
    )
    return value.join(' ')
  } else if (
    findCustomerSpecial.value.price &&
    props.product?.measurement_unit_id
  ) {
    return `${priceWithoutVat(+findCustomerSpecial.value.price, findCustomerSpecial.value.vat_percent)} ₽/${fillMeasurementUnitIdCommon(props.product.fullName, props.product.measurement_unit_id)}`
  } else if (findCustomerSpecial.value.price) {
    return `${priceWithoutVat(+findCustomerSpecial.value.price, findCustomerSpecial.value.vat_percent)} ₽`
  } else return '-'
})

const deleteProduct = async () => {
  isDeleting.value = true
  await FarmerProductAPI.delete(props.product?.id ?? 0)
    .then(() => {
      emit('onSuccess')
      isDeleteModal.value = false
    })
    .finally(() => {
      isDeleting.value = false
      isDeleteModal.value = false
      closeDrawerWrapper()
    })
}

const calcPriceWithoutVat = (price: string, product: IFarmerProduct, vat_percent: string | number) => {
  if (price && product.measurement_unit_id) {
    return `${priceWithoutVat(+price, +vat_percent)} ₽/${product.measurement_unit_id}`
  } else if (price) {
    return `${priceWithoutVat(+price, +vat_percent)} ₽`
  } else return '-'
}

const calcPrice = (price: string, product: IFarmerProduct) => {
  if (price && product?.measurement_unit_id) {
    return `${price}₽/${product?.measurement_unit_id}`
  } else if (product?.price) {
    return `${product?.price}₽`
  } else return '-'
}
const isQuantityExceeded = computed(() => {
  return Number(props.product?.quantity) < Number(props.orderQuantity)
})
</script>

<template>
  <Drawer
    width="1100"
    :bodyStyle="{ padding: '32px' }"
    :headerStyle="{
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      padding: '24px 64px 24px 32px',
    }"
    :open="open"
    @close="closeDrawerWrapper"
  >
    <template #extra>
      <h4 class="product-name">{{ product?.product?.fullName }}</h4>
      <div
        class="drawer-header-buttons"
        v-if="isFarmer && equalId && !hideControlElements"
      >
        <Button type="link" @click="emit('editProduct', product?.id)">
          <template #icon> <EditFilled /> </template>
          Редактировать данные товара
        </Button>
        <Divider type="vertical" />
        <Modal
          width="440px"
          centered
          v-model:open="modalIsOpen"
          :bodyStyle="{ marginBottom: '24px' }"
          title="Внести изменения в цены товара?"
        >
          При редактировании вариантов цен, будут внесены изменения в запросы
          цен по данному товару. Вы уверены, что хотите изменить цены?
          <template #footer>
            <Button @click="closeModal"> Отмена </Button>
            <Button
              type="primary"
              @click="emit('editPriceOptions', product?.id)"
            >
              Продолжить
            </Button>
          </template>
        </Modal>
        <Button type="link" @click="openModal">
          <template #icon> <UnorderedListOutlined /> </template>
          Редактировать список цен
        </Button>
        <Divider type="vertical" />
        <Button type="link" @click="emit('editSpecialPrices', product?.id)">
          <template #icon> <StarOutlined /> </template>
          Редактировать спеццены
        </Button>
        <Divider type="vertical" />
        <Button
          type="link"
          danger
          @click="
            !selfDeleting ? emit('deleteProduct') : (isDeleteModal = true)
          "
        >
          <template #icon> <CloseOutlined /> </template>
          Удалить товар
        </Button>
      </div>
    </template>

    <div class="drawer-main">
      <div class="product-info">
        <List class="product-list">
          <ListItem>
            <div>
              <span class="measure-title"> Минимальный заказ </span>
              <span class="measure-value">
                {{ product?.min_value }} {{ product?.measurement_unit_id }}
              </span>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <span
                class="measure-title"
                :class="{ 'quantity-exceeded': isQuantityExceeded }"
              >
                В наличии
              </span>
              <span
                class="measure-value"
                :class="{ 'quantity-exceeded': isQuantityExceeded }"
              >
                {{ product?.quantity }} {{ product?.measurement_unit_id }}
                <QuantityExceededTooltip
                  :show="isQuantityExceeded"
                  :text="'Недостаточно для минимального заказа'"
                />
              </span>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <span class="measure-title"> Срок подготовки товара </span>
              <span class="measure-value">
                {{ product?.prepare_days }}
                {{ declensionDays(product?.prepare_days ?? 0) }}
              </span>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <span class="measure-title"> Цена без НДС </span>
              <Tag
                class="special-price"
                color="success"
                v-if="findCustomerSpecial?.price"
              >
                Спеццена
              </Tag>
              <span class="measure-value">
                {{ priceWithoutVatString }}
              </span>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <span class="measure-title"> НДС </span>
              <Tag
                class="special-price"
                color="success"
                v-if="findCustomerSpecial?.vat_percent"
              >
                Спеццена
              </Tag>
              <span class="measure-value">
                {{
                  findCustomerSpecial?.vat_percent
                    ? findCustomerSpecial.vat_percent
                    : product?.vat_percent
                }}
                %
              </span>
            </div>
          </ListItem>
          <ListItem>
            <ProductPriceBlock
              :farmer-product="product as IFarmerProduct"
              :show_vat="false"
            />
            <AddToCart
              v-if="!isFarmer"
              class="measure-value"
              :product="product"
              @closeDrawer="closeDrawerWrapper"
            />
          </ListItem>
        </List>
      </div>
      <img
        class="product-image"
        v-if="product?.image_url"
        :src="product?.image_url"
        loading="lazy"
        alt="product"
      />
      <div v-if="!product?.image_url" class="no-image"></div>
    </div>

    <Tabs v-model:activeKey="activeKey">
      <TabPane v-if="product?.comment.length" key="1" tab="Описание">
        {{ product?.comment }}
      </TabPane>
      <TabPane key="2" tab="Список цен">
        <Table
          :columns="columnsPriceOptions"
          :data-source="props.product?.price_options ?? []"
          :pagination="false"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'min_value'">
              <span>
                {{ record.min_value }}-{{ getMaxValue(index) }}
                {{ product?.measurement_unit_id }}
              </span>
            </template>
            <template v-else-if="column.key === 'price_without_vat'">
              <span>
                {{ calcPriceWithoutVat(record?.price, product, product?.vat_percent) }}
              </span>
            </template>
            <template v-else-if="column.key === 'price'">
              <h6>
                {{ calcPrice(record?.price, product) }}
              </h6>
              <span class="vat"> НДС {{ product?.vat_percent }}% </span>
            </template>
            <template v-else-if="column.key === 'comment'">
              <span v-if="record.comment"> {{ record.comment }} </span>
              <span v-else> - </span>
            </template>
          </template>
        </Table>
      </TabPane>
      <TabPane v-if="isFarmer && equalId" key="3" tab="Спеццены">
        <Table
          v-if="props.product?.special_prices?.length"
          :columns="columnSpecialPrices"
          :data-source="props.product?.special_prices"
          :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'customer'">
              <span>
                {{ record.customer.legal_form }} {{ record.customer.name }}
              </span>
            </template>
            <template v-if="column.key === 'price'">
              <h6>
                {{ calcPrice(record?.price, product) }}
              </h6>
              <span class="vat"> НДС {{ record.vat_percent }}% </span>
            </template>
            <template v-else-if="column.key === 'price_without_vat'">
              <span>
               {{ calcPriceWithoutVat(record?.price, product, record.vat_percent) }}
              </span>
            </template>
            <!-- <template v-else-if="column.key === 'details'">
              <div v-if="record.expires_after || record.quantity">
                <Tag class="date" color="success" v-if="record.expires_after"> До {{ dayjs(record.expires_after).format('DD.MM.YYYY') }} </Tag> <br>
                <Tag class="date" color="success" v-if="record.quantity"> {{ record.quantity }} {{ product.measurement_unit_id }} </Tag> <br>
                <span class="vat"> Осталось {{ expiresDays(record.expires_after) }} дня</span> <br>
              </div>
              <span v-else> - </span>
            </template> -->
            <template v-else-if="column.key === 'comment'">
              <span v-if="!record.comment"> - </span>
              <span v-else> {{ record.comment }} </span>
            </template>
          </template>
        </Table>
        <div v-else class="create-new">
          <Empty :image="simpleImage" description="" :image-style="{}" />
          <div class="create-new-text">
            <h4>Нет созданных спеццен</h4>
            <span>
              Создайте спеццены, чтобы покупатели заказывали ваши товары по
              уникальным предложениям
            </span>
          </div>
          <Button
            type="primary"
            size="large"
            @click="pushToSpecialPrices"
            v-if="showCreateSpecialPrice"
          >
            Создать спеццену
          </Button>
        </div>
      </TabPane>
    </Tabs>
  </Drawer>
  <Modal
    title="Удалить товар?"
    v-model:open="isDeleteModal"
    @ok="deleteProduct"
    okText="Удалить"
    cancelText="Закрыть"
    :confirm-loading="isDeleting"
    centered
    width="440px"
  >
    <p>{{ product?.product?.fullName ?? '' }}</p>
  </Modal>
</template>

<style lang="scss" scoped>
.product-name {
  height: fit-content;
  width: 1004px;
}

.drawer-header-buttons button {
  padding: 0 8px 0 0;
}

.drawer-main {
  padding-bottom: 32px;
  display: flex;

  .product-info {
    height: 294px;
    width: 648px;

    .product-list {
      padding-right: 48px;

      .measure-title,
      .measure-value {
        font-size: 16px;
        color: #000000a6;
      }

      .special-price {
        margin-left: 8px;
      }

      .measure-value {
        position: absolute;
        right: 0;
        color: #000000e0;
      }

      .quantity-exceeded {
        font-weight: 600;
        color: #faad14;
      }
    }
  }
}

.date {
  margin-bottom: 4px;
}

.vat {
  color: #00000073;
}

.product-image,
.no-image {
  height: 340px;
  width: 340px;
  object-fit: cover;
  background-size: contain;
}

.no-image {
  background: #fafafa url('@/assets/img/no-image-product.png') no-repeat center;
}

.create-new {
  height: 333px;
  text-align: -webkit-center;

  &-text {
    margin: auto auto 24px;
    height: 80px;
    width: 380px;
  }

  & Button {
    font-size: 16px;
  }
}

:deep(.ant-divider-vertical) {
  padding-right: 8px;
}

:deep(.ant-tabs-nav) {
  margin: 0 0 24px 0;
}

:deep(.ant-list-item) {
  padding-left: 0;
}

:deep(.ant-drawer-close) {
  position: absolute !important;
  right: 0 !important;
}
</style>
