<template>
  <ul class="calendar__controls">
    <li
      class="calendar__controls-left"
      @click="$emit('change-month', data?.isDouble ? -2 : -1)"
    >
      <ChevronLeftIcon />
    </li>
    <li class="calendar__controls-month">
      <SelectControls
        class="select--month"
        @select-control="selectMonth"
        :value="monthValue"
        :options="allMonths"
      />
      <SelectControls
        class="select--year"
        @select-control="selectYear"
        :value="yearValue"
        :options="allYears"
      />
    </li>
    <li
      class="calendar__controls-right"
      :class="{ hidden: data?.isDouble }"
      @click="$emit('change-month', data?.isDouble ? 2 : 1)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </li>
  </ul>
</template>

<script>
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import { defineComponent } from 'vue'
import { capitalize } from '@/assets/calendar/helpers'
import SelectControls from './SelectControls.vue'
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

export default defineComponent({
  name: 'ControlsComp',
  components: {ChevronLeftIcon, SelectControls, BIcon: BootstrapIcon },

  props: {
    data: {
      type: Object,
      // default: () => {},
    },
    monthToDisplay: {
      type: Object,
      default: null,
    },

    yearSelectRange: {
      type: Object,
      // default: () => {},
    },
  },
  emits: ['update:month', 'update:year'],
  data() {
    return {
      capitalize,
      monthValue: '',
      yearValue: '',
    }
  },
  computed: {
    allMonths() {
      const months = []

      const currMonthIndex = new Date(this.monthToDisplay).getMonth()
      for (let i = currMonthIndex; i < 12; i++) {
        const newMonth = new Date(
          new Date(this.monthToDisplay).setMonth(i),
        ).toLocaleString(this.data?.locale || 'en-US', {
          month: 'long',
        })
        months.push({ name: newMonth, index: i })
      }
      for (let i = 0; i < currMonthIndex; i++) {
        const newMonth = new Date(
          new Date(this.monthToDisplay).setMonth(i),
        ).toLocaleString(this.data?.locale || 'en-US', {
          month: 'long',
        })
        months.push({ name: newMonth, index: i })
      }

      return months
    },

    allYears() {
      const years = []

      const min =
        this.yearSelectRange.min ?? new Date(this.monthToDisplay).getFullYear()
      const max = this.yearSelectRange.max

      for (let i = min; i <= max; i++) {
        years.push({ name: i })
      }

      return years
    },

    currentMonth() {
      if (!this.allMonths.length) {
        return
      }
      const currMonthIndex = new Date(this.monthToDisplay).getMonth()
      return this.allMonths[currMonthIndex]
    },
  },

  mounted() {
    this.setYearValue(this.monthToDisplay)
    this.setMonthValue(this.monthToDisplay)
  },
  methods: {
    setYearValue(year) {
      this.yearValue = new Date(year).getFullYear()
    },
    setMonthValue(date) {
      this.monthValue = new Date(date).toLocaleString(
        this.data?.locale || 'en-US',
        {
          month: 'long',
        },
      )
    },

    selectYear(option) {
      this.yearValue = option.name
      this.$emit('update:year', option)
    },

    selectMonth(option) {
      this.monthValue = option.name
      this.$emit('update:month', option)
    },
  },
  watch: {
    monthToDisplay(v) {
      this.setMonthValue(v)
      this.setYearValue(v)
    },

    monthValue(newValue, oldValue) {
      if (oldValue) {
        this.$emit(
          'update:month',
          this.allMonths.find((i) => i.name === newValue),
        )
      }
    },

    yearValue(newValue, oldValue) {
      if (oldValue) {
        this.$emit('update:year', newValue)
      }
    },
  },
})
</script>

<style scoped lang="scss">
.select--year {
  :deep(.select-container) {
    padding-left: 0;
  }
}

.select--month {
  :deep(.selected) {
    margin-left: auto;
  }
}
</style>
