<script setup lang="ts">
import { PropType, ref, watchEffect } from 'vue'
import { ISwitcherItem } from '@/types/interfaces'

const props = defineProps({
  items: {
    type: Array as PropType<ISwitcherItem[]>,
  },
  active: {
    // текущий выбранный элемент
    type: Object,
  },
  title: { type: String },
  isDisabled: { type: Boolean, default: false },
})

const emit = defineEmits(['select'])

const activeTab = ref()

watchEffect(() => {
  activeTab.value = props.active
})

const selectTab = (item: ISwitcherItem) => {
  if (!props.isDisabled) {
    emit('select', item)
  }
}
</script>

<template>
  <div class="switch" :class="[isDisabled ? 'disabled' : '']">
    <div class="switch__title" v-if="title">
      {{ title }}
    </div>
    <div class="switcher">
      <div
        class="switcher__tab"
        v-for="item in items"
        :key="item.name"
        @click="selectTab(item)"
        :class="{ active: activeTab && activeTab.value === item.value }"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.switch__title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $gray-600;
}

.switcher {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;

  &__tab {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 0;
    //width: 100%;
    line-height: 31px;
    color: #000000e0;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #1677ff;
      font-weight: 600;
      border-bottom: 2px solid #1677ff;
    }
  }
}

.switch.disabled {
  .switch__title {
    color: $gray-400;
  }
  .switcher__tab {
    cursor: default;
    color: $gray-500;
  }
}
</style>
