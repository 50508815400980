<script setup lang="ts">
import RequestSupplyRequirments from '@/components/CreateRequest/CreateRequestBody/RequestSupplyRequirments.vue'
import { onMounted, ref, UnwrapRef } from 'vue'
import { AddressAPI } from '@/api/AddressAPI'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { IAddress, IContact } from '@/types/interfaces'
import { ContactAPI } from '@/api/ContactAPI'
import RequestProducts from '@/components/CreateRequest/CreateRequestBody/RequestProducts.vue'
import RequestFarmers from '@/components/CreateRequest/CreateRequestBody/RequestFarmers.vue'
import {
  FirstFormState,
  IProductTableItem,
} from '@/views/Customer/CustomerCreateRequestView.vue'
import { ProductAntTreeAPI } from '@/api/ProductAntTreeAPI'

interface Props {
  currentStep: number
  firstFormState: UnwrapRef<FirstFormState>
  secondFormState: { domains: IProductTableItem[] }
  thirdFormState: UnwrapRef<any[]>
  checkedKeys: UnwrapRef<string[]>
  expandedKeys: UnwrapRef<string[]>
}

const props = defineProps<Props>()
const emit = defineEmits([
  'editFirstForm',
  'editSecondForm',
  'editSecondForm',
  'deleteFarmer',
  'handleSelectFarmerModal',
  'addProduct',
  'removeProduct',
  'checkKeys',
  'expandKeys',
])
const { getCoreCookie } = useJWTDecode()
const addressList = ref<IAddress[]>([])
const contactPersonList = ref<IContact[]>([])
const treeData = ref([])

const firstFormRef = ref()
const secondFormRef = ref()

defineExpose({
  firstFormRef,
  secondFormRef,
})

onMounted(async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const currentCompanyId = user?.company_id

  if (!currentCompanyId) return

  const addressGet = AddressAPI.getAddresses(currentCompanyId, 'customer').then(
    (res) => {
      addressList.value = res.items
    },
  )
  const userDataString = localStorage.getItem('userData')
  if (!userDataString) return
  const userData = JSON.parse(userDataString)
  const contactsGet = ContactAPI.getItemsContacts(
    [
      ['company_id', 'eq', userData.id],
      'AND',
      ['company_type', 'eq', 'customer'],
    ],
    1,
    100,
  ).then((res) => {
    contactPersonList.value = res.items
  })
  const treeGet = ProductAntTreeAPI.getItems().then((res) => {
    treeData.value = res
    sortTree()
  })
  await Promise.all([addressGet, contactsGet, treeGet])
})

// Сортировка дерева (категорий) по алфавиту
const sortTree = () => {
  treeData.value?.sort((a, b) => {
    if (a.path[0] > b.path[0]) {
      return 1
    }
    if (a.path[0] < b.path[0]) {
      return -1
    }
    return 0
  })

  sortTreeCategory()
}

// Сортировка дерева (подкатегорий) по алфавиту
const sortTreeCategory = () => {
  treeData.value.forEach((category) => {
    sortChildren(category)
  })
}

const sortChildren = (category: any) => {
  category.children?.forEach((child: any) => {
    child.products?.sort((a, b) => {
      const aTitle = a?.title?.join(' ')?.toLowerCase()
      const bTitle = b?.title?.join(' ')?.toLowerCase()
      if (aTitle < bTitle) {
        return -1
      }
      if (aTitle > bTitle) {
        return 1
      }
      return 0
    })
    sortChildren(child)
  })
}

const firstFormEditWrapper = (field: string, value: any) => {
  emit('editFirstForm', field, value)
}

const secondFormEditWrapper = (field: string, value: any, index: number) => {
  emit('editSecondForm', field, value, index)
}
const thirdFormEditWrapper = (uid: string) => {
  emit('deleteFarmer', uid)
}
const handleSelectFarmerModalWrapper = (value: boolean) => {
  emit('handleSelectFarmerModal', value)
}
const addProductWrapper = (value: IProductTableItem) => {
  emit('addProduct', value)
}

const removeProductWrapper = (key: string) => {
  emit('removeProduct', key)
}
const checkKeysWrapper = (keys: string[], e: any) => {
  emit('checkKeys', keys, e)
}
const expandKeysWrapper = (keys: string[]) => {
  emit('expandKeys', keys)
}
</script>

<template>
  <div class="create-request-body">
    <RequestSupplyRequirments
      ref="firstFormRef"
      v-if="props.currentStep === 0"
      :addressList="addressList"
      :contactPersonList="contactPersonList"
      :form-state="props.firstFormState"
      @editFirstForm="firstFormEditWrapper"
    />
    <RequestProducts
      v-if="props.currentStep === 1"
      ref="secondFormRef"
      :form-state="props.secondFormState"
      :checkedKeys="checkedKeys"
      :expandedKeys="expandedKeys"
      :treeData="treeData"
      @editSecondForm="secondFormEditWrapper"
      @addProduct="addProductWrapper"
      @removeProduct="removeProductWrapper"
      @checkKeys="checkKeysWrapper"
      @expandKeys="expandKeysWrapper"
    />
    <RequestFarmers
      v-if="props.currentStep === 2"
      :form-state="props.thirdFormState"
      @deleteFarmer="thirdFormEditWrapper"
      @handleSelectFarmerModal="handleSelectFarmerModalWrapper"
    />
  </div>
</template>

<style scoped lang="scss">
.create-request-body {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
