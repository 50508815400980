<script lang="ts" setup>
import { Flex, Button } from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { requestOrderCompositionStore } from '@/store/requestOrderCompositionStore'
import { computed } from 'vue'

const route = useRoute()
const router = useRouter()
const store = requestOrderCompositionStore()

const goBack = () => {
  router.push('/requests/' + route.params.requestId)
}

const goCreateOrder = async () => {
  await store.createOrder(Number(route.params.recipientId))
}

const isDisabledBtn = computed(() => !store.checkboxState.checkedList.length)
</script>

<template>
  <div class="footer-wrapper">
    <Flex class="footer" justify="space-between">
      <Button type="link" size="large" :onClick="goBack" danger>
        <template #icon> <CloseOutlined /></template>
        Отменить оформление заказа
      </Button>
      <Flex gap="12" align="center">
        <Button type="link" size="large" :onClick="goBack">Назад</Button>
        <Button
          type="primary"
          size="large"
          :onClick="goCreateOrder"
          :disabled="isDisabledBtn"
          >Перейти к оформлению</Button
        >
      </Flex>
    </Flex>
  </div>
</template>

<style scoped lang="scss">
.footer-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(245, 245, 245, 1);
  box-sizing: border-box;
  padding: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer {
  flex: 1;
}
</style>
