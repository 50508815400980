import axios from 'axios'
import {clearCookies} from "@/helpers/scripts";
import {useCookies} from "vue3-cookies";
import emitter from "@/emitter";

const { cookies } = useCookies();

const axiosInstance = axios.create();
const domain = import.meta.env.VITE_BASE_URL;

const getUMTToken = () => localStorage.getItem('umt') || '';
const getUMRTToken = () => localStorage.getItem('umrt') || '';

const setUMTToken = (token: string) => localStorage.setItem('umt', token);
const setUMRTToken = (token: string) => localStorage.setItem('umrt', token);

const IS_DEBUG = false;
const USER_REFRESH_TOKEN_INTERVAL_MINUTES = 60;
let isRefreshing = false;
let tokenRefreshPromise = null;
let time = 60 * 1000 * USER_REFRESH_TOKEN_INTERVAL_MINUTES;
let estimatedTime = '00:00';

const addTimeLogInConsole = (diff: number) => {
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = Math.floor(diff % 60);
    estimatedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    console.debug('session estimatedTime', estimatedTime);
}

export const initSessionTimer = () => {
    const storedTime = localStorage.getItem('sessionTime');
    if (storedTime === null || storedTime === '0') {
        const date = new Date();
        const startTime = date.getTime();
        localStorage.setItem('sessionTime', startTime.toString());
        localStorage.setItem('isStartedSession', 'true');
        intervalFunction(startTime);
    } else {
        const startTime = parseInt(storedTime);
        intervalFunction(startTime);
    }
}

const updateAllTokensInCookies = (sessionId?: NodeJS.Timeout | null) => {
    if (!sessionId) clearInterval(sessionId)
    if (IS_DEBUG) console.debug('update session, refresh tokens')
    refresh_master_token('auth')
        .then(async () => {
            await refresh_access_token('chat')
            await refresh_access_token('core')
            await refresh_access_token('onec')
            await refresh_access_token('auth')
        })
        .catch((error) => error)
        .finally(() => {
            const date = new Date()
            const startTime = date.getTime()
            localStorage.setItem('sessionTime', startTime.toString())
            initSessionTimer()
        })
}

const intervalFunction = (startTime: any) => {
    const prevSessionId = localStorage.getItem('currentSessionId')
    if (prevSessionId) clearInterval(Number(prevSessionId))
    const sessionId = setInterval(() => {
        const now = new Date().getTime()
        const diff = (startTime + time - now) / 1000
        if (diff <= 5) {
            updateAllTokensInCookies(sessionId)
        } else {
            if (IS_DEBUG) addTimeLogInConsole(diff);
        }
    }, 1000)
    if (IS_DEBUG) console.debug('CurrentSessionId:', sessionId + '...');
    localStorage.setItem('currentSessionId', String(sessionId));
}

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config } = error;
        const originalRequest = config;
        if (!originalRequest.url?.includes('login')) {
            if (error.response?.data?.action_error?.internal_code === 'ust_expired') {
                const serviceName = originalRequest?.url?.split('/')[4];
                if (!isRefreshing) {
                    isRefreshing = true;
                    tokenRefreshPromise = refresh_access_token(serviceName)
                        .then(() => isRefreshing = false)
                        .catch((refreshError) => {
                            isRefreshing = false;
                            return Promise.reject(refreshError);
                        });
                }
                await tokenRefreshPromise;
                originalRequest.headers['Authorization'] = cookies.get(serviceName);
                setTimeout(() => emitter.emit('refresh-token'), 1000)
            }
        }
        return Promise.reject(error);
    }
);

async function refresh_access_token(serviceName: string) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.data?.action_error?.internal_code === 'umt_expired') {
        refresh_master_token('auth')
          .then(() => refresh_access_token(serviceName))
          .catch((error) => error)
      } else return error
    },
  )

  if (getUMRTToken()) {
    delete axiosInstance.defaults.headers.common.Authorization
    axiosInstance({
      url: `${domain}/auth/User/loginToService`,
      method: 'POST',
      data: { service_name: serviceName, token: getUMTToken() }
    })
      .then((response) => {
        cookies.remove(serviceName)
        const newToken = response?.data?.action_result?.data
        cookies.set(serviceName, newToken)
        return newToken
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const logout = async (errorText: string) => {
    await clearCookies()
    localStorage.clear()
    console.error(errorText)
    // TODO: Добавить сообщение об ошибке пользователю (После уточнения)
    window.location.href = '/sign-in'
    return Promise.reject('Session expired')
}

async function refresh_master_token(serviceName: string) {
  if (getUMRTToken()) {
    delete axiosInstance.defaults.headers.common.Authorization
    return axiosInstance({
      url: `${domain}/${serviceName}/User/refreshUserMasterToken`,
      method: 'POST',
      data: { token: getUMRTToken() },
    })
      .then((response) => {
          const data = response.data?.action_result?.data
          if (data?.user_master_refresh_token && data.user_master_token) {
              setUMRTToken(data?.user_master_refresh_token);
              setUMTToken(data?.user_master_token);
              if (IS_DEBUG) {
                  console.debug('refresh_master_token:');
                  console.debug('umrt:', data?.user_master_refresh_token)
                  console.debug('umt:', data?.user_master_token)
              }
          } else {
              console.error('failed to refresh umrt and umt');
              setTimeout(() => {
                  updateAllTokensInCookies()
              }, time / 10)
          }
      })
      .catch(async () => await logout('Session expired 1'));
  } else {
      await logout('Session expired 2');
  }
}

export default axiosInstance
