<script setup lang="ts">
import { Button, Menu } from 'ant-design-vue'
import {
  ExportOutlined,
  HomeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue'
import { useAuth } from '@/composables/useAuth'
import { useRoute } from 'vue-router'
import {computed, h, onMounted, ref, watch} from 'vue'
import { MenuInfo } from 'ant-design-vue/es/menu/src/interface'
import router from '@/router'
import dayjs from "dayjs";
import {useClipboard} from "@vueuse/core";

const { logout } = useAuth()
const route = useRoute()

const version = computed(() => __APP_VERSION__)
const commitHash = computed(() => __COMMIT_HASH__)
const commitDate = computed(() => __COMMIT_DATE__)
const { copy, copied } = useClipboard({ source: commitHash })

const selected_keys = ref<string[]>([])
const menu_items = [
  {
    key: 'users',
    icon: () => h(HomeOutlined),
    label: 'Список компаний',
    title: 'Список компаний',
  },
  {
    key: 'orders',
    icon: () => h(UnorderedListOutlined),
    label: 'Список заказов',
    title: 'Список заказов',
  },
]

const menu_click = (menu_item: MenuInfo) => {
  switch (menu_item.key) {
    case 'users':
      router.push('/admin/users')
      break
    case 'orders':
      router.push('/admin/orders')
      break
    default:
      break
  }
}

const match_route_with_menu = (route: string) => {
  menu_items.forEach((menu_item) => {
    if (route.includes(menu_item.key)) {
      selected_keys.value = [menu_item.key]
    }
  })
}

watch(route, (new_route) => {
  match_route_with_menu(new_route.path)
  console.log('import.meta.env', import.meta.env)
})

onMounted(() => {
  match_route_with_menu(route.path)
  console.log('import.meta.env', import.meta.env)
})
</script>

<template>
  <div class="menu">
    <img src="@/assets/img/logo.svg" alt="easy-retail logo" loading="lazy" />
    <Menu
      :items="menu_items"
      :selectedKeys="selected_keys"
      mode="inline"
      @click="menu_click"
    />
    <div class="revision">
      <div class="revision__version">v{{ version }}</div>
      <div class="revision__hash" v-if="!copied" @click="copy(commitHash)">{{ commitHash }}</div>
      <div class="revision__hash" v-if="copied">Hash скопирован!</div>
      <div class="revision__date">{{ commitDate }}</div>
      <Button @click="logout" type="text">
        <template #icon> <ExportOutlined /></template>
        Выйти из системы</Button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.revision {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  &__hash {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    overflow: hidden;
    max-width: 200px;
  }
  &__version {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
}
.menu {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr auto;
  gap: 24px;
  height: 100vh;
  box-sizing: border-box;
  padding: 8px 8px 24px 8px;
  border-right: 1px solid #f0f0f0;
  :deep(.ant-menu-light.ant-menu-root.ant-menu-inline) {
    border-inline-end: none;
  }
}
</style>
