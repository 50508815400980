<script setup lang="ts">
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import { MessageOutlined } from '@ant-design/icons-vue'
import { Flex, Space, TypographyText as TgText } from 'ant-design-vue'
import { IOrder } from '@/types/interfaces'
import { computed } from 'vue'
import { is_orders_has_difference } from '@/helpers/scripts'
import { NewGeneralOrderStatus } from '@/types/enums'

interface IProps {
  order: IOrder
  history: IOrder | null
}

const props = defineProps<IProps>()

const orderWasEditedByFarmer = computed(() => {
  const orderWasEdited =
    props.order.history[props.order.history.length - 1]?.initiator_type ===
      'farmer' && is_orders_has_difference(props.order, props.history)

  return orderWasEdited && props.order.status === NewGeneralOrderStatus.New
})
</script>

<template>
  <Space
    :size="6"
    v-if="order.comment && !orderWasEditedByFarmer"
    align="start"
  >
    <MessageOutlined :style="iconStyle" />
    <Flex :gap="4" vertical>
      <TextWithOldPopover
        v-if="history && order.comment !== history.comment"
        :text="order.comment"
        :old_text="history?.comment || 'Без комментария'"
      />
      <TgText v-else> {{ order.comment }} </TgText>
    </Flex>
  </Space>
</template>

<style scoped lang="scss"></style>
