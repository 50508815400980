<script lang="ts" setup>
import { Button } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import MainFooter from '@/components/Footer/MainFooter.vue'
import { useCookies } from 'vue3-cookies'
import emitter from '@/emitter'
import { computed } from 'vue'

const router = useRouter()
const { cookies } = useCookies()
const route = useRoute()

const onSignInClick = async () => {
  if (route.path === '/sign-in') {
    emitter.emit('clear-login-form')
  }
  cookies.remove('core')
  cookies.remove('auth')
  cookies.remove('preRegisterData')

  localStorage.removeItem('umt')
  localStorage.removeItem('ust')
  localStorage.removeItem('umrt')

  await router.push('/sign-in')
}

const getClass = computed(() => {
  if (route.path == '/invite-register') {
    return 'not-clickable logo'
  } else return 'logo'
})

const isOnSignInPage = computed(() => route.path === '/sign-in')
</script>

<template>
  <div class="layout-container">
    <div class="layout-header">
      <div class="header-wrapper">
        <div @click="onSignInClick" v-bind:class="getClass">
          <img src="@/assets/img/logo.svg" alt="logo" loading="lazy" />
        </div>
        <Button
          v-if="!isOnSignInPage"
          class="login-button"
          @click="onSignInClick"
        >
          Войти
        </Button>
      </div>
    </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    <MainFooter :links="null" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';

.login-button {
  border: 1px solid #a0aec0;
  font-weight: 500;
}

.layout-container {
  width: 100%;
  min-height: 100vh;
  height: 100%;

  .header {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
  }

  .content {
    min-height: calc(100vh - 60px - 240px); // высота хэдера;
    background-color: $gray-100;
    align-items: center;
    display: flex;
  }
}

.logo {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.not-clickable {
    cursor: auto;
  }
}

.layout-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-bottom: 1px solid $gray-300;
  height: 68px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1408px;
    width: 100%;
    padding: 64px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
}
</style>
