<script setup lang="ts">
import NotificationHistoryItem from '@/components/Notification/NotificationHistoryItem.vue'
import { NotificationAPI } from '@/api/NotificationAPI'
import NotificationsEmpty from '../../assets/img/notifications-empty.png'

const emit = defineEmits(['close-notifications', 'update-notifications'])

interface Props {
  notifications: any
}

const props = defineProps<Props>()

const clearNotifications = () => {
  if (props.notifications.length === 0) return
  props.notifications.forEach((notify: { checked: boolean; id: number }) => {
    if (!notify.checked) {
      NotificationAPI.updateNotification({
        id: notify.id,
        checked: true,
      }).then(() => emit('update-notifications'))
    }
  })
}
</script>

<template>
  <div class="notification-history">
    <!--    <div class="notification-history__header" v-if="notifications.length > 0">-->
    <!--      <p class="notification-history__title">Уведомления</p>-->
    <!--      <a class="notification-history__clear" @click="clearNotifications">-->
    <!--        Отметить все как прочитанные-->
    <!--      </a>-->
    <!--    </div>-->
    <div class="notification-history__body" v-if="notifications.length > 0">
      <notification-history-item
        v-for="notify in notifications"
        :key="notify.id"
        :id="notify.id"
        :order="notify.title"
        :checked="notify.checked"
        :status="''"
        :img="notify.icon"
        :text="notify.text"
        :time="notify.created_at"
        :link="notify.link"
        :type="notify.type"
        @close-notifications="emit('close-notifications')"
        @update-notifications="emit('update-notifications')"
      />
    </div>

    <div class="notification-history__empty" v-if="notifications.length === 0">
      <img
        class="notification-history__empty-image"
        :src="NotificationsEmpty"
        alt="empty notify"
        loading="lazy"
      />
      <p class="notification-history__empty-title">
        Пока что у вас нет уведомлений
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.notification-history {
  font-size: 14px;
  line-height: 150%;
  color: #2d3748;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  width: 26.5rem;
  max-height: 84vh;
  position: absolute;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow:
    0 0 1px rgba(12, 26, 75, 0.1),
    0 20px 24px rgba(20, 37, 63, 0.06);
  border-radius: 8px;
  overflow: scroll;
  scrollbar-width: none;
  z-index: 3;
  right: 24px;
  margin-top: 60px;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    gap: 16px;
    border-bottom: 1px solid #e2e8f0;
    background: white;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2d3748;
  }

  &__clear {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #0066ff;
    text-decoration: none;
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow:
      0 0 1px rgba(12, 26, 75, 0.1),
      0 20px 24px rgba(20, 37, 63, 0.06);
    border-radius: 8px;
    padding: 2rem;

    &-image {
      width: 72px;
      height: 72px;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #2d3748;
      width: 256px;
      height: 20px;
    }
  }
}
</style>
