<script setup lang="ts">
import { Modal } from 'ant-design-vue'

interface IProps {
  location: "reduction" | "auction"
}

const props = defineProps<IProps>()

const emit = defineEmits(['close', 'continue'])
</script>

<template>
  <Modal
    title="Оформление заказа"
    closable
    centered
    open="true"
    okText="Продолжить оформление"
    @ok="emit('continue')"
    cancelText="Не оформлять"
    @cancel="emit('close')"
  >
    При оформлении заказа у участника торга на {{ props.location === 'reduction' ? 'покупку' : 'продажу' }}, вы не сможете изменить
    условия поставки и состав заказа.
    <br />
    Продолжить оформление заказа?
  </Modal>
</template>
