<script lang="ts" setup>
import { Tag } from 'ant-design-vue'

import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'

interface IProps {
  title: string
  buttonText: string
  createdAt: string
  expirationDate: string
  isOpen: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])
</script>

<template>
  <div class="reduction-header">
    <div class="reduction-header__left">
      <CustomerRequestHeaderActionButton
        type="Back"
        @handleClick="emit('onBtnClick')"
      />
      <div class="reduction-header__left-title">
        {{ props.title }}
      </div>
    </div>
    <div class="reduction-header__right">
      <Tag color="blue" class="large-tag" v-if="isOpen"
        >Торг на покупку открыт до {{ props.expirationDate }}</Tag
      >
      <Tag v-else class="large-tag"> Торг на покупку закрыт </Tag>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reduction-header {
  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    &-title {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }
  }
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.large-tag {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  padding: 5px 12px;
}
</style>
