<script setup lang="ts">
import { Breadcrumb, Button, Tag } from 'ant-design-vue'
import router from '@/router'
import { computed, onMounted, ref } from 'vue'
import { UpOutlined, FileOutlined } from '@ant-design/icons-vue'
import OrderStatusTag from '@/components/Tags/OrderStatusTag.vue'
import DeliveryTypeTag from '@/components/Tags/DeliveryTypeTag.vue'
import { OrderAPI } from '@/api/OrderAPI'
import {
  dateToLocale,
  dayEnding,
  getSortedArrayByCreatedAt, roundNumber,
} from '@/helpers/scripts'
import OrderRate from '@/components/Rating/OrderRate.vue'
import { NewGeneralOrderStatus } from '@/types/enums'
import CheckboxTable from '@/components/Orders/CheckboxTable.vue'
import { OrderPositionAPI } from '@/api/OrderPositionAPI'
import { useStatus } from '@/composables/useStatus'
import OrderDocumentDrawer from '@/components/Orders/OrderDocumentDrawer.vue'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import {useRoute} from "vue-router";
import emitter from "@/emitter";

const showMoreInfo = ref(false)
const route = useRoute()
const id = computed(() => route.params.id)
const order = ref()
const sortedOrderPositions = ref<any>([])
const orderPositions = ref<any[]>()
const categories = ref<any>([])
const isOpenDocumentDrawer = ref(false)

const getOrder = async () => {
  await OrderAPI.getOneOrder(
    [],
    [
      'farmer',
      'farmer.users',
      'customer.users',
      'customer.company_rating',
      'generalOrder.delivery_address',
      'generalOrder.contact',
      'generalOrder.orders',
      'generalOrder.history',
      'partnership',
      'history',
      'shipping_address',
      'order_marks',
    ],
    Number(id.value),
  ).then((res) => {
    order.value = res
  })
}

const getItemsForTable = () => {
  const existedProducts = orderPositions?.value?.map((i: any) => {
    return { ...i.farmer_product, ...i, product: i.farmer_product }
  })
  existedProducts?.forEach((prod: any) => {
    if (categories.value.includes(prod.product.secondCategory)) {
      sortedOrderPositions.value
        .find((pos) => pos.category === prod.product.secondCategory)
        .items.push(prod)
    } else {
      categories.value.push(prod.product.secondCategory)
      sortedOrderPositions.value.push({
        category: prod.product.secondCategory,
        items: [prod],
      })
    }
  })

  return sortedOrderPositions.value
}

const getOrderPositions: () => Promise<any> = async () => {
  return OrderPositionAPI.getPositions(
    [['order_id', 'eq', id.value]],
    [
      'farmerProduct.productParameters.parameter',
      'history',
      'farmerProduct.specialPrices.customer',
      'farmerProduct.priceOptions',
    ],
      []
  ).then((res) => {
    orderPositions.value = res?.items?.map((order_position) => ({
      ...order_position,
      history: getSortedArrayByCreatedAt(order_position.history),
    }))
    getItemsForTable()
  })
}

const generalStatusData = computed(() => {
  const { setStatus, getStatusClass: getClass } = useStatus()
  setStatus(order.value!.status)
  const { cssClass, text } = getClass.value
  return { cssClass, text }
})

const hasCustomerMark = computed(() => {
  let customerMark
  order.value?.order_marks?.forEach((mark) => {
    if (mark.initiator_type == 'customer') {
      customerMark = mark
    }
  })
  if (customerMark) return customerMark
  else return false
})

const hasFarmerMark = computed(() => {
  let farmerMark
  order.value?.order_marks?.forEach((mark) => {
    if (mark.initiator_type == 'farmer') {
      farmerMark = mark
    }
  })
  if (farmerMark) return farmerMark
  else return false
})

const orderInitiator = computed(() => {
  const id = order.value?.general_order?.initiator_id ?? ''
  return order.value?.customer?.users?.find((user) => user.id == id)
})

const getOrderName = computed(() => {
  return `
  Заказ от
  ${dateToLocale(order.value?.general_order?.order_date)},
  ${order.value?.farmer.legal_form} «${order.value?.farmer.name}»
  `
})

const getPaletiingType = computed(() => {
  if (order.value?.palletizing_type === 'standard') return 'Паллетирование'
  if (order.value?.palletizing_type === 'euro') return 'Европаллеты'
  return ''
})

const showEndDate = computed(() => {
  if (
    order.value?.status == NewGeneralOrderStatus.SupplierCancelledOrder ||
    order.value?.status == NewGeneralOrderStatus.BuyerCancelledOrder ||
    order.value?.status == NewGeneralOrderStatus.OrderCompleted ||
    order.value?.status == NewGeneralOrderStatus.PartialOrderCompleted ||
    order.value?.status == NewGeneralOrderStatus.OrderNotCompleted
  ) {
    return dateToLocale(order.value?.updated_at)
  } else return '-'
})

const getNeedUnload = computed(() => {
  return order.value?.need_unload ? 'Разгрузка товаров поставщиком' : ''
})

emitter.on('refresh-token', async (e) => {
  await getOrder()
  await getOrderPositions()
})

onMounted(async () => {
  await getOrder()
  await getOrderPositions()
})

const calcRoute = computed(() => route)
const isShowMoreButton = computed(() => showMoreInfo.value || order.value?.comment || order.value?.general_order?.comment || order.value?.execution_comment || hasCustomerMark.value || hasFarmerMark.value)
</script>

<template :key="calcRoute.fullPath">
  <OrderDocumentDrawer
    v-model:open="isOpenDocumentDrawer"
    :orderId="order?.id"
    :partnershipId="order?.partnership.id"
    :farmerId="order?.farmer_id"
    :customerId="order?.customer_id"
  />

  <div class="header">
    <Breadcrumb>
      <BreadcrumbItem>
        <a @click="router.push('/admin/orders')"> Список заказов </a>
      </BreadcrumbItem>
      <span class="divider"> / </span>
      <BreadcrumbItem class="title"> {{ getOrderName }} </BreadcrumbItem>
    </Breadcrumb>
    <div class="title-and-button">
      <div class="title">
        {{ getOrderName }}
      </div>
      <Button @click="isOpenDocumentDrawer = true" class="documents">
        <template #icon> <FileOutlined /> </template>
        Документы заказа
      </Button>
    </div>
  </div>

  <div class="order-info">
    <div class="title-and-button">
      <div class="title">
        Данные заказа
        <Button
            v-if="isShowMoreButton"
          class="description-button"
          @click="showMoreInfo = !showMoreInfo"
        >
          <UpOutlined :class="showMoreInfo ? '' : 'revert'" />
        </Button>
      </div>
    </div>
    <div class="main-info">
      <div class="info-block">
        <div class="info-item">
          <div class="title">Покупатель</div>
          <p
            class="link-to-user"
            @click="router.push(`/admin/users/${order?.customer?.users[0].id}`)"
          >
            {{ order?.customer?.legal_form }} «{{ order?.customer?.name }}»
          </p>
        </div>
        <div class="info-item">
          <div class="title">Поставщик</div>
          <p
            class="link-to-user"
            @click="router.push(`/admin/users/${order?.farmer?.users[0].id}`)"
          >
            {{ order?.farmer?.legal_form }} «{{ order?.farmer?.name }}»
          </p>
        </div>
        <div class="info-item">
          <div class="title">Статус контрагентов</div>
          <Tag v-if="order?.partnership" color="success">
            <PartnerIcon />
            Договор заключен
          </Tag>
          <Tag v-else> Договор не заключен </Tag>
        </div>
      </div>

      <div class="info-block">
        <div class="info-item">
          <div class="title">Статус заказа</div>
          <OrderStatusTag :status="order?.status" />
        </div>
        <div class="info-item">
          <div class="title">Способ получения</div>
          <DeliveryTypeTag :delivery-type="order?.delivery_method" />
        </div>
        <div class="info-item" v-if="order?.delivery_method != 'Самовывоз'">
          <div class="title">Оплата доставки</div>
          <div class="description">
            {{
              order?.delivery_payment
                ? 'Оплата доставки включена в стоимость'
                : 'Доставка оплачивается отдельно'
            }}
          </div>
        </div>
        <div class="info-item" v-if="order?.delivery_method != 'Самовывоз'">
          <div class="title">Адрес доставки</div>
          <div class="description">
            {{ order?.general_order?.delivery_address?.street }}
          </div>
        </div>
        <div class="info-item" v-if="order?.delivery_method == 'Самовывоз'">
          <div class="title">Адрес отгрузки</div>
          <div class="description">
            {{ order?.shipping_address?.street }}
          </div>
        </div>
      </div>

      <div class="info-block">
        <div class="info-item">
          <div class="title">Сумма заказа</div>
          <div class="description">{{ roundNumber(order?.price) }} ₽</div>
        </div>
        <div class="info-item">
          <div class="title">Условия оплаты</div>
          <div class="description">
            {{
              order?.payment_type == 'cash' ? 'Наличными' : 'Безналичный расчёт'
            }}
          </div>
          <div v-if="order?.is_payment_delayed" class="description">
            Отстрочка {{ order?.payment_delay_days }}
            {{ dayEnding(order?.payment_delay_days) }}
          </div>
        </div>
        <div class="info-item" v-if="getPaletiingType || getNeedUnload">
          <div class="title">Услуги</div>
          <div class="description">{{ getPaletiingType }}</div>
          <div class="description">{{ getNeedUnload }}</div>
        </div>
      </div>

      <div class="info-block">
        <div class="info-item">
          <div class="title">Дата создания</div>
          <div class="description">
            {{ dateToLocale(order?.general_order?.order_date) }}
          </div>
        </div>
        <div class="info-item">
          <div class="title">Дата получения</div>
          <div class="description">
            {{ dateToLocale(order?.general_order?.delivery_date) }}
          </div>
        </div>
        <div class="info-item">
          <div class="title">Дата завершения</div>
          <div class="description">{{ showEndDate }}</div>
        </div>
      </div>
    </div>
    <div class="comments" v-if="showMoreInfo">
      <div class="comment" v-if="order.comment">
        <div class="title">Комментарий от поставщика</div>
        <div class="description">{{ order.comment }}</div>
      </div>
      <div class="comment" v-if="order?.general_order.comment">
        <div class="title">Комментарий от покупателя</div>
        <div class="description">{{ order?.general_order.comment }}</div>
      </div>
    </div>
    <div class="ratings" v-if="showMoreInfo">
      <div class="rating-container" v-if="hasCustomerMark">
        <div class="title">Оценка от покупателя</div>
        <OrderRate :comment="order.execution_comment" :mark="hasCustomerMark" />
      </div>
      <div class="rating-container" v-if="hasFarmerMark">
        <div class="title">Оценка от поставщика</div>
        <OrderRate :mark="hasFarmerMark" />
      </div>
    </div>
  </div>

  <div class="tables-container">
    <div
      v-for="position in sortedOrderPositions"
      :key="position"
      class="table-category"
    >
      <p class="table-title">{{ position.category }}</p>
      <CheckboxTable
        :customerId="order.customer_id"
        :category="position.category"
        :items="position.items"
        :order-status="generalStatusData.text"
        :status="order.status"
        :isCanEditOrder="false"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
.link-to-user {
  width: 100%;
  word-break: break-word;
  line-height: 1.5;
  color: #1677ff;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.link-to-user:hover {
  color: #69b1ff;
}

.description-button {
  padding: 0 10px;

  :deep(.anticon-up) {
    transition: all 0.3s ease;
    &.revert {
      transform: rotate(180deg);
    }
  }
}

.header {
  width: 100%;
  padding: 16px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;

  .divider {
    margin: 0 8px 0 8px;
  }

  .title {
    color: #000000e0;
  }

  .title-and-button {
    position: relative;

    .title {
      margin-top: 24px;
      font-weight: 600;
      font-size: 20px;
    }

    .documents {
      position: absolute;
      top: -12px;
      right: 24px;
    }
  }
}

.order-info {
  margin: 24px;
  padding: 24px;
  height: fit-content;
  background-color: #ffffff;

  .title-and-button {
    display: flex;
    position: relative;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .description-button {
      position: absolute;
      right: 0;
      padding: 4px 10px 4px 10px;
    }
  }

  .main-info {
    margin: 16px 0 16px 0;
    display: flex;
    gap: 20px;

    .info-block {
      display: grid;
      gap: 16px;
      height: fit-content;
      width: 100%;

      .info-item {
        display: grid;
        height: auto;
        min-height: 54px;
        gap: 8px;
      }
    }

    .ant-btn {
      padding-left: 0;
      width: fit-content;
    }

    .ant-tag {
      width: fit-content;
    }

    .title {
      color: #00000073;
    }
  }

  .comments {
    margin-top: 16px;
    display: grid;
    gap: 20px;

    .comment {
      display: grid;
      gap: 8px;

      .title {
        color: #00000073;
      }
    }
  }

  .ratings {
    margin-top: 16px;
    display: flex;
    gap: 16px;

    .rating-container {
      padding: 12px;
      width: 100%;
      border: 1px solid #0000000f;
      border-radius: 8px;

      .title {
        color: #00000073;
        margin-bottom: 4px;
      }
    }
  }
}

.tables-container {
  margin: 24px;

  .table-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: $gray-800;
    margin-bottom: 12px;
  }
}
</style>
