<script lang="ts" setup>
import {
  outlineBlackButton,
  primaryButton,
  secondaryBlueButton,
  underlinedWithoutBGButton,
} from '@/assets/EgalStyles/EButton'
import { computed, h, ref } from 'vue'
import {
  NewGeneralOrderStatus,
  PartnerShipStatus,
  TagType,
} from '@/types/enums'
import {
  compare_price_if_equal,
  formatPrice,
  get_sorted_by_is_canceled,
  getStatusType,
  is_orders_has_difference,
  roundNumber,
} from '@/helpers/scripts'
import { Document } from '@/types/types'
import {
  IContact,
  IFarmerProduct,
  IGeneralOrder,
  IOrder,
  IOrderPosition,
} from '@/types/interfaces'
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import DocumentSignedIcon from '@/components/Icons/DocumentSignedIcon.vue'
import CustomerOrderPositionItem from '@/components/Customer/CustomerOrderPositionItem.vue'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import { Button, Modal, Tag } from 'ant-design-vue'
import { StarOutlined } from '@ant-design/icons-vue'
import SavedRate from '@/components/Rating/SavedRate.vue'
import PersonIcon from '@/components/Icons/PersonIcon.vue'
import documentIcon from '@/assets/img/document.svg'
import SupplierDelivery from '@/components/Icons/SupplierDelivery.vue'
import SelfDelivery from '@/components/Icons/SelfDelivery.vue'
import EditOrderPositionDrawer from '@/components/Orders/EditOrderPositionDrawer.vue'
import MoreAboutOrder from '@/components/Customer/MoreAboutOrder.vue'
import EditOrderDrawer from '@/components/Customer/EditOrderDrawer.vue'
import FarmerChangeDeliveryMethodAlert from '@/components/Customer/FarmerChangeDeliveryMethodAlert.vue'
import RejectFromOrder from '@/components/Orders/RejectFromOrder.vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'

interface Props {
  order: IOrder
  isInitiator: boolean
  general_order: IGeneralOrder
  deliveryAddress: {
    contact_number: string
    street: string
    comment: string
    id: number
    name: string
  }
  orderDocument: Document | undefined
  is_complex_order: boolean
}

interface ISelectViewProduct {
  product: IFarmerProduct
  orderQuantity: string
}

const props = defineProps<Props>()
const emit = defineEmits([
  'open-product',
  'confirm',
  'sign-contract',
  'upload-documents',
  'cancel',
  'cancel-all',
  'open-documents',
  'other-documents',
  'sent-accept-status',
  'supplier-contract-signed',
  'changeOrderStatus',
  'isChangesConfirm',
  'DoneOrder',
  'partlyDoingOrder',
  'notDoneOrder',
  'rejectOrder',
  'rateCanceled',
  'openFarmerInfo',
  'deleteOrderPosition',
  'restoreOrderPosition',
  'editOrderPosition',
  'approveOrder',
  'save_order_changes',
  'edit_general_order',
])

const route = useRoute()
const isOpenProductDrawer = ref(false)
const selectedViewProduct = ref<IFarmerProduct>()
const selectedOrderQuantity = ref<string>('')

const { getCoreCookie } = useJWTDecode()

const selectViewProduct = ({ product, orderQuantity }: ISelectViewProduct) => {
  selectedViewProduct.value = product
  selectedOrderQuantity.value = orderQuantity
  isOpenProductDrawer.value = true
}

const closeProductDrawer = () => {
  selectedViewProduct.value = undefined
  isOpenProductDrawer.value = false
}

const is_edit_order = ref(false)
const buttonsLayout = computed(() => {
  const style = {
    display: 'flex',
    flexWrap: 'wrap',
    gridTemplateColumns: '',
    gap: '12px',
  }
  if (getStatusClass.value.length === 3) {
    style.display = 'inherit'
    style.gridTemplateColumns = '1fr 1fr'
    style.gap = '12px'
  }
  return style
})

/**
 * Определения стиля и текста у бейджа со статусом заказа
 */
const getStatusClass = computed(() => {
  const buttonsArray = []
  const buttonConfig = {
    height: '32px',
    width: '100%',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '5px',
    borderRadius: '8px',
    justifyContent: 'center',
    fontFamily: 'Inter',
  }
  switch (props.order.status) {
    // Поставщик подписал договор
    case NewGeneralOrderStatus.SupplierContractSigned:
      buttonsArray.push({
        styleConfig: {
          ...primaryButton,
          ...buttonConfig,
        },
        // text: NewGeneralOrderStatus.SupplierContractSigned,
        buttonText:
          props.order.partnership.signed_earlier === 'signed_out'
            ? 'Подтвердить партнёрство'
            : 'Подписать договор с поставщиком',
        buttonData: { disabled: false },
        buttonHandler: () =>
          emit(
            'supplier-contract-signed',
            props.order.partnership.id,
            props.order.id,
            props.order.farmer_id,
            props.order.partnership.status,
            props.order.partnership.signed_earlier,
          ),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться от заказа',
        buttonData: { disabled: false },
        buttonHandler: () => emit('rejectOrder', props.order.id),
      })
      break
    //На доставке
    case NewGeneralOrderStatus.OrderInDelivery:
      buttonsArray.push({
        styleConfig: {
          ...primaryButton,
          ...buttonConfig,
        },
        buttonText: 'Заказ полностью выполнен',
        buttonData: { disabled: false },
        buttonHandler: () => emit('DoneOrder', props.order.id),
      })
      buttonsArray.push({
        styleConfig: {
          ...secondaryBlueButton,
          ...buttonConfig,
        },
        buttonText: 'Заказ выполнен частично',
        buttonData: { disabled: false },
        buttonHandler: () => emit('partlyDoingOrder', props.order.id),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Заказ не выполнен',
        buttonData: { disabled: false },
        buttonHandler: () => emit('notDoneOrder', props.order.id),
      })
      break
    case NewGeneralOrderStatus.SupplierModifiedOrder:
      if (order_was_edited_by_customer.value) {
        buttonsArray.push({
          styleConfig: {
            ...primaryButton,
            ...buttonConfig,
          },
          buttonText: 'Отправить на согласование',
          buttonData: { disabled: false },
          buttonHandler: () => send_to_approve(),
        })
      } else {
        buttonsArray.push({
          styleConfig: {
            ...primaryButton,
            ...buttonConfig,
          },
          buttonText: 'Согласен с изменениями',
          buttonData: { disabled: false },
          buttonHandler: () => {
            if (validate_order_delivery_address()) {
              emit('isChangesConfirm', {
                order_id: props.order.id,
                customer_id: props.order.id,
                partner_status: props.order.partnership.status,
              })
            }
          },
        })
      }
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Редактировать условия заказа',
        buttonData: { disabled: false },
        buttonHandler: () => (is_edit_order.value = true),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться от заказа',
        buttonData: { disabled: false },
        buttonHandler: () => emit('rejectOrder', props.order.id),
      })
      break
    case NewGeneralOrderStatus.New:
    case NewGeneralOrderStatus.OrderPicking:
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться от заказа',
        buttonData: { disabled: false },
        buttonHandler: () => emit('rejectOrder', props.order.id),
      })
      break
    case NewGeneralOrderStatus.ContractUnderSigning:
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться от заказа',
        buttonData: { disabled: false },
        buttonHandler: () => emit('rejectOrder', props.order.id),
      })
      break
  }
  return buttonsArray
})
const goToProductPage = (position: any) => {
  if (getFarmerProduct(position).is_deleted) {
    return
  }
  emit('open-product', getFarmerProduct(position), props.item)
}

const need_to_pick_address_modal = ref(false)
const send_to_approve = () => {
  if (validate_order_delivery_address()) {
    emit('approveOrder', props.order.id)
  }
}

const validate_order_delivery_address = () => {
  if (
    props.order.delivery_method === 'Доставка фермером' &&
    !props.deliveryAddress
  ) {
    need_to_pick_address_modal.value = true
    return false
  }
  return true
}
const getFarmerProduct = (position: any) => {
  return props.item.farmer.farmer_products.find(
    (prod: any) => prod.id === position.farmer_product_id,
  )
}

const orderDocuments = ref<Document[]>()
const contacts = ref<IContact[] | undefined>()

const showOrderDocuments = () => {
  emit(
    'open-documents',
    props.order.partnership.id,
    props.order.id,
    props.order.farmer_id,
    props.order.partnership.status,
    props.order.partnership.signed_earlier,
    props.order.farmer.legal_form + ' ' + props.order.farmer.name,
  )
}
const { cookies } = useCookies()

const hasCustomerMark = computed(() => {
  let customerMark
  props.order.order_marks?.forEach((mark) => {
    if (mark.initiator_type == 'customer') {
      customerMark = mark
    }
  })
  if (customerMark) return customerMark
  else return false
})

const isCompleted = computed(() => {
  let status = props.order.status
  return status.includes('отменен') || status.includes('выполнен')
})

const order_was_edited_by_customer = computed(() => {
  const general_order_was_edited =
    props.general_order.history![0]?.initiator_type === 'customer' &&
    is_general_order_has_difference(
      props.general_order,
      props.general_order.history![0]?.old_entity,
    )

  const order_was_edited =
    props.order.history[0]?.initiator_type === 'customer' &&
    is_orders_has_difference(props.order, props.order.history[0]?.old_entity)

  const order_positions_was_edited = props.order.order_positions?.some(
    (position) =>
      position.history![0]?.initiator_type === 'customer' &&
      position.is_changed,
  )

  return (
    general_order_was_edited || order_was_edited || order_positions_was_edited
  )
})

const is_general_order_has_difference = (
  general_order1: IGeneralOrder,
  general_order2: IGeneralOrder,
) => {
  return (
    general_order1.delivery_address?.id !==
      general_order2.delivery_address?.id ||
    general_order1.contact?.id !== general_order2.contact?.id ||
    general_order1.comment !== general_order2.comment ||
    general_order1.delivery_date !== general_order2.delivery_date
  )
}

const is_can_edit_order = computed(() => {
  return props.order.status === NewGeneralOrderStatus.SupplierModifiedOrder
})

const is_edit_drawer = ref(false)
const editable_order_position = ref<IOrderPosition | null>(null)
const edit_order_position = (position: IOrderPosition) => {
  is_edit_drawer.value = true
  editable_order_position.value = position
}
const close_edit_drawer = () => {
  is_edit_drawer.value = false
  editable_order_position.value = null
}

const open_product_drawer = (product: IFarmerProduct) => {
  selectedViewProduct.value = product
  isOpenProductDrawer.value = true
}

const no_products_left_modal = ref(false)
const delete_order_position = (order_position: IOrderPosition) => {
  const one_available_product_left =
    props.order.order_positions!.filter(
      (order_position) => !order_position.is_canceled,
    ).length === 1
  if (one_available_product_left) {
    no_products_left_modal.value = true
  } else {
    emit('deleteOrderPosition', order_position)
  }
}

const handleGoToPartner = () => {
  router.push({
    path: `/partners/${props.order.farmer.id}`,
    query: { redirectPath: `history/${route.params.id}` },
  })
}

const order_price = computed(() => {
  const last_change = props.order.history[0]
  if (
    (props.order.status === NewGeneralOrderStatus.OrderPicking ||
      props.order.status === NewGeneralOrderStatus.New) &&
    last_change?.initiator_type === 'farmer'
  ) {
    return {
      price: last_change.old_entity.price,
      previous_price: compare_price_if_equal(
        props.order?.history[1]?.old_entity?.price,
        last_change.old_entity.price,
      ),
    }
  } else {
    return {
      price: props.order.price,
      previous_price: compare_price_if_equal(
        last_change?.old_entity?.price,
        props.order.price,
      ),
    }
  }
})

const orderWasEditedByFarmer = computed(() => {
  const order_was_edited =
    props.order.history[props.order.history.length - 1]?.initiator_type ===
      'farmer' &&
    is_orders_has_difference(
      props?.order,
      props?.order?.history[props.order.history.length - 1]?.old_entity,
    )

  return order_was_edited && props.order.status === NewGeneralOrderStatus.New
})
</script>

<template>
  <div class="order-item-card">
    <div class="order-info-column">
      <div class="order-info-column-badges">
        <CustomTag
          :shadow="false"
          :text="order.status"
          :type="getStatusType(order.status)"
          :weight="'normal'"
        />
        <Tag>
          <template #icon>
            <SupplierDelivery
              v-if="
                order.delivery_method === 'Доставка фермером' &&
                !orderWasEditedByFarmer
              "
              fill="#2D3748"
            />
            <SelfDelivery
              v-if="
                order.delivery_method === 'Самовывоз' && !orderWasEditedByFarmer
              "
              fill="#2D3748"
            />
            <SupplierDelivery
              v-if="
                order?.history[props.order.history.length - 1]?.old_entity
                  .delivery_method === 'Доставка фермером' &&
                orderWasEditedByFarmer
              "
              fill="#2D3748"
            />
            <SelfDelivery
              v-if="
                order?.history[props.order.history.length - 1]?.old_entity
                  ?.delivery_method === 'Самовывоз' && orderWasEditedByFarmer
              "
              fill="#2D3748"
            />
          </template>
          {{
            !orderWasEditedByFarmer
              ? order?.delivery_method.split(' ')[0]
              : order?.history[
                  props.order.history.length - 1
                ]?.old_entity?.delivery_method.split(' ')[0]
          }}
        </Tag>
        <CustomTag
          :shadow="true"
          :text="'Договор подписан'"
          :type="TagType.LIGHTGREEN"
          weight="thin"
          v-if="order.partnership.status === PartnerShipStatus.SIGNED_CUSTOMER"
        >
          <template #icon>
            <DocumentSignedIcon fill="#11A162" />
          </template>
        </CustomTag>
      </div>
      <FarmerChangeDeliveryMethodAlert
        :is_complex_order="is_complex_order"
        :order="order"
        :delivery_address="general_order.delivery_address"
        @edit_general_order="emit('edit_general_order')"
      />
      <div class="order-info-column-price">
        <span>Сумма заказа:</span>
        <p v-if="order_price.previous_price">
          <TextWithOldPopover
            :text="formatPrice(order_price.price)"
            :old_text="formatPrice(order_price.previous_price)"
          />
        </p>
        <p v-else>{{ formatPrice(order_price.price) }}</p>
      </div>
      <div class="order-info-column-information">
        <div class="block-with-icon clickable" @click="handleGoToPartner">
          <PersonIcon fill="#0066ff" />
          <EButton
            :style-config="{
              fontSize: '14px',
              fontWeight: '400',
              ...underlinedWithoutBGButton,
              padding: 0,
              lineHeight: '17px',
            }"
            @click="true"
          >
            {{ order.farmer.legal_form + ' ' + order.farmer.name }}
          </EButton>
          <div class="rating">
            <SimpleRate :company_rating="order.farmer.company_rating" />
          </div>
        </div>
        <div class="block-with-icon clickable">
          <img :src="documentIcon" loading="lazy" alt="document" />
          <EButton
            :style-config="{
              fontSize: '14px',
              fontWeight: '400',
              ...underlinedWithoutBGButton,
              padding: 0,
              lineHeight: '17px',
            }"
            @click="showOrderDocuments"
          >
            Документы заказа
          </EButton>
        </div>
        <MoreAboutOrder :order="order" :deliveryAddress="deliveryAddress" />
        <SavedRate
          v-if="hasCustomerMark"
          :mark="hasCustomerMark"
          :comment="order.execution_comment"
        />
      </div>
      <div
        :style="buttonsLayout"
        class="order-info-column-buttons"
        v-if="isInitiator"
      >
        <EButton
          v-for="button in getStatusClass"
          :key="button.buttonText"
          :data="button.buttonData"
          :styleConfig="{ ...button.styleConfig }"
          @click="button.buttonHandler"
        >
          {{ button.buttonText }}
        </EButton>
      </div>
      <Button
        v-if="isCompleted && !hasCustomerMark && isInitiator"
        :icon="h(StarOutlined)"
        class="rate-order"
        @click="
          emit('rateCanceled', {
            id: props.order.id,
            status: NewGeneralOrderStatus.SupplierCancelledOrder,
          })
        "
      >
        Оценить заказ
      </Button>
    </div>
    <div class="order-positions-column">
      <CustomerOrderPositionItem
        v-for="(orderPosition, idx) in get_sorted_by_is_canceled(
          order.order_positions,
        )"
        :key="orderPosition.id"
        :order="order"
        :farmer_product="orderPosition.farmer_product"
        :is_canceled="orderPosition.is_canceled"
        :is_changed="orderPosition.is_changed"
        :is_can_edit="is_can_edit_order"
        :price="orderPosition.price"
        :quantity="orderPosition.quantity"
        :unit_price="orderPosition.unit_price"
        :vat_percent="orderPosition.vat_percent"
        :is_special_price="orderPosition.is_special_price_used"
        :history="orderPosition.history"
        @selectViewProduct="selectViewProduct"
        @edit="edit_order_position(orderPosition)"
        @restore="emit('restoreOrderPosition', orderPosition)"
        @delete="delete_order_position(orderPosition)"
      >
        <template v-if="idx" #divider>
          <div class="positions-divider" />
        </template>
      </CustomerOrderPositionItem>
    </div>
  </div>

  <DrawerForm
    :product="selectedViewProduct"
    @closeDrawer="closeProductDrawer"
    :isFarmer="getCoreCookie()?.company_type === 'farmer'"
    :open="isOpenProductDrawer"
    :orderQuantity="selectedOrderQuantity"
  />

  <EditOrderPositionDrawer
    :customerId="order?.customer_id"
    :is-farmer="false"
    :is-open="is_edit_drawer"
    :orderPosition="editable_order_position"
    @close="close_edit_drawer"
    @update="emit('editOrderPosition', $event)"
    @openProductDrawer="open_product_drawer"
  />

  <EditOrderDrawer
    v-model:open="is_edit_order"
    :order="order"
    :is_complex_order="is_complex_order"
    :delivery_address="general_order.delivery_address"
    @edit_general_order="emit('edit_general_order')"
    @save_changes="emit('save_order_changes', $event)"
  />

  <Modal
    v-model:open="need_to_pick_address_modal"
    width="440px"
    centered
    :bodyStyle="{ marginBottom: '24px' }"
    title="Невозможно принять изменения"
  >
    Способ получения изменён с самовывоза на доставку. Отмените заказ или
    измените способ получения.
    <template #footer>
      <Button
        @click="need_to_pick_address_modal = false"
        :style="{ marginLeft: 'auto' }"
        >Закрыть</Button
      ></template
    >
  </Modal>

  <RejectFromOrder
    v-model:open="no_products_left_modal"
    @ok="emit('rejectOrder', props.order.id)"
  />
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.order-item-card {
  display: grid;
  grid-template-columns: 520px 1fr;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid $gray-300;

  .order-info-column {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    &-badges {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      :deep(.ant-tag > .anticon + span) {
        margin: 0;
      }
    }

    &-price {
      position: relative;
      span {
        @include p5;
        color: $gray-600;
      }

      p {
        margin-top: 4px;
        color: $gray-800;
        font-weight: 700;
        font-size: 20px;
        line-height: 135%;
      }
    }

    &-information {
      display: grid;
      row-gap: 16px;

      .block-with-icon {
        display: grid;
        grid-template-columns: 20px auto;
        column-gap: 4px;
        align-items: center;

        &.clickable {
          cursor: pointer;
        }

        &.address-block {
          align-items: flex-start;

          p {
            margin-top: 2px;
          }

          .address-info {
            font-weight: 400;
            margin-top: 8px;
            font-size: 14px;
            line-height: 150%;
            color: $gray-600;
            display: flex;
            column-gap: 8px;
            margin-bottom: 8px;

            &-comment {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: $gray-600;
            }
          }
        }
      }
    }

    &-buttons {
      display: grid;
    }
  }

  .order-positions-column {
    border-left: 1px solid $gray-300;
    margin-left: 24px;
    padding-left: 24px;

    .positions-divider {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $gray-300;
    }
  }
}

.rate-order {
  width: 169px;
  height: 40px;
}

.wrapper {
  display: flex;
  gap: 32px;
  width: 100%;
}

.card {
  background: #ffffff;
  border: 1px solid $gray-300;
  border-radius: 12px;
  padding: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  width: 398px;
  height: fit-content;

  &__status {
    margin-bottom: 8px;
    border-radius: 4px;
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
    margin-bottom: 4px;
  }

  &_details {
    color: #0066ff;
    line-height: 17px;
    text-decoration: underline dotted;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  &__row {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
    margin-bottom: 11px;

    svg {
      color: $gray-500;
      width: 16px;
      height: 16px;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &__button-icon {
    stroke: $primary-accent !important;
    margin-right: 10px;
  }

  &__footer {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid $gray-300;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-link:first-child {
      //width: 100%;
      text-decoration: none;
    }

    &-link-documents {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #0066ff;
      cursor: pointer;
    }

    .egal-button {
      justify-content: center;
    }

    &--direction-column {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      width: 100%;
    }

    &--direction-row {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
    }

    &:empty {
      padding: 0;
      margin: 0;
      border-top: none;
    }
  }
}

.orders {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order {
  display: flex;
  gap: 20px;

  &__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    background: #fafafa url('@/assets/img/cow.svg') no-repeat center;
    background-size: 50%;
    border-radius: 16px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $default-accent;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-left: 8px;
      fill: $default-accent !important;
    }

    &:hover {
      color: $hover-accent;

      svg {
        fill: $hover-accent !important;
      }
    }
  }
  & .disable {
    cursor: default;
    color: $gray-800;
    svg {
      margin-left: 8px;
      fill: $gray-600 !important;
    }
  }

  &__row {
    display: flex;
    gap: 12px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray-600;
    width: 240px;
    display: flex;
    align-items: center;
  }

  &__red-text {
    color: $danger;

    svg {
      margin-bottom: 0;
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  &__old {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;
    color: $danger;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
  }
}

.red-btn {
  color: $danger;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    color: $hover-danger;
  }
}

.customerOrder_loader {
  display: flex;
  justify-content: center;
}

.buttons {
  display: flex;
  gap: 12px;

  &__button {
    position: relative;
    margin-bottom: 20px;
    &--text {
      border-bottom: 1px dashed $primary-accent;
      font-weight: 400;
      line-height: 19px;
    }
  }
}

.farmer-info {
  &__form {
    min-height: 380px;
    width: 464px;
    overflow-y: scroll;
    max-height: 600px;

    &_header {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    &_data {
      &_info {
        margin: 20px 0 48px 0;
        display: flex;
        flex-direction: column;

        &_block {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          &_title {
            color: #718096;
            font-size: 14px;
            line-height: 17px;
          }

          &_descr {
            font-weight: 500;
            width: 260px;
            font-size: 14px;
            line-height: 130%;
          }
        }
      }
    }

    &_contact {
      &_block {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-top: 17px;
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 21px;

        &_name {
          display: flex;
          align-items: flex-start;
          gap: 11px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;

          img {
            filter: opacity(0.35);
            width: 15px;
          }
        }

        &_phone {
          display: flex;
          gap: 11px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }

      &_block:first-child {
        margin-top: 21.5px;
      }

      &_block:last-child {
        margin-bottom: 21.5px;
      }
    }
  }
}
.price-crossed {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $gray-500;
  text-decoration: line-through;
  position: absolute;
  left: 0;
  top: 100%;
}

:deep(.ant-collapse-expand-icon) {
  padding-inline-end: 0 !important;
  position: absolute;
  right: 16px;
}

:deep(.ant-collapse) {
  margin-top: 8px;
}

.rating {
  margin-top: 4px;
  transform: translateX(-3px);
}

.person-icon {
  img {
    color: #11a162;
  }
}
</style>
