<script lang="ts" setup>

import NoProduct from '@/assets/img/statuses/NoProduct.svg'
import NoAnswer from '@/assets/img/statuses/NoAnswer.svg'
import AnswerWaiting from '@/assets/img/statuses/AnswerWaiting.svg'
import AnswerReceived from '@/assets/img/statuses/AnswerReceived.svg'
import CustomTooltip from '@/components/Tooltip/CustomTooltip.vue'

type TStatus =
  | 'answer_waiting'
  | 'answer_received'
  | 'no_answer'
  | 'no_product'
  | undefined

interface IProps {
  status: TStatus
}

const props = defineProps<IProps>()

const getStatusSrc = () => {
  const statuses = {
    no_product: NoProduct,
    no_answer: NoAnswer,
    answer_waiting: AnswerWaiting,
    answer_received: AnswerReceived,
  }
  return props.status ? statuses[props.status] : ''
}

const getTooltipText = () => {
  const statuses = {
    no_product: 'Товара нет в вашем каталоге',
    no_answer: 'Вы отказались от поставки товара',
    answer_waiting: 'Вы не указали цены на товары',
    answer_received: 'Вы указали цены на товары',
  }
  return props.status ? statuses[props.status] : ''
}
</script>

<template>
  <CustomTooltip
    :hover="true"
    :message="getTooltipText()"
    :show-popper="true"
    placement="top"
    class="status-tooltip"
  >
    <img :src="getStatusSrc()" :alt="props.status" loading="lazy" />
  </CustomTooltip>
</template>
