<script setup lang="ts">
import { computed, PropType } from 'vue'
import { IRoutLink } from '@/types/interfaces'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/store'
import { catalogStore } from '@/store/catalogStore'
import PhoneIcon from '@/components/Icons/PhoneIconNotAuth.vue'
import MailIcon from '@/components/Icons/MailIcon.vue'
import TelegramIcon from '@/components/Icons/TelegramIcon.vue'
import {
  OWNER_EMAIL,
  PRIVACY_POLICY_LINK,
  TELEGRAM_LINK,
} from '@/model/constants'
import Rustore from '@/assets/img/Rustore.svg'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

const { cookies } = useCookies()

const props = defineProps({
  links: { type: Array as PropType<IRoutLink[]>, default: () => [] } || null,
})

const useCatalogStore = catalogStore()

const route = useRoute()

const { isVerification } = storeToRefs(useStore())
const umt = cookies.get('umt')
const chat = cookies.get('chat')
const isAuth = computed(() => umt && chat)

const getRouterLinks = computed(() => {
  return isAuth.value ? props.links : null
})

const calcNavToLink = (label: string, to: string) => {
  if (
    [
      'История заказов',
      'Запросы цен',
      'Партнёры',
      'Торги продажа',
      'Торги покупка',
      'Корзина',
    ].includes(label) &&
    !isVerification
  ) {
    return ''
  } else return to
}

const calcNavDisabled = (label: string) => {
  if (
    [
      'История заказов',
      'Запросы цен',
      'Партнёры',
      'Торги продажа',
      'Торги покупка',
      'Корзина',
    ].includes(label) &&
    !isVerification
  ) {
    return 'disabled-link'
  } else return ''
}

const toggleCatalog = (label: string) => {
  if (label === 'Корзина') {
    const { closeOpenCart } = useStore()
    closeOpenCart()
  }
  if (label === 'Каталог') {
    if (useCatalogStore.isOpen) useCatalogStore.isOpen = false
    else {
      setTimeout(() => {
        useCatalogStore.isOpen = true
      }, 100)
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <div
      class="container"
      :class="{ container_not_auth: !isAuth && route.path === '/' }"
    >
      <div class="contacts">
        <span class="title">Свяжитесь с нами, если нужна помощь</span>
        <div class="contacts-data">
          <div class="contact">
            <div :class="{ phone: isAuth, phone_green: !isAuth }">
              <PhoneIcon :fill="isAuth ? '#1677FF' : 'rgba(15, 158, 121, 1)'" />
              <p class="myContact">+7 913 871-12-39</p>
            </div>
            <span class="comment">Звонки принимаем с 9:00 до 21:00</span>
          </div>
          <div class="contact">
            <div :class="{ mail: isAuth, mail_green: !isAuth }">
              <MailIcon :fill="isAuth ? '#1677FF' : 'rgba(15, 158, 121, 1)'" />
              <p class="myContact">{{ OWNER_EMAIL }}</p>
            </div>
            <span class="comment">Средний ответ в течение часа</span>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="mobile__title">Мобильное приложение</div>
        <a href="https://www.rustore.ru/catalog/app/com.mobile" target="_blank">
          <img class="mobile__icon" :src="Rustore" alt="Rustore" />
        </a>
      </div>
      <div class="links-list" v-if="getRouterLinks">
        <router-link class="links-list__item" to="/"> Главная </router-link>
        <router-link
          class="links-list__item link"
          :to="calcNavToLink(link.label, link.to)"
          v-for="link in getRouterLinks as IRoutLink[]"
          :key="link.label"
          :class="calcNavDisabled(link.label)"
        >
          <div
            v-if="link.label === 'Каталог' || link.label === 'Корзина'"
            :style="{ cursor: 'pointer', height: '100%' }"
            class="link__label"
            @click="toggleCatalog(link.label)"
          >
            {{ link.label }}
          </div>

          <span v-else>{{ link.label }}</span>
        </router-link>
        <router-link class="links-list__item" to="/account">
          Профиль
        </router-link>
      </div>
      <div class="company">
        <div class="company-telegram">
          <a
            :href="TELEGRAM_LINK"
            :class="{ 'telegram-link': isAuth, 'telegram-link_green': !isAuth }"
            target="_blank"
          >
            <TelegramIcon
              :fill="isAuth ? '#0066FF' : 'rgba(15, 158, 121, 1)'"
            />
            <p class="myContact">{{ TELEGRAM_LINK.split('//')[1] }}</p>
          </a>
        </div>
        <div class="company-rights">
          <a class="company-name" :href="PRIVACY_POLICY_LINK" target="_blank">
            Политика конфиденциальности
          </a>
          <span class="company-name">
            © 2022-{{ new Date().getFullYear() }} ООО «Изи Ритейл»
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/style/variables';
@use '@/assets/style/mixins';

.mobile {
  display: flex;
  flex-direction: column;
  height: 88px;
  width: 200px;
  &__title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    text-align: center;
  }
}

.link__label {
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background: inherit;
  z-index: 2;
  border-top: 1px solid #e2e8f0;

  .container {
    display: flex;
    gap: 48px;
    padding: 48px 0 48px 0;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    background: white;
  }

  .container_not_auth {
    background: url('@/assets/img/footer-not-auth-bg.svg') no-repeat top center;
    background-size: 100% auto;
    padding: 48px 48px 48px 48px;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: center;

    .title {
      text-align: left;
      font-size: 16px;
      font-weight: 600;
    }
    .contacts-data {
      display: flex;
      margin-top: 27px;
      width: 100%;
      gap: 40px;

      .contact {
        display: inline-block;
        margin: 0;
        text-align: left;

        .phone_green,
        .mail_green {
          display: flex;
          align-items: center;
          height: 16px;
          color: rgba(15, 158, 121, 1);
          gap: 8px;
          margin-bottom: 5px;

          .myContact {
            font-weight: 500;
            font-size: 14px;
            width: max-content;
          }
        }

        .phone,
        .mail {
          display: flex;
          align-items: center;
          height: 16px;
          color: #1677ff;
          gap: 8px;
          margin-bottom: 5px;

          .myContact {
            font-weight: 500;
            font-size: 14px;
            width: max-content;
          }
        }

        .comment {
          color: #718096;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .links-list {
    padding-right: 274px;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(3, 17px);
    grid-column-gap: 56px;
    grid-row-gap: 18px;
    grid-auto-rows: 17px;
    align-items: start;
    grid-auto-flow: column;

    &__catalog {
      height: 100%;
    }

    &__item {
      @include mixins.p6();
      color: #000000a6;
      text-decoration: none;
      cursor: pointer;
      height: 100%;
      width: max-content;

      &:hover {
        color: #0066ff;
      }

      &.disabled-link {
        @include mixins.disabled-link;
      }
    }
  }
}
.company {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 424px;

  &-telegram {
    margin-bottom: 42px;

    .telegram-link {
      color: variables.$primary-accent;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      display: flex;
      column-gap: 8px;
    }

    .telegram-link_green {
      color: rgba(15, 158, 121, 1);
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      display: flex;
      column-gap: 8px;
    }
  }

  &-rights {
    display: flex;
    flex-direction: column;
    text-align: right;
    row-gap: 8px;

    .company-name {
      font-size: 12px;
      font-weight: 400;
      color: #718096;
      text-decoration: none;
    }
  }
}

.actions {
  display: flex;
  gap: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: variables.$gray-800;
  //margin-right: 48px;

  &__item {
    cursor: pointer;
  }

  svg {
    color: variables.$gray-800;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 6px;
  }
}
</style>
