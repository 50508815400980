<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { Document } from '@/types/types'
import DocumentItem from '@/components/Document/DocumentItem.vue'
import { IAccountUser } from '@/types/interfaces'
import { fontSize16, primaryButton } from '@/assets/EgalStyles/EButton'
import { CustomerAPI } from '@/api/CustomerAPI'
import { FarmerAPI } from '@/api/FarmerAPI'
import { CONTRACT_LINK } from '@/model/constants'
import { Roles } from '@/types/enums'

interface Props {
  userData: IAccountUser | Record<string, string>
  role: string
}

interface IContractResponse {
  individual_contract: boolean
  only_individual_contract: boolean
  id?: string
}

const props = defineProps<Props>()
const emit = defineEmits(['update'])
const contractDoc: Document = {
  file_body: CONTRACT_LINK,
  file_name: '',
}

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const userId = user?.company_id

const responseBody = ref<IContractResponse>({
  id: userId,
  individual_contract: false,
  only_individual_contract: false,
})

const initialResponseBody = ref<IContractResponse>({
  id: userId,
  individual_contract: false,
  only_individual_contract: false,
})
const toggleContract = (value: any) => {
  switch (value) {
    case '2':
      responseBody.value.individual_contract = true
      responseBody.value.only_individual_contract = false
      break
    case '3':
      responseBody.value.individual_contract = true
      responseBody.value.only_individual_contract = true
      break
    default:
      responseBody.value.individual_contract = false
      responseBody.value.only_individual_contract = false
  }
}

const sendContract = async () => {
  if (props.role === Roles.Customer) {
    await CustomerAPI.updateCustomer(responseBody.value).then(() => {
      setInitialResponseBody(responseBody.value)
      emit('update')
    })
  } else if (props.role === Roles.Farmer) {
    await FarmerAPI.updateFarmer(responseBody.value).then(() => {
      setInitialResponseBody(responseBody.value)
      emit('update')
    })
  }
}

const isChanged = computed(() => {
  if (
    responseBody.value.individual_contract ===
      initialResponseBody.value.individual_contract &&
    responseBody.value.only_individual_contract ===
      initialResponseBody.value.only_individual_contract
  ) {
    return false
  }
  return true
})

onMounted(() => {
  responseBody.value.individual_contract = props.userData.individual_contract
  responseBody.value.only_individual_contract =
    props.userData.only_individual_contract
  setInitialResponseBody(props.userData)
})
const setInitialResponseBody = (data: {
  individual_contract: boolean
  only_individual_contract: boolean
}) => {
  initialResponseBody.value.individual_contract = data.individual_contract
  initialResponseBody.value.only_individual_contract =
    data.only_individual_contract
}
</script>

<template>
  <div class="card org-card">
    <div class="card-header">Шаблон договора поставки</div>
    <div class="card-body">
      <div class="card-text">
        Наш юридический отдел подготовил шаблон Договора Поставки, который
        поможет вам быстро и безопасно заключать сделки с новыми контрагентами.
        <br />
        <br />
        Договор заключается при оформлении первого заказа с новым контрагентом,
        в договор сразу вносятся ваши данные и данные контрагента, вам остаётся
        только подписать его. В дальнейшем система нe запрашивает договор.
        Договор заключается до конца года, с возможностью пролонгации на
        следующий год.
        <br />
        <br />
        Если у вас уже есть свой договор поставки, то вы можете использовать
        его.
        <br />
        <br />
        Вы можете скачать и ознакомиться с договором, если у вас возникнут
        вопросы — обратитесь в техподдержку, мы всегда вам поможем.
      </div>
      <DocumentItem :document-item="contractDoc" :title="'supplyContract'" />
    </div>
    <div class="card-header">Укажите какой договор вы будете использовать</div>
    <div class="card-body">
      <ERadio
        :data="{
          value: '1',
          name: 'contract',
          checked:
            !responseBody.individual_contract &&
            !responseBody.only_individual_contract,
        }"
        @change="toggleContract"
        >У меня нет своего договора, готов работать по любому договору
      </ERadio>
      <ERadio
        :data="{
          value: '2',
          name: 'contract',
          checked:
            responseBody.individual_contract &&
            !responseBody.only_individual_contract,
        }"
        @change="toggleContract"
        >У меня есть свой договор поставки, но готов работать по любому договору
      </ERadio>
      <ERadio
        :data="{
          value: '3',
          name: 'contract',
          checked:
            responseBody.individual_contract &&
            responseBody.only_individual_contract,
        }"
        @change="toggleContract"
        >Работаю только по своему договору
      </ERadio>
    </div>
    <div class="card-footer">
      <EButton
        :data="{
          disabled: !isChanged,
        }"
        :style-config="{
          ...primaryButton,
          ...fontSize16,
          weight: 500,
          width: 'fit-content',
          padding: '12px 18px',
          disabled: {
            backgroundColor: '#E2E8F0',
            borderColor: '#E2E8F0',
            color: '#A0AEC0',
          },
        }"
        @click="sendContract"
        >Сохранить изменения
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import 'accountStyles';

.card {
  padding: 48px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  box-sizing: border-box;
  max-width: 706px;

  &-header {
    color: $gray-800;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
  }

  &-body {
    display: flex;
    row-gap: 17px;
    flex-direction: column;
    margin-bottom: 40px;

    .card-text {
      @include p3;
    }

    :deep(.e-radio__text) {
      @include p3;
      line-height: 19px;
    }

    :deep(.e-radio--left .text:before) {
      width: 1em;
      height: 1em;
    }
  }
}
</style>
