<script setup lang="ts">
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  FormInstance,
  FormItem,
  InputNumber,
  Table,
  TypographyText as TgText,
} from 'ant-design-vue'
import { ref } from 'vue'
import 'dayjs/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { CloseOutlined } from '@ant-design/icons-vue'
import { Rule } from 'ant-design-vue/es/form'
import EmptyView from '@/views/EmptyView.vue'
import { IProductTableItem } from '@/views/Farmer/FarmerCreateАuctionView.vue'
import { roundNumber, splitNumberByThreeDigits } from '@/helpers/scripts'
import { RUBLE } from '@/model/constants'

interface Props {
  formState: { domains: IProductTableItem[] }
}
const props = defineProps<Props>()

const emit = defineEmits(['editSecondForm', 'removeProduct'])
const columns = [
  {
    title: 'Товар',
    dataIndex: 'farmerProductName',
  },
  {
    title: 'Объём',
    dataIndex: 'volume',
    width: '13%',
    isRequired: true,
  },
  {
    title: 'Стартовая цена с НДС',
    dataIndex: 'startPrice',
    width: '14%',
    isRequired: true,
  },
  {
    title: 'НДС',
    dataIndex: 'vat',
    width: '6%',
    isRequired: true,
  },
  {
    title: 'Срок хранения',
    dataIndex: 'remainingShelfLife',
    width: '10%',
  },
  {
    title: 'Предоставляю пробный образец',
    dataIndex: 'isSampleRequested',
    colSpan: 2,
    width: '2%',
  },
  {
    title: 'amount',
    colSpan: 0,
    dataIndex: 'requestedSamplesNumber',
    width: '11%',
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    width: '13%',
  },
  {
    title: '',
    dataIndex: 'delete-product',
    width: 64,
  },
]
const height100 = { height: '100%' }
const secondFormRef = ref<FormInstance>()
defineExpose({
  secondFormRef,
})

const onDelete = (key: string) => {
  emit('removeProduct', key)
}

const validVolume = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
  isRequired: boolean,
  minValue: number,
) => {
  if (isRequired && value === '') {
    return Promise.reject('Укажите значение')
  } else if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (Number(value) < minValue) {
      return Promise.reject(`Не меньше ${minValue}`)
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else if (Number(value) < 0) {
      return Promise.reject('Значение не может быть отрицательным')
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '') {
      return Promise.reject('Укажите значение')
    } else if (Number(value) < minValue) {
      return Promise.reject(`Не меньше ${minValue}`)
    } else if (Number(value) > 1000000) {
      return Promise.reject('Не больше 1 000 000')
    } else if (Number(value) < 0) {
      return Promise.reject('Значение не может быть отрицательным')
    } else {
      return Promise.resolve()
    }
  }
}

const validStartPrice = async (
  _rule: Rule,
  value: string,
  isRequired: boolean,
) => {
  if (isRequired && value === '') {
    return Promise.reject('Укажите значение')
  } else if (value === '') {
    return Promise.resolve()
  } else if (Number(value) < 0.01) {
    return Promise.reject('Не меньше 0,01')
  } else if (Number(value) > 1000000) {
    return Promise.reject('Не больше 1 000 000')
  } else if (Number(value) < 0) {
    return Promise.reject('Значение не может быть отрицательным')
  } else {
    return Promise.resolve()
  }
}

const validVat = async (_rule: Rule, value: string, isRequired: boolean) => {
  if (isRequired && value === '') {
    return Promise.reject('Укажите значение')
  } else if (value === '') {
    return Promise.reject('Укажите значение')
  } else if (Number(value) < 0 || Number(value) > 100) {
    return Promise.reject('Не меньше 0% и не больше 100%')
  } else {
    return Promise.resolve()
  }
}

const validShelfLife = async (_rule: Rule, value: string) => {
  if (value === '') {
    return Promise.resolve()
  } else if (Number(value) < 1 || Number(value) > 100) {
    return Promise.reject('Не меньше 1% и не больше 100%')
  } else {
    return Promise.resolve()
  }
}

const validTestSampleAmount = async (
  _rule: Rule,
  value: string,
  measure_unit: string,
) => {
  if (['КГ', 'Кг', 'кг', 'Г', 'г', 'Л', 'л'].includes(measure_unit)) {
    if (value === '') {
      return Promise.resolve()
    } else if (Number(value) < 0.01 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 0,01 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  } else if (['ШТ', 'Шт', 'шт'].includes(measure_unit)) {
    if (value === '') {
      return Promise.resolve()
    } else if (Number(value) < 1 || Number(value) > 1_000) {
      return Promise.reject(`Не меньше 1 и не больше 1000 ${measure_unit}`)
    } else {
      return Promise.resolve()
    }
  }
}
const fillMeasurementUnitId = (item: any) => {
  if (item.measurement_unit_id === null) {
    const productText = item.farmerProductName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    return item.measurement_unit_id
  } else return item.measurement_unit_id
}
</script>

<template>
  <ConfigProvider :locale="locale">
    <div class="excel-table" v-if="props.formState.domains.length > 0">
      <Form ref="secondFormRef" :model="props.formState">
        <Table
          bordered
          :data-source="props.formState.domains"
          :columns="columns"
          :row-class-name="'editable-row'"
          :pagination="false"
        >
          <template #headerCell="{ column }">
            <template v-if="column.title">
              <span>
                {{ column.title }}
                <span v-if="column.isRequired" style="color: red">*</span>
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex === 'farmerProductName'">
              {{ text }}
            </template>
            <template v-else-if="column.dataIndex === 'volume'">
              <FormItem
                :name="['domains', index, 'volume']"
                :style="height100"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validVolume(
                        _rule,
                        value,
                        record.measurement_unit_id,
                        column.isRequired,
                        record.min_value,
                      ),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].volume"
                  :placeholder="record.measurement_unit_id"
                  :step="0.01"
                  decimalSeparator=","
                  :controls="false"
                  @change="emit('editSecondForm', 'volume', $event, index)"
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'startPrice'">
              <FormItem
                :name="['domains', index, 'startPrice']"
                :style="height100"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validStartPrice(_rule, value, column.isRequired),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].startPrice"
                  :step="0.01"
                  decimalSeparator=","
                  :placeholder="`₽/${fillMeasurementUnitId(record)}`"
                  :controls="false"
                  @change="emit('editSecondForm', 'startPrice', $event, index)"
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'vat'">
              <FormItem
                :name="['domains', index, 'vat']"
                :style="height100"
                :rules="[
                  {
                    validator: (_rule: Rule, value: string) =>
                      validVat(_rule, value, column.isRequired),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].vat"
                  :controls="false"
                  placeholder="%"
                  @change="emit('editSecondForm', 'vat', $event, index)"
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'remainingShelfLife'">
              <FormItem
                :name="['domains', index, 'remainingShelfLife']"
                :style="height100"
                :rules="[
                  {
                    required: column.isRequired,
                    message: 'Укажите значение',
                    trigger: 'change',
                  },
                  {
                    validator: validShelfLife,
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].remainingShelfLife"
                  placeholder="%"
                  :controls="false"
                  @change="
                    emit('editSecondForm', 'remainingShelfLife', $event, index)
                  "
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'isSampleRequested'">
              <div class="text-align-center">
                <FormItem
                  :name="['domains', index, 'isSampleRequested']"
                  :style="height100"
                  :rules="[
                    {
                      required: column.isRequired,
                      message: 'Укажите значение',
                      trigger: 'change',
                    },
                  ]"
                >
                  <Checkbox
                    :checked="props.formState.domains[index].isSampleRequested"
                    @change="
                      emit(
                        'editSecondForm',
                        'isSampleRequested',
                        $event.target.checked,
                        index,
                      )
                    "
                  />
                </FormItem>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'requestedSamplesNumber'">
              <FormItem
                :name="['domains', index, 'requestedSamplesNumber']"
                :style="height100"
                :rules="[
                  {
                    required: column.isRequired,
                    message: 'Укажите значение',
                    trigger: 'change',
                  },
                  {
                    validator: (_rule: Rule, value: string) =>
                      validTestSampleAmount(
                        _rule,
                        value,
                        record.measurement_unit_id,
                      ),
                    trigger: 'change',
                  },
                ]"
              >
                <InputNumber
                  :value="props.formState.domains[index].requestedSamplesNumber"
                  :placeholder="record.measurement_unit_id"
                  :controls="false"
                  :disabled="!props.formState.domains[index].isSampleRequested"
                  @change="
                    emit(
                      'editSecondForm',
                      'requestedSamplesNumber',
                      $event,
                      index,
                    )
                  "
                  string-mode
                />
              </FormItem>
            </template>
            <template v-else-if="column.dataIndex === 'sum'">
              <TgText strong>
                <template v-if="record.volume && record.startPrice">
                  {{
                    `${splitNumberByThreeDigits(
                      roundNumber(
                        Number(record.volume) *
                        Number(record.startPrice)
                      ))
                    } ${RUBLE}`
                  }}
                </template>
                <template v-else> - </template>
              </TgText>
            </template>
            <template v-else-if="column.dataIndex === 'delete-product'">
              <Button type="text" class="delete-product-btn">
                <template #icon>
                  <CloseOutlined
                    :style="{ color: 'red' }"
                    @click="onDelete(record.key)"
                  />
                </template>
              </Button>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </Table>
      </Form>
    </div>
    <EmptyView
      v-else
      title="Список товаров пуст"
      subtitle="Добавьте товары из каталога"
    />
  </ConfigProvider>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.text-align-center {
  text-align: center;
}

:deep(.ant-table-thead .ant-table-cell) {
  text-align: left !important;
}

:deep(.ant-table-tbody tr td:nth-child(8)) {
  background-color: #f7fafc !important;
}
</style>
