<script lang="ts" setup>
import { Button, Flex, Tag, TypographyText as TpText } from 'ant-design-vue'
import { LeftOutlined, InfoCircleFilled } from '@ant-design/icons-vue'
import TpTitle from '@/components/Title/TpTitle.vue'
import { useRoute, useRouter } from 'vue-router'

interface IProps {
  isShowWarning: boolean
}

defineProps<IProps>()

const route = useRoute()
const router = useRouter()

const goBack = async () => {
  await router.push('/requests/' + route.params.requestId)
}
</script>
<template>
  <Flex gap="24" vertical>
    <Flex gap="16" align="center">
      <Button :onClick="goBack">
        <template #icon>
          <LeftOutlined />
        </template>
      </Button>
      <TpTitle :level="2">Состав заказа</TpTitle>
    </Flex>
    <Tag v-if="isShowWarning" color="warning">
      <template #icon>
        <InfoCircleFilled :style="{ fontSize: '16px' }" />
      </template>
      <Flex align="center">
        <span class="tag__text"
          >Поставщик указал несколько цен на один и тот же товар, выберите
          нужный вариант для продолжения оформления заказа</span
        >
      </Flex>
    </Tag
    >
  </Flex>
</template>

<style scoped lang="scss">
:deep(.ant-tag) {
  padding: 8px 12px;
  display: flex;
  align-items: center;

  .tag__text {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
  }
}
</style>
