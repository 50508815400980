<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import BadgesProduct from '@/components/ProductCard/BadgesProduct.vue'
import {
  beefPartsFirstGrade,
  beefPartsSecondGrade,
  beefPartsTopGrade,
  isProductMeat,
  potatoColorCategories,
  ProductCategory,
  ProductType,
  vegetablesOptions,
} from '@/types/enums'
import {
  getValueFromEnum,
  splitNumberByThreeDigits,
  updaterAmountValue,
  dayEnding,
} from '@/helpers/scripts'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import { fontSize16, secondaryBlueButton } from '@/assets/EgalStyles/EButton'
import EgalInput from '@/components/Egal/Input/EInput.vue'
import { useOrderStore } from '@/store/orderStore'
import { cartMaxValue, cartMinValue } from '@/helpers/validators'
import { useFractionalNumber } from '@/composables/useFractionalNumber'
import { useStore } from '@/store'
import router from '@/router'
import { storeToRefs } from 'pinia'
import {useRoute} from "vue-router";
import emitter from "@/emitter";

interface Props {
  product?: any
  otherProducts?: any
  farm?: any
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const { isVerification } = storeToRefs(useStore())

const isMilk = computed(() => {
  return props.product.type === ProductType.Milk
})

const isPotato = computed(() => {
  return props.product.type === ProductType.Potato
})
const isMeat = computed(() => {
  return isProductMeat.includes(props.product.type)
})

const isPork = computed(() => {
  return props.product.type === ProductType.Pork
})

const isVegetable = computed(() => {
  return (
    vegetablesOptions.find(
      (vegetable) => vegetable.name === props.product.name,
    ) ||
    props.product.cat === ProductCategory.Veggies ||
    vegetablesOptions.find(
      (vegetable) => vegetable.name === props.product?.type,
    )
  )
})

const getTotalPrice = computed(() => {
  const price = Number(activeProduct.value.price) * amountValue.value
  return splitNumberByThreeDigits((price || 0).toFixed(2))
})

const getProductParameters = () => {
  let parametersTitle = ''
  const parameters = JSON.parse(activeProduct.value.parameters)
  for (const titleProperty in parameters) {
    if (parameters[titleProperty].length === 0) continue
    parametersTitle += ', ' + parameters[titleProperty]
  }
  return parametersTitle
}

/**
 * Определяет заголовок карточки в зависимости от категории продукта
 */
const getTitle = () => {
  let title = activeProduct.value.name
  if (isMeat.value) {
    title = `${activeProduct.value.type}, ${JSON.parse(
      activeProduct.value.parameters,
    ).cutting?.toLowerCase()}`
  }

  if (isMilk.value) {
    title =
      'Молоко, жирность ' +
      JSON.parse(activeProduct.value.parameters).fat_content
  }

  if (isVegetable.value) {
    title = `${props.product.type}${getProductParameters()}`
  }
  return title
}
const isAddedToCart = ref(false)

const setIsAddedToCart = () => {
  isAddedToCart.value = !!orders.find((i) => i.id === props.product.id)
}

const { addToCartStore, orders } = useOrderStore()
const addToCart = () => {
  if (!isAddedToCart.value) {
    addToCartStore(props.product.id, amountValue.value)
    isAddedToCart.value = true
  } else {
    return
  }
}

/**
 * Переключение текущего просмотриваемого продукта (срабатывает при переключении тэгов)
 * @param name - name продукта (Огузок, 1 литр)
 */
const changeActiveProduct = (name: string) => {
  activeProduct.value =
    props.otherProducts.find((i: any) => i.name === name) ?? props.product
}

const activeProduct = ref()

const amountValue = ref(0)
const activeColor = ref('')

const activePacking = ref('')
const setActivePacking = (itemName: string) => {
  activePacking.value = itemName
  changeActiveProduct(itemName)
}

const activeCutting = ref('')
const setActiveCutting = (itemName: string) => {
  activeCutting.value = itemName
  changeActiveProduct(itemName)
}

//Тэги для переключения молока, свинины (?)
const existingBadges = ref<any>([])
// Тэги для переключения сортности говядины
const beefBadges = ref<any>({
  top: [],
  first: [],
  second: [],
})

watchEffect(() => {
  activeProduct.value = props.product
})

/**
 * Задание тэгов с сортностью для говядины
 * @param arr - массивы со значениями Высшего, Первого и Второго сортов
 * @param name - текущее значение выбранной сортности (Огузок, Шея)
 * @param sortType - в какой массив положить текущую сортность
 */
const setBeefBadges = (
  arr: string[],
  name: string,
  sortType: 'top' | 'first' | 'second',
) => {
  if (arr.includes(name)) {
    beefBadges.value[sortType].push(name)
  }
}


const initProductCart = async () => {
  setIsAddedToCart()
  window.scrollTo(0, 0)
  amountValue.value = activeProduct.value.min_value

  if (isProductMeat.includes(activeProduct.value.type)) {
    activeCutting.value = activeProduct.value.name

    const names = props.otherProducts.map((i: any) => i.name)
    setBeefBadges(beefPartsTopGrade, activeProduct.value.name, 'top')
    setBeefBadges(beefPartsFirstGrade, activeProduct.value.name, 'first')
    setBeefBadges(beefPartsSecondGrade, activeProduct.value.name, 'second')

    names.forEach((name: any) => {
      setBeefBadges(beefPartsTopGrade, name, 'top')
      setBeefBadges(beefPartsFirstGrade, name, 'first')
      setBeefBadges(beefPartsSecondGrade, name, 'second')
    })
  }

  if (isMilk.value) {
    activePacking.value = JSON.parse(activeProduct.value.parameters).packaging

    const names = props.otherProducts.map((i: any) => i.name)
    existingBadges.value.push(activeProduct.value.name, ...names)
  }

  if (isPotato.value) {
    activeColor.value = getValueFromEnum(
        potatoColorCategories,
        JSON.parse(activeProduct.value.parameters)?.color,
    )
  }
}

emitter.on('refresh-token', async (e) => {
  await initProductCart()
})

onMounted(async () => {
  await initProductCart()
})

const valueError = ref('')

const setValueError = (err: string) => {
  valueError.value = err
}

const ceilAmount = () => {
  if (!amountValue.value) {
    amountValue.value = 0
  }
  amountValue.value = +Number(amountValue.value).toFixed(2) || 0
}

const inputUpdater = (ev: any) => {
  setValueError('')

  if (Number(ev) > activeProduct.value.quantity) {
    setValueError(
      `Значение не должно превышать ${activeProduct.value.quantity}`,
    )
  }
  if (+ev < +activeProduct.value.min_value || !+ev) {
    setValueError(
      `Минимальная поставка должна быть меньше ${activeProduct.value.min_value}`,
    )
  }

  return updaterAmountValue(ev)
}

const { NumbersOnlyTwoDecimal } = useFractionalNumber()

const toCart = () => {
  router.push('/cart')
}

const route = useRoute()
</script>

<template>
  <div class="wrapper-card">
    <div class="wrapper">
      <CreateHeader
        :is-show-create-chat-button="false"
        :title="activeProduct.product.fullName"
        :is-product="true"
        @button-click="
          () => {
            emit('close')
          }
        "
      />

      <div class="product-modal__body">
        <div v-if="product?.image_url">
          <img class="product-modal__image" :src="product.image_url" alt="" loading="lazy" />
        </div>

        <article class="left">
          <div v-if="isMeat && !isPork">
            <BadgesProduct
              v-for="(badges, idx) in beefBadges"
              :key="badges"
              :title="
                idx === 'top'
                  ? 'Высший сорт'
                  : idx === 'first'
                    ? 'Первый сорт'
                    : 'Второй сорт'
              "
              :items="badges"
              :active="activeCutting"
              @set-active="setActiveCutting"
            />
          </div>

          <BadgesProduct
            v-if="isMilk"
            title="Фасовка"
            :items="existingBadges"
            :active="activePacking"
            @set-active="setActivePacking"
          />
          <BadgesProduct
            v-if="isPotato"
            title="Цвет картофеля"
            :items="[activeColor]"
            :active="activeColor"
          />

          <br />
          <br />

          <div class="row">
            <div class="row__title">Количество</div>
            <div class="row__text">
              {{ activeProduct.quantity }}
              {{ activeProduct.measurement_unit_id }}
            </div>
          </div>
          <div class="row">
            <div class="row__title">Минимальная продажа</div>
            <div class="row__text">
              {{ activeProduct.min_value }}
              {{ activeProduct.measurement_unit_id }}
            </div>
          </div>
          <div class="row">
            <div class="row__title">
              Стоимость за
              {{
                activeProduct.secondCategory === 'Молоко'
                  ? 'штуку'
                  : 'килограмм'
              }}
            </div>

            <div class="row__text">{{ activeProduct.price }} ₽</div>
          </div>
          <div class="row">
            <div class="row__title">Срок подготовки товара</div>
            <div class="row__text">
              {{ activeProduct.prepare_days }}
              {{ dayEnding(activeProduct.prepare_days) }}
            </div>
          </div>
        </article>
        <article class="right">
          <div class="price">{{ getTotalPrice }} ₽</div>

          <EgalInput
            class="field"
            v-model="amountValue"
            :data="{
              ...inputDataConfig,
              label: `Количество, ${activeProduct.measurement_unit_id}`,
              modelValue: amountValue || 0,
              validators: [
                cartMaxValue(activeProduct.quantity, activeProduct),
                cartMinValue(activeProduct.min_value, activeProduct),
              ],
              disabled: !isVerification || isAddedToCart,
            }"
            @update:modelValue="(ev) => (amountValue = inputUpdater(ev))"
            @keypress="NumbersOnlyTwoDecimal"
            @blur="ceilAmount"
            @error="(err) => setValueError(err)"
          />
          <EButton
            class="field"
            @click="addToCart"
            :style-config="{ ...fontSize16, padding: '12px' }"
            :data="{
              disabled: !isVerification || !!valueError || isAddedToCart,
            }"
          >
            Добавить в корзину
          </EButton>
          <EButton
            class="to-cart"
            v-if="isAddedToCart"
            :style-config="{
              ...secondaryBlueButton,
              ...fontSize16,
              padding: '12px',
            }"
            @click="toCart"
            >Перейти в корзину
          </EButton>
          <span class="to-cart--helper" v-if="isAddedToCart">
            Товар добавлен в корзину
          </span>
          <!--          <div class="certificate">-->
          <!--            <div class="certificate__text">Продукт сертифицирован</div>-->
          <!--            <div class="certificate__icon"></div>-->
          <!--          </div>-->
        </article>
      </div>

      <div v-if="activeProduct.comment.length !== 0" class="footer">
        <div class="title">Описание товара</div>
        <div class="container">
          {{ activeProduct.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper-card {
  background: white;
  min-height: calc(100vh - 69px - 56px - 70px);
  padding: 56px 50px 70px 50px;
}

.wrapper {
  @include wrapper();
}

.product-modal__image {
  width: 568px;
  max-height: 440px;
  border-radius: 12px;
  object-fit: cover;
}

.product-modal__body {
  display: flex;
  margin-top: 39px;
  gap: 2rem;
}

article.left {
  border-right: 1px solid $gray-300;
  padding-right: 40px;
  margin-bottom: 48px;
  max-width: 393px;
}

article.right {
  height: fit-content;
  width: 280px;
  margin-bottom: 48px;
  box-shadow: $shadow-lg;
  padding: 24px;
  border-radius: 16px;
}

.row {
  display: flex;
  //align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $gray-600;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    width: fit-content;
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__text {
    color: $gray-800;
    word-break: break-all;
  }
}

.price {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: $gray-800;
  margin-bottom: 20px;

  .small {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.field {
  margin-bottom: 20px;
}

.egal-button {
  width: 280px;
  justify-content: center;
}

.certificate {
  margin-top: 28px;
  background: $gray-100;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 17px;
  width: 280px;

  position: relative;

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
    width: 160px;
  }

  &__icon {
    position: absolute;
    top: -13px;
    right: -14px;
    width: 95px;
    height: 100px;
    background-repeat: no-repeat;
    background-image: url('@/assets/img/Vector.svg');
  }
}

:deep(.input--md) {
  input:disabled {
    color: #2d3748;
  }

  .input-label--disabled {
    color: #718096;
  }
}

.footer {
  background: $gray-100;
  margin-top: 56px;
  border-radius: 24px;
  padding: 40px;
  background: white;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
    margin-bottom: 25px;
  }

  .container {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $gray-800;
    max-width: 700px;
    word-break: break-all;

    .certs {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 28px;
    }
  }
}

.to-cart--helper {
  display: block;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $gray-600;
}
</style>
