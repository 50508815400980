<script lang="ts" setup>
import { computed, ref } from 'vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { outlineGrayButton } from '@/assets/EgalStyles/EButton'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import DocumentFilled from '../../assets/img/document-filled.png'
import DocumentImg from '../../assets/img/document.svg'
import DeleteDocument from '../../assets/img/delete-document.svg'
import DownloadImg from '../../assets/img/download.svg'

import {
  downloadCompanyOnClick,
  downloadPartnerDocOnClick,
  orderDownloadOnClick,
  profileDownloadOnClick,
} from '@/helpers/scripts'

const props = defineProps<{
  name: string
  size?: string
  index: number
  errors: string[]
  isDownload?: boolean
  id?: number
  documentModel: 'profile' | 'order' | 'partner' | 'company'
  downloadBtn?: boolean
  iconType?: 'filled' | 'outlined'
}>()
const emit = defineEmits(['delete-document'])

const deleteDocument = () => {
  emit('delete-document', props.index)
}
const isErrorsEmpty = computed(
  () => !props.errors.find((error) => error.length !== 0),
)

const downloadRef = ref<HTMLAnchorElement>()
const documentIsFetching = ref(false)
const document = ref({
  file_body: '',
  file_name: '',
})
const downloadDocument = async () => {
  documentIsFetching.value = true
  if (props.documentModel === 'profile') {
    await profileDownloadOnClick(
      props.id || -1,
      document.value,
      downloadRef.value,
    ).then(() => {
      documentIsFetching.value = false
    })
  }

  if (props.documentModel === 'order') {
    await orderDownloadOnClick(
      props.id || -1,
      document.value,
      downloadRef.value,
    ).then(() => {
      documentIsFetching.value = false
    })
  }

  if (props.documentModel === 'partner') {
    await downloadPartnerDocOnClick(
      props.id || -1,
      document.value,
      downloadRef.value,
    ).then(() => {
      documentIsFetching.value = false
    })
  }

  if (props.documentModel === 'company') {
    await downloadCompanyOnClick(
      props.id || -1,
      document.value,
      downloadRef.value,
    ).then(() => {
      documentIsFetching.value = false
    })
  }
}
</script>

<template>
  <div class="uploader-document">
    <div class="uploader-document__info">
      <img
        v-if="iconType === 'filled'"
        alt=""
        class="uploader-document__icon"
        :src="DocumentFilled"
        loading="lazy"
      />
      <img
        v-else
        class="uploader-document__icon"
        :src="DocumentImg"
        alt=""
        loading="lazy"
      />
      <div class="uploader-document__text">
        <span class="uploader-document__name">{{ name }}</span>
        <p class="uploader-document__size" v-if="isErrorsEmpty">{{ size }}</p>
        <template v-for="error in errors" :key="error">
          <p v-if="error" class="uploader-document__error">
            {{ error }}
          </p>
        </template>
      </div>
    </div>
    <img
      class="uploader-document__delete-icon"
      :src="DeleteDocument"
      alt=""
      @click="deleteDocument"
      v-if="!isDownload"
      loading="lazy"
    />

    <EButton
      v-if="isDownload && !documentIsFetching && downloadBtn"
      :style="{
        ...outlineGrayButton,
        fontWeight: 500,
        width: '108px',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#2D3748',
      }"
      class="download-button"
      @click="downloadDocument"
    >
      <div class="icon-wrapper">
        <DownloadIcon fill="#2D3748" />
      </div>
      Скачать
    </EButton>

    <a
      @click="downloadDocument"
      class="uploader-document__delete-icon"
      v-if="isDownload && !documentIsFetching && !downloadBtn"
    >
      <img
        class="uploader-document__delete-icon"
        :src="DownloadImg"
        alt=""
        loading="lazy"
      />
    </a>
    <div class="download-loader" v-if="isDownload && documentIsFetching">
      <StarLoader />
    </div>
  </div>
  <a ref="downloadRef" class="hidden-href" />
</template>

<style lang="scss" scoped>
.download-button {
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 6px;

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.uploader-document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
  }

  &__name {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2d3748;
    word-break: break-all;
  }

  &__size {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #718096;
  }

  &__error {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #f16063;
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__delete-icon {
    padding: 0.5rem;
    cursor: pointer;
  }
}
.hidden-href {
  position: absolute;
}

.download-loader {
  display: flex;
  transform: scale(0.3);
  :deep(.lds-spinner) {
    width: 50px;
  }
}
</style>
