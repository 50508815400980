<script lang="ts" setup>
import { SuggestionTypes } from '@/types/enums'
import { ISuggestBank, ISuggestOrg } from '@/types/interfaces'
import { isNull } from '@/helpers/scripts'

interface TProps {
  items: ISuggestOrg[] | ISuggestBank[]
  isShow: boolean
  type: SuggestionTypes
}

interface TEvets {
  (e: 'selectTip', item: ISuggestOrg | ISuggestBank): void

  (e: 'closeTip'): void
}

const props = defineProps<TProps>()
const emit = defineEmits<TEvets>()

const selectTip = (item: ISuggestOrg | ISuggestBank) => {
  emit('selectTip', item)
}
const closeTip = () => {
  emit('closeTip')
}

const suggestTitle = (item: ISuggestOrg | ISuggestBank) => {
  if (props.type === SuggestionTypes.Organisation) {
    return `ИНН ${isNull(item.tin)}, ${item.legal_address}`
  } else {
    return `ИНН ${isNull(item.bank_tin)}, ${item.legal_address}`
  }
}
</script>

<template>
  <div v-if="type === SuggestionTypes.Organisation" v-click-outside="closeTip">
    <div v-if="isShow" v-click-outside="closeTip" class="suggest_tip">
      <p class="suggest_tip--header">Выберите вариант или продолжите ввод</p>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="suggest_category"
        @click="selectTip(item)"
      >
        {{ `${item.legal_form} ${item.name}` }}
        <p class="suggest_category--title">{{ suggestTitle(item) }},</p>
        <div class="divider"></div>
      </div>
    </div>
  </div>
  <div v-else v-click-outside="closeTip">
    <div v-if="isShow" v-click-outside="closeTip" class="suggest_tip">
      <p class="suggest_tip--header">Выберите вариант или продолжите ввод</p>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="suggest_category"
        @click="selectTip(item)"
      >
        {{ item.bank }}
        <p class="suggest_category--title">{{ suggestTitle(item) }},</p>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.divider {
  width: 100%;
  background-color: $gray-300;
  height: 1px;
  margin-top: 12px;
}

.suggest {
  &_tip {
    background-color: #ffffff;
    position: absolute;
    z-index: 100;
    border-right: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    max-height: 416px;
    overflow-y: auto;

    &--header {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
      padding: 8px 0 0 12px;
    }
  }

  &_category {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-800;
    padding: 12px 12px 0 12px;
    cursor: pointer;

    &:hover {
      background-color: $gray-200;
    }

    &--title {
      font-size: 14px;
      line-height: 17px;
      color: $gray-600;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;

      .devide {
        height: 0;
      }
    }
  }

  &_category_title {
    font-size: 14px;
    padding-left: 11px;
  }

  &_category_item {
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: $gray-300;
    }
  }
}
</style>
