<script lang="ts" setup>
import { catalogStore } from '@/store/catalogStore'
import { StarOutlined } from '@ant-design/icons-vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'

interface ICategory {
  id: number
  name: string
  icon_url?: string
  is_active: boolean
}

interface IPropsItem {
  items: ICategory[]
  isSorting: boolean
}

const useCatalogStore = catalogStore()

const props = defineProps<IPropsItem>()
const emits = defineEmits(['selectCategory'])
</script>

<template>
  <div class="catalog-loader" v-if="props.isSorting">
    <StarLoader />
  </div>
  <ul class="catalog-menu-left-list">
    <li
      v-for="item in props.items"
      @click="emits('selectCategory', item.id)"
      :key="item.id"
      :class="{
        'catalog-menu-left-item': useCatalogStore.current.id !== item.id,
        'catalog-menu-left-item catalog-menu-left-item-active':
          useCatalogStore.current.id === item.id,
        'catalog-menu-left-item-disabled': !item.is_active,
      }"
    >
      <div class="category-preview">
        <img
          v-if="item.icon_url"
          :src="item.icon_url"
          alt="item.name"
          width="24"
          height="24"
          loading="lazy"
        />
        <StarOutlined v-else :style="{ fontSize: '24px' }" />
        {{ item.name }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.catalog {
  &-loader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10vw;
  }
  &-menu {
    position: absolute;
    width: 70%;
    background-color: $base-white;
    height: 359px;
    left: 13%;
    z-index: 2;
    border-radius: 0 0 16px 16px;
    display: flex;
    border: 1px solid $gray-300;

    &-left {
      width: 45%;
      border-right: 1px solid $gray-300;
    }

    &-left-item {
      padding: 15px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      white-space: nowrap;
      .catalog-menu-left-chevron {
        opacity: 0;
        transition: 0.3s ease;
      }
      .category-preview {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          color: #00000073;
        }
      }
      &:hover,
      &-active {
        background-color: #0000000f;
        cursor: pointer;
        .catalog-menu-left-chevron {
          opacity: 1;
        }
        span.anticon {
          color: #000000e0;
        }
        img {
          filter: brightness(0) saturate(100%);
        }
      }
      &-disabled {
        color: #00000040;
        pointer-events: none;
      }
    }
  }
}
</style>
