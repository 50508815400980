<script lang="ts">
import {computed, defineComponent, onMounted, onUnmounted, reactive} from 'vue'
import CustomerRequestHeader from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/CustomerRequestHeader.vue'
import CustomerRequestSubheader from '@/views/Customer/CustomerRequestView/components/CustomerRequestSubheader/CustomerRequestSubheader.vue'
import CustomerRequestDataTab from '@/views/Customer/CustomerRequestView/components/CustomerRequestDataTab/CustomerRequestDataTab.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import CustomerRequestAnswersTab from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/CustomerRequestAnswersTab.vue'
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'CustomerRequestView',
  setup() {
    const { currentTab } = useCustomerRequestStore()
    const route = useRoute()
    const activeTab = reactive(currentTab)
    const { getCurrentCustomerRequest } = useCustomerRequestStore()
    const url = window.location.href
    const lastParam = url.split('/').slice(-1)[0]

    const { resetRequest } = useCustomerRequestStore()

    onMounted(() => {
      getCurrentCustomerRequest(lastParam)
    })

    onUnmounted(() => {
      resetRequest()
    })

    return {
      activeTab,
      route,
    }
  },
  components: {
    CustomerRequestAnswersTab,
    CustomerRequestDataTab,
    CustomerRequestHeader,
    CustomerRequestSubheader,
  },
})
</script>

<template>
  <div class="customer-request-view">
    <CustomerRequestHeader />
    <CustomerRequestSubheader />
    <div class="customer-request-view_body">
      <CustomerRequestDataTab v-if="activeTab.value === 1" />
      <CustomerRequestAnswersTab v-else />
    </div>
  </div>
</template>

<style scoped lang="scss">
.customer-request-view {
  height: 80vh;
  width: 100%;
  &_body {
    display: flex;
    justify-content: center;
    background: #ffffff;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-height: 600px;
    margin-bottom: 20px;
  }
}
</style>
