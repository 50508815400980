<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Button, Table } from 'ant-design-vue'
import ProductCell from '@/views/Auctions/components/AuctionTable/ProductCell.vue'
import CreatedAtCell from '@/views/Auctions/components/AuctionTable/CreatedAtCell.vue'
import StatusCell from '@/views/Auctions/components/AuctionTable/StatusCell.vue'
import UsersCell from '@/views/Auctions/components/AuctionTable/UsersCell.vue'
import YourOfferCell from '@/views/Auctions/components/AuctionTable/YourOfferCell.vue'
import BestOfferCell from '@/views/Auctions/components/AuctionTable/BestOfferCell.vue'
import { auctionStore } from '@/store/auctionStore'
import { IAuction, IAuctionProductRequest } from '@/types/interfaces'
import InfiniteLoading from 'v3-infinite-loading'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { DownOutlined } from '@ant-design/icons-vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { PartnersAPI } from '@/api/PartnersAPI'

interface IProps {
  tableData: IAuction[]
  isMoreFetching: boolean
}

const props = defineProps<IProps>()
const router = useRouter()
const expandedRowKeys = ref<number[]>([])

const columns = [
  {
    title: '',
    key: 'switch',
    width: '2%',
  },
  {
    title: 'Товары',
    key: 'products',
    width: '30%',
  },
  {
    title: 'Поставщик',
    key: 'farmer',
    width: '16%',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
    width: '9%',
  },
  {
    title: 'Статус',
    key: 'status',
    width: '9%',
  },
  {
    title: 'Лучшее предложение',
    key: 'bestOffer',
    width: '10%',
  },
  {
    title: 'Ваше предложение',
    key: 'yourOffer',
    width: '10%',
  },
  {
    title: '',
    key: 'action',
    width: '12%',
  },
]

const getCustomRow = (record: IAuction) => {
  return {
    onClick: () => router.push(`/auctions/${record.id}`),
    style: { cursor: 'pointer' },
  }
}

const increasePage = () => {
  auctionStore().increasePage()
}

const handleExpandKey = (id: number) => {
  if (expandedRowKeys.value.includes(id)) {
    expandedRowKeys.value = expandedRowKeys.value.filter((exId) => exId !== id)
  } else {
    expandedRowKeys.value.push(id)
  }
}

const getProductsList = (
  products: IAuctionProductRequest[],
  id: number,
  expanded: number[],
) => {
  if (expanded.includes(id)) return products
  return products.slice(0, 3)
}

const partners = ref([])

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

onMounted(async () => {
  await getMyPartners()
})
</script>

<template>
  <div class="auction-table">
    <Table
      :columns="columns"
      :data-source="props.tableData"
      :custom-row="getCustomRow"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'switch'">
          <Button
            type="text"
            @click.stop="handleExpandKey(record.id)"
            class="rotate-btn"
            :class="{ isRotated: expandedRowKeys.includes(record.id) }"
            v-if="record.auction_product_requests.length > 3"
          >
            <template #icon>
              <DownOutlined />
            </template>
          </Button>
        </template>
        <template v-if="column.key === 'products'">
          <ProductCell
            :products="
              getProductsList(
                record.auction_product_requests,
                record.id,
                expandedRowKeys,
              )
            "
          />
        </template>
        <template v-if="column.key === 'farmer'">
          <div class="farmer__name">
            <PartnershipTooltip
              :is-partner="
                !!partners.find((el: any) => el.company_id === record?.farmer?.id)
              "
              v-if="!!partners.find((el: any) => el.company_id === record?.farmer?.id)"
            />
            {{ `${record.farmer.legal_form} ${record.farmer.name}` }}
          </div>
          <SimpleRate :company_rating="record.farmer.company_rating" />
        </template>
        <template v-if="column.key === 'createdAt'">
          <CreatedAtCell :created-at="record.created_at" />
        </template>
        <template v-if="column.key === 'status'">
          <StatusCell
            :expiration-date="record.expiration_date"
            :status="record.status"
          />
        </template>
        <template v-if="column.key === 'users'">
          <UsersCell
            :participants="record.merged_recipients ?? []"
            :is-public="record.is_public"
          />
        </template>
        <template v-if="column.key === 'bestOffer'">
          <BestOfferCell
            v-if="
              getProductsList(
                record.auction_product_requests,
                record.id,
                expandedRowKeys,
              ).length
            "
            :best-offer="
              record.best_customer_id
                ? {
                    price: record.best_sum,
                    company_name: null,
                  }
                : null
            "
          />
          <span v-else>-</span>
        </template>
        <template v-if="column.key === 'yourOffer'">
          <YourOfferCell :offer="record.your_sum" />
        </template>
        <template
          v-if="
            column.key === 'action' &&
            getProductsList(
              record.auction_product_requests,
              record.id,
              expandedRowKeys,
            )?.length &&
            record.status === 'open'
          "
        >
          <Button type="primary"> Сделать ставку </Button>
        </template>
      </template>
    </Table>
    <InfiniteLoading
      :distance="500"
      class="infinite-loading"
      @infinite="increasePage"
    />
    <div v-if="isMoreFetching" class="list-loader">
      <FullscreenLoading :type="'secondary'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.farmer {
  &__name {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}

.auction-table {
  flex-grow: 1;
  margin-top: 24px;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 20px;
  .rotate-btn {
    :deep(.anticon) {
      transform: rotate(0);
      transition: 0.2s ease-in;
    }
    &.isRotated {
      :deep(.anticon) {
        transform: rotate(180deg);
      }
    }
  }
}

.list-loader {
  transform: scale(0.6);
  width: 100%;
  height: 70px;
  position: relative;
}
</style>
