import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class PartnerDocumentAPIModel {
  async getItems(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.PartnerDocument,
      method: EgalMethods.GetItems,
      data: { filter: filters, withs, pagination: { per_page: perPage, page: 1 },},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItem(id: string | number): Promise<any> {
    return RequestManager.request({
      model: APIModels.PartnerDocument,
      method: EgalMethods.GetItem,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async upload(data: {
    file_name: string
    extension: string
    file_body: string
  }): Promise<any> {
    return RequestManager.request({
      model: APIModels.PartnerDocument,
      method: EgalMethods.upload,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async createMany(
    data: {
      order_id: number
      file_path: string
    }[],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.PartnerDocument,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const PartnerDocumentAPI = new PartnerDocumentAPIModel()
export { PartnerDocumentAPI }
