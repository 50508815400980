<script lang="ts" setup>

import ReductionHeaderItem from '@/views/Reductions/components/ReductionHeader/Components/Item/ReductionHeaderItem.vue'
import ReductionHeaderList from '@/views/Reductions/components/ReductionHeader/Components/List/ReductionHeaderList.vue'

interface IProps {
  title: string
  buttonText?: string
  buttonType: string
  isList: boolean
  createdAt?: string
  expirationDate?: string
  isOpen: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['onBtnClick'])
</script>

<template>
  <div class="reduction-header__wrapper">
    <ReductionHeaderList
      v-if="!props.isList"
      :title="props.title"
      :button-text="props.buttonText ?? ''"
      @onBtnClick="emit('onBtnClick')"
      :created-at="props.createdAt ?? ''"
      :expiration-date="props.expirationDate ?? ''"
      :isOpen="isOpen"
    />
    <ReductionHeaderItem
      v-if="props.isList"
      :title="props.title"
      :button-text="buttonText ?? ''"
      :created-at="props.createdAt"
      @onBtnClick="emit('onBtnClick')"
      :expiration-date="props.expirationDate"
      :buttonType="buttonType"
    />
  </div>
</template>
