<script lang="ts" setup>
import { onMounted, PropType, ref } from 'vue'
import { useOrderStore } from '@/store/orderStore'
import { useStore } from '@/store'
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  MenuItem,
  Space,
  Modal,
} from 'ant-design-vue'
import { CloseOutlined, EditFilled } from '@ant-design/icons-vue'
import ProductPriceBlock from '@/components/CardTable/ProductPriceBlock.vue'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import AddToCart from '@/components/AddToCart/AddToCart.vue'
import router from '@/router'
import { IFarmer } from '@/types/interfaces'

const props = defineProps({
  items: {
    type: Array as PropType<any>,
  },
  isFarmer: {
    type: Boolean,
    default: true,
  },
  farmer: Object,
  readOnly: {
    type: Boolean,
    required: false,
  },
})

const emits = defineEmits(['open', 'add-cart', 'deleteProduct'])

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const company_id = user?.company_id
const selectedProduct = ref({})
const open = ref<boolean>(false)

const priceOptionsAscending = (item: object) => {
  let newItem = JSON.parse(JSON.stringify(item))
  newItem.price_options.sort((a, b) =>
    Number(a.min_value) > Number(b.min_value) ? 1 : -1,
  )
  return newItem
}

const onCardClick = (item: object) => {
  open.value = true
  selectedProduct.value = priceOptionsAscending(item)
}

const pushToSpecialPrices = (itemID: number) => {
  router.push({ path: '/edit-special-prices/' + itemID })
}
const pushToPriceOptions = (itemID: number) => {
  router.push({ path: '/edit-price-options/' + itemID })
}
const pushToEditProduct = (itemID: number) => {
  router.push({ path: '/edit-product/' + itemID })
}

const cartState = ref<boolean>(true)
onMounted(() => {
  const { orders: storeOrders } = useOrderStore()
  orders.value = storeOrders
})

const cartStore = useStore()

const orders = ref<any[]>([])

cartStore.$subscribe((mutation, state) => {
  if (state.isOpenCart !== cartState.value) {
    const { orders: storeOrders } = useOrderStore()
    orders.value = storeOrders
  }
})

const deleteProduct = () => {
  emits('deleteProduct', selectedProduct.value, () => (open.value = false))
}

const closeDrawer = () => {
  open.value = false
}
const selectedProductId = ref<null | number>(null)
const modalIsOpen = ref<boolean>(false)

const openModal = (productId: number) => {
  selectedProductId.value = productId
  modalIsOpen.value = true
}

const closeModal = () => {
  modalIsOpen.value = false
  selectedProductId.value = null
}

const calcMinValue = (item: any) => {
  if (!!item.min_value && !!item.measurement_unit_id) {
    return `${item.min_value} ${item.measurement_unit_id}`
  } else if (item.min_value) return item.min_value
  else return ''
}

const calcQuantityValue = (item: any) => {
  if (!!item.quantity && !!item.measurement_unit_id) {
    return `${item.quantity} ${item.measurement_unit_id}`
  } else if (item.quantity) return item.quantity
  else return ''
}
</script>

<template>
  <DrawerForm
    :product="selectedProduct"
    @editProduct="pushToEditProduct"
    @editSpecialPrices="pushToSpecialPrices"
    @editPriceOptions="pushToPriceOptions"
    @deleteProduct="deleteProduct"
    @closeDrawer="closeDrawer"
    :isFarmer="isFarmer"
    :open="open"
  />
  <div class="table">
    <div v-for="(item, index) in items" :key="item" class="table__category">
      <div class="table__grid">
        <div
          :key="index"
          :class="['table__card', props.readOnly ? 'not-clicked' : '']"
          @click.stop="onCardClick(item)"
        >
          <div class="table__card__info">
            <div v-if="!item?.image_url" class="table__image"></div>
            <img
              v-if="item?.image_url"
              :src="item?.image_url"
              alt=""
              class="table__image"
              loading="lazy"
            />
            <div class="table__body">
              <span class="table__body__title">
                {{ item.product.fullName }}
              </span>
              <Space :size="16" class="table__body__subinfo" :align="'start'">
                <span>
                  Мин. заказ
                  <b>
                    {{ calcMinValue(item) }}
                  </b>
                </span>
                <span>
                  В наличии
                  <b>{{ calcQuantityValue(item) }}</b>
                </span>
              </Space>
            </div>
          </div>
          <Divider type="vertical" :style="{ height: '100%' }" />
          <div class="table__card__price">
            <ProductPriceBlock
              :farmer-product="item"
              style="margin-bottom: 40px"
            />
            <div v-if="isFarmer && company_id == item.farmer_id">
              <Space :size="24">
                <Dropdown placement="topLeft">
                  <Button type="link" @click.stop>
                    <template #icon> <EditFilled /> </template>
                    Редактировать
                  </Button>
                  <template #overlay>
                    <Menu>
                      <MenuItem key="1" @click="pushToEditProduct(item.id)"
                        >Редактировать данные товара</MenuItem
                      >
                      <MenuItem key="2" @click="openModal(item.id)"
                        >Редактировать список цен</MenuItem
                      >
                      <MenuItem key="3" @click="pushToSpecialPrices(item.id)"
                        >Редактировать спеццены</MenuItem
                      >
                    </Menu>
                  </template>
                </Dropdown>
                <Button
                  danger
                  @click.stop="emits('deleteProduct', item)"
                  type="link"
                >
                  <template #icon><CloseOutlined /></template>
                  Удалить
                </Button>
              </Space>
            </div>
            <div v-else>
              <AddToCart
                v-if="!isFarmer"
                :product="item"
                :farmer="farmer as IFarmer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    width="440px"
    centered
    v-model:open="modalIsOpen"
    :bodyStyle="{ marginBottom: '24px' }"
    title="Внести изменения в цены товара?"
  >
    При редактировании вариантов цен, будут внесены изменения в запросы цен по
    данному товару. Вы уверены, что хотите изменить цены?
    <template #footer>
      <Button @click="closeModal"> Отмена </Button>
      <Button type="primary" @click="pushToPriceOptions(selectedProductId!)">
        Продолжить
      </Button>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.table {
  &__category {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
  }

  &__badge {
    color: #000000e0;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 16px;
  }

  &__grid {
    display: grid;
    //auto-fit
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 24px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    &__title {
      color: #000000e0;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
    }

    &__subinfo {
      display: grid;
      grid-template-columns: repeat(2, 212px);

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #000000a6;

      b {
        color: #000000e0;
      }
    }
  }

  &__card {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto 280px;
    gap: 16px;
    padding: 24px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #f0f0f0;
    }
    &__info {
      display: grid;
      gap: 24px;
      grid-template-columns: 160px 1fr;
    }
    &__price {
      display: grid;
    }
  }

  &__image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    background: #fafafa url('@/assets/img/no-image-product.png') no-repeat
      center;
    background-size: contain;
  }

  :deep(.ant-btn-link) {
    width: fit-content;
    padding-left: 0;
  }
}
</style>
