export enum EgalMethods {
    search = 'search',
    getForFarmer = 'getForFarmer',
    getForCustomer = 'getForCustomer',
    sign = 'sign',
    changePassword = 'changePassword',
    GetItem = 'getItem',
    GetItems = 'getItems',
    Create = 'create',
    CreateAuctionOffer = 'createAuctionOffer',
    createOrder = 'createOrder',
    getItemsWithTotal = 'getItemsWithTotal',
    getFarmersWithFilter = 'getFarmersWithFilter',
    create = 'create',
    update = 'update',
    ping = 'ping',
    getFarmersWithCategories = 'getFarmersWithCategories',
    downloadReport = 'downloadReport',
    generateContract = 'generateContract',
    myPartners = 'myPartners',
    getPartnerProducts = 'getPartnerProducts',
    checkInvite = 'checkInvite',
    createInvite = 'createInvite',
    inviteRegister = 'inviteRegister',
    dropPassword = 'dropPassword',
    confirmDropPassword = 'confirmDropPassword',
    confirmRegistration = 'confirmRegistration',
    resendEmail = 'resendEmail',
    createMany = 'createMany',
    delete = 'delete',
    updateMany = 'updateMany',
    changeEmail = 'changeEmail',
    getCaptcha = 'getCaptcha',
    getGroupedItems = 'getGroupedItems',
    checkItems = 'checkItems',
    createOrders = 'createOrders',
    deleteMany = 'deleteMany',
    getEmployees = 'getEmployees',
    upload = 'upload',
    getPrompts = 'getPrompts',
    getAuctionProducts = 'getAuctionProducts',
    getFarmerAddresses = 'getFarmerAddresses',
    getFarmerAddresseesFromProductFilter = 'getFarmerAddresseesFromProductFilter'
}

export enum AxiosReqType {
    Post = 'post',
    Get = 'get',
    Put = 'put',
    Delete = 'delete'
}
