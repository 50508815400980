<script lang="ts" setup>

import { ICartData, IQuantityError } from '@/types/interfaces'
import { List, ListItem } from 'ant-design-vue'

import CartProductItem from '@/components/Cart/CartProductItem.vue'
import router from '@/router'
import CartProductHeader from '@/components/Cart/CartProductHeader.vue'

interface Props {
  farm: ICartData
  quantityError: IQuantityError[]
  collapsedCards: string[]
}

const props = defineProps<Props>()
const emit = defineEmits([
  'quantity-update',
  'delete',
  'error',
  'collapseCard',
  'toggleDeleteItemModal',
])

const onCartItemClick = (productId: number) => {
  router.push(/farmer-product/ + productId.toString())
}
</script>

<template>
  <div class="farm-order" v-if="props.farm.cart_items.length">
    <CartProductHeader
      :farm="props.farm"
      withoutIcon
      @collapseCard="(id) => emit('collapseCard', id)"
      :collapsedCards="collapsedCards"
    />
    <div v-show="!collapsedCards.includes(farm.id)">
      <List rowKey="id" :data-source="farm.cart_items">
        <template #renderItem="{ item }">
          <ListItem class="list-item">
            <CartProductItem
              :cartItem="item"
              :quantityError="quantityError"
              @quantity-update="
                (farmId: number, amount: number) =>
                  emit('quantity-update', farmId, amount)
              "
              @product-name-click="onCartItemClick"
              @toggleDeleteItemModal="
                emit('toggleDeleteItemModal', item.farmer_product_id)
              "
            />
          </ListItem>
        </template>
      </List>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.list-item {
  width: 100%;
  padding: 24px 16px;
  border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
}
</style>
