<script setup lang="ts">
import { ref, Ref, watch } from 'vue'
import { Flex } from 'ant-design-vue'
import UploadImg from '../../../assets/img/upload.svg'

import { useBase64 } from '@vueuse/core'

const props = defineProps<{
  type?: 'custom' | ''
  customName?: string
  documentInfo?: string
}>()
const emit = defineEmits(['load-file'])
const file = ref() as Ref<File>
const { base64 } = useBase64(file)
function onFileInput(e: Event) {
  file.value = (e.target as HTMLInputElement).files![0]
  if (base64.value.length === 0) return
}

watch(base64, () => emit('load-file', file.value, base64.value))
</script>

<template>
  <div class="file-uploader" v-if="type !== 'custom'">
    <div class="file-uploader__center-info">
      <img :src="UploadImg" alt="upload" loading="lazy" />
      <Flex align="center" vertical
        ><p class="file-uploader__text">Перетащите файлы сюда или</p>
        <div>
          <input
            class="file-uploader__input"
            type="file"
            @input="onFileInput"
          />
        </div>
        <a href="#" class="file-uploader__upload-link">
          нажмите, чтобы загрузить
        </a>
      </Flex>
    </div>
  </div>
  <p v-if="documentInfo && type !== 'custom'" class="file-uploader-docs-info">
    {{ documentInfo }}
  </p>
  <div class="file-uploader-custom" v-if="type === 'custom'">
    <slot class="file-uploader-custom__content" v-if="type === 'custom'" />
    <input class="file-uploader__input" type="file" @input="onFileInput" />
    <span class="file-uploader__name">{{ props.customName }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
.file-uploader {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100px;
  border: 1px dashed #a0aec0;
  border-radius: 4px;
  position: relative;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px dashed #70a0de;
  }

  &__center-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 8px;
    width: 202px;
    height: 58px;
    cursor: pointer;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #718096;
  }

  &__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 0;
    opacity: 0;
    font-size: 0;
  }

  &__upload-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0066ff;
    text-decoration: none;
  }
  &-docs-info {
    margin-top: 4px;
    @include p7;
    color: $gray-600;
  }
}

.file-uploader-custom {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 12px;

  &__content {
    z-index: 2;
  }
  .file-uploader__name {
    color: #0066ff;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
