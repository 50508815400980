<script lang="ts" setup>
/* eslint-disable */
import { catalogStore, ICatalogFilters } from '@/store/catalogStore'
import { ref } from 'vue'
import CatalogFilterItem from '@/components/Catalog/CatalogFilterItem.vue'
import { Switch } from 'ant-design-vue'
import { useDebounceFn } from '@vueuse/core'

interface FProps {
  isShow: boolean
  item: ICatalogFilters
  allItems: any
}
const props = defineProps<FProps>()
let isOpen = ref(props.isShow)
const useCatalogStore = catalogStore()

const handleFilter = (parameterValue: string = '') => {
  useCatalogStore.page = 1
  const parameter = useCatalogStore.filters.find(
    (filterItem) => filterItem.id === props.item.id,
  )
  if (!parameter) return

  handleFilterByType(parameter, parameterValue)
  checkRelatedFilter(parameter)
  makeFilterStatement()
}

const handleFilterByType = (
  parameter: ICatalogFilters,
  parameterValue: string,
) => {
  switch (props.item.type) {
    case 'binary':
      handleBinaryFilter(parameter)
      break
    case 'single':
      handleSingleMultipleFilter(parameter, parameterValue)
      break
    case 'multiple':
      handleSingleMultipleFilter(parameter, parameterValue)
      break
    default:
      break
  }
}

const handleBinaryFilter = (parameter: ICatalogFilters) => {
  parameter.values = [isBinaryAttributeTrue(parameter) ? 'нет' : 'да']
}

const handleSingleMultipleFilter = (
  parameter: ICatalogFilters,
  parameterValue: string,
) => {
  const index = parameter?.values.indexOf(parameterValue) ?? 0
  if (index !== -1) {
    parameter?.values.splice(index, 1)
  } else {
    parameter?.values.push(parameterValue)
  }
}

const checkRelatedFilter = (parameter: ICatalogFilters) => {
  const disablingItem = useCatalogStore.filters.find(
    (item) => item?.parameter_id === parameter.id,
  )
  if (!disablingItem) return
  disablingItem.disabled = !!(
    disablingItem &&
    disablingItem.related_rule &&
    !parameter.values.includes(disablingItem.related_rule)
  )
}

const makeFilterStatement = () => {
  const firstPart = useCatalogStore.filterFirstPart
  const secondPart: string[] = []
  useCatalogStore.filters.forEach((filterItem) => {
    if (filterItem.disabled) return
    switch (filterItem.type) {
      case 'single':
        filterItem.values.length && secondPart.push(filterItem.values.join(','))
        break
      case 'binary':
        isBinaryAttributeTrue(filterItem) && secondPart.push(filterItem.name)
        break
      case 'multiple':
        filterItem.values.length && secondPart.push(filterItem.values.join(','))
        break
    }
  })
  const filterPart = secondPart.length
    ? [firstPart, 'catalog_product', secondPart.join('|')]
    : ['product_category_id', 'eq', useCatalogStore.idSubCategory]
  useCatalogStore.filterPart = filterPart
  makeAPICall(filterPart)
}

const makeAPICall = useDebounceFn(async (filter: string[]) => {
  await useCatalogStore.getProductsWithFilter(
    useCatalogStore.idSubCategory,
    1,
    [filter],
  )
}, 500)

const isBinaryAttributeTrue = (binaryParameter: ICatalogFilters) => {
  return binaryParameter.values.includes('да')
}
</script>

<template>
  <div class="filters">
    <div
      class="single-filters"
      v-if="item.type === 'single' || item.type === 'multiple'"
      :class="{ disabled: props.item.disabled }"
    >
      <div
        class="filters-header"
        @click="isOpen = !isOpen"
        :class="{ disabled: props.item.disabled }"
      >
        <span class="filters-title">
          {{ props.item.name }}
        </span>
        <svg v-if="isOpen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
        </svg>
        <svg v-if="!isOpen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
        </svg>
      </div>
      <CatalogFilterItem
        :isShow="isOpen"
        class="filters-list"
        :allItems="props.allItems"
        :filterItem="props.item"
        @handleSingleFilter="handleFilter"
      />
    </div>
    <div class="binary-filters" v-else-if="item.type === 'binary'">
      <div class="filters-header" @click.stop="() => handleFilter()">
        <span class="filters-title">
          {{ props.item.name }}
        </span>
        <Switch
          :disabled="props.item.disabled"
          @change.stop="() => handleFilter()"
          :checked="isBinaryAttributeTrue(item)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-direction: column;

  &-list {
    display: flex;
    flex-direction: column;
  }

  &-title {
    cursor: pointer;
    text-transform: capitalize;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 32px 12px 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;

    &:not(.disabled):hover {
      color: #4096ff;
      .bi {
        color: #4096ff;
      }
    }
  }

  .disabled {
    color: #00000040;
    .bi {
      color: #00000040;
    }
  }

  &-item {
    margin: 8px 0;
  }
  .bi {
    color: #2d3748;
  }
}
</style>
