<template>
  <ModalWindow
    class="verification-modal"
    :show="props.show"
    :show-close-icon="props.showCloseIcon"
    @close="handleClose"
  >
    <template #header>
      Заявка на верификацию отправлена, пожалуйста, дождитесь ответа
      администратора
    </template>
    <template #body>
      Запрос на верификацию аккаунта будет рассмотрен в ближайшее время. Мы
      уведомим вас о результатах проверки, а пока вы можете указать удобную
      форму заключения договора поставки c потенциальными контрагентами.
      <div class="verification-buttons">
        <EButton
          @click="handleClose"
          class="larger-btn"
          :style-config="{ ...fontSize16 }"
          >Хорошо</EButton
        >
      </div>
    </template>
  </ModalWindow>
</template>

<script setup lang="ts">
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { defineProps } from 'vue'
import { IAccountUser } from '@/types/interfaces'
import {fontSize16} from "@/assets/EgalStyles/EButton";

interface Props {
  show: boolean
  showCloseIcon: IAccountUser | Record<string, string>
  class: string
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const handleClose = async () => {
  localStorage.setItem('isShowModalVerificationFull', 'true')
  emit('close')
}
</script>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.verification-modal {
  :deep(.modal-container) {
    min-width: 560px;
    padding: 0;
    .wrapper {
      padding: 32px;
    }
    .modal-header > h3 {
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
      margin: 0 auto;
    }
    .modal-body {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-600;
      .verification-buttons {
        margin-top: 32px;
      }
    }
  }
}

.wrapper {
  @include wrapper();
}
</style>
