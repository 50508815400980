import { computed, ref } from 'vue'
import { Document, UploadDocument } from '@/types/types'
import { DocumentAPI } from '@/api/DocumentAPI'

export function useDocument() {
  let maxSize = 30
  const maxSingleSize = 20

  let validDocumentTypes = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'application/pdf',
  ]
  const minResolution = {
    width: 568,
    height: 440,
  }
  const uploadDocuments = ref<UploadDocument[]>([])
  const requiredDocuments = ref<UploadDocument[]>([])
  const userOrganization = ref('')

  const bytesToMegabytes = (bytes: number) => {
    return +bytes / 1000000
  }

  const declensionLitres = (bytes: number) => {
    const megabytes = bytesToMegabytes(bytes)
    return megabytes.toFixed(2) + ' Мб'
  }

  const downloadFile = (base64: string, fileName = 'document') => {
    const anchorElement = document.createElement('a')
    anchorElement.style.display = 'none'
    anchorElement.href = base64
    anchorElement.download = fileName
    document.body.appendChild(anchorElement)
    anchorElement.click()
  }

  const addToUploadedDocuments = (newUploadDocument: UploadDocument) => {
    uploadDocuments.value.push(newUploadDocument)
  }

  const deleteDocument = (index: number) => {
    uploadDocuments.value.splice(index, 1)
  }

  const getDocumentType = () =>
    requiredDocuments?.value[uploadDocuments?.value?.length]?.type
      ? requiredDocuments.value[uploadDocuments.value.length].type
      : requiredDocuments.value[0].type

  const getSystemOthersDocuments = (documents: Document[], orderID: number) => {
    const systemOtherDocuments: Document[] = []
    documents.forEach((document) => {
      if (document.source === 'system' && document?.order_id === orderID) {
        console.log('document', document)
        systemOtherDocuments.push(document)
      }
    })
    console.log('systemOtherDocuments', systemOtherDocuments)
    return systemOtherDocuments
  }

  const getNotSystemOthersDocuments = (
    documents: Document[],
    orderID: number,
  ) => {
    return documents.find(
      (document) =>
        !document.is_system &&
        document.document_type === 'others' &&
        document?.orders?.find((order) => order.id === orderID),
    )
  }
  const getResolution = (file: File) => {
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
      return { width: img.width, height: img.height }
    }
    return { width: img.width, height: img.height }
  }

  const uploadFile = (file: File, base64: string) => {
    if (!file) {
      deleteDocument(0)
    } else {
      const fileError = [
        isValidFileTypes(file),
        isValidFileSize(+file.size / 1048576),
      ]
      const resolution = getResolution(file)
      uploadDocuments.value.push({
        name: file.name,
        size: declensionLitres(file.size),
        sizeMegabytes: bytesToMegabytes(file.size),
        base64: base64,
        errors: fileError,
        documentType: getDocumentType(),
        type: file.type,
        //@ts-ignore
        extention: `.${file?.name?.split('.')[1]}`,
      })
    }
  }
  const setValidDocumentTypes = (validDocuments: string[]) => {
    validDocumentTypes = validDocuments
  }

  const setUserOrganization = (newUserOrganization: string) =>
    (userOrganization.value = newUserOrganization)
  const setRequiredDocuments = (newRequiredDocuments: UploadDocument[]) =>
    (requiredDocuments.value = newRequiredDocuments)

  const setMaxSize = (newMaxSize: number) => {
    maxSize = newMaxSize
  }

  const isValidFileTypes = (file: File | UploadDocument) => {
    return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
  }

  const isValidFileSize = (size: number) => {
    return size > maxSingleSize ? 'Превышен размер файла. Максимум 20Мб' : ''
  }

  const uploadDocumentsNotEmpty = computed(
    () => uploadDocuments.value.length > 0,
  )
  const isSizeError = computed(
    () =>
      uploadDocuments.value.length > 1 &&
      uploadDocuments.value.reduce(
        (accumulator, document) => accumulator + document.sizeMegabytes,
        0,
      ) > maxSize,
  )

  const isSingleSizeError = computed(() =>
    uploadDocuments.value.find(
      (document) => isValidFileSize(document.sizeMegabytes).length > 0,
    ),
  )

  const isLengthError = computed(
    () => uploadDocuments.value.length < requiredDocuments.value.length,
  )
  const isFileTypesError = computed(
    () =>
      !!uploadDocuments.value.find(
        (document) => isValidFileTypes(document).length > 0,
      ),
  )
  const isDocumentsError = computed(
    () =>
      isSizeError.value ||
      isLengthError.value ||
      isFileTypesError.value ||
      isSingleSizeError.value,
  )

  const isSizeOrFileError = computed(
    () =>
      isSizeError.value || isFileTypesError.value || isSingleSizeError.value,
  )

  const sentDocuments = async (documents: UploadDocument[]) => {
    const sentDocuments: Document[] = uploadDocumentsFormat(documents)
    for (const document of sentDocuments) {
      DocumentAPI.uploadDocument(document).then((response: any) => {
        DocumentAPI.createDocument({
          ...document,
          file_path: response.path,
        })
      })
    }
  }
  const sentDocumentsWithoutWrap = async (documents: Document[]) => {
    for (const document of documents) {
      DocumentAPI.uploadDocument(document).then((response: any) => {
        DocumentAPI.createDocument({
          ...document,
          file_path: response.path,
        })
      })
    }
  }

  const uploadDocumentsFormat = (documents: UploadDocument[]) => {
    const sentDocuments: Document[] = []
    documents.forEach((file) => {
      sentDocuments.push({
        file_name: file.name,
        file_body: file.base64,
        document_type: file.documentType,
        // @ts-ignore
        extention: `.${file?.name?.split('.')[1]}`,
      })
      if (file.user_id) {
        sentDocuments[sentDocuments.length - 1].user_id = file.user_id
      }
      if (file.order_id) {
        sentDocuments[sentDocuments.length - 1].order_id = file.order_id
      }
    })
    return sentDocuments
  }

  const orderDocumentsFormat = (
    documents: UploadDocument[],
    orderID: number,
    documentId = 1,
  ) => {
    const orderDocuments: {
      id: number
      file_name: string
      file_body: string
      document_type: string
      user_id?: string
      order_id?: number
      extention?: string
    }[] = []
    documents.forEach((file) => {
      const documentObj: {
        id: number
        file_name: string
        file_body: string
        document_type: string
        user_id?: string
        order_id: number
        extention?: string
      } = {
        id: documentId,
        file_name: file.name,
        file_body: file.base64,
        document_type: file.documentType,
        order_id: orderID,
        extention: `.${file?.name?.split('.')[1]}`,
      }

      if (file.user_id) documentObj.user_id = file.user_id
      orderDocuments.push(documentObj)
    })
    return orderDocuments
  }

  return {
    bytesToMegabytes,
    declensionLitres,
    downloadFile,
    uploadDocuments,
    uploadFile,
    uploadDocumentsNotEmpty,
    isDocumentsError,
    isSizeOrFileError,
    isFileTypesError,
    isLengthError,
    isSizeError,
    isSingleSizeError,
    setUserOrganization,
    setRequiredDocuments,
    requiredDocuments,
    deleteDocument,
    sentDocuments,
    orderDocumentsFormat,
    uploadDocumentsFormat,
    getSystemOthersDocuments,
    setMaxSize,
    addToUploadedDocuments,
    setValidDocumentTypes,
    getNotSystemOthersDocuments,
    sentDocumentsWithoutWrap,
  }
}
