<script setup lang="ts">
import {
  secondaryBlueButton,
  primaryButton,
  fontSize14,
} from '@/assets/EgalStyles/EButton'
import {
  InputSearch,
  Checkbox,
  ListItem,
  Steps,
  Space,
  Empty,
  List,
  Col,
  Row,
} from 'ant-design-vue'
import CustomerRequestHeaderActionButton from '@/views/Customer/CustomerRequestView/components/CustomerRequestHeader/components/ActionButton.vue'
import SmallFarmerCardWithList from '@/components/Farmer/SmallFarmerCardWithList.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { computed, UnwrapRef } from 'vue'
import { useRouter } from 'vue-router'

interface Props {
  currentStep: number
  thirdFormState: UnwrapRef<any[]>
  farmers: UnwrapRef<any[]>
  selectedFarmers: UnwrapRef<string[]>
  isSelectFarmers: UnwrapRef<boolean>
  isFarmersFetching: UnwrapRef<boolean>
  farmersSearchQuery: UnwrapRef<string>
}

const props = defineProps<Props>()
const emit = defineEmits([
  'set-filter',
  'handleSelectFarmerModal',
  'saveFarmers',
  'setSelectedFarmers',
  'setSearchQuery',
  'setCurrentStep',
])
const width100 = { width: '100%' }
const simpleEmptyImage = Empty.PRESENTED_IMAGE_SIMPLE
const stepsItems = [
  {
    title: 'Требования к поставке',
    key: 'Требования к поставке',
  },
  {
    title: 'Список товаров',
    key: 'Список товаров',
  },
  {
    title: 'Поставщики',
    key: 'Поставщики',
  },
]
const handleSelectAll = (event: any) => {
  if (event.target.checked) {
    selectAllFarmers()
  } else unselectAllFarmers()
}
const selectAllFarmers = () => {
  const newSelectedFarmers = new Set([
    ...props.selectedFarmers,
    ...props.farmers.map((item) => item.id),
  ])
  emit('setSelectedFarmers', Array.from(newSelectedFarmers))
}

const unselectAllFarmers = () => {
  const newSelectedFarmers = [...props.selectedFarmers]
  props.farmers.forEach((farmer) => {
    newSelectedFarmers.splice(newSelectedFarmers.indexOf(farmer.id), 1)
  })
  emit('setSelectedFarmers', newSelectedFarmers)
}

const isCheckedAll = computed(() => {
  if (props.farmers.length === 0 || props.selectedFarmers.length === 0) {
    return false
  }
  for (const farmer of props.farmers) {
    if (!props.selectedFarmers.includes(farmer.id)) {
      return false
    }
  }
  if (
    props.selectedFarmers.length !== props.farmers.length &&
    !props.farmersSearchQuery.length
  ) {
    return false
  }
  return true
})

const saveFarmers = () => {
  emit('handleSelectFarmerModal', false)
  emit('saveFarmers')
}

const checkFarmer = (event: Event, id: string) => {
  if ((event.target as HTMLInputElement).checked) {
    emit('setSelectedFarmers', [...props.selectedFarmers, id])
  }
  if (!(event.target as HTMLInputElement).checked) {
    emit(
      'setSelectedFarmers',
      props.selectedFarmers.filter((uid) => uid !== id),
    )
  }
}

const router = useRouter()

const handleGoBack = () => {
  if (props.currentStep === 0) {
    router.push('/requests')
  } else {
    emit('setCurrentStep', props.currentStep - 1)
  }
}
</script>

<template>
  <div class="create-request-header">
    <Row justify="space-between" :gutter="80">
      <Col
        :style="{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          marginBottom: '10px',
        }"
      >
        <CustomerRequestHeaderActionButton
          type="Back"
          @handleClick="handleGoBack"
        />
        <h3>Новый запрос</h3>
      </Col>
      <Col flex="auto">
        <Steps :items="stepsItems" :current="currentStep" />
      </Col>
      <Col>
        <EButton
          v-if="props.currentStep === 2"
          :style-config="{
            ...secondaryBlueButton,
            fontSize14,
            padding: '11px 16px',
          }"
          @click="emit('handleSelectFarmerModal', true)"
        >
          Добавить поставщика
        </EButton>
        <div v-else />
      </Col>
    </Row>
  </div>

  <Teleport to="body">
    <ModalWindow
      :show="props.isSelectFarmers"
      :show-close-icon="true"
      class="select-modal select-farmers-modal"
      @close="emit('handleSelectFarmerModal', false)"
    >
      <template #header>
        <Space :size="25" :direction="'vertical'" :style="width100">
          <h3>Выберите подходящих поставщиков</h3>
          <Row :align="'middle'">
            <Col :flex="'auto'">
              <InputSearch
                :placeholder="'Поиск по поставщикам'"
                :size="'large'"
                @change="emit('setSearchQuery', $event.target.value)"
                allowClear
                class="search-input"
              />
            </Col>
            <Col :flex="'none'" class="to-right">
              <Checkbox
                :style="{ flexDirection: 'row-reverse' }"
                @change="handleSelectAll"
                :checked="isCheckedAll"
                :disabled="props.isFarmersFetching"
                class="select-all-checkbox"
                >Выбрать всех</Checkbox
              >
            </Col>
          </Row>
        </Space>
      </template>
      <template #body>
        <List
          :data-source="props.farmers"
          v-if="props.farmers.length"
          :style="{ ...width100 }"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Checkbox
                :checked="props.selectedFarmers.includes(item.id) || false"
                :key="item.id"
                :style="{
                  flexDirection: 'row-reverse',
                  width: '100%',
                }"
                @change="checkFarmer($event, item.id)"
              >
                <SmallFarmerCardWithList :farmer="item" />
              </Checkbox>
            </ListItem>
          </template>
        </List>
        <div class="no-data" v-else>
          <Empty :image="simpleEmptyImage" description="Нет данных" />
        </div>
        <div class="loading-indicator" v-if="props.isFarmersFetching">
          <FullscreenLoading :type="'secondary'" />
        </div>
      </template>
      <template #footer>
        <EButton
          :style-config="{
            ...primaryButton,
            fontSize14,
            padding: '14px 18px',
            marginRight: 'auto',
          }"
          :data="{
            disabled: !props.selectedFarmers.length || props.isFarmersFetching,
          }"
          class="cancel__button"
          @click="saveFarmers"
        >
          Выбрать поставщиков
        </EButton>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.create-request-header {
  padding: 24px 32px;
  border-bottom: 1px solid $gray-300;
  h3 {
    @include h3();
  }
}
.select-modal {
  :deep(.modal-container) {
    // width: 800px;
    height: calc(100vh - 100px);
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-container) {
    box-sizing: border-box;
  }
  :deep(.wrapper) {
    height: calc(100vh - 100px);
    //height: 100%;
    display: flex;
    flex-direction: column;
  }
  :deep(.modal-header) {
    padding: 32px 32px 0;
    box-sizing: border-box;
    .to-right {
      transform: translateX(40px);
    }
    h3 {
      @include h3();
      color: $gray-800;
    }
    span.search-input,
    .select-all-checkbox span {
      @include p3();
    }
  }
  :deep(.modal-footer) {
    box-sizing: border-box;
    padding: 24px 32px;
    display: flex;
    justify-items: flex-start;
    border-top: 1px solid $gray-300;
  }
  :deep(.modal-body) {
    min-width: 1070px;
    box-sizing: border-box;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    border-top: 1px solid $gray-300;
    margin-top: 24px;
  }
  :deep(.no-data) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :deep(.ant-list-item) {
    padding: 24px 26px !important;
    &:hover {
      background-color: #0000000a;
    }
  }

  :deep(.ant-checkbox-wrapper) {
    //width: 100%;
    justify-content: space-between;
  }

  :deep(.ant-checkbox-wrapper:after) {
    position: absolute;
  }
  :deep(.ant-checkbox) {
    transform: scale(1.25);
  }
  :deep(.checkbox-group .ant-checkbox) {
    align-self: flex-start;
  }
  .loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }
}
</style>
