<script lang="ts">
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import AgregateFarmerRes from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateFarmerRes/AgregateFarmerRes.vue'
import AgregateProductRes from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateProductRes/AgregateProductRes.vue'
import SupplierDrawer from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/SupplierDrawer/SupplierDrawer.vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import NoAnswersYet from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/NoAnswersYet.vue'

export default defineComponent({
  name: 'CustomerRequestAnswersTab',
  components: {
    NoAnswersYet,
    SupplierDrawer,
    AgregateProductRes,
    AgregateFarmerRes,
  },
  setup() {
    const {
      currentCustomerRequest: { recipients },
    } = useCustomerRequestStore()

    const { currentTab } = useCustomerRequestStore()

    const noAnswersYet = computed(() =>
      recipients?.every(
        (recipient) =>
          recipient.answer_status === 'waiting' ||
          recipient.answer_status === 'received',
      ),
    )

    const zoomLevel = computed(() => window.devicePixelRatio * 100);

    function setTableWrapperHeight() {
      const tableWrapper = document.querySelector('.table-wrapper');
      if (tableWrapper && zoomLevel.value >= 110) {
        // Устанавливаем высоту элемента равной высоте окна
        tableWrapper.style.height = `${window.innerHeight + 70}px`;
      } else if (tableWrapper) {
        tableWrapper.style.height = '100%';
      }
    }

    onMounted(() => {
      setTableWrapperHeight();
      window.addEventListener('resize', setTableWrapperHeight);
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setTableWrapperHeight);
    })

    return {
      noAnswersYet,
      currentTab,
    }
  },
})
</script>

<template>
  <div class="customer-request-answers-tab">
    <template v-if="!noAnswersYet">
      <AgregateFarmerRes v-show="currentTab.value === 2" />
      <AgregateProductRes v-show="currentTab.value === 3" />
    </template>
    <NoAnswersYet v-else />
    <SupplierDrawer />
  </div>
</template>

<style lang="scss">
.customer-request-answers-tab {
  width: 100%;
  padding: 24px 24px 0;
}

.segmented-block {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.empty-card {
  padding-top: 96px;
}

.img-wrapper {
  width: 360px;
  height: 220px;
}
</style>
