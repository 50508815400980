<script lang="ts" setup>
import { Button } from 'ant-design-vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import InfoSearchCounter from '@/views/HomeUnauthorized/components/UnitingBlock/components/UnitingBlockInfoSearch/components/InfoSearchCounter.vue'
import { computed } from 'vue'

interface IProps {
  farmersCount: number
  productsCount: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['toggleFilters'])

const counters = computed(() => [
  {
    count: props.farmersCount,
    text: 'поставщиков\nc нами',
  },
  {
    count: props.productsCount,
    text: 'товарных\nпозиций',
  },
])

const handleClickSearchButton = () => {
  emit('toggleFilters')
}
</script>

<template>
  <div class="uniting-block-info-search">
    <div class="uniting-block-info-search__counters">
      <InfoSearchCounter
        v-for="(counter, index) in counters"
        :key="index"
        :counter="counter"
      />
    </div>
    <Button
      type="primary"
      class="uniting-block-info-search__button"
      @click="handleClickSearchButton"
    >
      <SearchOutlined class="button__icon" />
    </Button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/style/variables';

.button__icon {
  height: 20px;
  width: 20px;
}

.uniting-block-info-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 107%;
  border-radius: 12px;
  padding: 16px;
  gap: 28px;
  background: variables.$info-block-search-gradient;
  box-shadow: variables.$info-block-card-shadow;
  &__counters {
    display: flex;
    gap: 30px;
  }
  &__button {
    background: variables.$info-block-bnt-search-color;
    border: 1px solid variables.$info-block-bnt-search-color;
    box-shadow: variables.$info-block-bnt-search-box-shadow;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    padding: 0;
    transition: 0.35s ease-in-out;
    &:hover {
      cursor: pointer;
      border: 1px solid variables.$btn-info-search-bg-active;
      background: variables.$btn-info-search-bg-active;
      box-shadow: variables.$btn-info-search-box-shadow;
    }
  }
}
</style>
