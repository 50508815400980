<script setup lang="ts">
import {
  Flex,
  Popover,
  Table,
  Tag,
  TypographyText as TpText,
} from 'ant-design-vue'
import { computed } from 'vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import {
  priceWithoutVat,
  roundNumber,
  splitNumberByThreeDigitsNumber,
} from '@/helpers/scripts'
import dayjs from 'dayjs'
import {
  IAnswerPriceOption,
  IProduct,
  IProductAnswer,
  IProductRequest,
} from '@/types/interfaces'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import YouAskedPopover from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/AgregateFarmerRes/agregateFarmerTable/YouAskedPopover.vue'

interface ICustomPriceOption extends IAnswerPriceOption {
  product?: IProduct
  productAnswer: IProductAnswer
  productRequest?: IProductRequest
}

interface Props {
  productAnswerws: IProductAnswer[]
}

const props = defineProps<Props>()

const { products, product_requests } =
  useCustomerRequestStore().currentCustomerRequest

const getProductCustomCell = (record: ICustomPriceOption, index: number) => {
  const [firstIndex, lastIndex] = getProductInterval(
    Number(record?.product?.id),
  )
  if (firstIndex === index) {
    return { rowSpan: lastIndex - firstIndex + 1 }
  } else {
    return { rowSpan: 0 }
  }
}

const getCustomPriceCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received'
    ? {
        colSpan: 7,
      }
    : {
        colSpan: 1,
      }
}

const sharedOnCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received'
    ? {
        colSpan: 0,
      }
    : {
        colSpan: 1,
      }
}

// const productRequest = computed(() =>
//   product_requests.find(
//     (productReq) => productReq.product_id === products[0].id,
//   ),
// )

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '25%',
    customCell: getProductCustomCell,
  },
  {
    title: 'Цена без НДС',
    key: 'price',
    width: '10%',
    customCell: getCustomPriceCell,
  },
  {
    title: 'Цена с НДС',
    key: 'priceWithVAT',
    width: '15%',
    customCell: sharedOnCell,
  },
  {
    title: 'Объём',
    key: 'volume',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Остаточный срок хранения',
    key: 'remaining_shelf_life',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: `Пробный образец`,
    key: 'samples_needed',
    colSpan: 2,
    width: '5%',
    customCell: sharedOnCell,
  },
  {
    title: '',
    colSpan: 0,
    key: 'samples_amount',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Итого с НДС',
    key: 'total',
    width: '10%',
    customCell: sharedOnCell,
  },
]

const getPriceInterval = (
  minimalVolume: string,
  nextMinimalVolume: string | undefined,
  measurmentUnitId: string,
  isSpecialPrice: boolean,
) => {
  return isSpecialPrice
    ? `на ${roundNumber(minimalVolume)} ${measurmentUnitId}`
    : (nextMinimalVolume
        ? `${roundNumber(minimalVolume)}-${nextMinimalVolume}`
        : `от ${roundNumber(minimalVolume)}`) + ` ${measurmentUnitId}`
}

const getProductInterval = (productId: number) => {
  const firstIndex = tableData.value.findIndex(
    (item) => item.product?.id === productId,
  )
  const lastIndex = tableData.value.findLastIndex(
    (item: ICustomPriceOption) => item?.product?.id === productId,
  )

  return [firstIndex, lastIndex]
}

const getUnitId = (product: IProduct) => {
  return fillMeasurementUnitId(product)
}

const fillMeasurementUnitId = (item: any) => {
  if (item?.product_category?.measurement_unit_id === null) {
    const productText = item.fullName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    return item.measurement_unit_id
  } else return item.measurement_unit_id
}

const tableData = computed(() => {
  const priceOptionsArr: ICustomPriceOption[] = []

  const statuses = {
    answer_received: 3,
    answer_waiting: 2,
    no_answer: 1,
    no_product: 0,
  }

  const sortedProductAnswers = JSON.parse(
    JSON.stringify(props.productAnswerws),
  ).sort(
    (product1: IProductAnswer, product2: IProductAnswer) =>
      statuses[product2.status] - statuses[product1.status],
  )

  sortedProductAnswers.forEach((productAnswer: IProductAnswer) => {
    const productRequest = product_requests.find(
      (productReq: IProductRequest) =>
        productReq.product_id === productAnswer.product_id,
    )
    const product = products.find(
      (product) => product.id === productAnswer.product_id,
    )

    const additionalInfo = {
      productAnswer,
      product,
      productRequest,
    }

    if (productAnswer.status === 'answer_received') {
      productAnswer.price_options?.forEach((priceOption: any) => {
        priceOption.status &&
          priceOptionsArr.push({
            ...priceOption,
            ...additionalInfo,
          })
      })

      if (productAnswer.special_price && productAnswer.special_price.status) {
        priceOptionsArr.push({
          ...productAnswer.special_price,
          ...additionalInfo,
        })
      }
    } else {
      priceOptionsArr.push({
        ...additionalInfo,
      })
    }
  })

  return priceOptionsArr
})
</script>

<template>
  <Table
    bordered
    :columns="columns"
    :data-source="tableData"
    :pagination="false"
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'product'">
        <ProductWithProperties
          :volume="record.productRequest.customer_needed_volume"
          :remaining_shelf_life="record.productRequest.remaining_shelf_life"
          :measurement_unit_id="getUnitId(record.product)"
          :name="record.product.fullName"
          :is_sample_needed="!!record.productRequest?.needed_samples_number"
          :needed_samples_number="record.productRequest?.needed_samples_number"
        />
      </template>
      <template v-if="record.productAnswer.status === 'answer_received'">
        <template v-if="column.key === 'price'">
          <Flex vertical class="price-cell">
            <Popover
              v-if="!!record.comment"
              placement="topLeft"
              class="comment-popover"
            >
              <template #content>
                <Flex vertical class="commentary-block">
                  <TpText strong>Комментарий</TpText>
                  <span>{{ record.comment }}</span>
                </Flex>
              </template>
              <InfoCircleOutlined
                :style="{
                  color: 'rgba(22, 119, 255, 1)',
                }"
              />
            </Popover>
            <span>{{
              `${roundNumber(priceWithoutVat(Number(record.price_per_unit), record.vat_percent))} ₽/${getUnitId(record.product)}`
            }}</span>
            <TpText
              v-if="record.minimal_volume || record.special_volume"
              type="secondary"
            >
              {{
                getPriceInterval(
                  record.minimal_volume || record.special_volume,
                  tableData[index + 1]?.minimal_volume,
                  getUnitId(record.product),
                  !!record.customer_id,
                )
              }}
            </TpText>
            <Flex v-if="record.customer_id" vertical gap="8">
              <TpText v-if="record.expiration_date" type="secondary">
                до {{ dayjs(record.expiration_date).format('DD.MM.YYYY') }}
              </TpText>

              <span>
                <Tag color="success">
                  <template #icon>
                    <StarFilled />
                  </template>
                  Спеццена
                </Tag>
              </span>
            </Flex>
          </Flex>
        </template>
        <template v-else-if="column.key === 'priceWithVAT'">
          <Flex vertical>
            <TpText strong>
              {{
                `${roundNumber(record.price_per_unit)}₽/${getUnitId(record.product)}`
              }}
            </TpText>
            <TpText type="secondary">{{ `НДС ${record.vat_percent}%` }}</TpText>
          </Flex>
        </template>
        <template v-else-if="column.key === 'volume'">
          <YouAskedPopover
            v-if="
              record.volume !== record.productRequest.customer_needed_volume
            "
          >
            {{
              `Вы запрашивали ${record.productRequest.customer_needed_volume} ${getUnitId(record.product)}`
            }}
          </YouAskedPopover>
          <TpText class="onlyX"
            >{{ `${record.volume} ${getUnitId(record.product)}` }}
          </TpText>
        </template>
        <template v-else-if="column.key === 'remaining_shelf_life'">
          <YouAskedPopover
            v-if="
              record.remaining_shelf_life !==
              record.productRequest.remaining_shelf_life
            "
          >
            <template v-if="record.productRequest.remaining_shelf_life">
              {{
                record.productRequest.remaining_shelf_life ? `Вы запрашивали ${record.productRequest.remaining_shelf_life}%` : '-'
              }}
            </template>
            <template v-else>
              Вы запрашивали любой остаточный срок хранения
            </template>
          </YouAskedPopover>
          <TpText class="onlyX" v-if="record.remaining_shelf_life"
            >{{ record.remaining_shelf_life ? `${record.remaining_shelf_life}%` : '-' }}
          </TpText>
          <TpText v-else type="secondary" class="onlyX">–</TpText>
        </template>
        <template v-else-if="column.key === 'samples_needed'">
          <div class="onlyX">
            <CheckOutlined class="icon check" v-if="record.is_sample_needed" />
            <CloseOutlined class="icon close" v-else />
          </div>
        </template>
        <template v-else-if="column.key === 'samples_amount'">
          <TpText v-if="!!record.samples_number" class="onlyX">
            {{ `${record.samples_number} ${getUnitId(record.product)}` }}
          </TpText>
          <TpText v-else type="secondary" class="onlyX">–</TpText>
          <YouAskedPopover
            v-if="
              record.samples_number !==
              record.productRequest?.needed_samples_number
            "
          >
            <template v-if="record.productRequest.needed_samples_number">
              {{
                `Вы запрашивали ${record.productRequest.needed_samples_number} ${getUnitId(record.product)}`
              }}
            </template>
            <template v-else> Вы запрашивали любой объем </template>
          </YouAskedPopover>
        </template>
        <template v-else-if="column.key === 'total'">
          <TpText strong class="onlyX">
            {{
              `${splitNumberByThreeDigitsNumber(
                record.volume * record.price_per_unit,
                2,
              )} ₽`
            }}
          </TpText>
        </template>
      </template>
      <template v-else>
        <template v-if="column.key === 'price'">
          <TpText
            v-if="record.productAnswer.status === 'no_answer'"
            class="onlyX"
          >
            Отметил товар как неинтересующий
          </TpText>
          <TpText
            v-else-if="record.productAnswer.status === 'no_product'"
            class="onlyX"
          >
            У поставщика нет этого товара
          </TpText>
        </template>
      </template>
    </template>
  </Table>
</template>

<style lang="scss" scoped>
:deep(.ant-table-cell) {
  font-weight: 400 !important;
  text-align: start !important;
  position: relative;
}
.icon {
  font-size: 16px;

  &.close {
    color: rgba(0, 0, 0, 0.45);
  }
  &.check {
    color: rgba(22, 119, 255, 1);
  }
}
.cellCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.onlyX {
    left: 0;
    transform: translate(16px, -50%);
  }
}

.commentary-block {
  max-width: 356px;
}

.price-cell {
  position: relative;

  .comment-popover {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
