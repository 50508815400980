<script lang="ts" setup>

import CatalogMenuRightItem from '@/components/Catalog/CatalogMenuRightItem.vue'
import { Space } from 'ant-design-vue'
import { StarOutlined } from '@ant-design/icons-vue'
import StarLoader from '@/components/Loaders/StarLoader.vue'

interface IPropsRigthMenu {
  items: []
  current: object
  isCategoryFetching: boolean
  isSorting: boolean
}
const props = defineProps<IPropsRigthMenu>()
</script>

<template>
  <div class="catalog-menu-right-category" v-if="!props.isSorting">
    <Space :size="8">
      <img
        v-if="props.current?.icon_url"
        :src="props.current?.icon_url"
        alt="props.current?.name"
        width="32"
        height="32"
        loading="lazy"
      />
      <StarOutlined v-else :style="{ fontSize: '32px', color: '#00000073' }" />
      <h2 class="catalog-menu-right-title">{{ props.current?.name }}</h2>
    </Space>
    <CatalogMenuRightItem :items="props.items" v-if="!props.isSorting" />
    <div class="catalog-menu-loader" v-if="props.isSorting">
      <StarLoader />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.catalog-menu {
  height: 100%;
  min-height: 100px;
  transition: 1s ease-in-out;
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-right-category {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    min-height: 100px;
  }
}
</style>
