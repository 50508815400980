<script setup lang="ts">
import {
  Flex,
  Popover,
  Table,
  Tag,
  TypographyText as TpText,
} from 'ant-design-vue'
import {
  IAnswerPriceOption,
  IProduct,
  IProductAnswer,
  IRecipient,
} from '@/types/interfaces'
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons-vue'
import { computed } from 'vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import RecipientInfo from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/RecipientInfo/RecipientInfo.vue'
import {
  priceWithoutVat,
  roundNumber,
  splitNumberByThreeDigitsNumber,
} from '@/helpers/scripts'
import dayjs from 'dayjs'

interface ICustomPriceOption extends IAnswerPriceOption {
  recipient: IRecipient
  productAnswer: IProductAnswer
}

interface ICustomProductAnswer extends IProductAnswer {
  recipient: IRecipient
}

interface Props {
  product: IProduct
}

const props = defineProps<Props>()

const {
  currentCustomerRequest: { recipients, product_requests },
} = useCustomerRequestStore()

const productRequest = computed(() =>
  product_requests.find(
    (productReq) => productReq.product_id === props.product.id,
  ),
)

const unitId = () =>
  props.product.product_category?.measurement_unit_id ||
  fillMeasurementUnitId(props.product)

const fillMeasurementUnitId = (item: any) => {
  if (item?.product_category?.measurement_unit_id === null) {
    const productText = item?.farmerProductName || item?.fullName
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    return item.measurement_unit_id
  } else return props.product.product_category?.measurement_unit_id
}

const getRecipientInterval = (recipientId: number) => {
  const firstIndex = tableData.value.findIndex(
    (item) => item.recipient?.id === recipientId,
  )
  const lastIndex = tableData.value.findLastIndex(
    (item: ICustomPriceOption) => item?.recipient?.id === recipientId,
  )

  return [firstIndex, lastIndex]
}

const getRecipientCustomCell = (record: ICustomPriceOption, index: number) => {
  const [firstIndex, lastIndex] = getRecipientInterval(
    Number(record?.recipient?.id),
  )
  if (firstIndex === index) {
    return { rowSpan: lastIndex - firstIndex + 1 }
  } else {
    return { rowSpan: 0 }
  }
}

const getCustomPriceCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received' ||
    record.recipient.answer_status !== 'answered'
    ? {
        colSpan: 7,
      }
    : {
        colSpan: 1,
      }
}

const sharedOnCell = (record: ICustomPriceOption) => {
  return record.productAnswer.status !== 'answer_received' ||
    record.recipient.answer_status !== 'answered'
    ? {
        colSpan: 0,
      }
    : {
        colSpan: 1,
      }
}

const columns = [
  {
    title: 'Поставщик',
    key: 'recipient',
    width: '25%',
    customCell: getRecipientCustomCell,
  },
  {
    title: 'Цена без НДС',
    key: 'price',
    width: '10%',
    customCell: getCustomPriceCell,
  },
  {
    title: 'Цена с НДС',
    key: 'priceWithVAT',
    width: '15%',
    customCell: sharedOnCell,
  },
  {
    title: 'Объём',
    key: 'volume',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Остаточный срок хранения',
    key: 'remaining_shelf_life',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: `Пробный образец`,
    key: 'samples_needed',
    colSpan: 2,
    width: '5%',
    customCell: sharedOnCell,
  },
  {
    title: '',
    colSpan: 0,
    key: 'samples_amount',
    width: '10%',
    customCell: sharedOnCell,
  },
  {
    title: 'Итого с НДС',
    key: 'total',
    width: '10%',
    customCell: sharedOnCell,
  },
]

const tableData = computed(() => {
  const neededProductAnswers: ICustomProductAnswer[] = []

  recipients?.forEach((recipient) => {
    const neededRecipientProcutAnswer =
      recipient.price_answer?.product_answers?.find(
        (productAnswer) => productAnswer.product_id === props.product.id,
      )

    if (neededRecipientProcutAnswer) {
      neededProductAnswers.push({
        ...neededRecipientProcutAnswer,
        recipient,
      })
    } else {
      neededProductAnswers.push({
        recipient,
      })

      // кейс, когда recipient имеет статус  'waiting', т.е. у него нет даже price_answer
    }
  })

  const recipientStatuses = {
    refused: 10,
    waiting: 20,
    closed: 30,
    canceled: 40,
    received: 50,
    answered: 60,
  }

  const productAnswerStatuses = {
    no_product: 1,
    no_answer: 2,
    answer_waiting: 3,
    answer_received: 4,
  }

  neededProductAnswers.sort(
    (a, b) =>
      (productAnswerStatuses[b.status] ?? 0) +
      recipientStatuses[b?.recipient?.answer_status] -
      ((productAnswerStatuses[a.status] ?? 0) +
        recipientStatuses[a?.recipient?.answer_status]),
  ) // для сортировки по реципиентам в порядке статусов реципиентов и productAnswers

  const priceOptions: ICustomPriceOption[] = []

  neededProductAnswers.forEach((needProductAnswer) => {
    if (
      needProductAnswer.status === 'no_product' ||
      needProductAnswer.status === 'no_answer' ||
      needProductAnswer.recipient.answer_status !== 'answered'
    ) {
      priceOptions.push({
        recipient: needProductAnswer.recipient,
        productAnswer: needProductAnswer,
      })

      return
    }

    needProductAnswer.price_options?.forEach((option) => {
      option.status &&
        priceOptions.push({
          ...option,
          recipient: needProductAnswer.recipient,
          productAnswer: needProductAnswer,
        })
    })

    if (
      needProductAnswer.special_price &&
      needProductAnswer.special_price.status
    ) {
      priceOptions.push({
        ...needProductAnswer.special_price,
        recipient: needProductAnswer.recipient,
        productAnswer: needProductAnswer,
      })
    }
  })
  return priceOptions
})

const getPriceInterval = (
  minimalVolume: string,
  nextMinimalVolume: string | undefined,
  measurmentUnitId: string,
  isSpecialPrice: boolean,
) => {
  return isSpecialPrice
    ? `на ${roundNumber(minimalVolume)} ${measurmentUnitId}`
    : (nextMinimalVolume
        ? `${roundNumber(minimalVolume)}-${roundNumber(nextMinimalVolume)}`
        : `от ${roundNumber(minimalVolume)}`) + ` ${measurmentUnitId}`
}
</script>

<template>
  <div class="table-wrapper">
    <Table
      bordered
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key === 'volume'">
          <Flex vertical :gap="8">
            <span>
              {{ column.title }}
            </span>
            <TpText strong>{{
              `${productRequest?.customer_needed_volume} ${unitId()}`
            }}</TpText>
          </Flex>
        </template>
        <template v-else-if="column.key === 'remaining_shelf_life'">
          <Flex vertical :gap="8">
            <span>
              {{ column.title }}
            </span>
            <TpText strong v-if="productRequest?.remaining_shelf_life">{{
              `${productRequest?.remaining_shelf_life}%`
            }}</TpText>
          </Flex>
        </template>
        <template v-else-if="column.key === 'samples_needed'">
          <Flex vertical :gap="8">
            <Flex justify="flex-start">
              {{ column.title }}
            </Flex>
            <Flex
              v-if="productRequest?.needed_samples_number"
              justify="flex-start"
            >
              <TpText strong>
                {{ `${productRequest?.needed_samples_number} ${unitId()}` }}
              </TpText>
            </Flex>
          </Flex>
        </template>
      </template>
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'recipient'">
          <div>
            <RecipientInfo
              :need-second-icon="true"
              :recipient="record.recipient"
            />
          </div>
        </template>
        <template
          v-if="
            record.productAnswer.status === 'answer_received' &&
            record.recipient.answer_status === 'answered'
          "
        >
          <template v-if="column.key === 'price'">
            <Flex vertical class="price-cell">
              <Popover
                v-if="!!record.comment"
                placement="topLeft"
                class="comment-popover"
              >
                <template #content>
                  <Flex vertical class="commentary-block">
                    <TpText strong>Комментарий</TpText>
                    <span>{{ record.comment }}</span>
                  </Flex>
                </template>
                <InfoCircleOutlined
                  :style="{
                    color: 'rgba(22, 119, 255, 1)',
                  }"
                />
              </Popover>
              <span>{{
                `${roundNumber(priceWithoutVat(record.price_per_unit, record.vat_percent))} ₽/${unitId()}`
              }}</span>
              <TpText
                v-if="record.minimal_volume || record.special_volume"
                type="secondary"
              >
                {{
                  getPriceInterval(
                    record.minimal_volume || record.special_volume,
                    tableData[index + 1]?.minimal_volume,
                    unitId(),
                    !!record.customer_id,
                  )
                }}
              </TpText>
              <Flex v-if="record.customer_id" vertical gap="8">
                <TpText v-if="record.expiration_date" type="secondary">
                  до {{ dayjs(record.expiration_date).format('DD.MM.YYYY') }}
                </TpText>

                <span>
                  <Tag color="success">
                    <template #icon>
                      <StarFilled />
                    </template>
                    Спеццена
                  </Tag>
                </span>
              </Flex>
            </Flex>
          </template>
          <template v-else-if="column.key === 'priceWithVAT'">
            <Flex vertical>
              <TpText strong>
                {{ `${roundNumber(record.price_per_unit)}₽/${unitId()}` }}
              </TpText>
              <TpText type="secondary">{{
                `НДС ${record.vat_percent}%`
              }}</TpText>
            </Flex>
          </template>
          <template v-else-if="column.key === 'volume'">
            <TpText class="onlyX"
              >{{ `${record.volume} ${unitId()}` }}
            </TpText>
          </template>
          <template v-else-if="column.key === 'remaining_shelf_life'">
            <TpText
              class="onlyX"
              v-if="record.remaining_shelf_life"
              >{{ `${record.remaining_shelf_life}%` }}
            </TpText>
            <TpText v-else type="secondary" class="onlyX"
              >–</TpText
            >
          </template>
          <template v-else-if="column.key === 'samples_needed'">
            <div class="onlyX">
              <CheckOutlined class="icon check" v-if="record.is_sample_needed" />
              <CloseOutlined class="icon close" v-else />
            </div>
          </template>
          <template v-else-if="column.key === 'samples_amount'">
            <TpText v-if="!!record.samples_number" class="onlyX">
              {{ `${record.samples_number} ${unitId()}` }}
            </TpText>
            <TpText v-else type="secondary" class="onlyX"
              >–</TpText
            >
          </template>
          <template v-else-if="column.key === 'total'">
            <TpText strong class="onlyX">
              {{
                `${splitNumberByThreeDigitsNumber(
                  record.volume * record.price_per_unit,
                  2,
                )} ₽`
              }}
            </TpText>
          </template>
        </template>
        <template v-else>
          <template v-if="column.key === 'price'">
            <TpText
              v-if="
                record.recipient.answer_status === 'closed' ||
                record.recipient.answer_status === 'canceled'
              "
              class="onlyX"
            >
              Не дал ответ
            </TpText>
            <TpText
              v-else-if="
                record.recipient.answer_status === 'waiting' ||
                record.recipient.answer_status === 'received'
              "
              class="onlyX"
            >
              Ещё не дал ответ
            </TpText>
            <TpText
              v-else-if="record.recipient.answer_status === 'refused'"
              class="onlyX"
            >
              Отметил запрос как неинтересующий
            </TpText>
            <TpText
              v-else-if="record.productAnswer.status === 'no_answer'"
              class="onlyX"
            >
              Отметил товар как неинтересующий
            </TpText>
            <TpText
              v-else-if="record.productAnswer.status === 'no_product'"
              class="onlyX"
            >
              У поставщика нет этого товара
            </TpText>
          </template>
        </template>
      </template>
    </Table>
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-table-cell) {
  font-weight: 400 !important;
  text-align: start !important;
}

.table-wrapper {
  margin-bottom: 40px;
}

.icon {
  font-size: 16px;

  &.close {
    color: rgba(0, 0, 0, 0.45);
  }
  &.check {
    color: rgba(22, 119, 255, 1);
  }
}

.cellCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.onlyX {
    left: 0;
    transform: translate(16px, -50%);
  }
}

.commentary-block {
  max-width: 356px;
}

.price-cell {
  position: relative;

  .comment-popover {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
