<script setup lang="ts">
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { defineProps, onMounted } from 'vue'
import { Button } from 'ant-design-vue'
import router from '@/router'

interface IProps {
  show: boolean
  path: string
  title?: string
  message?: string
  onCancel: () => void
  beforeRedirect?: () => void
}

const props = defineProps<IProps>()
const emit = defineEmits(['close'])

const handleRedirect = () => {
  props.beforeRedirect?.()
  emit('close')
  router.push(props.path)
}

const handleCancel = () => {
  emit('close')
  props.onCancel()
}

const handleClose = async () => {
  emit('close')
}
</script>

<template>
  <ModalWindow
    :show="props.show"
    :show-close-icon="true"
    class="select-modal select-address-modal"
    @close="handleClose"
  >
    <template #header v-if="props.title">
      <div class="select-address-modal__block">{{ props.title }}</div>
    </template>
    <template #body v-if="props.message">
      <div>{{ props.message }}</div>
    </template>
    <template #footer>
      <Button class="cancel__button" @click="handleCancel"> Остаться </Button>
      <Button type="primary" class="cancel__button" @click="handleRedirect">
        Перейти
      </Button>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.select-modal {
  :deep(.modal-container) {
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-header) {
    padding: 20px 24px;
    padding-bottom: 8px;
    box-sizing: border-box;
    max-width: 500px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      display: block;
    }
  }
  :deep(.modal-footer) {
    box-sizing: border-box;
    padding: 20px 24px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    button {
      font-size: 14px;
      font-weight: 500;
    }
  }
  :deep(.modal-body) {
    box-sizing: border-box;
    padding: 0 24px;
    max-width: 500px;
    min-height: 50px;
    margin-bottom: auto;
    overflow-y: auto;
    font-size: 16px;
    line-height: 22px;
  }
}

.wrapper {
  @include wrapper();
}
</style>
