<script lang="ts" setup>
import { computed, reactive, ref, onMounted } from 'vue'
import { primaryButton } from '@/assets/EgalStyles/EButton'
import { inputDataConfig } from '@/assets/EgalData/EInput'
import { inputStyleConfig } from '@/assets/EgalStyles/EInput'
import { format, maxString, minString, required } from '@/helpers/validators'
import { emailRegEx, togglePasswordEyeIcon } from '@/types/globalConst'
import { useAuth } from '@/composables/useAuth'
import { useCaptcha } from '@/composables/useCaptcha'
import { useTimer } from '@/composables/useTimer'
import router from '@/router'
import { useCookies } from 'vue3-cookies'
import emitter from '@/emitter'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import {useRoute} from "vue-router";

const { cookies } = useCookies()
const { login } = useAuth()

const umt = cookies.get('umt')
const chat = cookies.get('chat')
const fromMobile = cookies.get('fromMobile')

const { getCoreCookie } = useJWTDecode()

const toLoginFromRegister =
  localStorage.getItem('toLoginFromRegister') !== 'true'

const isAdmin = ref(getCoreCookie()?.company_type === Roles.Admin || getCoreCookie()?.company_type === Roles.SuperAdmin)

const isAuth = ref(!!umt && !!chat)
const isMobile = ref(!!fromMobile)

let loginForm = reactive({
  email: '',
  password: '',
  captcha: null,
})

// внешняя ошибка (поля не заполнены или ошибка с бэка)
const outerError = ref('')
const failedLoginCounter = ref(false)

// ошибки валидации при заполнении полей
const errors = ref({
  email: '',
  password: '',
})

const isFieldsValid = ref(() => {
  return (
    !!loginForm.password &&
    !!loginForm.email &&
    !errors.value.password &&
    !errors.value.email
  )
})

emitter.on('clear-login-form', () => {
  loginForm.captcha = null
  loginForm.email = ''
  loginForm.password = ''
  outerError.value = ''
  failedLoginCounter.value = false
  errors.value.email = ''
  errors.value.password = ''
  isFieldsValid.value = () => true
})

const passwordInput = reactive({
  type: 'password',
  iconRight: 'eye',
})

const { recaptcha } = useCaptcha()

const { timer, startTimer, formatTime, setDisableTime } = useTimer()

timer.value = 60

const isDisableTime = computed(
  () => failedLoginCounter.value && timer.value !== 0,
)

const loginUser = async () => {
  if (timer.value === 0) {
    failedLoginCounter.value = false
    timer.value = 60
  }
  loginForm.captcha = await recaptcha()

  if (!isFieldsValid.value) {
    outerError.value = 'Обязательное поле'
    return
  }
  const { cookies } = useCookies()
  cookies.set('loginForm', JSON.stringify(loginForm), '365d', '')

  login({ ...loginForm, email: loginForm.email.toLowerCase() }, false)
    .then((res) => {
      const token = res?.data.action_result.data.user_master_token
      cookies.set('umt', token, '365d', '')
      cookies.remove('inviteToken')
      cookies.remove('inviteStepOne')
      // Для админа после авторизации происходит редирект на страницу пользователей
      if (getCoreCookie()?.company_type === Roles.Admin || getCoreCookie()?.company_type === Roles.SuperAdmin) {
        router.push('/admin/users')
      } else {
        router.push('/')
      }
    })
    .catch(async (err) => {
      outerError.value = err.email
      failedLoginCounter.value = err.blockLogin
      if (failedLoginCounter.value && timer.value === 60) {
        setDisableTime(60)
        startTimer()
      }

      if (err.confirmEmail) {
        localStorage.removeItem('umt')
        localStorage.removeItem('umrt')
        cookies.remove('core')
        cookies.remove('auth')
        await router.push('/confirm-email')
      }
    })
}

const removeCookies = async () => {
  cookies.remove('registerForm')
  cookies.remove('umt')
  cookies.remove('auth')
  cookies.remove('role')
  cookies.remove('preRegisterData')
  cookies.remove('name')
  cookies.remove('chat')
  cookies.remove('activeOrganisation')
  cookies.remove('threeAlt')
  cookies.remove('activeBank')
  cookies.remove('user')
  cookies.remove('emailConfirmed')
  cookies.remove('registerToken')
  cookies.remove('loginForm')
  cookies.remove('inviteToken')
  cookies.remove('registerByInvite')
}

const pushToRegister = async () => {
  await removeCookies().then(() => {
    router.push('/register')
  })
}

/**
 * Смена типа и икноки у поля Пароль
 * @param ev
 */
const onClick = (ev: { target: any }) => {
  togglePasswordEyeIcon(ev.target.tagName, passwordInput)
}

// onMounted(() => {
// TODO: Доработать автологин для админа и для пользователей
//   setInterval(() => {
//     if (
//       isAuth.value &&
//       toLoginFromRegister &&
//       !isMobile.value &&
//       !isAdmin?.value
//     ) {
//       router.push('/')
//     }
//   }, 2000)
// })

onMounted(() => {
  cookies.remove('user')
  cookies.remove('userData')
  cookies.remove('company_id')
  cookies.remove('threeAlt')
  cookies.remove('preRegisterData')
  cookies.remove('loginForm')
  localStorage.removeItem('user')
  localStorage.removeItem('userData')
  localStorage.removeItem('company_id')
  localStorage.removeItem('isVerified')
  localStorage.removeItem('isSkipUserDataFill')
  localStorage.removeItem('umrt')
  localStorage.removeItem('FourAlt')
  localStorage.removeItem('ThreeAlt')
  localStorage.removeItem('loginForm')
  localStorage.removeItem('inviteToken')
  localStorage.removeItem('registerByInvite')
  setInterval(() => {
    if (
      isAuth.value &&
      toLoginFromRegister &&
      isMobile.value &&
      !isAdmin?.value
    ) {
      router.push('/chatify')
    }
  }, 2000)
})

const route = useRoute()
</script>

<template>
  <div class="auth-card">
    <div class="auth-card__header">Вход в систему</div>
    <div class="auth-card__body">
      <EInput
          class="ym-record-keys"
        v-model.trim="loginForm.email"
        :data="{
          ...inputDataConfig,
          id: 'email',
          label: 'Почта',
          placeholder: '',
          modelValue: loginForm.email,
          error: outerError,
          validators: [
            required,
            maxString(50),
            format(emailRegEx, 'Неверный формат'),
          ],
        }"
        @keyup.enter="loginUser"
        @change:modelValue="() => (outerError = '')"
        @update:modelValue="() => (outerError = '')"
        @error="(err) => (errors.email = err)"
        :style-config="inputStyleConfig"
      />
      <EInput
        class="password ym-record-keys"
        v-model.trim="loginForm.password"
        :data="{
          ...inputDataConfig,
          id: 'password',
          label: 'Пароль',
          placeholder: '',
          modelValue: loginForm.password,
          error: outerError,
          type: passwordInput.type,
          iconRight: passwordInput.iconRight,
          validators: [
            required,
            maxString(24),
            minString(6),
            format(
              /[a-zA-Z]/,
              'Пароль должен содержать минимум одну букву латинского алфавита',
            ),
            format(/\d/, 'Пароль должен содержать минимум одну цифру'),
          ],
        }"
        :style-config="inputStyleConfig"
        @click="onClick"
        @keyup.enter="loginUser"
        @change:modelValue="() => (outerError = '')"
        @update:modelValue="() => (outerError = '')"
        @error="(err) => (errors.password = err)"
      />
    </div>
    <div class="auth-card__error" v-if="isDisableTime">
      Слишком много попыток входа, попробуйте войти через
      {{ formatTime(timer) }}
    </div>

    <div class="auth-card__footer">
      <EButton
        @click="loginUser"
        :style="primaryButton"
        :data="{ disabled: isDisableTime }"
        >Войти
      </EButton>
      <div class="auth-card__links">
        <a class="auth-card__links-register" @click="pushToRegister"
          >Зарегистрировать компанию</a
        >
        <router-link class="auth-card__links-recovery" to="/recovery"
          >Забыли пароль?</router-link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.auth-card {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin: auto;
  width: 356px;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__error {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #718096;
    margin-bottom: 40px;
    margin-top: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
    gap: 27px;
    margin-bottom: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    a {
      @include router-link();
      cursor: pointer;
    }
    &-register {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
    }
    &-recovery {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
    }
  }
}

.egal-button {
  justify-content: center;
}

.password {
  :deep(.input-container .icon--right) {
    cursor: pointer;

    &:hover {
      color: $gray-700;
    }
  }
}

.eye-icon {
  color: $gray-800;
}
</style>
