<script setup lang="ts">

import TitleToolbarBlock from '@/views/Farmer/response/toolbarWidget/TitleToolbarBlock.vue'
import CustomerSubBlock from '@/views/Farmer/response/toolbarWidget/CustomerSubBlock.vue'

interface Props {
  customerComment: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="customer-additionally">
    <div class="customer-additionally__title">
      <TitleToolbarBlock title="Дополнительно от покупателя" />
    </div>
    <CustomerSubBlock title="Комментарий" :subtitle="props.customerComment" />
  </div>
</template>

<style scoped lang="scss">
.customer-additionally {
  padding: 20px 24px 0;

  &__title {
    margin-bottom: 24px;
  }
}
</style>
