import Centrifuge from 'centrifuge'

export const useCentrifuge = () => {
  let centrifuge = new Centrifuge(import.meta.env.VITE_WSS)
  const addChanel = (channel: string, callback: (data: any) => any) => {
    centrifuge.subscribe(channel, function (ctx: any) {
      callback(ctx.data)
    })
  }

  const startCentrifuge = () => {
    centrifuge = new Centrifuge(import.meta.env.VITE_WSS)
    centrifuge.on('connect', function (ctx: any) {
      return
    })

    centrifuge.on('disconnect', function (ctx: any) {
      return
    })

    centrifuge.connect()

    return () => {
      centrifuge.disconnect()
    }
  }

  return { addChanel, centrifuge, startCentrifuge }
}
