<script lang="ts" setup>
import { TypographyTitle as TpTitle } from 'ant-design-vue'

interface Props {
  level: number
}

const props = defineProps<Props>()
</script>

<template>
  <TpTitle class="title" :level="props.level">
    <slot></slot>
  </TpTitle>
</template>

<style lang="scss" scoped>
.title {
  margin: 0;
}
</style>
