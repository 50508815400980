<script setup lang="ts">

import { IReduction } from '@/types/interfaces'
import CustomerReductionProducts from '@/components/Customer/Reduction/CustomerReductionProducts.vue'
import CustomerReductionGeneralInfo from '@/components/Customer/Reduction/CustomerReductionGeneralInfo.vue'
import { Divider } from 'ant-design-vue'

interface IProps {
  reductionData: IReduction | null
}

const props = defineProps<IProps>()
</script>

<template>
  <div class="customer-reduction-info">
    <CustomerReductionGeneralInfo :reductionData="props.reductionData" />
    <Divider type="vertical" style="height: 100%" />
    <CustomerReductionProducts
      :products="props.reductionData?.reduction_product_requests ?? []"
    />
  </div>
</template>

<style scoped lang="scss">
.customer-reduction-info {
  display: grid;
  grid-template-columns: 1fr 16px 1fr;
  column-gap: 24px;
  margin-top: 8px;
}
</style>
