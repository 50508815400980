import { APIModels } from '@/types/enums'
import { IAnswerPriceOption } from '@/types/interfaces'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class AnswerPriceOptionAPIModel {
  async updateMany(data: Partial<IAnswerPriceOption>[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.AnswerPriceOption,
      method: EgalMethods.updateMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const AnswerPriceOptionAPI = new AnswerPriceOptionAPIModel()
export { AnswerPriceOptionAPI }
