<script lang="ts" setup>
import { computed, h, onMounted, ref, Ref, watch } from 'vue'
import {
  compare_price_if_equal,
  dateToLocale,
  formatDocumentName,
  formatPhoneNumber,
  formatPrice,
  getSortedArrayByCreatedAt,
  getStatusType,
  getUserId,
  getUserIdTemporary,
  is_orders_has_difference,
  orderDownloadOnClick,
  priceWithVat,
  show_notification,
  systemDocsName,
} from '@/helpers/scripts'
import {
  fontSize14,
  fontSize16,
  outlineBlackButton,
  primaryButton,
  secondaryButton,
} from '@/assets/EgalStyles/EButton'
import {DocumentTypes, NewGeneralOrderStatus, Roles, Sockets} from '@/types/enums'
import CheckboxTable from '@/components/Orders/CheckboxTable.vue'
import CreateHeader from '@/components/CreateProduct/CreateHeader.vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { OrderPositionAPI } from '@/api/OrderPositionAPI'
import { OrderAPI } from '@/api/OrderAPI'
import { Document, UploadDocument } from '@/types/types'
import DocumentOrder from '@/components/Document/DocumentOrder.vue'
import ModalCloseWindow from '@/components/Modal/ModalCloseWindow.vue'
import { DocumentAPI } from '@/api/DocumentAPI'
import { useDocument } from '@/composables/useDocument'
import DocumentUploadListItem from '@/components/Document/DocumentUploadListItem.vue'
import { useStatus } from '@/composables/useStatus'
import { useCentrifuge } from '@/composables/useCentrifuge'
import { useJWTDecode } from '@/composables/useJWTDecode'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { ContactAPI } from '@/api/ContactAPI'

import CustomModal from '@/components/Modal/CustomModal.vue'
import { OrderDocumentAPI } from '@/api/OrderDocumentAPI'
import DefaultModalWindow from '@/components/Modal/DefaultModalWindow.vue'
import CalendarIcon from '@/components/Icons/CalendarIcon.vue'
import { useRoute } from 'vue-router'
import FarmerDeliveryIcon from '@/components/Icons/FarmerDeliveryIcon.vue'
import PickupIcon from '@/components/Icons/PickupIcon.vue'
import CustomTag from '@/components/CustomTag/CustomTag.vue'
import {
  IAddress,
  IContact,
  ICustomer,
  IFarmer,
  IFarmerProduct,
  IMark,
  IOrder,
  IOrderDocument,
  IOrderPosition,
  IPartnership,
} from '@/types/interfaces'
import RoundLoader from '@/components/Loaders/RoundLoader.vue'
import FarmLoader from '@/components/Loaders/FarmLoader.vue'
import BigSidebar from '@/components/Sidebar/BigSidebar.vue'
import DeletionWarningModal from '@/components/Orders/EditModalBody/DeletionWarningModal.vue'
import FileUploader from '@/components/Egal/EFileUploader/FileUploader.vue'
import { CustomerAPI } from '@/api/CustomerAPI'
import EditOrderPositionDrawer from '@/components/Orders/EditOrderPositionDrawer.vue'
import DrawerForm from '@/components/Drawer/DrawerForm.vue'
import YourRate from '../Rating/YourRate.vue'
import RatingSetModal from '@/components/Rating/RatingSetModal.vue'
import { orderMarkAPI } from '@/api/OrderMarkAPI'
import { Button, Modal, notification, Popover } from 'ant-design-vue'
import {
  FileTextOutlined,
  InfoCircleFilled,
  StarOutlined,
  UpOutlined,
} from '@ant-design/icons-vue'
import CartIcon from '@/components/Icons/CartIcon.vue'
import FarmerPartnershipModal from '@/components/Orders/FarmerPartnershipModal/FarmerPartnershipModal.vue'
import OrderDocumentDrawer from '@/components/Orders/OrderDocumentDrawer.vue'
import dayjs from 'dayjs'
import ModalUpdatePage from '@/components/ModalUpdatePage/ModalUpdatePage.vue'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import PartnerIcon from '@/components/Icons/PartnerIcon.vue'
import CustomerItem from '@/components/Orders/OrderHeaderItems/CustomerItem.vue'
import OrderCreatorItem from '@/components/Orders/OrderHeaderItems/OrderCreatorItem.vue'
import PaymentItem from '@/components/Orders/OrderHeaderItems/PaymentItem.vue'
// import ContactItem from '@/components/Orders/OrderHeaderItems/ContactItem.vue'
import AddressItem from '@/components/Orders/OrderHeaderItems/AddressItem.vue'
import ServicesItem from '@/components/Orders/OrderHeaderItems/ServicesItem.vue'
import CommentItem from '@/components/Orders/OrderHeaderItems/CommentItem.vue'
import FarmerEditOrderDrawer from '@/components/Orders/FarmerEditOrderDrawer.vue'
import { GeneralOrderAPI } from '@/api/GeneralOrderAPI'
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'
import {ModalKeys} from "@/components/ModalSystem/ModalKeys";
import {useModalStore} from "@/components/ModalSystem/ModalSystemStore";


interface IModalOrder {
  orderId: number
  status: string
  completed: boolean
  mark: number
  file_list?: any[]
  comment: string
  order_marks: IMark[]
}

const emit = defineEmits([
  'back',
  'cancel',
  'approve',
  'edit',
  'sign-contract',
  'signing-an-agreement',
  'changeOrderStatus',
  'refetchOrders',
])

const { getCoreCookie } = useJWTDecode()
const modalStore = useModalStore()

const isOpenProductDrawer = ref(false)
const selectedViewProduct = ref<IFarmerProduct>()
const selectedProductQuantity = ref<string>('')
const ratingModalOpen = ref(false)
const isShowModalUpdatePage = ref(false)

const closeProductDrawer = () => {
  isOpenProductDrawer.value = false
  selectedViewProduct.value = undefined
}

const openProductDrawer = (product: IFarmerProduct, quantity: string) => {
  selectedViewProduct.value = product
  selectedProductQuantity.value = quantity
  isOpenProductDrawer.value = true
}

/**
 * Определения стиля и текста у бейджа со статусом заказа
 */
const generalStatusData = computed(() => {
  const { setStatus, getStatusClass: getClass } = useStatus()
  setStatus(orderInfo.value!.status)
  const { cssClass, text } = getClass.value
  return { cssClass, text }
})
const downloadRef = ref<HTMLAnchorElement>()

const route = useRoute()
const orderInfo = ref<IOrder | null>(null)

const getGeneralOrder = async () => {
  orderInfo.value = await OrderAPI.getOneOrder(
    [],
    [
      'customer.users',
      'customer.company_rating',
      'generalOrder.delivery_address',
      'generalOrder.contact',
      'generalOrder.orders',
      'generalOrder.history',
      'partnership',
      'history',
      'shipping_address',
      'order_marks',
      'farmer',
    ],
    Number(route.params.id),
  ).then((res) => {
    res.history = getSortedArrayByCreatedAt(res.history)
    res.general_order.history = getSortedArrayByCreatedAt(
      res.general_order.history,
    )
    return res
  })
}

//Открытие подробностей покупателя
const isCustomerInfoOpen = ref(false)
const isCustomerInfoFetching = ref(false)
const isPartnershipSinging = ref(false)

const toggleCustomerInfo = async () => {
  isCustomerInfoOpen.value = !isCustomerInfoOpen.value
  if (!customerContacts.value.length) {
    isCustomerInfoFetching.value = true
    await getCustomerContacts()
    isCustomerInfoFetching.value = false
  }
}
// Отмена заказа
const isCancelModal = ref(false)
const openCancelModal = () => {
  isCancelModal.value = true
}
const closeCancelModal = () => {
  isCancelModal.value = false
}
const headerBtn = ref<string>('Принять заказ')

// Отправка заказа на согласования
const isApproveModal = ref(false)
const isDeliveryModal = ref(false)

const onWarningDeleteModalClick = () => {
  updateOrder({
    id: orderInfo.value?.id || route.params.id,
    status: NewGeneralOrderStatus.SupplierCancelledOrder,
  }).then(() => {
    refetchOrder()
    closeCancelModal()
  })
}

const approveModalContent = ref({ title: '', description: '' })

const openApproveModal = () => {
  switch (orderInfo.value?.status) {
    case NewGeneralOrderStatus.OrderPicking:
      isDeliveryModal.value = true
      break

    case NewGeneralOrderStatus.ContractUnderSigning:
      isPartnershipSinging.value = true
      break

    case NewGeneralOrderStatus.New:
      if (order_was_edited_by_farmer.value) {
        updateOrder({
          id: orderInfo.value?.id || route.params.id,
          status: NewGeneralOrderStatus.SupplierModifiedOrder,
        }).then(() => {
          approveModalContent.value = {
            title: 'Ожидайте подтверждения покупателя',
            description:
              'Покупатель должен дать согласие на внесённые вами изменения. Мы отправим ему запрос и уведомим вас о его решении, пожалуйста, подождите.',
          }
          isApproveModal.value = true
        })
      } else if (orderInfo.value?.partnership.status === 'signed_customer') {
        updateOrder({
          id: orderInfo.value?.id || route.params.id,
          status: NewGeneralOrderStatus.OrderPicking,
        }).then(() => {
          approveModalContent.value = {
            title: 'Приступайте к сбору заказа',
            description:
              'Заказ согласован обеими сторонами, можете начинать собирать заказ',
          }

          isApproveModal.value = true
        })
      } else if (orderInfo.value?.partnership.status === 'not_signed') {
        updateOrder({
          id: orderInfo.value?.id || route.params.id,
          status: NewGeneralOrderStatus.ContractUnderSigning,
        }).then(() => {
          isPartnershipSinging.value = true
          refetchOrder()
        })
      } else if (orderInfo.value?.partnership.status === 'signed_farmer') {
        updateOrder({
          id: orderInfo.value?.id || route.params.id,
          status: NewGeneralOrderStatus.SupplierContractSigned,
        }).then(() => {
          isPartnershipSinging.value = true
          refetchOrder()
        })
      }
      break
    default:
      break
  }
}

const closeApproveModal = () => {
  isApproveModal.value = false
  isDeliveryModal.value = false
}

const isDownloadShippingDocuments = ref(false)

const closeDocumentsModal = () => {
  modalStore.closeModal(ModalKeys.DownloadDocsModal)
}

// Изменение заказа
const isEditModal = ref(false)
const editableOrderPosition = ref()
const openEditDrawer = (item: IOrderPosition) => {
  editableOrderPosition.value = item
  if (editableOrderPosition.value) {
    isEditModal.value = true
  }
}

const closeEditDrawer = () => {
  isEditModal.value = false
  editableOrderPosition.value = null
}

const customerContacts: Ref<any[]> = ref([])
const getCustomerContacts = async () => {
  const res = await ContactAPI.getItemsContacts([
    ['company_id', 'eq', orderInfo.value!.customer.id],
    'and',
    ['company_type', 'eq', 'customer'],
  ])
  customerContacts.value = res.items
}

// Расчет цены
const totalPrice = computed<number>(() => {
  let sum = 0
  orderPositions.value?.forEach((item: any) => {
    if (!item.is_canceled) sum += +item.price
  })
  return sum
})
const initialTotalPrice = computed<number>(() =>
  orderPositions.value?.reduce(
    (accumulator, item) =>
      accumulator + Number(item.initial_order_position?.price ?? 0),
    0,
  ),
)
// Подписание договора
const isSignContractModal = ref(false)
const toggleSignContractModal = () => {
  isSignContractModal.value = !isSignContractModal.value
}

const isAcceptModal = ref(false)

const sortedOrderPositions = ref<any>([])
const categories = ref<any>([])


const getItemsForTable = () => {
  const existedProducts = orderPositions?.value?.map((i: any) => {
    return { ...i.farmer_product, ...i, product: i.farmer_product }
  })
  existedProducts?.forEach((prod: any) => {
    if (categories.value.includes(prod.product.secondCategory)) {
      sortedOrderPositions.value
        .find((pos) => pos.category === prod.product.secondCategory)
        .items.push(prod)
    } else {
      categories.value.push(prod.product.secondCategory)
      sortedOrderPositions.value.push({
        category: prod.product.secondCategory,
        items: [prod],
      })
    }
  })

  return sortedOrderPositions.value
}
const isUploadOtherDocuments = ref(true)
const systemShippingDocument = ref<Document[]>([])
const userDocuments = ref<Document[]>([])
const farmerRequiredDocuments = [{ name: 'Договор заказа', type: 'AC' }]

const sentDocuments = async (documents: Ref<UploadDocument[]>) => {
  const document = documents.value
  document[0].user_id = orderInfo.value?.customer.id
  document[0].order_id = orderInfo.value?.id || route.params.id
  const { uploadDocumentsFormat } = useDocument()
  const sentDocs: Document[] = uploadDocumentsFormat(document)
  for (let i = 0; i < sentDocs.length; i++) {
    DocumentAPI.uploadDocument(sentDocs[i]).then((response: any) => {
      DocumentAPI.createDocument({
        ...sentDocs[i],
        file_path: response.path,
      }).then(() => {
        if (i === sentDocs.length - 1) {
          toggleSignContractModal()
          isUploadOtherDocuments.value = true
          isDownloadShippingDocuments.value = true
        }
      })
    })
  }
}

const downloadDocuments = async () => {
  isDownloadShippingDocuments.value = false
}

const isDocumentsLoading = ref<boolean>(false)

const getDocuments = async (filters?: any) => {
  isDocumentsLoading.value = true
  userDocuments.value = (
    await OrderDocumentAPI.getItems(filters, 100000000000, ['order'])
  ).items.map((item: IOrderDocument) => ({
    ...item,
    file_name: systemDocsName[item.type],
  }))
  isDocumentsLoading.value = false
}

const downloadOthersFilesAndDocsSent = async () => {
  const { getSystemOthersDocuments } = useDocument()
  const systemShippingDocument = getSystemOthersDocuments(
      userDocuments.value,
      orderInfo.value!.id || Number(route.params.id),
  )
  // скачать все файлы пачкой
  systemShippingDocument.forEach((item) => {
    orderDownloadOnClick(
      item.id || -1,
      {
        file_body: '',
        file_name: '',
      },
      downloadRef.value,
    ).then(() => {
      // documentIsFetching.value = false
    })
  })
}

const updateOtherDocuments = async (ctx?: any) => {
  const { getSystemOthersDocuments } = useDocument()
  await getDocuments([
    ['source', 'eq', 'system'],
    'AND',
    ['order_id', 'eq', orderInfo.value?.id || route.params.id],
  ])
  isUploadOtherDocuments.value = true
  const systemShippingDocumentData = getSystemOthersDocuments(
      userDocuments.value,
      orderInfo.value!.id || Number(route.params.id),
  )
  systemShippingDocument.value = systemShippingDocumentData
  setTimeout(() => {
    openModalDownloadDocs({ systemShippingDocument: systemShippingDocumentData  })
  }, 3000)
  isDocumentsLoading.value = false
  !ctx && emit('update-positions')
}

const isFetching = ref(true)

const connectDocumentSockets = () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  startCentrifuge()
  addChanel(
    Sockets.OrderDocument + '.' + String(getUserId()),
    updateOtherDocuments,
  )
}

const notAcceptedAcDocument = ref(false)

const onDeliverySendClick = async () => {
  await updateOrder({
    id: orderInfo.value?.id || route.params.id,
    status: NewGeneralOrderStatus.OrderInDelivery,
  })
  await refetchOrder()
  openModalGeneratingDocs()
  setTimeout(() => {
    isUploadOtherDocuments.value = true
    modalStore.closeModal(ModalKeys.GeneratingDocsModal)
  }, 4000)
}

const orderPositions = ref<any[]>()

const getOrderPositions: () => Promise<any> = async () => {
  return OrderPositionAPI.getPositions(
    [['order_id', 'eq', Number(route.params.id)]],
    [
      'farmerProduct.productParameters.parameter',
      'history',
      'farmerProduct.specialPrices.customer',
      'farmerProduct.priceOptions',
    ],
    [['id', 'asc']],
  ).then((res) => {
    orderPositions.value = res.items.map((order_position) => ({
      ...order_position,
      history: getSortedArrayByCreatedAt(order_position.history),
    }))
    getItemsForTable()
  })
}

onMounted(async () => {
  await getGeneralOrder()
  await getPartlyOrderDocuments()
  await getOrderPositions().then(() => (isFetching.value = false))
  connectDocumentSockets()
})

watch(
  () => orderInfo.value?.status,
  () => {
    if (orderInfo.value?.status === NewGeneralOrderStatus.OrderPicking) {
      headerBtn.value = 'Перевести заказ на доставку'
    }
  },
)

const updatePosition = async (data: {
  id: number
  quantity: string
  unit_price: string
}) => {
  await updateOrderPosition(data).then(async () => {
    show_notification('info', 'Изменения сохранены')
    await refetchOrder()
  })
}

const lastOrderPositionDelete = computed<boolean>(() => {
  const ordersCount = orderPositions.value?.filter((order: any) => {
    return !order.is_canceled
  })
  return ordersCount && ordersCount.length === 1
})

const deleteOrRestorePosition = async (id: number, canceled: boolean) => {
  if (canceled && lastOrderPositionDelete.value) {
    openCancelModal()
  } else {
    await updateOrderPosition({ id: id, is_canceled: canceled }).then(
      async () => {
        await refetchOrder()
      },
    )
  }
}

const orderDate = computed(() => {
  return dateToLocale(orderInfo.value!.general_order.order_date)
})

// Раскрытие подробностей заказа
const isDescriptionOpened = ref(false)
const toggleOrderDescription = () => {
  isDescriptionOpened.value = !isDescriptionOpened.value
}

const isPartlyDoingOrder = ref(false)
const togglePartlyDoingOrder = () => {
  isPartlyDoingOrder.value = !isPartlyDoingOrder.value
}
const customerPartlyOrderDocuments = ref<Document[]>([])
const farmerPartlyOrderDocuments = ref<Document[]>([])

const disableAttachOrderDocument = computed(() => {
  return farmerPartlyOrderDocuments.value.length >= 10
})

const sendPartlyDoingOrder = () => {
  isFetching.value = true
  const sendDocuments: {
    order_id: number
    file_path: string
  }[] = []
  uploadedOrderDocuments.value.forEach((doc) => {
    if (doc.path) {
      sendDocuments.push({
        order_id: orderInfo.value!.id || route.params.id,
        file_path: doc.path,
      })
    }
  })
  OrderDocumentAPI.createMany(sendDocuments).then(
    async () => await refetchOrder(),
  )
}

const getPartlyOrderDocuments = async () => {
  if (orderInfo.value!.status !== NewGeneralOrderStatus.PartialOrderCompleted)
    return
  await OrderDocumentAPI.getItems([
    ['order_id', 'eq', orderInfo.value!.id || route.params.id], "OR",
    [['source', 'eq', 'customer'], 'OR', ['source', 'eq', 'farmer']],
  ]).then((res) => {
    customerPartlyOrderDocuments.value = res.items.filter(
      (doc: { source: string }) => doc.source === 'customer',
    )
    farmerPartlyOrderDocuments.value = res.items.filter(
      (doc: { source: string }) => doc.source === 'farmer',
    )
  })
}

//Блок с документами частичного заказа
const { bytesToMegabytes, declensionLitres } = useDocument()

const validDocumentTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/jpe',
  'application/pdf',
  'application/rtf',
  'image/gif',
]

const isValidFileTypes = (file: File | UploadDocument) => {
  return validDocumentTypes.includes(file.type) ? '' : 'Неверный формат файла'
}

const isValidFileSize = (size: number) => {
  return size > 10 ? 'Превышен размер файла. Максимум 10Мб' : ''
}

const uploadedOrderDocuments = ref<UploadDocument[]>([])
const isPartlyOrderDocsUploading = ref(false)

const isOrderDocumentsError = computed(() => {
  return uploadedOrderDocuments.value.some((doc) => doc.errors.join('') !== '')
})

const isDisablePartlyDoingOrder = computed(() => {
  return isPartlyOrderDocsUploading.value || !!isOrderDocumentsError.value
})

const uploadOrderDocument = async (file: File, base64: string) => {
  const fileError = [
    isValidFileTypes(file),
    isValidFileSize(+file.size / 1048576),
  ]
  let path = ''
  if (!fileError.join('')) {
    isPartlyOrderDocsUploading.value = true
    await OrderDocumentAPI.upload({
      file_name: file.name,
      file_body: base64.split(',')[1],
      extension: file.type.split('/')[1],
    }).then((res) => {
      path = res.path
      isPartlyOrderDocsUploading.value = false
    })
  }
  uploadedOrderDocuments.value.push({
    name: file.name,
    size: declensionLitres(file.size),
    sizeMegabytes: bytesToMegabytes(file.size),
    base64: base64,
    errors: fileError,
    type: file.type,
    documentType: file.type,
    path,
  })
}

const deleteOrderDocument = (index: number) => {
  uploadedOrderDocuments.value = uploadedOrderDocuments.value.filter(
    (_, idx) => idx !== index,
  )
}

const refetchOrder = async () => {
  isFetching.value = true
  closeEditDrawer()
  isOrderWasChangedOtherUser.value = false
  orderPositions.value = []
  sortedOrderPositions.value = []
  categories.value = []
  uploadedOrderDocuments.value = []
  await getGeneralOrder()
  await getPartlyOrderDocuments()
  await getOrderPositions().then(() => (isFetching.value = false))
  emit('refetchOrders')
}

const isExceeded = computed(() => {
  return orderPositions?.value?.some(
    (item) => Number(item?.quantity) > Number(item?.farmer_product?.quantity),
  )
})

/**
 * Определения стиля и текста у бейджа со статусом заказа
 */
const getButtons = computed(() => {
  const buttonsArray = []
  const buttonConfig = {
    height: '32px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '11.5px 16px',
    borderRadius: '8px',
    justifyContent: 'center',
    fontFamily: 'Inter',
  }

  switch (orderInfo.value?.status) {
    //Новый измененный заказ
    case NewGeneralOrderStatus.New:
      if (order_was_edited_by_farmer.value) {
        buttonsArray.push({
          styleConfig: {
            ...primaryButton,
            ...buttonConfig,
          },
          buttonText: 'Отправить на согласование',
          buttonData: {
            disabled:
              !totalPrice.value ||
              notAcceptedAcDocument.value ||
              isExceeded.value,
          },
          buttonHandler: () => openApproveModal(),
        })
      } else {
        buttonsArray.push({
          styleConfig: {
            ...primaryButton,
            ...buttonConfig,
          },
          buttonText: 'Принять заказ',
          buttonData: {
            disabled:
              !totalPrice.value ||
              notAcceptedAcDocument.value ||
              isExceeded.value,
          },
          buttonHandler: () => openApproveModal(),
        })
      }
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться',
        buttonData: { disabled: false },
        buttonHandler: () => toggleRejectOrder(),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Редактировать условия',
        buttonData: { disabled: false },
        buttonHandler: () => (is_edit_order.value = true),
      })
      break
    // Договор на подписании
    case NewGeneralOrderStatus.ContractUnderSigning:
      buttonsArray.push({
        styleConfig: {
          ...primaryButton,
          ...buttonConfig,
        },
        buttonText: 'Продолжить подписание',
        buttonData: {
          disabled: !totalPrice.value || notAcceptedAcDocument.value,
        },
        buttonHandler: () => openApproveModal(),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться',
        buttonData: { disabled: false },
        buttonHandler: () => toggleRejectOrder(),
      })
      break
    case NewGeneralOrderStatus.OrderPicking:
      buttonsArray.push({
        styleConfig: {
          ...primaryButton,
          ...buttonConfig,
        },
        buttonText: 'Перевести заказ на доставку',
        buttonData: {
          disabled: !totalPrice.value || notAcceptedAcDocument.value,
        },
        buttonHandler: () => (isDeliveryModal.value = true),
      })
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться',
        buttonData: { disabled: false },
        buttonHandler: () => toggleRejectOrder(),
      })
      break
    case NewGeneralOrderStatus.SupplierModifiedOrder:
    case NewGeneralOrderStatus.SupplierContractSigned:
      buttonsArray.push({
        styleConfig: {
          ...outlineBlackButton,
          ...buttonConfig,
        },
        buttonText: 'Отказаться',
        buttonData: { disabled: false },
        buttonHandler: () => toggleRejectOrder(),
      })
      break
  }
  return buttonsArray
})

const isRejectOrder = ref(false)
const toggleRejectOrder = () => {
  // isRejectOrder.value = !isRejectOrder.value
  ratingModalOpen.value = true
}
const sendRejectOrder = async (data: IModalOrder) => {
  if (!data.completed) {
    await getGeneralOrder()
    if (data.status !== orderInfo.value.status) {
      isShowModalUpdatePage.value = true
    } else {
      await updateOrder({
        id: orderInfo.value?.id || route.params.id,
        status: NewGeneralOrderStatus.SupplierCancelledOrder,
      }).then(async () => {
        await successReject(data, true)
      })
    }
  } else {
    await successReject(data, false)
  }
}

const successReject = async (
  data: IModalOrder,
  showOrderStatusNotification: boolean,
) => {
  await rateOrder(orderInfo.value, data)
  await refetchOrder()
  if (showOrderStatusNotification) {
    notification.info({
      message: 'Заказ успешно отменён',
      description: 'Мы уведомим покупателя об отмене',
      top: '80px',
      icon: h(InfoCircleFilled, {
        style: { color: 'rgba(22, 119, 255, 1)' },
      }),
    })
  } else {
    notification.info({
      message: 'Ваша оценка сохранена',
      top: '80px',
      icon: h(InfoCircleFilled, {
        style: { color: 'rgba(22, 119, 255, 1)' },
      }),
    })
  }
}

const rateOrder = async (order: any, data: IModalOrder) => {
  await getGeneralOrder()
  if (hasFarmerMark.value) {
    isShowModalUpdatePage.value = true
  } else {
    await orderMarkAPI.createMark({
      recipient_id: order.customer_id,
      initiator_id: order.farmer_id,
      initiator_type: 'farmer',
      recipient_type: 'customer',
      order_id: order.id,
      mark: data.mark,
    })
  }
}

const isOpenDocumentDrawer = ref(false)

const closeRatingModal = () => {
  ratingModalOpen.value = false
}

const hasFarmerMark = computed(() => {
  let farmerMark
  orderInfo.value.order_marks.forEach((mark) => {
    if (mark.initiator_type == 'farmer') {
      farmerMark = mark
    }
  })
  if (farmerMark) return farmerMark
  else return false
})

const isCompleted = computed(() => {
  const status = orderInfo.value.status
  return status.includes('отменен') || status.includes('выполнен')
})
const isOrderWasChangedOtherUser = ref(false)

const updateOrder = async (data: any) => {
  if (await check_is_order_data_changed_other_user()) {
    isOrderWasChangedOtherUser.value = true
    return Promise.reject()
  }
  await OrderAPI.updateOrder(data)
}

const updateOrderPosition = async (data: any) => {
  if (await check_is_order_data_changed_other_user()) {
    isOrderWasChangedOtherUser.value = true
    return Promise.reject()
  }
  await OrderPositionAPI.updatePosition(data)
}

const check_is_order_was_changed = async () => {
  return await OrderAPI.getOneOrder([], [], Number(route.params.id)).then(
    (res) => is_orders_has_difference(res, orderInfo.value),
  )
}

const compareTwoArrays = (
  array1: IOrderPosition[],
  array2: IOrderPosition[],
) => {
  const changingFields = [
    'is_canceled',
    'is_changed',
    'is_special_price_used',
    'price',
    'quantity',
    'unit_price',
    'vat_percent',
  ]
  function sortByOrderId(a: IOrderPosition, b: IOrderPosition) {
    return a.order_id - b.order_id
  }
  const sortedArray1 = [...array1].sort(sortByOrderId)
  const sortedArray2 = [...array2].sort(sortByOrderId)

  for (let i = 0; i < array1.length; i++) {
    for (let field of changingFields) {
      if (sortedArray1[i][field] !== sortedArray2[i][field]) {
        return true
      }
    }
  }
  return false
}

const check_is_order_positions_was_changed = async () => {
  return await OrderPositionAPI.getPositions(
    [['order_id', 'eq', Number(route.params.id)]],
    [],
    [['id', 'asc']],
  ).then((res) => {
    return compareTwoArrays(
      orderPositions.value as IOrderPosition[],
      res.items as IOrderPosition[],
    )
  })
}

const check_is_general_order_was_changed = async () => {
  return await GeneralOrderAPI.getOneGeneralOrder(
    [],
    [],
    orderInfo.value?.general_order_id ?? 0,
  ).then((res) => {
    return res.delivery_date !== orderInfo.value?.general_order.delivery_date
  })
}

const check_is_order_data_changed_other_user = async () => {
  return (
    (await check_is_order_was_changed()) ||
    (await check_is_order_positions_was_changed()) ||
    (await check_is_general_order_was_changed())
  )
}

const isOrderWasEdited = computed(() => {
  return orderPositions.value.some(
    (orderPosition) =>
      orderPosition.initial_order_position || orderPosition.is_canceled,
  )
})

const orderInitiator = computed(() => {
  const id = orderInfo.value?.general_order.initiator_id ?? ''
  return orderInfo.value?.customer.users.find((user) => user.id == id)
})

const previousOrder = computed(() => {
  if (!orderInfo.value?.history?.length) return null
  return orderInfo.value.history[0].old_entity
})

const isShowServices = computed(() => {
  if (!previousOrder.value) {
    return (
      orderInfo.value?.palletizing_type !== null || orderInfo.value?.need_unload
    )
  } else {
    return (
      orderInfo.value?.palletizing_type !== null ||
      previousOrder.value?.palletizing_type !== null ||
      orderInfo.value?.need_unload ||
      previousOrder.value?.need_unload
    )
  }
})

const is_edit_order = ref(false)

const update_order = async (data: any) => {
  const { delivery_date, ...rest } = data
  await updateOrder(rest)
    .then(async () => {
      await GeneralOrderAPI.update({
        id: orderInfo.value?.general_order_id,
        delivery_date: delivery_date,
      }).then(() => {
        show_notification('info', 'Изменения сохранены')
        is_edit_order.value = false
        refetchOrder()
      })
    })
    .catch(() => {
      is_edit_order.value = false
    })
}

const order_was_edited_by_farmer = computed(() => {
  const order_positions_was_edited = orderPositions.value?.some(
    (position) =>
      position.history[0]?.initiator_type == 'farmer' && position.is_changed,
  )

  const order_was_edited =
    orderInfo.value?.history[0]?.initiator_type == 'farmer' &&
    is_orders_has_difference(
      orderInfo.value,
      orderInfo.value?.history[0]?.old_entity,
    )

  const general_order_was_edited =
    orderInfo.value?.general_order?.history![0]?.initiator_type == 'farmer' &&
    orderInfo.value?.general_order?.delivery_date !==
      orderInfo.value?.general_order?.history![0]?.old_entity?.delivery_date

  return (
    order_positions_was_edited || order_was_edited || general_order_was_edited
  )
})

const order_price = computed(() => {
  const last_change = orderInfo.value?.history[0]

  if (
    orderInfo.value?.status == NewGeneralOrderStatus.SupplierModifiedOrder &&
    last_change?.initiator_type === 'customer'
  ) {
    return {
      price: last_change.old_entity.price,
      previous_price: compare_price_if_equal(
        orderInfo.value?.history[1]?.old_entity?.price,
        last_change.old_entity.price,
      ),
    }
  } else {
    return {
      price: orderInfo.value?.price,
      previous_price: compare_price_if_equal(
        last_change?.old_entity?.price,
        orderInfo.value?.price,
      ),
    }
  }
})

const openModalGeneratingDocs = () => {
  modalStore.openModal(ModalKeys.GeneratingDocsModal, {
    isClosable: false,
    close: confirm,
  })
}

const openModalDownloadDocs = async ({ systemShippingDocument }: any) => {
    modalStore.openModal(ModalKeys.DownloadDocsModal, {
      isClosable: false,
      isDocumentsLoading: isDocumentsLoading.value,
      downloadOthersFilesAndDocsSent,
      closeDocumentsModal,
      systemShippingDocument,
    })
}


const orderWasEditedByCustomer = computed(() => {
  const order_was_edited =
    orderInfo.value.history[0]?.initiator_type === 'customer' &&
    is_orders_has_difference(
      orderInfo.value,
      orderInfo.value?.history[0]?.old_entity,
    )
  console.log(
    order_was_edited &&
      orderInfo.value.status === NewGeneralOrderStatus.SupplierModifiedOrder,
  )

  return (
    order_was_edited &&
    orderInfo.value.status === NewGeneralOrderStatus.SupplierModifiedOrder
  )
})

const deliveryMethod = computed(() => {
  return !orderWasEditedByCustomer.value
    ? orderInfo?.value.delivery_method.split(' ')[0]
    : orderInfo?.value.history[0]?.old_entity.delivery_method.split(' ')[0]
})

const conditionForTextPopover = computed(() => {
  return (
    orderInfo?.value?.general_order?.history![0]?.old_entity.delivery_date &&
    orderInfo?.value?.general_order?.history![0]?.old_entity.delivery_date !==
      orderInfo?.value?.general_order.delivery_date &&
    !orderWasEditedByCustomer.value
  )
})

const connectToSocket = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  if (user) {
    const userId = user.id
    startCentrifuge()
    console.log('connected to socket', `core@OrderDocument.${userId}`)
    addChanel(`core@OrderDocument.${userId}`, socketAction)
  }
}

const socketAction = async (socketMessage: any) => {
  if (socketMessage.name ==="create_document_centrifugo_event") {
    const { getSystemOthersDocuments } = useDocument()
    await getGeneralOrder()
    await getDocuments()

    const systemShippingDocument = getSystemOthersDocuments(
        userDocuments.value,
        orderInfo.value!.id || Number(route.params.id),
    )
    setTimeout(async () => {
      isUploadOtherDocuments.value = true
      await openModalDownloadDocs({ systemShippingDocument })
    }, 200)
  }
}

onMounted(async () => {
  await connectToSocket()
})

// TODO: Начат рефакторинг модалок
watch(() => !isUploadOtherDocuments.value, async () => {
  openModalGeneratingDocs()
})
</script>

<template>
  <div v-if="!isFetching && orderInfo" class="wrapper">
    <ModalUpdatePage
      description="Заказ был изменён другим пользователем. Обновите страницу для подгрузки актуальных данных."
      :open="isShowModalUpdatePage"
    />

    <RatingSetModal
      :status="orderInfo.status"
      :order-completed="isCompleted"
      :orderId="orderInfo.general_order_id"
      :open="ratingModalOpen"
      @close="closeRatingModal"
      @sendData="sendRejectOrder"
    />
    <CreateHeader
        :farmer_id="orderInfo.farmer_id"
      :title="`Заказ от ${orderDate}, ${orderInfo.customer.legal_form} ${orderInfo.customer.name}`"
      class="header"
      @button-click="() => emit('back')"
    />

    <div class="order-header-block">
      <div class="order-header-block-header">
        <div class="info-row">
          <div class="info-row-item">
            <CustomTag
              :shadow="false"
              :text="orderInfo.status"
              :type="getStatusType(orderInfo.status)"
              weight="thin"
            />
          </div>
          <div class="info-row-item">
            <FarmerDeliveryIcon
              v-if="
                orderInfo?.delivery_method === 'Доставка фермером' &&
                !orderWasEditedByCustomer
              "
              :height="22"
              :width="22"
              fill="#A0AEC0"
            />
            <PickupIcon
              v-if="
                orderInfo?.delivery_method === 'Самовывоз' &&
                !orderWasEditedByCustomer
              "
              :height="22"
              :width="22"
              fill="#00000073"
            />
            <PickupIcon
              v-if="
                orderInfo?.history[0]?.old_entity.delivery_method ===
                  'Самовывоз' && orderWasEditedByCustomer
              "
              :height="22"
              :width="22"
              :fill="'#A0AEC0'"
            />
            <FarmerDeliveryIcon
              v-if="
                orderInfo?.history[0]?.old_entity.delivery_method ===
                  'Доставка фермером' && orderWasEditedByCustomer
              "
              :height="22"
              :width="22"
              :fill="'#00000073'"
            />
            {{ deliveryMethod }}
            <template
              v-if="
                orderInfo?.history![0]?.old_entity.delivery_method !==
                  orderInfo?.delivery_method && !orderWasEditedByCustomer
              "
            >
              <span class="price-crossed">
                {{
                  orderInfo?.history![0]?.old_entity.delivery_method.split(
                    ' ',
                  )[0]
                }}
              </span>
            </template>
          </div>
          <div class="info-row-item">
            <CalendarIcon fill="#00000073" />
            <TextWithOldPopover
              v-if="conditionForTextPopover"
              :text="
                'к ' +
                dayjs(orderInfo?.general_order.delivery_date).format(
                  'DD.MM.YYYY',
                )
              "
              :old_text="
                'к ' +
                dayjs(
                  orderInfo?.general_order?.history![0]?.old_entity
                    .delivery_date,
                ).format('DD.MM.YYYY')
              "
            />
            <TextWithOldPopover
              v-else-if="orderWasEditedByCustomer"
              :text="
                'к ' +
                dayjs(
                  orderInfo?.general_order?.history![0]?.old_entity
                    .delivery_date,
                ).format('DD.MM.YYYY')
              "
              :old_text="
                'к ' +
                dayjs(
                  orderInfo?.general_order?.history![1]?.old_entity
                    .delivery_date,
                ).format('DD.MM.YYYY')
              "
            />

            <template v-else>
              к
              {{
                dayjs(orderInfo?.general_order.delivery_date).format(
                  'DD.MM.YYYY',
                )
              }}
            </template>
          </div>
          <div class="info-row-item">
            <CartIcon :fill="'#00000073'" />
            <TextWithOldPopover
              v-if="order_price.previous_price"
              :text="formatPrice(order_price.price)"
              :old_text="formatPrice(order_price.previous_price)"
            />
            <template v-else>
              {{ formatPrice(order_price.price) }}
            </template>
          </div>
          <div class="info-row-item" v-if="isCompleted && hasFarmerMark">
            <YourRate :mark="hasFarmerMark" />
          </div>
        </div>
        <div class="action-row">
          <div class="action-row-buttons" v-if="isCompleted && !hasFarmerMark">
            <!-- @click="reject {id: props.order.id, status: NewGeneralOrderStatus.SupplierCancelledOrder}"  -->
            <Button
              :icon="h(StarOutlined)"
              class="rate-order"
              @click="toggleRejectOrder"
            >
              Оценить заказ
            </Button>
          </div>
          <div class="action-row-buttons">
            <div class="buttons">
              <EButton
                v-for="button in getButtons"
                :key="button.buttonText"
                :data="button.buttonData"
                :styleConfig="{ ...button.styleConfig }"
                @click="button.buttonHandler"
              >
                <Popover
                  title="Нехватает товара"
                  v-if="
                    (button.buttonText === 'Отправить на согласование' ||
                      button.buttonText === 'Принять заказ') &&
                    isExceeded
                  "
                  placement="bottom"
                  :overlayStyle="{ maxWidth: '193px' }"
                >
                  <template #content>
                    Отредактируйте заказ или актуализируйте остаток товара
                  </template>
                  <div>{{ button.buttonText }}</div>
                </Popover>
                <div v-else>
                  {{ button.buttonText }}
                </div>
              </EButton>
            </div>
          </div>
          <div class="action-row-expand">
            <Button class="description-button" @click="toggleOrderDescription">
              <UpOutlined
                :class="isDescriptionOpened ? '' : 'revert'"
                class="order-description-icon"
                fill="#0066FF"
              />
            </Button>
          </div>
        </div>
      </div>
      <div v-show="isDescriptionOpened" class="order-header-block-body">
        <div class="body-columns">
          <div class="body-columns-item">
            <CustomerItem
              :customer="orderInfo.customer"
              :isPartnershipSigned="
                orderInfo.partnership.status === 'signed_customer'
              "
            />
          </div>
          <div class="body-columns-item">
            <OrderCreatorItem :initiator="orderInitiator" />
          </div>
          <div class="body-columns-item">
            <PaymentItem
              :order="orderInfo as IOrder"
              :history="previousOrder"
            />
          </div>
          <div class="body-columns-item">
            <Button
              type="link"
              @click="isOpenDocumentDrawer = true"
              class="document-btn"
            >
              <template #icon>
                <FileTextOutlined />
              </template>
              Документы заказа
            </Button>
          </div>
          <div class="body-columns-item" v-if="isShowServices">
            <ServicesItem
              :order="orderInfo as IOrder"
              :history="previousOrder"
            />
          </div>
          <div
            class="body-columns-item two-column"
            v-if="
              (orderInfo.delivery_method === 'Доставка фермером' &&
                orderInfo.general_order.delivery_address) ||
              (orderInfo.delivery_method === 'Самовывоз' &&
                orderInfo.shipping_address)
            "
          >
            <AddressItem
              :order="orderInfo as IOrder"
              :history="previousOrder"
              :old_address="
                orderInfo.general_order.history![0]?.old_entity.delivery_address
              "
            />
          </div>
        </div>
        <div
          class="body-row-item"
          v-if="orderInfo.general_order.comment && !orderWasEditedByCustomer"
        >
          <CommentItem
            :comment="orderInfo.general_order.comment"
            :history="orderInfo.general_order?.history![0]?.old_entity"
            :isFromCustomer="true"
          />
        </div>
        <div
          class="body-row-item"
          v-if="
            orderInfo.general_order?.history![0]?.old_entity.comment &&
            orderWasEditedByCustomer
          "
        >
          <CommentItem
            :comment="orderInfo.general_order?.history![0]?.old_entity.comment"
            :history="orderInfo.general_order?.history![1]?.old_entity"
            :isFromCustomer="true"
          />
        </div>
        <div class="body-row-item" v-if="orderInfo.comment">
          <CommentItem
            :comment="orderInfo.comment"
            :history="orderInfo?.history![0]?.old_entity"
            :isFromFarmer="true"
          />
        </div>
        <div
          v-if="
            orderInfo.status === NewGeneralOrderStatus.PartialOrderCompleted
          "
          class="partly-doing-order"
        >
          <div class="partly-doing-order-farmer">
            <div>
              <h6>Покупатель отметил, что заказ был выполнен частично</h6>
              <p>
                Вы можете прикрепить свои скан-копии исправленных отгрузочных
                документов, в формате: .pdf, .rtf, .gif, .jpg, .jpeg, .jpe,
                .png, до 10 Мб
              </p>
            </div>
            <EButton
              :data="{
                disabled: disableAttachOrderDocument,
              }"
              :styleConfig="{
                ...primaryButton,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '17px',
                padding: '11.5px 16px',
                borderRadius: '8px',
                justifyContent: 'center',
                fontFamily: 'Inter',
                height: '40px',
              }"
              @click="togglePartlyDoingOrder"
            >
              {{
                disableAttachOrderDocument
                  ? 'Загружено максимум документов'
                  : 'Прикрепить документы'
              }}
            </EButton>
          </div>
          <div v-if="farmerPartlyOrderDocuments.length" class="order-docs-row">
            <DocumentUploadListItem
              v-for="(document, index) in farmerPartlyOrderDocuments"
              :id="document.id"
              :key="document.id"
              :errors="['']"
              :icon-type="'filled'"
              :index="index"
              :is-download="true"
              :name="formatDocumentName(document.file_name)"
              document-model="order"
            />
          </div>
          <div
            v-if="
              orderInfo.execution_comment || customerPartlyOrderDocuments.length
            "
            class="partly-doing-order-customer"
          >
            <h6>От покупателя</h6>
            <p v-if="orderInfo.execution_comment">
              {{ orderInfo.execution_comment }}
            </p>
          </div>
          <div
            v-if="customerPartlyOrderDocuments.length"
            class="order-docs-row"
          >
            <DocumentUploadListItem
              v-for="(document, index) in customerPartlyOrderDocuments"
              :id="document.id"
              :key="document.id"
              :errors="['']"
              :icon-type="'filled'"
              :index="index"
              :is-download="true"
              :name="formatDocumentName(document.file_name)"
              document-model="order"
            />
          </div>
        </div>
      </div>
    </div>
    <!--   tables -->
    <div class="tables-container">
      <div
        v-for="position in sortedOrderPositions"
        :key="position"
        class="table-category"
      >
        <p class="table-title">{{ position.category }}</p>
        <CheckboxTable
          :customerId="orderInfo.customer_id"
          :category="position.category"
          :items="position.items"
          :order-status="generalStatusData.text"
          :status="orderInfo.status"
          @edit-row="openEditDrawer"
          @delete-restore="deleteOrRestorePosition"
          @openProductCard="openProductDrawer"
          :isCanEditOrder="
            orderInfo.status === NewGeneralOrderStatus.New ||
            orderInfo.status === NewGeneralOrderStatus.OrderPicking
          "
        />
      </div>
    </div>
  </div>
  <div v-else class="farmer-order-loader">
    <FarmLoader :fullscreen="true" />
  </div>
  <!--  Отмена заказа -->
  <Teleport to="body">
    <DefaultModalWindow
      :show="isCancelModal"
      :show-close-icon="false"
      class="cancel-modal"
      :onlyContent="true"
    >
      <template #content>
        <DeletionWarningModal
          @close="closeCancelModal"
          @continue="onWarningDeleteModalClick"
        />
      </template>
    </DefaultModalWindow>
  </Teleport>

  <!-- Отказаться от заказа  -->

  <CustomModal
    :button-direction="'row'"
    :close-icon="false"
    :only-main-action="false"
    :show="isRejectOrder"
    :type="'danger'"
    :width="'440px'"
    @toggle-modal="toggleRejectOrder"
    @main-action="sendRejectOrder"
    @additional-action="toggleRejectOrder"
    :main-text="'Отказаться'"
    :additional-text="'Закрыть'"
  >
    <template #header>Отказаться от заказа?</template>
    <template #description>Мы уведомим покупателя об отмене заказа</template>
  </CustomModal>

  <!--  Отправка на сбор заказа -->
  <CustomModal
    :button-direction="'column'"
    :close-icon="false"
    :only-main-action="true"
    :show="isApproveModal"
    :type="'primary'"
    :width="'440px'"
    @main-action="refetchOrder"
    @toggle-modal="closeApproveModal"
    :main-text="'Хорошо'"
  >
    <template #header>{{ approveModalContent.title }}</template>
    <template #description>{{ approveModalContent.description }} </template>
  </CustomModal>

  <!--  Отправка на доставку -->
  <CustomModal
    :button-direction="'row'"
    :close-icon="false"
    :only-main-action="false"
    :show="isDeliveryModal"
    :type="'primary'"
    :width="'480px'"
    @additionalAction="closeApproveModal"
    @toggleModal="closeApproveModal"
    @main-action="onDeliverySendClick"
    :additional-text="'Закрыть'"
    :main-text="'Подтвердить'"
  >
    <template #header
      >Пожалуйста, подтвердите, что состав заказа и цены окончательные
    </template>
    <template #description
      >На основе этой информации будут сформированы отгрузочные документы
    </template>
  </CustomModal>

  <!--  Подписание договора -->
  <Teleport to="body">
    <ModalCloseWindow
      :show="isSignContractModal"
      :show-close-icon="false"
      class="sign-modal"
      @close="toggleSignContractModal"
    >
      <template #body>
        <DocumentOrder
          :description="'Чтобы подтвердить заказ, вам нужно подписать и загрузить договор с покупателем. Если договор не скачался автоматически, то скачайте его самостоятельно.'"
          :document-filters="[
            ['is_system', 'eq', true],
            'AND',
            ['document_type', 'eq', DocumentTypes.AC],
            [
              ['document_users.company_id', 'eq', item.customer_id],
              'AND',
              ['document_users.company_type', 'eq', 'customer'],
            ],
            [
              ['document_users.company_id', 'eq', item.farmer_id],
              'AND',
              ['document_users.company_type', 'eq', 'farmer'],
            ],
          ]"
          :download-text="'Скачать договор'"
          :hide-required-documents="true"
          :order-customer-id="item.customer_id"
          :order-document="systemShippingDocument?.file_body"
          :order-farmer-id="item.farmer_id"
          :required-documents="farmerRequiredDocuments"
          :title="'Подпишите и загрузите договор'"
          @sent-documents="sentDocuments"
        />
      </template>
    </ModalCloseWindow>
  </Teleport>
  <!--  todo ниже хз что за модалка-->
  <Teleport to="body">
    <ModalCloseWindow
      :show="isDownloadShippingDocuments"
      :show-close-icon="false"
      class="cancel-modal cancel-modal--custom"
    >
      <template #body>
        <div class="page-card">
          <div class="page-card__header">Скачайте отгрузочные документы</div>
          <p class="page-card__info">
            Договор успешно загружен, дождитесь, когда покупатель подпишет свой
            договор.
          </p>
          <p class="page-card__info">
            Пока вы можете скачать и распечатать отгрузочные документы, их нужно
            будет передать покупателю вместе с товарами.
          </p>

          <div class="download-link">
            <EButton
              :data="{ disabled: !isUploadOtherDocuments }"
              :style-config="{
                ...primaryButton,
                ...fontSize14,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }"
              @click="downloadDocuments"
            >
              Скачать отгрузочные документы
            </EButton>
          </div>
        </div>
      </template>
    </ModalCloseWindow>
  </Teleport>
  <Teleport to="body">
    <ModalWindow
      :show="isAcceptModal"
      :show-close-icon="false"
      class="approve-modal"
    >
      <template #header> Заказ принят</template>
      <template #body>
        Вы уже заключили договор с покупателем, поэтому сейчас ничего
        подписывать не нужно
      </template>
      <template #footer>
        <div class="download-link">
          <EButton
            :style-config="{
              ...primaryButton,
              ...fontSize14,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }"
            @click="downloadOthersFilesAndDocsSent"
          >
            Скачать отгрузочные документы
          </EButton>
        </div>
      </template>
    </ModalWindow>
  </Teleport>

  <!-- Заказ выполнен частично  -->
  <CustomModal
    :button-direction="'column'"
    :close-icon="true"
    :main-btn-data="{
      disabled: isDisablePartlyDoingOrder,
    }"
    :main-text="'Загрузить документы '"
    :only-main-action="true"
    :show="isPartlyDoingOrder"
    :show-buttons="!!uploadedOrderDocuments.length"
    :type="'primary'"
    :width="'480px'"
    @toggle-modal="togglePartlyDoingOrder"
    @main-action="sendPartlyDoingOrder"
  >
    <template #header>Загрузите документы</template>
    <template #body>
      <div class="partly-modal-body">
        <div class="partly-modal-body-item">
          <template v-if="!isPartlyOrderDocsUploading">
            <p class="partly-modal-body-title">Исправленные документы заказа</p>
            <DocumentUploadListItem
              v-for="(document, index) in uploadedOrderDocuments"
              :key="index"
              :errors="document.errors"
              :icon-type="'filled'"
              :index="+index"
              :name="document.name"
              :size="document.size"
              document-model="order"
              @delete-document="deleteOrderDocument"
            />
            <FileUploader
              v-if="
                uploadedOrderDocuments.length +
                  farmerPartlyOrderDocuments.length <
                10
              "
              :document-info="'Формат файлов: .pdf, .rtf, .gif, .jpg, .jpeg, .jpe, .png, до 10 мб'"
              :type="uploadedOrderDocuments.length ? 'custom' : ''"
              @load-file="uploadOrderDocument"
            >
              <a class="add-document-title" href="#">
                <span class="plus">+</span>
                Добавить документы
              </a>
            </FileUploader>
          </template>
          <FarmLoader v-else />
        </div>
      </div>
    </template>
  </CustomModal>

  <!--  Изменение заказа -->
  <EditOrderPositionDrawer
    :customerId="orderInfo?.customer_id"
    :is-farmer="getCoreCookie()?.company_type === 'farmer'"
    :is-open="isEditModal"
    @close="closeEditDrawer"
    @update="updatePosition"
    @openProductDrawer="openProductDrawer"
    :orderPosition="editableOrderPosition"
  />

  <!--  Просмотр товара -->
  <DrawerForm
    :hide-control-elements="true"
    :is-farmer="getCoreCookie()?.company_type === 'farmer'"
    :product="selectedViewProduct"
    @closeDrawer="closeProductDrawer"
    :open="isOpenProductDrawer"
    :orderQuantity="selectedProductQuantity"
  />

  <FarmerPartnershipModal
    :partnershipId="orderInfo?.partnership.id ?? 0"
    :orderId="orderInfo?.id ?? Number(route.params.id)"
    v-model:open="isPartnershipSinging"
    @onSuccess="refetchOrder"
    :isFarmerSigned="
      orderInfo ? orderInfo.partnership.status === 'signed_farmer' : false
    "
  />

  <OrderDocumentDrawer
    v-model:open="isOpenDocumentDrawer"
    :orderId="orderInfo?.id || route.params.id"
    :partnershipId="orderInfo?.partnership.id"
    :farmerId="getUserIdTemporary()"
    :customerId="orderInfo?.customer_id"
  />

  <Modal
    width="440px"
    centered
    v-model:open="isOrderWasChangedOtherUser"
    :bodyStyle="{ marginBottom: '24px' }"
    title="Заказ был изменен другим пользователем"
    closable
    @cancel="refetchOrder"
  >
    Пожалуйста, обновите страницу
    <template #footer>
      <Button @click="refetchOrder" type="primary"> Обновить </Button>
    </template>
  </Modal>

  <FarmerEditOrderDrawer
    v-model:open="is_edit_order"
    :order="orderInfo"
    @save_changes="update_order($event)"
    :order_positions="orderPositions"
  />
  <a ref="downloadRef" class="hidden-href" />
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;

  :deep(.header) {
    margin-bottom: 16px;
  }

  :deep(.header .wrapper .title) {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  :deep(.header .wrapper .title-wrapper) {
    align-items: center;
  }
}

.loader {
  margin: 0 auto;
}

.loader-text {
  margin: 0 auto;
}

.rate-order {
  width: 169px;
  height: 40px;
}

.order-header-block {
  background: #ffffff;
  padding: 16px;
  border: 1px solid $gray-300;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: $shadow-lg;
  display: flex;
  flex-direction: column;

  &-header {
    height: 32px;
    display: flex;
    justify-content: space-between;

    .info-row {
      display: flex;
      column-gap: 32px;
      align-items: center;
      flex-direction: row;

      &-item {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000e0;
        display: flex;
        column-gap: 8px;
        align-items: center;
        position: relative;
        svg {
          transform: scale(0.9);
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $gray-600;
        }
      }
    }

    .action-row {
      display: flex;
      column-gap: 12px;

      .action-row-buttons {
        .buttons {
          display: flex;
          gap: 12px;
        }
      }

      .description-button {
        padding: 4px 9px 4px 9px;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;

        .order-description-icon {
          transition: all 0.3s ease;

          &.revert {
            transform: rotate(180deg);
          }
        }
      }

      &-expand {
        display: flex;
        align-items: center;
      }
    }
  }

  &-body {
    margin-top: 16px;
    border-top: 1px solid $gray-300;
    padding-top: 16px;
    display: flex;
    flex-direction: column;

    .body-columns {
      display: grid;
      column-gap: 40px;
      row-gap: 32px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      line-height: 22px;

      &-item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .document-btn {
          width: fit-content;
          margin-left: auto;
        }
      }

      .two-column {
        grid-column: span 2;
      }
    }

    .body-row {
      display: grid;
      grid-template-columns: 1fr;

      &-item {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 8px;
      }
    }

    .partly-doing-order {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 8px;
      background: #fffaf6;
      margin-top: 16px;

      h6 {
        @include h6;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        line-height: 150%;
        color: $gray-800;
        margin-bottom: 20px;
      }

      .partly-doing-order-farmer {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 12px;
      }

      .partly-doing-order-customer {
        padding-top: 20px;
        border-top: 1px solid $gray-400;
        margin-top: 20px;
      }

      .order-docs-row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 8px;

        :deep(.uploader-document__name) {
          overflow: hidden;
          white-space: nowrap;
          width: 92px;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.card {
  background: white;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid $gray-300;

  box-shadow: $shadow-lg;
  margin-bottom: 40px;

  &__header {
    display: grid;
    grid-template-columns: auto auto auto 1fr 205px;
    column-gap: 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $gray-800;
    justify-items: end;
    align-items: center;

    .order-description__icon {
      filter: invert(24%) sepia(93%) saturate(4130%) hue-rotate(213deg)
        brightness(105%) contrast(104%);
    }

    .order-description__icon-revert {
      transform: rotate(180deg);
    }
  }

  &__description {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $gray-300;
  }

  &__footer {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    line-height: 150%;
    margin-top: 16px;

    .comment-title {
      font-weight: 400;
      color: $gray-600;
    }

    .comment {
      font-weight: 500;
      color: $gray-800;
      word-break: break-word;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: auto auto 1fr;
    width: 100%;
    column-gap: 80px;
    justify-items: end;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;

    &--header {
      font-weight: 400;
      color: $gray-600;
      display: flex;
      gap: 7px;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  &__row {
    display: flex;
    gap: 12px;
    font-size: 16px;
    line-height: 19px;
    align-items: center;

    &--header {
      font-weight: 400;
      color: $gray-600;
    }

    &--text {
      font-weight: 500;
      color: $gray-800;

      word-break: break-word;
      text-align: left;
      max-width: 300px;
    }
  }
}

.tables-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  max-height: 650px;
  margin-bottom: 20px;
  overflow: auto;
}

.table-category {
}

.table-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $gray-800;
  margin-bottom: 12px;
}

.cancel-modal,
.approve-modal {
  :deep(.modal-container) {
    .modal-header > h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
      margin: 0 auto;
    }

    .modal-body {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray-600;
      text-align: start;
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-top: 24px;
    }
  }
}

.edit-modal {
  :deep(.modal-container) {
    padding: 40px;
    width: 608px;

    .modal-body {
      margin-top: 25px;
    }
  }
}

.helper {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $gray-600;
  display: flex;
  width: 222px;
  text-align: right;
  justify-content: flex-end;

  &--row-button {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 4px;
  }
}

.download-link {
  text-decoration: none;
  display: flex;
  gap: 12px;
}

.page-card,
.other-document-modal {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 476px;
  height: fit-content;
  min-height: fit-content;
  background: $base-white;
  box-shadow: $shadow-lg;
  border-radius: 24px;
  padding: 40px;
  gap: 1rem;
  text-align: left;

  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $gray-800;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }
}

.other-document-modal {
  padding: 8px;
  background: none;
  box-shadow: none;
  gap: 12px;

  .other-document-modal-content {
    margin-bottom: 8px;
  }

  .document-modal-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
    margin-bottom: 8px;
  }

  .document-modal-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $gray-700;
  }

  .download-link {
    margin-top: 20px;
  }
}

.preloader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.farmer-info {
  &__form {
    min-height: 320px;
    overflow-y: scroll;
    width: 100%;
    max-height: 600px;

    &_header {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 4px;
      color: $gray-800;
    }

    &_data {
      &_info {
        width: 95%;
        display: flex;
        flex-direction: column;

        &_block {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;

          &_title {
            color: #718096;
            font-size: 14px;
            line-height: 17px;
          }

          &_descr {
            font-weight: 500;
            width: 260px;
            font-size: 14px;
            line-height: 130%;
          }
        }
      }
    }

    &_contact {
      width: 95%;
      margin-top: 40px;

      .loader {
        display: flex;
        justify-content: center;
        transform: scale(0.5);
        margin-top: 25%;
      }

      &_block {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-top: 17px;
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 21px;

        &_name {
          display: flex;
          gap: 11px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;

          img {
            filter: opacity(0.35);
            width: 15px;
          }
        }

        &_phone {
          display: flex;
          gap: 11px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }

      &_block:first-child {
        margin-top: 21.5px;
      }

      &_block:last-child {
        border-bottom: none;
      }
    }
  }
}

.farmer-info-modal {
  :deep(.modal-container) {
    max-width: 520px;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    justify-content: normal;

    .wrapper {
      width: 100%;
    }

    .modal-body {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;
      padding: 32px 10px 40px 40px;

      &__close {
        top: 0.5rem;
      }
    }
  }
}

.farmer-order-loader {
  width: 100%;
}

.customer-info-sidebar {
  h3 {
    @include h3;
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 32px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    h4 {
      @include h4;
      font-weight: 700;
      color: $gray-800;
    }

    .customer-main-info-block {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .customer-main-info-item {
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        span {
          color: $gray-600;
        }

        p {
          color: $gray-800;
        }
      }
    }

    .customer-contact-info-block {
      .customer-contact-info-list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .customer-contact-info-item {
          display: flex;
          flex-direction: column;

          .p {
            @include p5;
            color: $gray-800;
          }

          .additional-info {
            margin-top: 4px;
            display: grid;
            grid-template-columns: auto 4px 130px;
            column-gap: 8px;
            justify-content: start;
            align-items: center;

            span {
              @include p5;
              color: $gray-600;
              word-break: break-word;
            }
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background-color: $gray-300;
          margin: 15px 0;
        }
      }
    }
  }

  &-loader {
    display: flex;
    align-items: center;
    height: 80vh;
  }
}

.partly-modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  :deep(.file-uploader) {
    height: 124px;
  }

  :deep(.file-uploader__center-info) {
    gap: 0;
  }

  :deep(.file-uploader__text) {
    margin-top: 8px;
  }

  :deep(.uploader-document:not(:first-child)) {
    margin-top: 8px;
  }

  &-title {
    @include p5;
    color: $gray-600;
    margin-bottom: 8px;
  }

  &-comment {
    min-height: 115px;

    textarea {
      width: 100%;
      height: 115px;
      box-sizing: border-box;
      resize: none;
    }
  }

  :deep(.file-uploader-custom) {
    margin-top: 20px;
  }

  .add-document-title {
    display: flex;
    text-decoration: none;
    column-gap: 8px;
    align-items: center;
    @include p6;
    color: $primary-accent;

    .plus {
      font-size: 20px;
    }
  }
}
.price-crossed {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $gray-500;
  text-decoration: line-through;
  position: absolute;
  right: 0;
  top: 100%;
}
</style>
