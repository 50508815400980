<script setup lang="ts">
import { outlineGrayButton } from '@/assets/EgalStyles/EButton'
import { Tag } from 'ant-design-vue'
import router from '@/router'
import { RecipientStatus } from '@/types/types'
import {Button} from "ant-design-vue";
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

interface IProps {
  recipientStatus: RecipientStatus | undefined
  organizationName: string
  requestDate: string
  requestExpired: string
}

defineProps<IProps>()

const handleGoBack = () => {
  router.push('/requests')
}
</script>

<template>
  <div class="toolbar-header">
    <div class="left-part-header">
      <Button @click="handleGoBack" class="back" :style="outlineGrayButton">
        <ChevronLeftIcon />
      </Button>
      <div class="left-part-header__title">
        Запрос от {{ organizationName }}, {{ requestDate }}
      </div>
    </div>
    <div class="right-part-header">
      <Tag v-if="recipientStatus === 'answered'" size="large" color="green">
        Вы ответили на запрос
      </Tag>
      <Tag v-else class="right-part-header__tag" color="blue"
        >Ожидает ответа до {{ requestExpired }}</Tag
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.back {
  padding-right: 10px;
  padding-left: 10px;
}

.toolbar-header {
  box-sizing: border-box;
  height: 85px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}

.left-part-header {
  display: flex;
  align-items: center;
  gap: 12px;

  &__title {
    font-size: 30px;
    font-weight: 600;
  }
  & .chevron-left {
    color: $gray-800;
    stroke: none;
  }
}

.right-part-header {
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(.ant-tag) {
    padding: 5px 12px;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
  }
}
</style>
