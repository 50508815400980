<script setup lang="ts">
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import EmptyFarmCard from '@/components/Customer/EmptyFarmCard.vue'
import TagFilters from '@/components/CardTable/TagFilters.vue'
import CardTable from '@/components/CardTable/CardTable.vue'
import { computed, onMounted, ref } from 'vue'
import { IFarmerProduct } from '@/types/interfaces'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { useRoute } from 'vue-router'
import { FarmerAPI } from '@/api/FarmerAPI'
import InfiniteLoading from 'v3-infinite-loading'
import { Roles } from '@/types/enums'
import { useJWTDecode } from '@/composables/useJWTDecode'
import {useCookies} from "vue3-cookies";

interface IFilterItem {
  value: string | null
  name: string
}

const { cookies } = useCookies()
const page = ref(1)
const category_name = ref<string | null>(null)
const is_fetching = ref(false)
const is_more_fetching = ref(false)
const products = ref<IFarmerProduct[]>([])
const route = useRoute()
const company_id = route.params.id as string
const filter_items = ref<IFilterItem[]>([])

const get_products = async () => {
  const filter = category_name.value
    ? [['', 'product_category', category_name.value]]
    : []

  await FarmerProductAPI.getProductsByCategory(
    filter,
    page.value,
    company_id,
    10,
    ['product', 'specialPrices.customer', 'priceOptions'],
  ).then((res: IFarmerProduct[]) => {
    if (page.value === 1) {
      products.value = [...res?.items]
    } else {
      products.value.push(...res?.items)
    }
    is_fetching.value = false
    is_more_fetching.value = false
  })
}

const load_more = async () => {
  is_more_fetching.value = true
  page.value++
  await get_products()
}

const get_categories = async () => {
  await FarmerAPI.getFarmersWithCategories([], company_id).then((res) => {
    const additional_filters = res?.items?.[0]?.categories.map((category: any) => {
      return {
        value: category.second_category_name,
        name: category.second_category_name,
      }
    })
    filter_items.value.push(...additional_filters)
    filter_items.value?.sort((a, b) => (a.name === 'ПРОЧЕЕ') ? 1 : -1)
  })
  filter_items.value.unshift({ value: null, name: 'Все товары' })
}

const set_category = (item: IFilterItem) => {
  category_name.value = item.value ? item.name : null
  page.value = 1
  is_fetching.value = true
  get_products()
}

const is_empty = computed(() => {
  return products.value.length === 0 && !is_fetching.value
})

const { getCoreCookie } = useJWTDecode()

onMounted(() => {
  is_fetching.value = true
  get_products()
  get_categories()
})
</script>

<template>
  <div class="catalog-wrapper">
    <div v-if="!is_empty">
      <TagFilters
        :active-filter="{ value: category_name }"
        :filter-items="filter_items"
        @set-filter="set_category"
      />
      <div v-if="!is_fetching">
        <CardTable :is-farmer="getCoreCookie()?.company_type === Roles.Farmer" :read-only="false" :items="products" />
        <InfiniteLoading
          :distance="300"
          class="infinite-loading"
          @infinite="load_more"
        />
      </div>
      <div v-else class="fullscreen-loader">
        <FullscreenLoading :type="'secondary'" />
      </div>
    </div>
    <EmptyFarmCard
      v-else
      description="Здесь пока ничего нет"
      title="У поставщика пока что нет созданных товаров"
    />
  </div>
</template>

<style scoped lang="scss">
.catalog-wrapper {
  padding: 24px 0;
}

.fullscreen-loader {
  position: relative;
  height: 50vh;
}
</style>
