<script setup lang="ts">
import {
  Row,
  Col,
  Tabs,
  TabPane,
  Space,
  Drawer,
  TypographyText as TgText,
  List,
  ListItem,
  Badge,
  Flex,
} from 'ant-design-vue'
import ReductionHeader from '@/views/Reductions/components/ReductionHeader/ReductionHeader.vue'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import {
  IReduction,
  IReductionParticipant,
  IReducSocketMessage,
} from '@/types/interfaces'
import { ReductionAPI } from '@/api/ReductionAPI'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import router from '@/router'
import ParticipantStatuses from '@/components/Customer/Reduction/ParticipantStatuses.vue'
import CustomerReductionInfo from '@/components/Customer/Reduction/CustomerReductionInfo.vue'
import CustomerReductionBetsByCompanies from '@/components/Customer/Reduction/CustomerReductionBetsByCompanies.vue'
import CustomerReductionBetsByProducts from '@/components/Customer/Reduction/CustomerReductionBetsByProducts.vue'
import FarmerNameWithAddress from '@/components/Farmer/FarmerNameWithAddress.vue'
import FullscreenLoading from '@/components/Loaders/FullscreenLoading.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import { useCentrifuge } from '@/composables/useCentrifuge'
import emitter from "@/emitter";

const route = useRoute()
const activeKey = ref('1')
const reductionData = ref<IReduction | null>(null)
const isFetching = ref(false)
const isShowFarmers = ref(false)
const partners = ref([])
const socketData = ref<IReducSocketMessage | null>(null)
const disconnectSocket = ref<(() => void) | null>(null)

const navToList = () => {
  router.go(-1)
}

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const connectReductionSocket = async () => {
  const { addChanel, startCentrifuge } = useCentrifuge()
  const disconnect = startCentrifuge()
  const id = route.params.id
  if (id) {
    addChanel(`core@Reduction.${id}`, socketAction)
  }

  disconnectSocket.value = disconnect
}

const socketAction = async (socketMessage: IReducSocketMessage) => {
  socketData.value = socketMessage
}

const refetch = async () => {
  isFetching.value = true
  reductionData.value = await ReductionAPI.getItem(String(route.params.id), [
    'reductionProductRequests.product',
    'reductionParticipants.farmer',
    'mergedRecipients.farmer',
    'reductionParticipants.farmer.company_rating',
    'reductionProductRequests.reductionProductAnswers.reductionParticipant.farmer.addresses',
    'reductionProductRequests.reductionProductAnswers.reductionParticipant.farmer.company_rating',
  ])
  await connectReductionSocket()
  await getMyPartners()
  isFetching.value = false
}

emitter.on('refresh-token', async (e) => {
  await refetch()
})

onMounted(async () => {
  await refetch()
})

onUnmounted(async () => {
  disconnectSocket.value?.()
})

const isShowFarmerDetails = ref(false)
const selectedParticipant = ref<IReductionParticipant | null>(null)
const showFarmerDetails = (value: IReductionParticipant) => {
  selectedParticipant.value = value
  isShowFarmerDetails.value = true
}

const farmersByStatuses = computed(() => {
  const result: IReductionParticipant[][] = [[], []]
  const participants =
    socketData.value?.reduction_participants ||
    reductionData.value?.merged_recipients
  participants?.forEach((participant) => {
    if (participant.answer_status === 'has_bet') {
      result[0].push(participant)
    } else {
      result[1].push(participant)
    }
  })
  return result
})
</script>

<template>
  <div class="customer-reduction-view" v-if="!isFetching">
    <Row :gutter="[0, 0]">
      <Col span="24">
        <ReductionHeader
          :title="`Торг на покупку от ${dayjs(reductionData?.created_at).format('DD.MM.YYYY')}`"
          @onBtnClick="navToList"
          :is-list="false"
          :created-at="dayjs(reductionData?.created_at).format('DD.MM.YYYY')"
          :expiration-date="
            dayjs(reductionData?.expiration_date).format('DD.MM.YYYY, HH:mm')
          "
          :isOpen="reductionData?.status === 'open'"
        />
      </Col>
      <Col span="24">
        <Tabs v-model:activeKey="activeKey">
          <TabPane key="1" tab="Информация о торге на покупку">
            <CustomerReductionInfo :reductionData="reductionData" />
          </TabPane>
          <TabPane key="2" tab="Ставки по компаниям">
            <CustomerReductionBetsByCompanies
              :reductionData="reductionData"
              @showFarmerDetails="showFarmerDetails"
              @refetch="refetch"
          /></TabPane>
          <TabPane key="3" tab="Ставки по товарам">
            <CustomerReductionBetsByProducts
              :reductionData="reductionData"
              @showFarmerDetails="showFarmerDetails"
            />
          </TabPane>
          <template #rightExtra>
            <ParticipantStatuses
              :isPublic="reductionData?.is_public ?? false"
              :participants="reductionData?.merged_recipients ?? []"
              :socket-data="socketData"
              @onBtnClick="isShowFarmers = true"
            />
          </template>
        </Tabs>
      </Col>
    </Row>
  </div>
  <div class="loader-wrapper" v-else>
    <FullscreenLoading :type="'secondary'" />
  </div>
  <Drawer
    v-model:open="isShowFarmerDetails"
    :width="500"
    :bodyStyle="{ padding: '24px 32px' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    title="Комментарий / документы / данные компании"
    class="drawer-reverse-header"
  >
    <Row :gutter="[0, 24]">
      <Col :span="24">
        <Space direction="vertical" :size="8">
          <span class="drawer-item-title">Поставщик</span>
          <FarmerNameWithAddress
            :farmer="selectedParticipant?.farmer ?? null"
          />
        </Space>
      </Col>
      <Col :span="24">
        <Space direction="vertical" :size="8">
          <span class="drawer-item-title">Комментарий</span>
          <TgText>
            {{ selectedParticipant?.comment }}
          </TgText>
        </Space>
      </Col>
    </Row>
  </Drawer>
  <Drawer
    v-model:open="isShowFarmers"
    :width="500"
    :bodyStyle="{ padding: '24px 0' }"
    :headerStyle="{
      padding: '24px 20px 24px 32px',
    }"
    :closable="true"
    title="Все поставщики"
    class="drawer-reverse-header"
  >
    <Row :gutter="[0, 24]">
      <Col span="24" v-if="farmersByStatuses[0].length">
        <span class="section-title">Сделали ставку</span>
        <List
          :data-source="farmersByStatuses[0]"
          rowKey="id"
          size="large"
          class="farmers-list"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Flex :gap="4">
                <Badge status="success" />
                <FarmerNameWithAddress
                  :farmer="item.farmer"
                  :is-partner="
                    partners.find(
                      (el: any) => el.company_id === item.farmer?.id,
                    )
                  "
                />
              </Flex>
            </ListItem>
          </template>
        </List>
      </Col>
      <Col span="24" v-if="farmersByStatuses[1].length">
        <span class="section-title">Не сделали ставку</span>
        <List
          :data-source="farmersByStatuses[1]"
          rowKey="id"
          size="large"
          class="farmers-list"
        >
          <template #renderItem="{ item }">
            <ListItem>
              <Flex :gap="4">
                <Badge status="warning" />
                <FarmerNameWithAddress
                  :farmer="item.farmer"
                  :is-partner="
                    partners.find(
                      (el: any) => el.company_id === item.farmer?.id,
                    )
                  "
                />
              </Flex>
            </ListItem>
          </template>
        </List>
      </Col>
    </Row>
  </Drawer>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.customer-reduction-view {
  @include wrapper();
  padding: 24px 0;
}

.drawer-item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
}

span.section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000e0;
  margin-left: 32px;
}
.farmers-list {
  :deep(.ant-list .ant-list-item) {
    padding: 16px 32px;
  }
}

.loader-wrapper {
  position: relative;
  height: 70vh;
}
</style>
