<script setup lang="ts">
import { computed, h, ref } from 'vue'
import CustomerInfoBlock from '@/views/Farmer/response/toolbarWidget/CustomerInfoBlock.vue'
import {
  Button,
  Flex,
  Form,
  FormItem,
  notification,
  Textarea,
  TypographyText as TpText,
} from 'ant-design-vue'
import { InfoCircleFilled, RightOutlined } from '@ant-design/icons-vue'
import { IReduction } from '@/types/interfaces'
import TitleToolbarBlock from '@/views/Farmer/response/toolbarWidget/TitleToolbarBlock.vue'
import ToolbarBlock from '@/views/Farmer/reductions/reduction/farmerReductionInfoTab/infoToolbar/ToolbarBlock.vue'
import { declensionDays, getUserIdTemporary } from '@/helpers/scripts'
import { PaletizingType } from '@/types/types'
import { ReductionParticipantAPI } from '@/api/ReductionParticipant'
import dayjs from 'dayjs'

enum EProperties {
  is_delivery_excluded = 'Оплачивается отдельно',
  is_delivery_included = 'Включена в стоимость товара',
  is_pay_cash = 'Наличный расчёт',
  is_pay_non_cash = 'Безналичный расчёт',
  is_self_delivery = 'Самовывоз',
  is_supplier_delivery = 'Доставка от поставщика',
  need_unload = 'Нужна разгрузка',
}

interface Props {
  reduction: IReduction
}

const props = defineProps<Props>()

const formRef = ref()

const isOpen = ref(true)

const farmerId = +getUserIdTemporary()

const farmerComment = ref(
  props.reduction?.merged_recipients?.find(
    (participant) => participant.farmer_id === farmerId,
  )?.comment ?? '',
)

const formState = ref({
  comment: farmerComment.value,
})

const toggleCollapse = () => {
  isOpen.value = !isOpen.value
}

const saveComment = () => {
  formRef.value.validate().then(() => {
    ReductionParticipantAPI.update({
      id: props.reduction?.merged_recipients?.find(
        (participant) => participant.farmer_id === farmerId,
      )?.id,
      comment: formState.value.comment,
    }).then(() => {
      farmerComment.value = formState.value.comment
      notification.info({
        message: 'Данные сохранены',
        icon: h(InfoCircleFilled, {
          style: { color: 'rgba(22, 119, 255, 1)' },
        }),
      })
    })
  })
}

const getProperties = (properties: string[]) => {
  return properties
    .filter((property) => !!props.reduction[property])
    .map((property) => EProperties[property as keyof EProperties])
}

const getPallets = (palletizing_type: PaletizingType) => {
  switch (palletizing_type) {
    case 'euro':
      return ['Паллетирование', 'Европаллеты']
    case 'standard':
      return ['Паллетирование']
    default:
      return []
  }
}

const termsDelivery = computed(() => {
  const terms = [
    {
      title: 'Способ получения',
      subtitles: getProperties(['is_self_delivery', 'is_supplier_delivery']),
    },
    {
      title: 'Как оплачивается доставка?',
      subtitles: getProperties([
        'is_delivery_included',
        'is_delivery_excluded',
      ]),
    },
  ]

  if (
    props.reduction.should_be_delivered_at ||
    props.reduction.should_be_delivered_in_days
  ) {
    terms.push({
      title: 'Срок поставки',
      subtitles: [
        props.reduction.should_be_delivered_at
          ? `к ${dayjs(props.reduction.should_be_delivered_at).format('DD.MM.YYYY')}`
          : `${props.reduction.should_be_delivered_in_days} ${declensionDays(props.reduction.should_be_delivered_in_days || '')}`,
      ],
    })
  }

  const subtitlesPaymentMethod = getProperties([
    'is_pay_cash',
    'is_pay_non_cash',
  ])

  if (props.reduction.is_payment_delayed) {
    subtitlesPaymentMethod.push(
      `Отсрочка по оплате ${props.reduction.payment_delay_days ? props.reduction.payment_delay_days + ' ' + declensionDays(props.reduction.payment_delay_days) : ''}`,
    )
  }

  terms.push({
    title: 'Способ оплаты',
    subtitles: subtitlesPaymentMethod,
  })

  terms.push({
    title: 'Услуги',
    subtitles: [
      ...getPallets(props.reduction.palletizing_type),
      ...getProperties(['need_unload']),
    ],
  })

  return terms.filter((term) => term.subtitles.length)
})

const isShowTermsDelivery = computed(() =>
  termsDelivery.value.some((item) => item.subtitles.length),
)

const farmerHasBet = computed(
  () =>
    props.reduction?.merged_recipients?.find(
      (participant) => participant.farmer_id === farmerId,
    )?.answer_status === 'has_bet',
)
</script>

<template>
  <div class="toolbar-wrapper" :style="{ minWidth: isOpen ? '360px' : '24px' }">
    <div v-show="isOpen" class="toolbar-uncollapsed">
      <CustomerInfoBlock
        @button-click="toggleCollapse"
        :contact_name="props.reduction.contact_name"
        :contact_phone="props.reduction.contact_phone"
        :contact_post="props.reduction.contact_post"
        :address="props.reduction.delivery_address"
        :auction="props.reduction"
        type="reduction"
      />
      <div class="block-wrapper" v-if="isShowTermsDelivery">
        <TitleToolbarBlock title="Условия к поставке" />
        <ToolbarBlock
          v-for="{ title, subtitles } in termsDelivery"
          :key="title"
          :title="title"
          :subtitles="subtitles"
        />
      </div>
      <div class="block-wrapper">
        <Flex :gap="4" vertical>
          <TitleToolbarBlock title="Комментарий" />
          <Flex :gap="24" vertical>
            <TpText type="secondary"
              >Сделайте первую ставку, чтобы оставить комментарий
            </TpText>
            <Form ref="formRef" :model="formState">
              <FormItem
                name="comment"
                help="Максимум 1000 символов"
                :rules="[
                  {
                    max: 1000,
                    message: 'Максимум 1000 символов',
                    trigger: 'change',
                  },
                ]"
              >
                <Textarea
                  v-model:value="formState.comment"
                  :disabled="!farmerHasBet || reduction.status === 'closed'"
                  :rows="4"
                  placeholder="Комментарий к торгу на покупку"
                />
              </FormItem>
              <FormItem :style="{ width: '100%', display: 'flex' }">
                <Button
                  @click="saveComment"
                  :disabled="
                    !farmerHasBet ||
                    formState.comment === farmerComment ||
                    reduction.status === 'closed'
                  "
                  :style="{ width: '100%' }"
                  >Сохранить доп. условия</Button
                >
              </FormItem>
            </Form>
          </Flex>
        </Flex>
      </div>
      <div v-if="reduction.comment" class="block-wrapper">
        <TitleToolbarBlock title="Дополнительно от покупателя" />
        <ToolbarBlock title="Комментарий" :subtitles="[reduction.comment]" />
      </div>
    </div>
    <div v-if="!isOpen" class="toolbar-collapsed">
      <Button type="link" @click="toggleCollapse">
        <template #icon>
          <RightOutlined />
        </template>
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar-wrapper {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 24px 0;
  width: min-content;
  overflow: auto;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.06);

  & .toolbar-collapsed {
    padding: 0 24px;
    min-height: calc(100vh - 72px - 86px - 46px - 152px);
  }

  & .toolbar-uncollapsed {
    height: 100%;
  }
}

.block-wrapper {
  padding: 20px 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

:deep(.toolbar-block-wrapper) {
  margin-bottom: 24px;
}
</style>
