<script setup lang="ts">
import { IAuction, IAuctionProductRequest } from '@/types/interfaces'
import { computed, ref, onMounted } from 'vue'
import {
  Button,
  Flex,
  Space,
  Table,
  TypographyText as TpText,
} from 'ant-design-vue'
import {
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import StartPriceCell from '@/views/Auctions/components/AuctionTable/StartPriceCell.vue'
import CustomerNameWithAddress from '@/components/Customer/CustomerNameWithAddress.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { priceWithoutVat, priceWithVat, roundNumber } from '@/helpers/scripts'

interface IProps {
  auctionData: IAuction | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['showCustomerDetails', 'setSelectedProduct'])
const expandedRowKeys = ref<string[]>([])
const partners = ref([])

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '58%',
  },
  {
    title: 'Стартовая цена',
    key: 'start_price',
    width: '13%',
  },
  {
    title: 'Лучшее предложение',
    key: 'best_offer',
  },
]

const nestedColumns = [
  {
    title: 'Рейтинг',
    key: 'rating',
    width: '7%',
  },
  {
    title: 'Покупатель',
    key: 'customer',
    width: '40%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const calculateBetSum = (auctionProductRequest: IAuctionProductRequest) => {
  const price = auctionProductRequest.best_bet?.price_per_unit ?? 0
  return roundNumber(+(price * Number(auctionProductRequest.volume)))
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const filteredProductRequests = computed(() => {
  if (!props.auctionData?.auction_product_requests) {
    return []
  }
  return props.auctionData?.auction_product_requests.filter(
    (currentRequest) => currentRequest.best_bet,
  )
})

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

onMounted(async () => {
  await getMyPartners()
})
// TODO: refactor with fillMeasurementUnitIdCommon
</script>

<template>
  <Table
    :columns="columns"
    :data-source="filteredProductRequests ?? []"
    :pagination="false"
    :expandedRowKeys="expandedRowKeys"
    :row-key="(record) => String(record.id)"
    :showExpandColumn="false"
    class="edged-border-table"
    v-if="filteredProductRequests?.length"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'product'">
        <ProductWithProperties
          :volume="record?.volume"
          :remaining_shelf_life="record.remaining_shelf_life"
          :measurement_unit_id="record?.farmer_product?.measurement_unit_id"
          :name="record.farmer_product_name"
          :is_sample_needed="!!record?.requested_samples_number"
          :needed_samples_number="record?.requested_samples_number"
          :isProductNameLink="true"
          @onClickName="emit('setSelectedProduct', record.farmer_product)"
        />
      </template>
      <template v-if="column.key === 'start_price'">
        <StartPriceCell
          :price="roundNumber(record.start_price)"
          :vat="record.vat"
          :measurementUnit="record.farmer_product.measurement_unit_id"
        />
      </template>
      <template v-if="column.key === 'best_offer'">
        <template v-if="record.best_bet">
          <Flex justify="space-between" align="center">
            <Space direction="vertical" :size="0">
              <TpText strong>{{ `${calculateBetSum(record)} ₽` }}</TpText>
              <span class="customer-name">{{
                `${record.best_bet.auction_participant.customer.legal_form} ${record.best_bet.auction_participant.customer.name}`
              }}</span>
            </Space>
            <Button type="link" @click="changeExpandedRow(record.id)">
              <template #icon>
                <DownOutlined
                  v-if="!expandedRowKeys.includes(String(record.id))"
                />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </Flex>
        </template>
        <template v-else><TpText type="secondary">Нет ставок</TpText></template>
      </template>
    </template>

    <template #expandedRowRender="{ record }">
      <Table
        :columns="nestedColumns"
        :data-source="record.auction_product_answers"
        :pagination="false"
        bordered
        class="nested-table"
      >
        <template #bodyCell="{ column, record: answer, index }">
          <template v-if="column.key === 'rating'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'customer'">
            <Space direction="vertical" :size="8">
              <CustomerNameWithAddress
                :customer="answer.auction_participant.customer"
                :is-partner="
                  partners.find(
                    (el: any) =>
                      el.company_id === answer.auction_participant.customer?.id,
                  )
                "
              />
              <Button
                type="link"
                class="additional-btn"
                v-if="answer.auction_participant.comment"
                @click="emit('showCustomerDetails', answer.auction_participant)"
              >
                <template #icon><InfoCircleOutlined /></template>
                Дополнительно
              </Button>
            </Space>
          </template>
          <template v-if="column.key === 'price_without_vat'">
            <CellDifData
              :new-data="
                roundNumber(priceWithoutVat(+answer.price_per_unit, record.vat))
              "
              :prev-data="
                roundNumber(
                  priceWithoutVat(+answer.price_per_unit_first, record.vat),
                )
              "
              :additional-info="
                record.farmer_product.measurement_unit_id
                  ? `₽/${record.farmer_product.measurement_unit_id}`
                  : '₽'
              "
            />
          </template>
          <template v-if="column.key === 'price_with_vat'">
            <CellDifData
              :new-data="answer.price_per_unit"
              :prev-data="answer.price_per_unit_first"
              :additional-info="
                record.farmer_product.measurement_unit_id
                  ? `₽/${record.farmer_product.measurement_unit_id}`
                  : '₽'
              "
            />
          </template>
          <template v-if="column.key === 'amount'">
            <Flex justify="space-between">
              <CellDifData
                :new-data="+answer.price_per_unit * Number(record.volume)"
                :prev-data="
                  +answer.price_per_unit_first * Number(record.volume)
                "
                additional-info="₽"
              />
            </Flex>
          </template>
        </template>
      </Table>
    </template>
  </Table>
  <div v-else class="empty-view-wrapper">
    <EmptyView
      :is-show-button="false"
      title="Ставок пока что нет"
      description="Они будут отображаться здесь"
    />
  </div>
</template>

<style scoped lang="scss">
.customer-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000a6;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}
.nested-table {
  :deep(.ant-table) {
    border-radius: 0 !important;
  }
  :deep(.ant-table-row .ant-table-cell:first-child) {
    text-align: center;
  }
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}

.empty-view-wrapper {
  padding: 72px 0;
}
</style>
