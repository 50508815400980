<script lang="ts" setup>


const props = defineProps({
  title: String,
  text: String,
  action: String,
  data: Object,
})

const emit = defineEmits(['restore-item'])
</script>

<template>
  <div class="notification">
    <div class="notification__title" v-if="title">{{ title }}</div>
    <div class="notification__text">{{ text }}</div>
    <div class="notification__action" @click="emit('restore-item', data)">
      {{ action }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.notification {
  font-size: 14px;
  line-height: 17px;
  color: $gray-800;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    font-weight: 700;
  }

  &__text {
    font-weight: 400;
  }

  &__action {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0066ff;
    cursor: pointer;
  }
}
</style>
