<script lang="ts" setup>
import { Rate } from 'ant-design-vue'
import { ref } from 'vue'
import { IMark } from '@/types/interfaces'

interface Props {
  mark: IMark
  comment: string
}

const props = defineProps<Props>()
const rate = ref<number>(3)

</script>

<template>
  <div class="rate-container">
    <div class="text-rate">
      <div class="black">
        Ваша оценка заказа
      </div>
      <div class="rate">
        <Rate
          :count="5"
          :value="mark.mark"
        />
        <span>
          {{ mark.mark }},0
        </span>
      </div>
    </div>
    <div class="comment">
      {{ comment }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rate-container {
  width: 496px;
  border-radius: 12px;
  background: #00000005;

  .text-rate {
    margin-top: 24px;
    align-items : center;
    display: flex;

    .black {
      margin-left: 24px;
      font-weight: 600;
      color: #000000E0;
    }

    .rate {
      margin-left: auto;
      margin-right: 24px;
      font-size: 14px;
      line-height: 22px;

      span {
        margin-left: 8px;
      }
    }
  }

  .comment {
    margin-left: 24px;
    margin-top: 8px;
    margin-bottom: 20px;
    width: 448px;
    font-size: 14px;
    line-height: 22px;
    color: #000000A6;
  }
}
</style>
