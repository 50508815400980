import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class CompanyAPIModel {
  async getItems(
    page = 1,
    perPage = 10,
    filters: any[] = [],
    withs: any[] = [],
  ): Promise<any> {
    return RequestManager.request({
      method: EgalMethods.GetItems,
      model: APIModels.Company,
      data: { pagination: { per_page: perPage, page }, filter: filters, withs },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getItem(id: string, withs: any[] = []): Promise<any> {
    return RequestManager.request({
      method: EgalMethods.GetItem,
      model: APIModels.Company,
      data: { id, withs },
      headers: { Authorization: cookies.get('core') }
    })
  }

  async getEmployees(page = 1, perPage = 10): Promise<any> {
    return RequestManager.request({
      method: EgalMethods.getEmployees,
      model: APIModels.Company,
      data: { pagination: { per_page: perPage, page }},
      headers: { Authorization: cookies.get('core') }
    })
  }
}

const CompanyAPI = new CompanyAPIModel()
export { CompanyAPI }
