<script setup lang="ts">
import TextWithOldPopover from '@/components/TextWithOldPopover/TextWithOldPopover.vue'
import { iconStyle } from '@/components/Orders/OrderHeaderItems/config'
import { CreditCardOutlined } from '@ant-design/icons-vue'
import { Flex, Space, TypographyText as TgText } from 'ant-design-vue'
import { IOrder } from '@/types/interfaces'
import { paymentType } from '@/helpers/dictionary'
import { declensionDays, is_orders_has_difference } from '@/helpers/scripts'
import { computed } from 'vue'
import { NewGeneralOrderStatus } from '@/types/enums'

interface IProps {
  order: IOrder
  history: IOrder | null
}

const props = defineProps<IProps>()

const isPaymentChanged = computed(() => {
  return (
    props.history &&
    (props.order.payment_type !== props.history?.payment_type ||
      props.order.is_payment_delayed !== props.history?.is_payment_delayed ||
      props.order.payment_delay_days !== props.history?.payment_delay_days)
  )
})

const isShowOldPaymentDelayDays = computed(() => {
  return (
    props.order.payment_delay_days !== props.history?.payment_delay_days &&
    props.history?.payment_delay_days
  )
})

const iShowOldNoPaymentDelay = computed(() => {
  return (
    props.order.is_payment_delayed !== props.history?.is_payment_delayed &&
    !props.history?.is_payment_delayed
  )
})

const newText = computed(() => {
  const result = [paymentType[props.order.payment_type]]
  props.order.payment_delay_days &&
    result.push(
      `Отсрочка по оплате ${props.order?.payment_delay_days} ${declensionDays(props.order?.payment_delay_days)}`,
    )

  return result
})

const orderWasEditedByFarmer = computed(() => {
  const order_was_edited =
    props.order.history[props.order.history.length - 1]?.initiator_type ===
      'farmer' && is_orders_has_difference(props.order, props.history)

  return order_was_edited && props.order.status === NewGeneralOrderStatus.New
})

const oldText = computed(() => {
  const result = []
  result.push(paymentType[props.history?.payment_type])
  isShowOldPaymentDelayDays.value &&
    result.push(
      `Отсрочка по оплате ${props.history?.payment_delay_days} ${declensionDays(props.history?.payment_delay_days)}`,
    )
  iShowOldNoPaymentDelay.value && result.push('Без отсрочки')

  return result
})

const paymentDelayText = computed(() => {
  return `Отсрочка по оплате ${props.order.history[props.order.history.length - 1].old_entity.payment_delay_days ? props.order.history[props.order.history.length - 1].old_entity.payment_delay_days + ' ' + declensionDays(props.order.history[props.order.history.length - 1].old_entity.payment_delay_days) : ''}`
})
</script>

<template>
  <Space :size="6" align="start">
    <CreditCardOutlined :style="iconStyle" />
    <Flex :gap="4" vertical>
      <TextWithOldPopover
        v-if="isPaymentChanged && !orderWasEditedByFarmer"
        :text="newText"
        :old_text="oldText"
      />
      <template v-else-if="orderWasEditedByFarmer">
        <TgText>
          {{
            paymentType[
              props.order.history[props.order.history.length - 1].old_entity
                .payment_type
            ]
          }}
        </TgText>
        <TgText
          v-if="
            order.history[order.history.length - 1].old_entity
              .payment_delay_days
          "
        >
          {{ paymentDelayText }}
        </TgText>
      </template>

      <template v-else>
        <TgText> {{ paymentType[order.payment_type] }} </TgText>
        <TgText v-if="order.is_payment_delayed">
          {{
            `Отсрочка по оплате ${order.payment_delay_days ? order?.payment_delay_days + ' ' + declensionDays(order?.payment_delay_days) : ''}`
          }}
        </TgText>
      </template>
    </Flex>
  </Space>
</template>

<style scoped lang="scss"></style>
