<script lang="ts" setup>
import { Button, Modal } from 'ant-design-vue'
interface IProps {
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['close'])
</script>

<template>
  <Modal
    class="modal"
    closable
    centered
    title="Нет доступных товаров для заказа"
    :open="open"
  >
    <div class="description">Поставщик удалил их из своего каталога</div>

    <template #footer>
      <Button type='primary' @click="emit('close')"> Понятно </Button>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal {
  max-height: 500px;
}

.description {
  transform: translateY(-5px);
  color: #00000073;
}
</style>
