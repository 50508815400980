<script setup lang="ts">
import FaqItem from '@/views/1C/views/1CFAQ/components/FaqItem.vue'
import FaqNav from '@/views/1C/views/1CFAQ/components/FaqNav.vue'
import OneOne from '@/views/1C/views/1CFAQ/components/assets/1-1.png'
import OneTwo from '@/views/1C/views/1CFAQ/components/assets/1-2.png'
import OneThree from '@/views/1C/views/1CFAQ/components/assets/1-3.png'
import TwoOne from '@/views/1C/views/1CFAQ/components/assets/2-1.png'
import TwoTwo from '@/views/1C/views/1CFAQ/components/assets/2-2.png'
import TwoThree from '@/views/1C/views/1CFAQ/components/assets/2-3.png'
import ThreeOne from '@/views/1C/views/1CFAQ/components/assets/3-1.png'
import ThreeTwo from '@/views/1C/views/1CFAQ/components/assets/3-2.png'
import ThreeThree from '@/views/1C/views/1CFAQ/components/assets/3-3.png'
import ThreeFour from '@/views/1C/views/1CFAQ/components/assets/3-4.png'
import FourOne from '@/views/1C/views/1CFAQ/components/assets/4-1.png'
import FourTwo from '@/views/1C/views/1CFAQ/components/assets/4-2.png'
import FourThree from '@/views/1C/views/1CFAQ/components/assets/4-3.png'
import FiveOne from '@/views/1C/views/1CFAQ/components/assets/5-1.png'
import FiveTwo from '@/views/1C/views/1CFAQ/components/assets/5-2.png'
import FiveThree from '@/views/1C/views/1CFAQ/components/assets/5-3.png'
import FiveFour from '@/views/1C/views/1CFAQ/components/assets/5-4.png'
import FiveFive from '@/views/1C/views/1CFAQ/components/assets/5-5.png'
import FiveSix from '@/views/1C/views/1CFAQ/components/assets/5-6.png'
import FiveSeven from '@/views/1C/views/1CFAQ/components/assets/5-7.png'
import FiveEight from '@/views/1C/views/1CFAQ/components/assets/5-8.png'
import FiveNine from '@/views/1C/views/1CFAQ/components/assets/5-9.png'

import {
  dividerHeights,
  FaqRender,
  FaqThemes,
} from '@/views/1C/views/1CFAQ/components/FaqRenderAPI'

const emit = defineEmits(['openModalEnterEmailAndPassword'])

const { renderDivider, renderImg, renderText, renderCollapse } = FaqRender

const openModalEnterEmailAndPassword = () => {
  emit('openModalEnterEmailAndPassword')
}

const FaqBlocks = {
  [FaqThemes['Настройка обмена с сайтом']]: [
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'Чтобы настроить обмен с нашей системой, перейдите в раздел «НСИ и администрирование», выберите пункт «Обмен с сайтом»',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '1-1', value: OneOne }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'Укажите «Использовать обмен с сайтом» и перейдите в раздел «Настройки обмена с сайтом»',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '1-2', value: OneTwo }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value: 'Нажмите кнопку «Создать»',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '1-3', value: OneThree }),
    renderDivider(dividerHeights['20']),
  ],
  [FaqThemes['Основные настройки']]: [
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Наименование',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value: 'Укажите любое название для обмена',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '2-1', value: TwoOne }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Режим обмена данными',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value: 'В «Способе обмена данными» выберите пункт «Выгружать на сайт»',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '2-2', value: TwoTwo }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Способ обмена данными',
    }),
    renderDivider(dividerHeights['10']),
    renderText({
      type: 'text',
      value:
        '1. В «Способе обмена данными» выберите пункт «Выгружать на сайт»;',
    }),
    renderText({
      type: 'text-with-end-action',
      value:
        '2. В поле «Имя пользователя» и «Пароль» укажите свои данные. Если вы ещё их не создавали, перейдите к',
      action: openModalEnterEmailAndPassword,
      linkText: 'данным для обмена',
    }),
    renderText({
      type: 'text-with-end-inner-link',
      value: '3. В поле «Адрес сайта» укажите следующее: ',
      link: '/1c-faq',
      linkText: 'http://farm-platform/onec/1c_exchange',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '2-3', value: TwoThree }),
    renderDivider(dividerHeights['10']),
    renderText({
      type: 'text',
      value:
        'Также вы можете проверить соединение. Если вы заполнили всё верно, то система сообщит об этом.',
    }),
    renderText({
      type: 'text',
      value: 'Перейдите на вкладку «Выгрузка товаров».',
    }),
  ],
  [FaqThemes['Выгрузка товаров']]: [
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Организация',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value: 'Укажите организацию, из которой будет браться каталог товаров',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '3-1', value: ThreeOne }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Узел обмена',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value:
        'Выберите пункт «Выгрузка на сайт описания товаров, типов цен, остатков»',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '3-2', value: ThreeTwo }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Выгружаемые данные',
    }),
    renderText({
      type: 'text',
      value: '1. Выберите пункт «Каталог товаров»;',
    }),
    renderText({
      type: 'text',
      value:
        '2. Выберите пункт «Цены по соглашениям и остатки товаров на складах».',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '3-3', value: ThreeThree }),
    renderDivider(dividerHeights['10']),
    renderText({
      type: 'text',
      value:
        'Вы можете выбрать склады, с которых будет производиться выгрузка товаров. Если вы хотите проводить',
    }),
    renderText({
      type: 'text',
      value: 'выгрузку со всех складов — оставьте поле пустым.',
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Режим выгрузки данных',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value: 'Выберите по своему усмотрению',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({ alt: '3-4', value: ThreeFour }),
  ],
  [FaqThemes['Настройки каталога']]: [
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'В таблице каталогов нажмите кнопку «добавить», добавится новый выгружаемый каталог.',
    }),
    renderText({
      type: 'text',
      value:
        'В колонке «Группы номенклатуры» нажмите на 3 точки для выбора номенклатур.',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '4-1',
      value: FourOne,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Список значений',
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'В появившемся окне нажмите кнопку «Добавить», выберите нужную номенклатуру из списка',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '4-2',
      value: FourTwo,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'Если в списке нет нужных номенклатур, нажмите кнопку «Показать все» и найдите подходящие номенклатуры',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '4-3',
      value: FourThree,
    }),
  ],
  [FaqThemes['Настройки отбора']]: [
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value: 'В колонке «Отбор» нажмите на 3 точки для настройки',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-1',
      value: FiveOne,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Настройка номенклатуры',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value: '1. В появившемся окне отметьте пункт «Номенклатура»;',
    }),
    renderText({
      type: 'text',
      value: '2. В ячейке «Вид сравнения» выберите пункт «В списке»;',
    }),
    renderText({
      type: 'text',
      value:
        '3. В ячейке «Значение» укажите номенклатуры аналогичные выбору номенклатур для каталога.',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-2',
      value: FiveTwo,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Настройка соглашений',
    }),
    renderText({
      type: 'text',
      value: '1. Отметьте пункт «Соглашение»;',
    }),
    renderText({
      type: 'text',
      value: '2. В ячейке «Вид сравнения» выберите пункт «Равно»;',
    }),
    renderText({
      type: 'text',
      value: '3. В ячейке «Вид сравнения» выберите пункт «Равно»;',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-3',
      value: FiveThree,
    }),
    renderDivider(dividerHeights['5']),
    renderImg({
      alt: '5-4',
      value: FiveFour,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value: 'После выбора соглашения, перейдите в настройки соглашения.',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-5',
      value: FiveFive,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value:
        'Важно, чтобы выбранное типовое соглашение поддерживало обмен с сайтом; в противном случае, выгрузка товаров пройдёт неправильно. В настройках соглашения перейдите на вкладку «Прочие условия», выберите пункт «Доступно внешним пользователям и для обмена с сайтом».',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-6',
      value: FiveSix,
    }),
    renderDivider(dividerHeights['20']),
    renderCollapse({
      title:
        'Что делать, если у вас нету пункта «Доступно внешним пользователям и для обмена с сайтом»?',
      content: [
        renderText({
          type: 'text',
          value:
            'Перейдите в «НСИ и администрирование», выберите пункт «Настройки пользователей и прав»',
        }),
        renderDivider(dividerHeights['10']),
        renderImg({
          alt: '5-7',
          value: FiveSeven,
        }),
        renderDivider(dividerHeights['20']),
        renderText({
          type: 'text',
          value:
            'Выберите пункт «Разрешить доступ внешним пользователям». После этого в соглашении появится нужный пукнт.',
        }),
        renderDivider(dividerHeights['10']),
        renderImg({
          alt: '5-8',
          value: FiveEight,
        }),
      ],
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text-bold',
      value: 'Все настройки завершены',
    }),
    renderDivider(dividerHeights['5']),
    renderText({
      type: 'text',
      value:
        'Нажмите кнопку «Записать», затем «Выполнить обмен»; после этого начнётся обмен с сайтом. Вы можете проводить обмен каждый раз вручную, либо настроить автоматический обмен.',
    }),
    renderDivider(dividerHeights['10']),
    renderImg({
      alt: '5-9',
      value: FiveNine,
    }),
    renderDivider(dividerHeights['20']),
    renderText({
      type: 'text',
      value: 'Теперь в нашей системе нажмите на кнопку «Выполнить обмен с 1С»',
    }),
    renderDivider(dividerHeights['50']),
  ],
}

const faqItems = [
  {
    id: FaqThemes['Настройка обмена с сайтом'],
    title: 'Настройка обмена с сайтом',
    content: FaqBlocks[FaqThemes['Настройка обмена с сайтом']],
  },
  {
    id: FaqThemes['Основные настройки'],
    title: 'Основные настройки',
    content: FaqBlocks[FaqThemes['Основные настройки']],
  },
  {
    id: FaqThemes['Выгрузка товаров'],
    title: 'Выгрузка товаров',
    content: FaqBlocks[FaqThemes['Выгрузка товаров']],
  },
  {
    id: FaqThemes['Настройки каталога'],
    title: 'Настройки каталога',
    content: FaqBlocks[FaqThemes['Настройки каталога']],
  },
  {
    id: FaqThemes['Настройки отбора'],
    title: 'Настройки отбора',
    content: FaqBlocks[FaqThemes['Настройки отбора']],
  },
]
</script>

<template>
  <div class="faq">
    <div class="faq__left">
      <FaqItem v-for="item in faqItems" :key="item.id" :item="item" />
    </div>
    <div class="faq__right">
      <FaqNav :items="faqItems" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.faq {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  gap: 10px;
  &__left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
  }
  &__right {
    margin-top: 20px;
    width: 20%;
    height: 100vh;
    position: sticky;
    top: 180px;
  }
}
</style>
