<script setup lang="ts">
import {
  ref,
  onMounted,
  onUnmounted, computed,
} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { OneCAPI } from '@/api/OneCAPI'
import OneCHeader from '@/views/1C/components/1CHeader/1CHeader.vue'
import FaqData from '@/views/1C/views/1CFAQ/components/FaqData.vue'
import OneCFooter from '@/views/1C/components/1CFooter/1CFooter.vue'
import OneCModalEnterEmailAndPassword from '@/views/1C/components/1CModalEnterEmailAndPassword/1CModalEnterEmailAndPassword.vue'
import OneCModalLoading from '@/views/1C/components/1CModalLoading/1CModalLoading.vue'
import OneCModalNotFound from '@/views/1C/components/1CModalNotFound/1CModalNotFound.vue'

const router = useRouter()

type TStatus = 'integrated' | 'started' | 'finished'

const isOpen1CModalEnterEmailAndPassword = ref(false)
const isOpen1CModalLoading = ref(false)
const isOpen1CModalNotFound = ref(false)
const status = ref<TStatus>('integrated')
const checkInterval = ref()

const goBack = () => {
  router.push('/products')
}

const openModalEnterEmailAndPassword = () => {
  isOpen1CModalEnterEmailAndPassword.value = true
}

const closeModalEnterEmailAndPassword = () => {
  isOpen1CModalEnterEmailAndPassword.value = false
}

const closeModalLoading = () => {
  isOpen1CModalLoading.value = false
}

const openModalLoading = () => {
  isOpen1CModalLoading.value = true
}

const close1CModalNotFound = () => {
  isOpen1CModalNotFound.value = false
}

const openModalNotFound = () => {
  isOpen1CModalNotFound.value = true
}

const isUpdateStatus = ref<boolean>(true)

const startLoading = async () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const companyId = user?.company_id
  if (companyId?.length) {
    OneCAPI.checkStatus(companyId).then((res) => {
      status.value = res
      console.log('status', status.value)
    })

    if (status.value === 'started') {
      openModalLoading()
    }

    if (status.value === 'finished') {
      await router.push('/1c-table')
    }

    if (isUpdateStatus.value) {
      checkInterval.value = setInterval(async () => {
        OneCAPI.checkStatus(companyId)
          .then((res) => {
            status.value = res
            if (status.value === 'finished') {
              router.push('/1c-table')
            }
            if (status.value === 'started') {
              closeModalEnterEmailAndPassword()
              openModalLoading()
            }
          })
          .catch((err) => {
            if (err.code != 'ERR_NETWORK' && err.code != 'ERR_BAD_REQUEST') {
              openModalNotFound()
            }
          })
      }, 5000)
    }
  }
}

onMounted(() => {
  startLoading()
})

onUnmounted(() => {
  isUpdateStatus.value = false
  clearInterval(checkInterval.value)
})

const route = useRoute()
</script>

<template>
  <div class="OneCFAQ">
    <OneCHeader
      type="Обмен данными с 1С"
      description="Управление торговлей, редакция 11 • Пользователь 1С с правами администратора"
      @goBack="goBack"
    />
    <FaqData @openModalEnterEmailAndPassword="openModalEnterEmailAndPassword" />
    <OneCFooter
      type="faq"
      @openModalEnterEmailAndPassword="openModalEnterEmailAndPassword"
    />
  </div>
  <OneCModalEnterEmailAndPassword
    :open="isOpen1CModalEnterEmailAndPassword"
    @close="closeModalEnterEmailAndPassword"
    @close1CModalEnterEmailAndPassword="closeModalEnterEmailAndPassword"
  />
  <OneCModalLoading
    :open="isOpen1CModalLoading"
    @close1CModalLoading="closeModalLoading"
  />
  <OneCModalNotFound
    :open="isOpen1CModalNotFound"
    @close1CModalNotFound="close1CModalNotFound"
  />
</template>

<style scoped lang="scss">
.OneCFAQ {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
