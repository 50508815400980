<script setup lang="ts">


interface Props {
  title: string
  subtitle?: string
}

const props = defineProps<Props>()
</script>

<template>
  <div class="tooltip-title-wrapper">
    <div class="toolbar-title">{{ props?.title }}</div>
    <div v-if="props?.subtitle" class="toolbar-subtitle">
      {{ props?.subtitle }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar-title-wrapper {
  display: flex;
  flex-direction: column;
}
.toolbar-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
}

.toolbar-subtitle {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
