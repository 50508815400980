<script lang="ts" setup>


const props = defineProps({
  fill: {
    type: String,
  },
})
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00015 18.9998C4.71681 18.9998 4.45848 18.9124 4.22515 18.7378C3.99181 18.5624 3.83348 18.3331 3.75015 18.0498L0.950145 7.94976C0.866812 7.71642 0.904145 7.49976 1.06215 7.29976C1.22081 7.09976 1.43348 6.99976 1.70015 6.99976H6.75015L11.1501 0.449756C11.2335 0.316423 11.3501 0.208089 11.5001 0.124756C11.6501 0.0414225 11.8085 -0.000244141 11.9751 -0.000244141C12.1418 -0.000244141 12.3001 0.0414225 12.4501 0.124756C12.6001 0.208089 12.7168 0.316423 12.8001 0.449756L17.2001 6.99976H22.3001C22.5668 6.99976 22.7795 7.09976 22.9381 7.29976C23.0961 7.49976 23.1335 7.71642 23.0501 7.94976L20.2501 18.0498C20.1668 18.3331 20.0085 18.5624 19.7751 18.7378C19.5418 18.9124 19.2835 18.9998 19.0001 18.9998H5.00015ZM12.0001 14.9998C12.5501 14.9998 13.0211 14.8041 13.4131 14.4128C13.8045 14.0208 14.0001 13.5498 14.0001 12.9998C14.0001 12.4498 13.8045 11.9788 13.4131 11.5868C13.0211 11.1954 12.5501 10.9998 12.0001 10.9998C11.4501 10.9998 10.9795 11.1954 10.5881 11.5868C10.1961 11.9788 10.0001 12.4498 10.0001 12.9998C10.0001 13.5498 10.1961 14.0208 10.5881 14.4128C10.9795 14.8041 11.4501 14.9998 12.0001 14.9998ZM9.17515 6.99976H14.8001L11.9751 2.79976L9.17515 6.99976Z"
      :fill="props.fill"
    />
  </svg>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
</style>
