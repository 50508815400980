import {createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw,} from 'vue-router'
import {useCookies} from 'vue3-cookies'
import {useJWTDecode} from '@/composables/useJWTDecode'
import {useStore} from '@/store'
import {clearCookies} from '@/helpers/scripts'
import OrderCreatedView from "@/views/OrderCreatedView.vue";
import OneCTable from "@/views/1C/views/1CTable/1CTable.vue";
import OneCFAQ from "@/views/1C/views/1CFAQ/1CFAQ.vue";
import ChatView from "@/views/ChatView.vue";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/Register/RegisterView.vue";
import InviteRegisterView from "@/views/InviteRegister/InviteRegisterView.vue";
import DocumentStep from "@/views/DocumentStep.vue";
import RecoveryView from "@/views/RecoveryView.vue";
import ConfirmEmailView from "@/views/Register/ConfirmEmailView.vue";
import FarmerHome from "@/views/Farmer/FarmerHome.vue";
import EditProductView from "@/views/Farmer/EditProductView.vue";
import AddProductView from "@/views/Farmer/AddProduct/AddProductView.vue";
import EditSpecialPrices from "@/views/Farmer/EditSpecialPrices.vue";
import EditPriceOptions from "@/views/Farmer/EditPriceOptions.vue";
import CartView from "@/views/Customer/CartView.vue";
import VerificationView from "@/views/VerificationView.vue";
import CustomerHistory from "@/views/Customer/CustomerHistory.vue";
import FarmerHistory from "@/views/Farmer/FarmerHistory.vue";
import ProfileView from "@/views/ProfileView.vue";
import ProductsCatalog from "@/views/ProductsCatalog.vue";
import MyAccount from "@/views/MyAccount.vue";
import ProductCard from "@/components/Customer/ProductCard.vue";
import FarmCardView from "@/views/Customer/FarmCardView.vue";
import CustomerCatalog from "@/views/Customer/CustomerCatalog/CustomerCatalog.vue";
import VerificationConfirmView from "@/views/VerificationConfirmView.vue";
import ReductionsView from "@/views/Reductions/ReductionsView.vue";
import CustomerCreateReductionView from "@/views/Customer/CustomerCreateReductionView.vue";
import ReductionView from "@/views/Reductions/ReductionView.vue";
import AuctionsView from "@/views/Auctions/AuctionsView.vue";
import FarmerCreateAuctionView from "@/views/Farmer/FarmerCreateАuctionView.vue";
import AuctionView from "@/views/Auctions/AuctionView.vue";
import RequestsView from "@/views/RequestsView.vue";
import CustomerRequestView from "@/views/Customer/CustomerRequestView/CustomerRequestView.vue";
import FarmerResponse from "@/views/FarmerResponse.vue";
import ProductResponseView from "@/views/Farmer/ProductResponseView.vue";
import CustomerCreateRequestView from "@/views/Customer/CustomerCreateRequestView.vue";
import PartnersView from "@/views/PartnersView.vue";
import PartnerView from "@/views/PartnerView.vue";
import UserListView from "@/views/Admin/UserListView.vue";
import UserView from "@/views/Admin/UserView/UserView.vue";
import OrderListView from "@/views/Admin/OrderListView.vue";
import OrderInfo from "@/views/Admin/OrderView/OrderInfo.vue";
import MailUsed from "@/views/Errors/MailUsed.vue";
import ErrorOccured from "@/views/Errors/ErrorOccured.vue";
import NoCompany from "@/views/Errors/NoCompany.vue";
import InviteExpired from "@/views/Errors/InviteExpired.vue";
import RequestOrderCompositionView from "@/views/Customer/RequestOrderComposition/RequestOrderCompositionView.vue";
import CreateOrderView from "@/views/Customer/CreateOrder/CreateOrderView.vue";

const { cookies } = useCookies()
const { getCoreCookie } = useJWTDecode()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/order-created',
    name: 'Заказ успешно создан',
    component: OrderCreatedView,
  },
  {
    path: '/1c-table',
    name: '1C table',
    component: OneCTable,
    props: true,
  },
  {
    path: '/1c-faq',
    name: '1C FAQ',
    component: OneCFAQ,
    props: true,
  },
  {
    path: '/chat/:id',
    name: 'Конкретный чат',
    component: ChatView,
  },
  {
    path: '/chat',
    name: 'Чат',
    component: ChatView,
  },
  {
    path: '/',
    name: 'Главная',
    component: HomeView,
  },
  {
    path: '/sign-in',
    name: 'Авторизация',
    component: LoginView,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register',
    name: 'Регистрация',
    component: RegisterView,
    meta: {
      layout: 'auth',
    },
  },

  {
    path: '/invite-register',
    name: 'Регистрация по инвайту',
    component: InviteRegisterView,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register-documents',
    name: 'Регистрация Документов',
    component: DocumentStep,
  },
  {
    path: '/recovery',
    name: 'Сброс пароля',
    component: RecoveryView,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/confirm-email',
    name: 'Подтверждение почты',
    component: ConfirmEmailView,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/products',
    name: 'Мои товары',
    component: FarmerHome,
  },

  {
    path: '/edit-product/:id',
    name: 'Редактирование товара',
    component: EditProductView,
  },
  {
    path: '/add-product',
    name: 'Добавление товара',
    component: AddProductView,
  },
  {
    path: '/edit-special-prices/:id',
    name: 'Редактирование спеццен',
    component: EditSpecialPrices,
  },
  {
    path: '/edit-price-options/:id',
    name: 'Редактирование списка цен',
    component: EditPriceOptions,
  },
  {
    path: '/cart',
    name: 'Корзина',
    component: CartView,
  },
  {
    path: '/verification',
    name: 'Верификация',
    component: VerificationView,
  },

  //  TODO переименовать роуты для профиля покупателя и поставщика тут и везде
  {
    // История заказов для покупателя
    path: '/profile',
    name: 'Покупатель',
    component: CustomerHistory
  },
  {
    // История заказов для поставщика
    path: '/home',
    name: 'Поставщик',
    component: FarmerHistory
  },
  {
    // История заказов
    path: '/history',
    name: 'История заказов',
    component: ProfileView,
  },
  {
    // Каталог товаров
    path: '/products-catalog',
    name: 'products-catalog',
    component: ProductsCatalog,
    props: true,
  },
  {
    // История заказ
    path: '/history/:id',
    name: 'История заказ',
    component: ProfileView,
  },
  {
    // Данные аккаунта. Общий компонент
    path: '/account',
    name: 'Мои данные',
    component: MyAccount,
  },
  {
    path: '/product/:id',
    name: 'Товар',
    component: ProductCard,
    props: true,
  },
  {
    path: '/farmer-product/:id',
    name: 'farmer-product',
    component: FarmCardView,
    props: true,
  },
  {
    path: '/catalog/:id',
    name: 'catalog',
    component: CustomerCatalog,
    props: true,
  },
  {
    path: '/verification-confirm',
    name: 'verification-confirm',
    component: VerificationConfirmView,
    props: true,
  },
  {
    path: '/reductions',
    name: 'Торги на покупку',
    component: ReductionsView,
  },
  {
    path: '/create-reduction',
    name: 'Создание торга на покупку',
    component: CustomerCreateReductionView,
  },
  {
    path: '/reductions/:id',
    name: 'Конкретный торг на покупку',
    component: ReductionView,
  },
  {
    path: '/auctions',
    name: 'Торги на продажу',
    component: AuctionsView,
  },
  {
    path: '/create-auction',
    name: 'Создание торга на продажу',
    component: FarmerCreateAuctionView,
  },
  {
    path: '/auctions/:id',
    name: 'Торг на продажу',
    component: AuctionView,
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsView,
    props: true,
  },
  {
    path: '/requests/:id',
    name: 'request',
    component: CustomerRequestView,
    props: true,
  },
  {
    path: '/response/:id',
    name: 'response',
    component: FarmerResponse,
    props: true,
  },
  {
    path: '/response/:id/:productAnswerId',
    name: 'responseProductAnswer',
    component: ProductResponseView,
    props: true,
  },
  {
    path: '/create-request',
    name: 'create-request',
    component: CustomerCreateRequestView,
    props: true,
  },
  {
    path: '/partners',
    name: 'partners',
    component: PartnersView,
    props: true,
  },
  {
    path: '/partners/:id',
    name: 'partner',
    component: PartnerView,
    props: true,
  },
  // -- Админка --
  {
    path: '/admin/users',
    name: 'Пользователи',
    component: UserListView,
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/users/:id',
    name: 'Пользователь',
    component: UserView,
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/orders',
    name: 'Заказы',
    component: OrderListView,
    meta: {
      layout: 'admin',
    },
  },
  {
    path: '/admin/orders/:id',
    name: 'Заказ',
    component: OrderInfo,
    meta: {
      layout: 'admin',
    },
  },
  // Ошибки
  {
    path: '/mail-used',
    name: 'mailUsed',
    component: MailUsed,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/error-occured',
    name: 'errorOccured',
    component: ErrorOccured,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/no-company',
    name: 'noCompany',
    component: NoCompany,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/invite-expired',
    name: 'inviteExpired',
    component: InviteExpired,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/requests/:requestId/:recipientId',
    name: 'requestOrderComposition',
    component: RequestOrderCompositionView,
  },
  {
    path: '/create-order',
    name: 'createOrder',
    component: CreateOrderView,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

const startRoutesNames = [
  'Регистрация',
  'Авторизация',
  'Сброс пароля',
  'Подтверждение почты',
]

const checkUnLogin = async (to: RouteLocationNormalized) => {
  if (
    !getCoreCookie() &&
    to.fullPath !== '/sign-in' &&
    to.fullPath !== '/confirm-email' &&
    to.fullPath !== '/mail-used' &&
    to.fullPath !== '/error-occured' &&
    to.fullPath !== '/no-company' &&
    to.fullPath !== '/invite-expired' &&
    to.fullPath !== '/' &&
    to.path !== '/invite-register' &&
    to.fullPath !== '/invite-register'
  ) {
    to.fullPath = '/sign-in'
    await router.push('/')
  }
}

const logout = async () => {
  localStorage.clear()
  await clearCookies()
}

router.beforeEach(async (to, from) => {

  if (!startRoutesNames.includes(<string>to.name)) {
    await checkUnLogin(to)

    if (getCoreCookie()) {
      cookies.set('preRegisterData', getCoreCookie(), '365d', '')
    }
  }
})

router.beforeResolve((from, to) => {
  if (from.path.includes('cart') && to.path !== '/create-order') {
    const { setPreviousRoute } = useStore()
    setPreviousRoute(to.path)
  }
})
export default router
