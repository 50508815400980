import { APIModels } from '@/types/enums'
import { IPriceRequestOrder } from '@/types/interfaces'
import { CreateOrderType } from '@/types/types'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class CartAPIModel {
  async createCart(farmer_product_id: number, quantity: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.create,
      data: { attributes: { farmer_product_id, quantity }},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getGroupedItems(boolean = false, operator: 'OR' | 'AND'): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.getGroupedItems,
      data: {
        withs: ['cartItems.farmerProduct.priceOptions'],
        attributes: { is_zero_quantity: boolean, is_deleted: boolean, farmer_product_operator: operator }
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getCartDataStore(
    page = 1,
    perPage = 100,
    withs: string[] = ['user'],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.GetItems,
      data: { withs, pagination: { per_page: perPage, page } },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async deleteCartItem(id: number | string): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.delete,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async checkItems(): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.checkItems,
      data: {},
      headers: { Authorization: cookies.get('core')},
    })
  }

  // оформление заказа из корзины
  async createOrderFromCart(orderData: CreateOrderType): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.createOrders,
      data: { attributes: orderData },
      headers: { Authorization: cookies.get('core')},
    })
  }

  // оформление заказа из аукциона/редукциона
  async createOtherOrder(attributes: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.createOrder,
      data: { attributes },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async createPriceRequestOrder(orderData: IPriceRequestOrder): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.createOrder,
      data: { attributes: orderData },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async deleteManyCartItem(ids: string[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.deleteMany,
      data: { ids },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateCartItem(
    id: number | string,
    quantity: number | string,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Cart,
      method: EgalMethods.update,
      data: { attributes: { id, quantity }},
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const CartAPI = new CartAPIModel()
export { CartAPI }
