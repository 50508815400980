import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class FarmerProductAPIModel {
  async getProduct(
    filters: string[][] = [],
    page = 1,
    withs: string[] = ['farmer', 'farmer.addresses'],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      responseType: 'items_data',
      data: {
        withs,
        filter: filters,
        pagination: { per_page: 15, page },
        order: [['fullname', 'asc']]
      },
      headers: { Authorization: cookies.get('core')},
    })
  }
  async getProductsCustom(
    filters: any = [],
    withs: string[] = ['farmer', 'farmer.addresses'],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      responseType: 'items_data',
      data: {
        withs,
        filter: filters,
        order: [['fullname', 'asc']]
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProductsWithoutWrapper(
    filters: (string | boolean)[][] = [],
    page: number,
    perPage = 100,
    withs = ['product'],
  ): Promise<any> {
    const userStr = localStorage.getItem('user')
    const companyId = userStr ? JSON.parse(userStr)?.company_id : null
    const filterData = [
      ['farmer_id', 'eq', companyId],
      'AND',
      ['is_deleted', 'eq', false],
    ]

    if (filters?.length) {
      filterData.push('AND')
      filterData.push(...filters)
    }

    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      data: {
        withs,
        filter: filterData,
        order: [['fullname', 'asc']],
        pagination: { per_page: perPage, page },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProducts(filters: any = [], page = 1, perPage = 10): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getProductsWithoutWrapper(filters, page, perPage)
        .then((res) => {
          return resolve(res.items)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getPriceOptions(
    filters: (string | number)[][] = [],
    orders: string[][] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.PriceOption,
      method: EgalMethods.GetItems,
      data: {
        order: orders,
        filter: filters,
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getProductsByCategory(
    filters: string[][] = [],
    page: number,
    farmer_id: string,
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      data: {
        withs: ['product', 'farmer', ...withs],
        filter: [
          ['farmer_id', 'eq', farmer_id],
            'AND',
          ['is_deleted', 'eq', false],
            'AND',
          ...filters,
        ],
        order: [['fullname', 'asc']],
        pagination: { per_page: perPage, page },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFilteredProducts(
    filters: any = [],
    page: number,
    perPage = 10,
    withs: string[] = ['farmer', 'farmer.farmer_products', 'farmer.addresses'],
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItems,
      data: {
        withs,
        filter: [
          ...filters,
            'AND',
          ['is_deleted', 'eq', false],
            'AND',
          ['farmer.is_active', 'eq', true],
        ],
        order: [['fullname', 'asc']],
        pagination: { per_page: perPage, page },
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getFarmersWithFilter(filter: any, withs: string[] = []): Promise<any> {
    return RequestManager.request({
      model: APIModels.Farmer,
      method: EgalMethods.getFarmersWithFilter,
      data: {
        filter,
        withs: ['farmer']
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async createProduct(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.create,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async updateProduct(data: any, isDeleteUpdate?: boolean): Promise<any> {
    if (data.image_url === 'NULL') {
      data = { ...data, image_body: 'NULL' }
    } else {
      data = {
        ...data,
        image_body: data.image_url.split(',')[1],
        image_url: '',
      }
    }

    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async deleteProduct(ids: number[]): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.deleteMany,
      data: { ids },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getItem(id: string | number, withs: string[] = []): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.GetItem,
      data: { id: Number(id), order: [['created_at', 'desc']], withs },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async update(data: any): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.update,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async delete(id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.delete,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async getAuctionProducts(): Promise<any> {
    return RequestManager.request({
      model: APIModels.FarmerProduct,
      method: EgalMethods.getAuctionProducts,
      data: {},
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const FarmerProductAPI = new FarmerProductAPIModel()
export { FarmerProductAPI }
