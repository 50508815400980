<script setup lang="ts">
import { IReduction, IReductionProductRequest } from '@/types/interfaces'
import { ref, computed, onMounted } from 'vue'
import {
  Button,
  Flex,
  Space,
  Table,
  TypographyText as TpText,
} from 'ant-design-vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import FarmerNameWithAddress from '@/components/Farmer/FarmerNameWithAddress.vue'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { priceWithoutVat, roundNumber } from '@/helpers/scripts'

interface IProps {
  reductionData: IReduction | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['showFarmerDetails'])
const expandedRowKeys = ref<string[]>([])
const partners = ref([])

const columns = [
  {
    title: 'Товар',
    key: 'product',
    width: '61%',
  },
  {
    title: 'Лучшее предложение',
    key: 'best_offer',
  },
]

const nestedColumns = [
  {
    title: 'Рейтинг',
    key: 'rating',
    width: '7%',
  },
  {
    title: 'Поставщики',
    key: 'farmer',
    width: '39%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'НДС',
    key: 'vat',
    width: '7%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const getProductParameters = (product: IReductionProductRequest) => {
  const result: string[] = []
  result.push(
    `Объем ${product.volume} ${product.product?.measurement_unit_id ?? 'кг'}`,
  )

  result.push(`Срок хранения ${product.remaining_shelf_life}%`)
  product.is_sample_needed &&
    result.push(
      `Пробный образец ${product.needed_samples_number ? `${product.needed_samples_number} ${product.product?.measurement_unit_id ?? 'кг'}` : ''}`,
    )
  return result
}

const calculateBetSum = (reductionProductRequest: IReductionProductRequest) => {
  const vat = reductionProductRequest.best_bet?.vat ?? 0
  const price = reductionProductRequest.best_bet?.price_per_unit ?? 0
  return +(Number(price) * Number(reductionProductRequest.volume)).toFixed(2)
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const filteredProductRequests = computed(() => {
  if (!props.reductionData?.reduction_product_requests) {
    return []
  }
  return props.reductionData?.reduction_product_requests
    .filter((currentRequest) => currentRequest.best_bet)
    .sort((productA, productB) =>
      productA.product_name.localeCompare(productB.product_name),
    )
})

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const fillMeasurementUnitId = (measurement_unit_id: string, name: string) => {
  if (measurement_unit_id === null) {
    const productText = name
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      measurement_unit_id = lastChar
    } else return '-'
    return measurement_unit_id
  } else return measurement_unit_id
}

onMounted(async () => {
  await getMyPartners()
})
</script>

<template>
  <Table
    :columns="columns"
    :data-source="filteredProductRequests ?? []"
    :pagination="false"
    :expandedRowKeys="expandedRowKeys"
    :row-key="(record) => String(record.id)"
    :showExpandColumn="false"
    class="edged-border-table"
    v-if="filteredProductRequests?.length"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'product'">
        <ProductWithProperties
          :volume="record?.volume"
          :remaining_shelf_life="record.remaining_shelf_life"
          :measurement_unit_id="record.product?.measurement_unit_id"
          :name="record.product_name"
          :is_sample_needed="!!record.is_sample_needed"
          :needed_samples_number="record.needed_samples_number"
        />
      </template>
      <template v-if="column.key === 'best_offer'">
        <template v-if="record.best_bet">
          <Flex justify="space-between" align="center">
            <Space direction="vertical" :size="0">
              <TpText strong>{{
                `${roundNumber(calculateBetSum(record))} ₽`
              }}</TpText>
              <span class="farmer-name">{{
                `${record.best_bet.reduction_participant.farmer.legal_form} ${record.best_bet.reduction_participant.farmer.name}`
              }}</span>
            </Space>
            <Button type="link" @click="changeExpandedRow(record.id)">
              <template #icon>
                <DownOutlined
                  v-if="!expandedRowKeys.includes(String(record.id))"
                />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </Flex>
        </template>
        <template v-else><TpText type="secondary">Нет ставок</TpText></template>
      </template>
    </template>

    <template #expandedRowRender="{ record }">
      <Table
        :columns="nestedColumns"
        :data-source="record.reduction_product_answers"
        :pagination="false"
        bordered
        class="nested-table"
      >
        <template #bodyCell="{ column, record: answer, index }">
          <template v-if="column.key === 'rating'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'farmer'">
            <Space direction="vertical" :size="8">
              <FarmerNameWithAddress
                :farmer="answer.reduction_participant.farmer"
                :is-partner="
                  partners.find(
                    (el: any) =>
                      el.company_id === answer.reduction_participant.farmer?.id,
                  )
                "
              />
              <Button
                type="link"
                class="additional-btn"
                v-if="answer.reduction_participant.comment"
                @click="emit('showFarmerDetails', answer.reduction_participant)"
              >
                <template #icon><InfoCircleOutlined /></template>
                Дополнительно
              </Button>
            </Space>
          </template>
          <template v-if="column.key === 'price_without_vat'">
            <CellDifData
              :new-data="
                roundNumber(priceWithoutVat(answer.price_per_unit, answer.vat))
              "
              :prev-data="
                roundNumber(
                  priceWithoutVat(
                    answer.price_per_unit_first,
                    answer.vat_first,
                  ),
                )
              "
              :additional-info="`₽/${fillMeasurementUnitId(record.product.measurement_unit_id, record.product_name)}`"
            />
          </template>
          <template v-if="column.key === 'vat'">
            <CellDifData
              :new-data="answer.vat"
              :prev-data="answer.vat_first"
              additional-info="%"
            />
          </template>
          <template v-if="column.key === 'price_with_vat'">
            <CellDifData
              :new-data="roundNumber(answer.price_per_unit)"
              :prev-data="roundNumber(answer.price_per_unit_first)"
              :additional-info="`₽/${fillMeasurementUnitId(record.product.measurement_unit_id, record.product_name)}`"
            />
          </template>
          <template v-if="column.key === 'amount'">
            <Flex justify="space-between">
              <CellDifData
                :new-data="
                  roundNumber(answer.price_per_unit * Number(record.volume))
                "
                :prev-data="
                  roundNumber(
                    answer.price_per_unit_first * Number(record.volume),
                  )
                "
                additional-info="₽"
              />
            </Flex>
          </template>
        </template>
      </Table>
    </template>
  </Table>
  <div v-else class="empty-view-wrapper">
    <EmptyView
      :is-show-button="false"
      title="Ставок пока что нет"
      description="Они будут отображаться здесь"
    />
  </div>
</template>

<style scoped lang="scss">
.farmer-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000a6;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}
:deep(.ant-table-expanded-row > .ant-table-cell) {
  padding-left: 0 !important;
}
.nested-table {
  :deep(.ant-table) {
    border-radius: 0 !important;
  }
  :deep(.ant-table-row .ant-table-cell:first-child) {
    text-align: center;
  }
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}

.empty-view-wrapper {
  padding: 72px 0;
}
</style>
