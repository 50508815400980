<script lang="ts" setup>
import {
  IFarmer,
  IProduct,
  IReduction,
  IReductionParticipant,
  IReductionProductAnswer,
  IReductionProductRequest,
} from '@/types/interfaces'
import { computed, ref, onMounted, defineProps } from 'vue'
import {
  Button,
  Flex,
  Space,
  Table,
  TypographyText as TpText,
} from 'ant-design-vue'
import {
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from '@ant-design/icons-vue'
import CellDifData from '@/views/Farmer/reductions/reduction/CellDifData.vue'
import FarmerNameWithAddress from '@/components/Farmer/FarmerNameWithAddress.vue'
import EmptyView from '@/components/EmptyView/EmptyView.vue'
import PartnershipTooltip from '@/components/Tooltip/PartnershipTooltip.vue'
import { PartnersAPI } from '@/api/PartnersAPI'
import NotIncludedProductsModal from '@/components/Modal/NotIncludedProductsModal.vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import ConditionsModal from '@/components/Modal/conditionsModal.vue'
import ConfirmModal from '@/components/Modal/confirmModal.vue'
import { CartAPI } from '@/api/CartAPI'
import router from '@/router'
import { AddressAPI } from '@/api/AddressAPI'
import dayjs from 'dayjs'
import NotAvailableProductsModal from '@/components/Modal/NotAvailableProductsModal.vue'
import { IAddress } from '@/types/IAddress'
import ProductWithProperties from '@/components/ProductWithProperties/ProductWithProperties.vue'
import { priceWithoutVat, roundNumber } from '@/helpers/scripts'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import { fillMeasurementUnitIdCommon } from '@/helpers/scripts'
import CartComplete from "@/components/Cart/CartComplete.vue";

type TProduct = {
  created_at?: string
  customer_id?: number
  id: number
  is_deleted?: boolean
  is_sample_needed?: boolean
  measurement_unit_id?: string | null
  needed_samples_number?: number | null
  price_per_unit?: string | null
  price_per_unit_first?: string | null
  product?: IProduct
  product_id?: number
  product_name?: string
  reduction_participant?: {
    answer_status: string
    comment: string
    created_at: string
    farmer: IFarmer
    farmer_id: number
    id: number
    reduction_id: number
    reduction_name: string
    updated_at: string
  }
  reduction_participant_id?: number
  reduction_product_request_id?: number
  remaining_shelf_life?: string | number | null
  updated_at?: string
  vat?: number | null
  vat_first?: number | null
  volume?: string | null
  quantity?: string | null
  unit_price?: string | null
  vat_percent?: number | null
  farmer_product_id?: number
}

interface IAdvancedParticipant extends IReductionParticipant {
  bets_count: number
  bets_amount: number
}

interface IAdvancedProductAnswer extends IReductionProductAnswer {
  measurement_unit_id: string
  is_sample_needed: boolean
  needed_samples_number: string | number | null
  product_name: string
  volume: string
  remaining_shelf_life: number
}

interface IProps {
  reductionData: IReduction | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['showFarmerDetails', 'refetch'])

const openNotAvailable = ref(false)
const openNotIncluded = ref(false)
const openConfirmModal = ref(false)
const openConditionsModal = ref(false)
const priorityPay = ref('')
const priorityDelivery = ref('')
const selectedFarmer = ref(0)
const farmerCanDeliver = ref<boolean>(false)
const answerProducts = ref()
const notIncludedProducts = ref()
const givenAddress = ref<IAddress>()
const addressOffers = ref({})

const expandedRowKeys = ref<string[]>([])

const modalStore = useModalStore()
const refetch = () => {
  emit('refetch')
}

const openHandleErrorModal = () => {
  modalStore.openModal(ModalKeys.HandleError, {
    title: 'Не удалось создать заказ',
    message:
      'Что-то пошло не так. Ваш заказ не создан, пожалуйста, попробуйте ещё раз. Возможно в каталоге поставщика больше нет необходимых товаров.',
    callback: refetch,
  })
}

const getAdditionalFields = (participantId: number) => {
  return props.reductionData?.reduction_product_requests?.reduce(
    (accum, currentRequest) => {
      currentRequest?.reduction_product_answers.forEach((currentAnswer) => {
        if (currentAnswer.reduction_participant_id === participantId) {
          accum.bets_count += 1
          accum.bets_amount += +(
            Number(currentAnswer?.price_per_unit) *
            Number(currentRequest.volume)
          ).toFixed(2)
        }
      })

      return accum
    },
    { bets_count: 0, bets_amount: 0 },
  )
}

const getParticipantAnswers = (
  participantId: number,
): IAdvancedProductAnswer[] => {
  const answers: IAdvancedProductAnswer[] = []

  const getAdvancedFields = ({
    product_name,
    volume,
    remaining_shelf_life,
    is_sample_needed,
    needed_samples_number,
    product,
  }: IReductionProductRequest) => ({
    product_name,
    volume,
    remaining_shelf_life,
    is_sample_needed,
    needed_samples_number,
    product,
  })

  props.reductionData?.reduction_product_requests?.forEach((currentRequest) => {
    currentRequest?.reduction_product_answers.forEach((currentAnswer) => {
      if (currentAnswer.reduction_participant_id === participantId) {
        answers.push({
          ...currentAnswer,
          ...getAdvancedFields(currentRequest),
          measurement_unit_id:
            currentRequest?.product?.measurement_unit_id ?? '',
        })
      }
    })
  })

  return answers
}

const changeExpandedRow = (rowIndex: number) => {
  if (expandedRowKeys.value.includes(String(rowIndex))) {
    expandedRowKeys.value = expandedRowKeys.value.filter(
      (currentValue) => currentValue !== String(rowIndex),
    )
  } else {
    expandedRowKeys.value.push(String(rowIndex))
  }
}

const advancedParticipants: IAdvancedParticipant[] = computed(() => {
  return (
    props.reductionData?.merged_recipients
      ?.map((participant) => ({
        ...participant,
        ...getAdditionalFields(participant.id),
      }))
      .filter((item) => item.bets_count) ?? []
  )
})

const columns = [
  {
    title: 'Рейтинг',
    key: 'rate',
    width: '7%',
  },
  {
    title: 'Поставщик',
    key: 'farmer',
    width: '48%',
  },
  {
    title: 'Количество ставок на позиции',
    key: 'bets_count',
    width: '14%',
  },
  {
    title: 'Сумма закупки',
    key: 'bets_amount',
  },
]

const nestedColumns = [
  {
    title: 'Товары',
    key: 'products',
    width: '46%',
  },
  {
    title: 'Цена без НДС',
    key: 'price_without_vat',
    width: '13%',
  },
  {
    title: 'НДС',
    key: 'vat',
    width: '6%',
  },
  {
    title: 'Цена с НДС',
    key: 'price_with_vat',
    width: '14%',
  },
  {
    title: 'Сумма',
    key: 'amount',
  },
]

const isShowFarmerDetails = ref(false)
const selectedParticipant = ref<IReductionParticipant | null>(null)
const showFarmerDetails = (value: IReductionParticipant) => {
  selectedParticipant.value = value
  isShowFarmerDetails.value = true
}

const partners = ref([])

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const showModal = async (
  answer_id: number,
  farmer_id: number,
  can_deliver: boolean,
) => {
  selectedFarmer.value = farmer_id
  farmerCanDeliver.value = can_deliver
  notIncludedProducts.value = []
  answerProducts.value = getParticipantAnswers(answer_id)

  if (!answerProducts.value || answerProducts.value.length === 0) {
    console.error('No answers found for the given answer_id')
    return
  }

  let product_ids = answerProducts.value.map(
    (product: TProduct) => product.product_id,
  )
  product_ids.sort((a, b) => a - b)

  let availableProducts = [] as any[]

  answerProducts.value.forEach((product: TProduct) => {
    if (product.is_deleted) {
      // if product is deleted
      notIncludedProducts.value.push(product.product)
    } else {
      availableProducts.push({
        quantity: product?.volume,
        unit_price: product?.price_per_unit,
        vat_percent: product?.vat,
        farmer_product_id: null,
        product_id: product?.product_id,
      })
    }
  })

  try {
    const res = await FarmerProductAPI.getProductsCustom([
      ['farmer_id', 'eq', farmer_id],
      'AND',
      ['is_deleted', 'eq', false],
      'AND',
      ['product_id', 'in', product_ids],
    ])

    if (res.length > 0) {
      res.sort((a: { id: number }, b: { id: number }) => a.id - b.id)

      const productMap: Map<string | number, TProduct> = new Map(
        res.map((product: TProduct) => [product.product_id, product]),
      )

      product_ids?.forEach((id: number) => {
        if (productMap.has(id)) {
          const index = availableProducts.findIndex(
            (el: TProduct) => el.product_id === id,
          )

          if (availableProducts[index]) {
            availableProducts[index].farmer_product_id = productMap?.get(id)?.id
          }
        }
      })

      answerProducts.value = availableProducts

      if (notIncludedProducts.value.length > 0) {
        openNotIncluded.value = true
      } else {
        openConfirmModal.value = true
      }
    } else {
      openNotAvailable.value = true
    }
  } catch (error) {
    console.error('Error fetching products:', error)
  }
}

const findAddress = async (address: string | undefined) => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const companyId = user?.company_id
  if (companyId) {
    await AddressAPI.getAddresses(companyId, 'customer').then((res) => {
      addressOffers.value = res.items
      const addresses = res.items
      addresses?.forEach((item: IAddress) => {
        if (item.street == address) {
          givenAddress.value = item
        }
      })
    })
  }
}

const createOrder = async (
  delivery: string,
  payment: string,
  addressId: number,
) => {
  const orderDay = dayjs(new Date()).add(3, 'day').format('YYYY-MM-DD')
  const deliveryPay = props.reductionData?.is_delivery_included
    ? 'included'
    : 'excluded'

  await CartAPI.createOtherOrder({
    farmer_id: selectedFarmer.value,
    delivery_date: orderDay,
    delivery_method: delivery,
    delivery_payment: delivery == 'Самовывоз' ? undefined : deliveryPay, // Если самовывоз - delivery_payment не должен быть
    payment_type: payment,
    address: delivery == 'Самовывоз' ? undefined : addressId,
    need_unload: props.reductionData?.need_unload,
    is_payment_delayed: props.reductionData?.is_payment_delayed,
    order_positions: answerProducts.value,
  })
    .then(async (res) => {
      localStorage.setItem('lastCreatedOrderId', res.id)
      await router.push('/order-created')
    })
    .catch((e) => {
      if (e.status === 422) {
        openConditionsModal.value = false
        openHandleErrorModal()
      }
    })
}

const prioritizeCheck = () => {
  const redData = props.reductionData
  if (redData?.is_pay_cash && !redData?.is_pay_non_cash)
    priorityPay.value = 'cash'
  else if (!redData?.is_pay_cash && redData?.is_pay_non_cash)
    priorityPay.value = 'non_cash'
  if (redData.is_self_delivery && !redData.is_supplier_delivery)
    priorityDelivery.value = 'Самовывоз'
  else if (!redData.is_self_delivery && redData.is_supplier_delivery)
    priorityDelivery.value = 'Доставка фермером'
}

const continueNotIncludedModal = () => {
  openNotIncluded.value = false
  openConfirmModal.value = true
}

const continueConfirmModal = () => {
  openConfirmModal.value = false
  openConditionsModal.value = true
}

onMounted(async () => {
  await getMyPartners()
  await findAddress(props.reductionData?.delivery_address)
  prioritizeCheck()
})
</script>

<template>
  <NotAvailableProductsModal
    :open="openNotAvailable"
    @close="openNotAvailable = false"
  />
  <NotIncludedProductsModal
    :open="openNotIncluded"
    :products="notIncludedProducts"
    @continue="continueNotIncludedModal"
    @close="openNotIncluded = false"
  />
  <ConfirmModal
    :open="openConfirmModal"
    @continue="continueConfirmModal"
    @close="openConfirmModal = false"
    location="reduction"
  />
  <ConditionsModal
    :can-deliver="farmerCanDeliver"
    :priority-address-id="givenAddress?.id"
    :address-offers="addressOffers"
    :priority-pay="priorityPay"
    :priority-delivery="priorityDelivery"
    :open="openConditionsModal"
    @close="openConditionsModal = false"
    @continue="createOrder"
  />
  <Table
    :columns="columns"
    :expandedRowKeys="expandedRowKeys"
    :data-source="advancedParticipants"
    :row-key="(record) => String(record.id)"
    :pagination="false"
    :showExpandColumn="false"
    class="edged-border-table"
    v-if="advancedParticipants.length"
  >
    <template #expandedRowRender="{ record }">
      <Table
        :columns="nestedColumns"
        :data-source="getParticipantAnswers(record.id)"
        :pagination="false"
        bordered
        class="nested-table"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'products'">
            <Flex :gap="8">
              <Flex :gap="4" vertical>
                <ProductWithProperties
                  :volume="record?.volume"
                  :remaining_shelf_life="record.remaining_shelf_life"
                  :measurement_unit_id="
                    record.product?.measurement_unit_id ?? null
                  "
                  :name="record.product_name"
                  :is_sample_needed="record.is_sample_needed"
                  :needed_samples_number="record.needed_samples_number"
                />
              </Flex>
            </Flex>
          </template>
          <template v-if="column.key === 'price_without_vat'">
            <CellDifData
              :new-data="
                roundNumber(priceWithoutVat(record.price_per_unit, record.vat))
              "
              :prev-data="
                roundNumber(
                  priceWithoutVat(record.price_per_unit_first, record.vat),
                )
              "
             :additional-info="`₽/${fillMeasurementUnitIdCommon(
                record.product_name,
                record.product?.measurement_unit_id,
              )}`"
            />
          </template>
          <template v-if="column.key === 'vat'">
            <CellDifData
              :new-data="record.vat"
              :prev-data="record.vat_first"
              additional-info="%"
            />
          </template>
          <template v-if="column.key === 'price_with_vat'">
            <CellDifData
              :new-data="roundNumber(record.price_per_unit)"
              :prev-data="roundNumber(record.price_per_unit_first)"
              :additional-info="`₽/${fillMeasurementUnitIdCommon(
                record.product_name,
                record.product?.measurement_unit_id,
              )}`"
            />
          </template>
          <template v-if="column.key === 'amount'">
            <Flex justify="space-between">
              <CellDifData
                :new-data="roundNumber(record.price_per_unit * record.volume)"
                :prev-data="
                  roundNumber(record.price_per_unit_first * record.volume)
                "
                additional-info="₽"
              />
            </Flex>
          </template>
        </template>
      </Table>
    </template>
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'rate'">
        <Flex justify="center" align="center">{{ index + 1 }}</Flex>
      </template>
      <template v-if="column.key === 'farmer'">
        <Space direction="vertical" :size="8">
          <div class="farmer__name">
            <PartnershipTooltip
              v-if="
                partners.find((el: any) => el.company_id === record?.farmer?.id)
              "
              class="partnership"
            />
            <FarmerNameWithAddress :farmer="record.farmer" />
          </div>

          <Button
            type="link"
            class="additional-btn"
            v-if="record.comment"
            @click="emit('showFarmerDetails', record)"
          >
            <template #icon><InfoCircleOutlined /></template>
            Дополнительно
          </Button>
        </Space>
      </template>
      <template v-if="column.key === 'bets_count'">
        {{ record.bets_count }}
      </template>
      <template v-if="column.key === 'bets_amount'">
        <Flex justify="space-between" align="center">
          <TpText strong>{{ `${roundNumber(record.bets_amount)} ₽` }}</TpText>
          <div class="buttons">
            <Button v-if="reductionData?.status === 'closed'"
              type="primary"
              @click="
                showModal(
                  record.id,
                  record.farmer_id,
                  record.farmer.can_deliver,
                )
              "
            >
              Оформить заказ
            </Button>
            <Button type="link" @click="changeExpandedRow(record.id)">
              <template #icon>
                <DownOutlined
                  v-if="!expandedRowKeys.includes(String(record.id))"
                />
                <UpOutlined v-else />
              </template>
              Ставки
            </Button>
          </div>
        </Flex>
      </template>
    </template>
  </Table>
  <div class="empty-view-wrapper" v-else>
    <EmptyView
      :is-show-button="false"
      title="Ставок пока что нет"
      description="Они будут отображаться здесь"
    />
  </div>
</template>

<style lang="scss" scoped>
.partnership {
  padding-top: 10px;
}

.farmer__name {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
}

:deep(.ant-table-bordered) {
  margin-left: 0 !important;
}

:deep(.ant-table-expanded-row .ant-table-container) {
  padding-left: 0 !important;
  border-radius: 0 !important;
}

:deep(.ant-table-expanded-row .ant-table-content) {
  width: 100% !important;
}

.nested-table {
  :deep(.ant-table-tbody) {
    background-color: #fafafa;
  }
}

.edged-border-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}

.additional-btn {
  padding-right: 0;
  padding-left: 0;
}

.empty-view-wrapper {
  padding: 72px 0;
}

.buttons {
  display: flex;
  gap: 4px;
}
</style>
