<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RecipientStatus',
  props: {
    status: String,
  },
})
</script>

<template>
  <div class="recipient-status">
    <img src="./icons/success.svg" alt="icon" v-if="status === 'answered'" loading="lazy" />
    <img
      src="./icons/warning.svg"
      alt="icon"
			v-else-if="status !== 'refused'"
      loading="lazy"
    />
    <img src="./icons/error.svg" alt="icon" v-else loading="lazy" />
  </div>
</template>

<style lang="scss">
.recipient-status {
}
</style>
