<script setup lang="ts">
import CartComplete from "@/components/Cart/CartComplete.vue";

const lastCreatedOrderId = localStorage.getItem('lastCreatedOrderId')
</script>

<template>
  <div class="wrapper">
    <CartComplete
        v-if="!!lastCreatedOrderId"
        :lastOrderId="Number(lastCreatedOrderId)"
        description="Мы уведомили продавца о заказе, пожалуйста, дождитесь подтверждения с его стороны"
        title="Заказ успешно оформлен"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
