<script lang="ts" setup>
import { ref } from 'vue'
import { BankStatus, ProfileStatus } from '@/types/enums'
import StarLoader from '@/components/Loaders/StarLoader.vue'
import { ISuggestBank, ISuggestOrg } from '@/types/interfaces'
import DangerIcon from '@/components/Icons/DangerIcon.vue'
import { isNull } from '@/helpers/scripts'
import InfoTooltip from '@/components/Tooltip/InfoTooltip.vue'

interface Props {
  userData: any
  status: ProfileStatus
}

const props = defineProps<Props>()
const emit = defineEmits([''])

const isFetching = ref(false)
const userOrg = ref<ISuggestOrg>({ ...props.userData })
const userBank = ref<{ bic_status: string; bank_kpp: string } & ISuggestBank>({
  ...props.userData,
})
</script>

<template>
  <div class="jur-loader" v-if="isFetching">
    <StarLoader />
  </div>
  <div v-if="!isFetching && status === ProfileStatus.Verifying">
    <div class="jur-wrapper">
      <div class="card org-card">
        <div class="card-header">Данные организации</div>
        <InfoTooltip
          message="Как только всё проверим — отправим вам уведомление, чтобы подтвердить юридические данные"
          style="margin-top: 24px"
        />
        <div class="card-body">
          <div class="info-column-row">
            <div class="info-column">
              <div class="info-column-item">
                <p class="info-title">ИНН организации</p>
                {{ userOrg.tin }}
              </div>
            </div>

            <div class="info-column">
              <div class="info-column-item">
                <p class="info-title">БИК банка</p>
                {{ userBank.bic }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="jur-wrapper" v-else>
    <div class="card org-card">
      <div class="card-header">Данные организации</div>
      <div class="card-body">
        <div class="info-row">
          <p class="info-title">Наименование организации</p>
          {{ userOrg.legal_form }}
          {{ userOrg.name }}
        </div>
        <div class="info-row">
          <p class="info-title">Юридический адрес</p>
          {{ userOrg.legal_address }}
        </div>
        <div class="info-column-row">
          <div class="info-column">
            <div class="info-column-item">
              <p class="info-title">ИНН</p>
              {{ userOrg.tin }}
            </div>

            <div class="info-column-item">
              <p class="info-title">ОКПО</p>
              {{ isNull(userOrg.okpo) }}
            </div>
          </div>

          <div class="info-column">
            <div class="info-column-item">
              <p class="info-title">КПП</p>
              {{ userOrg.tax_registration_reason }}
            </div>
            <div class="info-column-item">
              <p class="info-title">ОГРН</p>
              {{ userOrg.bin }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card bank-card">
      <div class="card-header">Данные банка</div>
      <div class="card-body">
        <div class="info-row">
          <p class="info-title">Наименование банка</p>
          {{ userBank.bank }}
        </div>
        <div class="info-column-row">
          <div class="info-column">
            <div class="info-column-item">
              <p class="info-title">ИНН</p>
              {{ isNull(userBank.bank_tin) }}
            </div>

            <div class="info-column-item">
              <p class="info-title">БИК</p>
              {{ isNull(userBank.bic) }}
            </div>
            <div
              class="info-column-item"
              v-if="BankStatus[userBank.bic_status] !== BankStatus.ACTIVE"
            >
              <p class="info-title">Статус банка</p>
              <p class="status-row">
                <DangerIcon />
                {{
                  BankStatus[userBank.bic_status] === BankStatus.LIQUIDATED
                    ? 'Ликвидирован'
                    : BankStatus[userBank.bic_status]
                }}
              </p>
            </div>
          </div>

          <div class="info-column">
            <div class="info-column-item">
              <p class="info-title">Кор. счёт</p>
              {{ isNull(userBank.correspondent_account) }}
            </div>
            <div class="info-column-item">
              <p class="info-title">КПП</p>
              {{ isNull(userBank.bank_kpp) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import 'accountStyles';

.jur-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .card {
    padding: 48px;
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 24px;
    min-width: 608px;
    &-header {
      color: $gray-800;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    &-body {
      margin-top: 24px;
      display: flex;
      row-gap: 32px;
      flex-direction: column;
      .info {
        &-row {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          color: $gray-800;
          font-weight: 400;
          font-size: 16px;
          line-height: 135%;
        }
        &-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $gray-600;
          margin-bottom: 4px;
        }
        &-column-row {
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: start;
          column-gap: 32px;
        }
        &-column {
          display: flex;
          row-gap: 20px;
          flex-direction: column;
          &-item {
            .status-row {
              color: $danger;
              display: flex;
              column-gap: 9px;
              align-items: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 135%;
            }
          }
        }
      }
    }
  }
}
.jur-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
