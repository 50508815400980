<script setup lang="ts">
import { computed, h, ref } from 'vue'
import {
  DeleteOutlined,
  LoadingOutlined,
  PaperClipOutlined,
} from '@ant-design/icons-vue'
import { Space, Spin, Tooltip } from 'ant-design-vue'
import {
  companyDownloadOnClick,
  downloadOnClick,
  downloadPartnerDocOnClick,
  orderDownloadOnClick,
} from '@/helpers/scripts'

interface IProps {
  name: string
  body: string
  errors: string[]
  id: number
  isShow: boolean
  documentModel: 'partner' | 'order' | 'profile' | 'company' | null
}
const props = defineProps<IProps>()
const emit = defineEmits(['delete-document'])
const downloadRef = ref<HTMLAnchorElement>()
const isDownloading = ref(false)
const innerErrors = ref<string[]>([])
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '16px',
  },
  spin: true,
})
const downloadDocument = async (e: PointerEvent) => {
  e.preventDefault()
  innerErrors.value = []
  if (props.documentModel === 'partner') {
    isDownloading.value = true
    await downloadPartnerDocOnClick(
      props.id,
      {
        file_name: props.name,
        file_body: props.body,
      },
      downloadRef.value,
    )
      .finally(() => {
        isDownloading.value = false
      })
      .catch(() => {
        innerErrors.value.push('При загрузке произошла ошибка')
      })
  } else if (props.documentModel === 'order') {
    isDownloading.value = true
    await orderDownloadOnClick(
      props.id,
      {
        file_name: props.name,
        file_body: props.body,
      },
      downloadRef.value,
    )
      .finally(() => {
        isDownloading.value = false
      })
      .catch(() => {
        innerErrors.value.push('При загрузке произошла ошибка')
      })
  } else if (props.documentModel === 'company') {
    isDownloading.value = true
    await companyDownloadOnClick(
      props.id,
      {
        file_name: props.name,
        file_body: props.body,
      },
      downloadRef.value,
    )
      .finally(() => {
        isDownloading.value = false
      })
      .catch(() => {
        innerErrors.value.push('При загрузке произошла ошибка')
      })
  } else {
    await downloadOnClick(
      1,
      {
        file_name: props.name,
        file_body: props.body,
      },
      downloadRef.value,
    )
  }
}

const error = computed(
  () => props.errors.concat(innerErrors.value).join('') !== '',
)
</script>

<template>
  <Tooltip v-if="error">
    <template #title>
      <div class="errors-list">
        <span
          v-for="error in innerErrors.concat(props.errors)"
          :key="error + id"
          >{{ error }}</span
        >
      </div>
    </template>
    <div class="document-mini-list-item" :class="{ error }">
      <Space :size="8">
        <PaperClipOutlined
          :style="{ color: error ? '#FF4D4F' : '#00000073' }"
          v-if="!isDownloading"
        />
        <Spin v-else :indicator="indicator" />
        <a
          ref="downloadRef"
          class="download-link"
          @click.once="downloadDocument"
        >
          {{ props.name }}</a
        >
      </Space>
      <div class="delete-btn" v-if="!isShow">
        <DeleteOutlined @click="emit('delete-document', id)" />
      </div>
    </div>
  </Tooltip>

  <template v-else>
    <div class="document-mini-list-item" :class="{ error }">
      <Space :size="8">
        <PaperClipOutlined
          :style="{ color: error ? '#FF4D4F' : '#00000073' }"
          v-if="!isDownloading"
        />
        <Spin v-else :indicator="indicator" />
        <a
          ref="downloadRef"
          class="download-link"
          @click.once="downloadDocument"
        >
          {{ props.name }}</a
        >
      </Space>
      <div class="delete-btn" v-if="!isShow">
        <DeleteOutlined @click="emit('delete-document', id)" />
      </div>
    </div>
  </template>
</template>

<style scoped lang="scss">
.document-mini-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  .download-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #1677ff;
    cursor: pointer;
    display: block;
    word-break: break-all;
    &:hover {
      color: #69b1ff;
    }
  }

  .delete-btn {
    padding: 3px;
    min-width: 16px;
    span.anticon {
      color: #00000073;
      display: none;
      font-size: 16px;
      &:hover {
        color: #000000e0;
      }
    }
  }

  &:hover {
    background-color: #0000000a;
    span.anticon {
      display: block;
    }
  }

  &.error {
    span.anticon {
      color: #ff4d4f;
      display: block;
    }
    .download-link {
      color: #ff4d4f;
    }
    .delete-btn {
      span.anticon {
        &:hover {
          color: #ff7875;
        }
      }
    }
  }
}

.errors-list {
  display: flex;
  flex-direction: column;
  row-gap: 0;
}
</style>
