<script setup lang="ts">

import { IAuction } from '@/types/interfaces'
import FarmerAuctionProducts from '@/components/Farmer/Auction/FarmerAuctionProducts.vue'
import FarmerAuctionGeneralInfo from '@/components/Farmer/Auction/FarmerAuctionGeneralInfo.vue'
import { Divider } from 'ant-design-vue'

interface IProps {
  auctionData: IAuction | null
}

const props = defineProps<IProps>()
const emits = defineEmits(['setSelectedProduct'])
</script>

<template>
  <div class="farmer-auction-info">
    <FarmerAuctionGeneralInfo :auctionData="props.auctionData" />
    <Divider type="vertical" style="height: 100%" />
    <FarmerAuctionProducts
      :products="props.auctionData?.auction_product_requests ?? []"
      @setSelectedProduct="emits('setSelectedProduct', $event)"
    />
  </div>
</template>

<style scoped lang="scss">
.farmer-auction-info {
  display: grid;
  grid-template-columns: 1fr 16px 1fr;
  column-gap: 24px;
  margin-top: 8px;
}
</style>
