<script lang="ts" setup>

import InfoToolbar from '@/views/Farmer/reductions/reduction/farmerReductionInfoTab/infoToolbar/InfoToolbar.vue'
import { IReduction } from '@/types/interfaces'
import ProductsTable from '@/views/Farmer/reductions/reduction/farmerReductionInfoTab/ProductsTable.vue'

interface IProps {
  reduction: IReduction
  myParticipantId: number
}

const props = defineProps<IProps>()
const emit = defineEmits(['setRequestId'])
</script>

<template>
  <div class="wrapper">
    <InfoToolbar :reduction="reduction" />
    <ProductsTable
      :products="reduction.reduction_product_requests ?? []"
      :isClosedReduction="reduction.status === 'closed'"
      :myParticipantId="myParticipantId"
      @set-request-id="emit('setRequestId', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}
</style>
