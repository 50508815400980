<script lang="ts" setup>
import { withDefaults } from 'vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import {
  dangerButton,
  fontSize16,
  primaryButton,
  secondaryButton,
} from '@/assets/EgalStyles/EButton'

interface Props {
  show: boolean
  closeIcon: boolean
  buttonDirection: 'row' | 'column'
  type: 'danger' | 'primary'
  onlyMainAction: boolean
  mainText: string
  additionalText?: string
  width?: string
  mainBtnData?: {
    disabled: boolean
  }
  additionalBtnData?: {
    disabled: boolean
  }
  showButtons?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showButtons: true,
})
const emit = defineEmits(['toggleModal', 'mainAction', 'additionalAction'])

const setMainAction = () => {
  emit('mainAction')
  emit('toggleModal')
}
const toggleModal = () => {
  if (props.onlyMainAction && !props.closeIcon) emit('mainAction')
  emit('toggleModal')
}
</script>
<template>
  <Teleport to="body">
    <ModalWindow
      :show="show"
      :show-close-icon="closeIcon"
      class="custom-modal"
      :width="width"
      @close="toggleModal"
    >
      <template #header>
        <div class="custom-modal-header">
          <slot name="header" />
        </div>
      </template>
      <template #body>
        <div class="custom-modal-description">
          <slot name="description" />
        </div>
        <div class="custom-modal-body">
          <slot name="body" />
        </div>
        <div
          :class="buttonDirection === 'column' ? ' column' : ' row'"
          class="custom-modal-buttons"
          v-if="showButtons"
        >
          <EButton
            v-if="!onlyMainAction"
            :style-config="{
              ...secondaryButton,
              ...fontSize16,
              backgroundColor: '#ffffff',
              padding: '14px 14px',
              border: '1px solid #A0AEC0',
              justifyContent: 'center',
              hover: {
                backgroundColor: '#F7FAFC',
              },
              active: {
                backgroundColor: '#F7FAFC',
              },
            }"
            @click="emit('additionalAction')"
            :data="additionalBtnData"
            >{{ additionalText }}
          </EButton>
          <EButton
            v-if="type === 'danger'"
            :style-config="{
              ...dangerButton,
              ...fontSize16,
              padding: '14px 14px',
              justifyContent: 'center',
            }"
            class="larger-btn"
            :data="mainBtnData"
            @click="setMainAction"
            >{{ mainText }}
          </EButton>
          <EButton
            v-if="type === 'primary'"
            :style-config="{
              ...primaryButton,
              ...fontSize16,
              padding: '14px 14px',
              justifyContent: 'center',
              hover: {
                backgroundColor: '#3385FF',
                borderColor: '#3385FF',
              },
              active: {
                backgroundColor: '#005CE4',
                borderColor: '#005CE4',
              },
              disabled: {
                backgroundColor: '#E2E8F0',
                borderColor: '#E2E8F0',
                color: '#A0AEC0',
              },
            }"
            :data="mainBtnData"
            class="larger-btn"
            @click="setMainAction"
            >{{ mainText }}
          </EButton>
        </div>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.custom-modal {
  :deep(.modal-container) {
    padding: 0;
    border-radius: 16px;
  }

  :deep(.wrapper) {
    padding: 24px;
    box-sizing: border-box;
  }

  &-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $gray-800;
  }

  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $gray-700;
    margin-top: 8px;
  }
  &-body {
    margin-top: 24px;
  }
  &-buttons {
    margin-top: 32px;
    display: grid;
    text-align: center;

    &.row {
      grid-template-columns: 1fr 1fr;
      column-gap: 12px;
    }

    &.column {
      grid-template-columns: 1fr;
      grid-auto-flow: column;
      row-gap: 12px;
    }
  }
}
</style>
