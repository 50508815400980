<script lang="ts" setup>
import { OptionType } from '@/types/types'
import { CheckableTag } from 'ant-design-vue'

interface Props {
  filterItems: OptionType[]
  activeFilter: OptionType
}

defineProps<Props>()
const emit = defineEmits(['set-filter'])

const setFilter = async (filter: OptionType) => {
  emit('set-filter', filter)
}
</script>

<template>
  <div class="products__filters">
    <CheckableTag
      v-for="filter in filterItems"
      :key="filter.name"
      :checked="activeFilter.value === filter.value"
      @click="() => setFilter(filter)"
      class="product-filter-tags"
    >
      {{ filter.name }}
    </CheckableTag>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.products__filters {
  display: flex;
  gap: 12px 4px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  .product-filter-tags {
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    &:not(.ant-tag-checkable-checked) {
      background-color: #0000000a;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.badge {
  border-radius: 8px;
  background: $default-secondary;
  padding: 14px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-self: flex-start;
  color: $default-accent;
  cursor: pointer;
  column-gap: 6px;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: $hover-secondary;
  }

  &.active {
    background: $default-accent;
    color: white;
  }

  .badge-amount {
    background: #ffffff;
    color: $default-accent;
    border-radius: 16px;
    padding: 2.5px 4.5px;
    font-size: 16px;
    line-height: 18px;
    min-width: 14px;
    text-align: center;
  }
}
</style>
