<script setup lang="ts">
import CreateRequestHeader from '@/components/CreateRequest/CreateRequestHeader.vue'
import {computed, h, reactive, ref, UnwrapRef, watch} from 'vue'
import CreateRequestFooter from '@/components/CreateRequest/CreateRequestFooter.vue'
import CreateRequestBody from '@/components/CreateRequest/CreateRequestBody/CreateRequestBody.vue'
import { IAddress, IContact } from '@/types/interfaces'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import router from '@/router'
import { useDebounceFn } from '@vueuse/core'
import { PriceRequestAPI } from '@/api/PriceRequestAPI'
import dayjs from 'dayjs'
import { PaletizingType } from '@/types/enums'
import { ProductRequestAPI } from '@/api/ProductRequestAPI'
import { RecipientAPI } from '@/api/RecipientAPI'
import { Button, notification } from 'ant-design-vue'
import { RankedFarmerAPI } from '@/api/RankedFarmer'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import {useRoute} from "vue-router";

export interface FirstFormState {
  expiration_date: string
  is_self_delivery: boolean
  is_supplier_delivery: boolean
  is_delivery_included: boolean
  is_delivery_excluded: boolean
  delivery_method: string[]
  delivery_payment_type: string[]
  payment_method: string[]
  is_pay_late: boolean
  standart: boolean
  euro: boolean
  payment_delay_days: number | string
  need_unload: boolean
  comment: string
  delivery_date_type: 'date' | 'days'
  should_be_delivered_in_days: string
  should_be_delivered_at: string

  addressIsError: boolean
  selectedAddress: number | null
  savedAddress: IAddress | null
  selectedContactPerson: number
  savedContactPerson: IContact | null
}
export interface IProductTableItem {
  key: string
  product: string
  amount: string | number
  shelfLife: string | number
  need_test_sample: boolean
  test_sample_amount: string | number
  measurement_unit_id: string
}

const currentStep = ref(0)
const firstFormState: UnwrapRef<FirstFormState> = reactive({
  expiration_date: '',
  is_self_delivery: false,
  is_supplier_delivery: false,
  is_delivery_included: false,
  is_delivery_excluded: false,
  delivery_method: ['is_self_delivery', 'is_supplier_delivery'],
  delivery_payment_type: ['is_delivery_included'],
  payment_method: ['is_pay_non_cash'],
  is_pay_late: false,
  payment_delay_days: '',
  standart: false,
  euro: false,
  need_unload: false,
  comment: '',
  delivery_date_type: 'date',
  should_be_delivered_in_days: '',
  should_be_delivered_at: '',

  addressIsError: false,
  selectedAddress: null,
  savedAddress: null,
  selectedContactPerson: 0,
  savedContactPerson: null,
})
const secondFormState = reactive<{ domains: IProductTableItem[] }>({
  domains: [],
})
const checkedKeys = ref<string[]>([])
const expandedKeys = ref<string[]>([])
const thirdFormState = ref<any[]>([])
const thirdFormError = ref<boolean>(false)
const allFarmers = ref<any[]>([])
const searchedFarmers = ref<any[]>([])
const isFarmersFetching = ref<boolean>(false)
const selectedFarmers = ref<string[]>([])
const farmersSearchQuery = ref<string>('')
const forms = ref()
const isConfirmModal = ref<boolean>(false)
const isRejectModal = ref<boolean>(false)
const isRequestSending = ref<boolean>(false)

const editFirstForm = (field: string, value: any) => {
  firstFormState[field] = value
}

const editSecondForm = (field: string, value: any, index: number) => {
  secondFormState.domains[index][field] = value === null ? '' : value
  if (field === 'need_test_sample' && !value) {
    secondFormState.domains[index].test_sample_amount = ''
  }

  if (field === 'amount' || field === 'test_sample_amount') {
    if (typeof value === 'string') {
      secondFormState.domains[index][field] =
        Number.parseFloat(value).toFixed(2)
    } else {
      secondFormState.domains[index][field] = ''
    }
  }
}

const addProduct = (item: IProductTableItem) => {
  if (item.measurement_unit_id === null) {
    const productText = item.product
    const lastTwoChars = productText?.slice(-2)
    const lastChar = productText?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars)) {
      item.measurement_unit_id = lastTwoChars
    } else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) {
      item.measurement_unit_id = lastChar
    }
    secondFormState.domains.push(item)
  } else {
    secondFormState.domains.push(item)
  }
}

const removeProduct = (key: string) => {
  secondFormState.domains = secondFormState.domains.filter(
    (obj) => obj.key !== key,
  )
  checkedKeys.value = checkedKeys.value.filter((k) => k !== key)
}

const validateFirstStep = () => {
  Promise.all([
    forms.value.firstFormRef.firstFormRef.validate(),
    validateAddress(),
  ])
    .then(() => {
      nextStep()
    })
    .catch((err) => {
      if (err === 'delivery-address') {
        firstFormState.addressIsError = true
      }
    })
}

const validateSecondStep = () => {
  forms.value.secondFormRef.secondFormRef.secondFormRef.validate().then(() => {
    nextStep()
  })
}

const validateThirdStep = () => {
  if (thirdFormState.value.length) {
    isConfirmModal.value = true
  } else {
    thirdFormError.value = true
  }
}

const resetThirdStep = () => {
  thirdFormState.value = []
  selectedFarmers.value = []
}

const checkKeys = (keys: string[], event: any) => {
  checkedKeys.value = keys
  if (event.checked) {
    addProduct(makeTableElement(event))
  } else {
    removeProduct(event.node.key)
  }
}

const expandKeys = (keys: string[]) => {
  expandedKeys.value = keys
}

const validateAddress = () => {
  return new Promise((resolve, reject) => {
    if (
      (firstFormState.savedAddress &&
        firstFormState.delivery_method.includes('is_supplier_delivery')) ||
      !firstFormState.delivery_method.includes('is_supplier_delivery')
    ) {
      resolve(true)
    } else {
      reject('delivery-address')
    }
  })
}

const setSelectedFarmers = (value: string[], more: any) => {
  selectedFarmers.value = value
}

const deleteFarmer = (uid: string) => {
  selectedFarmers.value = selectedFarmers.value.filter((id) => id !== uid)
  thirdFormState.value = thirdFormState.value.filter((obj) => obj.id !== uid)
}

const saveFarmers = () => {
  const foundObjects: any[] = []
  selectedFarmers.value.forEach((uid) => {
    const foundObject = allFarmers.value.find((obj) => obj.id === uid)
    if (foundObject) {
      foundObjects.push(foundObject)
    }
  })
  thirdFormState.value = sortByMissingProducts(foundObjects)
  thirdFormError.value = false
}

const nextStep = () => {
  currentStep.value = currentStep.value + 1
}

const prevStep = () => {
  if (currentStep.value === 2) {
    resetThirdStep()
  }
  currentStep.value = currentStep.value - 1
}

const isSelectFarmers = ref(false)

const handleSelectFarmerModal = (value: boolean) => {
  isSelectFarmers.value = value
  if (!value) {
    farmersSearchQuery.value = ''
  }
}

const setSearchQuery = useDebounceFn((value: string) => {
  farmersSearchQuery.value = value
}, 500)

const showRejectModal = () => {
  isRejectModal.value = true
}

const closeConfirmModal = () => {
  if (isRequestSending.value) return
  isConfirmModal.value = false
}

const toggleStep = () => {
  switch (currentStep.value) {
    case 0:
      validateFirstStep()
      break
    case 1:
      validateSecondStep()
      break
    case 2:
      validateThirdStep()
      break
    default:
      break
  }
}

const sendRequest = async () => {
  try {
    isRequestSending.value = true
    await PriceRequestAPI.create(collectPriceRequestData()).then(
      async (res) => {
        await ProductRequestAPI.createMany(
          collectProductRequestData(res.id),
        ).then(async () => {
          await RecipientAPI.createMany(collectRecipientData(res.id)).then(
            async () => {
              isRequestSending.value = false
              notification.info({
                message: 'Запрос отправлен поставщикам',
                description: 'Дождитесь их ответа',
                top: '80px',
                icon: h(InfoCircleFilled, {
                  style: { color: 'rgba(22, 119, 255, 1)' },
                }),
              })
              await router.push({ name: 'requests' }).then(() => {
                location.reload()
              })
            },
          )
        })
      },
    )
  } catch (error) {
    isRequestSending.value = false
    console.error(error)
  }
}

const getFarmers = async () => {
  isFarmersFetching.value = true
  const data = {
    products: secondFormState.domains.map((obj) => obj.key.split(':')[0]),
  }
  await RankedFarmerAPI.search(data)
    .then((res) => {
      if (farmersSearchQuery.value) {
        searchedFarmers.value = res.filter((obj) => {
          const farmerName = (obj.legal_form + obj.name)
            .toLowerCase()
            .replace(/\s+/, "")
          return farmerName.includes(farmersSearchQuery.value.toLowerCase().replace(/\s+/, "") )
        })
      } else {
        allFarmers.value = res
      }
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      isFarmersFetching.value = false
    })
}

const makeTableElement = (e: any): IProductTableItem => ({
  key: e.node.key,
  product: `${e.node.title.split(',').join('')}`,
  amount: '',
  shelfLife: '',
  need_test_sample: false,
  test_sample_amount: '',
  measurement_unit_id: e.node.measurement_unit_id,
})

const collectPriceRequestData = () => {
  return {
    expiration_date: dayjs(firstFormState.expiration_date).format('YYYY-MM-DD'),
    contact_name: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.name
      : '',
    contact_phone: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.phone
      : '',
    contact_post: firstFormState.savedContactPerson
      ? firstFormState.savedContactPerson.post
      : '',
    is_self_delivery:
      firstFormState.delivery_method.includes('is_self_delivery'),
    is_supplier_delivery: firstFormState.delivery_method.includes(
      'is_supplier_delivery',
    ),
    is_delivery_excluded:
      firstFormState.delivery_method.includes('is_supplier_delivery') &&
      firstFormState.delivery_payment_type.includes('is_delivery_excluded'),
    is_delivery_included:
      firstFormState.delivery_method.includes('is_supplier_delivery') &&
      firstFormState.delivery_payment_type.includes('is_delivery_included'),
    delivery_address: firstFormState.delivery_method.includes(
      'is_supplier_delivery',
    )
      ? firstFormState.savedAddress!.street
      : '',
    is_pay_cash: firstFormState.payment_method.includes('is_pay_cash'),
    is_pay_non_cash: firstFormState.payment_method.includes('is_pay_non_cash'),
    is_payment_delayed: firstFormState.is_pay_late,
    payment_delay_days: firstFormState.payment_delay_days
      ? firstFormState.payment_delay_days
      : null,
    palletizing_type: firstFormState.euro
      ? PaletizingType.EURO
      : firstFormState.standart
        ? PaletizingType.STANDARD
        : null,
    need_unload:
      firstFormState.delivery_method.includes('is_supplier_delivery') &&
      firstFormState.need_unload,
    comment: firstFormState.comment,
    should_be_delivered_in_days:
      firstFormState.delivery_date_type === 'days' &&
      firstFormState.should_be_delivered_in_days
        ? firstFormState.should_be_delivered_in_days
        : null,
    should_be_delivered_at:
      firstFormState.delivery_date_type === 'date' &&
      firstFormState.should_be_delivered_at
        ? dayjs(firstFormState.should_be_delivered_at).format('YYYY-MM-DD')
        : null,
  }
}

const collectProductRequestData = (priceRequestId: number) => {
  return secondFormState.domains.map((product: IProductTableItem) => ({
    price_request_id: priceRequestId,
    product_id: +product.key.split(':')[0],
    product_name: product.product,
    customer_needed_volume: +product.amount,
    remaining_shelf_life: product.shelfLife ? +product.shelfLife : null,
    is_sample_needed: product.need_test_sample,
    needed_samples_number:
      product.need_test_sample && product.test_sample_amount
        ? +product.test_sample_amount
        : null,
  }))
}

const collectRecipientData = (priceRequestId: number) => {
  return thirdFormState.value.map((obj) => ({
    price_request_id: priceRequestId,
    farmer_id: obj.id,
  }))
}

const sortByMissingProducts = (farmers: any[]) => {
  return farmers.sort(
    (a, b) => a.missing_products.length - b.missing_products.length,
  )
}

const setCurrentStep = (value: number) => {
  currentStep.value = value
}

watch([currentStep, farmersSearchQuery], async () => {
  if (currentStep.value === 2) {
    await getFarmers()
  }
})

const route = useRoute()
</script>

<template>
  <div class="create-request-view">
    <CreateRequestHeader
      :currentStep="currentStep"
      :thirdFormState="thirdFormState"
      :farmers="farmersSearchQuery ? searchedFarmers : allFarmers"
      :selectedFarmers="selectedFarmers"
      :isSelectFarmers="isSelectFarmers"
      :isFarmersFetching="isFarmersFetching"
      :farmersSearchQuery="farmersSearchQuery"
      @setSelectedFarmers="setSelectedFarmers"
      @saveFarmers="saveFarmers"
      @handleSelectFarmerModal="handleSelectFarmerModal"
      @setSearchQuery="setSearchQuery"
      @setCurrentStep="setCurrentStep"
    />
    <CreateRequestBody
      ref="forms"
      :currentStep="currentStep"
      :firstFormState="firstFormState"
      :secondFormState="secondFormState"
      :thirdFormState="thirdFormState"
      :checkedKeys="checkedKeys"
      :expandedKeys="expandedKeys"
      @triggerValidation="toggleStep"
      @editFirstForm="editFirstForm"
      @editSecondForm="editSecondForm"
      @deleteFarmer="deleteFarmer"
      @handleSelectFarmerModal="handleSelectFarmerModal"
      @addProduct="addProduct"
      @removeProduct="removeProduct"
      @checkKeys="checkKeys"
      @expandKeys="expandKeys"
    />
    <CreateRequestFooter
      :currentStep="currentStep"
      :thirdFormError="thirdFormError"
      @showRejectModal="showRejectModal"
      @next-step="toggleStep"
      @prev-step="prevStep"
    />
  </div>

  <Teleport to="body">
    <ModalWindow
      :show="isConfirmModal"
      :show-close-icon="true"
      class="select-modal confirm-request-modal"
      @close="closeConfirmModal"
    >
      <template #header>
        <span>Отправить запрос или внести изменения?</span>
      </template>
      <template #body>
        <span>
          Пожалуйста, проверьте состав запроса, условия поставки и получателей
        </span>
      </template>
      <template #footer>
        <Button @click="closeConfirmModal" :disabled="isRequestSending">
          Внести изменения
        </Button>
        <Button :loading="isRequestSending" type="primary" @click="sendRequest">
          Отправить
        </Button>
      </template>
    </ModalWindow>
  </Teleport>

  <Teleport to="body">
    <ModalWindow
      :show="isRejectModal"
      :show-close-icon="true"
      class="select-modal reject-request-modal"
      @close="isRejectModal = false"
    >
      <template #header>
        <span>Отменить запрос? </span>
      </template>
      <template #body>
        <span> Внесённые данные не будут сохранены </span>
      </template>
      <template #footer>
        <Button @click="isRejectModal = false"> Внести изменения </Button>
        <Button type="primary" @click="router.push('/requests')">
          Да, отменить
        </Button>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';

.create-request-view {
  height: calc(100vh - 69px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.confirm-request-modal,
.reject-request-modal {
  :deep(.wrapper) {
    padding: 24px;
  }
  :deep(.modal-header) {
    span {
      @include h4();
      color: $gray-800;
    }
  }
  :deep(.modal-body) {
    margin-top: 8px;
    margin-bottom: 32px;
    span {
      @include p5();
      color: $gray-700;
    }
  }
  :deep(.modal-container) {
    width: 440px;
    min-width: 440px;
    height: auto;
    padding: 0;
    box-sizing: border-box;
  }
  :deep(.modal-container) {
    div {
      box-sizing: border-box;
      height: 100%;
    }
  }
  :deep(.modal-footer) {
    display: flex;
    gap: 8px;
    .ant-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 8px;
    }
  }
  .button-loading {
    transform: scale(0.5);
  }
}
</style>
