<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { useStore } from '@/store'
import { Roles } from '@/types/enums'
import { FarmerAPI } from '@/api/FarmerAPI'
import { CustomerAPI } from '@/api/CustomerAPI'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import {useCookies} from "vue3-cookies";
import {useRoute} from "vue-router";
import HomePage from '@/components/Home/HomePage.vue'
import emitter from "@/emitter";

const { getCoreCookie } = useJWTDecode()
const { cookies } = useCookies()

const verificationStatus = ref<'none' | 'full' | 'part'>('none')
const mainStore = useStore()
const isActive = ref(false)
const status = ref('')
const isDocsSend = ref(false)
const userDataString = computed(() => localStorage.getItem('userData'))
const isFarmer = computed(() => getCoreCookie()?.company_type === Roles.Farmer)
const isCustomer = computed(() => getCoreCookie()?.company_type === Roles.Customer)
const isShowModalVerificationFull = localStorage.getItem('isShowModalVerificationFull') === 'true'

let userData = userDataString.value ? JSON.parse(userDataString.value) : ''

const confirm = async () => {
  if (isFarmer.value) {
    await FarmerAPI.getFarmer(userData?.id, []).then(async (res) => {
      isActive.value = !!res.is_active
      status.value = res?.status
      isDocsSend.value = res?.is_docs_sent
      await FarmerAPI.getFarmer(userData?.id, []).then(async (res) => {
        if (res?.status === 'need_verify' && res?.is_docs_sent) {
          localStorage.removeItem('isNotUploadDocuments')
          mainStore.setVerificationStatus('full')
          if (!isShowModalVerificationFull) {
            openModalVerificationFull()
          }
        }
      })
    })
  } else if (isCustomer.value) {
    await CustomerAPI.getItem(userData?.id).then(async (res) => {
      isActive.value = !!res.is_active
      status.value = res?.status
      isDocsSend.value = res?.is_docs_sent
      await CustomerAPI.getItem(userData?.id).then(async (res) => {
        if (res?.status === 'need_verify' && res?.is_docs_sent) {
          mainStore.setVerificationStatus('full')
          if (!isShowModalVerificationFull) {
            openModalVerificationFull()
          }
        }
      })
    })
  }
}

const modalStore = useModalStore()

const openModalVerificationFull = () => {
  modalStore.openModal(ModalKeys.VerificationFull, {
    isClosable: false,
    class: 'verification-modal',
    close: confirm,
  })
}

watch(
  () => mainStore.verificationStatus,
  async (newStatus) => {
    if (newStatus === 'full') {
      verificationStatus.value = 'full'
      if (!isShowModalVerificationFull) {
        openModalVerificationFull()
      }
    } else if (newStatus === 'part') {
      verificationStatus.value = 'part'
      if (!isShowModalVerificationFull) {
        openModalVerificationFull()
      }
    }
  },
)

emitter.on('refresh-token', async (e) => {
  await confirm()
})

onMounted(async () => {
  if (!userDataString.value) return
  localStorage.removeItem('toLoginFromRegister')
  await confirm()
})

const route = useRoute()
</script>

<template>
  <HomePage />
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
</style>
