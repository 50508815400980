<script lang="ts" setup>
import { ITopProduct } from '@/types/interfaces';
import TopOfYearCard from './components/TopOfYearCard/index.vue'
import {onMounted} from "vue";

interface IProps {
  topProducts: ITopProduct[]
}

const emit = defineEmits(['topYearFilter'])
defineProps<IProps>()

const topYearFilter = (filter: any) => {
  emit('topYearFilter', filter)
}
</script>

<template>
  <div class="top-of-year">
    <div class="top-of-year__header header">
      <div class="header__title">Товары года</div>
      <div class="header__subtitle">По сумме продаж</div>
    </div>
    <div class="top-of-year__cards cards">
      <TopOfYearCard
        v-for="card in topProducts"
        :key="card.id"
        :card="card"
        @topYearFilter="topYearFilter"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/style/variables';
.top-of-year {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  &__title {
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: variables.$title-color;
  }

  &__subtitle {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: variables.$subtitle-color;
  }
}
</style>
