<script setup lang="ts">
import { Drawer, Flex, Divider, TypographyText as TpText } from 'ant-design-vue'
import { useCustomerRequestStore } from '@/store/useCustomerRequestStore'
import { computed, onMounted, ref } from 'vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import DrawerBodyElement from '@/views/Customer/CustomerRequestView/components/CustomerRequestAnswersTab/components/SupplierDrawer/DrawerBodyElement.vue'
import { declensionDays } from '@/helpers/scripts'
import SimpleRate from '@/components/Rating/SimpleRate.vue'
import dayjs from 'dayjs'
import PartnershipStatus from '@/views/Customer/CustomerRequestView/components/DrawerSuppliers/components/DataListBlock/components/DataListBlockItem/components/PartnershipStatus/PartnershipStatus.vue'
import { PartnersAPI } from '@/api/PartnersAPI'

const request = useCustomerRequestStore()
const customerRequest = request.currentCustomerRequest
const partners = ref([])

const recipient = computed(() =>
  request?.currentCustomerRequest?.recipients?.find(
    (recipient) => recipient.id === request.chosenSupplierId,
  ),
)

const fullName = computed(
  () => `${recipient.value?.farmer.legal_form} ${recipient.value?.farmer.name}`,
)

const getMyPartners = async () => {
  PartnersAPI.myPartners().then((res) => {
    partners.value = res?.items
  })
}

const isPartner = () => {
  return !!partners.value.filter((el) => {
    return recipient.value?.farmer_id === el?.company_id
  })?.length
}

onMounted(async () => {
  await getMyPartners()
})
</script>

<template>
  <Drawer
    v-model:open="request.isOpenDrawerOneSupplier"
    title="Условия доставки и оплаты"
    :width="500"
    :bodyStyle="{ padding: '24px 32px' }"
  >
    <div class="drawer__body">
      <Flex vertical :gap="24">
        <DrawerBodyElement title="Поставщик">
          <Flex :gap="8" :style="{ alignItems: 'center' }">
            <PartnershipStatus v-if="isPartner()" :is-partner="isPartner()" />
            <TpText>{{ fullName }}</TpText>
          </Flex>
          <Flex :gap="8">
            <EnvironmentOutlined
              :style="{
                color: 'rgba(0, 0, 0, 0.45)',
                position: 'relative',
                top: '3px',
              }"
            />
            <TpText type="secondary">{{
              recipient.farmer.legal_address
            }}</TpText>
          </Flex>
          <SimpleRate :company_rating="recipient.farmer.company_rating" />
        </DrawerBodyElement>

        <DrawerBodyElement
          title="Способ получения"
          v-if="
            customerRequest?.is_self_delivery ||
            customerRequest?.is_supplier_delivery
          "
        >
          <Divider />
          <div class="comparison">
            <div class="answer">
              <span class="answer-title"> Вы запрашивали </span>
              <TpText v-if="customerRequest?.is_self_delivery">
                Самовывоз
              </TpText>
              <TpText v-if="customerRequest?.is_supplier_delivery">
                Доставка от поставщика
              </TpText>
            </div>
            <div
              class="answer"
              v-if="
                recipient?.price_answer?.is_self_delivery ||
                recipient?.price_answer?.is_supplier_delivery
              "
            >
              <span class="answer-title"> Ответ поставщика </span>
              <TpText v-if="recipient?.price_answer?.is_self_delivery">
                Самовывоз
              </TpText>
              <TpText v-if="recipient?.price_answer?.is_supplier_delivery">
                Доставка от поставщика
              </TpText>
            </div>
          </div>
        </DrawerBodyElement>

        <DrawerBodyElement
          title="Оплата доставки"
          v-if="
            customerRequest?.is_supplier_delivery ||
            recipient.price_answer.is_supplier_delivery
          "
        >
          <Divider v-if="true" />
          <div class="comparison">
            <div class="answer">
              <span class="answer-title"> Вы запрашивали </span>
              <span v-if="!customerRequest?.is_supplier_delivery">-</span>
              <TpText v-else-if="customerRequest?.is_delivery_included">
                Включена в стоимость товара
              </TpText>
              <TpText v-else-if="customerRequest?.is_delivery_excluded">
                Оплачивается отдельно
              </TpText>
            </div>
            <div class="answer">
              <span class="answer-title"> Ответ поставщика </span>
              <span v-if="!recipient.price_answer.is_supplier_delivery">-</span>
              <template v-else>
                <TpText v-if="recipient.price_answer.is_delivery_included">
                  Включена в стоимость товара
                </TpText>
                <TpText v-if="recipient.price_answer.is_delivery_excluded">
                  Оплачивается отдельно
                </TpText>
              </template>
            </div>
          </div>
        </DrawerBodyElement>

        <DrawerBodyElement
          title="Срок поставки"
          v-if="
            customerRequest?.should_be_delivered_at?.length ||
            customerRequest?.should_be_delivered_in_days
          "
        >
          <Divider />
          <div class="comparison">
            <div class="answer">
              <span class="answer-title"> Вы запрашивали </span>
              <TpText v-if="!!customerRequest.should_be_delivered_in_days">
                {{
                  `${
                    customerRequest.should_be_delivered_in_days
                  } ${declensionDays(
                    customerRequest.should_be_delivered_in_days,
                  )}`
                }}
              </TpText>
              <TpText v-if="customerRequest.should_be_delivered_at?.length">
                {{
                  `к ${dayjs(customerRequest.should_be_delivered_at).format(
                    'DD.MM.YYYY',
                  )}`
                }}
              </TpText>
            </div>
            <div class="answer">
              <span class="answer-title"> Ответ поставщика </span>
              <TpText
                v-if="!!recipient.price_answer.should_be_delivered_in_days"
              >
                {{
                  `${
                    recipient.price_answer.should_be_delivered_in_days
                  } ${declensionDays(
                    recipient.price_answer.should_be_delivered_in_days,
                  )}`
                }}
              </TpText>
              <TpText
                v-if="recipient.price_answer.should_be_delivered_at?.length"
              >
                {{
                  `к ${dayjs(
                    recipient.price_answer.should_be_delivered_at,
                  ).format('DD.MM.YYYY')}`
                }}
              </TpText>
            </div>
          </div>
        </DrawerBodyElement>

        <DrawerBodyElement
          title="Способ оплаты"
          v-if="
            customerRequest?.is_pay_cash ||
            customerRequest?.is_pay_non_cash ||
            customerRequest?.payment_delay_days
          "
        >
          <Divider />
          <div class="comparison">
            <div class="answer">
              <span class="answer-title"> Вы запрашивали </span>
              <TpText v-if="customerRequest.is_pay_cash">
                Наличный расчёт
              </TpText>
              <TpText v-if="customerRequest.is_pay_non_cash">
                Безналичный расчёт
              </TpText>
              <TpText v-if="customerRequest.payment_delay_days">
                Отсрочка по оплате
                {{
                  `${customerRequest.payment_delay_days} ${declensionDays(
                    customerRequest.payment_delay_days,
                  )}`
                }}
              </TpText>
            </div>
            <div class="answer">
              <span class="answer-title"> Ответ поставщика </span>
              <TpText v-if="recipient.price_answer.is_pay_cash">
                Наличный расчёт
              </TpText>
              <TpText v-if="recipient.price_answer.is_pay_non_cash">
                Безналичный расчёт
              </TpText>
              <TpText v-if="recipient.price_answer.payment_delay_days">
                Отсрочка по оплате
                {{
                  `${recipient.price_answer.payment_delay_days} ${declensionDays(
                    recipient.price_answer.payment_delay_days,
                  )}`
                }}
              </TpText>
            </div>
          </div>
        </DrawerBodyElement>

        <DrawerBodyElement
          title="Услуги"
          v-if="
            customerRequest.palletizing_type !== null ||
            customerRequest.palletizing_type === 'euro' ||
            customerRequest.need_unload
          "
        >
          <Divider v-if="true" />
          <div class="comparison">
            <div class="answer">
              <span class="answer-title"> Вы запрашивали </span>
              <TpText v-if="customerRequest.palletizing_type !== null">
                Палетирование
              </TpText>
              <TpText v-if="customerRequest.palletizing_type === 'euro'"
                >Европалеты
              </TpText>
              <TpText v-if="customerRequest.need_unload"
                >Разгрузка товаров
              </TpText>
            </div>
            <div class="answer">
              <span class="answer-title"> Ответ поставщика </span>
              <TpText v-if="recipient.price_answer.palletizing_type !== null">
                Палетирование
              </TpText>
              <TpText v-if="recipient.price_answer.palletizing_type === 'euro'"
                >Европалеты
              </TpText>
              <TpText v-if="recipient.price_answer.need_unload"
                >Разгрузка товаров
              </TpText>
            </div>
          </div>
        </DrawerBodyElement>

        <DrawerBodyElement
          v-if="!!recipient.price_answer.comment"
          title="Комментарий к ответу"
        >
          <TpText>{{ recipient?.price_answer.comment }}</TpText>
        </DrawerBodyElement>
      </Flex>
    </div>
  </Drawer>
</template>

<style lang="scss" scoped>
.drawer__body h5 {
  font-family: Inter sans-serif !important;
}

:deep(.ant-divider) {
  margin-top: 0;
  margin-bottom: 8px;
}

.comparison {
  display: flex;
}

.answer {
  margin-right: 16px;
  width: 248px;
  display: grid;
  gap: 4px;

  &-title {
    color: #00000073;
  }
}
</style>
