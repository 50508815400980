<script lang="ts" setup>

import { IAuctionProductRequest } from '@/types/interfaces'

const props = defineProps<IProps>()

interface IProps {
  products: IAuctionProductRequest[]
}
</script>

<template>
  <div class="product-cell" v-if="props.products?.length">
    <div
      v-for="product in props.products"
      :key="product.id"
      class="product-cell__item"
    >
      {{ product.farmer_product_name }}
    </div>
  </div>
  <span class="secondary-text" v-else>Все товары были удалены</span>
</template>

<style lang="scss" scoped>
.product-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: max-content;
  cursor: pointer;
  &__item {
    width: 100%;
    display: block;
    //flex-wrap: wrap;
    //word-break: break-word;
    font-family: SFMono-Regular, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.88);
  }
}
.secondary-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #00000073;
}
</style>
