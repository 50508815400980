<script setup lang="ts">
import { Button, FormItem, Select, Form } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { TMapCoordinates, TMapPoint } from '@/types/types'
import { defineProps, reactive, ref, watch } from 'vue'
import { FormInstance } from 'ant-design-vue/es/form'
import YaMap from '@/components/YaMap/YaMap.vue'
import { IAddress } from '@/types/interfaces'

interface IProps {
  show: boolean
  selectedAddress: IGeoObjectProps | null
  onSave: (data: {text: string, coordinates: [number, number]}) => void
}

interface IGeoObjectProps extends TMapPoint {
  description: string
  text: string
}

interface FormState {
  address: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['close', 'save'])

const selectedAddress = ref<IGeoObjectProps>(props.selectedAddress || null)
const selectOptions = ref([])
const formRef = ref<FormInstance>()
const formState = reactive<FormState>({
  address: props.selectedAddress?.address || null,
})

const handleClose = async () => {
  emit('close')
}

const handleMapClick = async ({ lat, lon }: TMapCoordinates) => {
  // @ts-ignore
  const data = await window.ymaps.geocode([lat, lon])
  const nearest = data.geoObjects.get(0)
  const pointData: IGeoObjectProps = nearest.properties.getAll()

  selectedAddress.value = {
    ...pointData,
    id: 1,
    coordinates: { lat, lon },
    text: pointData.text,
    name: pointData.text,
  }
  handleUpdateStreet(pointData.text)
}

const handleUpdateStreet = (e: string) => {
  formState.address = e
  // @ts-ignore
  new window.ymaps.suggest(e).then((res) => {
    selectOptions.value = res.map((item) => ({
      value: item.value,
      label: item.value,
    }))
  })
}

const handleAddressSelect = async (value: string) => {
  // @ts-ignore
  await window.ymaps.geocode(value).then((res) => {
    const obj = res.geoObjects.get(0)
    if (obj?.geometry?._coordinates?.length > 0) {
      const coordinates = obj?.geometry?._coordinates
      const [lat, lon] = coordinates
      selectedAddress.value = {
        coordinates: { lat, lon },
        text: value,
        description: value,
        name: value,
        id: 1,
        address: value,
      }
    }
  })
}

watch(selectOptions, () => {
  const selectItems = document.querySelectorAll('.ant-select-item')
  selectItems.forEach((item) => {
    item.classList.add('no-close')
  })
})

const handleSelectChange = (value: string | null | undefined) => {
  if (!value) {
    selectOptions.value = []
    formState.address = null
    selectedAddress.value = null
    return
  }
  formState.address = value
}

const handleSubmit = () => {
  if (!selectedAddress.value) {
    return
  }
  const lat = +selectedAddress.value.coordinates.lat
  const lon = +selectedAddress.value.coordinates.lon
  const result = {
    text: selectedAddress.value.name,
    coordinates: [lat, lon] as [number, number],
  }
  props.onSave(result)
  document.body.style.overflowY = 'auto' // фикс странного поведения в VerificationFirstStep.vue
  emit('close')
}

const handleBlur = (e) => {
  if (formState.address == "") {
    handleSelectChange(null)
  }
}
</script>

<template>
  <ModalWindow
    :show="props.show"
    :show-close-icon="true"
    class="select-modal select-address-modal"
    @close="handleClose"
  >
    <template #header>
      <div class="title">Найти адрес на карте</div>
      <div class="subtitle">Введите адрес или укажите его на карте</div>
    </template>
    <template #body>
      <Form ref="formRef" :model="formState" class="address-input-block">
        <FormItem
          name="address"
        >
          <Select
            v-model:value="formState.address"
            class="address-input"
            show-search
            size="large"
            placeholder="Введите адрес"
            popupClassName="no-close map-autocomplete"
            @select="handleAddressSelect"
            @change="handleSelectChange"
            @blur="handleBlur"
            :options="selectOptions"
            :show-arrow="false"
            :not-found-content="
              formState.address
                ? 'Мы не нашли этот адрес, наведите метку на нужное здание'
                : 'Введите адрес'
            "
            @search="handleUpdateStreet"
            allowClear
          />
        </FormItem>

        <Button
          class="submit-button"
          type="primary"
          size="large"
          @click="handleSubmit"
          :disabled="!selectedAddress"
          >Подтвердить</Button
        >
      </Form>

      <div class="map-wrapper">
        <YaMap
          width="852px"
          height="359px"
          :on-map-click="handleMapClick"
          :points="selectedAddress ? [selectedAddress] : []"
        />
      </div>
    </template>
  </ModalWindow>
</template>
<style lang="scss">
.map-autocomplete {
  padding: 0;
}
</style>

<style scoped lang="scss">
.select-address-modal {
  z-index: 2;
  :deep(.modal-container) {
    padding: 24px;
    box-sizing: border-box;
  }
  :deep(.title) {
    font-size: 18px;
  }
  :deep(.subtitle) {
    color: #4a5568;
    font-weight: 400;
    font-size: 14px;
  }

  :deep(.address-popup) {
    outline: 1px solid red;
    background-color: red;
  }
  :deep(.address-input-block) {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    gap: 8px;
    padding-bottom: 16px;
    .submit-button {
      font-size: 16px;
      overflow: visible;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  :deep(.ant-select-selection-search-input) {
    font-size: 16px;
  }
  :deep(.address-input) {
    width: 702px;
    font-size: 16px;
    font-weight: 400;
  }
  :deep(.map-wrapper) {
    border-radius: 8px;
    overflow: hidden;
  }
  :deep(.modal-header) {
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 700;
  }
  :deep(.modal-body) {
    box-sizing: border-box;
    margin-bottom: auto;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
