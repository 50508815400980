import { computed, ref } from 'vue'

export const useTimer = () => {
  const formatTime = (seconds: number) => {
    return (
      Math.floor(seconds / 60) +
      ':' +
      (seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60)
    )
  }
  // time in seconds
  let disableTime = sessionStorage.getItem('registerDisableTime') ? Number(sessionStorage.getItem('registerDisableTime')) : 150
  const timer = ref(disableTime)
  const isDisableTime = computed(() => disableTime > 0)
  let time = 0
  const startTimer = () => {
    clearInterval(time) // Clear any existing interval
    timer.value = disableTime
    time = setInterval(() => {
      timer.value--
      if (timer.value === 0) {
        clearInterval(time)
      }
      sessionStorage.setItem('registerDisableTime', timer.value.toString())
    }, 1000)
  }

  const setDisableTime = (newDisableTime: number) => {
    sessionStorage.setItem('registerDisableTime', timer.value.toString())
    return disableTime = newDisableTime
  }

  const buttonText = computed(() =>
    timer.value > 0
      ? 'Отправить письмо ещё раз через ' + formatTime(timer.value)
      : 'Отправить',
  )

  return { timer, formatTime, startTimer, setDisableTime, isDisableTime, buttonText }
}
