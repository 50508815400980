import { APIModels } from '@/types/enums'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies()

class ProductRequestAPIModel {
  async createMany(data: Record<string, any>): Promise<any> {
    return RequestManager.request({
      model: APIModels.ProductRequest,
      method: EgalMethods.createMany,
      data: { objects: data },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const ProductRequestAPI = new ProductRequestAPIModel()
export { ProductRequestAPI }
