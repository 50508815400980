<script lang="ts" setup>
import {
  Button,
  Divider,
  Drawer,
  Form,
  FormItem,
  Input,
  InputNumber,
  notification,
  Textarea,
  Alert,
  Tooltip,
} from 'ant-design-vue'
import { ref, computed, onMounted, watch, h } from 'vue'
import { IFarmer, IFarmerProduct, IPriceOption } from '@/types/interfaces'
import { PriceOptionAPI } from '@/api/PriceOptionAPI'
import { UserAPI } from '@/api/UserAPI'
import type { FormInstance, Rule } from 'ant-design-vue/es/form'
import { APIModels } from '@/types/enums'
import { priceWithoutVat } from '@/helpers/scripts'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { useRoute } from 'vue-router'

interface IFormState {
  farmer_product_id: number
  price: number
  min_value: number
  comment?: string
  vat_percent: number
}

interface Props {
  selectedIndex: number
  priceOption: IPriceOption
  product: IFarmerProduct
  products: IFarmerProduct[]
  editPrice: boolean
  isOpen: boolean
}
const props = defineProps<Props>()

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const userId = user?.company_id
const userData = ref<IFarmer>({})

const emit = defineEmits([
  'closeDrawer',
  'getPriceOptions',
  'showModal',
  'openUpdatePageModal',
])
const closeDrawer = () => {
  emit('closeDrawer')
}
const getSpecialPrices = () => {
  emit('getPriceOptions', props?.product?.id)
}
const showModal = () => {
  emit('showModal', props?.priceOption)
}

const formState = ref<IFormState>({})
const setForm = async () => {
  formState.value.min_value = props?.priceOption?.min_value
  formState.value.comment = props?.priceOption?.comment
  formState.value.price = props?.priceOption?.price
  formState.value.vat_percent = props?.priceOption?.vat_percent
}

const onFinish = async (values: any) => {
  values.farmer_product_id = props?.product?.id
  if (props?.editPrice) {
    await updateOption(values)
  } else {
    await createOption(values)
  }
}

const createOption = async (values: any) => {
  await PriceOptionAPI.createMany([values]).then(async () => {
    notificationCreateOrEdit()
    closeDrawer()
    getSpecialPrices()
  })
}

const route = useRoute()
const productId = route.params.id
const product = ref<IFarmerProduct>()
const priceOptions = ref<IPriceOption[]>([])
const currentPriceOption = ref<IPriceOption>()

const priceOptionsAscending = () => {
  priceOptions.value = priceOptions.value?.sort((a, b) =>
    Number(a.min_value) > Number(b.min_value) ? 1 : -1,
  )
}

const getPriceOption = async () => {
  FarmerProductAPI.getPriceOptions(
    [['farmer_product_id', 'eq', String(productId)]],
    [['min_value', 'asc']],
  ).then((res) => {
    const itemsSorted = res.items?.sort((a, b) =>
      Number(a.min_value) > Number(b.min_value) ? 1 : -1,
    )
    priceOptions.value = itemsSorted
    currentPriceOption.value = itemsSorted[props?.selectedIndex]
    priceOptionsAscending()
  })
}

const updateOption = async (values: any) => {
  const originalPriceOption = props.priceOption

  const originalPriceOptionPrep = {
    comment: originalPriceOption?.comment,
    price: originalPriceOption?.price,
    min_value: originalPriceOption?.min_value,
  }

  const currentPriceOptionPrep = {
    comment: currentPriceOption.value?.comment,
    price: currentPriceOption.value?.price,
    min_value: currentPriceOption.value?.min_value,
  }

  if (
    JSON.stringify(originalPriceOptionPrep) ===
    JSON.stringify(currentPriceOptionPrep)
  ) {
    console.debug('no diff data')

    await PriceOptionAPI.delete([props?.priceOption?.id])
      .then(async () => {
        await createOption(values)
        await getPriceOption()
        emit('openUpdatePageModal', false)
      })
      .catch((e) => {
        console.error('updateOption e', e)
        closeDrawer()
        emit('openUpdatePageModal', true)
      })
  } else if (
    JSON.stringify(originalPriceOptionPrep) !==
    JSON.stringify(currentPriceOptionPrep)
  ) {
    console.error('diff data')
    emit('openUpdatePageModal', true)
  }
}

onMounted(async () => {
  await getPriceOption()
})

watch(
  () => props?.selectedIndex,
  async () => {
    await getPriceOption()
  },
)

const deleteOption = () => {
  closeDrawer()
  showModal()
}

const validatePrice = async (_rule?: Rule, value?: number) => {
  if (!formState.value?.price || Number(value) <= 0 || isNaN(value)) {
    return Promise.reject('Укажите цену')
  } else {
    return Promise.resolve()
  }
}

const validateValue = async (_rule: Rule, value?: number) => {
  if (!Number(value)) {
    return Promise.reject('Укажите объём')
  }
  if (
    Number(value) <= 0 ||
    (props?.product?.measurement_unit_id === 'шт' && Number(value) < 1)
  ) {
    return Promise.reject(
      `Не меньше ${props?.product?.measurement_unit_id == 'кг' ? '0,01 кг' : '1 шт'}`,
    )
  } else if (Number(value) > 1000000) {
    return Promise.reject('Не больше 1000000')
  }
}

const validateComment = async (_rule: Rule, value?: string) => {
  if (!value) {
    return Promise.resolve()
  }
  if (value.length > 1000) {
    return Promise.reject('Максимум 1000 символов')
  }
}

const rules: Record<string, Rule[]> = {
  min_value: [{ required: true, validator: validateValue, trigger: 'change' }],
  price: [{ required: true, validator: validatePrice, trigger: 'change' }],
  comment: [{ required: false, validator: validateComment, trigger: 'change' }],
}

onMounted(async () => {
  await UserAPI.getUserData(userId, APIModels.Farmer).then((res) => {
    userData.value = res
  })
})

watch(props, () => {
  if (props.isOpen) {
    if (props?.priceOption) {
      setForm()
    } else {
      formState.value = {}
    }
  }
})

const notificationCreateOrEdit = () => {
  notification.info({
    message: `${props?.editPrice ? 'Изменения сохранены' : 'Добавлен вариант цены'}`,
    top: '80px',
    icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
  })
}

const getSuffix = (input: string) => {
  let suffix = props?.product?.measurement_unit_id // "шт или кг"
  if (input === 'price') suffix = '₽/' + suffix
  return suffix
}

const getPrevIndex = () => {
  let index = 0
  props?.product?.price_options?.forEach((option) => {
    if (Number(formState.value.min_value) < Number(option.min_value)) {
      return
    }
    index++
  })
  if (index - 1 === -1) return index
  return index - 1
}

// Находим предыдущую опцию, сравнивая цены товаров
// --------------------------------------------------
// Объём "до" при редактировании цены (editPrice) будет равняться возможной максимальной для её диапазона
// При редактировании цены "будет назначена" отображает что вводят
// ---------------------------------------------------
// Если цена создаётся, находим предыдущую цену (prevOption[prevIndex]) и подставляем значение (min_value, price)
const getAlertMessage = () => {
  let prevIndex = getPrevIndex()
  let prevOption = props?.product?.price_options
  let price = formState.value?.price
  if (formState.value.min_value && Number(price) > 0)
    return `Диапазону от
            ${prevOption[prevIndex].min_value}
            до ${props?.editPrice ? getMaxValue.value : formState.value.min_value}
            ${props.product?.measurement_unit_id}
            будет назначена цена
            ${props?.editPrice ? price : prevOption[prevIndex].price}
            ₽/${props?.product?.measurement_unit_id}`
  else return 'Укажите цену за единицу товара при заказе выбранного объема.'
}

const getErrorMessage = computed(() => {
  if (equalOneOfOptions() === 'priceEqual')
    return 'Указанная цена установлена для соседнего диапазона'
  else return 'У вас уже есть цена для данного промежутка объёма'
})

const getMinInputValue = computed(() => {
  if (props?.product?.measurement_unit_id === 'кг') {
    return 0.01
  } else return 1
})

const equalOneOfOptions = () => {
  if (props?.editPrice) {
    // Если мы редактируем опцию цену
    let options = props?.product?.price_options
    let optionIndex = options?.findIndex(
      (option) => option.id === props?.priceOption?.id,
    ) // Находим, какой индекс у этой цены среди остальных
    if (
      // Проверяем, равна ли цена этой опции цены с соседними
      formState.value.price === options[optionIndex + 1]?.price ||
      formState.value.price === options[optionIndex - 1]?.price
    ) {
      return 'priceEqual'
    }
    return false
  }
  // Сверяем, создаваемая цена имеет ли такую же цену или объём "от"
  if (
    props.product?.price_options?.find(
      (i) => Number(i.price) === Number(formState.value.price),
    )
  ) {
    return 'alreadyHavePrice'
  } else if (
    props?.product?.price_options?.find(
      (i) => Number(i.min_value) === Number(formState.value.min_value),
    )
  ) {
    return 'alreadyHavePrice'
  } else return false
}

const getMaxValue = computed(() => {
  let index = 0
  props?.product?.price_options?.forEach((option) => {
    if (Number(formState.value.min_value) < Number(option.min_value)) {
      return
    }
    index++
  })
  if (
    index + 1 > props?.product?.price_options?.length ||
    !formState.value.min_value
  ) {
    return 1000000
  } else {
    let subtractMin = props?.product?.measurement_unit_id == 'кг' ? 0.01 : 1
    return props?.product?.price_options[index].min_value - subtractMin
  }
})
</script>

<template>
  <Drawer
    width="500"
    :open="false"
    :bodyStyle="{ padding: '0px' }"
    :headerStyle="{
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      padding: '24px 64px 24px 32px',
    }"
    class="drawer-wrapper"
  >
    <template #extra>
      <h4>{{ editPrice ? 'Редактирование' : 'Создание' }} цены</h4>
      <div class="product-name">
        {{ product?.product?.fullName }}
      </div>
    </template>
    <div>
      <Form
        :rules="rules"
        :model="formState"
        name="formState"
        @finish="onFinish"
      >
        <div class="form-section">
          <div class="inputs">
            <div class="input-row">
              <FormItem required label='Объём "от"' name="min_value">
                <Tooltip
                  placement="topLeft"
                  :trigger="editPrice ? ['hover'] : ''"
                >
                  <template #title>
                    Нельзя редактировать параметры объёма
                  </template>
                  <Input
                    :disabled="editPrice"
                    :placeholder="getMinInputValue"
                    :step="0.01"
                    decimalSeparator=","
                    type="number"
                    :suffix="props?.product?.measurement_unit_id"
                    v-model:value="formState.min_value"
                  />
                </Tooltip>
              </FormItem>
              <FormItem required label='Объём "до"'>
                <Tooltip placement="topRight">
                  <template #title>
                    Нельзя редактировать параметры конечного объёма
                  </template>
                  <Input
                    disabled
                    type="number"
                    :suffix="props?.product?.measurement_unit_id"
                    :value="getMaxValue"
                  />
                </Tooltip>
              </FormItem>
            </div>
            <div class="input-row">
              <FormItem label="Цена c НДС" name="price" :step="0.01">
                <InputNumber
                  v-model:value="formState.price"
                  class="drawer-number-input"
                  :controls="false"
                  :step="0.01"
                  decimalSeparator=","
                  :precision="2"
                >
                  <template #addonAfter>
                    <span>{{ getSuffix(`price`) }}</span>
                  </template>
                </InputNumber>
                <template
                  #extra
                  v-if="formState.price && props?.product?.vat_percent"
                >
                  НДС {{ props?.product?.vat_percent }}%
                </template>
              </FormItem>

              <FormItem
                required
                class="price-item"
                label="Цена без НДС"
                :step="0.01"
              >
                {{ product }}
                <InputNumber
                  disabled
                  :step="0.01"
                  decimalSeparator=","
                  class="drawer-number-input number-input-disabled"
                  :value="
                    props?.product?.vat_percent
                      ? +priceWithoutVat(
                          formState.price,
                          props?.product?.vat_percent,
                        )
                      : formState.price
                  "
                >
                  <template #addonAfter>
                    <span>{{ getSuffix(`price`) }}</span>
                  </template>
                </InputNumber>
              </FormItem>
            </div>
            <Alert
              v-if="equalOneOfOptions()"
              show-icon
              type="error"
              :message="getErrorMessage"
            />
            <Alert v-else show-icon type="info" :message="getAlertMessage()" />
          </div>
        </div>
        <Divider />
        <div class="form-section">
          <FormItem
            label="Комментарий"
            name="comment"
            help="Максимум 1000 символов"
          >
            <Textarea
              placeholder="Необязательно"
              v-model:value="formState.comment"
            />
          </FormItem>
        </div>
        <div class="drawer-footer">
          <Divider />
          <div class="buttons">
            <div class="close-save">
              <Button size="large" @click="closeDrawer" class="close">
                Закрыть
              </Button>
              <Button
                :disabled="!!equalOneOfOptions()"
                size="large"
                type="primary"
                html-type="submit"
              >
                {{ editPrice ? 'Сохранить' : 'Создать' }}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </Drawer>
</template>

<style lang="scss" scoped>
.product-name {
  height: fit-content;
  margin-top: 4px;
  width: 404px;
  color: #000000a6;
  font-size: 14px;
}

.form-section {
  padding: 24px 32px 0 32px;

  .details-title {
    margin-bottom: 20px;
  }

  .inputs {
    width: -webkit-fill-available;
    width: -moz-available;

    div {
      display: flex;
    }

    :deep(.ant-alert-message) {
      font-size: 12px;
    }

    .input-row {
      display: flex;
      gap: 16px;
    }

    .ant-form-item,
    .ant-picker {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
}

textarea {
  height: 317px;
  max-height: 317px;
  width: 436px;
}

.drawer-footer {
  bottom: 0;
  margin-bottom: 24px;
  position: absolute;
  height: 88px;
  width: 100%;

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 28px;
    margin-top: 24px;

    .delete {
      left: 32px;
      bottom: 24px;
      position: absolute;
    }

    .close-save {
      .close {
        margin-right: 12px;
      }
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
}

:deep(.ant-row) {
  display: block;
}

:deep(.ant-divider-horizontal) {
  margin-bottom: 0;
}

:deep(.ant-form-item) {
  margin-bottom: 20px;
}

:deep(.ant-tooltip-inner) {
  width: 385px;
}

.drawer-number-input {
  :deep(.ant-input-number-group-addon) {
    background-color: transparent;
  }
}

.number-input-disabled {
  :deep(.ant-input-number-group-addon) {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
  }
  :deep(.ant-input-number-input) {
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>
