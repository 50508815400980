<script lang="ts" setup>
import AddIcon from '@/components/Icons/AddIcon.vue'
import { computed, onMounted, ref, watch } from 'vue'
import {
  fontSize14,
  primaryButton,
  withoutBGButton,
} from '@/assets/EgalStyles/EButton'
import ModalWindow from '@/components/Modal/ModalWindow.vue'
import { IAddress, IClientAddress } from '@/types/IAddress'
import { formatPhoneNumber } from '@/helpers/scripts'
import { ContactData, UserContactType } from '@/types/types'
import AddContactForm from '@/components/AccountForms/AddContactForm.vue'
import AddAddressForm from '@/components/AccountForms/AddAddressForm.vue'
import { AccountActions, ProfileStatus, Roles } from '@/types/enums'
import InfoTooltip from '@/components/Tooltip/InfoTooltip.vue'
import Dot from '../../assets/img/dot.svg'
import AccountEdit from '../../assets/img/account-edit.svg'
import Delete from '../../assets/img/deleteSvg.svg'

interface Props {
  userAddress?: IClientAddress[]
  titlePage: string
  userContacts?: UserContactType[]
  role: Roles
  status: ProfileStatus
}

const props = defineProps<Props>()
const emit = defineEmits(['send', 'delete', 'update'])

const showModal = ref(false)
const closeModal = () => {
  showModal.value = false
  selectItem.value = null
}
const selectItem = ref<IClientAddress | UserContactType | null>(null)
const editAddress = ref<IClientAddress | null>(null)
const editContact = ref<UserContactType | null>(null)

const deleteItem = (item: IClientAddress | UserContactType) => {
  showModal.value = true
  selectItem.value = item
}

const confirmDelete = () => {
  emit('delete', selectItem.value)
  closeModal()
}

const isAddForm = ref(false)
const onAddClick = () => {
  isAddForm.value = true
}

const onEditClick = (item: IClientAddress | UserContactType) => {
  isAddForm.value = true
  if (props.userAddress) {
    editAddress.value = item as IClientAddress
  }
  if (props.userContacts) {
    editContact.value = item as UserContactType
  }
}

const onBackClick = () => {
  isAddForm.value = false
  editAddress.value = null
  editContact.value = null
}

const onAddressSendClick = (addresses: IAddress[]) => {
  isAddForm.value = false
  emit('send', addresses)
  editAddress.value = null
  editContact.value = null
}

const onContactSendClick = (contacts: ContactData[]) => {
  isAddForm.value = false
  emit('send', contacts)
  editAddress.value = null
  editContact.value = null
}

const changeIsAddForm = () => {
  isAddForm.value = false
  editAddress.value = null
  editContact.value = null
}

const userData = computed(() => {
  return props.userAddress ? props.userAddress : props.userContacts
})

onMounted(() => {
  if (localStorage.getItem('MyAccountAction') === AccountActions.AddAddress) {
    isAddForm.value = true
    localStorage.removeItem('MyAccountAction')
  }
})

watch(
  () => [props.userAddress, props.userContacts],
  () => {
    userData.value
  },
)
</script>

<template>
  <!--  <button @click="emit('update')">обновить</button>-->
  <AddAddressForm
    v-if="isAddForm && props.userAddress"
    :editingAddress="editAddress || null"
    title="Вернуться к списку адресов"
    @prev="onBackClick"
    @send="onAddressSendClick"
    @change-form="changeIsAddForm"
  />
  <div class="address-card card" v-if="!isAddForm">
    <div class="card-header">{{ props.titlePage }}</div>
    <div
      v-if="
        status !== ProfileStatus.NeedToVerify &&
        status !== ProfileStatus.Verifying
      "
      class="wrapper"
    >
      <div class="card-body">
        <div v-for="(item, index) in userData" :key="item.id" class="body">
          <div class="content">
            <div class="info">
              <div class="address">{{ item.street || item.name }}</div>
              <div class="contact">
                <span
                  v-if="props.userAddress && item.name"
                  class="address-name"
                  >{{ item.name }}</span
                >
                <span
                  v-if="props.userContacts && item.post"
                  class="address-name"
                  >{{ item.post }}</span
                >
                <img
                  v-if="props.userAddress && item.name && item.contact_number"
                  alt="dot"
                  :src="Dot"
                  loading="lazy"
                />
                <img
                  v-if="props.userContacts && item.post && item.phone"
                  alt="dot"
                  :src="Dot"
                  loading="lazy"
                />
                <span
                  v-if="item.contact_number || item.phone"
                  class="address-phone"
                  >{{
                    formatPhoneNumber(item.contact_number || item.phone)
                  }}</span
                >
              </div>
            </div>
            <div class="btns">
              <div class="edit-btn" @click="onEditClick(item)">
                <img alt="edit" :src="AccountEdit" loading="lazy" />
              </div>
              <div
                v-if="userData.length > 1"
                class="delete-btn"
                @click="deleteItem(item)"
              >
                <img alt="delete" :src="Delete" loading="lazy" />
              </div>
            </div>
          </div>

          <div v-if="index + 1 !== userData.length" class="border"></div>
        </div>
      </div>
      <div
        v-if="!(props.role === Roles.Farmer && props.userAddress)"
        class="card-footer"
      >
        <div class="addBtn">
          <button @click="onAddClick">
            <AddIcon fill="#0066FF" />
            {{
              props.userAddress ? 'Добавить адрес' : 'Добавить контактное лицо'
            }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="status === ProfileStatus.Verifying">
      <InfoTooltip
        v-if="props.userAddress"
        message="Дождитесь проверки вашей организации, чтобы заполнить список адресов"
      />
      <InfoTooltip
        v-if="props.userContacts"
        message="Дождитесь проверки вашей организации, чтобы заполнить контактные лица"
      />
    </div>
    <div v-if="status === ProfileStatus.NeedToVerify">
      <InfoTooltip
        v-if="props.userAddress"
        message="Пройдите верификацию, чтобы заполнить список адресов"
      />
      <InfoTooltip
        v-if="props.userContacts"
        message="Пройдите верификацию, чтобы заполнить контактные лица"
      />
    </div>
  </div>
  <!-- Модалка по удалению адреса -->
  <Teleport to="body">
    <ModalWindow
      :show="showModal"
      @close="closeModal"
      :show-close-icon="false"
      class="delete-modal"
    >
      <template #header>
        <div class="delete-header" v-if="props.userAddress">
          Вы уверены, что хотите удалить адрес?
        </div>
        <div class="delete-header" v-if="props.userContacts">
          Вы уверены, что хотите удалить контакт?
        </div>
      </template>
      <template #body>
        <div class="delete-buttons">
          <EButton
            @click="confirmDelete"
            :style-config="{
              ...primaryButton,
              fontSize14,
              width: 'fit-content',
              padding: '11px 30px',
            }"
            >Удалить</EButton
          >
          <EButton @click="closeModal" :style-config="withoutBGButton"
            >Отмена
          </EButton>
        </div>
      </template>
    </ModalWindow>
  </Teleport>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import 'accountStyles';
.card {
  padding: 48px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  min-width: 608px;
  display: flex;
  flex-direction: column;
  &-header {
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .border {
    width: 100%;
    height: 24px;
    border-top: 1px solid $gray-300;
  }
  &-body {
    .content {
      display: flex;
      gap: 24px;
      justify-content: space-between;
      margin-bottom: 24px;

      .info {
        .address {
          max-width: 505px;
          font-weight: 400;
          font-size: 16px;
          color: $gray-800;
          margin-bottom: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .contact {
          font-weight: 400;
          font-size: 14px;
          color: $gray-600;
          display: flex;

          .address-name {
            max-width: 360px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }

      .btns {
        display: flex;
        gap: 18px;
        justify-content: center;
        align-items: center;

        .edit-btn {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: #deebfc;

          img {
            margin: 13px;
          }
          &:hover {
            cursor: pointer;
          }
        }

        .delete-btn {
          width: 20px;
          height: 20px;
          img {
            margin: 3px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-footer {
    .addBtn {
      button {
        height: 48px;
        display: flex;
        gap: 12px;
        font-weight: 500;
        font-size: 16px;
        color: #0066ff;
        background-color: inherit;
        align-items: center;
      }

      :hover {
        cursor: pointer;
      }
    }
  }
}

.delete-modal {
  :deep(.wrapper) {
    padding: 32px;
    border-radius: 8px;
    width: 420px;
  }
  :deep(.modal-container) {
    padding: 0;
    border-radius: 8px;
    width: 362px;
  }
  :deep(.modal-header) {
    justify-content: center;
  }
}
.delete-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $gray-800;
  margin-bottom: 24px;
}
.delete-buttons {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
</style>
