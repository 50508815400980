<script lang="ts" setup>
import { computed, onUnmounted } from 'vue'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import CustomerReductions from '@/views/Customer/reductions/CustomerReductions.vue'
import FarmerReductions from '@/views/Farmer/reductions/FarmerReductions.vue'
import { reductionStore } from '@/store/reductionStore'
import {useCookies} from "vue3-cookies";
import {useRoute} from "vue-router";

const { cookies } = useCookies()

const { getCoreCookie } = useJWTDecode()

const isCustomer = computed(() => getCoreCookie()?.company_type === Roles.Customer)

const store = reductionStore()

onUnmounted(() => {
  store.$reset()
})

const route = useRoute()
</script>

<template>
  <div class="reductions-view">
    <CustomerReductions v-if="isCustomer" />
    <FarmerReductions v-else />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
@import '@/assets/style/mixins.scss';
.reductions-view {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 20px;
  @include wrapper();
  &__filter {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .initial-loader {
    min-height: 70vh;
  }
}
</style>
