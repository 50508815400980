<script lang="ts" setup>
import {
  fontSize16,
  primaryButton,
  secondaryBlueButton,
} from '@/assets/EgalStyles/EButton'
import router from '@/router'
import CompleteCart from '../../assets/img/complete-cart.svg'


interface Props {
  title: string
  description: string
  lastOrderId?: number | undefined
}

const props = defineProps<Props>()

const goToMain = () => {
  router.push('/')
}

const goToHistory = () => {
  router.push('/history')
}

const goToOrder = () => {
  router.push(`/history/${props.lastOrderId}`)
}
</script>

<template>
  <div class="complete-cart">
    <div class="img">
      <img alt="complete-cart" :src="CompleteCart" loading="lazy" />
    </div>
    <div class="content">
      <h2 class="title">{{ props.title }}</h2>
      <span class="description">
        {{ props.description }}
      </span>
    </div>
    <div class="buttons-column">
      <EButton
        :style-config="{
          ...primaryButton,
          ...fontSize16,
          hover: {
            backgroundColor: '#3385FF',
          },
          active: {
            backgroundColor: '#005CE4',
          },
          padding: '14px 18px',
          justifyContent: 'center',
        }"
        @click="goToOrder"
      >
        Перейти к заказу
      </EButton>
      <EButton
        :style-config="{
          ...secondaryBlueButton,
          backgroundColor: 'transparent',
          hover: {
            color: '#3385FF',
          },
          active: {
            color: '#005CE4',
          },
          ...fontSize16,
          padding: '14px 18px',
          justifyContent: 'center',
        }"
        @click="goToHistory"
      >
        К списку заказов
      </EButton>
      <EButton
        :style-config="{
          ...secondaryBlueButton,
          backgroundColor: 'transparent',
          hover: {
            color: '#3385FF',
          },
          active: {
            color: '#005CE4',
          },
          ...fontSize16,
          padding: '14px 18px',
          justifyContent: 'center',
        }"
        @click="goToMain"
      >
        Вернуться на главную
      </EButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.complete-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 360px;

  .content {
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $gray-800;
      margin-bottom: 8px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }
  }

  .buttons-column {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
</style>
