<script lang="ts" setup>
import {
  Button,
  //DatePicker,
  Divider,
  Drawer,
  Form,
  FormItem,
  Input,
  InputNumber,
  notification,
  Select,
  Textarea,
} from 'ant-design-vue'
import { ref, watch, h, computed } from 'vue'
import { CustomerAPI } from '@/api/CustomerAPI'
import { IFarmer, IFarmerProduct, ISpecialPrice } from '@/types/interfaces'
import { SpecialPriceAPI } from '@/api/SpecialPriceAPI'
import type { Rule } from 'ant-design-vue/es/form'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'
import { priceWithoutVat } from '@/helpers/scripts'

interface IFormState {
  farmer_product_id: number
  customer_id: string
  comment?: string
  price: number | string
  vat_percent: number
}

interface IProps {
  index: number
  specialPrice: ISpecialPrice
  product: IFarmerProduct
  editPrice: boolean
  isOpen: boolean
}

const props = defineProps<IProps>()

const emit = defineEmits([
  'closeDrawer',
  'getSpecialPrices',
  'showModal',
  'openUpdatePageModal',
])
const closeDrawer = () => {
  emit('closeDrawer')
}
const getSpecialPrices = () => {
  emit('getSpecialPrices', props.product.id)
}

// Options
interface IOption {
  value: string
  label: string
}

const options = ref<IOption[]>([])
const makeOptions = async () => {
  options.value = []
  let adding = true
  customers.value?.forEach((item) => {
    if (props.editPrice) {
      adding = true
    } else {
      if (
        props?.product?.special_prices?.find(
          (special) => special?.customer_id == item?.id,
        )
      ) {
        adding = false
      } else adding = !(!item?.is_active || !item?.is_docs_sent)
    }
    if (adding) {
      options?.value.push({
        value: item?.id,
        label: `${item?.legal_form} ${item?.name}`,
      })
    }
  })
  sortOptions()
}

// Сортировка выбора покупателей по алфавиту
const sortOptions = () => {
  options?.value?.sort((a, b) => {
    if (a?.label > b?.label) {
      return 1
    }
    if (a?.label < b?.label) {
      return -1
    }
    return 0
  })
}

const formState = ref<IFormState>({})

const setForm = async () => {
  formState.value.customer_id = props?.specialPrice?.customer_id
  formState.value.comment = props?.specialPrice?.comment
  formState.value.price = props?.specialPrice?.price
  formState.value.vat_percent = props?.specialPrice?.vat_percent
}

const onFinish = async (values: any) => {
  values.farmer_product_id = props?.product?.id
  values.quantity = values?.quantity ? values?.quantity : null
  if (props.editPrice) {
    await updateSpecial(values)
  } else {
    await createSpecial(values)
  }
}

const createSpecial = async (values: any) => {
  await SpecialPriceAPI.create(values).then(async () => {
    notificationCreateOrEdit()
    closeDrawer()
    getSpecialPrices()
  })
}

const currentSpecialPrice = ref<ISpecialPrice>()

const getProduct = async (id: number) => {
  await FarmerProductAPI.getItem(Number(id), ['specialPrices.customer']).then(
    (res) => {
      currentSpecialPrice.value = res?.special_prices?.sort(
        (a: any, b: any) => {
          const nameA = a?.customer?.name?.toLowerCase()
          const nameB = b?.customer?.name?.toLowerCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        },
      )
    },
  )
  sortSpecialPrices()
}

// Сортировка спеццен по покупателям
const sortSpecialPrices = () => {
  currentSpecialPrice?.value?.sort((a: any, b: any) => {
    const firstFullname = `${a?.customer?.legal_form} ${a?.customer?.name}`
    const secondFullname = `${b?.customer?.legal_form} ${b?.customer?.name}`

    if (firstFullname > secondFullname) {
      return 1
    }
    if (firstFullname < secondFullname) {
      return -1
    }
    return 0
  })
}

const updateSpecial = async (values: any) => {
  const originalSpecialPrice = props?.specialPrice

  getProduct(props.product?.id).then(async () => {
    const originalSpecialPricePrep = {
      comment: originalSpecialPrice?.comment || '',
      price: originalSpecialPrice?.price,
      vat_percent: originalSpecialPrice?.vat_percent,
    }

    let currentSpecialPricePrep

    if (currentSpecialPrice?.value?.length) {
      currentSpecialPricePrep = {
        comment:
          currentSpecialPrice?.value[props.index]?.comment ||
          originalSpecialPrice?.comment ||
          '',
        price: currentSpecialPrice?.value[props.index]?.price,
        vat_percent: currentSpecialPrice?.value[props.index]?.vat_percent,
      }
    } else {
      currentSpecialPricePrep = {
        comment: originalSpecialPrice?.comment || '',
        price: originalSpecialPrice?.price,
        vat_percent: originalSpecialPrice?.vat_percent,
      }
    }

    if (
      JSON.stringify(originalSpecialPricePrep) ===
      JSON.stringify(currentSpecialPricePrep)
    ) {
      console.debug('no diff data')

      let data = {
        id: props?.specialPrice?.id,
        ...values,
      }

      await SpecialPriceAPI.update(data).then(async (res) => {
        notificationCreateOrEdit()
        getSpecialPrices()
        closeDrawer()
      })

      emit('openUpdatePageModal', false)
    } else if (
      JSON.stringify(originalSpecialPricePrep) !==
      JSON.stringify(currentSpecialPricePrep)
    ) {
      console.error('diff data')
      emit('openUpdatePageModal', true)
    }
  })
}

// const deleteSpecial = async (product: any) => {
//   closeDrawer()
//   showModal(product)
// }

const filterOption = (input: string, options: any) => {
  return options.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const validatePrice = async (_rule?: Rule, value?: number) => {
  if (value && value > 1000000) {
    return Promise.reject('Максимум 1 000 000 Р')
  }
  if (!formState?.value?.price || Number(value) <= 0 || isNaN(value as any)) {
    return Promise.reject('Минимум 0,01 Р')
  } else {
    return Promise.resolve()
  }
}

// const dateFormat = 'DD.MM.YYYY'
// const disabledDate = (current: dayjs) => {
//   return current && current <= dayjs().endOf('day')
// }

const validateVAT = async (_rule?: Rule, value?: number) => {
  if (
    !formState.value?.vat_percent ||
    Number(value) < 0 ||
    isNaN(value as any)
  ) {
    return Promise.reject('Укажите НДС')
  } else if (Number(value) > 100) {
    return Promise.reject('Максимум 100%')
  } else {
    return Promise.resolve()
  }
}

// const validateQuantity = async (_rule: Rule, value?: number) => {
//   if (!value) {
//     return Promise.resolve()
//   } else if (value.includes('-')) {
//     return Promise.reject('Укажите объём закупки')
//   } else if (
//     Number(value) <= 0 ||
//     (props.product.measurement_unit_id === 'шт' && Number(value) < 1)
//   ) {
//     return Promise.reject(
//       `Не меньше ${props.product.measurement_unit_id == 'кг' ? '0,01 кг' : '1 шт'}`,
//     )
//   } else if (Number(value) > 1000000) {
//     return Promise.reject('Не больше 1000000')
//   }
// }

const validateComment = async (_rule: Rule, value?: string) => {
  if (!value) {
    return Promise.resolve()
  }
  if (value.length > 1000) {
    return Promise.reject('Максимум 1000 символов')
  }
}

const rules: Record<string, Rule[]> = {
  customer_id: [{ required: true, message: 'Выберите покупателя' }],
  price: [{ required: true, validator: validatePrice, trigger: 'change' }],
  vat_percent: [{ required: true, validator: validateVAT, trigger: 'change' }],
  // quantity: [{ required: false, validator: validateQuantity, trigger: 'change' },],
  comment: [{ required: false, validator: validateComment, trigger: 'change' }],
}

// Customer
const customers = ref<IFarmer>()
const getCustomers = async () => {
  await CustomerAPI.getItems([], [], [['name', 'desc']], 100).then((res) => {
    customers.value = res.items
  })
  await makeOptions()
}

watch(props, () => {
  if (props.isOpen) {
    getCustomers()
    if (props.specialPrice) {
      setForm()
    } else {
      formState.value = {} as IFormState
    }
  }
})

const notificationCreateOrEdit = () => {
  notification.info({
    message: `${props.editPrice ? 'Изменения сохранены' : 'Спеццена создана'}`,
    top: '80px',
    icon: h(InfoCircleFilled, { style: { color: 'rgba(22, 119, 255, 1)' } }),
  })
}

const getSuffix = (input: string) => {
  let suffix = props.product.measurement_unit_id // "шт или кг"
  if (input === 'price') suffix = '₽/' + suffix
  return suffix
}

const priceWithoutVatString = computed(() => {
  return (
    formState.value.price &&
    formState.value.vat_percent &&
    'Цена без НДС' +
      ' ' +
      priceWithoutVat(+formState.value.price, formState.value.vat_percent) +
      ' ' +
      getSuffix(`price`)
  )
})
</script>

<template>
  <Drawer
    width="500"
    :bodyStyle="{ padding: '0px' }"
    :headerStyle="{
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      padding: '24px 64px 24px 32px',
    }"
  >
    <template #extra>
      <h4>{{ editPrice ? 'Редактирование' : 'Создание' }} спеццены</h4>
      <div class="product-name">
        {{ product.product.fullName }}
      </div>
    </template>

    <div class="drawer-main">
      <Form
        :rules="rules"
        :model="formState"
        name="formState"
        @finish="onFinish"
      >
        <div class="form-section">
          <FormItem label="Покупатель" name="customer_id">
            <Select
              show-search
              :disabled="editPrice"
              :options="options"
              :filter-option="filterOption"
              v-model:value="formState.customer_id"
            />
          </FormItem>
          <div class="inputs">
            <FormItem
              class="first"
              label="Цена c НДС"
              name="price"
              :step="0.01"
              :extra="priceWithoutVatString"
            >
              <InputNumber
                v-model:value="formState.price"
                class="drawer-number-input"
                :controls="false"
                :step="0.01"
                decimalSeparator=","
                :precision="2"
              >
                <template #addonAfter>
                  <span>{{ getSuffix(`price`) }}</span>
                </template>
              </InputNumber>
            </FormItem>
            <FormItem label="НДС" name="vat_percent" :step="0.01">
              <InputNumber
                v-model:value="formState.vat_percent"
                class="drawer-number-input"
                :controls="false"
                decimalSeparator=","
                :precision="0"
              >
                <template #addonAfter>
                  <span>%</span>
                </template>
              </InputNumber>
            </FormItem>
          </div>
        </div>
        <Divider />
        <div class="form-section">
          <!-- <div class="details-title">
            <h4>Условия спеццены</h4>
            Укажите, когда спеццена прекратит действовать
          </div>
          <div class="inputs">
            <FormItem class="first" label="Объём закупки" name="quantity">
              <Input
                type="number"
                :suffix="getSuffix(`quantity`)"
                v-model:value="formState.quantity"
              />
            </FormItem>
            <FormItem label="Действует до" name="expires_after">
              <DatePicker
                :locale="locale"
                :disabled-date="disabledDate"
                :format="dateFormat"
                placeholder=""
                v-model:value="formState.expires_after"
              />
            </FormItem>
          </div> -->
          <FormItem
            label="Комментарий"
            name="comment"
            help="Максимум 1000 символов"
          >
            <Textarea
              placeholder="Необязательно"
              v-model:value="formState.comment"
            />
          </FormItem>
        </div>
        <div class="drawer-footer">
          <Divider />
          <div class="buttons">
            <!--            <Button-->
            <!--              v-if="editPrice"-->
            <!--              size="large"-->
            <!--              danger class="delete"-->
            <!--              @click="deleteSpecial(product)"-->
            <!--            >-->
            <!--              Удалить-->
            <!--            </Button>-->
            <div class="close-save">
              <Button size="large" @click="closeDrawer" class="close">
                Закрыть
              </Button>
              <Button size="large" type="primary" html-type="submit">
                {{ editPrice ? 'Сохранить' : 'Создать' }}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </Drawer>
</template>

<style lang="scss" scoped>
.product-name {
  height: fit-content;
  margin-top: 4px;
  width: 404px;
  color: #000000a6;
  font-size: 14px;
}

.form-section {
  padding: 24px 32px 0 32px;

  .details-title {
    margin-bottom: 20px;
  }

  .inputs {
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;

    .first {
      margin-right: 16px;
    }

    .ant-form-item,
    .ant-picker {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
}

textarea {
  height: 86px;
  max-height: 200px;
  width: 436px;
}

.drawer-footer {
  bottom: 0;
  position: absolute;
  height: 88px;
  width: 100%;

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 28px;
    margin-top: 24px;

    .delete {
      left: 32px;
      bottom: 24px;
      position: absolute;
    }

    .close-save {
      .close {
        margin-right: 12px;
      }
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
}

:deep(.ant-row) {
  display: block;
}

:deep(.ant-divider-horizontal) {
  margin: 4px 0 0 0;
}

:deep(.ant-form-item) {
  margin-bottom: 20px;
}

.drawer-number-input {
  :deep(.ant-input-number-group-addon) {
    background-color: transparent;
  }
}
</style>
