import { APIModels } from '@/types/enums'
import { IFilter, ISortPartners } from '@/types/interfaces'
import { SigningAnAgreement } from '@/types/types'
import {RequestManager} from "@/helpers/RequestManager";
import {EgalMethods} from "@/types/RequestManagerEnum";
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies()

class PartnersAPIModel {
  async getItems(sort: any[] = [], filter: any[][] = []): Promise<any> {
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.GetItems,
      data: {
        pagination: { per_page: 1, page: 1 },
        withs: ['farmer', 'farmer.company_rating', 'customer', 'customer.company_rating' ],
        order: sort.length ? sort : [['created_at', 'desc']],
        filter
      },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async sign(data: SigningAnAgreement): Promise<any> {
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.sign,
      data: { attributes: data },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async downloadReportPartners(
    filter: IFilter,
    sort: ISortPartners,
  ): Promise<any> {
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.downloadReport,
      data: { filter: filter || [], order: sort || []},
      headers: { Authorization: cookies.get('core')},
    })
  }

  async generateContract(id: number): Promise<any> {
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.generateContract,
      data: { id },
      headers: { Authorization: cookies.get('core')},
    })
  }

  async myPartners(filter?: IFilter, sort?: ISortPartners): Promise<any> {
    return RequestManager.request({
      model: APIModels.Partner,
      method: EgalMethods.myPartners,
      data: {
        pagination: { per_page: 100, page: 1 },
        filter: filter || [],
        withs: ['farmer', 'farmer.company_rating', 'customer', 'customer.company_rating'],
        order: sort || [],
      },
      headers: { Authorization: cookies.get('core')},
    })
  }
}

const PartnersAPI = new PartnersAPIModel()
export { PartnersAPI }
