<template>
  <component :is="layout">
    <template v-slot:content>
      <router-view :key="calcRoute" />
    </template>
  </component>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { useCookies } from 'vue3-cookies';
import router from '@/router';
import AdminLayout from '@/layouts/AdminLayout.vue';
import {initOtelCollector} from "@/helpers/grafanaHelpers";
import {initDisableScrollOnNumberInputListener} from "@/helpers/numberInputHelpers";
import {useFavicon} from "@vueuse/core";
import favicon from "@/assets/favicon.ico"

useFavicon(favicon)
const { cookies } = useCookies();
const route = useRoute();
const calcRoute = computed(() => route.fullPath);
const umrt = localStorage.getItem('umrt');

const layout = computed(() => {
  switch (route.meta.layout) {
    case 'auth':
      return AuthLayout
    case 'admin':
      return AdminLayout
    default:
      return DefaultLayout
  }
})

watch(
  () => umrt,
  async () => {
    const isCookies = cookies.isKey('core')
    if (!isCookies && !umrt) {
      await router.push('/')
    }
  },
)

onMounted(() => {
  initOtelCollector()
  initDisableScrollOnNumberInputListener()
})
</script>

<style lang="scss">
@import '@/assets/style/variables';

.Vue-Toastification__toast {
  background-color: white !important;
  position: relative;
  color: $gray-800 !important;
  box-shadow: $shadow-lg;
  padding: 16px 13px 16px 24px !important;
  min-height: 50px !important;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    background-color: $info;
    left: 0;
    top: 0;
  }
}

.Vue-Toastification__close-button {
  color: $gray-800 !important;
  font-weight: normal !important;
  margin-bottom: auto;
  margin-top: -8px !important;
}
</style>
