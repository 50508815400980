<script lang="ts" setup>
import { Flex } from 'ant-design-vue'
import CreateOrderMainBlock from '@/views/Customer/CreateOrder/components/CreateOrderMainBlock.vue'
import OrderTotalSidebar from '@/components/OrderTotalSidebar/OrderTotalSidebar.vue'
import { registrationOrderStore } from '@/store/registrationOrderStore'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import CartComplete from '@/components/Cart/CartComplete.vue'
import CartError from '@/components/Cart/CartError.vue'
import { useRoute, useRouter } from 'vue-router'
import { priceWithoutVat } from '@/helpers/scripts'

const router = useRouter()

const ALERT =
  'Для поставщиков, у которых нет доставки, будет оформлен самовывоз — вы сможете самостоятельно забрать заказ в установленные сроки'

const store = registrationOrderStore()

const form = ref()

const handleCreateOrder = () => {
  form.value.formRef
    .validate()
    .then(() => {
      store.createOrder()
    })
    .catch((e: Error) => console.log(e))
}

const priceFarmerList = computed(() =>
  store.orderData.farmers?.map((farmer) => {
    const totalPrices = farmer.product_items.reduce(
      (accum, { quantity, chosen_price_option: { price, vat_percent } }) => {
        return {
          totalVat:
            accum.totalVat + quantity * +priceWithoutVat(+price, vat_percent),
          totalPrice: accum.totalPrice + quantity * +price,
        }
      },
      { totalVat: 0, totalPrice: 0 },
    )
    return {
      farmerName: `${farmer.legal_form} ${farmer.name}`,
      id: farmer.id,
      coordinates: farmer.address.coordinates,
      address: farmer.address.street,
      totalVat: totalPrices.totalPrice - totalPrices.totalVat,
      totalPrice: totalPrices.totalPrice,
    }
  }),
)

const isNotAllFarmersHaveDelivery = computed(() =>
  store.orderData.delivery_method === 'Самовывоз'
    ? false
    : store.orderData.farmers.some((farmer) => !farmer.can_deliver),
)

const isFillData = computed(() => Object.keys(store.orderData).length)
const fromAuctionReduction = ref(false)

onMounted(() => {
  checkComplete()
})

const checkComplete = () => {
  const hash = router.currentRoute.value.hash
  if (hash.length) {
    store.createdOrder.orderId = hash.slice(1)
    fromAuctionReduction.value = true
    router.replace('/create-order')
  } else {
    if (!isFillData.value) {
      router.go(-1)
    }
  }
}

onUnmounted(() => {
  localStorage.removeItem('registrationOrder')
})

const route = useRoute()
const calcRoute = computed(() => route)
</script>

<template :key="calcRoute.fullPath">
  <Flex
    v-if="isFillData || fromAuctionReduction"
    justify="center"
    class="wrapper"
  >
    <CartComplete
      v-if="store.createdOrder.orderId"
      :lastOrderId="store.createdOrder.orderId"
      description="Мы уведомили продавца о заказе, пожалуйста, дождитесь подтверждения с его стороны"
      title="Заказ успешно оформлен"
    />
    <CartError
      v-else-if="store.createdOrder.error"
      description="Свяжитесь с тех. поддержкой и мы поможем решить ваш вопрос."
      title="Произошла ошибка"
    />
    <Flex v-else style="width: 100%" :gap="24">
      <CreateOrderMainBlock ref="form" />
      <OrderTotalSidebar
        :isLoading="store.isCreatingOrder"
        :alerts="isNotAllFarmersHaveDelivery ? [ALERT] : []"
        btn-text="Оформить"
        :is-disabled-btn="false"
        :handle-btn-click="handleCreateOrder"
        :price-farmer-list="priceFarmerList"
      />
    </Flex>
  </Flex>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

.wrapper {
  @include wrapper();
  width: 100%;
  padding-top: 16px;
  min-height: calc(100vh - 75px);
}
</style>
