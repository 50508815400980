<script setup lang="ts">

import { useRouter } from 'vue-router'
import { Button } from 'ant-design-vue'
import ModalWindow from '@/components/Modal/ModalWindow.vue'

interface IProps {
  title: string
  open: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['close1CModal'])
const router = useRouter()

const closeModal = () => {
  emit('close1CModal')
}

const goToInstruction = () => {
  router.push('1c-faq')
}
</script>

<template>
  <ModalWindow
    :show="props.open"
    padding-value="20px"
    border-radius="8px"
    @close="closeModal"
  >
    <template #header>
      <div class="modal__title">
        {{ props.title }}
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        Вы можете синхронизировать свои товары с 1С, чтобы <br />
        остатки товаров в системе всегда были актуальными. <br />
        Прежде чем перейти к интеграции, прочитайте <br />
        инструкцию по настройке.
      </div>
    </template>
    <template #footer>
      <div class="modal__footer">
        <Button @click="closeModal"> Закрыть </Button>
        <Button @click="goToInstruction" type="primary">
          Перейти к инструкции
        </Button>
      </div>
    </template>
  </ModalWindow>
</template>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 440px;
  &__title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  &__body {
    max-width: 440px;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
  }
  &__footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
}
</style>
