<script lang="ts" setup>
import { reactive, ref, toRaw } from 'vue'
import { Button, Form, FormInstance, FormItem } from 'ant-design-vue'
import { onlyNumbersPhone } from '@/helpers/scripts'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { AddressType } from '@/types/enums'
import { IClientAddress } from '@/types/IAddress'
import VerificationAddress from '../VerificationSteps/VerificationAddress.vue'
import ChevronLeftIcon from "@/components/Icons/ChevronLeftIcon.vue";

interface Props {
  title: string
  editingAddress?: IClientAddress
}

interface IAddress {
  type: string
  company_type: string
  company_id: number
  name: string
  contact_number: string
  comment: string
  street: string
  coordinates: string
  id?: number
}

const props = defineProps<Props>()
const emit = defineEmits(['prev', 'send', 'change-form'])

const userStr = localStorage.getItem('user')
const user = userStr ? JSON.parse(userStr) : null
const { getCoreCookie } = useJWTDecode()

const formRef = ref<FormInstance>()

const DEFAULT_ADDRESS: IAddress = {
  type:
    getCoreCookie()?.company_type === 'farmer'
      ? AddressType.shipping
      : AddressType.delivery,
  company_type: getCoreCookie()?.company_type,
  company_id: Number(user?.company_id),
  name: '',
  contact_number: '',
  comment: '',
  street: '',
  coordinates: '',
  id: -1,
}

const formState = reactive({
  addresses: [props.editingAddress ?? DEFAULT_ADDRESS],
})

const onSubmit = () => {
  formRef.value.validate().then(() => {
    const address = toRaw(formState).addresses[0]
    const isChanged =
      props.editingAddress?.comment === address.comment &&
      props.editingAddress?.name === address.name &&
      props.editingAddress?.contact_number ===
        onlyNumbersPhone(address.contact_number) &&
      props.editingAddress?.street === address.street

    if (!isChanged) {
      emit('send', [
        {
          ...address,
          contact_number: onlyNumbersPhone(address.contact_number),
        },
      ])
    } else {
      emit('change-form')
    }
  })
}

const updateStreet = (
  street: string,
  index: number,
  coordinates: [number, number],
) => {
  formState.addresses[index] = {
    ...formState.addresses[index],
    street,
    coordinates: coordinates ? JSON.stringify(coordinates) : null,
  }
  formRef.value.validate()
}

const updateName = (name: string, index: number) => {
  formState.addresses[index] = {
    ...formState.addresses[index],
    name,
  }
}

const updateNumber = (number: string, index: number) => {
  formState.addresses[index] = {
    ...formState.addresses[index],
    contact_number: number,
  }
}

const updateComment = (comment: string, index: number) => {
  formState.addresses[index] = {
    ...formState.addresses[index],
    comment,
  }
}
</script>

<template>
  <div class="address-card card">
    <div class="header">
      <Button class="back" @click="emit('prev')"><ChevronLeftIcon /></Button>
      {{ props.title }}
    </div>
    <Form layout="vertical" ref="formRef" :model="formState">
      <VerificationAddress
        v-for="(address, index) in formState.addresses"
        :index="index"
        :address="{
          ...address,
          company_id: '0',
          company_type: '0',
        }"
        @update-street="updateStreet"
        @update-name="updateName"
        @update-number="updateNumber"
        @update-comment="updateComment"
      />
      <FormItem>
        <Button
          html-type="submit"
          size="large"
          type="primary"
          @click="onSubmit"
        >
          Сохранить изменения
        </Button>
      </FormItem>
    </Form>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/style/variables';
@import '@/assets/style/mixins';
@import 'accountStyles';
.card {
  padding: 48px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  min-width: 608px;
  max-width: 870px;
  display: flex;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    .back {
      width: 32px;
      padding: 0;
    }
  }

  :deep(.ant-select) {
    max-width: 687px;
  }

  :deep(.ant-form) {
    max-width: 777px;
  }
  
}
</style>
