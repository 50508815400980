import { defineStore } from 'pinia'
import {
  IFarmerProduct,
  IPriceRequest,
  IProductAnswer,
} from '@/types/interfaces'
import { PriceRequestAPI } from '@/api/PriceRequestAPI'
import { FarmerProductAPI } from '@/api/FarmerProductAPI'

interface ICurrentTab {
  name: string
  value: number
}

interface IState {
  isOpenDrawerSuppliers: boolean
  currentTab: { name: string; value: number }
  currentCustomerRequest: IPriceRequest
  isOpenDrawerOneSupplier: boolean
  chosenSupplierId: number | undefined
  farmerProducts: IFarmerProduct[]
}

export const useCustomerRequestStore = defineStore('customerRequestStore', {
  state: (): IState => ({
    currentTab: { name: 'Данные запроса', value: 1 },
    isOpenDrawerSuppliers: false,
    currentCustomerRequest: {} as IPriceRequest,
    isOpenDrawerOneSupplier: false,
    chosenSupplierId: undefined,
    farmerProducts: [],
  }),
  actions: {
    getCurrentCustomerRequest(id: string) {
      PriceRequestAPI.getItem(id, [
        'products.productCategory',
        'recipients.farmer',
        'recipients.farmer.company_rating',
        'productRequests.product.productCategory',
        'recipient_stats_in_price_request',
        'recipients.priceAnswer.productAnswers.priceOptions',
        'recipients.priceAnswer.productAnswers.specialPrice',
      ])
        .then(async (res: IPriceRequest) => {
          this.currentCustomerRequest = res

          if (res.status === 'closed') {
            this.farmerProducts = await FarmerProductAPI.getFilteredProducts(
              [
                [
                  'farmer_id',
                  'in',
                  res.recipients?.map((recipient) => recipient.farmer_id),
                ],
                'AND',
                [
                  'product_id',
                  'in',
                  res.product_requests.map(
                    (productRequest) => productRequest.product_id,
                  ),
                ],
              ],
              1,
            ).then((res) => res.items)
          }
        })
        .catch((e) => {
          console.log('getCurrentCustomerRequest e', e)
        })
    },
    setCurrentTab(tab: ICurrentTab) {
      this.currentTab = tab
    },
    setIsOpenDrawerSuppliers(isOpen: boolean) {
      this.isOpenDrawerSuppliers = isOpen
    },
    setIsOpenDrawerOneSupplier(isOpen: boolean, supplierId?: number) {
      this.isOpenDrawerOneSupplier = isOpen
      this.chosenSupplierId = supplierId
    },
    resetRequest() {
      this.currentCustomerRequest = {} as IPriceRequest
      this.currentTab = { name: 'Данные запроса', value: 1 }
    },
  },
})
