<script setup lang="ts">
import { fontSize14, primaryButton } from '@/assets/EgalStyles/EButton'
import router from '@/router'
import { Space } from 'ant-design-vue'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'


interface Props {
  isCustomer: boolean
}

const props = defineProps<Props>()
</script>

<template>
  <div class="requests-list-header">
    <h2 class="requests-title">Запросы цен</h2>
    <EButton
      v-if="props.isCustomer"
      :style-config="{
        ...primaryButton,
        fontSize14,
      }"
      @click="router.push('/create-request')"
    >
      <Space :size="8">
        <BootstrapIcon icon="plus-lg" />
        Создать запрос
      </Space>
    </EButton>
  </div>
</template>

<style scoped lang="scss">
.requests-title {
  margin-bottom: 24px;
}
.requests-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
